/* global angular, _ */

angular.module('smartvid').directive('searchInputAutoComplete', function ($sce, $q, $rootScope, $stateParams, tiUtil, utils,
                                                                          QuickSearchType, $filter, searchService,
                                                                          AssetSearchContext, smartvidApi,
                                                                          dashboardDataHelper, SearchEntityModel,
                                                                          searchResultService, assetGridHelper,
                                                                          SEARCH_ENTITY_MODEL_TYPE_FREE_TEXT, SEARCH_ENTITY_MODEL_TYPE_USER) {
  return {
    restrict: 'E',
    require: '^tagsInput',
    templateUrl: 'search-input-auto-complete.html',
    scope: {
      clearSearchState: '=',
      source: '&',
      systemSource: '&'
    },
    controller: function ($scope) {
      this.registerAutocompleteMatch = () => {
        return {
          getQuery: () => {
            return $scope.currentQuery
          }
        }
      }
    },
    link: function (scope, element, attrs, tagsInputCtrl) {
      scope.template = attrs.template
      scope.quickSearchTemplate = attrs.quickSearchTemplate
      scope.recentSearchTemplate = attrs.recentSearchTemplate

      scope.SELECTION_TYPE_QUICK_SEARCH_INDEX = 0
      scope.SELECTION_TYPE_TAG_INDEX = 1
      scope.SELECTION_TYPE_RECENT_SEARCH_INDEX = 2
      scope.SELECTION_TYPE_EXACT_SEARCH_INDEX = 3
      scope.MAX_TYPE = scope.SELECTION_TYPE_EXACT_SEARCH_INDEX

      scope.EXACT_SEARCH = {}

      scope.enableQuickSearch = attrs.enableQuickSearch === 'true'
      scope.enableTagSearch = attrs.enableTagSearch === 'true'
      scope.enableRecentSearch = attrs.enableRecentSearch === 'true'
      scope.enableExactSearch = attrs.enableExactSearch === 'true'
      scope.isObservationsMode = attrs.isObservationsMode === 'true'
      scope.showExactSearch = false

      scope.selectedIndex
      scope.allItems

      scope.currentQuery

      let tagsInput = tagsInputCtrl.registerAutocomplete()
      let lastPromise
      let lastRecentSearchesPromise

      let initQuickSearch = () => {
        scope.allItems[scope.SELECTION_TYPE_QUICK_SEARCH_INDEX] = _.map(QuickSearchType, (t) => {
          return {
            id: t.id,
            type: t.name,
            text: $filter('i18next')('searchInputAutoComplete.' + t.name)
          }
        })
      }
      let resetAssetGroup = () => {
        if ($stateParams.searchFilter) {
          $stateParams.searchFilter.assetGroup = undefined
        }
      }
      let reset = () => {
        scope.selectedIndex = undefined
        scope.currentQuery = undefined

        scope.allItems = [[], [], [], [scope.EXACT_SEARCH]]
        initQuickSearch()
        scope.showQuickSearch = false
        scope.showTagSearch = false
        scope.showExactSearch = false
      }

      reset()

      let length = () => {
        return _.reduce(scope.allItems, (m, i) => {
          return m + i.length
        }, 0)
      }

      let performSelected = () => {
        let l = 0
        for (var i = 0; i <= scope.MAX_TYPE; i++) {
          if (scope.allItems[i].length === 0) {
            continue
          }
          if (scope.selectedIndex >= l && scope.selectedIndex < l + scope.allItems[i].length) {
            scope.perform(scope.selectedIndex - l, i)
            return
          }
          l += scope.allItems[i].length
        }
      }

      let resetSearch = () => {
        scope.$parent.$parent.newTag.text('')
        scope.clearSearchState()
        searchResultService.setCurrentProjectSearchContext(undefined)
        searchResultService.clearProjectSearchInfo()
        searchResultService.setGlobalSearchContext(undefined)
        searchResultService.clearGlobalSearchInfo()
        searchResultService.reset()
      }

      scope.perform = (index, type) => {
        scope.select(index, type)

        if (type === scope.SELECTION_TYPE_QUICK_SEARCH_INDEX) {
          hide()

          smartvidApi.getQuickSearch(
            scope.allItems[scope.SELECTION_TYPE_QUICK_SEARCH_INDEX][index].id,
            dashboardDataHelper.getCurrentProject() ? dashboardDataHelper.getCurrentProject().id : undefined
          ).then((assetSearchContext) => {
            _.each(assetSearchContext.searchCreatedByUsers, (user) => {
              if (!user.text) {
                user.text = user.firstName + ' ' + user.lastName + ' ' + user.email
                user.type = SEARCH_ENTITY_MODEL_TYPE_USER
              }
            })
            const currentSearchContext = searchResultService.getCurrentSearchContext()
            if (angular.isDefined(currentSearchContext) && !assetSearchContext.searchDateRange) {
              assetSearchContext.searchDateRange = currentSearchContext.searchDateRange
            }
            resetSearch()
            searchResultService.reset()
            assetGridHelper.setIsActiveSearch(true)
            resetAssetGroup()
            searchService.searchWithAssetSearchContext(assetSearchContext)
          })
          reset()
        } else if (type === scope.SELECTION_TYPE_TAG_INDEX) {
          tagsInput.addTag(angular.copy(scope.allItems[scope.SELECTION_TYPE_TAG_INDEX][index]))
          assetGridHelper.setIsActiveSearch(true)
          reset()
        } else if (type === scope.SELECTION_TYPE_RECENT_SEARCH_INDEX) {
          let searchCriteria = scope.allItems[scope.SELECTION_TYPE_RECENT_SEARCH_INDEX][index].type
          hide()

          resetSearch()

          assetGridHelper.setIsActiveSearch(true)
          searchResultService.resetCurrentAssetGroup()
          searchService.searchWithAssetSearchContext(searchCriteria)
          reset()
        } else if (type === scope.SELECTION_TYPE_EXACT_SEARCH_INDEX) {
          hide()
          let freeTextSearch = new SearchEntityModel({
            id: SEARCH_ENTITY_MODEL_TYPE_FREE_TEXT,
            text: scope.currentQuery.trim(),
            type: SEARCH_ENTITY_MODEL_TYPE_FREE_TEXT,
            hasChildren: false
          })
          freeTextSearch.text = '"' + freeTextSearch.text + '"'
          tagsInput.addTag(freeTextSearch)
          assetGridHelper.setIsActiveSearch(true)
          reset()
          hide()
        }
      }

      scope.getSelected = () => {
        let l = 0
        for (var i = 0; i <= scope.MAX_TYPE; i++) {
          if (scope.allItems[i].length === 0) {
            continue
          }
          if (scope.selectedIndex >= l && scope.selectedIndex < l + scope.allItems[i].length) {
            return scope.allItems[i][scope.selectedIndex - l]
          }
          l += scope.allItems[i].length
        }
      }

      scope.select = (index, type) => {
        let length = 0
        for (var i = 0; i < type; ++i) {
          length += scope.allItems[i].length
        }
        scope.selectedIndex = index + length
      }

      let loadRecentSearches = (query) => {
        scope.allItems[scope.SELECTION_TYPE_RECENT_SEARCH_INDEX] = []
        let promise = smartvidApi.getRecentSearches(
          query,
          dashboardDataHelper.getCurrentProject() ? dashboardDataHelper.getCurrentProject().id : undefined
        ).then((assetSearchContexts) => {
          if (lastRecentSearchesPromise !== promise) {
            return
          }
          if (!scope.visible) {
            return
          }
          scope.allItems[scope.SELECTION_TYPE_RECENT_SEARCH_INDEX] = _.map(assetSearchContexts,
            (c) => {
              return {
                type: c
              }
            })
          utils.digest(scope)
        })
        lastRecentSearchesPromise = promise
        return promise
      }

      let hide = () => {
        scope.visible = false
      }

      let updateVisibleSections = () => {
        scope.showExactSearch = scope.enableExactSearch && !!scope.currentQuery

        scope.showTagSearch = !!scope.allItems[scope.SELECTION_TYPE_TAG_INDEX] &&
            scope.allItems[scope.SELECTION_TYPE_TAG_INDEX].length > 0 && scope.enableTagSearch

        scope.showQuickSearch = !scope.showExactSearch && !scope.showTagSearch && tagsInput.getTags().length === 0 && scope.enableQuickSearch

        if (!scope.showQuickSearch) {
          scope.allItems[scope.SELECTION_TYPE_QUICK_SEARCH_INDEX] = []
        } else {
          initQuickSearch()
        }
        if (!scope.showTagSearch) {
          scope.allItems[scope.SELECTION_TYPE_TAG_INDEX] = []
        }
        if (!scope.showExactSearch) {
          scope.allItems[scope.SELECTION_TYPE_EXACT_SEARCH_INDEX] = []
        } else {
          scope.allItems[scope.SELECTION_TYPE_EXACT_SEARCH_INDEX] = [scope.EXACT_SEARCH]
        }
      }

      let getDifference = (array1, array2) => {
        return array1.filter((item) => {
          var foundItem = tiUtil.findInObjectArray(array2, item, 'id', (a, b) => {
            return tiUtil.defaultComparer(a, b)
          })

          var foundItemType = foundItem && foundItem.type ? foundItem.type : 'FREE_TEXT'
          return !foundItem || foundItemType !== item.type
        })
      }

      let loadWithQuery = (query) => {
        scope.allItems[scope.SELECTION_TYPE_TAG_INDEX] = []

        let tagsPromise = $q.when(scope.source({
          $query: query
        }))

        let systemTagsPromise = $q.when(scope.systemSource({
          $query: query
        }))

        let populateTagsSection = (items) => {
          scope.allItems[scope.SELECTION_TYPE_TAG_INDEX] = scope.allItems[scope.SELECTION_TYPE_TAG_INDEX].concat(items.data)

          scope.allItems[scope.SELECTION_TYPE_TAG_INDEX] = _.uniq(scope.allItems[scope.SELECTION_TYPE_TAG_INDEX], (t) => {
            return t.text + t.hasChildren
          })
          scope.allItems[scope.SELECTION_TYPE_TAG_INDEX] = getDifference(
              scope.allItems[scope.SELECTION_TYPE_TAG_INDEX], tagsInput.getTags())

          scope.allItems[scope.SELECTION_TYPE_TAG_INDEX] = scope.allItems[scope.SELECTION_TYPE_TAG_INDEX].slice(0, 10)

          if ('extraData' in items) {
            scope.allItems[scope.SELECTION_TYPE_TAG_INDEX] = scope.allItems[scope.SELECTION_TYPE_TAG_INDEX].concat(
              items.extraData)
          }

          scope.selectedIndex = undefined
          scope.currentQuery = query

          updateVisibleSections(scope.SELECTION_TYPE_TAG_INDEX, items)
        }

        systemTagsPromise.then((items) => {
          if (allPromises !== lastPromise) {
            return
          }

          populateTagsSection(items)
        })

        let recentSearchesPromise = tagsInput.getTags().length === 0 && !scope.isObservationsMode ? loadRecentSearches(query) : $q.resolve({})

        let allPromises = $q.all(tagsPromise, recentSearchesPromise, systemTagsPromise)
        tagsPromise.then(function (items) {
          if (allPromises !== lastPromise) {
            return
          }

          populateTagsSection(items)
        })

        lastPromise = allPromises

        lastPromise
          .then(updateVisibleSections)
          .then(() => {
            scope.visible = true
          })
      }

      let load = _.debounce((query) => {
        loadWithQuery(query)
      }, 400)

      tagsInput
        .on('tag-removed', () => {
          if (tagsInput.getTags().length > 0) {
            reset()
            hide()
          } else {
            if (tagsInput.getTags().length === 0 && !scope.isObservationsMode) {
              loadRecentSearches(undefined)
            }
            updateVisibleSections()
            scope.visible = true
          }
        })
        .on('tag-added invalid-tag input-blur', () => {
          reset()
          hide()
        })
        .on('input-change', function (value) {
          load(value)
        })
        .on('input-focus', function () {
          var value = tagsInput.getCurrentTagText()
          load(value)
        })
        .on('input-keydown', function (event) {
          let key = event.keyCode
          let handled = false

          const KEY_TAB = 9
          const KEY_ENTER = 13
          const KEY_ESCAPE = 27
          const KEY_UP = 38
          const KEY_DOWN = 40

          if (tiUtil.isModifierOn(event)) {
            return
          }

          if (!scope.visible && key === KEY_ENTER) {
            tagsInputCtrl.fireSearch()
            handled = true
          }

          if (scope.visible) {
            if (key === KEY_DOWN) {
              scope.selectedIndex = (scope.selectedIndex + 1) || 0
              if (scope.selectedIndex >= length()) {
                scope.selectedIndex = 0
              }
              handled = true
            } else if (key === KEY_UP) {
              scope.selectedIndex = (scope.selectedIndex - 1) || 0
              if (scope.selectedIndex < 0) {
                scope.selectedIndex = length() - 1
              }
              handled = true
            } else if (key === KEY_ESCAPE) {
              reset()
              hide()
              handled = true
            } else if ((key === KEY_ENTER || key === KEY_TAB) && scope.selectedIndex !== undefined) {
              performSelected()
              handled = true
            }
          }

          if (handled) {
            event.preventDefault()
            event.stopImmediatePropagation()
            return false
          }
        })
    }
  }
})

angular.module('smartvid').directive('searchInputListItem', function ($sce, tiUtil) {
  return {
    restrict: 'E',
    require: '^searchInputAutoComplete',
    template: '<ng-include src="$$template"></ng-include>',
    scope: {
      data: '='
    },
    link: function (scope, element, attrs, autoCompleteCtrl) {
      let autoComplete = autoCompleteCtrl.registerAutocompleteMatch()
      scope.$$template = attrs.template

      scope.$index = scope.$parent.$index

      scope.$highlight = function (text) {
        text = tiUtil.safeHighlight(text, autoComplete.getQuery())
        return $sce.trustAsHtml(text)
      }
      scope.$getType = function () {
        return scope.data.type
      }
      scope.$getDisplayText = function () {
        return tiUtil.safeToString(scope.data['text'])
      }
    }
  }
})
