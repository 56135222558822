/* global angular */

/**
 * Created by ryanrubbico on 1/9/17.
 */
// Watch a given element, if it's still in the viewport and disable watchers, when it goes outside of the viewport
angular.module('smartvid').directive('viewportWatcher', function () {
  return {
    restrict: 'AE',
    link: function (scope, element, attr) {
      var LOCK_WATCHERS = false

      function watchDuringDisable () {
        this.$$watchersBackup = this.$$watchersBackup || []
        this.$$watchers = this.$$watchersBackup
        let unwatch = this.constructor.prototype.$watch.apply(this, arguments)
        this.$$watchers = null
        return unwatch
      }

      function toggleWatchers (scope, enable) {
        let digest
        let current
        let next = scope
        do {
          current = next
          if (enable) {
            if (Object.prototype.hasOwnProperty.call(current, '$$watchersBackup')) {
              current.$$watchers = current.$$watchersBackup
              delete current.$$watchersBackup
              delete current.$watch
              digest = !scope.$root.$$phase
            }
          } else {
            if (!Object.prototype.hasOwnProperty.call(current, '$$watchersBackup')) {
              current.$$watchersBackup = current.$$watchers
              current.$$watchers = null
              current.$watch = watchDuringDisable
            }
          }
          next = current.$$childHead
          while (!next && current !== scope) {
            if (current.$$nextSibling) {
              next = current.$$nextSibling
            } else {
              current = current.$parent
            }
          }
        } while (next)
        if (digest) {
          scope.$digest()
        }
      }

      function inViewport ($el, viewPort) {
        let elH = $el.outerHeight()
        let gridRect = viewPort[0].getBoundingClientRect()
        let H = viewPort.height()
        let r = $el[0].getBoundingClientRect()
        let t = r.top
        let b = r.bottom
        let inViewportBool = Math.max(0, t > 0 ? Math.min(elH, H - (t - gridRect.top)) : (b < H ? b : H))
        scope.inViewport = inViewportBool

        /*
         // Used to highlight element that are in viewport
         if (inViewportBool) {
         $el.css({'outline': '1px solid blue'})
         } else {
         $el.css({'outline': '1px solid red'})
         } */

        return inViewportBool
      }

      let previousState
      let viewPort = element.closest('[update-watchers]')
      scope.$on('$updateWatchers', function () {
        if (LOCK_WATCHERS) {
          return
        } // Don't toggle watchers if they are locked

        let newState = inViewport(element, viewPort)
        if (previousState !== newState) {
          toggleWatchers(scope, newState)
          previousState = newState
        }
      })

      function unlockWatchers () {
        LOCK_WATCHERS = false
      }

      scope.$on('$enableAllWatchers', function (event, lockAllOnUntil) {
        if (angular.isObject(lockAllOnUntil) && angular.isFunction(lockAllOnUntil.then)) {
          LOCK_WATCHERS = true
          lockAllOnUntil.then(unlockWatchers)
        }
        toggleWatchers(scope, true)
        previousState = true
      })
    }
  }
})
