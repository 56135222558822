/* global angular */

angular.module('smartvid').directive('thumbnailPlaceholder', ($rootScope) => {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: 'AssetGrid/thumbnail-placeholder.html',
    link: (scope) => {
      if ($rootScope.isMobile) {
        scope.width = '120'
        scope.height = '80'
      } else {
        scope.width = '220'
        scope.height = '147'
      }
    }
  }
})
