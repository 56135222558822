/* global angular */

angular.module('smartvid').directive('projectIntegrationsThumbnail', function ($timeout, $rootScope, $stateParams, $state) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'integrations/project-integrations-thumbnail.html',
    scope: {
      provider: '=',
      providers: '='
    },
    link (scope) {
      scope.selectProvider = (evt) => {
        $state.go('dashboard.projects.projectId.integrations.' + scope.provider.name.toLowerCase())
      }
    }
  }
})
