/* global angular, _ */
/**
 * Created by ryanrubbico on 10/10/17.
 */
angular.module('smartvid').service('projectFavoriteService', function (smartvidApi, ProjectModel, currentUser, $timeout) {
  class ProjectFavoriteService {

    constructor () {
      this.listeners = []
    }

    favorite (project, projectCollection, event) {
      smartvidApi.favoriteProject(project.id).then(_.identity, function onError () {
        this._updateDataStructure(false, project, projectCollection)
        this._callListeners(event, project, projectCollection)
      })
      this._updateDataStructure(true, project, projectCollection)
      this._callListeners(event, project, projectCollection)
    }

    unFavorite (project, projectCollection, event) {
      smartvidApi.unfavoriteProject(project.id).then(_.identity, function onError () {
        this._updateDataStructure(true, project, projectCollection)
        this._callListeners(event, project, projectCollection)
      })
      this._updateDataStructure(false, project, projectCollection)
      this._callListeners(event, project, projectCollection)
    }

    onShowProperties (project, event) {
      this._callListeners(event, project, undefined)
    }

    addListener (listener) {
      this.listeners.push(listener)
      return () => {
        this.listeners.splice(this.listeners.indexOf(listener), 1)
      }
    }

    _callListeners (event, project, projectCollection) {
      for (let listener of this.listeners) {
        listener(event, project, projectCollection)
      }
    }

    _updateDataStructure (isFavorite, project, projectCollection) {
      project.favorite = isFavorite

      if (isFavorite) {
        currentUser.userPreferences.favoriteProjectIds.push(project.id)
      } else {
        currentUser.userPreferences.favoriteProjectIds = _.filter(currentUser.userPreferences.favoriteProjectIds, (id) => id !== project.id)
      }
      // to trigger correct enter and leave animations
      this._removeAndReinsertCurrentProject(project, projectCollection)
    }

    _removeAndReinsertCurrentProject (project, projectCollection) {
      projectCollection.removeById(project.id)
      $timeout(() => {
        projectCollection.upsert([project], ProjectModel, true)
      }, 50)
    }
  }

  return new ProjectFavoriteService()
})
