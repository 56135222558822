/* global angular,_ */

angular.module('smartvid').directive('searchStatusBar', ($rootScope, $state, $filter, $q, $timeout, TAG_CONFIDENCE_LEVEL_HIGH,
                                                         TAG_CONFIDENCE_LEVEL_MEDIUM, TAG_CONFIDENCE_LEVEL_LOW, searchResultService,
                                                         contentSharingContext, dashboardDataHelper, stateIdentificationService) => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'AssetGrid/search-status-bar.html',
    scope: {
      public: '=?'
    },
    link: (scope) => {
      let tokenRegex = /[^\s"]+|"([^"]*)"/gi
      scope.searching = true
      scope.bannerText = ''
      scope.modal = $rootScope.modal
      scope.assets = dashboardDataHelper.getTargetAssets($state.params)
      scope.currentProject = dashboardDataHelper.getCurrentProject()
      scope.searchContext = scope.assets.searchContext
      scope.displayDateRange = displaySearchDateRangeText()
      scope.isSearchSharingEnabled = isSearchSharingEnabled()
      scope.searchSharing = searchResultService.isSearchSharing()
      scope.assetGroups = undefined
      scope.assetGroupCounts = {}
      scope.displayAssetGroups = false
      if (_.isObject(scope.public)) {
        scope.public.isShowingGroups = scope.displayAssetGroups
        scope.public.isShowingConfidence = !scope.searchSharing
      }
      scope.searchParts = getSearchParts()
      scope.isAndQuery = scope.searchContext.searchQueryType === 'ALL_TERMS'
      scope.tagConfidenceLevel =
        (scope.searchContext) ? scope.searchContext.tagConfidenceLevel : TAG_CONFIDENCE_LEVEL_HIGH
      scope.tagConfidenceLevels = [
        {
          displayName: 'Low',
          tagConfidenceLevel: TAG_CONFIDENCE_LEVEL_LOW
        }, {
          displayName: 'Medium',
          tagConfidenceLevel: TAG_CONFIDENCE_LEVEL_MEDIUM
        }, {
          displayName: 'High',
          tagConfidenceLevel: TAG_CONFIDENCE_LEVEL_HIGH
        }
      ]
      scope.totalCountHigh = 0
      scope.totalCountMedium = 0
      scope.totalCountLow = 0
      scope.searchTagConfidenceBarVisible = false
      scope.searchTagConfidenceBarDisabled = searchResultService.isInGlobalSearchContext() &&
        (scope.currentProject || stateIdentificationService.isProjectGroupState() || stateIdentificationService.isOrganizationState())

      scope.openShareSearchModal = openShareSearchModal
      scope.clearSearch = scope.$parent.clearSearch
      scope.applyAssetGroupFilter = applyAssetGroupFilter
      scope.isGroupSelected = isGroupSelected
      scope.switchTagConfidenceLevel = switchTagConfidenceLevel
      scope.totalCountFilter = totalCountFilter

      scope.assets.initPromise.then(() => {
        if (scope.currentProject) {
          scope.currentProject.assetCount = scope.assets.totalAssetCount
        }
        scope.totalCountHigh = scope.assets.searchCounts.totalCountTagConfidenceHigh
        scope.totalCountMedium = scope.assets.searchCounts.totalCountTagConfidenceMedium
        scope.totalCountLow = scope.assets.searchCounts.totalCountTagConfidenceLow
        let totalCount = scope.assets.totalAssetCount
        scope.bannerText = getBannerText(totalCount)
        scope.matchesMessage = getMessageText(totalCount)
        scope.assetGroups = angular.copy(scope.assets.assetGroups)
        scope.assetGroupCounts = angular.copy(scope.assets.assetGroupsCounts)
        if (scope.assetGroups.length !== 0) {
          scope.assetGroups.unshift('ALL')
          if (scope.assetGroups.indexOf('IMAGEREC') !== -1) {
            // Move IMAGEREC to the end
            scope.assetGroups.push(scope.assetGroups.splice(scope.assetGroups.indexOf('IMAGEREC'), 1)[0])
          }
          scope.assetGroupCounts['ALL'] = {
            totalCount: totalCount
          }
          if (scope.searchContext.assetGroup &&
            scope.assetGroupCounts[scope.searchContext.assetGroup].totalCount === 0) {
            scope.searchContext.assetGroup = undefined
          }
        }
        scope.displayAssetGroups = totalCount > 0
        scope.public.isShowingGroups = scope.displayAssetGroups && scope.assetGroups.length > 0
        scope.searching = false
        $timeout(() => {
          scope.searchTagConfidenceBarVisible = isSearchTagConfidenceBarVisible()
        })
      })

      function getSearchParts () {
        let result = []
        if (displayTagSearchBanner()) {
          result.push({
            banner: $filter('i18next')('search.resultsBannerForTags'),
            parts: _.map(scope.searchContext.searchTags, t => {
              return t.text
            })
          })
        }
        if (displayUserSearchBanner()) {
          result.push({
            banner: $filter('i18next')('search.resultsBannerForUsers'),
            parts: _.map(scope.searchContext.searchCreatedByUsers, t => {
              return t.email
            })
          })
        }
        if (displayTextSearchBanner()) {
          result.push({
            banner: $filter('i18next')('search.resultsBannerForText'),
            footer: $filter('i18next')('search.resultsFooterForText'),
            parts: _.flatten(_.map(scope.searchContext.textFragments, t => {
              return splitString(t)
            }))
          })
        }
        return result
      }

      function splitString (str) {
        let result = []
        let match = null
        do {
          match = tokenRegex.exec(str)
          if (match !== null) {
            result.push(match[1] ? match[1] : match[0])
          }
        } while (match !== null)
        return result
      }

      function isSearchTagConfidenceBarVisible () {
        if ((scope.searchContext.searchTags || scope.searchContext.searchTags.length === 0) &&
          (!scope.searchContext.searchCreatedByUsers || scope.searchContext.searchCreatedByUsers.length === 0)) {
          if (scope.searchContext.assetGroup === 'COMMENT' ||
            scope.searchContext.assetGroup === 'NAME' || scope.searchContext.assetGroup === 'DESCRIPTION') {
            return false
          }
        }
        return true
      }

      function switchTagConfidenceLevel (tagConfidenceLevel) {
        let assetGroup = (scope.searchContext.assetGroup === 'ALL') ? undefined : scope.searchContext.assetGroup
        goToProjectSearch(scope.searchContext, {
          assetGroup: assetGroup,
          tagConfidenceLevel: tagConfidenceLevel,
          skipRecentSearches: true
        }, false)
      }

      function totalCountFilter (groupName) {
        return scope.assetGroupCounts[groupName].totalCount > 0 || isGroupSelected(groupName)
      }

      function applyAssetGroupFilter (group) {
        let assetGroup = (group === 'ALL') ? undefined : group
        goToProjectSearch(scope.searchContext, {
          assetGroup: assetGroup,
          tagConfidenceLevel: scope.tagConfidenceLevel,
          skipRecentSearches: true
        }, false)
      }

      function goToProjectSearch (searchContext, filter) {
        if (scope.currentProject) {
          $state.go('dashboard.projects.projectId.files', {
            projectId: scope.currentProject.id,
            isDemoProject: scope.currentProject.isDemoProjectCopy,
            searchContext: searchContext,
            searchFilter: filter
          })
        } else {
          const params = $state.params
          const stateToGo = $state.current.name

          $state.go(stateToGo, {
            organizationId: params.organizationId,
            projectGroupId: params.projectGroupId,
            projectId: params.projectId,
            globalSearchContext: searchContext,
            globalSearchFilter: filter
          })
        }
      }

      function isSearchSharingEnabled () {
        if (contentSharingContext.isSet()) {
          return false
        }
        if (scope.currentProject && !scope.currentProject.canShareSearch) {
          return false
        }
        return true
      }

      function openShareSearchModal () {
        scope.modal.open('shareSearch', {
          assetSearchContext: scope.searchContext,
          currentProject: scope.currentProject
        })
      }

      function getMessageText (count) {
        if (count === 0) {
          return $filter('i18next')('search.resultNoMatches')
        } else if (count === 1) {
          return count + ' ' + $filter('i18next')('search.resultSingleMatch')
        } else {
          return count + ' ' + $filter('i18next')('search.resultMatches')
        }
      }

      function getBannerText (count) {
        let context = scope.searchContext
        let noFreeText = context.textFragments === undefined || (context.textFragments && context.textFragments.length === 0)
        let noTags = context.searchTags === undefined || (context.searchTags && context.searchTags.length === 0)
        let noUsers = context.searchCreatedByUsers === undefined || (context.searchCreatedByUsers && context.searchCreatedByUsers.length === 0)

        let searchByDatesOnly = noFreeText && noTags && noUsers
        let dateStr = searchByDatesOnly && scope.displayDateRange ? ' files dated ' : ''
        let forStr = scope.displayDateRange || !searchByDatesOnly ? $filter('i18next')('search.resultsBannerFor') : ''

        if (count === 0) {
          return $filter('i18next')('search.resultsBannerNoMatches') + ' ' + forStr + ' ' + dateStr
        } else if (count === 1) {
          return $filter('i18next')('search.resultsBannerSingleMatch') + ' ' + forStr + ' ' + dateStr
        } else {
          return $filter('i18next')('search.resultsBanner') + ' ' + forStr + ' ' + dateStr
        }
      }

      function displayTagSearchBanner () {
        return scope.searchContext.searchTags &&
          scope.searchContext.searchTags.length !== 0
      }

      function displayUserSearchBanner () {
        return scope.searchContext.searchCreatedByUsers &&
          scope.searchContext.searchCreatedByUsers.length !== 0
      }

      function displayTextSearchBanner () {
        return scope.searchContext.textFragments &&
          scope.searchContext.textFragments.length !== 0
      }

      function displaySearchDateRangeText () {
        return scope.searchContext.searchDateRange
      }

      function isGroupSelected (group) {
        if (group === 'ALL') {
          group = undefined
        }
        return scope.searchContext && (group === scope.searchContext.assetGroup ||
          (_.isEmpty(scope.searchContext.assetGroup) && _.isEmpty(group)))
      }
    }
  }
})
