/* global angular */

angular.module('smartvid').service('emailUtils', function () {
  let EmailUtils = {

    isFreeEmailService (emailOrDomain) {
      let emailParts = emailOrDomain.split('@')
      let domain = emailParts.length === 2 ? emailParts[1] : emailParts[0]

      return this.freeEmailServices.indexOf(domain.trim()) >= 0
    },

    isDisposableEmailService (emailOrDomain) {
      let emailParts = emailOrDomain.split('@')
      let domain = emailParts.length === 2 ? emailParts[1] : emailParts[0]

      return this.disposableEmailServices.indexOf(domain.trim()) >= 0
    },

    isValidEmail (email) {
      let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!email) {
        return false
      } else {
        return emailRegex.test(email.trim())
      }
    },

    isValidDomain (domain) {
      /*eslint-disable */
      let domainRegex = /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
      return domainRegex.test(domain.trim())
      /*eslint-enable */
    },

    freeEmailServices: [
      '0-mail.com',
      '0sg.net',
      '11mail.com',
      '123.com',
      '123india.com',
      '123mail.cl',
      '123mail.org',
      '126.com',
      '139.com',
      '150mail.com',
      '150ml.com',
      '15meg4free.com',
      '163.com',
      '16mail.com',
      '188.com',
      '189.cn',
      '1coolplace.com',
      '1funplace.com',
      '1internetdrive.com',
      '1mail.net',
      '1me.net',
      '1mum.com',
      '1musicrow.com',
      '1netdrive.com',
      '1webave.com',
      '1webhighway.com',
      '2-mail.com',
      '212.com',
      '24horas.com',
      '2980.com',
      '2bmail.co.uk',
      '2die4.com',
      '2trom.com',
      '30minutesmail.com',
      '3126.com',
      '321media.com',
      '37.com',
      '3ammagazine.com',
      '444.net',
      '4email.net',
      '4mg.com',
      '4warding.net',
      '4x4man.com',
      '50mail.com',
      '5iron.com',
      '60minutemail.com',
      '88.am',
      '8848.net',
      '97rock.com',
      'aaamail.zzn.com',
      'aamail.net',
      'aapt.net.au',
      'aaronkwok.net',
      'abbeyroadlondon.co.uk',
      'abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyzabcdefghijk.com',
      'abcflash.net',
      'abdulnour.com',
      'aberystwyth.com',
      'about.com',
      'abwesend.de',
      'academycougars.com',
      'acceso.or.cr',
      'access4less.net',
      'accessgcc.com',
      'accountant.com',
      'acdcfan.com',
      'acmemail.net',
      'acninc.net',
      'activist.com',
      'adam.com.au',
      'addcom.de',
      'address.com',
      'adelphia.net',
      'adexec.com',
      'adfarrow.com',
      'adoption.com',
      'ados.fr',
      'advalvas.be',
      'aeiou.pt',
      'aemail4u.com',
      'africamail.com',
      'agoodmail.com',
      'ahaa.dk',
      'aichi.com',
      'aim.com',
      'aircraftmail.com',
      'airforce.net',
      'airforceemail.com',
      'airpost.net',
      'ajacied.com',
      'ak47.hu',
      'aknet.kg',
      'albawaba.com',
      'algeria.com',
      'alibaba.com',
      'alive.cz',
      'aliyun.com',
      'allergist.com',
      'allmail.net',
      'allracing.com',
      'allsaintsfan.com',
      'alpenjodel.de',
      'alphafrau.de',
      'alskens.dk',
      'altavista.com',
      'altavista.net',
      'altavista.se',
      'alternativagratis.com',
      'alumni.com',
      'alumnidirector.com',
      'alvilag.hu',
      'amele.com',
      'america.hm',
      'ameritech.net',
      'amnetsal.com',
      'amorki.pl',
      'amrer.net',
      'amuro.net',
      'amuromail.com',
      'ananzi.co.za',
      'ancestry.com',
      'andylau.net',
      'angelfire.com',
      'angelic.com',
      'animail.net',
      'animalhouse.com',
      'anjungcafe.com',
      'annsmail.com',
      'anonymous.to',
      'anote.com',
      'another.com',
      'anotherwin95.com',
      'antisocial.com',
      'antispam24.de',
      'anymoment.com',
      'anytimenow.com',
      'aol.com',
      'apexmail.com',
      'apollo.lv',
      'appraiser.net',
      'arabia.com',
      'arabtop.net',
      'archaeologist.com',
      'arcor.de',
      'arcticmail.com',
      'argentina.com',
      'aristotle.org',
      'army.net',
      'arnet.com.ar',
      'artlover.com',
      'asean-mail.com',
      'asheville.com',
      'asia-links.com',
      'asia-mail.com',
      'asia.com',
      'asiafind.com',
      'asianavenue.com',
      'asiancityweb.com',
      'asiansonly.net',
      'asianwired.net',
      'asiapoint.net',
      'assala.com',
      'assamesemail.com',
      'astrolover.com',
      'astrosfan.com',
      'astrosfan.net',
      'asurfer.com',
      'atheist.com',
      'athenachu.net',
      'atina.cl',
      'atl.lv',
      'att.net',
      'attglobal.net',
      'attymail.com',
      'au.ru',
      'auctioneer.net',
      'ausi.com',
      'aussiemail.com.au',
      'austin.rr.com',
      'australia.edu',
      'australiamail.com',
      'autoescuelanerja.com',
      'avh.hu',
      'awsom.net',
      'axoskate.com',
      'bachelorboy.com',
      'bachelorgal.com',
      'backpackers.com',
      'backstreet-boys.com',
      'bagherpour.com',
      'baldmama.de',
      'baldpapa.de',
      'ballyfinance.com',
      'bangkok.com',
      'bangkok2000.com',
      'bannertown.net',
      'baptistmail.com',
      'baptized.com',
      'barcelona.com',
      'bartender.net',
      'baseballmail.com',
      'basketballmail.com',
      'batuta.net',
      'bboy.zzn.com',
      'beeebank.com',
      'beenhad.com',
      'beep.ru',
      'beer.com',
      'beethoven.com',
      'belice.com',
      'bellair.net',
      'bellsouth.net',
      'berlin.com',
      'berlin.de',
      'bestmail.us',
      'betriebsdirektor.de',
      'bettergolf.net',
      'bharatmail.com',
      'bigassweb.com',
      'bigblue.net.au',
      'bigfoot.com',
      'bigfoot.de',
      'bigger.com',
      'biggerbadder.com',
      'bigmailbox.com',
      'bigmir.net',
      'bigpond.com',
      'bigpond.com.au',
      'bigpond.net.au',
      'bigramp.com',
      'bikemechanics.com',
      'bikeracer.com',
      'bikerider.com',
      'billsfan.com',
      'billsfan.net',
      'bin-wieder-da.de',
      'bio-muesli.info',
      'birdlover.com',
      'bisons.com',
      'bitmail.com',
      'bitpage.net',
      'bizhosting.com',
      'blackplanet.com',
      'blader.com',
      'blazemail.com',
      'bleib-bei-mir.de',
      'blockfilter.com',
      'bluebottle.com',
      'bluehyppo.com',
      'bluemail.ch',
      'bluemail.dk',
      'bluesfan.com',
      'blushmail.com',
      'boardermail.com',
      'bodhi.lawlita.com',
      'bol.com.br',
      'bolando.com',
      'bolt.com',
      'boltonfans.com',
      'bombdiggity.com',
      'bornnaked.com',
      'bostonoffice.com',
      'bounce.net',
      'box.az',
      'boxbg.com',
      'boxemail.com',
      'boxfrog.com',
      'bradfordfans.com',
      'brasilia.net',
      'brazilmail.com',
      'breathe.com',
      'bresnan.net',
      'brew-master.com',
      'brew-meister.com',
      'briefemail.com',
      'bright.net',
      'broadcast.net',
      'brokenvalve.com',
      'bsdmail.com',
      'buerotiger.de',
      'buffymail.com',
      'bullsfan.com',
      'busta-rhymes.com',
      'buyersusa.com',
      'bvimailbox.com',
      'c3.hu',
      'c4.com',
      'cabacabana.com',
      'cableone.net',
      'caere.it',
      'californiamail.com',
      'callnetuk.com',
      'callsign.net',
      'caltanet.it',
      'camidge.com',
      'canada-11.com',
      'canada.com',
      'canadianmail.com',
      'canoemail.com',
      'caramail.com',
      'care2.com',
      'careerbuildermail.com',
      'carioca.net',
      'cartestraina.ro',
      'casablancaresort.com',
      'cash4u.com',
      'cashette.com',
      'casino.com',
      'catchamail.com',
      'catholic.org',
      'catlover.com',
      'cd2.com',
      'celineclub.com',
      'celtic.com',
      'center-mail.de',
      'centermail.at',
      'centermail.de',
      'centermail.info',
      'centoper.it',
      'centralpets.com',
      'centrum.cz',
      'centrum.sk',
      'centurytel.net',
      'certifiedmail.com',
      'cfl.rr.com',
      'cgac.es',
      'cghost.s-a-d.de',
      'chaiyomail.com',
      'chandrasekar.net',
      'charmedmail.com',
      'charter.com',
      'charter.net',
      'chat.ru',
      'check.com',
      'check1check.com',
      'cheerful.com',
      'chef.net',
      'chek.com',
      'chemist.com',
      'cheyenneweb.com',
      'chez.com',
      'chickmail.com',
      'china.com',
      'chinamail.com',
      'chirk.com',
      'chocaholic.com.au',
      'churchusa.com',
      'cia-agent.com',
      'cia.hu',
      'cicciociccio.com',
      'cincinow.net',
      'citiz.net',
      'citlink.net',
      'city-of-bath.org',
      'city-of-birmingham.com',
      'city-of-brighton.org',
      'city-of-cambridge.com',
      'city-of-coventry.com',
      'city-of-edinburgh.com',
      'city-of-lichfield.com',
      'city-of-lincoln.com',
      'city-of-liverpool.com',
      'city-of-manchester.com',
      'city-of-nottingham.com',
      'city-of-oxford.com',
      'city-of-swansea.com',
      'city-of-westminster.com',
      'city-of-westminster.net',
      'city-of-york.net',
      'cityofcardiff.net',
      'cityoflondon.org',
      'claramail.com',
      'classicmail.co.za',
      'clerk.com',
      'cliffhanger.com',
      'club4x4.net',
      'clubalfa.com',
      'clubbers.net',
      'clubducati.com',
      'clubhonda.net',
      'clubmember.org',
      'clubnetnoir.com',
      'clubvdo.net',
      'cluemail.com',
      'cmpmail.com',
      'cnnsimail.com',
      'cntv.cn',
      'codec.ro',
      'coder.hu',
      'coid.biz',
      'coldmail.com',
      'collectiblesuperstore.com',
      'collector.org',
      'collegeclub.com',
      'collegemail.com',
      'colleges.com',
      'columbus.rr.com',
      'columbusrr.com',
      'columnist.com',
      'comcast.com',
      'comcast.net',
      'comic.com',
      'communityconnect.com',
      'comprendemail.com',
      'compuserve.com',
      'computer-freak.com',
      'computer4u.com',
      'computermail.net',
      'conexcol.com',
      'conk.com',
      'connect4free.net',
      'consultant.com',
      'contractor.net',
      'coole-files.de',
      'coolgoose.ca',
      'coolgoose.com',
      'coolkiwi.com',
      'coollist.com',
      'coolmail.net',
      'coolsite.net',
      'cooooool.com',
      'cooperation.net',
      'cooperationtogo.net',
      'copacabana.com',
      'cornells.com',
      'cotas.net',
      'counsellor.com',
      'cox.com',
      'cox.net',
      'coxinet.net',
      'cracker.hu',
      'crazedanddazed.com',
      'cristianemail.com',
      'critterpost.com',
      'croeso.com',
      'crosshairs.com',
      'crosswinds.net',
      'crwmail.com',
      'cs.com',
      'csinibaba.hu',
      'cuemail.com',
      'curio-city.com',
      'curryworld.de',
      'cute-girl.com',
      'cutey.com',
      'cyber-africa.net',
      'cyber-matrix.com',
      'cyber-wizard.com',
      'cyber4all.com',
      'cyberbabies.com',
      'cybercafemaui.com',
      'cyberdude.com',
      'cybergal.com',
      'cybergrrl.com',
      'cybermail.net',
      'cybernet.it',
      'cyberservices.com',
      'cyberspace-asia.com',
      'cybertrains.org',
      'cyclefanz.com',
      'cynetcity.com',
      'dabsol.net',
      'dadacasa.com',
      'daha.com',
      'dailypioneer.com',
      'dallasmail.com',
      'dangerous-minds.com',
      'dawnsonmail.com',
      'dbzmail.com',
      'deadlymob.org',
      'deagot.com',
      'deal-maker.com',
      'dearriba.com',
      'death-star.com',
      'deliveryman.com',
      'deneg.net',
      'depechemode.com',
      'deseretmail.com',
      'desilota.com',
      'deskpilot.com',
      'destin.com',
      'detik.com',
      'deutschland-net.com',
      'dfwatson.com',
      'di-ve.com',
      'die-besten-bilder.de',
      'die-genossen.de',
      'die-optimisten.de',
      'die-optimisten.net',
      'diemailbox.de',
      'digibel.be',
      'digital-filestore.de',
      'diplomats.com',
      'directbox.com',
      'dirtracer.com',
      'disciples.com',
      'discofan.com',
      'discovery.com',
      'discoverymail.com',
      'disposable.com',
      'dm.w3internet.co.uk',
      'dnsmadeeasy.com',
      'docmail.cz',
      'doctor.com',
      'dodo.com.au',
      'dog.com',
      'dogit.com',
      'doglover.com',
      'dogmail.co.uk',
      'dogsnob.net',
      'doityourself.com',
      'doneasy.com',
      'dontgotmail.com',
      'dontmesswithtexas.com',
      'doramail.com',
      'dostmail.com',
      'dotcom.fr',
      'dott.it',
      'download-privat.de',
      'dplanet.ch',
      'dr.com',
      'dropzone.com',
      'drotposta.hu',
      'dublin.com',
      'dublin.ie',
      'dumpmail.com',
      'dumpmail.de',
      'dunlopdriver.com',
      'dunloprider.com',
      'duno.com',
      'dutchmail.com',
      'dwp.net',
      'dyndns.org',
      'e-apollo.lv',
      'e-mail.dk',
      'e-mail.ru',
      'e-mailanywhere.com',
      'e-tapaal.com',
      'earthalliance.com',
      'earthcam.net',
      'earthdome.com',
      'earthling.net',
      'earthlink.net',
      'earthonline.net',
      'eastcoast.co.za',
      'eastmail.com',
      'easy.to',
      'easypost.com',
      'ecardmail.com',
      'echina.com',
      'ecompare.com',
      'edmail.com',
      'educacao.te.pt',
      'ehmail.com',
      'eintagsmail.de',
      'eircom.net',
      'elitemail.org',
      'elvis.com',
      'elvisfan.com',
      'email-london.co.uk',
      'email.biz',
      'email.com',
      'email.cz',
      'email.ee',
      'email.it',
      'email.nu',
      'email.org',
      'email.ro',
      'email.ru',
      'email2me.net',
      'email4u.info',
      'emailaccount.com',
      'emailchoice.com',
      'emailcorner.net',
      'emailem.com',
      'emailengine.net',
      'emailengine.org',
      'emailgroups.net',
      'emailinfive.com',
      'emailit.com',
      'emailpinoy.com',
      'emailplanet.com',
      'emailplus.org',
      'emailto.de',
      'emailuser.net',
      'emailx.net',
      'embarqmail.com',
      'eml.cc',
      'end-war.com',
      'enel.net',
      'engineer.com',
      'england.com',
      'england.edu',
      'englandmail.com',
      'epix.net',
      'eposta.hu',
      'eresmas.com',
      'estranet.it',
      'etrademail.com',
      'eudoramail.com',
      'europe.com',
      'europemail.com',
      'euroseek.com',
      'eurosport.com',
      'every1.net',
      'everyday.com.kh',
      'everymail.net',
      'everyone.net',
      'examnotes.net',
      'excite.co.jp',
      'excite.com',
      'excite.it',
      'execs.com',
      'exemail.com.au',
      'expressasia.com',
      'extenda.net',
      'eyou.com',
      'ezcybersearch.com',
      'ezrs.com',
      'f-m.fm',
      'f1fans.net',
      'facebook.com',
      'fahr-zur-hoelle.org',
      'falseaddress.com',
      'fan.com',
      'fansonlymail.com',
      'fantasticmail.com',
      'farang.net',
      'farifluset.mailexpire.com',
      'faroweb.com',
      'fast-email.com',
      'fast-mail.org',
      'fastacura.com',
      'fastchevy.com',
      'fastchrysler.com',
      'fastem.com',
      'fastemail.us',
      'fastemailer.com',
      'fastermail.com',
      'fastest.cc',
      'fastimap.com',
      'fastkawasaki.com',
      'fastmail.ca',
      'fastmail.cn',
      'fastmail.co.uk',
      'fastmail.com.au',
      'fastmail.es',
      'fastmail.fm',
      'fastmail.im',
      'fastmail.in',
      'fastmail.jp',
      'fastmail.mx',
      'fastmail.net',
      'fastmail.nl',
      'fastmail.se',
      'fastmail.to',
      'fastmail.tw',
      'fastmail.us',
      'fastmailbox.net',
      'fastmazda.com',
      'fastmessaging.com',
      'fastmitsubishi.com',
      'fastnissan.com',
      'fastservice.com',
      'fastsubaru.com',
      'fastsuzuki.com',
      'fasttoyota.com',
      'fastyamaha.com',
      'fatcock.net',
      'fathersrightsne.org',
      'fbi-agent.com',
      'fbi.hu',
      'fea.st',
      'federalcontractors.com',
      'feinripptraeger.de',
      'felicitymail.com',
      'femenino.com',
      'fetchmail.co.uk',
      'fettabernett.de',
      'feyenoorder.com',
      'ffanet.com',
      'fiberia.com',
      'filipinolinks.com',
      'financemail.net',
      'financier.com',
      'findmail.com',
      'fire-brigade.com',
      'fireman.net',
      'fishburne.org',
      'fishfuse.com',
      'flashmail.com',
      'flashmail.net',
      'flipcode.com',
      'fmail.co.uk',
      'fmailbox.com',
      'fmgirl.com',
      'fmguy.com',
      'fnbmail.co.za',
      'fnmail.com',
      'foodmail.com',
      'footballmail.com',
      'for-president.com',
      'forpresident.com',
      'fortuncity.com',
      'fortunecity.com',
      'forum.dk',
      'foxmail.com',
      'fr33mail.info',
      'free-org.com',
      'free.com.pe',
      'free.fr',
      'freeaccess.nl',
      'freeaccount.com',
      'freeandsingle.com',
      'freedom.usa.com',
      'freedomlover.com',
      'freegates.be',
      'freeler.nl',
      'freemail.c3.hu',
      'freemail.com.pk',
      'freemail.de',
      'freemail.et',
      'freemail.gr',
      'freemail.hu',
      'freemail.it',
      'freemail.lt',
      'freemail.org.mk',
      'freenet.de',
      'freenet.kg',
      'freeola.com',
      'freeola.net',
      'freestart.hu',
      'freesurf.fr',
      'freesurf.nl',
      'freeuk.com',
      'freeuk.net',
      'freeukisp.co.uk',
      'freeweb.org',
      'freewebemail.com',
      'freeyellow.com',
      'freezone.co.uk',
      'fresnomail.com',
      'freudenkinder.de',
      'friends-cafe.com',
      'friendsfan.com',
      'from-africa.com',
      'from-asia.com',
      'from-australia.com',
      'from-canada.com',
      'from-china.net',
      'from-europe.com',
      'from-holland.com',
      'from-japan.net',
      'from-mexico.com',
      'from-russia.com',
      'fromalabama.com',
      'fromalaska.com',
      'fromarizona.com',
      'fromarkansas.com',
      'fromcalifornia.com',
      'fromconnecticut.com',
      'fromgeorgia.com',
      'fromidaho.com',
      'fromindiana.com',
      'fromiowa.com',
      'fromkansas.com',
      'fromlouisiana.com',
      'frommaryland.com',
      'frommassachusetts.com',
      'frommiami.com',
      'frommichigan.com',
      'fromminnesota.com',
      'frommississippi.com',
      'frommissouri.com',
      'fromnevada.com',
      'fromnewhampshire.com',
      'fromnewjersey.com',
      'fromnewyork.net',
      'fromnorthcarolina.com',
      'fromnorthdakota.com',
      'fromohio.com',
      'fromoklahoma.com',
      'fromoregon.net',
      'frompennsylvania.com',
      'fromrhodeisland.com',
      'fromru.com',
      'fromsouthcarolina.com',
      'fromtennessee.com',
      'fromtexas.com',
      'fromutah.com',
      'fromvermont.com',
      'fromvirginia.com',
      'fromwashington.com',
      'fromwashingtondc.com',
      'fromwestvirginia.com',
      'fromwisconsin.com',
      'fromwyoming.com',
      'front.ru',
      'frontier.com',
      'frontiernet.net',
      'frostbyte.uk.net',
      'fsmail.net',
      'ftml.net',
      'fullmail.com',
      'funkfan.com',
      'fuorissimo.com',
      'furnitureprovider.com',
      'fuse.net',
      'fut.es',
      'fwnb.com',
      'galaxyhit.com',
      'gamebox.net',
      'gamegeek.com',
      'gamespotmail.com',
      'garbage.com',
      'gardener.com',
      'gaybrighton.co.uk',
      'gaza.net',
      'gazeta.pl',
      'gazibooks.com',
      'gci.net',
      'geecities.com',
      'geek.com',
      'geeklife.com',
      'gentlemansclub.de',
      'geocities.com',
      'geography.net',
      'geologist.com',
      'geopia.com',
      'germanymail.com',
      'get1mail.com',
      'ghanamail.com',
      'ghostmail.com',
      'ghosttexter.de',
      'gigileung.org',
      'girl4god.com',
      'glay.org',
      'glendale.net',
      'globalfree.it',
      'globalpagan.com',
      'gmail.com',
      'gmx.at',
      'gmx.com',
      'gmx.de',
      'gmx.li',
      'gmx.net',
      'go.com',
      'go.ro',
      'go.ru',
      'go2net.com',
      'gocollege.com',
      'gofree.co.uk',
      'goldmail.ru',
      'goldtoolbox.com',
      'golfemail.com',
      'golfilla.info',
      'golfmail.be',
      'gonavy.net',
      'goodnewsmail.com',
      'goodstick.com',
      'googlemail.com',
      'goplay.com',
      'gothere.uk.com',
      'gotmail.com',
      'gotomy.com',
      'gportal.hu',
      'graduate.org',
      'graffiti.net',
      'graphic-designer.com',
      'grapplers.com',
      'greenmail.net',
      'groupmail.com',
      'gtmc.net',
      'gua.net',
      'guessmail.com',
      'guy.com',
      'guy2.com',
      'guyanafriends.com',
      'h-mail.us',
      'hab-verschlafen.de',
      'habmalnefrage.de',
      'hackermail.com',
      'hailmail.net',
      'hairdresser.net',
      'hamptonroads.com',
      'handbag.com',
      'handleit.com',
      'hanmail.net',
      'happemail.com',
      'happycounsel.com',
      'happypuppy.com',
      'hardcorefreak.com',
      'hawaii.rr.com',
      'hawaiiantel.net',
      'heerschap.com',
      'heesun.net',
      'hello.hu',
      'hello.net.au',
      'hello.to',
      'helter-skelter.com',
      'herediano.com',
      'herono1.com',
      'herr-der-mails.de',
      'hey.to',
      'hhdevel.com',
      'highquality.com',
      'highveldmail.co.za',
      'hilarious.com',
      'hiphopfan.com',
      'hispavista.com',
      'hitmail.com',
      'hitthe.net',
      'hkg.net',
      'hkstarphoto.com',
      'hockeymail.com',
      'hollywoodkids.com',
      'home-email.com',
      'home.de',
      'home.ro',
      'home.se',
      'homemail.com',
      'homestead.com',
      'honduras.com',
      'hongkong.com',
      'hoopsmail.com',
      'hot-shot.com',
      'hot.ee',
      'hotbrev.com',
      'hotletter.com',
      'hotmail.co.il',
      'hotmail.co.uk',
      'hotmail.com',
      'hotmail.fr',
      'hotmail.it',
      'hotmail.kg',
      'hotmail.kz',
      'hotmail.ru',
      'hotpop3.com',
      'hotvoice.com',
      'housemail.com',
      'hsuchi.net',
      'hughes.net',
      'humanoid.net',
      'hunsa.com',
      'hush.com',
      'hushmail.com',
      'hypernautica.com',
      'i-connect.com',
      'i-mail.com.au',
      'i-p.com',
      'i.am',
      'icestorm.com',
      'ich-bin-verrueckt-nach-dir.de',
      'ich-will-net.de',
      'icloud.com',
      'icmsconsultants.com',
      'icq.com',
      'icqmail.com',
      'icrazy.com',
      'idigjesus.com',
      'idirect.com',
      'iespana.es',
      'ifoward.com',
      'ig.com.br',
      'ignazio.it',
      'ihateclowns.com',
      'iinet.net.au',
      'ijustdontcare.com',
      'ilovechocolate.com',
      'ilovejesus.com',
      'ilse.nl',
      'imaginemail.com',
      'imail.org',
      'imail.ru',
      'imap-mail.com',
      'imap.cc',
      'imapmail.org',
      'imel.org',
      'imneverwrong.com',
      'imposter.co.uk',
      'imstations.com',
      'imstressed.com',
      'in-box.net',
      'in2jesus.com',
      'iname.com',
      'inbox.com',
      'inbox.ru',
      'incamail.com',
      'incredimail.com',
      'indexa.fr',
      'india.com',
      'indiatimes.com',
      'indo-mail.com',
      'indocities.com',
      'indomail.com',
      'indyracers.com',
      'inerted.com',
      'info-media.de',
      'info66.com',
      'infohq.com',
      'infomail.es',
      'infomart.or.jp',
      'infospacemail.com',
      'infovia.com.ar',
      'inicia.es',
      'inmail.sk',
      'inmail24.com',
      'innocent.com',
      'inorbit.com',
      'inoutbox.com',
      'insidebaltimore.net',
      'insight.rr.com',
      'instruction.com',
      'instructor.net',
      'insurer.com',
      'interburp.com',
      'interfree.it',
      'interia.pl',
      'interlap.com.ar',
      'intermail.co.il',
      'internet-e-mail.com',
      'internet-mail.org',
      'internet-police.com',
      'internetbiz.com',
      'internetegypt.com',
      'internetemails.net',
      'internetmailing.net',
      'internode.on.net',
      'inwind.it',
      'iobox.com',
      'iobox.fi',
      'iol.it',
      'iowaemail.com',
      'ip3.com',
      'iprimus.com.au',
      'iqemail.com',
      'irangate.net',
      'ireland.com',
      'irelandmail.com',
      'irj.hu',
      'isellcars.com',
      'iservejesus.com',
      'islamonline.net',
      'isleuthmail.com',
      'ismart.net',
      'isp9.net',
      'israelmail.com',
      'ist-allein.info',
      'ist-einmalig.de',
      'ist-ganz-allein.de',
      'ist-willig.de',
      'italymail.com',
      'itmom.com',
      'ivebeenframed.com',
      'ivillage.com',
      'iwmail.com',
      'iwon.com',
      'izadpanah.com',
      'jahoopa.com',
      'jakuza.hu',
      'jazzandjava.com',
      'jazzfan.com',
      'jazzgame.com',
      'jerusalemmail.com',
      'jetable.de',
      'jetemail.net',
      'jippii.fi',
      'jmail.co.za',
      'job4u.com',
      'jokes.com',
      'journalist.com',
      'jovem.te.pt',
      'jpopmail.com',
      'jubiimail.dk',
      'jump.com',
      'junkmail.com',
      'juno.com',
      'justemail.net',
      'justicemail.com',
      'kaazoo.com',
      'kaffeeschluerfer.com',
      'kaffeeschluerfer.de',
      'kaixo.com',
      'kalpoint.com',
      'kansascity.com',
      'karbasi.com',
      'katamail.com',
      'kayafmmail.co.za',
      'kbjrmail.com',
      'kcks.com',
      'keg-party.com',
      'kellychen.com',
      'keromail.com',
      'keyemail.com',
      'kgb.hu',
      'kickassmail.com',
      'killermail.com',
      'kimo.com',
      'kinglibrary.net',
      'kinki-kids.com',
      'kissfans.com',
      'kittymail.com',
      'kitznet.at',
      'kiwitown.com',
      'kommespaeter.de',
      'konx.com',
      'korea.com',
      'koreamail.com',
      'krim.ws',
      'krongthip.com',
      'krunis.com',
      'ksanmail.com',
      'kukamail.com',
      'kulturbetrieb.info',
      'kumarweb.com',
      'la.com',
      'ladymail.cz',
      'lagerlouts.com',
      'lakmail.com',
      'lamer.hu',
      'land.ru',
      'lankamail.com',
      'laposte.net',
      'lass-es-geschehen.de',
      'latemodels.com',
      'latinmail.com',
      'law.com',
      'lawyer.com',
      'leehom.net',
      'legislator.com',
      'leonlai.net',
      'letsgomets.net',
      'letterboxes.org',
      'levele.hu',
      'lex.bg',
      'lexis-nexis-mail.com',
      'liberomail.com',
      'lick101.com',
      'liebt-dich.info',
      'linkmaster.com',
      'linktrader.com',
      'linuxfreemail.com',
      'linuxmail.org',
      'liontrucks.com',
      'liquidinformation.net',
      'list.ru',
      'listomail.com',
      'littleblueroom.com',
      'live.co.uk',
      'live.com',
      'live.com.au',
      'liverpoolfans.com',
      'llandudno.com',
      'llangollen.com',
      'lobbyist.com',
      'localbar.com',
      'locos.com',
      'looksmart.co.uk',
      'looksmart.com',
      'lopezclub.com',
      'louiskoo.com',
      'loveable.com',
      'lovecat.com',
      'lovefootball.com',
      'lovelygirl.net',
      'lovemail.com',
      'lover-boy.com',
      'lovethebroncos.com',
      'loveyouforever.de',
      'lovingjesus.com',
      'lowandslow.com',
      'luukku.com',
      'lycos.co.uk',
      'lycos.com',
      'lycos.es',
      'lycos.ne.jp',
      'm-hmail.com',
      'm4.org',
      'mac.com',
      'macbox.com',
      'macfreak.com',
      'madcreations.com',
      'madonnafan.com',
      'maennerversteherin.com',
      'maennerversteherin.de',
      'maffia.hu',
      'magicmail.co.za',
      'mail-awu.de',
      'mail-box.cz',
      'mail-center.com',
      'mail-central.com',
      'mail-me.com',
      'mail-page.com',
      'mail.austria.com',
      'mail.az',
      'mail.be',
      'mail.bulgaria.com',
      'mail.co.za',
      'mail.com',
      'mail.ee',
      'mail.entrepeneurmag.com',
      'mail.freetown.com',
      'mail.gr',
      'mail.hitthebeach.com',
      'mail.htl22.at',
      'mail.md',
      'mail.misterpinball.de',
      'mail.nu',
      'mail.org.uk',
      'mail.pf',
      'mail.pt',
      'mail.ru',
      'mail.sisna.com',
      'mail.svenz.eu',
      'mail.usa.com',
      'mail15.com',
      'mail2007.com',
      'mail2aaron.com',
      'mail2abby.com',
      'mail2abc.com',
      'mail2actor.com',
      'mail2admiral.com',
      'mail2adorable.com',
      'mail2adoration.com',
      'mail2adore.com',
      'mail2adventure.com',
      'mail2aeolus.com',
      'mail2aether.com',
      'mail2affection.com',
      'mail2afghanistan.com',
      'mail2africa.com',
      'mail2agent.com',
      'mail2aha.com',
      'mail2ahoy.com',
      'mail2aim.com',
      'mail2air.com',
      'mail2airbag.com',
      'mail2airforce.com',
      'mail2airport.com',
      'mail2alabama.com',
      'mail2alan.com',
      'mail2alaska.com',
      'mail2albania.com',
      'mail2alcoholic.com',
      'mail2alec.com',
      'mail2alexa.com',
      'mail2algeria.com',
      'mail2alicia.com',
      'mail2alien.com',
      'mail2allan.com',
      'mail2allen.com',
      'mail2allison.com',
      'mail2alpha.com',
      'mail2alyssa.com',
      'mail2amanda.com',
      'mail2amazing.com',
      'mail2amber.com',
      'mail2america.com',
      'mail2american.com',
      'mail2andorra.com',
      'mail2andrea.com',
      'mail2andy.com',
      'mail2anesthesiologist.com',
      'mail2angela.com',
      'mail2angola.com',
      'mail2ann.com',
      'mail2anna.com',
      'mail2anne.com',
      'mail2anthony.com',
      'mail2aphrodite.com',
      'mail2apollo.com',
      'mail2april.com',
      'mail2aquarius.com',
      'mail2arabia.com',
      'mail2arabic.com',
      'mail2architect.com',
      'mail2ares.com',
      'mail2argentina.com',
      'mail2aries.com',
      'mail2arizona.com',
      'mail2arkansas.com',
      'mail2armenia.com',
      'mail2army.com',
      'mail2arnold.com',
      'mail2art.com',
      'mail2arthur.com',
      'mail2artist.com',
      'mail2ashley.com',
      'mail2ask.com',
      'mail2astronomer.com',
      'mail2athena.com',
      'mail2athlete.com',
      'mail2atlas.com',
      'mail2atom.com',
      'mail2attitude.com',
      'mail2auction.com',
      'mail2aunt.com',
      'mail2australia.com',
      'mail2austria.com',
      'mail2azerbaijan.com',
      'mail2baby.com',
      'mail2bahamas.com',
      'mail2bahrain.com',
      'mail2ballerina.com',
      'mail2ballplayer.com',
      'mail2band.com',
      'mail2bangladesh.com',
      'mail2bank.com',
      'mail2banker.com',
      'mail2bankrupt.com',
      'mail2baptist.com',
      'mail2bar.com',
      'mail2barbados.com',
      'mail2barbara.com',
      'mail2barter.com',
      'mail2basketball.com',
      'mail2batter.com',
      'mail2beach.com',
      'mail2beast.com',
      'mail2beatles.com',
      'mail2beauty.com',
      'mail2becky.com',
      'mail2beijing.com',
      'mail2belgium.com',
      'mail2belize.com',
      'mail2ben.com',
      'mail2bernard.com',
      'mail2beth.com',
      'mail2betty.com',
      'mail2beverly.com',
      'mail2beyond.com',
      'mail2biker.com',
      'mail2bill.com',
      'mail2billionaire.com',
      'mail2billy.com',
      'mail2bio.com',
      'mail2biologist.com',
      'mail2black.com',
      'mail2blackbelt.com',
      'mail2blake.com',
      'mail2blind.com',
      'mail2blonde.com',
      'mail2blues.com',
      'mail2bob.com',
      'mail2bobby.com',
      'mail2bolivia.com',
      'mail2bombay.com',
      'mail2bonn.com',
      'mail2bookmark.com',
      'mail2boreas.com',
      'mail2bosnia.com',
      'mail2boston.com',
      'mail2botswana.com',
      'mail2bradley.com',
      'mail2brazil.com',
      'mail2breakfast.com',
      'mail2brian.com',
      'mail2bride.com',
      'mail2brittany.com',
      'mail2broker.com',
      'mail2brook.com',
      'mail2bruce.com',
      'mail2brunei.com',
      'mail2brunette.com',
      'mail2brussels.com',
      'mail2bryan.com',
      'mail2bug.com',
      'mail2bulgaria.com',
      'mail2business.com',
      'mail2buy.com',
      'mail2ca.com',
      'mail2california.com',
      'mail2calvin.com',
      'mail2cambodia.com',
      'mail2cameroon.com',
      'mail2canada.com',
      'mail2cancer.com',
      'mail2capeverde.com',
      'mail2capricorn.com',
      'mail2cardinal.com',
      'mail2cardiologist.com',
      'mail2care.com',
      'mail2caroline.com',
      'mail2carolyn.com',
      'mail2casey.com',
      'mail2cat.com',
      'mail2caterer.com',
      'mail2cathy.com',
      'mail2catlover.com',
      'mail2catwalk.com',
      'mail2cell.com',
      'mail2chad.com',
      'mail2champaign.com',
      'mail2charles.com',
      'mail2chef.com',
      'mail2chemist.com',
      'mail2cherry.com',
      'mail2chicago.com',
      'mail2chile.com',
      'mail2china.com',
      'mail2chinese.com',
      'mail2chocolate.com',
      'mail2christian.com',
      'mail2christie.com',
      'mail2christmas.com',
      'mail2christy.com',
      'mail2chuck.com',
      'mail2cindy.com',
      'mail2clark.com',
      'mail2classifieds.com',
      'mail2claude.com',
      'mail2cliff.com',
      'mail2clinic.com',
      'mail2clint.com',
      'mail2close.com',
      'mail2club.com',
      'mail2coach.com',
      'mail2coastguard.com',
      'mail2colin.com',
      'mail2college.com',
      'mail2color.com',
      'mail2colorado.com',
      'mail2columbia.com',
      'mail2comedian.com',
      'mail2composer.com',
      'mail2computer.com',
      'mail2computers.com',
      'mail2concert.com',
      'mail2congo.com',
      'mail2connect.com',
      'mail2connecticut.com',
      'mail2consultant.com',
      'mail2convict.com',
      'mail2cook.com',
      'mail2cool.com',
      'mail2cory.com',
      'mail2costarica.com',
      'mail2country.com',
      'mail2courtney.com',
      'mail2cowboy.com',
      'mail2cowgirl.com',
      'mail2craig.com',
      'mail2crave.com',
      'mail2crazy.com',
      'mail2create.com',
      'mail2croatia.com',
      'mail2cry.com',
      'mail2crystal.com',
      'mail2cuba.com',
      'mail2culture.com',
      'mail2curt.com',
      'mail2customs.com',
      'mail2cute.com',
      'mail2cutey.com',
      'mail2cynthia.com',
      'mail2cyprus.com',
      'mail2czechrepublic.com',
      'mail2dad.com',
      'mail2dale.com',
      'mail2dallas.com',
      'mail2dan.com',
      'mail2dana.com',
      'mail2dance.com',
      'mail2dancer.com',
      'mail2danielle.com',
      'mail2danny.com',
      'mail2darlene.com',
      'mail2darling.com',
      'mail2darren.com',
      'mail2daughter.com',
      'mail2dave.com',
      'mail2dawn.com',
      'mail2dc.com',
      'mail2dealer.com',
      'mail2deanna.com',
      'mail2dearest.com',
      'mail2debbie.com',
      'mail2debby.com',
      'mail2deer.com',
      'mail2delaware.com',
      'mail2delicious.com',
      'mail2demeter.com',
      'mail2democrat.com',
      'mail2denise.com',
      'mail2denmark.com',
      'mail2dennis.com',
      'mail2dentist.com',
      'mail2derek.com',
      'mail2desert.com',
      'mail2devoted.com',
      'mail2devotion.com',
      'mail2diamond.com',
      'mail2diana.com',
      'mail2diane.com',
      'mail2diehard.com',
      'mail2dilemma.com',
      'mail2dillon.com',
      'mail2dinner.com',
      'mail2dinosaur.com',
      'mail2dionysos.com',
      'mail2diplomat.com',
      'mail2director.com',
      'mail2dirk.com',
      'mail2disco.com',
      'mail2dive.com',
      'mail2diver.com',
      'mail2divorced.com',
      'mail2djibouti.com',
      'mail2doctor.com',
      'mail2doglover.com',
      'mail2dominic.com',
      'mail2dominica.com',
      'mail2dominicanrepublic.com',
      'mail2don.com',
      'mail2donald.com',
      'mail2donna.com',
      'mail2doris.com',
      'mail2dorothy.com',
      'mail2doug.com',
      'mail2dough.com',
      'mail2douglas.com',
      'mail2dow.com',
      'mail2downtown.com',
      'mail2dream.com',
      'mail2dreamer.com',
      'mail2dude.com',
      'mail2dustin.com',
      'mail2dyke.com',
      'mail2dylan.com',
      'mail2earl.com',
      'mail2earth.com',
      'mail2eastend.com',
      'mail2eat.com',
      'mail2economist.com',
      'mail2ecuador.com',
      'mail2eddie.com',
      'mail2edgar.com',
      'mail2edwin.com',
      'mail2egypt.com',
      'mail2electron.com',
      'mail2eli.com',
      'mail2elizabeth.com',
      'mail2ellen.com',
      'mail2elliot.com',
      'mail2elsalvador.com',
      'mail2elvis.com',
      'mail2emergency.com',
      'mail2emily.com',
      'mail2engineer.com',
      'mail2english.com',
      'mail2environmentalist.com',
      'mail2eos.com',
      'mail2eric.com',
      'mail2erica.com',
      'mail2erin.com',
      'mail2erinyes.com',
      'mail2eris.com',
      'mail2eritrea.com',
      'mail2ernie.com',
      'mail2eros.com',
      'mail2estonia.com',
      'mail2ethan.com',
      'mail2ethiopia.com',
      'mail2eu.com',
      'mail2europe.com',
      'mail2eurus.com',
      'mail2eva.com',
      'mail2evan.com',
      'mail2evelyn.com',
      'mail2everything.com',
      'mail2exciting.com',
      'mail2expert.com',
      'mail2fairy.com',
      'mail2faith.com',
      'mail2fanatic.com',
      'mail2fancy.com',
      'mail2fantasy.com',
      'mail2farm.com',
      'mail2farmer.com',
      'mail2fashion.com',
      'mail2fat.com',
      'mail2feeling.com',
      'mail2female.com',
      'mail2fever.com',
      'mail2fighter.com',
      'mail2fiji.com',
      'mail2filmfestival.com',
      'mail2films.com',
      'mail2finance.com',
      'mail2finland.com',
      'mail2fireman.com',
      'mail2firm.com',
      'mail2fisherman.com',
      'mail2flexible.com',
      'mail2florence.com',
      'mail2florida.com',
      'mail2floyd.com',
      'mail2fly.com',
      'mail2fond.com',
      'mail2fondness.com',
      'mail2football.com',
      'mail2footballfan.com',
      'mail2found.com',
      'mail2france.com',
      'mail2frank.com',
      'mail2frankfurt.com',
      'mail2franklin.com',
      'mail2fred.com',
      'mail2freddie.com',
      'mail2free.com',
      'mail2freedom.com',
      'mail2french.com',
      'mail2freudian.com',
      'mail2friendship.com',
      'mail2from.com',
      'mail2fun.com',
      'mail2gabon.com',
      'mail2gabriel.com',
      'mail2gail.com',
      'mail2galaxy.com',
      'mail2gambia.com',
      'mail2games.com',
      'mail2gary.com',
      'mail2gavin.com',
      'mail2gemini.com',
      'mail2gene.com',
      'mail2genes.com',
      'mail2geneva.com',
      'mail2george.com',
      'mail2georgia.com',
      'mail2gerald.com',
      'mail2german.com',
      'mail2germany.com',
      'mail2ghana.com',
      'mail2gilbert.com',
      'mail2gina.com',
      'mail2girl.com',
      'mail2glen.com',
      'mail2gloria.com',
      'mail2goddess.com',
      'mail2gold.com',
      'mail2golfclub.com',
      'mail2golfer.com',
      'mail2gordon.com',
      'mail2government.com',
      'mail2grab.com',
      'mail2grace.com',
      'mail2graham.com',
      'mail2grandma.com',
      'mail2grandpa.com',
      'mail2grant.com',
      'mail2greece.com',
      'mail2green.com',
      'mail2greg.com',
      'mail2grenada.com',
      'mail2gsm.com',
      'mail2guard.com',
      'mail2guatemala.com',
      'mail2guy.com',
      'mail2hades.com',
      'mail2haiti.com',
      'mail2hal.com',
      'mail2handhelds.com',
      'mail2hank.com',
      'mail2hannah.com',
      'mail2harold.com',
      'mail2harry.com',
      'mail2hawaii.com',
      'mail2headhunter.com',
      'mail2heal.com',
      'mail2heather.com',
      'mail2heaven.com',
      'mail2hebe.com',
      'mail2hecate.com',
      'mail2heidi.com',
      'mail2helen.com',
      'mail2hell.com',
      'mail2help.com',
      'mail2helpdesk.com',
      'mail2henry.com',
      'mail2hephaestus.com',
      'mail2hera.com',
      'mail2hercules.com',
      'mail2herman.com',
      'mail2hermes.com',
      'mail2hespera.com',
      'mail2hestia.com',
      'mail2highschool.com',
      'mail2hindu.com',
      'mail2hip.com',
      'mail2hiphop.com',
      'mail2holland.com',
      'mail2holly.com',
      'mail2hollywood.com',
      'mail2homer.com',
      'mail2honduras.com',
      'mail2honey.com',
      'mail2hongkong.com',
      'mail2hope.com',
      'mail2horse.com',
      'mail2hot.com',
      'mail2hotel.com',
      'mail2houston.com',
      'mail2howard.com',
      'mail2hugh.com',
      'mail2human.com',
      'mail2hungary.com',
      'mail2hungry.com',
      'mail2hygeia.com',
      'mail2hyperspace.com',
      'mail2hypnos.com',
      'mail2ian.com',
      'mail2ice-cream.com',
      'mail2iceland.com',
      'mail2idaho.com',
      'mail2idontknow.com',
      'mail2illinois.com',
      'mail2imam.com',
      'mail2in.com',
      'mail2india.com',
      'mail2indian.com',
      'mail2indiana.com',
      'mail2indonesia.com',
      'mail2infinity.com',
      'mail2intense.com',
      'mail2iowa.com',
      'mail2iran.com',
      'mail2iraq.com',
      'mail2ireland.com',
      'mail2irene.com',
      'mail2iris.com',
      'mail2irresistible.com',
      'mail2irving.com',
      'mail2irwin.com',
      'mail2isaac.com',
      'mail2israel.com',
      'mail2italian.com',
      'mail2italy.com',
      'mail2jackie.com',
      'mail2jacob.com',
      'mail2jail.com',
      'mail2jaime.com',
      'mail2jake.com',
      'mail2jamaica.com',
      'mail2james.com',
      'mail2jamie.com',
      'mail2jan.com',
      'mail2jane.com',
      'mail2janet.com',
      'mail2janice.com',
      'mail2japan.com',
      'mail2japanese.com',
      'mail2jasmine.com',
      'mail2jason.com',
      'mail2java.com',
      'mail2jay.com',
      'mail2jazz.com',
      'mail2jed.com',
      'mail2jeffrey.com',
      'mail2jennifer.com',
      'mail2jenny.com',
      'mail2jeremy.com',
      'mail2jerry.com',
      'mail2jessica.com',
      'mail2jessie.com',
      'mail2jesus.com',
      'mail2jew.com',
      'mail2jeweler.com',
      'mail2jim.com',
      'mail2jimmy.com',
      'mail2joan.com',
      'mail2joann.com',
      'mail2joanna.com',
      'mail2jody.com',
      'mail2joe.com',
      'mail2joel.com',
      'mail2joey.com',
      'mail2john.com',
      'mail2join.com',
      'mail2jon.com',
      'mail2jonathan.com',
      'mail2jones.com',
      'mail2jordan.com',
      'mail2joseph.com',
      'mail2josh.com',
      'mail2joy.com',
      'mail2juan.com',
      'mail2judge.com',
      'mail2judy.com',
      'mail2juggler.com',
      'mail2julian.com',
      'mail2julie.com',
      'mail2jumbo.com',
      'mail2junk.com',
      'mail2justin.com',
      'mail2justme.com',
      'mail2kansas.com',
      'mail2karate.com',
      'mail2karen.com',
      'mail2karl.com',
      'mail2karma.com',
      'mail2kathleen.com',
      'mail2kathy.com',
      'mail2katie.com',
      'mail2kay.com',
      'mail2kazakhstan.com',
      'mail2keen.com',
      'mail2keith.com',
      'mail2kelly.com',
      'mail2kelsey.com',
      'mail2ken.com',
      'mail2kendall.com',
      'mail2kennedy.com',
      'mail2kenneth.com',
      'mail2kenny.com',
      'mail2kentucky.com',
      'mail2kenya.com',
      'mail2kerry.com',
      'mail2kevin.com',
      'mail2kim.com',
      'mail2kimberly.com',
      'mail2king.com',
      'mail2kirk.com',
      'mail2kiss.com',
      'mail2kosher.com',
      'mail2kristin.com',
      'mail2kurt.com',
      'mail2kuwait.com',
      'mail2kyle.com',
      'mail2kyrgyzstan.com',
      'mail2la.com',
      'mail2lacrosse.com',
      'mail2lance.com',
      'mail2lao.com',
      'mail2larry.com',
      'mail2latvia.com',
      'mail2laugh.com',
      'mail2laura.com',
      'mail2lauren.com',
      'mail2laurie.com',
      'mail2lawrence.com',
      'mail2lawyer.com',
      'mail2lebanon.com',
      'mail2lee.com',
      'mail2leo.com',
      'mail2leon.com',
      'mail2leonard.com',
      'mail2leone.com',
      'mail2leslie.com',
      'mail2letter.com',
      'mail2liberia.com',
      'mail2libertarian.com',
      'mail2libra.com',
      'mail2libya.com',
      'mail2liechtenstein.com',
      'mail2life.com',
      'mail2linda.com',
      'mail2linux.com',
      'mail2lionel.com',
      'mail2lipstick.com',
      'mail2liquid.com',
      'mail2lisa.com',
      'mail2lithuania.com',
      'mail2litigator.com',
      'mail2liz.com',
      'mail2lloyd.com',
      'mail2lois.com',
      'mail2lola.com',
      'mail2london.com',
      'mail2looking.com',
      'mail2lori.com',
      'mail2lost.com',
      'mail2lou.com',
      'mail2louis.com',
      'mail2louisiana.com',
      'mail2lovable.com',
      'mail2love.com',
      'mail2lucky.com',
      'mail2lucy.com',
      'mail2lunch.com',
      'mail2lust.com',
      'mail2luxembourg.com',
      'mail2luxury.com',
      'mail2lyle.com',
      'mail2lynn.com',
      'mail2madagascar.com',
      'mail2madison.com',
      'mail2madrid.com',
      'mail2maggie.com',
      'mail2mail4.com',
      'mail2maine.com',
      'mail2malawi.com',
      'mail2malaysia.com',
      'mail2maldives.com',
      'mail2mali.com',
      'mail2malta.com',
      'mail2mambo.com',
      'mail2man.com',
      'mail2mandy.com',
      'mail2manhunter.com',
      'mail2mankind.com',
      'mail2many.com',
      'mail2marc.com',
      'mail2marcia.com',
      'mail2margaret.com',
      'mail2margie.com',
      'mail2marhaba.com',
      'mail2maria.com',
      'mail2marilyn.com',
      'mail2marines.com',
      'mail2mark.com',
      'mail2marriage.com',
      'mail2married.com',
      'mail2marries.com',
      'mail2mars.com',
      'mail2marsha.com',
      'mail2marshallislands.com',
      'mail2martha.com',
      'mail2martin.com',
      'mail2marty.com',
      'mail2marvin.com',
      'mail2mary.com',
      'mail2maryland.com',
      'mail2mason.com',
      'mail2massachusetts.com',
      'mail2matt.com',
      'mail2matthew.com',
      'mail2maurice.com',
      'mail2mauritania.com',
      'mail2mauritius.com',
      'mail2max.com',
      'mail2maxwell.com',
      'mail2maybe.com',
      'mail2mba.com',
      'mail2me4u.com',
      'mail2mechanic.com',
      'mail2medieval.com',
      'mail2megan.com',
      'mail2mel.com',
      'mail2melanie.com',
      'mail2melissa.com',
      'mail2melody.com',
      'mail2member.com',
      'mail2memphis.com',
      'mail2methodist.com',
      'mail2mexican.com',
      'mail2mexico.com',
      'mail2mgz.com',
      'mail2miami.com',
      'mail2michael.com',
      'mail2michelle.com',
      'mail2michigan.com',
      'mail2mike.com',
      'mail2milan.com',
      'mail2milano.com',
      'mail2mildred.com',
      'mail2milkyway.com',
      'mail2millennium.com',
      'mail2millionaire.com',
      'mail2milton.com',
      'mail2mime.com',
      'mail2mindreader.com',
      'mail2mini.com',
      'mail2minister.com',
      'mail2minneapolis.com',
      'mail2minnesota.com',
      'mail2miracle.com',
      'mail2missionary.com',
      'mail2mississippi.com',
      'mail2missouri.com',
      'mail2mitch.com',
      'mail2model.com',
      'mail2mom.com',
      'mail2monaco.com',
      'mail2money.com',
      'mail2mongolia.com',
      'mail2monica.com',
      'mail2montana.com',
      'mail2monty.com',
      'mail2moon.com',
      'mail2morocco.com',
      'mail2morpheus.com',
      'mail2mors.com',
      'mail2moscow.com',
      'mail2moslem.com',
      'mail2mouseketeer.com',
      'mail2movies.com',
      'mail2mozambique.com',
      'mail2mp3.com',
      'mail2mrright.com',
      'mail2msright.com',
      'mail2museum.com',
      'mail2music.com',
      'mail2musician.com',
      'mail2muslim.com',
      'mail2my.com',
      'mail2myboat.com',
      'mail2mycar.com',
      'mail2mycell.com',
      'mail2mygsm.com',
      'mail2mylaptop.com',
      'mail2mymac.com',
      'mail2mypager.com',
      'mail2mypalm.com',
      'mail2mypc.com',
      'mail2myphone.com',
      'mail2myplane.com',
      'mail2namibia.com',
      'mail2nancy.com',
      'mail2nasdaq.com',
      'mail2nathan.com',
      'mail2nauru.com',
      'mail2navy.com',
      'mail2neal.com',
      'mail2nebraska.com',
      'mail2ned.com',
      'mail2neil.com',
      'mail2nelson.com',
      'mail2nemesis.com',
      'mail2nepal.com',
      'mail2netherlands.com',
      'mail2network.com',
      'mail2nevada.com',
      'mail2newhampshire.com',
      'mail2newjersey.com',
      'mail2newmexico.com',
      'mail2newyork.com',
      'mail2newzealand.com',
      'mail2nicaragua.com',
      'mail2nick.com',
      'mail2nicole.com',
      'mail2niger.com',
      'mail2nigeria.com',
      'mail2nike.com',
      'mail2no.com',
      'mail2noah.com',
      'mail2noel.com',
      'mail2noelle.com',
      'mail2normal.com',
      'mail2norman.com',
      'mail2northamerica.com',
      'mail2northcarolina.com',
      'mail2northdakota.com',
      'mail2northpole.com',
      'mail2norway.com',
      'mail2notus.com',
      'mail2noway.com',
      'mail2nowhere.com',
      'mail2nuclear.com',
      'mail2nun.com',
      'mail2ny.com',
      'mail2oasis.com',
      'mail2oceanographer.com',
      'mail2ohio.com',
      'mail2ok.com',
      'mail2oklahoma.com',
      'mail2oliver.com',
      'mail2oman.com',
      'mail2one.com',
      'mail2onfire.com',
      'mail2online.com',
      'mail2oops.com',
      'mail2open.com',
      'mail2ophthalmologist.com',
      'mail2optometrist.com',
      'mail2oregon.com',
      'mail2oscars.com',
      'mail2oslo.com',
      'mail2painter.com',
      'mail2pakistan.com',
      'mail2pan.com',
      'mail2panama.com',
      'mail2paraguay.com',
      'mail2paralegal.com',
      'mail2paris.com',
      'mail2park.com',
      'mail2parker.com',
      'mail2party.com',
      'mail2passion.com',
      'mail2pat.com',
      'mail2patricia.com',
      'mail2patrick.com',
      'mail2patty.com',
      'mail2paul.com',
      'mail2paula.com',
      'mail2pay.com',
      'mail2peace.com',
      'mail2pediatrician.com',
      'mail2peggy.com',
      'mail2pennsylvania.com',
      'mail2perry.com',
      'mail2persephone.com',
      'mail2persian.com',
      'mail2peru.com',
      'mail2pete.com',
      'mail2peter.com',
      'mail2pharmacist.com',
      'mail2phil.com',
      'mail2philippines.com',
      'mail2phoenix.com',
      'mail2phonecall.com',
      'mail2phyllis.com',
      'mail2pickup.com',
      'mail2pilot.com',
      'mail2pisces.com',
      'mail2planet.com',
      'mail2platinum.com',
      'mail2plato.com',
      'mail2pluto.com',
      'mail2pm.com',
      'mail2podiatrist.com',
      'mail2poet.com',
      'mail2poland.com',
      'mail2policeman.com',
      'mail2policewoman.com',
      'mail2politician.com',
      'mail2pop.com',
      'mail2pope.com',
      'mail2popular.com',
      'mail2portugal.com',
      'mail2poseidon.com',
      'mail2potatohead.com',
      'mail2power.com',
      'mail2presbyterian.com',
      'mail2president.com',
      'mail2priest.com',
      'mail2prince.com',
      'mail2princess.com',
      'mail2producer.com',
      'mail2professor.com',
      'mail2protect.com',
      'mail2psychiatrist.com',
      'mail2psycho.com',
      'mail2psychologist.com',
      'mail2qatar.com',
      'mail2queen.com',
      'mail2rabbi.com',
      'mail2race.com',
      'mail2racer.com',
      'mail2rachel.com',
      'mail2rage.com',
      'mail2rainmaker.com',
      'mail2ralph.com',
      'mail2randy.com',
      'mail2rap.com',
      'mail2rare.com',
      'mail2rave.com',
      'mail2ray.com',
      'mail2raymond.com',
      'mail2realtor.com',
      'mail2rebecca.com',
      'mail2recruiter.com',
      'mail2recycle.com',
      'mail2redhead.com',
      'mail2reed.com',
      'mail2reggie.com',
      'mail2register.com',
      'mail2rent.com',
      'mail2republican.com',
      'mail2resort.com',
      'mail2rex.com',
      'mail2rhodeisland.com',
      'mail2rich.com',
      'mail2richard.com',
      'mail2ricky.com',
      'mail2ride.com',
      'mail2riley.com',
      'mail2rita.com',
      'mail2rob.com',
      'mail2robert.com',
      'mail2roberta.com',
      'mail2robin.com',
      'mail2rock.com',
      'mail2rocker.com',
      'mail2rod.com',
      'mail2rodney.com',
      'mail2romania.com',
      'mail2rome.com',
      'mail2ron.com',
      'mail2ronald.com',
      'mail2ronnie.com',
      'mail2rose.com',
      'mail2rosie.com',
      'mail2roy.com',
      'mail2rss.org',
      'mail2rudy.com',
      'mail2rugby.com',
      'mail2runner.com',
      'mail2russell.com',
      'mail2russia.com',
      'mail2russian.com',
      'mail2rusty.com',
      'mail2ruth.com',
      'mail2rwanda.com',
      'mail2ryan.com',
      'mail2sa.com',
      'mail2sabrina.com',
      'mail2safe.com',
      'mail2sagittarius.com',
      'mail2sail.com',
      'mail2sailor.com',
      'mail2sal.com',
      'mail2salaam.com',
      'mail2sam.com',
      'mail2samantha.com',
      'mail2samoa.com',
      'mail2samurai.com',
      'mail2sandra.com',
      'mail2sandy.com',
      'mail2sanfrancisco.com',
      'mail2sanmarino.com',
      'mail2santa.com',
      'mail2sara.com',
      'mail2sarah.com',
      'mail2sat.com',
      'mail2saturn.com',
      'mail2saudi.com',
      'mail2saudiarabia.com',
      'mail2save.com',
      'mail2savings.com',
      'mail2school.com',
      'mail2scientist.com',
      'mail2scorpio.com',
      'mail2scott.com',
      'mail2sean.com',
      'mail2search.com',
      'mail2seattle.com',
      'mail2secretagent.com',
      'mail2senate.com',
      'mail2senegal.com',
      'mail2sensual.com',
      'mail2seth.com',
      'mail2sevenseas.com',
      'mail2sexy.com',
      'mail2seychelles.com',
      'mail2shane.com',
      'mail2sharon.com',
      'mail2shawn.com',
      'mail2ship.com',
      'mail2shirley.com',
      'mail2shoot.com',
      'mail2shuttle.com',
      'mail2sierraleone.com',
      'mail2simon.com',
      'mail2singapore.com',
      'mail2single.com',
      'mail2site.com',
      'mail2skater.com',
      'mail2skier.com',
      'mail2sky.com',
      'mail2sleek.com',
      'mail2slim.com',
      'mail2slovakia.com',
      'mail2slovenia.com',
      'mail2smile.com',
      'mail2smith.com',
      'mail2smooth.com',
      'mail2soccer.com',
      'mail2soccerfan.com',
      'mail2socialist.com',
      'mail2soldier.com',
      'mail2somalia.com',
      'mail2son.com',
      'mail2song.com',
      'mail2sos.com',
      'mail2sound.com',
      'mail2southafrica.com',
      'mail2southamerica.com',
      'mail2southcarolina.com',
      'mail2southdakota.com',
      'mail2southkorea.com',
      'mail2southpole.com',
      'mail2spain.com',
      'mail2spanish.com',
      'mail2spare.com',
      'mail2spectrum.com',
      'mail2splash.com',
      'mail2sponsor.com',
      'mail2sports.com',
      'mail2srilanka.com',
      'mail2stacy.com',
      'mail2stan.com',
      'mail2stanley.com',
      'mail2star.com',
      'mail2state.com',
      'mail2stephanie.com',
      'mail2steve.com',
      'mail2steven.com',
      'mail2stewart.com',
      'mail2stlouis.com',
      'mail2stock.com',
      'mail2stockholm.com',
      'mail2stockmarket.com',
      'mail2storage.com',
      'mail2store.com',
      'mail2strong.com',
      'mail2student.com',
      'mail2studio.com',
      'mail2studio54.com',
      'mail2stuntman.com',
      'mail2subscribe.com',
      'mail2sudan.com',
      'mail2superstar.com',
      'mail2surfer.com',
      'mail2suriname.com',
      'mail2susan.com',
      'mail2suzie.com',
      'mail2swaziland.com',
      'mail2sweden.com',
      'mail2sweetheart.com',
      'mail2swim.com',
      'mail2swimmer.com',
      'mail2swiss.com',
      'mail2switzerland.com',
      'mail2sydney.com',
      'mail2sylvia.com',
      'mail2syria.com',
      'mail2taboo.com',
      'mail2taiwan.com',
      'mail2tajikistan.com',
      'mail2tammy.com',
      'mail2tango.com',
      'mail2tanya.com',
      'mail2tanzania.com',
      'mail2tara.com',
      'mail2taurus.com',
      'mail2taxi.com',
      'mail2taxidermist.com',
      'mail2taylor.com',
      'mail2taz.com',
      'mail2teacher.com',
      'mail2technician.com',
      'mail2ted.com',
      'mail2telephone.com',
      'mail2tenderness.com',
      'mail2tennessee.com',
      'mail2tennis.com',
      'mail2tennisfan.com',
      'mail2terri.com',
      'mail2terry.com',
      'mail2test.com',
      'mail2texas.com',
      'mail2thailand.com',
      'mail2therapy.com',
      'mail2think.com',
      'mail2tickets.com',
      'mail2tiffany.com',
      'mail2tim.com',
      'mail2time.com',
      'mail2timothy.com',
      'mail2tina.com',
      'mail2titanic.com',
      'mail2toby.com',
      'mail2todd.com',
      'mail2togo.com',
      'mail2tom.com',
      'mail2tommy.com',
      'mail2tonga.com',
      'mail2tony.com',
      'mail2touch.com',
      'mail2tourist.com',
      'mail2tracey.com',
      'mail2tracy.com',
      'mail2tramp.com',
      'mail2travel.com',
      'mail2traveler.com',
      'mail2travis.com',
      'mail2trekkie.com',
      'mail2trex.com',
      'mail2triallawyer.com',
      'mail2trick.com',
      'mail2trillionaire.com',
      'mail2troy.com',
      'mail2truck.com',
      'mail2trump.com',
      'mail2try.com',
      'mail2tunisia.com',
      'mail2turbo.com',
      'mail2turkey.com',
      'mail2turkmenistan.com',
      'mail2tv.com',
      'mail2tycoon.com',
      'mail2tyler.com',
      'mail2u4me.com',
      'mail2uae.com',
      'mail2uganda.com',
      'mail2uk.com',
      'mail2ukraine.com',
      'mail2uncle.com',
      'mail2unsubscribe.com',
      'mail2uptown.com',
      'mail2uruguay.com',
      'mail2usa.com',
      'mail2utah.com',
      'mail2uzbekistan.com',
      'mail2v.com',
      'mail2vacation.com',
      'mail2valentines.com',
      'mail2valerie.com',
      'mail2valley.com',
      'mail2vamoose.com',
      'mail2vanessa.com',
      'mail2vanuatu.com',
      'mail2venezuela.com',
      'mail2venous.com',
      'mail2venus.com',
      'mail2vermont.com',
      'mail2vickie.com',
      'mail2victor.com',
      'mail2victoria.com',
      'mail2vienna.com',
      'mail2vietnam.com',
      'mail2vince.com',
      'mail2virginia.com',
      'mail2virgo.com',
      'mail2visionary.com',
      'mail2vodka.com',
      'mail2volleyball.com',
      'mail2waiter.com',
      'mail2wallstreet.com',
      'mail2wally.com',
      'mail2walter.com',
      'mail2warren.com',
      'mail2washington.com',
      'mail2wave.com',
      'mail2way.com',
      'mail2waycool.com',
      'mail2wayne.com',
      'mail2webmaster.com',
      'mail2webtop.com',
      'mail2webtv.com',
      'mail2weird.com',
      'mail2wendell.com',
      'mail2wendy.com',
      'mail2westend.com',
      'mail2westvirginia.com',
      'mail2whether.com',
      'mail2whip.com',
      'mail2white.com',
      'mail2whitehouse.com',
      'mail2whitney.com',
      'mail2why.com',
      'mail2wilbur.com',
      'mail2wild.com',
      'mail2willard.com',
      'mail2willie.com',
      'mail2wine.com',
      'mail2winner.com',
      'mail2wired.com',
      'mail2wisconsin.com',
      'mail2woman.com',
      'mail2wonder.com',
      'mail2world.com',
      'mail2worship.com',
      'mail2wow.com',
      'mail2www.com',
      'mail2wyoming.com',
      'mail2xfiles.com',
      'mail2xox.com',
      'mail2yachtclub.com',
      'mail2yahalla.com',
      'mail2yemen.com',
      'mail2yes.com',
      'mail2yugoslavia.com',
      'mail2zack.com',
      'mail2zambia.com',
      'mail2zenith.com',
      'mail2zephir.com',
      'mail2zeus.com',
      'mail2zipper.com',
      'mail2zoo.com',
      'mail2zoologist.com',
      'mail2zurich.com',
      'mail3000.com',
      'mail4trash.com',
      'mail4u.info',
      'mailandftp.com',
      'mailandnews.com',
      'mailas.com',
      'mailasia.com',
      'mailbolt.com',
      'mailbomb.net',
      'mailboom.com',
      'mailbox.as',
      'mailbox.co.za',
      'mailbox.gr',
      'mailbox.hu',
      'mailc.net',
      'mailcan.com',
      'mailcc.com',
      'mailcity.com',
      'mailclub.fr',
      'mailexcite.com',
      'mailforce.net',
      'mailftp.com',
      'mailhaven.com',
      'mailhood.com',
      'mailinblack.com',
      'mailingaddress.org',
      'mailingweb.com',
      'mailisent.com',
      'mailite.com',
      'mailme.dk',
      'mailmight.com',
      'mailnator.com',
      'mailnew.com',
      'mailoye.com',
      'mailpanda.com',
      'mailpost.zzn.com',
      'mailpride.com',
      'mailpuppy.com',
      'mailquack.com',
      'mailroom.com',
      'mailru.com',
      'mailsent.net',
      'mailservice.ms',
      'mailshuttle.com',
      'mailstart.com',
      'mailstartplus.com',
      'mailtag.com',
      'mailto.de',
      'mailueberfall.de',
      'mailup.net',
      'mailwire.com',
      'mailworks.org',
      'mailzilla.org',
      'maktoob.com',
      'malayalamtelevision.net',
      'maltesemail.com',
      'mamber.net',
      'manager.de',
      'mancity.net',
      'mantrafreenet.com',
      'mantramail.com',
      'marchmail.com',
      'mariahc.com',
      'marijuana.com',
      'marijuana.nl',
      'married-not.com',
      'martindalemail.com',
      'mash4077.com',
      'masrawy.com',
      'matmail.com',
      'mauimail.com',
      'mauritius.com',
      'maxmail.co.uk',
      'mbox.com.au',
      'me.com',
      'medical.net.au',
      'medscape.com',
      'meetingmall.com',
      'megapoint.com',
      'mehtaweb.com',
      'meine-dateien.info',
      'meine-diashow.de',
      'meine-fotos.info',
      'meine-urlaubsfotos.de',
      'mekhong.com',
      'melodymail.com',
      'message.hu',
      'messages.to',
      'metacrawler.com',
      'metalfan.com',
      'metaping.com',
      'metta.lk',
      'mexicomail.com',
      'miesto.sk',
      'mighty.co.za',
      'miho-nakayama.com',
      'mikrotamanet.com',
      'millionaireintraining.com',
      'millionairemail.com',
      'milmail.com',
      'mindless.com',
      'mindspring.com',
      'minister.com',
      'misery.net',
      'mittalweb.com',
      'mixmail.com',
      'mjfrogmail.com',
      'ml1.net',
      'mm.st',
      'mns.ru',
      'mochamail.com',
      'mohammed.com',
      'moldova.cc',
      'moldova.com',
      'moldovacc.com',
      'momslife.com',
      'money.net',
      'montevideo.com.uy',
      'moonman.com',
      'moose-mail.com',
      'mortaza.com',
      'moscowmail.com',
      'mostlysunny.com',
      'motormania.com',
      'movemail.com',
      'movieluver.com',
      'mp4.it',
      'mr-potatohead.com',
      'msn.com',
      'mttestdriver.com',
      'munich.com',
      'music.com',
      'musician.org',
      'musicscene.org',
      'muskelshirt.de',
      'muslim.com',
      'muslimsonline.com',
      'mutantweb.com',
      'mybox.it',
      'mycity.com',
      'mydomain.com',
      'mydotcomaddress.com',
      'myfamily.com',
      'myfastmail.com',
      'mygo.com',
      'myiris.com',
      'mymacmail.com',
      'mynamedot.com',
      'myownemail.com',
      'mypad.com',
      'mypersonalemail.com',
      'myplace.com',
      'myrealbox.com',
      'myremarq.com',
      'myself.com',
      'mystupidjob.com',
      'mythirdage.com',
      'myway.com',
      'myworldmail.com',
      'n2baseball.com',
      'n2mail.com',
      'n2soccer.com',
      'n2software.com',
      'nabc.biz',
      'nafe.com',
      'nakedgreens.com',
      'name.com',
      'naplesnews.net',
      'naseej.com',
      'nativeweb.net',
      'naui.net',
      'navigator.lv',
      'navy.org',
      'naz.com',
      'nchoicemail.com',
      'neeva.net',
      'nenter.com',
      'neo.rr.com',
      'nervhq.org',
      'net-pager.net',
      'net-shopping.com',
      'net4b.pt',
      'net4you.at',
      'netbounce.com',
      'netbroadcaster.com',
      'netby.dk',
      'netcenter-vn.net',
      'netcourrier.com',
      'netexecutive.com',
      'netexpressway.com',
      'netgenie.com',
      'netian.com',
      'netizen.com.ar',
      'netmongol.com',
      'netnoir.net',
      'netpiper.com',
      'netralink.com',
      'netscape.net',
      'netspace.net.au',
      'netster.com',
      'nettaxi.com',
      'netterchef.de',
      'netzero.com',
      'netzero.net',
      'neue-dateien.de',
      'neuro.md',
      'newmail.com',
      'newmail.ru',
      'newsboysmail.com',
      'newyork.com',
      'nexxmail.com',
      'nfmail.com',
      'nicebush.com',
      'nicegal.com',
      'nicholastse.net',
      'nicolastse.com',
      'nikopage.com',
      'nimail.com',
      'ninfan.com',
      'nirvanafan.com',
      'noavar.com',
      'nonpartisan.com',
      'norika-fujiwara.com',
      'norikomail.com',
      'northgates.net',
      'ntscan.com',
      'null.net',
      'nullbox.info',
      'nur-fuer-spam.de',
      'nwldx.com',
      'ny.com',
      'nybella.com',
      'nyc.com',
      'nycmail.com',
      'nzoomail.com',
      'o-tay.com',
      'o2.co.uk',
      'oaklandas-fan.com',
      'oath.com',
      'oceanfree.net',
      'oddpost.com',
      'odmail.com',
      'office-dateien.de',
      'office-email.com',
      'offroadwarrior.com',
      'oicexchange.com',
      'oikrach.com',
      'okbank.com',
      'okhuman.com',
      'okmad.com',
      'okmagic.com',
      'okname.net',
      'okuk.com',
      'oldies104mail.com',
      'ole.com',
      'olemail.com',
      'olympist.net',
      'omaninfo.com',
      'onebox.com',
      'onenet.com.ar',
      'onet.pl',
      'oninet.pt',
      'online.ie',
      'onlinewiz.com',
      'onmilwaukee.com',
      'onobox.com',
      'operamail.com',
      'optician.com',
      'optonline.net',
      'optusnet.com.au',
      'orbitel.bg',
      'orgmail.net',
      'orthodontist.net',
      'osite.com.br',
      'oso.com',
      'otakumail.com',
      'our-computer.com',
      'our-office.com',
      'our.st',
      'ourbrisbane.com',
      'ournet.md',
      'outgun.com',
      'outlook.com',
      'outlook.in',
      'over-the-rainbow.com',
      'ownmail.net',
      'ozbytes.net.au',
      'ozemail.com.au',
      'pacbell.net',
      'pacific-ocean.com',
      'pacific-re.com',
      'pacificwest.com',
      'packersfan.com',
      'pagina.de',
      'pagons.org',
      'pakistanmail.com',
      'pakistanoye.com',
      'parkjiyoon.com',
      'parrot.com',
      'parsmail.com',
      'partlycloudy.com',
      'partybombe.de',
      'partyheld.de',
      'partynight.at',
      'passwordmail.com',
      'pathfindermail.com',
      'pconnections.net',
      'pcsrock.com',
      'pediatrician.com',
      'penpen.com',
      'peoplepc.com',
      'peopleweb.com',
      'perfectmail.com',
      'personal.ro',
      'personales.com',
      'petlover.com',
      'petml.com',
      'pettypool.com',
      'pezeshkpour.com',
      'phayze.com',
      'phone.net',
      'photographer.net',
      'phreaker.net',
      'physicist.net',
      'pianomail.com',
      'pickupman.com',
      'picusnet.com',
      'pigpig.net',
      'pinoymail.com',
      'pisem.net',
      'planetaccess.com',
      'planetarymotion.net',
      'planetearthinter.net',
      'planetmail.com',
      'planetmail.net',
      'plasa.com',
      'playersodds.com',
      'playful.com',
      'plusmail.com.br',
      'pobox.sk',
      'pochta.ru',
      'poczta.fm',
      'poetic.com',
      'polandmail.com',
      'polbox.com',
      'politician.com',
      'polizisten-duzer.de',
      'poond.com',
      'popaccount.com',
      'popmail.com',
      'popsmail.com',
      'popstar.com',
      'portugalmail.com',
      'portugalmail.pt',
      'post.com',
      'post.cz',
      'post.sk',
      'posta.ro',
      'postaccesslite.com',
      'postafree.com',
      'postfach.cc',
      'postinbox.com',
      'postino.ch',
      'postmark.net',
      'postmaster.co.uk',
      'postpro.net',
      'pousa.com',
      'powerfan.com',
      'praize.com',
      'premiumservice.com',
      'presidency.com',
      'press.co.jp',
      'priest.com',
      'primposta.com',
      'primposta.hu',
      'privy-mail.com',
      'pro.hu',
      'probemail.com',
      'prodigy.net',
      'progetplus.it',
      'programmer.net',
      'proinbox.com',
      'promessage.com',
      'prontomail.com',
      'protestant.com',
      'prydirect.info',
      'psv-supporter.com',
      'ptd.net',
      'public-files.de',
      'public.usa.com',
      'publicist.com',
      'pulp-fiction.com',
      'purpleturtle.com',
      'put2.net',
      'q.com',
      'qmail.com',
      'qprfans.com',
      'quackquack.com',
      'quakemail.com',
      'qualityservice.com',
      'quantentunnel.de',
      'quickhosts.com',
      'quickwebmail.com',
      'quiklinks.com',
      'quikmail.com',
      'qv7.info',
      'qwest.net',
      'qwestoffice.net',
      'racedriver.com',
      'racefanz.com',
      'racingmail.com',
      'radicalz.com',
      'radiologist.net',
      'ragingbull.com',
      'ralib.com',
      'ranmamail.com',
      'rastogi.net',
      'ratt-n-roll.com',
      'rattle-snake.com',
      'raubtierbaendiger.de',
      'ravearena.com',
      'ravemail.com',
      'razormail.com',
      'realemail.net',
      'reallyfast.biz',
      'reallyfast.info',
      'realradiomail.com',
      'realtyagent.com',
      'reborn.com',
      'recycler.com',
      'recyclermail.com',
      'rediff.com',
      'rediffmail.com',
      'rediffmailpro.com',
      'rednecks.com',
      'redseven.de',
      'reggaefan.com',
      'registerednurses.com',
      'reincarnate.com',
      'religious.com',
      'renren.com',
      'repairman.com',
      'reply.hu',
      'representative.com',
      'rescueteam.com',
      'resumemail.com',
      'rezai.com',
      'richmondhill.com',
      'rickymail.com',
      'rin.ru',
      'riopreto.com.br',
      'rn.com',
      'roadrunner.com',
      'roanokemail.com',
      'rock.com',
      'rocketmail.com',
      'rocketship.com',
      'rockfan.com',
      'rodrun.com',
      'roosh.com',
      'rootprompt.org',
      'roughnet.com',
      'rr.com',
      'rrohio.com',
      'rsub.com',
      'runbox.com',
      'rushpost.com',
      'ruttolibero.com',
      'rvshop.com',
      's-mail.com',
      'sacbeemail.com',
      'saeuferleber.de',
      'safrica.com',
      'sagra.lu',
      'sags-per-mail.de',
      'sailormoon.com',
      'saintly.com',
      'saintmail.net',
      'salehi.net',
      'salesperson.net',
      'samerica.com',
      'samilan.net',
      'sammimail.com',
      'sandelf.de',
      'sanfranmail.com',
      'sanook.com',
      'sapo.pt',
      'saudia.com',
      'sayhi.net',
      'saynotospams.com',
      'sbcglobal.net',
      'scandalmail.com',
      'schizo.com',
      'schmusemail.de',
      'schoolemail.com',
      'schoolmail.com',
      'schoolsucks.com',
      'schreib-doch-mal-wieder.de',
      'sci.fi',
      'scientist.com',
      'scotland.com',
      'scotlandmail.com',
      'scottishmail.co.uk',
      'scubadiving.com',
      'seanet.com',
      'searchwales.com',
      'sebil.com',
      'secret-police.com',
      'secretary.net',
      'secretservices.net',
      'seductive.com',
      'seekstoyboy.com',
      'seguros.com.br',
      'send.hu',
      'sendme.cz',
      'sent.as',
      'sent.at',
      'sent.com',
      'sentrismail.com',
      'serga.com.ar',
      'servemymail.com',
      'sesmail.com',
      'sexmagnet.com',
      'seznam.cz',
      'shaniastuff.com',
      'shared-files.de',
      'sharmaweb.com',
      'she.com',
      'shieldedmail.com',
      'shinedyoureyes.com',
      'shortmail.com',
      'shotgun.hu',
      'sialkotcity.com',
      'sialkotian.com',
      'sialkotoye.com',
      'sify.com',
      'silkroad.net',
      'sina.cn',
      'sina.com',
      'sinamail.com',
      'singles4jesus.com',
      'singmail.com',
      'singnet.com.sg',
      'singpost.com',
      'skafan.com',
      'skim.com',
      'skizo.hu',
      'slamdunkfan.com',
      'slingshot.com',
      'slotter.com',
      'smapxsmap.net',
      'smoothmail.com',
      'sms.at',
      'snail-mail.net',
      'snakebite.com',
      'snet.net',
      'sniper.hu',
      'snoopymail.com',
      'snowboarding.com',
      'snowdonia.net',
      'socamail.com',
      'socceramerica.net',
      'soccermail.com',
      'soccermomz.com',
      'socialworker.net',
      'sociologist.com',
      'sofort-mail.de',
      'sofortmail.de',
      'softhome.net',
      'sogou.com',
      'sohu.com',
      'sol.dk',
      'soldier.hu',
      'solution4u.com',
      'songwriter.net',
      'sonnenkinder.org',
      'soon.com',
      'soulfoodcookbook.com',
      'sp.nl',
      'space-bank.com',
      'space-man.com',
      'space-ship.com',
      'space-travel.com',
      'space.com',
      'spacemart.com',
      'spacetowns.com',
      'spacewar.com',
      'spainmail.com',
      'spambob.org',
      'spamcero.com',
      'spameater.com',
      'spameater.org',
      'spaminator.de',
      'spaml.com',
      'spamoff.de',
      'spartapiet.com',
      'spazmail.com',
      'speedemail.net',
      'speedpost.net',
      'speedrules.com',
      'speedrulz.com',
      'speedymail.org',
      'sperke.net',
      'spils.com',
      'spinfinder.com',
      'spl.at',
      'sportemail.com',
      'sportsmail.com',
      'sporttruckdriver.com',
      'spray.se',
      'spymac.com',
      'srilankan.net',
      'ssl-mail.com',
      'st-davids.net',
      'stade.fr',
      'stalag13.com',
      'stargateradio.com',
      'starmail.com',
      'starmail.org',
      'starmedia.com',
      'starspath.com',
      'start.com.au',
      'stoned.com',
      'stones.com',
      'storksite.com',
      'streber24.de',
      'stribmail.com',
      'strompost.com',
      'strongguy.com',
      'subram.com',
      'sudanmail.net',
      'suhabi.com',
      'sukhumvit.net',
      'sunpoint.net',
      'sunrise-sunset.com',
      'sunsgame.com',
      'sunumail.sn',
      'superdada.com',
      'supereva.it',
      'supermail.ru',
      'surat.com',
      'surfree.com',
      'surfy.net',
      'surgical.net',
      'surimail.com',
      'survivormail.com',
      'swbell.net',
      'sweb.cz',
      'swedenmail.com',
      'sweetville.net',
      'swift-mail.com',
      'swiftdesk.com',
      'swingeasyhithard.com',
      'swingfan.com',
      'swipermail.zzn.com',
      'swissmail.com',
      'swissmail.net',
      'switchboardmail.com',
      'sx172.com',
      'syom.com',
      't2mail.com',
      'takuyakimura.com',
      'talk21.com',
      'talkcity.com',
      'tamil.com',
      'tampabay.rr.com',
      'tankpolice.com',
      'tatanova.com',
      'tbwt.com',
      'tds.net',
      'teachermail.net',
      'teachers.org',
      'teamtulsa.net',
      'tech-center.com',
      'tech4peace.org',
      'techemail.com',
      'techie.com',
      'technisamail.co.za',
      'technologist.com',
      'techscout.com',
      'techspot.com',
      'telebot.com',
      'teleline.es',
      'telerymd.com',
      'telinco.net',
      'telpage.net',
      'telstra.com',
      'telstra.com.au',
      'temp-mail.com',
      'tempail.com',
      'tenchiclub.com',
      'tenderkiss.com',
      'tennismail.com',
      'terminverpennt.de',
      'terra.cl',
      'terra.com',
      'terra.com.ar',
      'terra.com.br',
      'test.com',
      'test.de',
      'tfanus.com.er',
      'tfz.net',
      'thai.com',
      'thaimail.com',
      'thaimail.net',
      'thanksnospam.info',
      'the-african.com',
      'the-american.com',
      'the-animal.com',
      'the-army.com',
      'the-astronaut.com',
      'the-beauty.com',
      'the-big-apple.com',
      'the-boss.com',
      'the-captain.com',
      'the-chinese.com',
      'the-country.com',
      'the-cowboy.com',
      'the-dutchman.com',
      'the-eagles.com',
      'the-englishman.com',
      'the-fastest.net',
      'the-genius.com',
      'the-gentleman.com',
      'the-german.com',
      'the-gremlin.com',
      'the-italian.com',
      'the-lair.com',
      'the-marine.com',
      'the-master.com',
      'the-mexican.com',
      'the-monkey.com',
      'the-pentagon.com',
      'the-professional.com',
      'the-quickest.com',
      'the-snake.com',
      'the-spaceman.com',
      'the-whitehouse.net',
      'thecriminals.com',
      'thedoghousemail.com',
      'thedorm.com',
      'theend.hu',
      'theglobe.com',
      'thegolfcourse.com',
      'theheadoffice.com',
      'theinternetemail.com',
      'thelanddownunder.com',
      'themail.com',
      'themillionare.net',
      'theplate.com',
      'thepokerface.com',
      'thepostmaster.net',
      'theracetrack.com',
      'therapist.net',
      'thestreetfighter.com',
      'thewatercooler.com',
      'thezhangs.net',
      'thirdage.com',
      'thisgirl.com',
      'tidni.com',
      'timein.net',
      'tiscali.co.uk',
      'tkcity.com',
      'toast.com',
      'toke.com',
      'tom.com',
      'toolsource.com',
      'toothfairy.com',
      'topchat.com',
      'topletter.com',
      'topmail-files.de',
      'topmail.com.ar',
      'torontomail.com',
      'tortenboxer.de',
      'totalmail.de',
      'tpg.com.au',
      'trashdevil.de',
      'trialbytrivia.com',
      'trimix.cn',
      'tritium.net',
      'trmailbox.com',
      'tropicalstorm.com',
      'truckerz.com',
      'truckracer.com',
      'truckracers.com',
      'trust-me.com',
      'truthmail.com',
      'tsamail.co.za',
      'ttml.co.in',
      'turboprinz.de',
      'turboprinzessin.de',
      'turkey.com',
      'tvstar.com',
      'twc.com',
      'twinstarsmail.com',
      'typemail.com',
      'u2club.com',
      'ubbi.com',
      'uboot.com',
      'uk2.net',
      'uk2k.com',
      'uk2net.com',
      'uk7.net',
      'uk8.net',
      'ukbuilder.com',
      'ukcool.com',
      'ukdreamcast.com',
      'ukmail.org',
      'ukmax.com',
      'ukr.net',
      'uku.co.uk',
      'ultapulta.com',
      'ultrapostman.com',
      'ummah.org',
      'umpire.com',
      'unbounded.com',
      'unforgettable.com',
      'uni.de',
      'unican.es',
      'unihome.com',
      'universal.pt',
      'uno.ee',
      'uno.it',
      'unofree.it',
      'unterderbruecke.de',
      'uol.com.ar',
      'uol.com.br',
      'uol.com.ve',
      'uomail.com',
      'upf.org',
      'ureach.com',
      'usa.com',
      'usa.net',
      'usaaccess.net',
      'usermail.com',
      'usma.net',
      'usmc.net',
      'uswestmail.net',
      'uymail.com',
      'vahoo.com',
      'vampirehunter.com',
      'varbizmail.com',
      'vcmail.com',
      'velnet.co.uk',
      'velocall.com',
      'verizon.net',
      'verizonmail.com',
      'verlass-mich-nicht.de',
      'veryfast.biz',
      'veryrealemail.com',
      'veryspeedy.net',
      'vinbazar.com',
      'violinmakers.co.uk',
      'vip.126.com',
      'vip.21cn.com',
      'vip.citiz.net',
      'vip.gr',
      'vip.sina.com',
      'vipmail.ru',
      'virgilio.it',
      'virgin.net',
      'virginbroadband.com.au',
      'visitweb.com',
      'visto.com',
      'visualcities.com',
      'vivavelocity.com',
      'vivianhsu.net',
      'vnet.citiz.net',
      'vnn.vn',
      'volcanomail.com',
      'vollbio.de',
      'volloeko.de',
      'vorsicht-bissig.de',
      'vorsicht-scharf.de',
      'vote-democrats.com',
      'vote-hillary.com',
      'vote-republicans.com',
      'vote4gop.org',
      'votenet.com',
      'vr9.com',
      'w3.to',
      'wahoye.com',
      'wales2000.net',
      'wam.co.za',
      'wanadoo.es',
      'wanadoo.fr',
      'war-im-urlaub.de',
      'warmmail.com',
      'warpmail.net',
      'warrior.hu',
      'wbdet.com',
      'wearab.net',
      'web-police.com',
      'web.de',
      'webave.com',
      'webcammail.com',
      'webcity.ca',
      'webindia123.com',
      'webmail.co.za',
      'webmail.hu',
      'webmails.com',
      'webname.com',
      'webstation.com',
      'websurfer.co.za',
      'webtopmail.com',
      'weedmail.com',
      'weekmail.com',
      'weekonline.com',
      'wegas.ru',
      'wehshee.com',
      'weibsvolk.de',
      'weibsvolk.org',
      'weinenvorglueck.de',
      'welsh-lady.com',
      'westnet.com.au',
      'whale-mail.com',
      'whartontx.com',
      'wheelweb.com',
      'whipmail.com',
      'whoever.com',
      'wideopenwest.com',
      'wildmail.com',
      'will-hier-weg.de',
      'windowslive.com',
      'windrivers.net',
      'windstream.net',
      'wingnutz.com',
      'winning.com',
      'wir-haben-nachwuchs.de',
      'wir-sind-cool.org',
      'wirsindcool.de',
      'witty.com',
      'wiz.cc',
      'wkbwmail.com',
      'wo.com.cn',
      'woh.rr.com',
      'wolf-web.com',
      'wolke7.net',
      'wombles.com',
      'women-at-work.org',
      'wongfaye.com',
      'wooow.it',
      'worker.com',
      'workmail.com',
      'worldemail.com',
      'worldnet.att.net',
      'wormseo.cn',
      'wosaddict.com',
      'wowgirl.com',
      'wowmail.com',
      'wowway.com',
      'wp.pl',
      'wptamail.com',
      'wrexham.net',
      'writeme.com',
      'writemeback.com',
      'wrongmail.com',
      'wtvhmail.com',
      'www.com',
      'www2000.net',
      'wx88.net',
      'wxs.net',
      'x-mail.net',
      'x-networks.net',
      'x5g.com',
      'xmastime.com',
      'xoom.com',
      'xpressmail.zzn.com',
      'xsecurity.org',
      'xsmail.com',
      'xtra.co.nz',
      'xuno.com',
      'y7mail.com',
      'ya.ru',
      'yada-yada.com',
      'yaho.com',
      'yahoo.ae',
      'yahoo.at',
      'yahoo.be',
      'yahoo.ca',
      'yahoo.ch',
      'yahoo.cn',
      'yahoo.co.id',
      'yahoo.co.il',
      'yahoo.co.in',
      'yahoo.co.jp',
      'yahoo.co.kr',
      'yahoo.co.nz',
      'yahoo.co.th',
      'yahoo.co.uk',
      'yahoo.co.za',
      'yahoo.com',
      'yahoo.com.ar',
      'yahoo.com.au',
      'yahoo.com.br',
      'yahoo.com.cn',
      'yahoo.com.co',
      'yahoo.com.hk',
      'yahoo.com.mx',
      'yahoo.com.my',
      'yahoo.com.ph',
      'yahoo.com.ru',
      'yahoo.com.sg',
      'yahoo.com.tr',
      'yahoo.com.tw',
      'yahoo.com.vn',
      'yahoo.cz',
      'yahoo.de',
      'yahoo.dk',
      'yahoo.es',
      'yahoo.fi',
      'yahoo.fr',
      'yahoo.gr',
      'yahoo.hu',
      'yahoo.ie',
      'yahoo.in',
      'yahoo.it',
      'yahoo.jp',
      'yahoo.nl',
      'yahoo.no',
      'yahoo.pl',
      'yahoo.pt',
      'yahoo.ro',
      'yahoo.ru',
      'yahoo.se',
      'yahoofs.com',
      'yalla.com',
      'yalla.com.lb',
      'yalook.com',
      'yam.com',
      'yandex.com',
      'yandex.ru',
      'yawmail.com',
      'yeah.net',
      'yebox.com',
      'yepmail.net',
      'yesey.net',
      'ymail.com',
      'yogotemail.com',
      'yopolis.com',
      'yopweb.com',
      'youareadork.com',
      'youmailr.com',
      'your-mail.com',
      'yourname.freeservers.com',
      'yournightmare.com',
      'yours.com',
      'yoursubdomain.zzn.com',
      'yourteacher.net',
      'yourwap.com',
      'yuuhuu.net',
      'yyhmail.com',
      'zahadum.com',
      'zeepost.nl',
      'zhaowei.net',
      'zionweb.org',
      'zip.net',
      'zipido.com',
      'ziplip.com',
      'zipmail.com',
      'zipmail.com.br',
      'zipmax.com',
      'zmail.ru',
      'zoho.com',
      'zonnet.nl',
      'zoominternet.net',
      'zubee.com',
      'zuvio.com',
      'zuzzurello.com',
      'zwallet.com',
      'zweb.in',
      'zybermail.com',
      'zzn.com'
    ],

    disposableEmailServices: [
      '0815.ru',
      '0clickemail.com',
      '0wnd.net',
      '0wnd.org',
      '10minutemail.com',
      '20minutemail.com',
      '21cn.com',
      '2prong.com',
      '30minutemail.com',
      '4warding.com',
      '9ox.net',
      'a-bc.net',
      'afrobacon.com',
      'amilegit.com',
      'amiri.net',
      'amiriindustries.com',
      'anonymbox.com',
      'antichef.com',
      'antichef.net',
      'antispam.de',
      'baxomale.ht.cx',
      'beefmilk.com',
      'binkmail.com',
      'bio-muesli.net',
      'bobmail.info',
      'bofthew.com',
      'brefmail.com',
      'bsnow.net',
      'bspamfree.org',
      'bugmenot.com',
      'casualdx.com',
      'centermail.com',
      'centermail.net',
      'chogmail.com',
      'choicemail1.com',
      'cool.fr.nf',
      'courriel.fr.nf',
      'courrieltemporaire.com',
      'cubiclink.com',
      'dacoolest.com',
      'dandikmail.com',
      'dayrep.com',
      'dcemail.com',
      'deadaddress.com',
      'deadspam.com',
      'despam.it',
      'despammed.com',
      'devnullmail.com',
      'dfgh.net',
      'digitalsanctuary.com',
      'dingbone.com',
      'discardmail.com',
      'discardmail.de',
      'disposableaddress.com',
      'dispostable.com',
      'dodgeit.com',
      'dodgit.com',
      'donemail.ru',
      'dontreg.com',
      'dontsendmespam.de',
      'dump-email.info',
      'dumpandjunk.com',
      'e-mail.com',
      'e-mail.org',
      'e4ward.com',
      'email60.com',
      'emailias.com',
      'emailmiser.com',
      'emailsensei.com',
      'emailtemporanea.net',
      'emailtemporario.com.br',
      'emailwarden.com',
      'emailx.at.hm',
      'emailxfer.com',
      'emz.net',
      'explodemail.com',
      'fakeinbox.com',
      'fakeinformation.com',
      'fantasymail.de',
      'filzmail.com',
      'frapmail.com',
      'fuckingduh.com',
      'fudgerub.com',
      'garliclife.com',
      'get2mail.fr',
      'getonemail.com',
      'girlsundertheinfluence.com',
      'gishpuppy.com',
      'great-host.in',
      'greensloth.com',
      'gsrv.co.uk',
      'guerillamail.biz',
      'guerillamail.com',
      'guerrillamail.biz',
      'guerrillamail.com',
      'guerrillamail.de',
      'guerrillamail.info',
      'guerrillamail.net',
      'guerrillamail.org',
      'guerrillamailblock.com',
      'haltospam.com',
      'hatespam.org',
      'hidemail.de',
      'hmamail.com',
      'hochsitze.com',
      'hulapla.de',
      'imails.info',
      'inboxclean.com',
      'inboxclean.org',
      'irish2me.com',
      'iwi.net',
      'jetable.com',
      'jetable.fr.nf',
      'jetable.net',
      'jetable.org',
      'kasmail.com',
      'kaspop.com',
      'killmail.com',
      'killmail.net',
      'klassmaster.com',
      'klzlk.com',
      'kurzepost.de',
      'lhsdv.com',
      'lifebyfood.com',
      'link2mail.net',
      'lol.ovpn.to',
      'lookugly.com',
      'lortemail.dk',
      'lr78.com',
      'mail-temporaire.fr',
      'mail.mezimages.net',
      'mail333.com',
      'mailbidon.com',
      'mailblocks.com',
      'mailbucket.org',
      'mailcatch.com',
      'mailexpire.com',
      'mailfreeonline.com',
      'mailin8r.com',
      'mailinater.com',
      'mailinator.com',
      'mailinator.net',
      'mailinator2.com',
      'mailincubator.com',
      'mailme.ir',
      'mailme.lv',
      'mailmetrash.com',
      'mailmoat.com',
      'mailnesia.com',
      'mailnull.com',
      'mailscrap.com',
      'mailshell.com',
      'mailsiphon.com',
      'mailtrash.net',
      'mailzilla.com',
      'makemetheking.com',
      'mbx.cc',
      'mega.zik.dj',
      'meinspamschutz.de',
      'meltmail.com',
      'messagebeamer.de',
      'mintemail.com',
      'moncourrier.fr.nf',
      'monemail.fr.nf',
      'monmail.fr.nf',
      'mt2009.com',
      'mycleaninbox.net',
      'mymail-in.net',
      'mypartyclip.de',
      'myphantomemail.com',
      'mytempemail.com',
      'mytrashmail.com',
      'neomailbox.com',
      'nepwk.com',
      'nervmich.net',
      'nervtmich.net',
      'netmails.com',
      'netmails.net',
      'neverbox.com',
      'no-spam.ws',
      'nogmailspam.info',
      'nomail.xl.cx',
      'nomail2me.com',
      'nomorespamemails.com',
      'nospam.ze.tc',
      'nospam4.us',
      'nospammail.net',
      'notmailinator.com',
      'notsharingmy.info',
      'nowmymail.com',
      'nurfuerspam.de',
      'objectmail.com',
      'obobbo.com',
      'oneoffemail.com',
      'onewaymail.com',
      'oopi.org',
      'ordinaryamerican.net',
      'otherinbox.com',
      'ovpn.to',
      'owlpic.com',
      'pancakemail.com',
      'politikerclub.de',
      'poofy.org',
      'pookmail.com',
      'privacy.net',
      'proxymail.eu',
      'prtnx.com',
      'putthisinyourspamdatabase.com',
      'qq.com',
      'quickinbox.com',
      'rcpt.at',
      'recode.me',
      'recursor.net',
      'rtrtr.com',
      's0ny.net',
      'safe-mail.net',
      'safersignup.de',
      'safetymail.info',
      'safetypost.de',
      'senseless-entertainment.com',
      'sharklasers.com',
      'shiftmail.com',
      'shitmail.me',
      'shortmail.net',
      'sibmail.com',
      'slaskpost.se',
      'smellfear.com',
      'snakemail.com',
      'sneakemail.com',
      'sofimail.com',
      'sogetthis.com',
      'soodonims.com',
      'spambob.net',
      'spambog.com',
      'spambog.de',
      'spambog.ru',
      'spambox.info',
      'spambox.us',
      'spamcannon.com',
      'spamcannon.net',
      'spamcon.org',
      'spamcorptastic.com',
      'spamcowboy.com',
      'spamcowboy.net',
      'spamcowboy.org',
      'spamday.com',
      'spamex.com',
      'spamfree.eu',
      'spamfree24.com',
      'spamfree24.de',
      'spamfree24.org',
      'spamgourmet.com',
      'spamgourmet.net',
      'spamgourmet.org',
      'spamhereplease.com',
      'spamhole.com',
      'spamify.com',
      'spaml.de',
      'spammotel.com',
      'spamobox.com',
      'spamslicer.com',
      'spamspot.com',
      'spamthis.co.uk',
      'speed.1s.fr',
      'super-auswahl.de',
      'supergreatmail.com',
      'supermailer.jp',
      'suremail.info',
      'teewars.org',
      'teleworm.com',
      'temp-mail.org',
      'tempe-mail.com',
      'tempemail.com',
      'tempemail.net',
      'tempinbox.co.uk',
      'tempinbox.com',
      'tempmail.it',
      'tempomail.fr',
      'temporaryforwarding.com',
      'temporaryinbox.com',
      'thankyou2010.com',
      'thisisnotmyrealemail.com',
      'throwawayemailaddress.com',
      'tilien.com',
      'tmailinator.com',
      'tradermail.info',
      'trash-mail.at',
      'trash-mail.com',
      'trash-mail.de',
      'trash2009.com',
      'trashdevil.com',
      'trashemail.de',
      'trashmail.at',
      'trashmail.com',
      'trashmail.de',
      'trashmail.me',
      'trashmail.net',
      'trashmail.org',
      'trashmailer.com',
      'trashymail.com',
      'trbvm.com',
      'trillianpro.com',
      'twinmail.de',
      'tyldd.com',
      'uggsrock.com',
      'upliftnow.com',
      'venompen.com',
      'wegwerfadresse.de',
      'wegwerfemail.com',
      'wegwerfemail.de',
      'wegwerfmail.de',
      'wegwerfmail.net',
      'wegwerfmail.org',
      'wh4f.org',
      'whyspam.me',
      'willhackforfood.biz',
      'willselfdestruct.com',
      'winemaven.info',
      'wronghead.com',
      'wwwnew.eu',
      'xemaps.com',
      'xents.com',
      'xmaily.com',
      'xoxy.net',
      'yep.it',
      'yogamaven.com',
      'yopmail.com',
      'yopmail.fr',
      'yopmail.net',
      'yuurok.com',
      'zehnminutenmail.de',
      'zippymail.info',
      'zoemail.net'
    ]
  }

  return EmailUtils
})
