import * as moment from 'moment'
import { Component, Input } from '@angular/core'
import {
  CardInputChangeEvent,
  CardToggleMenuGroup,
  CardToggleMenuItem,
} from 'modules/insights/dashboard/cards/models/insights-card.model'
import {
  DashboardCardId,
  DashboardDateRangeTimeUnit,
  DashboardTabId,
  DashboardType,
  DateRangeChartData,
  InsightsDashboardParameters,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { TranslateService } from '@ngx-translate/core'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import { InsightsDashboardComponentBaseDirective } from 'modules/insights/dashboard/components/insights-dashboard-component-base'
import {
  ProjectGroupAssetsSafetyTableData,
  ProjectGroupSingleValueDashboardData,
} from 'modules/insights/dashboard/models/insights-dashboard-project-group-assets.model'
import { InsightsDashboardProjectGroupAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-project-group-assets.service'
import { ProjectGroupAssetsSafetyTableComponent } from 'modules/insights/dashboard/components/totals-table/project-group-assets/project-group-assets-safety-table.component'
import { chartHasAllNullValues, convertNullValuesToZero } from '../../../../../utils/dashboard-utils'

@Component({
  selector: 'sv-project-group-assets-insights-dashboard-safety',
  templateUrl: 'project-group-assets-insights-dashboard-safety.component.html',
  styleUrls: ['project-group-assets-insights-dashboard-safety.component.scss'],
})
export class ProjectGroupAssetsInsightsDashboardSafetyComponent extends InsightsDashboardComponentBaseDirective {
  private _projectGroupSingleValueData: ProjectGroupSingleValueDashboardData

  safetyTableData: ProjectGroupAssetsSafetyTableData[]
  projectGroupPeopleInGroupsOverTimeData: DateRangeChartData
  peopleInGroupsOverTimeChartMenu: CardToggleMenuGroup[]
  projectGroupPpeComplianceOverTimeData: DateRangeChartData
  laddersToLiftsRatioPercentChangeClassName: string
  projectGroupWorkAtHeightsOverTimeData: DateRangeChartData
  projectGroupWorkAtHeightsPerXAssetsData: DateRangeChartData
  projectGroupLaddersPerXAssetsData: DateRangeChartData
  projectGroupLiftsPerXAssetsData: DateRangeChartData
  projectGroupHousekeepingAndStandingWaterData: DateRangeChartData
  projectGroupSlipAndTripTrendOverTimeData: DateRangeChartData
  peoplePerXConstructionPercentChangeClassName: string
  chartHasAllNullValues = chartHasAllNullValues
  convertNullValuesToZero = convertNullValuesToZero

  @Input() set projectGroupSingleValueData(value: ProjectGroupSingleValueDashboardData) {
    this._projectGroupSingleValueData = value
    if (this._projectGroupSingleValueData) {
      this.laddersToLiftsRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectGroupSingleValueData.laddersToLiftsRatioAvgPercentChange
      )
      this.peoplePerXConstructionPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectGroupSingleValueData.peoplePerXConstructionAvgPercentChange
      )
    }
  }
  get projectGroupSingleValueData() {
    return this._projectGroupSingleValueData
  }

  constructor(
    protected insightsDataHelper: InsightsDataHelper,
    protected translate: TranslateService,
    protected insightsDashboardConfigurationService: InsightsDashboardConfigurationService,
    private insightsDashboardProjectGroupAssetsService: InsightsDashboardProjectGroupAssetsService
  ) {
    super(
      insightsDataHelper,
      insightsDashboardConfigurationService,
      translate,
      DashboardType.PROJECT_GROUP_ASSETS_DASHBOARD
    )
  }

  peopleInGroupsOverTimeChartMenuSelected($event: CardToggleMenuItem) {
    this.loadProjectPeopleInGroupsOverTimeBySourceData($event.eventId)
  }

  safetyTableFilterChanged(
    $event: CardInputChangeEvent,
    projectGroupAssetsSafetyTable: ProjectGroupAssetsSafetyTableComponent
  ) {
    if (!$event.value || !$event.value.trim()) {
      projectGroupAssetsSafetyTable.clearFilter()
      return
    }

    projectGroupAssetsSafetyTable.applyFilter({
      fieldName: 'name',
      filter: $event.value,
    })
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // the ()=> function is needed to capture the correct 'this' reference
  protected refresh() {
    this.initializeMenuItems()

    this.guardFor(
      () => this.loadSafetyTableData(),
      DashboardTabId.SAFETY,
      DashboardCardId.PROJECT_GROUP_ASSETS_SAFETY_BY_PROJECT
    )
    this.guardFor(
      () => this.loadProjectPeopleInGroupsOverTimeBySourceData(DashboardDateRangeTimeUnit.LAST_1),
      DashboardTabId.SAFETY,
      DashboardCardId.PROJECT_GROUP_ASSETS_PEOPLE_IN_GROUPS
    )
    this.guardFor(
      () => this.loadPpeComplianceTrendOverTimeData(),
      DashboardTabId.SAFETY,
      DashboardCardId.PROJECT_GROUP_ASSETS_PPE_COMPLIANCE_TREND_AVG
    )
    this.guardFor(
      () => this.loadWorkAtHeightsTrendOverTimeData(),
      DashboardTabId.SAFETY,
      DashboardCardId.PROJECT_GROUP_ASSETS_WORK_AT_HEIGHTS_TREND_AVG
    )
    this.guardFor(
      () => this.loadWorkAtHeightsPerXAssetsData(),
      DashboardTabId.SAFETY,
      DashboardCardId.PROJECT_GROUP_ASSETS_WORK_AT_HEIGHT_PER_X_ASSETS_AVG
    )
    this.guardFor(
      () => this.loadLaddersPerXAssetsData(),
      DashboardTabId.SAFETY,
      DashboardCardId.PROJECT_GROUP_ASSETS_LADDERS_PER_X_ASSETS_AVG
    )
    this.guardFor(
      () => this.loadLiftsPerXAssetsData(),
      DashboardTabId.SAFETY,
      DashboardCardId.PROJECT_GROUP_ASSETS_LIFTS_PER_X_ASSETS_AVG
    )
    this.guardFor(
      () => this.loadHousekeepingAndStandingWaterData(),
      DashboardTabId.SAFETY,
      DashboardCardId.PROJECT_GROUP_ASSETS_HOUSEKEEPING_AND_STANDING_WATER_AVG
    )
    this.guardFor(
      () => this.loadSlipAndTripTrendOverTimeData(),
      DashboardTabId.SAFETY,
      DashboardCardId.PROJECT_GROUP_ASSETS_SLIP_AND_TRIP_TREND_AVG
    )
  }

  private initializeMenuItems() {
    this.peopleInGroupsOverTimeChartMenu = this.createChartDateSwitchMenuItems(
      'PEOPLE_IN_GROUP_MENU',
      'projectGroupPeopleInGroupsOverTime'
    )
  }

  private loadSafetyTableData() {
    this.safetyTableData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadProjectGroupSafetyTableData(
        this.currentInsightsDashboardParameters,
        tableData => {
          this.safetyTableData = tableData
        }
      )
    )
  }

  private loadProjectPeopleInGroupsOverTimeBySourceData(chartResolution: DashboardDateRangeTimeUnit) {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeTimeUnit: chartResolution,
    }
    this.projectGroupPeopleInGroupsOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadPeopleInGroupsOverTimeData(dashboardParams, chartData => {
        this.projectGroupPeopleInGroupsOverTimeData = chartData
      })
    )
  }

  private loadPpeComplianceTrendOverTimeData() {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeStart: moment(this.currentInsightsDashboardParameters.dateRangeEnd)
        .subtract(90, 'days')
        .toDate(),
      dateRangeTimeUnit: DashboardDateRangeTimeUnit.LAST_30,
    }
    this.projectGroupPpeComplianceOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadPpeComplianceTrendOverTimeData(dashboardParams, chartData => {
        this.projectGroupPpeComplianceOverTimeData = chartData
      })
    )
  }

  private loadWorkAtHeightsTrendOverTimeData() {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeStart: moment(this.currentInsightsDashboardParameters.dateRangeEnd)
        .subtract(90, 'days')
        .toDate(),
      dateRangeTimeUnit: DashboardDateRangeTimeUnit.LAST_30,
    }
    this.projectGroupWorkAtHeightsOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadWorkAtHeightsTrendOverTimeData(dashboardParams, chartData => {
        this.projectGroupWorkAtHeightsOverTimeData = chartData
      })
    )
  }

  private loadWorkAtHeightsPerXAssetsData() {
    this.projectGroupWorkAtHeightsPerXAssetsData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadWorkAtHeightsPerXAssetsData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectGroupWorkAtHeightsPerXAssetsData = chartData
        }
      )
    )
  }

  private loadLaddersPerXAssetsData() {
    this.projectGroupLaddersPerXAssetsData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadLaddersPerXAssetsData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectGroupLaddersPerXAssetsData = chartData
        }
      )
    )
  }

  private loadLiftsPerXAssetsData() {
    this.projectGroupLiftsPerXAssetsData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadLiftsPerXAssetsData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectGroupLiftsPerXAssetsData = chartData
        }
      )
    )
  }

  private loadHousekeepingAndStandingWaterData() {
    this.projectGroupHousekeepingAndStandingWaterData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadHousekeepingAndStandingWaterData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectGroupHousekeepingAndStandingWaterData = chartData
        }
      )
    )
  }

  private loadSlipAndTripTrendOverTimeData() {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeStart: moment(this.currentInsightsDashboardParameters.dateRangeEnd)
        .subtract(90, 'days')
        .toDate(),
      dateRangeTimeUnit: DashboardDateRangeTimeUnit.LAST_30,
    }
    this.projectGroupSlipAndTripTrendOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadSlipAndTripTrendOverTimeData(dashboardParams, chartData => {
        this.projectGroupSlipAndTripTrendOverTimeData = chartData
      })
    )
  }
}
