<div class="sv-observation-list">
    <mat-toolbar class="sv-mat-toolbar">
        <mat-toolbar-row class="count-quick-filter-row">
            <div class="sv-toolbar-row-contents">
                <span>{{ count$ | async }} {{getHeaderTitle(count$ | async)}}</span>
                <span class="toolbar-spacer"></span>
                <ng-container *ngIf="!(isFilterUsed$ | async)">
                    <ng-container *ngIf="(quickFilters$ | async) as qf">
                        <mat-checkbox [ngModel]="qf.includeHighRisk" (ngModelChange)="applyQuickFilters(qf, {includeHighRisk: $event})" [hidden]="isFilterHidden('risk')">
                            High Risk
                        </mat-checkbox>
                        <mat-checkbox [ngModel]="qf.includePastDue" (ngModelChange)="applyQuickFilters(qf, {includePastDue: $event})" [hidden]="isFilterHidden('dueDate')">
                            Past Due
                        </mat-checkbox>
                        <mat-checkbox [ngModel]="qf.includeMine" (ngModelChange)="applyQuickFilters(qf, {includeMine: $event})" [hidden]="isFilterHidden('assignedUserId')">
                            Assigned To Me
                        </mat-checkbox>
                       <mat-checkbox [ngModel]="qf.includeCreatedByMe" (ngModelChange)="applyQuickFilters(qf, {includeCreatedByMe: $event})" [hidden]="isFilterHidden('creatorUserId')">
                            Created By Me
                        </mat-checkbox>
                        <mat-checkbox [ngModel]="qf.includeClosed" (ngModelChange)="applyQuickFilters(qf, {includeClosed: $event})" [hidden]="isFilterHidden('status')">
                            Show Closed
                        </mat-checkbox>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="isFilterUsed$ | async">
                    <div class="clear-filters-button" (click)="resetFilters()">
                        {{ ('components.observationFilterDialog.clearFilters') | translate }}
                    </div>
                </ng-container>
            </div>
        </mat-toolbar-row>
        <mat-toolbar-row class="filter-row" *ngIf="isFilterUsed$ | async">
            <div class="sv-toolbar-row-contents">
                <ng-container *ngIf="(filters$ | async) as f">
                    <mat-chip-list class="filter-chip-list">
                        <mat-chip [removable]="true" (removed)="removeTypeChip()" *ngIf="f.type">
                            {{ f.type === 'NEGATIVE' ? 'Risk Observations' : 'Positive Observations' }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <ng-container *ngIf="f.statuses && f.statuses.length">
                            <mat-chip [removable]="true" (removed)="removeStatusChip(status)" *ngFor="let status of f.statuses">
                                {{ ('components.observationDialog.status.' + status) | translate }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </ng-container>
                        <ng-container *ngIf="f.riskTypes && f.riskTypes.length">
                            <mat-chip [removable]="true" *ngFor="let riskType of f.riskTypes" (removed)="removeRiskTypeChip(riskType)">
                                {{ ('components.observationFilterDialog.riskTitle.' + riskType) | translate }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </ng-container>
                        <mat-chip [removable]="true" *ngIf="f.hazardCategory" (removed)="removeHazardCategoryChip()">
                            {{f.hazardCategory}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <mat-chip [removable]="true" *ngIf="f.identificationMethod" (removed)="removeIdentificationMethodChip()">
                            {{f.identificationMethod}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <mat-chip [removable]="true" *ngIf="f.creatorUser" (removed)="removeCreatorUserChip()">
                            {{ getUserDisplayName(f.creatorUser) }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <mat-chip [removable]="true" *ngIf="f.assignedUser" (removed)="removeAssignedUserChip()">
                            {{ getUserDisplayName(f.assignedUser) }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <mat-chip [removable]="true" *ngIf="f.dueDatePeriod" (removed)="removeDueDateChip()">
                            {{ ('components.observationFilterDialog.dueDatePeriod.' + f.dueDatePeriod) | translate }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <mat-chip [removable]="true" *ngIf="f.tradePartner" (removed)="removeTradePartnerChip()">
                            {{ f.tradePartner.name }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </ng-container>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <div *ngIf="isDataEmpty$ | async" class="sv-list-empty">
        <div class="sv-list-empty-wrapper">
            <mat-icon svgIcon="empty_observation_list" class="sv-list-empty-svg"></mat-icon>
            <div *ngIf="!((isAnyFilterUsed$ | async) || (isSearchUsed$ | async))">
                <h2 class="sv-list-empty-title">{{'components.observationList.emptyList.title' | translate}}</h2>
                <p class="sv-list-empty-desc">{{'components.observationList.emptyList.desc' | translate}}</p>
            </div>
            <div *ngIf="(isAnyFilterUsed$ | async) || (isSearchUsed$ | async)">
                <h2 class="sv-list-empty-title">{{'components.observationList.emptyListWithFilters.title' | translate}}</h2>

                <a *ngIf="(isAnyFilterUsed$ | async) && !(isSearchUsed$ | async)" class="reset-filters-link" (click)="resetFilters()">
                    {{'components.observationList.emptyListWithFilters.resetFilters' | translate}}
                </a>
                <a *ngIf="!(isAnyFilterUsed$ | async) && (isSearchUsed$ | async)" class="reset-filters-link" (click)="resetSearch()">
                    {{'components.observationList.emptyListWithFilters.resetSearch' | translate}}
                </a>
                <a *ngIf="(isAnyFilterUsed$ | async) && (isSearchUsed$ | async)" class="reset-filters-link" (click)="resetSearchAndFilters()">
                    {{'components.observationList.emptyListWithFilters.resetFiltersAndSearch' | translate}}
                </a>
            </div>
        </div>
    </div>
    <cdk-virtual-scroll-viewport [itemSize]="ITEM_SIZE" (scrolledIndexChange)="onScroll()">
        <table class="mat-table sv-mat-table">
            <tbody>
            <ng-container *cdkVirtualFor="let obs of dataSource$ | async; trackBy: observationRowTrackFn">
                <ng-container [ngSwitch]="getRowTemplate(obs)">
                    <tr *ngSwitchCase="'obsGroup'" class="mat-row sv-group-row">
                        <td colspan="4" class="mat-cell sv-obs-group-name">
                            {{  getGroupTitle(obs) }}
                        </td>
                        <td class="mat-cell sv-group-count">
                            {{obs.count}} {{getGroupCountTitle(obs.count)}}
                        </td>
                    </tr>
                    <tr *ngSwitchCase="'obsPositive'" class="mat-row sv-obs-row" (click)="viewObservation(obs)">
                        <td class="mat-cell sv-obs-score">
                            <button class="mat-flat-button sv-risk-label sv-risk-positive">
                                <mat-icon>star</mat-icon>
                            </button>
                        </td>
                        <td class="mat-cell sv-obs-name">
                            <div><span class="sv-externalId">{{obs.externalId|obsExtIdPipe}}</span> {{obs.hazardCategory}}</div>
                            <p class="sv-sub-line">
                                <span>{{getCreatedDate(obs)}}</span>
                            </p>
                        </td>
                        <td class="mat-cell sv-obs-project-name">
                            <div>{{obs.projectName}}</div>
                            <p class="sv-sub-line">
                                <span [hidden]="isTradePartnerIdHidden(obs)">{{getPartnerAssigned(obs)}}</span>
                            </p>
                        </td>
                        <td class="mat-cell sv-obs-assignee">
                            <div>{{getAssignee(obs)}}</div>
                            <p class="sv-sub-line">
                                <mat-icon *ngIf="showDuePastIcon(obs)" class="sv-due-past-icon"
                                          [hidden]="isDueDateHidden(obs)">alarm</mat-icon>
                                <span [hidden]="isDueDateHidden(obs)">{{getDueDate(obs)}}</span>
                            </p>
                        </td>
                        <td class="mat-cell sv-obs-status">
                            <span class="mat-flat-button sv-status-label sv-status-{{getStatusStyle(obs)}}"
                                  [hidden]="isStatusHidden(obs)">
                                {{getStatus(obs)}}
                            </span>
                        </td>
                    </tr>
                    <tr *ngSwitchCase="'obsNegative'" class="mat-row sv-obs-row" (click)="viewObservation(obs)">
                        <td class="mat-cell sv-obs-score">
                            <button class="mat-flat-button sv-risk-label sv-risk-{{obs.riskType.toLowerCase()}}">
                                {{ getRisk(obs) }}
                            </button>
                        </td>
                        <td class="mat-cell sv-obs-name">
                            <div><span class="sv-externalId">{{obs.externalId|obsExtIdPipe}}</span> {{obs.hazardCategory}}</div>
                            <p class="sv-sub-line">
                                <span>{{getCreatedDate(obs)}}</span>
                            </p>
                        </td>
                        <td class="mat-cell sv-obs-project-name">
                            <div>{{obs.projectName}}</div>
                            <p class="sv-sub-line">
                                <span [hidden]="isTradePartnerIdHidden(obs)">{{getPartnerAssigned(obs)}}</span>
                            </p>
                        </td>
                        <td class="mat-cell sv-obs-assignee">
                            <div [hidden]="isAssignedUserIdHidden(obs)">{{getAssignee(obs)}}</div>
                            <p class="sv-sub-line" [hidden]="isDueDateHidden(obs)">
                                <mat-icon *ngIf="showDuePastIcon(obs)" class="sv-due-past-icon"
                                          [hidden]="isDueDateHidden(obs)">alarm</mat-icon>
                                <span>{{getDueDate(obs)}}</span>
                            </p>
                        </td>
                        <td class="mat-cell sv-obs-status">
                            <span class="mat-flat-button sv-status-label sv-status-{{getStatusStyle(obs)}}"
                                *ngIf="!isStatusHidden(obs)">
                                {{getStatus(obs)}}
                            </span>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <tr *ngIf="isLoading$ | async" class="mat-row sv-loading-row">
                <td class="mat-cell sv-obs-score">
                    <mat-spinner strokeWidth="2" [diameter]="24"></mat-spinner>
                </td>
                <td colspan="4" class="mat-cell sv-obs-group-name">{{ 'common.loading' | translate }}</td>
            </tr>
            <tr class="mat-row sv-empty-row">
                <td class="mat-cell">
                    <div></div>
                </td>
            </tr>
            </tbody>
        </table>
    </cdk-virtual-scroll-viewport>
</div>
