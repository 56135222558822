/* global angular _ */

angular.module('smartvid').factory('UserProjectSettings', function (BaseModel) {
  class UserProjectSettings extends BaseModel {
    constructor (attrs) {
      let defaults = {
        projectId: undefined,
        isIncludeTimestampDownload: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return UserProjectSettings
})
