<div class="content" *ngIf="isInitialized()">
  <sv-dialog-header [onClose]="onClose.bind(this)" [title]="getDialogTitle()"></sv-dialog-header>

  <sv-observation-risk-header [riskType]="riskType" [riskScore]="riskScore" [riskHidden]="isFieldHidden(ObservationPredefinedFields.RISK)"></sv-observation-risk-header>

  <div mat-dialog-content class="observation-edit-panel" cdkScrollable [ngClass]="{'disabled':isEdit() && isObservationProjectInactive()}">

    <form [formGroup]="observationForm">
      <div>
        <mat-icon class="mat-icon-prefix">folder</mat-icon>
        <mat-form-field appearance="fill" class="organization-field">
          <mat-label>{{ 'components.observationDialog.organizationTitle' | translate }}</mat-label>
          <mat-select formControlName="organizationFormControl" required [disabled]="isEdit()">
            <mat-select-trigger>
              {{ observationForm.controls.organizationFormControl.value ? observationForm.controls.organizationFormControl.value.name : '' }}
            </mat-select-trigger>
            <mat-option *ngFor="let organization of organizations" [value]="organization">
              {{ organization.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <span class="organization-project-spacer">&nbsp;</span>

        <mat-form-field appearance="fill" class="project-field" [ngClass]="{'disabled':isEdit()}">
          <mat-label>{{ 'components.observationDialog.projectTitle' | translate }}</mat-label>
          <input type="text"
                 required
                 formControlName="projectFormControl"
                 [readonly]="isEdit()"
                 placeholder="{{ 'components.observationDialog.projectTitle' | translate }}"
                 matInput
                 [matAutocomplete]="projectAuto">
          <mat-autocomplete #projectAuto="matAutocomplete" [displayWith]="displayProject">
            <mat-option *ngFor="let project of filteredProjects" [value]="project">
              {{project.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div *ngIf="!isFieldHidden(ObservationPredefinedFields.STATUS)">
        <mat-icon class="mat-icon-prefix">turned_in</mat-icon>
        <mat-form-field appearance="fill" class="status-field">
          <mat-label>{{ getFieldLabel(ObservationPredefinedFields.STATUS) }}</mat-label>
          <mat-select formControlName="statusFormControl"
          [required]="isFieldRequired(ObservationPredefinedFields.STATUS)"
          [disabled]="!isProjectSelected">
            <mat-select-trigger>
              <mat-icon
                      *ngIf="observationForm.controls.statusFormControl.value && observationForm.controls.statusFormControl.value !== 'NOT_APPLICABLE'"
                      svgIcon="status_{{ observationForm.controls.statusFormControl.value.toLowerCase() }}"></mat-icon>
              {{ ('components.observationDialog.status.' + observationForm.controls.statusFormControl.value) | translate }}
            </mat-select-trigger>
            <mat-option *ngIf="statuses.length == 0" [value]="'status'">
              <mat-spinner strokeWidth="1" [diameter]="16"></mat-spinner>
              {{ 'common.loading' | translate }}
            </mat-option>
            <mat-option *ngFor="let status of statuses" [value]="status">
              <mat-icon svgIcon="status_{{ status.toLowerCase() }}"></mat-icon>
              {{ ('components.observationDialog.status.' + status) | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="!isFieldHidden(ObservationPredefinedFields.HAZARD_CATEGORY)">
        <mat-icon class="mat-icon-prefix">label</mat-icon>
        <mat-form-field appearance="fill">
          <mat-label>{{ getFieldLabel(ObservationPredefinedFields.HAZARD_CATEGORY) }}</mat-label>
          <mat-select formControlName="hazardCategoryFormControl"
          [required]="isFieldRequired(ObservationPredefinedFields.HAZARD_CATEGORY)"
          [disabled]="!isProjectSelected">
            <mat-select-trigger>
              {{ observationForm.controls.hazardCategoryFormControl.value ? observationForm.controls.hazardCategoryFormControl.value.label : '' }}
            </mat-select-trigger>
            <mat-option *ngFor="let hazardCategory of hazardCategories" [value]="hazardCategory">
              {{ hazardCategory.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="!isFieldHidden(ObservationPredefinedFields.IDENTIFICATION_METHOD)">
        <mat-icon class="mat-icon-prefix">remove_red_eye</mat-icon>
        <mat-form-field appearance="fill">
          <mat-label>{{ getFieldLabel(ObservationPredefinedFields.IDENTIFICATION_METHOD) }}</mat-label>
          <mat-select formControlName="identificationMethodFormControl"
          [required]="isFieldRequired(ObservationPredefinedFields.IDENTIFICATION_METHOD)"
          [disabled]="!isProjectSelected">
            <mat-select-trigger>
              {{ observationForm.controls.identificationMethodFormControl.value ? observationForm.controls.identificationMethodFormControl.value.label : '' }}
            </mat-select-trigger>
            <mat-option *ngFor="let identificationMethod of identificationMethods" [value]="identificationMethod">
              {{ identificationMethod.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="!isFieldHidden(ObservationPredefinedFields.TRADE_PARTNER_ID)">
        <mat-icon class="mat-icon-prefix">people</mat-icon>
        <mat-form-field appearance="fill">
          <mat-label>{{ getFieldLabel(ObservationPredefinedFields.TRADE_PARTNER_ID) }}</mat-label>
          <input type="text"
                 placeholder="{{ 'components.observationDialog.tradePartnerPlacefolder' | translate }}"
                 aria-label="Number"
                 matInput
                 [required]="isFieldRequired(ObservationPredefinedFields.TRADE_PARTNER_ID)"
                 [readonly]="!isProjectSelected"
                 formControlName="tradePartnerFormControl"
                 [matAutocomplete]="tradePartnerAuto">
          <mat-autocomplete #tradePartnerAuto="matAutocomplete" [displayWith]="displayTradePartner">
            <mat-option *ngFor="let tp of filteredTradePartners" [value]="tp">
              {{tp.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div *ngIf="!isFieldHidden(ObservationPredefinedFields.NOTES)">
        <mat-icon class="mat-icon-prefix" >assignment</mat-icon>
        <mat-form-field appearance="fill">
          <mat-label>{{ getFieldLabel(ObservationPredefinedFields.NOTES) }}</mat-label>
          <textarea rows="5"
                    matInput
                    [required]="isFieldRequired(ObservationPredefinedFields.NOTES)"
                    [readonly]="!isProjectSelected"
                    formControlName="observationNoteControl"
                    placeholder="{{ 'components.observationDialog.observationNotePlacefolder' | translate }}"></textarea>
        </mat-form-field>
      </div>

      <div *ngIf="!isFieldHidden(ObservationPredefinedFields.ASSETS)">
        <mat-icon class="mat-icon-prefix">photo_library</mat-icon>
        <mat-label>{{ getFieldLabel(ObservationPredefinedFields.ASSETS) }}</mat-label>
        <div  ng2FileDrop
             [ngClass]="{'file-over': hasAnotherDropZoneOver, 'disabled': dropZoneDisabled()}"
             [uploader]="uploader"
             (fileOver)="fileOverAnother($event)"
             class="image-drop-zone">
            <input #fileInput type="file" ng2FileSelect [uploader]="uploader"
                   multiple accept="{{allowedFileExtensions}}" style="display: none">
            <div class="dropzone" >
                <div class="container">

                    <p>
                      <span class="max-files-msg">Upload up to {{uploadFilesLimit}} files</span><br/>
                      Drag and drop your files here or
                        <span (click)="fileInput.click()">select files</span>
                    </p>
                    <div *ngFor="let item of uploader.queue" class="file-container" [ngClass]="{'done' : item.isUploaded}">
                            <div class="btn-container">
                                <button type="button"
                                        appearance="fill"
                                        mat-button [disableRipple]="true"
                                        (click)="onRemoveFromQueue($event, item)">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </div>
                        <div class="file-name-container">
                            <span>{{ item?.file?.name }}</span>
                        </div>
                      <mat-progress-bar class="file-item" mode="determinate"
                                        [hidden]="!item.isUploading"
                                        [value]="item.progress"></mat-progress-bar>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div *ngIf="!isFieldHidden(ObservationPredefinedFields.SEVERITY)">
        <mat-icon class="mat-icon-prefix">flash_on</mat-icon>
        <mat-form-field appearance="fill">
          <mat-label>{{ getFieldLabel(ObservationPredefinedFields.SEVERITY) }}</mat-label>
          <mat-select formControlName="severityFormControl"
          [required]="isFieldRequired(ObservationPredefinedFields.SEVERITY)"
          [disabled]="!isProjectSelected">
            <mat-select-trigger>
              <mat-icon *ngIf="observationForm.controls.severityFormControl.value"
                      svgIcon="observation_severity_{{ observationForm.controls.severityFormControl.value ? observationForm.controls.severityFormControl.value.value : '' }}"></mat-icon>
              {{ observationForm.controls.severityFormControl.value ? observationForm.controls.severityFormControl.value.label : '' }}
            </mat-select-trigger>
            <mat-option *ngFor="let severity of severities" [value]="severity">
              <mat-icon svgIcon="observation_severity_{{ severity.value }}"></mat-icon>
              {{ severity.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="!isFieldHidden(ObservationPredefinedFields.FREQUENCY)">
        <mat-icon class="mat-icon-prefix">update</mat-icon>
        <mat-form-field appearance="fill">
          <mat-label>{{ getFieldLabel(ObservationPredefinedFields.FREQUENCY) }}</mat-label>
          <mat-select formControlName="frequencyFormControl"
          [required]="isFieldRequired(ObservationPredefinedFields.FREQUENCY)"
          [disabled]="!isProjectSelected">
            <mat-select-trigger>
              <mat-icon *ngIf="observationForm.controls.frequencyFormControl.value"
                      svgIcon="observation_frequency_{{ observationForm.controls.frequencyFormControl.value ? observationForm.controls.frequencyFormControl.value.value : '' }}"></mat-icon>
              {{ observationForm.controls.frequencyFormControl.value ? observationForm.controls.frequencyFormControl.value.label : '' }}
            </mat-select-trigger>
            <mat-option *ngFor="let frequency of frequencies" [value]="frequency">
              <mat-icon svgIcon="observation_frequency_{{ frequency.value }}"></mat-icon>
              {{ frequency.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="!isFieldHidden(ObservationPredefinedFields.RECOMMENDED_ACTION)">
        <mat-icon class="mat-icon-prefix">assignment_late</mat-icon>
        <mat-form-field appearance="fill">
          <mat-label>{{ getFieldLabel(ObservationPredefinedFields.RECOMMENDED_ACTION) }}</mat-label>
          <textarea rows="5"
                    matInput
                    [required]="isFieldRequired(ObservationPredefinedFields.RECOMMENDED_ACTION)"
                    [readonly]="!isProjectSelected"
                    formControlName="recommendedActionControl"
                    placeholder="{{ 'components.observationDialog.recommendedActionPlaceholder' | translate }}"></textarea>
        </mat-form-field>
      </div>

      <div *ngIf="!isActionTakenHidden() && !isFieldHidden(ObservationPredefinedFields.ACTION_TAKEN)">
        <mat-icon class="mat-icon-prefix">assignment_turned_in</mat-icon>
        <mat-form-field appearance="fill">
          <mat-label>{{ getFieldLabel(ObservationPredefinedFields.ACTION_TAKEN) }}</mat-label>
          <textarea rows="5"
                    matInput
                    [readonly]="!isProjectSelected"
                    [required]="isFieldRequired(ObservationPredefinedFields.ACTION_TAKEN)"
                    formControlName="actionTakenControl"
                    placeholder="{{ getFieldPlaceholder('actionTaken') }}"></textarea>
        </mat-form-field>
      </div>

      <div *ngIf="!isReviewCommentHidden() && !isFieldHidden(ObservationPredefinedFields.REVIEW_COMMENT)">
        <mat-icon class="mat-icon-prefix">assignment_turned_in</mat-icon>
        <mat-form-field appearance="fill">
          <mat-label>{{ getFieldLabel(ObservationPredefinedFields.REVIEW_COMMENT) }}</mat-label>
          <textarea rows="5"
                    matInput
                    [readonly]="!isProjectSelected"
                    [required]="isFieldRequired(ObservationPredefinedFields.REVIEW_COMMENT)"
                    formControlName="reviewCommentControl"
                    placeholder="{{ getFieldPlaceholder('reviewComment') }}"></textarea>
        </mat-form-field>
      </div>

      <div *ngIf="!isFieldHidden(ObservationPredefinedFields.ASSIGNED_USER_ID)">
        <mat-icon class="mat-icon-prefix">assignment_ind</mat-icon>
        <mat-form-field appearance="fill" class="assignee">
          <mat-label>{{ getFieldLabel(ObservationPredefinedFields.ASSIGNED_USER_ID) }}</mat-label>

          <input type="text"
                 placeholder="{{ 'components.observationDialog.startTypingUserPlaceholder' | translate }}"
                 aria-label="Number"
                 matInput
                 [required]="isFieldRequired(ObservationPredefinedFields.ASSIGNED_USER_ID)"
                 [readonly]="!isProjectSelected"
                 formControlName="assigneeFormControl"
                 [matAutocomplete]="assigneeAuto">
          <mat-autocomplete #assigneeAuto="matAutocomplete" [displayWith]="displayUser">
            <mat-option *ngFor="let user of (filteredUsers | async)" [value]="user">
              {{displayUser(user)}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div *ngIf="!isFieldHidden(ObservationPredefinedFields.DUE_DATE)">
        <mat-icon class="mat-icon-prefix">access_alarm</mat-icon>
        <mat-form-field appearance="fill" class="due-date">
          <mat-label>{{ getFieldLabel(ObservationPredefinedFields.DUE_DATE) }}</mat-label>

          <input matInput [matDatepicker]="picker"
                 [disabled]="!isProjectSelected"
                 [min]="todayDate"
                 [required]="isFieldRequired(ObservationPredefinedFields.DUE_DATE)"
                 placeholder="{{ 'components.observationDialog.dueDatePlaceholder' | translate }}"
                 formControlName="dueDateFormControl">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="!isFieldHidden(ObservationPredefinedFields.IS_GREAT_CATCH)">
        <mat-icon class="mat-icon-prefix">flag</mat-icon>

        <mat-form-field>
          <mat-slide-toggle
                  formControlName="greatCatchControl"
                  color="warn"
                  [required]="isFieldRequired(ObservationPredefinedFields.IS_GREAT_CATCH)"
                  [disabled]="!isProjectSelected"
                  labelPosition="before"
                  checked="greatCatch">
            {{ getFieldLabel(ObservationPredefinedFields.IS_GREAT_CATCH) }}
          </mat-slide-toggle>

          <textarea matInput hidden></textarea>
        </mat-form-field>
      </div>

      <div *ngIf="uiConfiguration">
        <div *ngFor="let customField of uiConfiguration.uiConfigurationData.customFields">
          <mat-icon class="mat-icon-prefix"></mat-icon>

          <!-- TEXT_AREA -->
          <mat-form-field appearance="fill" *ngIf="customField.type === UiConfigurationFieldType[UiConfigurationFieldType.TEXT_AREA]">
            <mat-label>{{ customField.label }}</mat-label>
            <textarea rows="5"
                      matInput
                      [required]="customField.isRequired"
                      formControlName="{{ customField.name }}"></textarea>
          </mat-form-field>

          <!-- DATE_INPUT -->
          <mat-form-field appearance="fill"
                          class="date-custom-field"
                          *ngIf="customField.type === UiConfigurationFieldType[UiConfigurationFieldType.DATE_INPUT]">
              <mat-label>{{ customField.label }}</mat-label>
              <input matInput [matDatepicker]="customPicker"
                     [required]="customField.isRequired"
                     formControlName="{{ customField.name }}">
              <mat-datepicker-toggle
                      matSuffix [for]="customPicker"></mat-datepicker-toggle>
              <mat-datepicker #customPicker></mat-datepicker>
          </mat-form-field>

          <!-- SELECT -->
          <mat-form-field appearance="fill"
                          *ngIf="customField.type === UiConfigurationFieldType[UiConfigurationFieldType.SELECT]">
            <mat-label>{{ customField.label }}</mat-label>
            <mat-select formControlName="{{ customField.name }}"
                        [required]="customField.isRequired">
              <mat-option *ngFor="let listDataItem of customField.listData" [value]="listDataItem.value">
                {{ listDataItem.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- INPUT -->
          <mat-form-field appearance="fill"
                          *ngIf="customField.type === UiConfigurationFieldType[UiConfigurationFieldType.INPUT]">
            <mat-label>{{ customField.label }}</mat-label>
            <input type="text"
                   placeholder="{{ customField.label }}"
                   [required]="customField.isRequired"
                   matInput
                   formControlName="{{ customField.name }}">
          </mat-form-field>

          <!-- CHECKBOX -->
          <mat-form-field
                  *ngIf="customField.type === UiConfigurationFieldType[UiConfigurationFieldType.CHECKBOX]">
            <mat-slide-toggle
                    formControlName="{{ customField.name }}"
                    labelPosition="before">
              {{ customField.label }}
            </mat-slide-toggle>
            <textarea matInput hidden></textarea>
          </mat-form-field>

          <!-- RADIO -->
          <mat-form-field
                  class="mat-form-field-with-mat-radio-group"
                  *ngIf="customField.type === UiConfigurationFieldType[UiConfigurationFieldType.RADIO_BUTTON]">
            <label>{{ customField.label }}</label>
            <mat-radio-group
                    [required]="customField.isRequired"
                    formControlName="{{ customField.name }}">
              <mat-radio-button *ngFor="let listDataItem of customField.listData" value="{{ listDataItem.value }}">
                {{ listDataItem.label }}
              </mat-radio-button>
              <br>
            </mat-radio-group>
            <textarea matInput hidden></textarea>
          </mat-form-field>
        </div>
      </div>

    </form>
  </div>

  <div class="spacer"></div>
  <mat-divider></mat-divider>

  <div mat-dialog-actions>
      <button mat-stroked-button (click)="onClose()">
          {{ 'components.observationDialog.discard' | translate }}
      </button>
      <button mat-flat-button
              (click)="onSaveObservation()"
              [disabled]="!canSave()"
              cdkFocusInitial
              color="primary">
          <span *ngIf="isEdit()">{{ 'components.observationDialog.save' | translate }}</span>
          <span *ngIf="!isEdit()">{{ 'components.observationDialog.create' | translate }}</span>
      </button>
      <div *ngIf="(registeringAssets || isLoading)"> <mat-spinner strokeWidth="2" [diameter]="24"></mat-spinner></div>

  </div>
</div>
