/* global angular,analytics,_ */
angular.module('smartvid').controller('ShareSearchCtrl', function (
    $q, $filter, $timeout, $scope, $state, smartvidApi, utils, currentUser, dashboardDataHelper, searchResultService,
    assetGridHelper
) {
  const SCOPE_DISPLAY_ORDER = ['PROJECT', 'ORGANIZATION', 'USER', 'GLOBAL', 'PUBLIC']
  let contentShareRoleType
  let currentProject = dashboardDataHelper.getCurrentProject()
  let searchContext = searchResultService.getCurrentSearchContext()
  let allSharingRoles = (currentProject) ? currentUser.getSearchSharingRolesForProject(currentProject) : currentUser.getCrossProjectSearchSharingRoles()

  $scope.isCrossProjectSearch = angular.isUndefined(currentProject)
  $scope.shareType = 'SEND_EMAIL_LINK'
  $scope.roleScopes = undefined
  $scope.linkShareRoles = undefined
  $scope.userPoolRoles = undefined
  $scope.linkShareSelectedScope = undefined
  $scope.linkShareSelectedRole = undefined
  $scope.users = []
  $scope.shareMessage = {value: ''}
  $scope.isCopyLinkDisabled = false
  $scope.sharableLink = ''

  $scope.generateNewLink = generateNewLink
  $scope.copyLinkToClipboard = copyLinkToClipboard
  $scope.isSendDisabled = isSendDisabled
  $scope.shareSearch = shareSearch
  $scope.searchByEmail = searchByEmail
  $scope.getAllUsers = getAllUsers

  init()

// //////////////////////////////////////////////////////////////////////////////////////////////////////////

  function init () {
    contentShareRoleType = _.reduce(allSharingRoles, function (map, role) {
      if (!map[role.scope]) {
        map[role.scope] = {}
      }
      map[role.scope][role.permission] = role.name
      return map
    }, {})
    let tempRoleScopes = _.map(_.without(_.uniq(_.pluck(allSharingRoles, 'scope')), 'USER'), function (scope) {
      return {
        type: scope,
        displayName: $filter('i18next')('sharing.role.scope.' + scope)
      }
    })
    $scope.roleScopes = _.sortBy(tempRoleScopes, (s) => {
      return _.indexOf(SCOPE_DISPLAY_ORDER, s.type)
    })
    $scope.linkShareRoles = _.map(_.uniq(_.pluck(allSharingRoles, 'permission')), function (permission) {
      return {
        type: permission,
        displayName: $filter('i18next')('sharing.role.permission.' + permission)
      }
    })
    $scope.userPoolRoles = _.map(_.filter(allSharingRoles, function (role) {
      return role.scope === 'USER'
    }), function (role) {
      return {
        type: role.name,
        displayName: $filter('i18next')('sharing.role.permission.' + role.permission)
      }
    })
    $scope.linkShareSelectedScope = {
      value: $scope.roleScopes[0]
    }

    $scope.linkShareSelectedRole = {
      value: $scope.linkShareRoles[0]
    }

    if ($scope.shareType === 'CREATE_SHARE_LINK') {
      $scope.generateNewLink()
    }
  }

  function getSelectedContentShareRole () {
    return contentShareRoleType[$scope.linkShareSelectedScope.value.type][$scope.linkShareSelectedRole.value.type]
  }

  function shareWith (users) {
    let projectId = (currentProject) ? currentProject.id : undefined
    let createdByUserIds = _.map(searchContext.searchCreatedByUsers, (user) => {
      return user.id
    })
    let gridState = assetGridHelper.readAssetGridState(projectId)
    return smartvidApi.shareSearch(
      projectId, searchContext.searchTags, createdByUserIds,
      searchContext.textFragments,
      gridState,
      users,
      $scope.shareMessage.value,
      searchContext.tagConfidenceLevel,
      searchContext.searchDateRange,
      searchContext.searchQueryType)
  }

  function generateNewLink () {
    $scope.sharableLink = ''
    $scope.isCopyLinkDisabled = true
    let user = {role: {type: getSelectedContentShareRole()}}
    let promise = shareWith([user]).then((response) => {
      $scope.sharableLink = response.shareUrl
      $scope.isCopyLinkDisabled = false
    })
    return promise
  }

  function chooseLinkShare () {
    let linkText = document.querySelector('input[name=sharelink]')
    linkText.focus()
    linkText.setSelectionRange(0, linkText.value.length)
  }

  function copyLinkToClipboard () {
    chooseLinkShare()
    document.execCommand('Copy')
    utils.notify('sharing.linkCopied')
  }

  function isSendDisabled () {
    if ($scope.shareType === 'SEND_EMAIL_LINK') {
      if ($scope.users.length === 0) {
        return true
      }
    }
    return false
  }

  function shareSearch () {
    if (currentProject) {
      analytics.track('Share Search', {
        category: 'Asset Action',
        projectName: currentProject.name,
        orgName: (currentProject.getProjectOrg()) ? currentProject.getProjectOrg().name : undefined
      })
    } else {
      analytics.track('Share Cross Project Search', {
        category: 'Asset Action'
      })
    }
    if ($scope.shareType === 'CREATE_SHARE_LINK') {
      $scope.modal.close()
    } else {
      shareWith($scope.users).then(() => {
        utils.notify('sharing.linkSent')
        $scope.modal.close()
      })
    }
  }

  function searchByEmail (partialEmail) {
    let promises = []
    if (currentProject) {
      let projectPromise = smartvidApi.getUsersByPartialEmailForProject(currentProject.id, partialEmail)
      promises.push(projectPromise)
    }

    let orgIds = _.pluck(_.where(currentUser.organizations, {canManageUsers: true}), 'id')
    promises.push(smartvidApi.getUsersByPartialEmailForOrgs(orgIds, partialEmail))
    return $q.all(promises)
  }

  function getAllUsers () {
    let promises = []
    if (currentProject) {
      let projectPromise = smartvidApi.getProjectUsers(currentProject.id)
      promises.push(projectPromise)
    }
    _.each(currentUser.organizations, (org) => {
      promises.push(smartvidApi.getOrganizationUsers(org.id))
    })
    return $q.all(promises)
  }
})
