import { ViewChild, Directive } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { getColorClassForValue, getSign, isNA, pickClassNameFor } from '../../utils/dashboard-utils'

@Directive()
export abstract class TotalsTableBaseDirective<T> {
  protected _tableData: T[]
  public dataSource: MatTableDataSource<T>
  @ViewChild(MatPaginator, { static: false }) protected paginator: MatPaginator

  getColorClassForValue = getColorClassForValue
  pickClassNameFor = pickClassNameFor
  getSign = getSign
  isNA = isNA

  onMouseWheel(event: WheelEvent) {
    event.preventDefault()
    event.stopPropagation()
    if (event.deltaY > 0) {
      this.paginator.nextPage()
    } else {
      this.paginator.previousPage()
    }
  }

  protected setTableData(value: T[]) {
    this._tableData = value
    this.dataSource = new MatTableDataSource(this._tableData)
    if (value) {
      this.dataSource.paginator = this.paginator
      // eslint-disable-next-line @typescript-eslint/unbound-method
      this.dataSource.filterPredicate = this.filterTable
    }
  }

  protected abstract filterTable(data: T, filterString: string): boolean
}
