import { Injectable, TemplateRef } from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { ComponentType } from '@angular/cdk/portal'
import { FormControl } from '@angular/forms'

export function noWhitespaceValidator(control: FormControl) {
  if (!control.value) {
    return null
  }
  const isWhitespace = control.value.trim().length === 0
  const isValid = !isWhitespace
  return isValid ? null : { whitespace: true }
}

@Injectable({
  providedIn: 'root',
})
export class DialogUtilsService {
  constructor(private dialog: MatDialog) {}

  public open<T, D, R>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: MatDialogConfig<D>
  ): MatDialogRef<T, R> {
    if (config) {
      config.disableClose = true
    }
    return this.dialog.open(componentOrTemplateRef, config)
  }
}
