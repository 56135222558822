/* global angular _ */

angular.module('smartvid').controller('TagManagerOptionsCtrl', function ($scope, $rootScope, $filter, $state, modal, batchJobService, dashboardDataHelper) {
  let project = dashboardDataHelper.getCurrentProject()
  let org = dashboardDataHelper.getCurrentAdminOrganization()
  let tree = $scope.treeModel

  $scope.deleteSelectedTagDefinitions = deleteSelectedTagDefinitions
  $scope.getStyles = getStyles

  function deleteSelectedTagDefinitions () {
    $scope.flyout.isOpen = false
    let selectedEntities = $scope.gridApi.selection.getSelectedRows()
    let isAllSelected = $scope.gridApi.selection.getSelectAllState()
    if (!tree.containsParentsWithProtectedChildren(selectedEntities)) {
      modal.open('deleteConfirm', {
        confirm () {
          let goToStateName = project ? 'dashboard.projects.projectId.tagsmanager' : 'dashboard.adminOrganizations.organizationId.tagsmanager'
          let goToStateParams = {
            projectId: project ? project.id : undefined,
            organizationId: !project ? org.id : undefined,
            isDemoProject: project ? project.isDemoProjectCopy : undefined,
            assetSearchContext: $scope.assetSearchContext
          }
          $rootScope.$broadcast('sv-before-batch-tag-definition-delete-start')
          if (selectedEntities.length !== 0) {
            let nodeIds = _.map(selectedEntities, (entity) => {
              return entity.id
            })
            batchJobService.manageBatchJob(
              !project ? org.id : null,
              project ? project.id : null,
              (isAllSelected) ? tree.deleteAllNodes() : tree.deleteNodes(nodeIds),
              ['sv-all-batch-tag-definition-delete-complete', goToStateName, goToStateParams],
              ['sv-all-batch-tag-definition-delete-failed']
            )
          }
          $scope.flyout.close()
        },
        hasSelections: selectedEntities.length > 0
      })
    } else {
      modal.open('tagDefDeleteNotPermitted')
    }
  }

  function getStyles () {
    return {
      top: $scope.styles.top,
      left: $scope.styles.left,
      width: '190'
    }
  }
})
