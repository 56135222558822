/* global angular */

angular.module('smartvid').service('oauthClientApi', function ($http, $q, $log, config, currentUser) {
  let rootUrl = config.env.development.apiRootUrl

  let api = {
    getOAuthUrlForProvider (provider, userId = null) {
      let url = `${rootUrl}/api/oauth/client/authorization/url/${provider}/user/${userId ? userId : currentUser.id}`
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('getOAuthUrlForProvider', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getOAuthUserDataForProviderForCurrentUser (provider) {
      let url = `${rootUrl}/api/oauth/client/${provider}/user/${currentUser.id}`
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('getOAuthUserDataForProviderForCurrentUser', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    removeOAuthDataForProviderForExternalUserId (provider, externalUserId) {
      let url = `${rootUrl}/api/oauth/client/${provider}/externalUser/${externalUserId}`
      let defer = $q.defer()

      $http.delete(url).then((response) => {
        $log.debug('removeOAuthDataForProviderForExternalUserId', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    }
  }
  return api
})
