/* global angular, _, $ */
/**
 * Created by ryanrubbico on 7/25/17.
 */
angular.module('smartvid').service('normalTagMarkupService', function (TAG_MARKUP_OVERLAY_ID_PREFIX, $timeout,
                                                                       smartvidApi) {
  class NormalTagMarkupService {
    removeTagMarkup (tag, evt, scope) {
      evt.stopPropagation()
      if (scope.isEditable()) {
        tag.markup = undefined
        this.clearTagsMarkups(scope)
        smartvidApi.updateTagForAsset(tag)
      }
    }
    showTagsMarkups (tags, scope) {
      // noinspection ES6ConvertVarToLetConst
      var idCount = 0
      let tagsWithMarkups = _.map(_.filter(tags, (t) => {
        return t.hasMarkup()
      }), (t) => {
        t.markup.id = TAG_MARKUP_OVERLAY_ID_PREFIX + (++idCount)
        t.markup.readyToShow = true
        return t
      })

      if (tagsWithMarkups.length > 0) {
        this.clearTagsMarkups(scope)
        $timeout(() => {
          scope.tagInstancesWithMarkups = tagsWithMarkups
          $timeout(() => {
            _.chain(tagsWithMarkups)
              .map((t) => t.markup)
              .forEach((m) => {
                m.readyToShow = true
                $('#' + m.id).show()
              }).value()
          })
        })
      } else if (scope.tagInstancesWithMarkups.length > 0) {
        this.clearTagsMarkups(scope)
      }
    }
    clearTagsMarkups (scope) {
      scope.tagInstancesWithMarkups = []
      // for some reason elements are left in the DOM if not cleared explicitly
      $('[id^=' + TAG_MARKUP_OVERLAY_ID_PREFIX + ']').remove()
    }
    resizeTagMarkup (tag, evt, scope) {
      evt = evt || window.event
      evt.stopPropagation()
      if (!scope.isEditable()) {
        return
      }
      let startXDelta = evt.pageX !== undefined ? evt.pageX : evt.clientX
      let startYDelta = evt.pageY !== undefined ? evt.pageY : evt.clientY

      if (!tag.editMode) {
        tag.editMode = true
      }
      this._resizeTagMarkupInternal(evt, startXDelta, startYDelta, tag)
    }
    dragToPositionTagMarkup (tag, evt, scope) {
      evt = evt || window.event
      evt.stopPropagation()
      if (scope.asset.isReadOnly || !scope.isEditable()) {
        return false
      }

      if (!tag.editMode) {
        tag.editMode = true
      }

      let startXDelta = evt.pageX !== undefined ? evt.pageX : evt.clientX
      let startYDelta = evt.pageY !== undefined ? evt.pageY : evt.clientY

      this._dragToPositionMarkupRegularViewerInternal(evt, startXDelta, startYDelta, tag)
    }
    rotateTagMarkup (tag, evt, scope) {
      evt.stopPropagation()
      if (scope.isEditable()) {
        tag.markup.rotation = (tag.markup.rotation || 0) - 45
        smartvidApi.updateTagForAsset(tag)
      }
    }
    getTagShapeStyle (tag) {
      return {
        width: tag.markup.width,
        height: tag.markup.height,
        top: tag.markup.top,
        left: tag.markup.left
      }
    }
    compareVideoParams (tag, scope) {
      if (scope.isMobile) {
        return
      }
      let currentVideoParams = document.getElementsByTagName('video')
      this._getVideoParamsInternal(currentVideoParams)

      let xScale = (currentVideoParams.markup.actualWidth / tag.markup.actualWidth)
      let yScale = (currentVideoParams.markup.actualHeight / tag.markup.actualHeight)
      let xShift = (currentVideoParams.markup.hMargins - tag.markup.hMargins)
      let yShift = (currentVideoParams.markup.vMargins - tag.markup.vMargins)

      tag.markup.width = tag.markup.width * xScale
      tag.markup.left = tag.markup.left * xScale + xShift
      tag.markup.height = tag.markup.height * yScale
      tag.markup.top = tag.markup.top * yScale + yShift
      this._getVideoParamsInternal(tag)
    }

    // ///////////////////////////////////////////////////////////////////////////
    // ///////////////////////////////////////////////////////////////////////////
    // ///////////////////////////////////////////////////////////////////////////

    _resizeTagMarkupInternal (evt, startXDelta, startYDelta, tag) {
      this._getVideoParamsInternal(tag)
      // let heightLimit = heightOfMediaContainer - tag.height
      let elem = evt.currentTarget.parentElement

      document.body.onmousemove = (evt) => {
        evt = evt || window.event
        let endX = evt.pageX !== undefined ? evt.pageX : evt.clientX
        let endY = evt.pageY !== undefined ? evt.pageY : evt.clientY
        let amountMovedAlongX = endX - startXDelta
        let amountMovedAlongY = endY - startYDelta
        startXDelta = endX
        startYDelta = endY
        let widthLimit = tag.markup.clientWidth - tag.markup.left
        let heightLimit = tag.markup.clientHeight - tag.markup.top
        tag.markup.width = Math.min(widthLimit, (tag.markup.width + amountMovedAlongX))
        tag.markup.height = Math.min(heightLimit, (tag.markup.height + amountMovedAlongY))
        elem.style.width = tag.markup.width + 'px'
        elem.style.height = tag.markup.height + 'px'
      }

      document.body.onmouseup = (evt) => {
        this._getVideoParamsInternal(tag)
        document.body.onmousemove = document.body.onmouseup = null
        smartvidApi.updateTagForAsset(tag)
      }
    }

    _getVideoParamsInternal (tag) {
      let el = document.getElementsByTagName('video')
      if (typeof tag.markup === 'string' || typeof tag.markup === 'undefined') {
        tag.markup = {}
      }
      tag.markup.videoHeight = el[0].videoHeight
      tag.markup.videoWidth = el[0].videoWidth
      tag.markup.clientHeight = el[0].clientHeight
      tag.markup.clientWidth = el[0].clientWidth
      tag.markup.offsetHeight = el[0].offsetHeight
      tag.markup.offsetWidth = el[0].offsetWidth
      tag.markup.duration = el[0].duration
      tag.markup.currentTime = el[0].currentTime

      if (el[0].clientHeight / el[0].videoHeight > el[0].clientWidth / el[0].videoWidth) {
        tag.markup.actualWidth = el[0].clientWidth
        tag.markup.actualHeight = el[0].clientWidth * (el[0].videoHeight / el[0].videoWidth)
      } else {
        tag.markup.actualHeight = el[0].clientHeight
        tag.markup.actualWidth = el[0].clientHeight * (el[0].videoWidth / el[0].videoHeight)
      }

      tag.markup.vMargins = (el[0].clientHeight - tag.markup.actualHeight) / 2
      tag.markup.hMargins = (el[0].clientWidth - tag.markup.actualWidth) / 2

      return tag
    }

    _dragToPositionMarkupRegularViewerInternal (evt, startXDelta, startYDelta, tag) {
      this._getVideoParamsInternal(tag)

      let moved = false
      let startX = tag.markup.left
      let startY = tag.markup.top
      evt = evt || window.event
      let elem = evt.currentTarget

      document.body.onmousemove = (evt) => {
        moved = true
        evt = evt || window.event
        let endX = evt.pageX !== undefined ? evt.pageX : evt.clientX
        let endY = evt.pageY !== undefined ? evt.pageY : evt.clientY

        // TODO: Add x0Limit and y0Limit and update all limit params to include margins

        let x1Limit = tag.markup.clientWidth - tag.markup.width
        let y1Limit = tag.markup.clientHeight - tag.markup.height

        if (endX > startXDelta) {
          tag.markup.left = Math.min(x1Limit, Math.max(0, startX + Math.abs(endX - startXDelta)))
        } else {
          tag.markup.left = Math.min(x1Limit, Math.max(0, startX - Math.abs(endX - startXDelta)))
        }

        if (endY > startYDelta) {
          tag.markup.top = Math.min(y1Limit, Math.max(0, (startY + Math.abs(endY - startYDelta))))
        } else {
          tag.markup.top = Math.min(y1Limit, Math.max(0, (startY - Math.abs(endY - startYDelta))))
        }

        elem.style.left = tag.markup.left + 'px'
        elem.style.top = tag.markup.top + 'px'
      }

      document.body.onmouseup = (evt) => {
        if (moved) {
          elem.style.left = tag.markup.left + 'px'
          elem.style.top = tag.markup.top + 'px'
        }
        document.body.onmousemove = document.body.onmouseup = null
        smartvidApi.updateTagForAsset(tag)
      }
    }
    destroy () {
      document.body.onmouseup = null
      document.body.onmousemove = null
    }
  }

  return new NormalTagMarkupService()
})
