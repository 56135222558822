/* global angular */

angular.module('smartvid').directive('file', ($rootScope, $timeout) => {
  return {
    restrict: 'AE',
    scope: {
      files: '@'
    },
    link (scope, el, attrs) {
      scope.selectInProg = false
      el.on('change.file', (event) => {
        var files = event.target.files
        for (var i = 0; i < files.length; ++i) {
          scope.$parent.files.push(files[i])
        }
        scope.$apply()
        $rootScope.$broadcast('sv-media-files-selected', scope.$parent.files)
      })

      // Prevents user from quickly double clicking button which caused the browse window to appear twice
      el.on('click.file', (event) => {
        if (scope.selectInProg) {
          event.stopPropagation()
          event.preventDefault()
          return false
        }
        scope.selectInProg = true
        $timeout(() => {
          scope.selectInProg = false
        }, 300)
      })

      scope.$on('$destroy', () => {
        el.off('change.file')
        el.off('click.file')
      })
    }
  }
})
