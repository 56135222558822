/* global angular,analytics */

import { ModuleType } from 'shared/models/module-type'

angular
  .module('smartvid')
  .controller('LoginCtrl', function(
    $window,
    utils,
    $rootScope,
    $scope,
    $state,
    $location,
    $timeout,
    redirectorService,
    smartvidApi,
    currentUser,
    errorUtils,
    $stateParams
  ) {
    $scope.currentUser = currentUser
    $scope.submitted = false
    if ($stateParams.email) {
      $scope.email = window.atob($stateParams.email)
    }

    $scope.remember = {
      value: false,
    }
    $scope.rememberMe = {
      value: false,
    }

    $scope.clearPasswordError = () => {
      $scope.form.password.$invalid = false
      $scope.form.password.valid = true
      $scope.form.password.$error = {}
    }

    if (window.localStorage.getItem('email')) {
      let email = window.localStorage.getItem('email')
      $scope.email = email
      $scope.rememberMe = {
        value: true,
      }
    }

    let customErrorHandler = response => {
      if (response && response.errorCode === 'BAD_REQUEST' && errorUtils.isSsoUser(response)) {
        let url = errorUtils.getSsoUrl(response)
        if (url) {
          window.location = url
        }
        return true
      }

      let hasError =
        !response ||
        response.errorCode === 'BAD_REQUEST' ||
        response.errorCode === 'NOT_FOUND' ||
        errorUtils.isUserInactive(response) ||
        errorUtils.isUserInvited(response)

      if (hasError && response && response.errorMessages && response.errorMessages.length) {
        //
        // redirect
        //
        if (response.errorCode === 'NOT_FOUND') { //&& errorUtils.isBadPassword(response)
          $scope.form.password.$error.badPassword = 'errorCodes.resource_user_bad_credentials'
          $scope.form.password.$invalid = true
          $scope.form.password.$valid = false
        } else if ($scope.form.password.$invalid || $scope.form.password.$valid === false) {
          // show error message on first form field, email
          $scope.form.email.$error.badCredentials = 'errorCodes.resource_user_bad_credentials'
          // invalidate signin form fields
          $scope.form.email.$invalid = true
          $scope.form.email.$valid = false
          $scope.form.email.$dirty = true
        }
        /*else if (response.errorCode === 'NOT_FOUND' && errorUtils.isBadUser(response)) {
          $scope.form.email.$error.badCredentials = 'errorCodes.resource_user_bad_credentials'
          $scope.form.email.$invalid = true
          $scope.form.email.$valid = false
          $scope.form.email.$dirty = true
        }*/
      } else if (hasError && !response) {
        $scope.form.email.$error.badCredentials = 'errorCodes.resource_user_bad_connection'
        $scope.form.email.$error.svUserStatusRemote = false
        // invalidate signin form fields
        $scope.form.email.$invalid = true
        $scope.form.email.$valid = false
        $scope.form.email.$dirty = true
      }

      return hasError
    }

    $scope.submit = () => {
      //
      // make sure we have value to submit
      //
      $scope.email = $scope.form.email.$viewValue
      $scope.password = $scope.form.password.$viewValue

      //
      // reduce login spam
      //
      if (!$scope.inTransit) {
        $scope.inTransit = true
        let isKeepMeSignedIn = $scope.isMobile && $scope.remember.value
        currentUser.setIsKeepMeSignedIn(isKeepMeSignedIn)
        if ($scope.rememberMe.value) {
          window.localStorage.setItem('email', $scope.email)
        } else {
          window.localStorage.removeItem('email')
        }
        smartvidApi
          .login($scope.email, $scope.password, isKeepMeSignedIn, customErrorHandler)
          .then(
            () => {
              currentUser.initAnalytics(() => {
                analytics.track('Login', {
                  category: 'Authentication',
                })
                if (redirectorService.toState && redirectorService.shouldRedirectForUser($scope.email)) {
                  $state.go(redirectorService.toState.name, redirectorService.toParams)
                  .catch(error => {
                    console.log("Error switching to state:" + redirectorService.toState.name + ", Params: " + JSON.stringify(redirectorService.toParams))
                    let defaultHomePage = currentUser.getDefaultHomePage()
                    $state.go(defaultHomePage)
                  })
                } else {
                  let defaultHomePage = currentUser.getDefaultHomePage()
                  $state.go(defaultHomePage)
                }
              })
            },
            response => {
              if (errorUtils.isUserInactive(response)) {
                $state.go('user-inactive', {
                  email: $scope.email,
                })
              } else if (errorUtils.isUserInvited(response)) {
                $state.go('user-invited', {
                  email: $scope.email,
                })
              }
            }
          )
          .finally(() => {
            $timeout(() => {
              $scope.inTransit = false
            }, 500)
          })
      }
    }
  })
