<div class="sv-organization-home">
  <mat-tab-group
    *ngIf="!hasStandaloneVideoGrid()"
    class="sv-organization-tab-group"
    (selectedTabChange)="selectedTabChange($event)"
    [(selectedIndex)]="selectedTab"
  >
    <mat-tab *ngIf="hasInsightsTabView()" label="{{ 'components.organizationHome.insights' | translate }}">
      <ng-template matTabContent>
        <sv-organization-assets-insights-dashboard></sv-organization-assets-insights-dashboard>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'components.organizationHome.projects' | translate }}" class="sv-project-tab">
      <ng-template matTabContent>
        <svProjectGrid></svProjectGrid>
      </ng-template>
    </mat-tab>
     <mat-tab label="{{ 'components.organizationHome.reports' | translate }}" class="sv-project-tab">
      <ng-template matTabContent>
        <sv-reports-dashboard [organizationId] ="organizationId"></sv-reports-dashboard>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <svVideoGrid *ngIf="hasStandaloneVideoGrid()"></svVideoGrid>
</div>
