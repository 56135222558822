/* global angular, _ */

angular.module('smartvid').directive('svTabset', function () {
  return {
    restrict: 'E',
    transclude: {
      'headingSlot': '?svTabHeading'
    },
    scope: {
      publicSelectedTab: '=?currentlySelectedTab'   // Optional binding so outside logic can be aware of currently active tab
    },
    templateUrl: 'Tabs/sv-tabset.html',
    bindToController: true,
    replace: true,
    controllerAs: 'tabset',
    controller: function ($scope, $element, $attrs, $transclude) {
      var self = this
      self.tabs = []
      self.activeBarElement = $element.find('.active-bar')[0]
      self.activeBarElement.style.height = '3px'
      self.selectedTab
      $scope.tabset.publicSelectedTab

      $scope.$on('sv-select-tab', function (event, identifier) {
        if (identifier) {
          let matches = _.where(self.tabs, identifier)
          if (!_.isEmpty(matches)) {
            self.select(matches[0])
          }
        }
      })

      self.addTab = function (tab) {
        self.tabs.push(tab)
        self.selectFirstVisibleTab()
      }

      self.select = function (tabToSelect) {
        if (self.selectedTab === tabToSelect) { return }

        _.each(self.tabs, (tab) => {
          if (tab === tabToSelect) {
            tabToSelect.setToSelected()
            self.selectedTab = tabToSelect
            $scope.tabset.publicSelectedTab = {
              id: tabToSelect.id,
              name: tabToSelect.name,
              active: tabToSelect.active,
              visible: tabToSelect.visible
            }
          } else {
            tab.setToUnselected()
          }
        })

        self.moveActiveBar(tabToSelect)       // move
        self.redrawActiveBar(tabToSelect)     // resize if needed
      }

      self.getTotalWidth = function () {
        return $element.find('.tabpanel')[0].offsetWidth
      }

      self.moveActiveBar = function (tab) {
        if (tab.activeBarLocation !== undefined && tab.activeBarLocation !== null) {   // If user has defined custom move distance
          self.activeBarElement.style.left = tab.activeBarLocation + 'px'
        } else {                                                                      // Calculate active bar move distance
          let widthSoFar = 0
          let currTab = self.tabs[0]
          for (let i = 1; i < self.tabs.length && currTab !== tab; i++) {
            widthSoFar += (currTab.visible) ? currTab.getWidth() : 0
            currTab = self.tabs[i]
          }
          self.activeBarElement.style.left = widthSoFar + 'px'
        }
      }

      self.redrawActiveBar = function (tab) {
        self.activeBarElement.style.width = tab.getWidth() + 'px'
      }

      self.selectFirstVisibleTab = function () {
        let tab
        for (let i = 0; i < self.tabs.length; i++) {
          tab = self.tabs[i]
          if (tab.visible) {
            self.select(tab)
            self.moveActiveBar(tab)
            return
          }
        }
      }

      self.updateWhichTabSelected = function () {
        if (self.selectedTab && self.selectedTab.visible) {
          self.moveActiveBar(self.selectedTab)
        } else {
          self.selectFirstVisibleTab()
        }
      }

      self.transcludeHeader = function (element, transclusionElem) {
        element.append(transclusionElem)
      }
    }
  }
})
