/* global angular */

angular.module('smartvid').filter('videotime', function () {
  return (timeInMilliSeconds, params) => {
    if (params && params.zeroVal) {
      if (timeInMilliSeconds === 0) {
        return params.zeroVal
      }
    }

    let msNum = parseInt(timeInMilliSeconds, 10) // don't forget the second param
    let secNum = Math.floor(msNum / 1000)
    let hours = Math.floor(secNum / 3600)
    let minutes = Math.floor((secNum - (hours * 3600)) / 60)
    let seconds = secNum - (hours * 3600) - (minutes * 60)
    let time

    if (hours > 0 && hours < 10) {
      hours = '0' + hours
    }

    if (minutes > 0 && minutes < 10) {
      minutes = '0' + minutes
    }

    if (seconds < 10) {
      seconds = '0' + seconds
    }

    if (hours !== 0) {
      time = hours + 'h' + minutes + 'm' + seconds + 's'
    } else if (minutes !== 0) {
      time = minutes + 'm' + seconds + 's'
    } else {
      time = seconds + 's'
    }

    return time
  }
})
