/* global angular */

angular.module('smartvid').directive('filePropertiesPanel', function (
    MAX_ASSET_NAME_LENGTH, MAX_ASSET_DESCRIPTION_LENGTH, MAX_ASSETS_FOR_COMMON_TAG_COMPUTE,
    MAX_COMMENT_THREAD_DESCRIPTION_LENGTH, BATCH_TAG_ASSETS_COUNT_SHORTCUT, $rootScope, $stateParams, $state,
    gaugeSettingsService, dashboardDataHelper
) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'AssetGrid/file-properties-panel.html',
    scope: {
      assetSelectedObj: '=',
      newComment: '=',
      handleCreateTag: '=?',
      handleSelectedTag: '=?',
      onAssetChanged: '=?',
      persistAssetChanges: '=?',
      keyPressCommentLocation: '=?',
      stopEventIfInEditMode: '=?',
      saveComment: '=?',
      saveOnBlur: '=?',
      isCommentEditable: '=?',
      handleCommentClick: '=?',
      checkForReturnKey: '=?',
      checkForArrowKey: '=?',
      addComment: '=?',
      showMoreComment: '=?',
      showCommentOptions: '=?',
      showTagsTab: '=',
      showLinkedObservationsTab: '=?'
    },
    link (scope) {
      scope.MAX_ASSET_NAME_LENGTH = MAX_ASSET_NAME_LENGTH
      scope.MAX_ASSET_DESCRIPTION_LENGTH = MAX_ASSET_DESCRIPTION_LENGTH
      scope.MAX_ASSETS_FOR_COMMON_TAG_COMPUTE = MAX_ASSETS_FOR_COMMON_TAG_COMPUTE
      scope.MAX_COMMENT_THREAD_DESCRIPTION_LENGTH = MAX_COMMENT_THREAD_DESCRIPTION_LENGTH
      scope.oneAssetSelectedObj = {}
      scope.commonTags = []
      scope.onlyOneAssetSelected = true
      scope.assetChanged = false
      scope.currentlySelectedTab
      scope.canAddTags = scope.assetSelectedObj.canTag
      scope.comments = scope.assetSelectedObj.comments
      scope.readOnly = !scope.assetSelectedObj.canComment
      scope.isProjectScope = $stateParams.projectId
      scope.showingDeletedFiles = $state.current.name === 'dashboard.projects.projectId.deletedfiles'
      scope.getGaugeSettings = gaugeSettingsService.getGaugeSettings
      scope.goToObservation = function (obs) {
        let project = dashboardDataHelper.getProjectByProjectId(obs.projectId)
        $state.go('dashboard.observations.orgId.projectGroupId.projectId.viewer', {
          organizationId: project.organizationId,
          projectGroupId: project.projectGroup ? project.projectGroup.id : 'default',
          projectId: project.id,
          observationId: obs.observationId
        })
      }
      scope.isRiskClass = function (obs, type) {
        return obs && obs.riskType.toLowerCase() === type
      }
    }
  }
})
