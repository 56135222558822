/* global angular */

angular.module('smartvid').service('i18nUtils', function (utils, $filter, $rootScope) {
  return {
    success (messageGen) {
      if (!$filter('i18next')('errorCodes.title')) {
        // This may happen when i18next hasn't finished loading yet. We need need to wait for
        // its initialization to complete.
        $rootScope.$on('i18nextLanguageChange', function () {
          utils.notify(messageGen())
        })
      } else {
        utils.notify(messageGen())
      }
    },

    loadLabel (f) {
      if (!$filter('i18next')('errorCodes.title')) {
        // This may happen when i18next hasn't finished loading yet. We need need to wait for
        // its initialization to complete.
        $rootScope.$on('i18nextLanguageChange', function () {
          f()
        })
      } else {
        f()
      }
    }

  }
})
