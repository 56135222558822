export class ReportDashboardModel {
  reportId: string
  createdDate: Date
  nextScheduledDate: Date
  reportName: string
  createdBy: string
  projectNames: string[]
  tags: string[]
  isInactive: boolean
  toUserEmails: string[]
  projectIds: string[]
}

export enum ScheduleInterval {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  NEVER = 'NEVER',
}

export enum ReportName {
  SAFETY_SUMMARY_REPORT = 'SAFETY_SUMMARY_REPORT',
  PHOTO_REPORT = 'PHOTO_REPORT',
}

export class OneReportModel {
  savedReportGenerationParameters: any
}
