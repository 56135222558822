<div class="left-nav-affordance" (click)="toggleNav()">
    <div class="left-nav-container"
         matTooltip="{{NavController.leftNavOpen ? ('tooltips.navpanelClose' | translate) : ('tooltips.navpanelOpen' | translate)}}">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 30 15" enable-background="new 0 0 30 15" xml:space="preserve"
             *ngIf="NavController.leftNavOpen"
             [ngClass]="{ 'menu-close' :true, 'icon-animation-navigation': true, 'fill-red': NavController.leftNavOpen, 'fill-light-grey': !NavController.leftNavOpen }"
             class="menu-close icon-animation-navigation fill-red"><g><g><g><path d="M1.4,7.5L8,1.1c0.2-0.2,0.2-0.6,0-0.9c-0.2-0.2-0.6-0.2-0.8,0L0.2,7C0,7.1,0,7.3,0,7.5C0,7.7,0,7.8,0.2,8
				l7,6.8C7.4,15,7.8,15,8,14.8c0.2-0.2,0.2-0.6,0-0.9L1.4,7.5z"></path></g></g><g><path d="M30,2.4c0,0.4-0.3,0.7-0.7,0.7H13.2c-0.4,0-0.7-0.3-0.7-0.7V0.9c0-0.4,0.3-0.7,0.7-0.7h16.1
			c0.4,0,0.7,0.3,0.7,0.7V2.4z M30,8.3c0,0.4-0.3,0.7-0.7,0.7H13.2c-0.4,0-0.7-0.3-0.7-0.7V6.8c0-0.4,0.3-0.7,0.7-0.7h16.1
			c0.4,0,0.7,0.3,0.7,0.7V8.3z M30,14.3c0,0.4-0.3,0.7-0.7,0.7H13.2c-0.4,0-0.7-0.3-0.7-0.7v-1.5c0-0.4,0.3-0.7,0.7-0.7h16.1
			c0.4,0,0.7,0.3,0.7,0.7V14.3z"></path></g></g>
        </svg>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 30 15" enable-background="new 0 0 30 15" xml:space="preserve"
             *ngIf="!NavController.leftNavOpen"
             [ngClass]="{ 'menu-close' :true, 'icon-animation-navigation': true, 'fill-red': NavController.leftNavOpen, 'fill-light-grey': !NavController.leftNavOpen }"
             class="menu-close icon-animation-navigation fill-light-grey"><g transform="translate(-6,0)"><g><path d="M30,2.4c0,0.4-0.3,0.7-0.7,0.7H13.2c-0.4,0-0.7-0.3-0.7-0.7V0.9c0-0.4,0.3-0.7,0.7-0.7h16.1
			c0.4,0,0.7,0.3,0.7,0.7V2.4z M30,8.3c0,0.4-0.3,0.7-0.7,0.7H13.2c-0.4,0-0.7-0.3-0.7-0.7V6.8c0-0.4,0.3-0.7,0.7-0.7h16.1
			c0.4,0,0.7,0.3,0.7,0.7V8.3z M30,14.3c0,0.4-0.3,0.7-0.7,0.7H13.2c-0.4,0-0.7-0.3-0.7-0.7v-1.5c0-0.4,0.3-0.7,0.7-0.7h16.1
			c0.4,0,0.7,0.3,0.7,0.7V14.3z"></path></g></g>
        </svg>
    </div>
</div>
