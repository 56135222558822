/* global angular,_ */
angular.module('smartvid').controller('ExportAssetsCtrl', function (
    $scope, $rootScope, $state, $stateParams, $filter, $timeout, smartvidApi, searchApi, modal, utils, TagDefModel,
    currentUser, batchJobService, dashboardDataHelper, searchResultService, assetGridHelper
) {
  let projectId = $stateParams.projectId
  $scope.searchCriteria = searchResultService.getCurrentSearchContext() ? searchResultService.getCurrentSearchContext().getSearchByValues() : []
  $scope.projectId = projectId
  $scope.addedTags = []
  $scope.tagDefIds = []
  $scope.isFetching = false
  $scope.ignoredTagTexts = []

  let storedTagDefIds = assetGridHelper.readExportAssetsState(projectId)
  storedTagDefIds = !storedTagDefIds ? [] : storedTagDefIds
  _.each($scope.searchCriteria, (searchEntity) => {
    if (!_.includes(storedTagDefIds, searchEntity.id)) {
      storedTagDefIds.unshift(searchEntity.id)
    }
  })

  if (storedTagDefIds.length > 0) {
    $scope.isFetching = true
    searchApi.findSearchProjectTags(projectId, storedTagDefIds).then((tags) => {
      $scope.addedTags = tags
      $scope.isFetching = false
    }, () => {
      $scope.isFetching = false
    })
  }

  $scope.handleSelectedTag = function (tag) {
    // never allow blank tag
    if (!tag || _.trim(tag).length === 0) {
      return
    }

    if (!(tag instanceof TagDefModel)) {
      return
    }
    if (_.find($scope.addedTags,
      (existingTag) => {
        return tag.id === existingTag.id || tag.text.toUpperCase() === existingTag.text.toUpperCase()
      })) {
      return
    }
    tag.isNew = false
    $scope.addedTags.push(tag)
    $scope.ignoredTagTexts.push(tag.text)
  }

  $scope.removeTag = function (tag) {
    $scope.addedTags.splice($scope.addedTags.indexOf(tag), 1)
    _.pull($scope.ignoredTagTexts, tag.text)
  }

  $scope.generate = () => {
    if ($scope.isFetching) {
      return
    }
    modal.close()

    let multiAssetSelectionWithTagDefNames = dashboardDataHelper.getAssets().getMultiAssetSelection()

    multiAssetSelectionWithTagDefNames.tagDefsText = _.map($scope.addedTags, (t) => {
      return t.text
    })
    multiAssetSelectionWithTagDefNames.tagDefIds = _.map($scope.addedTags, (t) => {
      return t.id
    })
    assetGridHelper.saveExportAssetsState(projectId, multiAssetSelectionWithTagDefNames.tagDefIds)

    $rootScope.$broadcast('sv-before-batch-export-asset-with-tag-counts-start')
    batchJobService.manageBatchJob(
      null,
      projectId,
      smartvidApi.exportAssetsWithTagInstanceCounts(multiAssetSelectionWithTagDefNames),
      ['sv-before-batch-export-asset-with-tag-counts-complete'],
      ['sv-before-batch-export-asset-with-tag-counts-failed'],
      (jobResponse) => {
        utils.notify(
          'directives.uploadtracker.batchExportAssetsWithTagCountsComplete', '',
          $filter('i18next')('assets.download'), true, () => {
            let packageId = jobResponse.packageId
            utils.downloadFile(projectId, packageId, utils.utf8ToB64(currentUser.token))
          }
        )
      })
  }
})
