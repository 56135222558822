/* global angular */

angular.module('smartvid').controller('PasswordResetCtrl', function ($scope, $state, $stateParams, $filter, $window, smartvidApi) {
  //
  // The form submit for password reset
  //
  $scope.submit = () => {
    //
    // reset submit message
    //
    $scope.form.email.$submit = {}
    $scope.form.email.$dirty = true

    if ($scope.form.$invalid) {
      return
    }

    $scope.email = $scope.form.email.$viewValue

    $scope.inTransit = true
    smartvidApi.requestPasswordReset($scope.email).then(function (response) {
      if (response && response.status >= 200 && response.status < 300) {
        $scope.success = true
        $scope.form.email.$submit.message = 'forgotPassword.sentSuccess'
      } else if (!response || !response.data) {
        $scope.success = false
        $scope.form.email.$error.svUserStatusRemote = false
        $scope.form.email.$error.badCredentials = 'errorCodes.resource_user_bad_connection'
      }

      $scope.inTransit = false
    })
  }

  $scope.$watch('form.email.$dirty', () => {
    if ($scope.form && $scope.form.email) {
      $scope.form.email.$submit = {}
    }
  })

  // carry email over from previous screen
  $scope.email = $stateParams.email

  $scope.resetPassword = () => {
    $scope.inTransit = true

    smartvidApi.resetPassword($scope.password, $stateParams.token).then(function (response) {
      if (response && response.status >= 200 && response.status < 300) {
        $scope.success = true
        let email = window.atob($stateParams.token).split(':')[0]
        smartvidApi.login(email, $scope.password).then(function () {
          $scope.inTransit = false
          $state.go('dashboard.projects')
        })
      }
      $scope.inTransit = false
    }, () => {
      $scope.inTransit = false
    })
  }
})
