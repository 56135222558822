import { Component } from '@angular/core'
import {
  CardInputChangeEvent,
  CardToggleMenuGroup,
  CardToggleMenuItem,
} from 'modules/insights/dashboard/cards/models/insights-card.model'
import {
  DashboardCardId,
  DashboardDateRangeTimeUnit,
  DashboardTabId,
  DashboardType,
  DateRangeChartData,
  InsightsDashboardParameters,
  TableScopeFilter,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { TranslateService } from '@ngx-translate/core'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import { InsightsDashboardComponentBaseDirective } from 'modules/insights/dashboard/components/insights-dashboard-component-base'
import { InsightsDashboardOrganizationAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-organization-assets.service'
import { OrganizationAssetsActivityTableData } from 'modules/insights/dashboard/models/insights-dashboard-organization-assets.model'
import { OrganizationAssetsActivityTableComponent } from 'modules/insights/dashboard/components/totals-table/organization-assets/organization-assets-activity-table.component'
import { InsightsCardComponent } from 'modules/insights/dashboard/cards/components/insights-card/insights-card.component'

@Component({
  selector: 'sv-organization-assets-insights-dashboard-activity',
  templateUrl: 'organization-assets-insights-dashboard-activity.component.html',
  styleUrls: ['organization-assets-insights-dashboard-activity.component.scss'],
})
export class OrganizationAssetsInsightsDashboardActivityComponent extends InsightsDashboardComponentBaseDirective {
  activityTableData: OrganizationAssetsActivityTableData[]
  currentActivityScope: TableScopeFilter = this.orgHasGroups
    ? TableScopeFilter.BY_PROJECT_GROUP
    : TableScopeFilter.BY_PROJECT
  activityByGroupOrProjectTableMenu: CardToggleMenuGroup[]
  organizationUploadsOverTimeBySourceData: DateRangeChartData
  uploadsBySourceChartMenu: CardToggleMenuGroup[]
  organizationUploadersOverTimeData: DateRangeChartData
  uploadersChartMenu: CardToggleMenuGroup[]
  organizationConstructionActivityData: DateRangeChartData
  organizationPhotoDescriptionData: DateRangeChartData
  organizationUploadsByFileTypeData: DateRangeChartData
  organizationTagsByTypeData: DateRangeChartData

  constructor(
    protected insightsDataHelper: InsightsDataHelper,
    protected translate: TranslateService,
    protected insightsDashboardConfigurationService: InsightsDashboardConfigurationService,
    private insightsDashboardOrganizationAssetsService: InsightsDashboardOrganizationAssetsService
  ) {
    super(
      insightsDataHelper,
      insightsDashboardConfigurationService,
      translate,
      DashboardType.ORGANIZATION_ASSETS_DASHBOARD
    )
  }

  activityByGroupOrProjectMenuSelected(
    $event: CardToggleMenuItem,
    activityByGroupOrProjectCard: InsightsCardComponent
  ) {
    activityByGroupOrProjectCard.clearCardInputControl()
    this.currentActivityScope = $event.eventId
    this.loadActivityTableData($event.eventId)
  }

  get cardTitle() {
    return this.currentActivityScope === TableScopeFilter.BY_PROJECT_GROUP
      ? this.translate.instant('dashboard.cards.orgActivityByProjectOrGroup.titleGroup')
      : this.translate.instant('dashboard.cards.orgActivityByProjectOrGroup.titleProject')
  }

  get cardSubTitle() {
    return this.currentActivityScope === TableScopeFilter.BY_PROJECT_GROUP
      ? this.translate.instant('dashboard.cards.orgActivityByProjectOrGroup.subTitleGroup')
      : this.translate.instant('dashboard.cards.orgActivityByProjectOrGroup.subTitleProject')
  }

  get filterInputPlaceholder() {
    return this.currentActivityScope === TableScopeFilter.BY_PROJECT_GROUP
      ? this.translate.instant('dashboard.cards.orgActivityByProjectOrGroup.searchInputPlaceholderGroup')
      : this.translate.instant('dashboard.cards.orgActivityByProjectOrGroup.searchInputPlaceholderProject')
  }

  activityTableFilterChanged(
    $event: CardInputChangeEvent,
    organizationAssetsActivityTable: OrganizationAssetsActivityTableComponent
  ) {
    if (!$event.value || !$event.value.trim()) {
      organizationAssetsActivityTable.clearFilter()
      return
    }

    organizationAssetsActivityTable.applyFilter({
      fieldName: 'name',
      filter: $event.value,
    })
  }

  uploadsBySourceChartMenuSelected($event: CardToggleMenuItem) {
    this.loadOrganizationUploadsOverTimeBySourceData($event.eventId)
  }

  uploadersChartMenuSelected($event: CardToggleMenuItem) {
    this.loadOrganizationUploadersOverTimeData($event.eventId)
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private get orgHasGroups() {
    return this.insightsDataHelper.getOrgHasGroups(DashboardType.ORGANIZATION_ASSETS_DASHBOARD)
  }

  // the ()=> function is needed to capture the correct 'this' reference
  protected refresh() {
    this.initializeMenuItems()

    this.guardFor(
      () =>
        this.loadActivityTableData(this.orgHasGroups ? TableScopeFilter.BY_PROJECT_GROUP : TableScopeFilter.BY_PROJECT),
      DashboardTabId.ACTIVITY,
      DashboardCardId.ORGANIZATION_ASSETS_ACTIVITY_BY_GROUP_PROJECT
    )
    this.guardFor(
      () => this.loadOrganizationUploadsOverTimeBySourceData(DashboardDateRangeTimeUnit.LAST_1),
      DashboardTabId.ACTIVITY,
      DashboardCardId.ORGANIZATION_ASSETS_UPLOADS_OVERTIME_BY_SOURCE
    )
    this.guardFor(
      () => this.loadOrganizationUploadersOverTimeData(DashboardDateRangeTimeUnit.LAST_1),
      DashboardTabId.ACTIVITY,
      DashboardCardId.ORGANIZATION_ASSETS_UPLOADERS_OVERTIME
    )
    this.guardFor(
      () => this.loadOrganizationConstructionActivityData(),
      DashboardTabId.ACTIVITY,
      DashboardCardId.ORGANIZATION_ASSETS_CONSTRUCTION_ACTIVITY
    )
    this.guardFor(
      () => this.loadOrganizationPhotoDescriptionData(),
      DashboardTabId.ACTIVITY,
      DashboardCardId.ORGANIZATION_ASSETS_PHOTO_DESCRIPTION
    )
    this.guardFor(
      () => this.loadOrganizationUploadsByFileTypeData(),
      DashboardTabId.ACTIVITY,
      DashboardCardId.ORGANIZATION_ASSETS_UPLOADS_BY_FILE_TYPE
    )
    this.guardFor(
      () => this.loadOrganizationTagsByTypeData(),
      DashboardTabId.ACTIVITY,
      DashboardCardId.ORGANIZATION_ASSETS_TAGS_BY_TYPE
    )
  }

  private initializeMenuItems() {
    this.activityByGroupOrProjectTableMenu = [
      {
        groupId: 'ACTIVITY_BY_PROJECT_OR_GROUP',
        groupItems: [
          {
            label: 'dashboard.cards.orgActivityByProjectOrGroup.menu.group',
            eventId: TableScopeFilter.BY_PROJECT_GROUP,
            isChecked: this.orgHasGroups,
            isDisabled: !this.orgHasGroups,
          },
          {
            label: 'dashboard.cards.orgActivityByProjectOrGroup.menu.project',
            eventId: TableScopeFilter.BY_PROJECT,
            isChecked: !this.orgHasGroups,
          },
        ],
      },
    ]
    this.uploadsBySourceChartMenu = this.createChartDateSwitchMenuItems('UPLOADS_MENU', 'orgUploadsOverTimeBySource')
    this.uploadersChartMenu = this.createChartDateSwitchMenuItems('UPLOADERS_MENU', 'orgUploadersCountOverTime')
  }

  private loadActivityTableData(filter: string) {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      filter: filter,
    }
    this.activityTableData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadOrganizationActivityTableData(dashboardParams, tableData => {
        this.activityTableData = tableData
      })
    )
  }

  private loadOrganizationUploadsOverTimeBySourceData(chartResolution: DashboardDateRangeTimeUnit) {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeTimeUnit: chartResolution,
    }
    this.organizationUploadsOverTimeBySourceData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadUploadsOverTimeBySourceData(dashboardParams, chartData => {
        this.organizationUploadsOverTimeBySourceData = chartData
      })
    )
  }

  private loadOrganizationUploadersOverTimeData(chartResolution: DashboardDateRangeTimeUnit) {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeTimeUnit: chartResolution,
    }
    this.organizationUploadersOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadUploadersOverTimeData(dashboardParams, chartData => {
        this.organizationUploadersOverTimeData = chartData
      })
    )
  }

  private loadOrganizationConstructionActivityData() {
    this.organizationConstructionActivityData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadConstructionActivityData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.organizationConstructionActivityData = chartData
        }
      )
    )
  }

  private loadOrganizationPhotoDescriptionData() {
    this.organizationPhotoDescriptionData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadPhotoDescriptionData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.organizationPhotoDescriptionData = chartData
        }
      )
    )
  }

  private loadOrganizationUploadsByFileTypeData() {
    this.organizationUploadsByFileTypeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadUploadsByFileTypeData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.organizationUploadsByFileTypeData = chartData
        }
      )
    )
  }

  private loadOrganizationTagsByTypeData() {
    this.organizationTagsByTypeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadTagsByTypeData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.organizationTagsByTypeData = chartData
        }
      )
    )
  }
}
