/* global angular */

angular.module('smartvid').controller('TagImportCtrl', function ($rootScope, $scope, modal, tagImportService, $stateParams, $filter, $timeout) {
  $scope.files = []                         // List of files to import (only one for now)
  $scope.inTransit = false                  // Is the import request in transit?
  $scope.errors = []                        // List of errors encountered when attempting tag import
  $scope.numTagsImported = 0                // Number of tags imported if import successful
  $scope.errorType = {                      // General error type use by the tag import report (only one true at a time)
    standard: false,
    pwProtected: false,
    multipleSheets: false,
    wrongFormat: false,
    invalidVersion: false
  }
  $scope.hasError = false                   // Did the import encounter problems?
  let tree = $scope.treeModel
  /**
    ---------------------
    import tags
    ---------------------
    Called on submit to handle the importing of tags
  */
  $scope.importTags = () => {
    modal.close()
    $scope.inTransit = true
    $scope.errors = []
    if ($scope.files.length === 1) {
      $rootScope.$broadcast('sv-tag-import-before-start')

      let promise = tree.importTags($scope.files[0])
      promise.then((data) => {
        // success
        $scope.hasError = false
        $scope.inTransit = false
        $rootScope.$broadcast('sv-tag-import-complete')
        $scope.numTagsImported = data.numTags
      }, (data) => {
        // failure
        $scope.hasError = true
        $scope.inTransit = false
        let errorList = data.errorMessages
        if (errorList.length > 0 && errorList[0].label) {
          setErrorType(errorList[0].label)
        } else {
          $scope.errorType.standard = true
        }
        for (let i = 0; i < errorList.length; i++) {
          $scope.errors.push({
            description: (errorList[i].extraInfo) ? errorList[i].extraInfo + ' ' + errorList[i].message : errorList[i].message
          })
        }
        $rootScope.$broadcast('sv-tag-import-failed', $scope.errors)
      })
    }
  }

  /*
    ----------------
    Set Error Type
    ----------------
    Function to set the correct error type flag.
    The error type flags are used by the modal
    to display/hide error messages
  */
  let setErrorType = (error) => {
    switch (error) {
      case 'tag.import.excel.encrypted' : $scope.errorType.pwProtected = true
        break

      case 'tag.import.excel.multiplesheets' : $scope.errorType.multipleSheets = true
        break

      case 'tag.import.invalid.file' : $scope.errorType.wrongFormat = true
        break

      case 'tag.import.invalid.version' : $scope.errorType.invalidVersion = true
        break

      default : $scope.errorType.standard = true
    }
  }

  /*
    ----------------
    download template
    ----------------
    function to allow users to download an example
    tag import template for guidance
  */
  $scope.downloadTemplate = () => {
    var anchor = angular.element('<a/>')
    var templatePath = 'assets/documents/tag-import-template-v1-4.xlsx'
    anchor.attr({
      href: templatePath,
      target: '_blank',
      download: 'Template.xlsx'
    })[0].click()
  }

  /*
    --------------------------------
    open tag import report listener
    --------------------------------
    Function that listens for a queue to open the
    tag import report modal.
  */
  $scope.$on('sv-open-tag-import-report', (options) => {
    openTagImportReportModal(options, $scope.errors)
  })

  $scope.closeModal = () => {
    $timeout(function () {
      modal.close()
    }, 150)
  }

  /**
   --------------------------------
   open tag import report
   --------------------------------
   * Single call used to open tag import reviewer modal
   @param options
   @param errors -- List of errors to display
   */
  let openTagImportReportModal = (options, errors) => {
    modal.open('tagImportReport', {
      errors: errors,
      closeModal: function () { $scope.closeModal() },
      downloadTemplate: function () { $scope.downloadTemplate() },
      foundErrors: $scope.hasError,
      numTagsImported: $scope.numTagsImported,
      errorType: $scope.errorType,
      header: (($scope.hasError) ? $filter('i18next')('tags.modal.header-import-failure') : $filter('i18next')('tags.modal.header-import-success'))
    })
    if (options.evt) {
      options.evt.stopPropagation()
      options.evt.preventDefault()
      return false
    }
  }
})
