import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { MatButtonToggleChange } from '@angular/material/button-toggle'
import { MatCheckboxChange } from '@angular/material/checkbox'
import {
  CardActionItem,
  CardInputChangeEvent,
  CardMenuItem,
  CardToggleMenuGroup,
  CardToggleMenuItem,
} from 'modules/insights/dashboard/cards/models/insights-card.model'
import { NGXLogger } from 'ngx-logger'

@Component({
  selector: 'sv-insights-card',
  templateUrl: 'insights-card.component.html',
  styleUrls: ['insights-card.component.scss'],
})
export class InsightsCardComponent implements OnInit {
  @Input() cardTitle: string
  @Input() subTitle: string
  @Input() contentHeight: string
  @Input() darkTheme = true
  @Input() hasTextInput = false
  @Input() textInputPlaceholder = ''
  @Input() toggleMenuGroups: CardToggleMenuGroup[]
  @Input() menuItems: CardMenuItem[]
  @Input() actionItems: CardActionItem[]

  @Output() menuItemSelected = new EventEmitter<CardMenuItem>()
  @Output() toggleMenuItemSelected = new EventEmitter<CardToggleMenuItem>()
  @Output() actionItemSelected = new EventEmitter<CardActionItem>()
  @Output() cardInputChange = new EventEmitter<CardInputChangeEvent>()

  @ViewChild('cardInputControl', { static: false }) private cardInputControlRef: ElementRef

  constructor(private logger: NGXLogger) {}

  ngOnInit(): void {}

  menuItemClicked($event: any, menuItem: CardMenuItem) {
    let selectedMenuItem: CardMenuItem
    if ($event instanceof MatCheckboxChange) {
      selectedMenuItem = new CardMenuItem({
        ...menuItem,
        isChecked: $event.checked,
      })
    } else {
      selectedMenuItem = new CardMenuItem({ ...menuItem })
    }
    this.menuItemSelected.emit(selectedMenuItem)
  }

  actionItemClicked(actionItem: CardActionItem) {
    this.actionItemSelected.emit({ ...actionItem })
  }

  onCardInputChange($event: KeyboardEvent) {
    this.cardInputChange.emit({
      value: ($event.target as HTMLInputElement).value,
      $event: $event,
    })
  }

  toggleMenuChanged($event: MatButtonToggleChange, toggleItem: CardToggleMenuItem) {
    this.toggleMenuItemSelected.emit({
      ...toggleItem,
      isChecked: $event.source.checked,
    })
  }

  clearCardInputControl() {
    ;(this.cardInputControlRef.nativeElement as HTMLInputElement).value = ''
  }

  log(obj: any) {
    this.logger.info('INFO: ', obj)
  }
}
