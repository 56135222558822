/* global angular */

angular.module('smartvid').directive('emailDomainCheck', function (emailUtils) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elem, attrs, ctrl) {
      let validateDomains = () => {
        let emailDomainsStr = elem.val().trim().replace(/^\s+|\s+$/g, '')
        let emailDomains = emailDomainsStr ? emailDomainsStr.split(',') : undefined
        scope.invalidDomains = undefined
        scope.forbiddenDomains = undefined

        if (emailDomains) {
          let invalidDomains = []
          let forbiddenDomains = []

          emailDomains.forEach(function (domain) {
            if (!emailUtils.isValidDomain(domain)) {
              invalidDomains.push(domain)
            }

            if (emailUtils.isFreeEmailService(domain) || emailUtils.isDisposableEmailService(domain)) {
              forbiddenDomains.push(domain)
            }
          })

          scope.invalidDomains = invalidDomains.length > 0 ? invalidDomains : undefined
          scope.forbiddenDomains = forbiddenDomains.length > 0 ? forbiddenDomains : undefined
        }

        let input = scope[elem.closest('form').attr('name')].organizationEmailDomainsInput
        input.$error.invalidDomain = angular.isDefined(scope.invalidDomains)
        input.$error.forbiddenDomain = angular.isDefined(scope.forbiddenDomains)
        return angular.isDefined(scope.invalidDomains) || angular.isDefined(scope.forbiddenDomains)
      }
      elem.on('blur', validateDomains)
      elem.closest('form').on('submit', validateDomains)
    }
  }
})
