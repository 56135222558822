/* global angular,_ */

angular.module('smartvid').directive('monitorAsset', function (utils, AssetModel) {
  return {
    restrict: 'A',
    link: function (scope) {
      scope.$watch('item', function () {
        if (!_.isEmpty(scope.item) && scope.item instanceof AssetModel) {
          let localItem = scope.item
          localItem.computedThumbnailUrl = null
          scope.$evalAsync(() => {
            localItem.computedThumbnailUrl = localItem.getComputedThumbnailUrl()
          })
        }
      })
    }
  }
})
