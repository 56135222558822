/* global angular */
angular.module('smartvid').service('assetGridHelper', function (
    $log, $window, AssetViewType, dashboardDataHelper, currentUser
) {
  // Increase version number, when making changes to the format or content of  the asset grid state to make
  // sure state that's stored in user's browser cache is not incompatible with expected format
  const ASSET_GRID_STATE_VERSION = 'v5'
  let _isActiveSearch = false

  this.getAssetViewOptions = getAssetViewOptions
  this.getAssetGroupByOptions = getAssetGroupByOptions
  this.isGrouped = isGrouped
  this.isActiveSearch = isActiveSearch
  this.setIsActiveSearch = setIsActiveSearch
  this.defaultAssetGridState = defaultAssetGridState
  this.defaultMobileAssetGridState = defaultMobileAssetGridState
  this.saveAssetGridState = saveAssetGridState
  this.readAssetGridState = readAssetGridState
  this.setupTempGridState = setupTempGridState
  this.saveExportAssetsState = saveExportAssetsState
  this.readExportAssetsState = readExportAssetsState

  function getAssetViewOptions (projectId, assetViewType, isMobile = false, projectGroupId) {
    let options = {
      sortInfo: [],
      state: undefined
    }
    if (isMobile) {
      let defaultMobileState = defaultMobileAssetGridState()
      options.sortInfo.push({
        sortBy: defaultMobileState.sortByColumn,
        sortOrder: defaultMobileState.sortByOrder
      })
    } else {
      let state = readAssetGridState(projectId, assetViewType, undefined, projectGroupId)
      // Search doesn't support grouping/sorting by last name. Reset grid state in this case
      if (assetViewType === AssetViewType.SEARCH_RESULTS &&
        (state.groupByColumn === 'lastName' || state.sortByColumn === 'lastName')) {
        state = defaultAssetGridState()
        saveAssetGridState(projectId, state, assetViewType)
      }
      if (state.groupBy) {
        options.sortInfo.push({
          sortBy: state.groupByColumn,
          sortOrder: state.groupByOrder
        })
      }
      if (state.sortBy) {
        options.sortInfo.push({
          sortBy: state.sortByColumn,
          sortOrder: state.sortByOrder
        })
      }
      options.state = state
    }
    return options
  }

  function getAssetGroupByOptions (projectId, isMobile = false) {
    let state = readAssetGridState(projectId)
    let options = {
      sortInfo: []
    }
    if (!isMobile) {
      if (state.groupBy) {
        options.sortInfo.push({
          sortBy: state.groupByColumn,
          sortOrder: state.groupByOrder
        })
      }
    }

    return options
  }

  function isGrouped (projectId) {
    let state = readAssetGridState(projectId)
    return state.groupBy
  }

  function isActiveSearch () {
    return _isActiveSearch
  }

  function setIsActiveSearch (isActiveSearch) {
    _isActiveSearch = !!isActiveSearch
  }

  function defaultAssetGridState (assetViewType, projectId, searchContext) {
    let groupByColumn = 'captureTimePrecisionWeek'
    let groupBy = true
    let groupByOrder = 'DESC'
    let sortByColumn = 'captureTime'
    let sortBy = true
    let sortByOrder = 'DESC'
    let searchQueryType = searchContext && searchContext.searchQueryType
    if (assetViewType === AssetViewType.SEARCH_RESULTS) {
      groupBy = false
      groupByOrder = undefined
      groupByColumn = undefined
      sortByColumn = !projectId && searchQueryType === 'ANY_TERM' && !searchContext.isAllSearch() ? 'bestMatch' : 'captureTime'
    }
    return {
      sortBy: sortBy,
      groupBy: groupBy,
      groupByColumn: groupByColumn,
      groupByOrder: groupByOrder,
      sortByColumn: sortByColumn,
      sortByOrder: sortByOrder
    }
  }

  function defaultMobileAssetGridState (assetViewType, searchContext) {
    let sortBy = true
    let sortByOrder = 'DESC'
    let sortByColumn = 'captureTime'
    let groupBy = false
    let searchQueryType = searchContext && searchContext.searchQueryType
    if (assetViewType === AssetViewType.SEARCH_RESULTS) {
      sortByColumn = searchQueryType === 'ANY_TERM' && !searchContext.isAllSearch() ? 'bestMatch' : 'captureTime'
    }
    return {
      sortBy: sortBy,
      groupBy: groupBy,
      sortByColumn: sortByColumn,
      sortByOrder: sortByOrder
    }
  }

  function saveAssetGridState (projectId = '', assetGridState, assetViewType) {
    if (!assetViewType) {
      assetViewType = getCurrentViewType()
    }
    let gridKey = _getGridStateKey(projectId, assetViewType, currentUser.userId)
    $window.localStorage.setItem(
        gridKey,
        JSON.stringify(assetGridState))
  }

  function readAssetGridState (projectId = '', assetViewType, searchContext, projectGroupId) {
    if (!assetViewType) {
      assetViewType = getCurrentViewType()
    }
    let gridKey = _getGridStateKey(projectId, assetViewType, currentUser.userId)
    let state = $window.localStorage.getItem(gridKey)
    let ret = defaultAssetGridState(assetViewType, projectId, searchContext)
    if (state && state !== 'undefined') {
      try {
        ret = JSON.parse(state)
      } catch (err) {
        $log.error(err)
        ret = defaultMobileAssetGridState(assetViewType, searchContext)
      }
    } else if (!projectId) {
      ret = defaultMobileAssetGridState(assetViewType, searchContext)
    }
    if (projectGroupId) {
      ret.groupByColumn = 'captureTimePrecisionWeek'
    }
    if (!projectId) {
      ret.groupBy = false
    }
    // Type sort/group column was renamed to typeDetails
    if (ret.sortByColumn === 'type') {
      ret.sortByColumn = 'typeDetails'
    }
    if (ret.groupByColumn === 'type') {
      ret.groupByColumn = 'typeDetails'
    }
    return ret
  }

  function setupTempGridState (projectId, newGridState, assetViewType) {
    let gridState = readAssetGridState(projectId, assetViewType)
    if ('sortByColumn' in newGridState) {
      gridState.sortBy = !!newGridState.sortByColumn
      gridState.sortByColumn = newGridState.sortByColumn
      gridState.sortByOrder = newGridState.sortByOrder
    }
    if ('groupByColumn' in newGridState) {
      gridState.groupBy = !!newGridState.groupByColumn
      gridState.groupByColumn = newGridState.groupByColumn
      gridState.groupByOrder = newGridState.groupByOrder
    } else {
      gridState.groupBy = false
      gridState.groupByColumn = undefined
      gridState.groupByOrder = undefined
    }
    saveAssetGridState(projectId, currentUser.userId, gridState, assetViewType)
  }

  function saveExportAssetsState (projectId, tagDefIds) {
    $window.localStorage.setItem(
        _getExportAssetsStateKey(projectId, currentUser.userId),
        JSON.stringify(tagDefIds))
  }

  function readExportAssetsState (projectId) {
    let state = $window.localStorage.getItem(
        _getExportAssetsStateKey(projectId, currentUser.userId))
    if (state) {
      return JSON.parse(state)
    }
    return undefined
  }

  // //////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////

  function _getGridStateKey (projectId, assetViewType, userId) {
    let viewType = assetViewType
    if (!viewType) {
      viewType = getCurrentViewType()
    }
    if (viewType === AssetViewType.ASSET_LIST) {
      return `assetGrid:assetGridState:${ASSET_GRID_STATE_VERSION}:${userId}:${projectId}`
    } else {
      return `assetGrid:assetGridState:${ASSET_GRID_STATE_VERSION}:${viewType}:${userId}:${projectId}`
    }
  }

  function getCurrentViewType () {
    let currentAssets = dashboardDataHelper.getAssets()
    if (!currentAssets) {
      currentAssets = dashboardDataHelper.getGlobalAssets()
    }
    return currentAssets.assetViewType
  }

  function _getExportAssetsStateKey (projectId, userId) {
    return `assetGrid:assetGridState:${userId}:${projectId}`
  }
})
