import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'

export class ConfirmDialogData {
  title: string
  content: string
  discard: string
  confirm: string
  hideClose: boolean
}

@Component({
  selector: 'sv-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  dialogTitle: string
  dialogContent: string
  discardText: string
  confirmText: string

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmDialogData
  ) {
    this.dialogTitle = this.translate.instant(dialogData.title)
    this.dialogContent = this.translate.instant(dialogData.content)
    this.confirmText = this.translate.instant(dialogData.confirm || 'components.confirmDialog.confirm')
    this.discardText = this.translate.instant(dialogData.discard || 'components.confirmDialog.discard')
  }
}
