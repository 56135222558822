/* global angular */

import { AddOrganizationUserDialogStrategy, AddUserDialogComponent } from 'modules/admin/components/add-user-dialog/add-user-dialog.component'

angular.module('smartvid').directive('usersGridControlbar', function (
    modal, currentUser, dialogUtils) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'UsersGrid/controlbar.html',
    link: function (scope) {
      scope.showSettingsOptions = ($event) => {
        scope.flyout.open('usersGridOptions', {
          parentElement: $event.currentTarget,
          direction: 'top',
          users: scope.users,
          organizationId: scope.organizationId
        })
      }

      scope.openAddUsersModal = function () {
        let organizationId = scope.users.organizationId
        dialogUtils.open(AddUserDialogComponent, {
          data: {
            organizationId: organizationId,
            projectId: undefined,
            strategy: new AddOrganizationUserDialogStrategy()
          }
        })
      }
    }
  }
})
