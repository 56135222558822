//This component is needed to bootstrap angular. It's added in index.html so it's initialized synchronously.
//Otherwise router-hybrid will fail because it tries to retrieve angular injector before angular is initialized.
// https://github.com/ui-router/angular-hybrid/issues/98

import { Component } from '@angular/core'
import { UrlService } from '@uirouter/core'
import { NGXLogger } from 'ngx-logger'
import * as angular from 'angular'

@Component({
  selector: 'sv-bootstrap-angular',
  template: '',
})
export class BootstrapComponent {
  constructor(private logger: NGXLogger) {
    this.logger.log('Angular Bootstrap')
    const injector: angular.auto.IInjectorService = angular.element(document.body).injector()
    const urlService: UrlService = injector.get('$urlService')
    // setTimeout needed to allow angular routes to initialize after refresh
    urlService.listen()
    urlService.sync()
  }
}
