import { Component } from '@angular/core'
import { BaseChartDirective } from 'modules/insights/dashboard/charts/models/base-chart.type'
import { COLUMN_CHART_OPTIONS } from 'modules/insights/dashboard/charts/models/chart-static-options.type'

@Component({
  selector: 'sv-insights-column-chart',
  templateUrl: 'insights-column-chart.component.html',
  styleUrls: ['insights-column-chart.component.scss'],
})
export class InsightsColumnChartComponent extends BaseChartDirective {
  getChartType(): string {
    return 'ColumnChart'
  }

  getPredefinedChartOptions(): {} {
    return COLUMN_CHART_OPTIONS
  }
}
