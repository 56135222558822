/* global angular, _, $ */

angular.module('smartvid').directive('multiTagInput', function multiTagInput (
  $interval, $q, $log, $document, $filter, $stateParams, smartvidApi, utils, i18nUtils, searchResultService, stateIdentificationService, SearchEntityCollection,
  SearchEntityModel, assetGridHelper, flyout, QuickSearchType, $timeout, moment, SEARCH_ENTITY_MODEL_TYPE_TAG_DEFINITION
) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: 'multi-tag-input.html',
    controller: function ($scope) {
      var tagsInputParentCtrl = this
      $scope.tagsInputParentCtrl = tagsInputParentCtrl
      $scope.flyout = flyout
      $scope.didStartSearchFromSearchButton = function () {
        if (tagsInputParentCtrl.proceedWithSearchFromSearchButton) {
          tagsInputParentCtrl.proceedWithSearchFromSearchButton()
        }
      }
      $scope.maxSuggestionsResultsToShow = 8
    },
    scope: {
      projectId: '=',
      handler: '=',
      cancelSearchHandler: '=',
      clearSearchState: '=',
      addTag: '=',
      allowAsr: '=',
      allowImrec: '=',
      disabled: '@?',
      hideIcon: '=',
      searchCriteria: '=',
      defaultText: '=',
      noResultsText: '@',
      enableQuickSearch: '@',
      enableTagSearch: '@',
      enableRecentSearch: '@',
      enableQueryOptions: '@',
      enableExactSearch: '@',
      observationsMode: '@'
    },
    link (scope, element, attrs) {
      scope.rangePickerInFocus = false
      scope.allowAsr = angular.isDefined(scope.allowAsr) || false
      scope.allowImrec = angular.isDefined(scope.allowImrec) || false
      scope.queryType = {
        isOrQuery: scope.searchCriteria.searchQueryType === 'ANY_TERM'
      }
      scope.enableQuickSearch = attrs.enableQuickSearch
      scope.enableTagSearch = attrs.enableTagSearch
      scope.enableRecentSearch = attrs.enableRecentSearch
      scope.enableQueryOptions = attrs.enableQueryOptions === 'true'
      scope.enableExactSearch = attrs.enableExactSearch === 'true'
      scope.isObservationsMode = attrs.observationsMode === 'true'
      scope.dateFormat = attrs.dateFormat ? attrs.dateFormat : 'MM/DD/YYYY'

      scope.hasFocus = false
      scope.helper = assetGridHelper
      scope.placeholder
      scope.isDisabled = isDisabled
      scope.showQueryOptions = showQueryOptions

      scope.shouldRemoveTagsOnInputLostFocus = false

      scope.getStyles = getStyles
      scope.isInFocus = isInFocus
      scope.isQueryOptionsEnabled = isQueryOptionsEnabled
      scope.onSearchFocused = onSearchFocused
      scope.onSearchLostFocus = onSearchLostFocus
      scope.isClearVisible = isClearVisible

      scope.placeholder = $filter('i18next')(scope.defaultText)

      function onSearchFocused () {
        $timeout(() => {
          scope.hasFocus = true
        })
      }

      function onSearchLostFocus () {
        $timeout(() => {
          scope.hasFocus = false
        }, 100)
      }

      function isInFocus () {
        return scope.hasFocus || scope.helper.isActiveSearch() || flyout.isOpenFor('queryOptions') || scope.rangePickerInFocus
      }

      function isDisabled () {
        return searchResultService.isInGlobalSearchContext() &&
          (scope.projectId || stateIdentificationService.isProjectGroupState() || stateIdentificationService.isOrganizationState())
      }

      function isQueryOptionsEnabled () {
        return scope.enableQueryOptions === 'true' && scope.isInFocus()
      }

      scope.onSearch = () => {
        if (scope.projectId) {
          searchResultService.resetCurrentAssetGroup()
        } else {
          searchResultService.resetGlobalAssetGroup()
        }
        scope.helper.setIsActiveSearch(true)
        scope.hasFocus = false
        scope.shouldRemoveTagsOnInputLostFocus = !scope.helper.isActiveSearch()

        configureSearchDateRange()
        configureQueryType()
        scope.handler(scope.searchCriteria)
        $(element).parent().click()

        utils.digest(scope)
      }

      scope.$on('sv-search-context-update', (event) => {
        scope.searchCriteria = searchResultService.getCurrentSearchContext() ? searchResultService.getCurrentSearchContext().getSearchByValues() : []

        if (scope.searchCriteria.length !== 0 || scope.searchCriteria.searchDateRange) {
          scope.helper.setIsActiveSearch(true)
        }

        if (scope.searchCriteria.length === 0 && !scope.helper.isActiveSearch()) {
          clearSearchDateRange()
          scope.queryType.isOrQuery = false
        }
      })

      scope.$on('$destroy', function () {
        $document.off('click', documentClick)
      })

      /*
      * search date range
      */

      $document.on('click', documentClick)

      function configureQueryType () {
        scope.searchCriteria.searchQueryType = (scope.queryType.isOrQuery) ? 'ANY_TERM' : 'ALL_TERMS'
      }

      function documentClick (e) {
        scope.hasFocus = false

        scope.shouldRemoveTagsOnInputLostFocus = !scope.helper.isActiveSearch()

        if (scope.shouldRemoveTagsOnInputLostFocus) {
          scope.searchStartDate = null
          scope.searchEndDate = null
        }

        utils.digest(scope)
      }

      scope.clearDateRange = (e) => {
        if (isDisabled()) {
          e.stopPropagation()
          return
        }
        clearSearchDateRange()
        if (scope.searchCriteria && (scope.searchCriteria.length || scope.searchCriteria.searchDateRange)) {
          scope.onSearch()
        }
        e.stopPropagation()
      }

      scope.handleSearchClick = (e) => {
        scope.hasFocus = true
        scope.shouldRemoveTagsOnInputLostFocus = false
        e.stopPropagation()
      }

      scope.opts = searchDateRangeOptions(scope.searchCriteria.searchDateRange !== undefined)
      scope.date = searchDateRangeDate(scope.searchCriteria.searchDateRange !== undefined)

      function searchStartDate () {
        return scope.searchCriteria.searchDateRange ? moment(scope.searchCriteria.searchDateRange.startDate, scope.dateFormat) : moment()
      }

      function searchEndDate () {
        return scope.searchCriteria.searchDateRange ? moment(scope.searchCriteria.searchDateRange.endDate, scope.dateFormat) : moment()
      }

      function configureSearchDateRange () {
        if (scope.searchStartDate && scope.searchEndDate) {
          scope.searchCriteria.searchDateRange = {
            startDate: scope.searchStartDate.format(scope.dateFormat),
            endDate: scope.searchEndDate.format(scope.dateFormat)
          }
        }
      }

      function searchDateRangeDate (modified) {
        return {
          startDate: searchStartDate(),
          endDate: searchEndDate(),
          modified: modified
        }
      }

      function searchDateRangeOptions (modified) {
        return {
          locale: {
            format: scope.dateFormat,
            separator: '-'
          },
          pickerInitialVal: 'All time - Now',
          pickerModifiedStartDate: modified ? searchStartDate().format(scope.dateFormat) : undefined,
          pickerModifiedEndDate: modified ? searchEndDate().format(scope.dateFormat) : undefined,
          startDate: searchStartDate(),
          endDate: searchEndDate(),
          eventHandlers: {
            'apply.daterangepicker': function (ev, picker) {
              scope.searchStartDate = ev.model.startDate
              scope.searchEndDate = ev.model.endDate
              scope.date = searchDateRangeDate(true)
              scope.helper.setIsActiveSearch(true)
            },
            'show.daterangepicker': function (ev, picker) {
              scope.rangePickerInFocus = true
            },
            'hide.daterangepicker': function (ev, picker) {
              $timeout(() => {
                scope.rangePickerInFocus = false
              }, 100)
            }
          }
        }
      }

      function showQueryOptions (event) {
        if (isDisabled()) {
          return
        }
        event.stopPropagation()
        scope.helper.setIsActiveSearch(true)
        scope.flyout.open('queryOptions', {
          flyoutId: 'queryOptions',
          queryType: scope.queryType
        })
      }
      /*
      *  search specific
      */

      scope.$on('sv-tag-node-search', (event, tag) => {
        let searchTag = _.map([tag], function (aTag) {
          return new SearchEntityModel({
            id: aTag.tagDefinitionId,
            text: aTag.title,
            type: SEARCH_ENTITY_MODEL_TYPE_TAG_DEFINITION,
            hasChildren: aTag.children.length > 0
          })
        })

        clearSearchDateRange()
        scope.helper.setIsActiveSearch(true)
        scope.handler(searchTag)
      })

      scope.cancelSearch = (e) => {
        cancelSearchHelper()
        e.stopPropagation()
      }

      function clearSearchDateRange () {
        let dateRangePicker = $(element).find('.date-range-picker')
        if (dateRangePicker && dateRangePicker.data('daterangepicker') && dateRangePicker.data('daterangepicker').clear) {
          dateRangePicker.data('daterangepicker').clear()
        }

        scope.searchStartDate = null
        scope.searchEndDate = null
        scope.searchCriteria.searchDateRange = undefined

        scope.opts = searchDateRangeOptions(false)
        scope.date = searchDateRangeDate(false)
      }

      function cancelSearchHelper () {
        scope.hasFocus = false
        scope.helper.setIsActiveSearch(false)
        scope.cancelSearchHandler()
        utils.digest(scope)
        $(element).parent().click()
      }

      let searchEntities = (text, isSystemEntitySearch) => {
        let defer = $q.defer()
        if (!text.trim()) {
          defer.resolve({'data': []})
        } else {
          let api = isSystemEntitySearch
            ? _.bind(smartvidApi.findSystemSearchableEntity, undefined, text.trim())
            : _.bind(smartvidApi.findSearchableEntity, undefined, scope.projectId, text.trim())
          api().then((data) => {
            let expandedEntities = []
            _.each(data, (entity) => {
              if (entity.hasChildren) {
                let copy = _.clone(entity)
                copy.hasChildren = false
                expandedEntities.push(copy)
              }
              expandedEntities.push(entity)
            })

            let entities = new SearchEntityCollection(expandedEntities)
            defer.resolve({'data': entities.models})
          },
          (data) => {
            $log.error('Failure to search entities  ', data)
            defer.resolve({'data': []})
          })
        }
        return defer.promise
      }

      scope.searchEntities = (text) => {
        return searchEntities(text, false)
      }
      scope.searchSystemEntities = (text) => {
        return searchEntities(text, true)
      }

      function getStyles () {
        if (scope.helper.isActiveSearch() && !scope.hasFocus) {
          return {
            'overflow-x': 'auto'
          }
        } else {
          return {
            'z-index': '9999999',
            'position': 'relative'
          }
        }
      }

      function isClearVisible () {
        return scope.isObservationsMode && scope.helper.isActiveSearch()
      }
    }
  }
})

angular.module('smartvid').directive('tagContentEditable', function ($timeout, $sce) {
  return {
    restrict: 'A',
    require: ['^tagsInput', '?ngModel'],
    link: function (scope, element, attrs, requires) {
      let tagsInputCtrl = requires[0]
      let ngModel = requires[1]

      if (!ngModel) {
        return
      }

      let listener = $(element).on('keypress', (event) => {
        if (event.which === 13) {
          event.preventDefault()
          tagsInputCtrl.fireSearch()
        }
      })
      scope.$on('$destroy', function () {
        $(element).off('keypress', listener)
      })

      ngModel.$render = () => {
        element.html($sce.getTrustedHtml(ngModel.$viewValue || ''))
      }

      // Listen for change events to enable binding
      element.on('blur keyup change', () => {
        scope.$evalAsync(read)
      })

      function read () {
        var html = element.text()
        if (attrs.stripBr && html === '<br>') {
          html = ''
        }
        ngModel.$setViewValue(html)
      }
    }
  }
})
