/* global angular */

angular.module('smartvid').directive('thumbnailLock', ($rootScope) => {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: 'ProjectGrid/thumbnail-lock.html',
    link: (scope) => {
    }
  }
})
