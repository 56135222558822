import { Directive, Input, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core'
import { DashboardCardId, DashboardTabId } from 'modules/insights/dashboard/models/insights-dashboard.model'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'

interface CellData {
  tabId?: DashboardTabId
  cardId: DashboardCardId
}

@Directive({
  selector: '[svDashboardGridCell]',
})
export class DashboardGridCellDirective {
  private cellData: CellData

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private dashboardConfigService: InsightsDashboardConfigurationService
  ) {}

  @Input()
  set svDashboardGridCell(callData: CellData) {
    this.cellData = callData
    this.updateView()
  }

  get svDashboardGridCell() {
    return this.cellData
  }

  private updateView() {
    let classes: string[]
    let order: number
    if (
      this.cellData.tabId &&
      this.cellData.cardId &&
      this.dashboardConfigService.hasTab(this.cellData.tabId) &&
      this.dashboardConfigService.hasTabCard(this.cellData.tabId, this.cellData.cardId)
    ) {
      classes = this.dashboardConfigService.getTabCardClasses(this.cellData.tabId, this.cellData.cardId)
      order = this.dashboardConfigService.getTabCardOrder(this.cellData.tabId, this.cellData.cardId)
    } else if (this.cellData.cardId && this.dashboardConfigService.hasSummaryCard(this.cellData.cardId)) {
      classes = this.dashboardConfigService.getSummaryCardClasses(this.cellData.cardId)
      order = this.dashboardConfigService.getSummaryCardOrder(this.cellData.cardId)
    } else {
      this.viewContainer.clear()
      return
    }

    let view = this.viewContainer.createEmbeddedView(this.templateRef)
    view.rootNodes[0].classList.add(...classes)
    view.rootNodes[0].style.order = `${order}`
  }
}
