<div class="organization-list-container">
    <mat-action-list>
        <button mat-list-item (click)="clickFunc(org)" 
        matTooltip="{{org.name }}"
        matTooltipPosition='right'
        matTooltipClass="project-tooltip"
        *ngFor="let org of organizations | OrganizationSortListPipe" [ngClass]="{'active': isActiveOrg(org)}">
            <mat-icon svgIcon="all_inbox"></mat-icon>
            <span class="node-name-span">{{ org.name }}</span>
        </button>
    </mat-action-list>
</div>