/* global angular, _ */

angular.module('smartvid').service('videoPlayer', function ($rootScope, $interval, utils) {
  // The webkit HTML5 video player can handle fetching the currenTime more often then 250ms. However, other
  // video players, namely FF, appear to cache that value and thus return values that have not updated. This
  // has the negative effect of us thinking that the player buffered playback. Also 250 is not really a magic number,
  // The progress event built into the players is intended to fire every 250ms.
  const PLAYER_INTERVAL = utils.isWebkit() ? 50 : 250
  let playerInstance
  let progressInterval

  // The player timeupdate event only fires 250ms on most browsers. This make the progres feel very jumpy. After chatting with
  // Andrew we agreed that the current polling feels much more natural. However, looking at video.js and youtube we can see that
  // use the timeudpate event so we should be mindful of this.
  //
  // TODO - Decide if this has a performance hit on the assetview. If so One possibility here is to use native setInterval vs an
  // angular $interval. This will remove it form the normal digest cycle
  // And thus we can control which items are actually updated on the screen rather then doing a full repaint. Alternatively we can
  // Start and cancel this based on whether or not the player is player.
  let pollForPlaybackProgressAnBuffering = function () {
    progressInterval = $interval(function () {
      if (player.ready()) {
        $rootScope.$broadcast('sv-player-playback-progress')

        if (player.currentTime() >= player.endLimit && playerInstance.currentTime !== player.startLimit) {
          playerInstance.currentTime = player.startLimit
          player.pause()
        }
      }
    }, PLAYER_INTERVAL)
  }

  let playbackTimeout
  let position
  let stalled = false

  // Broadcast notifications when the player is detected to have stalled, while buffering the content
  let checkStalled = (player, ignorePaused) => {
    clearTimeout(playbackTimeout)
    position = player.currentTime
    if (ignorePaused || !player.paused) {
      playbackTimeout = setTimeout(() => {
        if (player.currentTime === position) {
          stalled = true
          $rootScope.$broadcast('sv-player-is-buffering')
        } else {
          stalled = false
          $rootScope.$broadcast('sv-player-done-buffering')
        }
      }, 256)
    } else if (stalled) {
      stalled = false
      $rootScope.$broadcast('sv-player-done-buffering')
    }
  }

  let configureStallDetection = (player) => {
    player.ontimeupdate = function () {
      checkStalled(player, false)
    }
  }

  let player = {

    set (player) {
      playerInstance = player

      this.loadedmetadata = false
      playerInstance.onloadedmetadata = () => {
        this.loadedmetadata = true
      }

      configureStallDetection(playerInstance)

      playerInstance.onplay = function () {
        $rootScope.$broadcast('sv-player-play')
      }

      playerInstance.onseeked = function () {
        let payload = {
          currentTime: (playerInstance) ? playerInstance.currentTime : 0
        }
        $rootScope.$broadcast('sv-player-seeked', payload)
      }

      playerInstance.onprogress = function (evt, data) {
        let buffered = this.buffered

        if (buffered.length === 0) {
          return
        }

        let payload = {
          start: buffered.start(buffered.length - 1),
          end: buffered.end(buffered.length - 1)
        }

        $rootScope.$broadcast('sv-player-load-progress', payload)
      }

      playerInstance.oncanplay = function () {
        $rootScope.$broadcast('sv-player-canplay')
      }

      pollForPlaybackProgressAnBuffering()
    },

    limitView (start, end) {
      this.startLimit = start
      this.endLimit = end
    },

    destroy () {
      playerInstance = undefined
      if (angular.isDefined(progressInterval)) {
        $interval.cancel(progressInterval)
      }
    },
    ready () {
      return this.loadedmetadata && playerInstance
    },
    pause () {
      if (this.ready()) {
        playerInstance.pause()
        let payload = {
          currentTime: playerInstance.currentTime
        }
        $rootScope.$broadcast('sv-player-pause', payload)
      }
    },
    play () {
      if (this.ready()) {
        if (this.endLimit) {
          if (playerInstance.currentTime >= this.endLimit) {
            playerInstance.currentTime = this.startLimit
          }
        }
        playerInstance.play()
      }
    },
    togglePlay () {
      if (this.ready()) {
        if (playerInstance.paused) {
          playerInstance.play()
        } else {
          playerInstance.pause()
        }
      }
    },
    frameForward () {
      if (this.ready()) {
        if (this.endLimit) {
          if (playerInstance.currentTime >= this.endLimit) {
            playerInstance.currentTime = playerInstance.currentTime + 0.1
          }
        } else {
          playerInstance.currentTime = playerInstance.currentTime + 0.1
        }
      }
    },
    frameBackward () {
      if (this.ready()) {
        if (this.startLimit) {
          if (playerInstance.currentTime <= this.startLimit) {
            playerInstance.currentTime = playerInstance.currentTime - 0.1
          }
        } else {
          playerInstance.currentTime = playerInstance.currentTime - 0.1
        }
      }
    },

    currentTime (time) {
      if (!this.ready()) {
        return 0
      }

      if (time === undefined) {
        return playerInstance.currentTime
      }
      // When viewing a shared tag make sure to limit the playable frames
      if (!_.isNaN(time)) {
        playerInstance.currentTime = time
      }

      checkStalled(playerInstance, true)

      return playerInstance.currentTime
    },

    duration () {
      if (this.ready()) {
        if (this.startLimit || this.endLimit) {
          return this.endLimit - this.startLimit
        } else {
          return playerInstance.duration
        }
      }

      return 0
    },

    paused () {
      if (this.ready()) {
        return playerInstance.paused
      }
    }

  }

  return player
})
