/* global angular */
/**
 * Created by ryanrubbico on 9/6/17.
 *
 * Settings:
 *  selected      (Boolean)  indicates selected state of item to be used by star
 *  onSelect      (Function) function called when star enters selected state
 *  onUnselect    (Function) function called when star enters unselected state
 */
angular.module('smartvid').directive('starButton', () => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'star-button.html',
    transclude: true,
    scope: {
      onSelect: '&starOnSelect',
      onUnselect: '&starOnUnselect',
      selected: '=starSelected',
      selectedHoverText: '@?',
      unselectedHoverText: '@?'
    },
    link (scope) {
      scope.select = select

      function select ($event) {
        if ($event) {
          $event.stopPropagation()
          $event.preventDefault()
        }
        if (scope.selected) {
          scope.onUnselect()
        } else {
          scope.onSelect()
        }

        scope.selected = !scope.selected
      }
    }
  }
})
