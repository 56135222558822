import _ from 'lodash'
import {
  DashboardDataObjectResponseBase,
  ScopeObjectTableInfo,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { ChartData } from 'modules/insights/dashboard/charts/models/base-chart.type'
import {
  COLUMN_CHART_OPTIONS,
  LINE_CHART_OPTIONS,
  PIE_CHART_OPTIONS,
} from 'modules/insights/dashboard/charts/models/chart-static-options.type'

export class OrganizationSingleValueDashboardData extends DashboardDataObjectResponseBase {
  uploadedFilesTotal: number
  uploadedFilesRatioPercentChange: number
  uploadersRatioPercentChange: number
  uploadersTotal: number
  tagsTotal: number
  tagsTotalRatioPercentChange: number
  ppeCompliancePercentAvg: number
  ppeCompliancePercentAvgChange: number
  workAtHeightAvg: number
  workAtHeightAvgPercentChange: number
  slipAndTripAvg: number
  slipAndTripAvgPercentChange: number
  constructionPhotosToAllPhotosRatioAvg: number
  constructionPhotosToAllPhotosRatioAvgPercentChange: number
  laddersToLiftsRatioAvg: number
  laddersToLiftsRatioAvgPercentChange: number
  peoplePerXConstructionAvg: number
  peoplePerXConstructionAvgPercentChange: number
  avgPhotosCreatedPerDay: number
  avgPhotosCreatedPerDayPercentChange: number
  avgPhotoDiffCreatedUploaded: number
  avgPhotoDiffCreatedUploadedPercentChange: number
  percentPersonCoverage: number
  percentPersonCoveragePercentChange: number
  percentOfAssetsWithoutCreatedDate: number
  percentOfAssetsWithoutCreatedDatePercentChange: number
  percentOfConstructionAssetsWithoutCreatedDate: number
  percentOfConstructionAssetsWithoutCreatedDatePercentChange: number
  glassesComplianceAvg: number
  glovesComplianceAvg: number
  hardHatComplianceAvg: number
  highVizComplianceAvg: number
}

export class OrganizationAssetsActivityTableData {
  organizationName: string
  scopeObjectInfo: ScopeObjectTableInfo
  scopeObjectCountsInfo: OrganizationAssetsActivityTableDataCountsInfo
  chartData: ChartData
}

export class OrganizationAssetsActivityTableDataCountsInfo {
  projectScore: number
  filesCount: number
  filesCountRatio: number
  tagCount: number
  tagCountRatio: number
}

export class DashboardOrganizationActivityTableDataResponse extends DashboardDataObjectResponseBase {
  tableDate: OrganizationAssetsActivityTableData[]
}

export class OrganizationAssetsSafetyTableData {
  organizationName: string
  scopeObjectInfo: ScopeObjectTableInfo
  scopeObjectCountsInfo: OrganizationAssetsSafetyTableDataCountsInfo
}

export class OrganizationAssetsSafetyTableDataCountsInfo {
  projectScore: number
  avgPpe: number
  avgPpePercentChange: number
  workAtHeights: number
  workAtHeightsPercentChange: number
  slipAndTrip: number
  slipAndTripPercentChange: number
  laddersToLiftsRatio: number
  laddersToLiftsRatioPercentChange: number
}

export class DashboardOrganizationSafetyTableDataResponse extends DashboardDataObjectResponseBase {
  tableDate: OrganizationAssetsSafetyTableData[]
}

export const PROJECT_OR_GROUP_ACTIVITY_CHART_OPTIONS = {
  legend: {
    position: 'none',
  },
  chartArea: { left: 0, top: '5%', width: '100%', height: '90%' },
  hAxis: {
    baselineColor: '#323232',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    baselineColor: '#323232',
    gridlines: {
      color: '#323232',
    },
  },
  tooltip: {
    isHtml: true,
  },
  colors: ['#cf1c0e'],
}

export const PROJECT_OR_GROUP_ACTIVITY_CHART_DATA_COLUMNS: any[] = [
  'dashboard.organizationAssets.activityChart.columns.date',
  'dashboard.organizationAssets.activityChart.columns.count',
  { type: 'string', role: 'tooltip', p: { html: true } },
]

export const ORG_UPLOADS_OVERTIME_BY_SOURCE_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.uploadsOverTimeChart.columns.date',
]

export const ORG_UPLOADS_OVERTIME_CHART_OPTIONS = _.merge({}, COLUMN_CHART_OPTIONS, {
  chartArea: {
    height: '70%',
  },
  isStacked: true,
  hAxis: {
    format: 'MMM d, y',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    // textPosition: 'none',
  },
  legend: {
    position: 'bottom',
  },
})

export const ORG_UPLOADERS_OVERTIME_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.uploadersOverTimeChart.columns.date',
  'dashboard.organizationAssets.uploadersOverTimeChart.columns.uploadersSv',
  'dashboard.organizationAssets.uploadersOverTimeChart.columns.uploadersIntegration',
]

export const ORG_UPLOADERS_OVERTIME_CHART_OPTIONS = _.merge({}, COLUMN_CHART_OPTIONS, {
  chartArea: {
    height: '73%',
  },
  isStacked: true,
  hAxis: {
    format: 'MMM d, y',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    // textPosition: 'none',
  },
  legend: {
    position: 'bottom',
  },
})

export const ORG_CONSTRUCTION_ACTIVITY_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.constructionActivityChart.columns.activity',
  'dashboard.organizationAssets.constructionActivityChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const ORG_CONSTRUCTION_ACTIVITY_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const ORG_PHOTO_DESCRIPTION_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.photoDescriptionChart.columns.description',
  'dashboard.organizationAssets.photoDescriptionChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const ORG_PHOTO_DESCRIPTION_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const ORG_UPLOADS_BY_FILE_TYPE_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.uploadsByFileTypeChart.columns.fileType',
  'dashboard.organizationAssets.uploadsByFileTypeChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const ORG_UPLOADS_BY_FILE_TYPE_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const ORG_TAGS_BY_TYPE_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.tagsByTypeTypeChart.columns.tagType',
  'dashboard.organizationAssets.tagsByTypeTypeChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const ORG_TAGS_BY_TYPE_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const ORG_PEOPLE_IN_GROUPS_OVERTIME_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.peopleInGroupsOverTimeChart.columns.date',
  'dashboard.organizationAssets.peopleInGroupsOverTimeChart.columns.count',
]

export const ORG_PEOPLE_IN_GROUPS_OVERTIME_CHART_OPTIONS = _.merge({}, LINE_CHART_OPTIONS, {
  chartArea: {
    height: '75%',
  },
  hAxis: {
    format: 'MMM d, y',
    gridlines: {
      color: '#323232',
    },
  },
  legend: {
    position: 'bottom',
  },
})

export const ORG_PPE_COMPLIANCE_OVERTIME_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.ppeComplianceOverTimeChart.columns.date',
  'dashboard.organizationAssets.ppeComplianceOverTimeChart.columns.compliance',
  { type: 'string', role: 'tooltip' },
  'dashboard.organizationAssets.ppeComplianceOverTimeChart.columns.complianceCutoff',
  { type: 'string', role: 'tooltip' },
]

export const ORG_PPE_COMPLIANCE_OVERTIME_CHART_OPTIONS = _.merge({}, LINE_CHART_OPTIONS, {
  hAxis: {
    format: 'MMM, y',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    format: "#'%'",
    minValue: 0,
  },
  legend: {
    position: 'none',
  },
})

export const ORG_WORK_AT_HEIGHTS_OVERTIME_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.workAtHeightsOverTimeChart.columns.date',
]

export const ORG_WORK_AT_HEIGHTS_OVERTIME_CHART_OPTIONS = _.merge({}, COLUMN_CHART_OPTIONS, {
  chartArea: {
    height: '73%',
  },
  isStacked: true,
  hAxis: {
    format: 'MMM, y',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    // textPosition: 'none',
  },
  legend: {
    position: 'bottom',
  },
})

export const ORG_WORK_AT_HEIGHTS_PER_X_ASSETS_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.workAtHeightPerXAssetsChart.columns.type',
  'dashboard.organizationAssets.workAtHeightPerXAssetsChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const ORG_WORK_AT_HEIGHTS_PER_X_ASSETS_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const ORG_LADDERS_PER_X_ASSETS_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.laddersPerXAssetsChart.columns.type',
  'dashboard.organizationAssets.laddersPerXAssetsChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const ORG_LADDERS_PER_X_ASSETS_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const ORG_LIFTS_PER_X_ASSETS_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.liftsPerXAssetsChart.columns.type',
  'dashboard.organizationAssets.liftsPerXAssetsChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const ORG_LIFTS_PER_X_ASSETS_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const ORG_HOUSEKEEPING_AND_STANDING_WATER_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.housekeepingAndStandingWaterChart.columns.type',
  'dashboard.organizationAssets.housekeepingAndStandingWaterChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const ORG_HOUSEKEEPING_AND_STANDING_WATER_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const ORG_SLIP_AND_TRIP_OVERTIME_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.slipAndTripOverTimeChart.columns.date',
]

export const ORG_SLIP_AND_TRIP_OVERTIME_CHART_OPTIONS = _.merge({}, COLUMN_CHART_OPTIONS, {
  chartArea: {
    height: '73%',
  },
  isStacked: true,
  hAxis: {
    format: 'MMM, y',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    // textPosition: 'none',
  },
  legend: {
    position: 'bottom',
  },
})

export const ORG_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK_CHART_OPTIONS = _.merge({}, COLUMN_CHART_OPTIONS, {
  chartArea: {
    height: '73%',
  },
  isStacked: true,
  hAxis: {
    gridlines: {
      color: '#323232',
    },
  },
  legend: {
    position: 'bottom',
  },
})

export const ORG_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK_CHART_COLUMNS: any[] = [
  'dashboard.organizationAssets.avgPhotosCreatedByDayOfWeekChart.columns.dayOfWeek',
  'dashboard.organizationAssets.avgPhotosCreatedByDayOfWeekChart.columns.avgCreated',
]
