/* global angular */

angular.module('smartvid').factory('TagTreeNodeModel', function (BaseModel) {
  const PROTECTED_TAG_NAMES = ['Construction']

  class TagTreeNodeModel extends BaseModel {
    constructor (attrs) {
      let model = { // TODO make this a little cleaner using _.defaults
        children: attrs.childNodes,
        title: attrs.data.text,
        type: attrs.data.tagDefinition.type,
        createdTime: attrs.data.tagDefTreeNode.createdTime,
        deleted: attrs.data.tagDefTreeNode.deleted,
        id: attrs.data.tagDefTreeNode.id,
        organizationId: attrs.data.tagDefTreeNode.organizationId,
        projectId: attrs.data.tagDefTreeNode.projectId,
        rootNode: attrs.data.tagDefTreeNode.rootNode,
        nodeOrder: attrs.data.tagDefTreeNode.nodeOrder,
        tagDefinitionId: attrs.data.tagDefTreeNode.tagDefinitionId,
        updatedTime: attrs.data.tagDefTreeNode.updatedTime,
        canUpdate: attrs.canUpdate,
        selected: false,
        collapsed: false,
        selectionDisabled: false
      }
      super(model)
    }

    isProtectedTag () {
      // TODO: PL: This should also/only account for the type (e.g. VINNIE) but for backward compatibility check only name for now
      return PROTECTED_TAG_NAMES.indexOf(this.title) >= 0
    }
  }

  return TagTreeNodeModel
})
