/* global angular */

angular.module('smartvid').directive('usersGridInfoPanel', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'UsersGrid/infopanel.html',
    link (scope) {
    }
  }
})
