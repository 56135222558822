/* global angular */

angular.module('smartvid').directive('projectMembers', function ($state, $filter, $timeout, $rootScope,
                                                                 listViewUtils, i18nUtils, uiGridConstants, $templateCache,
                                                                 flyout, smartvidApi, currentUser) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'ProjectMemberGrid/project-members.html',
    link: function (scope) {
      let gridApi

      scope.data = scope.projectMembers.models
      scope.performSearch = searchProjectMember

      scope.showingRow = 0
      scope.showingRowEnd = 0
      scope.totalRow = 0
      scope.isActiveProject = !scope.$parent.currentProject.isInactive

      listViewUtils.initGridHeader('project-members-list-view-header', 'directives.project-members-list.search.placeholder')

      scope.gridOptions = listViewUtils.createDefaultGridOptions(
        scope, 'project-members-list-view-header', sortChanged,
        'data', nextPageCallback,
        loadFirstPageCallback, setGridApiCallback, scope.isActiveProject ? rowSelectionChangedCallback : undefined)

      scope.gridOptions.enableSorting = true
      scope.gridOptions.useExternalSorting = false

      i18nUtils.loadLabel(() => {
        scope.gridOptions.columnDefs = [
          {
            cellTemplate: 'custom-project-members-list-view-checkbox',
            width: 30,
            name: 'checkbox',
            displayName: '',
            enableSorting: false
          },
          {
            sortName: 'name',
            field: 'getName()',
            cellTooltip: (row) => {
              return row.entity.getName()
            },
            displayName: $filter('i18next')('directives.project-members-list.nameColumn')
          },
          {
            sortName: 'email',
            name: 'email',
            sort: {
              direction: uiGridConstants.ASC,
              priority: 1
            },
            cellTooltip: (row) => {
              return row.entity.email
            },
            displayName: $filter('i18next')('directives.project-members-list.emailColumn')
          },
          {
            sortName: 'roleName',
            field: 'getRolei18nLabel()',
            cellTooltip: (row) => {
              return row.entity.getRolei18nLabel()
            },
            width: 120,
            displayName: $filter('i18next')('directives.project-members-list.filesPermissionColumn')
          },
          {
            sortName: 'observationRoleName',
            field: 'getObservationRolei18nLabel()',
            cellTooltip: (row) => {
              return row.entity.getObservationRolei18nLabel()
            },
            width: 120,
            visible: currentUser.isObservationEnabledForOrganization(scope.currentProject.organizationId),
            displayName: $filter('i18next')('directives.project-members-list.observationsPermissionColumn')
          },
          {
            sortName: 'status',
            field: 'getStatus()',
            cellTooltip: (row) => {
              return row.entity.getStatus()
            },
            width: 100,
            displayName: $filter('i18next')('directives.project-members-list.statusColumn')
          },
          {
            cellTemplate: 'custom-project-members-list-menu-tag',
            width: 30,
            name: 'menu',
            displayName: '',
            enableSorting: false
          }
        ]
      })

      $templateCache.put('custom-project-members-list-view-checkbox',
        '<div class="checkbox-container">' +
        '  <label class="checkbox" ng-click="grid.appScope.handleCheckbox($event, row.entity)">' +
        '   <div class="custom-checkbox" ng-class="{ checked: row.entity.selected }">' +
        '     <icon-checkmark ng-if="row.entity.selected" class="fill-red icon-checkmark"></icon-checkmark>' +
        '   </div>' +
        '   <input type="checkbox" ng-model="row.entity.selected">' +
        '  </label>' +
        '</div>'
      )

      if (scope.isActiveProject) {
        $templateCache.put('custom-project-members-list-menu-tag',
            '<div class="tag-column">' +
            '  <thumbnail-lock class="thumbnail-lock" ng-if="!row.entity.canUpdate || isActiveProject "></thumbnail-lock>' +
            '  <icon-options ng-if="row.entity.canUpdate" class="icon-options" ' +
            '     ng-click="grid.appScope.showProjectMemberOptions($event, row.entity)" ' +
            '     ng-class="{\'active\': hovering || isFlyoutOpenFor(row.entity.id) || row.entity.selected}">' +
            '  </icon-options>' +
            '</div>'
        )
      } else {
        $templateCache.put('custom-project-members-list-menu-tag',
            '<div class="tag-column">' +
            '  <thumbnail-lock class="thumbnail-lock"></thumbnail-lock>' +
            '</div>'
        )
      }

      scope.isFlyoutOpenFor = (flyoutId) => {
        return flyout.isOpenFor(flyoutId)
      }

      function loadFirstPage () {
        //getTotalRow()
        return scope.projectMembers.nextPage().then(() => {
          scope.data = scope.projectMembers.models
          //initDataWatcher()
          gridApi.infiniteScroll.dataLoaded()
        })
      }

      function sortChanged (grid, sortColumns) {
        if (!sortColumns || sortColumns.length !== 1) {
          // Sorting by multiple columns is not supported
          return
        }

        $timeout(() => {
          gridApi.infiniteScroll.setScrollDirections(false, false)

          scope.data = []

          scope.projectMembers.reset()
          scope.projectMembers.options.sortColumn = [sortColumns[0].colDef.sortName + ':' + sortColumns[0].sort.direction]
          if (scope.query) {
            searchProjectMember()
            gridApi.infiniteScroll.resetScroll()
          } else {
            loadFirstPage().then(() => {
              $timeout(() => {
                gridApi.infiniteScroll.resetScroll()
              })
            })
          }
        })
      }

      scope.showProjectMemberOptions = ($event, projectMember) => {
        $event.stopPropagation()
        flyout.open('projectMemberOptions', {
          parentElement: $event.currentTarget,
          direction: 'left',
          projectMember: projectMember,
          flyoutId: projectMember.userId,
          projectObj: scope.$parent.currentProject,
          projectMembers: projectMember
        })
      }

      scope.handleCheckbox = ($event, projectMember) => {
        $event.target.blur()
        if (!$event.shiftKey) {
          $event.stopPropagation()
        }

        $timeout(() => {
          if (projectMember.selected && !$rootScope.lastSelectedPlacard) {
            $rootScope.lastSelectedPlacard = projectMember
          }
          $rootScope.$broadcast('sv-placards-selected')
        })
      }

      scope.$on('sv-project-members-invited-to-project', onProjectMemberListUpdated)
      scope.$on('sv-project-members-uninvited-from-project', onProjectMemberListUpdated)

      // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      function searchProjectMember () {
        if (!scope.query || scope.query.length === 0 || !scope.currentProject) {
          scope.projectMembers.reset()
          loadFirstPageCallback()
        } else {
          scope.data = []
          smartvidApi.searchProjectUsers(scope.currentProject.id, scope.query, {}).then((projectMembers) => {
            scope.projectMembers.models = projectMembers
            scope.data = scope.projectMembers.models
            gridApi.infiniteScroll.dataLoaded()
          })
        }
      }

      function onProjectMemberListUpdated () {
        scope.data = scope.projectMembers.models
        scope.totalRow = scope.data.length
        gridApi.infiniteScroll.dataLoaded()
      }

      function nextPageCallback () {
        if (scope.query && scope.query.length !== 0) {
          return
        }
        if (scope.projectMembers.canFetch && !scope.projectMembers.isFetching) {
          scope.projectMembers.nextPage().then(() => {
            scope.data = scope.projectMembers.models
            scope.totalRow = scope.projectMembers.totalRows
            gridApi.infiniteScroll.dataLoaded()
          })
        }
      }

      function loadFirstPageCallback () {
        return scope.projectMembers.nextPage().then(() => {
          scope.data = scope.projectMembers.models
          scope.totalRow = scope.projectMembers.totalRows
          gridApi.infiniteScroll.dataLoaded()
        })
      }

      function setGridApiCallback (api) {
        gridApi = api
      }

      function rowSelectionChangedCallback (row) {
        $state.go('dashboard.projects.projectId.members.memberProfile', {userId: row.entity.userId})
      }
    }
  }
})
