/* global angular, _, $ */
import SparkMD5 from 'spark-md5'

angular.module('smartvid').service('utils', function (
    $timeout, $window, $document, $injector, $filter, $q, config, $log, LEFT_ARROW_KEY_CODE, RIGHT_ARROW_KEY_CODE,
    UP_ARROW_KEY_CODE, DOWN_ARROW_KEY_CODE
) {
  let isTrueObject = (obj) => {
    if (_.isUndefined(obj)) {
      return false
    }

    if (_.isObject(obj) && !_.isFunction(obj) && !_.isArray(obj) && !_.isElement(obj)) {
      return true
    }

    return false
  }

  let isValidNumber = (number) => {
    if (_(number).isNumber() && !_(number).isNaN()) {
      return true
    }

    return false
  }

  let makeNumber = function (text) {
    let possibleNumber = parseInt(text, 10)

    if (isValidNumber(possibleNumber)) {
      return possibleNumber
    } else {
      return text
    }
  }

  let findValue = (obj, propToFind) => {
    if (!_.isString(propToFind) || _.isEmpty(propToFind) || !obj) {
      throw new Error('The second argument supplied to findValue() should be a string of the property name that you\'re looking for.')
    }

    let foundValue = null

    _.find(obj, function (value, key) {
      if (String(key).toLowerCase() === String(propToFind).toLowerCase()) {
        foundValue = value
      } else if (isTrueObject(value) && !_.isElement(value)) {
        foundValue = findValue(value, propToFind)
      }
      if (foundValue) {
        return foundValue
      }
    })

    return foundValue
  }

  let findValues = (obj, arrOfValues) => {
    if (!_.isArray(arrOfValues)) {
      throw new Error('findValues() expects an array of strings for property names to find')
    }

    let foundValues = {}

    _.each(arrOfValues, function (propToFind) {
      let value = foundValues[propToFind] = findValue(this, propToFind)
      return value
    }, obj)

    return foundValues
  }

  let override = (startWith, overrideWith) => {
    let cleaned = {}

    if (!_.isObject(startWith || !_.isObject(overrideWith))) {
      throw new Error('Both arguments supplied to override() should be shallow objects.')
    }

    cleaned = _.defaults(startWith, overrideWith)

    angular.forEach(startWith, function (value, key) {
      return angular.forEach(overrideWith, function (overrideItemValue, overrideItemKey) {
        if (key === overrideItemKey) {
          let modified = cleaned[key] = overrideItemValue
          return modified
        }
      })
    })

    return cleaned
  }

  let getFilename = (key, dropExtension) => {
    let filename = _.last(key.split('/'))

    if (!dropExtension) {
      dropExtension = true
    }

    if (!dropExtension) {
      return filename
    }

    return _.first(filename.split('.'))
  }

  let createGuid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
    }

    let guid = `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
    return guid
  }

  let _intersectionObjects2 = (a, b, comparator) => {
    var results = []

    for (var i = 0; i < a.length; i++) {
      var aElement = a[i]
      var existsInB = _.any(b, function (bElement) {
        return comparator(bElement, aElement)
      })

      if (existsInB) {
        results.push(aElement)
      }
    }

    return results
  }

  let capitalizeFirstLetter = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  let intersectionOfObjects = function () {
    if (!arguments[0]) {
      return []
    }

    let results = arguments[0]

    // Handle scenario where only a function was passed in. Prevents too many digests from being called.
    if (_.isFunction(results)) {
      return []
    }

    let lastArgument = arguments[arguments.length - 1]
    let arrayCount = arguments.length
    let areEqualFunction = _.isEqual

    if (_.isFunction(lastArgument)) {
      areEqualFunction = lastArgument
      arrayCount--
    }

    for (let i = 1; i < arrayCount; i++) {
      let array = arguments[i]
      results = _intersectionObjects2(results, array, areEqualFunction)
      if (results.length === 0) break
    }

    return results
  }

  // TODO (rrubbico): FIXME $timeout and $apply both trigger digest so this is redundant. Also, naming it digest is misleading bc its actually an $apply happening
  let digest = (scope) => {
    return $timeout(() => {
      scope.$apply()
    }, 0)
  }

  let getExtention = (fileName) => {
    let i = fileName.lastIndexOf('.')
    if (i === -1) {
      return ''
    }
    return fileName.slice(i + 1).toUpperCase()
  }

  let isVideo = (file) => {
    if (file.type === '') {
      return _.indexOf(
        ['MOV', 'MPG', 'AVI', 'FLV', 'F4V', 'MP4', 'M4V', 'ASF', 'WMV', 'VOB', 'MOD', '3GP', 'MKV', 'DIVX', 'XVID'],
        getExtention(file.name)) >= 0
    }
    return file.type.indexOf('video') > -1
  }

  let isImage = (file) => {
    if (file.type === '') {
      return _.indexOf(
        ['JPG', 'JPEG', 'PNG', 'TIFF', 'GIF', 'BMP'],
        getExtention(file.name)) >= 0
    }
    return file.type.indexOf('image') > -1
  }

  let percentToInt = (percent, width) => {
    width = (typeof width === 'string' && width.indexOf('px') > -1) ? width.split('px').shift() : width
    percent = (typeof percent === 'string' && percent.indexOf('%') > -1) ? percent.split('%').shift() : percent
    percent = percent / 100

    return width * percent
  }

  let intToPercent = (width, parentWidth) => {
    return width / parentWidth * 100 + '%'
  }

  let getRandomNodeOrder = (min, max) => {
    return Math.random() * (max - min) + min
  }

  let isURL = (url) => {
    if (!_.isString(url)) {
      return false
    }

    var urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$'
    var regex = new RegExp(urlRegex, 'i')
    return url.length < 2083 && url.test(regex)
  }

  let urlOrigin = () => {
    return $window.location.protocol + '//' + $window.location.hostname + ($window.location.port ? ':' + $window.location.port : '')
  }

  let isWebkit = () => {
    return 'WebkitAppearance' in $document[0].documentElement.style
  }

  /**
   * Mobile Helper
   * @returns {boolean}
   */
  let isMobile = () => {
    /*eslint-disable */
    if (window.checkMobile !== undefined) {
      return window.checkMobile
    } else {
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) window.checkMobile = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return false || window.checkMobile
    }
    /*eslint-enable */
  }

  /**
   * Mobile Helper
   * @returns {boolean}
   */
  let iOS = () => {
    return /Android|iPad|iPhone|iPod/.test(navigator.platform)
  }

  /**
   * Mobile Helper
   * @returns {*}
   */
  let isStandalone = () => {
    return window.navigator.standalone
  }

  /**
   * Mobile Helper
   * @returns {boolean}
   */
  let isShowingMobileBottomNav = () => {
    return (iOS() && !isStandalone())
  }

  let notify = (mainMessage, subMessage, handlerLabel, userClose, handler, opt) => {
    var rScope = $injector.get('$rootScope')
    let notifyMessage = {
      'messagePrimary': $filter('i18next')(mainMessage),
      'messageSecondary': $filter('i18next')(subMessage),
      handlerLabel: $filter('i18next')(handlerLabel),
      userClose: userClose,
      handler: handler,
      templateName: (opt && opt.templateName) || false,
      templateOptions: (opt && opt.templateOptions) || {}
    }
    rScope.$broadcast('sv-notify', notifyMessage)
  }

  let clearNotifications = (mainMessage, subMessage, handlerLabel, userClose, handler) => {
    var rScope = $injector.get('$rootScope')
    rScope.$broadcast('sv-notify-clear')
  }

  // A more flexible notify with direct options
  let notice = (options) => {
    var rScope = $injector.get('$rootScope')
    rScope.$broadcast('sv-notify', options)
  }

  let fixCaptureButtonPosition = () => {
    var rScope = $injector.get('$rootScope')
    if (rScope.isCapture) {
      $('.capture-btn, .uploading').css('top', $('body').innerHeight() - 290)
    } else {
      $('.capture-btn, .uploading').css('top', $('body').innerHeight() - 180)
    }
  }

  let findTreeNode = (nodes, id, fn) => {
    var found
    _.forIn(nodes, function (node, idx) {
      if (!found && node && node.id === id) {
        found = _.clone(node)
        fn && fn(found, nodes, parseInt(idx, 10))
      } else if (!found && node.children && node.children.length) {
        found = findTreeNode(node.children, id, fn)
      }
    })
    return found
  }

  let clearText = () => {
    document.selection && document.selection.empty()
    window.getSelection && window.getSelection().removeAllRanges()
  }

  let utf8ToB64 = (str) => {
    return window.btoa(unescape(encodeURIComponent(str)))
  }

  let b64ToUtf8 = (str) => {
    return decodeURIComponent(escape(window.atob(str)))
  }

  /**
   * Checks if image link is viable. Always resolves and returns either true or false
   * TRUE = viable link
   * FALSE = broken link
   */
  let checkImage = (src) => {
    var deferred = $q.defer()
    var image = new Image()
    image.onerror = function () {
      deferred.reject()
    }
    image.onload = function () {
      if (!this.width || !this.height || this.width === 0 || this.height === 0) {
        deferred.reject()
      } else {
        deferred.resolve()
      }
    }
    image.src = src
    return deferred.promise
  }

  /** TODO remove this once sorting is computed on back end for all grids
   * Use shift key to select a range
   * @param options
   *        evt
   *        state
   *        scope
   *        current
   *        collection
   */
  let shiftSelect = (evt, scope, model, collection, cb) => {
    var rScope = $injector.get('$rootScope')

    model.selected = true
    if (!rScope.lastSelectedPlacard && model.selected) {
      rScope.lastSelectedPlacard = model
    } else if (rScope.lastSelectedPlacard && model.selected) {
      // deselect all
      for (i = 0; i < collection.models.length; i++) {
        collection.models[i].selected = false
      }

      rScope.lastSelectedPlacard.selected = true
      model.selected = true

      // find indexes
      let first = rScope.lastSelectedPlacard
      let second = model
      let found
      let reverse = false
      let i

      // find last placard or current
      let placards = $('.placard')
      _.each(placards, (elem) => {
        if (!found && rScope.lastSelectedPlacard.id === $(elem).attr('data-id')) {
          found = true
        } else if (!found && model.id === $(elem).attr('data-id')) {
          reverse = true
          found = true
        }
      })

      // swap first and second
      if (reverse) {
        first = model
        second = rScope.lastSelectedPlacard
      }

      let start = false
      let end = false

      // select range from visible DOM
      $timeout(() => {
        _.each(placards, (b) => {
          let el = $(b).find('input[type="checkbox"]')

          if ($(b).attr('data-id') === first.id) {
            start = true
          }

          if (start && !end) {
            if (!$(el)[0].checked) {
              let shiftClick = new MouseEvent('click', {
                shiftKey: true
              })
              $(el)[0].dispatchEvent(shiftClick)
            }
          }

          if ($(b).attr('data-id') === second.id) {
            end = true
          }
        })
        rScope.$broadcast('sv-placards-selected', {})
      })
    }
  }

  /**
   * Use shift key to select a range
   * @param options
   *        evt
   *        state
   *        scope
   *        current
   *        collection
   */
  let shiftSelectCollection = (evt, scope, model, collection, cb) => {
    var rScope = $injector.get('$rootScope')
    model.selected = true
    if (!rScope.lastSelectedPlacard && model.selected) {
      rScope.lastSelectedPlacard = model
    } else if (rScope.lastSelectedPlacard && model.selected) {
      // deselect all
      for (let i = 0; i < collection.models.length; i++) {
        collection.models[i].selected = false
      }
      model.selected = true
      rScope.lastSelectedPlacard.selected = true
      if (rScope.lastSelectedPlacard !== model) {
        let inSelectedRange = false
        // select all items in range
        for (let i = 0; i < collection.models.length; i++) {
          if (collection.models[i] === model || collection.models[i] === rScope.lastSelectedPlacard) {
            inSelectedRange = !inSelectedRange
            collection.models[i].selected = true
          } else {
            if (inSelectedRange) {
              collection.models[i].selected = true
            }
          }
        }
      }

      rScope.$broadcast('sv-placards-selected', {})
    }
  }

  /**
   * Use meta keys to select a placard or shift key to select range
   * @param options: json object of
   *                 evt
   *                 scope
   *                 model
   *                 collection
   *                 callback
   * @returns {boolean}
   */
  let isMetaKeySelect = (evt, scope, model, collection, cb, useCollectionOperations = false) => {
    var rScope = $injector.get('$rootScope')
    clearText()

    //
    // Shift select range
    //
    if (evt.shiftKey) {
      (useCollectionOperations) ? shiftSelectCollection(evt, scope, model, collection, cb) : shiftSelect(evt, scope, model, collection, cb)
      return true
    } else if (evt.ctrlKey || evt.metaKey) {
      //
      // Command and CTRL key select
      //
      model.selected = !model.selected
      if (model.selected) {
        rScope.lastSelectedPlacard = model
        if (cb) {
          cb(model)
        }
      } else {
        rScope.lastSelectedPlacard = undefined
      }
      rScope.$broadcast('sv-placards-selected', {})
      return true
    } else {
      return false
    }
  }

  function isMetaKeySelectTree (evt, node) {
    if (evt.ctrlKey || evt.metaKey) {
      //
      // Command and CTRL key select
      //
      var rScope = $injector.get('$rootScope')
      node.selected = !node.selected && node.canUpdate
      rScope.$broadcast('sv-tag-def-node-selected', [node])

      return true
    }
    return false
  }

  let isTimeoutResponse = (response) => {
    return response.status === 419
  }

  let flattenProject = (projectWithDetails) => {
    let project = projectWithDetails.project
    _.each(projectWithDetails, (value, key) => {
      if (key !== 'project') {
        project[key] = value
      }
    })
    return project
  }

  let calculateMD5Hash = (file, chunkSizeInMB) => {
    let start = Date.now()
    let fileSize = (file.size / (1024 * 1024)).toFixed(2)
    $log.info(`Starting hash calculation for ${file.name}, file size is ${fileSize}MB`)
    let deferred = $q.defer()
    let chunkSize = 1024 * 1024 * chunkSizeInMB
    let chunks = Math.ceil(file.size / chunkSize)
    let currentChunk = 0
    let spark = new SparkMD5.ArrayBuffer()
    let fileReader = new FileReader()
    fileReader.onload = function (e) {
      spark.append(e.target.result) // Append array buffer
      currentChunk++
      if (currentChunk < chunks) {
        loadNext()
      } else {
        let end = Date.now()
        $log.info(`Finish hash calculation for ${file.name} in ${end - start} ms`)
        deferred.resolve(spark.end())
      }
    }
    fileReader.onerror = function () {
      deferred.reject()
    }

    function loadNext () {
      let start = currentChunk * chunkSize
      let end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
      fileReader.readAsArrayBuffer(file.slice(start, end))
    }

    loadNext()
    return deferred.promise
  }

  let downloadFile = (projectId, packageId, token) => {
    const rootUrl = config.env.development.apiRootUrl
    let url = `${rootUrl}/api/project/${projectId}/download/package/${packageId}/type/ASSETS_WITH_TAG_COUNTS/token/${token}`

    var anchor = angular.element('<a/>')
    anchor.attr({
      href: url,
      target: '_blank',
      download: 'Assets.csv'
    })[0].click()
  }

  let downloadFileInfoReport = (orgId, packageId, token) => {
    const rootUrl = config.env.development.apiRootUrl
    let url = `${rootUrl}/api/org/${orgId}/download/package/${packageId}/type/ASSETS_WITH_TAG_COUNTS/token/${token}`

    var anchor = angular.element('<a/>')
    anchor.attr({
      href: url,
      target: '_blank',
      download: 'Assets.csv'
    })[0].click()
  }

  let turnOnControlbarAnimateAfterLoad = () => {
    $timeout(() => {
      $(angular.element('.controlbar').addClass('controlbar-animate'))
    }, 0)
  }

  let isShouldGetMore = (collection, max) => {
    return !!(collection.length <= max && collection.canFetch)
  }

  let isArrowKey = function (evt) {
    return !!(evt.keyCode === LEFT_ARROW_KEY_CODE || evt.keyCode === RIGHT_ARROW_KEY_CODE ||
        evt.keyCode === UP_ARROW_KEY_CODE || evt.keyCode === DOWN_ARROW_KEY_CODE)
  }

  let api = {
    isTrueObject: isTrueObject,
    isValidNumber: isValidNumber,
    isVideo: isVideo,
    isImage: isImage,
    isTimeoutResponse: isTimeoutResponse,
    downloadFile: downloadFile,
    downloadFileInfoReport: downloadFileInfoReport,
    makeNumber: makeNumber,
    findValue: findValue,
    findValues: findValues,
    override: override,
    getFilename: getFilename,
    createGuid: createGuid,
    intersectionOfObjects: intersectionOfObjects,
    digest: digest,
    percentToInt: percentToInt,
    intToPercent: intToPercent,
    turnOnControlbarAnimateAfterLoad: turnOnControlbarAnimateAfterLoad,
    getRandomNodeOrder,
    isURL: isURL,
    flattenProject: flattenProject,
    urlOrigin,
    isWebkit,
    isMobile,
    isShowingMobileBottomNav,
    iOS,
    isStandalone,
    fixCaptureButtonPosition,
    notify,
    notice,
    clearNotifications,
    findTreeNode,
    clearText,
    utf8ToB64,
    b64ToUtf8,
    checkImage,
    isMetaKeySelect,
    isMetaKeySelectTree,
    capitalizeFirstLetter,
    calculateMD5Hash,
    isShouldGetMore,
    isArrowKey
  }

  return api
})
