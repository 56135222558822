<mat-spinner class="dashboard-spinner" diameter="128" *ngIf="!isConfigurationLoaded"></mat-spinner>
<div class="insights-dashboard-container top-and-left-border smartvid-dark-theme mdc-layout-grid" *ngIf="isConfigurationLoaded">
  <div class="mdc-layout-grid__inner">
    <div *svDashboardGridCell="{ cardId: 'UPLOADED_TOTAL' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.fileUploads.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
        <div class="text-card-content" *ngIf="projectTotals">
          <div class="text-card-main-text">
            {{
              isNA(projectTotals.uploadedFilesTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectTotals.uploadedFilesTotal | number)
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ uploadedFilesRatioPercentChangeClassName }}">
            {{ getSign(projectTotals.uploadedFilesRatioPercentChange)
            }}{{
              isNA(projectTotals.uploadedFilesTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectTotals.uploadedFilesRatioPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'UPLOADERS_TOTAL' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.totalUploaders.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
        <div class="text-card-content" *ngIf="projectTotals">
          <div class="text-card-main-text">
            {{
              isNA(projectTotals.uploadersTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectTotals.uploadersTotal | number)
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ uploadersRatioPercentChangeClassName }}">
            {{ getSign(projectTotals.uploadersRatioPercentChange)
            }}{{
              isNA(projectTotals.uploadersTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectTotals.uploadersRatioPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'TAGS_TOTAL' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.totalTags.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
        <div class="text-card-content" *ngIf="projectTotals">
          <div class="text-card-main-text">
            {{
              isNA(projectTotals.tagsTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectTotals.tagsTotal | number)
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ tagsTotalRatioPercentChangeClassName }}">
            {{ getSign(projectTotals.tagsTotalRatioPercentChange)
            }}{{
              isNA(projectTotals.tagsTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectTotals.tagsTotalRatioPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'PROJECT_SCORE' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.projectScore.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
        <div class="text-card-content" *ngIf="projectTotals">
          <div class="project-score-dot {{ projectScoreValueClassName }}">
            <div class="text-card-main-text">
              {{
                isNA(projectTotals.projectScore)
                  ? ('dashboard.valueNotAvailable' | translate)
                  : (projectTotals.projectScore | number: '1.0-0')
              }}
            </div>
            <div class="v-space-5"></div>
            <div class="text-card-sub-text">
              {{ getSign(projectTotals.projectScorePercentChange)
              }}{{
                isNA(projectTotals.projectScore)
                  ? ('dashboard.valueNotAvailable' | translate)
                  : (projectTotals.projectScorePercentChange | number: '.1-1') + '%'
              }}
            </div>
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'PPE_COMPLIANCE_OVERALL' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.ppeCompliance.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
        <div class="text-card-content" *ngIf="projectTotals">
          <div class="text-card-main-text">
            {{
              isNA(projectTotals.ppeCompliancePercent)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectTotals.ppeCompliancePercent | number: '.1-1') + '%'
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ ppeCompliancePercentChangeClassName }}">
            {{ getSign(projectTotals.ppeCompliancePercentChange)
            }}{{
              isNA(projectTotals.ppeCompliancePercent)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectTotals.ppeCompliancePercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'WORK_AT_HEIGHTS' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.workAtHeights.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
        <div class="text-card-content" *ngIf="projectTotals">
          <div class="text-card-main-text">
            {{
              isNA(projectTotals.workAtHeight)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectTotals.workAtHeight | number: '.1-1')
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ workAtHeightPercentChangeClassName }}">
            {{ getSign(projectTotals.workAtHeightPercentChange)
            }}{{
              isNA(projectTotals.workAtHeight)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectTotals.workAtHeightPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'SLIP_AND_TRIP' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.slipAndTrip.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
        <div class="text-card-content" *ngIf="projectTotals">
          <div class="text-card-main-text">
            {{
              isNA(projectTotals.slipAndTrip)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectTotals.slipAndTrip | number: '.1-1')
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ slipAndTripPercentChangeClassName }}">
            {{ getSign(projectTotals.slipAndTripPercentChange)
            }}{{
              isNA(projectTotals.slipAndTrip)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectTotals.slipAndTripPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div
      class="tab-container-wrapper mdc-layout-grid__cell--order-12 mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone"
    >
      <mat-tab-group>
        <mat-tab *svDashboardTab="'ACTIVITY'" label="{{ getTabTitle('ACTIVITY') | translate }}">
          <div class="tab-content mdc-layout-grid">
            <sv-assets-insights-dashboard-activity></sv-assets-insights-dashboard-activity>
          </div>
        </mat-tab>
        <mat-tab *svDashboardTab="'SAFETY'" label="{{ getTabTitle('SAFETY') | translate }}">
          <div class="tab-content mdc-layout-grid">
            <sv-assets-insights-dashboard-safety [projectTotals]="projectTotals"></sv-assets-insights-dashboard-safety>
          </div>
        </mat-tab>
        <mat-tab *svDashboardTab="'IMAGE_HEALTH'" label="{{ getTabTitle('IMAGE_HEALTH') | translate }}">
          <div class="tab-content mdc-layout-grid">
            <sv-assets-insights-dashboard-image-health [projectTotals]="projectTotals">
            </sv-assets-insights-dashboard-image-health>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
