import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core'
import { InsightsDataHelper } from 'angular2/modules/insights/services/insights-data-helper.service'
import { Subscription } from 'rxjs'
import {
  DashboardType,
  OrganizationAssetsDashboardTabs,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { forceUnsubscribe } from 'modules/insights/dashboard/utils/dashboard-utils'

@Component({
  selector: 'sv-assets-home-common-control-bar',
  templateUrl: 'assets-home-common-control.component.html',
  styleUrls: ['assets-home-common-control.component.scss'],
})
export class AssetsHomeCommonControlBarComponent implements OnInit, OnDestroy {
  @Input() insightsDashboardType: DashboardType
  @Input() hasInsightsTab = false

  private assetsHomeSelectedTab = OrganizationAssetsDashboardTabs.INSIGHTS_TAB
  private assetsHomeSelectedTabChanged: Subscription

  constructor(
    @Inject('contentSharingContext') public contentSharingContext: any,
    @Inject('searchResultService') public searchResultService: any,
    private insightsDataHelper: InsightsDataHelper
  ) {}

  ngOnInit(): void {
    this.assetsHomeSelectedTab = this.insightsDataHelper.getInsightsTabSelectedTab(this.insightsDashboardType)
    this.assetsHomeSelectedTabChanged = this.insightsDataHelper
      .onInsightsSelectedTabChanged$(this.insightsDashboardType)
      .subscribe((index: number) => {
        this.assetsHomeSelectedTab = index
      })
  }

  ngOnDestroy(): void {
    forceUnsubscribe(this.assetsHomeSelectedTabChanged)
  }

  hasAssetGridControlBar(): boolean {
    return this.searchResultService.isInGlobalSearchContext() || this.contentSharingContext.isGlobalSharingContext()
  }

  hasInsightsControlBar(): boolean {
    return (
      !this.hasAssetGridControlBar() &&
      this.hasInsightsTab &&
      this.assetsHomeSelectedTab === OrganizationAssetsDashboardTabs.INSIGHTS_TAB
    )
  }

  hasReportsControlBar(): boolean {
    return !this.hasAssetGridControlBar() && this.assetsHomeSelectedTab === OrganizationAssetsDashboardTabs.REPORTS_TAB
  }

  hasProjectGridControlBar(): boolean {
    return !this.hasAssetGridControlBar() && !this.hasReportsControlBar() && !this.hasInsightsControlBar()
  }
}
