/* global angular */

angular.module('smartvid').directive('thumbnailProcessing', ($rootScope) => {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: 'AssetGrid/thumbnail-processing.html',
    link: (scope) => {
      if ($rootScope.isMobile) {
        scope.radius = '25'
      } else {
        scope.radius = '55'
      }
    }
  }
})
