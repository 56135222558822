/* global angular _ */

angular.module('smartvid').directive('tagManagerControlbar', ($rootScope, $interval, $state,  config, utils, currentUser, $filter,
    modal, NavController, dashboardDataHelper, flyout, $timeout) => {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      treeModel: '=',
      strategy: '='
    },
    templateUrl: 'TagManager/controlbar.html',
    link (scope) {
      let tree = scope.treeModel
      let statusInterval
      let cleanupListeners = []

      scope.NavController = NavController
      scope.isMobile = $rootScope.isMobile
      scope.uiTreeLoaded = false
      scope.exportInProgress = false
      scope.canPromote = false
      scope.canDemote = false
      scope.canMoveToTop = false
      scope.canMove = false
      scope.canCreate = canCreate()
      scope.expandAll = expandAll
      scope.collapseAll = collapseAll
      scope.promote = promote
      scope.demote = demote
      scope.openAddTagModal = openAddTagModal
      scope.openTagImportModal = openTagImportModal
      scope.exportTags = exportTags
      scope.showSettingsOptions = showSettingsOptions
      scope.showMoveTagsDialog = showMoveTagsDialog
      scope.moveTagsToTop = moveTagsToTop
      scope.showSortOptions = showSortOptions

      let uiTreeLoadedListener = scope.$on('uiTreeLoaded', function (event, gridApi, treeOwnerId) {
        if (treeOwnerId === 'TAG_MANAGER') {
          cleanup()
          scope.gridApi = gridApi
          onTreeUpdate()
          cleanupListeners.push(scope.gridApi.selection.on.rowSelectionChanged(scope, onTreeUpdate))
          cleanupListeners.push(scope.gridApi.selection.on.rowSelectionChangedBatch(scope, onTreeUpdate))
          cleanupListeners.push(tree.addUpdateListener(onTreeUpdate))
          scope.uiTreeLoaded = true
        }
      })

      let uiTreeNodesUpdatedForParentListener = scope.$on('uiTreeNodesUpdatedForParent', (event, parentId) => {
        let parentNodes = tree.getAllParentNodesInHierarchy(parentId, true)
        _.each(parentNodes, (node) => {
          let row = scope.gridApi.grid.getRow(node)
          scope.gridApi.treeBase.expandRow(row)
        })

        unselectAllTags()
      })

      scope.$on('$destroy', () => {
        cleanup()
        uiTreeLoadedListener()
        uiTreeNodesUpdatedForParentListener()
      })

      function cleanup () {
        _.each(cleanupListeners, (listener) => {
          listener()
        })
      }

      function showSortOptions ($event) {
        flyout.open('tagSortOptions', {
          parentElement: $event.currentTarget,
          treeModel: tree,
          gridApi: scope.gridApi,
          direction: 'top'
        })
      }

      function unselectAllTags () {
        scope.gridApi.selection.clearSelectedRows()
        scope.gridApi.grid.appScope.checkboxStatus = []
      }

      function onTreeUpdate () {
        scope.canPromote = canPromote()
        scope.canDemote = canDemote()
        scope.canMoveToTop = canMoveToTop()
        scope.canMove = canMove()
      }

      function getSelectedNodeIds () {
        let selectedEntities = scope.gridApi.selection.getSelectedRows()
        if (selectedEntities.length === 0) {
          return []
        }
        return _.map(selectedEntities, (entity) => {
          return entity.id
        })
      }

      function getSelectedTopmostNodeIds () {
        let nodeIds = getSelectedNodeIds()
        if (nodeIds.length === 0) {
          return []
        }
        return tree.getTopmostSelectedNodeIds(nodeIds)
      }

      function getNodeIdsForMoveToOperation () {
        let topMostSelectedNodeIds = getSelectedTopmostNodeIds()
        let topMostSelectedNodes = _.map(topMostSelectedNodeIds, (id) => {
          return tree.getNode(id)
        })
        let childNodeIdsFlatList = []
        tree.forEachNode((node, parent, index, level) => {
          if (!_.contains(topMostSelectedNodeIds, node.id)) {
            childNodeIdsFlatList.push(node.id)
          }
          return true
        }, topMostSelectedNodes)

        return {topMostSelectedNodeIds: topMostSelectedNodeIds, childNodeIdsFlatList: childNodeIdsFlatList}
      }

      function canPromote () {
        let nodeIds = getSelectedNodeIds()
        return nodeIds.length > 0 && tree.canPromote(nodeIds)
      }

      function canMoveToTop () {
        if (isTreeSorted()) {
          return false
        }
        let nodeIds = getSelectedNodeIds()
        return nodeIds.length > 0 && tree.canMoveToTop(nodeIds)
      }

      function canMove () {
        return getSelectedNodeIds().length > 0
      }

      function canDemote () {
        let nodeIds = getSelectedNodeIds()
        return nodeIds.length > 0 && tree.canDemote(nodeIds)
      }

      function expandAll () {
        scope.gridApi.treeBase.expandAllRows()
      }

      function collapseAll () {
        scope.gridApi.treeBase.collapseAllRows()
      }

      function promote () {
        let nodeIds = getSelectedNodeIds()
        if (nodeIds.length > 0) {
          tree.promote(nodeIds)
        }
      }

      function demote () {
        let nodeIds = getSelectedNodeIds()
        if (nodeIds.length > 0) {
          tree.demote(nodeIds)
        }
      }

      function openAddTagModal () {
        modal.open('addTag', {
          treeModel: tree,
          callback: function () {
            $timeout(() => {
              scope.gridApi.core.scrollTo(scope.gridApi.grid.appScope.data[0], scope.gridApi.grid.options.columnDefs[0])
            })
          }
        })
      }

      function openTagImportModal () {
        modal.open('tagImport', {
          treeModel: tree
        })
      }

      function showSettingsOptions ($event) {
        flyout.open('tagManagerOptions', {
          parentElement: $event.currentTarget,
          direction: 'top',
          treeModel: tree,
          gridApi: scope.gridApi
        })
      }

      function showMoveTagsDialog () {
        if (!canMove()) {
          return
        }

        modal.open('moveTags', {
          treeModel: tree,
          nodeIdsData: getNodeIdsForMoveToOperation(),
          callback: function (modalScope) {
            modalScope.$destroy()
          }
        })
      }

      function moveTagsToTop () {
        if (!canMoveToTop()) {
          return
        }
        let topMostSelectedNodeIds = _.filter(getSelectedTopmostNodeIds(), (tagId) => {
          return !tree.isRootNode(tree.getNode(tagId))
        })
        if (topMostSelectedNodeIds.length > 0) {
          tree.moveTagsToNewParent(topMostSelectedNodeIds, undefined)
          unselectAllTags()
        }
      }

      function isTreeSorted () {
        return scope.gridApi.grid.getColumnSorting().length !== 0
      }

      function exportTags () {
        if (scope.exportInProgress) {
          return
        }
        let type = 'TAG_TREE_EXPORT'
        let totalProgress = 0
        let completed = false
        let isCheckingStatus = false
        let exportHREF = ''
        let packageId
        scope.exportInProgress = true

        //
        // Check status of export
        //
        let checkStatus = function () {
          //
          // Create an interval for polling status
          //
          statusInterval = $interval(() => {
            //
            // skip until previous calls return
            //
            if (isCheckingStatus) {
              return
            }

            isCheckingStatus = true
            totalProgress = 0
            scope.exportInProgress = true

            if (!completed) {
              tree.getTagExportPackageStatus(packageId).then((data) => {
                if (data.status === 'FAILED') {
                  $interval.cancel(statusInterval)
                  scope.exportInProgress = false
                  isCheckingStatus = false
                  $rootScope.$broadcast('sv-tag-export-failed')
                  utils.notify($filter('i18next')('tagTreeExport.exportFailed'), '', $filter('i18next')('common.okay'), true, () => {
                  })
                } else {
                  //
                  // accumulate totalProgress
                  //
                  totalProgress = totalProgress + parseFloat(data.creationProgress, 10)
                  if (data.status === 'FINISHED' && !completed) {
                    completed = true
                    let rootUrl = config.env.development.apiRootUrl
                    let url = `${rootUrl}/api/project/${scope.projectId}/download/package/${data.packageId}/type/${type}/token/${utils.utf8ToB64(currentUser.token)}`
                    exportHREF = url
                  }

                  //
                  // recalculate total percentage
                  //
                  totalProgress = parseFloat((totalProgress) * 100, 10).toFixed(0)

                  $rootScope.$broadcast('sv-tag-export-total-progress', totalProgress)

                  isCheckingStatus = false
                }
              })
            } else {
              $rootScope.$broadcast('sv-tag-export-complete')
              utils.notify($filter('i18next')('tagTreeExport.exportReady'), '', $filter('i18next')('assets.download'), true, () => {
                var anchor = angular.element('<a/>')
                anchor.attr({
                  href: exportHREF,
                  target: '_blank',
                  download: 'Project_Tags_Export.xls'
                })[0].click()
              })
              $interval.cancel(statusInterval)
              scope.exportInProgress = false
            }
          }, 3000)
        }

        tree.createTagExportPackage().then((data) => {
          packageId = data.packageId
          checkStatus()
        }, () => {
          scope.exportInProgress = false
        })
        $rootScope.$broadcast('sv-tag-export-before-start')
      }

      function canCreate () {
        return $state.current.name === 'dashboard.adminOrganizations.organizationId.tagsmanager' ||
          !_.find(currentUser.organizations,
            org => {
              return org.id === dashboardDataHelper.getCurrentProject().organizationId
            })
            .projectLevelTagsDisabled
      }
    }
  }
})
