/* global angular, _ */

import {ModuleType} from 'shared/models/module-type';

angular.module('smartvid').service('searchApi', function ($http, $q, $log, $injector, config, SearchEntityCollection,
                                                          apiUtils, InvitedProjectUserModel, UserModel) {
  let rootUrl = config.env.development.apiRootUrl

  return {
    getQuickSearch (quickSearchId, projectId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/search/quick/${quickSearchId}`
      if (projectId) {
        url = `${url}?projectId=${projectId}`
      }
      $http.get(url).then(function (response) {
        let assetSearchContext = apiUtils.convertSearchResponseToAssetSearchContext(response.data).assetSearchContext
        defer.resolve(assetSearchContext)
      }, function (response) {
        defer.reject(response)
      })
      return defer.promise
    },

    getRecentSearches (partialText, projectId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/search/recent?maxResults=5`
      if (partialText) {
        url = `${url}&partialText=${encodeURIComponent(partialText)}`
      }
      if (projectId) {
        url = `${url}&projectId=${projectId}`
      }

      $http.get(url).then(function (response) {
        let assetSearchContexts = _.map(response.data.data, (context) => {
          return apiUtils.convertSearchResponseToAssetSearchContext(context).assetSearchContext
        })
        defer.resolve(assetSearchContexts)
      }, function (response) {
        defer.reject(response)
      })
      return defer.promise
    },

    searchAssets (projectId, tagDefs, userIds, textFragments, assetGroup, tagConfidenceLevel, searchDateRange, searchQueryType, includedProjectIds, options = {}) {
      let defaults = {
        pageSize: 80,
        page: 0,
        sortInfo: []
      }

      _.defaults(options, defaults)

      let defer = $q.defer()

      let skipRecentSearches = !!options.skipRecentSearches
      let offset = options.pageSize * options.page
      let limit = options.pageSize
      var sorts = []
      if (options.sortInfo) {
        sorts = _.map(options.sortInfo, (sortColumn) => {
          return `${sortColumn.sortBy}:${sortColumn.sortOrder}`
        })
      }
      let url = (projectId) ? `${rootUrl}/api/search/assets/project/${projectId}` : `${rootUrl}/api/search/assets`
      url = `${url}?offset=${offset}&limit=${limit}&skipRecentSearches=${skipRecentSearches}`
      let request = {
        tagDefs: tagDefs,
        userIds: userIds,
        textFragments: textFragments,
        includedProjectIds: includedProjectIds,
        assetGroup: assetGroup,
        sortColumns: sorts,
        tagConfidenceLevel: tagConfidenceLevel,
        searchDateRange: searchDateRange,
        searchQueryType: searchQueryType,
        organizationId: options.organizationId,
        projectGroupId: options.projectGroupId
      }

      $http.post(url, request).then((response) => {
        let assets = $injector.get('assetsApi')._formatMediaAssets(response.data.data)
        if (response.data.childToParentTagMap) {
          _.each(assets, a => {
            a.childToParentTagMap = response.data.childToParentTagMap
          })
        }
        defer.resolve({
          assets: assets,
          searchGroups: response.data.searchGroups
        })
      })

      return defer.promise
    },

    findSearchableEntity (projectId, partialText, projectIdsParams) {
      let defer = $q.defer()
      projectIdsParams = projectIdsParams ? projectIdsParams.join() : '';
      let url = (projectId) ? `${rootUrl}/api/search/entity/project/${projectId}?` : `${rootUrl}/api/search/entity?`
      url = `${url}partialText=${encodeURIComponent(partialText)}&projectIds=${projectIdsParams}`
      $http.get(url).then(function (response) {
        $log.debug('findSearchableEntity', response.data)
        defer.resolve(response.data)
      }, (response) => {
        defer.reject(response)
      })

      return defer.promise
    },

    findSystemSearchableEntity (partialText) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/search/entity/system?`
      url = `${url}partialText=${encodeURIComponent(partialText)}`
      $http.get(url).then(function (response) {
        $log.debug('findSearchableEntity', response.data)
        defer.resolve(response.data)
      }, (response) => {
        defer.reject(response)
      })

      return defer.promise
    },

    searchAssetsCount (
      projectId, tagDefs, userIds, textFragments, assetGroup, tagConfidenceLevel, searchDateRange,
      searchQueryType, includeTagConfidenceLevelCounters, includedProjectIds) {
      let defer = $q.defer()
      let url = (projectId) ? `${rootUrl}/api/search/assets/count/project/${projectId}` : `${rootUrl}/api/search/assets/count`
      let request = {
        tagDefs: tagDefs,
        userIds: userIds,
        textFragments: textFragments,
        includedProjectIds: includedProjectIds,
        assetGroup: assetGroup,
        tagConfidenceLevel: tagConfidenceLevel,
        searchDateRange: searchDateRange,
        searchQueryType: searchQueryType,
        includeTagConfidenceLevelCounters: includeTagConfidenceLevelCounters,
        organizationId: arguments[10],
        projectGroupId: arguments[11]
      }
      $http.post(url, request).then((response) => {
        defer.resolve(response.data)
      })
      return defer.promise
    },

    searchAssetsGroups (projectId, tagDefs, userIds, textFragments, tagConfidenceLevel, searchDateRange, searchQueryType, includedProjectIds, organizationId, projectGroupId) {
      let defer = $q.defer()
      let url = (projectId) ? `${rootUrl}/api/search/assets/groups/project/${projectId}` : `${rootUrl}/api/search/assets/groups`
      let request = {
        tagDefs: tagDefs,
        userIds: userIds,
        textFragments: textFragments,
        includedProjectIds: includedProjectIds,
        tagConfidenceLevel: tagConfidenceLevel,
        searchDateRange: searchDateRange,
        searchQueryType: searchQueryType,
        organizationId: organizationId,
        projectGroupId: projectGroupId
      }
      $http.post(url, request).then((response) => {
        defer.resolve(response.data)
      })
      return defer.promise
    },

    getSearchedProjects (assetSearchContext) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/search/projects`
      let tagDefs = _.map(assetSearchContext.searchTags, (t) => {
        return {
          tagDefId: t.id,
          hasChildren: t.hasChildren
        }
      })
      let userIds = _.map(assetSearchContext.searchCreatedByUsers, (u) => {
        return u.id
      })
      let request = {
        tagDefs: tagDefs,
        userIds: userIds,
        textFragments: assetSearchContext.textFragments,
        includedProjectIds: assetSearchContext.includedProjectIds,
        tagConfidenceLevel: assetSearchContext.tagConfidenceLevel,
        searchDateRange: assetSearchContext.searchDateRange,
        searchQueryType: assetSearchContext.searchQueryType
      }
      $http.post(url, request).then((response) => {
        defer.resolve(response.data)
      })
      return defer.promise
    },

    findSearchProjectTags (projectId, tagDefIds) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/search/tags/${projectId}`
      let request = {
        tagDefIds: tagDefIds
      }
      $http.post(url, request).then((response) => {
        $log.debug('findSearchProjectTags', response.data)
        let expandedEntities = []
        _.each(response.data, (entity) => {
          if (entity.hasChildren) {
            let copy = _.clone(entity)
            copy.hasChildren = false
            expandedEntities.push(copy)
          }
          expandedEntities.push(entity)
        })
        let entities = new SearchEntityCollection(expandedEntities)
        defer.resolve(entities.models)
      }, (response) => {
        defer.reject(response)
      })
      return defer.promise
    },

    searchOrgUsers (organizationId, term, options = {}) {
      let defaults = {
        pageSize: 50,
        page: 0
      }

      _.defaults(options, defaults)
      let defer = $q.defer()
      let url = `${rootUrl}/api/search/organization/user`
      let config = {
        params: {
          organizationId: organizationId,
          text: term,
          offset: options.pageSize * options.page,
          limit: options.pageSize
        }
      }
      $http.get(url, config).then(function (response) {
        let usersData = response.data
        let models = []
        _.each(usersData.data, d => models.push(new UserModel(d)))
        defer.resolve(models)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    searchOrgUsersByEmailForModule (organizationId, excludedOrgRoles, term, module, options = {}) {
      let defaults = {
        pageSize: 50,
        page: 0
      }

      _.defaults(options, defaults)
      let defer = $q.defer()
      let url = `${rootUrl}/api/search/organization/user/email`
      let config = {
        params: {
          organizationId: organizationId,
          excludedOrgRoles: excludedOrgRoles,
          partialText: term,
          module: module,
          offset: options.pageSize * options.page,
          limit: options.pageSize
        }
      }
      $http.get(url, config).then(function (response) {
        let usersData = response.data
        let models = []
        _.each(usersData.data, d => models.push(new UserModel(d)))
        defer.resolve(models)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    deepSearchOrganizationUsersByEmailForModule (organizationId, excludedProjectRoles, excludedProjectGroupRoles,
        excludedOrgRoles, term, module, options) {
      let defaults = {
        pageSize: 50,
        page: 0
      }

      _.defaults(options, defaults)
      let defer = $q.defer()
      let url = `${rootUrl}/api/search/deep/organization/user/email`
      let config = {
        params: {
          organizationId: organizationId,
          excludedProjectRoles: excludedProjectRoles,
          excludedProjectGroupRoles: excludedProjectGroupRoles,
          excludedOrgRoles: excludedOrgRoles,
          partialText: term,
          module: module,
          offset: options.pageSize * options.page,
          limit: options.pageSize
        }
      }
      $http.get(url, config).then(function (response) {
        let usersData = response.data
        let models = []
        _.each(usersData.data, d => models.push(new UserModel(d)))
        defer.resolve(models)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    searchProjectUsers (projectId, term) {
      let url = `${rootUrl}/api/search/project/user`
      let defer = $q.defer()
      let config = {
        params: {
          projectId: projectId,
          text: term
        }
      }

      $http.get(url, config).then(function (response) {
        let users = []
        _.each(response.data.invitedUsers, (user) => {
          users.push(new InvitedProjectUserModel(user))
        })
        defer.resolve(users)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    searchProjectUsersByEmailForModule (projectId, excludedProjectRoles, excludedProjectGroupRoles, excludedOrgRoles,
                                        term, module) {
      let url = `${rootUrl}/api/search/project/user/email`
      let defer = $q.defer()
      let config = {
        params: {
          projectId: projectId,
          excludedProjectRoles: excludedProjectRoles,
          excludedProjectGroupRoles: excludedProjectGroupRoles,
          excludedOrgRoles: excludedOrgRoles,
          partialText: term,
          module: module
        }
      }

      $http.get(url, config).then(function (response) {
        let users = []
        _.each(response.data.invitedUsers, (user) => {
          users.push(new InvitedProjectUserModel(user))
        })
        defer.resolve(users)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    }

  }
})
