<div class="organization-assets-safety-table-container">
  <mat-spinner class="table-spinner" diameter="64" *ngIf="!tableData"></mat-spinner>
  <table
    mat-table
    [dataSource]="dataSource"
    class="organization-assets-safety-table"
    *ngIf="tableData"
    (mousewheel)="onMouseWheel($event)"
  >
    <ng-container matColumnDef="score" [sticky]="tableData.length > 0">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'dashboard.organizationAssets.groupOrProjectSafetyTable.columns.score' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-score">
        <div class="name-cell">
          <p
            class="mat-body-2 project-score-table-cell-dot {{
              projectScoreColorClassMap[row.scopeObjectInfo.scopeObjectId]
            }}"
          >
            {{
              isNA(row.scopeObjectCountsInfo.projectScore)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.projectScore | number: '1.0-0')
            }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef>
        {{ 'dashboard.organizationAssets.groupOrProjectSafetyTable.columns.name' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-name">
        <div class="name-cell">
          <p class="mat-body-2 title-text">{{ row.scopeObjectInfo.scopeObjectName }}</p>
          <p class="mat-caption subtitle-text">{{ row.organizationName }}</p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="pprComplianceAvg">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'dashboard.organizationAssets.groupOrProjectSafetyTable.columns.avgPpe' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-other">
        <div>
          <p class="mat-body-2 title-text">
            {{
              isNA(row.scopeObjectCountsInfo.avgPpe)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.avgPpe | number: '.1-1') + '%'
            }}
          </p>
          <p
            class="mat-caption subtitle-text"
            [ngClass]="getColorClassForValue(row.scopeObjectCountsInfo.avgPpePercentChange, [0, 0.0001])"
          >
            {{ getSign(row.scopeObjectCountsInfo.avgPpePercentChange)
            }}{{
              isNA(row.scopeObjectCountsInfo.avgPpe)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.avgPpePercentChange | number: '.1-1') + '%'
            }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="workAtHeights">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'dashboard.organizationAssets.groupOrProjectSafetyTable.columns.workAtHeights' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-other">
        <div>
          <p class="mat-body-2 title-text">
            {{
              isNA(row.scopeObjectCountsInfo.workAtHeights)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.workAtHeights | number: '.1-1')
            }}
          </p>
          <p
            class="mat-caption subtitle-text"
            [ngClass]="getColorClassForValue(row.scopeObjectCountsInfo.workAtHeightsPercentChange, [0, 0.0001])"
          >
            {{ getSign(row.scopeObjectCountsInfo.workAtHeightsPercentChange)
            }}{{
              isNA(row.scopeObjectCountsInfo.workAtHeights)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.workAtHeightsPercentChange | number: '.1-1') + '%'
            }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="slipAndTrip">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'dashboard.organizationAssets.groupOrProjectSafetyTable.columns.slipAndTrip' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-other">
        <div>
          <p class="mat-body-2 title-text">
            {{
              isNA(row.scopeObjectCountsInfo.slipAndTrip)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.slipAndTrip | number: '.1-1')
            }}
          </p>
          <p
            class="mat-caption subtitle-text"
            [ngClass]="getColorClassForValue(row.scopeObjectCountsInfo.slipAndTripPercentChange, [0, 0.0001])"
          >
            {{ getSign(row.scopeObjectCountsInfo.slipAndTripPercentChange)
            }}{{
              isNA(row.scopeObjectCountsInfo.slipAndTrip)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.slipAndTripPercentChange | number: '.1-1') + '%'
            }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="ladderToLiftRatio">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'dashboard.organizationAssets.groupOrProjectSafetyTable.columns.laddersToLiftsRatio' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-other">
        <div>
          <p class="mat-body-2 title-text">
            {{
              isNA(row.scopeObjectCountsInfo.laddersToLiftsRatio)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.laddersToLiftsRatio | number: '.1-1') + ' : 1'
            }}
          </p>
          <p
            class="mat-caption subtitle-text"
            [ngClass]="getColorClassForValue(row.scopeObjectCountsInfo.laddersToLiftsRatioPercentChange, [0, 0.0001])"
          >
            {{ getSign(row.scopeObjectCountsInfo.laddersToLiftsRatioPercentChange)
            }}{{
              isNA(row.scopeObjectCountsInfo.laddersToLiftsRatio)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.laddersToLiftsRatioPercentChange | number: '.1-1') + '%'
            }}
          </p>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div [hidden]="!tableData" class="totals-table-paginator">
  <mat-paginator [pageSizeOptions]="[4]" showFirstLastButtons hidePageSize></mat-paginator>
</div>
