/* global angular, _ */

angular.module('smartvid').directive('provenanceIndicator', function ($timeout) {
  return {
    restrict: 'E',
    replace: 'true',
    scope: {
      options: '=',
      tag: '='
    },
    link: function (scope, element, attrs, controller, transcludeFn) {
      var options = scope.options || {}
      var parent = element.parent()
      options.content = element
      var before = options.functionBefore || _.identity
      options.functionBefore = function () {
        if (scope.tag && scope.tag.selected) {
          return false
        }
        before.apply(this, arguments)
      }
      element.detach()

      // apply tooltip to direct parent
      parent.tooltipster(options)

      scope.$on('$destroy', function () {
        try {
          parent.tooltipster('destroy')
        // eslint-disable-next-line no-empty
        } catch (e) {
        }
        element.off()
        element.remove()
      })
    }
  }
})
