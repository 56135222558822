/* global angular,analytics */

angular.module('smartvid').controller('UserProfileOptionsCtrl', function ($rootScope, $scope, $timeout, $window, $state, smartvidApi, dashboardDataHelper, modal, utils, $filter, currentUser) {
  const LEFT_OFFSET = 15
  const TOP_OFFSET = 17

  $scope.editUser = (organizationId, user) => {
    $state.go('dashboard.adminOrganizations.organizationId.userProfile', {
      organizationId: organizationId,
      userId: user.id,
      user: user
    })
    $scope.flyout.close()
  }
  $scope.removeFromOrganization = (organizationId, user) => {
    analytics.track('Delete Org User', {
      category: 'Organization Action',
      orgName: currentUser.getOrganization(organizationId).name
    })
    $scope.flyout.isOpen = false

    let confirmHandlers = {
      confirm () {
        smartvidApi.removeFromOrganization(organizationId, [user.id]).then(() => {
          dashboardDataHelper.getCurrentAdminOrganizationUsers().removeById(user.id)
          if ($scope.callBackFn && $scope.callBackFn.onDelete) {
            $timeout(function () {
              $scope.callBackFn.onDelete()
              $scope.callBackFn = undefined
            }, 100)
          }
          $scope.flyout.close()
        }, (data) => {
          $scope.flyout.close()
        })
      },
      hasSelections: true
    }

    modal.open('deleteConfirm', confirmHandlers)
  }

  $scope.reinviteUserToSmartvid = (user) => {
    let promise = smartvidApi.sendInvitationToSmartvidEmail(user.email)
    promise.then(() => {
      // success
      utils.notify($filter('i18next')('directives.reinviteUser.invitationSent') + user.email, '', '', false, null)
      $scope.flyout.close()
    }, (data) => {
      // failure
      utils.notify($filter('i18next')('directives.reinviteUser.failedInvitation'), '' + user.email, '', false, null)
      $scope.flyout.close()
    })
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top - TOP_OFFSET,
      left: $scope.styles.left + LEFT_OFFSET
    }
  }

  $scope.isInvited = (user) => {
    return user.isInvited
  }
})
