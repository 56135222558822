/* global angular, _ */
angular.module('smartvid').directive('thumbnailCarousel', function ($state, $stateParams, $timeout, utils, thumbnailCarouselStateService,
                                                                    AssetsCollection, $q, smartvidApi, assetGridHelper, $window,
                                                                    tagConfidenceLevelService) {
  return {
    restrict: 'E',
    templateUrl: 'AssetViewer/thumbnail-carousel.html',
    scope: {
      assetCollectionOriginal: '=assets',
      currentAsset: '=',
      obsLinkedAssetCollection: '='
    },
    link (scope, elem) {
      const MIN_WIDTH = 75
      const RIGHT_ARROW_KEY_CODE = 39
      const LEFT_ARROW_KEY_CODE = 37

      let service = thumbnailCarouselStateService
      let reachedNextEnd = false
      let reachedPrevEnd = false
      let showArrows = false
      let responsiveBreakpoints = [
        {
          breakpoint: 6000,
          settings: {
            slidesToShow: 15,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 14,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 13,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 12,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 890,
          settings: {
            slidesToShow: 11,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 822,
          settings: {
            slidesToShow: 10,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 757,
          settings: {
            slidesToShow: 9,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 679,
          settings: {
            slidesToShow: 8,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 609,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 549,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 457,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 388,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 338,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 315,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 222,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 154,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: MIN_WIDTH,
          settings: {}
        }
      ]
      scope.service = service
      scope.model = {
        showCarousel: false
      }
      scope.opaque = true
      scope.assetCollection = []

      scope.loadAssetIntoMediaReviewer = loadAssetIntoMediaReviewer
      scope.showLeftArrow = showLeftArrow
      scope.showRightArrow = showRightArrow
      scope.showDisabledLeftArrow = showDisabledLeftArrow
      scope.showDisabledRightArrow = showDisabledRightArrow
      scope.leftArrowLoading = leftArrowLoading
      scope.rightArrowLoading = rightArrowLoading

      scope.slickConfig = {
        dots: false,
        lazyLoad: 'ondemand',
        autoplay: false,
        centerMode: false,
        accessibility: false,
        draggable: false,
        prevArrow: '.slick-carousel-prev-btn',
        nextArrow: '.slick-carousel-next-btn',
        initialSlide: 0,
        infinite: false,
        slidesToShow: 10,
        slidesToScroll: 3,
        variableWidth: false,
        respondTo: 'slider',
        responsive: responsiveBreakpoints,
        method: {},
        event: {
          beforeChange: beforeChange,
          afterChange: afterChange,
          breakpoint: breakpointHit,
          init: initSLick
        },
        enabled: false
      }

      scope.$on('$destroy', onDestroy)
      scope.$on('sv-secret-slideshow-transition', onSecretSlideshowTransition)
      scope.$watch('service.isCarouselEnabled() && model.showCarousel', (val) => {
        scope.opaque = true
        $timeout(() => {
          scope.slickConfig.enabled = val
        }, 500)
      })

      // /////////////////////////////// INIT \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
      const obsLinkedAssets = scope.obsLinkedAssetCollection && scope.obsLinkedAssetCollection.obsLinkedAssets
      let tempAssetCollection = obsLinkedAssets && obsLinkedAssets.length > 0 ? scope.obsLinkedAssetCollection : scope.assetCollectionOriginal
      initAssetCollection(tempAssetCollection)

      /* ********************
       * $ DESTROY
       */
      function onDestroy () {
        angular.element($window).off('resize.smartvid.carousel', onWindowResize)
        angular.element($window).off('keydown.smartvid.carousel', onKeyDown)
      }

      /* ********************
       * INIT ASSET COLLECTION
       */
      function initAssetCollection (collection) {
        if (collection.isFetching) {
          let promise = (collection.nextPagePromise) ? collection.nextPagePromise : collection.previousPagePromise
          promise.then(() => {
            initAssetCollectionInternal(collection)
          })
        } else {
          initAssetCollectionInternal(collection)
        }
      }

      /* ********************
       * LOAD ASSET INTO MEDIA REVIEWER
       */
      function loadAssetIntoMediaReviewer (event, asset) {
        if (!asset || !asset.isViewable() || asset.id === scope.currentAsset.id) {
          event.stopPropagation()
          event.preventDefault()
          return false
        }
        service.transitionType = 'thumbnail-selected'
        loadAsset(asset)
      }

      function showLeftArrow () {
        return !service.prevPageInfo.fetching && !reachedPrevEnd && showArrows
      }

      function showDisabledLeftArrow () {
        return !showLeftArrow() && showArrows
      }

      function showRightArrow () {
        return !service.nextPageInfo.fetching && !reachedNextEnd && showArrows
      }

      function showDisabledRightArrow () {
        return !showRightArrow() && showArrows
      }

      function leftArrowLoading () {
        return service.prevPageInfo.fetching && showArrows
      }

      function rightArrowLoading () {
        return service.nextPageInfo.fetching && showArrows
      }

      /* ********************
       * DESTROY CAROUSEL
       */
      function destroyCarousel () {
        scope.$evalAsync(() => {
          scope.opaque = false
          scope.model.showCarousel = false
        })
      }

      /* ********************
       * REGENERATE CAROUSEL
       */
      function regenerateCarousel () {
        if (scope.assetCollection.models.length > 1) {
          scope.$evalAsync(() => {
            scope.model.showCarousel = true
          })
        }
      }

      /* ********************
       * STYLE CAROUSEL AND SHOW
       */
      function styleCarousel () {
        let sliderTrack = elem.find('.slick-track')
        let sliderTableCell = elem.find('.slider-compartment')

        if (sliderTrack[0] && sliderTableCell[0] &&
            sliderTrack[0].offsetWidth < sliderTableCell[0].offsetWidth) {
          sliderTableCell.addClass('center-track')
          showArrows = false
        } else {
          sliderTableCell.removeClass('center-track')
          showArrows = true
        }
      }

      /* ********************
       * GET CURRENT SELECTED ASSET INDEX
       */
      function getCurrentlySelectedAssetIndex (assetId, collection) {
        return (collection.length !== 0) ? collection.getIndexOf(assetId) : 0
      }

      function initAssetCollectionInternal (collection) {
        destroyCarousel()
        scope.assetCollection = service.collection = collection
        retrieveAssetPages()
      }

      function retrieveAssetPages () {
        scope.assetCollection.nextPagePromise.then(() => {
          const currentAssetId = scope.currentAsset.id
          if (scope.assetCollection.getIndexOf(currentAssetId) !== -1) {
            service.selectedAssetIndex = getCurrentlySelectedAssetIndex(currentAssetId, scope.assetCollection)
            regenerateCarousel()
            prepareNextAsset()
          } else {
            scope.assetCollection.nextPage()
            retrieveAssetPages()
          }
        })
      }

      /* ********************
       * SHOULD GET PREVIOUS PAGE?
       */
      function shouldGetPreviousPage (nextSlide) {
        return nextSlide <= 1 && !service.prevPageInfo.fetching &&
            scope.assetCollection.canFetchPrevious
      }

      /* ********************
       * SHOULD GET NEXT PAGE?
       */
      function shouldGetNextPage (nextSlide, slidesToShow) {
        return (nextSlide >= scope.assetCollection.length - slidesToShow - 1) && !service.nextPageInfo.fetching && scope.assetCollection.canFetch
      }

      /* ********************
       * CAROUSEL PREVIOUS PAGE
       */
      function carouselPreviousPage (event, slick, currentSlide, nextSlide) {
        let defer = $q.defer()
        let currCollectionLen = scope.assetCollection.models.length
        service.prevPageInfo.fetching = true
        service.prevPageInfo.fromIndex = slick.currentSlide
        service.prevPageInfo.toIndex = slick.currentSlide - slick.options.slidesToScroll
        service.transitionType = 'prev-page'

        scope.assetCollection.previousPage().then(() => {
          let changeInCollectionLen = scope.assetCollection.models.length - currCollectionLen
          service.prevPageInfo.fromIndex += changeInCollectionLen
          service.prevPageInfo.toIndex += changeInCollectionLen

          service.prevPageInfo.data_loaded = false
          utils.digest(scope).then(() => {
            service.prevPageInfo.data_loaded = true
            service.prevPageInfo.fetching = false
            utils.digest(scope)
            defer.resolve()
          })
        }, () => {
          service.prevPageInfo.data_loaded = true
          service.prevPageInfo.fetching = false
          defer.resolve()
        })
        return defer.promise
      }

      /* ********************
       * CAROUSEL NEXT PAGE
       */
      function carouselNextPage (event, slick, currentSlide, nextSlide) {
        let defer = $q.defer()
        service.nextPageInfo.fetching = true
        service.nextPageInfo.fromIndex = slick.currentSlide
        service.nextPageInfo.toIndex = slick.currentSlide + slick.options.slidesToScroll
        service.transitionType = 'next-page'

        scope.assetCollection.nextPage().then((success) => {
          if (success) {
            service.nextPageInfo.data_loaded = false
            utils.digest(scope).then(() => {
              service.nextPageInfo.data_loaded = true
              service.nextPageInfo.fetching = false
              utils.digest(scope)
              defer.resolve(true)
            })
          } else {
            service.nextPageInfo.data_loaded = true
            service.nextPageInfo.fetching = false
            defer.resolve(false)
          }
        }, () => {
          service.nextPageInfo.data_loaded = true
          service.nextPageInfo.fetching = false
          defer.resolve(false)
        })

        return defer.promise
      }

      /* ********************
       * BEFORE CHANGE
       */
      function beforeChange (event, slick, currentSlide, nextSlide) {
        service.currentIndex = slick.currentSlide
        reachedNextEnd = nextSlide >= scope.assetCollection.length - slick.options.slidesToShow
        reachedPrevEnd = nextSlide === 0

        // Paginate Next
        if (shouldGetNextPage(nextSlide, slick.options.slidesToShow)) {
          carouselNextPage(event, slick, currentSlide, nextSlide)
          // Paginate Prev
        } else if (shouldGetPreviousPage(nextSlide)) {
          carouselPreviousPage(event, slick, currentSlide, nextSlide)
        }
      }

      /* ********************
       * INIT SLICK
       */
      function initSLick (event, slick) {
        service.selectedAssetIndex = getCurrentlySelectedAssetIndex(scope.currentAsset.id, scope.assetCollection)
        handleSlideTransition(slick, service.transitionType)
        bindHandlers(slick)
        elem.find('.slick-list').focus()
        service.transitionType = undefined
        styleCarousel()
      }

      /* ********************
       * HANDLE SLIDE TRANSITION
       */
      function handleSlideTransition (slick, transitionType) {
        switch (transitionType) {
          case 'thumbnail-selected' :
            handleThumbnailSelectedTransition(slick)
            break
          case 'next-page' :
            handleNextPageTransition(slick)
            break
          case 'prev-page' :
            handlePreviousPageTransition(slick)
            break
          case 'next-asset' :
            handleNextAssetTransition(slick)
            break
          case 'prev-asset' :
            handlePreviousAssetTransition(slick)
            break
          default:
            goToFirstVisibleSection(slick)
            break
        }
      }

      function handleThumbnailSelectedTransition (slick) {
        let goToIdx = service.currentIndex
        slick.slickGoTo(goToIdx, true) // do slide, no animate
      }

      function handleNextPageTransition (slick) {
        slick.slickGoTo(service.nextPageInfo.fromIndex, true)
        slick.slickGoTo(service.nextPageInfo.toIndex, false)
      }

      function handlePreviousPageTransition (slick) {
        slick.slickGoTo(service.prevPageInfo.fromIndex, true)
        slick.slickGoTo(service.prevPageInfo.toIndex, false)
      }

      function handleNextAssetTransition (slick) {
        let assetIdx = getCurrentlySelectedAssetIndex(scope.currentAsset.id, scope.assetCollection)
        if (assetInViewport(slick, assetIdx, service.currentIndex)) {
          let goToIdx = service.currentIndex
          slick.slickGoTo(goToIdx, true) // do slide, no animate
        } else {
          goToFirstVisibleSection(slick)
        }
      }

      function handlePreviousAssetTransition (slick) {
        let assetIdx = getCurrentlySelectedAssetIndex(scope.currentAsset.id, scope.assetCollection)
        if (assetInViewport(slick, assetIdx, service.currentIndex)) {
          let goToIdx = service.currentIndex
          slick.slickGoTo(goToIdx, true) // do slide, no animate
        } else {
          goToFirstVisibleSection(slick)
        }
      }

      /* ********************
       * ASSET IN VIEWPORT
       */
      function assetInViewport (slick, assetIdx, currentIdx) {
        let inViewport = currentIdx <= assetIdx && (currentIdx + slick.options.slidesToShow) > assetIdx
        return inViewport
      }

      /* ********************
       * GO TO FIRST VISIBLE SECTION
       */
      function goToFirstVisibleSection (slick) {
        let goToIdx = 0
        // go to first available section where asset is visible
        goToIdx = Math.floor(service.selectedAssetIndex / slick.options.slidesToScroll) * slick.options.slidesToScroll
        if (slick.slideCount - goToIdx < slick.options.slidesToShow) {
          goToIdx = (slick.slideCount - slick.options.slidesToShow + 1) // TODO think more about this math. There are some edge cases that it might miss
        }
        slick.slickGoTo(goToIdx, true) // do slide, no animate
        reachedNextEnd = slick.currentSlide >= scope.assetCollection.length - slick.options.slidesToShow
        reachedPrevEnd = slick.currentSlide === 0
      }

      /* ********************
       * BIND HANDLERS
       */
      function bindHandlers (slick) {
        // When side panels finish toggling, behave responsively
        scope.$on('sv-left-nav-animation-finished', () => {
          scope.$evalAsync(() => {
            slick.checkResponsive()
            reachedNextEnd = slick.currentSlide >= scope.assetCollection.length - slick.options.slidesToShow
            reachedPrevEnd = slick.currentSlide === 0
            styleCarousel()
          })
        })

        scope.$on('sv-right-nav-animation-finished', () => {
          scope.$evalAsync(() => {
            slick.checkResponsive()
            reachedNextEnd = slick.currentSlide >= scope.assetCollection.length - slick.options.slidesToShow
            reachedPrevEnd = slick.currentSlide === 0
            styleCarousel()
          })
        })

        angular.element($window).on('resize.smartvid.carousel', null, slick, onWindowResize)
        angular.element($window).on('keydown.smartvid.carousel', null, slick, onKeyDown)
      }

      /* ********************
       * AFTER CHANGE
       */
      function afterChange (event, slick, currentSlide, nextSlide) {
        service.currentIndex = slick.currentSlide
      }

      /* ********************
       * ON KEY DOWN
       */
      function onKeyDown (event) {
        // let slick = event.data
        // slick.keyHandler(event)
        if (scope.currentAsset.type === 'IMAGE') {
          if (event.keyCode === LEFT_ARROW_KEY_CODE) {
            loadPreviousAsset()
            return
          }

          if (event.keyCode === RIGHT_ARROW_KEY_CODE) {
            loadNextAsset()
            return
          }
        }
      }

      /* ********************
       * ON WINDOW RESIZE
       */
      function onWindowResize (event) {
        let slick = event.data
        styleCarousel()
        reachedNextEnd = slick.currentSlide >= scope.assetCollection.length - slick.options.slidesToShow
        reachedPrevEnd = slick.currentSlide === 0
      }

      /* ********************
       * BREAKPOINT HIT
       */
      function breakpointHit (event, slick, breakpoint) {
        if (breakpoint === MIN_WIDTH) {
          scope.$evalAsync(() => {
            scope.opaque = false
          })
        } else {
          scope.opaque = true
        }
      }

      function prepareNextAsset () {
        if (service.selectedAssetIndex < scope.assetCollection.models.length - 1) {
          let id = scope.assetCollection.models[service.selectedAssetIndex + 1].id
          scope.assetCollection.getExtendedAsset(id, false, tagConfidenceLevelService.getAssetGridTagTagConfidenceLevel(), true)
        }
      }

      /* ********************
       * LOAD NEXT ASSET
       */
      function loadNextAsset () {
        if (service.selectedAssetIndex < scope.assetCollection.models.length - 1) {
          service.transitionType = 'next-asset'
          loadAsset(scope.assetCollection.models[service.selectedAssetIndex + 1])
        }
      }

      /* ********************
       * LOAD FIRST ASSET
       */
      function loadFirstAsset () {
        service.transitionType = 'next-asset'
        service.selectedAssetIndex = 0
        loadAsset(scope.assetCollection.models[service.selectedAssetIndex])
      }

      /* ********************
       * LOAD PREVIOUS ASSET
       */
      function loadPreviousAsset () {
        if (service.selectedAssetIndex > 0) {
          service.transitionType = 'prev-asset'
          loadAsset(scope.assetCollection.models[service.selectedAssetIndex - 1])
        }
      }

      /* *******************
       * LOAD ASSET
       */
      function loadAsset (asset) {
        let searchContext = scope.assetCollection.searchContext
        let searchedTags = (searchContext) ? searchContext.searchTags : undefined

        // if in search
        //
        //
        if (!_.isEmpty(searchedTags)) {
          // If we have a searchedTag value on the collection then we are in search mode and should deep link to the tag
          let tagsPromise = asset.tags.length ? $q.resolve(asset.tags) : asset.getTags()
          tagsPromise.then(() => {
            let tagInstance
            for (let searchedTag of searchedTags) {
              if (asset.childToParentTagMap) {
                let tagMap = asset.childToParentTagMap
                tagInstance = _.find(asset.tags.models, ti => {
                  let originalTag = tagMap[ti.tagDefinitionId]
                  return searchedTag.id === originalTag
                })
                if (tagInstance) {
                  break
                }
              }
            }
            $state.go('.', {
              assetId: asset.id,
              tagId: tagInstance ? tagInstance.id : undefined,
              asset: asset
            })
          })
        } else {
          // if not in search
          //
          //
          $state.go('.', {
            assetId: asset.id,
            asset: asset
          })
        }
      }

      function onSecretSlideshowTransition () {
        if (service.selectedAssetIndex >= scope.assetCollection.models.length - 1) {
          scope.assetCollection.init()
          service.resetToBeginning()
          destroyCarousel()
          scope.assetCollection.nextPage().then(() => {
            loadFirstAsset()
          })
        } else {
          loadNextAsset()
        }
      }
    }
  }
})
