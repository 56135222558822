import { Component, Input, Output, EventEmitter } from '@angular/core'
import { AdminProjectWithIntegrationSettings } from '../../models/admin.project.with.integration.settings.model'
import { IntegrationType } from '../../../../shared/models/integration-type'
import { TranslateService } from '@ngx-translate/core'
import { IntegrationSettingsFilterInputChangeEvent } from '../../models/admin.integration.settings.events'

@Component({
  selector: 'sv-admin-organization-integrations-search',
  templateUrl: 'admin-organization-integrations-search.component.html',
  styleUrls: ['admin-organization-integrations-search.component.scss'],
})
export class AdminOrganizationIntegrationsSearchComponent {
  @Input() projectsWithIntegrationSettings: AdminProjectWithIntegrationSettings[]
  @Input() integrationType: IntegrationType
  @Input() selectedIntegrationCount: number
  @Input() visibleIntegrationCount: number

  @Output() userInputChange = new EventEmitter<IntegrationSettingsFilterInputChangeEvent>()
  @Output() projectInputChange = new EventEmitter<IntegrationSettingsFilterInputChangeEvent>()

  searchByProject = ''
  searchByUser = ''

  constructor(protected translate: TranslateService) {}

  getIntegrationCount() {
    return this.projectsWithIntegrationSettings.length
  }

  getIntegrationType() {
    if (!this.integrationType) {
      return undefined
    }
    return this.translate.instant('components.adminOrganizationIntegrations.' + this.integrationType)
  }

  cancelProjectSearch() {
    this.searchByProject = ''
    this.onProjectInputChange()
  }

  cancelUserSearch() {
    this.searchByUser = ''
    this.onUserInputChange()
  }

  onUserInputChange() {
    this.userInputChange.emit({
      value: this.searchByUser,
    })
  }

  onProjectInputChange() {
    this.projectInputChange.emit({
      value: this.searchByProject,
    })
  }
}
