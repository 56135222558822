/* global angular analytics _ */

import { ModuleType } from 'shared/models/module-type'
angular.module('smartvid').component('organizationUserProfile', {
  templateUrl: 'UsersGrid/user-profile.html',
    bindings: {
    organization: '<',
    user: '<'
  },
  controller: function (MAX_USER_FIRST_NAME_LENGTH, MAX_USER_LAST_NAME_LENGTH, MAX_USER_EMAIL_LENGTH,
    $timeout, currentUser, dashboardDataHelper, smartvidApi, $state, projectGroupApi, $filter, $q) {
    const roleType = {
      ORG: 'org',
      GROUP: 'group'
    }

    let ctrl = this
    let noneRole = {
      name: 'NON_MEMBER',
      displayName: 'None',
      description: $filter('i18next')('user.observationRoles.descriptions.NON_MEMBER'),
      type: roleType.ORG,
      canAssign: true
    }

    ctrl.MAX_USER_FIRST_NAME_LENGTH = MAX_USER_FIRST_NAME_LENGTH
    ctrl.MAX_USER_LAST_NAME_LENGTH = MAX_USER_LAST_NAME_LENGTH
    ctrl.MAX_USER_EMAIL_LENGTH = MAX_USER_EMAIL_LENGTH
    ctrl.inTransit = false
    ctrl.removing = false
    
    ctrl.$onInit = onInit
    ctrl.checkCanUpdate = checkCanUpdate
    ctrl.reinviteUser = reinviteUser
    ctrl.updateUser = updateUser
    ctrl.removeUser = removeUser
    ctrl.isAssetGroupDisabled = isAssetGroupDisabled
    ctrl.isObservationGroupDisabled = isObservationGroupDisabled

    function onInit () {
      ctrl.hasObservations = currentUser.isObservationEnabledForOrganization(ctrl.organization.id)
      ctrl.canUpdate = ctrl.user.canUpdate
      ctrl.canUpdateRoles = ctrl.canUpdate && ctrl.user.id !== currentUser.id
      ctrl.creatableAssetOrgRoles = currentUser.getCreatableOrganizationRoles(ctrl.organization.id)
      ctrl.creatableObservationOrgRoles = currentUser.getCreatableOrganizationObservationRoles(ctrl.organization.id)
      ctrl.allOrgAssetRoles = getAllOrgAssetRoles()
      ctrl.allOrgObservationRoles = getAllOrgObservationRoles()
      ctrl.projectGroups = currentUser.getOrganization(ctrl.organization.id).projectGroups
      initUserModel(ctrl.user)
    }

    function initUserModel (user) {
      if (user) {
        ctrl.user = user
      }
      ctrl.model = {
        firstName: ctrl.user.firstName,
        lastName: ctrl.user.lastName,
        assetRole: getAssetRole(),
        assetGroupsSelected: getAssetGroupsSelected(),
        observationRole: getObservationRole(),
        observationGroupsSelected: getObservationGroupsSelected()
      }
    }
    function getAssetRole () {
      for (let projectGroup of ctrl.organization.projectGroups) {
        if (projectGroup.id in ctrl.user.projectGroupIdToModuleAndRole) {
          let projectGroupRole = ctrl.user.projectGroupIdToModuleAndRole[projectGroup.id][ModuleType.ASSETS]
          if (projectGroupRole) {
            return _.find(ctrl.allOrgAssetRoles, r => {
              return r.name === projectGroupRole && r.type === roleType.GROUP
            })
          }
        }
      }
      return _.find(ctrl.allOrgAssetRoles, r => {
        return r.name === ctrl.user.role && r.type === roleType.ORG
      })
    }

    function getObservationRole () {
      for (let projectGroup of ctrl.organization.projectGroups) {
        if (projectGroup.id in ctrl.user.projectGroupIdToModuleAndRole) {
          let projectGroupRole = ctrl.user.projectGroupIdToModuleAndRole[projectGroup.id][ModuleType.OBSERVATIONS]
          if (projectGroupRole) {
            return _.find(ctrl.allOrgObservationRoles, r => {
              return r.name === projectGroupRole && r.type === roleType.GROUP
            })
          }
        }
      }
      let result = _.find(ctrl.allOrgObservationRoles, r => {
        return r.name === ctrl.user.observationRole && r.type === roleType.ORG
      })
      return result || noneRole
    }

    function getAssetGroupsSelected () {
      let result = {}
      for (let projectGroup of ctrl.organization.projectGroups) {
        if (projectGroup.id in ctrl.user.projectGroupIdToModuleAndRole) {
          let projectGroupRole = ctrl.user.projectGroupIdToModuleAndRole[projectGroup.id][ModuleType.ASSETS]
          let groupMap = result[projectGroupRole]
          if (!groupMap) {
            result[projectGroupRole] = {}
            groupMap = result[projectGroupRole]
          }
          groupMap[projectGroup.id] = true
        }
      }
      return result
    }

    function getObservationGroupsSelected () {
      let result = {}
      for (let projectGroup of ctrl.organization.projectGroups) {
        if (projectGroup.id in ctrl.user.projectGroupIdToModuleAndRole) {
          let projectGroupRole = ctrl.user.projectGroupIdToModuleAndRole[projectGroup.id][ModuleType.OBSERVATIONS]
          let groupMap = result[projectGroupRole]
          if (!groupMap) {
            result[projectGroupRole] = {}
            groupMap = result[projectGroupRole]
          }
          groupMap[projectGroup.id] = true
        }
      }
      return result
    }

    function isAssetGroupDisabled (orgRole, projectGroup) {
      if (!ctrl.canUpdate || !ctrl.canUpdateRoles) {
        return true
      }
      if (ctrl.model.assetRole.type === roleType.ORG) {
        return true
      }
      if (ctrl.model.assetRole.name !== orgRole.name) {
        return true
      }
      return !orgRole.assignableGroups.includes(projectGroup.name)
    }

    function isObservationGroupDisabled (orgRole, projectGroup) {
      if (!ctrl.canUpdate || !ctrl.canUpdateRoles) {
        return true
      }
      if (ctrl.model.observationRole.type === roleType.ORG) {
        return true
      }
      if (ctrl.model.observationRole.name !== orgRole.name) {
        return true
      }
      return !orgRole.assignableGroups.includes(projectGroup.name)
    }

    function getAllOrgAssetRoles () {
      let orgRoles = _.map(_.filter(currentUser.organizationRoles, r => r.manuallyAssignable), (orgRole) => {
        return {
          name: orgRole.name,
          displayName: orgRole.displayName,
          description: orgRole.description,
          type: roleType.ORG,
          canAssign: canUpdateAssetOrgRole(orgRole.name)
        }
      })
      let groupRoles = _.map(_.filter(currentUser.regionRoles, r => r.manuallyAssignable), (regionRole) => {
        let assignableGroups = getAssignableAssetGroups(regionRole.name)
        return {
          name: regionRole.name,
          displayName: regionRole.displayName,
          description: regionRole.description,
          type: roleType.GROUP,
          canAssign: assignableGroups.length > 0 &&  ctrl.canUpdate && ctrl.canUpdateRoles,
          assignableGroups: assignableGroups
        }
      })
      return orgRoles.concat(groupRoles)
    }

    function getAllOrgObservationRoles () {
      let result = [noneRole]
      let orgRoles = _.map(_.filter(currentUser.observationOrganizationRoles, r => r.manuallyAssignable), (orgRole) => {
        return {
          name: orgRole.name,
          displayName: orgRole.displayName,
          description: orgRole.description,
          type: roleType.ORG,
          canAssign: canUpdateObservationOrgRole(orgRole.name)
        }
      })
      let groupRoles = _.map(_.filter(currentUser.observationRegionRoles, r => r.manuallyAssignable), (regionRole) => {
        let assignableGroups = getAssignableObservationGroups(regionRole.name)
        return {
          name: regionRole.name,
          displayName: regionRole.displayName,
          description: regionRole.description,
          type: roleType.GROUP,
          canAssign: assignableGroups.length > 0 && ctrl.canUpdate && ctrl.canUpdateRoles,
          assignableGroups: assignableGroups
        }
      })
      return result.concat(orgRoles).concat(groupRoles)
    }

    function canUpdateAssetOrgRole (roleName) {
      return ctrl.canUpdate && ctrl.canUpdateRoles && !_.isEmpty(_.findWhere(ctrl.creatableAssetOrgRoles, {'name': roleName}))
    }

    function canUpdateObservationOrgRole (roleName) {
      return ctrl.canUpdate && ctrl.canUpdateRoles && !_.isEmpty(_.findWhere(ctrl.creatableObservationOrgRoles, {'name': roleName}))
    }

    function getAssignableAssetGroups (groupRoleName) {
      let result = []
      for (let group of ctrl.organization.projectGroups) {
        if (group.creatableAssetRoles.includes(groupRoleName)) {
          result.push(group.name)
        }
      }
      return result
    }

    function getAssignableObservationGroups (groupRoleName) {
      let result = []
      for (let group of ctrl.organization.projectGroups) {
        if (group.creatableObservationRoles.includes(groupRoleName)) {
          result.push(group.name)
        }
      }
      return result
    }

    function reinviteUser () {
      analytics.track('Reinvite Org User', {
        category: 'Organization Action',
        orgName: currentUser.getOrganization(ctrl.organization.id).name
      })
      ctrl.inTransit = true
      let promise = smartvidApi.sendInvitationToSmartvidEmail(ctrl.user.email)
      promise.then((data) => {
        $timeout(function () {
          ctrl.inTransit = false
        }, 100)
      }, (data) => {
        ctrl.inTransit = false
      })
      return promise
    }

    function checkCanUpdate () {
      if (ctrl.model.assetRole.type === roleType.GROUP) {
        let selectedGroups = getSelectedAssetGroups()
        if (selectedGroups.length === 0) {
          return false
        }
      }
      if (ctrl.model.observationRole.type === roleType.GROUP) {
        let selectedGroups = getSelectedObservationGroups()
        if (selectedGroups.length === 0) {
          return false
        }
      }
      return true
    }

    function updateUser () {
      if (!ctrl.checkCanUpdate()) {
        return
      }
      analytics.track('Update Org User', {
        category: 'Organization Action',
        orgName: currentUser.getOrganization(ctrl.organization.id).name
      })
      ctrl.inTransit = true
      return performUserProjectGroupUpdate().then(performUserOrgUpdate)
        .finally(() => {
          let userPromise = smartvidApi.getUser(ctrl.organization.id, ctrl.user.id);
          userPromise.then((user) => {
           initUserModel(user)
           ctrl.inTransit = false
          })
        })
    }

    function removeUser () {
      analytics.track('Remove Org User', {
        category: 'Organization Action',
        orgName: currentUser.getOrganization(ctrl.organization.id).name
      })
      ctrl.removing = true
      smartvidApi.removeFromOrganization(ctrl.organization.id, [ctrl.user.id]).then(() => {
        dashboardDataHelper.getCurrentAdminOrganizationUsers().removeById(ctrl.user.id)
        let params = {organizationId: ctrl.organization.id}
        $state.go('dashboard.adminOrganizations.organizationId', params)
        ctrl.removing = false
      })
    }

    function performUserOrgUpdate () {
      let defer = $q.defer()
      let promise = smartvidApi.updateUser(
        ctrl.organization.id, ctrl.user.id,
        ctrl.model.firstName, ctrl.model.lastName, ctrl.user.email,
        (ctrl.model.assetRole.type === roleType.GROUP) ? ctrl.user.assetRole : ctrl.model.assetRole.name,
        (ctrl.model.observationRole === noneRole || ctrl.model.observationRole.type === roleType.GROUP) 
        ? undefined : ctrl.model.observationRole.name)
      promise.then((data) => {
        $timeout(function () {
          if (!data.observationRole) {
            data.observationRole = null;
          }
          dashboardDataHelper.getCurrentAdminOrganizationUsers().findAndUpdate({id: data.id}, data, undefined)
          defer.resolve()
        }, 100)
      }, (data) => {
        defer.reject()
      })
      return defer.promise
    }

    function performUserProjectGroupUpdate () {
      let defer = $q.defer()
      let request = {
        updatedUsers: [],
        uninvitedUsers: []
      }
      populateAssetGroupUsers(request)
      populateObservationGroupUsers(request)
      return projectGroupApi.updateInvitedUsers(ctrl.organization.id, request).toPromise()
    }

    function populateAssetGroupUsers (request) {
      let assetGroupRoles = _.filter(ctrl.allOrgAssetRoles, r => {
        return r.type === roleType.GROUP
      })
      for (let assetGroupRole of assetGroupRoles) {
        if (assetGroupRole === ctrl.model.assetRole) {
          let selectedProjectGroups = ctrl.model.assetGroupsSelected[assetGroupRole.name]
          for (let projectGroup of ctrl.projectGroups) {
            if (selectedProjectGroups[projectGroup.id]) {
              request.updatedUsers.push({
                userId: ctrl.user.id,
                userEmail: ctrl.user.email,
                projectGroupId: projectGroup.id,
                assetRole: ctrl.model.assetRole.name
              })
            } else {
              request.uninvitedUsers.push({
                userId: ctrl.user.id,
                projectGroupId: projectGroup.id,
                moduleType: ModuleType.ASSETS
              })
            }
          }
        } else {
          for (let projectGroup of ctrl.projectGroups) {
            request.uninvitedUsers.push({
              userId: ctrl.user.id,
              projectGroupId: projectGroup.id,
              moduleType: ModuleType.ASSETS
            })
          }
        }
      }
    }

    function populateObservationGroupUsers (request) {
      let observationGroupRoles = _.filter(ctrl.allOrgObservationRoles, r => {
        return r.type === roleType.GROUP
      })
      for (let observationGroupRole of observationGroupRoles) {
        if (observationGroupRole === ctrl.model.observationRole) {
          let selectedProjectGroups = ctrl.model.observationGroupsSelected[observationGroupRole.name]
          for (let projectGroup of ctrl.projectGroups) {
            if (selectedProjectGroups[projectGroup.id]) {
              request.updatedUsers.push({
                userId: ctrl.user.id,
                userEmail: ctrl.user.email,
                projectGroupId: projectGroup.id,
                observationRole: ctrl.model.observationRole.name
              })
            } else {
              request.uninvitedUsers.push({
                userId: ctrl.user.id,
                projectGroupId: projectGroup.id,
                moduleType: ModuleType.OBSERVATIONS
              })
            }
          }
        } else {
          for (let projectGroup of ctrl.projectGroups) {
            request.uninvitedUsers.push({
              userId: ctrl.user.id,
              projectGroupId: projectGroup.id,
              moduleType: ModuleType.OBSERVATIONS
            })
          }
        }
      }
    }

    function getSelectedAssetGroups () {
      let selectedGroupsMap = ctrl.model.assetGroupsSelected[ctrl.model.assetRole.name]
      if (!selectedGroupsMap) {
        return []
      }
      return _.filter(Object.keys(selectedGroupsMap), k => {
          return selectedGroupsMap[k]
      })
    }

    function getSelectedObservationGroups () {
      let selectedGroupsMap = ctrl.model.observationGroupsSelected[ctrl.model.observationRole.name]
      if (!selectedGroupsMap) {
        return []
      }
      return _.filter(Object.keys(selectedGroupsMap), k => {
          return selectedGroupsMap[k]
      })
    }
  }
})
