/* global angular */

/**
 * Created by ryanrubbico on 1/2/18.
 */
angular.module('smartvid').service('storageServiceIntegrationApi', function ($http, $q, $log, config) {
  let rootUrl = config.env.development.apiRootUrl
  this.listRootFolders = listRootFolders
  this.listSubFolders = listSubFolders
  this.getFolderInfo = getFolderInfo
  this.getStorageServiceUser = getStorageServiceUser

  function listRootFolders (projectId, integrationTypeName, customErrorHandler) {
    let url = `${rootUrl}/api/integration/project/${projectId}/${integrationTypeName}/folder`
    let defer = $q.defer()

    $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
      $log.debug(`get ${integrationTypeName} root folders`, response.data)
      defer.resolve(response.data)
    }, function (response) {
      defer.reject(response.data)
    })

    return defer.promise
  }

  function listSubFolders (projectId, integrationTypeName, folderId, customErrorHandler) {
    let url = `${rootUrl}/api/integration/project/${projectId}/${integrationTypeName}/folder/${encodeURIComponent(folderId)}/`
    let defer = $q.defer()

    $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
      $log.debug(`get ${integrationTypeName} sub folders folders for folder: ${folderId}`, response.data)
      defer.resolve(response.data)
    }, function (response) {
      defer.reject(response.data)
    })

    return defer.promise
  }

  function getFolderInfo (projectId, integrationTypeName, folderId, customErrorHandler) {
    let url = `${rootUrl}/api/integration/project/${projectId}/${integrationTypeName}/folder/${encodeURIComponent(folderId)}/info`
    let defer = $q.defer()

    $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
      $log.debug(`get ${integrationTypeName} folder info for folder: ${folderId}`, response.data)
      defer.resolve(response.data)
    }, function (response) {
      defer.reject(response.data)
    })

    return defer.promise
  }

  function getStorageServiceUser (projectId, integrationTypeName, customErrorHandler) {
    let url = `${rootUrl}/api/integration/project/${projectId}/${integrationTypeName}/user`
    let defer = $q.defer()

    $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
      $log.debug(`get ${integrationTypeName} user for project with ID: ${projectId}`, response.data)
      defer.resolve(response.data)
    }, function (response) {
      defer.reject(response.data)
    })
    return defer.promise
  }
})
