/* global angular */

angular.module('smartvid').directive('integrationsProcoreCreateIssue', function (
  $q, $filter, smartvidApi, ProcoreObservationIssueTypeCollection, moment, ProcoreObservationIssueAssigneeCollection,
  ProcoreObservationIssueDistributionMembersCollection, MAX_FORGE_DESCRIPTION_LENGTH) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'integrations/integrations-procore-create-issue.html',
    scope: {
      procoreIssue: '=?',
      attachments: '=?',
      projectId: '@',
      options: '='
    },
    link: function (scope, element) {
      angular.extend(scope.options, {
        collectData: function () {
          scope.procoreIssue.due_date = moment(scope.dueDate).format()
          scope.procoreIssue.type_id = scope.selectedIssueType && scope.selectedIssueType.id
          scope.procoreIssue.status = scope.procoreIssue.status = scope.selectedIssueStatus && scope.selectedIssueStatus.name
          scope.procoreIssue.assignee_id = scope.selectedIssueAssignee && scope.selectedIssueAssignee.id
          scope.procoreIssue.distribution_member_ids = []
          const distributionMemberId = scope.selectedIssueDisMember && scope.selectedIssueDisMember.id
          if (angular.isDefined(distributionMemberId)) {
            scope.procoreIssue.distribution_member_ids.push(distributionMemberId)
          }
        }
      })
      scope.PROCORE_ISSUE_STATUS = [
        {name: 'initiated', value: $filter('i18next')('integrations.procore.createIssue.statusType.initiated')},
        {
          name: 'ready_for_review',
          value: $filter('i18next')('integrations.procore.createIssue.statusType.ready_for_review')
        },
        {name: 'not_accepted', value: $filter('i18next')('integrations.procore.createIssue.statusType.not_accepted')},
        {name: 'closed', value: $filter('i18next')('integrations.procore.createIssue.statusType.closed')}]
      scope.PROCORE_ISSUE_PRIORITY = ['Low', 'Medium', 'High', 'Urgent']
      scope.MAX_FORGE_DESCRIPTION_LENGTH = MAX_FORGE_DESCRIPTION_LENGTH

      scope.procoreIssue.status = scope.PROCORE_ISSUE_STATUS[0].name
      scope.procoreIssue.priority = scope.PROCORE_ISSUE_PRIORITY[0]
      scope.procoreIssue.name = scope.attachments[0].name

      scope.procoreIssueTypes
      scope.procoreIssueAssignees
      scope.procoreIssuePotentialDisMembers
      scope.selectedIssueType = undefined
      scope.selectedIssueStatus = scope.PROCORE_ISSUE_STATUS[0]
      scope.selectedIssueAssignee = undefined
      scope.selectedIssueDisMember = undefined
      scope.dueDate = ''
      scope.isLoadingData = true
      scope.datePickerOptions = {
        locale: {
          format: 'MM/DD/YYYY'
        },
        singleDatePicker: true
      }

      scope.procoreObservationIssueTypeChanged = () => {
        scope.procoreIssue.type_id = scope.selectedIssueType.id
      }

      scope.procoreObservationIssueAssigneeChanged = () => {
        scope.procoreIssue.assignee_id = scope.selectedIssueAssignee.id
      }

      let issueTypesPromise = smartvidApi.getProcoreObservationIssueTypes(scope.projectId).then((data) => {
        scope.procoreIssueTypes = new ProcoreObservationIssueTypeCollection(data).models
        scope.selectedIssueType = scope.procoreIssueTypes[0]
      })
      let issueAssignees = smartvidApi.getProcoreObservationAssignees(scope.projectId).then((data) => {
        scope.procoreIssueAssignees = new ProcoreObservationIssueAssigneeCollection(data).models
        scope.selectedIssueAssignee = scope.procoreIssueAssignees[0]
      })
      let issuePotentialDisMembers = smartvidApi.getProcoreObservationPotentialDisMembers(scope.projectId).then((data) => {
        scope.procoreIssuePotentialDisMembers = new ProcoreObservationIssueDistributionMembersCollection(data).models
        scope.selectedIssueDisMember = scope.procoreIssuePotentialDisMembers[0]
      })
      $q.all([issueTypesPromise, issueAssignees, issuePotentialDisMembers]).then(() => {
        scope.isLoadingData = false
      })
    }
  }
})
