/* global angular */

angular.module('smartvid').directive('integrationsForgeCreateIssue', function (
  $q, utils, smartvidApi, ForgeIssueTypeCollection, ForgeRootCauseCollection, moment,
  MAX_FORGE_DESCRIPTION_LENGTH, MAX_FORGE_TITLE_LENGTH) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'integrations/integrations-forge-create-issue.html',
    scope: {
      forgeIssue: '=?',
      projectId: '@'
    },
    link: function (scope, element) {
      let FORGE_STATUS_OPEN = 'OPEN'
      let FORGE_STATUS_DRAFT = 'DRAFT'
      scope.FORGE_STATUS = [FORGE_STATUS_OPEN, FORGE_STATUS_DRAFT]

      scope.forgeIssue.status = FORGE_STATUS_OPEN

      scope.forgeIssueTypes
      scope.forgeRootCauses
      scope.selectedForgeIssueType
      scope.selectedForgeIssueSubType
      scope.selectedForgeRootCause
      scope.dueDate = ''
      scope.isLoadingData = true
      scope.MAX_FORGE_TITLE_LENGTH = MAX_FORGE_TITLE_LENGTH
      scope.MAX_FORGE_DESCRIPTION_LENGTH = MAX_FORGE_DESCRIPTION_LENGTH

      scope.datePickerOptions = {
        locale: {
          format: 'MM/DD/YYYY'
        },
        singleDatePicker: true
      }

      scope.$watch('dueDate', () => {
        scope.forgeIssue.dueDate = moment(scope.dueDate).valueOf()
      })

      scope.forgeIssueTypeChanged = () => {
        if (!scope.selectedForgeIssueType) {
          return
        }
        scope.forgeIssue.issueTypeId = scope.selectedForgeIssueType.issueTypeId
        scope.selectedForgeIssueSubType = undefined
      }

      scope.forgeIssueSubTypeChanged = () => {
        if (!scope.selectedForgeIssueSubType) {
          return
        }
        scope.forgeIssue.issueSubTypeId = scope.selectedForgeIssueSubType.issueTypeId
      }

      scope.forgeRootCauseChanged = () => {
        if (!scope.selectedForgeRootCause) {
          return
        }
        scope.forgeIssue.rootCauseId = scope.selectedForgeRootCause.id
      }

      let issueTypesPromise = smartvidApi.getForgeIssueTypes(scope.projectId).then((data) => {
        scope.forgeIssueTypes = new ForgeIssueTypeCollection(data).models
      })
      let issueRootCausesPromise = smartvidApi.getForgeRootCauses(scope.projectId).then((data) => {
        scope.forgeRootCauses = new ForgeRootCauseCollection(data).models
      })

      $q.all([issueTypesPromise, issueRootCausesPromise]).then(() => {
        scope.isLoadingData = false
      })
    }
  }
})
