/* global angular */

angular.module('smartvid').service('batchJobService', function ($interval, $rootScope, currentUser, BatchJobStatus, smartvidApi) {
  return {
    manageBatchJob (organizationId, projectId, jobDefer, successEventParameters, failedEventParameters, successCallback) {
      let apiStrategyFn = (projectId) ? smartvidApi.getProjectJobStatus : smartvidApi.getOrgJobStatus
      let entityId = projectId || organizationId
      jobDefer.then(
        (jobResponse) => {
          if (!jobResponse.data.jobName || !jobResponse.data.jobId) {
            $rootScope.$broadcast.apply($rootScope, failedEventParameters)
            return
          }
          this.watchBatchJob(
              apiStrategyFn, entityId, jobResponse.data.jobName, jobResponse.data.jobId, successEventParameters,
              failedEventParameters, successCallback, jobResponse)
        },
        () => {
          $rootScope.$broadcast.apply($rootScope, failedEventParameters)
        }
      )
    },

    watchBatchJob (apiStrategyFn, entityId, jobName, jobId, successEventParameters, failedEventParameters, successCallback, jobResponse) {
      var finished = false
      let stop = $interval(() => {
        if (finished) {
          return
        }
        if (!currentUser.isAuthenticated()) {
          $interval.cancel(stop)
          finished = true
          return
        }
        apiStrategyFn(entityId, jobName, jobId).then(
          (status) => {
            if (finished) {
              return
            }
            if (status === BatchJobStatus.COMPLETED) {
              $rootScope.$broadcast.apply($rootScope, successEventParameters, jobResponse)
              if (successCallback) {
                successCallback(jobResponse.data)
              }
              $interval.cancel(stop)
              finished = true
            } else if (status === BatchJobStatus.FAILED) {
              $rootScope.$broadcast.apply($rootScope, failedEventParameters)
              $interval.cancel(stop)
              finished = true
            } else if (status !== BatchJobStatus.RUNNING && status !== BatchJobStatus.UNKNOWN) {
              // Unexpected job status
              $rootScope.$broadcast.apply($rootScope, failedEventParameters)
              $interval.cancel(stop)
              finished = true
            }
          },
          () => {
            $rootScope.$broadcast.apply($rootScope, failedEventParameters)
            $interval.cancel(stop)
            finished = true
          })
      }, 3000)
    }
  }
})
