/* global angular */
angular.module('smartvid').service('gaugeSettingsService', function ($filter) {
  this.getGaugeSettings = getGaugeSettings

  function getGaugeSettings (snrVal) {
    let cleanedSNRVal = parseInt(snrVal, 10)
    cleanedSNRVal = (cleanedSNRVal > 50) ? 50 : cleanedSNRVal
    cleanedSNRVal = (cleanedSNRVal < 0) ? 0 : cleanedSNRVal
    return {
      containerBackgroundColor: '#4A4A4A',
      theme: 'generic.dark',
      geometry: {
        startAngle: 210,
        endAngle: -30
      },
      scale: {
        startValue: 0,
        endValue: 50,
        tick: {
          opacity: 1,
          color: '#4A4A4A',
          visible: true,
          width: 3,
          length: 10
        },
        majorTick: {
          tickInterval: 5
        },
        label: {
          visible: false,
          indentFromTick: -1
        }
      },
      rangeContainer: {
        ranges: [
          {startValue: 0, endValue: 5, color: '#95252A'},
          {startValue: 5, endValue: 10, color: '#964246'},
          {startValue: 10, endValue: 15, color: '#986062'},
          {startValue: 15, endValue: 20, color: '#997D7F'},
          {startValue: 20, endValue: 25, color: '#9B9B9B'},
          {startValue: 25, endValue: 30, color: '#9B9B9B'},
          {startValue: 30, endValue: 35, color: '#9B9B9B'},
          {startValue: 35, endValue: 40, color: '#9B9B9B'},
          {startValue: 40, endValue: 45, color: '#9B9B9B'},
          {startValue: 45, endValue: 50, color: '#9B9B9B'}
        ]
      },
      title: {
        text: $filter('i18next')('directives.infopanel.snrGauge.title'),
        font: {
          size: 13,
          family: 'Poppins',
          color: '#9B9B9B'
        }
      },
      valueIndicator: {
        type: 'twoColorNeedle',
        color: '#898989',
        spindleGapSize: 10,
        secondColor: '#C2C2C2'
      },
      value: cleanedSNRVal    // SNR value
    }
  }
})
