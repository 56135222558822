/* global angular analytics */
angular.module('smartvid').service('searchService', function ($state, dashboardDataHelper) {
  this.searchWithAssetSearchContext = searchWithAssetSearchContext
  this.searchWithObservationSearchContext = searchWithObservationSearchContext

  function searchWithAssetSearchContext (assetSearchContext) {
    if (dashboardDataHelper.getCurrentProject()) {
      analytics.track('Run Search', {
        category: 'Project Action',
        orgName: (dashboardDataHelper.getCurrentProject().getProjectOrg()) ? dashboardDataHelper.getCurrentProject().getProjectOrg().name : undefined,
        projectName: dashboardDataHelper.getCurrentProject().name
      })
      $state.go('dashboard.projects.projectId.files', {
        projectId: dashboardDataHelper.getCurrentProject().id,
        searchContext: assetSearchContext,
        isDemoProject: dashboardDataHelper.getCurrentProject().isDemoProjectCopy,
        skipRecentSearches: false
      }, {
        reload: true
      })
    } else {
      analytics.track('Run Search', {
        category: 'Organization Action'
      })
      // TODO WA-3302 global search context
      $state.go('dashboard.projects', {
        globalSearchContext: assetSearchContext,
        skipRecentSearches: false
      }, {
        reload: true
      })
    }
  }

  function searchWithObservationSearchContext (observationSearchContext) {
    $state.go($state.$current.name, {
      searchContext: observationSearchContext
    })
  }
})
