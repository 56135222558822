/* global angular, _ */

angular.module('smartvid').directive('uploadtracker', function ($rootScope, fileUploadService, Notification, $filter,
                                                                utils, $timeout, $window, $state,
                                                                vinnieLimitsNotificationService, FeatureSettingsModel) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'uploadtracker.html',
    link: function (scope, elem) {
      scope.expand = false
      scope.show = false
      scope.right = 220
      scope.activeCount = 0
      const Statuses = {
        'READY': 'READY',
        'IN_PROGRESS': 'IN_PROGRESS',
        'COMPLETE': 'COMPLETE',
        'FAILED': 'FAILED',
        'CANCELLED': 'CANCELLED'
      }
      const LoaderTypes = {
        'STATIC_LOADER': 'STATIC_LOADER',
        'DYNAMIC_LOADER': 'DYNAMIC_LOADER',
        'MESSAGE_LOADER': 'MESSAGE_LOADER'
      }
      scope.Statuses = Statuses
      scope.LoaderTypes = LoaderTypes
      scope.allCompleted = false
      scope.allProcesses = {
        tagImport: {
          id: 1,
          name: 'directives.uploadtracker.import',
          active: false,
          totalProgress: undefined,
          status: Statuses.READY,
          loaderType: LoaderTypes.STATIC_LOADER,
          isCancelSupported: false,
          data: {}
        },
        assetAndSnapshot: {
          id: 2,
          name: 'directives.uploadtracker.download',
          active: false,
          totalProgress: 0,
          status: Statuses.READY,
          loaderType: LoaderTypes.STATIC_LOADER,
          isCancelSupported: false,
          data: {}
        },
        tagExport: {
          id: 3,
          name: 'directives.uploadtracker.export',
          active: false,
          totalProgress: 0,
          status: Statuses.READY,
          loaderType: LoaderTypes.DYNAMIC_LOADER,
          isCancelSupported: false,
          data: {}
        },
        fileUpload: {
          id: 4,
          name: 'directives.uploadtracker.upload',
          active: false,
          totalProgress: 0,
          status: Statuses.READY,
          loaderType: LoaderTypes.DYNAMIC_LOADER,
          isCancelSupported: false,
          data: {}
        },
        bulkUpload: {
          id: 5,
          name: 'directives.uploadtracker.bulkUpload',
          active: false,
          totalProgress: 0,
          uploadedFilesCount: 0,
          duplicateFilesCount: 0,
          totalFilesCount: 0,
          status: Statuses.READY,
          loaderType: LoaderTypes.MESSAGE_LOADER,
          isCancelSupported: true,
          data: {}
        },
        batchFileDelete: {
          id: 6,
          name: 'directives.uploadtracker.batchDelete',
          active: false,
          operationCount: 0,
          status: Statuses.READY,
          loaderType: LoaderTypes.STATIC_LOADER,
          isCancelSupported: false,
          data: {}
        },
        batchTagDefDelete: {
          id: 6,
          name: 'directives.uploadtracker.batchTagDefDelete',
          active: false,
          operationCount: 0,
          status: Statuses.READY,
          loaderType: LoaderTypes.STATIC_LOADER,
          isCancelSupported: false,
          data: {}
        },
        batchTag: {
          id: 7,
          name: 'directives.uploadtracker.batchTag',
          active: false,
          operationCount: 0,
          status: Statuses.READY,
          loaderType: LoaderTypes.STATIC_LOADER,
          isCancelSupported: false,
          data: {}
        },
        batchSmartTag: {
          id: 8,
          name: 'directives.uploadtracker.batchSmartTag',
          active: false,
          operationCount: 0,
          status: Statuses.READY,
          loaderType: LoaderTypes.STATIC_LOADER,
          isCancelSupported: false,
          data: {}
        },
        batchExportAssetsWithTagCounts: {
          id: 9,
          name: 'directives.uploadtracker.batchExportAssetsWithTagCounts',
          active: false,
          operationCount: 0,
          status: Statuses.READY,
          loaderType: LoaderTypes.STATIC_LOADER,
          isCancelSupported: false,
          data: {}
        },
        batchExportWithinOrgAssetsWithTagCounts: {
          id: 10,
          name: 'directives.uploadtracker.batchExportAssetsWithTagCounts',
          active: false,
          operationCount: 0,
          status: Statuses.READY,
          loaderType: LoaderTypes.STATIC_LOADER,
          isCancelSupported: false,
          data: {}
        },
        batchMoveAsset: {
          id: 11,
          name: 'directives.uploadtracker.movingFiles',
          active: false,
          totalProgress: 0,
          status: Statuses.READY,
          loaderType: LoaderTypes.DYNAMIC_LOADER,
          isCancelSupported: false,
          data: {}
        }
      }

      // list of processes that are active in the upload tracker (references to items in allProcesses)
      scope.activeProcesses = []

      // TODO: PL: remove when we don't need to limit VINNIE anymore ->
      scope.showVinnieLimitsFooter = false
      let updateShowVinnieLimitsFooterStatus = () => {
        if (vinnieLimitsNotificationService.canProcessVinnieLimits(scope.currentProject)) {
          vinnieLimitsNotificationService.getVinnieLimitsInfo(scope.currentProject).then((data) => {
            let vinnieLimitsInfo = new FeatureSettingsModel(data)
            scope.showVinnieLimitsFooter = vinnieLimitsNotificationService.needToShowVinnieLimitsFooter(vinnieLimitsInfo, scope.currentProject)
          })
        }
      }
      // TODO: PL: remove when we don't need to limit VINNIE anymore <-

      scope.resetTrackedItem = function (trackedItem) {
        trackedItem.active = false
        trackedItem.data = {}
        trackedItem.totalProgress = 0
        trackedItem.status = Statuses.READY
      }

      scope.resetCompleteTasks = function () {
        _.each(scope.allProcesses, (trackedItem) => {
          if (trackedItem.status === Statuses.COMPLETE || trackedItem.status === Statuses.FAILED) {
            scope.resetTrackedItem(trackedItem)
          }
        })

        // reset active process list
        scope.activeProcesses = _.filter(scope.allProcesses, (process) => {
          return process.active
        })

        // digest changes
        utils.digest(scope)
      }

      scope.updateActiveCount = function () {
        scope.$evalAsync(() => {
          let count = 0
          _.each(scope.allProcesses, (trackedItem) => {
            if (trackedItem.active) {
              count++
            }
          })
          if (count <= 0) {
            scope.expand = false
            scope.show = false
          }
          scope.activeCount = count
        })
      }

      //
      // Batch asset tag
      //
      // ------------------ START --------------------
      let configureBatchEvents = (startEvent, completeEvent, failedEvent, trackerItem, completeMessage, failedMessage) => {
        scope.$on(startEvent, () => {
          scope.resetTrackedItem(trackerItem)
          trackerItem.active = true
          scope.expand = true
          scope.show = true
          trackerItem.operationCount++
          trackerItem.status = Statuses.IN_PROGRESS
          upsertProcess(trackerItem)
          scope.updateActiveCount()
          updateShowVinnieLimitsFooterStatus()
          start()
        })
        scope.$on(failedEvent, () => {
          trackerItem.operationCount--
          utils.notify(failedMessage)
          if (trackerItem.operationCount === 0) {
            trackerItem.status = Statuses.FAILED
            finish(trackerItem)
            utils.digest(scope)
          }
        })

        function doNotifyUponTrackerItem (goToStateName, goToStateParams, trackerItem) {
          function navigateWithParams () {
            $state.go(goToStateName, goToStateParams, {
              reload: true
            })
          }

          switch (trackerItem) {
            case scope.allProcesses.batchSmartTag:
            case scope.allProcesses.batchTag:
            case scope.allProcesses.batchFileDelete:
            case scope.allProcesses.batchTagDefDelete:
              utils.notice({
                messagePrimary: $filter('i18next')(completeMessage),
                messageSecondary: '',
                handler: navigateWithParams,
                callHandlerFunction: true
              })
              break
            default:
              utils.notify(completeMessage, '', $filter('i18next')('directives.uploadtracker.showNow'), true, () => navigateWithParams)
              break
          }
        }

        scope.$on(completeEvent, ($event, goToStateName, goToStateParams) => {
          trackerItem.operationCount--
          if (goToStateName) {
            doNotifyUponTrackerItem(goToStateName, goToStateParams, trackerItem)
          }
          if (trackerItem.operationCount === 0) {
            trackerItem.status = Statuses.COMPLETE
            finish(trackerItem.batchTag)
            utils.digest(scope)
          }
        })
      }

      configureBatchEvents(
        'sv-before-batch-asset-tag-start', 'sv-all-batch-asset-tag-complete', 'sv-all-batch-asset-tag-failed',
        scope.allProcesses.batchTag,
        'directives.uploadtracker.batchTagComplete', 'directives.uploadtracker.batchTagFailed')

      configureBatchEvents(
        'sv-before-batch-asset-smarttag-start', 'sv-all-batch-asset-smarttag-complete', 'sv-all-batch-asset-smarttag-failed',
        scope.allProcesses.batchSmartTag,
        'directives.uploadtracker.batchSmartTagComplete', 'directives.uploadtracker.batchSmartTagFailed')

      configureBatchEvents(
        'sv-before-batch-asset-delete-start', 'sv-all-batch-asset-delete-complete', 'sv-all-batch-asset-delete-failed',
        scope.allProcesses.batchFileDelete,
        'directives.uploadtracker.batchDeleteComplete', 'directives.uploadtracker.batchDeleteFailed')

      configureBatchEvents(
        'sv-before-batch-tag-definition-delete-start', 'sv-all-batch-tag-definition-delete-complete', 'sv-all-batch-tag-definition-delete-failed',
        scope.allProcesses.batchTagDefDelete,
        'directives.uploadtracker.batchTagDefDeleteComplete', 'directives.uploadtracker.batchDeleteFailed')

      configureBatchEvents(
        'sv-before-batch-export-asset-with-tag-counts-start', 'sv-before-batch-export-asset-with-tag-counts-complete', 'sv-before-batch-export-asset-with-tag-counts-failed',
        scope.allProcesses.batchExportAssetsWithTagCounts,
        'directives.uploadtracker.batchExportAssetsWithTagCountsComplete', 'directives.uploadtracker.batchExportAssetsWithTagCountsFailed')

      configureBatchEvents(
        'sv-before-batch-export-within-org-asset-with-tag-counts-start', 'sv-before-batch-export-asset-with-tag-counts-complete', 'sv-before-batch-export-asset-with-tag-counts-failed',
        scope.allProcesses.batchExportWithinOrgAssetsWithTagCounts,
        'directives.uploadtracker.batchExportAssetsWithTagCountsComplete', 'directives.uploadtracker.batchExportAssetsWithTagCountsFailed')

      //
      // Bulk File Upload
      //
      scope.onCancel = (process) => {
        process.status = Statuses.CANCELLED
        finish(process)
        utils.digest(scope)
        $rootScope.$broadcast('sv-cancel-bulk-upload')
      }

      // ----------------- UPDATE PROGRESS -----------
      scope.$on('sv-bulk-upload-file-uploaded', (evt, data) => {
        if (data.isDuplicate) {
          scope.allProcesses.bulkUpload.duplicateFilesCount++
        } else {
          scope.allProcesses.bulkUpload.uploadedFilesCount++
        }
      })
      // ------------------ COMPLETE --------------------
      scope.$on('sv-all-bulk-upload-complete', ($event, projectId, isDemoProject, numDuplicates) => {
        let secondaryMessage = (numDuplicates === 0) ? '' : $filter('i18next')('directives.uploadtracker.numberOfDuplicates') + ' ' + numDuplicates
        utils.notice({
          messagePrimary: $filter('i18next')('directives.uploadtracker.bulkUploadsComplete'),
          messageSecondary: secondaryMessage,
          callHandlerFunction: true,
          handler: function () {
            $state.go('dashboard.projects.projectId.files', {
              projectId: projectId,
              isDemoProject: isDemoProject
            }, {
              reload: true
            })
          }
        })
        scope.allProcesses.bulkUpload.status = Statuses.COMPLETE
        finish(scope.allProcesses.bulkUpload)
        utils.digest(scope)
      })
      // ------------------ FAILED --------------------
      scope.$on('sv-bulk-upload-failed', ($event, failed) => {
        scope.allProcesses.bulkUpload.status = Statuses.FAILED
        utils.notify('directives.uploadtracker.bulkUploadsFailed1', 'directives.uploadtracker.bulkUploadsFailed2', $filter('i18next')('directives.uploadtracker.retryAll'), true, ($scope) => {
          $scope.inTransit = true
          $rootScope.$broadcast('sv-retry-failed-uploads', failed)
          scope.allProcesses.bulkUpload.uploadedFilesCount = 0
          scope.allProcesses.bulkUpload.totalFilesCount = 0
          scope.allProcesses.bulkUpload.duplicateFilesCount = 0
        })
        finish(scope.allProcesses.bulkUpload)
        utils.digest(scope)
      })
      // ------------------ START --------------------
      scope.$on('sv-before-bulk-upload-start', () => {
        if (scope.allProcesses.bulkUpload.status === Statuses.CANCELLED || scope.allProcesses.bulkUpload.status === Statuses.COMPLETE) {
          scope.allProcesses.bulkUpload.uploadedFilesCount = 0
          scope.allProcesses.bulkUpload.totalFilesCount = 0
          scope.allProcesses.bulkUpload.duplicateFilesCount = 0
        }
        scope.resetTrackedItem(scope.allProcesses.bulkUpload)
        scope.allProcesses.bulkUpload.totalFilesCount++
        scope.allProcesses.bulkUpload.active = true
        scope.expand = true
        scope.show = true
        scope.allProcesses.bulkUpload.status = Statuses.IN_PROGRESS
        upsertProcess(scope.allProcesses.bulkUpload)
        scope.updateActiveCount()
        updateShowVinnieLimitsFooterStatus()
        start()
      })

      //
      // File Upload
      //
      // ----------------- UPDATE PROGRESS -----------
      scope.$on('sv-upload-total-progress', (evt, totalProgress) => {
        scope.allProcesses.fileUpload.totalProgress = totalProgress
        utils.digest(scope)
      })
      // ------------------ COMPLETE --------------------
      scope.$on('sv-all-uploads-complete', (evt, data) => {
        let secondaryMessage = (data.duplicatesCount === 0) ? '' : $filter('i18next')('directives.uploadtracker.numberOfDuplicates') + ' ' + data.duplicatesCount
        utils.notice({
          'messagePrimary': $filter('i18next')('directives.uploadtracker.uploadsComplete'),
          'messageSecondary': secondaryMessage
        })
        scope.allProcesses.fileUpload.status = Statuses.COMPLETE
        finish(scope.allProcesses.fileUpload)
        utils.digest(scope)
      })
      // ------------------ FAILED --------------------
      scope.$on('sv-uploads-failed', ($event, failed) => {
        scope.allProcesses.fileUpload.status = Statuses.FAILED
        utils.notify('directives.uploadtracker.uploadsComplete', 'directives.uploadtracker.oneOrMoreFailed', $filter('i18next')('directives.uploadtracker.retryAll'), true, ($scope) => {
          $scope.inTransit = true
          $rootScope.$broadcast('sv-retry-failed-uploads', failed)
        })
        finish(scope.allProcesses.fileUpload)
        utils.digest(scope)
      })
      // ------------------ START --------------------
      scope.$on('sv-before-upload-start', () => {
        scope.resetTrackedItem(scope.allProcesses.fileUpload)
        scope.allProcesses.fileUpload.active = true
        scope.expand = true
        scope.show = true
        scope.allProcesses.fileUpload.status = Statuses.IN_PROGRESS
        upsertProcess(scope.allProcesses.fileUpload)
        scope.updateActiveCount()
        updateShowVinnieLimitsFooterStatus()
        start()
      })

      scope.$on('sv-before-upload', () => {
        scope.show = true
        scope.expand = true
        scope.allCompleted = false
        updateShowVinnieLimitsFooterStatus()
      })

      //
      // Tag Import
      //
      // ------------------ START --------------------
      scope.$on('sv-tag-import-before-start', () => {
        scope.resetTrackedItem(scope.allProcesses.tagImport)
        scope.allProcesses.tagImport.active = true
        scope.allProcesses.tagImport.status = Statuses.IN_PROGRESS
        scope.show = true
        scope.expand = true
        upsertProcess(scope.allProcesses.tagImport)
        scope.updateActiveCount()
        updateShowVinnieLimitsFooterStatus()
        start()
        utils.digest(scope)
      })
      // ------------------ COMPLETE --------------------
      scope.$on('sv-tag-import-complete', () => {
        scope.allProcesses.tagImport.status = Statuses.COMPLETE
        utils.notify('tags.modal.header-import-success', '', 'View Report', true, ($scope) => {
          $rootScope.$broadcast('sv-open-tag-import-report')
        })
        finish(scope.allProcesses.tagImport)
        utils.digest(scope)
      })
      // ------------------ FAILED --------------------
      scope.$on('sv-tag-import-failed', (event, errors) => {
        scope.allProcesses.tagImport.status = Statuses.FAILED
        utils.notify('tags.modal.header-import-failure', 'directives.uploadtracker.tagImportFailed', 'View Report', true, ($scope) => {
          $rootScope.$broadcast('sv-open-tag-import-report')
        })
        finish(scope.allProcesses.tagImport)
        utils.digest(scope)
      })

      //
      // Asset Snapshot Downloads
      //
      // ------------------ START --------------------
      scope.$on('sv-asset-snapshot-before-start', (evt, isFile) => {
        scope.resetTrackedItem(scope.allProcesses.assetAndSnapshot)
        scope.allProcesses.assetAndSnapshot.active = true
        scope.allProcesses.assetAndSnapshot.status = Statuses.IN_PROGRESS
        if (isFile) {
          scope.allProcesses.assetAndSnapshot.data.preparingFiles = true
        } else {
          scope.allProcesses.assetAndSnapshot.data.preparingSnapshots = true
        }
        scope.allProcesses.assetAndSnapshot.totalProgress = 0
        scope.show = true
        scope.expand = true
        upsertProcess(scope.allProcesses.assetAndSnapshot)
        scope.updateActiveCount()
        updateShowVinnieLimitsFooterStatus()
        start()
        utils.digest(scope)
      })
      // ------------------ COMPLETE --------------------
      scope.$on('sv-asset-snapshot-complete', (evt, isFile) => {
        scope.allProcesses.assetAndSnapshot.status = Statuses.COMPLETE
        if (isFile) {
          scope.allProcesses.assetAndSnapshot.data.preparingFiles = false
        } else {
          scope.allProcesses.assetAndSnapshot.data.preparingSnapshots = false
        }
        scope.allProcesses.assetAndSnapshot.totalProgress = 100
        finish(scope.allProcesses.assetAndSnapshot)
        utils.digest(scope)
      })
      // ------------------ FAILED --------------------
      scope.$on('sv-asset-snapshot-failed', (evt, isFile) => {
        scope.allProcesses.assetAndSnapshot.status = Statuses.FAILED
        if (isFile) {
          scope.allProcesses.assetAndSnapshot.data.preparingFiles = false
        } else {
          scope.allProcesses.assetAndSnapshot.data.preparingSnapshots = false
        }
        scope.allProcesses.assetAndSnapshot.totalProgress = 0
        finish(scope.allProcesses.assetAndSnapshot)
        utils.digest(scope)
      })
      // ----------------- Update Progress -----------
      scope.$on('sv-asset-snapshot-total-progress', (evt, totalProgress) => {
        scope.allProcesses.assetAndSnapshot.totalProgress = totalProgress
        utils.digest(scope)
      })

      //
      // Tag Export
      //
      // ------------------ START --------------------
      scope.$on('sv-tag-export-before-start', (evt) => {
        scope.resetTrackedItem(scope.allProcesses.tagExport)
        scope.allProcesses.tagExport.active = true
        scope.allProcesses.tagExport.status = Statuses.IN_PROGRESS
        scope.allProcesses.tagExport.totalProgress = 0
        scope.show = true
        scope.expand = true
        upsertProcess(scope.allProcesses.tagExport)
        scope.updateActiveCount()
        updateShowVinnieLimitsFooterStatus()
        start()
        utils.digest(scope)
      })
      // ------------------ COMPLETE --------------------
      scope.$on('sv-tag-export-complete', (evt) => {
        scope.allProcesses.tagExport.status = Statuses.COMPLETE
        scope.allProcesses.tagExport.totalProgress = 100
        finish(scope.allProcesses.tagExport)
        utils.digest(scope)
      })
      // ------------------ FAILED --------------------
      scope.$on('sv-tag-export-failed', (evt) => {
        scope.allProcesses.tagExport.status = Statuses.FAILED
        scope.allProcesses.tagExport.totalProgress = 0
        finish(scope.allProcesses.tagExport)
        utils.digest(scope)
      })
      // ----------------- UPDATE PROGRESS -----------
      scope.$on('sv-tag-export-total-progress', (evt, totalProgress) => {
        scope.allProcesses.tagExport.totalProgress = totalProgress
        utils.digest(scope)
      })

      //
      // Move assets
      //
      // ------------------ START --------------------
      scope.$on('sv-move-asset-before-start', (evt) => {
        scope.resetTrackedItem(scope.allProcesses.batchMoveAsset)
        scope.allProcesses.batchMoveAsset.active = true
        scope.allProcesses.batchMoveAsset.status = Statuses.IN_PROGRESS
        scope.allProcesses.batchMoveAsset.totalProgress = 0
        scope.show = true
        scope.expand = true
        scope.showVinnieLimitsFooter = true
        upsertProcess(scope.allProcesses.batchMoveAsset)
        scope.updateActiveCount()
        start()
        utils.digest(scope)
      })
      // ------------------ COMPLETE --------------------
      scope.$on('sv-move-asset-complete', (evt) => {
        scope.allProcesses.batchMoveAsset.status = Statuses.COMPLETE
        scope.allProcesses.batchMoveAsset.totalProgress = 100
        finish(scope.allProcesses.batchMoveAsset)
        utils.digest(scope)
      })
      // ------------------ FAILED --------------------
      scope.$on('sv-move-asset-failed', (evt) => {
        scope.allProcesses.batchMoveAsset.status = Statuses.FAILED
        scope.allProcesses.batchMoveAsset.totalProgress = 0
        finish(scope.allProcesses.batchMoveAsset)
        utils.digest(scope)
      })
      // ----------------- UPDATE PROGRESS -----------
      scope.$on('sv-move-asset-total-progress', (evt, totalProgress) => {
        scope.allProcesses.batchMoveAsset.totalProgress = totalProgress
        utils.digest(scope)
      })

      scope.startDrag = function (evt) {
        evt = evt || window.event
        var start = evt.pageX !== undefined ? evt.pageX : evt.clientX
        start = start + scope.right

        let min = 116
        let max = angular.element($window).width() - angular.element(evt.target)[0].offsetWidth

        let right = 0

        document.body.onmousemove = function (evt) {
          evt = evt || window.event
          let end = evt.pageX !== undefined ? evt.pageX : evt.clientX

          // If we are going off the screen do nothing
          if ((end - start) > 0) {
            scope.right = min
          } else {
            right = Math.abs(end - start)
            right = Math.max(min, right)
            right = Math.min(max, right)
            scope.right = right
          }

          elem[0].style.right = scope.right + 'px'
        }

        document.body.onmouseup = function (evt) {
          document.body.onmousemove = document.body.onmouseup = null
        }
      }

      scope.setUploadTrackerStyle = function () {
        return {
          right: scope.right,
          height: (scope.expand) ? 30 + (51.82 * scope.activeCount) + 'px' : '30px',
          bottom: scope.showVinnieLimitsFooter ? '36px' : '0px'
        }
      }

      scope.getWidth = function (progress) {
        let width = progress + '%'
        return {
          width: width
        }
      }

      scope.openTagImportReport = function () {
        $rootScope.$broadcast('sv-open-tag-import-report')
      }

      scope.exit = function () {
        scope.show = false
        scope.resetCompleteTasks()
        scope.updateActiveCount()
      }

      // ///////////////////////////////////////////////////////////
      // /////////////////////// PRIVATE ///////////////////////////
      // ///////////////////////////////////////////////////////////

      function upsertProcess (process) {
        let found = false
        _.each(scope.activeProcesses, (activeProcess, idx) => {
          if (activeProcess.id === process.id) {
            scope.activeProcesses[idx] = process
            found = true
            return
          }
        })

        if (!found) {
          scope.activeProcesses.push(process)
        }
      }

      function finish (process) {
        scope.allCompleted = allCompleted()
      }

      function start () {
        scope.allCompleted = allCompleted()
      }

      function allCompleted () {
        let allCompleted = true
        _.each(scope.activeProcesses, (process) => {
          if (process.status !== Statuses.COMPLETE && process.status !== Statuses.FAILED) {
            allCompleted = false
            return
          }
        })
        return allCompleted
      }
    }
  }
})
