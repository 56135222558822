import * as _ from 'lodash'
import { Injectable, Inject } from '@angular/core'
import { HTTP } from 'shared/smartvid.types'
import { ConfigService } from 'modules/core/services/config.service'
import {
  CancellableRequestSubscription,
  DashboardCardConfiguration,
  DashboardCardId,
  DashboardConfiguration,
  DashboardConfigurationRequest,
  DashboardConfigurationResponse,
  DashboardTabConfiguration,
  DashboardTabId,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { Subject } from 'rxjs'
import { toObservable } from 'angular2/shared/utils/api-utils'
import { first } from 'rxjs/operators'
import * as Q from 'q'

@Injectable({
  providedIn: 'root',
})
export class InsightsDashboardConfigurationService {
  private _onConfigurationChange$ = new Subject<DashboardConfiguration>()
  public currentDashboardConfig: DashboardConfiguration
  private readonly rootUrl: string

  constructor(@Inject(HTTP) private $http: ng.IHttpService, private config: ConfigService) {
    this.rootUrl = this.config.env.development.apiRootUrl
  }

  public loadDashboardConfiguration(
    dashboardConfigurationRequest: DashboardConfigurationRequest,
    onConfigLoadedFunc: (config: DashboardConfiguration) => void = undefined
  ) {
    let url = this.getApiUrl(dashboardConfigurationRequest)
    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardConfigurationResponse>(
      this.$http.get(url, { timeout: cancellableRequest.promise }),
      DashboardConfigurationResponse
    )
      .pipe(first())
      .subscribe((data: DashboardConfigurationResponse) => {
        this.currentDashboardConfig = data.dashboardConfiguration
        this._onConfigurationChange$.next(this.currentDashboardConfig)
        if (onConfigLoadedFunc) {
          onConfigLoadedFunc(this.currentDashboardConfig)
        }
      })

    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  public onConfigurationChange$(): Subject<DashboardConfiguration> {
    return this._onConfigurationChange$
  }

  public hasSummaryCard(cardId: DashboardCardId): boolean {
    return this.hasSummary() ? this.getCardIdx(cardId, this.currentDashboardConfig.summaryCards) !== -1 : false
  }

  public hasTabCard(tabId: DashboardTabId, cardId: DashboardCardId): boolean {
    let tabIdx = this.getTabIdx(tabId)
    if (tabIdx === -1) {
      return false
    }

    return this.getCardIdx(cardId, this.currentDashboardConfig.tabs[tabIdx].cards) !== -1
  }

  public hasTab(tabId: DashboardTabId): boolean {
    return this.getTabIdx(tabId) !== -1
  }

  public hasSummary() {
    return this.currentDashboardConfig && this.currentDashboardConfig.summaryCards
  }

  public getSummaryCardClasses(cardId: DashboardCardId): string[] {
    return this.getMdcCellClasses(cardId, this.currentDashboardConfig.summaryCards)
  }

  public getTabCardClasses(tabId: DashboardTabId, cardId: DashboardCardId): string[] {
    let tabIdx = this.getTabIdx(tabId)
    if (tabIdx === -1) {
      return []
    }

    return this.getMdcCellClasses(cardId, this.currentDashboardConfig.tabs[tabIdx].cards)
  }

  public getTabCardOrder(tabId: DashboardTabId, cardId: DashboardCardId): number {
    let tabIdx = this.getTabIdx(tabId)
    if (tabIdx === -1) {
      return -1
    }

    return this.getCardIdx(cardId, this.currentDashboardConfig.tabs[tabIdx].cards) + 1
  }

  public getSummaryCardOrder(cardId: DashboardCardId): number {
    return this.getCardIdx(cardId, this.currentDashboardConfig.summaryCards) + 1
  }

  public getTabConfiguration(tabId: DashboardTabId): DashboardTabConfiguration {
    if (tabId) {
      let tabIdx = this.getTabIdx(tabId)
      if (tabIdx < 0) {
        return undefined
      }

      return this.currentDashboardConfig.tabs[tabIdx]
    }
    return null
  }

  public getCardConfiguration(tabId: DashboardTabId, cardId: DashboardCardId): DashboardCardConfiguration {
    if (tabId) {
      let tabIdx = this.getTabIdx(tabId)
      if (tabIdx < 0) {
        return undefined
      }
      let cardIdx = this.getCardIdx(cardId, this.currentDashboardConfig.tabs[tabIdx].cards)
      if (cardIdx < 0) {
        return undefined
      }

      return this.currentDashboardConfig.tabs[tabIdx].cards[cardIdx]
    }

    let cardIdx = this.getCardIdx(cardId, this.currentDashboardConfig.summaryCards)
    if (cardIdx < 0) {
      return undefined
    }

    return this.currentDashboardConfig.summaryCards[cardIdx]
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private getApiUrl(request: DashboardConfigurationRequest): string {
    return (
      this.rootUrl +
      `/api/organization/${request.organizationId}/dashboard/configuration/${request.dashboardType}/` +
      `group/${request.projectGroupId ? request.projectGroupId : '*'}/` +
      `project/${request.projectId ? request.projectId : '*'}/` +
      `user/${request.userId ? request.userId : '*'}`
    )
  }

  private getTabIdx(tabId: DashboardTabId): number {
    return this.currentDashboardConfig ? _.findIndex(this.currentDashboardConfig.tabs, { tabId: tabId }) : -1
  }

  private getCardIdx(cardId: DashboardCardId, cards: DashboardCardConfiguration[]): number {
    return _.findIndex(cards, { cardId: cardId })
  }

  private getMdcCellClasses(cardId: DashboardCardId, cards: DashboardCardConfiguration[]): string[] {
    let cardIdx = this.getCardIdx(cardId, cards)
    if (cardIdx === -1) {
      return []
    }

    let card = cards[cardIdx]
    return [
      // the MDC layout grid ordered cells are limited to 12 so we need to do a workaround see getTabCardOrderStyle
      `mdc-layout-grid__cell--order-${cardIdx + 1}`,
      `mdc-layout-grid__cell--span-${card.columnSpan.desktop}-desktop`,
      `mdc-layout-grid__cell--span-${card.columnSpan.tablet}-tablet`,
      `mdc-layout-grid__cell--span-${card.columnSpan.phone}-phone`,
    ]
  }
}
