/* global angular, _ */

/**
 * Created by ryanrubbico on 1/9/17.
 */
// Enable/disable watchers based on the element position in the viewport upon scroll
angular.module('smartvid').directive('updateWatchers', function () {
  return {
    restrict: 'A',
    link: function (scope, element) {
      element.on('scroll', _.throttle(function () {
        scope.$broadcast('$updateWatchers')
      }, 250))
    }
  }
})
