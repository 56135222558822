/* global angular */

angular.module('smartvid').service('procoreIntegrationApi', function ($http, $q, $log, config, currentUser) {
  let rootUrl = config.env.development.apiRootUrl

  let api = {
    getProcorePhotoSyncSettingsForProject (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/procore/settings/project/${projectId}`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getProcorePhotoSyncSettingsForProject', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    getProcoreCreateIssueContext (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/procore/project/${projectId}/issue/context`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getProcoreCreateIssueContext', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    listProcoreCompanies (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/procore/project/${projectId}/company`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('listProcoreCompanies', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    listProcoreProjects (projectId, procoreCompanyId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/procore/project/${projectId}/company/${procoreCompanyId}/project`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('listProcoreProjects', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    listProcoreAlbums (projectId, procoreCompanyId, procoreProjectId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/procore/project/${projectId}/${procoreCompanyId}/${procoreProjectId}/album`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('listProcoreAlbums', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getProcoreUserProfile (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/procore/project/${projectId}`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getProcoreUserProfile', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    saveProcorePhotoSyncSettings (projectId, settings) {
      let url = `${rootUrl}/api/integration/procore/settings/project/${projectId}/user/${currentUser.id}`
      let defer = $q.defer()
      let payload = {}
      payload.photoSyncSettings = settings
      payload.isEnabled = settings.isEnabled

      $http.post(url, payload).then((response) => {
        $log.debug('saveProcorePhotoSyncSettings', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    enableRunOnceProjectSync (projectId) {
      let url = `${rootUrl}/api/integration/procore/sync/project/${projectId}`
      let defer = $q.defer()

      $http.put(url).then((response) => {
        $log.debug('enableRunOnceProjectSync', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    deleteProcorePhotoSyncSettings (projectId) {
      let url = `${rootUrl}/api/integration/procore/settings/project/${projectId}`
      let defer = $q.defer()

      $http.delete(url).then((response) => {
        $log.debug('deleteProcorePhotoSyncSettings', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    createProcoreObservationIssue (projectId, procoreProjectId, inspectionItemFailed, observationItem, attachments) {
      let url = `${rootUrl}/api/integration/procore/project/${projectId}/observation`
      let defer = $q.defer()
      let payload = {
        project_id: procoreProjectId,
        inspection_item_failed: inspectionItemFailed,
        observation: observationItem,
        assets_ids: attachments
      }
      $http.post(url, payload).then((response) => {
        $log.debug('createProcoreObservationItem', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    createProcoreObservationIssueWithoutAttachments (projectId, procoreProjectId, inspectionItemFailed, observationItem) {
      let url = `${rootUrl}/api/integration/procore/project/${projectId}/observation`
      let defer = $q.defer()
      let payload = {
        project_id: procoreProjectId,
        inspection_item_failed: inspectionItemFailed,
        observation: observationItem
      }
      $http.post(url, payload).then((response) => {
        $log.debug('createProcoreObservationItemWithoutAttachments', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getProcoreObservationIssueTypes (projectId) {
      let url = `${rootUrl}/api/integration/procore/project/${projectId}/observation/type`
      let defer = $q.defer()
      $http.get(url).then((response) => {
        $log.debug('getProcoreObservationItemTypes', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getProcoreObservationAssignees (projectId) {
      let url = `${rootUrl}/api/integration/procore/project/${projectId}/observation/assignee`
      let defer = $q.defer()
      $http.get(url).then((response) => {
        $log.debug('getProcoreObservationItemAssignees', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getProcoreObservationDisMembers (projectId) {
      let url = `${rootUrl}/api/integration/procore/project/${projectId}/observation/distribution/member`
      let defer = $q.defer()
      $http.get(url).then((response) => {
        $log.debug('getProcoreObservationItemDisMembers', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getProcoreObservationPotentialDisMembers (projectId) {
      let url = `${rootUrl}/api/integration/procore/project/${projectId}/observation/distribution/potential/member`
      let defer = $q.defer()
      $http.get(url).then((response) => {
        $log.debug('getProcoreObservationItemPotentialDisMembers', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    }
  }

  return api
})
