/* global angular, $ */

/**
 * Make an element droppable
 */
angular.module('smartvid').directive('makeDroppable', function () {
  return {
    restrict: 'A',
    link: function (scope, el) {
      let watchListener = scope.$watch('row.entity', (newVal, oldVal) => {
        removeHandlers()
        if (newVal.canUpdate) {
          addHandlers()
        }
      })

      scope.$on('$destroy', () => {
        removeHandlers()
        watchListener()
      })

      function addHandlers () {
        el.on('dragover', onDragOver)
        el.on('dragenter', onDragEnter)
        el.on('dragleave', onDragLeave)
        el.on('drop', onDrop)
      }

      function removeHandlers () {
        el.off('dragover', onDragOver)
        el.off('dragenter', onDragEnter)
        el.off('dragleave', onDragLeave)
        el.off('drop', onDrop)
      }

      function onDragOver (e) {
        let node = scope.row.entity
        if (!node.canUpdate) {
          return
        }
        if (e.preventDefault) {
          e.preventDefault()
        }
        if (e.stopPropagation) {
          e.stopPropagation()
        }
        if (el.parents('.drag').length > 0) {
          return false
        }
        if (el.hasClass('drop-placeholder') && isTreeSorted()) {
          return
        }
        this.classList.add('over')
        if (el.hasClass('drop-placeholder')) {
          $('.node-container').removeClass('over')
        }
        if (el.hasClass('drop-placeholder') && el.hasClass('last')) {
          var nn = el.parents('.node-container').first()
          $(nn).addClass('over')
        }
        if (el.hasClass('drop-placeholder') && el.hasClass('first')) {
          el.parents('.node-container').first().addClass('over-first')
        }
        e.originalEvent.dataTransfer.dropEffect = 'move'
      }

      function onDragEnter (e) {
        let node = scope.row.entity
        if (!node.canUpdate) {
          return
        }
        scope.grid.appScope.gridApi.treeBase.expandRow(scope.row)
      }

      function onDragLeave (e) {
        let node = scope.row.entity
        if (!node.canUpdate) {
          return
        }
        this.classList.remove('over')
        $(el).parents('.node-container').first().removeClass('over-first')
      }

      function onDrop (e) {
        let node = scope.row.entity
        if (!node.canUpdate) {
          return
        }
        if (e.preventDefault) {
          e.preventDefault()
        }
        if (e.stopPropagation) {
          e.stopPropagation()
        }
        $('.tree-container').removeClass('drag-active')
        $('.over').removeClass('over')
        let dragId = e.originalEvent.dataTransfer.getData('nodeId')
        if (node.id === dragId) {
          return
        }
        let dropNodeData = {
          nodeId: dragId
        }
        if (el.hasClass('drop-placeholder') && el.hasClass('first')) {
          dropNodeData.siblingId = node.id
          dropNodeData.isAfter = false
        } else if (el.hasClass('drop-placeholder') && el.hasClass('last')) {
          dropNodeData.siblingId = node.id
          dropNodeData.isAfter = true
        } else {
          dropNodeData.parentId = node.id
          dropNodeData.isAfter = false
        }
        drop(dropNodeData)
      }

      function drop (data) {
        scope.grid.appScope.treeModel.moveTag(data.nodeId, data.parentId, data.siblingId, data.isAfter).then(() => {
          if (data.parentId) {
            let parentNode = scope.grid.appScope.treeModel.getNode(data.parentId)
            let row = scope.grid.getRow(parentNode)
            scope.grid.appScope.gridApi.treeBase.expandRow(row)
          }
        })
      }

      function isTreeSorted () {
        return scope.grid.getColumnSorting().length !== 0
      }
    }
  }
})
