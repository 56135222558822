/* global angular */

angular.module('smartvid').controller('AddTagToAssetCtrl', function ($rootScope, $scope, $timeout, $element, modal, $interpolate, $filter, TAG_DEF_LIMIT, utils, currentUser, TagNodesCollection, TagTreeNodeModel, TagDefModel) {
  $scope.TAG_DEF_LIMIT = TAG_DEF_LIMIT
  const ENTER_KEY_CODE = 13
  $scope.parentTagDefTitle
  $scope.parentTagDefId
  $scope.time = {
    startTime: undefined,
    endTime: undefined
  }
  $scope.tagText = ''
  $scope.canSelectParentTag = true
  $scope.tagDefId

  let organization = currentUser.getOrganization($scope.project.organizationId)
  $scope.tagTreeWithOrgTags = new TagNodesCollection(organization, $scope.project, true)
  $scope.tagTreeWithOrgTags.init()

  $scope.tagTree = new TagNodesCollection(organization, $scope.project, false)

  $scope.tagTree.init()

  let pseudoId = '-- Not Real Id ---'

  let attrs = {
    childNodes: [],
    canUpdate: false,
    data: {
      text: 'Top',
      tagDefinition: {
        id: pseudoId,
        text: 'Top',
        projectId: $scope.project.id,
        organizationId: $scope.project.organizationId,
        projectLevel: true,
        isAllowManual: false,
        isAllowAsr: false,
        isAllowImrec: false,
        aliases: []
      },
      tagDefTreeNode: {
        id: pseudoId,
        nodeOrder: 1.0,
        tagDefinitionId: pseudoId,
        rootNode: true,
        hasChildren: false
      }
    }
  }

  $scope.tagTree.loadingPromise.then(() => {
    $scope.tagTree.insertIntoTree(new TagTreeNodeModel(attrs), undefined, false)
    $scope.tagTree.getTagDefs().upsert([new TagDefModel(attrs.data.tagDefinition)])
  })

  $timeout(() => {
    $element.find('input[class=taginput]').focus()
  }, 100)

  let setCanSelectParentTag = () => {
    $scope.canSelectParentTag = !$scope.tagTreeWithOrgTags.findTagByText($scope.tagText)
  }

  $scope.addTagHandler = (text) => {
    $scope.tagDefId = text.id === undefined ? undefined : text.id
    $scope.tagText = text.text === undefined ? text : text.text
    setCanSelectParentTag()
    utils.digest($scope)
    $scope.$broadcast('angucomplete-alt:changeInput', 'tagInstanceAccumulator', $scope.tagText)
  }

  $scope.tagInputChanged = (text) => {
    $scope.tagText = text
    let existingTag = $scope.tagTreeWithOrgTags.findTagByText($scope.tagText)
    if (existingTag) {
      $scope.tagDefId = existingTag.id
    } else {
      $scope.tagDefId = undefined
    }
    setCanSelectParentTag()
    utils.digest($scope)
    $scope.$broadcast('angucomplete-alt:changeInput', 'tagInstanceAccumulator', text.text)
  }

  $scope.keydown = (evt) => {
    if (evt.keyCode === ENTER_KEY_CODE) {
      evt.stopPropagation()
      if (!$scope.canSelectParentTag) {
        $scope.addTag()
      } else {
        $element.find('.tag-def-title-input').focus()
      }
    }
    if (utils.isArrowKey(evt)) {
      evt.stopPropagation()
    }
  }

  $scope.addTag = () => {
    if (!$scope.tagText) {
      return
    }
    $scope.time.startTime = $scope.time.startTime === undefined && $scope.time.endTime ? 0 : $scope.time.startTime
    if (!$scope.canSelectParentTag || $scope.parentTagDefId === pseudoId) {
      $scope.createTagCallback($scope.tagDefId, $scope.tagText, undefined, $scope.time.startTime, $scope.time.endTime)
    } else {
      $scope.createTagCallback($scope.tagDefId, $scope.tagText, $scope.parentTagDefId, $scope.time.startTime, $scope.time.endTime)
    }
    $scope.modal.close()
  }

  $scope.tagSelectedCallback = (id, title) => {
    if (id === pseudoId) {
      return
    }
    $scope.parentTagDefTitle = title
    $scope.parentTagDefId = id
  }
})
