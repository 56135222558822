/* global angular SRA_UI_ENABLED */

angular.module('smartvid').directive('projectGridControlbar', function (currentUser, $rootScope, templatePickService) {
  return {
    restrict: 'E',
    // TODO: PL: remove commented out code
    // replace: true,
    templateUrl: 'ProjectGrid/controlbar.html',
    link: function (scope) {
      const availableProjectViews = [templatePickService.getProjectCurrentView(), templatePickService.getProjectNextView()]
      scope.currentViewOption = availableProjectViews[0]
      scope.nextViewOption = availableProjectViews[1]
      scope.showAdvancedConfig = false
      scope.sraEnabled = SRA_UI_ENABLED

      scope.canCreateProject = function () {
        return currentUser.canCreateProject
      }

      scope.showSettingsOptions = ($event) => {
        scope.flyout.open('projectGridOptions', {
          parentElement: $event.currentTarget,
          direction: 'top',
          projects: scope.projects
        })
      }

      scope.runReport = () => {
        scope.modal.open('runReport', {selectedProjects: scope.projects})
      }

      scope.checkForAdvancedConfigSwitch = (event) => {
        if (event.altKey) {
          scope.showAdvancedConfig = !scope.showAdvancedConfig
        }
      }

      scope.switchView = () => {
        var v = availableProjectViews[0]
        availableProjectViews[0] = availableProjectViews[1]
        availableProjectViews[1] = v
        scope.nextViewOption = availableProjectViews[1]
        let template = {currentView: availableProjectViews[0], nextView: scope.nextViewOption}
        templatePickService.setProjectCurrentView(template.currentView)
        templatePickService.setProjectNextView(scope.nextViewOption)
        $rootScope.$broadcast('sv-project-view-changed', template)
      }
    }
  }
})
