<div class="sv-project-group-assets-home">
  <mat-tab-group
    *ngIf="!hasStandaloneVideoGrid()"
    class="sv-project-group-assets-tab-group"
    (selectedTabChange)="selectedTabChange($event)"
    [(selectedIndex)]="selectedTab"
  >
    <mat-tab *ngIf="hasInsightsTabView()" label="{{ 'components.projectGroupAssetsHome.insights' | translate }}">
      <ng-template matTabContent>
        <sv-project-group-assets-insights-dashboard></sv-project-group-assets-insights-dashboard>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'components.projectGroupAssetsHome.projects' | translate }}" class="sv-project-tab">
      <ng-template matTabContent>
        <svProjectGrid></svProjectGrid>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <svVideoGrid *ngIf="hasStandaloneVideoGrid()"></svVideoGrid>
</div>
