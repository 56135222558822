/* global angular, _ */

angular.module('smartvid').controller('AconexCtrl', function AconexCtrl (
    $timeout, $interpolate, $filter, $log, smartvidApi, dashboardDataHelper, IntegrationName, IntegrationType,
    IntegrationSettingsState, projectIntegrationUtils, moment, Notification, AconexSyncSettingsModel, AconexUserModel,
    AconexProjectModel, AconexRegionModel, currentUser
) {
  const that = this
  that.currentProject = dashboardDataHelper.getCurrentProject()
  that.connectionStatus
  that.projects = []
  that.regions = []
  that.selectedData = {
    selectedProjectFullPath: $interpolate($filter('i18next')('integrations.aconex.projectsPlaceholder'))(),
    selectedProject: null,
    selectedRegion: null
  }
  that.hasRateLimitError = false
  that.userInfo
  that.projectTreeOptions = {
    nodeChildren: 'folders',
    dirSelectable: true,
    allowDeselect: false,
    isLeaf: (node) => node.folders !== undefined && node.folders.length === 0
  }
  that.integrationSettingsState = new IntegrationSettingsState()
  that.prettyName = $filter('i18next')('integrations.aconex.title')
  that.providerNameLowercase = $filter('lowercase')(IntegrationName.ACONEX)

  that.connect = connect
  that.isIntegrationUiEnabled = isIntegrationUiEnabled
  that.isPartnerIntegrationEnabledForProject = isPartnerIntegrationEnabledForProject
  that.selectAndLoadProject = selectAndLoadProject
  that.canLinkFolder = canLinkFolder
  that.unlinkFolder = unlinkFolder
  that.linkFolder = linkFolder
  that.disconnect = disconnect
  that.reloadProjects = reloadProjects
  that.dateRanges = [
    {
      name: 'None',
      value: 'NONE'
    },
    {
      name: '7 Days',
      value: '7_DAYS'
    },
    {
      name: '30 Days',
      value: '30_DAYS'
    },
    {
      name: '60 Days',
      value: '60_DAYS'
    },
    {
      name: 'All',
      value: 'ALL'
    }
  ]
  that.selectedData.historicalDataRange = that.dateRanges[0]

  that.isReadOnly =
      _.bind(
          projectIntegrationUtils.isReadOnly,
          undefined,
          that.integrationSettingsState)

  that.getNextSyncDate =
      _.bind(
          projectIntegrationUtils.getNextSyncDate,
          undefined,
          that.integrationSettingsState)

  that.getLastSyncDate =
      _.bind(
          projectIntegrationUtils.getLastSyncDate,
          undefined,
          that.integrationSettingsState)

  that.authenticateAndEnableIntegration =
      _.bind(
          projectIntegrationUtils.authenticateWithPartnerAndEnableIntegration,
          undefined,
          that.integrationSettingsState,
          that.currentProject.id,
          IntegrationName.ACONEX,
          IntegrationType.ACONEX_SYNC,
          _ /* region and user id */)

  init()

  function customErrorHandler (data) {
    Notification.error('Oops... Something went wrong!')
    $log.error('Aconex error ', data)
  }

  function init () {
    resetDefaults()
    that.integrationSettingsState.setCreatePartnerSyncSettingsFunc((settingsData) => {
      return new AconexSyncSettingsModel(settingsData)
    })

    $timeout(() => {
      that.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
      getRegions()
      projectIntegrationUtils.checkIntegrationStatusAndLoadData(
          that.integrationSettingsState, that.currentProject.id, IntegrationName.ACONEX, IntegrationType.ACONEX_SYNC, false)
    }, 10)

    that.integrationSettingsState.setLoadPageData((integrationSettingsState) => {
      let partnerUserId = projectIntegrationUtils.getPartnerUserId(integrationSettingsState)
      if (partnerUserId) {
        smartvidApi.getAconexUser(that.currentProject.id, customErrorHandler).then((data) => {
          that.userInfo = new AconexUserModel(data)
          adjustConnectionStatus()
          that.selectedData.selectedRegion = _.find(that.regions, (r) => {
            return r.region === that.userInfo.region.region
          })
          // loadFullSelectedFolderPath()
          if (!that.integrationSettingsState.isEnabled) {
            loadProjects()
          }
        })

        that.selectedData.historicalDataRange = _.find(that.dateRanges, (dr) => {
          return dr.value === integrationSettingsState.syncSettings.historicalDataSyncDateRange
        }) || that.dateRanges[0] // Set to all if none

      } else {
        // TODO that.loadData()
      }
    })
  }

  function getRegions () {
    smartvidApi.getAconexRegions(that.currentProject.id, customErrorHandler).then((data) => {
      that.regions = data
      if (that.integrationSettingsState.partnerIntegrationSettings &&
          that.integrationSettingsState.partnerIntegrationSettings.settingsData &&
          that.integrationSettingsState.partnerIntegrationSettings.settingsData.region) {
        that.selectedData.selectedRegion = _.find(that.regions, (r) => {
          return r.region === that.integrationSettingsState.partnerIntegrationSettings.settingsData.region
        })
      }
    })
  }

  function connect () {
    if (that.hasRateLimitError) {
      return
    }
    if (projectIntegrationUtils.isAutoConnect(that.integrationSettingsState)) {
      setIntegrationSettingsState()
      projectIntegrationUtils.enableIntegration(
          that.integrationSettingsState, that.currentProject.id, IntegrationName.ACONEX, IntegrationType.ACONEX_SYNC)
    } else {
      that.authenticateAndEnableIntegration(encodeURI(currentUser.id + ':::' + that.selectedData.selectedRegion.region))
    }
  }

  function resetDefaults (hasError) {
    that.integrationSettingsState.clear()
    resetProjectsAndRegions()
    that.integrationSettingsState.isEnabled = false
  }

  function resetProjectsAndRegions (hasError) {
    that.projects.splice(0, that.projects.length)
    that.selectedData.selectedProjectFullPath = $interpolate($filter('i18next')('integrations.aconex.projectsPlaceholder'))()
    that.selectedData.selectedProject = undefined
    that.selectedData.selectedRegion = undefined
    that.selectedData.historicalDataRange = that.dateRanges[0]
  }

  function adjustConnectionStatus () {
    if (that.integrationSettingsState.isPartnerIntegrationEnabledByCurrentUser && !that.hasRateLimitError) {
      let userName = that.userInfo ? that.userInfo.firstName + ' ' + that.userInfo.lastName : '?'
      that.connectionStatus = $interpolate($filter('i18next')('integrations.aconex.connection.connectedAsUser'))({
        userName: userName
      })
    } else if (that.integrationSettingsState.isPartnerIntegrationEnabledByOtherUser) {
      that.connectionStatus = $interpolate($filter('i18next')('integrations.aconex.connection.connectedAsSmartvidUser'))()
    } else if (that.hasRateLimitError) {
      // TODO(rr) Rate limit error handling
    }
  }

  function loadProjects () {
    setLoading(true)
    smartvidApi.getAconexProjects(that.currentProject.id, customErrorHandler).then((data) => {
      that.projects = _.map(data || [], (p) => new AconexProjectModel(p))

      if (that.integrationSettingsState.partnerIntegrationSettings &&
          that.integrationSettingsState.partnerIntegrationSettings.settingsData &&
          that.integrationSettingsState.partnerIntegrationSettings.settingsData.aconexProjectId) {
        that.selectedData.selectedProject = _.find(that.projects, (p) => {
          return p.id === that.integrationSettingsState.partnerIntegrationSettings.settingsData.aconexProjectId
        })

        if (that.selectedData.selectedProject) {
          that.selectedData.selectedProjectFullPath = that.selectedData.selectedProject.name
        }

      }
      setLoading(false)
    }, () => {
      setLoading(false)
    })
  }

  function selectAndLoadProject (project) {
    that.selectedData.selectedProject = project
    that.selectedData.selectedProjectFullPath = project.name
    saveSyncSettings()
  }

  function setLoading (value) {
    $timeout(() => { that.integrationSettingsState.isLoadingData = value })
  }

  function isIntegrationUiEnabled () {
    return true // TODO add logic here
  }

  function reloadProjects () {
    if (that.integrationSettingsState.isLoadingData) {
      resetProjectsAndRegions()
      loadProjects()
    } else {
      saveSyncSettings(() => {
        resetProjectsAndRegions()
        loadProjects()
      })
    }
  }

  function isPartnerIntegrationEnabledForProject () {
    return projectIntegrationUtils.isPartnerIntegrationEnabledForProject(that.integrationSettingsState)
  }

  function setIntegrationSettingsState () {
    if (that.selectedData.selectedProject && that.selectedData.selectedProject.id) {
      that.integrationSettingsState.partnerIntegrationSettings.settingsData.aconexProjectId = that.selectedData.selectedProject.id
    }

    if (that.selectedData.selectedRegion) {
      that.integrationSettingsState.partnerIntegrationSettings.settingsData.region = that.selectedData.selectedRegion.region
    }

    setHistoricalDataSyncThresholdDate(that.integrationSettingsState.partnerIntegrationSettings.settingsData, that.selectedData.historicalDataRange)
  }

  function isDataComplete () {
    return (!!that.selectedData.selectedProject || !!that.integrationSettingsState.partnerIntegrationSettings.settingsData.aconexProjectId) &&
        (!!that.selectedData.selectedRegion || !!that.integrationSettingsState.partnerIntegrationSettings.settingsData.region)
  }

  function canLinkFolder () {
    return that.integrationSettingsState.isPartnerIntegrationEnabledByCurrentUser && isDataComplete()
  }

  function linkFolder () {
    that.integrationSettingsState.partnerIntegrationSettings.isEnabled = true
    that.integrationSettingsState.syncOnlyAfterDate = Date.now()
    saveSyncSettings()
  }

  function unlinkFolder () {
    that.integrationSettingsState.partnerIntegrationSettings.isEnabled = false
    saveSyncSettings()
  }

  function saveSyncSettings (afterSaveFunc) {
    setIntegrationSettingsState()
    smartvidApi.saveIntegrationSettings(IntegrationType.ACONEX_SYNC, that.integrationSettingsState.partnerIntegrationSettings).then(() => {
      if (afterSaveFunc) {
        afterSaveFunc()
      }
    }, () => {
      if (that.integrationSettingsState) {
        that.integrationSettingsState.partnerIntegrationSettings.isEnabled = false
      }
    })
  }

  function disconnect () {
    if (that.isPartnerIntegrationEnabledForProject() && !that.hasRateLimitError) {
      disableIntegration()
    }
  }

  function disableIntegration () {
    setLoading(true)
    smartvidApi.deleteIntegrationSettings(IntegrationType.ACONEX_SYNC, that.currentProject.id).then(() => {
      that.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
      resetDefaults()
      projectIntegrationUtils.checkIntegrationStatusAndLoadData(
          that.integrationSettingsState, that.currentProject.id, IntegrationName.ACONEX, IntegrationType.ACONEX_SYNC, false)
    }, () => {
      resetDefaults()
      setLoading(false)
    })
  }

  function setHistoricalDataSyncThresholdDate (settingsData, historicalDataRange) {
    if (!historicalDataRange || historicalDataRange.value === 'ALL') {
      settingsData.historicalDataSyncThresholdDate = null
      if (historicalDataRange) {
        settingsData.historicalDataSyncDateRange = historicalDataRange.value
      }
    } else {
      switch (historicalDataRange.value) {
        case '7_DAYS':
          settingsData.historicalDataSyncThresholdDate = moment().subtract(7, 'days').startOf('day').toDate()
          settingsData.historicalDataSyncDateRange = historicalDataRange.value
          break
        case '30_DAYS':
          settingsData.historicalDataSyncThresholdDate = moment().subtract(30, 'days').startOf('day').toDate()
          settingsData.historicalDataSyncDateRange = historicalDataRange.value
          break
        case '60_DAYS':
          settingsData.historicalDataSyncThresholdDate = moment().subtract(60, 'days').startOf('day').toDate()
          settingsData.historicalDataSyncDateRange = historicalDataRange.value
          break
        case 'NONE':
          settingsData.historicalDataSyncThresholdDate = moment().toDate()
          settingsData.historicalDataSyncDateRange = historicalDataRange.value
          break
      }
    }
  }

})
