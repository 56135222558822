/* global angular */
/**
 * Created by ryanrubbico on 12/5/17.
 */
angular.module('smartvid').directive('projectIntegrationsStorageService', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'integrations/project-integrations-storage-service.html',
    link: function (scope) {}
  }
})
