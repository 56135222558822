/* global angular */

angular.module('smartvid').filter('videoplaybacktime', function () {
  return (timeInMilliSeconds) => {
    // If valid number is not passed in they want to simply return 0.
    if (!isFinite(timeInMilliSeconds)) {
      return '00:00'
    }

    var msNum = parseInt(timeInMilliSeconds, 10) // don't forget the second param
    var secNum = Math.floor(msNum / 1000) // don't forget the second param
    var hours = Math.floor(secNum / 3600)
    var minutes = Math.floor((secNum - (hours * 3600)) / 60)
    var seconds = secNum - (hours * 3600) - (minutes * 60)
    var time

    if (hours > 0 && hours < 10) {
      hours = '0' + hours
    }

    if (minutes < 10) {
      minutes = '0' + minutes
    }

    if (seconds < 10) {
      seconds = '0' + seconds
    }

    if (hours !== 0) {
      time = hours + ':' + minutes + ':' + seconds
    } else {
      time = minutes + ':' + seconds
    }
    return time
  }
})

angular.module('smartvid').filter('videoplaybacktimetoseconds', function () {
  // See format above
  return (playbackDisplay) => {
    var arr = playbackDisplay.split(':').reverse()
    var totalNumberOfSeconds = 0
    let num
    for (let i = 0, length = arr.length; i < length; i++) {
      num = parseInt(arr[i], 10)

      // If invalid input is inputted, then simply return 0
      if (num < 0) {
        return 0
      }

      if (i === 0) {
        totalNumberOfSeconds = num
      } else {
        totalNumberOfSeconds += parseInt(arr[i], 10) * (i * 60)
      }
    }

    return totalNumberOfSeconds * 1000
  }
})
