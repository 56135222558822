import {
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Inject,
  Input,
  IterableDiffers,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { FlatTreeControl } from '@angular/cdk/tree'
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree'
import { StateService, TransitionService } from '@uirouter/core'
import { Select, Store } from '@ngxs/store'
import { ObservationCommonState } from 'modules/state'
import { DashboardDataHelperService } from 'modules/core/services/dashboard-data-helper.service'
import { CURRENT_USER, CurrentUser } from 'shared/smartvid.types'
import { Observable, Subscription } from 'rxjs'
import { ProjectListPipe } from 'angular2/modules/admin/components/navigation-panel/navigation-panel.component'
import { ProjectItemMenuComponent } from 'angular2/modules/admin/components/project-tree/menu-item/project-item-menu.component'

export enum ProjectTreeFeatureFilter {
  OBSERVATION,
  ASSET,
}

enum TreeNodeType {
  ORGANIZATION,
  PROJECT_GROUP,
  PROJECT,
}

interface ProjectTreeNode {
  name: string
  type: TreeNodeType
  id: string
  level: number
  organizationId: string
  projectGroupId: string
  favorite: boolean
  primary: boolean
  children: ProjectTreeNode[]
}

@Component({
  selector: 'sv-project-tree',
  templateUrl: 'project-tree.component.html',
  styleUrls: ['project-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectTreeComponent implements OnInit, OnDestroy, AfterViewInit, DoCheck {
  projectsCollection: any
  searchValue: string
  differ: any
  // Allows only projects with access to a certain feature to be shown
  @Input() featureFilter: ProjectTreeFeatureFilter
  @Input() set projects(projects: any) {
    this.projectsCollection = projects
    if (!this.projectsCollection) {
      return
    }
    let params = this.getStateParams()
    if (this.isSelectedNodeOnCurrentOrgOrGroupLevel() || !this.projectCollectionChanged()) {
      this.expandProjectTreeNodes(params)
      return
    }
    this.initProjectTree()
    this.expandProjectTreeNodes(params)
  }

  get projects() {
    return this.projectsCollection
  }

  private expandProjectTreeNodes(params) {
    if (!this.treeControl.dataNodes) return
    this.treeControl.dataNodes.forEach(node =>
      this.expandNode(node, params.organizationId, params.projectGroupId, params.projectId)
    )
  }
  @Output() favorited: EventEmitter<void> = new EventEmitter<void>()
  TreeNodeType: typeof TreeNodeType = TreeNodeType
  treeControl: FlatTreeControl<ProjectTreeNode>
  flattener: MatTreeFlattener<ProjectTreeNode, ProjectTreeNode>
  dataSource: MatTreeFlatDataSource<ProjectTreeNode, ProjectTreeNode>
  selectedNode: ProjectTreeNode = null
  transitionHooks: Function[] = []
  navigation: {
    organizationId: undefined
    projectGroupId: undefined
    projectId: undefined
  }
  navigationSubscription: Subscription
  @ViewChild(ProjectItemMenuComponent, { static: false }) projectItemMenu: ProjectItemMenuComponent

  @Select(ObservationCommonState.navigation) navigation$: Observable<{
    organizationId: undefined
    projectGroupId: undefined
    projectId: undefined
  }>

  constructor(
    private store: Store,
    public stateService: StateService,
    public transitionService: TransitionService,
    @Inject('contentSharingContext') public contentSharingContext: any,
    @Inject('searchResultService') public searchResultService: any,
    @Inject('projectFavoriteService') public projectFavoriteService: any,
    @Inject(CURRENT_USER) private currentUser: CurrentUser,
    public dashboardDataHelper: DashboardDataHelperService,
    differs: IterableDiffers
  ) {
    this.differ = differs.find([]).create(null)
    this.treeControl = new FlatTreeControl<ProjectTreeNode>(this.getLevel, this.isExpandable)
    this.flattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren)
  }

  public resetSelectedNode() {
    this.selectedNode = undefined
  }

  public projectCollectionChanged(): boolean {
    if (this.differ === null) return false
    const change = this.differ.diff(this.projectsCollection.models)
    return change !== null
  }

  public getStateParams(): { projectId: ''; projectGroupId: ''; organizationId: '' } {
    let params: any = {}
    let project
    const projectId = this.stateService.params.projectId
    if (projectId) {
      project = this.projectsCollection.findById(projectId)
    }
    let projectGroupId = this.stateService.params.projectGroupId || (project && project.projectGroupId)
    let organizationId = this.stateService.params.organizationId || (project && project.organizationId)
    params.projectId = projectId
    params.projectGroupId = projectGroupId
    params.organizationId = organizationId
    return params
  }

  ngOnInit() {
    this.initProjectTree()
    const toState =
      this.featureFilter === ProjectTreeFeatureFilter.OBSERVATION
        ? 'dashboard.observations.**'
        : 'dashboard.projects.**'
    this.transitionHooks.push(
      this.transitionService.onSuccess({ to: toState }, transition => {
        let projectId = transition.params().projectId
        let projectGroupId = transition.params().projectGroupId
        let organizationId = transition.params().organizationId
        if (this.stateService.includes('dashboard.projects.projectId.*')) {
          let project = this.dashboardDataHelper.getProjectByProjectId(projectId)

          if (project) {
            organizationId = project.organizationId
            projectGroupId = project.projectGroupId
            let isPresentInDataNodes = !!this.treeControl.dataNodes.find(node => node.id === project.id)
            if (!isPresentInDataNodes) {
              this.initProjectTree()
            }
          }
        }

        if (!this.projectsCollection) {
          return
        }
        this.projectsCollection.projectPromise.then(() => {
          this.treeControl.dataNodes.forEach(node => this.expandNode(node, organizationId, projectGroupId, projectId))
        })
      })
    )
    this.navigationSubscription = this.navigation$.subscribe(nav => {
      if (
        this.navigation &&
        this.navigation.organizationId === nav.organizationId &&
        this.navigation.projectGroupId === nav.projectGroupId &&
        this.navigation.projectId === nav.projectId
      ) {
        return
      }
      const anyParameter = nav && (nav.organizationId || nav.projectGroupId || nav.projectId)
      if (!anyParameter) {
        this.selectedNode = null
        this.treeControl.collapseAll()
      }
      this.navigation = nav
    })
  }

  ngOnDestroy() {
    this.transitionHooks.forEach(hook => hook())
    this.navigationSubscription.unsubscribe()
  }

  private expandNode(node: ProjectTreeNode, organizationId: string, projectGroupId: string, projectId: string) {
    if (!node) {
      return
    }

    if (node.type === TreeNodeType.ORGANIZATION) {
      if (node.id !== organizationId) {
        return
      }
      this.treeControl.expand(node)
      this.selectedNode = node
    }
    if (node.type === TreeNodeType.PROJECT_GROUP) {
      if (node.id !== projectGroupId) {
        return
      }
      this.treeControl.expand(node)
      this.selectedNode = node
    }
    if (node.type === TreeNodeType.PROJECT && node.id === projectId) {
      this.treeControl.expand(node)
      this.selectedNode = node
      return
    }

    if (node.children) {
      node.children.forEach(child => this.expandNode(child, organizationId, projectGroupId, projectId))
    }
  }
  isSelectedNodeOnCurrentOrgOrGroupLevel() {
    if (!this.selectedNode) {
      return false
    }
    return (
      (this.isOrganizationState() && this.stateService.params['organizationId'] === this.selectedNode.id) ||
      (this.isProjectGroupState() && this.stateService.params['projectGroupId'] === this.selectedNode.id)
    )
  }

  private isProjectGroupState() {
    return (
      this.stateService.current.name === 'dashboard.projects.orgId.projectGroupId' ||
      this.stateService.current.name === 'dashboard.observations.orgId.projectGroupId'
    )
  }

  private isOrganizationState() {
    return (
      this.stateService.current.name === 'dashboard.projects.orgId' ||
      this.stateService.current.name === 'dashboard.observations.orgId'
    )
  }

  getProjectsByOrgMap() {
    let projectsByOrg = new Map()
    for (let project of (this.projectsCollection && this.projectsCollection.models) || []) {
      if (this.featureFilter !== undefined && this.featureFilter === ProjectTreeFeatureFilter.OBSERVATION) {
        if (
          !project.canReadObservations ||
          !this.currentUser.isObservationEnabledForOrganization(project.organizationId)
        ) {
          continue
        }
      } else if (this.featureFilter !== undefined && this.featureFilter === ProjectTreeFeatureFilter.ASSET) {
        if (!project.canListAllAssets) {
          continue
        }
      }

      if (!projectsByOrg.has(project.organizationId)) {
        projectsByOrg.set(project.organizationId, [])
      }
      projectsByOrg.get(project.organizationId).push(project)
    }
    return projectsByOrg
  }

  private initProjectTree() {
    let projectsByOrg = this.getProjectsByOrgMap()
    let data: ProjectTreeNode[] = this.fillNodesWithData(projectsByOrg, this.searchValue)
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.flattener)
    this.dataSource.data = data
  }

  private fillNodesWithData(projectsByOrg, filterText): ProjectTreeNode[] {
    let data: ProjectTreeNode[] = []
    let sortByIsPrimaryAndName = (lhs, rhs): number => {
      if (!lhs.primary && rhs.primary) {
        return 1
      }
      if (lhs.primary && !rhs.primary) {
        return -1
      }
      return lhs.name.localeCompare(rhs.name)
    }
    for (let orgWithProjects of Array.from(projectsByOrg.entries())) {
      let organizationId = orgWithProjects[0]
      let projects = orgWithProjects[1]
      let projectsByProjectGroup = new Map()
      let projectsWithoutProjectGroup = []

      if (filterText) {
        projects = projects.filter(p => p.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1)
      }
      if (projects.length === 0) {
        continue
      }
      const organizationByOrgId = this.dashboardDataHelper.getOrganizationByOrgId(organizationId)
      let organizationTreeNode = {
        id: organizationId,
        type: TreeNodeType.ORGANIZATION,
        name: projects[0].organizationName,
        organizationId: null,
        projectGroupId: null,
        level: null,
        favorite: false,
        primary: organizationByOrgId && organizationByOrgId.isPrimary,
        children: [],
      }
      data.push(organizationTreeNode)

      projects = new ProjectListPipe().transform(projects)
      for (let project of projects) {
        if (project.projectGroupId) {
          if (!projectsByProjectGroup.has(project.projectGroupId)) {
            projectsByProjectGroup.set(project.projectGroupId, [])
          }
          projectsByProjectGroup.get(project.projectGroupId).push(project)
        } else {
          projectsWithoutProjectGroup.push({
            id: project.id,
            name: project.name,
            type: TreeNodeType.PROJECT,
            organizationId: organizationId,
            projectGroupId: 'default',
            level: null,
            favorite: project.favorite,
            primary: false,
            children: [],
          })
        }
      }
      for (let projectGroupsWithProjects of Array.from(projectsByProjectGroup.entries())) {
        let projectGroupId = projectGroupsWithProjects[0]
        let groupsWithProjects = projectGroupsWithProjects[1]
        const projectWithGroup = groupsWithProjects.find(p => p.projectGroupId === projectGroupId)
        const projectGroupName = projectWithGroup ? projectWithGroup.projectGroupName : ''
        let projectGroupTreeNode = {
          id: projectGroupId,
          type: TreeNodeType.PROJECT_GROUP,
          name: projectGroupName,
          organizationId: organizationId,
          level: null,
          favorite: false,
          primary: false,
          children: [],
        }

        organizationTreeNode.children.push(projectGroupTreeNode)
        for (let project of groupsWithProjects) {
          projectGroupTreeNode.children.push({
            id: project.id,
            name: project.name,
            type: TreeNodeType.PROJECT,
            organizationId: organizationId,
            projectGroupId: projectGroupId,
            level: null,
            favorite: project.favorite,
            primary: false,
            children: [],
          })
        }
      }
      organizationTreeNode.children.sort(sortByIsPrimaryAndName)
      for (let project of projectsWithoutProjectGroup) {
        organizationTreeNode.children.push(project)
      }
    }
    data.sort(sortByIsPrimaryAndName)
    return data
  }

  transformer = (node: ProjectTreeNode, level: number) => {
    node.level = level
    return node
  }

  getLevel = (node: ProjectTreeNode) => node.level

  isExpandable = (node: ProjectTreeNode) => node.children.length > 0

  getChildren = (node: ProjectTreeNode): ProjectTreeNode[] => node.children

  hasChild = (_: number, node: ProjectTreeNode) => !!node.children && node.children.length > 0

  isObsProjectLevel = (_: number, node: ProjectTreeNode) =>
    this.featureFilter === ProjectTreeFeatureFilter.OBSERVATION && node.type === TreeNodeType.PROJECT

  isAssetProjectLevel = (_: number, node: ProjectTreeNode) =>
    this.featureFilter === ProjectTreeFeatureFilter.ASSET && node.type === TreeNodeType.PROJECT

  getProjectById(nodeId) {
    return this.projectsCollection && this.projectsCollection.models.find(p => p.id === nodeId)
  }

  isCrossProjectAssetView() {
    return this.searchResultService.isInGlobalSearchContext() || this.contentSharingContext.isGlobalSharingContext()
  }

  goToItems() {
    if (this.featureFilter !== undefined && this.featureFilter === ProjectTreeFeatureFilter.OBSERVATION) {
      if (this.selectedNode.type === TreeNodeType.ORGANIZATION) {
        this.stateService.go('dashboard.observations.orgId', {
          organizationId: this.selectedNode.id,
        })
      }
      if (this.selectedNode.type === TreeNodeType.PROJECT_GROUP) {
        this.stateService.go('dashboard.observations.orgId.projectGroupId', {
          organizationId: this.selectedNode.organizationId,
          projectGroupId: this.selectedNode.id,
        })
      }
      if (this.selectedNode.type === TreeNodeType.PROJECT) {
        this.stateService.go('dashboard.observations.orgId.projectGroupId.projectId', {
          organizationId: this.selectedNode.organizationId,
          projectGroupId: this.selectedNode.projectGroupId,
          projectId: this.selectedNode.id,
        })
      }
    } else if (this.featureFilter !== undefined && this.featureFilter === ProjectTreeFeatureFilter.ASSET) {
      if (this.selectedNode.type === TreeNodeType.ORGANIZATION) {
        this.stateService.go('dashboard.projects.orgId', {
          organizationId: this.selectedNode.id,
        })
      }
      if (this.selectedNode.type === TreeNodeType.PROJECT_GROUP) {
        this.stateService.go('dashboard.projects.orgId.projectGroupId', {
          organizationId: this.selectedNode.organizationId,
          projectGroupId: this.selectedNode.id,
        })
      }
    }
  }

  isActiveProject(project) {
    return project && project.id === this.stateService.params['projectId']
  }

  rerunTreeNodeSorting() {
    this.projectsCollection.projectPromise.then(() => {
      this.initProjectTree()
      const stateParams = this.getStateParams()
      this.treeControl.dataNodes.forEach(node =>
        this.expandNode(node, stateParams.organizationId, stateParams.projectGroupId, stateParams.projectId)
      )
    })
  }
  onFavorite() {
    this.favorited.emit()
    setTimeout(() => {
      this.rerunTreeNodeSorting()
    }, 50)
  }

  filterNodes(text) {
    this.searchValue = text
    this.initProjectTree()
    if (text) {
      this.treeControl.dataNodes.forEach(node => {
        this.treeControl.expand(node)
      })
    } else {
      const stateParams = this.getStateParams()
      this.treeControl.dataNodes.forEach(node =>
        this.expandNode(node, stateParams.organizationId, stateParams.projectGroupId, stateParams.projectId)
      )
    }
  }

  isGivenProjectInactive(nodeId) {
    const projectById = this.getProjectById(nodeId)
    return projectById && projectById.isInactive
  }

  ngDoCheck(): void {
    if (this.projectsCollection) {
      if (this.projectCollectionChanged()) {
        this.rerunTreeNodeSorting()
      }
    }
  }

  ngAfterViewInit(): void {
    let params = this.getStateParams()
    if (this.isSelectedNodeOnCurrentOrgOrGroupLevel() || !this.projectCollectionChanged()) {
      this.expandProjectTreeNodes(params)
      return
    }
    this.initProjectTree()
    this.expandProjectTreeNodes(params)
  }
}
