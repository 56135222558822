/* global angular */

import { AddProjectMemberDialogStrategy, AddUserDialogComponent } from 'modules/admin/components/add-user-dialog/add-user-dialog.component'

angular.module('smartvid').directive('projectMembersControlbar', function ($state, dialogUtils, currentUser, modal) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'ProjectMemberGrid/project-members-controlbar.html',
    link: function (scope) {
      scope.addProjectMember = () => {
        dialogUtils.open(AddUserDialogComponent, {
          data: {
            organizationId: scope.currentProject.organizationId,
            projectId: scope.currentProject.id,
            strategy: new AddProjectMemberDialogStrategy()
          }
        })
      }

      scope.showSettingsOptions = ($event) => {
        scope.flyout.open('projectMembersGridOptions', {
          parentElement: $event.currentTarget,
          direction: 'top',
          projectMembers: scope.projectMembers,
          currentProject: scope.currentProject
        })
      }
    }
  }
})
