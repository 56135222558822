/* global angular _ */

angular.module('smartvid').factory('IntegrationSettingsState', function (BaseModel) {
  class IntegrationSettingsState extends BaseModel {
    constructor (attrs) {
      let defaults = {
        waitingForIntegrationStatus: false,
        waitingForPartnerIntegrationStatus: false,
        currentUserAuthenticatedWithPartner: undefined,
        isLoadingData: false,
        waitingForOAuthIntegrationStatus: false,
        partnerIntegrationSettings: undefined,
        isPartnerIntegrationEnabledByCurrentUser: false,
        isPartnerIntegrationEnabledByOtherUser: false,
        partnerIntegrationNotEnabled: false,
        hasRateLimitError: false,
        syncSettings: undefined
      }

      super(_.defaults(attrs || {}, defaults))

      this.createPartnerSyncSettingsFunc = undefined
      this.loadPageDataFunc = undefined
    }

    clear () {
      this.waitingForIntegrationStatus = false
      this.waitingForPartnerIntegrationStatus = false
      this.currentUserAuthenticatedWithPartner = undefined
      this.isLoadingData = false
      this.waitingForOAuthIntegrationStatus = false
      this.partnerIntegrationSettings = undefined
      this.isPartnerIntegrationEnabledByCurrentUser = false
      this.isPartnerIntegrationEnabledByOtherUser = false
      this.partnerIntegrationNotEnabled = false
      this.hasRateLimitError = false
      this.syncSettings = undefined
    }

    setCreatePartnerSyncSettingsFunc (createPartnerSyncSettingsFunc) {
      this.createPartnerSyncSettingsFunc = createPartnerSyncSettingsFunc
    }

    setLoadPageData (loadPageDataFunc) {
      this.loadPageDataFunc = loadPageDataFunc
    }

    createPartnerSyncSettings (settingsData) {
      if (!this.createPartnerSyncSettingsFunc) {
        throw new Error('setCreatePartnerSyncSettingsFunc must be called to configure partner-specific create function')
      }
      return this.createPartnerSyncSettingsFunc(settingsData)
    }

    loadPageData () {
      if (!this.createPartnerSyncSettingsFunc) {
        throw new Error('loadPageDataFunc must be called to configure partner-specific load function')
      }
      return this.loadPageDataFunc(this)
    }

  }

  return IntegrationSettingsState
})

