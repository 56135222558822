/* global angular _ $ */

angular.module('smartvid').directive('projectIntegrationsBim360Field',
  function (utils, smartvidApi, Notification, currentUser,
            moment, $rootScope, $timeout, $window, $interval, $filter, $interpolate, $stateParams, $state,
            IntegrationSettingsModel, Bim360FieldProjectModel, Bim360FieldProjectCollection, Bim360FieldUserModel, modal, dashboardDataHelper) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'integrations/project-integrations-bim360-field.html',
      link: function (scope) {
        scope.project = dashboardDataHelper.getCurrentProject()
        if (!scope.project.canUpdate) {
          $state.go('unauthorized')
          return
        }

        const BIM360_FIELD = 'BIM360_WEB_TOKEN'
        const BIM360_FIELD_PHOTO_SYNC = 'BIM360_FIELD_PHOTO_SYNC'
        const UNAUTHORIZED = 'UNAUTHORIZED'
        const BIM360_FIELD_USER_IS_A_SUB = 'bim360.field.user.is.sub'
        const NOT_SELECTED_ID = -1
        var LAST_SYNC_NEVER_LABEL
        var NEXT_SYNC_NA
        var SYNC_PENDING_LABEL
        var SYNC_IN_PROGRESS_LABEL
        scope.currentUserAuthenticatedWithBim360Field = undefined
        scope.isBim360FieldIntegrationEnabledByCurrentUser = false
        scope.isBim360FieldIntegrationEnabledByOtherUser = false
        scope.bim360FieldIntegrationNotEnabled = true
        scope.waitingForBim360FieldIntegrationStatus = true
        scope.projectLinkToggleInTransit = false
        scope.connectedBim360FieldUser = undefined
        scope.isLoadingData = true
        scope.isWaitingForRunOnce = false
        scope.connectionStatus = ''
        // RE: http://stackoverflow.com/questions/19408883/angularjs-select-not-2-way-binding-to-mode
        scope.selectedData = {}
        scope.selectedData.bim360FieldSelectedProject = {}
        scope.lastSyncDate = ''
        scope.bim360FieldUserInactiveError = false
        scope.rateLimitResetsAt = undefined
        scope.bim360FieldIntegrationSettings = new IntegrationSettingsModel()
        scope.bim360FieldIntegrationSettings.integrationType = BIM360_FIELD_PHOTO_SYNC
        scope.selectedData.downloadLocations = false
        let smartvidProjectId = $stateParams.projectId

        function getProjectsPlaceholder (hasError) {
          return new Bim360FieldProjectModel({
            project_id: NOT_SELECTED_ID,
            name: (hasError ? $filter('i18next')('integrations.bim360.field.unavailablePlaceholder') : $filter('i18next')('integrations.bim360.field.projectsPlaceholder'))
          })
        }

        let resetDefaults = (hasError) => {
          scope.selectedData.bim360FieldSelectedProject = getProjectsPlaceholder(hasError)
          scope.bim360FieldProjects = new Bim360FieldProjectCollection([scope.selectedData.bim360FieldSelectedProject])
          scope.selectedData.downloadLocations = false
        }

        let hasErrorMessageWithLabel = (messages, label) => {
          return messages ? _.find(messages, (m) => {
            return m.label === label
          }) !== undefined : false
        }

        let customErrorHandler = (response) => {
          if (response && response.errorCode === UNAUTHORIZED && hasErrorMessageWithLabel(response.errorMessages, BIM360_FIELD_USER_IS_A_SUB)) {
            scope.bim360FieldUserInactiveError = true

            resetDefaults(scope.bim360FieldUserInactiveError)
            adjustConnectionStatus()

            return true
          }

          Notification.error($filter('i18next')('integrations.bim360.field.errors.failedToLoadSyncSettings'))
          return true
        }

        let setNoProjectsInBim360FieldState = () => {
          scope.selectedData.bim360FieldSelectedProject = new Bim360FieldProjectModel({
            project_id: NOT_SELECTED_ID,
            name: $filter('i18next')('integrations.bim360.field.noProjectsPlaceholder')
          })
          scope.procoreProjects = new Bim360FieldProjectCollection([scope.selectedData.bim360FieldSelectedProject])
        }

        let checkBim360FieldIntegrationStatusAndLoadData = (isAfterAuth) => {
          smartvidApi.getOAuthUserDataForProviderForCurrentUser(BIM360_FIELD).then((data) => {
            scope.waitingForBim360FieldIntegrationStatus = false
            // TODO: handle multiple identities
            scope.currentUserAuthenticatedWithBim360Field = data.length > 0 ? data[0] : undefined
            loadPageData(isAfterAuth)
          }, () => {
            scope.isLoadingData = false
            scope.waitingForBim360FieldIntegrationStatus = false
            Notification.error($filter('i18next')('integrations.bim360.field.failedToCheckIntegrationStatus'))
          })
        }

        let adjustConnectionStatus = () => {
          if (scope.isBim360FieldIntegrationEnabledByCurrentUser && !scope.bim360FieldUserInactiveError) {
            let bim360FieldUserName = scope.connectedBim360FieldUser ? (scope.connectedBim360FieldUser.first_name + ' ' + scope.connectedBim360FieldUser.last_name) : ''
            scope.connectionStatus = $interpolate($filter('i18next')('integrations.bim360.field.connection.connectedAsBim360FieldUser'))({
              bim360FieldUser: bim360FieldUserName
            })
          } else if (scope.isBim360FieldIntegrationEnabledByOtherUser) {
            scope.connectionStatus = $interpolate($filter('i18next')('integrations.bim360.field.connection.connectedAsSmartvidUser'))({
              smartvidUser: ''
            })
          } else if (scope.bim360FieldUserInactiveError) {
            var bim360FieldUserName = $filter('i18next')('integrations.bim360.field.connection.unknownUser')
            if (scope.connectedBim360FieldUser && scope.connectedBim360FieldUser.first_name && scope.connectedBim360FieldUser.last_name) {
              bim360FieldUserName = scope.connectedBim360FieldUser ? (scope.connectedBim360FieldUser.first_name + ' ' + scope.connectedBim360FieldUser.last_name) : ''
            } else if (scope.bim360FieldIntegrationSettings && scope.bim360FieldIntegrationSettings.settingsData.bim360FieldUserId) {
              // TODO: change to user name when/if available the bim360FieldUserId is a user's email
              bim360FieldUserName = scope.bim360FieldIntegrationSettings.settingsData.bim360FieldUserId
            }

            scope.connectionStatus = $interpolate($filter('i18next')('integrations.bim360.field.connection.inactiveBim360FieldUser'))({
              bim360FieldUser: bim360FieldUserName
            })
          }
        }

        let getBim360FieldUserId = () => {
          if (scope.isBim360FieldIntegrationEnabledByOtherUser && scope.bim360FieldIntegrationSettings.settingsData.bim360FieldUserId !== undefined) {
            return scope.bim360FieldIntegrationSettings.settingsData.bim360FieldUserId
          } else if (scope.currentUserAuthenticatedWithBim360Field !== undefined) {
            return scope.currentUserAuthenticatedWithBim360Field.externalUserId
          } else {
            return undefined
          }
        }

        let loadBim360FieldUserProfile = (afterProfileLoadedFunc) => {
          let bim360FieldUserId = getBim360FieldUserId()
          if (bim360FieldUserId) {
            // TODO: call actual API when/if available
            scope.connectedBim360FieldUser = new Bim360FieldUserModel({
              id: bim360FieldUserId,
              first_name: bim360FieldUserId,
              last_name: ''
            })
            if (afterProfileLoadedFunc) {
              afterProfileLoadedFunc()
            }
          } else if (afterProfileLoadedFunc) {
            afterProfileLoadedFunc()
          }
        }

        let continueLoadingPageData = () => {
          adjustConnectionStatus()
          if (scope.isBim360FieldIntegrationEnabledForProject()) {
            loadBim360FieldData()
          } else {
            scope.isLoadingData = false
          }
        }

        let prepareSyncSettingsIfNeededAndContinueLoadingData = (isAfterAuth) => {
          if (scope.isBim360FieldIntegrationEnabledForProject()) {
            loadBim360FieldUserProfile(continueLoadingPageData)
          } else if (scope.currentUserAuthenticatedWithBim360Field && isAfterAuth) {
            // reset the sync settings after authentication with procore
            scope.bim360FieldIntegrationSettings.settingsData.isDownloadLocations = true
            scope.selectedData.downloadLocations = false
            scope.bim360FieldIntegrationSettings.isEnabled = false
            scope.bim360FieldIntegrationSettings.userId = scope.currentUserAuthenticatedWithBim360Field.userId
            scope.bim360FieldIntegrationSettings.projectId = smartvidProjectId
            scope.bim360FieldIntegrationSettings.settingsData.bim360FieldUserId = scope.currentUserAuthenticatedWithBim360Field.externalUserId
            smartvidApi.saveIntegrationSettings(BIM360_FIELD_PHOTO_SYNC, scope.bim360FieldIntegrationSettings).then(() => {
              scope.isBim360FieldIntegrationEnabledByCurrentUser = true
              scope.bim360FieldIntegrationNotEnabled = false
              loadBim360FieldUserProfile(continueLoadingPageData)
            }, () => {
              scope.isLoadingData = false
              scope.waitingForBim360FieldIntegrationStatus = false
              Notification.error($filter('i18next')('integrations.bim360.field.errors.failedToLoadSyncSettings'))
            })
          } else {
            scope.isLoadingData = false
          }
        }

        let loadPageData = (isAfterAuth) => {
          smartvidApi.getIntegrationSettingsForProject(BIM360_FIELD_PHOTO_SYNC, smartvidProjectId).then((data) => {
            scope.bim360FieldIntegrationSettings = new IntegrationSettingsModel(data)
            scope.bim360FieldIntegrationSettings.integrationType = BIM360_FIELD_PHOTO_SYNC

            scope.waitingForBim360FieldIntegrationStatus = false
            scope.isBim360FieldIntegrationEnabledByOtherUser = scope.isConnectedByAnotherBim360FieldUser() || scope.isConnectedByAnotherSmartvidUser()
            scope.isBim360FieldIntegrationEnabledByCurrentUser = scope.isConnectedByCurrentlyAuthenticatedBim360FieldUser() && scope.isConnectedByCurrentSmartvidUser()
            scope.bim360FieldIntegrationNotEnabled = !scope.bim360FieldIntegrationSettings.settingsData.bim360FieldUserId
            scope.selectedData.downloadLocations = !scope.isBim360FieldIntegrationEnabledForProject() ? false : scope.bim360FieldIntegrationSettings.settingsData.isDownloadLocations

            prepareSyncSettingsIfNeededAndContinueLoadingData(isAfterAuth)
          }, () => {
            scope.isLoadingData = false
            scope.waitingForBim360FieldIntegrationStatus = false
            Notification.error($filter('i18next')('integrations.bim360.field.errors.failedToLoadSyncSettings'))
          })
        }

        let loadBim360FieldData = () => {
          loadBim360FieldProjects()
        }

        let loadBim360FieldProjects = () => {
          smartvidApi.listBim360FieldProjects(smartvidProjectId, true, customErrorHandler).then((data) => {
            scope.bim360FieldProjects = new Bim360FieldProjectCollection(data)
            if (scope.bim360FieldProjects.isEmpty) {
              setNoProjectsInBim360FieldState()
              scope.isLoadingData = false
            } else {
              scope.bim360FieldProjects.models.unshift(getProjectsPlaceholder(false))
              if (scope.bim360FieldIntegrationSettings.settingsData.bim360FieldProjectId) {
                scope.selectedData.bim360FieldSelectedProject = _.find(scope.bim360FieldProjects.models, (p) => {
                  return p.project_id === scope.bim360FieldIntegrationSettings.settingsData.bim360FieldProjectId
                })
                if (!scope.selectedData.bim360FieldSelectedProject) {
                  scope.selectedData.bim360FieldSelectedProject = scope.bim360FieldProjects.first()
                }
              } else {
                scope.selectedData.bim360FieldSelectedProject = scope.bim360FieldProjects.first()
              }

              scope.isLoadingData = false
            }
          }, () => {
            scope.isLoadingData = false
          })
        }

        // initialize page
        $timeout(() => {
          scope.connectionStatus = $filter('i18next')('integrations.bim360.field.connection.not_connected')
          LAST_SYNC_NEVER_LABEL = $filter('i18next')('integrations.lastSyncNever')
          NEXT_SYNC_NA = $filter('i18next')('integrations.nextSyncNA')
          SYNC_PENDING_LABEL = $filter('i18next')('integrations.syncPending')
          SYNC_IN_PROGRESS_LABEL = $filter('i18next')('integrations.syncInProgress')
          resetDefaults()
          checkBim360FieldIntegrationStatusAndLoadData(false)
        }, 10)
        // ------------------------------------------------

        scope.isConnectedByAnotherBim360FieldUser = () => {
          return scope.bim360FieldIntegrationSettings.settingsData.bim360FieldUserId !== undefined &&
            (!scope.currentUserAuthenticatedWithBim360Field || scope.bim360FieldIntegrationSettings.settingsData.bim360FieldUserId !== scope.currentUserAuthenticatedWithBim360Field.externalUserId)
        }

        scope.isConnectedByAnotherSmartvidUser = () => {
          return scope.bim360FieldIntegrationSettings.userId !== undefined && scope.bim360FieldIntegrationSettings.userId !== currentUser.id
        }

        scope.isConnectedByCurrentlyAuthenticatedBim360FieldUser = () => {
          return scope.bim360FieldIntegrationSettings.settingsData.bim360FieldUserId !== undefined &&
            scope.currentUserAuthenticatedWithBim360Field !== undefined && scope.bim360FieldIntegrationSettings.settingsData.bim360FieldUserId === scope.currentUserAuthenticatedWithBim360Field.externalUserId
        }

        scope.isConnectedByCurrentSmartvidUser = () => {
          return scope.bim360FieldIntegrationSettings.userId !== undefined && scope.bim360FieldIntegrationSettings.userId === currentUser.id
        }

        scope.connect = () => {
          if (!scope.currentUserAuthenticatedWithBim360Field) {
            scope.bim360FieldUserInactiveError = false
            scope.authenticateWithBim360FieldAndEnableIntegration()
          } else if (scope.currentUserAuthenticatedWithBim360Field && !scope.isBim360FieldIntegrationEnabledByCurrentUser && !scope.isBim360FieldIntegrationEnabledByOtherUser) {
            scope.bim360FieldUserInactiveError = false
            scope.enableBim360FieldIntegration()
          }
        }

        scope.disconnect = () => {
          if (scope.isBim360FieldIntegrationEnabledForProject()) {
            scope.disableBim360FieldIntegration()
          }
        }

        scope.isBim360FieldIntegrationEnabledForProject = () => {
          return (scope.isBim360FieldIntegrationEnabledByCurrentUser || scope.isBim360FieldIntegrationEnabledByOtherUser)
        }

        scope.reloadBim360FieldProjects = () => {
          if (!scope.bim360FieldUserInactiveError) {
            scope.isLoadingData = true
            loadBim360FieldData()
          }
        }

        scope.isReadOnly = (skipDataCheck) => {
          let prerequisites = scope.bim360FieldIntegrationSettings.isEnabled || scope.isBim360FieldIntegrationEnabledByOtherUser || !scope.isBim360FieldIntegrationEnabledForProject()
          if (prerequisites) {
            return true
          }

          return skipDataCheck ? false : !scope.isBim360FieldDataComplete()
        }

        scope.isSyncNowEnabled = () => {
          return scope.isBim360FieldIntegrationEnabledForProject() && scope.isBim360FieldDataComplete() && !scope.isBim360FieldIntegrationEnabledByOtherUser
        }

        scope.canLinkProject = () => {
          return scope.isBim360FieldIntegrationEnabledByCurrentUser && scope.isBim360FieldDataComplete()
        }

        scope.canUnlinkProject = () => {
          return scope.isBim360FieldIntegrationEnabledByCurrentUser && (scope.isBim360FieldDataComplete() || scope.bim360FieldUserInactiveError)
        }

        scope.getLastSyncDate = () => {
          if (scope.bim360FieldIntegrationSettings.lastSyncDate > 0) {
            return moment(scope.bim360FieldIntegrationSettings.lastSyncDate).format('MMM DD, YYYY hh:mmA')
          }

          if (scope.bim360FieldIntegrationSettings.isEnabled) {
            return SYNC_PENDING_LABEL
          }

          return LAST_SYNC_NEVER_LABEL
        }

        scope.getNextSyncDate = () => {
          if (scope.bim360FieldIntegrationSettings.isEnabled && scope.bim360FieldIntegrationSettings.isProcessing) {
            return SYNC_IN_PROGRESS_LABEL
          }

          if (scope.bim360FieldIntegrationSettings.isEnabled) {
            return $filter('i18next')('integrations.scheduled')
          }

          return NEXT_SYNC_NA
        }

        scope.isBim360FieldDataComplete = () => {
          return scope.selectedData.bim360FieldSelectedProject && scope.selectedData.bim360FieldSelectedProject.project_id && scope.selectedData.bim360FieldSelectedProject.project_id !== NOT_SELECTED_ID
        }

        let getTextWidth = (text, font) => {
          let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
          let context = canvas.getContext('2d')
          context.font = font
          let metrics = context.measureText(text)

          return metrics.width
        }

        scope.isConnectionStatusTextClipped = () => {
          let connectionStatusInput = $('#connectionStatus')
          // TODO: brute force approach, hardcoded font family, input width adjustment and padding for possible ellipses
          return scope.connectionStatus ? (getTextWidth(scope.connectionStatus + '...', 'Normal 15px Poppins') >= connectionStatusInput.width() + 2) : false
        }

        scope.authenticateWithBim360FieldAndEnableIntegration = () => {
          modal.open('connectToBim360Field')
        }

        scope.disableBim360FieldIntegration = () => {
          scope.isLoadingData = true
          smartvidApi.deleteIntegrationSettings(BIM360_FIELD_PHOTO_SYNC, smartvidProjectId).then(() => {
            scope.connectionStatus = $filter('i18next')('integrations.bim360.field.connection.not_connected')
            resetDefaults()
            checkBim360FieldIntegrationStatusAndLoadData(false)
          }, () => {
            scope.isLoadingData = false
          })
        }

        scope.enableBim360FieldIntegration = () => {
          scope.isLoadingData = true
          scope.bim360FieldIntegrationSettings.userId = scope.currentUserAuthenticatedWithBim360Field.userId
          scope.bim360FieldIntegrationSettings.projectId = smartvidProjectId
          scope.bim360FieldIntegrationSettings.settingsData.bim360FieldUserId = scope.currentUserAuthenticatedWithBim360Field.externalUserId
          scope.bim360FieldIntegrationSettings.settingsData.isDownloadLocations = scope.selectedData.downloadLocations
          smartvidApi.saveIntegrationSettings(BIM360_FIELD_PHOTO_SYNC, scope.bim360FieldIntegrationSettings).then(() => {
            checkBim360FieldIntegrationStatusAndLoadData(false)
          }, () => {
            scope.isLoadingData = false
          })
        }

        let fillInBasePhotoSyncSettingsInfo = () => {
          scope.bim360FieldIntegrationSettings.settingsData.bim360FieldUserName =
            scope.connectedBim360FieldUser ? (scope.connectedBim360FieldUser.first_name + ' ' + scope.connectedBim360FieldUser.last_name).trim() : undefined

          scope.bim360FieldIntegrationSettings.settingsData.bim360FieldProjectId =
            scope.selectedData.bim360FieldSelectedProject.project_id === NOT_SELECTED_ID ? undefined : scope.selectedData.bim360FieldSelectedProject.project_id

          scope.bim360FieldIntegrationSettings.syncOnlyAfterDate = Date.now()
          scope.bim360FieldIntegrationSettings.settingsData.isDownloadLocations = scope.selectedData.downloadLocations
        }

        scope.linkProject = () => {
          // userId and bim360FieldUserId are already set at this point
          scope.bim360FieldIntegrationSettings.isEnabled = true

          fillInBasePhotoSyncSettingsInfo()

          scope.saveBim360FieldPhotoSyncSettings()
        }

        scope.unlinkProject = () => {
          scope.bim360FieldIntegrationSettings.isEnabled = false
          scope.saveBim360FieldPhotoSyncSettings()
        }

        scope.saveBim360FieldPhotoSyncSettings = (afterSaveFunc) => {
          scope.projectLinkToggleInTransit = true

          smartvidApi.saveIntegrationSettings(BIM360_FIELD_PHOTO_SYNC, scope.bim360FieldIntegrationSettings).then(() => {
            if (afterSaveFunc) {
              afterSaveFunc()
            } else {
              scope.projectLinkToggleInTransit = false
            }
          }, () => {
            scope.projectLinkToggleInTransit = false
            scope.bim360FieldIntegrationSettings.isEnabled = false
          })
        }

        let saveRuneOnceStatus = () => {
          smartvidApi.enableRunOnceSyncIntegrationSettingsForProject(BIM360_FIELD_PHOTO_SYNC, smartvidProjectId).then(() => {
            scope.projectLinkToggleInTransit = false
            scope.isWaitingForRunOnce = false
            scope.bim360FieldIntegrationSettings.lastSyncDate = Date.now()
          }, () => {
            scope.projectLinkToggleInTransit = false
            scope.isWaitingForRunOnce = false
          })
        }

        scope.enableRunOnceSync = () => {
          scope.isWaitingForRunOnce = true

          if (scope.bim360FieldIntegrationSettings.isEnabled === false) {
            fillInBasePhotoSyncSettingsInfo()
            scope.saveBim360FieldPhotoSyncSettings(saveRuneOnceStatus)
          } else {
            saveRuneOnceStatus()
          }
        }

        scope.$on('sv-project-deleted', () => {
          scope.projectWasDeleted = true
        })

        // when user signs out the $destroy event is received the sate already changed to 'login', don't try to save the settings in this case
        scope.$on('$destroy', () => {
          if ($state.$current.name !== 'login' && !scope.isReadOnly() && !scope.projectWasDeleted) {
            fillInBasePhotoSyncSettingsInfo()
            scope.saveBim360FieldPhotoSyncSettings()
          }
        })

        let listener = $rootScope.$on('sv-connected-to-bim360-field', () => {
          checkBim360FieldIntegrationStatusAndLoadData(true)
        })
        scope.$on('$destroy', listener)
      }
    }
  })
