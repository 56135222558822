import { EventEmitter, Injectable } from '@angular/core'

export enum ForgeCheckboxValueType {
  ALL_SELECTED = 'ALL_SELECTED',
  NONE_SELECTED = 'NONE_SELECTED',
  PARTIALLY_SELECTED = 'PARTIALLY_SELECTED',
}

export enum ForgeChecklistStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface ProjectChangeEvent {
  forgeProjectId: string
  documentSelectedFolderIdPaths: string[]
  documentFolderSelectedType: ForgeCheckboxValueType
  checklistTemplateIds: string[]
  checklistSelectedType: ForgeCheckboxValueType
  issueTypeIds: string[]
  issueSelectedType: ForgeCheckboxValueType
}

export interface FolderChangeEvent {
  documentFolderSelectedType: ForgeCheckboxValueType
  documentSelectedFolderIdPaths: string[]
}

export interface TemplateChangeEvent {
  checklistSelectedType: ForgeCheckboxValueType
  checklistTemplateIds: string[]
}

export interface IssueTypeChangeEvent {
  issueSelectedType: ForgeCheckboxValueType
  issueTypeIds: string[]
}

@Injectable({
  providedIn: 'root',
})
export class ForgeComponentChangeService {
  folderSelectedChange = new EventEmitter<FolderChangeEvent>()
  forgeProjectChange = new EventEmitter<ProjectChangeEvent>()
  templateSelectedChange = new EventEmitter<TemplateChangeEvent>()
  issueTypeSelectedChange = new EventEmitter<IssueTypeChangeEvent>()
}
