/* global angular */

angular.module('smartvid').directive('formFieldEmail', function (MAX_USER_EMAIL_LENGTH, $timeout, UserStatusRemoteFlag) {
  function preLink ($scope, $element, $attr) {
    // set existing user flag
    if ($attr.existingUser === 'false') {
      $scope.userStatusRemoteFlag = UserStatusRemoteFlag.NEW_USER
    } else {
      $scope.userStatusRemoteFlag = UserStatusRemoteFlag.EXISTING_USER
    }
  }

  function postLink ($scope, $element, $attr) {
    $scope.MAX_USER_EMAIL_LENGTH = MAX_USER_EMAIL_LENGTH
    $scope.readOnly = ($attr.readOnly && $attr.readOnly.toLowerCase() === 'true')

    $scope.handleEnterOrTab = (evt) => {
      // enter or tab
      if (evt.keyCode === 13 || evt.keyCode === 9) {
        $scope.form.email.$dirty = true
      }
    }

    function init () {
      // if the email field is auto populated, then do validation on page load
      if ($scope.email) {
        $timeout(() => {
          $scope.form.email.$dirty = true
        }, 800)
      }
    }
    // //// Init \\\\\
    init()
  }

  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'forms/form-fields/form-field-email.html',
    link: {
      pre: preLink,
      post: postLink
    }
  }
})

