/* global angular */

angular.module('smartvid').directive('assetTooltip', function () {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    templateUrl: 'AssetGrid/asset-tooltip.html',
    scope: {
      show: '=',
      right: '@'
    },
    link (scope, el) {
      el.css('pointer-events', 'none')
      el.on('click', () => { return false })
    }
  }
})
