/* global angular */

// These limits should reflect current DB schema

angular.module('smartvid')
  .constant('MAX_TAG_DEF_TEXT_LENGTH', 250)
  .constant('MAX_TAG_DEF_ALIAS_TEXT_LENGTH', 250)

  .constant('MAX_COMMENT_THREAD_DESCRIPTION_LENGTH', 500)

  .constant('MAX_ASSET_NAME_LENGTH', 128)
  .constant('MAX_ASSET_DESCRIPTION_LENGTH', 250)

  .constant('MAX_USER_FIRST_NAME_LENGTH', 50)
  .constant('MAX_USER_LAST_NAME_LENGTH', 50)
  .constant('MAX_USER_EMAIL_LENGTH', 128)

  .constant('MAX_ORGANIZATION_NAME_LENGTH', 255)
  .constant('MAX_SSO_LOGIN_URL_LENGTH', 500)
  .constant('MAX_ORGANIZATION_DESCRIPTION_LENGTH', 1024)

  .constant('MAX_PROJECT_NAME_LENGTH', 255)
  .constant('MAX_PROJECT_DESCRIPTION_LENGTH', 255)

  .constant('MAX_ASSETS_FOR_COMMON_TAG_COMPUTE', 100)
  .constant('MAX_ASSETS_FOR_COMMON_COMMENT_COMPUTE', 10)
  .constant('TAG_DEF_LIMIT', 50)

  .constant('BULK_UPLOAD_FILE_COUNT', 50)

  .constant('BATCH_TAG_ASSETS_COUNT_SHORTCUT', 50)

  .constant('MAX_MULTIPLE_ASSETS_SHARE', 100)
  .constant('MAX_MULTIPLE_ASSETS_DOWNLOAD', 50)

  .constant('TRANSITION_SPEED', 300 /* 300ms */)

  .constant('MAX_BIM_360_USER_ID_LENGTH', 255)
  .constant('MAX_BIM_360_USER_PASSWORD_LENGTH', 255)

  .constant('MAX_OXBLUE_USER_ID_LENGTH', 255)
  .constant('MAX_OXBLUE_USER_PASSWORD_LENGTH', 255)

  .constant('MAX_STRUCTION_SITE_USER_ID_LENGTH', 255)
  .constant('MAX_STRUCTION_SITE_USER_PASSWORD_LENGTH', 255)

  .constant('MAX_FORGE_TITLE_LENGTH', 100)
  .constant('MAX_FORGE_DESCRIPTION_LENGTH', 255)

  .constant('TAG_CONFIDENCE_LEVEL_HIGH', 'HIGH')
  .constant('TAG_CONFIDENCE_LEVEL_MEDIUM', 'MEDIUM')
  .constant('TAG_CONFIDENCE_LEVEL_LOW', 'LOW')

  .constant('TAG_MARKUP_OVERLAY_ID_PREFIX', 'sv-tag-markup-id-')

  .constant('TAG_INSTANCE_TYPE_IMREC', 'IMREC')
  .constant('TAG_INSTANCE_TYPE_ASR', 'ASR')
  .constant('TAG_INSTANCE_TYPE_MANUAL', 'MANUAL')

  // key codes
  .constant('LEFT_ARROW_KEY_CODE', 37)
  .constant('UP_ARROW_KEY_CODE', 38)
  .constant('RIGHT_ARROW_KEY_CODE', 39)
  .constant('DOWN_ARROW_KEY_CODE', 40)

  // 360 viewer
  .constant('SPHERICAL_MARKUP_RADIUS', 900)
  .constant('SEARCH_ENTITY_MODEL_TYPE_TAG_DEFINITION', 'TAG_DEFINITION')
  .constant('SEARCH_ENTITY_MODEL_TYPE_TAG_DEFINITION_ALIAS', 'TAG_DEFINITION_ALIAS')
  .constant('SEARCH_ENTITY_MODEL_TYPE_USER', 'USER')
  .constant('SEARCH_ENTITY_MODEL_TYPE_FREE_TEXT', 'FREE_TEXT')

  // FeatureType
  .constant('FEATURE_TYPE_OBSERVATION', 'OBSERVATION')
  .constant('FEATURE_TYPE_VINNIE_SAGEMAKER', 'VINNIE_SAGEMAKER')
  .constant('FEATURE_TYPE_PTP', 'PTP')
  .constant('FEATURE_TYPE_POWERBI', 'POWERBI')
  .constant('FEATURE_TYPE_POWERBI_SAFETY_OBSERVATIONS', 'POWERBI_SAFETY_OBSERVATIONS')
  .constant('FEATURE_TYPE_POWERBI_SAFETY_MONITORING', 'POWERBI_SAFETY_MONITORING')
  .constant('FEATURE_TYPE_POWERBI_PREDICTIVE', 'POWERBI_PREDICTIVE')
