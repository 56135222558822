/* global angular,_,$ */

angular.module('smartvid').factory('AssetsCollection', function (
  $injector, $q, $interval, $rootScope, $log, BaseCollection, MultiAssetSelection, utils, AssetViewType,
  assetGridHelper
) {
  // TODO move these to DI when AssetsCollection is fixed (ie stops mutating all its own stuff)
  let smartvidApi = $injector.get('smartvidApi')
  let fileUploadService = $injector.get('fileUploadService')
  let AssetModel = $injector.get('AssetModel')

  const DEAFUALT_SEARCH_PAGE_SIZE = 80
  const DEFAULT_PAGE_SIZE = 50

  function getAssetViewType () {
    if (this.assetViewType) {
      return this.assetViewType
    }
    if (this.sharingType && this.sharingType !== 'xsearch' && this.sharingType !== 'search') {
      return AssetViewType.SHARED_ASSETS
    }
    if (this.searchContext || this.sharingType === 'xsearch') {
      return AssetViewType.SEARCH_RESULTS
    }
    return AssetViewType.ASSET_LIST
  }

  function getTotalCountPromise () {
    let defer = $q.defer()
    if (!this.assetCountApi) {
      return $q.when()
    }
    this.assetCountApi().then(count => {
      this.totalAssetCount = count
      defer.resolve()
    }, () => {
      defer.reject()
    })
    return defer.promise
  }

  function getAssetGroupMetadataPromise () {
    if (!this.assetGroupMetadataApi) {
      return $q.when()
    }
    if (!(this.options.state && this.options.state.groupBy)) {
      return $q.when()
    }
    let defer = $q.defer()
    this.assetGroupMetadataApi(this.options, false).then((response) => {
      this.assetGroupMetadata = response
      defer.resolve()
    }, () => {
      defer.reject()
    })
    return defer.promise
  }

  function decorateWithFileUploads () {
    _.each(fileUploadService.filesInfo, (fileInfo) => {
      if (!fileInfo.complete && this.projectId === fileInfo.projectId) {
        this.addUploadingAssetToCollection(fileInfo)
      }
    })
  }

  function longPollForUpdates () {
    // Check if there are any videos that are still being processed
    let processingAssets = _.filter(this.models, (asset) => {
      return asset.isProcessing() || asset.isSmartTaggingInProgress() || asset.isUploading || asset.isSnrInProgress() || asset.isDziInProgress()
    })

    if (processingAssets.length === 0) {
      return
    }

    let processingAssetsIds = _.map(processingAssets, (asset) => {
      return asset.id
    })
    let requestParams = {includeLinkedObservations: !!this.obsLinkedAssets}
    smartvidApi.getAssetsByIds(processingAssetsIds, requestParams, /* getAssetsByIds */ false).then((updatedAssets) => {
      let updatedAssetsMap = _.object(_.map(updatedAssets, function (updatedAsset) {
        return [updatedAsset.id, updatedAsset]
      }))
      // Go through existing assets and update status / progress.
      _.each(processingAssets, (asset) => {
        let updatedAsset = updatedAssetsMap[asset.id]
        if (!_.isEmpty(updatedAsset)) {
          let updatedAssetModel = new AssetModel(updatedAsset)
          let wasAssetProcessing = asset.isProcessing()
          let assetStillProcessing = updatedAssetModel.isProcessing()
          asset.update(updatedAsset, (value, key) => {
            if (_.isUndefined(value)) {
              return false
            }
            if (_.contains(['selected', 'tags', 'comments', 'fc', 'thumbnailUrl', 'tagThumbnailUrl'], key)) {
              return false
            }
            return true
          })
          asset.setThumbnailUrl(updatedAsset.thumbnailUrl)
          asset.setTagThumbnailUrl(updatedAsset.tagThumbnailUrl)
          if (wasAssetProcessing && !assetStillProcessing) {
            $rootScope.$broadcast('sv-asset-finished-processing', asset)
          }
          return updatedAsset
        }
      })
    })
  }

  class AssetsCollection extends BaseCollection {
    constructor (projectId, options, params, assetViewType) {
      super(undefined, AssetModel)
      let properties = $.extend({
        searchContext: null,
        sharingType: null,
        sharingId: null
      }, params)
      this.projectId = projectId
      this.projectGroupId = properties.projectGroupId
      this.organizationId = properties.orgId
      this.searchContext = properties.searchContext
      this.sharingType = properties.sharingType
      this.sharingId = properties.sharingId
      this.assetViewType = assetViewType || getAssetViewType.call(this)
      this.searchCounts = {}
      this.resetListeners = []
      this.updateListeners = []
      this.skipRecentSearches = properties.skipRecentSearches
      this.obsLinkedAssets = properties.obsLinkedAssets
      this.beforeDataLoaded = properties.beforeDataLoaded || (() => {})
      this.afterDataLoaded = properties.afterDataLoaded || (() => {})

      this.init(options)
    }

    doPollListenerUpdates () {
      return $interval(() => {
        longPollForUpdates.call(this)
      }, 9000)
    }

    reset () {
      this.cleanup()
      super.reset()
      this.pollListener = undefined
      this.uploadStartListener = undefined
      this.uploadCompleteListener = undefined
      this.uploadFailedListener = undefined
      this.options = {}
      this.lastPage = 0
      this.firstPage = -1
      this.canFetchPrevious = false
      this.nextPagePromise = $q.when()
      this.totalCountPromise = undefined
      this.assetGroupMetadataPromise = undefined
      this.assetApi = undefined
      this.assetsApi = undefined
      this.assetCountApi = undefined
      this.assetGroupMetadataApi = undefined
      this.assetGroupMetadata = undefined
      this.totalAssetCount = undefined
      this.assetGroups = undefined
      this.assetGroupsCounts = {}
      this.initialized = false
      this.initPromise = undefined
      this.searchCounts = {}
      _.each(this.resetListeners, (listener) => {
        listener()
      })
    }

    cleanup () {
      if (this.pollListener) {
        $interval.cancel(this.pollListener)
      }
      if (this.uploadStartListener) {
        this.uploadStartListener.call()
      }
      if (this.uploadCompleteListener) {
        this.uploadCompleteListener.call()
      }
      if (this.uploadFailedListener) {
        this.uploadFailedListener.call()
      }
    }

    addUpdateListener (listener) {
      let self = this
      self.updateListeners.push(listener)
      let removeListener = function () {
        var index = self.updateListeners.indexOf(listener)
        self.updateListeners.splice(index, 1)
      }
      return removeListener
    }

    addResetListener (listener) {
      let self = this
      self.resetListeners.push(listener)
      let removeListener = function () {
        var index = self.resetListeners.indexOf(listener)
        self.resetListeners.splice(index, 1)
      }
      return removeListener
    }

    init (options) {
      this.reset()
      this.options = (options) ? angular.copy(options) : assetGridHelper.getAssetViewOptions(this.projectId, this.assetViewType, $rootScope.isMobile, this.projectGroupId)
      this.options.pageSize = this.options.pageSize || DEFAULT_PAGE_SIZE
      this.lastPage = (!_.isUndefined(this.options.page)) ? this.options.page : 0
      this.firstPage = (!_.isUndefined(this.options.page)) ? this.options.page - 1 : -1
      this.canFetchPrevious = (this.options && this.options.page > 0)
      switch (this.assetViewType) {
        case AssetViewType.SHARED_ASSETS:
          if (!this.projectId) {
            this.options.sortInfo = this.options.sortInfo || []
            if (this.options.sortInfo.length === 1) {
              this.options.sortInfo.unshift({sortBy: 'projectId', sortOrder: 'ASC'})
            } else {
              this.options.sortInfo[0] = {sortBy: 'projectId', sortOrder: 'ASC'}
            }
          }
          this.assetsApi = _.bind(smartvidApi.getSharedAssets, undefined, this.sharingType, this.sharingId, _, {projectId: this.projectId})
          this.assetCountApi = _.bind(smartvidApi.getSharedAssetsCount, undefined, this.sharingType, this.sharingId)
          this.assetGroupMetadataApi = _.bind(smartvidApi.getSharedAssetGroupMetadata, undefined, this.sharingType, this.sharingId)
          this.pollListener = this.doPollListenerUpdates()
          break
        case AssetViewType.SEARCH_RESULTS:
          this.options.pageSize = this.options.pageSize || DEAFUALT_SEARCH_PAGE_SIZE
          var searchByTagDefs = _.map(this.searchContext.searchTags, (t) => {
            return {
              tagDefId: t.id,
              hasChildren: t.hasChildren
            }
          })
          var searchByUserIds = _.map(this.searchContext.searchCreatedByUsers, (u) => {
            return u.id
          })
          var opt = {skipRecentSearches: this.skipRecentSearches, organizationId: this.organizationId, projectGroupId: this.projectGroupId }
          _.extend(this.options, opt)
          this.assetsApi = (apiOptions) => {
            let defer = $q.defer()
            smartvidApi.searchAssets(
              this.projectId,
              searchByTagDefs,
              searchByUserIds,
              this.searchContext.textFragments,
              this.searchContext.assetGroup,
              this.searchContext.tagConfidenceLevel,
              this.searchContext.searchDateRange,
              this.searchContext.searchQueryType,
              this.searchContext.includedProjectIds,
              apiOptions
            ).then(data => {
              this.assetGroups = data.searchGroups
              defer.resolve(data.assets)
            }, () => {
              defer.reject()
            })
            return defer.promise
          }
          this.assetCountApi = () => {
            let defer = $q.defer()
            smartvidApi.searchAssetsCount(this.projectId,
              searchByTagDefs,
              searchByUserIds,
              this.searchContext.textFragments,
              undefined,
              this.searchContext.tagConfidenceLevel,
              this.searchContext.searchDateRange,
              this.searchContext.searchQueryType,
              true, /* includeTagConfidenceLevelCounters */
              this.searchContext.includedProjectIds,
              this.organizationId,
              this.projectGroupId
            ).then((data) => {
              this.searchCounts = data
              this.assetGroupsCounts = _.mapValues(data.groupCountsMap, (val, key) => {
                return {
                  totalCount: val
                }
              })
              defer.resolve(data.totalCount)
            }, () => {
              defer.resolve(0)
            })
            return defer.promise
          }
          this.pollListener = this.doPollListenerUpdates()
          break
        case AssetViewType.DELETED_ASSETS:
          this.assetsApi = _.bind(smartvidApi.getProjectDeletedAssets, undefined, this.projectId)
          this.assetGroupMetadataApi = _.bind(smartvidApi.getDeletedAssetGroupMetadata, undefined, this.projectId)
          break
        case AssetViewType.ASSET_LIST:
          _.extend(this.options, {includeLinkedObservations: !!this.obsLinkedAssets})
          var requestOpt = this.obsLinkedAssets ? {includeLinkedObservations: true} : undefined
          this.assetsApi = _.bind(smartvidApi.getProjectAssets, requestOpt,  this.projectId)
          this.assetCountApi = _.bind(smartvidApi.getProjectAssetsCount, undefined, this.projectId)
          this.assetGroupMetadataApi = _.bind(smartvidApi.getAssetGroupMetadata, undefined, this.projectId)
          decorateWithFileUploads.call(this)
          this.pollListener = this.doPollListenerUpdates()
          this.uploadStartListener = $rootScope.$on('sv-before-upload-start', (evt, fileInfo) => {
            this.addUploadingAssetToCollection(fileInfo)
          })
          this.uploadCompleteListener = $rootScope.$on('sv-upload-complete', (evt, data) => {
            if (data.isDuplicate) {
              this.removeById(data.key)
            } else {
              let json = data.assetJSON[0].asset
              this.replaceItem(data.key, json)
            }
          })
          this.uploadFailedListener = $rootScope.$on('sv-file-upload-failed', () => {
            this.removeUploadingAssetFromCollection()
          })
          break
      }
      if (this.sharingType) {
        this.assetApi = _.partial(smartvidApi.getSharedAsset, this.sharingType, this.sharingId, _)
      } else {
        this.assetApi = _.partial(smartvidApi.getAsset, _, true, true, true, true)
      }
      this.nextPagePromise = this.nextPage()
      this.totalCountPromise = getTotalCountPromise.call(this)
      this.assetGroupMetadataPromise = getAssetGroupMetadataPromise.call(this)
      let initPromises = [this.dataStartedLoading(), this.nextPagePromise]
      if (this.totalCountPromise) {
        initPromises.push(this.totalCountPromise)
      }
      this.initPromise = $q.all(initPromises)
      this.initPromise.then(() => {
        this.initialized = true
        this.afterDataLoaded()
      }, this.afterDataLoaded)
    }

    reinit () {
      this.init(this.options)
    }

    upsert (assets, beginning = false) {
      super.upsert(assets, AssetModel, beginning)
      _.each(this.updateListeners, (listener) => {
        listener()
      })
    }

    removeById (id) {
      super.removeById(id)
      _.each(this.updateListeners, (listener) => {
        listener()
      })
    }

    dataStartedLoading () {
      let defer = $q.defer()
      this.beforeDataLoaded()
      defer.resolve(true)
      return defer.promise
    }

    nextPage () {
      if (!this.canFetch) {
        return this.nextPagePromise
      }
      let defer = $q.defer()
      this.nextPagePromise.then(() => {
        this.isFetching = true
        let apiOptions = angular.copy(this.options)
        apiOptions.page = this.lastPage
        this.assetsApi(apiOptions).then((assets) => {
          this.lastPage++
          if (this.obsLinkedAssets && this.obsLinkedAssets.length > 0) {
            const obsLinkAssetIds = this.obsLinkedAssets.map(ola => ola.id)
            const foundAssets = assets.filter(a => obsLinkAssetIds.includes(a.id))
            if (foundAssets.length > 0) {
              this.upsert(foundAssets)
            }
          } else {
            this.upsert(assets)
          }
          if (_.isEmpty(assets)) {
            this.canFetch = false
          }
          if (this.allSelected) {
            $rootScope.$broadcast('sv-more-assets-selected')
          }
          this.isFetching = false
          defer.resolve(true)
        }, () => {
          this.isFetching = false
          $log.error('Failure to read assets from the server')
          defer.resolve(false)
        })
      }, () => {
        this.isFetching = false
        defer.resolve(false)
      })
      this.nextPagePromise = defer.promise
      return defer.promise
    }

    previousPage () {
      if (!this.canFetchPrevious) {
        return this.nextPagePromise
      }
      let defer = $q.defer()
      this.nextPagePromise.then(() => {
        this.isFetching = true
        let apiOptions = angular.copy(this.options)
        apiOptions.page = this.firstPage - 1
        this.assetsApi(apiOptions).then((assets) => {
          this.lastPage--
          if (this.obsLinkedAssets && this.obsLinkedAssets.length > 0) {
            const obsLinkAssetIds = this.obsLinkedAssets.map(ola => ola.id)
            const foundAssets = assets.filter(a => obsLinkAssetIds.includes(a.id))
            if (foundAssets.length > 0) {
              this.upsert(foundAssets, true)
            }
          } else {
            this.upsert(assets, true)
          }
          if (_.isEmpty(assets) || assets.length < this.options.pageSize) {
            this.canFetchPrevious = false
          }
          if (this.allSelected) {
            $rootScope.$broadcast('sv-more-assets-selected')
          }
          this.isFetching = false
          defer.resolve()
        }, () => {
          this.isFetching = false
          $log.error('Failure to read assets from the server')
        })
      }, () => {
        this.isFetching = false
        defer.reject()
      })
      this.nextPagePromise = defer.promise
      return defer.promise
    }

    getSelected () {
      return _.where(this.models, {selected: true})
    }

    findById (id) {
      return _.find(this.models, (model) => {
        return model.id === id
      })
    }

    getIndexOf (id) {
      for (let i = 0; i < this.models.length; i++) {
        if (id === this.models[i].id) {
          return i
        }
      }
      return -1
    }

    replaceItem (id, assetJSON) {
      let asset = _.find(this.models, (model) => {
        return model.id === id
      })

      if (!asset) {
        return
      }

      let newAsset = new AssetModel(assetJSON)

      for (let key in newAsset) {
        asset[key] = newAsset[key]
      }
      asset.isUploading = false
      asset.recentUpload = true
    }

    addUploadingAssetToCollection (fileInfo) {
      let type = utils.isVideo(fileInfo.file) ? 'VIDEO' : 'IMAGE'
      let asset = {
        id: fileInfo.key,
        name: fileInfo.file.name,
        type: type,
        isUploading: true,
        recentUpload: true,
        createdTime: new Date().getTime()
      }
      this.upsert([asset], AssetModel, true /* beginning */)
    }

    removeUploadingAssetFromCollection () {
      _.remove(this.models, {
        isUploading: true
      })

      if (this.isEmpty) {
        this.allSelected = false
      }
      _.each(this.updateListeners, (listener) => {
        listener()
      })
    }

    getMultiAssetSelection () {
      let selection = new MultiAssetSelection()

      selection.projectId = this.projectId
      selection.assetViewType = this.assetViewType
      selection.shareLinkId = this.sharingId

      if (this.searchContext) {
        selection.searchRequest = {
          projectId: this.projectId,
          tagDefs: this.searchContext.getSearchByTagDefs(),
          userIds: this.searchContext.getSearchByUserIds(),
          textFragments: this.searchContext.textFragments,
          assetGroup: this.searchContext.assetGroup,
          tagConfidenceLevel: this.searchContext.tagConfidenceLevel,
          searchQueryType: this.searchContext.searchQueryType,
          searchDateRange: this.searchContext.searchDateRange
        }
      }

      if (this.allSelected) {
        selection.isAllAssets = true
        selection.unselectedAssetIds = _.pluck(this.getUnselected(), 'id')
        if (selection.unselectedAssetIds.length > 0) {
          selection.selectedAssetIds = _.pluck(this.getSelected(), 'id')
        }
      } else {
        selection.selectedAssetIds = _.pluck(this.getSelected(), 'id')
      }
      return selection
    }

    getExtendedAsset (id, forceGoToServer = false, tagConfidenceLevel = undefined, includeLinkedObservations = true) {
      let found = this.first({id: id})
      if (!forceGoToServer && found && found.extended) {
        return $q.resolve(found)
      }
      return this.assetApi(id, tagConfidenceLevel, includeLinkedObservations).then((asset) => {
        asset.extended = true
        return (found) ? found.update(asset) : asset
      })
    }
  }

  return AssetsCollection
})
