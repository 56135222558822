/* global angular */

angular.module('smartvid').directive('adminOrganizationsGridControlbar', function (currentUser) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'AdminOrganizationsGrid/controlbar.html',
    link: function (scope) {
      scope.showSettingsOptions = ($event) => {
        scope.flyout.open('adminOrganizationsGridOptions', {
          parentElement: $event.currentTarget,
          direction: 'top',
          adminOrganizations: scope.adminOrganizations
        })
      }

      scope.isShowAddOrgIcon = currentUser.isSupportsCreationOrganizations()
    }
  }
})
