/* global angular,analytics,_,FORGE_INTEGRATION_UI_ENABLED, PROCORE_INTEGRATION_UI_ENABLED */

import * as angular from 'angular'
import { ObservationDialogComponent } from 'modules/observations/components/observation-dialog/observation-dialog.component'
import { ObservationType } from 'modules/observations/models/observation-type.enum'

angular.module('smartvid').controller('ShareAssetCtrl', function (
  $q, $filter, $timeout, $scope, $state, smartvidApi, utils, currentUser,
  dashboardDataHelper, MAX_MULTIPLE_ASSETS_SHARE, i18nUtils, ProjectsCollection, ForgeIssueCreateModel, ProcoreObservationIssueModel, dialogUtils) {
  const SCOPE_DISPLAY_ORDER = ['PROJECT', 'ORGANIZATION', 'USER', 'GLOBAL', 'PUBLIC']

  let contentShareRoleType
  let currentProject = dashboardDataHelper.getCurrentProject() || {}
  let currentOrgId = currentProject.organizationId
  let allProjects = dashboardDataHelper.getAllProjects() || new ProjectsCollection()
  let allSharingRoles
  $scope.users = []
  $scope.shareMessage = {value: ''}
  $scope.isCopyLinkDisabled = false
  $scope.sharableLink = ''
  $scope.tooManyFilesToShare = false
  $scope.roleScopes
  $scope.linkShareRoles
  $scope.userPoolRoles
  $scope.linkShareSelectedScope
  $scope.linkShareSelectedRole
  $scope.buttonDisabled = false
  $scope.isCrossProjectSearch = angular.isUndefined(dashboardDataHelper.getCurrentProject())
  $scope.isCrossProjectAssetSelection = getIsCrossProjectAssetSelection()
  $scope.observationTypes = [{
    type: ObservationType.POSITIVE,
    label: $filter('i18next')('observations.controlbar.positiveObservation')
  },
    {type: ObservationType.NEGATIVE, label: $filter('i18next')('observations.controlbar.negativeObservation')}]

  let SEND_EMAIL_LINK
  let CREATE_SHARE_LINK
  let CREATE_BIM_360_ISSUE
  let CREATE_PROCORE_OBSERVATION_ISSUE
  let CREATE_OBSERVATION_ISSUE

  $scope.shareType = {}
  $scope.forgeIssue = new ForgeIssueCreateModel()
  $scope.procoreIssue = new ProcoreObservationIssueModel()
  $scope.procoreProjectId
  $scope.procoreUserId
  $scope.procoreAttachments = []
  $scope.procoreOptions = {}
  $scope.beforeProcoreSubmit = function () {
    $scope.procoreOptions.collectData()
  }

  $scope.projectId = $state.params.projectId || currentProject.id
  $scope.isPositiveObservation = {value: true}
  i18nUtils.loadLabel(() => {
    SEND_EMAIL_LINK = {
      id: 'SEND_EMAIL_LINK',
      displayName: $filter('i18next')('sharing.sendEmailLink')
    }
    CREATE_SHARE_LINK = {
      id: 'CREATE_SHARE_LINK',
      displayName: $filter('i18next')('sharing.createSharableLink')
    }
    CREATE_BIM_360_ISSUE = {
      id: 'CREATE_BIM_360_ISSUE',
      displayName: $filter('i18next')('sharing.createBIM360Issue')
    }
    CREATE_PROCORE_OBSERVATION_ISSUE = {
      id: 'CREATE_PROCORE_OBSERVATION_ISSUE',
      displayName: $filter('i18next')('sharing.createProcoreObservationIssue')
    }
    CREATE_OBSERVATION_ISSUE = {
      id: 'CREATE_OBSERVATION_ISSUE',
      displayName: $filter('i18next')('sharing.createObservationIssue')
    }

    $scope.allShareTypes = [SEND_EMAIL_LINK, CREATE_SHARE_LINK]

    $scope.shareType = SEND_EMAIL_LINK
  })

  $scope.getAllUsers = getAllUsers
  $scope.searchByEmail = searchByEmail
  $scope.shareAssets = shareAssets
  $scope.isSendDisabled = isSendDisabled
  $scope.copyLinkToClipboard = copyLinkToClipboard
  $scope.generateNewLink = generateNewLink
  $scope.refreshLinkShareRoles = refreshLinkShareRoles
  $scope.getLabelUponShareType = getLabelUponShareType
  $scope.isShouldGetMoreAssets = utils.isShouldGetMore

  checkProcoreIntegrationEnabledForProject()
  checkForgeIssueCreateEnabledForProject()
  checkObservationFeatureEnabled()

  init()

  $scope.shareTypeChanged = () => {
    if ($scope.shareType.id === 'CREATE_SHARE_LINK') {
      generateNewLink()
    }
  }

  function getLabelUponShareType () {
    let label = ''
    switch ($scope.shareType.id) {
      case 'SEND_EMAIL_LINK':
        label = 'common.send'
        break
      case 'CREATE_OBSERVATION_ISSUE':
      case 'CREATE_PROCORE_OBSERVATION_ISSUE':
        label = 'common.create'
        break
      default:
        label = 'common.done'
        break
    }
    return label
  }

  function checkObservationFeatureEnabled () {
    let observationFeatureEnabled = currentProject && currentProject.canCreateObservations && currentUser.isObservationEnabledForOrganization(currentProject.organizationId)
    if (observationFeatureEnabled && $scope.projectId && !currentProject.isInactive) {
      $scope.allShareTypes = $scope.allShareTypes.concat([CREATE_OBSERVATION_ISSUE])
    }
  }

  function checkProcoreIntegrationEnabledForProject () {
    if ($scope.projectId && !currentProject.isInactive) {
      smartvidApi.getProcoreCreateIssueContext($scope.projectId, () => {
        // we don't want to block user from using other parts of the UI so we have to ignore the error here
        return true
      }).then(data => {
        if (data.isEnabled && PROCORE_INTEGRATION_UI_ENABLED) {
          $scope.allShareTypes = $scope.allShareTypes.concat([CREATE_PROCORE_OBSERVATION_ISSUE])
          $scope.procoreProjectId = data.photoSyncSettings.procoreProjectId
          $scope.procoreUserId = data.photoSyncSettings.procoreUserId
        }
      }).catch(angular.noop)
    }
  }

  function checkForgeIssueCreateEnabledForProject () {
    if ($scope.projectId && FORGE_INTEGRATION_UI_ENABLED && !currentProject.isInactive) {
      smartvidApi.getForgeIssueCreateContext($scope.projectId).then((data) => {
        if (data.isCreateEnabled) {
          $scope.allShareTypes = $scope.allShareTypes.concat([CREATE_BIM_360_ISSUE])
        }
      })
    }
  }

  function init () {
    if ($scope.allSelected) {
      $scope.buttonDisabled = true
      fillCollectionToMax($scope.collection, MAX_MULTIPLE_ASSETS_SHARE)
    }
    setTooManyFilesToShare()
    allSharingRoles = getAllSharingRoles()
    contentShareRoleType = getContentShareRoleTypeMap(allSharingRoles)
    $scope.roleScopes = getRoleScopes(allSharingRoles)
    $scope.userPoolRoles = getUserPoolRoles(allSharingRoles)
    refreshLinkShareRoles()
    $scope.linkShareSelectedScope = {
      value: $scope.roleScopes[0]
    }
    if ($scope.shareType.id === 'CREATE_SHARE_LINK') {
      generateNewLink()
    }
  }

  function refreshLinkShareRoles () {
    if ($scope.linkShareSelectedScope && $scope.linkShareSelectedScope.value.type === 'PUBLIC') {
      $scope.linkShareRoles = _($scope.linkShareRoles).filter(role => role.type !== 'COLLABORATE').value()
    } else {
      $scope.linkShareRoles = getLinkShareRoles(allSharingRoles)
    }
    $scope.linkShareSelectedRole = {
      value: $scope.linkShareRoles[0]
    }
  }

  function fillCollectionToMax (assetCollection, max) {
    assetCollection.nextPagePromise.then(() => {
      if ($scope.isShouldGetMoreAssets(assetCollection, max)) {
        assetCollection.nextPage()
        fillCollectionToMax(assetCollection, max)
      } else {
        $scope.assets = assetCollection.getSelected()
        setTooManyFilesToShare()
        $scope.buttonDisabled = false
      }
    })
  }

  function setTooManyFilesToShare () {
    $scope.tooManyFilesToShare = ($scope.assets && $scope.assets.length > MAX_MULTIPLE_ASSETS_SHARE)
  }

  function getAllSharingRoles () {
    let assets = ($scope.assets) ? $scope.assets : [$scope.asset]
    return _.intersection.apply(
      this,
      _.map(assets, (asset) => {
        return ($scope.isCrossProjectAssetSelection)
          ? currentUser.getCrossProjectAssetSharingRolesForAssetAndOrgId(asset, (allProjects.findById(asset.projectId) || {}).organizationId)
          : currentUser.getSharingRolesForAsset(asset)
      })
    )
  }

  function getContentShareRoleTypeMap (allSharingRoles) {
    return _.reduce(allSharingRoles, function (map, role) {
      if (!map[role.scope]) {
        map[role.scope] = {}
      }
      map[role.scope][role.permission] = role.name
      return map
    }, {})
  }

  function getRoleScopes (allSharingRoles) {
    let temp = _.map(_.without(_.uniq(_.pluck(allSharingRoles, 'scope')), 'USER'),
      function (scope) {
        return {
          type: scope,
          displayName: $filter('i18next')('sharing.role.scope.' + scope)
        }
      })
    return _.sortBy(temp, (s) => {
      return _.indexOf(SCOPE_DISPLAY_ORDER, s.type)
    })
  }

  function getLinkShareRoles (allSharingRoles) {
    return _.map(_.uniq(_.pluck(allSharingRoles, 'permission')), function (permission) {
      return {
        type: permission,
        displayName: $filter('i18next')('sharing.role.permission.' + permission)
      }
    })
  }

  function getUserPoolRoles (allSharingRoles) {
    return _.map(_.filter(allSharingRoles, function (role) {
      return role.scope === 'USER'
    }), function (role) {
      return {
        type: role.name,
        displayName: $filter('i18next')('sharing.role.permission.' + role.permission)
      }
    })
  }

  function getSelectedContentShareRole () {
    return contentShareRoleType[$scope.linkShareSelectedScope.value.type][$scope.linkShareSelectedRole.value.type]
  }

  function createBim360Issue () {
    let projectId = $state.params.projectId || currentProject.id
    if (!projectId) {
      return
    }

    let contentIds
    let contentShareType

    if ($scope.tag) {
      contentIds = [$scope.tag.id]
      contentShareType = 'TAG_INSTANCE'
    } else if ($scope.comment) {
      contentIds = [$scope.comment.id]
      contentShareType = 'COMMENT_THREAD'
    } else {
      contentIds = _.map($scope.assets, (asset) => {
        return asset.id
      })
      contentShareType = 'ASSET'
    }

    smartvidApi.createForgeIssue(projectId, contentIds, contentShareType, $scope.forgeIssue).then(() => {
      $scope.modal.close()
    }, () => {
      $scope.buttonDisabled = false
    })
  }

  function createProcoreObservationItem () {
    let projectId = $state.params.projectId || currentProject.id
    if (!projectId) {
      return
    }
    $scope.beforeProcoreSubmit()
    let inspectionItemFailed = 0
    $scope.procoreAttachments = _.map($scope.assets, (asset) => {
      return asset.id
    })
    $scope.procoreIssue.created_by_id = $scope.procoreUserId
    smartvidApi.createProcoreObservationIssue(projectId, $scope.procoreProjectId, inspectionItemFailed, $scope.procoreIssue, $scope.procoreAttachments).then(() => {
      $scope.modal.close()
    }, () => {
      $scope.buttonDisabled = false
    })
  }

  function shareWith (users) {
    let projectId = $state.params.projectId || currentProject.id
    if (!projectId) {
      projectId = !$scope.isCrossProjectAssetSelection ? ($scope.assets[0] || {}).projectId : null
    }
    let contentIds
    let contentShareType
    if ($scope.tag) {
      contentIds = [$scope.tag.id]
      contentShareType = 'TAG_INSTANCE'
    } else if ($scope.comment) {
      contentIds = [$scope.comment.id]
      contentShareType = 'COMMENT_THREAD'
    } else {
      contentIds = _.map($scope.assets, (asset) => {
        return asset.id
      })
      contentShareType = 'ASSET'
    }
    return smartvidApi.shareContent(projectId, contentIds, contentShareType, users, $scope.shareMessage.value)
  }

  function getIsCrossProjectAssetSelection () {
    return _.uniq($scope.assets || [], 'projectId').length > 1
  }

  function generateNewLink () {
    $scope.sharableLink = ''
    $scope.isCopyLinkDisabled = true
    let user = {role: {type: getSelectedContentShareRole()}}
    let promise = shareWith([user]).then((response) => {
      $scope.sharableLink = response.shareUrl
      $scope.isCopyLinkDisabled = false
    })
    return promise
  }

  function chooseLinkShare () {
    let linkText = document.querySelector('input[name=sharelink]')
    linkText.focus()
    linkText.setSelectionRange(0, linkText.value.length)
  }

  function copyLinkToClipboard () {
    chooseLinkShare()
    document.execCommand('Copy')
    utils.notify('sharing.linkCopied')
  }

  function isSendDisabledForForgeIssue () {
    return $scope.shareType.id === CREATE_BIM_360_ISSUE.id &&
      (!$scope.forgeIssue.issueTypeId || !$scope.forgeIssue.issueSubTypeId || !$scope.forgeIssue.title)
  }

  function isSendDisabled () {
    return ($scope.shareType.id === SEND_EMAIL_LINK.id && $scope.users.length === 0) || $scope.buttonDisabled ||
      isSendDisabledForForgeIssue()
  }

  function shareAssets () {
    if ($scope.shareType.id === CREATE_BIM_360_ISSUE.id) {
      $scope.buttonDisabled = true
      createBim360Issue()
      return
    } else if ($scope.shareType.id === CREATE_PROCORE_OBSERVATION_ISSUE.id) {
      $scope.buttonDisabled = true
      createProcoreObservationItem()
      return
    } else if ($scope.shareType.id === CREATE_OBSERVATION_ISSUE.id) {
      $scope.buttonDisabled = true
      $scope.modal.close()
      const obsType = $scope.isPositiveObservation.value ? $scope.observationTypes[0].type : $scope.observationTypes[1].type
      dialogUtils.open(ObservationDialogComponent, {
        data: {
          observationType: obsType,
          observationId: undefined,
          projectId: $scope.projectId,
          organizationId: currentOrgId,
          externalAssets: $scope.assets
        }
      })
      return
    }
    let getProject = (asset) => {
      if ($scope.isCrossProjectAssetSelection || _.isEmpty(currentProject)) {
        return allProjects.findById(asset.projectId) || {}
      }
      return currentProject
    }
    _.each($scope.assets, (asset) => {
      let project = getProject(asset) || {}
      let org = _.isEmpty(project) ? {} : (project.getProjectOrg() || {})
      analytics.track('Share Asset', {
        category: 'Asset Action',
        assetType: asset.type,
        assetName: asset.name,
        projectName: project.name,
        orgName: org.name,
        shareType: $scope.shareType.id,
        shareScope: ($scope.tag) ? 'TAG' : (($scope.comment) ? 'COMMENT' : 'ASSET')
      })
    })
    if ($scope.shareType.id === CREATE_SHARE_LINK.id) {
      $scope.modal.close()
    } else {
      shareWith($scope.users).then(() => {
        utils.notify('sharing.linkSent')
        $scope.modal.close()
      })
    }
  }

  function searchByEmail (partialEmail) {
    let promises = []
    if ($scope.isCrossProjectSearch) {
      let projectIds = _.pluck(allProjects.where({canInviteUsers: true}), 'id')
      let orgIds = _.pluck(_.where(currentUser.organizations, {canManageUsers: true}), 'id')
      promises.push(smartvidApi.getUsersByPartialEmailForProjects(projectIds, partialEmail))
      promises.push(smartvidApi.getUsersByPartialEmailForOrgs(orgIds, partialEmail))
    } else {
      promises.push(smartvidApi.getUsersByPartialEmailForProject(currentProject.id, partialEmail))
      promises.push(smartvidApi.getUsersByPartialEmailForOrgs([currentProject.organizationId], partialEmail))
    }

    return $q.all(promises)
  }

  function getAllUsers () {
    let promises = []
    let projectPromise = smartvidApi.getProjectUsers($scope.currentProject.id)
    promises.push(projectPromise)

    if ($scope.isCrossProjectSearch) {
      _.each(allProjects.models, (project) => {
        promises.push(smartvidApi.getProjectUsers(project.id))
      })
      _.each(currentUser.organizations, (org) => {
        promises.push(smartvidApi.getOrganizationUsers(org.id))
      })
    } else {
      promises.push(smartvidApi.getProjectUsers(currentProject.id))
      promises.push(smartvidApi.getOrganizationUsers(currentProject.organizationId))
    }
    return $q.all(promises)
  }
})
