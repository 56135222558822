/* global angular */

angular.module('smartvid').directive('adminOrganizationsGrid', function ($timeout, currentUser, OrganizationModel, utils, $filter, dashboardDataHelper) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'AdminOrganizationsGrid/admin-organizations-grid.html',
    link: function (scope) {
      scope.getSortedOrganizations = () => {
        return $filter('orderBy')(scope.adminOrganizations.models, 'name')
      }

      scope.$on('sv-organization-created', (message, data) => {
        data.newlyAdded = true
        let organization = new OrganizationModel(data)
        let adminOrganizations = dashboardDataHelper.getAllOrganizations()
        adminOrganizations.add(organization)
        currentUser.addOrganization(organization)
        $timeout(() => {
          organization.newlyAdded = false
          currentUser.updateOrganization(organization)
        }, 1)
      })

      scope.isShowAddOrgPlacard = currentUser.isSupportsCreationOrganizations()
    }
  }
})
