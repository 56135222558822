/* global angular */

angular.module('smartvid').controller('PasswordResetTimeout', function ($scope, $stateParams, smartvidApi, utils,
 $filter) {
  $scope.inTransit = false
  $scope.userEmail = $stateParams.email
  $scope.success = false
  $scope.resendPasswordReset = () => {
    $scope.inTransit = true
    smartvidApi.requestPasswordReset($scope.userEmail).then((response) => {
      $scope.inTransit = false
      $scope.success = true
    }, (response) => {
      $scope.inTransit = false
      utils.notify($filter('i18next')('forgotPassword.emailSendError') + $scope.userEmail)
    })
  }
})
