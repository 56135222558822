/* global angular _ */

angular.module('smartvid').factory('FeatureSettingsModel', function (BaseModel) {
  class FeatureSettingsModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined,
        featureType: undefined,
        ownerType: undefined,
        ownerName: undefined,
        ownerId: undefined,
        isEnabled: false,
        customDataJson: {}
      }

      super(_.defaults(attrs || {}, defaults))
    }

    isVinnieRecognitionsLimitReached () {
      return this.customDataJson.maximumRecognitionsCount && this.customDataJson.currentRecognitionsCount && this.customDataJson.currentRecognitionsCount >= this.customDataJson.maximumRecognitionsCount
    }
  }

  return FeatureSettingsModel
})
