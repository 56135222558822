/* global angular */

angular.module('smartvid').factory('ProcoreObservationIssueAssigneeCollection', function (BaseCollection, ProcoreObservationIssueAssigneeModel) {
  class ProcoreObservationIssueAssigneeCollection extends BaseCollection {
    constructor (models) {
      super(models, ProcoreObservationIssueAssigneeModel)
    }
  }

  return ProcoreObservationIssueAssigneeCollection
})
