/* global angular _ $ */
// TODO: PL: This service can be change to handle general, DZI based, markup manipulations for both comments and tags
angular.module('smartvid').service('dziTagMarkupService', function (smartvidApi, openSeaDragonService,
                                                                    $timeout, TAG_MARKUP_OVERLAY_ID_PREFIX) {
  let dragToPositionTagMarkupInternal = (dziViewer, startXDelta, startYDelta, tag) => {
    // create points and rects here to reuse instead of creating a new one for each mouse move
    let startScreenPoint = openSeaDragonService.point(startXDelta, startYDelta)
    let startViewportPoint = dziViewer.viewport.pointFromPixel(startScreenPoint)
    let endScreenPoint = openSeaDragonService.point(0, 0)
    let overlayRect = openSeaDragonService.rect(tag.markup.left, tag.markup.top, tag.markup.width, tag.markup.height)

    document.body.onmousemove = function (evt) {
      evt = evt || window.event
      evt.stopPropagation()
      endScreenPoint.x = evt.pageX !== undefined ? evt.pageX : evt.clientX
      endScreenPoint.y = evt.pageY !== undefined ? evt.pageY : evt.clientY

      let endViewPortPoint = dziViewer.viewport.pointFromPixel(endScreenPoint)
      tag.markup.left += endViewPortPoint.x - startViewportPoint.x
      tag.markup.top += endViewPortPoint.y - startViewportPoint.y

      startViewportPoint = endViewPortPoint

      overlayRect.x = tag.markup.left
      overlayRect.y = tag.markup.top
      overlayRect.width = tag.markup.width
      overlayRect.height = tag.markup.height

      updatePanAndZoomForTag(dziViewer, tag)

      dziViewer.updateOverlay(tag.markup.id, overlayRect)
    }

    document.body.onmouseup = function (evt) {
      evt = evt || window.event
      evt.stopPropagation()

      document.body.onmousemove = document.body.onmouseup = null
      smartvidApi.updateTagForAsset(tag)
    }
  }

  let resizeTagMarkupInternal = (dziViewer, startXDelta, startYDelta, tag) => {
    // create points and rects here to reuse instead of creating a new one for each mouse move
    let startScreenPoint = openSeaDragonService.point(startXDelta, startYDelta)
    let endScreenPoint = openSeaDragonService.point(0, 0)
    let overlayRect = openSeaDragonService.rect(tag.markup.left, tag.markup.top, tag.markup.width, tag.markup.height)
    let startViewportPoint = dziViewer.viewport.pointFromPixel(startScreenPoint)

    document.body.onmousemove = function (evt) {
      evt = evt || window.event
      evt.stopPropagation()
      endScreenPoint.x = evt.pageX !== undefined ? evt.pageX : evt.clientX
      endScreenPoint.y = evt.pageY !== undefined ? evt.pageY : evt.clientY

      let endViewportPoint = dziViewer.viewport.pointFromPixel(endScreenPoint)
      let amountMovedAlongX = endViewportPoint.x - startViewportPoint.x
      let amountMovedAlongY = endViewportPoint.y - startViewportPoint.y

      startViewportPoint = endViewportPoint

      tag.markup.width += amountMovedAlongX
      tag.markup.height += amountMovedAlongY

      updatePanAndZoomForTag(dziViewer, tag)

      overlayRect.x = tag.markup.left
      overlayRect.y = tag.markup.top
      overlayRect.width = tag.markup.width
      overlayRect.height = tag.markup.height

      dziViewer.updateOverlay(tag.markup.id, overlayRect)
    }

    document.body.onmouseup = function (evt) {
      evt = evt || window.event
      evt.stopPropagation()

      document.body.onmousemove = document.body.onmouseup = null
      smartvidApi.updateTagForAsset(tag)
    }
  }

  let updatePanAndZoomForTag = (dziViewer, tag) => {
    let centerPoint = dziViewer.viewport.getCenter()
    tag.markup.panX = centerPoint.x
    tag.markup.panY = centerPoint.y
    tag.markup.zoomLevel = dziViewer.viewport.getZoom()
    tag.markup.imageZoomLevel = dziViewer.viewport.viewportToImageZoom(tag.markup.zoomLevel)
  }

  // service's public methods

  let service = {

    isMarkupEditable (tags) {
      return tags && tags.length === 1 && tags[0].canUpdate === true
    },

    clearTagsMarkups (dziViewer, scope) {
      openSeaDragonService.clearAllOverlays(dziViewer)
      scope.tagInstancesWithMarkups = []
      // for some reason elements are left in the DOM if not cleared explicitly
      $('[id^=' + TAG_MARKUP_OVERLAY_ID_PREFIX + ']').remove()
    },

    showTagsMarkups (dziViewer, tags, scope) {
      // noinspection ES6ConvertVarToLetConst
      var idCount = 0
      let tagsWithMarkups = _.map(_.filter(tags, (t) => {
        return t.hasMarkup()
      }), (t) => {
        t.markup.id = TAG_MARKUP_OVERLAY_ID_PREFIX + (++idCount)
        return t
      })

      if (tagsWithMarkups.length > 0) {
        this.clearTagsMarkups(dziViewer, scope)
        $timeout(() => {
          scope.tagInstancesWithMarkups = tagsWithMarkups
          $timeout(() => {
            openSeaDragonService.displayOverlaysForMarkups(dziViewer, _.map(scope.tagInstancesWithMarkups, (t) => {
              return t.markup
            }))
          })
        })
      } else if (scope.tagInstancesWithMarkups.length > 0) {
        this.clearTagsMarkups(dziViewer, scope)
      }
    },

    dragToPositionTagMarkup (dziViewer, tag, evt, scope) {
      evt = evt || window.event
      evt.stopPropagation()
      if (scope.asset.isReadOnly || !scope.isEditable()) {
        return false
      }

      let startXDelta = evt.pageX !== undefined ? evt.pageX : evt.clientX
      let startYDelta = evt.pageY !== undefined ? evt.pageY : evt.clientY

      dragToPositionTagMarkupInternal(dziViewer, startXDelta, startYDelta, tag)
    },

    resizeTagMarkup (dziViewer, tag, evt, scope) {
      evt = evt || window.event
      evt.stopPropagation()
      if (!scope.isEditable()) {
        return
      }
      let startXDelta = evt.pageX !== undefined ? evt.pageX : evt.clientX
      let startYDelta = evt.pageY !== undefined ? evt.pageY : evt.clientY

      resizeTagMarkupInternal(dziViewer, startXDelta, startYDelta, tag)
    },

    rotateTagMarkup (dziViewer, tag, evt, scope) {
      evt.stopPropagation()
      if (scope.isEditable()) {
        tag.markup.rotation = (tag.markup.rotation || 0) - 45
        updatePanAndZoomForTag(dziViewer, tag)
        smartvidApi.updateTagForAsset(tag)
      }
    },

    removeTagMarkup (dziViewer, tag, evt, scope) {
      evt.stopPropagation()
      if (scope.isEditable()) {
        let id = tag.markup.id
        tag.markup = undefined
        scope.tagInstancesWithMarkups.pop(tag)
        openSeaDragonService.removeOverlay(dziViewer, id)
        smartvidApi.updateTagForAsset(tag)
      }
    }
  }

  return service
})
