/* global angular */

angular.module('smartvid').constant('QuickSearchType', {
  LAST_7_DAYS: {
    name: 'LAST_7_DAYS',
    id: 3
  },
  LAST_30_DAYS: {
    name: 'LAST_30_DAYS',
    id: 4
  },
  SAFETY: {
    name: 'SAFETY',
    id: 1
  },
  PPE: {
    name: 'PPE',
    id: 2
  },
  MY_UPLOADS: {
    name: 'MY_UPLOADS',
    id: 5
  }
})
