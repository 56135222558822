/* global angular, $ */

angular.module('smartvid').directive('pageKeyboardScroll', function ($timeout) {
  return {
    restrict: 'A',
    replace: true,
    link: function (scope, element) {
      let listener = $(window).on('keydown', function (e) {
        if (e.keyCode === 34 || e.keyCode === 33) {
          // If no scrollable div or input field is in focus, then focus on the element's div
          // to ensure keyboard scrolling
          if (!($('*:focus').get(0))) {
            $(element).attr('tabindex', -1).focus()
          }
        }
      })
      scope.$on('$destroy', function () {
        $(window).off('keydown', listener)
      })
    }
  }
})
