<div class="mdc-layout-grid__inner">
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PEOPLE_IN_GROUPS' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.peopleInGroupsOverTime.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
      [toggleMenuGroups]="peopleInGroupsOverTimeChartMenu"
      (toggleMenuItemSelected)="peopleInGroupsOverTimeChartMenuSelected($event)"
    >
      <sv-insights-line-chart
        [chartData]="projectPeopleInGroupsOverTimeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-line-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PPE_COMPLIANCE_OVERTIME' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.ppeComplianceTrendOverTime.title' | translate }}"
      subTitle="{{ 'dashboard.cards.ppeComplianceTrendOverTime.subTitle' | translate }}"
    >
      <sv-insights-line-chart
        [chartData]="projectPpeComplianceOverTimeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-line-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PPE_COMPLIANCE_PERCENT' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.overallPpeCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
      <div *ngIf="projectTotals" class="card-gauge-container">
        <sv-insights-safety-gauge-chart [currentValue]="projectTotals.ppeCompliancePercent"></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'GLASSES_COMPLIANCE' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.glassesCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
      <div *ngIf="projectTotals" class="card-gauge-container">
        <sv-insights-safety-gauge-chart [currentValue]="projectTotals.glassesCompliance"></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'GLOVES_COMPLIANCE' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.glovesCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
      <div *ngIf="projectTotals" class="card-gauge-container">
        <sv-insights-safety-gauge-chart [currentValue]="projectTotals.glovesCompliance"></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'HARDHAT_COMPLIANCE' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.hardHatCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
      <div *ngIf="projectTotals" class="card-gauge-container">
        <sv-insights-safety-gauge-chart [currentValue]="projectTotals.hardHatCompliance"></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'HIVIZ_COMPLIANCE' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.hiVizCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
      <div *ngIf="projectTotals" class="card-gauge-container">
        <sv-insights-safety-gauge-chart [currentValue]="projectTotals.highVizCompliance"></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'WORK_AT_HEIGHTS_OVERTIME' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.workAtHeightTrendOverTime.title' | translate }}"
      subTitle="{{ 'dashboard.cards.workAtHeightTrendOverTime.subTitle' | translate }}"
    >
      <sv-insights-column-chart
        *ngIf="!chartHasAllNullValues(projectWorkAtHeightsOverTimeData?.chartData)"
        [chartData]="convertNullValuesToZero(projectWorkAtHeightsOverTimeData?.chartData)"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
      <div class="text-card-content dashboard-single-value-card" *ngIf="chartHasAllNullValues(projectWorkAtHeightsOverTimeData?.chartData)">
        <div class="text-card-main-text">
          {{
          ('dashboard.valueNotAvailable' | translate)
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'LADDERS_TO_LIFTS_RATIO' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.laddersToLiftsRatio.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
      <div class="text-card-content" *ngIf="projectTotals">
        <div class="text-card-main-text">
          {{
            isNA(projectTotals.laddersToLiftsRatio)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.laddersToLiftsRatio | number: '.1-1') + ' to 1'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ laddersToLiftsRatioPercentChangeClassName }}">
          {{ getSign(projectTotals.laddersToLiftsRatioPercentChange)
          }}{{
            isNA(projectTotals.laddersToLiftsRatio)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.laddersToLiftsRatioPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'WORK_AT_HEIGHT_PER_X_ASSETS' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.workAtHeightDistribution.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectWorkAtHeightsPerXAssetsData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'LADDERS_PER_X_ASSETS' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.laddersDistribution.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectLaddersPerXAssetsData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'LIFTS_PER_X_ASSETS' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.liftsDistribution.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectLiftsPerXAssetsData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'HOUSEKEEPING_AND_STANDING_WATER' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.housekeepingAndStandingWater.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectHousekeepingAndStandingWaterData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'SLIP_AND_TRIP_OVERTIME' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.slipAndTripTrendOverTime.title' | translate }}"
      subTitle="{{ 'dashboard.cards.slipAndTripTrendOverTime.subTitle' | translate }}"
    >
      <sv-insights-column-chart
        *ngIf="!chartHasAllNullValues(projectSlipAndTripTrendOverTimeData?.chartData)"
        [chartData]="projectSlipAndTripTrendOverTimeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
      <div class="text-card-content dashboard-single-value-card" *ngIf="chartHasAllNullValues(projectSlipAndTripTrendOverTimeData?.chartData)">
        <div class="text-card-main-text">
          {{
          ('dashboard.valueNotAvailable' | translate)
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PEOPLE_PER_X_CONSTRUCTION' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.peoplePerXConstruction.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
      <div class="text-card-content" *ngIf="projectTotals">
        <div class="text-card-main-text">
          {{
            isNA(projectTotals.peoplePerXConstruction)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.peoplePerXConstruction | number: '.1-1')
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ peoplePerXConstructionPercentChangeClassName }}">
          {{ getSign(projectTotals.peoplePerXConstructionPercentChange)
          }}{{
            isNA(projectTotals.peoplePerXConstruction)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.peoplePerXConstructionPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
</div>
