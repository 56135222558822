export class TradePartner {
  public id: string
  public name: string
  public description: string
  public trade: string
  public addressLine1: string
  public addressLine2: string
  public city: string
  public postalCode: string
  public state: string
  public country: string
  public telephone: string
  public companyWebsite: string
  public sourceSystemId: string
  public taxId: string
  public email: string
}
