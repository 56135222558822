import * as Q from 'q'
import { ChartData } from 'modules/insights/dashboard/charts/models/base-chart.type'
import { Subscription } from 'rxjs'
import { forceUnsubscribe } from 'modules/insights/dashboard/utils/dashboard-utils'

export enum DashboardDateRangeTimeUnit {
  ALL_TIME = 'ALL_TIME',
  LAST_1 = 'LAST_1',
  LAST_7 = 'LAST_7',
  LAST_30 = 'LAST_30',
  LAST_60 = 'LAST_60',
  LAST_90 = 'LAST_90',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  NA = 'NA',
}

export const TIME_UNIT_TO_DAYS = { LAST_1: 1, LAST_7: 7, LAST_30: 30, LAST_60: 60, LAST_90: 90 }

export const DATA_CANNOT_BE_CALCULATED = -1.0
export const NO_DATA_AVAILABLE = -2.0

export enum DashboardDataObjectType {
  PROJECT_ASSETS_DATE_RANGE_TOTALS = 'PROJECT_ASSETS_DATE_RANGE_TOTALS',
  PROJECT_ASSETS_DATE_RANGE_ACTIVITY_BY_MEMBERS = 'PROJECT_ASSETS_DATE_RANGE_ACTIVITY_BY_MEMBERS',
  PROJECT_ASSETS_DATE_RANGE_UPLOADS_BY_SOURCE = 'PROJECT_ASSETS_DATE_RANGE_UPLOADS_BY_SOURCE',
  PROJECT_ASSETS_DATE_RANGE_UPLOADERS_OVER_TIME = 'PROJECT_ASSETS_DATE_RANGE_UPLOADERS_OVER_TIME',
  PROJECT_ASSETS_DATE_RANGE_UPLOADS_BY_FILE_TYPE = 'PROJECT_ASSETS_DATE_RANGE_UPLOADS_BY_FILE_TYPE',
  PROJECT_ASSETS_DATE_RANGE_TAGS_BY_TYPE = 'PROJECT_ASSETS_DATE_RANGE_TAGS_BY_TYPE',
  PROJECT_ASSETS_DATE_RANGE_PPE_COMPLIANCE_OVER_TIME = 'PROJECT_ASSETS_DATE_RANGE_PPE_COMPLIANCE_OVER_TIME',
  PROJECT_ASSETS_DATE_RANGE_SLIP_AND_TRIP_OVER_TIME = 'PROJECT_ASSETS_DATE_RANGE_SLIP_AND_TRIP_OVER_TIME',
  PROJECT_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_OVER_TIME = 'PROJECT_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_OVER_TIME',
  PROJECT_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_PER_X_ASSETS = 'PROJECT_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_PER_X_ASSETS',
  PROJECT_ASSETS_DATE_RANGE_LADDERS_PER_X_ASSETS = 'PROJECT_ASSETS_DATE_RANGE_LADDERS_PER_X_ASSETS',
  PROJECT_ASSETS_DATE_RANGE_LIFTS_PER_X_ASSETS = 'PROJECT_ASSETS_DATE_RANGE_LIFTS_PER_X_ASSETS',
  PROJECT_ASSETS_DATE_RANGE_HOUSEKEEPING_AND_STANDING_WATER = 'PROJECT_ASSETS_DATE_RANGE_HOUSEKEEPING_AND_STANDING_WATER',
  PROJECT_ASSETS_DATE_RANGE_CONSTRUCTION_ACTIVITY = 'PROJECT_ASSETS_DATE_RANGE_CONSTRUCTION_ACTIVITY',
  PROJECT_ASSETS_DATE_RANGE_PHOTO_DESCRIPTION = 'PROJECT_ASSETS_DATE_RANGE_PHOTO_DESCRIPTION',
  PROJECT_ASSETS_DATE_RANGE_PEOPLE_IN_GROUPS = 'PROJECT_ASSETS_DATE_RANGE_PEOPLE_IN_GROUPS',
  PROJECT_ASSETS_DATE_RANGE_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK = 'PROJECT_ASSETS_DATE_RANGE_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK',

  PROJECT_GROUP_ASSETS_DATE_RANGE_TOTALS = 'PROJECT_GROUP_ASSETS_DATE_RANGE_TOTALS',
  PROJECT_GROUP_ASSETS_DATE_RANGE_ACTIVITY_BY_PROJECT = 'PROJECT_GROUP_ASSETS_DATE_RANGE_ACTIVITY_BY_PROJECT',
  PROJECT_GROUP_ASSETS_DATE_RANGE_UPLOADS_BY_SOURCE = 'PROJECT_GROUP_ASSETS_DATE_RANGE_UPLOADS_BY_SOURCE',
  PROJECT_GROUP_ASSETS_DATE_RANGE_UPLOADERS_OVER_TIME = 'PROJECT_GROUP_ASSETS_DATE_RANGE_UPLOADERS_OVER_TIME',
  PROJECT_GROUP_ASSETS_DATE_RANGE_CONSTRUCTION_ACTIVITY = 'PROJECT_GROUP_ASSETS_DATE_RANGE_CONSTRUCTION_ACTIVITY',
  PROJECT_GROUP_ASSETS_DATE_RANGE_PHOTO_DESCRIPTION = 'PROJECT_GROUP_ASSETS_DATE_RANGE_PHOTO_DESCRIPTION',
  PROJECT_GROUP_ASSETS_DATE_RANGE_UPLOADS_BY_FILE_TYPE = 'PROJECT_GROUP_ASSETS_DATE_RANGE_UPLOADS_BY_FILE_TYPE',
  PROJECT_GROUP_ASSETS_DATE_RANGE_TAGS_BY_TYPE = 'PROJECT_GROUP_ASSETS_DATE_RANGE_TAGS_BY_TYPE',
  PROJECT_GROUP_ASSETS_DATE_RANGE_SAFETY_BY_PROJECT = 'PROJECT_GROUP_ASSETS_DATE_RANGE_SAFETY_BY_PROJECT',
  PROJECT_GROUP_ASSETS_DATE_RANGE_PEOPLE_IN_GROUPS = 'PROJECT_GROUP_ASSETS_DATE_RANGE_PEOPLE_IN_GROUPS',
  PROJECT_GROUP_ASSETS_DATE_RANGE_PPE_COMPLIANCE_OVER_TIME = 'PROJECT_GROUP_ASSETS_DATE_RANGE_PPE_COMPLIANCE_OVER_TIME',
  PROJECT_GROUP_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_OVER_TIME = 'PROJECT_GROUP_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_OVER_TIME',
  PROJECT_GROUP_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_PER_X_ASSETS = 'PROJECT_GROUP_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_PER_X_ASSETS',
  PROJECT_GROUP_ASSETS_DATE_RANGE_LADDERS_PER_X_ASSETS = 'PROJECT_GROUP_ASSETS_DATE_RANGE_LADDERS_PER_X_ASSETS',
  PROJECT_GROUP_ASSETS_DATE_RANGE_LIFTS_PER_X_ASSETS = 'PROJECT_GROUP_ASSETS_DATE_RANGE_LIFTS_PER_X_ASSETS',
  PROJECT_GROUP_ASSETS_DATE_RANGE_HOUSEKEEPING_AND_STANDING_WATER = 'PROJECT_GROUP_ASSETS_DATE_RANGE_HOUSEKEEPING_AND_STANDING_WATER',
  PROJECT_GROUP_ASSETS_DATE_RANGE_SLIP_AND_TRIP_OVER_TIME = 'PROJECT_GROUP_ASSETS_DATE_RANGE_SLIP_AND_TRIP_OVER_TIME',
  PROJECT_GROUP_ASSETS_DATE_RANGE_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK = 'PROJECT_GROUP_ASSETS_DATE_RANGE_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK',

  ORGANIZATION_ASSETS_DATE_RANGE_TOTALS = 'ORGANIZATION_ASSETS_DATE_RANGE_TOTALS',
  ORGANIZATION_ASSETS_DATE_RANGE_ACTIVITY_BY_PROJECT_OR_GROUP = 'ORGANIZATION_ASSETS_DATE_RANGE_ACTIVITY_BY_PROJECT_OR_GROUP',
  ORGANIZATION_ASSETS_DATE_RANGE_UPLOADS_BY_SOURCE = 'ORGANIZATION_ASSETS_DATE_RANGE_UPLOADS_BY_SOURCE',
  ORGANIZATION_ASSETS_DATE_RANGE_UPLOADERS_OVER_TIME = 'ORGANIZATION_ASSETS_DATE_RANGE_UPLOADERS_OVER_TIME',
  ORGANIZATION_ASSETS_DATE_RANGE_CONSTRUCTION_ACTIVITY = 'ORGANIZATION_ASSETS_DATE_RANGE_CONSTRUCTION_ACTIVITY',
  ORGANIZATION_ASSETS_DATE_RANGE_PHOTO_DESCRIPTION = 'ORGANIZATION_ASSETS_DATE_RANGE_PHOTO_DESCRIPTION',
  ORGANIZATION_ASSETS_DATE_RANGE_UPLOADS_BY_FILE_TYPE = 'ORGANIZATION_ASSETS_DATE_RANGE_UPLOADS_BY_FILE_TYPE',
  ORGANIZATION_ASSETS_DATE_RANGE_TAGS_BY_TYPE = 'ORGANIZATION_ASSETS_DATE_RANGE_TAGS_BY_TYPE',
  ORGANIZATION_ASSETS_DATE_RANGE_SAFETY_BY_PROJECT_OR_GROUP = 'ORGANIZATION_ASSETS_DATE_RANGE_SAFETY_BY_PROJECT_OR_GROUP',
  ORGANIZATION_ASSETS_DATE_RANGE_PEOPLE_IN_GROUPS = 'ORGANIZATION_ASSETS_DATE_RANGE_PEOPLE_IN_GROUPS',
  ORGANIZATION_ASSETS_DATE_RANGE_PPE_COMPLIANCE_OVER_TIME = 'ORGANIZATION_ASSETS_DATE_RANGE_PPE_COMPLIANCE_OVER_TIME',
  ORGANIZATION_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_OVER_TIME = 'ORGANIZATION_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_OVER_TIME',
  ORGANIZATION_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_PER_X_ASSETS = 'ORGANIZATION_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_PER_X_ASSETS',
  ORGANIZATION_ASSETS_DATE_RANGE_LADDERS_PER_X_ASSETS = 'ORGANIZATION_ASSETS_DATE_RANGE_LADDERS_PER_X_ASSETS',
  ORGANIZATION_ASSETS_DATE_RANGE_LIFTS_PER_X_ASSETS = 'ORGANIZATION_ASSETS_DATE_RANGE_LIFTS_PER_X_ASSETS',
  ORGANIZATION_ASSETS_DATE_RANGE_HOUSEKEEPING_AND_STANDING_WATER = 'ORGANIZATION_ASSETS_DATE_RANGE_HOUSEKEEPING_AND_STANDING_WATER',
  ORGANIZATION_ASSETS_DATE_RANGE_SLIP_AND_TRIP_OVER_TIME = 'ORGANIZATION_ASSETS_DATE_RANGE_SLIP_AND_TRIP_OVER_TIME',
  ORGANIZATION_ASSETS_DATE_RANGE_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK = 'ORGANIZATION_ASSETS_DATE_RANGE_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK',

  OBSERVATION_DATE_RANGE_TOTALS = 'OBSERVATION_DATE_RANGE_TOTALS',
  OBSERVATION_DATE_RANGE_ACTIVITY_BY_MEMBERS = 'OBSERVATION_DATE_RANGE_ACTIVITY_BY_MEMBERS',
  OBSERVATION_DATE_RANGE_ACTIVITY_TOTALS = 'OBSERVATION_DATE_RANGE_ACTIVITY_TOTALS',
  OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME = 'OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME',
  OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_TYPE = 'OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_TYPE',
  OBSERVATION_DATE_RANGE_GREAT_CATCH_COUNT_BY_TYPE = 'OBSERVATION_DATE_RANGE_GREAT_CATCH_COUNT_BY_TYPE',
  OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_IDENTIFICATION_METHOD = 'OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_IDENTIFICATION_METHOD',
  OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_CREATOR = 'OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_CREATOR',
  OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME_BY_RISK_BAND = 'OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME_BY_RISK_BAND',
  OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_HAZARD_CATEGORY = 'OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_HAZARD_CATEGORY',
  OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_SCORE = 'OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_SCORE',
  OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_BAND = 'OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_BAND',
  OBSERVATION_DATE_RANGE_SAFETY_TOTALS = 'OBSERVATION_DATE_RANGE_SAFETY_TOTALS',
  OBSERVATION_DATE_RANGE_OBSERVATION_SCORES_OVER_TIME = 'OBSERVATION_DATE_RANGE_OBSERVATION_SCORES_OVER_TIME',

  OBSERVATION_DATE_RANGE_OVERTIME_BY_STATUS = 'OBSERVATION_DATE_RANGE_OVERTIME_BY_STATUS',
  OBSERVATION_DATE_RANGE_COUNT_BY_OPEN_VS_CLOSED = 'OBSERVATION_DATE_RANGE_COUNT_BY_OPEN_VS_CLOSED',
  OBSERVATION_DATE_RANGE_OPEN_BY_STATUS = 'OBSERVATION_DATE_RANGE_OPEN_BY_STATUS',
  OBSERVATION_DATE_RANGE_AVG_DAYS_OPEN_BY_RISK_BAND = 'OBSERVATION_DATE_RANGE_AVG_DAYS_OPEN_BY_RISK_BAND',
  OBSERVATION_DATE_RANGE_WORKFLOW_TOTALS = 'OBSERVATION_DATE_RANGE_WORKFLOW_TOTALS',

  ORGANIZATION_OBSERVATION_DATE_RANGE_TOTALS = 'ORGANIZATION_OBSERVATION_DATE_RANGE_TOTALS',
  ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME = 'ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME',
  ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_TYPE = 'ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_TYPE',
  ORGANIZATION_OBSERVATION_DATE_RANGE_GREAT_CATCH_COUNT_BY_TYPE = 'ORGANIZATION_OBSERVATION_DATE_RANGE_GREAT_CATCH_COUNT_BY_TYPE',
  ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_IDENTIFICATION_METHOD = 'ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_IDENTIFICATION_METHOD',
  ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_CREATOR = 'ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_CREATOR',
  ORGANIZATION_OBSERVATION_DATE_RANGE_ACTIVITY = 'ORGANIZATION_OBSERVATION_DATE_RANGE_ACTIVITY',

  ORGANIZATION_OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_BAND = 'ORGANIZATION_OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_BAND',
  ORGANIZATION_OBSERVATION_DATE_RANGE_SAFETY_BY_GROUP_PROJECT = 'ORGANIZATION_OBSERVATION_DATE_RANGE_SAFETY_BY_GROUP_PROJECT',
  ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATION_SCORES_OVER_TIME = 'ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATION_SCORES_OVER_TIME',
  ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_HAZARD_CATEGORY = 'ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_HAZARD_CATEGORY',
  ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME_BY_RISK_BAND = 'ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME_BY_RISK_BAND',
  ORGANIZATION_OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_SCORE = 'ORGANIZATION_OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_SCORE',

  ORGANIZATION_OBSERVATION_DATE_RANGE_OVERTIME_BY_STATUS = 'ORGANIZATION_OBSERVATION_DATE_RANGE_OVERTIME_BY_STATUS',
  ORGANIZATION_OBSERVATION_DATE_RANGE_COUNT_BY_OPEN_VS_CLOSED = 'ORGANIZATION_OBSERVATION_DATE_RANGE_COUNT_BY_OPEN_VS_CLOSED',
  ORGANIZATION_OBSERVATION_DATE_RANGE_AVG_DAYS_OPEN_BY_RISK_BAND = 'ORGANIZATION_OBSERVATION_DATE_RANGE_AVG_DAYS_OPEN_BY_RISK_BAND',
  ORGANIZATION_OBSERVATION_DATE_RANGE_OPEN_BY_STATUS = 'ORGANIZATION_OBSERVATION_DATE_RANGE_OPEN_BY_STATUS',
  ORGANIZATION_OBSERVATION_DATE_RANGE_WORKFLOW_BY_GROUP_PROJECT = 'ORGANIZATION_OBSERVATION_DATE_RANGE_WORKFLOW_BY_GROUP_PROJECT',

  PROJECT_GROUP_OBSERVATION_DATE_RANGE_TOTALS = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_TOTALS',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_TYPE = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_TYPE',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_IDENTIFICATION_METHOD = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_IDENTIFICATION_METHOD',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_CREATOR = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_CREATOR',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_GREAT_CATCH_COUNT_BY_TYPE = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_GREAT_CATCH_COUNT_BY_TYPE',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_BAND = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_BAND',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATION_SCORES_OVER_TIME = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATION_SCORES_OVER_TIME',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_HAZARD_CATEGORY = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_HAZARD_CATEGORY',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME_BY_RISK_BAND = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME_BY_RISK_BAND',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_SCORE = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_SCORE',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_OVERTIME_BY_STATUS = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_OVERTIME_BY_STATUS',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_COUNT_BY_OPEN_VS_CLOSED = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_COUNT_BY_OPEN_VS_CLOSED',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_OPEN_BY_STATUS = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_OPEN_BY_STATUS',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_AVG_DAYS_OPEN_BY_RISK_BAND = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_AVG_DAYS_OPEN_BY_RISK_BAND',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_ACTIVITY = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_ACTIVITY',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_SAFETY_BY_PROJECT = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_SAFETY_BY_PROJECT',
  PROJECT_GROUP_OBSERVATION_DATE_RANGE_WORKFLOW_BY_PROJECT = 'PROJECT_GROUP_OBSERVATION_DATE_RANGE_WORKFLOW_BY_PROJECT',
}

export enum DashboardType {
  PROJECT_ASSETS_DASHBOARD = 'PROJECT_ASSETS_DASHBOARD',
  ORGANIZATION_ASSETS_DASHBOARD = 'ORGANIZATION_ASSETS_DASHBOARD',
  PROJECT_GROUP_ASSETS_DASHBOARD = 'PROJECT_GROUP_ASSETS_DASHBOARD',
  PROJECT_OBSERVATIONS_DASHBOARD = 'PROJECT_OBSERVATIONS_DASHBOARD',
  ORGANIZATION_OBSERVATIONS_DASHBOARD = 'ORGANIZATION_OBSERVATIONS_DASHBOARD',
  PROJECT_GROUP_OBSERVATIONS_DASHBOARD = 'PROJECT_GROUP_OBSERVATIONS_DASHBOARD',
}

export enum OrganizationAssetsDashboardTabs {
  INSIGHTS_TAB,
  PROJECTS_TAB,
  REPORTS_TAB,
}

export enum ProjectGroupAssetsDashboardTabs {
  INSIGHTS_TAB,
  PROJECTS_TAB,
}

export enum DashboardCardId {
  UPLOADED_TOTAL = 'UPLOADED_TOTAL',
  UPLOADERS_TOTAL = 'UPLOADERS_TOTAL',
  TAGS_TOTAL = 'TAGS_TOTAL',
  PROJECT_SCORE = 'PROJECT_SCORE',
  PPE_COMPLIANCE_OVERALL = 'PPE_COMPLIANCE_OVERALL',
  WORK_AT_HEIGHTS = 'WORK_AT_HEIGHTS',
  SLIP_AND_TRIP = 'SLIP_AND_TRIP',
  ACTIVITY_BY_USER = 'ACTIVITY_BY_USER',
  CONSTRUCTION_TO_ALL_RATIO = 'CONSTRUCTION_TO_ALL_RATIO',
  UPLOADS_OVERTIME_BY_SOURCE = 'UPLOADS_OVERTIME_BY_SOURCE',
  UPLOADERS_OVERTIME = 'UPLOADERS_OVERTIME',
  UPLOADS_BY_FILE_TYPE = 'UPLOADS_BY_FILE_TYPE',
  TAGS_BY_TYPE = 'TAGS_BY_TYPE',
  PPE_COMPLIANCE_OVERTIME = 'PPE_COMPLIANCE_OVERTIME',
  PPE_COMPLIANCE_PERCENT = 'PPE_COMPLIANCE_PERCENT',
  GLASSES_COMPLIANCE = 'GLASSES_COMPLIANCE',
  GLOVES_COMPLIANCE = 'GLOVES_COMPLIANCE',
  HARDHAT_COMPLIANCE = 'HARDHAT_COMPLIANCE',
  HIVIZ_COMPLIANCE = 'HIVIZ_COMPLIANCE',
  WORK_AT_HEIGHTS_OVERTIME = 'WORK_AT_HEIGHTS_OVERTIME',
  LADDERS_TO_LIFTS_RATIO = 'LADDERS_TO_LIFTS_RATIO',
  WORK_AT_HEIGHT_PER_X_ASSETS = 'WORK_AT_HEIGHT_PER_X_ASSETS',
  LADDERS_PER_X_ASSETS = 'LADDERS_PER_X_ASSETS',
  LIFTS_PER_X_ASSETS = 'LIFTS_PER_X_ASSETS',
  HOUSEKEEPING_AND_STANDING_WATER = 'HOUSEKEEPING_AND_STANDING_WATER',
  SLIP_AND_TRIP_OVERTIME = 'SLIP_AND_TRIP_OVERTIME',
  CONSTRUCTION_ACTIVITY = 'CONSTRUCTION_ACTIVITY',
  PHOTO_DESCRIPTION = 'PHOTO_DESCRIPTION',
  PEOPLE_IN_GROUPS = 'PEOPLE_IN_GROUPS',
  PEOPLE_PER_X_CONSTRUCTION = 'PEOPLE_PER_X_CONSTRUCTION',
  AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK = 'AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK',
  AVG_PHOTOS_CREATED_PER_DAY = 'AVG_PHOTOS_CREATED_PER_DAY',
  AVG_TIME_BETWEEN_PHOTO_CREATION_AND_UPLOAD = 'AVG_TIME_BETWEEN_PHOTO_CREATION_AND_UPLOAD',
  PERSON_COVERAGE_PERCENT = 'PERSON_COVERAGE_PERCENT',
  PHOTOS_WITHOUT_CREATED_DATE = 'PHOTOS_WITHOUT_CREATED_DATE',
  CONSTRUCTION_PHOTOS_WITHOUT_CREATED_DATE = 'CONSTRUCTION_PHOTOS_WITHOUT_CREATED_DATE',

  // PROJECT_GROUP_ASSETS_DASHBOARD
  // SUMMARY
  PROJECT_GROUP_ASSETS_PROJECT_SCORE_AVG = 'PROJECT_GROUP_ASSETS_PROJECT_SCORE_AVG',
  PROJECT_GROUP_ASSETS_UPLOADED_TOTAL = 'PROJECT_GROUP_ASSETS_UPLOADED_TOTAL',
  PROJECT_GROUP_ASSETS_UPLOADERS_TOTAL = 'PROJECT_GROUP_ASSETS_UPLOADERS_TOTAL',
  PROJECT_GROUP_ASSETS_PPE_COMPLIANCE_AVG = 'PROJECT_GROUP_ASSETS_PPE_COMPLIANCE_AVG',
  PROJECT_GROUP_ASSETS_WORK_AT_HEIGHTS_AVG = 'PROJECT_GROUP_ASSETS_WORK_AT_HEIGHTS_AVG',
  PROJECT_GROUP_ASSETS_SLIP_AND_TRIP_AVG = 'PROJECT_GROUP_ASSETS_SLIP_AND_TRIP_AVG',
  // ACTIVITY
  PROJECT_GROUP_ASSETS_ACTIVITY_BY_PROJECT = 'PROJECT_GROUP_ASSETS_ACTIVITY_BY_PROJECT',
  PROJECT_GROUP_ASSETS_UPLOADS_OVERTIME_BY_SOURCE = 'PROJECT_GROUP_ASSETS_UPLOADS_OVERTIME_BY_SOURCE',
  PROJECT_GROUP_ASSETS_UPLOADERS_OVERTIME = 'PROJECT_GROUP_ASSETS_UPLOADERS_OVERTIME',
  PROJECT_GROUP_ASSETS_CONSTRUCTION_ACTIVITY = 'PROJECT_GROUP_ASSETS_CONSTRUCTION_ACTIVITY',
  PROJECT_GROUP_ASSETS_PHOTO_DESCRIPTION = 'PROJECT_GROUP_ASSETS_PHOTO_DESCRIPTION',
  PROJECT_GROUP_ASSETS_UPLOADS_BY_FILE_TYPE = 'PROJECT_GROUP_ASSETS_UPLOADS_BY_FILE_TYPE',
  PROJECT_GROUP_ASSETS_TAGS_BY_TYPE = 'PROJECT_GROUP_ASSETS_TAGS_BY_TYPE',
  // SAFETY
  PROJECT_GROUP_ASSETS_SAFETY_BY_PROJECT = 'PROJECT_GROUP_ASSETS_SAFETY_BY_PROJECT',
  PROJECT_GROUP_ASSETS_PEOPLE_IN_GROUPS = 'PROJECT_GROUP_ASSETS_PEOPLE_IN_GROUPS',
  PROJECT_GROUP_ASSETS_PPE_COMPLIANCE_TREND_AVG = 'PROJECT_GROUP_ASSETS_PPE_COMPLIANCE_TREND_AVG',
  PROJECT_GROUP_ASSETS_PPE_COMPLIANCE_PERCENT_AVG = 'PROJECT_GROUP_ASSETS_PPE_COMPLIANCE_PERCENT_AVG',
  PROJECT_GROUP_ASSETS_GLASSES_COMPLIANCE_PERCENT_AVG = 'PROJECT_GROUP_ASSETS_GLASSES_COMPLIANCE_PERCENT_AVG',
  PROJECT_GROUP_ASSETS_GLOVES_COMPLIANCE_PERCENT_AVG = 'PROJECT_GROUP_ASSETS_GLOVES_COMPLIANCE_PERCENT_AVG',
  PROJECT_GROUP_ASSETS_HARDHAT_COMPLIANCE_PERCENT_AVG = 'PROJECT_GROUP_ASSETS_HARDHAT_COMPLIANCE_PERCENT_AVG',
  PROJECT_GROUP_ASSETS_HIVIZ_COMPLIANCE_PERCENT_AVG = 'PROJECT_GROUP_ASSETS_HIVIZ_COMPLIANCE_PERCENT_AVG',
  PROJECT_GROUP_ASSETS_WORK_AT_HEIGHTS_TREND_AVG = 'PROJECT_GROUP_ASSETS_WORK_AT_HEIGHTS_TREND_AVG',
  PROJECT_GROUP_ASSETS_LADDERS_TO_LIFTS_RATIO_AVG = 'PROJECT_GROUP_ASSETS_LADDERS_TO_LIFTS_RATIO_AVG',
  PROJECT_GROUP_ASSETS_WORK_AT_HEIGHT_PER_X_ASSETS_AVG = 'PROJECT_GROUP_ASSETS_WORK_AT_HEIGHT_PER_X_ASSETS_AVG',
  PROJECT_GROUP_ASSETS_LADDERS_PER_X_ASSETS_AVG = 'PROJECT_GROUP_ASSETS_LADDERS_PER_X_ASSETS_AVG',
  PROJECT_GROUP_ASSETS_LIFTS_PER_X_ASSETS_AVG = 'PROJECT_GROUP_ASSETS_LIFTS_PER_X_ASSETS_AVG',
  PROJECT_GROUP_ASSETS_HOUSEKEEPING_AND_STANDING_WATER_AVG = 'PROJECT_GROUP_ASSETS_HOUSEKEEPING_AND_STANDING_WATER_AVG',
  PROJECT_GROUP_ASSETS_SLIP_AND_TRIP_TREND_AVG = 'PROJECT_GROUP_ASSETS_SLIP_AND_TRIP_TREND_AVG',
  PROJECT_GROUP_ASSETS_PEOPLE_PER_X_CONSTRUCTION = 'PROJECT_GROUP_ASSETS_PEOPLE_PER_X_CONSTRUCTION',
  // IMAGE_HEALTH
  PROJECT_GROUP_ASSETS_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK = 'PROJECT_GROUP_ASSETS_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK',
  PROJECT_GROUP_ASSETS_AVG_PHOTOS_CREATED_PER_DAY = 'PROJECT_GROUP_ASSETS_AVG_PHOTOS_CREATED_PER_DAY',
  PROJECT_GROUP_ASSETS_AVG_TIME_BETWEEN_PHOTO_CREATION_AND_UPLOAD = 'PROJECT_GROUP_ASSETS_AVG_TIME_BETWEEN_PHOTO_CREATION_AND_UPLOAD',
  PROJECT_GROUP_ASSETS_CONSTRUCTION_TO_ALL_RATIO_AVG = 'PROJECT_GROUP_ASSETS_CONSTRUCTION_TO_ALL_RATIO_AVG',
  PROJECT_GROUP_ASSETS_PERSON_COVERAGE_PERCENT = 'PROJECT_GROUP_ASSETS_PERSON_COVERAGE_PERCENT',
  PROJECT_GROUP_ASSETS_PHOTOS_WITHOUT_CREATED_DATE = 'PROJECT_GROUP_ASSETS_PHOTOS_WITHOUT_CREATED_DATE',
  PROJECT_GROUP_ASSETS_CONSTRUCTION_PHOTOS_WITHOUT_CREATED_DATE = 'PROJECT_GROUP_ASSETS_CONSTRUCTION_PHOTOS_WITHOUT_CREATED_DATE',

  // ORGANIZATION_ASSETS_DASHBOARD
  // SUMMARY
  ORGANIZATION_ASSETS_UPLOADED_TOTAL = 'ORGANIZATION_ASSETS_UPLOADED_TOTAL',
  ORGANIZATION_ASSETS_UPLOADERS_TOTAL = 'ORGANIZATION_ASSETS_UPLOADERS_TOTAL',
  ORGANIZATION_ASSETS_TAGS_TOTAL = 'ORGANIZATION_ASSETS_TAGS_TOTAL',
  ORGANIZATION_ASSETS_PPE_COMPLIANCE_AVG = 'ORGANIZATION_ASSETS_PPE_COMPLIANCE_AVG',
  ORGANIZATION_ASSETS_WORK_AT_HEIGHTS_AVG = 'ORGANIZATION_ASSETS_WORK_AT_HEIGHTS_AVG',
  ORGANIZATION_ASSETS_SLIP_AND_TRIP_AVG = 'ORGANIZATION_ASSETS_SLIP_AND_TRIP_AVG',
  // ACTIVITY
  ORGANIZATION_ASSETS_ACTIVITY_BY_GROUP_PROJECT = 'ORGANIZATION_ASSETS_ACTIVITY_BY_GROUP_PROJECT',
  ORGANIZATION_ASSETS_UPLOADS_OVERTIME_BY_SOURCE = 'ORGANIZATION_ASSETS_UPLOADS_OVERTIME_BY_SOURCE',
  ORGANIZATION_ASSETS_UPLOADERS_OVERTIME = 'ORGANIZATION_ASSETS_UPLOADERS_OVERTIME',
  ORGANIZATION_ASSETS_CONSTRUCTION_ACTIVITY = 'ORGANIZATION_ASSETS_CONSTRUCTION_ACTIVITY',
  ORGANIZATION_ASSETS_PHOTO_DESCRIPTION = 'ORGANIZATION_ASSETS_PHOTO_DESCRIPTION',
  ORGANIZATION_ASSETS_UPLOADS_BY_FILE_TYPE = 'ORGANIZATION_ASSETS_UPLOADS_BY_FILE_TYPE',
  ORGANIZATION_ASSETS_TAGS_BY_TYPE = 'ORGANIZATION_ASSETS_TAGS_BY_TYPE',
  // SAFETY
  ORGANIZATION_ASSETS_SAFETY_BY_GROUP_PROJECT = 'ORGANIZATION_ASSETS_SAFETY_BY_GROUP_PROJECT',
  ORGANIZATION_ASSETS_PEOPLE_IN_GROUPS = 'ORGANIZATION_ASSETS_PEOPLE_IN_GROUPS',
  ORGANIZATION_ASSETS_PPE_COMPLIANCE_TREND_AVG = 'ORGANIZATION_ASSETS_PPE_COMPLIANCE_TREND_AVG',
  ORGANIZATION_ASSETS_PPE_COMPLIANCE_PERCENT_AVG = 'ORGANIZATION_ASSETS_PPE_COMPLIANCE_PERCENT_AVG',
  ORGANIZATION_ASSETS_GLASSES_COMPLIANCE_PERCENT_AVG = 'ORGANIZATION_ASSETS_GLASSES_COMPLIANCE_PERCENT_AVG',
  ORGANIZATION_ASSETS_GLOVES_COMPLIANCE_PERCENT_AVG = 'ORGANIZATION_ASSETS_GLOVES_COMPLIANCE_PERCENT_AVG',
  ORGANIZATION_ASSETS_HARDHAT_COMPLIANCE_PERCENT_AVG = 'ORGANIZATION_ASSETS_HARDHAT_COMPLIANCE_PERCENT_AVG',
  ORGANIZATION_ASSETS_HIVIZ_COMPLIANCE_PERCENT_AVG = 'ORGANIZATION_ASSETS_HIVIZ_COMPLIANCE_PERCENT_AVG',
  ORGANIZATION_ASSETS_WORK_AT_HEIGHTS_TREND_AVG = 'ORGANIZATION_ASSETS_WORK_AT_HEIGHTS_TREND_AVG',
  ORGANIZATION_ASSETS_LADDERS_TO_LIFTS_RATIO_AVG = 'ORGANIZATION_ASSETS_LADDERS_TO_LIFTS_RATIO_AVG',
  ORGANIZATION_ASSETS_WORK_AT_HEIGHT_PER_X_ASSETS_AVG = 'ORGANIZATION_ASSETS_WORK_AT_HEIGHT_PER_X_ASSETS_AVG',
  ORGANIZATION_ASSETS_LADDERS_PER_X_ASSETS_AVG = 'ORGANIZATION_ASSETS_LADDERS_PER_X_ASSETS_AVG',
  ORGANIZATION_ASSETS_LIFTS_PER_X_ASSETS_AVG = 'ORGANIZATION_ASSETS_LIFTS_PER_X_ASSETS_AVG',
  ORGANIZATION_ASSETS_HOUSEKEEPING_AND_STANDING_WATER_AVG = 'ORGANIZATION_ASSETS_HOUSEKEEPING_AND_STANDING_WATER_AVG',
  ORGANIZATION_ASSETS_SLIP_AND_TRIP_TREND_AVG = 'ORGANIZATION_ASSETS_SLIP_AND_TRIP_TREND_AVG',
  ORGANIZATION_ASSETS_PEOPLE_PER_X_CONSTRUCTION = 'ORGANIZATION_ASSETS_PEOPLE_PER_X_CONSTRUCTION',
  // IMAGE_HEALTH
  ORGANIZATION_ASSETS_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK = 'ORGANIZATION_ASSETS_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK',
  ORGANIZATION_ASSETS_AVG_PHOTOS_CREATED_PER_DAY = 'ORGANIZATION_ASSETS_AVG_PHOTOS_CREATED_PER_DAY',
  ORGANIZATION_ASSETS_AVG_TIME_BETWEEN_PHOTO_CREATION_AND_UPLOAD = 'ORGANIZATION_ASSETS_AVG_TIME_BETWEEN_PHOTO_CREATION_AND_UPLOAD',
  ORGANIZATION_ASSETS_CONSTRUCTION_TO_ALL_RATIO_AVG = 'ORGANIZATION_ASSETS_CONSTRUCTION_TO_ALL_RATIO_AVG',
  ORGANIZATION_ASSETS_PERSON_COVERAGE_PERCENT = 'ORGANIZATION_ASSETS_PERSON_COVERAGE_PERCENT',
  ORGANIZATION_ASSETS_PHOTOS_WITHOUT_CREATED_DATE = 'ORGANIZATION_ASSETS_PHOTOS_WITHOUT_CREATED_DATE',
  ORGANIZATION_ASSETS_CONSTRUCTION_PHOTOS_WITHOUT_CREATED_DATE = 'ORGANIZATION_ASSETS_CONSTRUCTION_PHOTOS_WITHOUT_CREATED_DATE',

  /////////// PROJECT_OBSERVATIONS_DASHBOARD ///////////
  RISK_OBSERVATION_COUNT = 'RISK_OBSERVATION_COUNT',
  POSITIVE_OBSERVATION_COUNT = 'POSITIVE_OBSERVATION_COUNT',
  OBSERVATION_CREATOR_COUNT = 'OBSERVATION_CREATOR_COUNT',
  OPEN_OBSERVATION_COUNT = 'OPEN_OBSERVATION_COUNT',
  AVG_RISK_SCORE = 'AVG_RISK_SCORE',
  PAST_DUE_OBSERVATION_COUNT = 'PAST_DUE_OBSERVATION_COUNT',
  OBSERVATION_POSITIVE_TO_RISK_RATIO = 'OBSERVATION_POSITIVE_TO_RISK_RATIO',
  OBSERVATIONS_OVERTIME_BY_TYPE = 'OBSERVATIONS_OVERTIME_BY_TYPE',
  OBSERVATIONS_BY_TYPE = 'OBSERVATIONS_BY_TYPE',
  OBSERVATIONS_BY_METHOD_OF_IDENTIFICATION = 'OBSERVATIONS_BY_METHOD_OF_IDENTIFICATION',
  OBSERVATIONS_BY_CREATOR = 'OBSERVATIONS_BY_CREATOR',
  OBSERVATIONS_GREAT_CATCH_BY_TYPE = 'OBSERVATIONS_GREAT_CATCH_BY_TYPE',
  OBSERVATIONS_OVERTIME_BY_RISK_BAND = 'OBSERVATIONS_OVERTIME_BY_RISK_BAND',
  OBSERVATIONS_BY_HAZARD_CATEGORY = 'OBSERVATIONS_BY_HAZARD_CATEGORY',
  OBSERVATIONS_BY_RISK_BAND = 'OBSERVATIONS_BY_RISK_BAND',
  OBSERVATIONS_BY_RISK_SCORE = 'OBSERVATIONS_BY_RISK_SCORE',
  OBSERVATIONS_MAX_RISK_SCORE = 'OBSERVATIONS_MAX_RISK_SCORE',
  OBSERVATION_SCORES_OVER_TIME = 'OBSERVATION_SCORES_OVER_TIME',
  OBSERVATIONS_OVERTIME_BY_STATUS = 'OBSERVATIONS_OVERTIME_BY_STATUS',
  OBSERVATIONS_COUNT_BY_OPEN_VS_CLOSED = 'OBSERVATIONS_COUNT_BY_OPEN_VS_CLOSED',
  OBSERVATIONS_OPEN_BY_STATUS = 'OBSERVATIONS_OPEN_BY_STATUS',
  OBSERVATIONS_AVG_DAYS_OPEN_BY_RISK_BAND = 'OBSERVATIONS_AVG_DAYS_OPEN_BY_RISK_BAND',
  OBSERVATIONS_AVG_DAYS_OPEN = 'OBSERVATIONS_AVG_DAYS_OPEN',
  OBSERVATIONS_AVG_DAYS_TO_CLOSE = 'OBSERVATIONS_AVG_DAYS_TO_CLOSE',
  OBSERVATIONS_AVG_DAYS_LATENESS = 'OBSERVATIONS_AVG_DAYS_LATENESS',

  ORGANIZATION_RISK_OBSERVATION_COUNT = 'ORGANIZATION_RISK_OBSERVATION_COUNT',
  ORGANIZATION_POSITIVE_OBSERVATION_COUNT = 'ORGANIZATION_POSITIVE_OBSERVATION_COUNT',
  ORGANIZATION_OBSERVATION_CREATOR_COUNT = 'ORGANIZATION_OBSERVATION_CREATOR_COUNT',
  ORGANIZATION_OPEN_OBSERVATION_COUNT = 'ORGANIZATION_OPEN_OBSERVATION_COUNT',
  ORGANIZATION_AVG_RISK_SCORE = 'ORGANIZATION_AVG_RISK_SCORE',
  ORGANIZATION_PAST_DUE_OBSERVATION_COUNT = 'ORGANIZATION_PAST_DUE_OBSERVATION_COUNT',
  ORGANIZATION_OBSERVATIONS_OVERTIME_BY_TYPE = 'ORGANIZATION_OBSERVATIONS_OVERTIME_BY_TYPE',
  ORGANIZATION_OBSERVATIONS_BY_TYPE = 'ORGANIZATION_OBSERVATIONS_BY_TYPE',
  ORGANIZATION_OBSERVATIONS_BY_METHOD_OF_IDENTIFICATION = 'ORGANIZATION_OBSERVATIONS_BY_METHOD_OF_IDENTIFICATION',
  ORGANIZATION_OBSERVATIONS_BY_CREATOR = 'ORGANIZATION_OBSERVATIONS_BY_CREATOR',
  ORGANIZATION_OBSERVATIONS_GREAT_CATCH_BY_TYPE = 'ORGANIZATION_OBSERVATIONS_GREAT_CATCH_BY_TYPE',
  ORGANIZATION_OBSERVATIONS_ACTIVITY_BY_GROUP_PROJECT_USER = 'ORGANIZATION_OBSERVATIONS_ACTIVITY_BY_GROUP_PROJECT_USER',
  ORGANIZATION_OBSERVATION_POSITIVE_TO_RISK_RATIO = 'ORGANIZATION_OBSERVATION_POSITIVE_TO_RISK_RATIO',
  ORGANIZATION_OBSERVATIONS_SAFETY_BY_GROUP_PROJECT = 'ORGANIZATION_OBSERVATIONS_SAFETY_BY_GROUP_PROJECT',
  ORGANIZATION_OBSERVATIONS_OVERTIME_BY_RISK_BAND = 'ORGANIZATION_OBSERVATIONS_OVERTIME_BY_RISK_BAND',
  ORGANIZATION_OBSERVATION_SCORES_OVER_TIME = 'ORGANIZATION_OBSERVATION_SCORES_OVER_TIME',
  ORGANIZATION_OBSERVATIONS_BY_HAZARD_CATEGORY = 'ORGANIZATION_OBSERVATIONS_BY_HAZARD_CATEGORY',
  ORGANIZATION_OBSERVATIONS_BY_RISK_BAND = 'ORGANIZATION_OBSERVATIONS_BY_RISK_BAND',
  ORGANIZATION_OBSERVATIONS_BY_RISK_SCORE = 'ORGANIZATION_OBSERVATIONS_BY_RISK_SCORE',
  ORGANIZATION_OBSERVATIONS_AVG_DAYS_OPEN_BY_RISK_BAND = 'ORGANIZATION_OBSERVATIONS_AVG_DAYS_OPEN_BY_RISK_BAND',
  ORGANIZATION_OBSERVATIONS_AVG_DAYS_OPEN = 'ORGANIZATION_OBSERVATIONS_AVG_DAYS_OPEN',
  ORGANIZATION_OBSERVATIONS_AVG_DAYS_TO_CLOSE = 'ORGANIZATION_OBSERVATIONS_AVG_DAYS_TO_CLOSE',
  ORGANIZATION_OBSERVATIONS_AVG_DAYS_LATENESS = 'ORGANIZATION_OBSERVATIONS_AVG_DAYS_LATENESS',
  ORGANIZATION_OBSERVATIONS_COUNT_BY_OPEN_VS_CLOSED = 'ORGANIZATION_OBSERVATIONS_COUNT_BY_OPEN_VS_CLOSED',
  ORGANIZATION_OBSERVATIONS_OPEN_BY_STATUS = 'ORGANIZATION_OBSERVATIONS_OPEN_BY_STATUS',
  ORGANIZATION_OBSERVATIONS_OVERTIME_BY_STATUS = 'ORGANIZATION_OBSERVATIONS_OVERTIME_BY_STATUS',
  ORGANIZATION_OBSERVATIONS_WORKFLOW_BY_GROUP_PROJECT = 'ORGANIZATION_OBSERVATIONS_WORKFLOW_BY_GROUP_PROJECT',

  PROJECT_GROUP_RISK_OBSERVATION_COUNT = 'PROJECT_GROUP_RISK_OBSERVATION_COUNT',
  PROJECT_GROUP_POSITIVE_OBSERVATION_COUNT = 'PROJECT_GROUP_POSITIVE_OBSERVATION_COUNT',
  PROJECT_GROUP_OBSERVATION_CREATOR_COUNT = 'PROJECT_GROUP_OBSERVATION_CREATOR_COUNT',
  PROJECT_GROUP_OPEN_OBSERVATION_COUNT = 'PROJECT_GROUP_OPEN_OBSERVATION_COUNT',
  PROJECT_GROUP_AVG_RISK_SCORE = 'PROJECT_GROUP_AVG_RISK_SCORE',
  PROJECT_GROUP_PAST_DUE_OBSERVATION_COUNT = 'PROJECT_GROUP_PAST_DUE_OBSERVATION_COUNT',
  PROJECT_GROUP_OBSERVATIONS_OVERTIME_BY_TYPE = 'PROJECT_GROUP_OBSERVATIONS_OVERTIME_BY_TYPE',
  PROJECT_GROUP_OBSERVATIONS_BY_TYPE = 'PROJECT_GROUP_OBSERVATIONS_BY_TYPE',
  PROJECT_GROUP_OBSERVATIONS_BY_METHOD_OF_IDENTIFICATION = 'PROJECT_GROUP_OBSERVATIONS_BY_METHOD_OF_IDENTIFICATION',
  PROJECT_GROUP_OBSERVATIONS_BY_CREATOR = 'PROJECT_GROUP_OBSERVATIONS_BY_CREATOR',
  PROJECT_GROUP_OBSERVATIONS_GREAT_CATCH_BY_TYPE = 'PROJECT_GROUP_OBSERVATIONS_GREAT_CATCH_BY_TYPE',
  PROJECT_GROUP_OBSERVATIONS_ACTIVITY_BY_PROJECT_USER = 'PROJECT_GROUP_OBSERVATIONS_ACTIVITY_BY_PROJECT_USER',
  PROJECT_GROUP_OBSERVATION_POSITIVE_TO_RISK_RATIO = 'PROJECT_GROUP_OBSERVATION_POSITIVE_TO_RISK_RATIO',
  PROJECT_GROUP_OBSERVATIONS_SAFETY_BY_PROJECT = 'PROJECT_GROUP_OBSERVATIONS_SAFETY_BY_PROJECT',
  PROJECT_GROUP_OBSERVATIONS_OVERTIME_BY_RISK_BAND = 'PROJECT_GROUP_OBSERVATIONS_OVERTIME_BY_RISK_BAND',
  PROJECT_GROUP_OBSERVATION_SCORES_OVER_TIME = 'PROJECT_GROUP_OBSERVATION_SCORES_OVER_TIME',
  PROJECT_GROUP_OBSERVATIONS_BY_HAZARD_CATEGORY = 'PROJECT_GROUP_OBSERVATIONS_BY_HAZARD_CATEGORY',
  PROJECT_GROUP_OBSERVATIONS_BY_RISK_BAND = 'PROJECT_GROUP_OBSERVATIONS_BY_RISK_BAND',
  PROJECT_GROUP_OBSERVATIONS_BY_RISK_SCORE = 'PROJECT_GROUP_OBSERVATIONS_BY_RISK_SCORE',
  PROJECT_GROUP_OBSERVATIONS_MAX_RISK_SCORE = 'PROJECT_GROUP_OBSERVATIONS_MAX_RISK_SCORE',
  PROJECT_GROUP_OBSERVATIONS_OVERTIME_BY_STATUS = 'PROJECT_GROUP_OBSERVATIONS_OVERTIME_BY_STATUS',
  PROJECT_GROUP_OBSERVATIONS_COUNT_BY_OPEN_VS_CLOSED = 'PROJECT_GROUP_OBSERVATIONS_COUNT_BY_OPEN_VS_CLOSED',
  PROJECT_GROUP_OBSERVATIONS_OPEN_BY_STATUS = 'PROJECT_GROUP_OBSERVATIONS_OPEN_BY_STATUS',
  PROJECT_GROUP_OBSERVATIONS_AVG_DAYS_OPEN_BY_RISK_BAND = 'PROJECT_GROUP_OBSERVATIONS_AVG_DAYS_OPEN_BY_RISK_BAND',
  PROJECT_GROUP_OBSERVATIONS_AVG_DAYS_OPEN = 'PROJECT_GROUP_OBSERVATIONS_AVG_DAYS_OPEN',
  PROJECT_GROUP_OBSERVATIONS_AVG_DAYS_TO_CLOSE = 'PROJECT_GROUP_OBSERVATIONS_AVG_DAYS_TO_CLOSE',
  PROJECT_GROUP_OBSERVATIONS_AVG_DAYS_LATENESS = 'PROJECT_GROUP_OBSERVATIONS_AVG_DAYS_LATENESS',
  PROJECT_GROUP_OBSERVATIONS_WORKFLOW_BY_PROJECT = 'PROJECT_GROUP_OBSERVATIONS_WORKFLOW_BY_PROJECT',
}

export enum DashboardTabId {
  ACTIVITY = 'ACTIVITY',
  SAFETY = 'SAFETY',
  IMAGE_HEALTH = 'IMAGE_HEALTH',
  WORKFLOW = 'WORKFLOW',
}

export enum ScopeObjectType {
  ORGANIZATION,
  PROJECT_GROUP,
  PROJECT,
}

export interface ScopeObjectTableInfo {
  scope: ScopeObjectType
  scopeObjectId: string
  scopeObjectName: string
}

export interface DashboardRequest {
  dashboardDataObjectRequests: DashboardObjectRequest[]
}

export interface DashboardObjectRequest {
  dashboardDataObject: DashboardDataObjectType
  dashboardDateRangeTimeUnit: DashboardDateRangeTimeUnit
  dateRangeStart: number
  dateRangeEnd: number
  scopeObjectId: string
  tagNames?: string[]
  useDemoData?: boolean
  ignoreCache?: boolean
  filter?: string
}

export class DashboardDataObjectResponseBase {
  dateRangeStart: number
  dateRangeEnd: number
  dateRangeTimeUnit: DashboardDateRangeTimeUnit
}

export class DashboardDataResponse<T extends DashboardDataObjectResponseBase> {
  dashboardData: { [key in DashboardDataObjectType]: T }
}

export class InsightsDashboardParameters {
  organizationId: string
  projectGroupId?: string
  projectId?: string
  userId: string
  dateRangeStart?: Date
  dateRangeEnd?: Date
  dateRangeTimeUnit?: DashboardDateRangeTimeUnit = DashboardDateRangeTimeUnit.NA
  useDemoData? = false
  ignoreCache? = false
  filter?: string
  orgHasGroups? = false
}

export interface DashboardConfigurationRequest {
  dashboardType: DashboardType
  organizationId: string
  projectGroupId?: string
  projectId?: string
  userId?: string
}

export class DashboardConfigurationResponse {
  dashboardConfiguration: DashboardConfiguration
}

export class ApiDashboardConfigurationRequest {
  dashboardConfiguration: DashboardConfiguration
}

export class ApiGetDashboardConfigurationResourceBundleRequest implements DashboardConfigurationRequest {
  languageCode: string
  dashboardType: DashboardType
  organizationId: string
  projectGroupId?: string
  projectId?: string
  userId?: string
}

export class DashboardCardColumnSpan {
  desktop: number
  tablet: number
  phone: number
}

export class DashboardCardConfiguration {
  cardId: string
  columnSpan: DashboardCardColumnSpan
  customCardData?: any
}

export class DashboardTabConfiguration {
  tabId: string
  displayLabel?: string
  cards: DashboardCardConfiguration[]
}

export class DashboardConfiguration {
  summaryCards: DashboardCardConfiguration[]
  tabs: DashboardTabConfiguration[]
}

export class DateRangeChartData extends DashboardDataObjectResponseBase {
  chartData: ChartData

  get startDate() {
    return this.dateRangeStart ? new Date(this.dateRangeStart) : undefined
  }

  get endDate() {
    return this.dateRangeEnd ? new Date(this.dateRangeEnd) : undefined
  }
}

export interface NameBasedTableFilter {
  fieldName: string
  filter: string
}

export enum TableScopeFilter {
  ACTIVITY_BY_USER_MENU_SV_USERS = 'ACTIVITY_BY_USER_MENU_SV_USERS',
  ACTIVITY_BY_USER_MENU_INTEGRATION_USERS = 'ACTIVITY_BY_USER_MENU_INTEGRATION_USERS',
  BY_PROJECT = 'BY_PROJECT',
  BY_PROJECT_GROUP = 'BY_PROJECT_GROUP',
}

export class CancellableRequestSubscription {
  constructor(public cancellableRequest: Q.Deferred<any>, public cancellableSubscription: Subscription) {}

  public cancel() {
    if (this.cancellableRequest) {
      this.cancellableRequest.resolve()
    }

    forceUnsubscribe(this.cancellableSubscription)
  }
}
