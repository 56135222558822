import { IntegrationType } from '../../../shared/models/integration-type'
import { AdminUserModel } from './admin.user.model'

export class AdminIntegrationSettingsWithUser {
  public integrationSettings: AdminIntegrationSettings
  public user: AdminUserModel
  public canEnable: boolean
}

export class AdminIntegrationSettings {
  public id: string
  public projectId: string
  public userId: string
  public integrationType: IntegrationType
  public isEnabled: boolean
  public isRunOnce: boolean
  public lastSyncDate: number
  public syncOnlyAfterDate: number
  public settingsData: any
  public isProcessing: boolean
  public syncConfigParams: SyncConfigParams
}

class SyncConfigParams {
  public syncInterval: number
  public downloadInterval: number
  public staleSyncCleanupInterval: number
}

export enum OrganizationIntegrationSettingsActionType {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  DISCONNECT = 'DISCONNECT',
}

export class ApiOrganizationIntegrationSettingsByIdsRequest {
  public action: OrganizationIntegrationSettingsActionType
  public integrationSettingsIds: string[]
}
