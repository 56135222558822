/* global angular */

angular.module('smartvid').filter('range', function () {
  return (input, total) => {
    total = parseInt(total, 10)

    for (var i = 0; i < total; i++) {
      input.push(i)
    }
    return input
  }
})
