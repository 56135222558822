/* global angular _ */

angular.module('smartvid').factory('AconexSyncSettingsModel', function (BaseModel) {
  class AconexSyncSettingsModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        partnerUserId: undefined,
        isForceReSyncAll: undefined,
        region: undefined,
        aconexProjectId: undefined,
        lastSyncDate: undefined,
        historicalDataSyncDateRange: undefined,
        historicalDataSyncThresholdDate: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return AconexSyncSettingsModel
})
