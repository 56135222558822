import { ConfigService } from './config.service'
import { HTTP } from 'shared/smartvid.types'
import { Injectable, Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { ObservationPredefinedFields, UiConfiguration } from 'shared/models/ui-configuration.model'
import { toObservable } from 'shared/utils/api-utils'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class UiConfigurationService {
  rootUrl: string

  constructor(@Inject(HTTP) private $http: ng.IHttpService, private config: ConfigService) {
    this.rootUrl = this.config.env.development.apiRootUrl
  }

  private getUiConfigurationForObservationsCache: {
    [organizationId: string]: Observable<UiConfiguration>
  } = {}

  clearCache() {
    this.getUiConfigurationForObservationsCache = {}
  }

  getUiConfigurationForObservations(organizationId: string): Observable<UiConfiguration> {
    if (Object.prototype.hasOwnProperty.call(this.getUiConfigurationForObservationsCache, organizationId)) {
      return this.getUiConfigurationForObservationsCache[organizationId]
    }
    let url = this.rootUrl + `/api/organization/${organizationId}/uiconfiguration/object_type/OBSERVATION_DETAILS`
    let ret = toObservable<UiConfiguration>(this.$http.get(url), UiConfiguration).pipe(
      map(config => UiConfiguration.fromBackend(config))
    )

    this.getUiConfigurationForObservationsCache[organizationId] = ret
    return ret
  }

  getPredefinedStatuses(organizationId: string): Observable<string[]> {
    return this.getUiConfigurationForObservations(organizationId).pipe(
      map(data => {
        return data.uiConfigurationData.getFieldListData(ObservationPredefinedFields.STATUS).map(f => f.value)
      })
    )
  }
}
