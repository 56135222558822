/* global angular */

angular.module('smartvid').factory('ProcoreAlbumCollection', function (BaseCollection, ProcoreUserModel) {
  class ProcoreUserCollection extends BaseCollection {
    constructor (models) {
      super(models, ProcoreUserModel)
    }
  }

  return ProcoreUserCollection
})
