/* global angular, _ */

angular.module('smartvid').controller('ProjectGridOptionsCtrl', function ($scope, $rootScope, $state, modal, smartvidApi,
                                                                         utils, currentUser) {
  /**
   * Delete selected projects
   */
  $scope.deleteSelectedProjects = () => {
    let selected = $scope.projects.where({ selected: true })
    let deleteIsProhibited = false
    selected.forEach(function (value) {
      if (!value.canDelete) {
        deleteIsProhibited = true
      }
    })
    $scope.flyout.isOpen = false
    modal.open('projectDeleteConfirm', {
      confirm () {
        let projects = $scope.projects.getSelected()
        let projectIds = _.pluck(projects, 'id')

        smartvidApi.deleteProjects(projectIds).then(() => {
          _.each(projects, (project) => {
            $scope.projects.removeById(project.id)
          })
          $rootScope.$broadcast('sv-project-deleted', { projectIds: projectIds })
          $state.go($state.current.name, {}, {
            reload: true
          })
          utils.notify('common.projectsMovedToDeletedFolder', '', 'common.view', null, () => {
            $state.go('dashboard.adminOrganizations.organizationId.deletedProjects',
              {
                organizationId: projects[0].organizationId
              },
              {
                reload: true
              })
          })
          $scope.flyout.close()
        })
      },
      hasSelections: selected.length > 0,
      deleteIsProhibited: deleteIsProhibited
    })
  }

  function showActivateDeactivateOption () {
    return $scope.projects.getSelected() &&
      $scope.projects.getSelected().length > 0 &&
      !_.find($scope.projects.getSelected(), p => {
        return !currentUser.getOrganization(p.organizationId).canManageProjects
      })
  }

  $scope.showActivateOption = function () {
    return showActivateDeactivateOption() && _.find($scope.projects.getSelected(), p => {
      return p.isInactive
    })
  }

  $scope.showDeactivateOption = function () {
    return showActivateDeactivateOption() && _.find($scope.projects.getSelected(), p => {
      return !p.isInactive
    })
  }

  $scope.setProjectsActivityStatus = function (isActive) {
    // address confusing parameter name
    let isInactive = !isActive
    let selectedProjects = $scope.projects.getSelected()
    let promise = smartvidApi.updateProjectsStatus(_.pluck(selectedProjects, 'id'), isInactive)
    promise.then(() => {
      _.each(selectedProjects, p => {
        p.update({ isInactive: isInactive })
        $rootScope.$broadcast('sv-project-updated', p)
      })
      $state.go($state.current.name, {}, {
        reload: true
      })
    })
    $scope.flyout.close()
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top,
      left: $scope.styles.left
    }
  }
})
