import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { UiConfiguration } from '../../../../shared/models/ui-configuration.model'
import { UiConfigurationService } from '../../../core/services/ui-configuration.service'

export class UiConfigurationSupport {
  protected uiConfiguration: UiConfiguration

  constructor(protected uiConfigApi: UiConfigurationService) {}

  public isHidden(fieldName: string): boolean {
    return this.isHiddenForUiConfiguration(this.uiConfiguration, fieldName)
  }

  protected isRequired(fieldName: string): boolean {
    return this.isRequiredForUiConfiguration(this.uiConfiguration, fieldName)
  }

  protected initOrganizationUiConfiguration(organizationId: string): Observable<UiConfiguration> {
    return this.uiConfigApi.getUiConfigurationForObservations(organizationId).pipe(first())
  }
  protected clearUiConfigCache() {
    return this.uiConfigApi.clearCache()
  }

  protected isHiddenForUiConfiguration(uiConfiguration: UiConfiguration, fieldName: string): boolean {
    return (
      uiConfiguration &&
      uiConfiguration.uiConfigurationData.getField(fieldName) &&
      uiConfiguration.uiConfigurationData.getField(fieldName).isHidden
    )
  }
  protected isRequiredForUiConfiguration(uiConfiguration: UiConfiguration, fieldName: string): boolean {
    return (
      uiConfiguration &&
      uiConfiguration.uiConfigurationData.getField(fieldName) &&
      uiConfiguration.uiConfigurationData.getField(fieldName).isRequired
    )
  }
}
