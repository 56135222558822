/* global angular $ _ moment */

angular.module('smartvid').controller('MoveAssetCtrl', function ($rootScope, $interpolate,
                                                                 $scope, $element, $q, $timeout, $filter, moment, smartvidApi, dashboardDataHelper, currentUser, reportStateService,
                                                                 modal
) {
    $scope.getSelectedAssets = () => {
        let selected = _.where($scope.assets, {selected: true})
        return selected;
    }

    $scope.excludeAssetProject = (proj) => {
        const selected = $scope.getSelectedAssets();
        if (!selected) {
            return proj
        } else {
            return proj.id !== selected[0].projectId
        }
    }

    $scope.sameOrganization = (proj) => {
        const selected = $scope.getSelectedAssets();
        if (!selected) {
            return proj
        } else {
            const project = dashboardDataHelper.getAllProjects().models.find(p => {
              return  p.id === selected[0].projectId
            })
            return project && project.organizationId === proj.organizationId;
        }
    }

    let allProjects = dashboardDataHelper.getAllProjects()

    $scope.projectOptions = _(allProjects.models)
        .filter((proj) => $scope.excludeAssetProject(proj))
        .filter((proj) => $scope.sameOrganization(proj))
        .sortBy((proj) => safe(proj.name))
        .value()

    $scope.isFetchingSystemData = true
    $scope.selectedProject = null

    $scope.selectProject = selectProject
    $scope.closeModal = closeModal
    $scope.handleDownArrow = handleDownArrow
    $scope.searchProjectsByPartialText = searchProjectsByPartialText

    function closeModal () {
        modal.close()
    }

    function safe (str) {
        return (str || '').toUpperCase()
    }

    function selectProject (project) {
        if (project) {
            $scope.selectedProject = project.originalObject
        }
    }

    function handleDownArrow () {
        let angucompleteElement = angular.element('.select-project .angucomplete-holder')
        let angucompleteScope = angucompleteElement.scope()
        if (angucompleteScope.showDropdown) {
            angucompleteScope.showDropdown = false
            return
        }
        let inputField = angucompleteElement.find('input')
        inputField.focus()
        let inputFieldValue = inputField.val()
        $timeout(() => {
            angucompleteScope.results = _.map(searchProjectsByPartialText(inputFieldValue), (project) => {
                return {
                    title: project.name,
                    description: '',
                    image: '',
                    originalObject: project
                }
            })
            angucompleteScope.showDropdown = true
        }, 300)
    }

    function searchProjectsByPartialText (partial) {

        let result = _(allProjects.models)
            .filter((project) => safe(project.name).indexOf(safe(partial)) !== -1)
            .filter((proj) => $scope.excludeAssetProject(proj))
            .filter((proj) => $scope.sameOrganization(proj))
            .sortBy((proj) => safe(proj.name))
            .value()
        return result
    }

    $scope.moveAsset = () => {
        $scope.flyout.isOpen = false
        modal.open('moveAssetConfirm', {
            confirm () {
                let selected = $scope.getSelectedAssets()
                let assetIds = selected.map(asset => asset.id)
                const sourceProjectId = selected[0].projectId
                const targetProjectId = $scope.selectedProject.id
                let payload = {
                    sourceProjectId: sourceProjectId,
                    targetProjectId: targetProjectId,
                    assetIdsToMove: assetIds
                }
                $rootScope.$broadcast('assetGrid:moveAsset', payload)
                $scope.flyout.close()
            },
            hasSelections: 1
        })
    }

    $scope.getSelectedAssetsMessage = () => {
        let msg = 'moveAssetModal.selectedAssets';
        let selected = $scope.getSelectedAssets();
        return $interpolate($filter('i18next')(msg))({
            number: selected.length
        })
    }

})
