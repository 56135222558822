/* global angular _ */

angular.module('smartvid').factory('ForgeRootCauseModel', function (BaseModel) {
  class ForgeRootCauseModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined,
        title: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return ForgeRootCauseModel
})
