/* global angular */

angular.module('smartvid').controller('AssetGridDownloadOptionsCtrl', function ($scope, $rootScope, modal, dashboardDataHelper) {
  $scope.downloadSelectedAssetsSnapshots = () => {
    let assets = dashboardDataHelper.getAssets()
    $rootScope.$broadcast('assetGrid:downloadSelectedSnapshots', false, assets)
    $scope.flyout.close()
  }

  $scope.downloadSelectedAssetsFiles = () => {
    let assets = dashboardDataHelper.getAssets()
    $rootScope.$broadcast('assetGrid:downloadSelectedSnapshots', true, assets)
    $scope.flyout.close()
  }

  $scope.exportAssetsWithTagCounts = () => {
    let selected = $scope.assets.where({selected: true})
    $scope.flyout.isOpen = false
    if (selected.length === 0) {
      modal.open('exportAssetsWithTagCountsNoSelection', {
        confirm () {
          $scope.flyout.close()
        },
        hasSelections: selected.length > 0
      })
    } else {
      $rootScope.$broadcast('assetGrid:exportAssetsWithTagCounts', true)
      $scope.flyout.close()
    }
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top,
      left: $scope.styles.left,
      width: '150'
    }
  }
})
