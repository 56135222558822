/* global angular, _ */

angular.module('smartvid').factory('TagNodesCollection', function (BaseCollection, TagTreeNodeModel, smartvidApi, $q, utils, tagTreeStateService, tagImportService, TagDefCollection) {
  function forEachRecursive (models, iteratee = _.identity, parent, level = 0) {
    let that = this
    _.each(models, function (model, index) {
      if (iteratee.call(that, model, parent, index, level)) {
        forEachRecursive.call(that, model.children, iteratee, model, level + 1)
      }
    })
  }

  function fixChildNodes (models) { // TODO migrate this into the TagNodesCollection instructor
    let result = _.map(models, (node) => {
      let treeNode = new TagTreeNodeModel(node)
      treeNode.children = fixChildNodes.call(this, node.childNodes)
      return treeNode
    })
    return result
  }

  function getRefreshPromise () {
    let defer = $q.defer()
    this.strategy.getTreeUpdateTime().then((newUpdateTime) => {
      if (newUpdateTime > this.lastUpdateTime || newUpdateTime === -1) {
        this.strategy.getTree().then((tagsCollection) => {
          let nodes = fixChildNodes.call(this, tagsCollection)
          this.lastUpdateTime = newUpdateTime
          defer.resolve(nodes)
        }, () => {
          defer.reject()
        })
      } else {
        defer.reject()
      }
    }, () => {
      defer.reject()
    })
    return defer.promise
  }

  class TagNodesCollection extends BaseCollection {
    constructor (org, project, includeOrgTags) {
      super(undefined, TagTreeNodeModel)
      this.org = org
      this.project = project
      this.lastUpdateTime = -1
      this.isFetching = false
      this.strategy = (this.project) ? new ProjectStrategy(this.org, this.project, this, includeOrgTags) : new OrgStrategy(this.org, this)
      this.key = this.strategy.getKey()
      this.tagDefs = this.strategy.getTagDefs()
      this.allNodes = {}
      this.updateListeners = []
    }

    init () {
      this.refresh()
    }

    refresh () {
      if (this.isFetching) {
        return
      }
      this.isFetching = true
      let tagsPromise = getRefreshPromise.call(this)
      tagsPromise.then((nodes) => {
        this.insert(nodes)
      }, () => {
        //ignore
      }).finally(() => {
        this.isFetching = false
      })
      this.loadingPromise = tagsPromise
      return this.loadingPromise
    }

    insert (nodes) {
      this.models = []
      this.allNodes = {}
      this.nodeToParent = {}
      this.upsert(nodes, TagTreeNodeModel)
      this.forEachNode(function (node, parent, index) {
        this.allNodes[node.id] = node
        if (parent) {
          this.nodeToParent[node.id] = parent.id
        }
        return true
      })
      _.each(this.updateListeners, (listener) => {
        listener()
      })
    }

    getTagDefs () {
      return this.tagDefs
    }

    getTagDefinition (nodeId) {
      let node = this.getNode(nodeId)
      return this.getTagDefs().getFromServerAndUpsert(node.tagDefinitionId)
    }

    findTagByText (text) {
      return this.getTagDefs().findTagByText(text)
    }

    getChildren (nodeId) {
      return (nodeId) ? this.getNode(nodeId).children : this.models
    }

    getTagDefinitionsInOrder () {
      let tagDefs = []
      this.forEachNode((node) => {
        let tagDef = this.tagDefs.findById(node.tagDefinitionId)
        tagDefs.push(tagDef)
        return true
      })
      return tagDefs
    }

    canPromote (nodeIds) {
      let promotable = _.find(nodeIds, (nodeId) => {
        let node = this.getNode(nodeId)
        return (node) ? this.isPromotable(node) : false
      })
      return (!!promotable)
    }

    canDemote (nodeIds) {
      let demotable = _.find(nodeIds, (nodeId) => {
        let node = this.getNode(nodeId)
        return (node) ? this.isDemotable(node, nodeIds) : false
      })
      return (!!demotable)
    }

    canMoveToTop (nodeIds) {
      let anyRootNode = _.find(nodeIds, (nodeId) => {
        let node = this.getNode(nodeId)
        return (node) ? this.isRootNode(node) : false
      })
      return !anyRootNode
    }

    canImportTags () {
      return this.strategy.canImportTags()
    }

    canExportTags () {
      return this.strategy.canExportTags()
    }

    canAddTagDefs () {
      return this.strategy.canAddTagDefs()
    }

    canDeleteTagDefs () {
      return this.strategy.canDeleteTagDefs()
    }

    importTags (file) {
      let defer = $q.defer()
      if (this.strategy.canImportTags()) {
        let promise = this.strategy.importTags(file)
        promise.then((data) => {
          this.refresh().then(() => {
            defer.resolve(data)
          }, (data) => {
            defer.reject(data)
          })
        }, (data) => {
          defer.reject(data)
        })
      } else {
        defer.reject()
      }
      return defer.promise
    }

    createTagExportPackage () {
      let defer = $q.defer()
      if (this.strategy.canExportTags()) {
        return this.strategy.createTagExportPackage()
      } else {
        defer.reject()
      }
      return defer.promise
    }

    getTagExportPackageStatus (packageId) {
      let defer = $q.defer()
      if (this.strategy.canExportTags()) {
        return this.strategy.getTagExportPackageStatus(packageId)
      } else {
        defer.reject()
      }
      return defer.promise
    }

    demote (nodeIds) {
      if (!this.canDemote(nodeIds)) {
        return
      }
      let demotables = this.findDemotables(nodeIds)
      _.each(demotables, (demotable) => {
        demotable.isAfter = true
      })
      return this.moveTagNodes(demotables)
    }

    promote (nodeIds) {
      if (!this.canPromote(nodeIds)) {
        return
      }
      let promotables = this.findPromotables(nodeIds)
      _.each(promotables, (promotable) => {
        promotable.sibling = promotable.currentParent
        promotable.isAfter = false
      })
      return this.moveTagNodes(promotables)
    }

    moveTagsToNewParent (tagIdsToMove, newParentId) {
      let newParentNode = this.getNode(newParentId)
      return this.moveTagNodes(_.map(tagIdsToMove, (tagId) => {
        return {
          selected: this.getNode(tagId),
          currentParent: this.getParentNode(tagId),
          newParent: newParentNode,
          sibling: undefined,
          isAfter: false
        }
      }))
    }

    moveTag (tagId, parentTagId, siblingTagId, isAfter) {
      let newParent = null
      if (siblingTagId) {
        newParent = this.getParentNode(siblingTagId)
      } else if (parentTagId) {
        newParent = this.getNode(parentTagId)
      }
      let moveDescriptor = {
        selected: this.getNode(tagId),
        currentParent: this.getParentNode(tagId),
        newParent: newParent,
        sibling: this.getNode(siblingTagId),
        isAfter: isAfter
      }
      return this.moveTagNodes([moveDescriptor])
    }

    moveTagNodes (moveDescriptors) {
      let tagDefinitionToNode = {}
      let payloads = _.map(moveDescriptors, (descriptor) => {
        if (descriptor.newParent) {
          tagDefinitionToNode[descriptor.newParent.tagDefinitionId] = descriptor.newParent
        }
        return {
          tagDefinitionIdToMove: descriptor.selected.tagDefinitionId,
          oldParentTagDefinitionId: (descriptor.currentParent) ? descriptor.currentParent.tagDefinitionId : undefined,
          newParentTagDefinitionId: (descriptor.newParent) ? descriptor.newParent.tagDefinitionId : undefined,
          siblingTagDefinitionId: (descriptor.sibling) ? descriptor.sibling.tagDefinitionId : undefined,
          isAfter: descriptor.isAfter
        }
      })
      let groups = _.groupBy(payloads, (payload) => {
        return payload.oldParentTagDefinitionId + payload.newParentTagDefinitionId + payload.siblingTagDefinitionId + payload.isAfter
      })
      let promises = {}
      let requests = {}
      _.each(Object.keys(groups), (groupKey) => {
        let payloads = groups[groupKey]
        let request = _.reduce(payloads, function (memo, payload) {
          memo.tagDefinitionIdsToMove.push(payload.tagDefinitionIdToMove)
          memo.oldParentTagDefinitionId = payload.oldParentTagDefinitionId
          memo.newParentTagDefinitionId = payload.newParentTagDefinitionId
          memo.siblingTagDefinitionId = payload.siblingTagDefinitionId
          memo.isAfter = payload.isAfter
          return memo
        }, {
          tagDefinitionIdsToMove: []
        })
        let promise = this.strategy.moveTagNode(request)
        requests[groupKey] = request
        promises[groupKey] = promise
      })
      let allPromise = $q.all(promises)
      let defer = $q.defer()
      allPromise.then((values) => {
        let valueKeys = Object.keys(values)
        _.each(valueKeys, (valueKey) => {
          let request = requests[valueKey]
          let serverNodes = values[valueKey]
          _.each(serverNodes, (serverNode) => {
            let parentNode = (request.newParentTagDefinitionId) ? tagDefinitionToNode[request.newParentTagDefinitionId] : undefined
            let nodeToMove = this.getNode(serverNode.id)
            nodeToMove.nodeOrder = serverNode.nodeOrder
            this.deleteFromTree(nodeToMove, false)
            this.insertIntoTree(nodeToMove, parentNode, false)
            _.each(this.updateListeners, (listener) => {
              listener()
            })
          })
        })
        defer.resolve()
      })
      return defer.promise
    }

    createTag (tagName, isAllowAsr, isAllowImrec, isAllowManual, addedAliases, parentTag, ignoreError = false) {
      let defer = $q.defer()
      let tagPromise = this.strategy.createTag(tagName, isAllowAsr, isAllowImrec, isAllowManual, addedAliases || [], parentTag, ignoreError)
      tagPromise.then((tagNode) => {
        this.insertIntoTree(tagNode, parentTag)
        defer.resolve(tagNode)
      }, () => {
        defer.reject()
      })
      return defer.promise
    }

    updateTag (tagId, tagName, isAllowAsr, isAllowImrec, isAllowManual, addedAliases, removedAliases) {
      let defer = $q.defer()
      let node = this.getNode(tagId)
      smartvidApi.updateTagDefinition(node.tagDefinitionId, tagName, isAllowAsr, isAllowImrec, isAllowManual, addedAliases, removedAliases).then((tagDef) => {
        this.getTagDefs().upsert([tagDef])
        node.title = tagDef.text
        defer.resolve()
      }, () => {
        defer.reject()
      })
      return defer.promise
    }

    deleteTag (tagId) {
      let defer = $q.defer()
      let node = this.getNode(tagId)
      this.strategy.deleteTag(node.tagDefinitionId).then(() => {
        this.deleteFromTree(node)
        this.getTagDefs().removeById(node.tagDefinitionId)
        defer.resolve()
      }, () => {
        defer.reject()
      })
      return defer.promise
    }

    containsParentsWithProtectedChildren (nodes) {
      var protectedNodeFound = false
      this.forEachNode((node, parent, index, level) => {
        if (!protectedNodeFound) {
          protectedNodeFound = node.isProtectedTag()
        }
        return !protectedNodeFound
      }, nodes)

      return protectedNodeFound
    }

    hasProtectedChildren (nodeId) {
      return this.containsParentsWithProtectedChildren([this.getNode(nodeId)])
    }

    getAssetCountByTagDefs (tagIds) {
      return this.strategy.getAssetCountByTagDefs(tagIds)
    }

    getTagInstancesCountsGroupedByType (tagId) {
      return this.strategy.getTagInstancesCountsGroupedByType(tagId)
    }

    isDemotable (node, nodeIds) {
      if (this.isFirstNode(node)) {
        return false
      }
      let neighbor = this.findUpdatableNeighbor(node, nodeIds)
      node.neighbor = neighbor
      return !!neighbor
    }

    isPromotable (node) {
      let parentNode = this.getParentNode(node.id)
      return !this.isRootNode(node) && parentNode.canUpdate
    }

    isRootNode (node) {
      let parentNode = this.getParentNode(node.id)
      return (parentNode === undefined)
    }

    isFirstNode (node) {
      let parentNode = this.getParentNode(node.id)
      let children = (parentNode) ? parentNode.children : this.models
      return children[0] === node
    }

    findDemotables (nodeIds) {
      let demotableNodes = _.filter(nodeIds, (nodeId) => {
        let node = this.getNode(nodeId)
        return this.isDemotable(node, nodeIds)
      })
      let demotable = _.map(demotableNodes, (nodeId) => {
        let node = this.getNode(nodeId)
        return {
          selected: node,
          currentParent: this.getParentNode(node.id),
          newParent: this.getNode(node.neighbor.id)
        }
      })
      return demotable
    }

    findPromotables (nodeIds) {
      let promotableNodes = _.filter(nodeIds, (nodeId) => {
        let node = this.getNode(nodeId)
        return this.isPromotable(node)
      })
      let promotable = _.map(promotableNodes, (nodeId) => {
        let node = this.getNode(nodeId)
        let parentNode = this.getParentNode(node.id)
        return {
          selected: node,
          currentParent: parentNode,
          newParent: this.getParentNode(parentNode.id)
        }
      })
      return promotable
    }

    findUpdatableNeighbor (node, nodeIds) {
      let parentNode = this.getParentNode(node.id)
      let children = (parentNode) ? parentNode.children : this.models
      if (!children) {
        return undefined
      }
      let nodeIndex = children.indexOf(node)
      for (let i = nodeIndex - 1; i >= 0; i--) {
        let neighbor = children[i]
        if (neighbor.canUpdate && !_.contains(nodeIds, neighbor.id)) {
          return neighbor
        }
      }
      return undefined
    }

    deleteNode (node) {
      delete this.allNodes[node.id]
      delete this.nodeToParent[node.id]
    }

    deleteFromTree (node, executeListeners = true) {
      let parentNode = this.getParentNode(node.id)
      let children = (parentNode) ? parentNode.children : this.models
      let index = _.findIndex(children, (child) => {
        return node.id === child.id
      })
      if (index !== -1) {
        children.splice(index, 1)
        this.deleteNode(node)
        forEachRecursive.call(this, node.children, (child) => {
          this.deleteNode(child)
          return true
        })
      }
      if (executeListeners) {
        _.each(this.updateListeners, (listener) => {
          listener()
        })
      }
    }

    insertIntoTree (node, parent, executeListeners = true) {
      if (parent && !parent.children) {
        parent.children = []
      }
      let parentChildren = (parent) ? parent.children : this.models
      if (parentChildren.length === 0) {
        parentChildren.push(node)
      } else {
        let index = _.sortedIndex(parentChildren, node, 'nodeOrder')
        if (node.projectId && !parent && index !== 0 && parentChildren[index + 1] && !parentChildren[index + 1].projectId) {
          index = _.findIndex(parentChildren, (child) => {
            return child.projectId
          })
          if (index === -1) {
            index = parentChildren.length
          }
        }
        parentChildren.splice(index, 0, node)
      }
      this.insertNode(node, parent)
      forEachRecursive.call(this, node.children, (child) => {
        this.insertNode(child, node)
      })
      if (executeListeners) {
        _.each(this.updateListeners, (listener) => {
          listener()
        })
      }
    }

    insertNode (node, parent) {
      this.allNodes[node.id] = node
      if (parent) {
        this.nodeToParent[node.id] = parent.id
      }
    }

    getParentNode (nodeId) {
      return this.getNode(this.nodeToParent[nodeId])
    }

    getAllParentNodesInHierarchy (nodeId, includeStartingNode) {
      var parentNodes = includeStartingNode ? [this.getNode(nodeId)] : []
      var parentNode = this.getParentNode(nodeId)
      while (parentNode !== undefined) {
        parentNodes.push(parentNode)
        parentNode = this.getParentNode(parentNode.id)
      }

      return parentNodes
    }

    getNode (nodeId) {
      return this.allNodes[nodeId]
    }

    deleteNodes (nodeIds) {
      let selectedNodes = _.map(nodeIds, nodeId => {
        return this.getNode(nodeId)
      })
      return this.strategy.deleteSelectedNodes(selectedNodes)
    }

    deleteAllNodes () {
      return this.strategy.deleteAllNodes()
    }

    addUpdateListener (listener) {
      let self = this
      self.updateListeners.push(listener)
      let removeListener = function () {
        var index = self.updateListeners.indexOf(listener)
        self.updateListeners.splice(index, 1)
      }
      return removeListener
    }

    forEachNode (handler, nodes) {
      forEachRecursive.call(this, nodes || this.models, handler, undefined)
    }

    forEachSelectedNode (selected, handler) {
      forEachRecursive.call(this, selected, handler, undefined)
    }

    getTopmostSelectedNodeIds (selectedNodeIds) {
      let topLevelNodeIds = []
      _.each(selectedNodeIds, (nodeId) => {
        var parentNodeId = this.nodeToParent[nodeId]
        var finalNodeId = nodeId
        while (parentNodeId) {
          if (_.contains(selectedNodeIds, parentNodeId)) {
            finalNodeId = parentNodeId
          }
          parentNodeId = this.nodeToParent[parentNodeId]
        }
        if (!_.contains(topLevelNodeIds, finalNodeId)) {
          topLevelNodeIds.push(finalNodeId)
        }
      })

      return topLevelNodeIds
    }
  }

  class ProjectStrategy {
    constructor (org, project, tree, includeOrgTags) {
      this.org = org
      this.project = project
      this.projectId = project.id
      this.orgId = (org) ? org.id : undefined
      this.tree = tree
      this.includeOrgTags = includeOrgTags !== undefined ? includeOrgTags : true
      if (this.orgId === undefined) {
        this.includeOrgTags = false
      }
    }

    getKey () {
      return 'tag-tree-project-' + this.projectId
    }

    getTree () {
      return smartvidApi.getTagDefinitionTree(this.projectId, this.includeOrgTags)
    }

    getTagDefs () {
      return new TagDefCollection(this.orgId, this.projectId, this.includeOrgTags)
    }

    getTreeUpdateTime () {
      return smartvidApi.getProjectTagsTreeUpdateTime(this.projectId)
    }

    canImportTags () {
      return this.project.canImportTags
    }

    canExportTags () {
      return this.project.canImportTags
    }

    canAddTagDefs () {
      return this.project.canAddTagDefs
    }

    canDeleteTagDefs () {
      return this.project.canUploadAsset
    }

    importTags (file) {
      return smartvidApi.importTagsForProject(this.projectId, file)
    }

    createTagExportPackage () {
      let packageId = this.projectId + ((new Date()).getTime() / 1000) + '_tag_export'
      return smartvidApi.createTagExportPackageForProject(packageId, this.projectId, 'TAG_TREE_EXPORT')
    }

    getTagExportPackageStatus (packageId) {
      return smartvidApi.getTagExportPackageStatusForProject(this.projectId, packageId)
    }

    deleteSelectedNodes (selectedNodes) {
      let multiTagDefinitionSelection = {
        projectId: this.projectId,
        isAllTagDefinitions: false,
        selectedTagDefinitionIds: _.pluck(selectedNodes, 'tagDefinitionId')
      }
      return smartvidApi.deleteTagDefinitions(multiTagDefinitionSelection)
    }

    deleteAllNodes () {
      let multiTagDefinitionSelection = {
        projectId: this.projectId,
        isAllTagDefinitions: true,
        selectedTagDefinitionIds: []
      }
      return smartvidApi.deleteTagDefinitions(multiTagDefinitionSelection)
    }

    moveTagNode (payload) {
      return smartvidApi.moveProjectTagNode(this.projectId, payload)
    }

    createTag (tagName, isAllowAsr, isAllowImrec, isAllowManual, addedAliases, parentTag, ignoreError = false) {
      let defer = $q.defer()
      let customErrorHandler = null
      if (ignoreError) {
        customErrorHandler = (response) => {
          return response.errorCode === 'BAD_REQUEST'
        }
      }
      smartvidApi.createProjectTag(tagName, this.projectId, isAllowAsr, isAllowImrec, isAllowManual, addedAliases, customErrorHandler).then((tagDef) => {
        this.tree.getTagDefs().upsert([tagDef])
        let payload = {
          tagDefinitionId: tagDef.id,
          parentTagDefinitionId: (parentTag) ? parentTag.tagDefinitionId : undefined
        }
        smartvidApi.createProjectTagTreeNode(this.projectId, payload).then((tagTreeNode) => {
          defer.resolve(tagTreeNode)
        }, (response) => {
          defer.reject(response)
        })
      }, (response) => {
        defer.reject(response)
      })
      return defer.promise
    }

    deleteTag (tagDefinitionId) {
      return smartvidApi.deleteTagNode(this.projectId, tagDefinitionId)
    }

    getAssetCountByTagDefs (tagDefinitionIds) {
      return smartvidApi.getAssetCountByTagDefsForProject(this.projectId, tagDefinitionIds)
    }

    getTagInstancesCountsGroupedByType (tagDefinitionId) {
      return smartvidApi.getTagInstancesCountsGroupedByTypeForProject(this.projectId, tagDefinitionId)
    }
  }

  class OrgStrategy {
    constructor (org, tree) {
      this.org = org
      this.orgId = org.id
      this.tree = tree
    }

    getKey () {
      return 'tag-tree-org-' + this.orgId
    }

    getTree () {
      return smartvidApi.getTagDefinitionTreeForOrg(this.orgId)
    }

    getTreeUpdateTime () {
      return smartvidApi.getOrgTagsTreeUpdateTime(this.orgId)
    }

    canImportTags () {
      return this.org.canImportTags
    }

    importTags (file) {
      return smartvidApi.importTagsForOrg(this.orgId, file)
    }

    canExportTags () {
      return this.org.canImportTags
    }

    createTagExportPackage () {
      let packageId = this.orgId + ((new Date()).getTime() / 1000) + '_tag_export'
      return smartvidApi.createTagExportPackageForOrg(packageId, this.orgId, 'TAG_TREE_EXPORT')
    }

    getTagExportPackageStatus (packageId) {
      return smartvidApi.getTagExportPackageStatusForOrg(this.orgId, packageId)
    }

    canAddTagDefs () {
      return this.org.canAddTagDefs
    }

    canDeleteTagDefs () {
      return this.org.canAddTagDefs
    }

    deleteSelectedNodes (selectedNodes) {
      let multiTagDefinitionSelection = {
        organizationId: this.orgId,
        isAllTagDefinitions: false,
        selectedTagDefinitionIds: _.pluck(selectedNodes, 'tagDefinitionId')
      }
      return smartvidApi.deleteTagDefinitions(multiTagDefinitionSelection)
    }

    deleteAllNodes () {
      let multiTagDefinitionSelection = {
        organizationId: this.orgId,
        isAllTagDefinitions: true,
        selectedTagDefinitionIds: []
      }
      return smartvidApi.deleteTagDefinitions(multiTagDefinitionSelection)
    }

    getTagDefs () {
      return new TagDefCollection(this.orgId)
    }

    moveTagNode (payload) {
      return smartvidApi.moveOrgTagNode(this.orgId, payload)
    }

    createTag (tagName, isAllowAsr, isAllowImrec, isAllowManual, addedAliases, parentTag) {
      let defer = $q.defer()
      smartvidApi.createOrgTag(tagName, this.orgId, isAllowAsr, isAllowImrec, isAllowManual, addedAliases).then((tagDef) => {
        this.tree.getTagDefs().upsert([tagDef])
        let payload = {
          tagDefinitionId: tagDef.id,
          parentTagDefinitionId: (parentTag) ? parentTag.tagDefinitionId : undefined
        }
        smartvidApi.createOrgTagTreeNode(this.orgId, payload).then((tagTreeNode) => {
          defer.resolve(tagTreeNode)
        }, (response) => {
          defer.reject(response)
        })
        smartvidApi
      }, (response) => {
        defer.reject(response)
      })
      return defer.promise
    }

    deleteTag (tagDefinitionId) {
      return smartvidApi.deleteOrgTagNode(this.orgId, tagDefinitionId)
    }

    getAssetCountByTagDefs (tagDefinitionIds) {
      return smartvidApi.getAssetCountByTagDefsForOrg(this.orgId, tagDefinitionIds)
    }

    getTagInstancesCountsGroupedByType (tagDefinitionId) {
      return smartvidApi.getTagInstancesCountsGroupedByTypeForOrg(this.orgId, tagDefinitionId)
    }
  }

  return TagNodesCollection
})
