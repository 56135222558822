/* global angular, _ */

angular.module('smartvid').directive('selectAllTreeNodes', function (hotkeys, $rootScope) {
  return {
    restrict: 'A',
    replace: true,
    link: function (scope, element, attrs) {
      let collection = null
      let waitFor = scope[attrs['selectAllWaitFor']]
      let allSelected = false

      if (waitFor) {
        waitFor.then(() => {
          collection = scope[attrs['selectAllTreeNodes']]
        })
      } else {
        collection = scope[attrs['selectAllTreeNodes']]
      }

      let selectAll = (e) => {
        // Don't select all if the collection is already selected or is empty
        collection = scope[attrs['selectAllTreeNodes']]
        allSelected = !!(!allSelected && collection.length)

        let selectedNodes = []

        recursiveEach(collection, (model) => {
          model.selected = allSelected && model.canUpdate
          selectedNodes.push(model)
        })

        $rootScope.$broadcast('sv-tag-def-node-selected', selectedNodes)
        e.preventDefault()
        return false
      }

      function recursiveEach (collection, iteratee = _.identity) {
        _.each(collection, function (model) {
          iteratee(model)
          if (!_.isEmpty(model.children)) {
            recursiveEach(model.children, iteratee)
          }
        })
      }

      hotkeys.add({
        combo: 'command+a',
        callback: selectAll
      })

      hotkeys.add({
        combo: 'ctrl+a',
        callback: selectAll
      })
    }
  }
})

