<div class="controlbar"
     [ngClass]="{ 'left-nav-open': NavController.leftNavOpen, 'right-nav-open': NavController.rightNavOpen }">

    <mat-toolbar>
        <mat-toolbar-row>
            <sv-left-nav-button></sv-left-nav-button>

            <ul class="icon-list">
                <li class="icon-container">
                    <div class="svg-container">
                        <mat-icon class="icon fill-grey" svgIcon="icon-reports" (click)="runReport()"></mat-icon>
                    </div>
                </li>
            </ul>

        </mat-toolbar-row>
    </mat-toolbar>

</div>
