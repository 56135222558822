/* global angular */

angular.module('smartvid').controller('GroupOptionsCtrl', function (
    $scope, $rootScope, dashboardDataHelper, assetGridHelper
) {
  $scope.isGroupedBy = (method, order) => {
    let state = assetGridHelper.readAssetGridState(dashboardDataHelper.getCurrentProject().id)
    return method === state.groupByColumn && (!order || order === state.groupByOrder)
  }
  $scope.groupBy = (method, order = 'DESC') => {
    $rootScope.$broadcast('assetGrid:groupBy', ...[method, order])
    $scope.flyout.close()
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top,
      left: $scope.styles.left,
      width: 200
    }
  }
})
