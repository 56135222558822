import { Observable } from 'rxjs'
import { Inject, Injectable } from '@angular/core'
import { ConfigService } from 'modules/core/services/config.service'
import { HTTP } from 'shared/smartvid.types'
import { toObservableArray } from 'shared/utils/api-utils'

export class ForgeFolder {
  public id: string
  public name: string
  public expandable: boolean
}

export class ForgeTemplate {
  public id: string
  public title: string
}

export class ForgeIssueType {
  public issueTypeId: string
  public title: string
  public subtypes?: ForgeIssueType[]
}

@Injectable({
  providedIn: 'root',
})
export class ForgeService {
  rootUrl: string

  constructor(@Inject(HTTP) private $http: ng.IHttpService, private config: ConfigService) {
    this.rootUrl = this.config.env.development.apiRootUrl
  }

  getRootFolders(projectId: string): Observable<ForgeFolder[]> {
    let url = this.rootUrl + `/api/integration/project/${projectId}/forge/folder`
    return toObservableArray<ForgeFolder>(this.$http.get(url), ForgeFolder)
  }

  getSubFolders(projectId: string, folderId: string): Observable<ForgeFolder[]> {
    let url = this.rootUrl + `/api/integration/project/${projectId}/forge/folder/${folderId}`
    return toObservableArray<ForgeFolder>(this.$http.get(url), ForgeFolder)
  }

  getTemplates(projectId: string, title: string, offset: number, limit: number): Observable<ForgeTemplate[]> {
    let url =
      this.rootUrl + `/api/integration/project/${projectId}/forge/checklist/templates?offset=${offset}&limit=${limit}`
    if (title && title.length > 0) {
      url = url + '&title=' + title
    }
    return toObservableArray<ForgeTemplate>(this.$http.get(url), ForgeTemplate)
  }

  getIssueTypes(projectId: string): Observable<ForgeIssueType[]> {
    let url = this.rootUrl + `/api/integration/project/${projectId}/forge/issue/type`
    return toObservableArray<ForgeIssueType>(this.$http.get(url), ForgeIssueType)
  }
}
