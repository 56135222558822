import { InjectionToken } from '@angular/core'
import { AssetsCollection } from './models/AssetsCollection'
import { Asset } from './models/Asset'
import { ModuleType } from 'shared/models/module-type'

export const CURRENT_USER = new InjectionToken<CurrentUser>('smartvid.currentUser')
export const HTTP = new InjectionToken<ng.IHttpService>('smartvid.http')
export const SMARTVID_API = new InjectionToken<DashboardDataHelper>('smartvid.smartvidApi')
export const FILE_UPLOAD_SERVICE = new InjectionToken<FileUploadService>('smartvid.fileUploadService')
export const ASSETS_COLLECTION_PRODUCER = new InjectionToken<AssetsCollectionProducer>(
  'smartvid.assetsCollectionProducer'
)
export const ASSET_PRODUCER = new InjectionToken<AssetProducer>('smartvid.assetProducer')
export const NAV_CONTROLLER = new InjectionToken<AssetProducer>('smartvid.navController')
export const UTILS = new InjectionToken<Utils>('smartvid.utils')
export const PROJECT_FAVORITE_SERVICE = new InjectionToken<ProjectFavoriteService>('smartvid.projectFavoriteService')
export const SEARCH_SERVICE = new InjectionToken<SearchService>('smartvid.searchService')

export interface CurrentUser {
  id: string
  email: string
  username: string
  firstName: string
  lastName: string
  isActive: boolean
  isInvited: boolean
  token: string
  organizations: any[]
  observationRegionRoles: any[]
  regionRoles: any[]
  organizationRoles: any[]
  projectRoles: any[]
  observationOrganizationRoles: any[]
  observationProjectRoles: any[]

  isAuthenticated(): boolean

  getSupportedFileExtensions(): any[]

  isPtpFeatureEnabledForAnyOrganization()

  isObservationFeatureEnabledForAnyOrganization()

  isAnalyticsFeatureEnabledForAnyOrganization()

  isAnalyticsFeatureEnabledForOrganization(organizationId)

  isAnalyticsSafetyMonitoringFeatureEnabledForOrganization(organizationId)

  isAnalyticsSafetyObservationsFeatureEnabledForOrganization(organizationId)

  isAnalyticsPredictiveFeatureEnabledForOrganization(organizationId)

  isObservationEnabledForOrganization(organizationId)

  getCreatableOrganizationRoles(organizationId)
  getCreatableOrganizationObservationRoles(organizationId)

  getAssignableProjectRoles(project)
  getAssignableObservationProjectRoles(project)
}

export interface DashboardDataHelper {
  getAllOrganizations(): any

  getAllProjects(): any
}

export interface SmartvidApi {
  getIntegrationSettingsForOrganization(organizationId: string)
  updateIntegrationSettingsForOrganization(request: any, organizationId: string)

  getUsersByPartialEmailForOrg(organizationId: string, email: string)

  getOrganizationUsers(organizationId: string)
  getAsset(
    assetId: string,
    includeMetaData: boolean,
    includeTags: boolean,
    includeComments: boolean,
    includeOrgData: boolean,
    tagConfidenceLevel: string,
    includeLinkedObservations: boolean
  )
  getAssetsByIds(assetIds: string[])

  addUsersToProject(projectId: string, users: any[])

  getUsersByPartialEmailForOrgs(organizationIds: string[], email: string)

  findAssigneeUsersByPartialEmailInProject(projectId: string, email: string)
  findAssigneeUsersByPartialEmailInOrganization(projectId: string, email: string)
  searchOrgUsers(organizationId: string, term: string, options: {})
  deepSearchOrganizationUsersByEmailForModule(
    organizationId: string,
    excludedProjectRoles: string[],
    excludedProjectGroupRoles: string[],
    excludedOrgRoles: string[],
    term: string,
    module: ModuleType,
    options: {}
  )
  searchOrgUsersByEmailForModule(
    organizationId: string,
    excludedOrgRoles: string[],
    term: string,
    module: ModuleType,
    options: {}
  )
  searchProjectUsersByEmailForModule(
    projectId: string,
    excludedProjectRoles: string[],
    excludedProjectGroupRoles: string[],
    excludedOrgRoles: string[],
    term: string,
    module: ModuleType,
    options: {}
  )

  createUser(organizationId: string, users: any[])
  getUser(organizationId: string, userId: string)

  ensureAdminMode()

  getOAuthUrlForProvider(provider: string, userId: string)
}

export interface FileUploadService {
  upload(
    files,
    projectId,
    isDemoProject,
    tagDefIds,
    doAsr,
    doImrec,
    checkForDups,
    isBulkUpload,
    isRetry,
    fileToFolderPathTagDefs
  )
  uploadToS3(files, projectId)
  uploadFileToS3(file, projectId, totalCount)
  createAsset(fileInfo, folderPathTagDefs)
  deletePhoto(fileInfo)
}

export interface ProjectFavoriteService {
  favorite(project: any, projects: any)
  unfavorite(project: any, projects: any)
  addListener(listener): any
}

export interface AssetsCollectionProducer {
  createAssetsCollection(projectId: object, options: object, params: object, assetViewType: string): AssetsCollection
}

export interface AssetProducer {
  createAsset(attrs: object): Asset
}

export interface NavController {
  isLeftNavOpen()
  isRightNavOpen()
  openLeftNav()
  openRightNav()
  closeLeftNav()
  closeRightNav()
  toggleLeftNav()
  toggleRightNav()
}

export interface Utils {
  notify(mainMessage: string, subMessage?: string, handlerLabel?: string, userClose?: Function, handler?: Function)
  clearNotifications()
}

export interface SearchService {
  searchWithAssetSearchContext(assetSearchContext)
  searchWithObservationSearchContext(observationSearchContext)
}
