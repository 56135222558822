/* global angular _ $ PROCORE_INTEGRATION_UI_ENABLED */

angular.module('smartvid').directive('projectIntegrationsProcore',
  function (utils, smartvidApi, Notification, currentUser,
            ProcoreUserModel, ProcoreCompanyModel, ProcoreProjectModel, ProcoreAlbumModel, ProcorePhotoSyncSettingsModel,
            ProcoreCompanyCollection, ProcoreProjectCollection, ProcoreAlbumCollection,
            moment, $rootScope, $timeout, $window, $interval, $filter, $interpolate, $stateParams, $state, dashboardDataHelper) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'integrations/project-integrations-procore.html',
      link: function (scope) {
        scope.project = dashboardDataHelper.getCurrentProject()
        if (!scope.project.canUpdate) {
          $state.go('unauthorized')
          return
        }

        const PROCORE = 'PROCORE'
        const FIXED_ALBUM_DEFAULT_NAME = 'Smartvid'
        const FIXED_NAME = {value: 'FIXED_NAME', name: ''}
        const EXISTING_ALBUM = {value: 'EXISTING_ALBUM', name: ''}
        const NEW_WEEKLY = {value: 'NEW_WEEKLY', name: ''}
        const RATE_LIMIT_REACHED = 'RATE_LIMIT_REACHED'
        const NOT_FOUND = 'NOT_FOUND'
        const PROCORE_USER_INACTIVE_MESSAGE = 'procore.user.inactive'
        const NOT_SELECTED_ID = -1
        const COMPANY_DATA = 'company_data'
        const PROJECT_DATA = 'project_data'
        const ALBUM_DATA = 'album_data'
        const ALBUM_HANDLING_DATA = 'album_data_handling'
        const TWO_WAY_SYNC = 'TO_AND_FROM_PROCORE'
        const ONE_WAY_SYNC_FROM_PROCORE = 'FROM_PROCORE'
        var WEEKLY_ALBUM_DEFAULT_PREFIX
        var LAST_SYNC_NEVER_LABEL
        var NEXT_SYNC_NA
        var SYNC_PENDING_LABEL
        var SYNC_IN_PROGRESS_LABEL
        scope.COMPANIES_SELECT = 'companies'
        scope.PROJECTS_SELECT = 'projects'
        scope.ALBUMS_SELECT = 'albums'
        scope.ALBUMS_HANDLING_SELECT = 'albums-handling'
        scope.currentUserAuthenticatedWithProcore = undefined
        scope.isProcoreIntegrationEnabledByCurrentUser = false
        scope.isProcoreIntegrationEnabledByOtherUser = false
        scope.procoreIntegrationNotEnabled = true
        scope.waitingForProcoreIntegrationStatus = true
        scope.projectLinkToggleInTransit = false
        scope.connectedProcoreUser = undefined
        scope.isLoadingData = true
        scope.isWaitingForRunOnce = false
        scope.connectionStatus = ''
        scope.rateLimitMessage = ''
        scope.procoreCompanies = []
        scope.procoreProjects = []
        scope.procoreAlbums = []
        scope.photoSyncTypes = [FIXED_NAME, EXISTING_ALBUM, NEW_WEEKLY]
        scope.photoSyncSettings = {}
        // RE: http://stackoverflow.com/questions/19408883/angularjs-select-not-2-way-binding-to-mode
        scope.selectedData = {}
        scope.selectedData.procoreSelectedCompany = {}
        scope.selectedData.procoreSelectedProject = {}
        scope.selectedData.procoreSelectedAlbum = {}
        scope.selectedData.selectedPhotoSyncType = NEW_WEEKLY
        scope.selectedData.twoWaySyncEnabled = false
        scope.selectedData.onlyPushTagsToProcore = false
        scope.selectedData.downloadLocations = false
        scope.dateRanges = [
          {
            name: 'None',
            value: 'NONE'
          },
          {
            name: '7 Days',
            value: '7_DAYS'
          },
          {
            name: '30 Days',
            value: '30_DAYS'
          },
          {
            name: '60 Days',
            value: '60_DAYS'
          },
          {
            name: 'All',
            value: 'ALL'
          }
        ]
        scope.selectedData.historicalDataRange = scope.dateRanges[0]
        scope.lastSyncDate = ''
        scope.hasRateLimitError = false
        scope.procoreUserInactiveError = false
        scope.rateLimitResetsAt = undefined
        let smartvidProjectId = $stateParams.projectId

        scope.$watch('selectedData.twoWaySyncEnabled', (oldValue, newValue) => {
          if (scope.selectedData.onlyPushTagsToProcore) {
            scope.selectedData.onlyPushTagsToProcore = newValue
            scope.selectedData.twoWaySyncEnabled = oldValue
          }
        })

        scope.$watch('selectedData.onlyPushTagsToProcore', (oldValue, newValue) => {
          if (scope.selectedData.twoWaySyncEnabled) {
            scope.selectedData.twoWaySyncEnabled = newValue
            scope.selectedData.onlyPushTagsToProcore = oldValue
          }
        })

        function getCompaniesPlaceholder (hasProcoreError) {
          return new ProcoreCompanyModel({
            id: NOT_SELECTED_ID,
            name: (hasProcoreError ? $filter('i18next')('integrations.procore.unavailablePlaceholder') : $filter('i18next')('integrations.procore.companiesPlaceholder'))
          })
        }

        function getProjectsPlaceholder (hasProcoreError) {
          return new ProcoreProjectModel({
            id: NOT_SELECTED_ID,
            display_name: (hasProcoreError ? $filter('i18next')('integrations.procore.unavailablePlaceholder') : $filter('i18next')('integrations.procore.projectsPlaceholder'))
          })
        }

        function getAlbumsPlaceholder (hasProcoreError) {
          return new ProcoreAlbumModel({
            id: NOT_SELECTED_ID,
            name: (hasProcoreError ? $filter('i18next')('integrations.procore.unavailablePlaceholder') : $filter('i18next')('integrations.procore.albumsPlaceholder'))
          })
        }

        function resetDefaults (hasProcoreError, defaultsType, keepHistoricalData) {
          if (!defaultsType || defaultsType.includes(COMPANY_DATA)) {
            scope.selectedData.procoreSelectedCompany = getCompaniesPlaceholder(hasProcoreError)
            scope.procoreCompanies = new ProcoreCompanyCollection([scope.selectedData.procoreSelectedCompany])
          }

          if (!defaultsType || defaultsType.includes(PROJECT_DATA)) {
            scope.selectedData.procoreSelectedProject = getProjectsPlaceholder(hasProcoreError)
            scope.procoreProjects = new ProcoreProjectCollection([scope.selectedData.procoreSelectedProject])
          }

          if (!defaultsType || defaultsType.includes(ALBUM_DATA)) {
            scope.selectedData.procoreSelectedAlbum = getAlbumsPlaceholder(hasProcoreError)
            scope.procoreAlbums = new ProcoreAlbumCollection([scope.selectedData.procoreSelectedAlbum])
          }

          if (!defaultsType || defaultsType.includes(ALBUM_HANDLING_DATA)) {
            scope.selectedData.selectedPhotoSyncType = NEW_WEEKLY
            scope.photoSyncSettings.fixedAlbumName = WEEKLY_ALBUM_DEFAULT_PREFIX
          }

          scope.selectedData.twoWaySyncEnabled = false
          scope.selectedData.onlyPushTagsToProcore = false
          scope.selectedData.downloadLocations = false

          if (!keepHistoricalData) {
            scope.selectedData.historicalDataRange = scope.dateRanges[0]
          }
        }

        let hasErrorMessageWithLable = (messages, label) => {
          return messages ? _.find(messages,
            (m) => {
              return m.label === label
            }) !== undefined : false
        }

        let customErrorHandler = (response) => {
          if (response && response.errorCode === RATE_LIMIT_REACHED) {
            scope.rateLimitResetsAt = response.customData ? response.customData.rateLimitResetsAt : undefined

            scope.hasRateLimitError = true
            resetDefaults(scope.hasRateLimitError)
            adjustConnectionStatus()
            repositionRateLimitMessage()

            return true
          } else if (response && response.errorCode === NOT_FOUND && hasErrorMessageWithLable(response.errorMessages, PROCORE_USER_INACTIVE_MESSAGE)) {
            scope.procoreUserInactiveError = true

            resetDefaults(scope.procoreUserInactiveError)
            adjustConnectionStatus()

            return true
          }

          return false
        }

        let setNoProjectsInProcoreState = () => {
          scope.selectedData.procoreSelectedProject = new ProcoreProjectModel({
            id: NOT_SELECTED_ID,
            display_name: $filter('i18next')('integrations.procore.noProjectsPlaceholder')
          })
          scope.procoreProjects = new ProcoreProjectCollection([scope.selectedData.procoreSelectedProject])
        }

        let checkProcoreIntegrationStatusAndLoadData = (isAfterOAuth) => {
          smartvidApi.getOAuthUserDataForProviderForCurrentUser(PROCORE).then((data) => {
            scope.waitingForProcoreIntegrationStatus = false
            // TODO: handle multiple procore identities
            scope.currentUserAuthenticatedWithProcore = data.length > 0 ? data[0] : undefined
            if (!scope.currentUserAuthenticatedWithProcore || scope.currentUserAuthenticatedWithProcore.isTokenValid) {
              loadPageData(isAfterOAuth)
            } else {
              resetDefaults(true)
              adjustConnectionStatus()
              scope.isLoadingData = false
              scope.waitingForProcoreIntegrationStatus = false
            }
          }, () => {
            scope.isLoadingData = false
            scope.waitingForProcoreIntegrationStatus = false
            Notification.error($filter('i18next')('directives.mysettings.failedToCheckProcoreIntegrationStatus'))
          })
        }

        let adjustConnectionStatus = () => {
          if (scope.currentUserAuthenticatedWithProcore && !scope.currentUserAuthenticatedWithProcore.isTokenValid) {
            scope.connectionStatus = $interpolate($filter('i18next')('integrations.procore.connection.tokenInvalid'))({
              tokenFillBeDeletedAt: scope.currentUserAuthenticatedWithProcore.invalidTokenWillBeRemovedAtDate > 0 ? moment(scope.currentUserAuthenticatedWithProcore.invalidTokenWillBeRemovedAtDate).format('MMM DD, YYYY hh:mmA') : $filter('i18next')('integrations.procore.connection.tokenInvalid.deletion.unknown')
            })
          } else if (scope.isProcoreIntegrationEnabledByCurrentUser && !scope.hasRateLimitError && !scope.procoreUserInactiveError) {
            let procoreUserName = scope.connectedProcoreUser ? (scope.connectedProcoreUser.first_name + ' ' + scope.connectedProcoreUser.last_name) : ''
            scope.connectionStatus = $interpolate($filter('i18next')('integrations.procore.connection.connectedAsProcoreUser'))({
              procoreUser: procoreUserName
            })
          } else if (scope.isProcoreIntegrationEnabledByOtherUser) {
            scope.connectionStatus = $interpolate($filter('i18next')('integrations.procore.connection.connectedAsSmartvidUser'))({
              smartvidUser: ''
            })
          } else if (scope.hasRateLimitError) {
            if (scope.photoSyncSettings.procoreUserName) {
              scope.connectionStatus = $interpolate($filter('i18next')('integrations.procore.connection.connectedButRateLimitedWithUserName'))({
                rateLimitResetsAt: scope.rateLimitResetsAt ? moment(scope.rateLimitResetsAt).format('MMM DD, YYYY hh:mmA') : $filter('i18next')('integrations.procore.unavailablePlaceholder'),
                procoreUser: scope.photoSyncSettings.procoreUserName
              })
            } else {
              scope.connectionStatus = $interpolate($filter('i18next')('integrations.procore.connection.connectedButRateLimited'))({
                rateLimitResetsAt: scope.rateLimitResetsAt ? moment(scope.rateLimitResetsAt).format('MMM DD, YYYY hh:mmA') : $filter('i18next')('integrations.procore.unavailablePlaceholder')
              })
            }

            scope.rateLimitMessage = $interpolate($filter('i18next')('integrations.procore.connection.connectedButRateLimitedMessage'))({
              rateLimitResetsAt: scope.rateLimitResetsAt ? moment(scope.rateLimitResetsAt).format('MMM DD, YYYY hh:mmA') : $filter('i18next')('integrations.procore.unavailablePlaceholder')
            })
          } else if (scope.procoreUserInactiveError) {
            var procoreUserName = $filter('i18next')('integrations.procore.connection.unknownUser')
            if (scope.connectedProcoreUser && scope.connectedProcoreUser.first_name && scope.connectedProcoreUser.last_name) {
              procoreUserName = scope.connectedProcoreUser ? (scope.connectedProcoreUser.first_name + ' ' + scope.connectedProcoreUser.last_name) : ''
            } else if (scope.photoSyncSettings && scope.photoSyncSettings.procoreUserName) {
              procoreUserName = scope.photoSyncSettings.procoreUserName
            }

            scope.connectionStatus = $interpolate($filter('i18next')('integrations.procore.connection.inactiveProcoreUser'))({
              procoreUser: procoreUserName
            })
          }
        }

        let getProcoreUserId = () => {
          if (scope.isProcoreIntegrationEnabledByOtherUser && scope.photoSyncSettings.procoreUserId !== undefined) {
            return scope.photoSyncSettings.procoreUserId
          } else if (scope.currentUserAuthenticatedWithProcore !== undefined) {
            return scope.currentUserAuthenticatedWithProcore.externalUserId
          } else {
            return undefined
          }
        }

        let loadProcoreUserProfile = (afterProfileLoadedFunc) => {
          let procoreUserId = getProcoreUserId()
          if (procoreUserId) {
            smartvidApi.getProcoreUserProfile(smartvidProjectId, customErrorHandler).then((data) => {
              scope.connectedProcoreUser = new ProcoreUserModel(data)
              if (afterProfileLoadedFunc) {
                afterProfileLoadedFunc()
              }
            }, () => {
              scope.isLoadingData = false
              scope.waitingForProcoreIntegrationStatus = false
              Notification.error($filter('i18next')('integrations.procore.errors.failedToLoadPhotoSyncSettings'))
            })
          } else if (afterProfileLoadedFunc) {
            afterProfileLoadedFunc()
          }
        }

        let continueLoadingPageData = () => {
          adjustConnectionStatus()
          scope.selectedData.onlyPushTagsToProcore = scope.photoSyncSettings.onlyPushTagsToProcore
          scope.selectedData.twoWaySyncEnabled = scope.photoSyncSettings.onlyPushTagsToProcore ? false : scope.photoSyncSettings.photoSyncDirection === TWO_WAY_SYNC
          scope.selectedData.downloadLocations = scope.photoSyncSettings.isDownloadLocations
          scope.selectedData.selectedPhotoSyncType = _.find(scope.photoSyncTypes,
            (t) => {
              return t.value === scope.photoSyncSettings.albumHandlingStrategy
            }) || NEW_WEEKLY
          scope.adjustAlbumPrefixIfNeeded()

          if (scope.isProcoreIntegrationEnabledForProject()) {
            loadProcoreData()
          } else {
            scope.isLoadingData = false
          }
        }

        let prepareSyncSettingsIfNeededAndContinueLoadingData = (isAfterOAuth) => {
          if (scope.isProcoreIntegrationEnabledForProject()) {
            loadProcoreUserProfile(continueLoadingPageData)
          } else if (scope.currentUserAuthenticatedWithProcore && isAfterOAuth) {
            // reset the sync settings after authentication with procore
            scope.photoSyncSettings.isEnabled = false
            scope.photoSyncSettings.isDownloadLocations = true
            scope.photoSyncSettings.userId = scope.currentUserAuthenticatedWithProcore.userId
            scope.photoSyncSettings.procoreUserId = scope.currentUserAuthenticatedWithProcore.externalUserId
            scope.photoSyncSettings.onlyPushTagsToProcore = scope.selectedData.onlyPushTagsToProcore

            smartvidApi.saveProcorePhotoSyncSettings(smartvidProjectId, scope.photoSyncSettings).then(() => {
              scope.isProcoreIntegrationEnabledByCurrentUser = true
              scope.procoreIntegrationNotEnabled = false
              loadProcoreUserProfile(continueLoadingPageData)
            }, () => {
              scope.isLoadingData = false
              scope.waitingForProcoreIntegrationStatus = false
              Notification.error($filter('i18next')('integrations.procore.errors.failedToLoadPhotoSyncSettings'))
            })
          } else {
            scope.adjustAlbumPrefixIfNeeded()
            scope.isLoadingData = false
          }
        }

        let loadPageData = (isAfterOAuth) => {
          smartvidApi.getProcorePhotoSyncSettingsForProject(smartvidProjectId, customErrorHandler).then((data) => {
            scope.photoSyncSettings = new ProcorePhotoSyncSettingsModel(data)
            scope.waitingForProcoreIntegrationStatus = false
            scope.isProcoreIntegrationEnabledByOtherUser = scope.isConnectedByAnotherProcoreUser() || scope.isConnectedByAnotherSmartvidUser()
            scope.isProcoreIntegrationEnabledByCurrentUser = scope.isConnectedByCurrentlyAuthenticatedProcoreUser() && scope.isConnectedByCurrentSmartvidUser()
            scope.procoreIntegrationNotEnabled = !scope.photoSyncSettings.userId

            if (scope.photoSyncSettings.rateLimitInfo) {
              scope.isLoadingData = false
              scope.rateLimitResetsAt = scope.photoSyncSettings.rateLimitInfo.rateLimitResetsAt
              scope.hasRateLimitError = true
              resetDefaults(scope.hasRateLimitError)
              adjustConnectionStatus()
              repositionRateLimitMessage()
            } else {
              prepareSyncSettingsIfNeededAndContinueLoadingData(isAfterOAuth)
            }

            scope.selectedData.historicalDataRange = _.find(scope.dateRanges, (dr) => {
              return dr.value === scope.photoSyncSettings.historicalDataSyncDateRange
            }) || scope.dateRanges[0]

          }, () => {
            scope.isLoadingData = false
            scope.waitingForProcoreIntegrationStatus = false
            Notification.error($filter('i18next')('integrations.procore.errors.failedToLoadPhotoSyncSettings'))
          })
        }

        let loadProcoreData = () => {
          smartvidApi.listProcoreCompanies(smartvidProjectId, customErrorHandler).then((data) => {
            scope.procoreCompanies = new ProcoreCompanyCollection(_.sortBy(data, 'name'))
            scope.procoreCompanies.models.unshift(getCompaniesPlaceholder(false))
            if (scope.photoSyncSettings.procoreCompanyId) {
              scope.selectedData.procoreSelectedCompany = _.find(scope.procoreCompanies.models, (c) => {
                return c.id === scope.photoSyncSettings.procoreCompanyId
              })
              if (!scope.selectedData.procoreSelectedCompany) {
                scope.selectedData.procoreSelectedCompany = scope.procoreCompanies.first()
              }
            } else if (!scope.procoreCompanies.isEmpty) {
              scope.selectedData.procoreSelectedCompany = scope.procoreCompanies.first()
            }

            if (scope.procoreCompanies.length > 1) {
              loadProcoreProjects()
            } else {
              scope.isLoadingData = false
            }
          }, () => {
            scope.isLoadingData = false
          })
        }

        let loadProcoreProjects = () => {
          if (!scope.selectedData.procoreSelectedCompany || scope.selectedData.procoreSelectedCompany.id === NOT_SELECTED_ID) {
            scope.isLoadingData = false
            return
          }

          let companyId = scope.selectedData.procoreSelectedCompany.id
          smartvidApi.listProcoreProjects(smartvidProjectId, companyId, customErrorHandler).then((data) => {
            scope.procoreProjects = new ProcoreProjectCollection(_.sortBy(data, 'display_name'))

            if (scope.procoreProjects.isEmpty) {
              setNoProjectsInProcoreState()
              scope.isLoadingData = false
            } else {
              scope.procoreProjects.models.unshift(getProjectsPlaceholder(false))
              if (scope.photoSyncSettings.procoreProjectId) {
                scope.selectedData.procoreSelectedProject = _.find(scope.procoreProjects.models, (p) => {
                  return p.id === scope.photoSyncSettings.procoreProjectId
                })
                if (!scope.selectedData.procoreSelectedProject) {
                  scope.selectedData.procoreSelectedProject = scope.procoreProjects.first()
                }
              } else if (!scope.procoreProjects.isEmpty) {
                scope.selectedData.procoreSelectedProject = scope.procoreProjects.first()
              }

              if (scope.procoreProjects.length > 1) {
                loadProcoreAlbums()
              } else {
                scope.isLoadingData = false
              }
            }
          }, () => {
            scope.isLoadingData = false
          })
        }

        let loadProcoreAlbums = () => {
          if (!scope.selectedData.procoreSelectedProject || scope.selectedData.procoreSelectedProject.id === NOT_SELECTED_ID) {
            scope.isLoadingData = false
            return
          }

          let procoreCompanyId = scope.selectedData.procoreSelectedCompany.id
          let procoreProjectId = scope.selectedData.procoreSelectedProject.id
          smartvidApi.listProcoreAlbums(smartvidProjectId, procoreCompanyId, procoreProjectId, customErrorHandler).then((data) => {
            scope.procoreAlbums = new ProcoreAlbumCollection(_.sortBy(data, 'name'))
            scope.procoreAlbums.models.unshift(getAlbumsPlaceholder(false))
            if (scope.photoSyncSettings.procoreAlbumId) {
              scope.selectedData.procoreSelectedAlbum = _.find(scope.procoreAlbums.models, (a) => {
                return a.id === scope.photoSyncSettings.procoreAlbumId
              })
              if (!scope.selectedData.procoreSelectedAlbum) {
                scope.selectedData.procoreSelectedAlbum = scope.procoreAlbums.first()
              }
            } else if (!scope.procoreProjects.isEmpty) {
              scope.selectedData.procoreSelectedAlbum = scope.procoreAlbums.first()
            }

            scope.isLoadingData = false
          }, () => {
            scope.isLoadingData = false
          })
        }

        let repositionRateLimitMessage = () => {
          let syncStatusElem = $('.integration-status-and-sync')
          let rateLimitMessageElem = $('.rate-limit-message')
          rateLimitMessageElem.css({
            position: 'absolute',
            top: (syncStatusElem.position().top - 12) + 'px',
            left: (syncStatusElem.position().left + syncStatusElem.outerWidth() + 10) + 'px'
          })
        }

        // initialize page
        $timeout(() => {
          FIXED_NAME.name = $filter('i18next')('integrations.procore.fixedAlbumName')
          EXISTING_ALBUM.name = $filter('i18next')('integrations.procore.existingAlbum')
          NEW_WEEKLY.name = $filter('i18next')('integrations.procore.newWeekly')
          scope.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
          WEEKLY_ALBUM_DEFAULT_PREFIX = $filter('i18next')('integrations.procore.weeklyAlbumPrefix')
          LAST_SYNC_NEVER_LABEL = $filter('i18next')('integrations.lastSyncNever')
          NEXT_SYNC_NA = $filter('i18next')('integrations.nextSyncNA')
          SYNC_PENDING_LABEL = $filter('i18next')('integrations.syncPending')
          SYNC_IN_PROGRESS_LABEL = $filter('i18next')('integrations.syncInProgress')
          resetDefaults()
          checkProcoreIntegrationStatusAndLoadData(false)
        }, 10)

        // setup rate limit message positioning listeners
        $(window).on('resize.doResize', () => {
          repositionRateLimitMessage()
        })

        let integrationContainer = $('.entity-integrations')
        integrationContainer.on('scroll.doScroll', () => {
          repositionRateLimitMessage()
        })
        // ------------------------------------------------

        scope.isProcoreIntegrationUiEnabled = () => {
          return PROCORE_INTEGRATION_UI_ENABLED
        }

        scope.isConnectedByAnotherProcoreUser = () => {
          return scope.photoSyncSettings.procoreUserId !== undefined && (!scope.currentUserAuthenticatedWithProcore || scope.photoSyncSettings.procoreUserId !== scope.currentUserAuthenticatedWithProcore.externalUserId)
        }

        scope.isConnectedByAnotherSmartvidUser = () => {
          return scope.photoSyncSettings.userId !== undefined && scope.photoSyncSettings.userId !== currentUser.id
        }

        scope.isConnectedByCurrentlyAuthenticatedProcoreUser = () => {
          return scope.photoSyncSettings.procoreUserId !== undefined && scope.currentUserAuthenticatedWithProcore !== undefined && scope.photoSyncSettings.procoreUserId === scope.currentUserAuthenticatedWithProcore.externalUserId
        }

        scope.isConnectedByCurrentSmartvidUser = () => {
          return scope.photoSyncSettings.userId !== undefined && scope.photoSyncSettings.userId === currentUser.id
        }

        scope.connect = () => {
          if (!scope.currentUserAuthenticatedWithProcore) {
            scope.procoreUserInactiveError = false
            scope.authenticateWithProcoreAndEnableIntegration()
          } else if (scope.currentUserAuthenticatedWithProcore && !scope.isProcoreIntegrationEnabledByCurrentUser && !scope.isProcoreIntegrationEnabledByOtherUser) {
            scope.procoreUserInactiveError = false
            scope.enableProcoreIntegration()
          }
        }

        scope.disconnect = () => {
          if (scope.isProcoreIntegrationEnabledForProject()) {
            scope.disableProcoreIntegration()
          }
        }

        scope.isProcoreIntegrationEnabledForProject = () => {
          return (scope.isProcoreIntegrationEnabledByCurrentUser || scope.isProcoreIntegrationEnabledByOtherUser)
        }

        scope.reloadProcoreCompaniesProjectsAndAlbums = () => {
          if (!scope.hasRateLimitError && !scope.procoreUserInactiveError && scope.isOAuthTokenNotPresentOrValid()) {
            scope.isLoadingData = true
            loadProcoreData()
          }
        }

        scope.reloadProcoreProjects = () => {
          if (!scope.hasRateLimitError && !scope.procoreUserInactiveError && scope.isOAuthTokenNotPresentOrValid()) {
            scope.isLoadingData = true
            resetDefaults(false, [PROJECT_DATA, ALBUM_DATA], true)
            loadProcoreProjects()
          }
        }

        scope.reloadProcoreAlbums = () => {
          if (!scope.hasRateLimitError && !scope.procoreUserInactiveError && scope.isOAuthTokenNotPresentOrValid()) {
            scope.isLoadingData = true
            resetDefaults(false, [ALBUM_DATA], true)
            loadProcoreAlbums()
          }
        }

        scope.isSelectedPhotoSyncType = (value) => {
          return scope.selectedData.selectedPhotoSyncType.value === value
        }

        scope.isOAuthTokenNotPresentOrValid = () => {
          return !scope.currentUserAuthenticatedWithProcore || scope.isOAuthTokenValid()
        }

        scope.isOAuthTokenValid = () => {
          return (scope.currentUserAuthenticatedWithProcore && scope.currentUserAuthenticatedWithProcore.isTokenValid)
        }

        scope.isReadOnly = () => {
          return (scope.photoSyncSettings.isEnabled || scope.isProcoreIntegrationEnabledByOtherUser || !scope.isProcoreIntegrationEnabledForProject() || !scope.isProcoreDataComplete() || !scope.isOAuthTokenNotPresentOrValid())
        }

        scope.isSelectReadOnly = (selectType) => {
          let prerequisites = scope.photoSyncSettings.isEnabled || scope.isProcoreIntegrationEnabledByOtherUser || !scope.isProcoreIntegrationEnabledForProject() || !scope.isOAuthTokenNotPresentOrValid()
          if (prerequisites) {
            return true
          }

          if (selectType === scope.PROJECTS_SELECT) {
            return !scope.selectedData.procoreSelectedCompany || scope.selectedData.procoreSelectedCompany.id === NOT_SELECTED_ID
          } else if (selectType === scope.ALBUMS_SELECT) {
            return scope.selectedData.selectedPhotoSyncType.value === 'EXISTING_ALBUM' && (!scope.selectedData.procoreSelectedProject || scope.selectedData.procoreSelectedProject.id === NOT_SELECTED_ID)
          } else if (selectType === scope.ALBUMS_HANDLING_SELECT) {
            return !scope.selectedData.procoreSelectedCompany || scope.selectedData.procoreSelectedCompany.id === NOT_SELECTED_ID || !scope.selectedData.procoreSelectedProject || scope.selectedData.procoreSelectedProject.id === NOT_SELECTED_ID
          } else {
            return false
          }
        }

        scope.isSyncNowEnabled = () => {
          return scope.isProcoreIntegrationEnabledForProject() && scope.isProcoreDataComplete() && !scope.isProcoreIntegrationEnabledByOtherUser
        }

        scope.canLinkProject = () => {
          return scope.isProcoreIntegrationEnabledByCurrentUser && scope.isProcoreDataComplete()
        }

        scope.canUnlinkProject = () => {
          return scope.isProcoreIntegrationEnabledByCurrentUser && (scope.isProcoreDataComplete() || scope.hasRateLimitError || scope.procoreUserInactiveError)
        }

        scope.getLastSyncDate = () => {
          if (scope.photoSyncSettings.lastSyncDate > 0) {
            return moment(scope.photoSyncSettings.lastSyncDate).format('MMM DD, YYYY hh:mmA')
          }

          if (scope.photoSyncSettings.isEnabled) {
            return SYNC_PENDING_LABEL
          }

          return LAST_SYNC_NEVER_LABEL
        }

        scope.getNextSyncDate = () => {
          if (scope.photoSyncSettings.isEnabled && scope.photoSyncSettings.isProcessing) {
            return SYNC_IN_PROGRESS_LABEL
          }

          if (scope.photoSyncSettings.isEnabled) {
            return $filter('i18next')('integrations.scheduled')
          }

          return NEXT_SYNC_NA
        }

        scope.isProcoreDataComplete = () => {
          return (
            scope.selectedData.procoreSelectedCompany && scope.selectedData.procoreSelectedCompany.id !== NOT_SELECTED_ID &&
            scope.selectedData.procoreSelectedProject && scope.selectedData.procoreSelectedProject.id !== NOT_SELECTED_ID &&
            (
              scope.selectedData.selectedPhotoSyncType.value !== 'EXISTING_ALBUM' ||
              scope.selectedData.procoreSelectedAlbum && scope.selectedData.procoreSelectedAlbum.id !== NOT_SELECTED_ID
            )
          )
        }

        let getTextWidth = (text, font) => {
          let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
          let context = canvas.getContext('2d')
          context.font = font
          let metrics = context.measureText(text)

          return metrics.width
        }

        scope.isConnectionStatusTextClipped = () => {
          let connectionStatusInput = $('#connectionStatus')
          // TODO: brute force approach, hardcoded font family, input width adjustment and padding for possible ellipses
          return scope.connectionStatus ? (getTextWidth(scope.connectionStatus + '...', 'Normal 15px Poppins') >= connectionStatusInput.width() + 2) : false
        }

        scope.adjustAlbumPrefixIfNeeded = () => {
          if (scope.selectedData.selectedPhotoSyncType.value === 'FIXED_NAME' &&
            (!scope.photoSyncSettings.fixedAlbumName || scope.photoSyncSettings.fixedAlbumName === WEEKLY_ALBUM_DEFAULT_PREFIX)) {
            scope.photoSyncSettings.fixedAlbumName = FIXED_ALBUM_DEFAULT_NAME
          } else if (scope.selectedData.selectedPhotoSyncType.value === 'NEW_WEEKLY' &&
            (!scope.photoSyncSettings.fixedAlbumName || scope.photoSyncSettings.fixedAlbumName === FIXED_ALBUM_DEFAULT_NAME)) {
            scope.photoSyncSettings.fixedAlbumName = WEEKLY_ALBUM_DEFAULT_PREFIX
          }
        }

        let monitorOAuthPopup = (oauthWindow) => {
          let winCheck = $interval(() => {
            if (!oauthWindow || oauthWindow.closed) {
              $interval.cancel(winCheck)
              checkProcoreIntegrationStatusAndLoadData(true)
            }
          }, 300)
        }

        scope.authenticateWithProcoreAndEnableIntegration = () => {
          scope.waitingForProcoreIntegrationStatus = true
          let oauthWindow = $window.open('', '_blank', 'width=800,height=600')
          smartvidApi.getOAuthUrlForProvider(PROCORE).then((data) => {
            oauthWindow.location = data.value
            monitorOAuthPopup(oauthWindow)
          }, (response) => {
            oauthWindow.close()
            if (!utils.isTimeoutResponse(response)) {
              Notification.error($filter('i18next')('directives.mysettings.failedToEnableProcoreIntegration'))
            }
          })
        }

        scope.disableProcoreIntegration = () => {
          scope.isLoadingData = true
          smartvidApi.deleteProcorePhotoSyncSettings(smartvidProjectId).then(() => {
            scope.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
            resetDefaults()
            checkProcoreIntegrationStatusAndLoadData(false)
          }, () => {
            scope.isLoadingData = false
          })
        }

        scope.enableProcoreIntegration = () => {
          scope.isLoadingData = true
          scope.photoSyncSettings.userId = scope.currentUserAuthenticatedWithProcore.userId
          scope.photoSyncSettings.procoreUserId = scope.currentUserAuthenticatedWithProcore.externalUserId
          scope.photoSyncSettings.isDownloadLocations = scope.selectedData.downloadLocations
          scope.photoSyncSettings.onlyPushTagsToProcore = scope.selectedData.onlyPushTagsToProcore
          smartvidApi.saveProcorePhotoSyncSettings(smartvidProjectId, scope.photoSyncSettings).then(() => {
            checkProcoreIntegrationStatusAndLoadData(false)
          }, () => {
            scope.isLoadingData = false
          })
        }

        let fillInBasePhotoSyncSettingsInfo = () => {
          scope.photoSyncSettings.procoreUserName = scope.connectedProcoreUser ? (scope.connectedProcoreUser.first_name + ' ' + scope.connectedProcoreUser.last_name) : undefined
          scope.photoSyncSettings.procoreCompanyId = scope.selectedData.procoreSelectedCompany.id === NOT_SELECTED_ID ? undefined : scope.selectedData.procoreSelectedCompany.id
          scope.photoSyncSettings.procoreProjectId = scope.selectedData.procoreSelectedProject.id === NOT_SELECTED_ID ? undefined : scope.selectedData.procoreSelectedProject.id
          scope.photoSyncSettings.procoreAlbumId = scope.selectedData.procoreSelectedAlbum.id === NOT_SELECTED_ID ? undefined : scope.selectedData.procoreSelectedAlbum.id
          scope.photoSyncSettings.albumHandlingStrategy = scope.selectedData.selectedPhotoSyncType.value
          scope.photoSyncSettings.photoSyncDirection = scope.selectedData.twoWaySyncEnabled ? TWO_WAY_SYNC : ONE_WAY_SYNC_FROM_PROCORE
          scope.photoSyncSettings.isDownloadLocations = scope.selectedData.downloadLocations
          scope.photoSyncSettings.onlyPushTagsToProcore = scope.selectedData.onlyPushTagsToProcore
          if (scope.photoSyncSettings.onlyPushTagsToProcore) {
            scope.photoSyncSettings.photoSyncDirection = TWO_WAY_SYNC
          }
          scope.photoSyncSettings.syncOnlyAfterDate = Date.now()
          setHistoricalDataSyncThresholdDate(scope.photoSyncSettings, scope.selectedData.historicalDataRange)
        }

        scope.linkProject = () => {
          // userId and procoreUserId are already set at this point
          scope.photoSyncSettings.isEnabled = true

          fillInBasePhotoSyncSettingsInfo()

          scope.savePhotoSyncSettings()
        }

        scope.unlinkProject = () => {
          scope.photoSyncSettings.isEnabled = false
          scope.savePhotoSyncSettings()
        }
        if (scope.photoSyncSettings.onlyPushTagsToProcore) {
          scope.photoSyncSettings.photoSyncDirection = TWO_WAY_SYNC
        }
        scope.savePhotoSyncSettings = (afterSaveFunc) => {
          scope.projectLinkToggleInTransit = true

          smartvidApi.saveProcorePhotoSyncSettings(smartvidProjectId, scope.photoSyncSettings).then(() => {
            if (afterSaveFunc) {
              afterSaveFunc()
            } else {
              scope.projectLinkToggleInTransit = false
            }
          }, () => {
            scope.projectLinkToggleInTransit = false
            scope.isWaitingForRunOnce = false
            scope.photoSyncSettings.isEnabled = false
          })
        }

        let saveRuneOnceStatus = () => {
          smartvidApi.enableRunOnceProjectSync(smartvidProjectId).then(() => {
            scope.projectLinkToggleInTransit = false
            scope.isWaitingForRunOnce = false
            scope.photoSyncSettings.lastSyncDate = Date.now()
          }, () => {
            scope.projectLinkToggleInTransit = false
            scope.isWaitingForRunOnce = false
          })
        }

        scope.enableRunOnceSync = () => {
          scope.isWaitingForRunOnce = true

          if (scope.photoSyncSettings.isEnabled === false) {
            fillInBasePhotoSyncSettingsInfo()
            scope.savePhotoSyncSettings(saveRuneOnceStatus)
          } else {
            saveRuneOnceStatus()
          }
        }

        scope.$on('sv-project-deleted', () => {
          scope.projectWasDeleted = true
        })

        // when user signs out the $destroy event is received the sate already changed to 'login', don't try to save the settings in this case
        scope.$on('$destroy', () => {
          $(window).off('resize.doResize')
          let integrationContainer = $('.entity-integrations')
          if (integrationContainer) {
            integrationContainer.off('scroll.doScroll')
          }

          if ($state.$current.name !== 'login' && (!scope.isReadOnly() || !scope.isSelectReadOnly(scope.COMPANIES_SELECT)) && !scope.projectWasDeleted) {
            fillInBasePhotoSyncSettingsInfo()
            scope.savePhotoSyncSettings()
          }
        })

        function setHistoricalDataSyncThresholdDate (settingsData, historicalDataRange) {
          if (!historicalDataRange || historicalDataRange.value === 'ALL') {
            settingsData.historicalDataSyncThresholdDate = null
            if (historicalDataRange) {
              settingsData.historicalDataSyncDateRange = historicalDataRange.value
            }
          } else {
            switch (historicalDataRange.value) {
              case '7_DAYS':
                settingsData.historicalDataSyncThresholdDate = moment().subtract(7, 'days').startOf('day').toDate()
                settingsData.historicalDataSyncDateRange = historicalDataRange.value
                break
              case '30_DAYS':
                settingsData.historicalDataSyncThresholdDate = moment().subtract(30, 'days').startOf('day').toDate()
                settingsData.historicalDataSyncDateRange = historicalDataRange.value
                break
              case '60_DAYS':
                settingsData.historicalDataSyncThresholdDate = moment().subtract(60, 'days').startOf('day').toDate()
                settingsData.historicalDataSyncDateRange = historicalDataRange.value
                break
              case 'NONE':
                settingsData.historicalDataSyncThresholdDate = moment().toDate()
                settingsData.historicalDataSyncDateRange = historicalDataRange.value
                break
            }
          }
        }

      }
    }
  })
