import {
  UiConfiguration,
  UiConfigurationFieldInfo,
  ObservationPredefinedFields,
  UiConfigurationListFieldData,
} from 'shared/models/ui-configuration.model'
import { ObservationType } from '../models/observation-type.enum'
import { Observation } from '../models/observation.model'

export class ObservationUiConfigurationSupport {
  observationPredefinedFields = ObservationPredefinedFields
  public uiConfiguration: UiConfiguration
  initialized = false
  fieldsMap: Map<string, UiConfigurationFieldInfo>
  observationType: ObservationType
  negativeObservationOnlyFields: ObservationPredefinedFields[] = [
    ObservationPredefinedFields.ACTION_TAKEN,
    ObservationPredefinedFields.REVIEW_COMMENT,
    ObservationPredefinedFields.SEVERITY,
    ObservationPredefinedFields.FREQUENCY,
  ]

  initUiConfigurationSupport(uiConfiguraiton: UiConfiguration, observationType: ObservationType) {
    this.uiConfiguration = uiConfiguraiton
    this.fieldsMap = this.getObservationFieldDescriptorsFromConfig(uiConfiguraiton)
    this.observationType = observationType
    this.initialized = true
  }

  public isFieldHidden(field: ObservationPredefinedFields): boolean {
    let fieldInfo = this.fieldsMap.get(field)
    if (!fieldInfo) {
      return true
    }
    if (this.observationType === ObservationType.POSITIVE && this.negativeObservationOnlyFields.includes(field)) {
      return true
    }
    return fieldInfo.isHidden
  }

  public isFieldRequired(field: ObservationPredefinedFields): boolean {
    let fieldInfo = this.fieldsMap.get(field)
    if (!fieldInfo) {
      return false
    }
    return fieldInfo.isRequired
  }

  public isFieldReadOnly(field: ObservationPredefinedFields): boolean {
    let fieldInfo = this.fieldsMap.get(field)
    if (!fieldInfo) {
      return false
    }
    return fieldInfo.isReadOnly
  }

  public getFieldLabel(field: ObservationPredefinedFields): string {
    let fieldInfo = this.fieldsMap.get(field)
    if (!fieldInfo) {
      return ''
    }
    return fieldInfo.label
  }

  protected getFieldValue(field: ObservationPredefinedFields, observation: Observation): any {
    return observation[this.fieldsMap.get(field).name]
  }

  protected getFieldListData(field: ObservationPredefinedFields): UiConfigurationListFieldData[] {
    let fieldInfo = this.fieldsMap.get(field)
    if (!fieldInfo) {
      return []
    }
    return fieldInfo.listData
  }

  public isInitialized(): boolean {
    return this.initialized
  }

  private getObservationFieldDescriptorsFromConfig(config: UiConfiguration): Map<string, UiConfigurationFieldInfo> {
    let result = new Map<string, UiConfigurationFieldInfo>()
    for (let field of config.uiConfigurationData.predefinedFields) {
      result.set(field.name, field)
    }
    return result
  }
}
