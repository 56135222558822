/* global angular _ */

angular.module('smartvid').factory('ForgeIssueCreateModel', function (BaseModel) {
  class ForgeIssueCreateModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        title: undefined,
        description: undefined,
        subType: undefined,
        issueTypeId: undefined,
        issueSubTypeId: undefined,
        rootCauseId: undefined,
        dueDate: undefined,
        status: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return ForgeIssueCreateModel
})
