/* global angular */
angular.module('smartvid').directive('svTab', function ($sce) {
  return {
    restrict: 'E',
    transclude: {
      'tabHeadingSlot': '?svTabHeading'
    },
    templateUrl: 'Tabs/sv-tab.html',
    require: '^svTabset',
    replace: true,
    scope: {
      headingWidth: '@',                  // Required width for tab heading
      activeBarLocation: '@?',            // Optional custom left distance for active bar. eg. <sv-tab active-bar-location="10"></sv-tab>
      name: '@?',                         // Optional tab name to display at top of tab body
      visible: '=?isVisible',             // Optional value indicating if tab should show or hide
      id: '@?tabId',                       // Optional id for the tab
      textTooltipCustomOffsetLeft: '@?textTooltipCustomOffsetLeft'
    },
    bindToController: true,
    controllerAs: 'tab',
    controller: function ($scope, $element, $attrs) {
      var self = this
      self.id = $scope.tab.id
      self.active = false                 // Is selected tab?
      self.headingElem                    // Contains the heading element
      self.visible = ($scope.tab.visible !== undefined) ? $scope.tab.visible : true

      self.init = function () {
        $scope.tabsetCtrl.addTab(self)    // Invoke add tab from parent controller to register self
        $scope.$watch('tab.visible', (newVal, oldVal) => { // TODO (rrubbico) find way to do this without watch b/c they are awful
          if (newVal !== oldVal || (newVal && oldVal)) {
            $scope.tabsetCtrl.updateWhichTabSelected()
            self.visitable = newVal
            $scope.tab.visible = newVal
          }
        })
      }

      self.setToSelected = function () {
        self.active = true
      }

      self.setToUnselected = function () {
        self.active = false
      }

      self.destroy = function () {
        self.$destroy()
        self = null
        $element.remove()
        $element = null
      }

      self.getWidth = function () {
        return parseInt($scope.tab.headingWidth, 10)
      }

      self.getInnerHtml = function () {
        return $sce.trustAsHtml(self.headingElem[0].parentElement.innerHTML)
      }
    },
    link: function (scope, elem, attr, tabsetCtrl) {
      scope.tabsetCtrl = tabsetCtrl       // link parent controller
      scope.tab.init()                    // initialize tab using controller alias 'tab'
    }
  }
})
