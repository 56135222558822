import { Observable } from 'rxjs'
import { Inject, Injectable } from '@angular/core'
import { ConfigService } from 'modules/core/services/config.service'
import {
  Observation,
  ObservationCloseRequest,
  ObservationCreateRequest,
  ObservationFilterCriteria,
  ObservationNextSteps,
  ObservationPage,
  ObservationQuickFilterCriteria,
  ObservationReviewRequest,
  ObservationRiskCalculatorResponse,
  ObservationSearchCountRequest,
  ObservationSearchCountResponse,
  ObservationSearchCriteria,
  ObservationSearchRequest,
  ObservationSearchType,
  ObservationSortType,
  ObservationUpdateRequest,
  ObservationUpdateStatusRequest,
  ObservationPhotoReportRequest,
} from '../models/observation.model'
import { HTTP } from 'shared/smartvid.types'
import { toNumberObservable, toObservable, toObservableArray } from 'shared/utils/api-utils'
import { ObservationType } from '../models/observation-type.enum'
import { ObservationDetails } from '../models/observation-details.enum'

@Injectable({
  providedIn: 'root',
})
export class ObservationService {
  rootUrl: string

  constructor(@Inject(HTTP) private $http: ng.IHttpService, private config: ConfigService) {
    this.rootUrl = this.config.env.development.apiRootUrl
  }

  /**
   *  Observation Photo Report API
   */
  generateObservationsPhotoReport(req: ObservationPhotoReportRequest) {
    const url = this.rootUrl + '/api/report/observation_photo'
    this.$http.post(url, req)
  }

  /**
   *  ElasticSearch Observation List API
   */
  countObservations(req: ObservationSearchCountRequest): Observable<ObservationSearchCountResponse> {
    const url = this.rootUrl + '/api/observation/search/count'
    return toObservable<ObservationSearchCountResponse>(this.$http.post(url, req), ObservationSearchCountResponse)
  }

  findObservations(req: ObservationSearchRequest): Observable<Observation[]> {
    const url = this.rootUrl + '/api/observation/search'
    return toObservableArray<Observation>(this.$http.post(url, req), Observation)
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   *  Database Observation List API
   */
  countUserObservations(filters: ObservationQuickFilterCriteria): Observable<number> {
    let url = this.countUrl('/api/user/observation/count', filters)
    return toNumberObservable(this.$http.get(url))
  }

  countOrgObservations(organizationId: string, qf: ObservationQuickFilterCriteria): Observable<number> {
    let url = this.countUrl(`/api/organization/${organizationId}/observation/count`, qf)
    return toNumberObservable(this.$http.get(url))
  }

  countGroupObservations(
    organizationId: string,
    projectGroupId: string,
    qf: ObservationQuickFilterCriteria
  ): Observable<number> {
    let url = this.countUrl(`/api/organization/${organizationId}/group/${projectGroupId}/observation/count`, qf)
    return toNumberObservable(this.$http.get(url))
  }

  countProjectObservations(projectId: string, qf: ObservationQuickFilterCriteria): Observable<number> {
    let url = this.countUrl(`/api/project/${projectId}/observation/count`, qf)
    return toNumberObservable(this.$http.get(url))
  }

  getUserObservations(
    page: ObservationPage,
    details: ObservationDetails[],
    qf: ObservationQuickFilterCriteria
  ): Observable<Observation[]> {
    let url = this.pageUrl('/api/user/observation', page, details, qf)
    return toObservableArray<Observation>(this.$http.get(url), Observation)
  }

  getOrgObservations(
    organizationId: string,
    page: ObservationPage,
    details: ObservationDetails[],
    qf: ObservationQuickFilterCriteria
  ): Observable<Observation[]> {
    let url = this.pageUrl(`/api/organization/${organizationId}/observation`, page, details, qf)
    return toObservableArray<Observation>(this.$http.get(url), Observation)
  }

  getGroupObservations(
    organizationId: string,
    projectGroupId: string,
    page: ObservationPage,
    details: ObservationDetails[],
    qf: ObservationQuickFilterCriteria
  ): Observable<Observation[]> {
    let url = this.pageUrl(`/api/organization/${organizationId}/group/${projectGroupId}/observation`, page, details, qf)
    return toObservableArray<Observation>(this.$http.get(url), Observation)
  }

  getProjectObservations(
    projectId: string,
    page: ObservationPage,
    details: ObservationDetails[],
    qf: ObservationQuickFilterCriteria
  ): Observable<Observation[]> {
    let url = this.pageUrl(`/api/project/${projectId}/observation`, page, details, qf)
    return toObservableArray<Observation>(this.$http.get(url), Observation)
  }

  deleteObservation(projectId: string, observationId: string) {
    let url = this.rootUrl + `/api/project/${projectId}/observation/${observationId}`
    return toObservable(this.$http.delete(url), Object)
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObservation(
    projectId: string,
    observationId: string,
    observationDetails: ObservationDetails[] = undefined
  ): Observable<Observation> {
    let url =
      this.rootUrl +
      `/api/project/${projectId}/observation/${observationId}` +
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      (observationDetails ? `?details=${observationDetails}` : '')
    return toObservable<Observation>(this.$http.get(url), Observation)
  }

  getInitialStatuses(projectId: string, obsType: ObservationType): Observable<ObservationNextSteps> {
    let url = this.rootUrl + `/api/project/${projectId}/observation/status/next?observationType=${obsType}`
    return toObservable<ObservationNextSteps>(this.$http.get(url), ObservationNextSteps)
  }

  getInitialStatusesForOrg(organizationId: string, obsType: ObservationType): Observable<ObservationNextSteps> {
    let url = this.rootUrl + `/api/organization/${organizationId}/observation/status/next?observationType=${obsType}`
    return toObservable<ObservationNextSteps>(this.$http.get(url), ObservationNextSteps)
  }

  getNextStatuses(projectId: string, observationId: string): Observable<ObservationNextSteps> {
    let url = this.rootUrl + `/api/project/${projectId}/observation/${observationId}/status/next`
    return toObservable<ObservationNextSteps>(this.$http.get(url), ObservationNextSteps)
  }

  createObservation(request: ObservationCreateRequest): Observable<Observation> {
    let url = this.rootUrl + `/api/project/${request.projectId}/observation`
    return toObservable(this.$http.post(url, request), Observation)
  }

  updateObservation(
    projectId: string,
    observationId: string,
    request: ObservationUpdateRequest
  ): Observable<Observation> {
    let url = this.rootUrl + `/api/project/${projectId}/observation/${observationId}`
    return toObservable(this.$http.put(url, request), Observation)
  }

  updateObservationStatus(projectId: string, observationId: string, request: ObservationUpdateStatusRequest) {
    let url = this.rootUrl + `/api/project/${projectId}/observation/${observationId}/status`
    return toObservable(this.$http.put(url, request), Object)
  }

  closeObservation(projectId: string, observationId: string, request: ObservationCloseRequest) {
    let url = this.rootUrl + `/api/project/${projectId}/observation/${observationId}/close`
    return toObservable(this.$http.put(url, request), Object)
  }

  reviewObservation(projectId: string, observationId: string, request: ObservationReviewRequest) {
    let url = this.rootUrl + `/api/project/${projectId}/observation/${observationId}/review`
    return toObservable(this.$http.put(url, request), Object)
  }

  calcRisk(projectId: string, request: ObservationCreateRequest): Observable<ObservationRiskCalculatorResponse> {
    let url = this.rootUrl + `/api/project/${projectId}/observation/calculation/risk`
    return toObservable<ObservationRiskCalculatorResponse>(
      this.$http.put(url, request),
      ObservationRiskCalculatorResponse
    )
  }

  getObservationApi(
    organizationId: string,
    projectGroupId: string,
    projectId: string,
    sortType: ObservationSortType,
    searchCriteria: ObservationSearchCriteria,
    filterCriteria: ObservationFilterCriteria,
    quickFilterCriteria: ObservationQuickFilterCriteria,
    observationDetails: ObservationDetails[],
    page: ObservationPage
  ): Observable<Observation[]> {
    if (searchCriteria.isEmpty() && filterCriteria.isEmpty()) {
      return this.getDatabaseObservationApi$(
        organizationId,
        projectGroupId,
        projectId,
        sortType,
        quickFilterCriteria,
        observationDetails,
        page
      )
    } else {
      return this.getSearchObservationApi$(
        organizationId,
        projectGroupId,
        projectId,
        sortType,
        searchCriteria,
        filterCriteria,
        quickFilterCriteria,
        observationDetails,
        page
      )
    }
  }

  getSearchType(organizationId, projectGroupId, projectId): ObservationSearchType {
    if (projectId) {
      return ObservationSearchType.IN_PROJECT
    } else if (organizationId && projectGroupId) {
      return ObservationSearchType.IN_PROJECT_GROUP
    } else if (organizationId) {
      return ObservationSearchType.IN_ORGANIZATION
    } else {
      return ObservationSearchType.CROSS_ORGANIZATIONS
    }
  }

  private getDatabaseObservationApi$(
    organizationId: string,
    projectGroupId: string,
    projectId: string,
    sortType: ObservationSortType,
    quickFilterCriteria: ObservationQuickFilterCriteria,
    observationDetails: ObservationDetails[],
    page: ObservationPage
  ): Observable<Observation[]> {
    const sortColumns =
      ObservationSortType.RISK === sortType
        ? [ObservationSortType.RISK.toString(), ObservationSortType.CREATED_DATE.toString()]
        : [ObservationSortType.CREATED_DATE.toString()]
    const sortPage = new ObservationPage(page.pageNum, page.limit, sortColumns)
    const quickFilter = ObservationQuickFilterCriteria.fromObject(quickFilterCriteria)
    const details = observationDetails
    if (projectId) {
      return this.getProjectObservations(projectId, sortPage, details, quickFilter)
    } else if (organizationId && projectGroupId) {
      return this.getGroupObservations(organizationId, projectGroupId, sortPage, details, quickFilter)
    } else if (organizationId) {
      return this.getOrgObservations(organizationId, sortPage, details, quickFilter)
    } else {
      return this.getUserObservations(sortPage, details, quickFilter)
    }
  }

  private getSearchObservationApi$(
    organizationId: string,
    projectGroupId: string,
    projectId: string,
    sortType: ObservationSortType,
    searchCriteria: ObservationSearchCriteria,
    filterCriteria: ObservationFilterCriteria,
    quickFilterCriteria: ObservationQuickFilterCriteria,
    observationDetails: ObservationDetails[],
    page: ObservationPage
  ): Observable<Observation[]> {
    const sortColumns =
      ObservationSortType.RISK === sortType
        ? [ObservationSortType.RISK.toString(), ObservationSortType.CREATED_DATE.toString()]
        : [ObservationSortType.CREATED_DATE.toString()]
    const sortPage = new ObservationPage(page.pageNum, page.limit, sortColumns)

    const req = new ObservationSearchRequest()
    req.searchType = this.getSearchType(organizationId, projectGroupId, projectId)
    req.organizationId = organizationId
    req.projectGroupId = projectGroupId === 'default' ? null : projectGroupId
    req.projectId = projectId
    req.quickFilterCriteria = quickFilterCriteria
    req.filterCriteria = filterCriteria
    req.searchCriteria = searchCriteria
    req.details = observationDetails
    req.limit = sortPage.limit
    req.offset = sortPage.offset
    req.sortColumns = sortColumns
    return this.findObservations(req)
  }

  private pageUrl(
    partUrl: string,
    page: ObservationPage,
    details: ObservationDetails[],
    filters: ObservationQuickFilterCriteria
  ): string {
    return (
      this.rootUrl +
      `${partUrl}?offset=${page.offset}&limit=${page.limit}&sortColumn=${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        page.sort
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      }&details=${details}&${filters.toUrlParams()}`
    )
  }

  private countUrl(partUrl: string, filters: ObservationQuickFilterCriteria): string {
    return this.rootUrl + `${partUrl}?${filters.toUrlParams()}`
  }
}
