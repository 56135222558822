/* global angular, _ */

angular.module('smartvid').directive('avatar', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'avatar.html',
    scope: {
      firstname: '@',
      lastname: '@',
      email: '@',
      imageUrl: '@'
    },
    link (scope) {
      scope.showInitials = function () {
        return !scope.imageUrl || scope.imageUrl.trim().length === 0
      }

      scope.getInitials = function () {
        let initials = _.capitalize(scope.firstname.slice(0, 1)) + _.capitalize(scope.lastname.slice(0, 1))
        if (!initials) {
          initials = _.capitalize(scope.email.slice(0, 1))
        }
        return initials
      }
    }
  }
})
