/* global angular,_ */

angular.module('smartvid').controller('SortOptionsCtrl', function ($scope, $rootScope,
  dashboardDataHelper, searchResultService, assetGridHelper) {
  let currentProject = dashboardDataHelper.getCurrentProject()
  let projectId = (currentProject) ? currentProject.id : undefined
  let state = assetGridHelper.readAssetGridState(projectId, undefined, searchResultService.getCurrentSearchContext())
  let defaultState = assetGridHelper.defaultAssetGridState()
  let allSorts = [
    {
      name: 'bestMatch',
      direction: 'DESC',
      bundle: 'common.bestMatch'
    },
    {
      name: 'createdTime',
      direction: 'DESC',
      bundle: 'common.uploadedNewest'
    },
    {
      name: 'createdTime',
      direction: 'ASC',
      bundle: 'common.uploadedOldest'
    },
    {
      name: 'captureTime',
      direction: 'DESC',
      bundle: 'common.createdNewest'
    },
    {
      name: 'captureTime',
      direction: 'ASC',
      bundle: 'common.createdOldest'
    },
    {
      name: 'typeDetails',
      direction: 'ASC',
      bundle: 'common.type'
    },
    {
      name: 'lastName',
      direction: 'ASC',
      bundle: 'common.member'
    },
    {
      name: 'name',
      direction: 'ASC',
      bundle: 'common.name'
    }
  ]
  $scope.availableSorts = _.filter(allSorts, sort => isSortAvailable(sort.name))
  $scope.selectedSort = getSelectedSort($scope.availableSorts)
  $scope.sortBy = sortBy
  $scope.getStyles = getStyles

  function isSortAvailable (method) {
    if (method === 'bestMatch' && !searchResultService.isInSearchContext()) {
      return false
    }
    if (method === 'bestMatch' && searchResultService.getCurrentSearchContext().isAllSearch()) {
      return false
    }
    return true
  }

  function getSelectedSort (availableSorts) {
    let found = _.find(availableSorts, (sort) => {
      return sort.name === state.sortByColumn && sort.direction === state.sortByOrder
    })
    return found ? state : defaultState
  }

  function sortBy (method, order) {
    state.sortBy = true
    state.sortByColumn = method
    state.sortByOrder = order
    assetGridHelper.saveAssetGridState(projectId, state)
    $scope.flyout.close()
    $rootScope.$broadcast('assetGrid:sortBy', ...[method, order])
  }

  function getStyles () {
    return {
      top: $scope.styles.top,
      left: $scope.styles.left,
      width: 200
    }
  }
})
