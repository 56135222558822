/* global angular */

/**
 * The main Smartvid.io Notifications Box
 *
 * to trigger e.g. utils.notify('main message', 'sub message', handlerLabel, handler)
 *
 */
angular.module('smartvid').directive('svNotification', (utils, $timeout, $interpolate, $filter) => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'sv-notification.html',
    link (scope) {
      scope.isNotify = false
      scope.noWidth = true
      scope.callHandlerFunction = false
      scope.templateName = undefined
      scope.templateOptions = {}

      let manualCloseNotifyQueue = []

      scope.close = () => {
        scope.isNotify = false
        utils.digest(scope)
        if (manualCloseNotifyQueue.length > 0) {
          transitionNotify(manualCloseNotifyQueue.shift())
        }
      }

      let transitionNotify = (messageOptions) => {
        $timeout(() => {
          $timeout(() => {
            scope.options = messageOptions
            scope.isNotify = true
            scope.noWidth = false
            scope.templateName = messageOptions.templateName
            scope.templateOptions = messageOptions.templateOptions
            scope.successAssets = messageOptions.successAssets
            if (messageOptions.callHandlerFunction) {
              scope.callhandlerWithTimeout(7000)
            } else utils.digest(scope)
          }, scope.isNotify ? 800 : 400)
        })

        if (!messageOptions.userClose) {
          $timeout(() => {
            scope.close()
            $timeout(() => {
              scope.noWidth = true
            })
          }, 7000)
        }
      }

      scope.callhandlerWithTimeout = (timeout) => {
        scope.options.handler(scope)
        scope.inTransit = true
        $timeout(() => {
          scope.close()
          scope.inTransit = false
        }, timeout)
      }

      scope.callhandler = () => {
        scope.callhandlerWithTimeout(400)
      }

      scope.$on('sv-notify', (event, options) => {
        if (scope.isNotify || manualCloseNotifyQueue.length > 0) {
          manualCloseNotifyQueue.push(options)
        } else {
          transitionNotify(options)
        }
      })

      scope.$on('sv-notify-clear', (event) => {
        manualCloseNotifyQueue = []
        scope.close()
      })

      scope.getFailedAssetsMsg = () => {
        const number = scope.templateOptions.failedAssetNames.length
        const assetsMoveFailedMessage = number === 1 ? 'services.moveAssetsService.assetMoveFailedMessage1' : 'services.moveAssetsService.assetsMoveFailedMessage1'
        return  $interpolate($filter('i18next')(assetsMoveFailedMessage))({
          number: number
        })
      }
    }
  }
})
