export class ConfigService {
  static $inject = ['$window']
  public env: { development: { apiRootUrl: string; apiPredictiveRootUrl: string; angularRootUrl: string } }
  public resetPasswordUrl: string
  constructor(private $window: ng.IWindowService) {
    let origin = $window.location.origin
    if (!origin) {
      origin =
        $window.location.protocol +
        '//' +
        $window.location.hostname +
        ($window.location.port ? ':' + $window.location.port : '')
    }

    let env = {
      development: {
        // API_ROOT_URL is dynamically generated by the build script
        apiRootUrl: $window.API_ROOT_URL,
        apiPredictiveRootUrl: $window.API_PREDICTIVE_ROOT_URL,
        angularRootUrl: origin,
      },
    }
    this.env = env
    this.resetPasswordUrl = '/reset-password'
  }
}
