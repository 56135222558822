/* global angular,$ */
angular.module('smartvid').component('dziViewer', {
  templateUrl: 'AssetViewer/dzi-viewer.html',
  bindings: {
    asset: '<',
    onLoad: '&?'
  },
  controller: function ($scope, $element, $attrs, openSeaDragonService, utils, $timeout, dziTagMarkupService, $rootScope, $q) {
    const DZI_MARKUP_VERSION_NUMBER = 1
    const DZI_MARKUP_TYPE = 'DZI'
    let ctrl = this
    let dziViewer = null
    let dziViewerInitDeferred = $q.defer()
    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy

    function onInit () {
      dziViewer = createDziViewer()
      $scope.tagInstancesWithMarkups = $scope.$parent.tagInstancesWithMarkups
      $scope.$on('sv-comment-added', onCommentAdded)
      $scope.$on('sv-comment-selected', onCommentSelected)
      $scope.$on('sv-comment-removed', onCommentRemoved)
      $scope.$on('sv-set-comment-markup', onSetCommentMarkup)
      $scope.$on('sv-resize-shape', onResizeShape)
      $scope.$on('sv-drag-to-position-markup', onDragToPositionMarkup)
      $scope.$on('sv-remove-markup', removeDziMarkupOverlay)
      $scope.$on('sv-clear-all-markup', clearDziOverlays)
      $scope.$on('sv-tag-show-markup', onTagShowMarkup)
      $scope.$on('sv-drag-to-position-tag-markup', onDragToPositionTagMarkup)
      $scope.$on('sv-resize-tag-shape', onResizeTagShape)
      $scope.$on('sv-remove-tag-markup', removeDziTagMarkupOverlay)
      $scope.$on('sv-set-tag-markup', onSetTagMarkup)
      $scope.$on('sv-rotate-tag-markup', rotateTagMarkup)
      $scope.$on('sv-rotate-comment-markup', rotateCommentMarkup)
    }

    function onDestroy () {
      dziViewer.destroy()
      dziViewer.homeButton.tracker.destroy()
      dziViewer.zoomOutButton.tracker.destroy()
      dziViewer.zoomInButton.tracker.destroy()
      dziViewer = null
    }

    function createDziViewer () {
      let tileSources = getTileSources()
      let options = {
        id: 'dziViewer',
        assetId: ctrl.asset.id,
        tileSources: tileSources,
        prefixUrl: 'assets/images/osd/',
        showFullPageControl: false,
        visibilityRatio: 1.0,
        viewportMargins: {},
        crossOriginPolicy: 'Anonymous',
        zoomInButton: 'osd-zoom-in',
        zoomOutButton: 'osd-zoom-out',
        homeButton: 'osd-home'
      }

      let dziViewer = openSeaDragonService.openSeaDragonViewer(options)

      dziViewer.addHandler('open', () => {
        ctrl.onLoad({frameFunc: getDziImageClipRect})
        let selectedComment = ctrl.asset.comments.getEditedComment()
        if (selectedComment) {
          clearDziOverlays()
          if (selectedComment.shape) {
            $timeout(function () {
              displayDziOverlayForComment(selectedComment)
            }, 0)
          } else {
            $timeout(function () {
              navigateToSavedPanAndZoom(selectedComment)
            }, 0)
          }
        }
        dziViewerInitDeferred.resolve()
      })

      dziViewer.addHandler('canvas-drag-end', () => {
        let selectedComment = ctrl.asset.comments.getEditedComment()
        if (selectedComment && selectedComment.editMode) {
          updatePanAndZoomForTagOrComment(selectedComment)
        }
      })

      dziViewer.addHandler('zoom', () => {
        let selectedComment = ctrl.asset.comments.getEditedComment()
        if (selectedComment && selectedComment.editMode) {
          updatePanAndZoomForTagOrComment(selectedComment)
        }
      })
      return dziViewer
    }

    function getTileSources () {
      let separator = ctrl.asset.imageUrl.indexOf('?') !== -1 ? '&' : '?'
      if (ctrl.asset.isDziImage()) {
        return ctrl.asset.imageUrl + separator + 'fc=' + utils.createGuid()
      } else {
        return {
          type: 'image',
          url: ctrl.asset.imageUrl + separator + 'fc=' + utils.createGuid()
        }
      }
    }

    function onCommentAdded () {
      let selectedComment = ctrl.asset.comments.getEditedComment()
      $timeout(function () {
        clearDziOverlays()
        updatePanAndZoomForTagOrComment(selectedComment)
      }, 0)
    }

    function onCommentSelected () {
      clearDziOverlays()
      let selectedComment = ctrl.asset.comments.getEditedComment()
      if (selectedComment.shape) {
        $timeout(function () {
          displayDziOverlayForComment(selectedComment)
        }, 0)
      } else {
        $timeout(function () {
          clearDziOverlays()
          navigateToSavedPanAndZoom(selectedComment)
        }, 0)
      }
    }

    function onSetCommentMarkup () {
      let selectedComment = ctrl.asset.comments.getEditedComment()
      onSetMarkupForDziViewerForComment(selectedComment)
    }

    function clearDziOverlays () {
      if ($scope.tagInstancesWithMarkups.length > 0) {
        dziTagMarkupService.clearTagsMarkups(dziViewer, $scope)
      }
      dziViewer.clearOverlays()
    }

    function displayDziOverlayForComment (selectedComment) {
      if ($scope.tagInstancesWithMarkups > 0) {
        dziTagMarkupService.clearTagsMarkups(dziViewer, $scope)
      }
      clearDziOverlays()
      dziViewer.addOverlay({
        element: 'sv-markup-' + selectedComment.shape,
        x: parseFloat(selectedComment.left),
        y: parseFloat(selectedComment.top),
        width: parseFloat(selectedComment.width),
        height: parseFloat(selectedComment.height)
      })
      // PL: workaround for bug in DZI viewer
      $('#sv-markup-' + selectedComment.shape).show()
      navigateToSavedPanAndZoom(selectedComment)
    }

    function navigateToSavedPanAndZoom (selectedComment) {
      if (selectedComment.panX && selectedComment.panY) {
        dziViewer.viewport.panTo(openSeaDragonService.point(selectedComment.panX, selectedComment.panY))
      }

      if (selectedComment.zoomLevel) {
        dziViewer.viewport.zoomTo(selectedComment.zoomLevel)
      }

      if (!selectedComment.panX && !selectedComment.panY && !selectedComment.zoomLevel) {
        dziViewer.viewport.goHome()
      }
    }

    function updatePanAndZoomForTagOrComment (selectedTagComment) {
      if (!selectedTagComment) {
        return
      }
      let centerPoint = dziViewer.viewport.getCenter()
      selectedTagComment.panX = centerPoint.x
      selectedTagComment.panY = centerPoint.y
      selectedTagComment.zoomLevel = dziViewer.viewport.getZoom()
      selectedTagComment.imageZoomLevel = dziViewer.viewport.viewportToImageZoom(selectedTagComment.zoomLevel)
    }

    function onSetMarkupForDziViewerForComment (selectedTagComment) {
      $timeout(function () {
        let centerPoint = dziViewer.viewport.getCenter()
        selectedTagComment.width = 0.1 / dziViewer.viewport.getZoom()
        selectedTagComment.height = 0.1 / dziViewer.viewport.getZoom()
        selectedTagComment.left = centerPoint.x - selectedTagComment.width / 2
        selectedTagComment.top = centerPoint.y - selectedTagComment.height / 2
        selectedTagComment.version = DZI_MARKUP_VERSION_NUMBER
        selectedTagComment.type = DZI_MARKUP_TYPE
        updatePanAndZoomForTagOrComment(selectedTagComment)
        displayDziOverlayForComment(selectedTagComment)
      }, 0)
    }

    function onResizeShape (event, {startXDelta, startYDelta}) {
        // create points and rects here to reuse instead of creating a new one for each mouse move
      let selectedComment = ctrl.asset.comments.getEditedComment()
      let startScreenPoint = openSeaDragonService.point(startXDelta, startYDelta)
      let endScreenPoint = openSeaDragonService.point(0, 0)
      let overlayRect = openSeaDragonService.rect(selectedComment.left, selectedComment.top, selectedComment.width, selectedComment.height)
      let startViewportPoint = dziViewer.viewport.pointFromPixel(startScreenPoint)

      document.body.onmousemove = function (evt) {
        evt = evt || window.event
        evt.stopPropagation()
        endScreenPoint.x = evt.pageX !== undefined ? evt.pageX : evt.clientX
        endScreenPoint.y = evt.pageY !== undefined ? evt.pageY : evt.clientY

        let endViewportPoint = dziViewer.viewport.pointFromPixel(endScreenPoint)
        let amountMovedAlongX = endViewportPoint.x - startViewportPoint.x
        let amountMovedAlongY = endViewportPoint.y - startViewportPoint.y

        startViewportPoint = endViewportPoint

        selectedComment.width += amountMovedAlongX
        selectedComment.height += amountMovedAlongY

        updatePanAndZoomForTagOrComment(selectedComment)

        overlayRect.x = selectedComment.left
        overlayRect.y = selectedComment.top
        overlayRect.width = selectedComment.width
        overlayRect.height = selectedComment.height

        dziViewer.updateOverlay('sv-markup-' + selectedComment.shape, overlayRect)
      }

      document.body.onmouseup = function (evt) {
        evt = evt || window.event
        evt.stopPropagation()

        document.body.onmousemove = document.body.onmouseup = null

        $rootScope.$broadcast('sv-update-comment-markup')
      }
    }

    function onDragToPositionMarkup (event, {startXDelta, startYDelta}) {
      // create points and rects here to reuse instead of creating a new one for each mouse move
      let selectedComment = ctrl.asset.comments.getEditedComment()
      let startScreenPoint = openSeaDragonService.point(startXDelta, startYDelta)
      let startViewportPoint = dziViewer.viewport.pointFromPixel(startScreenPoint)
      let endScreenPoint = openSeaDragonService.point(0, 0)
      let overlayRect = openSeaDragonService.rect(selectedComment.left, selectedComment.top, selectedComment.width, selectedComment.height)

      document.body.onmousemove = function (evt) {
        evt = evt || window.event
        evt.stopPropagation()
        endScreenPoint.x = evt.pageX !== undefined ? evt.pageX : evt.clientX
        endScreenPoint.y = evt.pageY !== undefined ? evt.pageY : evt.clientY

        let endViewPortPoint = dziViewer.viewport.pointFromPixel(endScreenPoint)
        selectedComment.left += endViewPortPoint.x - startViewportPoint.x
        selectedComment.top += endViewPortPoint.y - startViewportPoint.y

        startViewportPoint = endViewPortPoint

        overlayRect.x = selectedComment.left
        overlayRect.y = selectedComment.top
        overlayRect.width = selectedComment.width
        overlayRect.height = selectedComment.height

        updatePanAndZoomForTagOrComment(selectedComment)

        dziViewer.updateOverlay('sv-markup-' + selectedComment.shape, overlayRect)
      }

      document.body.onmouseup = function (evt) {
        evt = evt || window.event
        evt.stopPropagation()
        document.body.onmousemove = document.body.onmouseup = null
        $rootScope.$broadcast('sv-update-comment-markup')
      }
    }

    function removeDziMarkupOverlay (evt, comment) {
      let selectedComment = comment || ctrl.asset.comments.getEditedComment()
      if (selectedComment.shape) {
        dziViewer.removeOverlay('sv-markup-' + selectedComment.shape)
      }
    }

    function onCommentRemoved (evt, comment) {
      removeDziMarkupOverlay(evt, comment)
    }

    function getDziImageClipRect () {
      if (dziViewer) {
        let dim = dziViewer.source.dimensions
        let originalImageRect = openSeaDragonService.rect(0, 0, dim.x, dim.y)
        let imageInViewport = dziViewer.viewport.imageToViewportRectangle(originalImageRect)
        let viewportBounds = dziViewer.viewport.getBounds(true)
        let viewportImageIntersection = viewportBounds.intersection(imageInViewport)
        let imageClipRect = viewportImageIntersection ? dziViewer.viewport.viewportToImageRectangle(viewportImageIntersection) : originalImageRect

        return {
          x: Math.round(imageClipRect.x),
          y: Math.round(imageClipRect.y),
          width: Math.min(dim.x, Math.round(imageClipRect.width)),
          height: Math.min(dim.y, Math.round(imageClipRect.height))
        }
      }
      return undefined
    }

    function onTagShowMarkup (evt, tags) {
      dziViewerInitDeferred.promise.then(function () {
        $timeout(() => {
          dziTagMarkupService.showTagsMarkups(dziViewer, tags, $scope.$parent.$parent)
        }, 0)
      })
    }

    function onDragToPositionTagMarkup (event, {tag, evt}) {
      dziTagMarkupService.dragToPositionTagMarkup(dziViewer, tag, evt, $scope.$parent.$parent)
    }

    function onResizeTagShape (event, {tag, evt}) {
      dziTagMarkupService.resizeTagMarkup(dziViewer, tag, evt, $scope.$parent.$parent)
    }

    function removeDziTagMarkupOverlay (event, {tag, evt}) {
      dziTagMarkupService.removeTagMarkup(dziViewer, tag, evt, $scope.$parent.$parent)
      clearDziOverlays()
    }

    function onSetTagMarkup () {
      let selectedTag = ctrl.asset.tags.getSelectedTag()
      $timeout(function () {
        let centerPoint = dziViewer.viewport.getCenter()
        selectedTag.markup.width = 0.1 / dziViewer.viewport.getZoom()
        selectedTag.markup.height = 0.1 / dziViewer.viewport.getZoom()
        selectedTag.markup.left = centerPoint.x - selectedTag.markup.width / 2
        selectedTag.markup.top = centerPoint.y - selectedTag.markup.height / 2
        selectedTag.markup.version = DZI_MARKUP_VERSION_NUMBER
        selectedTag.markup.type = DZI_MARKUP_TYPE
        updatePanAndZoomForTagOrComment(selectedTag.markup)
        displayDziOverlayForTag(selectedTag)
      }, 0)
    }

    function displayDziOverlayForTag (selectTag) {
      $scope.tagInstancesWithMarkups.push(selectTag)
      dziTagMarkupService.showTagsMarkups(dziViewer, [selectTag], $scope.$parent.$parent)
    }

    function rotateTagMarkup (event, {tag, evt}) {
      dziTagMarkupService.rotateTagMarkup(dziViewer, tag, evt, $scope.$parent.$parent)
    }

    function rotateCommentMarkup (event, {rotation}) {
      let selectedComment = ctrl.asset.comments.getEditedComment()
      selectedComment.rotation -= rotation
      updatePanAndZoomForTagOrComment(selectedComment)
    }
  }
})
