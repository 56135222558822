/* global angular */
/**
 * Created by plitvak on 1/8/16.
 */

angular.module('smartvid').directive('svReadFile', () => {
  return {
    scope: {
      svReadFile: '='
    },
    link (scope, element) {
      element.on('change', function (changeEvent) {
        let file = changeEvent.target.files[0]
        if (file) {
          let reader = new window.FileReader()
          reader.onload = function (loadEvent) {
            scope.$apply(function () {
              scope.svReadFile = {file: file, url: loadEvent.target.result}
            })
          }
          reader.readAsDataURL(file)
        }
      })
    }
  }
})
