/* global angular */

angular.module('smartvid').service('listViewUtils', function (utils, uiGridConstants, $templateCache) {
  let createDefaultGridOptions = (scope, headerTemplate, sortChangedCallback,
                                  dataScopeAttributeName, nextPageCallback,
                                  loadFirstPageCallback, setGridApiCallback, rowSelectionChangedCallback) => {
    return {
      enableColumnResizing: false,
      enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
      enableFiltering: false,
      useExternalSorting: true,
      gridMenuShowHideColumns: false,
      appScopeProvider: scope,
      enableColumnMenus: false,
      showGridFooter: true,
      infiniteScrollRowsFromEnd: 10,
      infiniteScrollDown: true,
      rowHeight: 51,
      gridHeaderHeight: 150,
      gridFooterHeight: 50,
      gridFooterTemplate: 'default-list-view-footer',
      headerTemplate: headerTemplate,
      data: dataScopeAttributeName,
      enableFullRowSelection: true,
      enableRowSelection: true,
      multiSelect: false,
      enableRowHeaderSelection: false,
      onRegisterApi: (api) => {
        let gridApi = api
        setGridApiCallback(gridApi)

        if (sortChangedCallback) {
          api.core.on.sortChanged(scope, sortChangedCallback)
        }
        loadFirstPageCallback()
        gridApi.infiniteScroll.on.needLoadMoreData(scope, nextPageCallback)

        gridApi.core.on.scrollEnd(scope, function (event) {
          let rows = gridApi.core.getVisibleRows(gridApi.grid)
          if (rows.length === 0) {
            return
          }
          let rc = event.grid.renderContainers
          scope.showingRow =
            Math.round(rc.body.prevScrollTop / event.grid.options.rowHeight) + 1

          scope.showingRowEnd = rows.length -
            Math.round((rc.body.getCanvasHeight() - rc.body.getViewportHeight() - rc.body.prevScrollTop) / event.grid.options.rowHeight)

          utils.digest(scope)
        })

        gridApi.selection.on.rowSelectionChanged(scope, rowSelectionChangedCallback)
      }
    }
  }

  let initGridHeader = (templateName, headerPlaceholder) => {
    // Custom header with search + https://github.com/angular-ui/ui-grid/blob/master/src/templates/ui-grid/ui-grid-header.html
    $templateCache.put(templateName,
      '<div class="default-list-view-header">' +
      '  <div class="search-row">' +
      '  <icon-search class="search-icon"></icon-search>' +
      '    <input class="search-input" type="search" ng-model="grid.appScope.query" ng-keyup="grid.appScope.performSearch()" placeholder="{{ \'' + headerPlaceholder + '\' | i18next }}"/>' +
      '</div>' +

      '<div role="rowgroup" class="ui-grid-header"> <div class="ui-grid-top-panel"> <div class="ui-grid-header-viewport">' +
      '<div class="ui-grid-header-canvas"><div class="ui-grid-header-cell-wrapper" ng-style="colContainer.headerCellWrapperStyle()">' +
      '<div role="row" class="ui-grid-header-cell-row"> <div class="ui-grid-header-cell ui-grid-clearfix" ng-repeat="col in colContainer.renderedColumns track by col.uid"' +
      'ui-grid-header-cell col="col" render-index="$index">' +
      '</div> </div> </div> </div> </div> </div> </div>' +
      '</div>'
    )
  }

  let initDefaultGridTemplates = () => {
    $templateCache.put('default-list-view-footer',
      '<div>' +
      '  <hr>' +
      '  <span>Showing {{ grid.appScope.showingRow }} of {{ grid.appScope.totalRow }} </span>' +
      '</div>'
    )

    $templateCache.put('default-list-view-checkbox',
      '<div class="checkbox-container">' +
      '  <label class="checkbox" ng-click="grid.appScope.handleCheckbox($event, row.entity)" ng-if="row.entity.canUpdate">' +
      '   <div class="custom-checkbox" ng-class="{ checked: row.entity.selected }">' +
      '     <icon-checkmark ng-if="row.entity.selected" class="fill-red icon-checkmark"></icon-checkmark>' +
      '   </div>' +
      '   <input type="checkbox" ng-model="row.entity.selected">' +
      '  </label>' +
      '</div>'
    )
  }

  return {
    createDefaultGridOptions: createDefaultGridOptions,
    initDefaultGridTemplates: initDefaultGridTemplates,
    initGridHeader: initGridHeader
  }
})
