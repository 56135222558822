import { Component } from '@angular/core'
import { BaseChartDirective } from 'modules/insights/dashboard/charts/models/base-chart.type'
import { PIE_CHART_OPTIONS } from 'modules/insights/dashboard/charts/models/chart-static-options.type'

@Component({
  selector: 'sv-insights-pie-chart',
  templateUrl: 'insights-pie-chart.component.html',
  styleUrls: ['insights-pie-chart.component.scss'],
})
export class InsightsPieChartComponent extends BaseChartDirective {
  getChartType(): string {
    return 'PieChart'
  }

  getPredefinedChartOptions(): {} {
    return PIE_CHART_OPTIONS
  }
}
