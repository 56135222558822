/* global angular _ */

angular.module('smartvid').factory('UserProjectNotificationSettings', function (BaseModel) {
  class UserProjectNotificationSettings extends BaseModel {
    constructor (attrs) {
      let defaults = {
        projectId: undefined,
        isUploadNotification: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return UserProjectNotificationSettings
})
