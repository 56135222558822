/* global angular analytics _ */

angular.module('smartvid').directive('assetGridInfoPanel', function (
  MAX_ASSET_NAME_LENGTH, MAX_ASSET_DESCRIPTION_LENGTH, MAX_ASSETS_FOR_COMMON_TAG_COMPUTE,
  MAX_COMMENT_THREAD_DESCRIPTION_LENGTH, MAX_ASSETS_FOR_COMMON_COMMENT_COMPUTE,
  BATCH_TAG_ASSETS_COUNT_SHORTCUT, $rootScope, $stateParams, $state, $interval, utils, moment, $filter, $timeout,
  smartvidApi, dashboardDataHelper, $q, assetDebugUtils, TagNodesCollection, gaugeSettingsService, CommentsCollection,
  CommentModel, currentUser, infopanelCommentsTabService
) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'AssetGrid/infopanel.html',
    link (scope) {
      scope.MAX_ASSET_NAME_LENGTH = MAX_ASSET_NAME_LENGTH
      scope.MAX_ASSET_DESCRIPTION_LENGTH = MAX_ASSET_DESCRIPTION_LENGTH
      scope.MAX_ASSETS_FOR_COMMON_TAG_COMPUTE = MAX_ASSETS_FOR_COMMON_TAG_COMPUTE
      scope.MAX_ASSETS_FOR_COMMON_COMMENT_COMPUTE = MAX_ASSETS_FOR_COMMON_COMMENT_COMPUTE
      scope.selectedVideos = []
      scope.commonTags = []
      scope.oneAssetSelectedObj = {}
      scope.multipleSelected = false
      scope.onlyOneAssetSelected = false
      scope.assetChanged = false
      scope.manualTags = {
        models: []
      }
      scope.canAddComments = true
      scope.newComment = new CommentModel()
      scope.newComment.editMode = false
      scope.currentlySelectedTab
      scope.canAddTags = false
      scope.tooManyAssetsSelectedCommonTags = false
      scope.tooManyAssetsSelectedCommonComments = false
      scope.isShouldGetMoreAssets = utils.isShouldGetMore
      scope.isProjectScope = $stateParams.projectId
      scope.getProjectIdFromAssets = getProjectIdFromAssets
      scope.showingDeletedFiles = $state.current.name === 'dashboard.projects.projectId.deletedfiles'

      let init = function () {
        updateSelectedAssets() // call on init
      }

      let refreshAssetGridListener = $rootScope.$on('refreshAssetGrid', () => {
        updateSelectedAssets()
      })
      scope.$on('$destroy', refreshAssetGridListener)

      let moreAssetsSelectedListener = $rootScope.$on('sv-more-assets-selected', () => {
        updateSelectedAssets()
      })
      scope.$on('$destroy', moreAssetsSelectedListener)

      scope.$watch('projectTags', () => {
        if (scope.projectTags && scope.projectTags.length > 0) {
          let manualTags = scope.projectTags.findManualTags()
          scope.manualTags.models.length = 0
          Array.prototype.splice.apply(scope.manualTags.models, [0, manualTags.length].concat(manualTags))
        }
      })

      let updateSelectedAssets = function () {
        if (scope.assets.allSelected) {
          populateCollectionToMax(scope.assets, scope.MAX_ASSETS_FOR_COMMON_TAG_COMPUTE)
        }
        let currentSelection = scope.assets.where({selected: true})
        scope.selectedVideos = currentSelection
        scope.canAddTags = _.reduce(currentSelection, function (memo, asset) {
          return memo && asset.canTag
        }, true)
        scope.tagsTabName = (scope.selectedVideos.length > 1)
          ? utils.capitalizeFirstLetter($filter('i18next')('directives.infopanel.commonTags'))
          : utils.capitalizeFirstLetter($filter('i18next')('common.tags'))

        let selectedAssetIds = _.pluck(scope.selectedVideos, 'id')
        let projectId = getProjectIdFromAssets(scope.selectedVideos)

        // Make sure to update tooManyAssetsSelectedCommonTags message as well, when changing MAX_ASSETS_FOR_COMMON_TAG_COMPUTE number
        scope.tooManyAssetsSelectedCommonTags = false
        scope.tooManyAssetsSelectedCommonComments = false
        scope.commonTags = []

        if (selectedAssetIds.length > scope.MAX_ASSETS_FOR_COMMON_TAG_COMPUTE) {
          scope.tooManyAssetsSelectedCommonTags = true
        } else if (selectedAssetIds.length === 1) {
          let before = currentSelection[0]
          currentSelection[0].getTags().then(() => {
            if (before === currentSelection[0]) {
              scope.commonTagInstances = _.uniq(currentSelection[0].tags.models, 'tagDefinitionId')
              scope.commonTags = _.map(scope.commonTagInstances, (tagInstance) => {
                return {
                  text: tagInstance.text,
                  tagDefId: tagInstance.tagDefinitionId
                }
              })
            }
          })
          scope.canAddTags = currentSelection[0].canTag
        } else if (selectedAssetIds.length > 0 && projectId) {
          smartvidApi.getCommonTagDefinitionsForAssets(projectId, selectedAssetIds).then(
            (commonTagDefinitions) => {
              if (!_.isEqual(currentSelection, scope.assets.where({selected: true}))) {
                // Selection has changed since the request was fired, ignore the results
                return
              }
              scope.commonTags = _.map(commonTagDefinitions, t => {
                return {
                  text: t.text,
                  tagDefId: t.id
                }
              })
            })
        }

        if (selectedAssetIds.length > scope.MAX_ASSETS_FOR_COMMON_COMMENT_COMPUTE) {
          scope.tooManyAssetsSelectedCommonComments = true
        } else if (selectedAssetIds.length === 1 && (!scope.oneAssetSelectedObj || scope.oneAssetSelectedObj.id !== currentSelection[0].id)) {
          let before = currentSelection[0]
          scope.commonComments = new CommentsCollection()
          currentSelection[0].getComments().then(() => {
            if (before === currentSelection[0]) {
              scope.commonComments.add(currentSelection[0].comments.models, CommentModel)
              scope.commonComments.models = _.sortBy(scope.commonComments.models, 'createdTime').reverse()
            }
          })
          scope.canAddComments = currentSelection[0].canComment
          scope.newComment = new CommentModel()
          scope.newComment.editMode = false
        }

        scope.onlyOneAssetSelected = scope.selectedVideos.length === 1
        scope.multipleSelected = scope.selectedVideos.length > 1
        scope.oneAssetSelectedObj = (scope.onlyOneAssetSelected) ? scope.selectedVideos[0] : undefined

        if (scope.onlyOneAssetSelected && scope.oneAssetSelectedObj.hasAudio()) {
          if (!scope.oneAssetSelectedObj.snrAvailable() && !scope.oneAssetSelectedObj.isSnrFinished() && !scope.oneAssetSelectedObj.isSnrInProgress()) {
            smartvidApi.runSnrAsset(scope.oneAssetSelectedObj.id).then(() => {
              scope.oneAssetSelectedObj.setSnrInProgress()
            })
          }
        }
        if (scope.oneAssetSelectedObj) {
          assetDebugUtils.printAssetDump(scope.oneAssetSelectedObj)
        }
      }

      scope.$on('sv-placards-selected', updateSelectedAssets)
      // scope.$on('sv-asset-finished-processing', updateSelectedAssets)

      // Listens for removal of Node from Control Bar, updates InfoBar Tag grouping
      let deleteListener = $rootScope.$on('deleteNode', function (event, data) {
        scope.removeTag(data)
      })
      scope.$on('$destroy', deleteListener)

      scope.handleCreateTag = function (tagText) {
        scope.handleSelectedTag(tagText)
      }

      scope.handleSelectedTag = function (tag) {
        let selected = scope.assets.getSelected()
        let multiSelection = scope.assets.getMultiAssetSelection()

        let shouldShortCut =
          (selected.length < BATCH_TAG_ASSETS_COUNT_SHORTCUT) &&
          (multiSelection.unselectedAssetIds === undefined || multiSelection.unselectedAssetIds.length === 0 ||
            (multiSelection.unselectedAssetIds.length + selected.length) < BATCH_TAG_ASSETS_COUNT_SHORTCUT)
        if (shouldShortCut) {
          let before = selected
          let tagTree = null
          if (scope.isProjectScope) {
            tagTree = dashboardDataHelper.getCurrentProjectTagTree()
          } else {
            let asset = _.first(selected)
            let project = _.first(dashboardDataHelper.getAllProjects().where({id: asset.projectId}))
            let organizations = dashboardDataHelper.getAllOrganizations()
            let projectOrganization = _.first(organizations.where({id: project.organizationId}))
            tagTree = new TagNodesCollection(projectOrganization, project)
            tagTree.refresh()
          }
          let promise = createTagIfNeeded(tag, tagTree)
          promise.then(() => {
            let text = (tag.id) ? tag.text : tag
            let tagPromises = _.map(before, (asset) => {
              return createTagInstanceOnAsset(asset, text)
            })
            $q.all(tagPromises).then((tagInstances) => {
              let tagInstance = tagInstances[0]
              let foundTagInstance = _.find(scope.commonTags,
                (existingTag) => {
                  return existingTag.tagDefId === tagInstance.tagDefinitionId
                })
              if (!foundTagInstance) {
                let tagDef = tagTree.getTagDefs().findById(tagInstance.tagDefinitionId)
                scope.commonTags.splice(0, 0, {
                  text: tagDef.text,
                  tagDefId: tagDef.id
                })
              }
            })
          })
        } else {
          $rootScope.$broadcast('sv-trigger-batch-asset-tag', tag)
        }
      }

      function createTagIfNeeded (newTag, tagTree) {
        if (newTag.id) {
          return $q.when()
        }

        let defer = $q.defer()
        tagTree.getTagDefs().loadingPromise.finally(() => {
          let tagDefExists = !!_(tagTree.getTagDefinitionsInOrder() || [])
            .map((tag) => tag.text)
            .filter((txt) => txt.toUpperCase() === newTag.toUpperCase())
            .first()
          if (!tagDefExists) {
            tagTree.createTag(newTag, true, true, true, [], undefined, true).finally(() => {
              defer.resolve()
            })
          }
        })

        return defer.promise
      }

      function createTagInstanceOnAsset (asset, text) {
        let defer = $q.defer()
        asset.getTags().then(() => {
          let payload = {
            assetId: asset.id,
            text: text,
            onTimeline: false,
            startTime: undefined,
            endTime: undefined,
            status: 'VERIFIED',
            type: 'MANUAL'
          }
          asset.addTag(payload).then((tagInstance) => {
            defer.resolve(tagInstance)
          })
        })
        return defer.promise
      }

      scope.removeCommonTag = function (tagDefId) {
        scope.commonTags = _.filter(scope.commonTags, (tag) => {
          return tag.tagDefId !== tagDefId
        })
      }

      scope.removeTagByTagDefId = function (tagDefId) {
        _.each(scope.selectedVideos, function (asset) {
          asset.getTags().then(() => {
            asset.removeTagByTagDefId(tagDefId)
          })
        })
        scope.removeCommonTag(tagDefId)
      }

      scope.onAssetChanged = () => {
        scope.assetChanged = true
      }

      scope.saveAssetChanges = (asset) => {
        if (scope.assetChanged) {
          scope.assetChanged = false
          smartvidApi.updateAsset(asset.id, asset.name, asset.userDescription)
        }
      }

      // comments related logic ------->

      scope.addComment = () => {
        infopanelCommentsTabService.addComment(scope.oneAssetSelectedObj, scope.newComment, scope.commonComments,
          (comment) => {
            scope.newComment = comment
            scope.newComment.id = undefined
          })
      }

      scope.saveComment = () => {
        infopanelCommentsTabService.saveComment(scope.oneAssetSelectedObj, scope.currentProject, scope.commonComments,
          scope.newComment,
          (cc) => {
            scope.handleCommentClick(cc)
          },
          () => {
            // TODO: Is this suppose to be undefined?
            scope.currentTime = undefined

            scope.newComment = new CommentModel()
            scope.newComment.editMode = false
          })
      }

      scope.handleCommentClick = (comment) => {
        infopanelCommentsTabService.handleCommentClick(scope.oneAssetSelectedObj, scope.commonComments, comment,
          !scope.canAddComments,
          () => {
            scope.forceOverflowUpdate = (new Date()).getTime()
          },
          undefined,
          (comment) => {
            comment.selected = true
          })
      }

      scope.showCommentOptions = ($event, comment) => {
        infopanelCommentsTabService.showCommentOptions($event, scope, scope.oneAssetSelectedObj,
          scope.currentProject, scope.commonComments, comment)
      }

      scope.showMoreComment = ($event, comment) => {
        return infopanelCommentsTabService.showMoreComment($event, comment)
      }

      scope.isCommentEditable = (comment) => {
        return infopanelCommentsTabService.isCommentEditable(comment)
      }

      scope.checkForReturnKeyForComment = (evt, comment, editMode) => {
        infopanelCommentsTabService.checkForReturnKeyForComment(evt, scope.oneAssetSelectedObj, scope.currentProject, comment,
          editMode,
          () => {
            scope.saveComment()
          })
      }

      scope.stopEventIfInEditModeForComment = (event, comment) => {
        infopanelCommentsTabService.stopEventIfInEditModeForComment(event, comment)
      }

      scope.checkForArrowKeyForComment = (evt) => {
        infopanelCommentsTabService.checkForArrowKeyForComment(evt)
      }

      // <----- comments related logic

      scope.getGaugeSettings = gaugeSettingsService.getGaugeSettings

      // //////////////////////////////////INIT\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
      init()

      function populateCollectionToMax (assetCollection, max) {
        assetCollection.nextPagePromise.then(() => {
          if (scope.isShouldGetMoreAssets(assetCollection, max)) {
            assetCollection.nextPage()
            populateCollectionToMax(assetCollection, max)
          }
        })
      }

      function getProjectIdFromAssets () {
        let projectIds = _.pluck(scope.selectedVideos, 'projectId')
        let uniqueValues = new Set(projectIds)
        return (uniqueValues.size === 1) ? uniqueValues.values().next().value : undefined
      }
    }
  }
})
