<div class="container">
    <form [formGroup]="rolePermissionForms" class="form-container" (ngSubmit)="updateRolePermissions()">
        <div formGroupName="safetyMonitoring" class="group sm-container" *ngIf="isSafetyMonitoringEnabled()">
            <span class="section-title">Safety Monitoring Analytics</span>
            <mat-divider></mat-divider>
            <div class="checkbox-section">
                <span class="roles-title">Roles & Permissions</span>
                <div class="checkbox-data-list">
                    <div class="checkbox-data">
                        <mat-checkbox formControlName="orgAdmin"></mat-checkbox>
                        <div class="checkbox-description">
                            <span class="chk-title-1">Org Admin</span>
                            <span class="chk-title-2">Can View all organization analytics</span>
                        </div>
                    </div>
                    <div class="checkbox-data">
                        <mat-checkbox formControlName="groupAdmin"></mat-checkbox>
                        <div class="checkbox-description">
                            <span class="chk-title-1">Group Admin</span>
                            <span class="chk-title-2">Can View all organization analytics</span>
                        </div>
                    </div>
                    <div class="checkbox-data">
                        <mat-checkbox formControlName="projectAdmin"></mat-checkbox>
                        <div class="checkbox-description">
                            <span class="chk-title-1">Project Level</span>
                            <span class="chk-title-2">Can View all organization analytics</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="divider" *ngIf="isSafetyMonitoringEnabled()"></div>
        <div formGroupName="safetyObservations" class="group so-container" *ngIf="isSafetyObservationsEnabled()">
            <span class="section-title">Safety Observations Analytics</span>
            <mat-divider></mat-divider>
            <div class="checkbox-section">
                <span class="roles-title">Roles & Permissions</span>
                <div class="checkbox-data-list">
                    <div class="checkbox-data">
                        <mat-checkbox formControlName="orgAdmin"></mat-checkbox>
                        <div class="checkbox-description">
                            <span class="chk-title-1">Org Admin</span>
                            <span class="chk-title-2">Can View all organization analytics</span>
                        </div>
                    </div>
                    <div class="checkbox-data">
                        <mat-checkbox formControlName="groupAdmin"></mat-checkbox>
                        <div class="checkbox-description">
                            <span class="chk-title-1">Group Admin</span>
                            <span class="chk-title-2">Can View all organization analytics</span>
                        </div>
                    </div>
                    <div class="checkbox-data">
                        <mat-checkbox formControlName="projectAdmin"></mat-checkbox>
                        <div class="checkbox-description">
                            <span class="chk-title-1">Project Level</span>
                            <span class="chk-title-2">Can View all organization analytics</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="divider" *ngIf="isSafetyObservationsEnabled()"></div>
        <div formGroupName="predictive" class="group safety-container" *ngIf="isPredictiveEnabled()">
            <span class="section-title">Safety Risk Forecast Analytics</span>
            <mat-divider></mat-divider>
            <div class="checkbox-section">
                <span class="roles-title">Roles & Permissions</span>
                <div class="checkbox-data-list">
                    <div class="checkbox-data">
                        <mat-checkbox formControlName="orgAdmin"></mat-checkbox>
                        <div class="checkbox-description">
                            <span class="chk-title-1">Org Admin</span>
                            <span class="chk-title-2">Can View all organization analytics</span>
                        </div>
                    </div>
                    <div class="checkbox-data">
                        <mat-checkbox formControlName="groupAdmin"></mat-checkbox>
                        <div class="checkbox-description">
                            <span class="chk-title-1">Group Admin</span>
                            <span class="chk-title-2">Can View all organization analytics</span>
                        </div>
                    </div>
                    <div class="checkbox-data">
                        <mat-checkbox formControlName="projectAdmin"></mat-checkbox>
                        <div class="checkbox-description">
                            <span class="chk-title-1">Project Level</span>
                            <span class="chk-title-2">Can View all organization analytics</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="divider" *ngIf="isPredictiveEnabled()"></div>
        <button mat-raised-button id="submit-button" color="primary" type="submit" [disabled]="!rolePermissionForms.valid">Update</button>
    </form>
</div>

