import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import { DashboardTabId, DashboardType } from 'modules/insights/dashboard/models/insights-dashboard.model'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import { InsightsDashboardComponentBaseDirective } from 'modules/insights/dashboard/components/insights-dashboard-component-base'
import { TranslateService } from '@ngx-translate/core'
import { NavController } from 'shared/smartvid.types'
import { ProjectGroupSingleValueDashboardData } from 'modules/insights/dashboard/models/insights-dashboard-project-group-assets.model'
import { InsightsDashboardProjectGroupAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-project-group-assets.service'

@Component({
  selector: 'sv-project-group-assets-insights-dashboard',
  templateUrl: 'project-group-assets-insights-dashboard.component.html',
  styleUrls: ['project-group-assets-insights-dashboard.component.scss'],
})
export class ProjectGroupAssetsInsightsDashboardComponent extends InsightsDashboardComponentBaseDirective
  implements OnInit, OnDestroy {
  projectGroupSingleValueData: ProjectGroupSingleValueDashboardData
  uploadedFilesRatioPercentChangeClassName: string
  uploadersRatioPercentChangeClassName: string
  projectScoreValueClassName: string
  ppeCompliancePercentAvgChangeClassName: string
  workAtHeightAvgPercentChangeClassName: string
  slipAndTripAvgPercentChangeClassName: string

  isConfigurationLoaded = false

  private wasRightNavOpen: boolean

  constructor(
    protected insightsDataHelper: InsightsDataHelper,
    protected insightsDashboardConfigurationService: InsightsDashboardConfigurationService,
    protected translate: TranslateService,
    private insightsDashboardProjectGroupAssetsService: InsightsDashboardProjectGroupAssetsService,
    @Inject('NavController') private navController: NavController
  ) {
    super(
      insightsDataHelper,
      insightsDashboardConfigurationService,
      translate,
      DashboardType.PROJECT_GROUP_ASSETS_DASHBOARD
    )
  }

  ngOnInit() {
    this.wasRightNavOpen = this.navController.isRightNavOpen()
    this.navController.closeRightNav()
    this.loadDashboardConfiguration()
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    if (this.wasRightNavOpen) {
      this.navController.openRightNav()
    }
  }

  getTabTitle(tabId: DashboardTabId) {
    let tabConfig = this.insightsDashboardConfigurationService.getTabConfiguration(tabId)
    return tabConfig && tabConfig.displayLabel ? tabConfig.displayLabel : ''
  }

  protected refresh() {
    this.loadSummaryCardsData()
  }

  private loadSummaryCardsData() {
    this.projectGroupSingleValueData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadProjectGroupSingleValueDashboardData(
        this.currentInsightsDashboardParameters,
        (data: ProjectGroupSingleValueDashboardData) => {
          this.projectGroupSingleValueData = data
          this.projectScoreValueClassName = this.pickClassNameFor(
            this.projectGroupSingleValueData.projectScoreAvg,
            'bg-na-value',
            [
              [35, 'bg-critical-value'],
              [45, 'bg-warning-3-value'],
              [55, 'bg-warning-2-value'],
              [65, 'bg-warning-1-value'],
              [100, 'bg-normal-value'],
            ]
          )
          this.uploadedFilesRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.projectGroupSingleValueData.uploadedFilesRatioPercentChange
          )
          this.uploadersRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.projectGroupSingleValueData.uploadersRatioPercentChange
          )
          this.ppeCompliancePercentAvgChangeClassName = this.pickColorClassNameForPercentChange(
            this.projectGroupSingleValueData.ppeCompliancePercentAvgChange
          )
          this.workAtHeightAvgPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.projectGroupSingleValueData.workAtHeightAvgPercentChange
          )
          this.slipAndTripAvgPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.projectGroupSingleValueData.slipAndTripAvgPercentChange
          )
        }
      )
    )
  }

  private loadDashboardConfiguration() {
    this.apiCallsSubscriptions.push(
      this.insightsDashboardConfigurationService.loadDashboardConfiguration(
        {
          dashboardType: DashboardType.PROJECT_GROUP_ASSETS_DASHBOARD,
          organizationId: this.currentInsightsDashboardParameters.organizationId,
          projectGroupId: this.currentInsightsDashboardParameters.projectGroupId,
          projectId: this.currentInsightsDashboardParameters.projectId,
          userId: this.currentInsightsDashboardParameters.userId,
        },
        () => {
          this.isConfigurationLoaded = true
          this.refresh()
        }
      )
    )
  }
}
