/* global angular */

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static'
import { ForgeFolderTreeComponent } from '../angular2/modules/integrations/components/forge-folder-tree/forge-folder-tree.component'
import { ForgeTemplateTreeComponent } from '../angular2/modules/integrations/components/forge-template-tree/forge-template-tree.component'
import { ForgeIssueTypeTreeComponent } from '../angular2/modules/integrations/components/forge-issue-type-tree/forge-issue-type-tree.component'

angular
  .module('smartvid')
  .directive('svForgeFolderTree', downgradeComponent({ component: ForgeFolderTreeComponent }))
  .directive('svForgeTemplateTree', downgradeComponent({ component: ForgeTemplateTreeComponent }))
  .directive('svForgeIssueTypeTree', downgradeComponent({ component: ForgeIssueTypeTreeComponent }))
