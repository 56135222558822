/* global angular, _ */

angular.module('smartvid').service('organizationsApi', function (
    $http, $log, $q, config, UserModel, Notification, $filter, utils, UserOrganizationNotificationSettings,
    dashboardDataHelper, $injector
) {
  let rootUrl = config.env.development.apiRootUrl

  let flattenOrgData = (data) => {
    let organization = data.organization
    let org = data
    _.each(org, (value, key) => {
      if (key !== 'organization') {
        organization[key] = value
      }
    })
    return organization
  }

  let api = {
    createUser (organizationId, usersList, customErrorHandler) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/user`
      let payload = {
        organizationId: organizationId,
        users: _(usersList || []).map((u) => {
          return {
            'organizationId': organizationId,
            firstName: u.firstName,
            lastName: u.lastName,
            email: u.email,
            role: u.role,
            observationRole: u.observationRole
          }
        }).value()
      }

      $http.post(url, payload, {
        customErrorHandler: customErrorHandler
      }).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    getOrganizationNotificationSettings () {
      let url = `${rootUrl}/api/organization/user/notification/settings`
      let defer = $q.defer()

      $http.get(url).then(function (response) {
        let organizationSettings = []
        _.each(response.data, (settings) => {
          organizationSettings.push(new UserOrganizationNotificationSettings(settings))
        })
        defer.resolve(organizationSettings)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    removeFromOrganization (organizationId, userIds) {
      let url = `${rootUrl}/api/organization/${organizationId}/user/invitation`
      let defer = $q.defer()
      let payload = {
        userIds: userIds
      }
      $http.post(url, payload).then(function (response) {
        $log.debug('removeFromOrganization', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    createOrganizationAddUpdatePayload (requestParamName, organizationName, organizationDescription, signUpMode,
                                        emailDomains, publicSharingEnabled, ssoLoginUrl,
                                        thumbnail, logo, clearThumbnail, clearLogo, projectLevelTagsDisabled) {
      let payload = {
        name: organizationName,
        description: organizationDescription
      }
      if (angular.isDefined(publicSharingEnabled)) {
        payload.publicSharingEnabled = publicSharingEnabled
      }
      if (angular.isDefined(projectLevelTagsDisabled)) {
        payload.projectLevelTagsDisabled = projectLevelTagsDisabled
      }
      if (angular.isDefined(signUpMode)) {
        payload.signUpMode = signUpMode
      }
      if (emailDomains) {
        payload.emailDomains = emailDomains.split(',')
      }
      payload.ssoLoginUrl = ssoLoginUrl

      if (angular.isDefined(clearThumbnail)) {
        payload.clearThumbnail = clearThumbnail
      }
      if (angular.isDefined(clearLogo)) {
        payload.clearLogo = clearLogo
      }
      var fd = new window.FormData()
      fd.append(requestParamName, utils.utf8ToB64(JSON.stringify(payload)))

      if (thumbnail) {
        fd.append('thumbnail', thumbnail)
      } else {
        fd.append('thumbnail', [])
      }
      if (logo) {
        fd.append('logo', logo)
      } else {
        fd.append('logo', [])
      }
      return fd
    },
    createOrganization (organizationName, organizationDescription, emailDomains, thumbnail, logo) {
      let defer = $q.defer()

      let onSuccess = (json) => {
        defer.resolve(json)
      }

      let onResponse = (response) => {
        if (response) {
          $log.debug('createOrganization', response)
          onSuccess(flattenOrgData(response.data))
        }
      }

      let fd = api.createOrganizationAddUpdatePayload(
        'createOrganizationRequest', organizationName, organizationDescription, undefined, emailDomains,
        undefined, undefined, thumbnail, logo, undefined, undefined)
      let url = `${rootUrl}/api/organization`

      $http.post(url, fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      }).then(onResponse, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    },
    updateOrganization (organizationId, organizationName, organizationDescription, signUpMode, emailDomains,
                        publicSharingEnabled, ssoLoginUrl,
                        thumbnail, logo, clearThumbnail, clearLogo, projectLevelTagsDisabled) {
      let defer = $q.defer()

      let onSuccess = (updatedOrg) => {
        let currentOrg = dashboardDataHelper.getOrganizationByOrgId(organizationId)
        let currentUser = $injector.get('currentUser')
        currentUser.updateOrganization(updatedOrg)
        angular.copy(updatedOrg, currentOrg)
        defer.resolve(updatedOrg)
      }

      let onResponse = (response) => {
        if (response) {
          $log.debug('updateOrganization', response)
          onSuccess(flattenOrgData(response.data))
        }
      }

      let fd = api.createOrganizationAddUpdatePayload(
        'updateOrganizationRequest', organizationName, organizationDescription, signUpMode,
        emailDomains, publicSharingEnabled, ssoLoginUrl, thumbnail, logo,
        clearThumbnail, clearLogo, projectLevelTagsDisabled)
      let url = `${rootUrl}/api/organization/` + organizationId

      $http.post(url, fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      }).then(onResponse, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    addProjectGroupToOrganization (organizationId, groupName) {
      let defer = $q.defer()
      let projectGroupApi = $injector.get('projectGroupApi')
      projectGroupApi.addGroup(organizationId, groupName).toPromise().then((projectGroup) => {
        this.getOrganizationById(organizationId).then(serverOrg => {
          let currentUser = $injector.get('currentUser')
          currentUser.updateOrganization(serverOrg)
          let currentOrg = dashboardDataHelper.getOrganizationByOrgId(organizationId)
          angular.copy(serverOrg, currentOrg)
          defer.resolve(currentOrg)
        }, () => {
          defer.reject()
        })
      }, (response) => {
        defer.reject(response.data)
      })
      return defer.promise
    },
    removeProjectGroupFromOrganization (organizationId, projectGroupId) {
      let defer = $q.defer()
      let projectGroupApi = $injector.get('projectGroupApi')
      projectGroupApi.removeGroup(organizationId, projectGroupId).toPromise().then((projectGroup) => {
        this.getOrganizationById(organizationId).then(serverOrg => {
          let currentUser = $injector.get('currentUser')
          currentUser.updateOrganization(serverOrg)
          let currentOrg = dashboardDataHelper.getOrganizationByOrgId(organizationId)
          angular.copy(serverOrg, currentOrg)
          defer.resolve(currentOrg)
        }, () => {
          defer.reject()
        })
      }, (response) => {
        defer.reject(response.data)
      })
      return defer.promise
    },
    updateProjectGroup (organizationId, projectGroupId, groupName) {
      let defer = $q.defer()
      let projectGroupApi = $injector.get('projectGroupApi')
      projectGroupApi.updateGroup(organizationId, projectGroupId, groupName).toPromise().then((projectGroup) => {
        this.getOrganizationById(organizationId).then(serverOrg => {
          let currentUser = $injector.get('currentUser')
          currentUser.updateOrganization(serverOrg)
          let currentOrg = dashboardDataHelper.getOrganizationByOrgId(organizationId)
          angular.copy(serverOrg, currentOrg)
          defer.resolve(currentOrg)
        }, () => {
          defer.reject()
        })
      }, (response) => {
        defer.reject(response.data)
      })
      return defer.promise
    },
    deleteOrganization (organizationId) {
      let url = `${rootUrl}/api/organization/${organizationId}`
      let defer = $q.defer()

      $http.delete(url).then(function (response) {
        $log.debug('deleteOrganization', response.data)
        defer.resolve(response.data)
      }, function (response) {
        let errorOptions = {
          title: $filter('i18next')('deleteOrganization.deleteFailed')
        }

        if (response.data.isAttemptedToDeleteOwnMyOrg) {
          errorOptions.message = $filter('i18next')('deleteOrganization.myOrg')
        } else if (response.data.projectsCount) {
          errorOptions.message = $filter('i18next')('deleteOrganization.projectsExist')
        } else if (response.data.usersCount) {
          errorOptions.message = $filter('i18next')('deleteOrganization.usersExist')
        } else {
          errorOptions.message = $filter('i18next')('deleteOrganization.unexpectedFailure')
        }

        Notification.error(errorOptions)

        defer.reject(response.data)
      })

      return defer.promise
    },
    getOrganizationById (organizationId) {
      let url = `${rootUrl}/api/organization/${organizationId}`
      let defer = $q.defer()
      $http.get(url).then(function (response) {
        defer.resolve(flattenOrgData(response.data))
      }, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    },
    getUser (organizationId, userId) {
      let url = `${rootUrl}/api/organization/` + organizationId + '/user/' + userId
      let defer = $q.defer()

      $http.get(url).then(function (response) {
        let model = new UserModel(response.data)
        defer.resolve(model)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    autoJoinRequest (organizationId, userId) {
      let url = `${rootUrl}/api/organization/` + organizationId + '/auto-join-request'
      let defer = $q.defer()

      let payload = {
        userId: userId
      }

      $http.post(url, payload).then(function (response) {
        defer.resolve()
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    getOrganizationUsers (organizationId, options = {}) {
      let defaults = {
        pageSize: 50,
        page: 0
      }

      _.defaults(options, defaults)

      let url = `${rootUrl}/api/user`
      let defer = $q.defer()
      let config = {
        params: {
          organizationId: organizationId,
          sortColumn: options.sortColumn,
          offset: options.pageSize * options.page,
          limit: options.pageSize
        }
      }

      $http.get(url, config).then(function (response) {
        let users = response.data.data
        defer.resolve(users)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getOrganizationUsersCount (organizationId) {
      let url = `${rootUrl}/api/user/count`
      let defer = $q.defer()
      let config = {
        params: {
          organizationId: organizationId
        }
      }

      $http.get(url, config).then(function (response) {
        defer.resolve(response.data.totalCount)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    updateUser (organizationId, userId, firstName, lastName, email, role, observationRole) {
      let defer = $q.defer()

      let onSuccess = (json) => {
        defer.resolve(json)
      }

      let onResponse = (response) => {
        if (response) {
          $log.debug('updateUser', response)
          onSuccess(response.data)
        }
      }

      let payload = {
        organizationId: organizationId,
        id: userId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        role: role,
        observationRole: observationRole
      }

      let url = `${rootUrl}/api/user`

      $http.put(url, payload).then(onResponse, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    removeUserFromOrganization (organizationId, userId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/organization/user/invitation`
      let payload = {
        'organizationId': organizationId,
        'uninvitedUsers': [userId]
      }

      $http.post(url, payload).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    validateOrganizationEmailDomains (organizationId, domains) {
      let defer = $q.defer()

      let onSuccess = (json) => {
        defer.resolve(json)
      }

      let onResponse = (response) => {
        if (response) {
          $log.debug('validateOrganizationEmailDomains', response)
          onSuccess(response.data)
        }
      }

      let url = `${rootUrl}/api/organization/${organizationId}/domains/check`

      let payload = {
        emailDomains: domains
      }

      $http.post(url, payload).then(onResponse, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    }
  }

  return api
})
