export enum ObservationDetails {
  ASSETS = 'ASSETS',
  TRADE_PARTNER = 'TRADE_PARTNER',
  ASSIGNED_USER = 'ASSIGNED_USER',
  CREATED_BY_USER = 'CREATED_BY_USER',
  UPLOADING_FILES = 'UPLOADING_FILES',
  UPDATED_BY_USER = 'UPDATED_BY_USER',
  NONE = 'NONE',
}

export const LIST_VIEW_OBSERVATION_DETAILS = [ObservationDetails.TRADE_PARTNER, ObservationDetails.ASSIGNED_USER]
