import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import { TranslateService } from '@ngx-translate/core'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import {
  CancellableRequestSubscription,
  DashboardCardId,
  DashboardDateRangeTimeUnit,
  DashboardTabId,
  DashboardType,
  InsightsDashboardParameters,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { CardToggleMenuGroup } from 'modules/insights/dashboard/cards/models/insights-card.model'
import { Subscription } from 'rxjs'
import { OnDestroy, OnInit, Directive } from '@angular/core'
import { forceUnsubscribe, getColorClassForValue, getSign, isNA, pickClassNameFor } from '../utils/dashboard-utils'
import { v4 as uuid } from 'uuid'

@Directive()
export abstract class InsightsDashboardComponentBaseDirective implements OnInit, OnDestroy {
  protected paramChangeSubscription: Subscription
  protected wasOnDestroyCalled = false
  // for debugging purposes
  protected instanceId = uuid()

  protected apiCallsSubscriptions: CancellableRequestSubscription[] = []

  getColorClassForValue = getColorClassForValue
  pickClassNameFor = pickClassNameFor
  getSign = getSign
  isNA = isNA

  protected constructor(
    protected insightsDataHelper: InsightsDataHelper,
    protected insightsDashboardConfigurationService: InsightsDashboardConfigurationService,
    protected translate: TranslateService,
    protected dashboardType: DashboardType
  ) {
    this.paramChangeSubscription = this.insightsDataHelper.onParametersChange$(this.dashboardType).subscribe(() => {
      this.refresh()
    })
  }

  protected getClassName() {
    return this.constructor.name
  }

  ngOnInit(): void {
    this.refresh()
  }

  ngOnDestroy(): void {
    this.wasOnDestroyCalled = true

    forceUnsubscribe(this.paramChangeSubscription)

    if (this.apiCallsSubscriptions) {
      this.apiCallsSubscriptions.forEach(cs => cs.cancel())
    }
    this.apiCallsSubscriptions = undefined
  }

  protected abstract refresh()

  get currentInsightsDashboardParameters(): InsightsDashboardParameters {
    return this.insightsDataHelper.getCurrentInsightsDashboardParameters(this.dashboardType)
  }

  protected pickColorClassNameForPercentChange(value: number): string {
    return this.pickClassNameFor(value, 'na-value', [
      [0, 'critical-value'],
      [0.001, 'warning-value'], // effectively 0
      [100, 'normal-value'],
    ])
  }

  protected guardFor(func: () => void, forTabId: DashboardTabId, forCardId: DashboardCardId) {
    if (!this.wasOnDestroyCalled && this.insightsDashboardConfigurationService.hasTabCard(forTabId, forCardId)) {
      func()
    }
  }

  protected guardForFuncCallInDestroy(func: () => void) {
    if (!this.wasOnDestroyCalled) {
      func()
    }
  }

  protected createChartDateSwitchMenuItems(groupId: string, enJsSectionName: string): CardToggleMenuGroup[] {
    // new CardMenuItem({
    //   label: 'Checkbox',
    //   eventId: 'CB',
    //   type: CardMenuItemType.CHECK_BOX,
    // }),
    // new CardMenuItem({
    //   label: 'Reg',
    //   eventId: 'R',
    //   type: CardMenuItemType.REGULAR,
    // }),
    return [
      {
        groupId: groupId,
        groupItems: [
          {
            label: this.translate.instant(`dashboard.cards.${enJsSectionName}.menu.day`),
            eventId: DashboardDateRangeTimeUnit.LAST_1,
            isChecked: true,
            isDisabled: this.currentInsightsDashboardParameters.dateRangeTimeUnit === DashboardDateRangeTimeUnit.LAST_1,
          },
          {
            label: this.translate.instant(`dashboard.cards.${enJsSectionName}.menu.week`),
            eventId: DashboardDateRangeTimeUnit.LAST_7,
            isDisabled: [DashboardDateRangeTimeUnit.LAST_1, DashboardDateRangeTimeUnit.LAST_7].includes(
              this.currentInsightsDashboardParameters.dateRangeTimeUnit
            ),
          },
          {
            label: this.translate.instant(`dashboard.cards.${enJsSectionName}.menu.month`),
            eventId: DashboardDateRangeTimeUnit.LAST_30,
            isDisabled: [
              DashboardDateRangeTimeUnit.LAST_1,
              DashboardDateRangeTimeUnit.LAST_7,
              DashboardDateRangeTimeUnit.LAST_30,
            ].includes(this.currentInsightsDashboardParameters.dateRangeTimeUnit),
          },
        ],
      },
    ]
  }
}
