<div class="forge-template-tree">

    <div class="search-form-field-wrap">
        <div class="search-form-field">
            <input id="search-forge-template-field"
                   matInput type="text"
                   placeholder="Search"
                   [(ngModel)]="searchTerm"
                   (ngModelChange)="onSearch(searchTerm)">
            <button mat-button *ngIf="searchTerm" (click)="clearSearch()" matSuffix mat-icon-button aria-label="Clear">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- Leaf node -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <button mat-icon-button disabled></button>
            <mat-checkbox class="checklist-leaf-node"
                          [disabled]="!expandable || readOnly"
                          [checked]="checkboxSelection.isSelected(node)"
                          (change)="toggleCheckbox(node)">{{node.name}}</mat-checkbox>
        </mat-tree-node>

        <!-- Expandable node -->
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <button mat-icon-button *ngIf="!expandable" disabled>
                <mat-icon class="mat-icon-rtl-mirror">chevron_right</mat-icon>
            </button>
            <button mat-icon-button *ngIf="expandable"
                    [attr.aria-label]="'Toggle ' + node.name"
                    (click)="clickLoadMore(node)"
                    [disabled]="node.isLoading"
                    matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <mat-checkbox [checked]="checkboxSelection.isSelected(node)"
                          [indeterminate]="partiallySelection.isSelected(node)"
                          [disabled]="node.isLoading || !expandable || readOnly"
                          (change)="toggleCheckbox(node)">
                {{node.name}}
                <mat-spinner *ngIf="node.isLoading"
                             [diameter]="24"
                             class="folder-spinner"></mat-spinner>
            </mat-checkbox>
        </mat-tree-node>
    </mat-tree>
</div>