/* global angular, _, $ */

angular.module('smartvid').directive('tagcontrolbar', function ($document, $rootScope, $stateParams, utils, videoPlayer, smartvidApi, currentUser) {
  $document = $document[0]
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'tagcontrolbar.html',
    scope: {
      progress: '@',
      length: '@',
      tags: '=',
      asset: '=',
      viewLimits: '=',
      comments: '='
    },
    link (scope, elem) {
      const OFFSET_OF_TAG_WIDTH = 6
      const PADDING_AROUND_PROGRESSBAR = 30

      let downloadProgress = '0%'

      scope.isDragging = false

      let _calcWidth = (startTime, endTime) => {
        return (endTime - startTime) / scope.length * (elem[0].offsetWidth - PADDING_AROUND_PROGRESSBAR)
      }

      let _precisionOfThree = (number) => {
        return Math.round(number * 1000) / 1000
      }

      let _debouncedPlayerScrubbing = _.debounce(function (newTime) {
        videoPlayer.currentTime(newTime)
      }, 50)

      let setWidthOfProgressBar = function (newTime) {
        let progressElem = $document.getElementById('player-progress')
        let playerStart = videoPlayer.startLimit ? videoPlayer.startLimit : 0
        let playerEnd = videoPlayer.endLimit ? videoPlayer.endLimit : videoPlayer.duration()
        progressElem.style.width = (newTime - playerStart) / (playerEnd - playerStart) * 100 + '%'
      }

      scope.getWidth = () => {
        let width = scope.progress + '%'

        return {
          width: width
        }
      }

      scope.getDownloadWidth = () => {
        return {
          width: downloadProgress
        }
      }

      scope.showComment = (comment) => {
        $rootScope.$broadcast('sv-comment-clicked', comment)
      }

      scope.selectComment = (comment) => {
        $rootScope.$broadcast('sv-comment-clicked', comment)
      }

      scope.selectTag = (tag) => {
        $rootScope.$broadcast('sv-tag-clicked', tag)
      }

      scope.goTo = (evt) => {
        let percentOfVideo = evt.offsetX / (elem[0].offsetWidth - PADDING_AROUND_PROGRESSBAR)
        let newPos = scope.length * percentOfVideo
        let playerStart = videoPlayer.startLimit ? videoPlayer.startLimit : 0
        videoPlayer.currentTime(newPos + playerStart)
      }

      scope.$on('sv-player-play', function () {
        scope.comments.unselectAllAndPersistChanges($stateParams.assetId)
        utils.digest(scope)
      })

      scope.$on('sv-player-load-progress', function (evt, data) {
        let playerStart = videoPlayer.startLimit ? videoPlayer.startLimit : 0
        let playerEnd = videoPlayer.endLimit ? videoPlayer.endLimit : videoPlayer.duration()
        downloadProgress = (Math.min(data.end, playerEnd) - playerStart) / videoPlayer.duration() * 100 + '%'
      })

      scope.getPosition = (tag) => {
        if (!angular.isFunction(tag.startTimeInSeconds) && !tag.local) {
          return
        }

        let playerStart = videoPlayer.startLimit ? videoPlayer.startLimit : 0
        let startTime = (tag.local ? tag.startTime / 1000 : tag.startTimeInSeconds()) - playerStart
        let endTime = (tag.local ? tag.endTime / 1000 : tag.endTimeInSeconds()) - playerStart

        let left = startTime / scope.length * 100 + '%'

        // If the tag.endTimeInSeconds() is greater then duration then set it the end of the video.
        if (videoPlayer.duration() && endTime > videoPlayer.duration()) {
          tag.endTime = videoPlayer.duration() * 1000
        }

        let width = _calcWidth(startTime, endTime) + 'px'

        return {
          left: left,
          width: width
        }
      }

      scope.getCommentPosition = (comment) => {
        let playerStart = videoPlayer.startLimit ? videoPlayer.startLimit : 0
        let left = (comment.startTime / 1000 - playerStart) / scope.length * 100 + '%'

        return {
          left: left
        }
      }

      scope.userCanUpdateComment = function (comment, user) {
        return user.userId === comment.creator.id
      }

      scope.dragComment = function (evt, comment) {
        if (scope.asset.isReadOnly || !comment.isEditable || !scope.userCanUpdateComment(comment, currentUser)) {
          return
        }
        let commentElem = evt.currentTarget
        let containingElem = $('.centered-controls')[0]
        let startRelativeToViewport = evt.pageX !== undefined ? evt.pageX : evt.clientX
        let progressBarElem = $document.getElementById('player-progressbar')
        let start = startRelativeToViewport - containingElem.getBoundingClientRect().left
        videoPlayer.pause()

        $document.body.onmousemove = (evt) => {
          let newPos
          let newTime
          let newLeft
          let delta

          comment.editMode = true
          newPos = evt.pageX !== undefined ? evt.pageX : evt.clientX

          delta = newPos - startRelativeToViewport
          newLeft = start + delta
          commentElem.style.left = newLeft + 'px'

          newTime = newLeft / progressBarElem.offsetWidth * videoPlayer.duration()
          comment.startTime = newTime * 1000
          _debouncedPlayerScrubbing(newTime)
          setWidthOfProgressBar(newTime)
        }

        $document.body.onmouseup = (evt) => {
          document.body.onmousemove = document.body.onmouseup = null
        }
      }

      scope.dragForStartTime = function (evt, tag) {
        if (scope.asset.isReadOnly) {
          return
        }

        var end
        var editContainerElem
        var tagContainerElem
        var width

        tag.editMode = true

        evt = evt || window.event
        end = evt.pageX !== undefined ? evt.pageX : evt.clientX
        end = end + _calcWidth(tag.startTimeInSeconds(), tag.endTimeInSeconds())
        editContainerElem = evt.target.parentNode
        tagContainerElem = evt.target.parentNode.parentNode

        $document.body.onmousemove = function (evt) {
          var start
          var newTime
          evt = evt || window.event
          start = evt.pageX !== undefined ? evt.pageX : evt.clientX
          width = end - start + OFFSET_OF_TAG_WIDTH

          if (width < 0) {
            width = 0
          }

          newTime = _precisionOfThree(tag.endTimeInSeconds() - width / (elem[0].offsetWidth - PADDING_AROUND_PROGRESSBAR) * scope.length)

          tag.startTime = newTime * 1000

          editContainerElem.style.width = width + 'px'
          tagContainerElem.style.left = newTime / scope.length * 100 + '%'

          _debouncedPlayerScrubbing(newTime)
          setWidthOfProgressBar(newTime)
        }

        $document.body.onmouseup = function (evt) {
          // If we do not have a width, then we never got a mousemove event, which means we should do nothing
          if (angular.isUndefined(width)) {
            document.body.onmousemove = document.body.onmouseup = null
            return
          }

          let newTime = _precisionOfThree(tag.endTimeInSeconds() - width / (elem[0].offsetWidth - PADDING_AROUND_PROGRESSBAR) * scope.length)
          if (newTime < 0) {
            newTime = 0
          }
          tag.startTime = newTime * 1000

          videoPlayer.currentTime(newTime)
          editContainerElem.style.width = _calcWidth(tag.startTimeInSeconds(), tag.endTimeInSeconds()) + 'px'
          tagContainerElem.style.left = tag.startTimeInSeconds() / scope.length * 100 + '%'
          document.body.onmousemove = document.body.onmouseup = null

          // update tag on change
          smartvidApi.updateTagForAsset(tag)
        }
      }

      scope.dragForEndTime = function (evt, tag) {
        if (scope.asset.isReadOnly) {
          return
        }

        var start
        var editContainerElem
        var width

        tag.editMode = true

        evt = evt || window.event
        start = evt.pageX !== undefined ? evt.pageX : evt.clientX
        start = start - _calcWidth(tag.startTimeInSeconds(), tag.endTimeInSeconds())
        editContainerElem = evt.target.parentNode

        $document.body.onmousemove = function (evt) {
          let newTime
          evt = evt || window.event
          let end = evt.pageX !== undefined ? evt.pageX : evt.clientX
          width = end - start + OFFSET_OF_TAG_WIDTH

          if (width < 0) {
            width = 0
          }

          newTime = _precisionOfThree(tag.startTimeInSeconds() + width / (elem[0].offsetWidth - PADDING_AROUND_PROGRESSBAR) * scope.length)
          let playerStart = videoPlayer.startLimit ? videoPlayer.startLimit : 0
          if (newTime > videoPlayer.duration() + playerStart) {
            newTime = videoPlayer.duration() + playerStart
          }

          tag.endTime = newTime * 1000

          editContainerElem.style.width = width + 'px'

          _debouncedPlayerScrubbing(newTime)
          setWidthOfProgressBar(newTime)
        }

        $document.body.onmouseup = function (evt) {
          // If we do not have a width, then we never got a mousemove event, which means we should do nothing
          if (angular.isUndefined(width)) {
            document.body.onmousemove = document.body.onmouseup = null
            return
          }

          let newTime = _precisionOfThree(tag.startTimeInSeconds() + width / (elem[0].offsetWidth - PADDING_AROUND_PROGRESSBAR) * scope.length)
          let playerStart = videoPlayer.startLimit ? videoPlayer.startLimit : 0
          if (newTime > videoPlayer.duration() + playerStart) {
            newTime = videoPlayer.duration() + playerStart
          }
          tag.endTime = newTime * 1000
          smartvidApi.updateTagForAsset(tag)

          videoPlayer.currentTime(newTime)
          editContainerElem.style.width = _calcWidth(tag.startTimeInSeconds(), tag.endTimeInSeconds()) + 'px'
          document.body.onmousemove = document.body.onmouseup = null
        }
      }

      scope.dragPlayhead = (evt) => {
        let start
        let wasPlaying = !videoPlayer.paused()
        let progressElem = $document.getElementById('player-progress')
        let width = progressElem.offsetWidth
        let progressBarElem = $document.getElementById('player-progressbar')
        let containerWidth = progressBarElem.offsetWidth
        let newWidth

        videoPlayer.pause()

        if (!scope.asset.isReadOnly) {
          scope.comments.unselectAllAndPersistChanges($stateParams.assetId)
        }

        evt = evt || window.event
        start = evt.pageX !== undefined ? evt.pageX : evt.clientX
        scope.isDragging = true

        $document.body.onmousemove = (evt) => {
          let newPos
          let delta
          let timeToSeekTo
          evt = evt || window.event
          newPos = evt.pageX !== undefined ? evt.pageX : evt.clientX

          delta = newPos - start
          newWidth = width + delta
          progressElem.style.width = width + delta + 'px'

          timeToSeekTo = newWidth / containerWidth * videoPlayer.duration()
          _debouncedPlayerScrubbing(timeToSeekTo)
        }

        $document.body.onmouseup = (evt) => {
          let timeToSeekTo
          scope.isDragging = false
          timeToSeekTo = newWidth / containerWidth * videoPlayer.duration()
          videoPlayer.currentTime(timeToSeekTo)
          if (wasPlaying) {
            videoPlayer.play()
          }

          document.body.onmousemove = document.body.onmouseup = null
        }
      }

      // Provenance Indicator
      function position (instance, helper, position) {
        position.coord.left -= 7
        return position
      }

      scope.provenanceIndicatorOptions = {
        content: elem,
        contentCloning: false,
        arrow: false,
        side: 'top',
        delay: [500, 0],
        debug: false,
        theme: ['tooltipster-borderless', 'tooltipster-provenance-indicator'],
        functionPosition: position
      }
    }
  }
})
