/* global angular,analytics, _ */

angular.module('smartvid').controller('DeletedProjectOptionsCtrl', function ($rootScope, $scope, $timeout, $window, $state,  dashboardDataHelper, smartvidApi, modal, utils, batchJobService) {
  const LEFT_OFFSET = 15
  const TOP_OFFSET = 17

  $scope.deleteProject = (projects, project, allProjects) => {
    $scope.flyout.isOpen = false

    let confirmHandlers = {
      confirm () {
        analytics.track('Delete Project', {
          category: 'Project Action',
          orgName: (project.getProjectOrg()) ? project.getProjectOrg().name : undefined,
          projectName: project.name
        })
        smartvidApi.deleteProjects([project.id], true).then(() => {
          $timeout(() => {
            projects.removeById(project.id)
            dashboardDataHelper.getAllProjects().removeById(project.id)
            if (allProjects) {
              allProjects.removeById(project.id)
            }
            $state.go('dashboard.adminOrganizations.organizationId.deletedProjects', {}, {
              reload: true
            })
          }, 400)
          $scope.flyout.close()
        })
      },
      hasSelections: true
    }

    modal.open('projectPermanentlyDeleteConfirm', confirmHandlers)
  }
  $scope.restoreProject = (projects, project, allProjects) => {
    $scope.flyout.isOpen = false

    let confirmHandlers = {
      confirm () {
        analytics.track('Restore Project', {
          category: 'Project Action',
          orgName: (project.getProjectOrg()) ? project.getProjectOrg().name : undefined,
          projectName: project.name
        })
        smartvidApi.restoreProjects(project.organizationId, [project.id]).then((jobResponse) => {
          if (!jobResponse.jobName || !jobResponse.jobId) {
            return
          }
          let successCallback = () => {
            $timeout(() => {
              projects.removeById(project.id)
              dashboardDataHelper.getAllProjects().removeById(project.id)
              if (allProjects) {
                allProjects.removeById(project.id)
              }
              $state.go('dashboard.adminOrganizations.organizationId.deletedProjects', {}, {
                reload: true
              })
            }, 400)
            utils.notify('common.projectRestore', '', 'common.view', null, () => {
              $state.go('dashboard.projects.projectId.files',
                {
                  projectId: project.id,
                  isDemoProject: project.isDemoProjectCopy,
                  searchContext: undefined,
                  searchFilter: undefined,
                  skipRecentSearches: true,
                  organizationId: project.organizationId,
                  projectGroupId: project.projectGroupId
                },
                {
                  reload: true
                })
            })
            $scope.flyout.close()
          }
          batchJobService.watchBatchJob(
            smartvidApi.getOrgJobStatus, project.organizationId, jobResponse.jobName, jobResponse.jobId,
            null, null, successCallback, jobResponse);
        })
      },
      hasSelections: true
    }

    modal.open('projectRestoreConfirm', confirmHandlers)
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top - TOP_OFFSET,
      left: $scope.styles.left + LEFT_OFFSET
    }
  }
})
