/* global angular */

angular.module('smartvid').service('tagConfidenceLevelService', function (searchResultService, TAG_CONFIDENCE_LEVEL_HIGH) {
  this.tagConfidenceLevel

  // Allows override of asset grid tag confidence level with a different value
  this.setTagConfidenceLevel = (tagConfidenceLevel) => {
    this.tagConfidenceLevel = tagConfidenceLevel
  }

  this.isTagConfidenceSet = () => {
    return this.tagConfidenceLevel ||
      (searchResultService.getGlobalSearchContext() && searchResultService.getGlobalSearchContext().tagConfidenceLevel) ||
      (searchResultService.getCurrentSearchContext() && searchResultService.getCurrentSearchContext().tagConfidenceLevel)
  }

  this.getAssetGridTagTagConfidenceLevel = () => {
    if (searchResultService.getGlobalSearchContext() && searchResultService.getGlobalSearchContext().tagConfidenceLevel) {
      return searchResultService.getGlobalSearchContext().tagConfidenceLevel
    }
    if (searchResultService.getCurrentSearchContext() && searchResultService.getCurrentSearchContext().tagConfidenceLevel) {
      return searchResultService.getCurrentSearchContext().tagConfidenceLevel
    }
    return TAG_CONFIDENCE_LEVEL_HIGH
  }

  this.getTagConfidenceLevel = () => {
    if (this.tagConfidenceLevel) {
      return this.tagConfidenceLevel
    }
    return this.getAssetGridTagTagConfidenceLevel()
  }
})

