/* global angular */
angular.module('smartvid').service('vinnieLimitsNotificationService', function (smartvidApi, modal, FeatureSettingsModel,
                                                                                $window, $filter, $interpolate, $interval) {
  const VINNIE_LIMITS_POPUP_LOCAL_STORAGE_KEY_PREFIX = 'vinnieLimitsPopupShownForOrg:'
  const VINNIE_LIMITS_BANNER_UPDATE_FREQUENCY = 120000

  let service = {

    getVinnieLimitsInfo (currentProject) {
      return smartvidApi.getFeatureSettingsForOwnerTypeAndOwnerIdAndFeatureType('ORGANIZATION', currentProject.projectOrg.id, 'VINNIE_LIMITS')
    },

    showNotificationModalIfNeeded (vinnieLimitsInfo, currentProject) {
      let LOCAL_STORAGE_KEY = VINNIE_LIMITS_POPUP_LOCAL_STORAGE_KEY_PREFIX + currentProject.projectOrg.id
      let wasVinnieLimitsPopupShown = $window.localStorage.getItem(LOCAL_STORAGE_KEY) === 'true'

      if (vinnieLimitsInfo.isEnabled && currentProject.canUploadAsset && !wasVinnieLimitsPopupShown) {
        $window.localStorage.setItem(LOCAL_STORAGE_KEY, true)
        modal.open('vinnieLimitsNotification', {
          message: $interpolate($filter('i18next')('admin.vinnieLimits.popupText'))({
            numImages: vinnieLimitsInfo.customDataJson.maximumRecognitionsCount,
            org: currentProject.projectOrg.name
          })
        })
      }
    },

    needToShowVinnieLimitsFooter (vinnieLimitsInfo, currentProject) {
      return this.canProcessVinnieLimits(currentProject) && vinnieLimitsInfo.isEnabled
    },

    canProcessVinnieLimits (currentProject) {
      return currentProject && currentProject.canUploadAsset && currentProject.getProjectOrg()
    },

    updateFooterContent (scope, vinnieLimitsInfo) {
      scope.showGreenLimitsIcon = !vinnieLimitsInfo.isVinnieRecognitionsLimitReached()
      scope.showRedLimitsIcon = vinnieLimitsInfo.isVinnieRecognitionsLimitReached()
      if (scope.showGreenLimitsIcon) {
        scope.vinnieLimitsFooterText = $interpolate($filter('i18next')('admin.vinnieLimits.footerLimitNotReached'))({
          currCount: vinnieLimitsInfo.customDataJson.currentRecognitionsCount,
          maxCount: vinnieLimitsInfo.customDataJson.maximumRecognitionsCount,
          org: scope.currentProject.projectOrg.name
        })
      } else {
        scope.vinnieLimitsFooterText = $interpolate($filter('i18next')('admin.vinnieLimits.footerLimitReached'))({
          currCount: vinnieLimitsInfo.customDataJson.currentRecognitionsCount,
          maxCount: vinnieLimitsInfo.customDataJson.maximumRecognitionsCount,
          org: scope.currentProject.projectOrg.name
        })
      }
    },

    startUpdatingVinnieFooter (scope) {
      let stop = $interval(() => {
        if (!this.canProcessVinnieLimits(scope.currentProject)) {
          $interval.cancel(stop)
          return
        }

        this.getVinnieLimitsInfo(scope.currentProject).then((data) => {
          let vinnieLimitsInfo = new FeatureSettingsModel(data)
          this.updateFooterContent(scope, vinnieLimitsInfo)
        })
      }, VINNIE_LIMITS_BANNER_UPDATE_FREQUENCY)

      return stop
    },

    showVinnieTagsHelp () {
      //$window.open('', '_new')
    }
  }

  return service
})
