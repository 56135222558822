import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { Ng2GoogleChartsModule } from 'ng2-google-charts'
import { DxCircularGaugeModule } from 'devextreme-angular/ui/circular-gauge'

import { InsightsBarChartComponent } from 'modules/insights/dashboard/charts/components/insights-bar-chart/insights-bar-chart.component'
import { InsightsComboChartComponent } from 'modules/insights/dashboard/charts/components/insights-combo-chart/insights-combo-chart.component'
import { InsightsColumnChartComponent } from 'modules/insights/dashboard/charts/components/insights-column-chart/insights-column-chart.component'
import { InsightsPieChartComponent } from 'modules/insights/dashboard/charts/components/insights-pie-chart/insights-pie-chart.component'
import { InsightsLineChartComponent } from 'modules/insights/dashboard/charts/components/insights-line-chart/insights-line-chart.component'
import { InsightsCardComponent } from 'modules/insights/dashboard/cards/components/insights-card/insights-card.component'
import { SmartvidMaterialModule } from 'modules/material/material.module'
import { ProjectTotalsTableComponent } from 'modules/insights/dashboard/components/totals-table/assets/project-totals-table.component'
import { AssetsInsightsDashboardComponent } from 'modules/insights/dashboard/components/assets-insights-dashboard/project/assets-insights-dashboard.component'
import { InsightsAreaChartComponent } from 'modules/insights/dashboard/charts/components/insights-area-chart/insights-area-chart.component'
import { InsightsDashboardProjectAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-project-assets.service'
import { AssetsInsightsDashboardActivityComponent } from 'modules/insights/dashboard/components/assets-insights-dashboard/project/tabs/activity/assets-insights-dashboard-activity.component'
import { AssetsInsightsDashboardSafetyComponent } from 'modules/insights/dashboard/components/assets-insights-dashboard/project/tabs/safety/assets-insights-dashboard-safety.component'
import { InsightsSafetyGaugeChartComponent } from 'modules/insights/dashboard/charts/components/insights-safety-gauge-chart/insights-safety-gauge-chart.component'
import { TranslateModule } from '@ngx-translate/core'
import { DashboardGridCellDirective } from 'modules/insights/dashboard/directives/dashboard-grid-cell.directive'
import { DashboardTabDirective } from 'modules/insights/dashboard/directives/dashboard-tab.directive'
import { InsightsControlBarComponent } from 'modules/insights/components/insights-controlbar/insights-controlbar.component'
import { AppSharedModule } from 'shared/shared.module'
import { InsightsDataHelper } from './services/insights-data-helper.service'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import { ObservationsInsightsDashboardActivityComponent } from './dashboard/components/observations-insights-dashboard/project/tabs/activity/observations-insights-dashboard-activity.component'
import { ProjectObservationsTotalsTableComponent } from './dashboard/components/totals-table/observations/project-observations-totals-table.component'
import { AssetsInsightsDashboardImageHealthComponent } from 'modules/insights/dashboard/components/assets-insights-dashboard/project/tabs/image-health/assets-insights-dashboard-image-health.component'
import { ObservationsInsightsDashboardSafetyComponent } from './dashboard/components/observations-insights-dashboard/project/tabs/safety/observations-insights-dashboard-safety.component'
import { ObservationsInsightsDashboardWorkflowComponent } from './dashboard/components/observations-insights-dashboard/project/tabs/workflow/observations-insights-dashboard-workflow.component'
import { OrganizationAssetsInsightsDashboardComponent } from 'modules/insights/dashboard/components/assets-insights-dashboard/organization/organization-assets-insights-dashboard.component'
import { InsightsDashboardOrganizationAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-organization-assets.service'
import { OrganizationAssetsInsightsDashboardActivityComponent } from 'modules/insights/dashboard/components/assets-insights-dashboard/organization/tabs/activity/organization-assets-insights-dashboard-activity.component'
import { OrganizationAssetsActivityTableComponent } from 'modules/insights/dashboard/components/totals-table/organization-assets/organization-assets-activity-table.component'
import { OrganizationAssetsInsightsDashboardSafetyComponent } from 'modules/insights/dashboard/components/assets-insights-dashboard/organization/tabs/safety/organization-assets-insights-dashboard-safety.component'
import { OrganizationAssetsSafetyTableComponent } from 'modules/insights/dashboard/components/totals-table/organization-assets/organization-assets-safety-table.component'
import { OrganizationAssetsInsightsDashboardImageHealthComponent } from 'modules/insights/dashboard/components/assets-insights-dashboard/organization/tabs/image-health/organization-assets-insights-dashboard-image-health.component'
import { InsightsDashboardProjectGroupAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-project-group-assets.service'
import { ProjectGroupAssetsInsightsDashboardComponent } from 'modules/insights/dashboard/components/assets-insights-dashboard/project-group/project-group-assets-insights-dashboard.component'
import { ProjectGroupAssetsInsightsDashboardActivityComponent } from 'modules/insights/dashboard/components/assets-insights-dashboard/project-group/tabs/activity/project-group-assets-insights-dashboard-activity.component'
import { ProjectGroupAssetsActivityTableComponent } from 'modules/insights/dashboard/components/totals-table/project-group-assets/project-group-assets-activity-table.component'
import { ObservationsOrganizationInsightsDashboardComponent } from './dashboard/components/observations-insights-dashboard/organization/observations-organization-insights-dashboard.component'
import { ObservationsOrganizationInsightsDashboardActivityComponent } from './dashboard/components/observations-insights-dashboard/organization/tabs/activity/observations-organization-insights-dashboard-activity.component'
import { ObservationsInsightsDashboardComponent } from 'modules/insights/dashboard/components/observations-insights-dashboard/project/observations-insights-dashboard.component'
import { OrganizationObservationsTotalsTableComponent } from './dashboard/components/totals-table/observations/organization-observations-totals-table.component'
import { ProjectGroupAssetsInsightsDashboardSafetyComponent } from 'modules/insights/dashboard/components/assets-insights-dashboard/project-group/tabs/safety/project-group-assets-insights-dashboard-safety.component'
import { ProjectGroupAssetsSafetyTableComponent } from 'modules/insights/dashboard/components/totals-table/project-group-assets/project-group-assets-safety-table.component'
import { ProjectGroupAssetsInsightsDashboardImageHealthComponent } from 'modules/insights/dashboard/components/assets-insights-dashboard/project-group/tabs/image-health/project-group-assets-insights-dashboard-image-health.component'
import { ReportsDashboardComponent } from 'modules/insights/dashboard/components/reports-dashboard/reports-dashboard.component'
import { ReportsTableComponent } from 'modules/insights/dashboard/components/reports-table/reports-table.component'
import { ReportControlBarComponent } from 'modules/insights/components/report-controlbar/report-controlbar.component'
import { ReportsDashboardService } from 'modules/insights/dashboard/services/reports-dashboard-service'
import { ObservationsOrganizationInsightsDashboardSafetyComponent } from './dashboard/components/observations-insights-dashboard/organization/tabs/safety/observations-organization-insights-dashboard-safety.component'
import { OrganizationObservationsSafetyTotalsTableComponent } from './dashboard/components/totals-table/observations/organization-observations-safety-totals-table.component'
import { ObservationsOrganizationInsightsDashboardWorkflowComponent } from './dashboard/components/observations-insights-dashboard/organization/tabs/workflow/observations-organization-insights-dashboard-workflow.component'
import { OrganizationObservationsWorkflowTotalsTableComponent } from './dashboard/components/totals-table/observations/organization-observations-workflow-totals-table.component'
import { InsightsDashboardOrganizationObservationsService } from './dashboard/services/insights-dashboard-organization-observations.service'
import { InsightsDashboardProjectGroupObservationsService } from './dashboard/services/insights-dashboard-project-group-observations.service'
import { ObservationsProjectGroupInsightsDashboardComponent } from './dashboard/components/observations-insights-dashboard/projectgroup/observations-projectgroup-insights-dashboard.component'
import { ObservationsProjectGroupInsightsDashboardActivityComponent } from './dashboard/components/observations-insights-dashboard/projectgroup/tabs/activity/observations-project-group-insights-dashboard-activity.component'
import { ObservationsProjectGroupInsightsDashboardSafetyComponent } from './dashboard/components/observations-insights-dashboard/projectgroup/tabs/safety/observations-project-group-insights-dashboard-safety.component'
import { ObservationsProjectGroupInsightsDashboardWorkflowComponent } from './dashboard/components/observations-insights-dashboard/projectgroup/tabs/workflow/observations-project-group-insights-dashboard-workflow.component'

export function insightsDataHelperFactory(i: any): InsightsDataHelper {
  return i.get('insightsDataHelper')
}

export const insightsDataHelperProvider = {
  provide: InsightsDataHelper,
  useFactory: insightsDataHelperFactory,
  deps: ['$injector'],
}

@NgModule({
  imports: [
    Ng2GoogleChartsModule,
    CommonModule,
    FormsModule,
    SmartvidMaterialModule,
    DxCircularGaugeModule,
    TranslateModule,
    AppSharedModule,
  ],
  declarations: [
    InsightsBarChartComponent,
    InsightsComboChartComponent,
    InsightsColumnChartComponent,
    InsightsPieChartComponent,
    InsightsLineChartComponent,
    InsightsCardComponent,
    InsightsAreaChartComponent,
    InsightsControlBarComponent,
    ProjectTotalsTableComponent,
    AssetsInsightsDashboardComponent,
    AssetsInsightsDashboardActivityComponent,
    AssetsInsightsDashboardSafetyComponent,
    ProjectGroupAssetsInsightsDashboardComponent,
    ProjectGroupAssetsInsightsDashboardActivityComponent,
    ProjectGroupAssetsActivityTableComponent,
    ProjectGroupAssetsInsightsDashboardSafetyComponent,
    ProjectGroupAssetsSafetyTableComponent,
    ProjectGroupAssetsInsightsDashboardImageHealthComponent,
    OrganizationAssetsInsightsDashboardComponent,
    OrganizationAssetsInsightsDashboardActivityComponent,
    OrganizationAssetsActivityTableComponent,
    OrganizationAssetsInsightsDashboardSafetyComponent,
    OrganizationAssetsSafetyTableComponent,
    OrganizationAssetsInsightsDashboardImageHealthComponent,
    ObservationsInsightsDashboardComponent,
    ProjectObservationsTotalsTableComponent,
    OrganizationObservationsTotalsTableComponent,
    OrganizationObservationsSafetyTotalsTableComponent,
    OrganizationObservationsWorkflowTotalsTableComponent,
    ObservationsInsightsDashboardActivityComponent,
    ObservationsInsightsDashboardSafetyComponent,
    ObservationsInsightsDashboardWorkflowComponent,
    ObservationsOrganizationInsightsDashboardComponent,
    ObservationsOrganizationInsightsDashboardActivityComponent,
    ObservationsOrganizationInsightsDashboardSafetyComponent,
    ObservationsOrganizationInsightsDashboardWorkflowComponent,
    ObservationsProjectGroupInsightsDashboardComponent,
    ObservationsProjectGroupInsightsDashboardActivityComponent,
    ObservationsProjectGroupInsightsDashboardSafetyComponent,
    ObservationsProjectGroupInsightsDashboardWorkflowComponent,
    AssetsInsightsDashboardImageHealthComponent,
    InsightsSafetyGaugeChartComponent,
    DashboardGridCellDirective,
    DashboardTabDirective,
    ReportsDashboardComponent,
    ReportsTableComponent,
    ReportControlBarComponent,
  ],
  exports: [
    InsightsBarChartComponent,
    InsightsComboChartComponent,
    InsightsColumnChartComponent,
    InsightsPieChartComponent,
    InsightsLineChartComponent,
    InsightsCardComponent,
    InsightsAreaChartComponent,
    InsightsControlBarComponent,
    ProjectTotalsTableComponent,
    AssetsInsightsDashboardComponent,
    AssetsInsightsDashboardActivityComponent,
    AssetsInsightsDashboardSafetyComponent,
    ProjectGroupAssetsInsightsDashboardComponent,
    ProjectGroupAssetsInsightsDashboardActivityComponent,
    ProjectGroupAssetsActivityTableComponent,
    ProjectGroupAssetsInsightsDashboardSafetyComponent,
    ProjectGroupAssetsSafetyTableComponent,
    ProjectGroupAssetsInsightsDashboardImageHealthComponent,
    OrganizationAssetsInsightsDashboardComponent,
    OrganizationAssetsInsightsDashboardActivityComponent,
    OrganizationAssetsActivityTableComponent,
    OrganizationAssetsInsightsDashboardSafetyComponent,
    OrganizationAssetsSafetyTableComponent,
    OrganizationAssetsInsightsDashboardImageHealthComponent,
    ObservationsOrganizationInsightsDashboardComponent,
    ObservationsProjectGroupInsightsDashboardComponent,
    ObservationsInsightsDashboardComponent,
    AssetsInsightsDashboardImageHealthComponent,
    InsightsSafetyGaugeChartComponent,
    DashboardGridCellDirective,
    DashboardTabDirective,
    ReportsDashboardComponent,
    ReportsTableComponent,
    ReportControlBarComponent,
  ],
  entryComponents: [AssetsInsightsDashboardComponent, InsightsControlBarComponent],
  providers: [
    InsightsDashboardProjectAssetsService,
    InsightsDashboardConfigurationService,
    InsightsDashboardOrganizationAssetsService,
    InsightsDashboardProjectGroupAssetsService,
    InsightsDashboardOrganizationObservationsService,
    InsightsDashboardProjectGroupObservationsService,
    insightsDataHelperProvider,
    ReportsDashboardService,
    { provide: 'googleChartsVersion', useValue: '46' },
    //{provide: 'mapsApiKey', useValue: '...'}
  ],
})
export class InsightsDashboardModule {}
