/* global angular */

angular.module('smartvid').factory('MultiAssetSelection', function () {
  class MultiAssetSelection {
    constructor () {
      this.projectId = undefined
      this.assetViewType = undefined
      this.shareLinkId = undefined

      this.searchRequest = undefined

      this.isAllAssets = undefined
      this.selectedAssetIds = undefined
      this.unselectedAssetIds = undefined
    }
  }

  return MultiAssetSelection
})
