<div class="controlbar"
     [ngClass]="{ 'left-nav-open': NavController.leftNavOpen, 'right-nav-open': NavController.rightNavOpen }">

  <mat-toolbar>
    <mat-toolbar-row>
      <sv-left-nav-button></sv-left-nav-button>

      <ul class="icon-list">
        <li class="icon-container">
          <div class="svg-container">
            <button mat-icon-button [matMenuTriggerFor]="menu" #t="matMenuTrigger">
              <mat-icon svgIcon="icon-settings-dropdown"></mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <ng-template matMenuContent let-name="name">
                <button mat-menu-item (click)="enableIntegrations()">
                  <span>{{ 'components.adminOrganizationIntegrations.menu.enable' | translate }}</span>
                </button>
                  <button mat-menu-item (click)="disableIntegrations()">
                  <span>{{ 'components.adminOrganizationIntegrations.menu.disable' | translate }}</span>
                </button>
                <button mat-menu-item (click)="disconnectIntegrations()">
                  <span>{{ 'components.adminOrganizationIntegrations.menu.disconnect' | translate }}</span>
                </button>
              </ng-template>
            </mat-menu>

          </div>
        </li>
      </ul>

    </mat-toolbar-row>
  </mat-toolbar>

</div>
