/* global angular */

angular.module('smartvid').directive('observationsGridControlbar', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'observations/controlbar.html',
    link: function (scope) {
    }
  }
})
