<div class="content">
    <h2 mat-dialog-title>
        {{dialogTitle}}
        <button *ngIf="!dialogData.hideClose" mat-icon-button appearance="fill" mat-dialog-close class="close-button">
            <mat-icon>clear</mat-icon>
        </button>
    </h2>
    <mat-dialog-content>{{dialogContent}}</mat-dialog-content>
    <mat-dialog-actions>
        <button *ngIf="dialogData.discard" mat-stroked-button mat-dialog-close cdkFocusInitial>{{discardText}}</button>
        <button mat-flat-button color="primary" [mat-dialog-close]="true">{{confirmText}}</button>
    </mat-dialog-actions>
</div>
