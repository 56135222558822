/* global angular, $ */

angular.module('smartvid').directive('svAllowFrameCapture', function ($rootScope, $stateParams, $timeout, smartvidApi) {
  return {
    restrict: 'A',
    link (scope, element) {
      let assetId = $stateParams.assetId
      scope.assetMediaUrl = undefined

      scope.$on('sv-download-asset-frame', () => {
        $('.asset-frame-capture-link').remove()
        // TODO: do we really need to check scope.$parent.player?
        let currentTime = scope.player ? scope.player.currentTime() : scope.$parent.player ? scope.$parent.player.currentTime() : 0

        let imageClipRect = scope.getDziImageClipRect ? scope.getDziImageClipRect() : undefined
        let tag = scope.assetTags ? scope.assetTags.getSelectedTag() : undefined

        smartvidApi.getAssetFrameUrl(assetId, (tag ? tag.id : undefined), currentTime, imageClipRect).then((data) => {
          scope.assetMediaUrl = data.assetMediaUrl
          let a = $(`<a href="${scope.assetMediaUrl}" class="asset-frame-capture-link" download></a>`)
          $('body').append(a)
          //
          // make it wait since there is spotty behavior downloading the image from Wistia
          //
          $timeout(() => {
            $rootScope.$broadcast('sv-download-asset-frame-complete')
            $(a)[0].click()
          }, 250)
        })
      })

      var canvas
      /**
       * Pure DOM manipulation for right click to copy video frame as image
       */
      $timeout(() => {
        angular.element($('#sv-video-player')[0]).on('contextmenu', ($event) => {
          //
          // Only allow right click frame capture when video is paused
          //
          if (scope.$parent.player.paused()) {
            //
            // 1) create canvas overlay with same media player coordinates
            //
            let video = element[0]
            canvas = $('<canvas>')[0]
            $(canvas).attr('id', 'copy-image')
            $(canvas).attr('download', 'myimage.jpg')
            $(canvas).attr('name', 'myimage1.jpg')
            $(canvas).attr('alt', 'myimage2.jpg')
            $(canvas).attr('title', 'myimage3.jpg')

            $(canvas).on('mouseout, click, mouseover', () => {
              $('#copy-image').remove()
              scope.hasCopyImage = true
            })

            var videoRect = video.getBoundingClientRect()
            $(canvas).css({
              'position': 'absolute',
              'top': videoRect.top,
              'left': videoRect.left,
              'width': videoRect.width,
              'height': videoRect.height,
              'outline': '1px dashed #999'
            })
            canvas.width = videoRect.width
            canvas.height = videoRect.height

            //
            // 2) apply capture to canvas
            //
            var ctx = canvas.getContext('2d')
            ctx.drawImage(video, 0, 0, videoRect.width, videoRect.height)
            scope.hasCopyImage = true
            document.body.appendChild(canvas)

            //
            // be sure right click event never bubbles beyond here
            //
            $event.stopPropagation()
            return true
          }
        })
      })

      //
      // always remove any existing copy-image
      //
      let listener = () => {
        if (scope.hasCopyImage === true) {
          $('#copy-image').remove()
          scope.hasCopyImage = false
        }
      }
      $('body').on('mouseover', listener)
      scope.$on('$destroy', function () {
        $('body').off('mouseover', listener)
        if (canvas) {
          $(canvas).off()
        }
      })
    }
  }
})
