<div class="main-container smartvid-dark-theme">
  <mat-spinner class="table-spinner" diameter="64" *ngIf="!projectsWithIntegrationSettings"></mat-spinner>

  <sv-admin-organization-integrations-search
          *ngIf="projectsWithIntegrationSettings"
          (userInputChange)="onUserFilterChange($event)"
          (projectInputChange)="onProjectFilterChange($event)"
          [selectedIntegrationCount]="getSelectedIntegrationCount()"
          [visibleIntegrationCount]="getVisibleIntegrationCount()"
          [projectsWithIntegrationSettings]="projectsWithIntegrationSettings">
  </sv-admin-organization-integrations-search>

  <div class="integrations-table-container smartvid-dark-theme">
    <table mat-table [dataSource]="dataSource" class="integrations-table" matSort>

      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name">
          <span>
            {{ 'components.adminOrganizationIntegrations.tableColumns.projects' | translate }}
          </span>
        </th>
        <td mat-cell *matCellDef="let row">
            <span class="mat-body-2 title-text">{{ row.project.name }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="connectedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="connectedBy">
          <span>
            {{ 'components.adminOrganizationIntegrations.tableColumns.connectedBy' | translate }}
          </span>
        </th>
        <td mat-cell *matCellDef="let row">
            <span class="mat-body-2 title-text">{{ getUserName(row) }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="integration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="integration">
          <span>
            {{ 'components.adminOrganizationIntegrations.tableColumns.integration' | translate }}
          </span>
        </th>
        <td mat-cell *matCellDef="let row">
          <span class="mat-body-2 title-text">{{ 'components.adminOrganizationIntegrations.' + row.integrationSettings.integrationSettings.integrationType | translate }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="status">
          <span>
            {{ 'components.adminOrganizationIntegrations.tableColumns.status' | translate }}
          </span>
        </th>
        <td mat-cell *matCellDef="let row" [ngClass]="getStatusColorStyle(row)">
          <span class="mat-body-2 title-text">{{ 'components.adminOrganizationIntegrations.enabled.' + row.integrationSettings.integrationSettings.isEnabled | translate }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastSync">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="lastSync">
          <span>
            {{ 'components.adminOrganizationIntegrations.tableColumns.lastSyncDate' | translate }}
          </span>
        </th>
        <td mat-cell *matCellDef="let row">
          <span class="mat-body-2 title-text">{{ getLastSyncDate(row.integrationSettings.integrationSettings.lastSyncDate) }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="menu">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let row">
          <div>
            <button mat-icon-button [matMenuTriggerFor]="menu" #t="matMenuTrigger">
              <mat-icon
                      *ngIf="(t && t.menuOpen) || mouseOverMenuIntegrationSettingsId === row.integrationSettings.integrationSettings.id">
                more_vert
              </mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <ng-template matMenuContent let-name="name">
                <button mat-menu-item (click)="viewIntegration(row)">
                  <span>{{ 'components.adminOrganizationIntegrations.menu.view' | translate }}</span>
                </button>
                <button mat-menu-item (click)="disableIntegration(row)" *ngIf="isEnabled(row)">
                  <span>{{ 'components.adminOrganizationIntegrations.menu.disable' | translate }}</span>
                </button>
                <button mat-menu-item (click)="enableIntegration(row)" *ngIf="!isEnabled(row) && row.integrationSettings.canEnable">
                  <span>{{ 'components.adminOrganizationIntegrations.menu.enable' | translate }}</span>
                </button>
                <button mat-menu-item (click)="disconnectIntegration(row)">
                  <span>{{ 'components.adminOrganizationIntegrations.menu.disconnect' | translate }}</span>
                </button>
              </ng-template>
            </mat-menu>

          </div>
        </td>
      </ng-container>

      <tr mat-header-row
          class="integration-table-header"
          *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row
          class="integration-row"
          (mouseover)="handleMouseOver(row)"
          (mouseleave)="handleMouseLeave(row)"
          *matRowDef="let row; columns: displayedColumns"></tr>

    </table>
  </div>

  <div [hidden]="!projectsWithIntegrationSettings" class="table-paginator">
    <mat-paginator [pageSizeOptions]="[10]" showFirstLastButtons hidePageSize></mat-paginator>
  </div>

</div>
