import { Component, OnInit } from '@angular/core'
import { ProjectTotalCounts } from 'modules/insights/dashboard/models/insights-dashboard-project-assets.model'
import { InsightsDashboardProjectAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-project-assets.service'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import { DashboardTabId, DashboardType } from 'modules/insights/dashboard/models/insights-dashboard.model'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import { InsightsDashboardComponentBaseDirective } from 'modules/insights/dashboard/components/insights-dashboard-component-base'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'sv-assets-insights-dashboard',
  templateUrl: 'assets-insights-dashboard.component.html',
  styleUrls: ['assets-insights-dashboard.component.scss'],
})
export class AssetsInsightsDashboardComponent extends InsightsDashboardComponentBaseDirective implements OnInit {
  projectTotals: ProjectTotalCounts
  projectScoreValueClassName: string
  uploadedFilesRatioPercentChangeClassName: string
  uploadersRatioPercentChangeClassName: string
  tagsTotalRatioPercentChangeClassName: string
  ppeCompliancePercentChangeClassName: string
  workAtHeightPercentChangeClassName: string
  slipAndTripPercentChangeClassName: string

  isConfigurationLoaded = false

  constructor(
    protected insightsDataHelper: InsightsDataHelper,
    protected insightsDashboardConfigurationService: InsightsDashboardConfigurationService,
    protected translate: TranslateService,
    private insightsDashboardProjectService: InsightsDashboardProjectAssetsService
  ) {
    super(insightsDataHelper, insightsDashboardConfigurationService, translate, DashboardType.PROJECT_ASSETS_DASHBOARD)
  }

  ngOnInit() {
    this.loadDashboardConfiguration()
  }

  getTabTitle(tabId: DashboardTabId) {
    let tabConfig = this.insightsDashboardConfigurationService.getTabConfiguration(tabId)
    return tabConfig && tabConfig.displayLabel ? tabConfig.displayLabel : ''
  }

  protected refresh() {
    this.loadTextCardsData()
  }

  private loadTextCardsData() {
    this.projectTotals = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadProjectTotalsData(
        this.currentInsightsDashboardParameters,
        (data: ProjectTotalCounts) => {
          this.projectTotals = data
          this.projectScoreValueClassName = this.pickClassNameFor(this.projectTotals.projectScore, 'bg-na-value', [
            [35, 'bg-critical-value'],
            [45, 'bg-warning-3-value'],
            [55, 'bg-warning-2-value'],
            [65, 'bg-warning-1-value'],
            [100, 'bg-normal-value'],
          ])
          this.uploadedFilesRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.projectTotals.uploadedFilesRatioPercentChange
          )
          this.uploadersRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.projectTotals.uploadersRatioPercentChange
          )
          this.tagsTotalRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.projectTotals.tagsTotalRatioPercentChange
          )
          this.ppeCompliancePercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.projectTotals.ppeCompliancePercentChange
          )
          this.workAtHeightPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.projectTotals.workAtHeightPercentChange
          )
          this.slipAndTripPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.projectTotals.slipAndTripPercentChange
          )
        }
      )
    )
  }

  private loadDashboardConfiguration() {
    this.apiCallsSubscriptions.push(
      this.insightsDashboardConfigurationService.loadDashboardConfiguration(
        {
          dashboardType: DashboardType.PROJECT_ASSETS_DASHBOARD,
          organizationId: this.currentInsightsDashboardParameters.organizationId,
          projectGroupId: this.currentInsightsDashboardParameters.projectGroupId,
          projectId: this.currentInsightsDashboardParameters.projectId,
          userId: this.currentInsightsDashboardParameters.userId,
        },
        () => {
          this.isConfigurationLoaded = true
          this.refresh()
        }
      )
    )
  }
}
