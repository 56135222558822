/* global angular, _, $, analytics */
import { ApplyObservationSearch } from 'modules/state'
import { ObservationSearchCriteria } from 'modules/observations/models/observation.model'

angular.module('smartvid').controller('DashboardCtrl', function (
  $filter, $rootScope, $document, $scope, $state, $stateParams, $interval, $timeout, $window, smartvidApi, utils, currentUser,
  ProjectsCollection, TagDefCollection, TagInstanceCollection, AssetsCollection, OrganizationsCollection, modal,
  UsersCollection, ProjectMembersCollection, InvitedProjectUserModel, ProjectModel, $q, $log, config, navStateService,
  NavController, assetGridHelper, TRANSITION_SPEED, searchResultService, dashboardDataHelper, contentSharingContext,
  AssetSearchContext, SearchEntityModel, AssetViewType, batchJobService, secretSlideshowHelper,
  searchService, ngxsStoreService, moveAssetsService,
  MAX_MULTIPLE_ASSETS_DOWNLOAD, collectionUtils, $transitions
) {
  let statusInterval
  $scope.logout = currentUser.destroy
  $scope.helper = assetGridHelper
  $scope.searchByValues = []
  $scope.assetSearchContext = undefined
  $scope.NavController = NavController
  $scope.currentUser = currentUser
  $scope.isGrouped = false
  $scope.adminOrganizations = new OrganizationsCollection() // List of organizations that current user can manage
  $scope.projectMembers = new ProjectMembersCollection()
  $scope.showOrgLogo = false
  $scope.orgLogo
  $scope.orgName
  $scope.assetName
  $scope.searchResultService = searchResultService

  dashboardDataHelper.collections = {
    media: [],
    users: []
  }

  $scope.isWorkspaceLoading = () => {
    return $rootScope.workspaceLoadingQueueLength && $rootScope.workspaceLoadingQueueLength > 0
  }

  $scope.getSearchFieldDefaultText = getSearchFieldDefaultText
  $scope.getAssetViewOptions = getAssetViewOptions
  $scope.searchAssets = searchAssets
  $scope.clearSearch = clearSearch
  $scope.clearSearchState = clearSearchState
  $scope.showUserOptions = showUserOptions
  $scope.isSearchFieldAvailable = isSearchFieldAvailable
  $scope.isObservationSearchAvailable = isObservationSearchAvailable
  $scope.animPlacardStyle = animPlacardStyle
  $scope.handleAvatarClick = handleAvatarClick
  $scope.searchObservations = searchObservations
  $scope.clearObservationSearch = clearObservationSearch

  /**
   * For screens with placard animations be sure to trigger the digest to update the placard coordinates.
   */
  angular.element($window).on('resize.smartvid.dashboard', onWindowResize)

  $scope.$on('sv-secret-slideshow-activate', onStartSlideshow)
  $scope.$on('sv-secret-slideshow-deactivate', onEndSlideshow)
  $scope.$on('sv-secret-slideshow-transition', onSecretSlideshowTransition)
  $scope.$on('sv-project-members-uninvited-from-project', onProjectMembersUninvitedFromProject)
  $scope.$on('sv-project-members-invited-to-project', onProjectMembersInvitedToProject)
  $scope.$on('sv-organization-updated', onOrganizationUpdated)
  $scope.$on('sv-project-updated', onProjectUpdated)
  $scope.$on('sv-set-unverified-tags-count', onSetUnverifiedTagsCount)
  $scope.$on('sv-project-copy-done', onProjectCopyDone)
  $scope.$on('sv-upload-progress', onUploadProgress)
  $transitions.onSuccess({}, trans => {
    handleState(trans.params(), trans.to())
  })
  //RK
  //onSuccess is not called on refresh. Recommended to move to app.run block later.
  handleState($state.params, $state.current)
  $scope.$on('assetGrid:sortBy', onAssetGridSortBy)
  $scope.$on('assetGrid:groupBy', onAssetGridGroupBy)
  $scope.$on('assetGrid:deleteSelected', onAssetGridDeleteSelected)
  $scope.$on('assetGrid:exportAssetsWithTagCounts', onAssetGridExportAssetsWithTagCounts)
  $scope.$on('assetGrid:deleteAsset', onAssetGridDeleteAsset)
  $scope.$on('assetGrid:forceDeleteAsset', onAssetGridForceDeleteAsset)
  $scope.$on('assetGrid:undeleteAsset', onAssetGridUndeleteAsset)
  $scope.$on('assetGrid:shareSelectedAssetSelected', onAssetGridShareSelectedAssetSelected)
  $scope.$on('assetGrid:reRunSmartTaggingAsset', onAssetGridRerunSmartTaggingAsset)
  $scope.$on('assetGrid:moveAsset', onAssetGridMoveAssets)
  $scope.$on('assetGrid:downloadSelectedSnapshots', onAssetGridDownloadSelectedSnapshots)
  $scope.$on('projectGrid:deleteSelected', onProjectGridDeleteSelected)
  $scope.$on('adminOrganizationsGrid:deleteSelected', onAdminOrganizationsGridDeleteSelected)
  $scope.$on('$destroy', onDestroy)
  let deregisterCurrentUserListener = $rootScope.$on('sv-current-user-updated', loadOrganizations)
  let deregisterDeleteNodeListener = $rootScope.$on('deleteNode', onDeleteNode)

  $scope.$watch('currentUser.token', onCurrentUserTokenChange)

  setLeftNav(leftNavInitialState())
  setRightNav(navStateService.getRightNavState($state.current.name))
  loadOrganizations()

  /**
   * Awful iOS mobile fix since we never know when the top/bottom or bottom navigation bars
   * are showing with CSS.
   */
  function mobleAdjustLeftNav () {
    if ($scope.isMobile) {
      $('.sidepanel').css('height', $('body').innerHeight())
      $('.sidepanel .items-container').css('height', $('body').innerHeight() - 175)
    }
  }

  function onAssetGridDownloadSelectedSnapshots ($event, isFile, assets) {
    collectionUtils.checkSelectedMoreThan(assets, MAX_MULTIPLE_ASSETS_DOWNLOAD,
      () => {
        startAssetGridDownload(isFile, assets.projectId, assets.getSelected())
      },
      () => {
        modal.open('downloadTooManySelectedConfirm', {
          confirm () {
            startAssetGridDownload(isFile, assets.projectId, assets.getSelected())
          }
        })
      }
    )

    return false
  }

  function startAssetGridDownload (isFile, projectId, assets) {
    let snapshotType = (isFile) ? 'ASSET' : 'ASSET_SNAPSHOT'
    if (snapshotType !== 'ASSET' && searchResultService.isInSearchContext()) {
      snapshotType = 'TAG_INSTANCE_SNAPSHOT'
    }

    if (assets.length > MAX_MULTIPLE_ASSETS_DOWNLOAD) {
      assets.length = MAX_MULTIPLE_ASSETS_DOWNLOAD
    }

    if (assets.length === 0) {
      return
    }

    let downloads = []
    dashboardDataHelper.resetDownloads()
    let isCheckingStatus = false
    let totalProgress = 0
    let completed = 0

    /**
     * Check the snapshot download statuses
     */
    let checkStatus = () => {
      //
      // Create an interval for polling status
      //
      statusInterval = $interval(() => {
        //
        // skip until previous calls return
        //
        if (isCheckingStatus) {
          return
        }

        isCheckingStatus = true
        totalProgress = 0

        if (completed !== downloads.length) {
          let idx = 0
          _.each(downloads, (download) => {
            //
            // Check server for snapshot status
            //
            smartvidApi.getDownloadPackageStatus(projectId, download.packageId).then((data) => {
              idx++

              if (data.status === 'FAILED') {
                $interval.cancel(statusInterval)
                isCheckingStatus = false
                $rootScope.$broadcast('sv-asset-snapshot-failed', snapshotType === 'ASSET')
                utils.notify($filter('i18next')('assets.downloadsFailed'), '', $filter('i18next')('common.okay'), true, () => {
                })
              } else {
                //
                // accumulate totalProgress
                //
                totalProgress = totalProgress + parseFloat(data.creationProgress, 10)
                if (data.status === 'FINISHED' && !download.complete) {
                  completed++
                  let rootUrl = config.env.development.apiRootUrl
                  let url = `${rootUrl}/api/project/${projectId}/download/package/${data.packageId}/type/ASSET_AND_SNAPSHOT/token/${utils.utf8ToB64(currentUser.token)}`
                  dashboardDataHelper.addDownloads(url)
                  download.complete = true
                }

                //
                // recalculate total percentage
                //
                if (idx === downloads.length) {
                  totalProgress = parseFloat((totalProgress / downloads.length) * 100, 10).toFixed(0)

                  $rootScope.$broadcast('sv-asset-snapshot-total-progress', totalProgress)
                  isCheckingStatus = false
                }
              }
            })
          })
        } else {
          $rootScope.$broadcast('sv-asset-snapshot-complete', snapshotType === 'ASSET')
          let msg = (snapshotType === 'ASSET') ? 'assets.downloadsFilesReady' : 'assets.downloadsReady'
          utils.notify($filter('i18next')(msg), '', $filter('i18next')('assets.download'), true, () => {
            dashboardDataHelper.getDownloads().forEach((url) => {
              const a = document.createElement('a')
              a.href = url
              a.download = ''
              a.click()
            })
          })
          $interval.cancel(statusInterval)
        }
      }, 3000)
    }

    //
    // Initiate/Prepare download for each selection
    //
    let packageItemInfo = []
    let assetName

    $q.all(_.map(assets, (asset) => {
      return asset.getTags()
    })).then(() => {
      _.each(assets, (asset) => {
        let packageId = asset.id

        //
        // packageId: find tagInstanceId for asset
        //
        if (snapshotType === 'TAG_INSTANCE_SNAPSHOT') {
          if (!asset.childToParentTagMap || asset.isImage()) {
            packageItemInfo.push({
              packageItemId: packageId,
              packageItemType: 'ASSET_SNAPSHOT'
            })
          } else {
            let searchContext = searchResultService.getCurrentSearchContext()
            let tagDefIds = _.map(searchContext.searchTags, tag => {
              for (let key of Object.keys(asset.childToParentTagMap)) {
                let parent = asset.childToParentTagMap[key]
                if (parent === tag.id) {
                  return key
                }
              }
              return null
            })

            _.each(tagDefIds, (tagDefId) => {
              _.each(asset.tags.models, (tag) => {
                if (tag.tagDefinitionId === tagDefId) {
                  packageItemInfo.push({
                    packageItemId: tag.id,
                    packageItemType: snapshotType
                  })
                }
              })
            })
          }
        } else {
          packageItemInfo.push({
            packageItemId: packageId,
            packageItemType: snapshotType
          })
        }

        assetName = assetName || asset.name
      })
      if (assetName) {
        smartvidApi.createDownloadPackage(assetName, projectId, packageItemInfo).then((data) => {
          // Track the downloads
          downloads.push({packageId: data.packageId})

          checkStatus()
        })
      }
    })

    $rootScope.$broadcast('sv-asset-snapshot-before-start', snapshotType === 'ASSET')
  }

  function onProjectGridDeleteSelected () {
    let projects = $scope.projects.getSelected()
    let projectIds = _.pluck(projects, 'id')

    smartvidApi.deleteProjects(projectIds).then(() => {
      _.each(projects, (project) => {
        $scope.projects.removeById(project.id)
      })
      $rootScope.$broadcast('sv-project-deleted', {projectIds: projectIds})
    })
  }

  function onAssetGridRerunSmartTaggingAsset ($event, asset) {
    asset.tags = [] // reset tags collection
    analytics.track('Run ASR', {
      category: 'Asset Action'
    })
    analytics.track('Run Image Recognition', {
      category: 'Asset Action'
    })
    smartvidApi.reRunSmartTaggingAsset(asset.id).then((updatedAsset) => {
      asset.update(updatedAsset)
    })
  }

  function onAssetGridMoveAssets ($event, payload) {

    $rootScope.moveAssetsInProgress = true
    $rootScope.$broadcast('sv-move-asset-before-start');

    let promise = smartvidApi.moveAssets(payload)
    promise.then(
      data => {
        moveAssetsService.startPollingForMoveAssetsCompletion(data)
      },
      () => {
        Notification.error($filter('i18next')('directives.mysettings.failedMoveAsset'))
      }
    )

  }

  function onAssetGridShareSelectedAssetSelected () {
    let selected = $scope.assets.getSelected()
    if (selected.length > 0) {
      $scope.modal.open('shareAsset', {
        assets: selected,
        collection: $scope.assets,
        currentProject: $scope.currentProject,
        allSelected: $scope.assets.allSelected
      })
    }
  }

  function onAdminOrganizationsGridDeleteSelected () {
    let adminOrganizations = $scope.adminOrganizations.getSelected()

    _.each(adminOrganizations, (organization) => {
      smartvidApi.deleteOrganization(organization.id).then(() => {
        currentUser.deleteOrganization(organization)
        $scope.adminOrganizations.removeById(organization.id)
      })
    })
  }

  function onAssetGridUndeleteAsset ($event, asset) {
    smartvidApi.undeleteAsset(asset.id).then(() => {
      $scope.assets.removeById(asset.id)
      $rootScope.$broadcast('refreshAssetGrid')
    })
    $scope.assets.nextPage()
  }

  function onAssetGridForceDeleteAsset ($event, asset) {
    smartvidApi.forceDeleteAsset(asset.id).then(() => {
      $scope.assets.removeById(asset.id)
      $rootScope.$broadcast('refreshAssetGrid')
    })
    $scope.assets.nextPage()
  }

  function onAssetGridDeleteAsset ($event, asset) {
    smartvidApi.deleteAsset(asset.id).then(() => {
      $scope.assets.removeById(asset.id)
      $rootScope.$broadcast('refreshAssetGrid')
    })
    $scope.assets.nextPage()
  }

  function onAssetGridExportAssetsWithTagCounts () {
    $scope.modal.open('exportAssets')
  }

  function onAssetGridDeleteSelected () {
    let multiAssetSelection = $scope.assets.getMultiAssetSelection()
    let goToStateName = 'dashboard.projects.projectId.files'
    let goToStateParams = {
      projectId: $scope.currentProject.id,
      isDemoProject: $scope.currentProject.isDemoProjectCopy,
      assetSearchContext: $scope.assetSearchContext
    }

    $scope.assets.resetSelected()

    $rootScope.$broadcast('sv-before-batch-asset-delete-start')
    batchJobService.manageBatchJob(
      null,
      $scope.currentProject.id,
      smartvidApi.deleteAssets(multiAssetSelection),
      ['sv-all-batch-asset-delete-complete', goToStateName, goToStateParams],
      ['sv-all-batch-asset-delete-failed']
    )
  }

  function onAssetGridGroupBy (event, ...args) {
    $scope.isGrouped = true
    let [sortBy, sortOrder] = args
    if (!sortBy) {
      let state = assetGridHelper.readAssetGridState($state.params.projectId)
      state.groupBy = false
      state.groupByColumn = undefined
      state.groupByOrder = undefined
      $scope.isGrouped = false
      assetGridHelper.saveAssetGridState($state.params.projectId, state)
    } else {
      let state = assetGridHelper.readAssetGridState($state.params.projectId)
      state.groupBy = true
      state.groupByColumn = sortBy
      state.groupByOrder = sortOrder
      assetGridHelper.saveAssetGridState($state.params.projectId, state)
    }
    $scope.assets.init()
    // clear last selected placard
    $rootScope.lastSelectedPlacard = undefined
  }

  function onAssetGridSortBy (event, ...args) {
    let [sortBy, sortOrder] = args

    let state = assetGridHelper.readAssetGridState($state.params.projectId)
    state.sortBy = true
    state.sortByColumn = sortBy
    state.sortByOrder = sortOrder
    assetGridHelper.saveAssetGridState($state.params.projectId, state)

    $scope.assets.init()
  }

  function onCurrentUserTokenChange (newVal, oldVal) {
    if (newVal !== oldVal && !newVal) {
      // Token expired. Reject here and reset user token
      currentUser.timeoutUser()
    }
  }

  function onStateChangeSuccess (event, toState, toParams, fromState, fromParams) {
    handleState(toParams, toState)
  }

  function onUploadProgress (evt, fileInfo) {
    let guid = fileInfo.key
    if (!$scope.assets) {
      return
    }
    let asset = $scope.assets.findById(guid)
    // If we have changed projects then we no longer have that video so we should not try and update it.
    if (asset) {
      asset.uploadProgress = fileInfo.progress / 100
      utils.digest($scope)
    }
  }

  function onProjectCopyDone (evt, project) {
    if (!$scope.projects) {
      return
    }
    if (project.isDemoProjectCopy) {
      let prevCopiedDemoProject = $scope.projects.where({isDemoProjectCopy: true})
      if (prevCopiedDemoProject && prevCopiedDemoProject.length === 1) {
        $scope.projects.removeById(prevCopiedDemoProject[0].id)
      }
    }

    $scope.projects.add(project)
  }

  function onSetUnverifiedTagsCount (event, payload) {
    if (payload.assetId && $scope.assets.findById !== undefined) {
      let assetModel = $scope.assets.findById(payload.assetId)
      if (assetModel.unverifiedTagsCount !== undefined && payload.unverifiedTagsCount !== undefined) {
        assetModel.unverifiedTagsCount = payload.unverifiedTagsCount
      }
    }
  }

  function onProjectUpdated (message, data) {
    let project = $scope.projects.findById(data.id)
    if (project) {
      project.update(data)
    } else {
      $log.error('Failure to update project in the project collection')
    }
  }

  function onWindowResize () {
    if ($scope.placardAnimationInScope) {
      utils.digest($scope)
    }
  }

  function onProjectMembersUninvitedFromProject (message, projectId, userIds) {
    if ($scope.currentProject && $scope.currentProject.id === projectId) {
      _.each(userIds, (userId) => {
        $scope.projectMembers.removeByUserId(userId)
      })
    }
    // Remove project from the list if the user uninvited himself
    if (_.find(userIds,
      (userId) => {
        return userId === currentUser.userId
      })) {
      $scope.projects.removeById(projectId)
      $scope.currentProject = null
      $state.go('dashboard.projects')
    }
  }

  function onDestroy () {
    deregisterCurrentUserListener()
    deregisterDeleteNodeListener()
    angular.element($window).off('resize.smartvid.dashboard', onWindowResize)
  }

  function onDeleteNode (event, data) {
    if ($scope.searchByValues && _.find($scope.searchByValues,
      (v) => {
        return v.id === data && v.type === 'TAG_DEFINITION'
      })) {
      clearSearchState(true)
    }
  }

  function onProjectMembersInvitedToProject (message, projectId, invitedUsers) {
    if ($scope.currentProject && $scope.currentProject.id === projectId) {
      $scope.projectMembers.upsert(invitedUsers)
    }
  }

  function onOrganizationUpdated (message, data) {
    smartvidApi.getCurrentUser().then((response) => {
      loadOrganizations()
    })
  }

  function leftNavInitialState () {
    let open = $scope.NavController.leftNavOpen
    mobleAdjustLeftNav()
    return open
  }

  function setLeftNav (open) {
    if (open) {
      $scope.NavController.openLeftNav()
    } else {
      $scope.NavController.closeLeftNav()
    }
  }

  function setRightNav (open) {
    if (open) {
      $scope.NavController.openRightNav()
    } else {
      $scope.NavController.closeRightNav()
    }
  }

  function handleShowMiddleOrgLogo () {
    $scope.showOrgLogo = true
    let asset = dashboardDataHelper.getCurrentAsset()
    setOrgLogoData(asset)
  }

  function setOrgLogoData (asset) {
    isImage(asset.orgData.logoUrl).then((result) => {
      $scope.orgLogo = result ? asset.orgData.logoUrl : undefined
    })
    $scope.orgLogo
    $scope.orgName = asset.orgData.name
    $scope.assetName = asset.name
  }

  //
  // is the image an accessible resource
  //
  function isImage (src) {
    var deferred = $q.defer()
    var image = new Image()
    image.onerror = function () {
      deferred.resolve(false)
    }
    image.onload = function () {
      deferred.resolve(true)
    }
    image.src = src
    return deferred.promise
  }

  function getAssetViewOptions (projectId, isMobile = false) {
    let options = $scope.helper.getAssetViewOptions(projectId, isMobile)
    $scope.isGrouped = $scope.helper.isGrouped(projectId)
    return options
  }

  function loadOrganizations () {
    $scope.adminOrganizations = new OrganizationsCollection(currentUser.organizations)
  }

  function loadProjectMembers () {
    $scope.projectMembers = new ProjectMembersCollection($state.params.projectId)
  }

  /**
   * Handle State Change
   * @param params
   */
  function handleState (params = $state.params, toState = null) {
    $scope.adminOrganizations = dashboardDataHelper.getAllOrganizations()
    $scope.currentAdminOrganization = dashboardDataHelper.getCurrentAdminOrganization()
    $scope.users = dashboardDataHelper.getCurrentAdminOrganizationUsers()
    $scope.projects = dashboardDataHelper.getAllProjects()
    $scope.currentProject = dashboardDataHelper.getCurrentProject()

    $scope.assets = dashboardDataHelper.getTargetAssets($state.params)
    $scope.projectTags = dashboardDataHelper.getCurrentProjectTags()
    $scope.orgTags = dashboardDataHelper.getCurrentOrgTags()
    $scope.assetSearchContext = searchResultService.getCurrentSearchContext()
    $scope.searchByValues = ($scope.assetSearchContext) ? $scope.assetSearchContext.getSearchByValues() : []
    $scope.organizationAssets = dashboardDataHelper.getOrganizationAssets()
    $scope.projectGroupAssets = dashboardDataHelper.getProjectGroupAssets()
    //
    // Need to revisit. Fix for clearing search when switching away from 'files' menu while search
    // is active. Ideally, should clear search when search context is updated inside searchResultService.
    // However, now onSearch is invoked before 'search context' is updated.
    //
    if ($scope.helper.isActiveSearch() && !$scope.assetSearchContext) {
      $scope.helper.setIsActiveSearch(false)
      $rootScope.$broadcast('sv-search-context-update')
    }

    NavController.init()
    if ($scope.currentProject && $scope.assets) {
      $scope.isGrouped = $scope.helper.isGrouped($scope.currentProject.id)
    }
    //
    // We turn off placard animations on route change.
    // If any placards need to be animated this scope variable
    // will automatically be set to true by the placard.
    //
    $scope.placardAnimationInScope = false

    /* -------------------
     * Shared Content Viewer
     * -------------------
     * State where a user is currently looking at a piece of
     * shared content. Could be an asset,tag,comment ... etc
     */
    if (searchResultService.isAssetSharing() || searchResultService.isTagSharing() || searchResultService.isCommentSharing()) {
      handleShowMiddleOrgLogo()
    } else {
      $scope.showOrgLogo = false
    }

    if (toState && toState.name === 'dashboard.projects.projectId.members' && _.has(params, 'projectId')) {
      loadProjectMembers()
    }

    if (toState && toState.name === 'dashboard.projects.projectId.profile' && _.has(params, 'projectId')) {
      loadProjectMembers()
    }

    let assetsCollection = $scope.assets

    if (_.has(params, 'projectId') && !_.has(params, 'assetId') &&
      (!assetsCollection || $state.params.projectId !== assetsCollection.projectId)) {
      if ($scope.isMobile) {
        $scope.NavController.closeLeftNav()
      }
    }

    if ($state.params.asset) {
      $scope.assetTags = $state.params.asset.tags
    }

    // keep leftnav closed on media viewer
    if ($scope.isMobile && toState && toState.name === 'dashboard.projects.projectId.files.viewer') {
      $scope.NavController.closeLeftNav()
      $scope.isShowingMobileBottomNav = utils.isShowingMobileBottomNav()
    }

    // clear last selected placard
    $rootScope.lastSelectedPlacard = undefined
  }

  function searchAssets (searchByValues) {
    let assetSearchContext = getAssetSearchContext(searchByValues)
    searchService.searchWithAssetSearchContext(assetSearchContext)
  }

  function searchObservations (searchByValues) {
    let searchContext = getObservationSearchContext(searchByValues)
    let searchCriteria = ObservationSearchCriteria.fromSearchContext(searchContext)
    ngxsStoreService.dispatch(new ApplyObservationSearch(searchCriteria))
  }

  function clearObservationSearch (searchByValues) {
    let searchCriteria = ObservationSearchCriteria.empty()
    ngxsStoreService.dispatch(new ApplyObservationSearch(searchCriteria))
  }

  function clearSearchState (clearGlobal) {
    $scope.searchByValues = []
    $scope.assetSearchContext = undefined
    if ($scope.currentProject) {
      searchResultService.clearProjectSearchInfo()
    }
    if (clearGlobal) {
      searchResultService.clearGlobalSearchInfo()
    }
  }

  function clearSearch () {
    $scope.helper.setIsActiveSearch(false)
    if (searchResultService.getCurrentSearchContext()) {
      searchResultService.getCurrentSearchContext().tagConfidenceLevel = undefined
    }
    if ($scope.currentProject) {
      let params = {
        projectId: $scope.currentProject.id,
        isDemoProject: $scope.currentProject.isDemoProjectCopy,
        searchContext: null,
        searchFilter: null
      }
      let reloadState = 'dashboard.projects.projectId.files'
      if (searchResultService.isInGlobalSearchContext()) {
        params.globalSearchContext = null
        params.globalSearchFilter = null
        $state.go('dashboard.projects', {
          globalSearchContext: null,
          globalSearchFilter: null
        }, {
          reload: 'dashboard.projects'
        })
      } else {
        $state.go('dashboard.projects.projectId.files', params, {
          reload: reloadState
        })
      }
    } else {
      $state.go('dashboard.projects', {
        globalSearchContext: null,
        globalSearchFilter: null
      }, {
        reload: 'dashboard.projects'
      })
    }
  }

  function showUserOptions ($event) {
    $scope.flyout.open('userOptions', {
      parentElement: $event.currentTarget,
      direction: 'top',
      newComment: $scope.newComment
    })
  }

  /**
   * Set the placard coordinates and trigger animation
   * @param idx
   * @param groupNumber
   * @returns {{position: string, transition: string, margin: string, left: number, top: number}}
   */
  function animPlacardStyle (idx, groupNumber) {
    // only for desktop
    if ($scope.isMobile) {
      return
    }
    //
    // Be sure the scope knows to update the digest on resize to position the placards properly.
    //
    $scope.placardAnimationInScope = true

    let rightNavOpen = $scope.NavController.leftNavOpen
    let leftNavOpen = $scope.NavController.rightNavOpen

    let leftOffset = leftNavOpen ? 220 : 0
    let rightOffset = rightNavOpen ? 220 : 0
    let screenW = window.innerWidth - (leftOffset + rightOffset)
    let columns = parseInt(screenW / 242, 10)
    let margin = (screenW - (columns * 222)) / (columns + 1)

    let column = idx % columns
    let row = parseInt(idx / columns, 10)
    let topOffset = 0
    let scrollTop = 0
    if (groupNumber !== undefined && $('.group-number-' + groupNumber).length) {
      topOffset = $('.group-number-' + groupNumber).position().top + 50
      scrollTop = $('.video-grid').scrollTop()
    }

    return {
      'position': 'absolute',
      'transition': 'top 400ms linear, left 400ms linear',
      'margin': '0',
      'left': column * 222 + column * margin + margin,
      'top': row * 222 + 20 + topOffset + scrollTop
    }
  }

  function isSearchFieldAvailable () {
    if (contentSharingContext.isSet()) {
      return false
    }
    if ($state.current.name.endsWith('.viewer')) {
      return false
    }
    if ($state.current.name.startsWith('dashboard.adminOrganizations.organizationId.deletedProjects')) {
      return false
    }
    if ($state.current.name.startsWith('dashboard.projects') || $state.current.name.startsWith('dashboard.adminOrganizations')) {
      return true
    }
    return false
  }

  function isObservationSearchAvailable () {
    if (contentSharingContext.isSet()) {
      return false
    }
    let regex = new RegExp('(?!.*?viewer)^dashboard.observations.*$')
    return regex.test($state.current.name)
  }

  function getAssetSearchContext (searchByValues) {
    let searchByTextFragments = _.map(_.uniq(_.filter(searchByValues, (t) => {
      return ((t instanceof SearchEntityModel) && t.isFreeText()) || !(t instanceof SearchEntityModel)
    })), (t) => {
      return t.text
    })
    let searchByTagDefs = _.uniq(_.filter(searchByValues, (t) => {
      return (t instanceof SearchEntityModel) && t.isTagDefinition()
    }))
    let searchByUsers = _.map(_.uniq(_.filter(searchByValues, (t) => {
      return (t instanceof SearchEntityModel) && t.isUser()
    })), (u) => {
      // Map value of SearchEntityModel instance to email field for the user
      u.email = u.text
      return u
    })

    let assetSearchContext = new AssetSearchContext(searchByTagDefs, searchByUsers,
      searchByTextFragments, null, null, searchByValues.searchDateRange, null, searchByValues.searchQueryType, null)
    return assetSearchContext
  }

  function getObservationSearchContext (searchByValues) {
    let assetContext = getAssetSearchContext(searchByValues)
    return ObservationSearchCriteria.getSearchContextFrom(assetContext.textFragments, assetContext.searchDateRange)
  }

  function getSearchFieldDefaultText () {
    if ($scope.currentProject) {
      return $filter('i18next')('search.inputPlaceholder')
    } else {
      return $filter('i18next')('search.crossProjectinputPlaceholder')
    }
  }

  function handleAvatarClick ($event) {
    showUserOptions($event)
    $scope.showSignoutTooltip = false
  }

  function onEndSlideshow () {
    $scope.showSecretSlideshow = false
  }

  function onStartSlideshow () {
    $scope.showSecretSlideshow = true
    let asset = dashboardDataHelper.getCurrentAsset()
    setOrgLogoData(asset)
  }

  function onSecretSlideshowTransition () {
    if (!isViewerState()) {
      secretSlideshowHelper.endSlideshow()
      return
    }

    $timeout(() => {
      let asset = dashboardDataHelper.getCurrentAsset()
      setOrgLogoData(asset)
    }, 500)
  }

  function isViewerState () {
    return $state.current.name.includes('viewer')
  }
})
