<div class="safety-gauge-container">
  <dx-circular-gauge [value]="currentValue" containerBackgroundColor="#323232" theme="generic.dark">
    <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="10">
      <dxo-tick opacity="1" color="#323232" [visible]="true" width="3" length="5"></dxo-tick>
      <dxo-minor-tick [visible]="false"></dxo-minor-tick>
      <dxo-label [visible]="false" indentFromTick="-1"></dxo-label>
    </dxo-scale>
    <dxo-geometry [startAngle]="210" [endAngle]="-30"> </dxo-geometry>
    <dxo-value-indicator type="twoColorNeedle" color="#898989" secondColor="#C2C2C2" spindleGapSize="10">
    </dxo-value-indicator>
    <dxo-range-container>
      <dxi-range [startValue]="0" [endValue]="90" color="#E24027"></dxi-range>
      <dxi-range [startValue]="90" [endValue]="97" color="#F5A623"></dxi-range>
      <dxi-range [startValue]="97" [endValue]="100" color="#2EA96F"></dxi-range>
    </dxo-range-container>
  </dx-circular-gauge>
  <span class="safety-gauge-value-label mat-headline">{{
    isNA(currentValue) ? ('dashboard.valueNotAvailable' | translate) : (currentValue | number: '.1-1') + '%'
  }}</span>
</div>
