import { Component } from '@angular/core'
import { BaseChartDirective } from 'modules/insights/dashboard/charts/models/base-chart.type'
import { AREA_CHART_OPTIONS } from 'modules/insights/dashboard/charts/models/chart-static-options.type'

@Component({
  selector: 'sv-insights-area-chart',
  templateUrl: 'insights-area-chart.component.html',
  styleUrls: ['insights-area-chart.component.scss'],
})
export class InsightsAreaChartComponent extends BaseChartDirective {
  getChartType(): string {
    return 'AreaChart'
  }

  getPredefinedChartOptions(): {} {
    return AREA_CHART_OPTIONS
  }
}
