/* global angular, _ */

angular.module('smartvid').factory('TagInstanceModel', function (BaseModel, TAG_INSTANCE_TYPE_ASR,
                                                                 TAG_INSTANCE_TYPE_IMREC, TAG_INSTANCE_TYPE_MANUAL) {
  class TagInstanceModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        assetId: undefined, // timestamp
        deleted: undefined, // boolean
        createdTime: undefined,
        endTime: undefined,
        onTimeline: undefined,
        status: undefined,
        text: undefined,
        updatedTime: undefined,
        type: undefined,
        score: undefined,
        startTime: undefined,
        tagDefinitionId: undefined,
        creator: {
          firstName: undefined,
          lastName: undefined,
          email: undefined
        },
        editMode: false,
        local: false,
        tagConfidenceLevel: undefined,
        originalTagConfidenceLevel: undefined,
        tagDefinitionType: undefined,
        markup: {
          top: undefined,
          left: undefined,
          width: undefined,
          height: undefined,
          shape: undefined,
          rotation: undefined,
          version: undefined,
          type: undefined,
          panX: undefined,
          panY: undefined,
          zoomLevel: undefined,
          imageZoomLevel: undefined
        }
      }

      super(_.defaults(attrs || {}, defaults))
    }

    startTimeInSeconds () {
      return this.startTime / 1000
    }

    endTimeInSeconds () {
      return this.endTime / 1000
    }

    isAssetLevel () {
      return this.startTime === -1
    }

    isVerified () {
      return this.status === 'VERIFIED'
    }

    isSmartTag () {
      return this.type === TAG_INSTANCE_TYPE_IMREC || this.type === TAG_INSTANCE_TYPE_ASR
    }

    hasMarkup () {
      return this.markup !== undefined &&
        (
          (
            this.markup.top !== undefined &&
            this.markup.left !== undefined &&
            this.markup.width !== undefined && this.markup.width > 0 &&
            this.markup.height !== undefined && this.markup.height > 0
          ) ||
          (
              this.markup.sphericalMarkup &&
              this.markup.sphericalMarkup.pitch !== undefined &&
              this.markup.sphericalMarkup.yaw !== undefined &&
              this.markup.sphericalMarkup.width !== undefined && this.markup.sphericalMarkup.width > 0 &&
              this.markup.sphericalMarkup.height !== undefined && this.markup.sphericalMarkup.height > 0

          )

        )
    }
  }

  return TagInstanceModel
})
