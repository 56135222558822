/* global angular */

angular.module('smartvid').factory('ForgeIssueTypeCollection', function (BaseCollection, ForgeIssueTypeModel) {
  class ForgeIssueTypeCollection extends BaseCollection {
    constructor (models) {
      super(models, ForgeIssueTypeModel)
    }
  }

  return ForgeIssueTypeCollection
})
