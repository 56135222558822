/* global angular, _ */

angular.module('smartvid').factory('BaseModel', function () {
  class BaseModel {
    constructor (attrs) {
      this.update(attrs)
    }

    /**
     * @param oiteratee See _.pick oiteratee argument for details.
     * @return updated self !important!
     */
    update (attrs, oiteratee = BaseModel.defaultOiterateePredicate) {
      if (oiteratee) {
        attrs = _.pick(attrs, oiteratee)
      }
      // assigns all key-value pairs to the `this` object
      let that = this
      _.each(attrs, (value, key) => {
        if (that[key] instanceof BaseModel) {
          that[key] = that[key].update(value)
        } else {
          that[key] = value
        }
      })

      return this
    }

    static defaultOiterateePredicate (value, key, model) {
      return (angular.isDefined(value) && key !== 'selected')
    }
  }

  return BaseModel
})
