/* global angular */

angular.module('smartvid').service('commentsApi', function ($http, $q, config, $log, CommentsCollection, CommentModel) {
  let rootUrl = config.env.development.apiRootUrl

  let api = {
    getCommentsForAsset (assetId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/comment_thread/asset/${assetId}`

      $http.get(url).then(
        function (response) {
          let commentCollection = new CommentsCollection()
          if (response && response.data) {
            commentCollection.add(response.data, CommentModel)
            $log.debug('getCommentsForAsset', commentCollection)
          }

          defer.resolve(commentCollection)
        },
        () => {
          defer.reject()
        }
      )

      return defer.promise
    },
    createCommentForAsset (assetId, comment) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/comment_thread`
      let markupJSON = comment.markupAsJSONString()
      let payload = {
        assetId: assetId,
        description: comment.description,
        startTime: comment.startTime,
        markup: markupJSON,
        shape: comment.shape,
        width: comment.width,
        height: comment.height,
        top: comment.top,
        left: comment.left,
        color: comment.color,
        panX: comment.panX,
        panY: comment.panY,
        zoomLevel: comment.zoomLevel,
        imageZoomLevel: comment.imageZoomLevel
      }

      $http.post(url, payload).then(function (response) {
        defer.resolve(response.data)
      })

      return defer.promise
    },
    updateCommentForAsset (assetId, comment) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/comment_thread`
      let markupJSON = comment.markupAsJSONString()
      let payload = {
        assetId: assetId,
        description: comment.description,
        startTime: comment.startTime,
        markup: markupJSON,
        id: comment.id,
        shape: comment.shape,
        width: comment.width,
        height: comment.height,
        top: comment.top,
        left: comment.left,
        color: comment.color,
        panX: comment.panX,
        panY: comment.panY,
        zoomLevel: comment.zoomLevel,
        imageZoomLevel: comment.imageZoomLevel
      }

      $http.put(url, payload).then(function (response) {
        defer.resolve(response.data)
      })

      return defer.promise
    },

    deleteCommentForAsset (commentThreadId) {
      let url = `${rootUrl}/api/comment_thread/${commentThreadId}`
      let defer = $q.defer()

      $http.delete(url).then(function (response) {
        defer.resolve(commentThreadId)
      })
      return defer.promise
    }
  }

  return api
})
