import * as _ from 'lodash'
import { Component, OnDestroy, Input, OnInit, HostListener, Inject } from '@angular/core'
import { GoogleChartInterface } from 'ng2-google-charts'
import { Organization } from '../../models/organization.model'
import { SmartvidApi, SMARTVID_API } from 'shared/smartvid.types'
import { DashboardDataHelperService } from '../../../core/services/dashboard-data-helper.service'
import {
  AdminIntegrationSettingsWithUser,
  OrganizationIntegrationSettingsActionType,
} from '../../models/admin.integration.settings.model'
import { TranslateService } from '@ngx-translate/core'
import { IntegrationType } from '../../../../shared/models/integration-type'
import { AdminProjectWithIntegrationSettings } from '../../models/admin.project.with.integration.settings.model'
import { AdminProject } from '../../models/admin.project.model'
import {
  IntegrationSettingsDisconnectedEvent,
  IntegrationSettingsEnableEvent,
} from '../../models/admin.integration.settings.events'

@Component({
  selector: 'sv-admin-organization-integrations',
  templateUrl: 'admin-organization-integrations.component.html',
  styleUrls: ['admin-organization-integrations.component.scss'],
})
export class AdminOrganizationIntegrationsComponent implements OnInit, OnDestroy {
  @Input() organization: Organization
  @Input() integrationSettings: AdminIntegrationSettingsWithUser[]
  @Input() projectsWithIntegrationSettings: AdminProjectWithIntegrationSettings[]
  @Input() integrationTypes: IntegrationType[]

  isInitialized = false

  INTEGRATIONS = [
    IntegrationType.PROJECT_PHOTO_SYNC,
    IntegrationType.BIM360_FIELD_PHOTO_SYNC,
    IntegrationType.EGNYTE_SYNC,
    IntegrationType.FORGE_SYNC,
    IntegrationType.OXBLUE_SYNC,
    IntegrationType.BOX_SYNC,
    IntegrationType.ACONEX_SYNC,
    IntegrationType.SHAREPOINT_SYNC,
    IntegrationType.STRUCTION_SITE_SYNC,
  ]

  public columnChart: GoogleChartInterface = {
    chartType: 'BarChart',
    options: {
      height: '100',
      legend: { position: 'top', maxLines: 1 },
      bar: { groupWidth: '60%' },
      isStacked: true,
      chartArea: {
        left: 0,
        width: '100%',
        height: 50,
      },
      hAxis: {
        format: '',
        textStyle: {
          fontSize: 1,
          color: 'transparent',
        },
        gridlines: {
          color: 'transparent',
        },
      },
      backgroundColor: {
        stroke: 'transparent',
      },
    },
  }

  constructor(
    private dashboardDataHelper: DashboardDataHelperService,
    protected translate: TranslateService,
    @Inject(SMARTVID_API) private smartvidApi: SmartvidApi
  ) {}

  // https://www.devrandom.it/software/ng2-google-charts/additional-documentation/advanced-usage.html
  @HostListener('window:resize', ['$event'])
  private onResize() {
    if (this.isInitialized) {
      this.columnChart.component.draw()
    }
  }

  ngOnInit(): void {
    this.smartvidApi.getIntegrationSettingsForOrganization(this.organization.id).then(response => {
      this.init(response.integrationSettings)
    })
  }

  ngOnDestroy(): void {}

  getOrgName() {
    return this.organization.name
  }

  getIntegrationCount() {
    if (!this.integrationSettings) {
      return 0
    }
    return this.integrationSettings.length
  }

  init(integrationSettings: AdminIntegrationSettingsWithUser[]) {
    this.integrationSettings = integrationSettings

    let byType = this.countByType()
    let header = ['']
    let values = ['']
    for (let type of this.INTEGRATIONS) {
      this.addToHeadersAndValues(header, values, byType, type)
    }
    this.columnChart.dataTable = [header, values]

    this.integrationTypes = _.filter(this.INTEGRATIONS, integrationType => {
      return integrationType in byType
    })

    let projectsById = _.indexBy(this.dashboardDataHelper.getOrgProjects().models, 'id')

    this.projectsWithIntegrationSettings = _.map(this.integrationSettings, settings => {
      let ret = new AdminProjectWithIntegrationSettings()
      ret.integrationSettings = settings
      ret.project = projectsById[settings.integrationSettings.projectId] as AdminProject
      return ret
    })

    this.isInitialized = true
  }

  getProjectCount() {
    if (!this.dashboardDataHelper.getOrgProjects()) {
      return 0
    }
    return this.dashboardDataHelper.getOrgProjects().length
  }

  getAllProjectsWithIntegrationSettings(): AdminProjectWithIntegrationSettings[] {
    return this.projectsWithIntegrationSettings
  }

  getProjectsWithIntegrationSettingsByType(integrationType: IntegrationType): AdminProjectWithIntegrationSettings[] {
    if (!this.integrationSettings) {
      return undefined
    }
    return _.filter(this.projectsWithIntegrationSettings, integrationSettings => {
      return integrationSettings.integrationSettings.integrationSettings.integrationType === integrationType
    })
  }

  addToHeadersAndValues(header: any[], values: any[], byType: {}, integrationType: IntegrationType) {
    if (!byType[integrationType]) {
      return
    }
    header.push(this.translate.instant('components.adminOrganizationIntegrations.' + integrationType))
    header.push({ role: 'annotation' })
    values.push(byType[integrationType])
    values.push(byType[integrationType])
  }

  countByType() {
    return _.countBy(this.integrationSettings, i => {
      return i.integrationSettings.integrationType
    })
  }

  getIntegrationSettingsIdsWithType(integrationSettingsIds: string[]) {
    let integrationSettingsMap = {}
    for (let settings of this.integrationSettings) {
      integrationSettingsMap[settings.integrationSettings.id] = settings
    }
    return _.map(integrationSettingsIds, id => {
      return {
        id: id,
        integrationType: integrationSettingsMap[id].integrationSettings.integrationType,
      }
    })
  }

  disconnectIntegrations($event: IntegrationSettingsDisconnectedEvent) {
    if (!$event.integrationSettingsIds) {
      return
    }

    let integrationSettingsIdsWithType = this.getIntegrationSettingsIdsWithType($event.integrationSettingsIds)

    let ids = new Set($event.integrationSettingsIds)
    this.integrationSettings = _.filter(this.integrationSettings, i => {
      return !ids.has(i.integrationSettings.id)
    })
    this.init(this.integrationSettings)
    this.columnChart.component.draw()

    this.smartvidApi.updateIntegrationSettingsForOrganization(
      {
        action: OrganizationIntegrationSettingsActionType.DISCONNECT,
        integrationSettingsIdsWithType: integrationSettingsIdsWithType,
      },
      this.organization.id
    )
  }

  changeEnabledForIntegrations($event: IntegrationSettingsEnableEvent) {
    if (!$event.integrationSettingsIds) {
      return
    }

    let integrationSettingsIdsWithType = this.getIntegrationSettingsIdsWithType($event.integrationSettingsIds)

    this.smartvidApi
      .updateIntegrationSettingsForOrganization(
        {
          action: $event.enabled
            ? OrganizationIntegrationSettingsActionType.ENABLE
            : OrganizationIntegrationSettingsActionType.DISABLE,
          integrationSettingsIdsWithType: integrationSettingsIdsWithType,
        },
        this.organization.id
      )
      .then(() => {
        let ids = new Set($event.integrationSettingsIds)
        _.forEach(this.integrationSettings, i => {
          if (ids.has(i.integrationSettings.id)) {
            i.integrationSettings.isEnabled = $event.enabled
          }
        })
        _.forEach(this.projectsWithIntegrationSettings, i => {
          if (ids.has(i.integrationSettings.integrationSettings.id)) {
            i.integrationSettings.integrationSettings.isEnabled = $event.enabled
          }
        })
      })
  }
}
