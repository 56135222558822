/* global angular, _ */
/**
 * Created by ryanrubbico on 7/7/17.
 */
angular.module('smartvid').filter('orderedUnique', function () {
  return function orderedUniqueFn (items, filterProp) {
    function getPropValue (item, filterProp) {
      return (_.isObject(item) && _.isString(filterProp)) ? item[filterProp] : item
    }

    let prevVal = null
    let filteredItems = []

    angular.forEach(items, function (item) {
      if (getPropValue(item, filterProp) !== prevVal) {
        prevVal = getPropValue(item, filterProp)
        filteredItems.push(item)
      }
    })

    return filteredItems
  }
})
