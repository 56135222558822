/* global angular,_ */

angular.module('smartvid').directive('tagTreeInfoPanel', function (smartvidApi, $rootScope) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      treeModel: '='
    },
    templateUrl: 'TagManager/infopanel.html',
    link (scope) {
      let cleanupListeners = []
      scope.selectedNodes = []
      scope.retrievedAssetCount = null
      scope.retrievingAssetCount = false
      scope.manualTagCount = null
      scope.audioTagCount = null
      scope.imageTagCount = null
      scope.lastTagFound = null
      scope.tagAliases = null

      scope.searchSelectedTag = searchSelectedTag

      let uiTreeLoadedListener = scope.$on('uiTreeLoaded', function (event, gridApi) {
        cleanup()
        scope.gridApi = gridApi
        update()
        cleanupListeners.push(scope.gridApi.selection.on.rowSelectionChanged(scope, function (row) {
          update()
        }))
        cleanupListeners.push(scope.gridApi.selection.on.rowSelectionChangedBatch(scope, function (rows) {
          update()
        }))
        scope.uiTreeLoaded = true
      })

      scope.$on('$destroy', () => {
        cleanup()
        uiTreeLoadedListener()
      })

      function cleanup () {
        _.each(cleanupListeners, (listener) => {
          listener()
        })
      }
      function update () {
        scope.selectedNodes = scope.gridApi.selection.getSelectedRows()
        if (scope.selectedNodes.length === 1) {
          let selectedNode = scope.selectedNodes[0]
          numberOfItems(selectedNode.tagDefinitionId)
          getTagAliases(selectedNode.tagDefinitionId)
          getTagCountsByType(selectedNode.tagDefinitionId)
        }
      }

      function searchSelectedTag () {
        if (scope.selectedNodes.length !== 1) {
          return
        }
        $rootScope.$broadcast('sv-tag-node-search', scope.selectedNodes[0])
      }

      function numberOfItems (tagDefId) {
        scope.retrievingAssetCount = true
        scope.treeModel.getAssetCountByTagDefs([tagDefId]).then((response) => {
          scope.retrievedAssetCount = response
        }).finally(() => {
          scope.retrievingAssetCount = false
        })
      }

      function getTagAliases (tagDefId) {
        let tagDef = scope.treeModel.getTagDefs().findById(tagDefId)
        scope.tagAliases = tagDef.aliases
      }

      function getTagCountsByType (tagDefId) {
        scope.manualTagCount = null
        scope.audioTagCount = null
        scope.imageTagCount = null
        scope.lastTagFound = null
        scope.treeModel.getTagInstancesCountsGroupedByType(tagDefId).then((response) => {
          if (angular.isDefined(response.lastTagFound)) {
            scope.lastTagFound = response.lastTagFound
          }

          for (var index in response.listOfCounts) {
            let tagDetails = response.listOfCounts[index]

            if (angular.isDefined(tagDetails.tagType)) {
              let tagType = tagDetails.tagType
              if (tagType === 'MANUAL') {
                scope.manualTagCount = tagDetails.tagCount
              } else if (tagType === 'IMREC') {
                scope.imageTagCount = tagDetails.tagCount
              } else if (tagType === 'ASR') {
                scope.audioTagCount = tagDetails.tagCount
              }
            }
          }
        })
      }
    }
  }
})
