/* global angular */

angular.module('smartvid').directive('downArrowDecorate', function ($compile, $timeout) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      let inputElement = element
      if (!inputElement.is('input, select')) {
        inputElement = element.find('input, select')
      }
      let html = '<icon-down-arrow></icon-down-arrow>'
      let handlerFunction = attrs.downArrowHandler
      let arrowElement = angular.element(html)
      if (handlerFunction) {
        arrowElement.attr('ng-click', handlerFunction)
      }
      $compile(arrowElement)(scope)
      $timeout(() => {
        let inputPosition = inputElement.position()
        let inputWidth = inputElement.outerWidth()
        arrowElement.css({
          fill: '#8D8D8D',
          position: 'absolute',
          top: (inputPosition.top + 15) + 'px',
          left: (inputPosition.left + inputWidth - 25) + 'px',
          'pointer-events': 'bounding-box'                        // Make transparent sections of SVG clickable
        })
        arrowElement.insertAfter(inputElement)
        if (inputElement.is('select')) {
          inputElement.css({
            appearance: 'none',
            padding: '10px'
          })
          arrowElement.css({
            'pointer-events': 'none'
          })
        }
      }, 1)
    }
  }
})
