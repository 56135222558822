/* global angular */

angular.module('smartvid').factory('ForgeHubCollection', function (BaseCollection, ForgeHubModel) {
  class ForgeHubCollection extends BaseCollection {
    constructor (models) {
      super(models, ForgeHubModel)
    }
  }

  return ForgeHubCollection
})
