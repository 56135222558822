/* global angular _ */

angular.module('smartvid').controller('ProjectMemberOptionsCtrl', function ($rootScope, $scope, $timeout, $window, $state, smartvidApi, modal, utils, $filter, NavController) {
  const LEFT_OFFSET = 15
  const TOP_OFFSET = 17
  $scope.openProjectMemberInformation = openProjectMemberInformation
  $scope.unInviteProjectMember = unInviteProjectMember
  $scope.reinviteUserToSmartvidAndProject = reinviteUserToSmartvidAndProject
  $scope.getStyles = getStyles

  function unInviteProjectMember () {
    $scope.flyout.isOpen = false

    let confirmHandlers = {
      confirm () {
        $scope.projectObj.removeUsers([$scope.projectMember.userId]).then(() => {
          $scope.flyout.close()
        }, () => {
          $scope.flyout.close()
        })
      },
      hasSelections: true
    }
    modal.open('unInviteProjectMember', confirmHandlers)
  }

  function reinviteUserToSmartvidAndProject (projectId, user) {
    $scope.flyout.isOpen = false
    smartvidApi.reinviteUserToSmartvidAndProject($state.params.projectId, $scope.projectMember.email).then(() => {
      // success
      utils.notify($filter('i18next')('directives.reinviteUser.invitationSent') + user.email, '', '', false, null)
      $scope.flyout.close()
    }, (data) => {
      // failure
      utils.notify($filter('i18next')('directives.reinviteUser.failedInvitation'), '' + user.email, '', false, null)
      $scope.flyout.close()
    })
  }

  function getStyles () {
    return {
      top: $scope.styles.top - TOP_OFFSET,
      left: $scope.styles.left + LEFT_OFFSET
    }
  }

  function openProjectMemberInformation (members, member) {
    $scope.flyout.close()
    _.each(members.models, (model) => {
      model.selected = false
    })
    member.selected = true
    $rootScope.$broadcast('sv-placards-selected')
    $timeout(function () {
      NavController.openRightNav()
    }, 10)
  }
})
