import { Component, Input } from '@angular/core'
import { InsightsDashboardComponentBaseDirective } from 'modules/insights/dashboard/components/insights-dashboard-component-base'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import { TranslateService } from '@ngx-translate/core'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import {
  DashboardCardId,
  DashboardTabId,
  DashboardType,
  DateRangeChartData,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { OrganizationSingleValueDashboardData } from 'modules/insights/dashboard/models/insights-dashboard-organization-assets.model'
import { InsightsDashboardOrganizationAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-organization-assets.service'

@Component({
  selector: 'sv-organization-assets-insights-dashboard-image-health',
  templateUrl: 'organization-assets-insights-dashboard-image-health.component.html',
  styleUrls: ['organization-assets-insights-dashboard-image-health.component.scss'],
})
export class OrganizationAssetsInsightsDashboardImageHealthComponent extends InsightsDashboardComponentBaseDirective {
  private _organizationSingleValueData: OrganizationSingleValueDashboardData

  @Input() set organizationSingleValueData(value: OrganizationSingleValueDashboardData) {
    this._organizationSingleValueData = value

    if (this._organizationSingleValueData) {
      this.avgPhotosCreatedPerDayPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._organizationSingleValueData.avgPhotosCreatedPerDayPercentChange
      )
      this.avgPhotoDiffCreatedUploadedPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._organizationSingleValueData.avgPhotoDiffCreatedUploadedPercentChange
      )
      this.constructionPhotosToAllPhotosRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._organizationSingleValueData.constructionPhotosToAllPhotosRatioAvgPercentChange
      )
      this.percentPersonCoveragePercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._organizationSingleValueData.percentPersonCoveragePercentChange
      )
      this.percentOfAssetsWithoutCreatedDatePercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._organizationSingleValueData.percentOfAssetsWithoutCreatedDatePercentChange
      )
      this.percentOfConstructionAssetsWithoutCreatedDatePercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._organizationSingleValueData.percentOfConstructionAssetsWithoutCreatedDatePercentChange
      )
    }
  }
  get organizationSingleValueData() {
    return this._organizationSingleValueData
  }

  organizationAvgPhotosCreatedByDayOfWeekData: DateRangeChartData

  avgPhotosCreatedPerDayPercentChangeClassName: string
  avgPhotoDiffCreatedUploadedPercentChangeClassName: string
  constructionPhotosToAllPhotosRatioPercentChangeClassName: string
  percentPersonCoveragePercentChangeClassName: string
  percentOfAssetsWithoutCreatedDatePercentChangeClassName: string
  percentOfConstructionAssetsWithoutCreatedDatePercentChangeClassName: string

  constructor(
    protected insightsDataHelper: InsightsDataHelper,
    protected translate: TranslateService,
    protected insightsDashboardConfigurationService: InsightsDashboardConfigurationService,
    private insightsDashboardOrganizationAssetsService: InsightsDashboardOrganizationAssetsService
  ) {
    super(
      insightsDataHelper,
      insightsDashboardConfigurationService,
      translate,
      DashboardType.ORGANIZATION_ASSETS_DASHBOARD
    )
  }

  protected refresh() {
    this.guardFor(
      () => this.loadAvgPhotosCreatedByDayOfWeekData(),
      DashboardTabId.IMAGE_HEALTH,
      DashboardCardId.ORGANIZATION_ASSETS_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK
    )
  }

  private loadAvgPhotosCreatedByDayOfWeekData() {
    this.organizationAvgPhotosCreatedByDayOfWeekData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadAvgPhotosCreatedByDayOfWeekData(
        this.currentInsightsDashboardParameters,
        tableData => {
          this.organizationAvgPhotosCreatedByDayOfWeekData = tableData
        }
      )
    )
  }
}
