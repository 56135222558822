/* global angular */

/*
  Directive to verify that the user entered password
  conforms to smartvid's password requirements. Also
  solves problem caused by setting ng-minlength where
  model password variable would not reflect user value unless
  password was greater than said minlength (problem for testing pw strength).
*/
angular.module('smartvid').directive('svPasswordValid', function (passwordService) {
  return {
    require: 'ngModel',
    link: function (scope, elem, attrs, ctrl) {
      elem.on('keyup', function () {
        let password = elem.val()
        ctrl.$setValidity('pwvalid', passwordService.valid(password))
      })
    }
  }
})
