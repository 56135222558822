import { OnInit, Pipe, PipeTransform } from '@angular/core'
import { Component, Input } from '@angular/core'
import { UIRouterGlobals } from '@uirouter/angular'
import { Organization } from '../../models/organization.model'

/*
 Pipe that performs sorting of organizations
 */
@Pipe({
  name: 'OrganizationSortListPipe',
  pure: false,
})
export class OrganizationSortListPipe implements PipeTransform {
  transform(items: Organization[]): Organization[] {
    if (!items) {
      return items
    }
    return items.sort((lhs, rhs) => {
      return lhs.name.localeCompare(rhs.name)
    })
  }
}

@Component({
  selector: 'sv-organization-list',
  templateUrl: 'organization-list.component.html',
  styleUrls: ['organization-list.component.scss'],
})
export class OrganizationListComponent implements OnInit {
  @Input() organizations: Organization[]
  @Input() clickFunc: (org: Organization) => void

  constructor(private routerGlobals: UIRouterGlobals) {}

  ngOnInit(): void {}

  isActiveOrg(org: Organization) {
    let params = this.routerGlobals.params
    return org.id === params.organizationId
  }
}
