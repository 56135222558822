/* global angular */

angular.module('smartvid').factory('OrganizationsCollection', function (BaseCollection, OrganizationModel, smartvidApi, $rootScope, $state) {
  class OrganizationsCollection extends BaseCollection {
    constructor (models) {
      super(models, OrganizationModel)
    }
  }

  return OrganizationsCollection
})
