/* global angular */

angular.module('smartvid').directive('projectProfileControlbar', function (currentUser) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'ProjectGrid/project-profile-controlbar.html',
    link: function (scope) {
    }
  }
})
