import _ from 'lodash'
import { ChartData } from 'modules/insights/dashboard/charts/models/base-chart.type'
import {
  COLUMN_CHART_OPTIONS,
  LINE_CHART_OPTIONS,
  PIE_CHART_OPTIONS,
} from 'modules/insights/dashboard/charts/models/chart-static-options.type'
import {
  DashboardDataObjectResponseBase,
  TableScopeFilter,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { CardToggleMenuGroup } from 'modules/insights/dashboard/cards/models/insights-card.model'

export class ProjectTotalCounts extends DashboardDataObjectResponseBase {
  uploadedFilesTotal: number
  uploadedFilesRatioPercentChange: number
  uploadersTotal: number
  uploadersRatioPercentChange: number
  tagsTotal: number
  tagsTotalRatioPercentChange: number
  projectScore: number
  projectScorePercentChange: number
  ppeCompliancePercent: number
  ppeCompliancePercentChange: number
  workAtHeight: number
  workAtHeightPercentChange: number
  slipAndTrip: number
  slipAndTripPercentChange: number
  constructionPhotosToAllPhotosRatio: number
  constructionPhotosToAllPhotosRatioPercentChange: number
  laddersToLiftsRatio: number
  laddersToLiftsRatioPercentChange: number
  peoplePerXConstruction: number
  peoplePerXConstructionPercentChange: number
  avgPhotosCreatedPerDay: number
  avgPhotosCreatedPerDayPercentChange: number
  avgPhotoDiffCreatedUploaded: number
  avgPhotoDiffCreatedUploadedPercentChange: number
  percentPersonCoverage: number
  percentPersonCoveragePercentChange: number
  percentOfAssetsWithoutCreatedDate: number
  percentOfAssetsWithoutCreatedDatePercentChange: number
  percentOfConstructionAssetsWithoutCreatedDate: number
  percentOfConstructionAssetsWithoutCreatedDatePercentChange: number
  glassesCompliance: number
  glovesCompliance: number
  hardHatCompliance: number
  highVizCompliance: number
}

export interface ProjectTotalsTableCountsInfo {
  userFilesCount: number
  userFilesCountRatio: number
  userTagCount: number
  userTagCountRatio: number
  userCommentsCount: number
  userCommentsCountRatio: number
}

export interface ProjectTotalsTableUserInfo {
  id: string
  firstName: string
  lastName: string
  roleName: string
  email: string
  integrationType: string
  isExternalUser: boolean
}

export class ProjectTotalsTableData {
  userInfo: ProjectTotalsTableUserInfo
  countsInfo: ProjectTotalsTableCountsInfo
  chartData: ChartData
}

export class ProjectTotalsTableDataResponse extends DashboardDataObjectResponseBase {
  tableDate: ProjectTotalsTableData[]
}

export const PROJECT_TOTALS_CHART_OPTIONS = {
  legend: {
    position: 'none',
  },
  chartArea: { left: 0, top: '5%', width: '100%', height: '90%' },
  hAxis: {
    baselineColor: '#323232',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    baselineColor: '#323232',
    gridlines: {
      color: '#323232',
    },
  },
  tooltip: {
    isHtml: true,
  },
  colors: ['#cf1c0e'],
}

export const ACTIVITY_BY_USER_TABLE_MENU: CardToggleMenuGroup[] = [
  {
    groupId: 'ACTIVITY_BY_USER',
    groupItems: [
      {
        label: 'dashboard.cards.activityByUser.menu.svUsers',
        eventId: TableScopeFilter.ACTIVITY_BY_USER_MENU_SV_USERS,
        isChecked: true,
      },
      {
        label: 'dashboard.cards.activityByUser.menu.integrationUsers',
        eventId: TableScopeFilter.ACTIVITY_BY_USER_MENU_INTEGRATION_USERS,
      },
    ],
  },
]

export const PROJECT_TOTALS_CHART_DATA_COLUMNS: any[] = [
  'dashboard.projectAssets.totalChart.columns.date',
  'dashboard.projectAssets.totalChart.columns.count',
  { type: 'string', role: 'tooltip', p: { html: true } },
]

export const UPLOADS_OVERTIME_BY_SOURCE_CHART_OPTIONS = _.merge({}, COLUMN_CHART_OPTIONS, {
  chartArea: {
    height: '73%',
  },
  isStacked: true,
  hAxis: {
    format: 'MMM d, y',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    // textPosition: 'none',
  },
  legend: {
    position: 'bottom',
  },
})

export const UPLOADS_OVERTIME_BY_SOURCE_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.uploadsOverTimeChart.columns.date',
]

export const UPLOADERS_OVERTIME_CHART_OPTIONS = _.merge({}, COLUMN_CHART_OPTIONS, {
  chartArea: {
    height: '73%',
  },
  isStacked: true,
  hAxis: {
    format: 'MMM d, y',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    // textPosition: 'none',
  },
  legend: {
    position: 'bottom',
  },
})

export const UPLOADERS_OVERTIME_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.uploadersOverTimeChart.columns.date',
  'dashboard.projectAssets.uploadersOverTimeChart.columns.uploadersSv',
  'dashboard.projectAssets.uploadersOverTimeChart.columns.uploadersIntegration',
]

export const CONSTRUCTION_ACTIVITY_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.constructionActivityChart.columns.activity',
  'dashboard.projectAssets.constructionActivityChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const CONSTRUCTION_ACTIVITY_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const PHOTO_DESCRIPTION_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.photoDescriptionChart.columns.description',
  'dashboard.projectAssets.photoDescriptionChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const PHOTO_DESCRIPTION_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const UPLOADS_BY_FILE_TYPE_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.uploadsByFileTypeChart.columns.fileType',
  'dashboard.projectAssets.uploadsByFileTypeChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const UPLOADS_BY_FILE_TYPE_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const TAGS_BY_TYPE_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.tagsByTypeTypeChart.columns.tagType',
  'dashboard.projectAssets.tagsByTypeTypeChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const TAGS_BY_TYPE_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const SLIP_AND_TRIP_OVERTIME_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.slipAndTripOverTimeChart.columns.date',
]

export const SLIP_AND_TRIP_OVERTIME_CHART_OPTIONS = _.merge({}, COLUMN_CHART_OPTIONS, {
  chartArea: {
    height: '73%',
  },
  isStacked: true,
  hAxis: {
    format: 'MMM, y',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    // textPosition: 'none',
  },
  legend: {
    position: 'bottom',
  },
})

export const WORK_AT_HEIGHTS_OVERTIME_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.workAtHeightsOverTimeChart.columns.date',
]

export const WORK_AT_HEIGHTS_OVERTIME_CHART_OPTIONS = _.merge({}, COLUMN_CHART_OPTIONS, {
  chartArea: {
    height: '73%',
  },
  isStacked: true,
  hAxis: {
    format: 'MMM, y',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    // textPosition: 'none',
  },
  legend: {
    position: 'bottom',
  },
})

export const WORK_AT_HEIGHTS_PER_X_ASSETS_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.workAtHeightPerXAssetsChart.columns.type',
  'dashboard.projectAssets.workAtHeightPerXAssetsChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const WORK_AT_HEIGHTS_PER_X_ASSETS_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const LADDERS_PER_X_ASSETS_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.laddersPerXAssetsChart.columns.type',
  'dashboard.projectAssets.laddersPerXAssetsChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const LADDERS_PER_X_ASSETS_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const LIFTS_PER_X_ASSETS_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.liftsPerXAssetsChart.columns.type',
  'dashboard.projectAssets.liftsPerXAssetsChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const LIFTS_PER_X_ASSETS_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const HOUSEKEEPING_AND_STANDING_WATER_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.housekeepingAndStandingWaterChart.columns.type',
  'dashboard.projectAssets.housekeepingAndStandingWaterPerChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const HOUSEKEEPING_AND_STANDING_WATER_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const PPE_COMPLIANCE_OVERTIME_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.ppeComplianceOverTimeChart.columns.date',
  'dashboard.projectAssets.ppeComplianceOverTimeChart.columns.compliance',
  { type: 'string', role: 'tooltip' },
  'dashboard.projectAssets.ppeComplianceOverTimeChart.columns.complianceCutoff',
  { type: 'string', role: 'tooltip' },
]

export const PPE_COMPLIANCE_OVERTIME_CHART_OPTIONS = _.merge({}, LINE_CHART_OPTIONS, {
  hAxis: {
    format: 'MMM, y',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    format: "#'%'",
    minValue: 0,
  },
  legend: {
    position: 'none',
  },
})

export const PEOPLE_IN_GROUPS_OVERTIME_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.peopleInGroupsOverTimeChart.columns.date',
  'dashboard.projectAssets.peopleInGroupsOverTimeChart.columns.count',
]

export const PEOPLE_IN_GROUPS_OVERTIME_CHART_OPTIONS = _.merge({}, LINE_CHART_OPTIONS, {
  chartArea: {
    height: '75%',
  },
  hAxis: {
    format: 'MMM d, y',
    gridlines: {
      color: '#323232',
    },
  },
  legend: {
    position: 'bottom',
  },
})

export const AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK_CHART_OPTIONS = _.merge({}, COLUMN_CHART_OPTIONS, {
  chartArea: {
    height: '73%',
  },
  isStacked: true,
  hAxis: {
    gridlines: {
      color: '#323232',
    },
  },
  legend: {
    position: 'bottom',
  },
})

export const AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK_CHART_COLUMNS: any[] = [
  'dashboard.projectAssets.avgPhotosCreatedByDayOfWeekChart.columns.dayOfWeek',
  'dashboard.projectAssets.avgPhotosCreatedByDayOfWeekChart.columns.avgCreated',
]
