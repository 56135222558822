/* global angular */
/**
 * Created by ryanrubbico on 12/8/17.
 */
angular.module('smartvid').service('IntegrationType', function () {
  class IntegrationType {
    constructor () {
      Object.defineProperties(this, {
        'ACONEX_SYNC': {
          value: 'ACONEX_SYNC',
          writable: false
        },
        'BOX_SYNC': {
          value: 'BOX_SYNC',
          writable: false
        },
        'SHAREPOINT_SYNC': {
          value: 'SHAREPOINT_SYNC',
          writable: false
        },
        'EGNYTE_SYNC': {
          value: 'EGNYTE_SYNC',
          writable: false
        },
        'FORGE_SYNC': {
          value: 'FORGE_SYNC',
          writable: false
        },
        'OXBLUE_SYNC': {
          value: 'OXBLUE_SYNC',
          writable: false
        },
        'BIM360_FIELD_PHOTO_SYNC': {
          value: 'BIM360_FIELD_PHOTO_SYNC',
          writable: false
        },
        'STRUCTION_SITE_SYNC': {
          value: 'STRUCTION_SITE_SYNC',
          writable: false
        }
      })
    }
  }

  return new IntegrationType()
})
