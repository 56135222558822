import { Component, Input } from '@angular/core'
import { ObservationRiskType } from '../../models/observation-risk-type.enum'

@Component({
  selector: 'sv-observation-risk-header',
  templateUrl: 'observation-risk-header.component.html',
  styleUrls: ['observation-risk-header.component.scss'],
})
export class ObservationRiskHeaderComponent {
  @Input() riskType: any
  @Input() riskScore: any
  @Input() riskHidden: boolean

  ObservationRiskType = ObservationRiskType
}
