import { RawParams } from '@uirouter/core'

export class RouteChangeStart {
  static readonly type = '[Router] RouteChangeStart'

  constructor(public transition: RouterTransition) {}
}

export class RouteChangeSuccess {
  static readonly type = '[Router] RouteChangeSuccess'

  constructor(public transition: RouterTransition) {}
}

export interface RouterTransition {
  to: string
  toParams?: RawParams
  from: string
  fromParams?: RawParams
}
