/* global angular */

angular.module('smartvid').factory('ProcoreAlbumCollection', function (BaseCollection, ProcoreAlbumModel, smartvidApi, $interval) {
  class ProcoreAlbumCollection extends BaseCollection {
    constructor (models) {
      super(models, ProcoreAlbumModel)
    }
  }

  return ProcoreAlbumCollection
})
