/* global angular */

angular.module('smartvid').controller('AutoJoinRequestCtrl', function ($scope, $state, $stateParams, smartvidApi) {
  $scope.organizationName = $stateParams.organizationName
  $scope.userId = $stateParams.userId
  $scope.organizationId = $stateParams.organizationId

  $scope.requestMembership = () => {
    smartvidApi.autoJoinRequest($scope.organizationId, $scope.userId).then(function () {
      $state.go('welcome', {organizationName: $stateParams.organizationName})
    })
  }
})
