import { Inject, Injectable } from '@angular/core'
import { ConfigService } from './config.service'
import { HTTP } from 'shared/smartvid.types'
import { toObservable } from 'shared/utils/api-utils'
import { Observable, from } from 'rxjs'
import {
  UpdateInvitedUserProjectGroupsRequest,
  UpdateInvitedUserProjectGroupsResponse,
} from 'shared/models/projectgroup.model'

@Injectable({
  providedIn: 'root',
})
export class ProjectGroupService {
  rootUrl: string

  constructor(@Inject(HTTP) private $http: ng.IHttpService, private config: ConfigService) {
    this.rootUrl = this.config.env.development.apiRootUrl
  }

  updateInvitedUsers(
    organizationId: string,
    request: UpdateInvitedUserProjectGroupsRequest
  ): Observable<UpdateInvitedUserProjectGroupsResponse> {
    let url = this.rootUrl + `/api/organization/${organizationId}/projectGroup/user/invitation`
    return toObservable<UpdateInvitedUserProjectGroupsResponse>(
      this.$http.post(url, request),
      UpdateInvitedUserProjectGroupsResponse
    )
  }

  addGroup(organizationId: string, groupName: string): Observable<any> {
    let url = this.rootUrl + `/api/organization/${organizationId}/projectGroup`
    let request = {
      name: groupName,
    }
    return from(this.$http.post(url, request))
  }

  removeGroup(organizationId: string, projectGroupId: string): Observable<any> {
    let url = this.rootUrl + `/api/organization/${organizationId}/projectGroup/${projectGroupId}`
    return from(this.$http.delete(url))
  }

  updateGroup(organizationId: string, projectGroupId: string, groupName: string): Observable<any> {
    let url = this.rootUrl + `/api/organization/${organizationId}/projectGroup/${projectGroupId}`
    let request = {
      name: groupName,
    }
    return from(this.$http.put(url, request))
  }

  getGroup(organizationId: string, projectGroupId: string): Observable<any> {
    let url = this.rootUrl + `/api/organization/${organizationId}/projectGroup/${projectGroupId}`
    return from(this.$http.get(url))
  }
}
