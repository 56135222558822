/* global angular */

angular.module('smartvid').service('adminApi', function ($http, $q, $log, config) {
  let rootUrl = config.env.development.apiRootUrl

  let api = {
    scheduleCopyDemoProjectForAllUsers () {
      let url = `${rootUrl}/api/project/demo/copy/all`
      let defer = $q.defer()

      $http.post(url).then((response) => {
        $log.debug('scheduleCopyDemoProjectForAllUsers', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    ensureAdminMode () {
      let url = `${rootUrl}/api/admin`
      let defer = $q.defer()

      $http.head(url).then((response) => {
        $log.debug('ensureAdminMode', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    }
  }
  return api
})
