/* global angular, _ */

angular.module('smartvid').factory('ProjectMembersCollection', function ($q, BaseCollection, InvitedProjectUserModel, smartvidApi, $interval) {
  class ProjectMembersCollection extends BaseCollection {
    constructor (projectId, models) {
      super(models, InvitedProjectUserModel)

      this.projectId = projectId
      this.nextPagePromise = $q.when()
      this.lastPage = 0
      this.totalRows = 0

      this.options = {
        page: 0,
        pageSize: 50
      }

      if (!models && projectId) {
        this.initialized = false
        this.nextPage()
        this.nextPagePromise.then(() => {
          this.initialized = true
        })
      }
    }

    upsert (users) {
      users = (!_.isArray(users)) ? [users] : users
      let updated = false
      _.each(users, (user) => {
        updated = false
        for (let i = 0; i < this.models.length; i++) {
          if (this.models[i].userId === user.userId) {
            _.extend(this.models[i], user)
            updated = true
          }
        }

        if (!updated) this.add(user, InvitedProjectUserModel)
      })
    }

    removeByUserId (userId) {
      for (let i = 0; i < this.models.length; i++) {
        if (this.models[i].userId === userId) {
          this.models.splice(i, 1)
          return
        }
      }
    }

    reset () {
      this.lastPage = false
      this.nextPagePromise = $q.when()
      super.reset()
    }

    nextPage () {
      if (this.isFetching || !this.canFetch) {
        return this.nextPagePromise
      }

      this.isFetching = true
      let defer = $q.defer()
      this.nextPagePromise.then(() => {
        let apiOptions = angular.copy(this.options)
        apiOptions.page = this.lastPage
        smartvidApi.getProjectUsers(this.projectId, apiOptions).then((users) => {
          super.upsert(users, InvitedProjectUserModel)
          this.totalRows = users.totalRows
          if (_.isEmpty(users)) {
            this.canFetch = false
          } else {
            this.lastPage++
          }
          this.isFetching = false
          defer.resolve()
        }, () => {
          this.isFetching = false
          defer.reject()
        })
      }, () => {
        this.isFetching = false
        defer.reject()
      })
      this.nextPagePromise = defer.promise
      return defer.promise
    }
  }

  return ProjectMembersCollection
})
