/* global angular */

angular.module('smartvid').directive('userProfileLeftNav', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'UsersGrid/user-profile-left-nav.html',
    link: function (scope) {
    }
  }
})
