import _ from 'lodash'
import moment from 'moment'
import { Injectable, Inject } from '@angular/core'
import {
  AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK_CHART_COLUMNS,
  AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK_CHART_OPTIONS,
  CONSTRUCTION_ACTIVITY_CHART_COLUMNS,
  CONSTRUCTION_ACTIVITY_CHART_OPTIONS,
  HOUSEKEEPING_AND_STANDING_WATER_CHART_COLUMNS,
  HOUSEKEEPING_AND_STANDING_WATER_CHART_OPTIONS,
  LADDERS_PER_X_ASSETS_CHART_COLUMNS,
  LADDERS_PER_X_ASSETS_CHART_OPTIONS,
  LIFTS_PER_X_ASSETS_CHART_COLUMNS,
  LIFTS_PER_X_ASSETS_CHART_OPTIONS,
  PEOPLE_IN_GROUPS_OVERTIME_CHART_COLUMNS,
  PEOPLE_IN_GROUPS_OVERTIME_CHART_OPTIONS,
  PHOTO_DESCRIPTION_CHART_COLUMNS,
  PHOTO_DESCRIPTION_CHART_OPTIONS,
  PPE_COMPLIANCE_OVERTIME_CHART_COLUMNS,
  PPE_COMPLIANCE_OVERTIME_CHART_OPTIONS,
  PROJECT_TOTALS_CHART_DATA_COLUMNS,
  PROJECT_TOTALS_CHART_OPTIONS,
  ProjectTotalCounts,
  ProjectTotalsTableData,
  ProjectTotalsTableDataResponse,
  SLIP_AND_TRIP_OVERTIME_CHART_COLUMNS,
  SLIP_AND_TRIP_OVERTIME_CHART_OPTIONS,
  TAGS_BY_TYPE_CHART_COLUMNS,
  TAGS_BY_TYPE_CHART_OPTIONS,
  UPLOADERS_OVERTIME_CHART_COLUMNS,
  UPLOADERS_OVERTIME_CHART_OPTIONS,
  UPLOADS_BY_FILE_TYPE_CHART_COLUMNS,
  UPLOADS_BY_FILE_TYPE_CHART_OPTIONS,
  UPLOADS_OVERTIME_BY_SOURCE_CHART_COLUMNS,
  UPLOADS_OVERTIME_BY_SOURCE_CHART_OPTIONS,
  WORK_AT_HEIGHTS_OVERTIME_CHART_COLUMNS,
  WORK_AT_HEIGHTS_OVERTIME_CHART_OPTIONS,
  WORK_AT_HEIGHTS_PER_X_ASSETS_CHART_COLUMNS,
  WORK_AT_HEIGHTS_PER_X_ASSETS_CHART_OPTIONS,
} from 'modules/insights/dashboard/models/insights-dashboard-project-assets.model'
import { makeMultilineChartTooltip } from 'modules/insights/dashboard/utils/dashboard-utils'
import { HTTP } from 'shared/smartvid.types'
import { toObservable } from 'shared/utils/api-utils'
import {
  CancellableRequestSubscription,
  DashboardDataObjectType,
  DashboardDataResponse,
  DateRangeChartData,
  InsightsDashboardParameters,
  ScopeObjectType,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { first } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { InsightsDashboardServiceHelper } from 'modules/insights/dashboard/services/insights-dashboard-service-helper'
import Q from 'q'

@Injectable({
  providedIn: 'root',
})
export class InsightsDashboardProjectAssetsService {
  constructor(
    @Inject(HTTP) private $http: ng.IHttpService,
    private insightsDashboardServiceHelper: InsightsDashboardServiceHelper,
    private translate: TranslateService
  ) {}

  loadProjectTotalsData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: ProjectTotalCounts) => void
  ) {
    let url = this.insightsDashboardServiceHelper.getApiUrl(dashboardParameters.organizationId)
    let payload = InsightsDashboardServiceHelper.createDashboardRequest(
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_TOTALS,
      dashboardParameters
    )

    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardDataResponse<ProjectTotalCounts>>(
      this.$http.post(url, payload, { timeout: cancellableRequest.promise }),
      DashboardDataResponse
    )
      .pipe(first())
      .subscribe((data: DashboardDataResponse<ProjectTotalCounts>) => {
        onDataLoadedFunc(data.dashboardData[DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_TOTALS])
      })

    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  loadProjectTotalsTableData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: ProjectTotalsTableData[]) => void
  ) {
    let url = this.insightsDashboardServiceHelper.getApiUrl(dashboardParameters.organizationId)
    let payload = InsightsDashboardServiceHelper.createDashboardRequest(
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_ACTIVITY_BY_MEMBERS,
      dashboardParameters
    )

    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardDataResponse<ProjectTotalsTableDataResponse>>(
      this.$http.post(url, payload, { timeout: cancellableRequest.promise }),
      DashboardDataResponse
    )
      .pipe(first())
      .subscribe((data: DashboardDataResponse<ProjectTotalsTableDataResponse>) => {
        let tableData =
          data.dashboardData[DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_ACTIVITY_BY_MEMBERS].tableDate
        tableData.forEach(entry => {
          entry.chartData.dataTable.forEach(row => {
            row[0] = moment(new Date(row[0]))
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0)
              .set('millisecond', 0)
              .toDate()
            row.push(
              makeMultilineChartTooltip([
                moment(row[0]).format('MMM D, YYYY'),
                this.translate.instant('dashboard.projectAssets.activityTable.chartTooltip', { count: row[1] }),
              ])
            )
          })
          entry.chartData.dataTable = [
            [...this.insightsDashboardServiceHelper.translateStringsIn(PROJECT_TOTALS_CHART_DATA_COLUMNS)],
            ...entry.chartData.dataTable,
          ]
          entry.chartData.options = { ..._.cloneDeep(PROJECT_TOTALS_CHART_OPTIONS) }
        })
        onDataLoadedFunc(tableData)
      })

    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  loadUploadsOverTimeBySourceData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleDateBasedChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_UPLOADS_BY_SOURCE,
      UPLOADS_OVERTIME_BY_SOURCE_CHART_COLUMNS,
      true,
      UPLOADS_OVERTIME_BY_SOURCE_CHART_OPTIONS,
      onDataLoadedFunc
    )
  }

  loadUploadersOverTimeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleDateBasedChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_UPLOADERS_OVER_TIME,
      UPLOADERS_OVERTIME_CHART_COLUMNS,
      false,
      UPLOADERS_OVERTIME_CHART_OPTIONS,
      onDataLoadedFunc
    )
  }

  loadConstructionActivityData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_CONSTRUCTION_ACTIVITY,
      CONSTRUCTION_ACTIVITY_CHART_COLUMNS,
      CONSTRUCTION_ACTIVITY_CHART_OPTIONS,
      onDataLoadedFunc
    )
  }

  loadPhotoDescriptionData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_PHOTO_DESCRIPTION,
      PHOTO_DESCRIPTION_CHART_COLUMNS,
      PHOTO_DESCRIPTION_CHART_OPTIONS,
      onDataLoadedFunc
    )
  }

  loadUploadsByFileTypeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_UPLOADS_BY_FILE_TYPE,
      UPLOADS_BY_FILE_TYPE_CHART_COLUMNS,
      UPLOADS_BY_FILE_TYPE_CHART_OPTIONS,
      onDataLoadedFunc
    )
  }

  loadTagsByTypeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_TAGS_BY_TYPE,
      TAGS_BY_TYPE_CHART_COLUMNS,
      TAGS_BY_TYPE_CHART_OPTIONS,
      onDataLoadedFunc
    )
  }

  loadPeopleInGroupsOverTimeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleDateBasedChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_PEOPLE_IN_GROUPS,
      PEOPLE_IN_GROUPS_OVERTIME_CHART_COLUMNS,
      false,
      PEOPLE_IN_GROUPS_OVERTIME_CHART_OPTIONS,
      onDataLoadedFunc
    )
  }

  loadPpeComplianceTrendOverTimeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadStandardPpeComplianceTrendOverTimeData(
      dashboardParameters,
      onDataLoadedFunc,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_PPE_COMPLIANCE_OVER_TIME,
      ScopeObjectType.PROJECT,
      PPE_COMPLIANCE_OVERTIME_CHART_COLUMNS,
      PPE_COMPLIANCE_OVERTIME_CHART_OPTIONS,
      'dashboard.projectAssets.ppeComplianceOverTimeChart.columns.tooltip.compliance',
      'dashboard.projectAssets.ppeComplianceOverTimeChart.columns.tooltip.complianceCutoff'
    )
  }

  loadSlipAndTripTrendOverTimeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    // TODO: PL: We always return 3 data points here and they can be not equidistant in time
    //  so for now just hardcode the X-axis labels
    let funcWrapper = (data: DateRangeChartData) => {
      this.insightsDashboardServiceHelper.adjustDataForNPointsChartWithEqualXAxisTicksSpacing(
        data,
        dashboardParameters,
        30
      )
      onDataLoadedFunc(data)
    }

    return this.insightsDashboardServiceHelper.loadSimpleDateBasedChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_SLIP_AND_TRIP_OVER_TIME,
      SLIP_AND_TRIP_OVERTIME_CHART_COLUMNS,
      true,
      SLIP_AND_TRIP_OVERTIME_CHART_OPTIONS,
      funcWrapper
    )
  }

  loadWorkAtHeightsTrendOverTimeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    // TODO: PL: We always return 3 data points here and they can be not equidistant in time
    //  so for now just hardcode the X-axis labels
    let funcWrapper = (data: DateRangeChartData) => {
      this.insightsDashboardServiceHelper.adjustDataForNPointsChartWithEqualXAxisTicksSpacing(
        data,
        dashboardParameters,
        30
      )
      onDataLoadedFunc(data)
    }

    return this.insightsDashboardServiceHelper.loadSimpleDateBasedChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_OVER_TIME,
      WORK_AT_HEIGHTS_OVERTIME_CHART_COLUMNS,
      true,
      WORK_AT_HEIGHTS_OVERTIME_CHART_OPTIONS,
      funcWrapper
    )
  }

  loadWorkAtHeightsPerXAssetsData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_PER_X_ASSETS,
      WORK_AT_HEIGHTS_PER_X_ASSETS_CHART_COLUMNS,
      WORK_AT_HEIGHTS_PER_X_ASSETS_CHART_OPTIONS,
      onDataLoadedFunc
    )
  }

  loadLaddersPerXAssetsData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_LADDERS_PER_X_ASSETS,
      LADDERS_PER_X_ASSETS_CHART_COLUMNS,
      LADDERS_PER_X_ASSETS_CHART_OPTIONS,
      onDataLoadedFunc
    )
  }

  loadLiftsPerXAssetsData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_LIFTS_PER_X_ASSETS,
      LIFTS_PER_X_ASSETS_CHART_COLUMNS,
      LIFTS_PER_X_ASSETS_CHART_OPTIONS,
      onDataLoadedFunc
    )
  }

  loadHousekeepingAndStandingWaterData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_HOUSEKEEPING_AND_STANDING_WATER,
      HOUSEKEEPING_AND_STANDING_WATER_CHART_COLUMNS,
      HOUSEKEEPING_AND_STANDING_WATER_CHART_OPTIONS,
      onDataLoadedFunc
    )
  }

  loadAvgPhotosCreatedByDayOfWeekData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.PROJECT_ASSETS_DATE_RANGE_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK,
      AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK_CHART_COLUMNS,
      AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK_CHART_OPTIONS,
      onDataLoadedFunc
    )
  }
}
