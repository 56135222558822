/* global angular */

angular.module('smartvid').service('oxBlueIntegrationApi', function ($http, $q, $log, config, currentUser) {
  let rootUrl = config.env.development.apiRootUrl

  let api = {
    listOxBlueCamerasInfo (projectId, includeInactive, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/oxblue/camera?includeInactive=${includeInactive}`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('listOxBlueCamerasInfo', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    loginInToOxBlue (userLogin, password, customErrorHandler) {
      let url = `${rootUrl}/api/integration/oxblue/login`
      let defer = $q.defer()

      let payload = {userId: currentUser.id, oxBlueUserId: userLogin, password: password}

      $http.post(url, payload, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('loginInToOxBlue', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    }
  }

  return api
})
