/* global angular, _ */

angular.module('smartvid').controller('AddTagCtrl', function (MAX_TAG_DEF_TEXT_LENGTH, $rootScope, $scope, smartvidApi, $filter, modal) {
  let isEditTag = angular.isDefined($scope.editTagNode)
  let tagDef = (isEditTag) ? $scope.treeModel.getTagDefinition($scope.editTagNode.id) : undefined

  $scope.MAX_TAG_DEF_TEXT_LENGTH = MAX_TAG_DEF_TEXT_LENGTH
  $scope.cvDiscoverable = true
  $scope.removedAliases = []
  $scope.addedAliases = []
  $scope.isAllowAsr = true
  $scope.isAllowImrec = true
  $scope.isAllowManual = true
  $scope.tags = $scope.treeModel.getTagDefs()
  if (isEditTag) {
    $scope.treeModel.getTagDefinition($scope.editTagNode.id).then((tagDefWithAliases) => {
      $scope.addedAliases = tagDefWithAliases.aliases.slice()
      $scope.tagName = tagDefWithAliases.text
      $scope.isAllowAsr = tagDefWithAliases.isAllowAsr
      $scope.isAllowImrec = tagDefWithAliases.isAllowImrec
      $scope.isAllowManual = tagDefWithAliases.isAllowManual
    })
  }

  $scope.saveTag = saveTag
  $scope.checkTagCvDiscoverable = _.debounce(checkTagCvDiscoverable, 200)

  function checkTagCvDiscoverable (tag) {
    if (!_.isEmpty(tag)) {
      smartvidApi.checkCvTagDiscoverable(tag).then(function (response) {
        $scope.$evalAsync(() => { $scope.cvDiscoverable = response })
      })
    } else {
      $scope.cvDiscoverable = true
    }
  }

  /**
   * Called from add-tag modal form submit
   * @param editTag
   */
  function saveTag () {
    let promise = getSavePromise()
    promise.then(() => {
      modal.close()
    }, () => {})
  }

  function getSavePromise () {
    if (isEditTag) {
      let updateAliases = _.difference($scope.addedAliases, tagDef.aliases)
      return $scope.treeModel.updateTag($scope.editTagNode.id, $scope.tagName,
            $scope.isAllowAsr, $scope.isAllowImrec, $scope.isAllowManual,
            updateAliases, $scope.removedAliases)
    } else {
      return $scope.treeModel.createTag($scope.tagName, $scope.isAllowAsr,
        $scope.isAllowImrec, $scope.isAllowManual,
        $scope.addedAliases, $scope.parentTagNode, false)
    }
  }
})

