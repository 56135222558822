/* global angular,_ */

angular.module('smartvid').service('apiUtils', function (AssetSearchContext, SearchEntityModel, SEARCH_ENTITY_MODEL_TYPE_TAG_DEFINITION) {
  this.convertSearchResponseToAssetSearchContext = convertSearchResponseToAssetSearchContext

  function convertSearchResponseToAssetSearchContext (data) {
    let result = data
    let searchTags = _.map(result.searchTags, function (searchTag) {
      return new SearchEntityModel({
        id: searchTag.tagDefinition.id,
        text: searchTag.tagDefinition.text,
        type: SEARCH_ENTITY_MODEL_TYPE_TAG_DEFINITION,
        hasChildren: searchTag.includeChildren
      })
    })

    result.assetSearchContext = new AssetSearchContext(
      searchTags, result.searchCreatedByUsers, result.freeTextFragments, undefined, result.tagConfidenceLevel,
      result.searchDateRange, result.userTimeZone, result.searchQueryType, result.includedProjectIds)
    return result
  }
})
