/* global angular */

angular.module('smartvid').directive('svValidPasswordRemote', function ($q, $timeout) {
  return {
    restrict: 'A',
    replace: false,
    require: 'ngModel',
    link: (scope, element, attrs, ngModel) => {
      ngModel.$asyncValidators.svValidPasswordRemote = function (email) {
        let defer = $q.defer()

        /* TODO: need api call/change for valid user password check
         api.authenticatePassword(email).then((data) => {
         // success
         defer.resolve('woohoo')
         },(data) => {
         // error
         defer.reject('oh no')
         })
         */

        // success
        $timeout(() => {
          defer.resolve('woohoo')
        })

        // error
        $timeout(() => {
          // defer.reject('oh no')
        })

        return defer.promise
      }
    }
  }
})
