/* global angular */
/**
 * Created by ryanrubbico on 7/5/17.
 */
// Custom Hover class handler to avoid stuck 'hover' CSS class that happens on browser's drag'n drop
angular.module('smartvid').directive('treeNodeHoverClass', function () {
  return {
    restrict: 'A',
    link: function (scope, element) {
      element.hover(function () {
        addApplicableClass()
      }, function () {
        removeAllHoverClasses()
      })

      function addApplicableClass () {
        if (scope.node.canUpdate) {
          element.addClass('hovering')
        } else {
          element.addClass('hovering-no-update')
        }
      }

      function removeAllHoverClasses () {
        element.removeClass('hovering')
        element.removeClass('hovering-no-update')
      }
    }
  }
})
