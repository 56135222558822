/* global angular analytics _ */

angular.module('smartvid').controller('CreateUserCtrl', function (
    MAX_USER_EMAIL_LENGTH, $scope, $rootScope, $filter, $stateParams, smartvidApi, $state, $timeout, currentUser,
    emailUtils, errorUtils, $q
) {
  $scope.orgRoles = currentUser.getCreatableOrganizationRoles($stateParams.organizationId)
  $scope.selectedUsers = []
  $scope.inTransit = false
  $scope.orgUserEmails = _.pluck($scope.users.models, 'email')

  $scope.searchByEmail = searchByEmail
  $scope.getAllUsers = getAllUsers
  $scope.validateEmailFn = validateEmailFn
  $scope.sendUserCreateRequest = sendUserCreateRequest

  function sendUserCreateRequest () {
    $scope.inTransit = true
    trackUserCreateAnalytics($scope.selectedUsers)
    let requestUsers = _($scope.selectedUsers).map((u) => {
      return {
        email: u.email,
        role: u.role.name
      }
    }).value()
    let promise = smartvidApi.createUser($stateParams.organizationId, requestUsers)
    promise.then((data) => {
      $timeout(function () {
        $scope.inTransit = false
        $rootScope.$broadcast('sv-user-created', data)
        $scope.modal.close()
      }, 100)
    }, (data) => {
      $scope.inTransit = false
    })
    return promise
  }

  function trackUserCreateAnalytics (users) {
    _.forEach(users || [], (u) => {
      analytics.track('Create Org User', {
        category: 'Organization Action',
        orgName: currentUser.getOrganization($stateParams.organizationId).name
      })
    })
  }

  function validateEmail (email) {
    let defer = $q.defer()
    if (!emailUtils.isValidEmail(email)) {
      defer.reject({status: '', message: $filter('i18next')('directives.createUser.userInvalidEmail')})
    } else {
      defer.resolve(email)
    }
    return defer.promise
  }

  function isUserInOrg (email) {
    let defer = $q.defer()
    smartvidApi.getUsersByPartialEmailForOrg($stateParams.organizationId, email).then(users => {
      if (users.length !== 0) {
        defer.reject($filter('i18next')('directives.createUser.userAlreadyBelongsToOrganization'))
      } else {
        defer.resolve(email)
      }
    })
    return defer.promise
  }

  function isUserActive (email) {
    let defer = $q.defer()
    smartvidApi.getUserByEmail(email).then((user) => {
      if (user.id && !user.isActive && !user.isInvited) {
        defer.reject($filter('i18next')('directives.userPool.userDeactivated'))
      } else {
        defer.resolve(email)
      }
    })
    return defer.promise
  }

  function validateEmailFn (email) {
    if (!email) {
      return $q.when()
    }
    let defer = $q.defer()
    validateEmail(email).then(isUserInOrg).then(isUserActive).then(() => {
      defer.resolve()
    }, (error) => {
      defer.reject(error)
    })
    return defer.promise
  }

  function searchByEmail (partialEmail) {
    let promises = []
    let orgIds = _.without(_.pluck(_.where(currentUser.organizations, {canManageUsers: true}), 'id'), $stateParams.organizationId)
    promises.push(smartvidApi.getUsersByPartialEmailForOrgs(orgIds, partialEmail))
    return $q.all(promises)
  }

  function getAllUsers () {
    let promises = []
    _.each(currentUser.organizations, (org) => {
      if (org.id !== $stateParams.organizationId) {
        promises.push(smartvidApi.getOrganizationUsers(org.id))
      }
    })
    return $q.all(promises)
  }
})
