import { Injectable, Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { NGXLogger } from 'ngx-logger'
import jstz from 'jstz'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http'
import { CurrentUser, CURRENT_USER } from 'shared/smartvid.types'

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private logger: NGXLogger, @Inject(CURRENT_USER) private currentUser: CurrentUser) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      })
    }
    if (!request.headers.has('X-Auth-Token')) {
      request = request.clone({
        headers: request.headers.set('X-Auth-Token', this.currentUser.token),
      })
    }
    request = request.clone({
      headers: request.headers.set('X-User-Smartvid-TimeZone', this.getTimeZone()),
    })
    this.logger.debug('Before making an api request: ', request)
    return next.handle(request).pipe(
      tap(
        event => {
          //logging the http response to browser's console in case of a success
          if (event instanceof HttpResponse) {
            this.logger.debug('api call success :', event)
          }
        },
        error => {
          //logging the http response to browser's console in case of a failuer
          if (error instanceof HttpResponse) {
            this.logger.error('api call error :', error)
          }
        }
      )
    )
  }

  getTimeZone(): string {
    return jstz.determine().name()
  }
}
