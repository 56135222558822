/* global angular */

angular.module('smartvid').directive('preventSpacebarScroll', function ($window) {
  return {
    restrict: 'A',
    link: function (scope, elem) {
      elem.attr('tabindex', 1) // allow element to gain focus
      elem.css('outline', 'none') // prevent browser implemented outline
      elem.keydown(function (e) {
        if (e.keyCode === 32 && e.target === elem[0]) {
          e.preventDefault() // prevent the default scroll
        }
      })
    }
  }
})
