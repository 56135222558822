/* global angular */

angular.module('smartvid').directive('formFieldNewPassword', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'forms/form-fields/form-field-new-password.html',
    link ($scope, $element, $attrs) {
    }
  }
})
