/* global angular, _ */

angular.module('smartvid').service('tagsApi', function ($http, $q, $log, $injector, assetsApi, config, TagDefModel, TagInstanceCollection,
    TagInstanceModel, TagTreeNodeModel, currentUser, tagConfidenceApiUtils) {
  let rootUrl = config.env.development.apiRootUrl

  function updateTimeErrorHandler (data) {
    $log.info('Error updating tag tree, has it been deleted?')
    return true
  }

  let api = {
    createAccountTag (tag) {
      var data = {
        text: tag,
        organizationId: currentUser.organizationId
      }
      return $http.post(`${rootUrl}/api/tagdef`, data).then((response) => {
        $log.debug('createAccountTag', response)
      })
    },
    createProjectTag (tag, projectId, isAllowAsr, isAllowImrec, isAllowManual, addedAliases = [], customErrorHandler) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef`
      let config = {
        text: tag,
        projectId: projectId,
        isAllowAsr: isAllowAsr,
        isAllowImrec: isAllowImrec,
        isAllowManual: isAllowManual,
        addedAliases: addedAliases
      }

      $http.post(url, config, {customErrorHandler: customErrorHandler}).then(function (response) {
        $log.debug('createProjectTag', response.data)
        defer.resolve(new TagDefModel(response.data))
      }, () => {
        defer.reject()
      })

      return defer.promise
    },
    createOrgTag (tag, orgId, isAllowAsr, isAllowImrec, isAllowManual, addedAliases = []) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef`
      let config = {
        text: tag,
        organizationId: orgId,
        isAllowAsr: isAllowAsr,
        isAllowImrec: isAllowImrec,
        isAllowManual: isAllowManual,
        addedAliases: addedAliases
      }

      $http.post(url, config).then(function (response) {
        $log.debug('createProjectTag', response.data)
        defer.resolve(new TagDefModel(response.data))
      }, () => {
        defer.reject()
      })

      return defer.promise
    },
    /*
     *  Expected object
     *  tagOjb = {
     assetId: required,
     tagDefinitionId: required,
     startTime: optional,
     endTime: optional,
     text: required
     }
     */
    createTagInstance (projectId, payload) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/taginst`

      _.assign(payload, {projectId: projectId})

      $http.post(url, payload).then(function (response) {
        let tagInstance = new TagInstanceModel(response.data)
        $log.debug('createTagInstance', tagInstance)
        defer.resolve(tagInstance)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    createTagInstanceDefAndNode (projectId, payload) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/taginst/tagdef`

      _.assign(payload, {projectId: projectId})

      $http.post(url, payload).then(function (response) {
        let tagInstance = new TagInstanceModel(response.data)
        $log.debug('createTagInstanceDefAndNode', tagInstance)
        defer.resolve(tagInstance)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    createProjectTagTreeNode (projectId, payload) {
      let url = `${rootUrl}/api/tagdef/tree/project/${projectId}/node`
      let defer = $q.defer()

      $http.post(url, payload).then((response) => {
        $log.debug('createTagTreeNode', response)
        defer.resolve(new TagTreeNodeModel(response.data))
      }, (response) => {
        defer.reject(response.data)
      })

      return defer.promise
    },
    createOrgTagTreeNode (orgId, payload) {
      let url = `${rootUrl}/api/tagdef/tree/organization/${orgId}/node`
      let defer = $q.defer()

      $http.post(url, payload).then((response) => {
        $log.debug('createTagTreeNode', response)
        defer.resolve(new TagTreeNodeModel(response.data))
      }, (response) => {
        defer.reject(response.data)
      })

      return defer.promise
    },
    deleteTagNode (projectId, tagDefinitionId) {
      let url = `${rootUrl}/api/tagdef/tree/project/${projectId}/node/${tagDefinitionId}`
      let defer = $q.defer()

      $http.delete(url).then(function (response) {
        $log.debug('deleteTagNode', response.data)
        defer.resolve(tagDefinitionId)
      })

      return defer.promise
    },
    deleteOrgTagNode (orgId, tagDefinitionId) {
      let url = `${rootUrl}/api/tagdef/tree/organization/${orgId}/node/${tagDefinitionId}`
      let defer = $q.defer()

      $http.delete(url).then(function (response) {
        $log.debug('deleteTagNode', response.data)
        defer.resolve(tagDefinitionId)
      })

      return defer.promise
    },
    deleteTagDefinition (tagDefinitionId) {
      let url = `${rootUrl}/api/tagdef/${tagDefinitionId}`
      let defer = $q.defer()

      $http.delete(url).then((response) => {
        $log.debug('deleteTagDefinition', response.data)
        defer.resolve(tagDefinitionId)
      })

      return defer.promise
    },
    deleteTagDefinitions (multiTagDefinitionSelection) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef/batch/job/delete`

      $http.put(url, multiTagDefinitionSelection).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    destroyTagInstance (tagId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/taginst/${tagId}`

      $http.delete(url).then(function (response) {
        $log.debug('destroyTagInstance', response)
        defer.resolve(response)
      })

      return defer.promise
    },
    getProjectTags (projectId, includeOrgTags, partialText, limit = undefined) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef/project/${projectId}?includeOrgTags=${includeOrgTags}`
      if (angular.isDefined(partialText)) {
        url = `${url}&partialText=${encodeURIComponent(partialText)}`
      }
      if (angular.isDefined(limit)) {
        url = `${url}&limit=${encodeURIComponent(limit)}`
      }
      $http.get(url).then(function (response) {
        $log.debug('getProjectTags', response.data)
        defer.resolve(response.data)
      }, (response) => {
        defer.reject(response)
      })

      return defer.promise
    },
    getOrgTags (orgId, partialText = undefined, limit = undefined) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef/organization/${orgId}?`
      if (angular.isDefined(partialText)) {
        url = `${url}partialText=${encodeURIComponent(partialText)}`
      }
      if (angular.isDefined(limit)) {
        url = `${url}limit=${encodeURIComponent(limit)}`
      }

      $http.get(url).then(function (response) {
        $log.debug('getOrgTags', response.data)
        defer.resolve(response.data)
      }, (response) => {
        defer.reject(response)
      })

      return defer.promise
    },
    getProjectAndParentOrgTags (
        orgId, projectId, includeAccountTags = true, isAllowAsr = undefined, isAllowImrec = undefined,
        isAllowManual = undefined, partialText = undefined, limit = undefined) {
      let defer = $q.defer()

      let url = `${rootUrl}/api/tagdef/organization/${orgId}/project/${projectId}?`

      if (angular.isDefined(isAllowAsr)) {
        url = `${url}isAllowAsr=${isAllowAsr}&`
      }
      if (angular.isDefined(isAllowImrec)) {
        url = `${url}isAllowImrec=${isAllowImrec}&`
      }
      if (angular.isDefined(isAllowManual)) {
        url = `${url}isAllowManual=${isAllowManual}&`
      }
      if (angular.isDefined(partialText)) {
        url = `${url}partialText=${encodeURIComponent(partialText)}&`
      }
      if (angular.isDefined(limit)) {
        url = `${url}limit=${encodeURIComponent(limit)}&`
      }

      $http.get(url).then(function (response) {
        $log.debug('getProjectAndParentOrgTags', response.data)
        defer.resolve(response.data)
      }, (response) => {
        defer.reject(response)
      })

      return defer.promise
    },
    getTagWithAlias (tagId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef/${tagId}?withAliases=true`

      $http.get(url).then(function (response) {
        $log.debug('getTagWithAlias', response.data)
        defer.resolve(new TagDefModel(response.data))
      }, (response) => {
        defer.reject(response)
      })

      return defer.promise
    },
    getCommonTagDefinitionsForAssets (projectId, assetIds) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef/project/${projectId}/assets/common`

      let payload = {
        assetIds: assetIds
      }

      $http.post(url, payload).then(function (response) {
        let tagDefinions = _.map(response.data, r => new TagDefModel(r))
        $log.debug('getCommonTagDefinitionsForAssets', tagDefinions)
        defer.resolve(tagDefinions)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    /*
     *  Expected object
     *  tagOjb = {
     assetId: required,
     tagDefinitionId: required,
     startTime: optional,
     endTime: optional,
     text: required
     }
     */
    getTagDefinitionTree (projectId, includeOrgTags) {
      let url = `${rootUrl}/api/tagdef/tree/project/${projectId}?includeOrgTags=${includeOrgTags}`
      let defer = $q.defer()

      $http.get(url).then(function (response) {
        $log.debug('getTagDefinitionTree', response)
        defer.resolve(response.data)
      })

      return defer.promise
    },
    getTagDefinitionTreeForOrg (orgId) {
      let url = `${rootUrl}/api/tagdef/tree/organization/${orgId}`
      let defer = $q.defer()

      $http.get(url).then(function (response) {
        $log.debug('getTagDefinitionTreeForOrg', response)
        defer.resolve(response.data)
      })

      return defer.promise
    },
    getCombinedTagDefinitionTreeForOrgAndProject (orgId, projectId) {
      let url = `${rootUrl}/api/tagdef/tree/organization/${orgId}/project/${projectId}`
      let defer = $q.defer()

      $http.get(url).then(function (response) {
        $log.debug('getCombinedTagDefinitionTreeForOrgAndProject', response)
        defer.resolve(response.data)
      })

      return defer.promise
    },
    getTagsForAsset (assetId, tagConfidenceLevel) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/taginst/asset/${assetId}`

      if (angular.isUndefined(assetId)) {
        defer.resolve()
        return defer.promise
      }

      var request = tagConfidenceApiUtils.createGetRequest(url, tagConfidenceLevel)

      $http(request).then(function (response) {
        let tagInstanceCollection = new TagInstanceCollection()
        if (response && response.data) {
          tagInstanceCollection.add(response.data, TagInstanceModel)
          $log.debug('getTagsForAsset', tagInstanceCollection)
        }

        defer.resolve(tagInstanceCollection)
      }, () => { defer.reject() })

      return defer.promise
    },
    moveProjectTagNode (projectId, payload) { // payload details http://cl.ly/dUPJ
      let url = `${rootUrl}/api/tagdef/tree/project/${projectId}/node`

      let defer = $q.defer()

      $http.put(url, payload).then((response) => {
        $log.debug('moveTagNode', response.data)
        let result = _.map(response.data, (nodeData) => {
          return new TagTreeNodeModel(nodeData)
        })
        defer.resolve(result)
      })

      return defer.promise
    },
    moveOrgTagNode (orgId, payload) { // payload details http://cl.ly/dUPJ
      let url = `${rootUrl}/api/tagdef/tree/organization/${orgId}/node`

      let defer = $q.defer()

      $http.put(url, payload).then((response) => {
        $log.debug('moveTagNode', response.data)
        let result = _.map(response.data, (nodeData) => {
          return new TagTreeNodeModel(nodeData)
        })
        defer.resolve(result)
      })

      return defer.promise
    },
    updateTagDefinition (tagId, tagName, isAllowAsr, isAllowImrec, isAllowManual, addedAliases = [], removedAliases = []) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef`
      let params = {
        id: tagId,
        text: tagName,
        isAllowAsr: isAllowAsr,
        isAllowImrec: isAllowImrec,
        isAllowManual: isAllowManual,
        addedAliases: addedAliases,
        removedAliases: removedAliases
      }

      $http.put(url, params).then(function (response) {
        $log.debug('updateTagDefinition', response.data)
        defer.resolve(new TagDefModel(response.data))
      })

      return defer.promise
    },
    updateTagForAsset (tagInstance, customErrorHandler) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/taginst`

      $http.put(url, tagInstance, {customErrorHandler: customErrorHandler}).then(function (response) {
        $log.debug('updateTagForAsset', response.data)
        defer.resolve(response.data)
      })

      return defer.promise
    },
    importTagsForProject (projectId, file) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef/project/${projectId}/import`

      let fd = new window.FormData()
      fd.append('file', file)

      $http.post(url, fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      }).then(function (response) {
        // success
        $log.debug('importTagsForProject', response.data)
        defer.resolve(response.data)
      }, (response) => {
        // failure
        $log.debug('importTagsForProject', response.data)
        defer.reject(response.data)
      })
      return defer.promise
    },
    importTagsForOrg (orgId, file) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef/org/${orgId}/import`

      let fd = new window.FormData()
      fd.append('file', file)

      $http.post(url, fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      }).then(function (response) {
        // success
        $log.debug('importTagsForOrg', response.data)
        defer.resolve(response.data)
      }, (response) => {
        // failure
        $log.debug('importTagsForOrg', response.data)
        defer.reject(response.data)
      })
      return defer.promise
    },
    createTagExportPackageForProject (packageId, projectId, type = 'TAG_TREE_EXPORT') {
      let defer = $q.defer()
      let url = `${rootUrl}/api/project/${projectId}/download/package?type=${type}`

      let payload = {
        packageItemInfo: [{
          packageItemId: packageId,
          packageItemType: type
        }]
      }

      $http.post(url, payload).then(function (response) {
        // success
        defer.resolve(response.data)
      }, function (response) {
        // error
        defer.reject(response)
      }).catch(function (response) {
        // error
        defer.reject(response)
      })

      return defer.promise
    },
    getTagExportPackageStatusForProject (projectId, packageId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/project/${projectId}/download/package/${packageId}/type/TAG_TREE_EXPORT/status`

      $http.get(url).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },
    downloadTagExportPackageForProject (projectId, packageId, type = 'TAG_TREE_EXPORT') {
      let defer = $q.defer()
      let url = `${rootUrl}/api/project/${projectId}/download/package/${packageId}/type/${type}`

      $http.get(url).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },
    createTagExportPackageForOrg (packageId, orgId, type = 'TAG_TREE_EXPORT') {
      let defer = $q.defer()
      let url = `${rootUrl}/api/org/${orgId}/download/package?type=${type}`

      let payload = {
        packageItemInfo: [{
          packageItemId: packageId,
          packageItemType: type
        }]
      }

      $http.post(url, payload).then(function (response) {
        // success
        defer.resolve(response.data)
      }, function (response) {
        // error
        defer.reject(response)
      }).catch(function (response) {
        // error
        defer.reject(response)
      })

      return defer.promise
    },
    getTagExportPackageStatusForOrg (orgId, packageId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/org/${orgId}/download/package/${packageId}/type/TAG_TREE_EXPORT/status`

      $http.get(url).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },
    downloadTagExportPackageForOrg (orgId, packageId, type = 'TAG_TREE_EXPORT') {
      let defer = $q.defer()
      let url = `${rootUrl}/api/org/${orgId}/download/package/${packageId}/type/${type}`

      $http.get(url).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },
    getAssetCountByTagDefsForProject (projectId, tagDefIds) {
      let defer = $q.defer()
      let type = 'BY_TAG_DEF_IDS'
      let url = `${rootUrl}/api/assets/count/project/${projectId}?type=${type}`

      let payload = {
        tagDefIds: tagDefIds,
        includeSubtrees: true
      }

      $http.post(url, payload).then(function (response) {
        defer.resolve(response.data.value)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },
    getAssetCountByTagDefsForOrg (orgId, tagDefIds) {
      let defer = $q.defer()
      let type = 'BY_TAG_DEF_IDS'
      let url = `${rootUrl}/api/assets/count/organization/${orgId}?type=${type}`

      let payload = {
        tagDefIds: tagDefIds,
        includeSubtrees: true
      }

      $http.post(url, payload).then(function (response) {
        defer.resolve(response.data.value)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },
    getTagInstancesCountsGroupedByTypeForProject (projectId, tagDefId) {
      let defer = $q.defer()

      let url = `${rootUrl}/api/tagdef/project/${projectId}/details/${tagDefId}`

      $http.get(url).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },
    getTagInstancesCountsGroupedByTypeForOrg (orgId, tagDefId) {
      let defer = $q.defer()

      let url = `${rootUrl}/api/tagdef/organization/${orgId}/details/${tagDefId}`

      $http.get(url).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },
    checkCvTagDiscoverable (tagText) {
      let defer = $q.defer()

      let url = `${rootUrl}/api/tagdef/cv/discoverable`

      let params = {
        partialTag: tagText
      }

      $http.get(url, {params: params}).then(function (response) {
        defer.resolve(response.data.value)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },
    getOrgTagsUpdateTime (orgId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef/organization/${orgId}/updateTime`
      $http.get(url).then(function (response) {
        defer.resolve(response.data.value)
      }, function (response) {
        defer.reject(response)
      })
      return defer.promise
    },
    getProjectTagsUpdateTime (projectId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef/project/${projectId}/updateTime`
      $http.get(url).then(function (response) {
        defer.resolve(response.data.value)
      }, function (response) {
        defer.reject(response)
      })
      return defer.promise
    },
    getOrgTagsTreeUpdateTime (orgId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef/tree/organization/${orgId}/updateTime`
      $http.get(url, {customErrorHandler: updateTimeErrorHandler}).then(function (response) {
        defer.resolve(response.data.value)
      }, function (response) {
        defer.reject(response)
      })
      return defer.promise
    },
    getProjectTagsTreeUpdateTime (projectId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/tagdef/tree/project/${projectId}/updateTime`
      $http.get(url, {customErrorHandler: updateTimeErrorHandler}).then(function (response) {
        defer.resolve(response.data.value)
      }, function (response) {
        defer.reject(response)
      })
      return defer.promise
    }
  }

  return api
})
