/* global angular */

angular.module('smartvid').directive('radioButton', () => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'graphics/radio-button.html',
    scope: {
      click: '&',
      selected: '=isSelected',
      buttonClass: '='
    },
    link: (scope) => {
      scope.hover
    }
  }
})
