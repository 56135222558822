/* global angular */
angular.module('smartvid').directive('preload', function ($timeout) {
  return {
    restrict: 'C',
    link (scope, elem) {
      $timeout(() => {
        elem.removeClass('preload')
      }, 0)
    }
  }
})
