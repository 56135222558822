import { EMPTY, forkJoin, from, merge, Observable, ReplaySubject, Subject, Subscription, timer } from 'rxjs'
import { NGXLogger } from 'ngx-logger'
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  skip,
  startWith,
  switchMap,
} from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { TradePartner } from '../../models/tradepartner.model'
import { TradePartnerService } from '../../services/tradepartner.service'
import { DashboardDataHelperService } from 'modules/core/services/dashboard-data-helper.service'
import { UiConfigurationService } from 'modules/core/services/ui-configuration.service'
import { ObservationService } from '../../services/observation.service'

import {
  ObservationPredefinedFields,
  UiConfiguration,
  UiConfigurationFieldInfo,
  UiConfigurationListFieldData,
} from 'shared/models/ui-configuration.model'
import {
  CURRENT_USER,
  CurrentUser,
  FILE_UPLOAD_SERVICE,
  FileUploadService,
  SMARTVID_API,
  SmartvidApi,
  UTILS,
  Utils,
} from 'shared/smartvid.types'
import { ObservationType } from '../../models/observation-type.enum'
import { FileItem, FileLikeObject, FileUploader, FileUploaderOptions, FilterFunction } from 'ng2-file-upload'
import * as _ from 'lodash'
import { ObservationStatus } from '../../models/observation-status.enum'
import { Observation, ObservationCreateRequest, ObservationUpdateRequest } from '../../models/observation.model'
import { ObservationRiskType } from '../../models/observation-risk-type.enum'
import { Store } from '@ngxs/store'
import { CreateObservation, ObservationUpdated, ReloadObservations } from 'modules/state'
import { DialogUtilsService, noWhitespaceValidator } from 'modules/core/services/dialog-utils.service'
import { StateService } from '@uirouter/core'
import { ProjectListPipe } from 'angular2/modules/admin/components/navigation-panel/navigation-panel.component'
import { ConfirmDialogComponent } from 'shared/components/confirm-dialog/confirm-dialog.component'
import { ObservationExternalIdPipe } from 'modules/observations/components/observation-list/observation-list.component'
import { UiConfigurationFieldType } from '../../../../shared/models/ui-configuration.model'
import { CustomFieldValue } from '../../../../shared/models/custom-field'
import { FieldDataType } from '../../models/field-data-type.enum'
import { CustomValidators } from 'ng2-validation'
import { ObservationUiConfigurationSupport } from '../../common/observation-ui-configuration-support'
import { ModuleType } from 'shared/models/module-type'

export class ObservationDialogData {
  organizationId: string
  projectId: string
  observation: Observation
  observationType: ObservationType
  externalAssets: any
  uiConfiguration: UiConfiguration
}

@Component({
  selector: 'sv-observation-dialog',
  templateUrl: 'observation-dialog.component.html',
  styleUrls: ['observation-dialog.component.scss'],
})
export class ObservationDialogComponent extends ObservationUiConfigurationSupport implements OnInit, OnDestroy {
  uploadFilesLimit = 10
  allowedFileExtensions: string[]
  uploadOptions: FileUploaderOptions
  uploader: FileUploader

  observationAssets: any[]
  addedAssetIds: any[] = []
  removedAssetIds: any[] = []
  registeringAssets: boolean
  uploadingToS3: boolean
  isLoading: boolean
  s3Items: any[] = []
  UiConfigurationFieldType = UiConfigurationFieldType
  uploadStatusSubscription
  ObservationPredefinedFields = ObservationPredefinedFields

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private logger: NGXLogger,
    private translate: TranslateService,
    private tradePartnerApi: TradePartnerService,
    private uiConfigApi: UiConfigurationService,
    private observationService: ObservationService,
    private dialogRef: MatDialogRef<ObservationDialogComponent>,
    private dialogUtils: DialogUtilsService,
    private dashboardDataHelper: DashboardDataHelperService,
    private snackBar: MatSnackBar,
    private stateService: StateService,
    @Inject(CURRENT_USER) private currentUser: CurrentUser,
    @Inject(UTILS) private utils: Utils,
    @Inject(FILE_UPLOAD_SERVICE) private fileUploadService: FileUploadService,
    @Inject(SMARTVID_API) private smartvidApi: SmartvidApi,
    @Inject(MAT_DIALOG_DATA) public observationDialogData: ObservationDialogData
  ) {
    super()
    if (!this.observationDialogData) {
      throw new Error('observationDialogData is not specified')
    }
    this.allowedFileExtensions = this.currentUser.getSupportedFileExtensions()
    this.uploadOptions = {
      queueLimit: this.uploadFilesLimit + 1,
      filters: [this._fileExtensionFilter()],
    }
    this.uploader = new FileUploader(this.uploadOptions)

    this.observationForm = this.fb.group({
      statusFormControl: new FormControl(ObservationStatus.NOT_APPLICABLE),
      hazardCategoryFormControl: new FormControl(undefined),
      identificationMethodFormControl: new FormControl(undefined),
      tradePartnerFormControl: new FormControl(),
      dueDateFormControl: new FormControl(),
      frequencyFormControl: new FormControl(undefined),
      severityFormControl: new FormControl(undefined),
      observationNoteControl: new FormControl(undefined),
      recommendedActionControl: new FormControl(),
      actionTakenControl: new FormControl(undefined, [noWhitespaceValidator]),
      reviewCommentControl: new FormControl(undefined, [noWhitespaceValidator]),
      greatCatchControl: new FormControl(),
      organizationFormControl: new FormControl(undefined, [Validators.required]),
      projectFormControl: new FormControl(undefined, [Validators.required]),
      assigneeFormControl: new FormControl(undefined, undefined, [this.validateAssignee.bind(this)]),
      customFields: this.fb.array([]),
    })

    if (this.isEdit()) {
      this.observationDialogData.observationType = this.observationDialogData.observation.type
      let observation = this.observationDialogData.observation
      let project = this.dashboardDataHelper
        .getAllProjects()
        .models.find((p: any) => p.id === this.observationDialogData.projectId)
      if (project) {
        this.observationDialogData.organizationId = project.organizationId

        this.initTradePartners(observation)
        this.initObservationForm()
        this.initObservationPhotos(observation.assets, false)

        this.organizationConfigLoader$.subscribe(() => {
          let hazardCategory = this.findConfigValueByLabel(observation.hazardCategory, this.hazardCategories)
          this.observationForm.controls['hazardCategoryFormControl'].setValue(hazardCategory)

          let identificationMethod = this.findConfigValueByLabel(
            observation.identificationMethod,
            this.identificationMethods
          )
          this.observationForm.controls['identificationMethodFormControl'].setValue(identificationMethod)

          if (observation.dueDate) {
            this.observationForm.controls['dueDateFormControl'].setValue(new Date(observation.dueDate))
          } else {
            this.todayDate = new Date()
          }

          let frequency = this.findConfigValueByValue(observation.frequency, this.frequencies)
          this.observationForm.controls['frequencyFormControl'].setValue(frequency)

          let severity = this.findConfigValueByValue(observation.severity, this.severities)
          this.observationForm.controls['severityFormControl'].setValue(severity)

          this.observationForm.controls['observationNoteControl'].setValue(observation.notes)
          this.observationForm.controls['recommendedActionControl'].setValue(observation.recommendedAction)
          this.observationForm.controls['actionTakenControl'].setValue(observation.actionTaken)
          this.observationForm.controls['reviewCommentControl'].setValue(observation.reviewComment)
          this.observationForm.controls['greatCatchControl'].setValue(observation.isGreatCatch)

          if (observation.assignedUserId && observation.assignedUserEmail) {
            this.observationForm.controls['assigneeFormControl'].setValue(observation.assignedUserEmail)
          }
        })

        this.statusLoader$.subscribe(() => {
          this.statuses.unshift(ObservationStatus[observation.status])
          this.observationForm.controls['statusFormControl'].setValue(observation.status)
        })
      }
    } else {
      if (this.observationDialogData.observationType === undefined) {
        throw new Error('observationType must be specified')
      }
      this.initObservationForm()
      let orgValue = this.observationForm.controls['organizationFormControl'].value
      if (!orgValue) {
        this.observationForm.controls['organizationFormControl'].setValue(this.organizations[0])
      }
      this.initTradePartners(undefined)

      this.todayDate = new Date()
      this.statusLoader$.subscribe(() => {
        this.observationForm.controls['statusFormControl'].setValue(this.statuses[0])
      })
    }
    this.initObservationPhotos(this.observationDialogData.externalAssets, true)
    //this.initRiskCalculation()
  }

  observationForm: FormGroup

  isProjectPreset = false

  organizations = _.filter(this.dashboardDataHelper.getAllOrganizations().models, (o: any) =>
    this.currentUser.isObservationEnabledForOrganization(o.id)
  )

  tradePartners: TradePartner[] = []
  filteredTradePartners: TradePartner[]
  filteredProjects: any[]

  frequencies: UiConfigurationListFieldData[] = []
  statuses: string[] = []
  predefinedStatuses: string[] = []
  riskScore: number
  riskType: ObservationRiskType = ObservationRiskType.UNKNOWN
  severities: UiConfigurationListFieldData[] = []
  identificationMethods: UiConfigurationListFieldData[] = []
  hazardCategories: UiConfigurationListFieldData[] = []
  filteredUsers: Observable<{} | any[]>
  photoField: UiConfigurationFieldInfo
  isProjectSelected = false
  selectedProjectModel: any = undefined
  hasAnotherDropZoneOver = false
  todayDate: Date = undefined
  organizationConfigLoader$: Subject<any> = new ReplaySubject()
  statusLoader$: Subject<any> = new ReplaySubject()

  FORM_CONTROL_TO_FIELD_NAME = {
    hazardCategoryFormControl: 'hazardCategory',
    identificationMethodFormControl: 'identificationMethod',
    tradePartnerFormControl: 'tradePartnerId',
    observationNoteControl: 'notes',
    severityFormControl: 'severity',
    frequencyFormControl: 'frequency',
    recommendedActionControl: 'recommendedAction',
    actionTakenControl: 'actionTaken',
    reviewCommentControl: 'reviewComment',
    assigneeFormControl: 'assignedUserId',
    dueDateFormControl: 'dueDate',
    greatCatchControl: 'isGreatCatch',
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e
  }

  ngOnInit() {
    this.dialogRef.updateSize('640px', '80%')
    this.uploader.onAfterAddingAll = () => this.onAfterAddingAll()
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => this.onAfterAddingFile(fileItem)
    this.uploader.uploadAll = () => this.uploadAll()
    this.uploader.onWhenAddingFileFailed = (fileItem: FileLikeObject) => this.onWhenAddingFileFailed(fileItem)
    this.registeringAssets = false
    this.uploadingToS3 = false
    this.isLoading = false
  }

  ngOnDestroy() {
    if (this.uploadStatusSubscription) {
      this.uploadStatusSubscription.unsubscribe()
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    })
  }

  openConfirmDeleteDialog(fileItem: FileItem): void {
    const dialogRef = this.dialogUtils.open(ConfirmDialogComponent, {
      width: '300px',
      height: '200px',
      data: {
        title: 'components.deletePhotoConfirmDialog.title',
        content: 'components.deletePhotoConfirmDialog.content',
        confirm: 'components.deletePhotoConfirmDialog.remove',
        discard: 'components.confirmDialog.cancel',
      },
    })
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(confirm => {
        if (confirm) {
          this.removeFromQueue(fileItem)
        }
      })
  }

  private findConfigValueByLabel(label: string, values: UiConfigurationListFieldData[]): UiConfigurationListFieldData {
    return _.find(values, v => v.label === label)
  }

  private findConfigValueByValue(value: string, values: UiConfigurationListFieldData[]): UiConfigurationListFieldData {
    return _.find(values, v => v.value === value)
  }

  private initOrganizationFormConfiguration(organizationId: string): Subscription {
    return this.uiConfigApi
      .getUiConfigurationForObservations(organizationId)
      .pipe(first())
      .subscribe(data => {
        this.observationDialogData.uiConfiguration = data
        this.initUiConfigurationSupport(
          this.observationDialogData.uiConfiguration,
          this.observationDialogData.observationType
        )

        for (const customField of data.uiConfigurationData.customFields) {
          let validators: ValidatorFn[] = []
          if (customField.isRequired) {
            validators.push(Validators.required)
          }
          if (customField.fieldDataType === FieldDataType.LONG) {
            validators.push(CustomValidators.number)
            validators.push(Validators.pattern(/[-+]?\d+/))
            validators.push(CustomValidators.max(Number.MAX_SAFE_INTEGER))
            validators.push(CustomValidators.min(Number.MIN_SAFE_INTEGER))
          }
          if (customField.fieldDataType === FieldDataType.REAL) {
            validators.push(CustomValidators.number)
            validators.push(CustomValidators.max(Number.MAX_VALUE))
            validators.push(CustomValidators.min(Number.MIN_VALUE))
          }
          this.observationForm.addControl(customField.name, new FormControl(undefined, validators))

          if (
            customField.isRequired &&
            customField.type === UiConfigurationFieldType[UiConfigurationFieldType.RADIO_BUTTON] &&
            customField.listData.length > 0
          ) {
            this.observationForm.controls[customField.name].setValue(customField.listData[0].value)
          }

          if (this.observationDialogData.observation && this.observationDialogData.observation.customFields) {
            let value = _.find(this.observationDialogData.observation.customFields, f => {
              return f.identifier === customField.name
            })
            if (value) {
              this.observationForm.controls[customField.name].setValue(CustomFieldValue.getValue(value))
            }
          }
        }

        // eslint-disable-next-line guard-for-in
        for (const controlName in this.FORM_CONTROL_TO_FIELD_NAME) {
          const fieldName = this.FORM_CONTROL_TO_FIELD_NAME[controlName]
          if (this.isFieldHidden(fieldName)) {
            this.observationForm.controls[controlName].clearValidators()
            this.observationForm.controls[controlName].clearAsyncValidators()
            this.observationForm.controls[controlName].setErrors(null)
          }
        }

        this.initRiskCalculation()

        this.predefinedStatuses = data.uiConfigurationData
          .getFieldListData(ObservationPredefinedFields.STATUS)
          .map(f => f.value)
        this.frequencies = data.uiConfigurationData.getFieldListData(ObservationPredefinedFields.FREQUENCY)
        this.severities = data.uiConfigurationData.getFieldListData(ObservationPredefinedFields.SEVERITY)
        this.identificationMethods = data.uiConfigurationData.getFieldListData(
          ObservationPredefinedFields.IDENTIFICATION_METHOD
        )
        this.hazardCategories = data.uiConfigurationData.getFieldListData(ObservationPredefinedFields.HAZARD_CATEGORY)
        this.photoField = data.uiConfigurationData.getField(ObservationPredefinedFields.ASSETS)
        this.organizationConfigLoader$.next()
      })
  }

  private initStatuses(projectId: string) {
    this.organizationConfigLoader$.pipe(first()).subscribe(() => {
      if (this.observationDialogData.observation) {
        const observationId = this.observationDialogData.observation.observationId
        return this.observationService
          .getNextStatuses(projectId, observationId)
          .pipe(first())
          .subscribe(resp => {
            this.statuses = resp.nextSteps
            this.statusLoader$.next()
          })
      } else {
        if (!this.isFieldHidden(ObservationPredefinedFields.STATUS)) {
          const observationType = this.observationDialogData.observationType
          return this.observationService
            .getInitialStatuses(projectId, observationType)
            .pipe(first())
            .subscribe(resp => {
              this.statuses = resp.nextSteps
              this.statusLoader$.next()
            })
        }
        return EMPTY
      }
    })
  }

  private validateAssignee() {
    if (!this.filteredUsers) {
      return EMPTY
    }
    return this.filteredUsers.pipe(
      first(),
      map((users: any[]) => {
        let value = this.observationForm.controls['assigneeFormControl'].value
        if (!value || !(typeof value === 'string')) {
          return null
        }
        return _.find(users, u => {
          return u.email === value
        })
          ? null
          : { invalid: true }
      })
    )
  }

  public canSave(): boolean {
    let allAsyncComplete =
      !this.registeringAssets &&
      !this.uploadingToS3 &&
      !this.isLoading &&
      this.checkAllItemsUploadComplete(this.uploader.queue)
    let isPhotosEmpty = this.photoField && this.photoField.isRequired && this.uploader.queue.length === 0
    if (!this.observationForm.valid || !allAsyncComplete || this.observationForm.pristine) {
      return false
    }
    if (this.isEdit()) {
      return !isPhotosEmpty
    } else {
      return true
    }
  }

  private initTradePartners(observation: Observation): Subscription {
    if (this.observationForm.controls['projectFormControl'].value) {
      return this.tradePartnerApi
        .getActiveTradePartnersForProject(this.observationForm.controls['projectFormControl'].value.id)
        .subscribe(this.handelTradePartnersResponse(observation))
    } else {
      return this.observationForm.controls['projectFormControl'].valueChanges
        .pipe(
          debounceTime(300),
          filter(value => value && value.id),
          switchMap(value => this.tradePartnerApi.getActiveTradePartnersForProject(value.id))
        )
        .subscribe(this.handelTradePartnersResponse(observation))
    }
  }

  private handelTradePartnersResponse(observation: Observation) {
    return (result: TradePartner[]) => {
      const sortedTradePartners = result.sort((lhs, rhs) => {
        return lhs.name.localeCompare(rhs.name)
      })
      this.tradePartners = sortedTradePartners
      this.filteredTradePartners = sortedTradePartners

      // Set initial trade partner value
      if (observation && observation.tradePartnerId) {
        this.observationForm.controls['tradePartnerFormControl'].setValue(
          this.tradePartners.find(tp => tp.id === observation.tradePartnerId)
        )
        observation = undefined
      }
    }
  }

  private initObservationForm() {
    this.observationForm.controls['organizationFormControl'].valueChanges.subscribe(() => {
      this.tradePartners = []
      this.observationForm.controls['tradePartnerFormControl'].setValue('')
    })
    this.observationForm.controls['projectFormControl'].valueChanges.subscribe(() => {
      this.tradePartners = []
      this.observationForm.controls['tradePartnerFormControl'].setValue('')
      this.uploader.clearQueue()
      this.addedAssetIds = []
      this.removedAssetIds = []
      this.observationAssets = []
      this.s3Items = []
      const selectedProject = this.observationForm.controls['projectFormControl'].value
      if (selectedProject) {
        this.selectedProjectModel = this.dashboardDataHelper.getProjectByProjectId(selectedProject.id)
        this.isProjectSelected = true
      }
    })

    this.observationForm.controls['organizationFormControl'].valueChanges.subscribe(() => {
      const formControlValue = this.observationForm.controls['organizationFormControl'].value
      if (formControlValue) {
        this.observationForm.controls['projectFormControl'].setValue('')
        this.initOrganizationFormConfiguration(formControlValue.id)
      }
    })

    this.observationForm.controls['tradePartnerFormControl'].valueChanges.subscribe(value => {
      if (!this.observationForm.controls['tradePartnerFormControl'].value) {
        this.filteredTradePartners = this.tradePartners
        return
      }
      if (!this.observationForm.controls['tradePartnerFormControl'].value.id) {
        this.observationForm.controls['tradePartnerFormControl'].setErrors({
          incorrect: true,
        })
      }
      if (typeof value === 'string') {
        this.filteredTradePartners = this.filterTradePartners(value)
      } else {
        this.filteredTradePartners = this.filterTradePartners(value.name)
      }
    })

    this.observationForm.controls['projectFormControl'].valueChanges.subscribe(value => {
      const searchString = value && value.name ? value.name : value
      this.filteredProjects = this.filterProjects(searchString)
      const projectId = value.id
      if (projectId) {
        this.initStatuses(projectId)
      }
    })

    let assigneeCache: { [value: string]: Observable<{}> } = {}

    let getServerUsersFunc = () => {
      const value = this.getAssignedUserEmail()
      const project = this.observationForm.controls['projectFormControl'].value
      if (!project || !project.id || !value) {
        return Promise.resolve([])
      }
      let cacheId = project.id + '_' + value
      assigneeCache[cacheId] =
        assigneeCache[cacheId] ||
        this.smartvidApi.searchProjectUsersByEmailForModule(
          project.id,
          ['SAFETY_OBSERVER', 'NON_MEMBER'],
          ['NON_MEMBER'],
          ['NON_MEMBER', 'SAFETY_OBSERVER'],
          value,
          ModuleType.OBSERVATIONS,
          {
            page: 0,
            pageSize: 50,
            sortColumn: ['email:ASC'],
          }
        )
      return assigneeCache[cacheId]
    }

    this.filteredUsers = merge(
      this.observationForm.controls['projectFormControl'].valueChanges,
      this.observationForm.controls['assigneeFormControl'].valueChanges
    ).pipe(startWith(''), distinctUntilChanged(), debounceTime(300), switchMap(getServerUsersFunc))

    // Set initial value of the assignee after the list has been loaded
    this.filteredUsers.pipe(first()).subscribe(users => {
      if (this.observationDialogData.observation) {
        this.observationForm.controls['assigneeFormControl'].setValue(
          _.find(users, u => u.userId === this.observationDialogData.observation.assignedUserId)
        )
      }
    })

    if (this.observationDialogData.organizationId) {
      this.observationForm.controls['organizationFormControl'].setValue(
        this.organizations.find((organization: any) => organization.id === this.observationDialogData.organizationId)
      )
    }

    if (this.observationDialogData.projectId) {
      this.isProjectPreset = true
      this.observationForm.controls['projectFormControl'].setValue(
        this.dashboardDataHelper
          .getAllProjects()
          .models.find((project: any) => project.id === this.observationDialogData.projectId)
      )
    }
  }

  private getProjectsForCurrentOrg(): any[] {
    if (!this.observationForm.controls['organizationFormControl'].value) {
      return []
    }
    return this.dashboardDataHelper
      .getAllProjects()
      .models.filter(
        project => project.organizationId === this.observationForm.controls['organizationFormControl'].value.id
      )
      .filter(project => project.canCreateObservations)
  }

  private filterTradePartners(name: string): TradePartner[] {
    const filterValue = name.toLowerCase()
    return this.tradePartners
      .filter(tp => tp.name.toLowerCase().includes(filterValue))
      .sort((lhs, rhs) => {
        return lhs.name.localeCompare(rhs.name)
      })
  }

  private filterProjects(projectName: string): any[] {
    let projectsForCurrentOrg = this.getProjectsForCurrentOrg()
    let sortedProjects = projectsForCurrentOrg ? new ProjectListPipe().transform(projectsForCurrentOrg) : []
    return sortedProjects.filter(p => p.name.toLowerCase().includes(projectName.toLowerCase()) && !p.isInactive)
  }

  displayTradePartner(tradePartner?: TradePartner): string | undefined {
    return tradePartner ? tradePartner.name : undefined
  }

  displayUser(user): string | undefined {
    return user ? user.email : undefined
  }
  displayProject(project): string | undefined {
    return project ? project.name : undefined
  }

  getControl(name: string, mapFn: (AbstractControl) => string) {
    let control = this.observationForm.controls[name].value
    return control ? mapFn(control) : undefined
  }

  getFieldTitle(fieldName: string) {
    return this.translate.instant('components.observationDialog.predefinedFields.' + fieldName + '.title')
  }

  getFieldPlaceholder(fieldName: string) {
    return this.translate.instant('components.observationDialog.predefinedFields.' + fieldName + '.placeholder')
  }

  onWhenAddingFileFailed(fileItem: FileLikeObject) {
    this.logger.error('Could not add file: ' + fileItem.name + ', type: ' + fileItem.type)
  }

  onAfterAddingFile(fileItem: FileItem) {
    if (!this.isEnoughPermissions()) {
      this.uploader.removeFromQueue(fileItem)
      this.showNotEnoughPermissionsMsg()
      return
    }
    if (this.uploader.queue.length > this.uploadFilesLimit) {
      this.uploader.removeFromQueue(fileItem)
      this.dialogUtils.open(ConfirmDialogComponent, {
        width: '300px',
        height: '220px',
        data: {
          title: 'components.observationDialog.canNotUpload',
          content: 'components.observationPermissions.limitExceeded',
          confirm: 'components.observationDialog.gotIt',
        },
      })
      return
    }

    let file = fileItem._file
    fileItem.isUploading = true
    fileItem.isUploaded = false
    this.observationForm.markAsDirty()
    let projectControlValue = this.observationForm.controls['projectFormControl'].value
    let projectId = (projectControlValue && projectControlValue.id) || this.observationDialogData.projectId
    this.fileUploadService.uploadFileToS3(file, projectId, 1).then(
      data => {
        this.s3Items.push(data)
        data.managedUpload
          .on('httpUploadProgress', progress => {
            fileItem.progress = Math.round((progress.loaded / progress.total) * 100)
            let itemUploaded = fileItem.progress === 100
            fileItem.isUploaded = itemUploaded
            fileItem.isUploading = !fileItem.isUploaded
            if (itemUploaded) {
              fileItem.isSuccess = true
            }
          })
          .send(error => {
            if (error) {
              this.logger.log(error)
            }
          })
      },
      error => {
        this.logger.log(error)
        fileItem.isError = true
      }
    )
  }

  onAfterAddingAll() {
    this.uploadingToS3 = true
    this.pollUploadingStatus()
  }

  checkAllItemsUploadComplete(fileItems: any) {
    return !_.find(fileItems, (item: FileItem) => item.isUploading)
  }

  checkAllItemsUploadCompleteAsync(fileItems: any) {
    return new Promise(resolve => {
      let uploadComplete = this.checkAllItemsUploadComplete(fileItems)
      if (uploadComplete) {
        resolve(true)
      } else {
        resolve(false)
      }
    })
  }

  pollUploadingStatus() {
    timer(0, 1000)
      .pipe(
        concatMap(() =>
          from(this.checkAllItemsUploadCompleteAsync(this.uploader.queue)).pipe(
            filter((result: any) => result === true)
          )
        )
      )
      .pipe(first())
      .subscribe(() => {
        // possible delay on s3 bucket
        setTimeout(() => {
          this.uploadingToS3 = false
        }, 1000)
      })
  }

  findFailedFileItems() {
    return _.filter(this.uploader.queue, (item: FileItem) => item.isError)
  }

  onRemoveFromQueue(event, fileItem: FileItem) {
    event.preventDefault()
    event.stopPropagation()
    if (this.isEnoughPermissions()) {
      this.openConfirmDeleteDialog(fileItem)
    } else {
      this.showNotEnoughPermissionsMsg()
    }
  }

  isEnoughPermissions() {
    if (!this.selectedProjectModel) {
      return false
    }
    const enoughPermissions =
      this.selectedProjectModel.canCreateObservations || this.selectedProjectModel.canEditObservations
    return enoughPermissions
  }

  dropZoneDisabled() {
    return !this.isEnoughPermissions()
  }

  showNotEnoughPermissionsMsg() {
    let keys = [
      'components.observationPermissions.notEnough',
      'components.observationPermissions.canCreate',
      'components.observationPermissions.canEdit',
    ]
    this.translate
      .get(keys)
      .pipe(first())
      .subscribe(values => {
        this.openSnackBar(values[keys[0]], values[keys[1]] + ' ' + values[keys[2]])
      })
  }

  removeFromQueue(fileItem: FileItem) {
    this.uploader.removeFromQueue(fileItem)
    this.observationForm.markAsDirty()
    let allAssets = []
    if (this.observationAssets) {
      allAssets.push(...this.observationAssets)
    }
    let alreadyExistAsset = allAssets.find(a => {
      return a.name === fileItem._file.name
    })
    _.remove(this.s3Items, a => a.file.name === fileItem._file.name)
    if (alreadyExistAsset) {
      if (!this.removedAssetIds.includes(alreadyExistAsset.id)) {
        this.removedAssetIds.push(alreadyExistAsset.id)
      }
      if (this.addedAssetIds.includes(alreadyExistAsset.id)) {
        _.remove(this.addedAssetIds, a => a === alreadyExistAsset.id)
      }
    }
  }

  uploadAll() {
    return new Promise((resolve, reject) => {
      let registerAssetObservables = []
      _.each(this.s3Items, item => {
        this.registeringAssets = true
        registerAssetObservables.push(from(this.registerSingleAsset(item)))
      })
      if (registerAssetObservables.length === 0) {
        this.registeringAssets = false
        resolve([])
        return
      }
      forkJoin(registerAssetObservables)
        .pipe(first())
        .subscribe(
          results => {
            if (!this.observationAssets) {
              this.observationAssets = []
            }
            _.each(results, (r: any) => {
              let assetId = r.asset.id
              if (!this.observationAssets.includes(r.asset)) {
                this.observationAssets.push(r.asset)
                if (!this.addedAssetIds.includes(assetId)) {
                  this.addedAssetIds.push(assetId)
                  // check if given asset already exists in removedAssetIds then remove it
                  _.remove(this.removedAssetIds, a => a === assetId)
                }
              }
            })
            this.registeringAssets = false
            resolve(results)
          },
          error => {
            this.registeringAssets = false
            reject(error)
          }
        )
    })
  }

  registerSingleAsset(fileItem) {
    return new Promise((resolve, reject) => {
      let folderPathTagDefs
      if (fileItem.folderPathTagDefs && fileItem.file.id) {
        folderPathTagDefs = fileItem.folderPathTagDefs
      }
      this.fileUploadService.createAsset(fileItem, folderPathTagDefs).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  }

  onSaveObservation() {
    if (!this.checkAllItemsUploadComplete(this.uploader.queue)) {
      return
    }
    const failedItems = this.findFailedFileItems()
    if (failedItems && failedItems.length > 0) {
      const files = _.pluck(failedItems, '_file')
      const fileNames = _.pluck(files, 'name')
      this.openSnackBar('Failed to upload files: ', fileNames.join(','))
    }
    if (this.observationDialogData.observation) {
      this.uploadAll()
        .then(this.updateObservation)
        .catch(e => {
          this.logger.log(e)
        })
    } else {
      this.uploadAll()
        .then(this.createObservation)
        .catch(e => {
          this.logger.log(e)
        })
    }
  }

  buildCustomFieldsRequest(): CustomFieldValue[] {
    let customFields: CustomFieldValue[] = []

    for (const customField of this.uiConfiguration.uiConfigurationData.customFields) {
      let value = this.observationForm.controls[customField.name].value
      if (!value) {
        if (!this.isEdit()) {
          continue
        }
        if (
          this.observationDialogData.observation.customFields &&
          !_.find(this.observationDialogData.observation.customFields, f => {
            return f.identifier === customField.name
          })
        ) {
          continue
        }
      }
      customFields.push(new CustomFieldValue(customField.name, customField.fieldDataType, value))
    }
    return customFields
  }

  buildCreateRequest(): ObservationCreateRequest {
    return {
      customFields: this.buildCustomFieldsRequest(),
      projectId: this.observationForm.controls['projectFormControl'].value
        ? this.observationForm.controls['projectFormControl'].value.id
        : undefined,
      tradePartnerId: this.observationForm.controls['tradePartnerFormControl'].value
        ? this.observationForm.controls['tradePartnerFormControl'].value.id
        : undefined,
      assetIds: this.addedAssetIds,
      assignedUserEmail: this.getAssignedUserEmail(),
      status: this.observationForm.controls['statusFormControl'].value
        ? this.observationForm.controls['statusFormControl'].value
        : undefined,
      hazardCategory: this.observationForm.controls['hazardCategoryFormControl'].value
        ? this.observationForm.controls['hazardCategoryFormControl'].value.label
        : undefined,
      identificationMethod: this.observationForm.controls['identificationMethodFormControl'].value
        ? this.observationForm.controls['identificationMethodFormControl'].value.label
        : undefined,
      notes: this.observationForm.controls['observationNoteControl'].value
        ? this.observationForm.controls['observationNoteControl'].value
        : undefined,
      severity: this.observationForm.controls['severityFormControl'].value
        ? this.observationForm.controls['severityFormControl'].value.value
        : undefined,
      frequency: this.observationForm.controls['frequencyFormControl'].value
        ? this.observationForm.controls['frequencyFormControl'].value.value
        : undefined,
      risk: this.riskScore,
      recommendedAction: this.observationForm.controls['recommendedActionControl'].value,
      actionTaken: this.observationForm.controls['actionTakenControl'].value,
      reviewComment: this.observationForm.controls['reviewCommentControl'].value,
      dueDate: this.observationForm.controls['dueDateFormControl'].value
        ? this.observationForm.controls['dueDateFormControl'].value.getTime()
        : undefined,
      type: this.observationDialogData.observationType,
      isGreatCatch: this.observationForm.controls['greatCatchControl'].value,
    }
  }

  buildUpdateRequest(): ObservationUpdateRequest {
    return {
      customFields: this.buildCustomFieldsRequest(),
      tradePartnerId: this.observationForm.controls['tradePartnerFormControl'].value
        ? this.observationForm.controls['tradePartnerFormControl'].value.id
        : undefined,
      projectId: this.observationForm.controls['projectFormControl'].value
        ? this.observationForm.controls['projectFormControl'].value.id
        : undefined,
      assignedUserEmail: this.getAssignedUserEmail(),
      status: this.observationForm.controls['statusFormControl'].value
        ? this.observationForm.controls['statusFormControl'].value
        : undefined,
      hazardCategory: this.observationForm.controls['hazardCategoryFormControl'].value
        ? this.observationForm.controls['hazardCategoryFormControl'].value.label
        : undefined,
      identificationMethod: this.observationForm.controls['identificationMethodFormControl'].value
        ? this.observationForm.controls['identificationMethodFormControl'].value.label
        : undefined,
      notes: this.observationForm.controls['observationNoteControl'].value
        ? this.observationForm.controls['observationNoteControl'].value
        : undefined,
      severity: this.observationForm.controls['severityFormControl'].value
        ? this.observationForm.controls['severityFormControl'].value.value
        : undefined,
      frequency: this.observationForm.controls['frequencyFormControl'].value
        ? this.observationForm.controls['frequencyFormControl'].value.value
        : undefined,
      risk: this.riskScore,
      recommendedAction: this.observationForm.controls['recommendedActionControl'].value,
      actionTaken: this.observationForm.controls['actionTakenControl'].value,
      reviewComment: this.observationForm.controls['reviewCommentControl'].value,
      dueDate: this.observationForm.controls['dueDateFormControl'].value
        ? this.observationForm.controls['dueDateFormControl'].value.getTime()
        : undefined,
      type: this.observationDialogData.observation.type,
      isGreatCatch: this.observationForm.controls['greatCatchControl'].value,
      addedAssetIds: this.addedAssetIds,
      removedAssetIds: this.removedAssetIds,
    }
  }

  getAssignedUserEmail(): string {
    if (!this.observationForm.controls['assigneeFormControl'].value) {
      return null
    }
    if (typeof this.observationForm.controls['assigneeFormControl'].value === 'object') {
      return this.observationForm.controls['assigneeFormControl'].value.email
    }
    return this.observationForm.controls['assigneeFormControl'].value
  }

  private createObservation = () => {
    this.isLoading = true
    this.store
      .dispatch(new CreateObservation(this.buildCreateRequest()))
      .pipe(first())
      .subscribe(
        () => {
          this.isLoading = false
          this.onClose(true)
        },
        () => {
          this.isLoading = false
        }
      )
  }

  updateObservation = () => {
    this.isLoading = true
    this.observationService
      .updateObservation(
        this.observationDialogData.observation.projectId,
        this.observationDialogData.observation.observationId,
        this.buildUpdateRequest()
      )
      .pipe(first())
      .subscribe(
        () => {
          this.isLoading = false
          this.onClose(true)
          this.emitObservationUpdated()
          this.utils.notify('Observation updates saved')
        },
        () => {
          this.isLoading = false
        }
      )
  }

  onClose(afterSave?: boolean) {
    if (!afterSave && this.observationForm.dirty) {
      const dialogRef = this.dialogUtils.open(ConfirmDialogComponent, {
        width: '360px',
        height: '200px',
        data: {
          title: 'components.unsavedСhangesConfirmDialog.title',
          content: 'components.unsavedСhangesConfirmDialog.content',
          confirm: 'components.unsavedСhangesConfirmDialog.discard',
          discard: 'components.unsavedСhangesConfirmDialog.stay',
          hideClose: true,
        },
      })
      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(discard => {
          if (discard) {
            this.dialogRef.close()
          }
        })
    } else {
      this.dialogRef.close()
    }
  }

  onRiskChange() {
    console.log('onRiskChange')
    let request = this.buildCreateRequest()
    const projectId = this.observationForm.controls['projectFormControl'].value.id
    if (!request.severity && !request.frequency) {
      this.setInitialRisk()
    } else {
      this.observationService
        .calcRisk(projectId, request)
        .pipe(first())
        .subscribe(riskResponse => {
          this.riskScore = riskResponse.risk
          this.riskType = riskResponse.riskType
          if (riskResponse.riskTypeDependentDueDate) {
            this.observationForm.controls['dueDateFormControl'].setValue(
              new Date(riskResponse.riskTypeDependentDueDate)
            )
          }
        })
    }
  }

  getStatus() {
    return this.observationForm.controls['statusFormControl'].value
  }

  isFieldRequired(field: ObservationPredefinedFields): boolean {
    if (field === ObservationPredefinedFields.ACTION_TAKEN) {
      return this.isActionTakenRequired()
    }
    if (field === ObservationPredefinedFields.REVIEW_COMMENT) {
      return this.isReviewCommentRequired()
    }
    return super.isFieldRequired(field)
  }

  isActionTakenHidden() {
    const isClosed =
      this.getStatus() === ObservationStatus.CLOSED ||
      this.getStatus() === ObservationStatus.CLOSED_REVIEWED ||
      this.getStatus() === ObservationStatus.READY_TO_REVIEW
    return this.isPositiveObservation() || !isClosed
  }

  isActionTakenRequired() {
    return !this.isActionTakenHidden()
  }

  isReviewCommentHidden() {
    const isClosed = this.getStatus() === ObservationStatus.CLOSED_REVIEWED
    return this.isPositiveObservation() || !isClosed
  }

  isReviewCommentRequired() {
    return !this.isReviewCommentHidden()
  }

  private setInitialRisk() {
    this.riskType = this.isPositiveObservation()
      ? ObservationRiskType.POSITIVE
      : this.isEdit()
      ? this.observationDialogData.observation.riskType
      : this.isFieldHidden(ObservationPredefinedFields.RISK)
      ? ObservationRiskType.RISK
      : ObservationRiskType.UNKNOWN
    this.riskScore = this.isPositiveObservation()
      ? 0
      : this.isEdit()
      ? this.observationDialogData.observation.risk
      : undefined
  }

  private initRiskCalculation() {
    this.setInitialRisk()
    if (!this.isPositiveObservation()) {
      let severityPipe: Observable<any> = this.observationForm.controls['severityFormControl'].valueChanges
      if (this.isEdit()) {
        severityPipe = severityPipe.pipe(skip(1))
      }
      severityPipe.subscribe(() => this.onRiskChange())
      let frequencyPipe: Observable<any> = this.observationForm.controls['frequencyFormControl'].valueChanges
      if (this.isEdit()) {
        frequencyPipe = frequencyPipe.pipe(skip(1))
      }
      frequencyPipe.subscribe(() => this.onRiskChange())
    }
  }

  private initObservationPhotos(assets, isExternalAssets) {
    if (assets) {
      this.observationAssets = [...assets]
      if (isExternalAssets) {
        this.addedAssetIds = [..._.pluck(assets, 'id')]
      }
      const fileNames = _.pluck(this.observationAssets, 'name')
      let fileItems: FileItem[] = []
      // create empty files in case of observation edit mode
      _.each(fileNames, fileName => {
        let file = new File([], fileName)
        let fileItem: FileItem = new FileItem(this.uploader, file, this.uploadOptions)
        fileItem.isUploaded = true
        fileItems.push(fileItem)
      })
      this.uploader.queue.push(...fileItems)
    }
  }

  public isEdit(): boolean {
    return !!this.observationDialogData.observation
  }

  private isNegativeObservation(): boolean {
    if (this.observationDialogData.observation) {
      return this.observationDialogData.observation.type === ObservationType.NEGATIVE
    } else {
      return this.observationDialogData.observationType === ObservationType.NEGATIVE
    }
  }

  private isPositiveObservation(): boolean {
    if (this.observationDialogData.observation) {
      return this.observationDialogData.observation.type === ObservationType.POSITIVE
    } else {
      return this.observationDialogData.observationType === ObservationType.POSITIVE
    }
  }

  public getDialogTitle(): string {
    const root = 'components.observationDialog.dialogTitle.'
    const observation: Observation = this.observationDialogData.observation
    if (observation) {
      const externalId = new ObservationExternalIdPipe().transform(observation.externalId)
      const appender = 'edit'
      return this.translate.instant(root + appender, { param: externalId })
    } else {
      const appender =
        this.observationDialogData.observationType === ObservationType.POSITIVE ? 'createPositive' : 'createNegative'
      return this.translate.instant(root + appender)
    }
  }

  isObservationProjectInactive() {
    const projectId = this.observationDialogData.projectId
    let project = this.dashboardDataHelper.getAllProjects().models.find((p: any) => p.id === projectId)
    return project && project.isInactive
  }

  private emitReloadObservations() {
    this.store.dispatch(new ReloadObservations())
  }

  private emitObservationUpdated() {
    this.store.dispatch(new ObservationUpdated(this.observationDialogData.observation.observationId))
  }

  private _fileExtensionFilter(): FilterFunction {
    return {
      name: 'fileExtensionFilter',
      fn: this._fileExtensionFilterFn.bind(this),
    }
  }

  private _fileExtensionFilterFn(item: FileLikeObject): boolean {
    let i = item.name.lastIndexOf('.')
    if (i === -1) {
      return false
    }
    let extension = item.name.slice(i).toUpperCase()
    return this.allowedFileExtensions.includes(extension)
  }
}
