/* global angular */

angular.module('smartvid').factory('OxBlueCameraInfoCollection', function (BaseCollection, OxBlueCameraInfoModel) {
  class OxBlueCameraInfoCollection extends BaseCollection {
    constructor (models) {
      super(models, OxBlueCameraInfoModel)
    }
  }

  return OxBlueCameraInfoCollection
})
