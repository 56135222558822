/* global angular, _ */
angular.module('smartvid').factory('DefaultGroupParser', function (GroupMetadata) {
  class DefaultGroupParser {
    constructor (comparetorFn) {
      this.comparetorFn = comparetorFn
    }
    parse (items) {
      if (_.isEmpty(items) || !_.isFunction(this.comparetorFn)) { return [] }
      var groups = []
      var prevGroup = this.comparetorFn(items[0])
      var currGroup
      var currItemCount = 1
      for (var i = 1; i < items.length; ++i) {
        currGroup = this.comparetorFn(items[i])
        if (prevGroup !== currGroup) {
          groups.push(new GroupMetadata({
            groupHeader: true,
            text: String(prevGroup),
            parsedSize: currItemCount
          }))
          currItemCount = 1
        } else {
          currItemCount++
        }
        prevGroup = currGroup
      }
      groups.push(new GroupMetadata({
        groupHeader: true,
        text: String(prevGroup),
        parsedSize: currItemCount
      })) // push last group
      return groups
    }
  }

  return DefaultGroupParser
})
