/* global angular */

angular.module('smartvid').factory('Bim360FieldProjectCollection', function (BaseCollection, Bim360FieldProjectModel) {
  class Bim360FieldProjectCollection extends BaseCollection {
    constructor (models) {
      super(models, Bim360FieldProjectModel)
    }
  }

  return Bim360FieldProjectCollection
})
