/* global angular, $ */

angular.module('smartvid').directive('formFieldValidator', function ($timeout) {
  return {
    restrict: 'E',
    templateUrl: 'forms/validators/form-field-validator.html',
    scope: {
      messageLabel: '='
    },
    link: function ($scope, el$, attrs) {
      // This needs to be executed after page is rendered and timeout seems to be the only way to do it.
      $timeout(() => {
        let inputField = $('#' + attrs.inputFieldId)
        let inputPosition = inputField.position()
        let inputWidth = inputField.outerWidth()
        $(el$).find('.form-field-validator').css({
          position: 'absolute',
          top: (inputPosition.top + 3) + 'px',
          left: (inputPosition.left + inputWidth + 10) + 'px'
        }).show()
      }, 1)
    }
  }
})
