/* global angular, _ */

angular.module('smartvid').service('tagUtils', function (TagDefModel) {
  let service = {
    createTagPayload: function (assetId, tag) {
      return {
        assetId: assetId,
        text: _.trim((tag instanceof TagDefModel || _.isString(tag.text)) ? tag.text : tag),
        status: 'VERIFIED',
        type: 'MANUAL'
      }
    }
  }

  return service
})
