import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core'

@Component({
  selector: 'sv-report-control-bar',
  templateUrl: 'report-controlbar.component.html',
  styleUrls: ['report-controlbar.component.scss'],
})
export class ReportControlBarComponent implements OnInit, OnDestroy {
  public NavController: any
  private openRightPanel: boolean

  constructor(private injector: Injector, @Inject('$rootScope') public rootScope: any) {
    this.NavController = this.injector.get('NavController')
  }

  ngOnInit(): void {
    this.openRightPanel = this.NavController.rightNavOpen
    this.NavController.rightNavOpen = false
  }

  ngOnDestroy(): void {
    this.NavController.rightNavOpen = this.openRightPanel
  }

  runReport() {
    this.rootScope.modal.open('runReport', { isReportView: true })
  }
}
