/* global angular, _ */

angular.module('smartvid').directive('tagTree', ($rootScope, $state, modal, $timeout, $compile, hotkeys, uiGridTreeViewConstants, uiGridConstants, $filter, tagTreeStateService, $q, uiGridTreeBaseConstants, $templateCache, currentUser, dashboardDataHelper) => {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      treeModel: '=',
      customGridOptions: '=',
      treeOwnerId: '@',
      initCallbacks: '=?'
    },
    templateUrl: 'TagManager/tag-tree.html',
    link (scope, $el) {
      let tooltipStatus = {}
      let cleanupListeners = []
      let isInitialized = false
      const COMMAND_A_HOT_KEY = 'command+a'
      const CTRL_A_HOT_KEY = 'ctrl+a'
      const rowHeight = 46
      scope.activeNode = null
      scope.checkboxStatus = {}
      scope.data = []
      scope.openAddTagModal = openAddTagModal
      scope.openEditTagModal = openEditTagModal
      scope.onKeyUp = onKeyUp
      scope.hasChildren = hasChildren
      scope.isNodeCollapsed = isNodeCollapsed
      scope.collapseExpand = collapseExpand
      scope.setActiveNode = setActiveNode
      scope.setInactiveNode = setInactiveNode
      scope.isActiveNode = isActiveNode
      scope.getNodeClass = getNodeClass
      scope.search = search
      scope.selectNode = selectNode
      scope.deleteTag = deleteTag
      scope.showTooltip = showTooltip
      scope.hideTooltip = hideTooltip
      scope.isActiveTooltip = isActiveTooltip
      scope.isFirstChild = isFirstChild
      scope.canCreate = canCreate()
      scope.loading = true
      scope.gridOptions = _.defaults(scope.customGridOptions || {}, createGridOptions())

      $templateCache.put('ui-grid/uiGridViewport',
        '<div role="rowgroup" class="ui-grid-viewport" ng-style="colContainer.getViewportStyle()"><!-- tbody --><div class="ui-grid-canvas"><div ng-repeat="(rowRenderIndex, row) in rowContainer.renderedRows track by $index" class="ui-grid-row my-viewport" ng-style="Viewport.rowStyle(rowRenderIndex)"><div role="row" ui-grid-row="row" row-render-index="rowRenderIndex"></div></div></div></div>'
      )

      reloadTree()

      scope.$on('$destroy', () => {
        saveState()
        cleanup()
        $rootScope.$broadcast('uiTreeClosed', scope.gridApi.grid.id)
      })

      scope.$on('uiTreeClosed', (event, gridId) => {
        if (scope.gridApi.grid.id !== gridId) {
          captureHotKeys()
        }
      })

      if (scope.initCallbacks) {
        scope.initCallbacks({
          clearSearchInput: clearSearchInput
        })
      }

// -----------------------------------------------------------------------------------------

      function init () {
        isInitialized = true
        restoreState().then(() => {
          $rootScope.$broadcast('uiTreeLoaded', scope.gridApi, scope.treeOwnerId)
          scope.loading = false
        })
      }

      function cleanup () {
        _.each(cleanupListeners, (listener) => {
          listener()
        })
      }

      function onTreeUpdate () {
        reloadTree()
      }

      function onDataChanged () {
        if (scope.gridApi.grid.rows.length === 0) {
          return
        }
        if (!isInitialized) {
          init()
        } else {
          restoreSelectAll()
          scope.loading = false
        }
      }

      function onRowSelectionChanged (row) {
        scope.checkboxStatus[row.entity.id] = row.isSelected
      }

      function onFilterChanged () {
        let term = scope.gridApi.grid.columns[1].filters[0].term
        if (term) {
          scope.gridApi.treeBase.expandAllRows()
        }
      }

      function saveState () {
        if (scope.gridApi) {
          console.log('Begin Save State')
          let state = scope.gridApi.saveState.save()
          state.treeState = getGridExpandedState()
          if (state.scrollFocus && state.scrollFocus.rowVal) {
            state.scrollFocus.rowVal.row = getEntityToScrollTo(state)
            let entity = scope.treeModel.getNode(state.scrollFocus.rowVal.row)
            console.log('Saving scroll position to entity: ' + entity.title)
          }
          tagTreeStateService.saveState(scope.treeModel.key, state)
          console.log('End Save State')
        }
      }

      function getEntityToScrollTo (state) {
        let scrollEntityId = state.scrollFocus.rowVal.row
        let visibleRows = scope.gridApi.grid.getVisibleRows()
        let scrollEntityIdindex = _.findIndex(visibleRows, function (row) {
          return row.entity.id === scrollEntityId
        })
        let gridHeight = $el.height() - 49
        let newIndex = scrollEntityIdindex + Math.floor(gridHeight / rowHeight) - 2
        newIndex = (newIndex >= visibleRows.length) ? visibleRows.length - 1 : newIndex
        let entityId = visibleRows[newIndex].entity.id
        return entityId
      }

      function restoreState () {
        let defer = $q.defer()
        let state = tagTreeStateService.getState(scope.treeModel.key)
        if (!state) {
          defer.resolve()
        } else {
          console.log('Begin Restore State')
          restoreTreeState(state).then(() => {
            console.log('End Restore State collapse/expand')
            defer.resolve()
          })
        }
        return defer.promise
      }

      function restoreTreeState (state) {
        console.log('Restore Tree State collapse/expand')
        let defer = $q.defer()
        let treeState = state.treeState
        _.each(scope.gridApi.grid.rows, (row) => {
          let rowState = treeState[row.entity.id]
          if (rowState) {
            if (rowState === uiGridTreeBaseConstants.COLLAPSED) {
              scope.gridApi.treeBase.collapseRow(row)
            } else {
              scope.gridApi.treeBase.expandRow(row)
            }
          }
        })
        // timeout is necessary so collapse/expands states are rendared, otherwise scroll does not work.
        $timeout(() => {
          scope.gridApi.saveState.restore(scope, state).then(() => {
            defer.resolve()
          })
        })
        return defer.promise
      }

      function restoreSelectAll () {
        if (scope.gridApi && scope.gridApi.selection.getSelectAllState()) {
          selectAll(true)
        }
      }

      function getGridExpandedState () {
        let state = {}
        scope.gridApi.grid.treeBase.tree.forEach((node) => {
          getGridExpandesStateInternal(node, state)
        })
        return state
      }

      function getGridExpandesStateInternal (node, state) {
        state[node.row.entity.id] = node.state
        if (node.children && node.children.length > 0) {
          _.each(node.children, (node) => {
            getGridExpandesStateInternal(node, state)
          })
        }
      }

      function matchRow (searchTerm, cellValue, row, column) {
        return row.entity.title.toLowerCase().includes(searchTerm.toLowerCase())
      }

      function reloadTree () {
        let defer = $q.defer()
        scope.treeModel.loadingPromise.finally(() => {
          scope.data = getData()
          scope.gridOptions.data = scope.data
          if (scope.data.length === 0) {
            init()
          }
          defer.resolve()
        })
        return defer.promise
      }

      function getData () {
        let data = []
        scope.treeModel.forEachNode((node, parent, index, level) => {
          node.$$treeLevel = level
          data.push(node)
          return true
        })
        return data
      }

      function hasChildren (row) {
        return row.entity.children.length > 0
      }

      function isNodeCollapsed (row) {
        return row.treeNode.state === 'collapsed'
      }

      function collapseExpand (row) {
        scope.gridApi.treeBase.toggleRowTreeState(row)
      }

      function setActiveNode (row) {
        scope.activeNode = row.entity.id
      }

      function setInactiveNode () {
        scope.activeNode = null
      }

      function isActiveNode (row) {
        return scope.activeNode === row.entity.id
      }

      function getNodeClass (row) {
        if (scope.activeNode === row.entity.id) {
          return (row.entity.canUpdate) ? 'hovering' : 'hovering-no-update'
        }
        return ''
      }

      function search (node) {
        $rootScope.$broadcast('sv-tag-node-search', node)
      }

      function selectNode (event, node, noToggle) {
        if (event) {
          event.stopPropagation()
          event.preventDefault()
        }
        if (noToggle) {
          scope.gridApi.selection.selectRow(node)
        } else {
          scope.gridApi.selection.toggleRowSelection(node)
        }
      }

      function selectAllCallback () {
        selectAll()
      }

      function selectAll (state) {
        if (!angular.isDefined(state)) {
          state = !scope.gridApi.selection.getSelectAllState()
        }
        if (state) {
          scope.gridApi.selection.selectAllRows()
        } else {
          scope.gridApi.selection.clearSelectedRows()
        }
      }

      function openAddTagModal (parentTagNode) {
        modal.open('addTag', {
          treeModel: scope.treeModel,
          parentTagNode: parentTagNode,
          callback: function () {
            $timeout(() => {
              if (parentTagNode) {
                let row = scope.gridApi.grid.getRow(parentTagNode)
                scope.gridApi.treeBase.expandRow(row)
              }
              let entity = parentTagNode || scope.gridApi.grid.appScope.data[0]
              scope.gridApi.core.scrollTo(entity, scope.gridApi.grid.options.columnDefs[0])
            })
          }
        })
      }

      function openEditTagModal (editTagNode) {
        modal.open('addTag', {
          editTagNode: editTagNode,
          treeModel: scope.treeModel,
          header: $filter('i18next')('tags.modal.header-edit')
        })
      }

      function deleteTag (node) {
        if (!scope.treeModel.hasProtectedChildren(node.id)) {
          let deleteScope = {
            confirm () {
              scope.treeModel.deleteTag(node.id)
            },
            hasSelections: true,
            retrievingAssetCount: false,
            affectedAssetCount: '?',
            getAffectedAssetCount: function () {
              this.retrievingAssetCount = true
              scope.treeModel.getAssetCountByTagDefs([node.tagDefinitionId]).then((response) => {
                this.affectedAssetCount = response
                this.retrievingAssetCount = false
              }, () => {
                this.retrievingAssetCount = false
              })
            },
            callback: function () {
              $rootScope.$emit('deleteNode', node.id)
            }
          }

          modal.open('tagDefDeleteConfirm', deleteScope)
        } else {
          modal.open('tagDefDeleteNotPermitted')
        }
      }

      /**
       * Start creating a tag when the user starts typing
       * @param event
       * @returns {boolean}
       */
      function onKeyUp (event) {
        let tagName
        if (event.keyCode !== 13) {
          tagName = String.fromCharCode(event.charCode)
        }
        openAddTagModal(tagName)
        event.stopPropagation()
        event.preventDefault()
        return false
      }

      function showTooltip (tooltip) {
        tooltipStatus[tooltip] = true
      }

      function hideTooltip (tooltip) {
        tooltipStatus[tooltip] = false
      }

      function canCreate () {
        return $state.current.name === 'dashboard.adminOrganizations.organizationId.tagsmanager' ||
            $state.current.name === 'dashboard.projects' ||
            !_.find(currentUser.organizations,
                org => {
                  return org.id === dashboardDataHelper.getCurrentProject().organizationId
                })
                .projectLevelTagsDisabled
      }

      function isActiveTooltip (row, tooltip) {
        return isActiveNode(row) && tooltipStatus[tooltip]
      }

      function isFirstChild (row) {
        return scope.treeModel.isFirstNode(row.entity)
      }

      function captureHotKeys () {
        hotkeys.del(COMMAND_A_HOT_KEY)
        hotkeys.del(CTRL_A_HOT_KEY)
        hotkeys.add({
          combo: COMMAND_A_HOT_KEY,
          callback: selectAllCallback
        })
        hotkeys.add({
          combo: CTRL_A_HOT_KEY,
          callback: selectAllCallback
        })
        cleanupListeners.push(() => {
          hotkeys.del(COMMAND_A_HOT_KEY)
          hotkeys.del(CTRL_A_HOT_KEY)
        })
      }

      function clearSearchInput () {
        scope.gridApi.grid.clearAllFilters()
      }

      function createGridOptions () {
        return {
          showHeader: true,
          enableFiltering: true,
          showTreeExpandNoChildren: false,
          showTreeRowHeader: false,
          enableExpandAll: false,
          treeRowHeaderAlwaysVisible: false,
          enableColumnMenus: false,
          enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
          rowTemplate: 'TagManager/tree-node-new.html',
          rowHeight: rowHeight,
          headerRowHeight: 49,
          columnVirtualizationThreshold: 50,
          excessRows: 30,
          minRowsToShow: 20,
          enableRowHeaderSelection: false,
          enableFullRowSelection: false,
          multiSelect: true,
          saveFocus: false,
          saveScroll: true,
          saveTreeView: true,
          saveSelection: true,
          saveFilter: false,
          enableTreeView: true,
          data: null,
          saveRowIdentity: function (rowEntity) {
            return rowEntity.id
          },
          columnDefs: [
            {
              name: 'title',
              filter: {
                type: uiGridConstants.filter.INPUT,
                condition: matchRow,
                placeholder: $filter('i18next')('tags.tree.search-placeholder'),
                rawTerm: true
              },
              headerCellTemplate: 'TagManager/tree-header.html'
            }
          ],
          onRegisterApi: function (gridApi) {
            cleanup()
            scope.gridApi = gridApi
            cleanupListeners.push(scope.gridApi.selection.on.rowSelectionChanged(scope, onRowSelectionChanged))
            cleanupListeners.push(scope.gridApi.selection.on.rowSelectionChangedBatch(scope, function (rows) {
              _.each(rows, row => {
                onRowSelectionChanged(row)
              })
            }))
            cleanupListeners.push(scope.gridApi.core.on.filterChanged(scope, onFilterChanged))
            cleanupListeners.push(scope.gridApi.core.on.rowsRendered(scope, onDataChanged))
            cleanupListeners.push(scope.gridApi.core.on.sortChanged(scope, () => {
              scope.gridApi.grid.refresh()
            }))
            cleanupListeners.push(scope.treeModel.addUpdateListener(onTreeUpdate))
            captureHotKeys()
          },
          isRowSelectable: function (row) {
            return row.entity.canUpdate
          },
          rowIdentity: function (entity) {
            return entity.id
          }
        }
      }
    }
  }
})
