import * as _ from 'lodash'
import * as moment from 'moment'
import * as Q from 'q'
import { Injectable, Inject } from '@angular/core'
import { HTTP } from 'shared/smartvid.types'
import { ConfigService } from 'modules/core/services/config.service'
import { toObservable } from 'shared/utils/api-utils'
import {
  DashboardDataObjectType,
  DashboardObjectRequest,
  DashboardRequest,
  DashboardDataResponse,
  InsightsDashboardParameters,
  DashboardDateRangeTimeUnit,
  TIME_UNIT_TO_DAYS,
  DateRangeChartData,
  ScopeObjectType,
  DashboardDataObjectResponseBase,
  CancellableRequestSubscription,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { first } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { ChartData } from 'modules/insights/dashboard/charts/models/base-chart.type'

@Injectable({
  providedIn: 'root',
})
export class InsightsDashboardServiceHelper {
  private readonly rootUrl: string

  constructor(
    @Inject(HTTP) private $http: ng.IHttpService,
    private config: ConfigService,
    private translate: TranslateService
  ) {
    this.rootUrl = this.config.env.development.apiRootUrl
  }

  public static createDashboardRequest(
    objectType: DashboardDataObjectType,
    dashboardParameters: InsightsDashboardParameters,
    scopeObjectType: ScopeObjectType = ScopeObjectType.PROJECT
  ): DashboardRequest {
    let request: DashboardObjectRequest = {
      dashboardDataObject: objectType,
      dashboardDateRangeTimeUnit: dashboardParameters.dateRangeTimeUnit,
      dateRangeStart: dashboardParameters.dateRangeStart ? dashboardParameters.dateRangeStart.getTime() : undefined,
      dateRangeEnd: dashboardParameters.dateRangeEnd ? dashboardParameters.dateRangeEnd.getTime() : undefined,
      scopeObjectId: InsightsDashboardServiceHelper.getScopeObjectId(dashboardParameters, scopeObjectType),
      useDemoData: dashboardParameters.useDemoData,
      ignoreCache: dashboardParameters.ignoreCache,
      filter: dashboardParameters.filter,
    }

    return {
      dashboardDataObjectRequests: [request],
    }
  }

  public static setupHAxis(chartData: ChartData, timeUnit: DashboardDateRangeTimeUnit) {
    let dataRowsCount = chartData.dataTable.length - 1
    // workaround the bug/feature of Google Charts where it doesn't show data if there is only one data point for column chart
    if (dataRowsCount === 1) {
      chartData.options.hAxis.ticks = [chartData.dataTable[1][0]]
      chartData.options.hAxis.viewWindow = {
        min: moment(chartData.dataTable[1][0])
          .subtract(1, 'days')
          .toDate(),
        max: moment(chartData.dataTable[1][0])
          .add(1, 'days')
          .toDate(),
      }
      return
    }

    let hMinValue = chartData.dataTable[1][0]
    let hMaxValue = chartData.dataTable[dataRowsCount][0]
    let valuesBetweenCount = dataRowsCount > 7 ? dataRowsCount - 1 : dataRowsCount
    let unitsStep = dataRowsCount > 7 ? Math.ceil(valuesBetweenCount / 6) : 1
    let hTicks = []
    for (let i = 0; i < Math.min(valuesBetweenCount, 6); i++) {
      hTicks.push(
        moment(hMinValue)
          .add(i * unitsStep * TIME_UNIT_TO_DAYS[timeUnit], 'days')
          .toDate()
      )
    }
    hTicks.push(hMaxValue)
    // console.debug(unitsStep, valuesBetweenCount, hTicks.length)
    if (hTicks.length === 7) {
      chartData.options.hAxis.viewWindow = { min: hMinValue, max: hMaxValue }
    }
    chartData.options.hAxis.ticks = hTicks
  }

  public getApiUrl(organizationId: string): string {
    return this.rootUrl + `/api/organization/${organizationId}/dashboard`
  }

  public loadSimpleDateBasedChartData(
    dashboardParameters: InsightsDashboardParameters,
    dashboardDataObjectType: DashboardDataObjectType,
    chartColumns: any[],
    hasHeaderDataRow: boolean,
    chartOptions: {},
    onDataLoadedFunc: (data: DateRangeChartData) => void,
    scopeObjectType: ScopeObjectType = ScopeObjectType.PROJECT
  ) {
    let url = this.getApiUrl(dashboardParameters.organizationId)
    let payload = InsightsDashboardServiceHelper.createDashboardRequest(
      dashboardDataObjectType,
      dashboardParameters,
      scopeObjectType
    )

    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardDataResponse<DateRangeChartData>>(
      this.$http.post(url, payload, { timeout: cancellableRequest.promise }),
      DashboardDataResponse
    )
      .pipe(first())
      .subscribe((data: DashboardDataResponse<DateRangeChartData>) => {
        let dateRangeChartData = _.merge(new DateRangeChartData(), data.dashboardData[dashboardDataObjectType])
        if (
          !dateRangeChartData.chartData ||
          !dateRangeChartData.chartData.dataTable ||
          dateRangeChartData.chartData.dataTable.length === 0
        ) {
          onDataLoadedFunc(dateRangeChartData)
          return
        }
        // first row of the table contains column names and tooltip data if any
        if (hasHeaderDataRow) {
          dateRangeChartData.chartData.dataTable[0] = [
            ...this.translateStringsIn(chartColumns),
            ...dateRangeChartData.chartData.dataTable[0],
          ]
        } else {
          dateRangeChartData.chartData.dataTable = [
            [...this.translateStringsIn(chartColumns)],
            ...dateRangeChartData.chartData.dataTable,
          ]
        }

        for (let i = 1; i < dateRangeChartData.chartData.dataTable.length; i++) {
          dateRangeChartData.chartData.dataTable[i][0] = moment(new Date(dateRangeChartData.chartData.dataTable[i][0]))
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0)
            .toDate()
        }

        dateRangeChartData.chartData.options = { ..._.cloneDeep(chartOptions) }
        InsightsDashboardServiceHelper.setupHAxis(dateRangeChartData.chartData, dashboardParameters.dateRangeTimeUnit)
        // console.debug(
        //   dashboardDataObjectType,
        //   dateRangeChartData.chartData.dataTable.length - 1,
        //   dateRangeChartData.chartData.options.hAxis.ticks
        // )

        onDataLoadedFunc(dateRangeChartData)
      })

    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  public loadSimpleCategoryCountChartData(
    dashboardParameters: InsightsDashboardParameters,
    dashboardDataObjectType: DashboardDataObjectType,
    chartColumns: any[],
    chartOptions: {},
    onDataLoadedFunc: (data: DateRangeChartData) => void,
    scopeObjectType: ScopeObjectType = ScopeObjectType.PROJECT
  ) {
    let url = this.getApiUrl(dashboardParameters.organizationId)
    let payload = InsightsDashboardServiceHelper.createDashboardRequest(
      dashboardDataObjectType,
      dashboardParameters,
      scopeObjectType
    )

    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardDataResponse<DateRangeChartData>>(
      this.$http.post(url, payload, { timeout: cancellableRequest.promise }),
      DashboardDataResponse
    )
      .pipe(first())
      .subscribe((data: DashboardDataResponse<DateRangeChartData>) => {
        let dateRangeChartData = _.merge(new DateRangeChartData(), data.dashboardData[dashboardDataObjectType])

        if (
          !dateRangeChartData.chartData ||
          !dateRangeChartData.chartData.dataTable ||
          dateRangeChartData.chartData.dataTable.length === 0
        ) {
          onDataLoadedFunc(dateRangeChartData)
          return
        }

        if (chartColumns.length === 3 && chartColumns[2].role === 'tooltip') {
          dateRangeChartData.chartData.dataTable.forEach(row => {
            row.push(row[0])
          })
        }
        dateRangeChartData.chartData.dataTable = [
          [...this.translateStringsIn(chartColumns)],
          ...dateRangeChartData.chartData.dataTable,
        ]
        dateRangeChartData.chartData.options = { ..._.cloneDeep(chartOptions) }
        onDataLoadedFunc(dateRangeChartData)
      })

    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  public translateStringsIn(data: any[]): any[] {
    return _.map(data, k => {
      return typeof k === 'string' && k.startsWith('dashboard.') ? this.translate.instant(k) : k
    })
  }

  public adjustDataForNPointsChartWithEqualXAxisTicksSpacing(
    data: DateRangeChartData,
    dashboardParameters: InsightsDashboardParameters,
    ticksSpacingInDays: number
  ) {
    for (let i = 1; i < data.chartData.dataTable.length; i++) {
      let dateIfDataPointDateIsZero = moment(dashboardParameters.dateRangeStart)
        .add((i - 1) * ticksSpacingInDays, 'days')
        .toDate()
      data.chartData.dataTable[i][0] = moment(
        data.chartData.dataTable[i][0].getTime() <= 0 ? dateIfDataPointDateIsZero : data.chartData.dataTable[i][0]
      )
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)
        .toDate()
    }
    data.chartData.options.hAxis.ticks = []
    data.chartData.dataTable.forEach((row, idx) => {
      if (idx > 0) {
        data.chartData.options.hAxis.ticks.push(row[0])
      }
    })
  }

  public loadStandardPpeComplianceTrendOverTimeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void,
    dashboardDataObjectType: DashboardDataObjectType,
    scopeObjectType: ScopeObjectType,
    chartColumns: any[],
    chartOptions: any,
    tooltipCompliance: string,
    tooltipComplianceCutoff: string
  ) {
    let url = this.getApiUrl(dashboardParameters.organizationId)
    let payload = InsightsDashboardServiceHelper.createDashboardRequest(
      dashboardDataObjectType,
      dashboardParameters,
      scopeObjectType
    )

    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardDataResponse<DateRangeChartData>>(
      this.$http.post(url, payload, { timeout: cancellableRequest.promise }),
      DashboardDataResponse
    )
      .pipe(first())
      .subscribe((data: DashboardDataResponse<DateRangeChartData>) => {
        let dateRangeChartData = _.merge(new DateRangeChartData(), data.dashboardData[dashboardDataObjectType])
        // first row of the table contains column names an tooltip data if any
        dateRangeChartData.chartData.dataTable = [
          [...this.translateStringsIn(chartColumns)],
          ...dateRangeChartData.chartData.dataTable,
        ]

        for (let i = 1; i < dateRangeChartData.chartData.dataTable.length; i++) {
          dateRangeChartData.chartData.dataTable[i][0] = moment(new Date(dateRangeChartData.chartData.dataTable[i][0]))
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0)
            .toDate()
          let val = dateRangeChartData.chartData.dataTable[i][1]
          let cutoffVal = dateRangeChartData.chartData.dataTable[i][2]
          dateRangeChartData.chartData.dataTable[i].push(
            this.translate.instant(tooltipCompliance, {
              val: val.toFixed(1),
            })
          )
          dateRangeChartData.chartData.dataTable[i].push(
            this.translate.instant(tooltipComplianceCutoff, {
              val: cutoffVal,
            })
          )
          // tooltip column must follow the value one
          dateRangeChartData.chartData.dataTable[i][2] = dateRangeChartData.chartData.dataTable[i][3]
          dateRangeChartData.chartData.dataTable[i][3] = cutoffVal
        }

        dateRangeChartData.chartData.options = { ..._.cloneDeep(chartOptions) }
        // TODO: PL: We always return 3 data points here and they can be not equidistant in time
        //  so for now just hardcode the X-axis labels
        this.adjustDataForNPointsChartWithEqualXAxisTicksSpacing(dateRangeChartData, dashboardParameters, 30)

        onDataLoadedFunc(dateRangeChartData)
      })

    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  public loadSingleValueDashboardData<T extends DashboardDataObjectResponseBase>(
    dashboardType: DashboardDataObjectType,
    scopeObjectType: ScopeObjectType,
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: T) => void
  ): CancellableRequestSubscription {
    let url = this.getApiUrl(dashboardParameters.organizationId)
    let payload = InsightsDashboardServiceHelper.createDashboardRequest(
      dashboardType,
      dashboardParameters,
      scopeObjectType
    )

    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardDataResponse<T>>(
      this.$http.post(url, payload, { timeout: cancellableRequest.promise }),
      DashboardDataResponse
    )
      .pipe(first())
      .subscribe((data: DashboardDataResponse<T>) => {
        onDataLoadedFunc(data.dashboardData[dashboardType])
      })

    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private static getScopeObjectId(params: InsightsDashboardParameters, scopeObjectType: ScopeObjectType): string {
    switch (scopeObjectType) {
      case ScopeObjectType.ORGANIZATION:
        return params.organizationId
      case ScopeObjectType.PROJECT_GROUP:
        return params.projectGroupId
      case ScopeObjectType.PROJECT:
      default:
        return params.projectId
    }
  }
}
