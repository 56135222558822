<mat-card class="insights-card" [class.dark-background-override]="darkTheme">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title class="mat-title" matTooltip="{{ cardTitle }}">{{ cardTitle }}</mat-card-title>
      <mat-card-subtitle class="mat-body-2" matTooltip="{{ subTitle }}">{{ subTitle }}</mat-card-subtitle>
    </mat-card-title-group>
    <span class="spacer"></span>
    <div *ngIf="toggleMenuGroups">
      <mat-button-toggle-group
        *ngFor="let toggleGroup of toggleMenuGroups"
        name="{{ toggleGroup.groupId }}"
        [disabled]="toggleGroup.isDisabled"
        [multiple]="toggleGroup.isMultiToggle"
      >
        <mat-button-toggle
          *ngFor="let toggleItem of toggleGroup.groupItems"
          value="{{ toggleItem.eventId }}"
          [disabled]="toggleItem.isDisabled"
          [checked]="toggleItem.isChecked"
          (change)="toggleMenuChanged($event, toggleItem)"
        >
          <mat-icon *ngIf="toggleItem.icon">{{ toggleItem.icon }}</mat-icon>
          {{ toggleItem.label | translate }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div *ngIf="menuItems" class="v-spacer">
    </div>
    <div *ngIf="menuItems">
      <button class="mat-icon-button" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <div *ngFor="let menuItem of menuItems">
          <button
            *ngIf="menuItem.isRegular"
            [disabled]="menuItem.isDisabled"
            (click)="menuItemClicked($event, menuItem)"
            class="mat-menu-item"
          >
            <mat-icon *ngIf="menuItem.icon">{{ menuItem.icon }}</mat-icon>
            <span>{{ menuItem.label | translate }}</span>
          </button>
          <mat-checkbox
            *ngIf="menuItem.isCheckBox"
            [disabled]="menuItem.isDisabled"
            [checked]="menuItem.isChecked"
            (change)="menuItemClicked($event, menuItem)"
            (click)="$event.stopPropagation()"
            class="mat-menu-item"
          >
            <mat-icon *ngIf="menuItem.icon">{{ menuItem.icon }}</mat-icon>
            <span>{{ menuItem.label }}</span>
          </mat-checkbox>
          <mat-radio-button
            *ngIf="menuItem.isRadioButton"
            [disabled]="menuItem.isDisabled"
            [checked]="menuItem.isChecked"
            [name]="menuItem.groupName"
            (change)="menuItemClicked($event, menuItem)"
            class="mat-menu-item"
          >
            <mat-icon *ngIf="menuItem.icon">{{ menuItem.icon }}</mat-icon>
            <span>{{ menuItem.label }}</span>
          </mat-radio-button>
        </div>
      </mat-menu>
    </div>
    <div *ngIf="hasTextInput" class="v-spacer">
    </div>
    <div *ngIf="hasTextInput" class="card-title-input-box">
      <input
        #cardInputControl
        class="card-title-input"
        type="text"
        placeholder="{{ textInputPlaceholder }}"
        (keyup)="onCardInputChange($event)"
      />
    </div>
  </mat-card-header>
  <mat-card-content [style.height]="contentHeight">
    <ng-content></ng-content>
  </mat-card-content>
  <mat-card-actions>
    <button
      *ngFor="let actionItem of actionItems"
      mat-stroked-button
      [disabled]="actionItem.isDisabled"
      (click)="actionItemClicked(actionItem)"
      class="card-action-button"
    >
      {{ actionItem.label }}
    </button>
  </mat-card-actions>
</mat-card>
