/* global angular, _ */

angular.module('smartvid').factory('UsersCollection', function (BaseCollection, UserModel, smartvidApi, $interval, $q) {
  class UsersCollection extends BaseCollection {
    constructor (organizationId) {
      super(undefined, UserModel)
      this.organizationId = organizationId
      this.nextPagePromise = $q.when()
      this.lastPage = 0
      this.isFetching = false
      this.options = {
        pageSize: 50
      }
      this.initialized = false
      this.nextPage()
      this.nextPagePromise.then(() => {
        this.initialized = true
      })
    }

    nextPage () {
      if (!this.canFetch) {
        return this.nextPagePromise
      }
      let defer = $q.defer()
      this.nextPagePromise.then(() => {
        this.isFetching = true
        let apiOptions = angular.copy(this.options)
        apiOptions.page = this.lastPage
        smartvidApi.getOrganizationUsers(this.organizationId, apiOptions).then((users) => {
          this.lastPage++
          super.upsert(users, UserModel)
          if (_.isEmpty(users)) {
            this.canFetch = false
          }
          this.isFetching = false
          defer.resolve()
        }, () => {
          this.isFetching = false
          defer.reject()
        })
      }, () => {
        this.isFetching = false
        defer.reject()
      })
      this.nextPagePromise = defer.promise
      return defer.promise
    }

    reset () {
      this.lastPage = false
      this.nextPagePromise = $q.when()
      super.reset()
    }
  }

  return UsersCollection
})
