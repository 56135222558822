/* global angular, $ */

angular.module('smartvid').directive('formFieldHelpRollover', function ($timeout, $templateCache, $compile) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      let template = attrs.template
      let templateHtml = $templateCache.get(template)
      let templateElement = angular.element(templateHtml)
      templateElement.insertAfter(element)
      $compile(templateElement)(scope)
      templateElement.hide()
      element.on('click mouseenter', function (event) {
        let isModalOpen = $('.modal-container').css('opacity') !== '0'
        let targetPosition
        if (isModalOpen) {
          targetPosition = element.offset()
          targetPosition.top -= $('.modal-content').offset().top
          targetPosition.left -= $('.modal-content').offset().left
        } else {
          targetPosition = $(element)[0].getBoundingClientRect()
        }
        let targetWidth = element.outerWidth()

        const ROLLOVER_WIDTH = 300

        let left = ($(element).offset().left + ROLLOVER_WIDTH) > $(window).width()

        templateElement.css({
          position: 'absolute',
          'z-index': 9999,
          top: (targetPosition.top - 12) + 'px'
        })

        if (left) {
          templateElement.css({
            left: (targetPosition.left - ROLLOVER_WIDTH - 20) + 'px'
          })
          templateElement.removeClass('form-field-help-rollover-right')
          templateElement.addClass('form-field-help-rollover-left')
        } else {
          templateElement.css({
            left: (targetPosition.left + targetWidth + 20) + 'px'
          })
          templateElement.removeClass('form-field-help-rollover-left')
          templateElement.addClass('form-field-help-rollover-right')
        }
        if (!isModalOpen) {
          $(templateElement).detach().prependTo('.page')
        } else {
          $(templateElement).detach().prependTo('.modal-content')
        }
        templateElement.show()
      })
      element.on('mouseleave', function (event) {
        templateElement.hide()
      })
    }
  }
})
