/* global angular */

angular.module('smartvid').service('integrationSettingsApi', function ($http, $q, $log, config) {
  let rootUrl = config.env.development.apiRootUrl

  let api = {
    getIntegrationSettingsForProject (integrationType, projectId) {
      let url = `${rootUrl}/api/integration/${integrationType}/settings/project/${projectId}`
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('getIntegrationSettingsForProject', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    updateIntegrationSettingsForOrganization (request, organizationId) {
      let url = `${rootUrl}/api/integration/settings/organization/${organizationId}`
      let defer = $q.defer()

      $http.put(url, request).then((response) => {
        $log.debug('updateIntegrationSettingsForOrganization', response.data)
      defer.resolve(response.data)
    }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getIntegrationSettingsForOrganization (organizationId) {
      let url = `${rootUrl}/api/integration/settings/organization/${organizationId}`
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('getIntegrationSettingsForOrganization', response.data)
      defer.resolve(response.data)
    }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    saveIntegrationSettings (integrationType, settings) {
      let url = `${rootUrl}/api/integration/${integrationType}/settings`
      let defer = $q.defer()

      $http.post(url, settings).then((response) => {
        $log.debug('saveIntegrationSettings', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    enableRunOnceSyncIntegrationSettingsForProject (integrationType, projectId) {
      let url = `${rootUrl}/api/integration/${integrationType}/sync/project/${projectId}`
      let defer = $q.defer()

      $http.put(url).then((response) => {
        $log.debug('enableRunOnceSyncIntegrationSettingsForProject', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    deleteIntegrationSettings (integrationType, projectId) {
      let url = `${rootUrl}/api/integration/${integrationType}/settings/project/${projectId}`
      let defer = $q.defer()

      $http.delete(url).then((response) => {
        $log.debug('deleteIntegrationSettings', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    }
  }

  return api
})
