<div class="project-group-assets-insights-dashboard-activity mdc-layout-grid__inner">
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'PROJECT_GROUP_ASSETS_ACTIVITY_BY_PROJECT' }">
    <sv-insights-card
      #activityByProjectCard
      contentHeight="400px"
      cardTitle="{{ 'dashboard.cards.projectGroupActivityByProject.titleProject' | translate }}"
      subTitle="{{ activityTableData?.length }} {{
        'dashboard.cards.projectGroupActivityByProject.subTitleProject' | translate
      }}"
      class="project-group-activity-table-card"
      hasTextInput="true"
      textInputPlaceholder="{{
        'dashboard.cards.projectGroupActivityByProject.searchInputPlaceholderProject' | translate
      }}"
      (cardInputChange)="activityTableFilterChanged($event, activityByProjectCard)"
    >
      <sv-project-group-assets-activity-table
        #activityByProjectCard
        [tableData]="activityTableData"
      ></sv-project-group-assets-activity-table>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'PROJECT_GROUP_ASSETS_UPLOADS_OVERTIME_BY_SOURCE' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupUploadsOverTimeBySource.title' | translate }}"
      subTitle="{{ projectGroupUploadsOverTimeBySourceData?.startDate.toDateString() }} - {{
        projectGroupUploadsOverTimeBySourceData?.endDate.toDateString()
      }}"
      [toggleMenuGroups]="uploadsBySourceChartMenu"
      (toggleMenuItemSelected)="uploadsBySourceChartMenuSelected($event)"
    >
      <sv-insights-column-chart
        [chartData]="projectGroupUploadsOverTimeBySourceData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'PROJECT_GROUP_ASSETS_UPLOADERS_OVERTIME' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupUploadersCountOverTime.title' | translate }}"
      subTitle="{{ projectGroupUploadersOverTimeData?.startDate.toDateString() }} - {{
        projectGroupUploadersOverTimeData?.endDate?.toDateString()
      }}"
      [toggleMenuGroups]="uploadersChartMenu"
      (toggleMenuItemSelected)="uploadersChartMenuSelected($event)"
    >
      <sv-insights-column-chart
        [chartData]="projectGroupUploadersOverTimeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'PROJECT_GROUP_ASSETS_CONSTRUCTION_ACTIVITY' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupConstructionActivity.title' | translate }}"
      subTitle="{{ projectGroupConstructionActivityData?.startDate.toDateString() }} - {{
        projectGroupConstructionActivityData?.endDate.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectGroupConstructionActivityData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'PROJECT_GROUP_ASSETS_PHOTO_DESCRIPTION' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupPhotoDescription.title' | translate }}"
      subTitle="{{ projectGroupPhotoDescriptionData?.startDate.toDateString() }} - {{
        projectGroupPhotoDescriptionData?.endDate.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectGroupPhotoDescriptionData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'PROJECT_GROUP_ASSETS_UPLOADS_BY_FILE_TYPE' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupUploadsByFileType.title' | translate }}"
      subTitle="{{ projectGroupUploadsByFileTypeData?.startDate.toDateString() }} - {{
        projectGroupUploadsByFileTypeData?.endDate.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectGroupUploadsByFileTypeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'PROJECT_GROUP_ASSETS_TAGS_BY_TYPE' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupTagsByType.title' | translate }}"
      subTitle="{{ projectGroupTagsByTypeData?.startDate.toDateString() }} - {{
        projectGroupTagsByTypeData?.endDate.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectGroupTagsByTypeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
</div>
