import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { RouterState } from './router.state'
import { ObservationListState } from './observation-list.state'
import { ObservationCommonState } from './observation-common.state'
import { ObservationViewState } from './observation-view.state'
import { ObservationProjectHomeState } from './observation-project-home.state'
import { ObservationOrganizationHomeState } from './observation-organization-home.state'
import { environment } from '../../../../environments/environment'

let checkDevMode = false
if (!environment.production) {
  checkDevMode = true
}

@NgModule({
  imports: [
    NgxsModule.forRoot(
      [
        RouterState,
        ObservationCommonState,
        ObservationViewState,
        ObservationListState,
        ObservationProjectHomeState,
        ObservationOrganizationHomeState,
      ],
      {
        developmentMode: checkDevMode,
      }
    ),
    NgxsStoragePluginModule.forRoot({
      key: ['observationList.sortType', 'observationList.quickFilterCriteria'],
    }),
    checkDevMode ? NgxsReduxDevtoolsPluginModule.forRoot() : [],
  ],
  providers: [],
})
export class AppStateModule {}
