/* global angular */
angular.module('smartvid').service('scrollService', function () {
  /*
    May want this to survive page reload by using different persistence layer
    but be aware that this could cause problems w/ paginated content like assets and users.
    Specifying the persistence layer and any other parameters can be extended through
    the 'params' variable.
    -Ryan
  */

  let scrollPositionMap = {} // Map where the keys are the unique element ids and the values are scroll distances

  let service = {
    getScrollPosition: function (uniqueId, params) {
      return (scrollPositionMap[uniqueId]) ? scrollPositionMap[uniqueId] : 0
    },

    setScrollPosition: function (uniqueId, value, params) {
      scrollPositionMap[uniqueId] = value
    },

    resetForKey: function (uniqueId) {
      scrollPositionMap[uniqueId] = 0
    },
    reset: function () {
      scrollPositionMap = {}
    }
  }

  return service
})
