/* global angular */
/* **** PROPERTIES *****
  {
    id:               [String]  Unique ID for save scroll instance. Will be used to save and lookup scroll point
    selector:         [String]  jQuery type selector of container to measure scroll distance against
    performScroll:    [Boolean] Variable that tells this directive whether to perform scroll or not
    performFade:      [Boolean] Tells directive whether to fade in contents after scroll
  }
**********************/
angular.module('smartvid').directive('saveScroll', function ($log, $timeout, $rootScope, scrollService) {
  return {
    restrict: 'A',
    link: function (scope, elem, attrs) {
      const DEFAULT_ID = 'default-ss-id'
      var options = (attrs.saveScroll) ? angular.fromJson(attrs.saveScroll) : {}
      var scrollDistance = 0
      var selector = options.selector
      var performFade = options.performFade
      var delay = options.delay ? options.delay : 0
      var scrollElem = (selector ? elem.find(selector) : angular.element(elem))
      attrs.$observe('saveScroll', updateOptions)

      function updateOptions (newVal) {
        options = angular.fromJson(newVal)
        if (options.selector !== selector) {
          selector = options.selector
          scrollElem = (selector ? elem.find(selector) : angular.element(elem))
        }
      }

      function onScroll () {
        scrollDistance = scrollElem.scrollTop()
      }

      function saveScroll () {
        scrollService.setScrollPosition(options.id || DEFAULT_ID, scrollDistance)
      }

      function scrollToPosition () {
        let scrollPos = scrollService.getScrollPosition(options.id || DEFAULT_ID, {})
        if (scrollPos === 0 || options.performScroll === false) { return }
        if (scrollPos !== undefined) {
          try {
            if (performFade) {
              scrollElem.fadeTo(0, 0)
            } else {
              scrollElem.css('visibility', 'hidden')
            }
            let performScroll = () => {
              scrollElem.scrollTop(parseInt(scrollPos, 10))
              if (performFade) {
                scrollElem.fadeTo(300, 1)
              } else {
                scrollElem.css('visibility', 'visible')
              }
            }
            $timeout(performScroll, delay)
          } catch (e) {
            $log.error('Could not parse scroll distance value into an int ', e)
            if (performFade) {
              scrollElem.fadeTo(0, 1)
            } else {
              scrollElem.css('visibility', 'visible')
            }
          }
        } else {
          scrollElem.scrollTop(0)
        }
      }

      scope.$on('perform-save-scroll-if-needed', (evt, scrollId) => {
        if (options.id === scrollId) {
          scrollToPosition()
        }
      })

      scope.$on('perform-scroll-top', () => {
        scrollElem.scrollTop(0)
        scrollDistance = 0
      })

      function onDestroy () {
        saveScroll()
        scrollElem.off('scroll', onScroll)
      }

      scrollElem.on('scroll', onScroll)
      scope.$on('$destroy', onDestroy)
      scrollToPosition()
    }
  }
})
