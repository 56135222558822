<div class="project-group-assets-activity-table-container">
  <mat-spinner class="table-spinner" diameter="64" *ngIf="!tableData"></mat-spinner>
  <table
    mat-table
    [dataSource]="dataSource"
    class="project-group-assets-activity-table"
    *ngIf="tableData"
    (mousewheel)="onMouseWheel($any($event))"
  >
    <ng-container matColumnDef="score" [sticky]="tableData.length > 0">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'dashboard.projectGroupAssets.projectActivityTable.columns.score' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-score">
        <div class="name-cell">
          <p
            class="mat-body-2 project-score-table-cell-dot {{
              projectScoreColorClassMap[row.scopeObjectInfo.scopeObjectId]
            }}"
          >
            {{
              isNA(row.scopeObjectCountsInfo.projectScore)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.projectScore | number: '1.0-0')
            }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef>
        {{ 'dashboard.projectGroupAssets.projectActivityTable.columns.name' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-name">
        <div class="name-cell">
          <p class="mat-body-2 title-text">{{ row.scopeObjectInfo.scopeObjectName }}</p>
          <p class="mat-caption subtitle-text">{{ row.organizationName }}</p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="files">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'dashboard.projectGroupAssets.projectActivityTable.columns.files' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-other">
        <div>
          <p class="mat-body-2 title-text">
            {{
              isNA(row.scopeObjectCountsInfo.filesCount)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.filesCount | number)
            }}
          </p>
          <p
            class="mat-caption subtitle-text"
            [ngClass]="getColorClassForValue(row.scopeObjectCountsInfo.filesCountRatio, [0, 0.0001])"
          >
            {{ getSign(row.scopeObjectCountsInfo.filesCountRatio)
            }}{{
              isNA(row.scopeObjectCountsInfo.filesCount)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.filesCountRatio | number: '.1-1') + '%'
            }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'dashboard.projectGroupAssets.projectActivityTable.columns.tags' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-other">
        <div>
          <p class="mat-body-2 title-text">
            {{
              isNA(row.scopeObjectCountsInfo.tagCount)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.tagCount | number)
            }}
          </p>
          <p
            class="mat-caption subtitle-text"
            [ngClass]="getColorClassForValue(row.scopeObjectCountsInfo.tagCountRatio, [0, 0.0001])"
          >
            {{ getSign(row.scopeObjectCountsInfo.tagCountRatio)
            }}{{
              isNA(row.scopeObjectCountsInfo.tagCount)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.scopeObjectCountsInfo.tagCountRatio | number: '.1-1') + '%'
            }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="assetCountsForTimePeriod">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'dashboard.projectGroupAssets.projectActivityTable.columns.assetCountsForTimePeriod' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-chart">
        <div class="chart-cell">
          <sv-insights-area-chart [chartData]="row.chartData" [isResponsive]="false"></sv-insights-area-chart>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div [hidden]="!tableData" class="totals-table-paginator">
  <mat-paginator [pageSizeOptions]="[4]" showFirstLastButtons hidePageSize></mat-paginator>
</div>
