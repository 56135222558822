/* global angular, _ */

angular.module('smartvid').factory('AssetSearchContext', function (TAG_CONFIDENCE_LEVEL_HIGH, SearchEntityModel,
                                    SEARCH_ENTITY_MODEL_TYPE_TAG_DEFINITION, SEARCH_ENTITY_MODEL_TYPE_USER) {
  class AssetSearchContext {
    constructor (searchTags, searchCreatedByUsers, textFragments, assetGroup,
      tagConfidenceLevel, searchDateRange, userTimeZone, searchQueryType, includedProjectIds) {
      this.searchTags = searchTags
      this.searchCreatedByUsers = searchCreatedByUsers
      this.textFragments = textFragments
      this.assetGroup = assetGroup
      this.tagConfidenceLevel = tagConfidenceLevel || TAG_CONFIDENCE_LEVEL_HIGH
      this.searchQueryType = searchQueryType
      this.searchDateRange = searchDateRange
      this.userTimeZone = userTimeZone
      this.searchQueryType = searchQueryType
      this.includedProjectIds = includedProjectIds
    }

    isOnlySearchByTagDefs () {
      return (!this.searchCreatedByUsers || this.searchCreatedByUsers.length === 0)  &&
        (!this.textFragments || this.textFragments.length === 0)
    }

    isAllSearch () {
      return _.isEmpty(this.searchCreatedByUsers) && _.isEmpty(this.textFragments) && _.isEmpty(this.searchTags)
    }

    getSearchByTagDefs () {
      return _.map(this.searchTags, (t) => {
        return {
          tagDefId: t.id,
          hasChildren: t.hasChildren
        }
      })
    }

    getSearchByUserIds () {
      return _.map(this.searchCreatedByUsers, (u) => {
        return u.id
      })
    }

    getSearchByValues () {
      var result = _.union(
        _.map(this.searchTags, (tag) => {
          return new SearchEntityModel({
            id: tag.id,
            text: tag.text,
            type: SEARCH_ENTITY_MODEL_TYPE_TAG_DEFINITION,
            hasChildren: tag.hasChildren
          })
        }),
        _.map(this.searchCreatedByUsers), (user) => {
          return new SearchEntityModel({
            id: user.id,
            text: user.email,
            type: SEARCH_ENTITY_MODEL_TYPE_USER,
            hasChildren: false
          })
        },
        _.map(this.textFragments, (textFragment) => {
          return {
            text: textFragment
          }
        })
      )
      result.searchDateRange = this.searchDateRange
      result.searchQueryType = this.searchQueryType
      return result
    }
  }

  return AssetSearchContext
})
