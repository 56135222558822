<div class="mdc-layout-grid__inner">
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'PROJECT_GROUP_ASSETS_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupAvgPhotoCreatedByDayOfWeek.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-column-chart
        [chartData]="projectGroupAvgPhotosCreatedByDayOfWeekData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'PROJECT_GROUP_ASSETS_AVG_PHOTOS_CREATED_PER_DAY' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.projectGroupAvgPhotoCreatedPerDay.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="projectGroupSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(projectGroupSingleValueData.avgPhotosCreatedPerDay)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.avgPhotosCreatedPerDay | number: '1.0-0')
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ avgPhotosCreatedPerDayPercentChangeClassName }}">
          {{ getSign(projectGroupSingleValueData.avgPhotosCreatedPerDayPercentChange)
          }}{{
            isNA(projectGroupSingleValueData.avgPhotosCreatedPerDay)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.avgPhotosCreatedPerDayPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div
    *svDashboardGridCell="{
      tabId: 'IMAGE_HEALTH',
      cardId: 'PROJECT_GROUP_ASSETS_AVG_TIME_BETWEEN_PHOTO_CREATION_AND_UPLOAD'
    }"
  >
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.projectGroupAvgTimeDiffPhotoCreatedUploaded.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="projectGroupSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(projectGroupSingleValueData.avgPhotoDiffCreatedUploaded)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.avgPhotoDiffCreatedUploaded | number: '1.0-0')
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ avgPhotoDiffCreatedUploadedPercentChangeClassName }}">
          {{ getSign(projectGroupSingleValueData.avgPhotoDiffCreatedUploadedPercentChange)
          }}{{
            isNA(projectGroupSingleValueData.avgPhotoDiffCreatedUploaded)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.avgPhotoDiffCreatedUploadedPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'PROJECT_GROUP_ASSETS_CONSTRUCTION_TO_ALL_RATIO_AVG' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.projectGroupConstructionToAllPhotosRatio.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="projectGroupSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(projectGroupSingleValueData.constructionPhotosToAllPhotosRatioAvg)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.constructionPhotosToAllPhotosRatioAvg | number: '.1-1') + ' to 1'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ constructionPhotosToAllPhotosRatioPercentChangeClassName }}">
          {{ getSign(projectGroupSingleValueData.constructionPhotosToAllPhotosRatioAvgPercentChange)
          }}{{
            isNA(projectGroupSingleValueData.constructionPhotosToAllPhotosRatioAvg)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.constructionPhotosToAllPhotosRatioAvgPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'PROJECT_GROUP_ASSETS_PERSON_COVERAGE_PERCENT' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.projectGroupPersonCoveragePercent.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="projectGroupSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(projectGroupSingleValueData.percentPersonCoverage)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.percentPersonCoverage | number: '1.0-0') + '%'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ percentPersonCoveragePercentChangeClassName }}">
          {{ getSign(projectGroupSingleValueData.percentPersonCoveragePercentChange)
          }}{{
            isNA(projectGroupSingleValueData.percentPersonCoverage)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.percentPersonCoveragePercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'PROJECT_GROUP_ASSETS_PHOTOS_WITHOUT_CREATED_DATE' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.projectGroupPhotosWithoutCreatedDate.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="projectGroupSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(projectGroupSingleValueData.percentOfAssetsWithoutCreatedDate)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.percentOfAssetsWithoutCreatedDate | number: '1.0-0') + '%'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ percentOfAssetsWithoutCreatedDatePercentChangeClassName }}">
          {{ getSign(projectGroupSingleValueData.percentOfAssetsWithoutCreatedDatePercentChange)
          }}{{
            isNA(projectGroupSingleValueData.percentOfAssetsWithoutCreatedDate)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.percentOfAssetsWithoutCreatedDatePercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div
    *svDashboardGridCell="{
      tabId: 'IMAGE_HEALTH',
      cardId: 'PROJECT_GROUP_ASSETS_CONSTRUCTION_PHOTOS_WITHOUT_CREATED_DATE'
    }"
  >
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.projectGroupConstructionPhotosWithoutCreatedDate.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="projectGroupSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(projectGroupSingleValueData.percentOfConstructionAssetsWithoutCreatedDate)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.percentOfConstructionAssetsWithoutCreatedDate | number: '1.0-0') + '%'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ percentOfConstructionAssetsWithoutCreatedDatePercentChangeClassName }}">
          {{ getSign(projectGroupSingleValueData.percentOfConstructionAssetsWithoutCreatedDatePercentChange)
          }}{{
            isNA(projectGroupSingleValueData.percentOfConstructionAssetsWithoutCreatedDate)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.percentOfConstructionAssetsWithoutCreatedDatePercentChange
                  | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
</div>
