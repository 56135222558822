/* global angular STRUCTION_SITE_INTEGRATION_UI_ENABLED _ */

angular.module('smartvid').directive('projectIntegrationsStructionSite', function (
    $timeout,
    $filter,
    $interpolate,
    $stateParams,
    $state,
    $rootScope,
    utils,
    smartvidApi,
    Notification,
    dashboardDataHelper,
    modal,
    projectIntegrationUtils,
    IntegrationSettingsState,
    StrucitonSiteProjectInfoCollection,
    StructionSiteProjectInfoModel,
    StructionSiteUserModel
) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'integrations/project-integrations-struction-site.html',
        link: function (scope) {
            scope.project = dashboardDataHelper.getCurrentProject()
            if (!scope.project.canUpdate) {
                $state.go('unauthorized')
                return
            }

            const STRUCTION_SITE = 'STRUCTION_SITE'
            const STRUCTION_SITE_SYNC = 'STRUCTION_SITE_SYNC'

            const availableStructionSiteDownloadAssetsOptions = {
                VIDEOWALKS_AND_IMAGES: 'VIDEOWALKS_AND_IMAGES',
                IMAGES_ONLY: 'IMAGES_ONLY',
                VIDEOWALKS_ONLY: 'VIDEOWALKS_ONLY'
            }

            let availableStructionSiteHistoricalDataSyncDepthOptions = [
                {
                    name: $filter('i18next')('integrations.structionSite.historicalDataNone'),
                    value: 'NONE'
                },
                {
                    name: $filter('i18next')('integrations.structionSite.historicalData7Days'),
                    value: 'PAST_7'
                },
                {
                    name: $filter('i18next')('integrations.structionSite.historicalData30Days'),
                    value: 'PAST_30'
                },
                {
                    name: $filter('i18next')('integrations.structionSite.historicalData60Days'),
                    value: 'PAST_60'
                },
                {
                    name: $filter('i18next')('integrations.structionSite.historicalDataAll'),
                    value: 'ALL'
                },
            ]

            scope.historicalDataSyncDepthOptions = availableStructionSiteHistoricalDataSyncDepthOptions
            scope.downloadAssetsOptions = availableStructionSiteDownloadAssetsOptions

            scope.integrationSettingsState = new IntegrationSettingsState()
            scope.integrationSettingsState.setCreatePartnerSyncSettingsFunc(settingsData => {
                return settingsData
            })

            scope.connectionStatus = ''
            scope.structionSiteProjects = {}
            scope.structionSiteUserInfo = {}

            let smartvidProjectId = $stateParams.projectId

            let initializeSelectedData = () => {
                scope.selectedData = {
                    historicalDataSyncDepth: availableStructionSiteHistoricalDataSyncDepthOptions[0],
                    downloadAssetsOption : availableStructionSiteDownloadAssetsOptions.VIDEOWALKS_AND_IMAGES
                }
            }

            let resetStructionSiteProjects = () => {
                scope.structionSiteProjects = new StrucitonSiteProjectInfoCollection()
            }

            let resetDefaults = () => {
                initializeSelectedData()
                scope.integrationSettingsState.clear()
                resetStructionSiteProjects()
                scope.integrationSettingsState.isEnabled = false
            }

            scope.isStructionSiteIntegrationUiEnabled = () => {
                return STRUCTION_SITE_INTEGRATION_UI_ENABLED
            }

            scope.isProjectDataUnavailable = () => {
                return !scope.structionSiteProjects || scope.structionSiteProjects.length === 0
            }

            scope.getProjectDataUnavailableMessage = () => {
                if (scope.isProjectDataUnavailable() && scope.isPartnerIntegrationEnabledForProject()) {
                    return $filter('i18next')('integrations.structionSite.projectPlaceholder')
                }

                return $filter('i18next')('integrations.unavailablePlaceholder')
            }

            scope.integrationSettingsState.isLoadingData = true
            // initialize page
            $timeout(() => {
                scope.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
                resetDefaults()
                projectIntegrationUtils.checkIntegrationStatusAndLoadData(
                    scope.integrationSettingsState,
                    smartvidProjectId,
                    STRUCTION_SITE,
                    STRUCTION_SITE_SYNC,
                    false
                )
            }, 10)

            scope.disableStructionSiteIntegration = () => {
                setLoading(true)
                smartvidApi.deleteIntegrationSettings(STRUCTION_SITE_SYNC, smartvidProjectId).then(
                    () => {
                        scope.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
                        resetDefaults()
                        projectIntegrationUtils.checkIntegrationStatusAndLoadData(
                            scope.integrationSettingsState,
                            smartvidProjectId,
                            STRUCTION_SITE,
                            STRUCTION_SITE_SYNC,
                            false
                        )
                    },
                    () => {
                        resetDefaults()
                        setLoading(false)
                    }
                )
            }

            scope.disconnect = () => {
                if (scope.isPartnerIntegrationEnabledForProject()) {
                    scope.disableStructionSiteIntegration()
                }
            }

            scope.isStructionSiteProjectSelected = () => {
                return scope.selectedData.structionSiteProject
            }

            scope.canLinkProject = () => {

                return (
                    scope.integrationSettingsState.isPartnerIntegrationEnabledByCurrentUser && scope.isStructionSiteProjectSelected()
                )
            }

            scope.linkProject = () => {
                scope.integrationSettingsState.partnerIntegrationSettings.isEnabled = true
                scope.integrationSettingsState.syncOnlyAfterDate = Date.now()
                scope.saveStrcuctionSiteSyncSettings(enableStructionSiteIntegration)
            }

            scope.unlinkProject = () => {
                scope.integrationSettingsState.partnerIntegrationSettings.isEnabled = false
                scope.saveStrcuctionSiteSyncSettings()
            }

            let adjustConnectionStatus = () => {
                if (scope.integrationSettingsState.isAuthenticationTokenExpired) {
                    let structionSiteUser = scope.structionSiteUserInfo ? scope.structionSiteUserInfo.id : ''
                    scope.connectionStatus = $interpolate(
                        $filter('i18next')('integrations.structionSite.connection.authTokenExpired')
                    )({
                        structionSiteUser: structionSiteUser
                    })
                } else if (scope.integrationSettingsState.isPartnerIntegrationEnabledByCurrentUser) {
                    let structionSiteUser = scope.structionSiteUserInfo ? scope.structionSiteUserInfo.id : ''
                    scope.connectionStatus = $interpolate(
                        $filter('i18next')('integrations.structionSite.connection.connectedAsUser')
                    )({
                        structionSiteUser: structionSiteUser
                    })
                } else if (scope.integrationSettingsState.isPartnerIntegrationEnabledByOtherUser) {
                    scope.connectionStatus = $interpolate(
                        $filter('i18next')('integrations.connection.connectedAsSmartvidUser')
                    )({
                        smartvidUser: ''
                    })
                }
            }

            scope.saveStrcuctionSiteSyncSettings = (afterSaveFunc) => {
                scope.setIntegrationSettingsState()

                smartvidApi
                    .saveIntegrationSettings(STRUCTION_SITE_SYNC, scope.integrationSettingsState.partnerIntegrationSettings)
                    .then(
                        () => {
                            if (afterSaveFunc) {
                                afterSaveFunc()
                            }
                        },
                        () => {
                            if (scope.integrationSettingsState) {
                                scope.integrationSettingsState.partnerIntegrationSettings.isEnabled = false
                            }
                        }
                    )
            }

            let enableStructionSiteIntegration = () => {
                smartvidApi.enableStructionSiteIntegration(smartvidProjectId, customErrorHandler)
            }

            scope.isReadOnly = () => {
                return projectIntegrationUtils.isReadOnly(scope.integrationSettingsState)
            }

            let setLoading = value => {
                scope.integrationSettingsState.isLoadingData = value
                utils.digest(scope)
            }

            scope.setIntegrationSettingsState = () => {
                scope.integrationSettingsState.partnerIntegrationSettings.settingsData.historicalDataSyncDepth = scope.selectedData.historicalDataSyncDepth.value
                scope.integrationSettingsState.partnerIntegrationSettings.settingsData.structionSiteProjectId = scope.selectedData.structionSiteProject.id
                scope.integrationSettingsState.partnerIntegrationSettings.settingsData.downloadAssetsOption = scope.selectedData.downloadAssetsOption
            }

            scope.reloadProjects = () => {
                scope.selectedData.structionSiteProject = undefined
                scope.loadStructionSiteData(false)
            }

            let customErrorHandler = response => {
                if (
                    response &&
                    projectIntegrationUtils.checkIfAuthenticationTokenExpired(
                        scope.integrationSettingsState,
                        response.errorCode,
                        response.errorMessages
                    )
                ) {
                    scope.expiredAuthenticationPartnerUserId = scope.integrationSettingsState.syncSettings.partnerUserId
                    resetDefaults()
                    adjustConnectionStatus()
                    scope.selectedData.downloadAssetsOption = undefined

                    return true
                }

                Notification.error($filter('i18next')('integrations.structionSite.errors.failedToLoadSyncSettings'))
                return true
            }

            scope.connect = () => {
                if (!scope.expiredAuthenticationPartnerUserId &&
                    projectIntegrationUtils.isAutoConnect(scope.integrationSettingsState)
                ) {
                    projectIntegrationUtils.enableIntegration(
                        scope.integrationSettingsState,
                        smartvidProjectId,
                        STRUCTION_SITE,
                        STRUCTION_SITE_SYNC
                    )
                } else {
                    projectIntegrationUtils.performDirectLoginAndEnableIntegration(
                        scope,
                        $rootScope,
                        'sv-connected-to-structionsite',
                        'integrationStructionSiteLogin',
                        scope.integrationSettingsState,
                        smartvidProjectId,
                        STRUCTION_SITE,
                        STRUCTION_SITE_SYNC
                    )
                }
            }

            scope.loadStructionSiteData = (updateSelected) => {
                setLoading(true)
                scope.expiredAuthenticationPartnerUserId = undefined
                smartvidApi.listStructionSiteProjectInfo(smartvidProjectId, customErrorHandler).then(
                    data => {
                        scope.structionSiteProjects = new StrucitonSiteProjectInfoCollection(_.sortBy(data, 'name'))
                        if (updateSelected) {
                            let settings = scope.integrationSettingsState.partnerIntegrationSettings.settingsData
                            if (settings.structionSiteProjectId) {
                                scope.selectedData.structionSiteProject = _.find(scope.structionSiteProjects.models,
                                    p => {
                                        return p.id === settings.structionSiteProjectId
                                    })
                            }
                            if (settings.historicalDataSyncDepth) {
                                scope.selectedData.historicalDataSyncDepth = _.find(scope.historicalDataSyncDepthOptions,
                                    o => {
                                        return o.value === settings.historicalDataSyncDepth
                                    })
                            }
                            if (settings.downloadAssetsOption) {
                                scope.selectedData.downloadAssetsOption = settings.downloadAssetsOption
                            } else {
                                scope.selectedData.downloadAssetsOption = availableStructionSiteDownloadAssetsOptions.VIDEOWALKS_AND_IMAGES
                            }
                        }
                        setLoading(false)
                    },
                    () => {
                        setLoading(false)
                    }
                )
            }

            scope.isPartnerIntegrationEnabledForProject = _.bind(
                projectIntegrationUtils.isPartnerIntegrationEnabledForProject,
                undefined,
                scope.integrationSettingsState
            )

            scope.integrationSettingsState.setLoadPageData(integrationSettingsState => {
                let partnerUserId = projectIntegrationUtils.getPartnerUserId(integrationSettingsState)
                if (partnerUserId) {
                    scope.structionSiteUserInfo = new StructionSiteUserModel({
                        id: partnerUserId
                    })
                    adjustConnectionStatus()
                    scope.loadStructionSiteData(true)
                } else {
                    scope.loadStructionSiteData(true)
                }
            })

            scope.getNextSyncDate = _.bind(
                projectIntegrationUtils.getNextSyncDate,
                undefined,
                scope.integrationSettingsState
            )

            scope.getLastSyncDate = _.bind(
                projectIntegrationUtils.getLastSyncDate,
                undefined,
                scope.integrationSettingsState
            )

            scope.isConnectionStatusTextClipped = _.bind(
                projectIntegrationUtils.isConnectionStatusTextClipped,
                undefined,
                '#connectionStatus'
            )
        }
    }
})
