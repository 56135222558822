import { Action, Selector, State, StateContext } from '@ngxs/store'
import { RawParams } from '@uirouter/core'
import { RouteChangeSuccess, RouterTransition, RouteChangeStart } from './router.actions'
import { MatDialog } from '@angular/material/dialog'
import { Injectable } from '@angular/core'

export interface RouterStateModel {
  previousState: { name: string; params: RawParams }
}

@State<RouterStateModel>({
  name: 'router',
  defaults: {
    previousState: { name: undefined, params: undefined },
  },
})
@Injectable()
export class RouterState {
  constructor(private dialog: MatDialog) {}

  @Selector()
  static previousState(state: RouterStateModel) {
    return state.previousState
  }

  @Action(RouteChangeSuccess)
  routeChangeSuccess({ patchState }: StateContext<RouterStateModel>, { transition }: RouteChangeSuccess) {
    patchState({
      previousState: {
        name: transition.from,
        params: transition.fromParams,
      },
    })
  }

  @Action(RouteChangeStart)
  routeChangeStart(ctx: StateContext<RouterStateModel>, { transition }: RouteChangeSuccess) {
    this.closeAllDialogs(transition)
  }

  closeAllDialogs(transition: RouterTransition) {
    const routes = ['timeout', 'login', 'unauthorized']
    if (routes.includes(transition.to)) {
      if (this.dialog.openDialogs.length > 0) {
        this.dialog.closeAll()
      }
    }
  }
}
