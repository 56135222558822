/* global angular _ */

angular.module('smartvid').factory('ProcoreUserModel', function (BaseModel) {
  class ProcoreUserModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined,
        first_name: undefined,
        last_name: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return ProcoreUserModel
})
