import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { SmartvidMaterialModule } from '../material/material.module'
import { ForgeFolderTreeComponent } from 'modules/integrations/components/forge-folder-tree/forge-folder-tree.component'
import { ForgeTemplateTreeComponent } from './components/forge-template-tree/forge-template-tree.component'
import { ForgeIssueTypeTreeComponent } from 'modules/integrations/components/forge-issue-type-tree/forge-issue-type-tree.component'

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, SmartvidMaterialModule],
  declarations: [ForgeFolderTreeComponent, ForgeTemplateTreeComponent, ForgeIssueTypeTreeComponent],
  entryComponents: [ForgeFolderTreeComponent, ForgeTemplateTreeComponent, ForgeIssueTypeTreeComponent],
})
export class AppIntegrationsModule {}
