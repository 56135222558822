/* global angular */

angular.module('smartvid').directive('hasOverflow', ($timeout) => {
  return {
    restrict: 'AE',
    scope: {
      overflowModel: '=',
      force: '='
    },
    link ($scope, element) {
      let update = () => {
        $timeout(function () {
          $scope.overflowModel.hasOverflow = element[0].scrollHeight > element[0].clientHeight + 5 || element[0].scrollWidth > element[0].clientWidth + 5
        }, 10)
      }

      $scope.$watch('force', function () {
        update()
      })

      $scope.$watch('overflowModel', function () {
        update()
      })
      update()
    }
  }
})
