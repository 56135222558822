/* global angular, _ */
/**
 * Created by ryanrubbico on 12/8/17.
 */
angular.module('smartvid').controller('BaseStorageServiceCtrl', function BaseStorageServiceCtrl (
    $timeout, $interpolate, $filter, $log, StorageServiceSyncSettingsModel, smartvidApi, dashboardDataHelper,
    IntegrationSettingsState, projectIntegrationUtils, StorageServiceUserModel, StorageServiceFolderModel,
    StorageServiceFolderCollection, Notification, moment,
    /* injected using routes */
    providerName, integrationType
) {
  const that = this
  that.currentProject = dashboardDataHelper.getCurrentProject()
  that.connectionStatus
  that.folders = []
  that.expandedFolders = []
  that.selectedData = {}
  that.hasRateLimitError = false
  that.userInfo
  that.folderTreeOptions = {
    nodeChildren: 'folders',
    dirSelectable: true,
    allowDeselect: false,
    isLeaf: (node) => node.folders !== undefined && node.folders.length === 0
  }
  that.integrationSettingsState = new IntegrationSettingsState()
  that.prettyName = $filter('i18next')('integrations.storageService.prettyNames.' + providerName)
  that.providerNameLowercase = $filter('lowercase')(providerName)
  that.providerNameInterpObj = { providerName: that.prettyName }

  that.connect = connect
  that.isIntegrationUiEnabled = isIntegrationUiEnabled
  that.getRootFolders = getRootFolders
  that.getChildFolders = getRootFolders
  that.isPartnerIntegrationEnabledForProject = isPartnerIntegrationEnabledForProject
  that.selectAndLoadFolder = selectAndLoadFolder
  that.reloadFolders = reloadFolders
  that.canLinkFolder = canLinkFolder
  that.unlinkFolder = unlinkFolder
  that.linkFolder = linkFolder
  that.disconnect = disconnect
  that.dateRanges = [
    {
      name: 'None',
      value: 'NONE'
    },
    {
      name: '7 Days',
      value: '7_DAYS'
    },
    {
      name: '30 Days',
      value: '30_DAYS'
    },
    {
      name: '60 Days',
      value: '60_DAYS'
    },
    {
      name: 'All',
      value: 'ALL'
    }
  ]

  that.historicalDataRange = that.dateRanges[0]

  that.isReadOnly =
      _.bind(
          projectIntegrationUtils.isReadOnly,
          undefined,
          that.integrationSettingsState)

  that.getNextSyncDate =
      _.bind(
          projectIntegrationUtils.getNextSyncDate,
          undefined,
          that.integrationSettingsState)

  that.getLastSyncDate =
      _.bind(
          projectIntegrationUtils.getLastSyncDate,
          undefined,
          that.integrationSettingsState)

  that.authenticateAndEnableIntegration =
    _.bind(
        projectIntegrationUtils.authenticateWithPartnerAndEnableIntegration,
        undefined,
        that.integrationSettingsState,
        that.currentProject.id,
        providerName,
        integrationType)

  init()

  function customErrorHandler (data) {
    Notification.error('Oops... Something went wrong!')
    $log.error('Storage service error ', data)
    setLoading(false)
  }

  function init () {
    resetDefaults()
    that.integrationSettingsState.setCreatePartnerSyncSettingsFunc((settingsData) => {
      return new StorageServiceSyncSettingsModel(settingsData)
    })

    $timeout(() => {
      that.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
      projectIntegrationUtils.checkIntegrationStatusAndLoadData(
          that.integrationSettingsState, that.currentProject.id, providerName.toUpperCase(), integrationType, false)
    }, 10)

    that.integrationSettingsState.setLoadPageData((integrationSettingsState) => {
      let partnerUserId = projectIntegrationUtils.getPartnerUserId(integrationSettingsState)
      if (partnerUserId) {
        smartvidApi.getStorageServiceUser(that.currentProject.id, providerName, customErrorHandler).then((data) => {
          that.userInfo = new StorageServiceUserModel(data)
          that.historicalDataRange = _.find(that.dateRanges, (dr) => {
            return dr.value === integrationSettingsState.partnerIntegrationSettings.settingsData.historicalDataSyncDateRange
          }) || that.dateRanges[4] // Set to all if none
          adjustConnectionStatus()
          loadFullSelectedFolderPath()
          if (!that.integrationSettingsState.isEnabled) {
            loadFolders()
          }
        })
      }
    })
  }

  function connect () {
    if (that.hasRateLimitError) {
      return
    }
    if (projectIntegrationUtils.isAutoConnect(that.integrationSettingsState)) {
      projectIntegrationUtils.enableIntegration(
          that.integrationSettingsState, that.currentProject.id, providerName, integrationType)
    } else {
      setHistoricalDataSyncThresholdDate(that.integrationSettingsState, that.dateRanges[0])
      that.authenticateAndEnableIntegration()
    }
  }

  function resetDefaults (hasError) {
    that.integrationSettingsState.clear()
    resetFolders()
    that.integrationSettingsState.isEnabled = false
  }

  function resetFolders (hasError) {
    that.folders.splice(0, that.folders.length)
    that.expandedFolders.splice(0, that.expandedFolders.length)
    that.selectedData.selectedFolderFullPath = $interpolate($filter('i18next')('integrations.storageService.foldersPlaceholder'))({ providerName: that.prettyName })
    that.selectedData.selectedFolder = undefined
  }

  function adjustConnectionStatus () {
    if (that.integrationSettingsState.isPartnerIntegrationEnabledByCurrentUser && !that.hasRateLimitError) {
      let userName = that.userInfo ? that.userInfo.account : '?'
      that.connectionStatus = $interpolate($filter('i18next')('integrations.storageService.connection.connectedAsUser'))({
        userName: userName,
        providerName: that.prettyName
      })
    } else if (that.integrationSettingsState.isPartnerIntegrationEnabledByOtherUser) {
      that.connectionStatus = $interpolate($filter('i18next')('integrations.storageService.connection.connectedAsSmartvidUser'))({
        smartvidUser: '',
        providerName: that.prettyName
      })
    } else if (that.hasRateLimitError) {
      // TODO(rr) Rate limit error handling
    }
  }

  function loadFolders () {
    setLoading(true)
    smartvidApi.listStorageServiceRootFolders(that.currentProject.id, providerName).then((data) => {
      that.folders = new StorageServiceFolderCollection(data).models

      if (that.integrationSettingsState.partnerIntegrationSettings &&
          that.integrationSettingsState.partnerIntegrationSettings.settingsData &&
          that.integrationSettingsState.partnerIntegrationSettings.settingsData.folderId) {
        that.selectedData.selectedFolder = _.find(that.folders, (f) => {
          return f.id === that.integrationSettingsState.partnerIntegrationSettings.settingsData.folderId
        })
      }
      setLoading(false)
    }, () => {
      setLoading(false)
    })
  }

  function selectAndLoadFolder (folder) {
    getChildFolders(folder)
    that.expandedFolders.push(folder)
    that.selectedData.selectedFolder = folder
    saveSyncSettings()
    that.selectedData.selectedFolderFullPath = folder.path
  }

  function setLoading (value) {
    $timeout(() => { that.integrationSettingsState.isLoadingData = value })
  }

  function isIntegrationUiEnabled () {
    return true // TODO add logic here
  }

  function getRootFolders () {
    return smartvidApi.listStorageServiceRootFolders(that.currentProject.id, providerName).then((result) => {
      that.folders = result
      return result
    })
  }

  function getChildFolders (parentFolder) {
    setLoading(true)
    return smartvidApi.listStorageServiceSubFolders(that.currentProject.id, providerName, parentFolder.id).then((result) => {
      parentFolder.folders = result
      setLoading(false)
      return result
    })
  }

  function reloadFolders () {
    if (that.integrationSettingsState.isLoadingData) {
      resetFolders()
      loadFolders()
    } else {
      saveSyncSettings(() => {
        resetFolders()
        loadFolders()
      })
    }
  }

  function loadFullSelectedFolderPath () {
    let id = (that.selectedData.selectedFolder && that.selectedData.selectedFolder.id) || that.integrationSettingsState.partnerIntegrationSettings.settingsData.folderId
    if (!id) {
      return
    }
    setLoading(true)
    smartvidApi.getStorageServiceFolderInfo(that.currentProject.id, providerName, id).then((folderInfo) => {
      that.selectedData.selectedFolderFullPath = folderInfo.path
      if (!angular.isObject(that.selectedData.selectedFolder)) {
        that.selectedData.selectedFolder = folderInfo
      }
      setLoading(false)
    }, () => {
      setLoading(false)
    })
  }

  function isPartnerIntegrationEnabledForProject () {
    return projectIntegrationUtils.isPartnerIntegrationEnabledForProject(that.integrationSettingsState)
  }

  function setIntegrationSettingsState () {
    if (that.selectedData.selectedFolder && that.selectedData.selectedFolder.id) {
      that.integrationSettingsState.partnerIntegrationSettings.settingsData.folderId = that.selectedData.selectedFolder.id
    }
  }

  function isDataComplete () {
    return !!that.selectedData.selectedFolder || !!that.integrationSettingsState.partnerIntegrationSettings.settingsData.folderId
  }

  function canLinkFolder () {
    return that.integrationSettingsState.isPartnerIntegrationEnabledByCurrentUser && isDataComplete()
  }

  function linkFolder () {
    that.integrationSettingsState.partnerIntegrationSettings.isEnabled = true
    that.integrationSettingsState.syncOnlyAfterDate = Date.now()
    setHistoricalDataSyncThresholdDate(that.integrationSettingsState, that.historicalDataRange)
    saveSyncSettings()
  }

  function unlinkFolder () {
    that.integrationSettingsState.partnerIntegrationSettings.isEnabled = false
    saveSyncSettings()
  }

  function saveSyncSettings (afterSaveFunc) {
    setIntegrationSettingsState()
    smartvidApi.saveIntegrationSettings(integrationType, that.integrationSettingsState.partnerIntegrationSettings).then(() => {
      if (afterSaveFunc) {
        afterSaveFunc()
      }
    }, () => {
      if (that.integrationSettingsState) {
        that.integrationSettingsState.partnerIntegrationSettings.isEnabled = false
      }
    })
  }

  function disconnect () {
    if (that.isPartnerIntegrationEnabledForProject() && !that.hasRateLimitError) {
      disableIntegration()
    }
  }

  function disableIntegration () {
    setLoading(true)
    smartvidApi.deleteIntegrationSettings(integrationType, that.currentProject.id).then(() => {
      that.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
      resetDefaults()
      projectIntegrationUtils.checkIntegrationStatusAndLoadData(
          that.integrationSettingsState, that.currentProject.id, providerName, integrationType, false)
    }, () => {
      resetDefaults()
      setLoading(false)
    })
  }

  function setHistoricalDataSyncThresholdDate (integrationSettingsState, historicalDataRange) {
    if (!historicalDataRange || historicalDataRange.value === 'ALL') {
      integrationSettingsState.partnerIntegrationSettings.settingsData.historicalDataSyncThresholdDate = null
      if (historicalDataRange) {
        integrationSettingsState.partnerIntegrationSettings.settingsData.historicalDataSyncDateRange =
            historicalDataRange.value
      }
    } else {
      switch (historicalDataRange.value) {
        case '7_DAYS':
          integrationSettingsState.partnerIntegrationSettings.settingsData.historicalDataSyncThresholdDate =
              moment().subtract(7, 'days').startOf('day').toDate()
          integrationSettingsState.partnerIntegrationSettings.settingsData.historicalDataSyncDateRange =
              historicalDataRange.value
          break
        case '30_DAYS':
          integrationSettingsState.partnerIntegrationSettings.settingsData.historicalDataSyncThresholdDate =
              moment().subtract(30, 'days').startOf('day').toDate()
          integrationSettingsState.partnerIntegrationSettings.settingsData.historicalDataSyncDateRange =
              historicalDataRange.value
          break
        case '60_DAYS':
          integrationSettingsState.partnerIntegrationSettings.settingsData.historicalDataSyncThresholdDate =
              moment().subtract(60, 'days').startOf('day').toDate()
          integrationSettingsState.partnerIntegrationSettings.settingsData.historicalDataSyncDateRange =
              historicalDataRange.value
          break
        case 'NONE':
          integrationSettingsState.partnerIntegrationSettings.settingsData.historicalDataSyncThresholdDate =
          moment().toDate()
          integrationSettingsState.partnerIntegrationSettings.settingsData.historicalDataSyncDateRange =
              historicalDataRange.value
          break
      }
    }
  }
})
