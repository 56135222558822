/* global angular */

angular.module('smartvid').directive('voiceGuide', function ($rootScope) {
  return {
    restrict: 'E',
    replace: false,
    templateUrl: 'mobile/voice-guide.html',
    link: function (scope) {
      scope.remindMeAgain = true
      scope.closeVoiceGuide = () => {
        $rootScope.$broadcast('sv-close-voice-guide', scope.remindMeAgain)
      }
    }
  }
})

