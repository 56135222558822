/* global angular */
angular.module('smartvid').directive('bubbleLoader', function ($rootScope, fileUploadService, Notification, $filter, utils) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'bubble-loader.html',
    link: function (scope, elem) {
      // Nothing here yet
    }
  }
})
