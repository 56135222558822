import * as _ from 'lodash'

export enum CardMenuItemType {
  REGULAR,
  CHECK_BOX,
  RADIO_BUTTON,
}

export class CardMenuItem {
  icon?: string
  label: string
  type: CardMenuItemType = CardMenuItemType.REGULAR
  radioGroup?: string
  isDisabled?: boolean
  isChecked?: boolean
  eventId: string

  constructor(data: any) {
    _.assign(this, data)
  }

  get isRegular() {
    return this.type === CardMenuItemType.REGULAR
  }

  get isRadioButton() {
    return this.type === CardMenuItemType.RADIO_BUTTON
  }

  get isCheckBox() {
    return this.type === CardMenuItemType.CHECK_BOX
  }
}

export interface CardToggleMenuItem {
  icon?: string
  label?: string
  isDisabled?: boolean
  isChecked?: boolean
  eventId: any
}

export interface CardToggleMenuGroup {
  groupId: string
  isDisabled?: boolean
  isMultiToggle?: boolean
  groupItems: CardToggleMenuItem[]
}

export interface CardActionItem {
  icon?: string
  label: string
  isDisabled?: boolean
  eventId: any
}

export interface CardInputChangeEvent {
  value: string
  $event: KeyboardEvent
}
