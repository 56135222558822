/* global angular, _ */
import Marzipano from 'marzipano'

angular.module('smartvid').component('360MediaViewer', {
  templateUrl: 'AssetViewer/360-media-viewer.html',
  bindings: {
    asset: '<',
    onLoad: '&?'
  },
  controller: function controller (
      $scope, $element, $attrs, $timeout, $document, $rootScope, sphericalMarkupService, smartvidApi, TAG_MARKUP_OVERLAY_ID_PREFIX
  ) {
    let ctrl = this
    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy
    let parentScope = null

    function onInit () {
      parentScope = $scope.$parent.$parent // TODO rrubbico terrible hack to make tags work. Refactor viewer
      ctrl.cleanupListeners = []
      ctrl.imageWidth = ctrl.asset.metadata.width || 0
      ctrl.imageHeight = ctrl.asset.metadata.height || 0
      ctrl.zoomInButton = null
      ctrl.zoomOutButton = null
      ctrl.homeButton = null
      ctrl.playButton = null
      ctrl.maxVFov = 100 * Math.PI / 180
      ctrl.velocity = 0.7
      ctrl.friction = 3
      ctrl.viewer = new Marzipano.Viewer($element.find('#pano')[0])
      ctrl.source = Marzipano.ImageUrlSource.fromString(bustCacheForUrl(ctrl.asset.imageUrl))
      ctrl.geometry = new Marzipano.EquirectGeometry([{width: ctrl.imageWidth}])
      ctrl.limiter = Marzipano.RectilinearView.limit.traditional(ctrl.imageHeight, ctrl.maxVFov)
      ctrl.view = new Marzipano.RectilinearView({yaw: Math.PI, fov: ctrl.maxVFov}, ctrl.limiter)
      ctrl.controls = ctrl.viewer.controls()
      ctrl.scene = ctrl.viewer.createScene({
        source: ctrl.source,
        geometry: ctrl.geometry,
        view: ctrl.view,
        pinFirstLevel: true
      })
      ctrl.autorotate = Marzipano.autorotate({
        yawSpeed: 0.1,
        targetPitch: 0,
        targetFov: Math.PI / 2
      })
      sphericalMarkupService.setProperties(ctrl.scene)
      $scope.tagInstancesWithMarkups = $scope.$parent.tagInstancesWithMarkups
      ctrl.cleanupListeners.push($scope.$on('sv-comment-selected', onCommentSelected))
      ctrl.cleanupListeners.push($scope.$on('sv-set-comment-markup', onSetCommentMarkup))
      ctrl.cleanupListeners.push($scope.$on('sv-resize-shape', onResizeCommentShape))
      ctrl.cleanupListeners.push($scope.$on('sv-comment-removed', onCommentRemoved))
      ctrl.cleanupListeners.push($scope.$on('sv-remove-markup', clearAllMarkup))
      ctrl.cleanupListeners.push($scope.$on('sv-drag-to-position-markup', onDragToPositionCommentMarkup))
      ctrl.cleanupListeners.push($scope.$on('sv-clear-all-markup', clearAllMarkup))
      ctrl.cleanupListeners.push($scope.$on('sv-rotate-comment-markup', rotateCommentMarkup))
      ctrl.cleanupListeners.push($scope.$on('sv-tag-show-markup', onTagShowMarkup))
      ctrl.cleanupListeners.push($scope.$on('sv-drag-to-position-tag-markup', onDragToPositionTagMarkup))
      ctrl.cleanupListeners.push($scope.$on('sv-resize-tag-shape', onResizeTagShape))
      ctrl.cleanupListeners.push($scope.$on('sv-set-tag-markup', onSetTagMarkup))
      ctrl.cleanupListeners.push($scope.$on('sv-remove-tag-markup', onRemoveTagMarkup))
      ctrl.cleanupListeners.push($scope.$on('sv-rotate-tag-markup', rotateTagMarkup))
      ctrl.cleanupListeners.push($scope.$on('sv-left-nav-animation-finished', updateSize))
      ctrl.cleanupListeners.push($scope.$on('sv-right-nav-animation-finished', updateSize))
      $timeout(function () {
        ctrl.zoomInButton = $document.find('#sv-zoom-in-button')
        ctrl.zoomOutButton = $document.find('#sv-zoom-out-button')
        ctrl.homeButton = $document.find('#sv-home-button')
        ctrl.playButton = $document.find('#sv-spherical-play-button')
        ctrl.playButton.on('click', toggleAutorotate)
        ctrl.homeButton.on('click', lookToHome)

        if (ctrl.zoomInButton.get(0)) {
          ctrl.controls.registerMethod('inElement',
              new Marzipano.ElementPressControlMethod(ctrl.zoomInButton.get(0), 'zoom', -ctrl.velocity, ctrl.friction), true)
        }
        if (ctrl.zoomOutButton.get(0)) {
          ctrl.controls.registerMethod('outElement',
              new Marzipano.ElementPressControlMethod(ctrl.zoomOutButton.get(0), 'zoom', ctrl.velocity, ctrl.friction), true)
        }
        ctrl.scene.switchTo({}, () => {
          ctrl.onLoad()
        })
        toggleAutorotate()
      })
    }

    function toggleAutorotate () {
      if (ctrl.playButton.hasClass('enabled')) {
        ctrl.playButton.removeClass('enabled')
        stopAutorotate()
      } else {
        ctrl.playButton.addClass('enabled')
        startAutorotate()
      }
    }

    function stopAutorotate () {
      ctrl.viewer.stopMovement()
      ctrl.viewer.setIdleMovement(Infinity)
    }

    function startAutorotate () {
      if (!ctrl.playButton.hasClass('enabled')) {
        return
      }
      ctrl.viewer.startMovement(ctrl.autorotate)
      ctrl.viewer.setIdleMovement(5 * 60 * 1000, ctrl.autorotate)
    }

    function onDestroy () {
      ctrl.playButton.off('click', toggleAutorotate)
      ctrl.homeButton.off('click', lookToHome)
      _.each(ctrl.cleanupListeners, (listener) => listener())
      ctrl.viewer.destroy()
    }

    function updateSize () {
      ctrl.viewer.updateSize()
    }

    function lookToHome () {
      ctrl.scene.lookTo({yaw: Math.PI, pitch: 0, fov: ctrl.maxVFov})
    }

    function bustCacheForUrl (url) {
      return url.includes('?') ? url + '&timestamp=' + Date.now() : url + '?timestamp=' + Date.now()
    }

    function onTagShowMarkup (evt, tags) {
      display360MarkupForTags(tags)
    }

    function onResizeTagShape (event, {tag, evt}) {
      sphericalMarkupService.resizeTagMarkup(tag, evt)
    }

    function onDragToPositionTagMarkup (event, {tag, evt}) {
      return sphericalMarkupService.dragToPositionTagMarkup(tag, evt)
    }

    function rotateTagMarkup (event, {tag, evt}) {
      sphericalMarkupService.rotateTagMarkup(tag, evt)
    }

    function onRemoveTagMarkup (event, {tag, evt}) {
      tag.markup = undefined
      sphericalMarkupService.clearMarkups()
      smartvidApi.updateTagForAsset(tag)
    }

    function onSetTagMarkup () {
      let selectedTag = ctrl.asset.tags.getSelectedTag()
      sphericalMarkupService.setDefaultMarkup(selectedTag)
      display360MarkupForTags([selectedTag])
    }

    function onSetCommentMarkup () {
      let selectedComment = ctrl.asset.comments.getEditedComment()
      sphericalMarkupService.setDefaultMarkup(selectedComment)
      display360MarkupForComment(selectedComment)
    }

    function onCommentSelected () {
      let selectedComment = ctrl.asset.comments.getEditedComment()
      display360MarkupForComment(selectedComment)
    }

    function rotateCommentMarkup (event, {comment, evt}) {
      let noSaveCallback = null
      sphericalMarkupService.rotateMarkupAndSave(comment, evt, noSaveCallback)
    }

    function onDragToPositionCommentMarkup (event, {comment, evt}) {
      sphericalMarkupService.dragToPositionMarkupAndSave(comment, evt,
        (comment) => $rootScope.$broadcast('sv-update-comment-markup')
      )
    }

    function onResizeCommentShape (event, {comment, evt}) {
      sphericalMarkupService.resizeMarkupAndSave(comment, evt,
        (comment) => $rootScope.$broadcast('sv-update-comment-markup')
      )
    }

    function display360MarkupForComment (comment) {
      if (!comment || !comment.markup) {
        return
      }
      parentScope.tagInstancesWithMarkups = []
      sphericalMarkupService.clearMarkups()
      parentScope.showMarkup = true
      comment.markup.id = 'sv-markup-' + comment.shape
      $timeout(() => sphericalMarkupService.showMarkups([comment], ctrl.asset))
    }

    function onCommentRemoved (evt, comment) {
      sphericalMarkupService.clearMarkups()
    }

    function display360MarkupForTags (tags) {
      parentScope.tagInstancesWithMarkups = []
      sphericalMarkupService.clearMarkups()
      parentScope.showMarkup = true
      $timeout(() => {
        let idCount = 0
        parentScope.tagInstancesWithMarkups = _(tags)
            .filter((t) => t.hasMarkup())
            .forEach(t => {
              t.markup.id = TAG_MARKUP_OVERLAY_ID_PREFIX + (++idCount)
              return t
            }).value()
        sphericalMarkupService.showMarkups(parentScope.tagInstancesWithMarkups, ctrl.asset)
      })
    }

    function clearAllMarkup () {
      parentScope.tagInstancesWithMarkups = []
      // hack for when comment goest into edit mode
      if (!ctrl.asset.comments.getEditedComment()) {
        sphericalMarkupService.clearMarkups()
      }
    }
  }
})
