import {
  ObservationCreateRequest,
  ObservationFilterCriteria,
  ObservationPage,
  ObservationQuickFilterCriteria,
  ObservationReviewRequest,
  ObservationSearchCriteria,
  ObservationSortType,
  ObservationUpdateRequest,
  ObservationUpdateStatusRequest,
} from 'modules/observations/models/observation.model'

export class GetObservationPage {
  static readonly type = '[ObservationList] GetPage'

  constructor(public payload: ObservationPage) {}
}

export class GetObservationCount {
  static readonly type = '[ObservationList] Count'

  constructor() {}
}

export class ApplyObservationContext {
  static readonly type = '[Observations] ApplyContext'

  constructor(public payload: ObservationContext) {}
}

export class ApplyObservationSearch {
  static readonly type = '[Observations] ApplySearchContext'

  constructor(public payload: ObservationSearchCriteria) {}
}

export class ApplyObservationSort {
  static readonly type = '[ObservationList] ApplySort'

  constructor(public payload: ObservationSortType) {}
}

export class ApplyObservationQuickFilters {
  static readonly type = '[ObservationList] ApplyObservationQuickFilters'

  constructor(public payload: ObservationQuickFilterCriteria) {}
}

export class UpdateObservationFilters {
  static readonly type = '[ObservationList] UpdateObservationFilters'

  constructor(public payload: ObservationFilterCriteria) {}
}

export class ApplyObservationFilters {
  static readonly type = '[ObservationList] ApplyObservationFilters'

  constructor(public payload: ObservationFilterCriteria) {}
}

export class ResetObservationFilters {
  static readonly type = '[ObservationList] ResetObservationFilters'

  constructor() {}
}

export class ResetObservationAdvancedFilters {
  static readonly type = '[ObservationList] ResetObservationAdvancedFilters'

  constructor() {}
}

export class ResetObservationSearch {
  static readonly type = '[ObservationList] ResetObservationSearch'

  constructor() {}
}

export class ResetObservationSearchAndFilters {
  static readonly type = '[ObservationList] ResetObservationSearchAndFilters'

  constructor() {}
}

export class ReloadObservations {
  static readonly type = '[ObservationList] Reload'

  constructor() {}
}

export class RemoveObservation {
  static readonly type = '[ObservationList] Remove'

  constructor(public projectId: string, public observationId: string) {}
}

export class ViewObservation {
  static readonly type = '[ObservationViewer] ViewObservation'

  constructor(public projectId: string, public observationId: string) {}
}

export class GetNextStatus {
  static readonly type = '[ObservationDialog] GetNextStatus'

  constructor(public observationId: string) {}
}

export class ObservationUpdated {
  static readonly type = '[ObservationDialog] ObservationUpdated'

  constructor(public observationId: string) {}
}

export class CreateObservation {
  static readonly type = '[ObservationDialog] CreateObservation'

  constructor(public request: ObservationCreateRequest) {}
}

export class UpdateObservation {
  static readonly type = '[ObservationDialog] UpdateObservation'

  constructor(public request: ObservationUpdateRequest) {}
}

export class ReviewObservation {
  static readonly type = '[ObservationDialog] ReviewObservation'

  constructor(public request: ObservationReviewRequest) {}
}

export class ChangeObservationStatus {
  static readonly type = '[ObservationDialog] ChangeObservationStatus'

  constructor(public request: ObservationUpdateStatusRequest) {}
}

export class ApplyPreviousNextObservationView {
  static readonly type = '[ObservationViewer] ApplyPreviousNextObservationView'

  constructor(public observationId: string) {}
}

export interface ObservationContext {
  organization?: { id: string }
  projectGroup?: { id: string }
  project?: { id: string }
}
