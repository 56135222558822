/* global angular */

angular.module('smartvid').service('collectionUtils', function () {
  function isShouldGetMore (collection, max) {
    return !!(collection.length <= max && collection.canFetch)
  }

  function checkSelectedMoreThan (collection, max, okCallback, tooManySelectedCallback) {
    collection.nextPagePromise.then(() => {
      if (isShouldGetMore(collection, max)) {
        collection.nextPage()
        checkSelectedMoreThan(collection, max, okCallback, tooManySelectedCallback)
      } else {
        let selected = collection.getSelected()
        let tooManySelected = selected && selected.length > max
        if (tooManySelected) {
          tooManySelectedCallback()
        } else {
          okCallback()
        }
      }
    })
  }

  return {
    checkSelectedMoreThan: checkSelectedMoreThan
  }
})
