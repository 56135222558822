/* global angular */

angular.module('smartvid').service('contentSharingContext', function () {
  let _linkId
  let _sharedContentType

  this.set = set
  this.reset = reset
  this.getLinkId = getLinkId
  this.getSharedContentType = getSharedContentType
  this.isSet = isSet
  this.isGlobalSharingContext = isGlobalSharingContext

  function set (id, type) {
    _linkId = id
    _sharedContentType = type
  }

  function reset () {
    _linkId = undefined
    _sharedContentType = undefined
  }

  function getLinkId () {
    return _linkId
  }

  function getSharedContentType () {
    return _sharedContentType
  }

  function isSet () {
    return angular.isDefined(_sharedContentType)
  }

  function isGlobalSharingContext () {
    return _sharedContentType === 'xassets' || _sharedContentType === 'xsearch'
  }
})
