/* global angular,$ */

angular.module('smartvid').controller('QueryOptionsCtrl', function ($scope, $timeout) {
  $scope.getStyles = getStyles
  $scope.closeWindow = closeWindow

  function closeWindow () {
    $timeout(() => {
      $scope.flyout.close()
    }, 500)
  }

  function getStyles () {
    let rect = $('.multi-row-search').get(0).getBoundingClientRect()
    return {
      'top': rect.top + rect.height + 8 + 'px',
      'left': rect.left + 'px',
      'width': rect.width + 31 + 'px'
    }
  }
})
