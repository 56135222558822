/* global angular */
import jstz from 'jstz'

angular.module('smartvid').service('jstzService', function ($window) {
  let jstzService = {

    jstz () {
      return jstz
    }
  }

  return jstzService
})
