/* global angular */

angular.module('smartvid').service('tagConfidenceApiUtils', function () {
  let api = {
    createGetRequest (url, tagConfidenceLevel) {
      var request = {
        method: 'GET',
        url: url,
        headers: {
        }
      }
      if (tagConfidenceLevel) {
        request.headers['Smartvid-Tag-Confidence-Level'] = tagConfidenceLevel
      }
      return request
    }
  }

  return api
})

