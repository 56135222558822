/* global angular */

angular.module('smartvid').factory('SearchEntityCollection', function (BaseCollection, SearchEntityModel) {
  class SearchEntityCollection extends BaseCollection {
    constructor (models) {
      super(models, SearchEntityModel)
    }
  }

  return SearchEntityCollection
})
