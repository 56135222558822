import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AdminIntegrationSettingsControlBarActions {
  private enableSubject = new Subject()
  private disableSubject = new Subject()
  private disconnectSubject = new Subject()

  public enableIntegrations$ = this.enableSubject.asObservable()
  public disableIntegrations$ = this.disableSubject.asObservable()
  public disconnectIntegrations$ = this.disconnectSubject.asObservable()

  enableIntegrations() {
    this.enableSubject.next()
  }

  disableIntegrations() {
    this.disableSubject.next()
  }

  disconnectIntegrations() {
    this.disconnectSubject.next()
  }
}
