/* global angular */
angular.module('smartvid').service('tagImportService', function (smartvidApi) {
  //
  // Exposed API for tag import service
  //
  let service = {
    import: function (projectId, file) {
      let promise = smartvidApi.importTagsForProject(projectId, file)
      return promise
    }

  }

  return service
})
