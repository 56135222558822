/* global angular */

angular.module('smartvid').factory('ProcorePhotoSyncSettingsCollection', function (BaseCollection, ProcorePhotoSyncSettingsModel, smartvidApi, $interval) {
  class ProcorePhotoSyncSettingsCollection extends BaseCollection {
    constructor (models) {
      super(models, ProcorePhotoSyncSettingsModel)
    }
  }

  return ProcorePhotoSyncSettingsCollection
})
