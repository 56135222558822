/* global angular, _ */
import AWS from 'aws-sdk/global'
import S3 from 'aws-sdk/clients/s3'

angular.module('smartvid').service('fileUploadService', function ($q, $rootScope, $window, $filter, $interval, $stateParams, smartvidApi,
                                                              BULK_UPLOAD_FILE_COUNT, Notification, utils, $log, currentUser, md5Service) {
  // one upload at a time for mobile and 5 for desktop
  let NUMBER_CONCURRENT_UPLOADS = $rootScope.isMobile ? 1 : 5
  // This is useful for development and testing of uploads
  let SIMULATE_S3_UPLOAD = false
  let SIMULATE_S3_UPLOAD_ERROR // To simulate error during upload set the value to {}
  let serviceContext = this
  serviceContext.defer
  // Listen for any window close event and warn the user if a file upload is in progress
  $window.onbeforeunload = (event) => {
    let message

    if (service.filesInfo.length > 0) {
      message = $filter('i18next')('directives.uploadtracker.windowCloseWarning')
      if (!event) {
        event = $window.event
      }

      if (event) {
        event.returnValue = message
      }

      return message
    }
  }

  /**
   * This method will determine to resolve or reject the upload promise when
   * all file uploads are complete in a failed or completed state.
   *
   * For now it looks like this method needs to be call from a variety of scenarios due to the asynchronous
   * setup of our upload process.
   *
   * @param defer the original upload promise
   * @param fileInfo the current file for upload
   */
  let batchResolution = (defer, response) => {
    //
    // If we've already resolved or rejected then no more batch resolution handling is needed.
    // This method will most likely be called again during a simultaneous S3 upload
    // where multiple failures can occur if the network is unavailable.
    //
    if (service.batchPromiseDone) {
      return
    }

    let bulkUploadSuccessCount = 0
    let regularUploadSuccessCount = 0
    let duplicatesCount = 0
    let uploadInProgress = false

    service.succeeded = service.succeeded || {}
    let failedRegularUploads = []
    let failedBulkUploads = []

    _.each(service.filesInfo, (fileInfo) => {
      if (fileInfo.success) {
        service.succeeded[fileInfo.file.name + fileInfo.file.lastModifiedDate] = true
        if (fileInfo.isBulkUpload) {
          bulkUploadSuccessCount++
        } else {
          regularUploadSuccessCount++
        }
        if (fileInfo.isDuplicate) {
          duplicatesCount++
        }
      } else if (fileInfo.failed) {
        if (fileInfo.isBulkUpload) {
          failedBulkUploads.push(fileInfo)
        } else {
          failedRegularUploads.push(fileInfo)
        }
      } else {
        uploadInProgress = true
      }
    })

    if (uploadInProgress) {
      return
    }

    service.batchPromiseDone = true

    // S3 uploads complete. Stop API and STS token refresh.
    $interval.cancel(apiTokenRefreshStop)
    apiTokenRefreshStop = undefined

    // Handle bulk upload success/failure
    if (failedBulkUploads.length) {
      // failed bulk uploads
      $rootScope.$broadcast('sv-bulk-upload-failed', failedBulkUploads)

      defer.reject({
        response: response,
        allFailedFiles: failedBulkUploads
      })
    } else if (bulkUploadSuccessCount > 0) {
      handleBulkUploadProgress()
      clearAllBulkUploads()
      defer.resolve(response)
    }

    // Handle regular upload success/failure
    if (failedRegularUploads.length) {
      // failed uploads
      $rootScope.$broadcast('sv-uploads-failed', failedRegularUploads)

      defer.reject({
        response: response,
        allFailedFiles: failedRegularUploads
      })
    } else if (regularUploadSuccessCount > 0) {
      $rootScope.$broadcast('sv-all-uploads-complete', {
        duplicatesCount: duplicatesCount
      })
      handleRegularUploadProgress()
      clearAllRegularUploads()
      defer.resolve(response)
    }

    if (failedBulkUploads.length === 0 && failedRegularUploads.length === 0) {
      service.filesInfo = []
      service.succeeded = {}
    }
  }

  /**
   * Calculate progress for a set of files.
   */
  let calculateProgress = (filesInfo) => {
    let total = 0
    let loaded = 0
    let failed = []

    // calculate the progress from each file size and loaded attribute
    _.each(filesInfo, (fileInfo) => {
      if (fileInfo.useInTotal) {
        total += fileInfo.file.size || 0
        loaded += fileInfo.loaded || 0
        if (fileInfo.failed) {
          failed.push(fileInfo)
        }
      }
    })

    return {
      total: total,
      loaded: loaded,
      failed: failed
    }
  }

  let handleBulkUploadProgress = () => {
    let bulkUploadFilesInfo = _.filter(service.filesInfo, (file) => {
      return file.isBulkUpload
    })
    if (bulkUploadFilesInfo.length === 0) {
      return
    }
    let bulkUploadFilesProgressData = calculateProgress(bulkUploadFilesInfo)
    let bulkUploadFilesProgress = 0
    if (bulkUploadFilesProgressData.total > 0) {
      bulkUploadFilesProgress = Math.round(bulkUploadFilesProgressData.loaded / bulkUploadFilesProgressData.total * 100)
    } else if (bulkUploadFilesProgressData.total === 0) {
      bulkUploadFilesProgress = 100
    }

    if (bulkUploadFilesInfo && bulkUploadFilesProgress === 100) {
      let duplicateUploads = _.filter(bulkUploadFilesInfo, (file) => {
        return file.isDuplicate
      })
      $rootScope.$broadcast('sv-all-bulk-upload-complete', bulkUploadFilesInfo[0].projectId, bulkUploadFilesInfo[0].isDemoProject, duplicateUploads.length)
    }
  }

  /**
   * Calculate the totals of the regular S3 file uploads and emit and event on the progress.
   */
  let handleRegularUploadProgress = () => {
    let regularUploadFilesInfo = _.filter(service.filesInfo, (file) => {
      return !file.isBulkUpload
    })
    if (regularUploadFilesInfo.length === 0) {
      return
    }
    let regularUploadFilesProgressData = calculateProgress(regularUploadFilesInfo)
    let regularUploadFilesProgress = 0
    if (regularUploadFilesProgressData.total > 0) {
      regularUploadFilesProgress = Math.round(regularUploadFilesProgressData.loaded / regularUploadFilesProgressData.total * 100)
    }

    //
    // batchResolution() will emit this final event when the entire transaction is complete
    // and api.createAssetOnService() has succeeded for each file upload.
    //
    $rootScope.$broadcast('sv-upload-total-progress', regularUploadFilesProgress)
  }

  /**
   * Handle the progress of and individual S3 file upload and call handleRegularUploadProgress()
   * to calculate and emit an event of the total progress.
   *
   * @param fileInfo
   * @param progress
   */
  let handleProgress = (fileInfo, progress) => {
    fileInfo.progress = Math.round(progress.loaded / progress.total * 100)
    fileInfo.loaded = progress.loaded
    $rootScope.$broadcast('sv-upload-progress', fileInfo)

    handleRegularUploadProgress()
  }
  serviceContext.createAsset = (fileInfo, folderPathTagDefs, defer) => {
    smartvidApi.createAsset(fileInfo.projectId, fileInfo, fileInfo.tagIds, fileInfo.doAsr, fileInfo.doImrec, fileInfo.checkForDups, folderPathTagDefs)
      .then((response) => {
        // success
        serviceContext.defer = defer
        fileInfo.assetJSON = response
        fileInfo.isDuplicate = response[0].isDuplicate
        if (fileInfo.isBulkUpload) {
          $rootScope.$broadcast('sv-bulk-upload-file-uploaded', fileInfo)
        } else {
          $rootScope.$broadcast('sv-upload-complete', fileInfo)
        }
        fileInfo.success = true
        if (!fileInfo.cancelled) {
          batchResolution(defer, response)
        }
      }, (response) => {
        // error
        fileInfo.failed = true
        $rootScope.$broadcast('sv-file-upload-failed')
        serviceContext.defer = defer
        if (!fileInfo.cancelled) {
          batchResolution(defer, response)
        }
      })
  }
  let batchUpload = (defer, fileInfo) => {
    /**
     * Upload simulator that is useful for development and testing. Doesn't upload any content to S3 buckets.
     */
    let simulateUpload = (fileInfo) => {
      $log.info('Simulating upload for ', fileInfo)
      setTimeout(() => {
        handleProgress(fileInfo, {
          loaded: fileInfo.file.size / 2,
          total: fileInfo.file.size
        })
        setTimeout(() => {
          handleProgress(fileInfo, {
            loaded: fileInfo.file.size,
            total: fileInfo.file.size
          })
          onUpload(SIMULATE_S3_UPLOAD_ERROR)
        }, 1000)
      }, 1000)
    }

    /**
     * S3 Upload Success and Error handler
     * @param error
     * @param response
     */
    let onUpload = (error, response) => {
      // Error on S3 Upload
      if (error) {
        $log.error('error uploading file ', fileInfo, error, response)
        fileInfo.failed = true
        $rootScope.$broadcast('sv-file-upload-failed')
        if (!fileInfo.cancelled) {
          batchResolution(serviceContext.defer, response)
          beginUploads(serviceContext.defer)
        }
        return
      }

      //
      // Success on S3 Upload
      //
      fileInfo.complete = true

      beginUploads(defer)
      $log.info('Finished uploading file ', fileInfo, error, response)

      let folderPathTagDefs
      if (fileInfo.folderPathTagDefs && fileInfo.file.id) {
        folderPathTagDefs = fileInfo.folderPathTagDefs
      }

      // Create the Asset on the server
      serviceContext.createAsset(fileInfo, folderPathTagDefs, serviceContext.defer)
    }

    fileInfo.uploading = true
    if (SIMULATE_S3_UPLOAD) {
      simulateUpload(fileInfo)
    } else {
      let params = {Key: fileInfo.key, ContentType: fileInfo.file.type, Body: fileInfo.file}
      let uploadOptions = {
        partSize: 1024 * 1024 * 5
      }
      serviceContext.defer = defer
      fileInfo.managedUpload = fileInfo.bucket.upload(params, uploadOptions, onUpload).on(
        'httpUploadProgress', (progress) => handleProgress(fileInfo, progress))
    }
    return defer.promise
  }

  /**
   * Determine and begin a number of simultaneous uploads based on completed and remaining uploads
   *
   * @param defer the original promise handler from fileUploadService.upload()
   */
  let beginUploads = (defer) => {
    // Number of current uploads
    let currentUploads = _.filter(service.filesInfo, (file) => {
      return !file.complete && file.uploading && !file.failed && !file.cancelled
    })

    let pendingUploads = _.filter(service.filesInfo, (file) => {
      return !file.complete && !file.uploading && !file.failed && !file.cancelled
    })

    let uploadsToBatch = NUMBER_CONCURRENT_UPLOADS - currentUploads.length
    uploadsToBatch = uploadsToBatch > pendingUploads.length ? pendingUploads.length : uploadsToBatch

    //
    // Start a batch of simultaneous uploads - a maximum of NUMBER_CONCURRENT_UPLOADS
    //
    if (uploadsToBatch > 0) {
      for (let i = 0; i < uploadsToBatch; i++) {
        batchUpload(defer, pendingUploads[i])
      }
    } else {
      batchResolution(defer, {})
    }
  }

  $rootScope.$on('sv-retry-failed-uploads', (event, failed) => {
    service.batchPromiseDone = false

    let failedFileInfos = _.indexBy(failed, (fileInfo) => {
      return fileInfo.file.name + fileInfo.file.lastModifiedDate
    })
    service.filesInfo = _.filter(service.filesInfo, (fileInfo) => {
      let key = fileInfo.file.name + fileInfo.file.lastModifiedDate
      return !(key in failedFileInfos)
    })

    _.each(failed, (fileInfo) => {
      service.upload(
        [fileInfo.file], fileInfo.projectId, fileInfo.isDemoProject, fileInfo.tagIds, fileInfo.doAsr, fileInfo.doImrec,
        fileInfo.checkForDups,
        fileInfo.isBulkUpload,
        true /* isRetry */,
        fileInfo.fileToFolderPathTagDefs)
    })
  })

  $rootScope.$on('sv-cancel-bulk-upload', () => {
    $log.info('Cancelling bulk upload')
    service.batchPromiseDone = false

    _.each(service.filesInfo, (file) => {
      if (file.isBulkUpload) {
        file.cancelled = true
        if (file.uploading && file.managedUpload) {
          file.managedUpload.abort()
        }
      }
    })

    clearAllBulkUploads()
  })

  let clearAllRegularUploads = () => {
    service.filesInfo = _.filter(service.filesInfo, (file) => {
      return file.isBulkUpload
    })
  }

  let clearAllBulkUploads = () => {
    service.filesInfo = _.filter(service.filesInfo, (file) => {
      return !file.isBulkUpload
    })
  }

  let apiTokenRefreshStop

  function findDuplicates (projectId, fileInfos) {
    let defer = $q.defer()
    md5Service.generateHashes(fileInfos).then(function () {
      let checksums = {}
      for (let fileInfo of fileInfos) {
        if (checksums[fileInfo.checksum]) {
          fileInfo.isDuplicate = true
          fileInfo.complete = true
          fileInfo.success = true
          fileInfo.useInTotal = false
        } else {
          checksums[fileInfo.checksum] = fileInfo
        }
      }
      let allChecksums = Object.keys(checksums)
      smartvidApi.getDuplicateChecksums(projectId, allChecksums).then(function (duplicates) {
        for (let checksum of duplicates) {
          let duplicateFileInfo = checksums[checksum]
          duplicateFileInfo.isDuplicate = true
          duplicateFileInfo.complete = true
          duplicateFileInfo.success = true
          duplicateFileInfo.useInTotal = false
        }
        defer.resolve()
      }, () => {
        defer.reject()
      })
    })
    return defer.promise
  }

  class RefreshingSTSCredentials extends AWS.Credentials {
    constructor (fileLength, projectId) {
      super()
      this.fileLength = fileLength
      this.projectId = projectId
    }

    refresh (callback) {
      $log.info('Refreshing STS token for projectId: ' + this.projectId)
      smartvidApi.getS3TTL(this.fileLength, this.projectId).then((creds) => {
        $log.info('Successfully refreshed STS token for projectId: ' + this.projectId)
        this.accessKeyId = creds.accessKeyId
        this.secretAccessKey = creds.secretAccessKey
        this.sessionToken = creds.sessionToken
        this.assetS3Keys = creds.assetS3Keys
        this.s3videoBucketName = creds.s3videoBucketName
        this.s3photoBucketName = creds.s3photoBucketName
        this.expireTime = new Date(Date.now() + creds.expirationSeconds * 1000)
        this.refreshInMinutes = creds.expirationSeconds / (60 * 2)
        callback()
      }, () => {
        $log.info('Failed to refresh STS token for projectId: ' + this.projectId)
        callback(new Error('Could not get STS Token'))
      })
    }

    needsRefresh () {
      if (!this.expireTime) {
        return true
      }
      let result = (this.expireTime.getTime() - new Date().getTime()) / (1000 * 60) < this.refreshInMinutes
      return result
    }

    getPromise () {
      let defer = $q.defer()
      let callback = (error) => {
        if (error) {
          defer.reject(error)
        } else {
          defer.resolve()
        }
      }
      super.get(callback)
      return defer.promise
    }
  }

  //
  // The exposed API for fileUploadService
  //
  let service = {
    filesInfo: [],
    batchPromiseDone: false,
    succeeded: {},
    credentials: undefined,
    onUpload: (error, response) => {
      return new Promise((resolve, reject) => {
        if (error) {
          reject(error)
        }
        resolve(response)
      })
    },
    createAsset: function (fileInfo, folderPathTagDefs) {
      return new Promise((resolve, reject) => {
        smartvidApi.createAsset(fileInfo.projectId, fileInfo, fileInfo.tagIds, fileInfo.doAsr, fileInfo.doImrec, fileInfo.checkForDups, folderPathTagDefs)
          .then((response) => {
            // success
            fileInfo.assetJSON = response
            fileInfo.isDuplicate = response[0].isDuplicate
            fileInfo.success = true
            resolve(response[0])
          }, (error) => {
            // error
            fileInfo.failed = true
            reject(error)
          })
      })
    },
    uploadFileToS3: function (file, projectId, totalCount) {
      let credentials = this.getRefreshingSTSCredentials(totalCount, projectId)
      this.credentials = credentials
      return new Promise((resolve, reject) => {
        credentials.getPromise().then(() => {
          const retry = 5
          const timeOut = 600000
          let bucket = utils.isVideo(file) ? this.getS3VideoBucket(credentials, retry, timeOut) : this.getS3ImageBucket(credentials, retry, timeOut)
          let preparedFile = this.prepareFile(file, projectId, bucket, false, credentials, utils.isVideo(file), [], true, true, true, true, [])
          let params = {Key: preparedFile.key, ContentType: preparedFile.file.type, Body: preparedFile.file}
          let uploadOptions = {
            partSize: 1024 * 1024 * 5
          }
          preparedFile.managedUpload = preparedFile.bucket.upload(params, uploadOptions, this.onUpload).on(
            'httpUploadProgress', (progress) => handleProgress(preparedFile, progress))

          resolve(preparedFile)
        })
      })
    },
    deletePhoto: function (fileItem) {
      let tokenRefreshInterval
      if (!this.getApiTokenRefreshStop()) {
        tokenRefreshInterval = this.refreshApiTokenWithInterval()
      }
      return new Promise((resolve, reject) => {
        fileItem.bucket.deleteObject({Key: fileItem.key}, function (err, data) {
          if (err) {
            reject(err)
          }
          resolve(data)
          $interval.cancel(tokenRefreshInterval)
        })
      })
    },

    prepareFile: function prepareFile (file, projectId, bucket, isDemoProject, credentials, isVideo, tagIds, isBulkUpload, doAsr, doImrec, checkForDups, fileToFolderPathTagDefs) {
      let fileInfo = {
        file: file,
        fileToFolderPathTagDefs: fileToFolderPathTagDefs,
        projectId: projectId,
        isDemoProject: isDemoProject,
        key: credentials.assetS3Keys.pop(),
        progress: 0,
        loaded: 0,
        isVideo: isVideo,
        managedUpload: undefined, // S3 ManagedUpload to track AWS upload
        tagIds: tagIds,
        bucket: bucket,
        uploading: false,
        isBulkUpload: isBulkUpload,
        cancelled: false,
        doAsr: doAsr,
        doImrec: doImrec,
        checkForDups: checkForDups,
        checksum: undefined,
        folderPathTagDefs: fileToFolderPathTagDefs[file.id],
        useInTotal: true
      }
      return fileInfo
    },
    getS3VideoBucket: function (credentials, retriesNum, timeout) {
      return new S3({
        credentials: credentials,
        params: {Bucket: credentials.s3videoBucketName},
        maxRetries: retriesNum,
        httpOptions: {
          timeout: timeout
        },
        retryDelayOptions: {
          base: 1000
        }
      })
    },
    getS3ImageBucket: function (credentials, retriesNum, timeout) {
      return new S3({
        credentials: credentials,
        params: {Bucket: credentials.s3photoBucketName},
        maxRetries: retriesNum,
        timeout: timeout,
        httpOptions: {
          timeout: timeout
        },
        retryDelayOptions: {
          base: 1000
        }
      })
    },
    getRefreshingSTSCredentials: function (totalCount, projectId) {
      return new RefreshingSTSCredentials(totalCount, projectId)
    },
    refreshApiTokenWithInterval: function () {
      return $interval(() => {
        smartvidApi.refreshToken().then(() => {
          $log.info('Refreshed API token during upload')
        }, () => {
          $log.error('Failed to refresh API token during upload')
        })
      }, 60 * 1000)
    },
    cancelApiRefreshToken: function () {
      $interval.cancel(this.getApiTokenRefreshStop())
      apiTokenRefreshStop = undefined
    },
    getApiTokenRefreshStop: function () {
      return apiTokenRefreshStop
    },
    /**
     * File Upload Service upload method takes an array of files from an input[type=file] user selection for a
     * project, projectId
     *
     * @param files the user selected files from input[type=file]
     * @param projectId the current projectId
     * @param tagDefIds any extra tag definitions for the new asset(s)
     * @param doAsr boolean to run ASR immediately after upload
     * @param doImrec boolean to run image recognition immediately after upload
     * @returns deferred promise for success and error handling
     */
    upload: (files, projectId, isDemoProject, tagDefIds, doAsr, doImrec, checkForDups, isBulkUpload, isRetry, fileToFolderPathTagDefs) => {
      let defer = $q.defer()
      let tagIds = tagDefIds
      isBulkUpload = isBulkUpload || (files.length > BULK_UPLOAD_FILE_COUNT)

      service.batchPromiseDone = false
      let credentials = service.getRefreshingSTSCredentials(files.length, projectId)
      //
      // Get Initial Amazon S3 token for new asset upload

//
      credentials.getPromise().then(() => {
        // Refresh API token, while uploads are in progress
        if (!service.getApiTokenRefreshStop()) {
          apiTokenRefreshStop = service.refreshApiTokenWithInterval()
        }
        let retriesNum = 5
        let timeout = 60 * 10 * 1000
        let videoBucket = service.getS3VideoBucket(credentials, retriesNum, timeout)
        let imageBucket = service.getS3ImageBucket(credentials, retriesNum, timeout)
        //
        // aggregate all files and info into filesInfo array
        //
        let newFilesInfo = []
        _.each(files, (file) => {
          //
          // all files or skip already succeeded uploads - and attempt retry on remaining
          //
          if (!isRetry || !service.succeeded[file.name + file.lastModifiedDate]) {
            let bucket = (utils.isVideo(file)) ? videoBucket : imageBucket
            let fileInfo = service.prepareFile(file, projectId, bucket, isDemoProject, credentials, utils.isVideo(file), tagIds, isBulkUpload, doAsr, doImrec, checkForDups, fileToFolderPathTagDefs)
            newFilesInfo.push(fileInfo)
            service.filesInfo.push(fileInfo)
            $log.info('Starting upload ', fileInfo)
          }
        })
        //
        // Start uploads for files in service.filesInfo - will be queued and processed in batches
        //
        //
        $rootScope.$broadcast('sv-before-upload')
        let promise = (checkForDups) ? findDuplicates(projectId, newFilesInfo) : $q.when()
        promise.then(() => {
          for (let fileInfo of newFilesInfo) {
            if (fileInfo.useInTotal) {
              if (isBulkUpload) {
                $rootScope.$broadcast('sv-before-bulk-upload-start', fileInfo)
              } else {
                $rootScope.$broadcast('sv-before-upload-start', fileInfo)
              }
            }
          }
          // Start the upload progress tracker
          handleRegularUploadProgress()
          beginUploads(defer)
        })
      }, (response) => {
        // error handling - if we cannot get asset token from server - inform user to try upload again
        if (!service.batchPromiseDone) {
          $rootScope.$broadcast('sv-uploads-failed',
            _.map(files, (f) => {
              return {
                file: f,
                fileToFolderPathTagDefs: fileToFolderPathTagDefs,
                projectId: projectId,
                tagIds: tagIds,
                isDemoProject: isDemoProject,
                isBulkUpload: isBulkUpload,
                cancelled: false,
                doAsr: doAsr,
                doImrec: doImrec,
                checkForDups: checkForDups,
                checksum: undefined,
                folderPathTagDefs: fileToFolderPathTagDefs[f.id],
                useInTotal: true
              }
            }))
        }
        $rootScope.$broadcast('sv-file-upload-failed')
        defer.reject(response)
      })
      return defer.promise
    }
  }

  return service
})
