/* global angular */

angular.module('smartvid').directive('userProfileInfoPanel', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'UsersGrid/user-profile-infopanel.html',
    link (scope) {
    }
  }
})
