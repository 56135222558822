
declare const SELF_REGISTRATION: string

import * as angular from 'angular'
import * as _ from 'lodash'

import { NavigationPanelComponent } from 'angular2/modules/admin/components/navigation-panel/navigation-panel.component'
import { DashboardDataHelperService } from 'modules/core/services/dashboard-data-helper.service'
import { InsightsControlBarComponent } from 'modules/insights/components/insights-controlbar/insights-controlbar.component'
import { AssetsInsightsDashboardComponent } from 'modules/insights/dashboard/components/assets-insights-dashboard/project/assets-insights-dashboard.component'
import { OrganizationAssetsHomeComponent } from 'modules/assets/components/organization-home/organization-assets-home.component'
import { DashboardType } from 'modules/insights/dashboard/models/insights-dashboard.model'
import { ProjectGroupAssetsHomeComponent } from 'modules/assets/components/project-group-home/project-group-assets-home.component'
import { AssetsHomeCommonControlBarComponent } from 'modules/assets/components/assets-home-common-control-bar/assets-home-common-control-bar.component'
import { Organization } from 'modules/admin/models/organization.model'
import { AdminOrganizationIntegrationsControlBarComponent } from 'modules/admin/components/admin-organization-integrations-control-bar/admin-organization-integrations-control-bar.component'
import { AdminOrganizationIntegrationsComponent } from 'modules/admin/components/admin-organization-integrations/admin-organization-integrations.component'
import { AdminOrganizationAnalyticsComponent } from 'modules/admin/components/admin-organization-analytics/admin-organization-analytics.component'

export interface CollectionParams {
  sharingType?: string;
  sharingId?: string;
  skipRecentSearches?: boolean;
  searchContext?: object;
  beforeDataLoaded?: any;
  afterDataLoaded?: any;
  orgId?: any;
  projectGroupId?: any;
  searchDeletedProjectsContext?: any;
  obsLinkedAssets?: any;
  collectionModels?: any;
}

angular
  .module('smartvid')
  .config(['$stateProvider', '$urlRouterProvider', '$locationProvider', function($stateProvider, $urlRouterProvider, $locationProvider) {
    $locationProvider.html5Mode(true)

    $urlRouterProvider.otherwise(function($injector) {
      let currentUser = $injector.get('currentUser')
      let state = $injector.get('$state')
      return state.go(currentUser.getDefaultHomePage())
    })

    let states = {
      login: {
        url: '/login?email',
        views: {
          page: {
            templateUrl: 'login.html',
            controller: 'LoginCtrl',
          },
        },
        data: {
          anonymous: true,
        },
      },
      ssoLogin: {
        url: '/sso-login',
        views: {
          page: {
            templateUrl: 'sso-login.html',
            controller: 'LoginSSOCtrl',
          },
        },
        data: {
          anonymous: true,
        },
      },
      impersonate: {
        url: '/impersonate',
        views: {
          page: {
            templateUrl: 'impersonate.html',
            controller: 'ImpersonateCtrl',
          },
        },
        data: {
          anonymous: true,
        },
      },
      sso: {
        url: '/sso?token&expires',
        views: {
          page: {
            controller: 'SsoCtrl',
          },
        },
        data: {
          anonymous: true,
        },
      },
      ssoError: {
        url: '/sso-error',
        views: {
          page: {
            templateUrl: 'sso-error.html',
            controller: 'SsoErrorCtrl',
          },
        },
        data: {
          anonymous: true,
        },
      },
      internalAdmin: {
        url: '/admin',
        abstract: true,
        views: {
          page: {
            template: '<div ui-view="page" class="page" ng-class="{ \'overlay\': modal.isOpen }"></div>'
          }
        }
      },
      scheduleGlobalDemoProjectReset: {
        url: '/reset-all-demo-projects',
        views: {
          page: {
            templateUrl: 'schedule-global-demo-projects-reset.html',
            controller: 'ScheduleGlobalDemoProjectsResetCtrl',
          },
        },
      },
      adminVinnieLimits: {
        url: '/vinnie-limits',
        views: {
          page: {
            templateUrl: 'admin-vinnie-limits.html',
            controller: 'AdminVinnieLimitsCtrl',
          },
        },
      },
      reportingScheduleUnsubscribe: {
        url: '/report/schedule/:scheduleId/unsubscribe-request/:email',
        views: {
          page: {
            templateUrl: 'reporting-schedule-unsubscribe.html',
            controller: 'ReportingScheduleUnsubscribeCtrl',
          },
        },
      },
      loggedOut: {
        url: '/logged-out',
        views: {
          page: {
            templateUrl: 'logged-out.html',
            controller: 'LoggedOutCtrl',
          },
        },
        data: {
          anonymous: true,
        },
      },
      unauthorized: {
        url: '/unauthorized',
        views: {
          page: {
            templateUrl: 'unauthorized.html',
            controller: 'UnauthorizedCtrl',
          },
        },
      },
      timeout: {
        url: '/timeout',
        views: {
          page: {
            templateUrl: 'timeout.html',
            controller: 'TimeoutCtrl',
          },
        },
        data: {
          anonymous: true,
        },
      },
      passwordResetRequest: {
        url: '/password-reset-request?email',
        views: {
          page: {
            templateUrl: 'PasswordReset/password-reset-request.html',
            controller: 'PasswordResetCtrl',
          },
        },
        data: {
          anonymous: true,
        },
      },
      resetPassword: {
        url: '/reset-password/:token',
        views: {
          page: {
            templateUrl: 'PasswordReset/reset-password.html',
            controller: 'PasswordResetCtrl',
          },
        },
        data: {
          anonymous: true,
        },
        resolve: {
          load: ['$state', 'smartvidApi', '$stateParams', ($state, smartvidApi, $stateParams) => {
            let promise = smartvidApi.checkIfPasswordResetLinkExpired(
              $stateParams.token,
            )
            promise.then(response => {
              if (
                response.data &&
                response.data.status &&
                (response.data.status === 'EXPIRED' ||
                  response.data.status === 'INVALID_SIGNATURE')
              ) {
                $state.go(
                  'reset-password-timeout',
                  { email: response.data.email },
                  { location: true },
                )
              }
            })
            return promise
          }],
        },
      },
      resetPasswordTimeout: {
        url: '/reset-password-timeout/:email',
        views: {
          page: {
            templateUrl: 'PasswordReset/password-reset-timeout.html',
            controller: 'PasswordResetTimeout',
          },
        },
        data: {
          anonymous: true,
        },
      },
      registration: {
        url: '/registration/:token?email',
        params: {
          sharing: null,
        },
        views: {
          page: {
            templateUrl: 'registration.html',
            controller: 'RegistrationCtrl',
          },
        },
        data: {
          anonymous: true,
        },
      },
      newUser: {
        url:
          '/new-user?email&firstName&lastName&isProcoreTrial&isEgnyteTrial&isBim360Trial&isOxBlueTrial&isBoxTrial',
        views: {
          page: {
            templateUrl: 'registration.html',
            controller: 'NewUserCtrl',
          },
        },
        data: {
          anonymous: true,
        },
      },
      welcome: {
        url: '/welcome/:organizationName',
        params: {
          organizationName: null,
        },
        views: {
          page: {
            templateUrl: 'welcome.html',
            controller: 'WelcomeCtrl',
          },
        },
        data: {
          anonymous: true,
        },
      },
      userInvited: {
        url: '/user-invited/:email/user-invited',
        params: {
          email: null,
        },
        views: {
          page: {
            templateUrl: 'user-inactive.html',
            controller: 'UserInactiveCtrl',
          },
        },
        data: {
          anonymous: true,
        },
      },
      userInactive: {
        url: '/user-invited/:email/user-inactive',
        params: {
          email: null,
        },
        views: {
          page: {
            templateUrl: 'user-inactive.html',
            controller: 'UserInactiveCtrl',
          },
        },
        data: {
          anonymous: true,
        },
      },
      autoJoinRequest: {
        url: '/auto-join-request/:organizationId/:organizationName/:userId',
        views: {
          page: {
            templateUrl: 'auto-join-request.html',
            controller: 'AutoJoinRequestCtrl',
          },
        },
        params: {
          organizationName: null,
          organizationId: null,
          userId: null,
        },
        data: {
          anonymous: true,
        },
      },
      activateUser: {
        url: '/activate-user/:token',
        views: {
          page: {
            templateUrl: 'activate-user.html',
            controller: 'ActivateUserCtrl',
          },
        },
        data: {
          anonymous: true,
        },
        resolve: {
          activate: ['$stateParams', '$q', 'smartvidApi', ($stateParams, $q, smartvidApi) => {
            let defer = $q.defer()

            smartvidApi.validateEmail($stateParams.token).then(
              () => {
                defer.resolve()
              },
              data => {
                $stateParams.activationError = data
                defer.resolve()
              },
            )
            return defer.promise
          }],
        },
      },
      joinOrganization: {
        url: '/join-organization/:token',
        data: {
          anonymous: true,
        },
        views: {
          page: {
            controller: 'JoinOrganizationCtrl',
          },
        },
      },
      sharing: {
        name: 'sharing',
        url: '/dashboard/shared/:sharingType/:sharingId?email&token&isWidget',
        data: {
          anonymous: true,
        },
      },
      dashboard: {
        abstract: {
          url: '/dashboard?sharingType&sharingId&isWidget',
          redirectTo: 'dashboard.adminOrganizations',
          views: {
            'leftNav@dashboard': {
              component: NavigationPanelComponent,
            },
            page: {
              templateUrl: 'dashboard.html',
              controller: 'DashboardCtrl',
            },
          },
          resolve: {
            organizations: ['currentUser', 'OrganizationsCollection', function(currentUser, OrganizationsCollection) {
              return new OrganizationsCollection(currentUser.organizations)
            }],
            i18next: ['$q', 'i18nUtils', function($q, i18nUtils) {
              let defer = $q.defer()
              i18nUtils.loadLabel(() => {
                defer.resolve()
              })
              return defer.promise
            }],
          },
          onEnter: [
            'organizations', 'dashboardDataHelper',
            function(organizations, dashboardDataHelper: DashboardDataHelperService) {
            dashboardDataHelper.setAllOrganizations(organizations)
          }],
        },
        adminOrganizations: {
          url: '/adminOrganizations',
          params: {
            canViewOrgSelected: undefined,
            showJoinConfirmedNotification: undefined,
          },
          views: {
            'controlNav@dashboard': {
              template:
                '<admin-organizations-grid-controlbar></admin-organizations-grid-controlbar>',
            },
            'workspace@dashboard': {
              template: '<admin-organizations-grid></admin-organizations-grid>',
            },
            'rightNav@dashboard': {
              template:
                '<admin-organizations-grid-info-panel></admin-organizations-grid-info-panel>',
            },
          },
          resolve: {
            handleViewOnlyOrgHighlight: [
              '$rootScope', '$stateParams',
              ($rootScope, $stateParams) => {
              $rootScope.$broadcast('sv-view-only-org-selected', {
                organizationId: $stateParams.canViewOrgSelected,
              })
            }],
            projects: ['ProjectsCollection', function(ProjectsCollection) {
              let collectionParams: CollectionParams = {}
              return new ProjectsCollection(collectionParams)
            }],
          },
          onEnter: ['projects', 'dashboardDataHelper', function(projects, dashboardDataHelper) {
            dashboardDataHelper.setAllProjects(projects)
          }],
          onExit: ['dashboardDataHelper', function(dashboardDataHelper) {
            dashboardDataHelper.setAllProjects(undefined)
          }],
        },
        organizationUsers: {
          url: '/:organizationId',
          params: {
            showJoinConfirmedNotification: undefined,
            organizationName: undefined,
            user: undefined,
          },
          views: {
            'workspace@dashboard': {
              template: '<users-grid></users-grid>',
            },
            'controlNav@dashboard': {
              template: '<users-grid-controlbar></users-grid-controlbar>',
            },
            'rightNav@dashboard': {
              template: '<users-grid-info-panel></users-grid-info-panel>',
            },
          },
          resolve: {
            organization: [
              '$stateParams', 'dashboardDataHelper',
              function($stateParams, dashboardDataHelper: DashboardDataHelperService) {
              return dashboardDataHelper.getOrganizationByOrgId($stateParams.organizationId)
            }],
            users: ['organization', 'UsersCollection', function(organization, UsersCollection) {
              return new UsersCollection(organization.id)
            }],
            tagTree: ['organization', 'TagNodesCollection', function(organization, TagNodesCollection) {
              let tagTree = new TagNodesCollection(organization)
              tagTree.init()
              return tagTree
            }],
          },
          onEnter: [
            'dashboardDataHelper', 'organization', 'tagTree', 'utils', 'users', 'NavController',
            function(
            dashboardDataHelper,
            organization,
            tagTree,
            utils,
            users,
            NavController,
          ) {
            dashboardDataHelper.setCurrentAdminOrganization(organization)
            dashboardDataHelper.setCurrentAdminOrganizationUsers(users)
            dashboardDataHelper.setCurrentOrgTags(tagTree.getTagDefs())
            dashboardDataHelper.setCurrentOrgTagTree(tagTree)
            if (utils.isMobile()) {
              NavController.closeLeftNav()
            }
          }],
          onExit: ['dashboardDataHelper', function(dashboardDataHelper) {
            dashboardDataHelper.setCurrentAdminOrganization(undefined)
            dashboardDataHelper.setCurrentAdminOrganizationUsers(undefined)
            dashboardDataHelper.setCurrentOrgTags(undefined)
            dashboardDataHelper.setCurrentOrgTagTree(undefined)
          }],
        },
        organizationProfile: {
          url: '/profile',
          views: {
            'controlNav@dashboard': {
              template: '<basic-controlbar></basic-controlbar>',
            },
            'workspace@dashboard': {
              component: 'adminOrganizationProfile',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
        },
        organizationSettings: {
          url: '/settings',
          views: {
            'controlNav@dashboard': {
              template: '<basic-controlbar></basic-controlbar>',
            },
            'workspace@dashboard': {
              template:
                '<admin-organization-settings></admin-organization-settings>',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
        },
        userProfile: {
          url: '/user/:userId',
          views: {
            'controlNav@dashboard': {
              template: '<user-profile-controlbar></user-profile-controlbar>',
            },
            'workspace@dashboard': {
              component: 'organizationUserProfile',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
          resolve: {
            user: [
              'organization', 'users', '$stateParams', 'smartvidApi',
              (organization, users, $stateParams, smartvidApi) => {
              let user = _.find(users.models, { id: $stateParams.userId })
              if (user) {
                return user
              }
              return smartvidApi.getUser(organization.id, $stateParams.userId)
            }],
          },
        },
        orgTagsManager: {
          url: '/orgTagsManager',
          views: {
            'controlNav@dashboard': {
              controller: ['$scope', 'tagTree',
              function ($scope, tagTree) {
                $scope.tagTree = tagTree
              }],
              template:
                '<tag-manager-controlbar tree-model="tagTree" strategy="\'ORG\'"></tag-manager-controlbar>',
            },
            'workspace@dashboard': {
              controller: ['$scope', 'dashboardDataHelper',
              function ($scope, dashboardDataHelper) {
                $scope.tagTree = dashboardDataHelper.getCurrentOrgTagTree()
              }],
              template:
                '<div class="tagsmanager"><tag-tree tree-model="tagTree" tree-owner-id="TAG_MANAGER"></tag-tree></div>',
            },
            'rightNav@dashboard': {
              controller: ['$scope', 'tagTree',
              function ($scope, tagTree) {
                $scope.tagTree = tagTree
              }],
              template:
                '<tag-tree-info-panel tree-model="tagTree"></tag-tree-info-panel>',
            },
          },
        },
        analytics: {
          url: '/analytics',
          views: {
            'controlNav@dashboard': {
              template: '<basic-controlbar></basic-controlbar>',
            },
            'workspace@dashboard': {
              component: AdminOrganizationAnalyticsComponent
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          }
        },
        myprofile: {
          url: '/myprofile',
          views: {
            'controlNav@dashboard': {
              template: '<basic-controlbar></basic-controlbar>',
            },
            'workspace@dashboard': {
              template: '<my-profile></my-profile>',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
        },
        mysettings: {
          url: '/mysettings',
          views: {
            'controlNav@dashboard': {
              template: '<basic-controlbar></basic-controlbar>',
            },
            'workspace@dashboard': {
              component: 'mySettings',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
          resolve: {
            allProjects: ['ProjectsCollection', function(ProjectsCollection) {
              return new ProjectsCollection()
            }],
            allOrganizations: ['currentUser', 'OrganizationsCollection', function(currentUser, OrganizationsCollection) {
              return new OrganizationsCollection(currentUser.organizations)
            }],
            projectSettings: ['smartvidApi', function(smartvidApi) {
              return smartvidApi.getProjectNotificationSettings()
            }],
            organizationSettings: ['smartvidApi', function(smartvidApi) {
              return smartvidApi.getOrganizationNotificationSettings()
            }],
          },
        },
        projects: {
          url: '/projects',
          params: {
            globalSearchContext: undefined,
            globalSearchFilter: undefined,
            skipRecentSearches: false,
          },
          views: {
            'controlNav@dashboard': {
              component: AssetsHomeCommonControlBarComponent,
            },
            'workspace@dashboard': {
              component: OrganizationAssetsHomeComponent,
            },
            'rightNav@dashboard': {
              templateProvider: [
                'searchResultService', 'contentSharingContext',
                function(
                searchResultService,
                contentSharingContext,
              ) {
                return searchResultService.isInGlobalSearchContext() ||
                contentSharingContext.isGlobalSharingContext()
                  ? '<asset-grid-info-panel></asset-grid-info-panel>'
                  : '<project-grid-info-panel></project-grid-info-panel>'
              }],
            },
          },
          resolve: {
            globalSearchContext: ['$stateParams', 'searchResultService', function($stateParams, searchResultService) {
              let searchContext = angular.isDefined(
                $stateParams.globalSearchContext,
              )
                ? $stateParams.globalSearchContext
                : searchResultService.getGlobalSearchContext()
              if (
                $stateParams.globalSearchContext &&
                $stateParams.globalSearchFilter
              ) {
                searchResultService.applyFilterToContext(
                  $stateParams.globalSearchContext,
                  $stateParams.globalSearchFilter,
                )
              }
              return searchContext
            }],
            projects: ['$rootScope', 'ProjectsCollection', 'globalSearchContext', 'contentSharingContext', function(
              $rootScope,
              ProjectsCollection,
              globalSearchContext,
              contentSharingContext,
            ) {
              let collectionParams: CollectionParams = {}
              if (contentSharingContext.isSet()) {
                collectionParams.sharingType = contentSharingContext.getSharedContentType()
                collectionParams.sharingId = contentSharingContext.getLinkId()
              }
              if (globalSearchContext) {
                collectionParams.searchContext = globalSearchContext
              }
              collectionParams.beforeDataLoaded = () => {
                $rootScope.workspaceLoadingQueueLength =
                  ($rootScope.workspaceLoadingQueueLength || 0) + 1
              }
              collectionParams.afterDataLoaded = () => {
                $rootScope.workspaceLoadingQueueLength--
              }
              return new ProjectsCollection(collectionParams)
            }],
            organization: ['currentUser', function(currentUser) {
              return currentUser.getPrimaryOrganization()
            }],
            orgHasGroups: ['organization', function(organization) {
              return organization !== undefined && organization.projectGroups !== undefined && organization.projectGroups.length > 0
            }],
            orgProjects: ['organization', 'projects', 'ProjectsCollection', '$q', function(organization, projects, ProjectsCollection, $q) {
              if (!organization) {
                return projects
              }

              let defer = $q.defer()
              projects.projectPromise.then(() => {
                let collectionParams: CollectionParams = {}
                collectionParams.orgId = organization.id
                collectionParams.collectionModels = projects.models
                defer.resolve(new ProjectsCollection(collectionParams))
              })
              return defer.promise
            }],
            globalAssets: [
              '$rootScope', 'AssetsCollection', 'AssetViewType', '$stateParams', 'globalSearchContext', 'contentSharingContext',
              function(
              $rootScope,
              AssetsCollection,
              AssetViewType,
              $stateParams,
              globalSearchContext,
              contentSharingContext,
            ) {
              if (
                !globalSearchContext &&
                !contentSharingContext.isGlobalSharingContext()
              ) {
                return undefined
              }
              let collectionParams: CollectionParams = {}
              if (contentSharingContext.isSet()) {
                collectionParams.sharingType = contentSharingContext.getSharedContentType()
                collectionParams.sharingId = contentSharingContext.getLinkId()
              }
              if (globalSearchContext) {
                collectionParams.searchContext = globalSearchContext
              }
              collectionParams.skipRecentSearches =
                $stateParams.skipRecentSearches
              collectionParams.beforeDataLoaded = () => {
                $rootScope.workspaceLoadingQueueLength =
                  ($rootScope.workspaceLoadingQueueLength || 0) + 1
              }
              collectionParams.afterDataLoaded = () => {
                $rootScope.workspaceLoadingQueueLength--
              }
              let assetViewType =
                !globalSearchContext &&
                contentSharingContext.isGlobalSharingContext()
                  ? AssetViewType.SHARED_ASSETS
                  : AssetViewType.SEARCH_RESULTS
              collectionParams.projectGroupId = $stateParams.projectGroupId
              collectionParams.orgId = $stateParams.organizationId
              let assetsCollection = new AssetsCollection(
                undefined,
                undefined,
                collectionParams,
                assetViewType,
              )
              return assetsCollection
            }],
            insightsDashboardType: function() {
              return DashboardType.ORGANIZATION_ASSETS_DASHBOARD
            },
            hasInsightsTab: ['organization', function(organization) {
              return organization && organization.hasAssetInsights
            }],
            setUpParameters: [
              'hasInsightsTab', 'organization', 'currentUser', 'insightsDataHelper', 'orgHasGroups',
              function(hasInsightsTab, organization, currentUser, insightsDataHelper, orgHasGroups) {
              if (hasInsightsTab) {
                insightsDataHelper.setOrgHasGroups(DashboardType.ORGANIZATION_ASSETS_DASHBOARD, orgHasGroups)
                insightsDataHelper.setIds(DashboardType.ORGANIZATION_ASSETS_DASHBOARD,
                  organization ? organization.id : undefined, '', '', currentUser.userId)
              }
            }],
          },
          onEnter: [
            'searchResultService', 'dashboardDataHelper', 'projects', 'orgProjects', 'globalSearchContext', 'globalAssets',
            function(
            searchResultService,
            dashboardDataHelper,
            projects,
            orgProjects,
            globalSearchContext,
            globalAssets,
          ) {
            if (angular.isDefined(globalSearchContext)) {
              searchResultService.setGlobalSearchContext(globalSearchContext)
            }
            dashboardDataHelper.setPrimaryOrgProjects(orgProjects)
            dashboardDataHelper.setAllProjects(projects)
            dashboardDataHelper.setGlobalAssets(globalAssets)
          }],
          onExit: ['searchResultService', 'dashboardDataHelper',
          function(searchResultService, dashboardDataHelper) {
            searchResultService.clearGlobalSearchInfo()
            dashboardDataHelper.setPrimaryOrgProjects(undefined)
            dashboardDataHelper.setAllProjects(undefined)
            dashboardDataHelper.setGlobalAssets(undefined)
          }],
        },
        projectsOrgId: {
          url: '/organization/:organizationId',
          name: 'dashboard.projects.orgId',
          params: {
            globalSearchContext: undefined,
            globalSearchFilter: undefined,
            skipRecentSearches: false,
          },
          views: {
            'controlNav@dashboard': {
              component: AssetsHomeCommonControlBarComponent,
            },
            'workspace@dashboard': {
              component: OrganizationAssetsHomeComponent,
            },
            'rightNav@dashboard': {
              templateProvider: ['searchResultService', 'contentSharingContext',
              function(
                searchResultService,
                contentSharingContext,
              ) {
                return searchResultService.isInGlobalSearchContext() ||
                contentSharingContext.isGlobalSharingContext()
                  ? '<asset-grid-info-panel></asset-grid-info-panel>'
                  : '<project-grid-info-panel></project-grid-info-panel>'
              }],
            },
          },
          resolve: {
            organization: [
              '$stateParams', 'organizations',
              function($stateParams, organizations) {
              return _.first(
                organizations.where({ id: $stateParams.organizationId }),
              )
            }],
            orgHasGroups: ['organization', function(organization) {
              return organization !== undefined && organization.projectGroups !== undefined && organization.projectGroups.length > 0
            }],
            orgProjects: [
              '$stateParams', 'ProjectsCollection', '$q', 'projects',
              function($stateParams, ProjectsCollection, $q, projects) {
              let defer = $q.defer()
              projects.projectPromise.then(() => {
                let collectionParams: CollectionParams = {}
                collectionParams.orgId = $stateParams.organizationId
                collectionParams.collectionModels = projects.models
                defer.resolve(new ProjectsCollection(collectionParams))
              })
              return defer.promise
            }],
            organizationAssets: [
              '$rootScope', 'AssetsCollection', 'AssetViewType', '$stateParams', 'globalSearchContext', 'contentSharingContext',
              function(
              $rootScope,
              AssetsCollection,
              AssetViewType,
              $stateParams,
              globalSearchContext,
              contentSharingContext,
            ) {
              if (
                !globalSearchContext &&
                !contentSharingContext.isGlobalSharingContext()
              ) {
                return undefined
              }
              let collectionParams: CollectionParams = {}
              if (contentSharingContext.isSet()) {
                collectionParams.sharingType = contentSharingContext.getSharedContentType()
                collectionParams.sharingId = contentSharingContext.getLinkId()
              }
              if (globalSearchContext) {
                collectionParams.searchContext = globalSearchContext
              }
              collectionParams.skipRecentSearches =
                $stateParams.skipRecentSearches
              collectionParams.beforeDataLoaded = () => {
                $rootScope.workspaceLoadingQueueLength =
                  ($rootScope.workspaceLoadingQueueLength || 0) + 1
              }
              collectionParams.afterDataLoaded = () => {
                $rootScope.workspaceLoadingQueueLength--
              }
              let assetViewType =
                !globalSearchContext &&
                contentSharingContext.isGlobalSharingContext()
                  ? AssetViewType.SHARED_ASSETS
                  : AssetViewType.SEARCH_RESULTS
              collectionParams.orgId = $stateParams.organizationId
              let assetsCollection = new AssetsCollection(
                undefined,
                undefined,
                collectionParams,
                assetViewType,
              )
              return assetsCollection
            }],
            insightsDashboardType: function() {
              return DashboardType.ORGANIZATION_ASSETS_DASHBOARD
            },
            hasInsightsTab: ['organization', function(organization) {
              return organization && organization.hasAssetInsights
            }],
            setUpParameters: [
              'hasInsightsTab', 'organization', 'currentUser', 'insightsDataHelper', 'orgHasGroups',
              function(hasInsightsTab, organization, currentUser, insightsDataHelper, orgHasGroups) {
              if (hasInsightsTab) {
                insightsDataHelper.setOrgHasGroups(DashboardType.ORGANIZATION_ASSETS_DASHBOARD, orgHasGroups)
                insightsDataHelper.setIds(DashboardType.ORGANIZATION_ASSETS_DASHBOARD,
                  organization ? organization.id : undefined, '', '', currentUser.userId)
              }
            }],
          },
          onEnter: [
            'searchResultService', 'dashboardDataHelper', 'globalSearchContext', 'organizationAssets', 'orgProjects',
            function(
            searchResultService,
            dashboardDataHelper,
            globalSearchContext,
            organizationAssets,
            orgProjects,
          ) {
            if (angular.isDefined(globalSearchContext)) {
              searchResultService.setGlobalSearchContext(globalSearchContext)
            }
            dashboardDataHelper.setOrgProjects(orgProjects)
            dashboardDataHelper.setOrganizationAssets(organizationAssets)
          }],
          onExit: [
            'dashboardDataHelper', 'insightsDataHelper', 'currentUser',
            function(dashboardDataHelper, insightsDataHelper, currentUser) {
            dashboardDataHelper.setOrganizationAssets(undefined)
            dashboardDataHelper.setOrgProjects(undefined)
            let primaryOrg = currentUser ? currentUser.getPrimaryOrganization() : undefined
            let primaryOrgId = primaryOrg ? primaryOrg.id : undefined
            let orgHasGroups = primaryOrg && primaryOrg.projectGroups && primaryOrg.projectGroups.length > 0
            insightsDataHelper.setOrgHasGroups(DashboardType.ORGANIZATION_ASSETS_DASHBOARD, orgHasGroups)
            insightsDataHelper.setIds(DashboardType.ORGANIZATION_ASSETS_DASHBOARD, primaryOrgId, '', '', currentUser.userId)
          }],
        },
        projectGroupId: {
          url: '/region/:projectGroupId',
          views: {
            'controlNav@dashboard': {
              component: AssetsHomeCommonControlBarComponent,
            },
            'workspace@dashboard': {
              component: ProjectGroupAssetsHomeComponent,
            },
            'rightNav@dashboard': {
              templateProvider: [
                'searchResultService', 'contentSharingContext',
                function(
                searchResultService,
                contentSharingContext,
              ) {
                return searchResultService.isInGlobalSearchContext() ||
                contentSharingContext.isGlobalSharingContext()
                  ? '<asset-grid-info-panel></asset-grid-info-panel>'
                  : '<project-grid-info-panel></project-grid-info-panel>'
              }],
            },
          },
          resolve: {
            region: ['$transition$', function($transition$) {
              return { id: $transition$.params().projectGroupId }
            }],
            groupProjects: [
              'ProjectsCollection', 'region', 'projects', '$q',
              function(ProjectsCollection, region, projects, $q) {
              let defer = $q.defer()
              projects.projectPromise.then(() => {
                let collectionParams: CollectionParams = {}
                collectionParams.projectGroupId = region.id
                collectionParams.collectionModels = projects.models
                defer.resolve(new ProjectsCollection(collectionParams))
              })
              return defer.promise
            }],
            projectGroupAssets: [
              '$rootScope', 'AssetsCollection', 'AssetViewType', '$stateParams', 'smartvidApi', 'globalSearchContext', 'contentSharingContext',
              function(
              $rootScope,
              AssetsCollection,
              AssetViewType,
              $stateParams,
              smartvidApi,
              globalSearchContext,
              contentSharingContext,
            ) {
              if (
                !globalSearchContext &&
                !contentSharingContext.isGlobalSharingContext()
              ) {
                return undefined
              }
              let collectionParams: CollectionParams = {}
              if (contentSharingContext.isSet()) {
                collectionParams.sharingType = contentSharingContext.getSharedContentType()
                collectionParams.sharingId = contentSharingContext.getLinkId()
              }
              if (globalSearchContext) {
                collectionParams.searchContext = globalSearchContext
              }
              collectionParams.skipRecentSearches =
                $stateParams.skipRecentSearches
              collectionParams.beforeDataLoaded = () => {
                $rootScope.workspaceLoadingQueueLength =
                  ($rootScope.workspaceLoadingQueueLength || 0) + 1
              }
              collectionParams.afterDataLoaded = () => {
                $rootScope.workspaceLoadingQueueLength--
              }
              let assetViewType =
                !globalSearchContext &&
                contentSharingContext.isGlobalSharingContext()
                  ? AssetViewType.SHARED_ASSETS
                  : AssetViewType.SEARCH_RESULTS
              collectionParams.projectGroupId = $stateParams.projectGroupId
              let assetsCollection = new AssetsCollection(
                undefined,
                undefined,
                collectionParams,
                assetViewType,
              )
              return assetsCollection
            }],
            insightsDashboardType: function() {
              return DashboardType.PROJECT_GROUP_ASSETS_DASHBOARD
            },
            hasInsightsTab: [
              'organizations', '$stateParams', '$transition$',
              function(organizations, $stateParams, $transition$) {
              let organization: Organization = _.first(
                organizations.where({ id: $stateParams.organizationId }),
              )
              if (!organization || !organization.projectGroups) {
                return false
              }

              let projectGroup: any = _.find(organization.projectGroups, { id: $transition$.params().projectGroupId })
              if (!projectGroup) {
                return false
              }

              return projectGroup.hasAssetInsights
            }],
            setUpParameters: [
              'hasInsightsTab', '$stateParams', 'currentUser', 'insightsDataHelper', '$transition$',
              function(hasInsightsTab, $stateParams, currentUser, insightsDataHelper, $transition$) {
              if (hasInsightsTab) {
                insightsDataHelper.setIds(DashboardType.PROJECT_GROUP_ASSETS_DASHBOARD,
                  $stateParams.organizationId, $transition$.params().projectGroupId, '', currentUser.userId)
              }
            }],
          },
          onEnter: [
            'searchResultService', 'dashboardDataHelper', 'globalSearchContext', 'projectGroupAssets', 'groupProjects',
            function(
            searchResultService,
            dashboardDataHelper,
            globalSearchContext,
            projectGroupAssets,
            groupProjects,
          ) {
            if (angular.isDefined(globalSearchContext)) {
              searchResultService.setGlobalSearchContext(globalSearchContext)
            }
            dashboardDataHelper.setProjectGroupAssets(projectGroupAssets)
            dashboardDataHelper.setGroupProjects(groupProjects)
          }],
          onExit: ['dashboardDataHelper', function(dashboardDataHelper) {
            dashboardDataHelper.setProjectGroupAssets(undefined)
            dashboardDataHelper.setGroupProjects(undefined)
          }],
        },
        integrations: {
          url: '/integrations',
          views: {
            'controlNav@dashboard': {
              component: AdminOrganizationIntegrationsControlBarComponent,
            },
            'workspace@dashboard': {
              component: AdminOrganizationIntegrationsComponent,
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
          resolve: {
            orgProjects: [
              '$stateParams', '$q', 'projects', 'ProjectsCollection',
              function($stateParams, $q, projects, ProjectsCollection) {
              let defer = $q.defer()
              projects.projectPromise.then(() => {
                let collectionParams: CollectionParams = {}
                collectionParams.orgId = $stateParams.organizationId
                collectionParams.collectionModels = projects.models
                defer.resolve(new ProjectsCollection(collectionParams))
              })
              return defer.promise
            }],
            onEnter: [
              'dashboardDataHelper', 'orgProjects',
              function(
              dashboardDataHelper,
              orgProjects
            ) {
              dashboardDataHelper.setOrgProjects(orgProjects)
            }],
            organization: [
              'organizations', '$transition$',
              function(organizations, $transition$) {
              return _.first(
                organizations.where({
                  id: $transition$.params().organizationId,
                })
              )
            }]
          }
        },
        deletedProjects: {
          url: '/deleted-projects',
          views: {
            'controlNav@dashboard': {
              templateProvider: function() {
                return '<deleted-projects-grid-controlbar></deleted-projects-grid-controlbar>'
              },
            },
            'workspace@dashboard': {
              templateProvider: function() {
                return '<project-grid></project-grid>'
              },
            },
            'rightNav@dashboard': {
              templateProvider: function() {
                return '<project-grid-info-panel></project-grid-info-panel>'
              },
            },
          },
          resolve: {
            deletedProjects: [
              '$stateParams', '$rootScope', 'ProjectsCollection',
              function(
              $stateParams,
              $rootScope,
              ProjectsCollection,
            ) {
              let collectionParams: CollectionParams = {}
              collectionParams.beforeDataLoaded = () => {
                $rootScope.workspaceLoadingQueueLength =
                  ($rootScope.workspaceLoadingQueueLength || 0) + 1
              }
              collectionParams.afterDataLoaded = () => {
                $rootScope.workspaceLoadingQueueLength--
              }
              collectionParams.searchDeletedProjectsContext = { organizationId: $stateParams.organizationId }
              return new ProjectsCollection(collectionParams)
            }],
          },
          onEnter: [
            'dashboardDataHelper', 'deletedProjects',
            function(
            dashboardDataHelper,
            deletedProjects,
          ) {
            dashboardDataHelper.setAllProjects(deletedProjects)
          }],
          onExit: [
            'ProjectsCollection', 'dashboardDataHelper',
            function(ProjectsCollection, dashboardDataHelper) {
            dashboardDataHelper.setAllProjects(new ProjectsCollection({}))
          }],
        },
        projectId: {
          url: '/:projectId',
          redirectTo: 'dashboard.projects.projectId.files',
          params: {
            uploadComplete: { value: false },
          },
          resolve: {
            project: ['$stateParams', 'projects', '$q', (
              $stateParams,
              projects,
              $q,
            ) => {
              let defer = $q.defer()
              projects.projectPromise.then(() => {
                let project = _.first(
                  projects.where({ id: $stateParams.projectId }),
                )
                defer.resolve(project)
              })
              return defer.promise
            }],
            projectOrganization: ['organizations', 'project', function(organizations, project) {
              return _.first(
                organizations.where({ id: project.organizationId }),
              )
            }],
            tagTree: ['project', 'projectOrganization', 'TagNodesCollection', function(
              project,
              projectOrganization,
              TagNodesCollection,
            ) {
              if (projectOrganization) {
                let tagTree = new TagNodesCollection(
                  projectOrganization,
                  project,
                )
                tagTree.init()
                return tagTree
              } else {
                return undefined
              }
            }],
          },
          onEnter: [
            'dashboardDataHelper', 'project', 'currentUser', 'utils', 'tagTree',
            function(
            dashboardDataHelper,
            project,
            currentUser,
            utils,
            tagTree,
          ) {
            dashboardDataHelper.setCurrentProject(project)
            if (tagTree) {
              dashboardDataHelper.setCurrentProjectTags(tagTree.getTagDefs())
              dashboardDataHelper.setCurrentProjectTagTree(tagTree)
            }
            if (utils.isMobile()) {
              currentUser.update({ projectId: project.id })
            }
          }],
          onExit: [
            'searchResultService', 'dashboardDataHelper', 'currentUser', 'projects', 'ProjectsCollection',
            function(
            searchResultService,
            dashboardDataHelper,
            currentUser,
            projects,
            ProjectsCollection,
          ) {
            searchResultService.clearProjectSearchInfo()
            const orgId = currentUser && currentUser.getPrimaryOrganization() ? currentUser.getPrimaryOrganization().id : undefined;

            projects.projectPromise.then(() => {
              let collectionParams: CollectionParams = {}
              collectionParams.orgId = orgId
              collectionParams.collectionModels = projects.models
              dashboardDataHelper.setPrimaryOrgProjects(new ProjectsCollection(collectionParams))
            })

            dashboardDataHelper.setCurrentProject(undefined)
            dashboardDataHelper.setCurrentProjectTags(undefined)
            dashboardDataHelper.setCurrentProjectTagTree(undefined)
          }],
        },
        insights: {
          name: 'dashboard.projects.projectId.insights',
          url: '/insights?isDemoProject',
          views: {
            'controlNav@dashboard': {
              component: InsightsControlBarComponent,
            },
            'workspace@dashboard': {
              component: AssetsInsightsDashboardComponent,
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
          resolve: {
            project: ['$stateParams', 'projects', '$q', ($stateParams, projects, $q) => {
              let defer = $q.defer()
              projects.projectPromise.then(() => {
                let project = _.first(projects.where({ id: $stateParams.projectId }))
                defer.resolve(project)
              })
              return defer.promise
            }],
            projectOrganization: ['organizations', 'project', function(organizations, project) {
              return _.first(
                organizations.where({ id: project.organizationId }),
              )
            }],
            dashboardType: () => {
              return DashboardType.PROJECT_ASSETS_DASHBOARD
            },
            setUpParameters: [
              '$stateParams', 'projectOrganization', 'project', 'currentUser', 'insightsDataHelper',
              function($stateParams, projectOrganization, project, currentUser, insightsDataHelper) {
              insightsDataHelper.setIds(DashboardType.PROJECT_ASSETS_DASHBOARD, projectOrganization.id, project.projectGroupId, $stateParams.projectId, currentUser.userId)
            }],
          },
        },
        projectFiles: {
          name: 'dashboard.projects.projectId.files',
          url: '/files?isDemoProject',
          params: {
            searchContext: undefined,
            searchFilter: undefined,
            saveScrollOptions: undefined,
            skipRecentSearches: false,
          },
          views: {
            'workspace@dashboard': {
              template: '<video-grid></video-grid>',
            },
            'controlNav@dashboard': {
              template: '<asset-grid-controlbar></asset-grid-controlbar>',
            },
            'rightNav@dashboard': {
              template: '<asset-grid-info-panel></asset-grid-info-panel>',
            },
          },
          resolve: {
            projectSearchContext: [
              '$stateParams', 'searchResultService', 'globalSearchContext',
              function(
              $stateParams,
              searchResultService,
              globalSearchContext,
            ) {
              if (globalSearchContext) {
                //$stateParams.skipRecentSearches = !!$stateParams.skipRecentSearches || !!$stateParams.searchFilter.skipRecentSearches
                return searchResultService.getProjectContextFromGlobal(
                  $stateParams.projectId,
                  globalSearchContext,
                  $stateParams.searchFilter,
                )
              } else if (angular.isDefined($stateParams.searchContext)) {
                if ($stateParams.searchContext && $stateParams.searchFilter) {
                  //$stateParams.skipRecentSearches = !!$stateParams.searchFilter.skipRecentSearches
                  searchResultService.applyFilterToContext(
                    $stateParams.searchContext,
                    $stateParams.searchFilter,
                  )
                }
                return $stateParams.searchContext
              }
              return null
            }],
            assets: [
              '$stateParams', '$rootScope', 'AssetsCollection', 'searchResultService', 'projectSearchContext', 'globalSearchContext', 'contentSharingContext',
              function(
              $stateParams,
              $rootScope,
              AssetsCollection,
              searchResultService,
              projectSearchContext,
              globalSearchContext,
              contentSharingContext,
            ) {
              searchResultService.setCurrentProjectSearchContext(
                projectSearchContext,
              )
              let collectionParams: CollectionParams = {}
              collectionParams.skipRecentSearches =
                $stateParams.skipRecentSearches
              if (contentSharingContext.isSet()) {
                collectionParams.sharingType = contentSharingContext.getSharedContentType()
                collectionParams.sharingId = contentSharingContext.getLinkId()
              }
              if (projectSearchContext) {
                collectionParams.searchContext = projectSearchContext
              } else if (globalSearchContext) {
                collectionParams.searchContext = globalSearchContext
              }
              collectionParams.beforeDataLoaded = () => {
                $rootScope.workspaceLoadingQueueLength =
                  ($rootScope.workspaceLoadingQueueLength || 0) + 1
              }
              collectionParams.afterDataLoaded = () => {
                $rootScope.workspaceLoadingQueueLength--
              }
              let assetsCollection = new AssetsCollection(
                $stateParams.projectId,
                undefined,
                collectionParams,
              )
              //$stateParams.skipRecentSearches = false
              return assetsCollection
            }],
          },
          onEnter: [
            'searchResultService', 'assets', 'projectSearchContext', 'dashboardDataHelper',
            function(
            searchResultService,
            assets,
            projectSearchContext,
            dashboardDataHelper,
          ) {
            //$stateParams.isDemoProject = String(!!project.isDemoProjectCopy)
            searchResultService.setCurrentProjectSearchContext(
              projectSearchContext,
            )
            dashboardDataHelper.setAssets(assets)
          }],
          onExit: ['dashboardDataHelper', 'searchResultService', function(dashboardDataHelper, searchResultService) {
            searchResultService.setCurrentProjectSearchContext(undefined)
            dashboardDataHelper.setAssets(undefined)
          }],
        },
        deletedfiles: {
          url: '/deletedfiles',
          views: {
            'workspace@dashboard': {
              template: '<video-grid></video-grid>',
            },
            'controlNav@dashboard': {
              template: '<asset-grid-controlbar></asset-grid-controlbar>',
            },
            'rightNav@dashboard': {
              template: '<asset-grid-info-panel></asset-grid-info-panel>',
            },
          },
          resolve: {
            assets: [
              '$stateParams', 'AssetsCollection', 'AssetViewType',
              function($stateParams, AssetsCollection, AssetViewType) {
              let assetsCollection = new AssetsCollection(
                $stateParams.projectId,
                undefined,
                {},
                AssetViewType.DELETED_ASSETS,
              )
              return assetsCollection
            }],
          },
          onEnter: ['dashboardDataHelper', 'assets', function(dashboardDataHelper, assets) {
            dashboardDataHelper.setAssets(assets)
          }],
          onExit: ['dashboardDataHelper', function(dashboardDataHelper) {
            dashboardDataHelper.setAssets(undefined)
          }],
        },
        tagsmanager: {
          url: '/tagsmanager',
          views: {
            'controlNav@dashboard': {
              controller: ['$scope', 'tagTree',
              function ($scope, tagTree) {
                $scope.tagTree = tagTree
              }],
              template:
                '<tag-manager-controlbar tree-model="tagTree" strategy="\'PROJECT\'"></tag-manager-controlbar>',
            },
            'workspace@dashboard': {
              controller: ['$scope', 'dashboardDataHelper',
              function ($scope, dashboardDataHelper) {
                $scope.tagTree = dashboardDataHelper.getCurrentProjectTagTree()
              }],
              template:
                '<div class="tagsmanager"><tag-tree tree-model="tagTree" tree-owner-id="TAG_MANAGER"></tag-tree></div>',
            },
            'rightNav@dashboard': {
              controller: ['$scope', 'tagTree',
              function ($scope, tagTree) {
                $scope.tagTree = tagTree
              }],
              template:
                '<tag-tree-info-panel tree-model="tagTree"></tag-tree-info-panel>',
            },
          },
        },
        projectProfile: {
          url: '/profile',
          views: {
            'controlNav@dashboard': {
              template:
                '<project-profile-controlbar></project-profile-controlbar>',
            },
            'workspace@dashboard': {
              template: '<project-profile></project-profile>',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
        },
        projectMembers: {
          url: '/members',
          params: {
            organization: null,
          },
          views: {
            'controlNav@dashboard': {
              template:
                '<project-members-controlbar></project-members-controlbar>',
            },
            'workspace@dashboard': {
              template: '<project-members></project-members>',
            },
            'rightNav@dashboard': {
              template:
                '<project-member-grid-info-panel></project-member-grid-info-panel>',
            },
          },
        },
        memberProfile: {
          url: '/:userId',
          params: {
            userId: null,
          },
          views: {
            'controlNav@dashboard': {
              template: '<basic-controlbar></basic-controlbar>',
            },
            'workspace@dashboard': {
              template: '<project-member-profile></project-member-profile>',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
        },
        projectIntegrations: {
          url: '/integrations',
          params: {
            organization: null,
          },
          views: {
            'controlNav@dashboard': {
              template:
                '<project-integrations-controlbar></project-integrations-controlbar>',
            },
            'workspace@dashboard': {
              template: '<project-integrations></project-integrations>',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
        },
        projectIntegrationsProcore: {
          url: '/PROCORE',
          params: {
            organization: null,
          },
          views: {
            'controlNav@dashboard': {
              template:
                '<project-integrations-controlbar></project-integrations-controlbar>',
            },
            'workspace@dashboard': {
              template:
                '<project-integrations-procore></project-integrations-procore>',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
        },
        projectIntegrationsEgnyte: {
          url: '/EGNYTE',
          params: {
            organization: null,
          },
          views: {
            'controlNav@dashboard': {
              template:
                '<project-integrations-controlbar></project-integrations-controlbar>',
            },
            'workspace@dashboard': {
              template:
                '<project-integrations-egnyte></project-integrations-egnyte>',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
        },
        projectIntegrationsForge: {
          url: '/FORGE',
          params: {
            organization: null,
          },
          views: {
            'controlNav@dashboard': {
              template:
                '<project-integrations-controlbar></project-integrations-controlbar>',
            },
            'workspace@dashboard': {
              template:
                '<project-integrations-forge></project-integrations-forge>',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
        },
        projectIntegrationsBim360Field: {
          url: '/BIM_360_FIELD',
          params: {
            organization: null,
          },
          views: {
            'controlNav@dashboard': {
              template:
                '<project-integrations-controlbar></project-integrations-controlbar>',
            },
            'workspace@dashboard': {
              template:
                '<project-integrations-bim360-field></project-integrations-bim360-field>',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
        },
        projectIntegrationsOxBlue: {
          url: '/OXBLUE',
          params: {
            organization: null,
          },
          views: {
            'controlNav@dashboard': {
              template:
                '<project-integrations-controlbar></project-integrations-controlbar>',
            },
            'workspace@dashboard': {
              template:
                '<project-integrations-oxblue></project-integrations-oxblue>',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
        },
        projectIntegrationsBox: {
          url: '/BOX',
          params: {
            organization: null,
          },
          views: {
            'controlNav@dashboard': {
              template:
                '<project-integrations-controlbar></project-integrations-controlbar>',
            },
            'workspace@dashboard': {
              template:
                '<project-integrations-storage-service></project-integrations-storage-service>',
              controller: 'BaseStorageServiceCtrl',
              controllerAs: 'StorageServiceCtrl',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
          resolve: {
            providerName: ['IntegrationName', IntegrationName => IntegrationName.BOX],
            integrationType: ['IntegrationType', IntegrationType => IntegrationType.BOX_SYNC],
          },
        },
        projectIntegrationsSharepoint: {
          url: '/SHAREPOINT',
          params: {
            organization: null,
          },
          views: {
            'controlNav@dashboard': {
              template:
                '<project-integrations-controlbar></project-integrations-controlbar>',
            },
            'workspace@dashboard': {
              template:
                '<project-integrations-storage-service></project-integrations-storage-service>',
              controller: 'BaseStorageServiceCtrl',
              controllerAs: 'StorageServiceCtrl',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
          resolve: {
            providerName: ['IntegrationName', IntegrationName => IntegrationName.SHAREPOINT],
            integrationType: ['IntegrationType', IntegrationType => IntegrationType.SHAREPOINT_SYNC],
          },
        },
        projectIntegrationsAconex: {
          url: '/ACONEX',
          params: {
            organization: null,
          },
          views: {
            'controlNav@dashboard': {
              template:
                '<project-integrations-controlbar></project-integrations-controlbar>',
            },
            'workspace@dashboard': {
              template:
                '<project-integrations-aconex></project-integrations-aconex>',
              controller: 'AconexCtrl',
              controllerAs: 'AconexCtrl',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
        },
        projectIntegrationsStructionSite: {
          url: '/STRUCTION_SITE',
          params: {
            organization: null,
          },
          views: {
            'controlNav@dashboard': {
              template:
                  '<project-integrations-controlbar></project-integrations-controlbar>',
            },
            'workspace@dashboard': {
              template:
                  '<project-integrations-struction-site></project-integrations-struction-site>',
            },
            'rightNav@dashboard': {
              template: '<default-info-panel></default-info-panel>',
            },
          },
        },
        globalViewer: {
          url: '/viewer/:assetId?tagId&commentId',
          views: {
            'controlNav@dashboard': {
              template: '<asset-view-controlbar></asset-view-controlbar>',
              controller: ['$scope', 'asset', 'globalAssets', 'tag', 'comment',
                function ($scope, asset, globalAssets, tag, comment) {
                $scope.asset = asset
                $scope.assets = globalAssets
                $scope.tag = tag
                $scope.comment = comment
              }],
            },
            'workspace@dashboard': {
              template:
                '<player tags="tags" asset="asset" is-mobile="isMobile"></player>',
              controller: ['$scope', 'asset', 'globalAssets', 'tag', 'comment',
                function ($scope, asset, globalAssets, tag, comment) {
                $scope.asset = asset
                $scope.assets = globalAssets
                $scope.tag = tag
                $scope.comment = comment
              }],
            },
            'leftNav@dashboard': {
              template: '<taglist tags="tags"></taglist>',
              controller: ['$scope', 'asset', 'globalAssets', 'tag', 'comment',
                function ($scope, asset, globalAssets, tag, comment) {
                $scope.asset = asset
                $scope.assets = globalAssets
                $scope.tag = tag
                $scope.comment = comment
              }],
            },
            'rightNav@dashboard': {
              template: '<asset-viewer-info-panel></asset-viewer-info-panel>',
              controller: ['$scope', 'asset', 'globalAssets', 'tag', 'comment',
                function ($scope, asset, globalAssets, tag, comment) {
                $scope.asset = asset
                $scope.assets = globalAssets
                $scope.tag = tag
                $scope.comment = comment
              }],
            },
          },
          resolve: {
            asset: ['$stateParams', 'globalAssets', '$q', 'access',
              ($stateParams, globalAssets, $q, access) => {
              return globalAssets
                ? globalAssets.getExtendedAsset($stateParams.assetId)
                : $q.reject({ status: access.FORBIDDEN })
            }],
            tag: ['asset', '$stateParams', (asset, $stateParams) => {
              return asset.tags.findById($stateParams.tagId)
            }],
            comment: ['asset', '$stateParams', (asset, $stateParams) => {
              let found = _.find(asset.comments.models, (comment: any) => {
                return comment.id === $stateParams.commentId
              })
              return found
            }],
            project: ['asset', 'projects', '$q', (asset, projects, $q) => {
              let defer = $q.defer()
              projects.projectPromise.then(() => {
                let project = _.first(projects.where({ id: asset.projectId }))
                defer.resolve(project)
              })
              return defer.promise
            }],
            projectOrganization: ['organizations', 'project',
              function(organizations, project) {
              return _.first(
                organizations.where({ id: project.organizationId }),
              )
            }],
            tagTree: ['project', 'projectOrganization', 'TagNodesCollection',
              function(
              project,
              projectOrganization,
              TagNodesCollection,
            ) {
              if (projectOrganization) {
                let tagTree = new TagNodesCollection(
                  projectOrganization,
                  project,
                )
                tagTree.init()
                return tagTree
              } else {
                return undefined
              }
            }],
          },
          onEnter: [
            'project', 'asset', 'tag', 'comment', 'dashboardDataHelper', 'tagTree',
            (
            project,
            asset,
            tag,
            comment,
            dashboardDataHelper,
            tagTree,
          ) => {
            if (tag) {
              tag.selected = true
            }
            if (comment) {
              comment.selected = true
            }
            dashboardDataHelper.setCurrentProject(project)
            dashboardDataHelper.setCurrentAsset(asset)
            if (tagTree) {
              dashboardDataHelper.setCurrentProjectTags(tagTree.getTagDefs())
              dashboardDataHelper.setCurrentProjectTagTree(tagTree)
            }
          }],
          onExit: ['dashboardDataHelper', dashboardDataHelper => {
            dashboardDataHelper.setCurrentProject(undefined)
            dashboardDataHelper.setCurrentAsset(undefined)
            dashboardDataHelper.setCurrentProjectTags(undefined)
            dashboardDataHelper.setCurrentProjectTagTree(undefined)
          }],
        },
        viewer: {
          url:
            '^/dashboard/viewer/:projectId/:assetId?sharingType&sharingId&tagId&commentId&email&token',
          params: {
            asset: null,
            obsLinkedAssets: [],
            obsExternalId: null,
            mediaReviewerAssetId: null,
          },
          views: {
            'controlNav@dashboard': {
              template: '<asset-view-controlbar></asset-view-controlbar>',
              controller: 'AssetInjectingCtrl',
            },
            'workspace@dashboard': {
              template:
                '<player tags="tags" asset="asset" is-mobile="isMobile"></player>',
              controller: 'AssetInjectingCtrl',
            },
            'leftNav@dashboard': {
              template: '<taglist tags="tags"></taglist>',
              controller: 'AssetInjectingCtrl',
            },
            'rightNav@dashboard': {
              template: '<asset-viewer-info-panel></asset-viewer-info-panel>',
              controller: 'AssetInjectingCtrl',
            },
          },
          resolve: {
            asset: ['$stateParams', 'assets', ($stateParams, assets) => {
              return assets.getExtendedAsset($stateParams.assetId, false, undefined, true)
            }],
            tag: ['asset', '$stateParams', (asset, $stateParams) => {
              return asset.tags.findById($stateParams.tagId)
            }],
            comment: ['asset', '$stateParams', (asset, $stateParams) => {
              let found = _.find(asset.comments.models, (comment: any) => {
                return comment.id === $stateParams.commentId
              })
              return found
            }],
            obsLinkedAssets: [
              '$stateParams', 'AssetsCollection', 'projectSearchContext', 'globalSearchContext', 'contentSharingContext',
              (
                $stateParams,
                AssetsCollection,
                projectSearchContext,
                globalSearchContext,
                contentSharingContext) => {
              let collectionParams: CollectionParams = {}
              collectionParams.skipRecentSearches =
                $stateParams.skipRecentSearches
              if (contentSharingContext.isSet()) {
                collectionParams.sharingType = contentSharingContext.getSharedContentType()
                collectionParams.sharingId = contentSharingContext.getLinkId()
              }
              if (projectSearchContext) {
                collectionParams.searchContext = projectSearchContext
              } else if (globalSearchContext) {
                collectionParams.searchContext = globalSearchContext
              }
              collectionParams.obsLinkedAssets = $stateParams.obsLinkedAssets
              let obsLinkedAssetsCollection = new AssetsCollection(
                $stateParams.projectId,
                undefined,
                collectionParams,
              )
              return obsLinkedAssetsCollection
            }],
          },
          onEnter: [
            'asset', 'tag', 'comment', 'dashboardDataHelper',
            (asset, tag, comment, dashboardDataHelper) => {
            if (tag) {
              tag.selected = true
            }
            if (comment) {
              comment.selected = true
            }
            dashboardDataHelper.setCurrentAsset(asset)
          }],
          onExit: ['dashboardDataHelper', dashboardDataHelper => {
            dashboardDataHelper.setCurrentAsset(undefined)
          }],
        },
      },
    }

    $stateProvider
      .state('timeout', states.timeout)
      .state('login', states.login)
      .state('sso-login', states.ssoLogin)
      .state('sso', states.sso)
      .state('sso-error', states.ssoError)
      .state('impersonate', states.impersonate)
      .state('internalAdmin', states.internalAdmin)
      .state('internalAdmin.reset-all-demo-projects', states.scheduleGlobalDemoProjectReset)
      .state('internalAdmin.admin-vinnie-limits', states.adminVinnieLimits)
      .state(
        'reporting-schedule-unsubscribe-confirmation',
        states.reportingScheduleUnsubscribe,
      )
      .state('loggedOut', states.loggedOut)
      .state('unauthorized', states.unauthorized)
      .state('auto-join-request', states.autoJoinRequest)
      .state('join-organization', states.joinOrganization)
      .state('password-reset-request', states.passwordResetRequest)
      .state('reset-password', states.resetPassword)
      .state('reset-password-timeout', states.resetPasswordTimeout)
      .state('registration', states.registration)
      .state('dashboard', states.dashboard.abstract)
      .state('dashboard.projects', states.dashboard.projects)
      .state('dashboard.projects.orgId', states.dashboard.projectsOrgId)
      .state('dashboard.projects.orgId.projectGroupId', states.dashboard.projectGroupId)
      .state('dashboard.projects.projectId', states.dashboard.projectId)
      .state(
        'dashboard.projects.projectId.insights',
        states.dashboard.insights,
      )
      .state(
        'dashboard.projects.projectId.files',
        states.dashboard.projectFiles,
      )
      .state(
        'dashboard.projects.projectId.deletedfiles',
        states.dashboard.deletedfiles,
      )
      .state(
        'dashboard.projects.projectId.tagsmanager',
        states.dashboard.tagsmanager,
      )
      .state(
        'dashboard.projects.projectId.profile',
        states.dashboard.projectProfile,
      )
      .state(
        'dashboard.projects.projectId.members',
        states.dashboard.projectMembers,
      )
      .state(
        'dashboard.projects.projectId.members.memberProfile',
        states.dashboard.memberProfile,
      )
      .state(
        'dashboard.projects.projectId.integrations',
        states.dashboard.projectIntegrations,
      )
      .state(
        'dashboard.projects.projectId.integrations.procore',
        states.dashboard.projectIntegrationsProcore,
      )
      .state(
        'dashboard.projects.projectId.integrations.egnyte',
        states.dashboard.projectIntegrationsEgnyte,
      )
      .state(
        'dashboard.projects.projectId.integrations.forge',
        states.dashboard.projectIntegrationsForge,
      )
      .state(
        'dashboard.projects.projectId.integrations.bim_360_field',
        states.dashboard.projectIntegrationsBim360Field,
      )
      .state(
        'dashboard.projects.projectId.integrations.oxblue',
        states.dashboard.projectIntegrationsOxBlue,
      )
      .state(
        'dashboard.projects.projectId.integrations.box',
        states.dashboard.projectIntegrationsBox,
      )
      .state(
        'dashboard.projects.projectId.integrations.sharepoint',
        states.dashboard.projectIntegrationsSharepoint,
      )
      .state(
        'dashboard.projects.projectId.integrations.aconex',
        states.dashboard.projectIntegrationsAconex,
      )
      .state(
            'dashboard.projects.projectId.integrations.struction_site',
            states.dashboard.projectIntegrationsStructionSite,
        )
      .state(
        'dashboard.adminOrganizations',
        states.dashboard.adminOrganizations,
      )
      .state(
        'dashboard.adminOrganizations.organizationId',
        states.dashboard.organizationUsers,
      )
      .state(
        'dashboard.adminOrganizations.organizationId.organizationProfile',
        states.dashboard.organizationProfile,
      )
      .state(
        'dashboard.adminOrganizations.organizationId.organizationSettings',
        states.dashboard.organizationSettings,
      )
      .state(
        'dashboard.adminOrganizations.organizationId.userProfile',
        states.dashboard.userProfile,
      )
      .state('dashboard.adminOrganizations.organizationId.integrations',
        states.dashboard.integrations,
      )
      .state('dashboard.adminOrganizations.organizationId.deletedProjects',
        states.dashboard.deletedProjects,
      )
      .state('dashboard.adminOrganizations.organizationId.analytics',
        states.dashboard.analytics,
      )
      .state(
        'dashboard.adminOrganizations.organizationId.tagsmanager',
        states.dashboard.orgTagsManager,
      )
      .state('dashboard.myprofile', states.dashboard.myprofile)
      .state('dashboard.mysettings', states.dashboard.mysettings)
      .state(
        'dashboard.projects.projectId.files.viewer',
        states.dashboard.viewer,
      )
      .state('dashboard.projects.viewer', Object.assign({}, states.dashboard.globalViewer))
      .state('dashboard.projects.orgId.viewer', Object.assign({}, states.dashboard.globalViewer))
      .state('dashboard.projects.orgId.projectGroupId.viewer', Object.assign({}, states.dashboard.globalViewer))
      .state('sharing', states.sharing)

    if (SELF_REGISTRATION) {
      $stateProvider
        .state('new-user', states.newUser)
        .state('welcome', states.welcome)
        .state('activate-user', states.activateUser)
        .state('user-inactive', states.userInactive)
        .state('user-invited', states.userInvited)
    }
  }])
