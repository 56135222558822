/* global angular, _ */

angular.module('smartvid').service('projectsApi', function ($http, $log, $q, config, InvitedProjectUserModel, UserProjectNotificationSettings, utils) {
  let rootUrl = config.env.development.apiRootUrl

  let api = {
    addUsersToProject (projectId, users) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/project/user/invitation`
      let payload = {
        'projectId': projectId,
        'updatedUsers': _.map(users, (user) => {
          return {
            'userId': user.userId,
            'userEmail': user.userEmail,
            'userProjectRole': user.role,
            'observationRole': user.observationRole
          }
        }),
        'uninvitedUsers': []
      }

      $http.post(url, payload).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    addUsersToProjects (projectIds, users) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/multi/project/user/invitation`
      let payload = {
        'projectIds': projectIds,
        'updatedUsers': _.map(users, (user) => {
          return {
            'userId': user.userId,
            'userEmail': user.userEmail,
            'userProjectRole': user.role,
            'observationRole': user.observationRole
          }
        }),
        'uninvitedUsers': []
      }

      $http.post(url, payload).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    updateUserForProject (projectId, userId, userProjectRole, isUploadNotification, isIncludeTimestampDownload, observationRole = undefined) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/project/${projectId}/user/${userId}`
      let payload = {
        'userProjectRole': userProjectRole,
        'isUploadNotification': isUploadNotification,
        'isIncludeTimestampDownload': isIncludeTimestampDownload,
        'observationRole': observationRole
      }

      $http.post(url, payload).then(function (response) {
        defer.resolve(new InvitedProjectUserModel(response.data))
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    updateUsersForProject (projectId, users, uninvitedUsers = [], moduleType = undefined) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/project/user/invitation`
      let payload = {
        'projectId': projectId,
        'updatedUsers': _.map(users, (user) => {
          return {
            'userId': user.userId,
            'userEmail': user.userEmail,
            'userProjectRole': user.role
          }
        }),
        'uninvitedUsers': uninvitedUsers,
        'moduleType': moduleType
      }

      $http.post(url, payload).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    createProject (projectName, externalProjectId, projectDescription, users, thumbnail, organizationId, projectGroupId, customErrorHandler) {
      let defer = $q.defer()
      let invitedUsers = _.map(users, function (user) {
        return {
          userId: user.id,
          userEmail: user.email,
          userProjectRole: user.role.type
        }
      })

      let onSuccess = (json) => {
        defer.resolve(json)
      }

      let onResponse = (response) => {
        if (response) {
          $log.debug('createProject', response)
          onSuccess(response.data)
        }
      }

      let payload = {
        organizationId: organizationId,
        name: projectName,
        externalProjectId: externalProjectId,
        description: projectDescription,
        invitedUsers: invitedUsers,
        projectGroupId: projectGroupId
      }

      let url = `${rootUrl}/api/project`

      var fd = new window.FormData()
      fd.append('createProjectRequest', utils.utf8ToB64(JSON.stringify(payload)))
      if (thumbnail) {
        fd.append('thumbnail', thumbnail)
      } else {
        fd.append('thumbnail', [])
      }
      $http.post(url, fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined},
        customErrorHandler: customErrorHandler
      }).then(onResponse, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    },
    copySampleProject () {
      let url = `${rootUrl}/api/project/demo/copy`
      let defer = $q.defer()

      $http.post(url).then(function (response) {
        $log.debug('copySampleProject', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    getCopyProjectProgress (copyProjectProcessId) {
      let url = `${rootUrl}/api/project/copy/status/${copyProjectProcessId}`
      let defer = $q.defer()

      $http.get(url).then(function (response) {
        $log.debug('pollCopyProjectProgress', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    updateProject (projectId, projectName, externalProjectId, projectDescription, organizationId, thumbnail,
                   clearThumbnail, customErrorHandler, projectGroupId,
                   projectType, startDate, endDate, longitude, latitude,
                   budget, addressLine1, city, postalCode, state, country) {
      let defer = $q.defer()

      let onSuccess = (json) => {
        defer.resolve(json)
      }

      let onResponse = (response) => {
        if (response) {
          $log.debug('updateProject', response)
          onSuccess(response.data)
        }
      }

      let payload = {
        organizationId: organizationId,
        name: projectName,
        description: projectDescription,
        externalProjectId: externalProjectId,
        projectGroupId: projectGroupId,
        projectType: projectType,
        startDate: startDate,
        endDate: endDate,
        longitude: longitude,
        latitude: latitude,
        budget: budget,
        addressLine1: addressLine1,
        city: city,
        postalCode: postalCode,
        state: state,
        country: country
      }

      if (angular.isDefined(clearThumbnail)) {
        payload.clearThumbnail = clearThumbnail
      }

      let url = `${rootUrl}/api/project/${projectId}`

      var fd = new window.FormData()
      fd.append('updateProjectRequest', utils.utf8ToB64(JSON.stringify(payload)))
      if (thumbnail) {
        fd.append('thumbnail', thumbnail)
      } else {
        fd.append('thumbnail', [])
      }
      $http.post(url, fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined},
        customErrorHandler: customErrorHandler
      }).then(onResponse, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    },
    restoreProjects (organizationId, projectIds) {
      let payload = {
        organizationId: organizationId,
        projectIds: projectIds
      }
      let url = `${rootUrl}/api/project/batch/job/restore`
      let defer = $q.defer()

      $http.put(url, payload).then(function (response) {
        $log.debug('restoreProjects', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    updateProjectStatus (projectId, isInactive) {
      let url = `${rootUrl}/api/project/${projectId}/activation/status/${isInactive}`
      let defer = $q.defer()

      $http.patch(url).then(function (response) {
        $log.debug('update project status', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    updateProjectsStatus (projectIds, isInactive) {
      let payload = {
        isInactive: isInactive,
        projectIds: projectIds
      }
      let url = `${rootUrl}/api/project/activation/status`
      let defer = $q.defer()

      $http.patch(url, payload).then(function (response) {
        $log.debug('update projects status', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    deleteProjects (projectIds, isForceDelete) {
      let paramsString = 'id=' + projectIds.join('&id=')
      if (isForceDelete === true) {
        paramsString += '&isForceDelete=true'
      }
      let url = `${rootUrl}/api/project?${paramsString}`
      let defer = $q.defer()

      $http.delete(url).then(function (response) {
        $log.debug('deleteProjects', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    getProjectNotificationSettings () {
      let url = `${rootUrl}/api/project/user/notification/settings`
      let defer = $q.defer()

      $http.get(url).then(function (response) {
        let projectSettings = []
        _.each(response.data, (settings) => {
          projectSettings.push(new UserProjectNotificationSettings(settings))
        })
        defer.resolve(projectSettings)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    getProjectUserDetails (projectId, userId) {
      let url = `${rootUrl}/api/project/${projectId}/user/${userId}/details`
      let defer = $q.defer()

      $http.get(url).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    getProjectUsers (projectId, options = {}) {
      let defaults = {
        pageSize: 1000,
        page: 0
      }

      _.defaults(options, defaults)

      let url = `${rootUrl}/api/project/user/invitation`
      let defer = $q.defer()
      let config = {
        params: {
          projectId: projectId,
          sortColumn: options.sortColumn,
          offset: options.pageSize * options.page,
          limit: options.pageSize
        }
      }

      $http.get(url, config).then(function (response) {
        let users = []
        _.each(response.data.invitedUsers, (user) => {
          users.push(new InvitedProjectUserModel(user))
        })
        users.totalRows = response.data.totalRows
        defer.resolve(users)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    getProjectUser (projectId, userId) {
      let url = `${rootUrl}/api/project/${projectId}/user/${userId}`
      let defer = $q.defer()

      $http.get(url).then(function (response) {
        let invitedProjectMember = response.data
        $log.debug('getProjectUser', invitedProjectMember)
        defer.resolve(new InvitedProjectUserModel(invitedProjectMember))
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    getUserProjects (page = 0, pageCount = 1000, partialText = undefined) {
      let url = `${rootUrl}/api/project/user`
      let defer = $q.defer()
      let offset = pageCount * page
      let config = {
        params: {
          offset: offset,
          limit: pageCount
        }
      }
      url = partialText ? url + `?partialText=${partialText}` : url
      $http.get(url, config).then(function (response) {
        let projects = response.data
        $log.debug('getUserProjects', projects)
        defer.resolve(projects)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    getUserDeletedProjects (organizationId, page = 0, pageCount = 1000) {
    let url = `${rootUrl}/api/organization/${organizationId}/deleted/project/user`
    let defer = $q.defer()
    let offset = pageCount * page
    let config = {
      params: {
        offset: offset,
        limit: pageCount
      }
    }
    $http.get(url, config).then(function (response) {
      let projects = response.data
      $log.debug('getUserDeletedProjects', projects)
      defer.resolve(projects)
    }, function (response) {
      defer.reject(response.data)
    })

    return defer.promise
    },
    getUserProject (projectId, isDeletedProjects) {
      let url
      if (isDeletedProjects === true) {
        url = `${rootUrl}/api/project/deleted/` + projectId
      } else {
        url = `${rootUrl}/api/project/` + projectId
      }
      let defer = $q.defer()

      $http.get(url, config).then(function (response) {
        let project = response.data
        $log.debug('getUserProject', project)
        defer.resolve(project)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    removeUsersFromProject (projectId, userIds) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/project/user/invitation`
      let payload = {
        'projectId': projectId,
        'uninvitedUsers': userIds
      }

      $http.post(url, payload).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    reinviteUserToSmartvidAndProject (projectId, email) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/user/invite`
      let payload = {
        projectId: projectId,
        userEmail: email
      }
      $http.post(url, payload).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    favoriteProject (projectId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/project/${projectId}/favorite`
      $http.post(url).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response)
      })
      return defer.promise
    },
    unfavoriteProject (projectId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/project/${projectId}/favorite`
      $http.delete(url).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response)
      })
      return defer.promise
    }
  }

  return api
})
