/* global angular _ */

angular.module('smartvid').factory('OxBlueUserModel', function (BaseModel) {
  class OxBlueUserModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return OxBlueUserModel
})
