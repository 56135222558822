<div class="admin-organization-integrations-container">

  <div class="info-card">
    <mat-card *ngIf="isInitialized && projectsWithIntegrationSettings.length === 0">
      <div class="mat-h2 integrations-header-label">
        {{ 'components.adminOrganizationIntegrations.headerNoIntegrations' | translate: {orgName: getOrgName()} }}
      </div>
      <div class="mat-h3 integrations-header-label">
        {{ 'components.adminOrganizationIntegrations.subHeaderNoIntegrations' | translate }}
      </div>
    </mat-card>

    <mat-card *ngIf="isInitialized && projectsWithIntegrationSettings.length > 0">
      <div class="mat-h2 integrations-header-label">
        {{ 'components.adminOrganizationIntegrations.header' | translate }}
      </div>
      <div class="mat-h3 integrations-header-label">
        {{ 'components.adminOrganizationIntegrations.subHeader' | translate: {orgName: getOrgName(), integrationCount:
        getIntegrationCount(), projectCount: getProjectCount()} }}
      </div>
      <div *ngIf="columnChart.dataTable">
        <google-chart #integrationsChart [data]="columnChart"></google-chart>
      </div>
    </mat-card>
  </div>

  <mat-tab-group class="integration-tabs" *ngIf="isInitialized && projectsWithIntegrationSettings.length > 0">
    <mat-tab label="{{ 'components.adminOrganizationIntegrations.allIntegrations' | translate }}">
      <ng-template matTabContent>
        <sv-admin-organization-integrations-table
                (integrationSettingsDisconnectedChange)="disconnectIntegrations($event)"
                (integrationSettingsEnableChange)="changeEnabledForIntegrations($event)"
                [projectsWithIntegrationSettings]="getAllProjectsWithIntegrationSettings()"></sv-admin-organization-integrations-table>
      </ng-template>
    </mat-tab>

    <mat-tab label="{{ 'components.adminOrganizationIntegrations.' + integrationType | translate }}"
             *ngFor="let integrationType of integrationTypes">
      <ng-template matTabContent>
        <sv-admin-organization-integrations-table
                [integrationType]="integrationType"
                (integrationSettingsDisconnectedChange)="disconnectIntegrations($event)"
                (integrationSettingsEnableChange)="changeEnabledForIntegrations($event)"
                [projectsWithIntegrationSettings]="getProjectsWithIntegrationSettingsByType(integrationType)"></sv-admin-organization-integrations-table>
      </ng-template>
    </mat-tab>

  </mat-tab-group>
</div>
