/* global angular, _ */

angular.module('smartvid').factory('TagInstanceCollection', function (BaseCollection, TagInstanceModel) {
  class TagInstanceCollection extends BaseCollection {

    add (models) {
      super.add(models, TagInstanceModel)
    }

    getSelectedTag () {
      return _.find(this.models, (model) => {
        return model.selected
      })
    }

    sortedIndexFunc (model) {
      return model ? model.startTime : undefined
    }

    unselectAll () {
      _.each(this.models, (model) => {
        model.selected = false
        model.editMode = false
      })
      this.models = _.filter(this.models, (model) => {
        return !model.local
      })
    }

    getUnverifiedTags () {
      return _.filter(this.models, (model) => {
        return !model.isVerified()
      })
    }

    selectTagById (id) {
      let tag = _.find(this.models, (model) => {
        return id === model.id
      })

      if (tag) {
        tag.selected = true
      }
    }

    findByTagDefinitionId (tagDefId) {
      return _.find(this.models, (model) => {
        return model.tagDefinitionId === tagDefId
      })
    }

    // Local is a tag that is currently being created, but has not been saved to the server yet.
    // Typically this is because the meta data is still being filled out.
    getLocal () {
      return _.find(this.models, (model) => {
        return model.local
      })
    }
  }

  return TagInstanceCollection
})
