/* global angular _ */

angular.module('smartvid').factory('SafetySummaryReportScheduleDataModel', function (ReportScheduleDataModel) {
  class SafetySummaryReportScheduleDataModel extends ReportScheduleDataModel {
    constructor (attrs) {
      let defaults = {
        projectIds: [],
        projectNames: [],
        isConstructionRelatedOnly: undefined,
        tagConfidenceLevel: undefined,
        selectedSafetyTags: []
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return SafetySummaryReportScheduleDataModel
})
