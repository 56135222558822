import { BehaviorSubject, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators'
import { Component, Injectable, Input, OnDestroy, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { FlatTreeControl } from '@angular/cdk/tree'
import { SelectionModel } from '@angular/cdk/collections'
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree'
import { ForgeIssueType, ForgeService } from 'modules/integrations/services/forge.service'
import {
  ForgeCheckboxValueType,
  ForgeComponentChangeService,
} from 'modules/integrations/services/forge-component-change.service'

/** Nested node */
class IssueTypeNode {
  constructor(
    public name: string,
    public issueTypeId: string,
    public expandable: boolean,
    public children: IssueTypeNode[]
  ) {}
}

/** Flat node with expandable and level information */
class IssueTypeFlatNode {
  constructor(
    public name: string,
    public issueTypeId: string,
    public level = 1,
    public expandable = false,
    public isLoading = false
  ) {}
}

const FORGE_ROOT_NODE_ID = '@@FORGE_FOLDER_ROOT_NODE_ID@@'

@Injectable()
export class ForgeIssueTypeDatabase {
  dataChange = new BehaviorSubject<IssueTypeNode[]>([])

  private _nodeMap = new Map<string, IssueTypeNode>()
  private _cachedNodes: IssueTypeNode[] = []

  constructor(private _forgeService: ForgeService, private translate: TranslateService) {}

  initialize() {
    this._nodeMap.clear()
    this._cachedNodes = []
    const rootNode = this.createRootNode()
    this._nodeMap.set(rootNode.issueTypeId, rootNode)
    this.dataChange.next([rootNode])
  }

  createRootNode() {
    return new IssueTypeNode(this.translate.instant('integrations.forge.allTypes'), FORGE_ROOT_NODE_ID, true, [])
  }

  getRootNode() {
    return this._nodeMap.get(FORGE_ROOT_NODE_ID)
  }

  getLoadedSize() {
    return this._cachedNodes.reduce((acc, cur) => acc + 1 + cur.children.length, 0)
  }

  loadMore(
    projectId: string,
    node: IssueTypeFlatNode,
    searchTerm?: string,
    expandFn?: (IssueTypeFlatNode) => void
  ): void {
    const rootNode = this.getRootNode()
    if (this._cachedNodes.length > 0) {
      if (searchTerm && searchTerm.length > 0) {
        rootNode.children = this._searchNodes(this._cachedNodes, searchTerm)
        expandFn?.(node)
      } else {
        rootNode.children = this._cachedNodes
      }
      this.dataChange.next(this.dataChange.value)
      return
    }
    node.isLoading = true
    this._forgeService
      .getIssueTypes(projectId)
      .pipe(take(1))
      .subscribe(resp => {
        this._cachedNodes = resp.map(t => this._fromIssueTypeToNode(t))
        rootNode.children = this._cachedNodes
        this.dataChange.next(this.dataChange.value)

        if (searchTerm && searchTerm.length > 0) {
          rootNode.children = this._searchNodes(this._cachedNodes, searchTerm)
          this.dataChange.next(this.dataChange.value)
          expandFn?.(node)
        }
        node.isLoading = false
      })
  }

  private _searchNodes(nodes: IssueTypeNode[], searchTerm: string): IssueTypeNode[] {
    const cloned = nodes.map(x => Object.assign({}, x))
    const bySearch = (n: IssueTypeNode) => n.name.toLowerCase().includes(searchTerm.toLowerCase())
    return cloned
      .filter(n => bySearch(n) || n.children.find(bySearch))
      .map(n => {
        if (!bySearch(n)) {
          n.children = n.children.filter(bySearch)
        }
        return n
      })
  }

  private _fromIssueTypeToNode(issueType: ForgeIssueType): IssueTypeNode {
    if (this._nodeMap.has(issueType.issueTypeId)) {
      return this._nodeMap.get(issueType.issueTypeId)
    }
    const children = issueType.subtypes.map(it => this._fromIssueTypeToNode(it))
    const result = new IssueTypeNode(issueType.title, issueType.issueTypeId, children.length > 0, children)
    this._nodeMap.set(issueType.issueTypeId, result)
    return result
  }
}

/**
 * @title Forge folder tree with checkboxes
 */
@Component({
  selector: 'sv-forge-issue-type-tree',
  templateUrl: 'forge-issue-type-tree.component.html',
  styleUrls: ['forge-issue-type-tree.component.css'],
  providers: [ForgeIssueTypeDatabase],
})
export class ForgeIssueTypeTreeComponent implements OnInit, OnDestroy {
  @Input() projectId: string
  @Input() readOnly: boolean

  treeControl: FlatTreeControl<IssueTypeFlatNode>
  treeFlattener: MatTreeFlattener<IssueTypeNode, IssueTypeFlatNode>
  dataSource: MatTreeFlatDataSource<IssueTypeNode, IssueTypeFlatNode>
  checkboxSelection: SelectionModel<IssueTypeFlatNode>
  partiallySelection: SelectionModel<IssueTypeFlatNode>
  searchTerm = ''

  private $destroy = new Subject<void>()
  private $searchChange = new Subject<string>()
  private _nodeMap = new Map<string, IssueTypeFlatNode>()
  private initialIssueTypeIds: string[] = []
  private expandable = false

  constructor(private _database: ForgeIssueTypeDatabase, private _folderTreeChange: ForgeComponentChangeService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isNodeExpandable, this.getChildren)
    this.treeControl = new FlatTreeControl<IssueTypeFlatNode>(this.getLevel, this.isNodeExpandable)
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener)
    this.checkboxSelection = new SelectionModel<IssueTypeFlatNode>(true)
    this.partiallySelection = new SelectionModel<IssueTypeFlatNode>(true)
  }

  ngOnInit(): void {
    this._database.dataChange.pipe(takeUntil(this.$destroy)).subscribe(data => {
      this.dataSource.data = data
      this.autoSelectChildren(data, 0, this.initialIssueTypeIds)
      if (this.isSearchMode()) {
        const descendants = this.treeControl.getDescendants(this.getRootFlatNode())
        descendants.forEach(n => this.treeControl.expand(n))
      }
    })
    this._folderTreeChange.forgeProjectChange.pipe(takeUntil(this.$destroy)).subscribe(changeEvent => {
      this._nodeMap.clear()
      this._database.initialize()
      this.searchTerm = ''
      this.initialIssueTypeIds = changeEvent.issueTypeIds
      this.checkboxSelection.clear()
      this.partiallySelection.clear()
      this.expandable = changeEvent.forgeProjectId !== null && changeEvent.forgeProjectId !== undefined
      const rootFlatNode = this._nodeMap.get(FORGE_ROOT_NODE_ID)
      switch (changeEvent.issueSelectedType) {
        case ForgeCheckboxValueType.PARTIALLY_SELECTED:
          this.checkboxSelection.deselect(rootFlatNode)
          this.partiallySelection.select(rootFlatNode)
          break
        case ForgeCheckboxValueType.NONE_SELECTED:
          this.checkboxSelection.deselect(rootFlatNode)
          this.partiallySelection.deselect(rootFlatNode)
          break
        default:
          this.checkboxSelection.select(rootFlatNode)
          this.partiallySelection.deselect(rootFlatNode)
          break
      }
    })
    this._database.initialize()
    const rootFlatNode = this._nodeMap.get(FORGE_ROOT_NODE_ID)
    this.checkboxSelection.select(rootFlatNode)
    this.partiallySelection.deselect(rootFlatNode)

    this.$searchChange.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.$destroy)).subscribe(term => {
      const rootNode = this.getRootFlatNode()
      this._database.loadMore(this.projectId, rootNode, term, n => this.treeControl.expand(n))
    })
  }

  ngOnDestroy(): void {
    this.$destroy.next()
    this.$destroy.complete()
  }

  getFlatNode(nodeId: string): IssueTypeFlatNode {
    return this._nodeMap.get(nodeId)
  }

  getRootFlatNode(): IssueTypeFlatNode {
    return this.getFlatNode(FORGE_ROOT_NODE_ID)
  }

  isRootNode(node: IssueTypeFlatNode): boolean {
    return node.issueTypeId === FORGE_ROOT_NODE_ID
  }

  onSearch(term: string) {
    this.$searchChange.next(term.trim())
  }

  clearSearch() {
    this.searchTerm = ''
    this.onSearch(this.searchTerm)
  }

  isSearchMode() {
    return this.searchTerm && this.searchTerm.length > 0
  }

  autoSelectChildren(nodes: IssueTypeNode[], level: number, issueTypeIds: string[]) {
    nodes.forEach(node => {
      const flatNode = this._nodeMap.get(node.issueTypeId)
      if (issueTypeIds.includes(flatNode.issueTypeId)) {
        this.checkboxSelection.select(flatNode)
        this.partiallySelection.deselect(flatNode)
      } else {
        const selectedNodes = node.children.filter(n => issueTypeIds.includes(n.issueTypeId))
        if (selectedNodes != null && selectedNodes.length > 0) {
          this.partiallySelection.select(flatNode)
        }
      }
      if (this.checkboxSelection.isSelected(flatNode)) {
        const descendants = this.treeControl.getDescendants(flatNode)
        if (descendants && descendants.length > 0) {
          this.checkboxSelection.select(...descendants)
          this.partiallySelection.deselect(...descendants)
        }
      }
      this.autoSelectChildren(node.children, level + 1, issueTypeIds)
    })
  }

  getChildren = (node: IssueTypeNode): IssueTypeNode[] => node.children

  transformer = (node: IssueTypeNode, level: number) => {
    const existingNode = this._nodeMap.get(node.issueTypeId)
    if (existingNode) {
      return existingNode
    }
    const newNode = new IssueTypeFlatNode(node.name, node.issueTypeId, level, node.expandable)
    this._nodeMap.set(node.issueTypeId, newNode)
    return newNode
  }

  getLevel = (node: IssueTypeFlatNode) => node.level

  isNodeExpandable = (node: IssueTypeFlatNode) => node.expandable

  hasChild = (_: number, node: IssueTypeFlatNode) => node.expandable

  emitSelectionChange(): void {
    let rootNode = this._nodeMap.get(FORGE_ROOT_NODE_ID)
    let selectedType = ForgeCheckboxValueType.ALL_SELECTED
    let selectedIssueTypeIds: string[] = []
    if (!this.checkboxSelection.isSelected(rootNode) && this.partiallySelection.isSelected(rootNode)) {
      selectedType = ForgeCheckboxValueType.PARTIALLY_SELECTED
      selectedIssueTypeIds = this.checkboxSelection.selected.map(n => n.issueTypeId)
    } else if (!this.checkboxSelection.isSelected(rootNode) && !this.partiallySelection.isSelected(rootNode)) {
      selectedType = ForgeCheckboxValueType.NONE_SELECTED
      selectedIssueTypeIds = []
    }
    this._folderTreeChange.issueTypeSelectedChange.emit({
      issueSelectedType: selectedType,
      issueTypeIds: selectedIssueTypeIds,
    })
  }

  clickLoadMore(node: IssueTypeFlatNode) {
    this._database.loadMore(this.projectId, node, this.searchTerm)
  }

  toggleCheckbox(folderNode: IssueTypeFlatNode): void {
    this.checkboxSelection.toggle(folderNode)
    this.checkboxSelection.isSelected(folderNode)
    this.partiallySelection.isSelected(folderNode)
    const descendants = this.treeControl.getDescendants(folderNode)
    if (this.checkboxSelection.isSelected(folderNode)) {
      this.checkboxSelection.select(...descendants)
    } else {
      this.checkboxSelection.deselect(...descendants)
    }
    this.partiallySelection.deselect(folderNode)
    this.partiallySelection.deselect(...descendants)
    descendants.forEach(child => this.checkboxSelection.isSelected(child))
    this.initialIssueTypeIds = []
    this.checkAllParentsSelection(folderNode)
    this.emitSelectionChange()
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: IssueTypeFlatNode): void {
    let parent: IssueTypeFlatNode | null = this.getParentNode(node)
    while (parent) {
      if (this.isRootNode(parent) && this.isSearchMode()) {
        this.checkRootNode(parent)
      } else {
        this.checkParentNodeSelection(parent)
      }
      parent = this.getParentNode(parent)
    }
  }

  /** Check root node checked state and change it accordingly */
  checkParentNodeSelection(node: IssueTypeFlatNode): void {
    const descendants = this.treeControl.getDescendants(node)
    const allSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checkboxSelection.isSelected(child)
      })
    const someSelected =
      descendants.length > 0 &&
      descendants.some(child => {
        return this.checkboxSelection.isSelected(child) || this.partiallySelection.isSelected(child)
      })
    if (allSelected) {
      this.checkboxSelection.select(node)
      this.partiallySelection.deselect(node)
    } else {
      if (someSelected) {
        this.checkboxSelection.deselect(node)
        this.partiallySelection.select(node)
      } else {
        this.checkboxSelection.deselect(node)
        this.partiallySelection.deselect(node)
      }
    }
  }

  checkRootNode(rootNode: IssueTypeFlatNode): void {
    const selectedNodes = this.checkboxSelection.selected.filter(n => n.issueTypeId !== FORGE_ROOT_NODE_ID)
    if (selectedNodes.length === this._database.getLoadedSize()) {
      this.checkboxSelection.select(rootNode)
      this.partiallySelection.deselect(rootNode)
    } else if (selectedNodes.length > 0) {
      this.checkboxSelection.deselect(rootNode)
      this.partiallySelection.select(rootNode)
    } else {
      this.checkboxSelection.deselect(rootNode)
      this.partiallySelection.deselect(rootNode)
    }
  }

  checkParentNodeInSearchMode(parentNode: IssueTypeFlatNode): void {
    const descendants = this.treeControl.getDescendants(parentNode)
    const someSelected =
      descendants.length > 0 &&
      descendants.some(child => {
        return this.checkboxSelection.isSelected(child) || this.partiallySelection.isSelected(child)
      })
    if (someSelected) {
      this.checkboxSelection.deselect(parentNode)
      this.partiallySelection.select(parentNode)
    } else {
      this.checkboxSelection.deselect(parentNode)
      this.partiallySelection.deselect(parentNode)
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: IssueTypeFlatNode): IssueTypeFlatNode | null {
    const currentLevel = this.getLevel(node)
    if (currentLevel < 1) {
      return null
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i]
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode
      }
    }
    return null
  }
}
