/* global angular */
/**
 * Created by ryanrubbico on 9/7/17.
 */
angular.module('smartvid').service('secretSlideshowHelper', function ($rootScope, $interval, NavController) {
  class SecretSlideshowHelper {
    constructor () {
      this.interval = null
    }

    startSlideshow () {
      $rootScope.$broadcast('sv-secret-slideshow-activate')
      $rootScope.secretSlidshowActive = true
      NavController.activateTemporarySaveMode()
      NavController.closeLeftNav(true)
      NavController.closeRightNav(true)
      this.interval = $interval(function () {
        $rootScope.$broadcast('sv-secret-slideshow-transition')
      }, 15000)
    }

    endSlideshow () {
      $rootScope.$broadcast('sv-secret-slideshow-deactivate')
      $rootScope.secretSlidshowActive = false
      NavController.deactivateTemporarySaveMode()
      NavController.init()
      $interval.cancel(this.interval)
    }
  }

  return new SecretSlideshowHelper()
})
