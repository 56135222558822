/* global angular */

angular.module('smartvid').directive('timeinput', function ($filter) {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, ngModelController) {
      ngModelController.$parsers.push(function (data) {
        return $filter('videoplaybacktimetoseconds')(data)
      })

      ngModelController.$formatters.push(function (data) {
        return $filter('videoplaybacktime')(data)
      })
    }
  }
})
