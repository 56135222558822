/* global angular _ */

angular.module('smartvid').controller('ScheduleGlobalDemoProjectsResetCtrl', function ($scope, $filter, $state, $timeout, smartvidApi, currentUser) {
  $scope.actionInProgress = false
  $scope.data = undefined
  $scope.adminMode = false

  $scope.resetAllDemoProjects = () => {
    $scope.actionInProgress = true
    smartvidApi.scheduleCopyDemoProjectForAllUsers().then((data) => {
      $scope.actionInProgress = false
      $scope.data = data
    }, () => {
      $scope.actionInProgress = false
    })
  }

  $scope.refreshStatus = () => {
    $scope.actionInProgress = true
    let promises = []
    _.each($scope.data.successfullyScheduled, (e, i) => {
      if (e.statusResponse.status !== 'FAILED') {
        promises.push(smartvidApi.getCopyProjectProgress(e.statusResponse.copyProjectProcessId).then((data) => {
          $scope.data.successfullyScheduled[i].statusResponse = data
        }))
      }
    })

    Promise.all(promises).then(() => {
      $timeout(() => {
        $scope.actionInProgress = false
      }, 100)
    }, () => {
      $timeout(() => {
        $scope.actionInProgress = false
      }, 100)
    })
  }

  if (!currentUser.isAuthenticated()) {
    $state.go('login')
  }

  smartvidApi.ensureAdminMode().then(() => {
    $scope.adminMode = true
  })
})
