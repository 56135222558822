/* global angular, _, BUILD_VERSION */

angular.module('smartvid').factory('apiInterceptor', function ($q, $filter, $injector, $log, $rootScope, jstzService) {
  // ----------------------- Notification setup (to skirt circular dependcy issue)
  /* Because of the circular dependency problem with $http and Notification,
   * we have to inject it but we also have to wait (gotta love Angular). So,
   * the solution is to inject it singleton-style _when_ we need it. I've done
   * that here by wrapping the service with this `notify` object.
   */

  function getNotification () {
    return $injector.get('Notification')
  }

  function getCurrentUser () {
    return $injector.get('currentUser')
  }

  function getContentSharingContext () {
    return $injector.get('contentSharingContext')
  }

  function getUtils () {
    return $injector.get('utils')
  }

  let notify = {
    error (payload) {
      getNotification().error(payload)
    }
  }

  const contentSourceTypeDetails = $rootScope.isMobile ? 'MOBILE' : undefined

  let request = (config) => {
    let headers
    let authCall = (config.url.indexOf('authenticate') !== -1 || config.url.endsWith('/impersonate')) &&
      config.method.toLowerCase() === 'post'

    if (authCall) {
      // auth headers
      headers = _.assign(config.headers, {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      })
    } else {
      headers = _.assign(config.headers, {
        'X-Auth-Token': getCurrentUser().token,
        'Accept': 'application/json'
      })
    }

    headers = _.assign(headers, {
      'X-User-Smartvid-TimeZone': jstzService.jstz().determine().name()
    })

    let tagConfidenceLevelService = $injector.get('tagConfidenceLevelService')
    if (tagConfidenceLevelService.getTagConfidenceLevel() &&
      !('Smartvid-Tag-Confidence-Level' in headers)) {
      headers = _.assign(headers, {
        'Smartvid-Tag-Confidence-Level': tagConfidenceLevelService.getTagConfidenceLevel()
      })
    }

    if (getContentSharingContext().getLinkId()) {
      headers = _.assign(headers, {
        'Smartvid-Shared-Link-Id': getContentSharingContext().getLinkId(),
        'Smartvid-Shared-Content-Type': getContentSharingContext().getSharedContentType()
      })
    }

    headers = _.assign(headers, {
      'Smartvid-Request-Type': 'WWW',
      'Smartvid-Request-Type-Details': contentSourceTypeDetails,
      'Smartvid-Request-Client-Version': BUILD_VERSION
    })

    config.headers = headers

    return config
  }

  const ERROR_CODES = ['BAD_REQUEST', 'NOT_FOUND']
  let responseError = (response) => {
    let errorOptions

    let setErrorOptions = () => {
      if (!response.data) {
        return
      }
      let errorMessages = response.data.errorMessages
      let message = ''

      if (_.isArray(errorMessages) && !_.isEmpty(errorMessages) && _.isString(errorMessages[0].message)) {
        message = errorMessages[0].message
      } else {
        message = _.map(errorMessages, function (e) {
          return $filter('i18next')('errorCodes.' + e.label.replace(/\./g, '_'))
        }).join('\n')
      }

      errorOptions = {
        title: $filter('i18next')('errorCodes.title'),
        message: message
      }
    }

    let showErrorOptions = () => {
      if (errorOptions && !$rootScope.isMobile && response.config.url.indexOf('import') === -1) {
        $log.log(response)
        const HTTP_ERROR_CODE_TOO_MANY_REQUESTS = 429
        if (response.status !== HTTP_ERROR_CODE_TOO_MANY_REQUESTS) {
          notify.error(errorOptions)
        }
      }
    }

    // Check if custom error handler is defined for this request. If custom error handler returns true,
    // don't do generic error handling.
    if (response && response.config && response.config.customErrorHandler &&
      response.config.customErrorHandler(response.data)) {
      return $q.reject(response)
    } else if (response && response.data &&
      _.contains(ERROR_CODES, response.data.errorCode) && 'errorMessages' in response.data) {
      if (!$filter('i18next')('errorCodes.title')) {
        // This may happen when i18next hasn't finished loading yet. We need need to wait for
        // its initialization to complete.
        $rootScope.$on('i18nextLanguageChange', function () {
          setErrorOptions()
          showErrorOptions()
        })
      } else {
        setErrorOptions()
      }
    } else if (!response || response.status === 0) {
      // TODO: handle login case for now, but come up with better way to register specific error handling

      // This is a very unhelpful message. need better error notification system for users.
      // errorOptions = {
      //  title: $filter('i18next')('common.server.no_connection.title'),
      //  message: $filter('i18next')('common.server.no_connection.message')
      // }
      return $q.reject(response)
    } else if (response && response.config && response.config.url.indexOf('authenticate') !== -1 && response.status === 404) {
      errorOptions = {
        title: $filter('i18next')('login.error.not_found.title'),
        message: $filter('i18next')('login.error.not_found.message')
      }
    } else if (response && ((response.statusText === 'Unauthorized' && response.status === 401) || response.status === 403)) {
      if (!getCurrentUser().isSignedOut()) {
        $injector.get('$state').go('unauthorized')
      }
    } else if (getUtils().isTimeoutResponse(response)) {
      // Token expired. Reject here and reset user token
      getCurrentUser().timeoutUser()
      return $q.reject(response)
    } else {
      setErrorOptions()
    }

    showErrorOptions()

    return $q.reject(response)
  }

  let response = (response) => {
    if (response.headers('x-auth-token-renew') && !getCurrentUser().isSignedOut()) {
      getCurrentUser().renewToken(response.headers('x-auth-token-renew'), response.headers('x-auth-token-expires-renew'))
    }
    return response
  }

  return {
    request: request,
    responseError: responseError,
    response: response
  }
})
  .config(function ($httpProvider) {
    $httpProvider.interceptors.push('apiInterceptor')
  })
