/* global angular _ */

angular.module('smartvid').factory('ProcoreObservationIssueTypeModel', function (BaseModel) {
  class ProcoreObservationIssueTypeModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined,
        category: undefined,
        name: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return ProcoreObservationIssueTypeModel
})
