/* global angular, _, BUILD_VERSION */

angular.module('smartvid').service('assetsApi', function ($http, $q, $log, $injector, $rootScope, config, CommentsCollection, UserModel, CommentModel, TagInstanceCollection,
                                                          TagInstanceModel, utils, jstzService, searchResultService, AssetType, tagConfidenceApiUtils) {
  let rootUrl = config.env.development.apiRootUrl

  // -------------------------------------------- "private" methods

  function getTagThumbnailForAsset (assetDetails) {
    if (assetDetails && assetDetails.asset && assetDetails.asset.projectId && assetDetails.tagInstanceList) {
      let tagInstanceList = assetDetails.tagInstanceList
      let searchedTags = (searchResultService.isInSearchContext()) ? searchResultService.getCurrentSearchContext().searchTags : undefined

      if (!_.isEmpty(searchedTags) && !_.isEmpty(tagInstanceList)) {
        let tagInstanceToFind = searchedTags[0]
        // return first instance of first search tag
        for (let i = 0; i < tagInstanceList.length; i++) {
          if (tagInstanceList[i].tagDefinitionId === tagInstanceToFind.id) {
            return tagInstanceList[i].thumbnailUrl
          }
        }
      }
    }

    return undefined
  }

  function findBestRendition (files) {
    if (!files || files.size === 0) {
      return undefined
    }
    let file = _.find(files, (f) => {
      return f.type === 'MdMp4VideoFile'
    })

    if (!file) {
      file = _.find(files, (f) => {
        return f.type === 'IphoneVideoFile'
      })
    }

    if (!file) {
      file = _.find(files, (f) => {
        return f.type === 'OriginalFile'
      })
    }
    return file
  }

  function _formatAsset (assetDetails) {
    let asset = assetDetails.asset
    asset.commentsCount = assetDetails.commentsCount
    asset.tagsCount = assetDetails.tagsCount
    asset.unverifiedTagsCount = assetDetails.unverifiedTagsCount
    asset.userDescription = asset.userDescription || ''
    if (assetDetails.creatorUser) {
      asset.creatorUser = new UserModel(assetDetails.creatorUser)
    }
    if (assetDetails.childToParentTagMap) {
      asset.childToParentTagMap = assetDetails.childToParentTagMap
    }
    if (asset.type === AssetType.VIDEO) {
      asset.processingProgress = assetDetails.assetMetaDataWithMediaInfoResponse.videoTranscodingProgress
      asset.duration = assetDetails.assetMetaDataWithMediaInfoResponse.videoDuration
      asset.assets = assetDetails.assetMetaDataWithMediaInfoResponse.assetMediaInfo
    }
    let files = assetDetails.assetMetaDataWithMediaInfoResponse.assetMediaInfo
    let file = findBestRendition(files)
    if (file) {
      if (asset.type === AssetType.IMAGE) {
        asset.thumbnailUrl = file.assetMediaThumbnail ? file.assetMediaThumbnail.url : undefined
        asset.imageUrl = file.accessUrl
        asset.audioAccessUrl = file.audioAccessUrl
        asset.tagThumbnailUrl = undefined
      } else {
        if (file.assetMediaInfoStatus === 'ERROR') {
          asset.isError = true
          // TODO(vgorsky) Handle isError in templates
        } else {
          let originalFile = _.find(files, (f) => {
            return f.type === 'OriginalFile'
          })
          if (assetDetails.tagInstanceList && assetDetails.tagInstanceList.length > 0) {
            asset.tagThumbnailUrl = getTagThumbnailForAsset(assetDetails)
          } else if (assetDetails.tagThumbnailUrl) {
            asset.tagThumbnailUrl = assetDetails.tagThumbnailUrl
          } else {
            asset.tagThumbnailUrl = undefined
          }
          asset.thumbnailUrl = originalFile.assetMediaThumbnail.url
        }
      }
    }
    return asset
  }

  let api = {
    _formatAssetNewAPI (assetDetails) {
      // TODO(vgorsky) Replace old server asset API with new response format. Remove _formatAsset
      let asset = assetDetails

      asset.debugInfo = assetDetails.debugInfo
      asset.commentsCount = assetDetails.commentsCount
      asset.tagsCount = assetDetails.tagsCount
      asset.unverifiedTagsCount = assetDetails.unverifiedTagsCount
      if (assetDetails.creatorUser) {
        asset.creatorUser = new UserModel(assetDetails.creatorUser)
      }

      if (!assetDetails.metadata) {
        return asset
      }

      if (asset.type === AssetType.IMAGE) {
        asset.thumbnailUrl = assetDetails.metadata.thumbnailUrl
        asset.imageUrl = assetDetails.metadata.accessUrl
        asset.audioAccessUrl = assetDetails.metadata.audioAccessUrl
        asset.projectionType = assetDetails.metadata.projectionType
        asset.isPossible360Image = assetDetails.metadata.isPossible360Image
      } else {
        asset.thumbnailUrl = assetDetails.metadata.thumbnailUrl
        asset.processingProgress = assetDetails.metadata.videoTranscodingProgress
        asset.duration = assetDetails.metadata.videoDuration
        asset.tagThumbnailUrl = (assetDetails.tagInstanceList && assetDetails.tagInstanceList[0] && assetDetails.tagInstanceList[0].thumbnailUrl) ? assetDetails.tagInstanceList[0].thumbnailUrl : undefined
        // TODO(vgorsky) Update getMediaSource once conversion to new API response is done for
        // all of the asset endpoints.
        asset.assets = [{
          accessUrl: assetDetails.metadata.accessUrl,
          contentType: 'video/mp4'
        }]
      }
      asset.metadata = assetDetails.metadata.additionalMetadata
      return asset
    },
    _formatMediaAssets (assets) {
      let mapped = _.map(assets, (assetDetails) => {
        return _formatAsset(assetDetails)
      })

      return mapped
    },

    _getServerAssets (url, params, options = {}) {
      let defaults = {
        pageSize: 50,
        page: 0,
        includeTranscodedMediaInfo: false,
        includeLinkedObservations: false,
        sortInfo: []
      }

      _.defaults(options, defaults)

      let defer = $q.defer()

      params.offset = options.pageSize * options.page
      params.limit = options.pageSize
      params.includeTranscodedMediaInfo = options.includeTranscodedMediaInfo
      params.includeLinkedObservations = options.includeLinkedObservations

      if (options.sortInfo) {
        params.sortColumn = _.map(options.sortInfo, (sortColumn) => {
          return `${sortColumn.sortBy}:${sortColumn.sortOrder}`
        })
      }

      $http.get(url, {params: params}).then((response) => {
        let assets = api._formatMediaAssets(response.data.data)

        // The API returns data which are the assets as well a sibling field to indicate how many assets
        // there are in total.
        $log.debug('_getServerAssets', response.data.data)
        defer.resolve(assets)
      }).catch((response) => {
        defer.reject(response)
      })

      return defer.promise
    },

    createAsset (projectId, fileInfo, tags = [], doAsr = false, doImrec = false, checkForDups, folderPathTagDefs) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/assets/project/${projectId}/upload/registration`

      let payload = {
        tagDefsText: _.pluck(tags, 'text'),
        doAsr: doAsr,
        doImrec: doImrec,
        userTimeZone: jstzService.jstz().determine().name(),
        contentSource: {
          type: 'WWW',
          typeDetails: $rootScope.isMobile ? 'MOBILE' : undefined,
          clientVersion: BUILD_VERSION
        },
        checkForDups: checkForDups
      }
      if (folderPathTagDefs) {
        payload.s3KeyToFolderPathTagDefs = {}
        payload.s3KeyToFolderPathTagDefs[fileInfo.key] = folderPathTagDefs
      }
      let assetType = (utils.isVideo(fileInfo.file)) ? 'videos' : 'photos'
      payload[assetType] = [{
        s3Key: fileInfo.key,
        contentType: fileInfo.file.type,
        contentLength: fileInfo.file.size,
        fileName: fileInfo.file.name,
        checksum: fileInfo.checksum
      }]

      $http.post(url, payload).then(function (response) {
        // success
        $log.debug('createAsset', response.data)
        defer.resolve(response.data)
      }, function (response) {
        // error
        defer.reject(response)
      }).catch(function (response) {
        // error
        defer.reject(response)
      })

      return defer.promise
    },

    updateAsset (assetId, name, userDescription, is360Image) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/asset/${assetId}`

      let payload = {
        name: name,
        userDescription: userDescription,
        is360Image: is360Image
      }

      $http.put(url, payload).then(function (response) {
        $log.debug('updateAsset', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },
    getAsset (assetId, includeMetaData = true, includeTags = false, includeComments = true, includeOrgData = true,
               tagConfidenceLevel = undefined, includeLinkedObservations = false) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/asset/${assetId}?includeMetaData=${includeMetaData}&includeTags=${includeTags}&includeComments=${includeComments}&includeOrgData=${includeOrgData}&includeLinkedObservations=${includeLinkedObservations}`

      let request = tagConfidenceApiUtils.createGetRequest(url, tagConfidenceLevel)

      $http(request).then(function (response) {
        let commentCollection = new CommentsCollection()
        commentCollection.add(response.data.comments, CommentModel)
        let tagsCollection = new TagInstanceCollection()
        tagsCollection.add(response.data.tags, TagInstanceModel)
        let AssetModel = $injector.get('AssetModel')
        let model = new AssetModel(api._formatAssetNewAPI(response.data))
        model.comments = commentCollection
        model.tags = tagsCollection
        model.commentsCount = model.comments.length
        model.tagsCount = (model.tags) ? model.tags.length : 0
        model.unverifiedTagsCount = (model.tags) ? model.tags.getUnverifiedTags().length : 0
        model.totalCountTagConfidenceHigh = response.data.totalCountTagConfidenceHigh
        model.totalCountTagConfidenceMedium = response.data.totalCountTagConfidenceMedium
        model.totalCountTagConfidenceLow = response.data.totalCountTagConfidenceLow
        model.linkedObservations = response.data.linkedObservations
        defer.resolve(model)
      }).catch(function (data) {
        defer.reject(data)
      })

      return defer.promise
    },
    deleteAsset (assetId) {
      let url = `${rootUrl}/api/asset/${assetId}`
      let defer = $q.defer()

      $http.delete(url).then(function (response) {
        $log.debug('deleteAsset', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    undeleteAsset (assetId) {
      let url = `${rootUrl}/api/asset/undelete/${assetId}`
      let defer = $q.defer()
      let customErrorHandler = (response) => {
        if (response && response.errorCode === 'BAD_REQUEST') {
          const find = _.find(response.errorMessages, (m) => {
            return m.label === 'resource.asset.delete.in.progress'
          })
          if (find) {
            utils.notify('errorCodes.resource_asset_delete_in_progress')
          }

        }
        return !response || response.errorCode === 'BAD_REQUEST'
      }
      $http.put(url, '', {customErrorHandler: customErrorHandler}).then(function (response) {
        $log.debug('undeleteAsset', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    forceDeleteAsset (assetId) {
      let url = `${rootUrl}/api/asset/force/${assetId}`
      let defer = $q.defer()

      $http.delete(url).then(function (response) {
        $log.debug('forceDeleteAsset', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    reRunSmartTaggingAsset (assetId) {
      let url = `${rootUrl}/api/asset/smarttag/${assetId}`
      let defer = $q.defer()

      $http.put(url).then(function (response) {
        $log.debug('reRunSmartTaggingAsset', response.data)
        let AssetModel = $injector.get('AssetModel')
        let model = new AssetModel(api._formatAssetNewAPI(response.data))
        defer.resolve(model)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    moveAssets (payload) {
      let url = `${rootUrl}/api/project/assets/move`
      let defer = $q.defer()

      $http.post(url, payload).then(function (response) {
        $log.debug('moveAssets', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    },

    getMoveAssetsProgress (moveAssetsProcessId) {
      let url = `${rootUrl}/api/project/assets/move/status/${moveAssetsProcessId}`
      let defer = $q.defer()

      $http.get(url).then(function (response) {
        $log.debug('moveAssetsProgress', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    },

    runSnrAsset (assetId) {
      let url = `${rootUrl}/api/asset/snr/${assetId}`
      let defer = $q.defer()

      $http.put(url).then(function (response) {
        $log.debug('runSnrAsset', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    runDziAsset (assetId) {
      let url = `${rootUrl}/api/asset/dzi/${assetId}`
      let defer = $q.defer()

      $http.put(url).then(function (response) {
        $log.debug('runDziAsset', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    },
    getAssetsByIds (assetIds, requestParams, shouldRescheduleUponTooManyRequests) {
      let defer = $q.defer()
      var includeLinkedObservations = requestParams && requestParams.includeLinkedObservations
      let url = `${rootUrl}/api/assets?includeLinkedObservations=${includeLinkedObservations}`

      let payload = {
        assetIds: assetIds
      }

      let config = {
        shouldRescheduleUponTooManyRequests: shouldRescheduleUponTooManyRequests
      }

      $http.post(url, payload, config).then(function (response) {
        let assets = api._formatMediaAssets(response.data)
        defer.resolve(assets)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    getAssetImageUrl (assetId, urlPath) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/asset/${assetId}/image/url`

      let payload = {
        urlPath: urlPath
      }

      $http.post(url, payload).then(function (response) {
        defer.resolve(response.data.url)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    deleteAssets (multiAssetSelection) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/assets/batch/job/delete`

      $http.put(url, multiAssetSelection).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    exportAssetsWithTagInstanceCounts (multiAssetSelectionWithTagDefNames) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/assets/batch/job/assetexportwithtagcounts`

      $http.put(url, multiAssetSelectionWithTagDefNames).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    addTagInstanceToAssets (multiAssetSelection, tagInstance) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/assets/batch/job/tag`

      let payload = _.extend({}, multiAssetSelection, tagInstance)

      $http.put(url, payload).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    smartTagAssets (multiAssetSelection) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/assets/batch/job/smarttag`

      $http.put(url, multiAssetSelection).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getProjectAssets (projectId, options = {}) {
      let url = `${rootUrl}/api/assets`
      let params = {
        projectId: projectId
      }

      return api._getServerAssets(url, params, options)
    },

    getProjectAssetsCount (projectId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/assets/count`
      let params = {
        projectId: projectId
      }
      $http.get(url, {params: params}).then((response) => {
        defer.resolve(response.data.totalCount)
      })
      return defer.promise
    },

    getProjectDeletedAssets (projectId, options = {}) {
      let url = `${rootUrl}/api/assets/deleted`
      let params = {
        projectId: projectId
      }

      return api._getServerAssets(url, params, options)
    },

    getProjectDeletedAssetsCount (projectId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/assets/deleted/count`
      let params = {
        projectId: projectId
      }
      $http.get(url, {params: params}).then((response) => {
        defer.resolve(response.data.totalCount)
      })
      return defer.promise
    },

    getAssetPage (projectId, assetId, options) {
      let defaults = {
        pageSize: 50,
        sortInfo: []
      }

      _.defaults(options, defaults)

      let defer = $q.defer()
      let url = `${rootUrl}/api/asset/${assetId}/page`

      let params = {
        projectId: projectId,
        pageSize: options.pageSize
      }

      if (options.sortInfo) {
        params.sortColumn = _.map(options.sortInfo, (sortColumn) => {
          return `${sortColumn.sortBy}:${sortColumn.sortOrder}`
        })
      }

      $http.get(url, {params: params}).then((response) => {
        $log.debug('getAssetPage', response.data)
        defer.resolve(response.data.page)
      }, () => {
        defer.reject()
      })

      return defer.promise
    },

    getAssetGroupMetadata (projectId, sortOptions) {
      let defer = $q.defer()

      if (_.isEmpty(sortOptions.sortInfo)) {
        $log.error('Cannot retrieve asset group metadata without a \'groupBy\' column', new Error())
        defer.reject()
      }

      let options = {
        sortInfo: sortOptions.sortInfo
      }

      let url = `${rootUrl}/api/asset/group/metadata`

      let params = {
        projectId: projectId
      }

      if (options.sortInfo) {
        params.sortColumn = _.map(options.sortInfo, (sortColumn) => {
          return `${sortColumn.sortBy}:${sortColumn.sortOrder}`
        })
      }

      $http.get(url, {params: params}).then((response) => {
        $log.debug('getAssetGroups', response.data)
        defer.resolve(response.data)
      }, () => {
        defer.reject()
      })

      return defer.promise
    },

    getDeletedAssetGroupMetadata (projectId, sortOptions) {
      let defer = $q.defer()

      if (_.isEmpty(sortOptions.sortInfo)) {
        $log.error('Cannot retrieve asset group metadata without a \'groupBy\' column', new Error())
        defer.reject()
      }

      let options = {
        sortInfo: sortOptions.sortInfo
      }

      let url = `${rootUrl}/api/asset/deleted/group/metadata`

      let params = {
        projectId: projectId
      }

      if (options.sortInfo) {
        params.sortColumn = _.map(options.sortInfo, (sortColumn) => {
          return `${sortColumn.sortBy}:${sortColumn.sortOrder}`
        })
      }

      $http.get(url, {params: params}).then((response) => {
        $log.debug('getDeletedAssetGroups', response.data)
        defer.resolve(response.data)
      }, () => {
        defer.reject()
      })

      return defer.promise
    },

    getS3TTL (assetCount, projectId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/assets/project/${projectId}/upload/token`
      let config = {
        params: {
          assetCount: assetCount
        }
      }

      $http.get(url, config).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },

    /**
     * Return an image url for a video frame at param frameAtSecond
     */
    getAssetFrameUrl (assetId, tagId, frameAtSecond, clipRect) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/assets/${assetId}/media/snapshot/url?frameAtSecond=` + window.parseInt(frameAtSecond, 10)
      if (clipRect) {
        url += `&x=${clipRect.x}&y=${clipRect.y}&width=${clipRect.width}&height=${clipRect.height}`
      }
      if (tagId) {
        url += `&tagId=${tagId}`
      }

      $http.get(url).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },

    verifyAllTagsForAsset (assetId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/asset/${assetId}/tagStatus`

      $http.put(url).then((response) => {
        defer.resolve(response.data)
      }, (response) => {
        defer.reject(response)
      }).catch((response) => {
        defer.reject(response)
      })

      return defer.promise
    },

    /**
     * This API will initiate a creation of the download package and will return immediately.
     * @param packageName
     * @param projectId
     * @param packageItemInfo array of object with packageItemId and packageItemType
     * @returns {*}
     */
    createDownloadPackage (packageName, projectId, packageItemInfo, type = 'ASSET_AND_SNAPSHOT') {
      let defer = $q.defer()
      let url = `${rootUrl}/api/project/${projectId}/download/package?type=${type}`

      let payload = {
        // packageName: packageName,
        packageItemInfo: packageItemInfo
      }

      $http.post(url, payload).then(function (response) {
        // success
        defer.resolve(response.data)
      }, function (response) {
        // error
        defer.reject(response)
      }).catch(function (response) {
        // error
        defer.reject(response)
      })

      return defer.promise
    },

    /**
     * Check the status of a previously requested download package
     * @param projectId
     * @param packageId
     * @returns {*}
     */
    getDownloadPackageStatus (projectId, packageId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/project/${projectId}/download/package/${packageId}/type/ASSET_AND_SNAPSHOT/status`

      $http.get(url).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },

    /**
     * The response will be of binary content type with download package zip file as an attachment.
     */
    downloadPackage (projectId, packageId, type = 'ASSET_AND_SNAPSHOT') {
      let defer = $q.defer()
      let url = `${rootUrl}/api/project/${projectId}/download/package/${packageId}/type/${type}`

      $http.get(url).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response)
      }).catch(function (response) {
        defer.reject(response)
      })

      return defer.promise
    },

    getDuplicateChecksums (projectId, checksums) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/assets/project/${projectId}/duplicates`
      let payload = {
        checksums: checksums
      }
      $http.post(url, payload).then(function (response) {
        defer.resolve(response.data.duplicates)
      }, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    }

  }

  return api
})
