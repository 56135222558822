/* global angular */
angular.module('smartvid').service('assetDebugUtils', function ($log) {
  function printAssetDump (asset) {
    $log.debug('---------------------')
    $log.debug('Asset id:' + asset.id)
    $log.debug('Asset name:' + asset.name)
    $log.debug(asset.statusDebugInfo)
    if (asset.debugInfo) {
      $log.debug('Vinnie model version=' + asset.debugInfo.vinnieModelVersion)
    }
    $log.debug('---------------------')
  }
  return {
    printAssetDump: printAssetDump
  }
})
