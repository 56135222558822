/* global angular,_,$ */

angular.module('smartvid').factory('ProjectsCollection', function (
    BaseCollection, ProjectModel, smartvidApi, utils, $q
) {
  class ProjectsCollection extends BaseCollection {
    constructor (params) {
      let properties = $.extend({
        searchContext: null,
        sharingType: null,
        sharingId: null,
        orgId: null,
        projectGroupId: null,
        searchDeletedProjectsContext: null
      }, params)
      super(undefined, ProjectModel)
      this.searchContext = properties.searchContext
      this.sharingType = properties.sharingType
      this.sharingId = properties.sharingId
      this.orgId = properties.orgId
      this.projectGroupId = properties.projectGroupId
      this.isFetching = true
      this.partialText = properties.partialText
      this.searchDeletedProjectsContext = properties.searchDeletedProjectsContext
      this.collectionModels = properties.collectionModels
      if (properties.beforeDataLoaded) {
        properties.beforeDataLoaded()
      }
      this.projectPromise = this.retrieveProjects()
      if (properties.afterDataLoaded) {
        this.projectPromise.then(properties.afterDataLoaded, properties.afterDataLoaded)
      }
    }

    getModels () {
      let projectModels = this.collectionModels
      if (this.orgId) {
        projectModels = _.filter(projectModels, (pModel) => {
          return pModel.organizationId === this.orgId
        })
      }
      if (this.projectGroupId) {
        projectModels = _.filter(projectModels, (pModel) => {
          return pModel.projectGroupId === this.projectGroupId
        })
      }
      if (this.partialText && this.partialText.length > 0) {
        projectModels = _.filter(projectModels, (pModel) => {
          return pModel.name && pModel.name.length > 0 &&
            pModel.name.toLowerCase().includes(this.partialText.toLowerCase())
        })
      }
      return projectModels
    }
    retrieveProjects () {
      let defer = $q.defer()
      this.isFetching = true
      let promise = null
      if (this.collectionModels && this.collectionModels.length > 0) {
        const models = this.getModels()
        super.upsert(models, ProjectModel)
        this.isFetching = false
        defer.resolve()
        return defer.promise
      }
      if (this.sharingType && this.sharingType !== 'xsearch') {
        promise = smartvidApi.getSharedProjects(this.sharingType, this.sharingId)
      } else if (this.searchContext) {
        promise = smartvidApi.getSearchedProjects(this.searchContext)
      } else if (this.searchDeletedProjectsContext) {
        promise = smartvidApi.getUserDeletedProjects(this.searchDeletedProjectsContext.organizationId)
      } else {
        promise = smartvidApi.getUserProjects.call(null, 0, 1000, this.partialText)
      }
      promise.then(projects => {
        let mappedProjects = _.map(projects, function (projectWithRole) {
          return new ProjectModel(utils.flattenProject(projectWithRole))
        })
        super.upsert(mappedProjects, ProjectModel)
        this.isFetching = false
        defer.resolve()
      })
      return defer.promise
    }
  }

  return ProjectsCollection
})
