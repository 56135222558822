/* global angular */

angular.module('smartvid').directive('projectMemberGridInfoPanel', function (smartvidApi, dashboardDataHelper) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'ProjectMemberGrid/infopanel.html',
    link (scope, elem) {
      scope.selectedMembers = null
      scope.currentlySelectedMember = null
      scope.isFetchingProjectUserDetails = false

      function updateSelectedMembers () {
        scope.selectedMembers = scope.projectMembers.where({selected: true})
        let selectedMember = scope.selectedMembers && scope.selectedMembers.length === 1 ? scope.selectedMembers[0] : null
        scope.currentlySelectedMember

        if (selectedMember) {
          scope.isFetchingProjectUserDetails = true
          smartvidApi.getProjectUserDetails(dashboardDataHelper.getCurrentProject().id, selectedMember.userId).then(
          (data) => {
            scope.currentlySelectedMember = data
            scope.isFetchingProjectUserDetails = false
          }, () => {
            scope.isFetchingProjectUserDetails = false
          })
        }
      }

      scope.$on('sv-placards-selected', updateSelectedMembers)
    }
  }
})

