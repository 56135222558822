/* global angular */

angular.module('smartvid').controller('NewUserCtrl', function (MAX_USER_FIRST_NAME_LENGTH, MAX_USER_LAST_NAME_LENGTH, $scope, $log, $stateParams, $state, $rootScope, $location, smartvidApi, $filter, Notification, $timeout, $cookies) {
  $scope.MAX_USER_FIRST_NAME_LENGTH = MAX_USER_FIRST_NAME_LENGTH
  $scope.MAX_USER_LAST_NAME_LENGTH = MAX_USER_LAST_NAME_LENGTH

  $scope.submitted = false
  $scope.inTransit = false
  $scope.failedToUpdate = false

  let getParameterValue = (value) => {
    if (!value) {
      return undefined
    }
    try {
      return window.atob(value)
    } catch (e) {
      $log.error('Argument value is invalid ' + value)
      $log.error(e)
    }
    return undefined
  }

  // Support encoded page parameters to pre-fill information about the user
  $scope.firstName = getParameterValue($stateParams.firstName)
  $scope.lastName = getParameterValue($stateParams.lastName)
  $scope.email = getParameterValue($stateParams.email)
  $scope.isProcoreTrial = $stateParams.isProcoreTrial
  $scope.isEgnyteTrial = $stateParams.isEgnyteTrial
  $scope.isBim360Trial = $stateParams.isBim360Trial
  $scope.isOxBlueTrial = $stateParams.isOxBlueTrial
  $scope.isBoxTrial = $stateParams.isBoxTrial

  $scope.password
  $scope.confirmPassword

  $scope.submit = function () {
    $scope.inTransit = true
    let promise = smartvidApi.registerNewUser(
      $scope.firstName, $scope.lastName, $scope.email, $scope.password,
      $scope.isProcoreTrial, $scope.isBim360Trial, $scope.isEgnyteTrial,
      $scope.isOxBlueTrial, $scope.isBoxTrial,
      $cookies.get('hubspotutk'))
    promise.then((data) => {
      $timeout(function () {
        $scope.inTransit = false
        if (data.organizationNameDomainMatch) {
          $state.go('auto-join-request', {
            organizationName: data.organizationNameDomainMatch,
            organizationId: data.organizationId,
            userId: data.userId
          })
        } else {
          $state.go('welcome')
        }
      }, 100)
    }, (data) => {
      $scope.inTransit = false
      $scope.failedToUpdate = true
      Notification.error({
        message: $filter('i18next')('errorCodes.failed_register_new_user')
      })
      // $location.url('/new-user')
    })
    return promise
  }

  $scope.submittable = function () {
    return $scope.form.$valid && $scope.password && $scope.password.length >= 8
  }
})
