import { Directive, Input, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core'
import { DashboardTabId } from 'modules/insights/dashboard/models/insights-dashboard.model'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'

@Directive({
  selector: '[svDashboardTab]',
})
export class DashboardTabDirective {
  private tabId: DashboardTabId

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private dashboardConfigService: InsightsDashboardConfigurationService
  ) {}

  @Input()
  set svDashboardTab(tabId: DashboardTabId) {
    this.tabId = tabId
    this.updateView()
  }

  get svDashboardTab(): DashboardTabId {
    return this.tabId
  }

  private updateView() {
    if (this.tabId && this.dashboardConfigService.hasTab(this.tabId)) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    } else {
      this.viewContainer.clear()
    }
  }
}
