<div class="reports-table-container">
    <div class="reports-description-container">
        <div class="report-headline">{{'reporting.reportType.' + reportType | translate}}</div>
        <div class="report-amount-description"
             *ngIf="!this.dataSource.loadingSubject$.getValue()">
            {{ reportType === reportTypeAdapter.NEVER ? dataSource.reportsCount + ' Reports' : dataSource.reportsCount + ' Scheduled Reports'}}
        </div>
    </div>

    <mat-spinner class="table-spinner" diameter="64" *ngIf="this.dataSource.loadingSubject$.getValue()"></mat-spinner>
    <table mat-table [dataSource]="dataSource" class="reports-table"
           *ngIf="!this.dataSource.loadingSubject$.getValue()">

        <ng-container matColumnDef="nextScheduled">
            <th mat-header-cell *matHeaderCellDef>
                {{reportType === reportTypeAdapter.NEVER ? 'Created Date' : 'Next Scheduled' }}
            </th>
            <td mat-cell *matCellDef="let row">
                <div *ngIf="row.isInactive" style="color: red">Deactivated</div>
                <div *ngIf="!row.isInactive">
                    {{reportType === reportTypeAdapter.NEVER ?
                  (row.createdDate === 0 ? '' : row.createdDate | date: 'MMM d, y h:mm a') :
                  (row.nextScheduledDate | date: 'MMM d, y h:mm a')
                    }}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="reportType">
            <th mat-header-cell *matHeaderCellDef>
                Report Type
            </th>
            <td mat-cell *matCellDef="let row">
                {{'reporting.' + row.reportName | translate}}
            </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef>
                Created By
            </th>
            <td mat-cell *matCellDef="let row">
                {{row.createdBy}}
            </td>
        </ng-container>

        <ng-container matColumnDef="projects">
            <th mat-header-cell *matHeaderCellDef>
                Projects
            </th>
            <td mat-cell *matCellDef="let row">
                <div *ngIf="row.projectNames.length > 1"
                     class="report-tooltip">{{row.projectNames.slice(0, 1).join(', ') + '...'}}
                    <span class="tooltiptext"
                          style="width: 300px;  margin-left: -150px;">{{row.projectNames.join(', ')}}</span>
                </div>
                <div *ngIf="row.projectNames.length <= 1">
                    {{row.projectNames.join(', ')}}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="tags">
            <th mat-header-cell *matHeaderCellDef>
                Tags
            </th>
            <td mat-cell *matCellDef="let row">
                <div *ngIf="row.tags.length > 5" class="report-tooltip">{{row.tags.slice(0, 4).join(', ') + '...'}}
                    <span class="tooltiptext">{{row.tags.join(', ')}}</span>
                </div>
                <div *ngIf="row.tags.length <=5">
                    {{row.tags.join(', ')}}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="runReport(row)">
                        <span>Run Report</span>
                    </button>
                    <button mat-menu-item (click)="editReport(row)">
                        <span>Edit Report</span>
                    </button>
                   <div *ngIf="reportType !== reportTypeAdapter.NEVER">
                    <button mat-menu-item (click)="activateReport(row)">
                        <span>{{!row.isInactive ? 'Deactivate Report' : 'Activate Report'}} </span>
                    </button>
                    </div>
                    <div *ngIf="reportType !== reportTypeAdapter.NEVER">
                        <button mat-menu-item (click)="deleteReport(row)">
                            <span>Delete Report</span>
                        </button>
                    </div>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

</div>
