import _ from 'lodash'
import moment from 'moment'
import { first, map } from 'rxjs/operators'
import { toObservable } from 'shared/utils/api-utils'
import { InsightsDashboardServiceHelper } from './insights-dashboard-service-helper'
import { ConfigService } from '../../../core/services/config.service'
import { TranslateService } from '@ngx-translate/core'
import {
  ScopeObjectType,
  DashboardDataObjectType,
  DashboardDataResponse,
  InsightsDashboardParameters,
  DateRangeChartData,
  CancellableRequestSubscription,
} from '../models/insights-dashboard.model'
import {
  DashboardOrganizationObservationActivity,
  DashboardOrganizationObservationSafety,
  DashboardOrganizationObservationWorkflow,
  OrganizationObservationTotalCounts,
  OBSERVATIONS_BY_TYPE_CHART_COLUMNS,
  OBSERVATIONS_BY_CHART_OPTIONS,
  OBSERVATIONS_BY_IDENTIFICATION_METHOD_CHART_COLUMNS,
  OBSERVATIONS_BY_IDENTIFICATION_METHOD_CHART_OPTIONS,
  OBSERVATIONS_OVERTIME_BY_TYPE_CHART_COLUMNS,
  OBSERVATIONS_OVERTIME_BY_TYPE_CHART_OPTIONS,
  OBSERVATIONS_GREAT_CATCH_BY_TYPE_CHART_COLUMNS,
  OBSERVATIONS_GREAT_CATCH_BY_TYPE_CHART_OPTIONS,
  OBSERVATIONS_BY_CREATOR_CHART_COLUMNS,
  OBSERVATIONS_BY_CREATOR_CHART_OPTIONS,
  OBSERVATIONS_BY_RISK_BAND_CHART_OPTIONS,
  OBSERVATIONS_BY_RISK_BAND_CHART_COLUMNS,
  OBSERVATIONS_BY_RISK_SCORE_CHART_COLUMNS,
  OBSERVATIONS_BY_RISK_SCORE_CHART_OPTIONS,
  OBSERVATIONS_BY_HAZARD_CATEGORY_CHART_COLUMNS,
  OBSERVATIONS_BY_HAZARD_CATEGORY_CHART_OPTIONS,
  OBSERVATIONS_RISK_SCORE_OVER_TIME_CHART_OPTIONS,
  OBSERVATION_RISK_SCORE_OVER_TIME_CHART_COLUMNS,
  OBSERVATIONS_OVERTIME_BY_STATUS_CHART_COLUMNS,
  OBSERVATIONS_COUNT_BY_OPEN_VS_CLOSED_CHART_COLUMNS,
  OBSERVATIONS_COUNT_BY_OPEN_VS_CLOSED_CHART_OPTIONS,
  OBSERVATIONS_AVG_DAYS_OPEN_BY_RISK_BAND_CHART_COLUMNS,
  OBSERVATIONS_AVG_DAYS_OPEN_BY_RISK_BAND_CHART_OPTIONS,
  OBSERVATIONS_OPEN_BY_STATUS_CHART_COLUMNS,
  OBSERVATIONS_OPEN_BY_STATUS_CHART_OPTIONS,
  OBSERVATIONS_OVERTIME_BY_RISK_BAND_CHART_OPTIONS,
  applyRiskBandColors,
} from '../models/insights-dashboard-observations.model'
import { makeMultilineChartTooltip } from '../utils/dashboard-utils'
import {
  PROJECT_TOTALS_CHART_OPTIONS,
  PROJECT_TOTALS_CHART_DATA_COLUMNS,
} from '../models/insights-dashboard-project-assets.model'
import Q from 'q'

export abstract class InsightsDashboardObservationsServiceBase {
  constructor(
    private scopeObjectType: ScopeObjectType,
    protected $http: ng.IHttpService,
    config: ConfigService,
    protected insightsDashboardServiceHelper: InsightsDashboardServiceHelper,
    protected translate: TranslateService
  ) {}

  protected abstract getObservationsOverTimeByRiskBandDataObjectType(): DashboardDataObjectType

  loadObservationsOverTimeByRiskBandData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleDateBasedChartData(
      dashboardParameters,
      this.getObservationsOverTimeByRiskBandDataObjectType(),
      OBSERVATIONS_OVERTIME_BY_TYPE_CHART_COLUMNS,
      true,
      OBSERVATIONS_OVERTIME_BY_RISK_BAND_CHART_OPTIONS,
      onDataLoadedFunc,
      this.scopeObjectType
    )
  }

  protected abstract getObservationScoresOverTimeObjectType(): DashboardDataObjectType

  loadObservationScoresOverTime(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    let url = this.insightsDashboardServiceHelper.getApiUrl(dashboardParameters.organizationId)
    let payload = InsightsDashboardServiceHelper.createDashboardRequest(
      this.getObservationScoresOverTimeObjectType(),
      dashboardParameters,
      this.scopeObjectType
    )
    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardDataResponse<DateRangeChartData>>(
      this.$http.post(url, payload),
      DashboardDataResponse
    )
      .pipe(first())
      .pipe(
        map((data: DashboardDataResponse<DateRangeChartData>) => {
          let dateRangeChartData = data.dashboardData[this.getObservationScoresOverTimeObjectType()]
          if (
            !dateRangeChartData.chartData ||
            !dateRangeChartData.chartData.dataTable ||
            dateRangeChartData.chartData.dataTable.length === 0
          ) {
            return dateRangeChartData
          }
          // first row of the table contains column names an tooltip data if any
          dateRangeChartData.chartData.dataTable = [
            [...this.insightsDashboardServiceHelper.translateStringsIn(OBSERVATION_RISK_SCORE_OVER_TIME_CHART_COLUMNS)],
            ...dateRangeChartData.chartData.dataTable,
          ]

          let is_data_fully_empty = true

          for (let i = 1; i < dateRangeChartData.chartData.dataTable.length; i++) {
            dateRangeChartData.chartData.dataTable[i][0] = moment(
              new Date(dateRangeChartData.chartData.dataTable[i][0])
            )
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0)
              .set('millisecond', 0)
              .toDate()

            let max = dateRangeChartData.chartData.dataTable[i][1]
            let avg = dateRangeChartData.chartData.dataTable[i][2]
            if (is_data_fully_empty) {
              is_data_fully_empty = !(max || avg)
            }
            dateRangeChartData.chartData.dataTable[i][1] = max !== null && max !== undefined ? _.round(max, 1) : null
            dateRangeChartData.chartData.dataTable[i][2] = avg !== null && avg !== undefined ? _.round(avg, 1) : null
          }

          if (is_data_fully_empty) {
            // workaround the bug/feature of Google Charts where
            // error is displayed if all values are null or undefined
            dateRangeChartData.chartData.dataTable = []
          }

          dateRangeChartData.chartData.options = { ..._.cloneDeep(OBSERVATIONS_RISK_SCORE_OVER_TIME_CHART_OPTIONS) }
          if (dateRangeChartData.chartData.dataTable.length > 1) {
            InsightsDashboardServiceHelper.setupHAxis(
              dateRangeChartData.chartData,
              dashboardParameters.dateRangeTimeUnit
            )
          }
          return dateRangeChartData
        })
      )
      .subscribe(data => {
        onDataLoadedFunc(data)
      })
    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  protected abstract getObservationsByHazardCategoryDataObjectType(): DashboardDataObjectType

  loadObservationsByHazardCategoryData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      this.getObservationsByHazardCategoryDataObjectType(),
      OBSERVATIONS_BY_HAZARD_CATEGORY_CHART_COLUMNS,
      OBSERVATIONS_BY_HAZARD_CATEGORY_CHART_OPTIONS,
      onDataLoadedFunc,
      this.scopeObjectType
    )
  }

  protected abstract getObservationsByRiskBandDataObjectType(): DashboardDataObjectType

  loadObservationsByRiskBandData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      this.getObservationsByRiskBandDataObjectType(),
      OBSERVATIONS_BY_RISK_BAND_CHART_COLUMNS,
      OBSERVATIONS_BY_RISK_BAND_CHART_OPTIONS,
      onDataLoadedFunc,
      this.scopeObjectType
    )
  }

  protected abstract getObservationsByRiskScoreObjectType(): DashboardDataObjectType

  loadObservationsByRiskScoreData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      this.getObservationsByRiskScoreObjectType(),
      OBSERVATIONS_BY_RISK_SCORE_CHART_COLUMNS,
      OBSERVATIONS_BY_RISK_SCORE_CHART_OPTIONS,
      onDataLoadedFunc,
      this.scopeObjectType
    )
  }

  protected abstract getObservationDateRangeTotalsObjectType(): DashboardDataObjectType

  loadObservationTotalsData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: OrganizationObservationTotalCounts) => void
  ) {
    let url = this.insightsDashboardServiceHelper.getApiUrl(dashboardParameters.organizationId)
    let payload = InsightsDashboardServiceHelper.createDashboardRequest(
      this.getObservationDateRangeTotalsObjectType(),
      dashboardParameters,
      this.scopeObjectType
    )
    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardDataResponse<OrganizationObservationTotalCounts>>(
      this.$http.post(url, payload),
      DashboardDataResponse
    )
      .pipe(first())
      .pipe(
        map((data: DashboardDataResponse<OrganizationObservationTotalCounts>) => {
          return data.dashboardData[this.getObservationDateRangeTotalsObjectType()]
        })
      )
      .subscribe(data => {
        onDataLoadedFunc(data)
      })
    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  protected abstract getObservationsOverTimeDataObjectType(): DashboardDataObjectType

  loadObservationsOverTimeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleDateBasedChartData(
      dashboardParameters,
      this.getObservationsOverTimeDataObjectType(),
      OBSERVATIONS_OVERTIME_BY_TYPE_CHART_COLUMNS,
      true,
      OBSERVATIONS_OVERTIME_BY_TYPE_CHART_OPTIONS,
      onDataLoadedFunc,
      this.scopeObjectType
    )
  }

  protected abstract getObservationsByTypeDataObjectType(): DashboardDataObjectType

  loadObservationsByTypeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      this.getObservationsByTypeDataObjectType(),
      OBSERVATIONS_BY_TYPE_CHART_COLUMNS,
      OBSERVATIONS_BY_CHART_OPTIONS,
      onDataLoadedFunc,
      this.scopeObjectType
    )
  }

  protected abstract getObservationsGreatCatchByTypeDataObjectType(): DashboardDataObjectType

  loadObservationsGreatCatchByTypeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      this.getObservationsGreatCatchByTypeDataObjectType(),
      OBSERVATIONS_GREAT_CATCH_BY_TYPE_CHART_COLUMNS,
      OBSERVATIONS_GREAT_CATCH_BY_TYPE_CHART_OPTIONS,
      onDataLoadedFunc,
      this.scopeObjectType
    )
  }

  protected abstract getObservationsByIdentificationMethodDataObjectType(): DashboardDataObjectType

  loadObservationsByIdentificationMethodData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      this.getObservationsByIdentificationMethodDataObjectType(),
      OBSERVATIONS_BY_IDENTIFICATION_METHOD_CHART_COLUMNS,
      OBSERVATIONS_BY_IDENTIFICATION_METHOD_CHART_OPTIONS,
      onDataLoadedFunc,
      this.scopeObjectType
    )
  }

  protected abstract getObservationsByCreatorMethodDataObjectType(): DashboardDataObjectType

  loadObservationsByCreatorMethodData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      this.getObservationsByCreatorMethodDataObjectType(),
      OBSERVATIONS_BY_CREATOR_CHART_COLUMNS,
      OBSERVATIONS_BY_CREATOR_CHART_OPTIONS,
      onDataLoadedFunc,
      this.scopeObjectType
    )
  }

  protected abstract getObservationsOverTimeByStatusDataObjectType(): DashboardDataObjectType

  loadObservationsOverTimeByStatusData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleDateBasedChartData(
      dashboardParameters,
      this.getObservationsOverTimeByStatusDataObjectType(),
      OBSERVATIONS_OVERTIME_BY_STATUS_CHART_COLUMNS,
      true,
      OBSERVATIONS_OVERTIME_BY_TYPE_CHART_OPTIONS,
      onDataLoadedFunc,
      this.scopeObjectType
    )
  }

  protected abstract getObservationCountByOpenVsClosedDataObjectType(): DashboardDataObjectType

  loadObservationCountByOpenVsClosedData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      this.getObservationCountByOpenVsClosedDataObjectType(),
      OBSERVATIONS_COUNT_BY_OPEN_VS_CLOSED_CHART_COLUMNS,
      OBSERVATIONS_COUNT_BY_OPEN_VS_CLOSED_CHART_OPTIONS,
      onDataLoadedFunc,
      this.scopeObjectType
    )
  }

  protected abstract getObservationAvgDaysOpenByRiskBandDataObjectType(): DashboardDataObjectType

  loadObservationAvgDaysOpenByRiskBandData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      this.getObservationAvgDaysOpenByRiskBandDataObjectType(),
      OBSERVATIONS_AVG_DAYS_OPEN_BY_RISK_BAND_CHART_COLUMNS,
      OBSERVATIONS_AVG_DAYS_OPEN_BY_RISK_BAND_CHART_OPTIONS,
      data => {
        applyRiskBandColors(data)
        onDataLoadedFunc(data)
      },
      this.scopeObjectType
    )
  }

  protected abstract getObservationOpenByStatusDataObjectType(): DashboardDataObjectType

  loadObservationOpenByStatusData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      this.getObservationOpenByStatusDataObjectType(),
      OBSERVATIONS_OPEN_BY_STATUS_CHART_COLUMNS,
      OBSERVATIONS_OPEN_BY_STATUS_CHART_OPTIONS,
      onDataLoadedFunc,
      this.scopeObjectType
    )
  }

  protected abstract getActivityDataObjectType(): DashboardDataObjectType

  loadActivityData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DashboardOrganizationObservationActivity) => void
  ) {
    let url = this.insightsDashboardServiceHelper.getApiUrl(dashboardParameters.organizationId)
    let payload = InsightsDashboardServiceHelper.createDashboardRequest(
      this.getActivityDataObjectType(),
      dashboardParameters,
      this.scopeObjectType
    )
    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardDataResponse<DashboardOrganizationObservationActivity>>(
      this.$http.post(url, payload),
      DashboardDataResponse
    )
      .pipe(first())
      .pipe(
        map((data: DashboardDataResponse<DashboardOrganizationObservationActivity>) => {
          let tableData = data.dashboardData[this.getActivityDataObjectType()].activityData
          tableData.forEach(entry => {
            entry.chartData.dataTable.forEach(row => {
              row[0] = moment(new Date(row[0]))
                .set('hour', 0)
                .set('minute', 0)
                .set('second', 0)
                .set('millisecond', 0)
                .toDate()
              row.push(
                makeMultilineChartTooltip([
                  moment(row[0]).format('MMM D, YYYY'),
                  this.translate.instant('dashboard.projectObservations.activityTable.chartTooltip', { count: row[1] }),
                ])
              )
            })
            entry.chartData.dataTable = [
              [...this.translateStringsIn(PROJECT_TOTALS_CHART_DATA_COLUMNS)],
              ...entry.chartData.dataTable,
            ]
            entry.chartData.options = { ..._.cloneDeep(PROJECT_TOTALS_CHART_OPTIONS) }
          })
          return data.dashboardData[this.getActivityDataObjectType()]
        })
      )
      .subscribe(data => {
        onDataLoadedFunc(data)
      })
    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  protected abstract getSafetyDataObjectType(): DashboardDataObjectType

  loadSafetyData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DashboardOrganizationObservationSafety) => void
  ) {
    let url = this.insightsDashboardServiceHelper.getApiUrl(dashboardParameters.organizationId)
    let payload = InsightsDashboardServiceHelper.createDashboardRequest(
      this.getSafetyDataObjectType(),
      dashboardParameters,
      this.scopeObjectType
    )

    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardDataResponse<DashboardOrganizationObservationSafety>>(
      this.$http.post(url, payload),
      DashboardDataResponse
    )
      .pipe(first())
      .pipe(
        map((data: DashboardDataResponse<DashboardOrganizationObservationSafety>) => {
          return data.dashboardData[this.getSafetyDataObjectType()]
        })
      )
      .subscribe(data => {
        onDataLoadedFunc(data)
      })
    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  protected abstract getWorkflowDataObjectType(): DashboardDataObjectType

  loadWorkflowData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DashboardOrganizationObservationWorkflow) => void
  ) {
    let url = this.insightsDashboardServiceHelper.getApiUrl(dashboardParameters.organizationId)
    let payload = InsightsDashboardServiceHelper.createDashboardRequest(
      this.getWorkflowDataObjectType(),
      dashboardParameters,
      this.scopeObjectType
    )

    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardDataResponse<DashboardOrganizationObservationWorkflow>>(
      this.$http.post(url, payload),
      DashboardDataResponse
    )
      .pipe(first())
      .pipe(
        map((data: DashboardDataResponse<DashboardOrganizationObservationWorkflow>) => {
          return data.dashboardData[this.getWorkflowDataObjectType()]
        })
      )
      .subscribe(data => {
        onDataLoadedFunc(data)
      })
    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  protected translateStringsIn(data: any[]): any[] {
    return _.map(data, k => {
      return typeof k === 'string' && k.startsWith('dashboard.') ? this.translate.instant(k) : k
    })
  }
}
