/* global angular */

angular.module('smartvid').directive('tagInstance', () => {
  return {
    templateUrl: 'tag-instance.html',
    scope: {
      tag: '='
    },
    link (scope) {
    }
  }
})
