/* global angular _ */

angular.module('smartvid').factory('EgnyteSyncSettingsModel', function (BaseModel) {
  class EgnyteSyncSettingsModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        egnyteUserId: undefined,
        egnyteFolderId: undefined,
        egnyteFolderPath: undefined,
        historicalDataSyncDateRange: undefined,
        historicalDataSyncThresholdDate: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return EgnyteSyncSettingsModel
})

