/* global angular _ */

angular.module('smartvid').factory('UserOrganizationNotificationSettings', function (BaseModel) {
  class UserOrganizationNotificationSettings extends BaseModel {
    constructor (attrs) {
      let defaults = {
        organizationId: undefined,
        isProjectCreationNotification: undefined,
        showInactiveProjects: undefined,
        pushNotificationEventsSettings: []
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return UserOrganizationNotificationSettings
})
