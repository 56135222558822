/* global angular analytics */
angular.module('smartvid').service('stateIdentificationService', function ($state) {
  this.isProjectGroupState = isProjectGroupState
  this.isOrganizationState = isOrganizationState
  const PROJECT_GROUP_STATE = 'dashboard.projects.orgId.projectGroupId'
  const ORGANIZATION_STATE = 'dashboard.projects.orgId'

  function isProjectGroupState () {
    return $state.current.name === PROJECT_GROUP_STATE
  }

  function isOrganizationState () {
    return $state.current.name === ORGANIZATION_STATE
  }

})
