import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { take, takeUntil } from 'rxjs/operators'
import { Component, Injectable, Input, OnDestroy } from '@angular/core'
import { FlatTreeControl } from '@angular/cdk/tree'
import { SelectionModel } from '@angular/cdk/collections'
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree'
import { ForgeFolder, ForgeService } from 'modules/integrations/services/forge.service'
import {
  ForgeCheckboxValueType,
  ForgeComponentChangeService,
} from 'modules/integrations/services/forge-component-change.service'
import { TranslateService } from '@ngx-translate/core'

/** Nested node */
class FolderNode {
  childrenChange = new BehaviorSubject<FolderNode[]>([])

  get children(): FolderNode[] {
    return this.childrenChange.value
  }

  constructor(public name: string, public folderId: string, public expandable) {}
}

/** Flat node with expandable and level information */
class FolderFlatNode {
  constructor(
    public name: string,
    public folderId: string,
    public level = 1,
    public expandable = false,
    public isLoading = false
  ) {}
}

const FORGE_ROOT_FOLDER_ID = '@@FORGE_FOLDER_ROOT_NODE_ID@@'
const PLANS = 'Plans'

@Injectable()
export class ForgeFoldersDatabase {
  dataChange = new BehaviorSubject<FolderNode[]>([])

  private _nodeMap = new Map<string, FolderNode>()

  constructor(private _forgeService: ForgeService, private translate: TranslateService) {}

  initialize() {
    this._nodeMap.clear()
    const rootNode = this.createRootNode()
    this._nodeMap.set(rootNode.folderId, rootNode)
    this.dataChange.next([rootNode])
  }

  createRootNode() {
    return new FolderNode(this.translate.instant('integrations.forge.allFolders'), FORGE_ROOT_FOLDER_ID, true)
  }

  /** Expand a node whose children are not loaded */
  loadSubFolders(projectId: string, node: FolderFlatNode): void {
    if (!this._nodeMap.has(node.folderId)) {
      return
    }
    const parent = this._nodeMap.get(node.folderId)
    if (parent && parent.children.length > 0) {
      return
    }
    this._loadSubFolders(projectId, node)
  }

  private _loadRootFolders(projectId: string, node: FolderFlatNode): void {
    node.isLoading = true
    this._forgeService
      .getRootFolders(projectId)
      .pipe(take(1))
      .subscribe(resp => {
        const parent = this._nodeMap.get(node.folderId)
        const nodes = resp.filter(f => f.name !== PLANS).map(f => this._fromFolderToNode(f))
        parent.childrenChange.next(nodes)
        this.dataChange.next(this.dataChange.value)
        node.isLoading = false
      })
  }

  private _loadSubFolders(projectId: string, node: FolderFlatNode): void {
    if (node.folderId === FORGE_ROOT_FOLDER_ID) {
      return this._loadRootFolders(projectId, node)
    }
    node.isLoading = true
    this._forgeService
      .getSubFolders(projectId, node.folderId)
      .pipe(take(1))
      .subscribe(resp => {
        const parent = this._nodeMap.get(node.folderId)
        const nodes = resp.map(f => this._fromFolderToNode(f))
        parent.childrenChange.next(nodes)
        this.dataChange.next(this.dataChange.value)
        node.isLoading = false
      })
  }

  private _fromFolderToNode(folder: ForgeFolder): FolderNode {
    if (this._nodeMap.has(folder.id)) {
      return this._nodeMap.get(folder.id)
    }
    const result = new FolderNode(folder.name, folder.id, folder.expandable)
    this._nodeMap.set(folder.id, result)
    return result
  }
}

/**
 * @title Forge folder tree with checkboxes
 */
@Component({
  selector: 'sv-forge-folder-tree',
  templateUrl: 'forge-folder-tree.component.html',
  styleUrls: ['forge-folder-tree.component.css'],
  providers: [ForgeFoldersDatabase],
})
export class ForgeFolderTreeComponent implements OnDestroy {
  @Input() projectId: string
  @Input() readOnly: boolean

  treeControl: FlatTreeControl<FolderFlatNode>
  treeFlattener: MatTreeFlattener<FolderNode, FolderFlatNode>
  dataSource: MatTreeFlatDataSource<FolderNode, FolderFlatNode>
  checkboxSelection: SelectionModel<FolderFlatNode>
  partiallySelection: SelectionModel<FolderFlatNode>

  private $destroy = new Subject<void>()
  private _nodeMap = new Map<string, FolderFlatNode>()
  private initialFolderPaths: string[] = []
  private expandable = false

  constructor(private _database: ForgeFoldersDatabase, private _folderTreeChange: ForgeComponentChangeService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren)
    this.treeControl = new FlatTreeControl<FolderFlatNode>(this.getLevel, this.isExpandable)
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener)
    this.checkboxSelection = new SelectionModel<FolderFlatNode>(true)
    this.partiallySelection = new SelectionModel<FolderFlatNode>(true)

    _database.dataChange.pipe(takeUntil(this.$destroy)).subscribe(data => {
      this.dataSource.data = data
      if (this.initialFolderPaths.length > 0) {
        this.initialFolderPaths.forEach(folderPath => {
          this.autoSelectChildren(data, 0, this._splitPath(folderPath))
        })
      } else {
        this.autoSelectChildren(data, 0, [])
      }
    })
    this._folderTreeChange.forgeProjectChange.pipe(takeUntil(this.$destroy)).subscribe(changeEvent => {
      this._nodeMap.clear()
      _database.initialize()
      this.initialFolderPaths = this._prependRootNodeId(changeEvent.documentSelectedFolderIdPaths)
      this.checkboxSelection.clear()
      this.partiallySelection.clear()
      this.expandable = changeEvent.forgeProjectId !== null && changeEvent.forgeProjectId !== undefined
      const rootFlatNode = this._nodeMap.get(FORGE_ROOT_FOLDER_ID)
      switch (changeEvent.documentFolderSelectedType) {
        case ForgeCheckboxValueType.PARTIALLY_SELECTED:
          this.checkboxSelection.deselect(rootFlatNode)
          this.partiallySelection.select(rootFlatNode)
          break
        case ForgeCheckboxValueType.NONE_SELECTED:
          this.checkboxSelection.deselect(rootFlatNode)
          this.partiallySelection.deselect(rootFlatNode)
          break
        default:
          this.checkboxSelection.select(rootFlatNode)
          this.partiallySelection.deselect(rootFlatNode)
          break
      }
    })
    _database.initialize()
    const rootFlatNode = this._nodeMap.get(FORGE_ROOT_FOLDER_ID)
    this.checkboxSelection.select(rootFlatNode)
    this.partiallySelection.deselect(rootFlatNode)
  }

  ngOnDestroy(): void {
    this.$destroy.next()
    this.$destroy.complete()
  }

  private _splitPath(path: string): string[] {
    return path.split('/').filter(s => s.length > 0)
  }

  private _prependRootNodeId(paths: string[]): string[] {
    return paths.map(p => `/${FORGE_ROOT_FOLDER_ID}` + p)
  }

  autoSelectChildren(nodes: FolderNode[], level: number, folderIds: string[]) {
    nodes.forEach(node => {
      const flatNode = this._nodeMap.get(node.folderId)
      if (this.checkboxSelection.isSelected(flatNode)) {
        const descendants = this.treeControl.getDescendants(flatNode)
        if (descendants && descendants.length > 0) {
          this.checkboxSelection.select(...descendants)
          this.partiallySelection.deselect(...descendants)
        }
      } else {
        if (folderIds.length - 1 > level && folderIds[level] === node.folderId) {
          this.partiallySelection.select(flatNode)
        }
        if (folderIds.length - 1 === level && folderIds[level] === node.folderId) {
          this.checkboxSelection.select(flatNode)
          this.partiallySelection.deselect(flatNode)
        }
      }
      this.autoSelectChildren(node.children, level + 1, folderIds)
    })
  }

  getChildren = (node: FolderNode): Observable<FolderNode[]> => node.childrenChange

  transformer = (node: FolderNode, level: number) => {
    const existingNode = this._nodeMap.get(node.folderId)
    if (existingNode) {
      return existingNode
    }
    const newNode = new FolderFlatNode(node.name, node.folderId, level, node.expandable)
    this._nodeMap.set(node.folderId, newNode)
    return newNode
  }

  getLevel = (node: FolderFlatNode) => node.level

  isExpandable = (node: FolderFlatNode) => node.expandable

  hasChild = (_: number, node: FolderFlatNode) => node.expandable

  emitSelectionChange(): void {
    let rootNode = this._database.dataChange.getValue()[0]
    let folderIds = new Set<string>(this.checkboxSelection.selected.map(n => n.folderId))
    let selectedPaths: string[] = []

    this._getSelectedNodes(rootNode, '', selectedPaths, folderIds)

    let selectedType = ForgeCheckboxValueType.NONE_SELECTED
    if (selectedPaths.length === 1 && selectedPaths[0] === `/${FORGE_ROOT_FOLDER_ID}`) {
      selectedType = ForgeCheckboxValueType.ALL_SELECTED
    } else if (selectedPaths.length > 0) {
      selectedType = ForgeCheckboxValueType.PARTIALLY_SELECTED
    }
    selectedPaths = selectedPaths.map(p => p.replace(`/${FORGE_ROOT_FOLDER_ID}`, '')).filter(s => s.length > 0)

    this._folderTreeChange.folderSelectedChange.emit({
      documentFolderSelectedType: selectedType,
      documentSelectedFolderIdPaths: selectedPaths,
    })
  }

  private _getSelectedNodes(
    currentNode: FolderNode,
    parentPath: string,
    selectedPaths: string[],
    allSelectedIds: Set<string>
  ): void {
    let currentNodePath = parentPath + '/' + currentNode.folderId
    if (allSelectedIds.has(currentNode.folderId)) {
      selectedPaths.push(currentNodePath)
    } else {
      let childNodes = currentNode.children
      if (childNodes && childNodes.length > 0) {
        childNodes.forEach(node => {
          this._getSelectedNodes(node, currentNodePath, selectedPaths, allSelectedIds)
        })
      }
    }
  }

  toggleExpand(node: FolderFlatNode) {
    this._database.loadSubFolders(this.projectId, node)
  }

  toggleCheckbox(folderNode: FolderFlatNode): void {
    this.checkboxSelection.toggle(folderNode)
    const descendants = this.treeControl.getDescendants(folderNode)
    if (this.checkboxSelection.isSelected(folderNode)) {
      this.checkboxSelection.select(...descendants)
    } else {
      this.checkboxSelection.deselect(...descendants)
    }
    this.partiallySelection.deselect(folderNode)
    this.partiallySelection.deselect(...descendants)
    this.initialFolderPaths = []
    // Force update for the parent
    descendants.forEach(child => this.checkboxSelection.isSelected(child))
    this.checkAllParentsSelection(folderNode)
    this.emitSelectionChange()
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FolderFlatNode): void {
    let parent: FolderFlatNode | null = this.getParentNode(node)
    while (parent) {
      this.checkParentNodeSelection(parent)
      parent = this.getParentNode(parent)
    }
  }

  /** Check root node checked state and change it accordingly */
  checkParentNodeSelection(node: FolderFlatNode): void {
    const descendants = this.treeControl.getDescendants(node)
    const allSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checkboxSelection.isSelected(child)
      })
    const someSelected =
      descendants.length > 0 &&
      descendants.some(child => {
        return this.checkboxSelection.isSelected(child) // || this.partiallySelection.isSelected(child)
      })
    if (allSelected) {
      this.partiallySelection.select(node)
    } else {
      if (someSelected) {
        this.checkboxSelection.deselect(node)
        this.partiallySelection.select(node)
      } else {
        this.checkboxSelection.deselect(node)
        this.partiallySelection.deselect(node)
      }
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: FolderFlatNode): FolderFlatNode | null {
    const currentLevel = this.getLevel(node)
    if (currentLevel < 1) {
      return null
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i]
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode
      }
    }
    return null
  }
}
