/* global angular */

angular.module('smartvid').service('structionSiteIntegrationApi', function ($http, $q, $log, config, currentUser) {
  let rootUrl = config.env.development.apiRootUrl

  this.loginToStructionSiteIntegration = loginToStructionSiteIntegration
  this.listStructionSiteProjectInfo = listStructionSiteProjectInfo
  this.enableStructionSiteIntegration = enableStructionSiteIntegration

  function listStructionSiteProjectInfo (projectId, customErrorHandler) {
    let url = `${rootUrl}/api/integration/structionsite/project/${projectId}/project`
    let defer = $q.defer()

    $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
      $log.debug('listStructionSiteProjectsInfo', response.data)
      defer.resolve(response.data)
    }, function (response) {
      defer.reject(response.data)
    })

    return defer.promise
  }

  function loginToStructionSiteIntegration (userLogin, userPassword, customErrorHandler) {
    let url = `${rootUrl}/api/integration/structionsite/login`
    let defer = $q.defer()

    let payload = {userId: currentUser.id, structionSiteUserName: userLogin, structionSiteUserPassword: userPassword}

    $http.post(url, payload, {customErrorHandler: customErrorHandler}).then((response) => {
      $log.debug('loginInToStructionSite', response.data)
      defer.resolve(response.data)
    }, function (response) {
      defer.reject(response.data)
    })

    return defer.promise
  }

  function enableStructionSiteIntegration (projectId, customErrorHandler) {
    let url = `${rootUrl}/api/integration/structionsite/project/${projectId}/enable`
    let defer = $q.defer()

    $http.post(url, {}, {customErrorHandler: customErrorHandler}).then((response) => {
      $log.debug('enableStructionSiteIntegration', response.data)
      defer.resolve(response.data)
    }, function (response) {
      defer.reject(response.data)
    })

    return defer.promise
  }
})
