/* global angular */

angular.module('smartvid').factory('ProcoreObservationIssueDistributionMembersCollection', function (BaseCollection, ProcoreObservationIssueDistributionMemberModel) {
  class ProcoreObservationIssueDistributionMembersCollection extends BaseCollection {
    constructor (models) {
      super(models, ProcoreObservationIssueDistributionMemberModel)
    }
  }

  return ProcoreObservationIssueDistributionMembersCollection
})
