/* global angular analytics _ */
angular.module('smartvid').service('infopanelCommentsTabService', function ($timeout, utils, smartvidApi, currentUser,
                                                                            CommentModel) {
  const ENTER_KEY_CODE = 13

  let infopanelCommentsTabService = {
    checkForArrowKeyForComment (evt) {
      if (utils.isArrowKey(evt)) {
        evt.stopPropagation()
      }
    },

    stopEventIfInEditModeForComment (event, comment) {
      if (comment.editMode) {
        event.stopPropagation()
      }
    },

    isCommentEditable (comment) {
      return comment.isEditable
    },

    showCommentOptions ($event, scope, asset, project, commentsCollection, comment) {
      scope.flyout.open('commentOptions', {
        parentElement: $event.currentTarget,
        direction: 'right',
        asset: asset,
        comments: commentsCollection,
        comment: comment,
        currentProject: project
      })
    },

    showMoreComment ($event, comment) {
      comment.showMoreActive = !comment.showMoreActive
      $event.stopPropagation()
      $event.preventDefault()
      return false
    },

    addComment (asset, newComment, commentsCollection, newCommentResetFunc, videoPlayerHandler,
                broadcastAllTagsAndGroupsFunc, broadcastCommentAddedFunc, commentTimeSourceFunc) {
      // If we are already in edit mode do not add another one
      if (newComment.editMode) {
        return
      }

      if (videoPlayerHandler) {
        videoPlayerHandler()
      }

      commentsCollection.unselectAllAndPersistChanges(asset.id)
      if (broadcastAllTagsAndGroupsFunc) {
        broadcastAllTagsAndGroupsFunc()
      }

      newComment.editMode = true
      newComment.selected = true
      newComment.startTime = commentTimeSourceFunc ? commentTimeSourceFunc() : 0
      newComment.local = true
      commentsCollection.add(newComment, CommentModel)
      asset.commentsCount++

      // We create a new object when we add it to the collection, so we need to get that object
      // And use that to bind to the DOM.
      newCommentResetFunc(commentsCollection.getLocal())
    },

    handleCommentClick (asset, commentsCollection, comment, isAssetReadOnly, forceOverflowFunc,
                        broadcastAllTagsAndGroupsFunc, mediaPlayerCallbackFnc, broadcastCommentSelectedFunc) {
      // limit share view
      if (broadcastAllTagsAndGroupsFunc) {
        broadcastAllTagsAndGroupsFunc()
      }

      $timeout(forceOverflowFunc(), 350)

      if (!comment.selected) {
        if (!isAssetReadOnly) {
          commentsCollection.unselectAllAndPersistChanges(asset.id)
        } else {
          commentsCollection.unselectAll()
        }

        if (mediaPlayerCallbackFnc) {
          mediaPlayerCallbackFnc(comment)
        }
        return
      }

      // If this user cannot edit this comment and they have already selected it, then simply return
      if (comment.selected && !this.isCommentEditable(comment)) {
        commentsCollection.unselectAll()
        return
      }

      if (comment.selected && !comment.editMode && !isAssetReadOnly) {
        comment.editMode = true
        if (broadcastCommentSelectedFunc) {
          broadcastCommentSelectedFunc()
        }
        return
      }

      if (comment.editMode) {
        if (!isAssetReadOnly) {
          commentsCollection.unselectAllAndPersistChanges(asset.id)
        }
        comment.selected = true
        if (broadcastCommentSelectedFunc) {
          broadcastCommentSelectedFunc()
        }
        return
      }
    },

    saveComment (asset, project, commentsCollection, newComment, commentClickFunction, resetNewCommentFunc) {
      if (!newComment.description) {
        return
      }
      analytics.track('Add Comment', {
        category: 'Comment Action',
        isMarkup: newComment.shape !== undefined,
        assetName: asset.name,
        projectName: project ? project.name : asset.projectId,
        orgName: (project && project.getProjectOrg()) ? project.getProjectOrg().name : undefined
      })
      smartvidApi.createCommentForAsset(asset.id, newComment).then(function (comment) {
        comment.creator = {
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.email
        }

        commentsCollection.add(comment, CommentModel)
        commentsCollection.removeById(newComment.id)

        if (resetNewCommentFunc) {
          resetNewCommentFunc()
        }

        let cc = _.find(commentsCollection.models, (c1) => {
          return comment.id === c1.id
        })
        if (cc && commentClickFunction) {
          commentClickFunction(cc)
        }
      })
    },

    checkForReturnKeyForComment (evt, asset, project, comment, editMode, saveCommentFunc) {
      if (evt.keyCode === ENTER_KEY_CODE && !evt.shiftKey) {
        if (comment && editMode) {
          analytics.track('Edit Comment', {
            category: 'Comment Action',
            assetName: asset.name,
            projectName: project ? project.name : asset.projectId,
            orgName: (project && project.getProjectOrg()) ? project.getProjectOrg().name : undefined
          })
          smartvidApi.updateCommentForAsset(asset.id, comment).then(() => {
            comment.editMode = false
          })
        } else if (saveCommentFunc) {
          saveCommentFunc()
        }
        evt.currentTarget.setAttribute('style', '');
        evt.currentTarget.blur()
      }
    }
  }

  return infopanelCommentsTabService
})
