/* global angular */
/**
 * Created by ryanrubbico on 1/3/18.
 */
angular.module('smartvid').factory('StorageServiceFolderCollection', function (BaseCollection, StorageServiceFolderModel) {
  class StorageServiceFolderCollection extends BaseCollection {
    constructor (models) {
      super(models, StorageServiceFolderModel)
    }
  }
  return StorageServiceFolderCollection
})
