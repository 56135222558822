/* global angular */
angular.module('smartvid').service('tagTreeStateService', function () {
  let stateMap = {}
  let service = {
    saveState: function (key, state) {
      stateMap[key] = state
    },
    getState: function (key) {
      return stateMap[key]
    },
    reset: function () {
      stateMap = {}
    }
  }

  return service
})
