<div class="mdc-layout-grid__inner">
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'ORGANIZATION_ASSETS_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgAvgPhotoCreatedByDayOfWeek.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-column-chart
        [chartData]="organizationAvgPhotosCreatedByDayOfWeekData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'ORGANIZATION_ASSETS_AVG_PHOTOS_CREATED_PER_DAY' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.orgAvgPhotoCreatedPerDay.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="organizationSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(organizationSingleValueData.avgPhotosCreatedPerDay)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.avgPhotosCreatedPerDay | number: '1.0-0')
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ avgPhotosCreatedPerDayPercentChangeClassName }}">
          {{ getSign(organizationSingleValueData.avgPhotosCreatedPerDayPercentChange)
          }}{{
            isNA(organizationSingleValueData.avgPhotosCreatedPerDay)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.avgPhotosCreatedPerDayPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div
    *svDashboardGridCell="{
      tabId: 'IMAGE_HEALTH',
      cardId: 'ORGANIZATION_ASSETS_AVG_TIME_BETWEEN_PHOTO_CREATION_AND_UPLOAD'
    }"
  >
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.orgAvgTimeDiffPhotoCreatedUploaded.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="organizationSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(organizationSingleValueData.avgPhotoDiffCreatedUploaded)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.avgPhotoDiffCreatedUploaded | number: '1.0-0')
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ avgPhotoDiffCreatedUploadedPercentChangeClassName }}">
          {{ getSign(organizationSingleValueData.avgPhotoDiffCreatedUploadedPercentChange)
          }}{{
            isNA(organizationSingleValueData.avgPhotoDiffCreatedUploaded)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.avgPhotoDiffCreatedUploadedPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'ORGANIZATION_ASSETS_CONSTRUCTION_TO_ALL_RATIO_AVG' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.orgConstructionToAllPhotosRatio.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="organizationSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(organizationSingleValueData.constructionPhotosToAllPhotosRatioAvg)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.constructionPhotosToAllPhotosRatioAvg | number: '.1-1') + ' to 1'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ constructionPhotosToAllPhotosRatioPercentChangeClassName }}">
          {{ getSign(organizationSingleValueData.constructionPhotosToAllPhotosRatioAvgPercentChange)
          }}{{
            isNA(organizationSingleValueData.constructionPhotosToAllPhotosRatioAvg)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.constructionPhotosToAllPhotosRatioAvgPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'ORGANIZATION_ASSETS_PERSON_COVERAGE_PERCENT' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.orgPersonCoveragePercent.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="organizationSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(organizationSingleValueData.percentPersonCoverage)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.percentPersonCoverage | number: '1.0-0') + '%'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ percentPersonCoveragePercentChangeClassName }}">
          {{ getSign(organizationSingleValueData.percentPersonCoveragePercentChange)
          }}{{
            isNA(organizationSingleValueData.percentPersonCoverage)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.percentPersonCoveragePercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'ORGANIZATION_ASSETS_PHOTOS_WITHOUT_CREATED_DATE' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.orgPhotosWithoutCreatedDate.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="organizationSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(organizationSingleValueData.percentOfAssetsWithoutCreatedDate)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.percentOfAssetsWithoutCreatedDate | number: '1.0-0') + '%'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ percentOfAssetsWithoutCreatedDatePercentChangeClassName }}">
          {{ getSign(organizationSingleValueData.percentOfAssetsWithoutCreatedDatePercentChange)
          }}{{
            isNA(organizationSingleValueData.percentOfAssetsWithoutCreatedDate)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.percentOfAssetsWithoutCreatedDatePercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div
    *svDashboardGridCell="{
      tabId: 'IMAGE_HEALTH',
      cardId: 'ORGANIZATION_ASSETS_CONSTRUCTION_PHOTOS_WITHOUT_CREATED_DATE'
    }"
  >
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.orgConstructionPhotosWithoutCreatedDate.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="organizationSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(organizationSingleValueData.percentOfConstructionAssetsWithoutCreatedDate)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.percentOfConstructionAssetsWithoutCreatedDate | number: '1.0-0') + '%'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ percentOfConstructionAssetsWithoutCreatedDatePercentChangeClassName }}">
          {{ getSign(organizationSingleValueData.percentOfConstructionAssetsWithoutCreatedDatePercentChange)
          }}{{
            isNA(organizationSingleValueData.percentOfConstructionAssetsWithoutCreatedDate)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.percentOfConstructionAssetsWithoutCreatedDatePercentChange
                  | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
</div>
