/* global angular */

angular.module('smartvid').service('redirectorService', function () {
  const BEFORE_LOGIN_USER_EMAIL = {}

  return {
    toState: undefined,
    toParams: undefined,
    userEmail: BEFORE_LOGIN_USER_EMAIL,

    clear: function () {
      this.toParams = undefined
      this.toState = undefined
      if (this.userEmail !== BEFORE_LOGIN_USER_EMAIL && this.userEmail !== undefined) {
        this.userEmail = undefined
      } else {
        this.userEmail = BEFORE_LOGIN_USER_EMAIL
      }
    },

    /**
     * This state is set when user tries to access a route within application prior to logging in.
     */
    setAccessRouteBeforeLoginState: function () {
      this.userEmail = BEFORE_LOGIN_USER_EMAIL
    },

    shouldRedirectForUser: function (email) {
      // Return true if it's the same user or user hasn't logged in prior to accessing the route yet
      return this.userEmail === email || this.userEmail === BEFORE_LOGIN_USER_EMAIL
    }

  }
})
