import { Component, Injector, Inject, OnInit, Input } from '@angular/core'
import { CurrentUser, CURRENT_USER } from 'shared/smartvid.types'
import { DashboardDateRangeTimeUnit, DashboardType } from '../../dashboard/models/insights-dashboard.model'
import { InsightsDataHelper } from '../../services/insights-data-helper.service'
import { NGXLogger } from 'ngx-logger'

@Component({
  selector: 'sv-insights-control-bar',
  templateUrl: 'insights-controlbar.component.html',
  styleUrls: ['insights-controlbar.component.scss'],
})
export class InsightsControlBarComponent implements OnInit {
  NavController: any
  supportedRanges: DashboardDateRangeTimeUnit[]
  dateRange: DashboardDateRangeTimeUnit

  @Input() dashboardType: DashboardType
  @Input() includeMonthToDate = false

  private ignoreCache = false

  constructor(
    private insightsDataHelper: InsightsDataHelper,
    private injector: Injector,
    @Inject(CURRENT_USER) private currentUser: CurrentUser,
    private logger: NGXLogger
  ) {
    this.NavController = this.injector.get('NavController')
    this.supportedRanges = [
      DashboardDateRangeTimeUnit.LAST_1,
      DashboardDateRangeTimeUnit.LAST_7,
      DashboardDateRangeTimeUnit.LAST_30,
      DashboardDateRangeTimeUnit.LAST_60,
      DashboardDateRangeTimeUnit.LAST_90,
    ]
  }

  ngOnInit() {
    if (this.includeMonthToDate) {
      this.supportedRanges.push(DashboardDateRangeTimeUnit.MONTH_TO_DATE)
    }
    this.dateRange = this.insightsDataHelper.getCurrentInsightsDashboardParameters(this.dashboardType).dateRangeTimeUnit
  }

  updateDateRange() {
    this.logger.info('DASHBOARD CACHE IS: ', this.ignoreCache ? 'OFF' : 'ON')
    setTimeout(() => this.insightsDataHelper.setDateRangeTimeUnit(this.dashboardType, this.dateRange), 100)
  }

  toggleIgnoreCache(event: MouseEvent) {
    if (event.altKey && event.shiftKey && event.metaKey) {
      this.ignoreCache = !this.ignoreCache
      this.logger.info('DASHBOARD CACHE IS: ', this.ignoreCache ? 'OFF' : 'ON')
      setTimeout(() => this.insightsDataHelper.setIgnoreCache(this.dashboardType, this.ignoreCache), 100)
    }
  }
}
