/* global angular, _, $ */

angular.module('smartvid').directive('tagAlias', (MAX_TAG_DEF_ALIAS_TEXT_LENGTH, $rootScope, $timeout, $filter) => {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: 'tag-alias.html',
    scope: {
      tags: '=',
      editTag: '=',
      addedAliases: '=',
      removedAliases: '='
    },
    link (scope, el) {
      scope.MAX_TAG_DEF_ALIAS_TEXT_LENGTH = MAX_TAG_DEF_ALIAS_TEXT_LENGTH

      scope.addedAliases = scope.addedAliases || []
      scope.removedAliases = scope.removedAliases || []

      scope.removeAlias = (alias) => {
        let rem = _.remove(scope.addedAliases, function (a) {
          return a === alias
        })
        rem.length && scope.removedAliases.push(rem[0])
      }

      scope.$watch('newAlias', function () {
        if (!scope.newAlias) {
          scope.errorMessage = ''
        }
      })

      /**
       * add tag alias
       */
      scope.addTagAlias = () => {
        scope.errorMessage = ''
        document.getElementsByName('tag-alias')[0].focus()

        if (!scope.newAlias) {
          return
        }

        //
        // make sure scope.newAlias is missing from current projectTags and current addedAliases
        //
        if (_.some(scope.addedAliases, function (a) { return a === scope.newAlias }) ||
              _.some(scope.tags.models, function (a) { return a && (a.text === scope.newAlias) })) {
          scope.errorMessage = $filter('i18next')('tags.modal.alias.error-dup')
          return
        } else {
          // if newAlias in removeAlias remove it!
          _.remove(scope.removedAliases, function (a) {
            return a === scope.newAlias
          })

          scope.flash = true
          scope.addedAliases.push(scope.newAlias)
          scope.newAlias = ''

          $timeout(() => {
            $('.chosen-tags').scrollTop($('.chosen-tags').innerHeight())
          })

          $timeout(() => {
            scope.flash = false
          }, 100)
        }
      }
    }
  }
})
