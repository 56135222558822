/* global angular, _ */

import {ModuleType} from 'shared/models/module-type'

angular.module('smartvid').factory('UserModel', function (BaseModel, $filter, currentUser) {
  class UserModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        role: undefined,
        observationRole: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }

    getName () {
      return (this.firstName || '') + ' ' + (this.lastName || '')
    }

    getRolei18nLabel () {
      let groupRoleName = this.getFirstGroupRoleName(ModuleType.ASSETS)
      if (groupRoleName) {
        return currentUser.getAssetGroupRoleByName(groupRoleName).displayName
      }
      if (!this.role) {
        return $filter('i18next')('user.observationRoleLabels.NON_MEMBER')
      }
      return currentUser.getAssetOrgRoleByName(this.role).displayName
    }

    getObservationRolei18nLabel () {
      if (this.observationRole !== 'ORGANIZATION_SAFETY_ADMIN') {
        let groupRoleName = this.getFirstGroupRoleName(ModuleType.OBSERVATIONS)
        if (groupRoleName) {
          return currentUser.getObservationGroupRoleByName(groupRoleName).displayName
        }
      }
      if (!this.observationRole) {
        return $filter('i18next')('user.observationRoleLabels.NON_MEMBER')
      }
      return currentUser.getObservationOrgRoleByName(this.observationRole).displayName
    }

    getFirstGroupRoleName (moduleType) {
      if (this.projectGroupIdToModuleAndRole && Object.keys(this.projectGroupIdToModuleAndRole).length > 0) {
        let projectGroupIds = Object.keys(this.projectGroupIdToModuleAndRole)
        for (let projectGroupId of projectGroupIds) {
          if (moduleType in this.projectGroupIdToModuleAndRole[projectGroupId]) {
            return this.projectGroupIdToModuleAndRole[projectGroupId][moduleType]
          }
        }
      }
      return undefined
    }

    getStatus () {
      return this.isInvited ? $filter('i18next')('user.organizationUserStatus.invited')
        : $filter('i18next')('user.organizationUserStatus.active')
    }

  }

  return UserModel
})
