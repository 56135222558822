/* global angular */

angular.module('smartvid').controller('AdminVinnieLimitsCtrl', function ($scope, $filter, $q, $state, $timeout,
                                                                         smartvidApi, currentUser, utils, i18nUtils,
                                                                         uiGridConstants) {
  $scope.actionInProgress = false
  $scope.data = undefined
  $scope.adminMode = false

  if (!currentUser.isAuthenticated()) {
    $state.go('login')
  }

  smartvidApi.ensureAdminMode().then(() => {
    $scope.adminMode = true
  })

  $scope.actionInProgress = true
  $scope.gridOptions = {
    enableColumnResizing: true,
    enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
    enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
    enableFiltering: true,
    rowEditWaitInterval: 10,
    paginationPageSizes: [50, 100, 500],
    paginationPageSize: 50,
    onRegisterApi: function (gridApi) {
      $scope.gridApi = gridApi
      gridApi.rowEdit.on.saveRow($scope, $scope.saveRow)
    }
  }

  i18nUtils.loadLabel(() => {
    let orgNameHeader = $filter('i18next')('admin.vinnieLimits.orgName')
    let limitsEnabledHeader = $filter('i18next')('admin.vinnieLimits.limitsEnabled')
    let currentCountHeader = $filter('i18next')('admin.vinnieLimits.currentRecognitionsCount')
    let maxCountHeader = $filter('i18next')('admin.vinnieLimits.maximumRecognitionsCount')

    $scope.gridOptions.columnDefs = [
      {
        name: 'ownerName',
        displayName: orgNameHeader,
        enableCellEdit: false,
        enableHiding: false,
        filter: {
          condition: function (searchTerm, cellValue) {
            return cellValue.toLowerCase().includes(searchTerm.toLowerCase())
          }
        }
      },
      {
        name: 'isEnabled',
        displayName: limitsEnabledHeader,
        type: 'boolean',
        enableFiltering: false,
        enableHiding: false
      },
      {
        name: 'customDataJson.currentRecognitionsCount',
        displayName: currentCountHeader,
        type: 'number',
        enableCellEdit: false,
        enableHiding: false
      },
      {
        name: 'customDataJson.maximumRecognitionsCount',
        displayName: maxCountHeader,
        type: 'number',
        enableHiding: false
      }
    ]

    smartvidApi.getAllFeatureSettingsForOwnerTypeAndFeatureType('ORGANIZATION', 'VINNIE_LIMITS').then((data) => {
      $scope.gridOptions.data = data
      $scope.actionInProgress = false
    }, () => {
      $scope.actionInProgress = false
    })
  })

  $scope.saveRow = (rowEntity) => {
    $scope.actionInProgress = true
    var promise = $q.defer()
    $scope.gridApi.rowEdit.setSavePromise(rowEntity, promise.promise)

    if ((!rowEntity.customDataJson.maximumRecognitionsCount && rowEntity.customDataJson.maximumRecognitionsCount !== 0) || rowEntity.customDataJson.maximumRecognitionsCount < 0) {
      $scope.actionInProgress = false
      promise.reject()
      return
    }

    // prevent update of the currentRecognitionsCount field
    let updatedCustomDataJson = {
      maximumRecognitionsCount: rowEntity.customDataJson.maximumRecognitionsCount
    }
    let payload = {
      featureType: rowEntity.featureType,
      ownerType: rowEntity.ownerType,
      ownerId: rowEntity.ownerId,
      isEnabled: rowEntity.isEnabled,
      customDataJson: updatedCustomDataJson
    }

    smartvidApi.saveFeatureSettings(payload, true).then(() => {
      $scope.actionInProgress = false
      utils.notify('admin.vinnieLimits.updateSuccessful')
      promise.resolve()
    }, () => {
      $scope.actionInProgress = false
      promise.reject()
    })
  }
})
