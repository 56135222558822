/* global angular,analytics */

angular.module('smartvid').controller('CreateOrganizationCtrl', function (MAX_ORGANIZATION_NAME_LENGTH, MAX_ORGANIZATION_DESCRIPTION_LENGTH, $scope, $rootScope, $filter, $state, smartvidApi, $timeout, currentUser) {
  $scope.MAX_ORGANIZATION_NAME_LENGTH = MAX_ORGANIZATION_NAME_LENGTH
  $scope.MAX_ORGANIZATION_DESCRIPTION_LENGTH = MAX_ORGANIZATION_DESCRIPTION_LENGTH

  $scope.inTransit = false

  $scope.thumbnailImg = undefined
  $scope.logoImg = undefined

  $scope.clearThumbnail = function () {
    $scope.thumbnailImg = undefined
  }

  $scope.clearLogo = function () {
    $scope.logoImg = undefined
  }

  $scope.createOrganization = () => {
    if ($scope.invalidDomains || $scope.forbiddenDomains) {
      return
    }

    analytics.track('Create Organization', {
      category: 'Organization Action',
      orgName: $scope.organizationName
    })
    $scope.inTransit = true
    let thumbnail = $scope.thumbnailImg ? $scope.thumbnailImg.file : undefined
    let logo = $scope.logoImg ? $scope.logoImg.file : undefined
    let promise = smartvidApi.createOrganization(
      $scope.organizationName, $scope.organizationDescription, $scope.organizationEmailDomains, thumbnail, logo)
    promise.then((data) => {
      $timeout(function () {
        $scope.inTransit = false
        $rootScope.$broadcast('sv-organization-created', data)
        $scope.modal.close()
      }, 100)
    }, () => {
      $scope.inTransit = false
    })
    return promise
  }
})
