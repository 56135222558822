/* global angular, _ */
angular.module('smartvid').factory('AngucompleteModel', function (BaseModel) {
  class AngucompleteModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        text: '',
        originalObject: undefined
      }
      super(_.defaults(attrs || {}, defaults))
    }
  }

  return AngucompleteModel
})
