/* global angular _ */

angular.module('smartvid').factory('ProcoreObservationIssueAssigneeModel', function (BaseModel) {
  class ProcoreObservationIssueAssigneeModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined,
        name: undefined,
        vendor: {
          id: undefined,
          name: undefined
        }
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return ProcoreObservationIssueAssigneeModel
})
