/* global angular, _ */

angular.module('smartvid').directive('projectGrid', function (currentUser, ProjectModel, ProjectsCollection, $timeout, $state, utils,
                                                             listViewUtils, $filter, $rootScope, $templateCache, $compile,
                                                             assetGridHelper, searchResultService, templatePickService, dashboardDataHelper,
                                                             flyout, smartvidApi, i18nUtils, projectFavoriteService, DefaultGridSupplier) {
  return {
    restrict: 'E',
    // TODO: PL: remove commented out code
    // replace: true,
    templateUrl: 'project-grid.html',
    link: function (scope) {
      const TILEVIEW_ROW_WIDTH = 248
      const TILEVIEW_ROW_HEIGHT = 231
      const PRJ_MENU_FAV_ON_EVENT = 'PRJ_MENU_FAV_ON'
      const PRJ_MENU_FAV_OFF_EVENT = 'PRJ_MENU_FAV_OFF'
      const PRJ_MENU_INFO_EVENT = 'PRJ_MENU_INFO'

      let gridApi
      let originalCollection = getOriginalCollection()
      scope.showingRow = 0
      scope.showingRowEnd = 0
      scope.totalRow = 0
      scope.currentView = isDeletedProjectsState() ? 'GRID_VIEW' : templatePickService.getProjectCurrentView()
      scope.gridOptions = createGridOptions()
      scope.isFetching = true
      scope.tileViewOptions = {
        templateUrl: 'project-thumbnail.html',
        tileSize: { width: TILEVIEW_ROW_WIDTH, height: TILEVIEW_ROW_HEIGHT },
        scrollEndOffset: 5,
        debounce: 0 // 10?
      }
      scope.items = []

      scope.getCollection = getCollection
      scope.canCreateProject = canCreateProject
      scope.performSearch = _.debounce(searchProjects, 100);
      scope.onFavorite = onFavorite
      scope.onUnfavorite = onUnfavorite
      scope.isFlyoutOpenFor = isFlyoutOpenFor
      scope.showProjectOptions = showProjectOptions
      scope.handleCheckbox = handleCheckbox
      scope.goToProject = goToProject
      scope.showFavoriteIcon = isDeletedProjectsState
      scope.showEmptyFolderPlaceHolder = isDeletedProjectsState

      scope.$on('sv-project-view-changed', onProjectViewChanged)
      scope.$on('sv-project-created', onProjectCreated)
      scope.$on('sv-project-deleted', onProjectDeleted)
      scope.$watchCollection('projects.models', onProjectsUpdated)

      assetGridHelper.setIsActiveSearch(searchResultService.isInSearchContext())
      listViewUtils.initGridHeader('project-list-view-header', 'directives.projectList.search-projects.placeholder')

      addTemplates()

      let removeOnFavChangeListenerFunc = projectFavoriteService.addListener((event, project) => {
        if (event === PRJ_MENU_FAV_ON_EVENT || event === PRJ_MENU_FAV_OFF_EVENT) {
          if (scope.data) {
            scope.data.forEach(p => {
              if (p.id === project.id) {
                p.favorite = project.favorite
              }
            })
          }
        } else if (event === PRJ_MENU_INFO_EVENT) {
          if (scope.data) {
            scope.data.forEach(p => {
              if (p.id === project.id) {
                p.selected = project.selected
              } else {
                p.selected = false
              }
            })
          }
        }
      })
      scope.$on('$destroy', function () {
        removeOnFavChangeListenerFunc()
      })

      getCollection().projectPromise.then(() => {
        scope.isFetching = false
        getCollection().models = getSortedProjects(getCollection().models)
        scope.gridSupplier = createGridSupplier()
      })
//--------------------------------------------------------------------------------------------------------------------------
//
      function getOriginalCollection () {
        if (dashboardDataHelper.getGroupProjects() && $state.current.name === 'dashboard.projects.orgId.projectGroupId') {
          return dashboardDataHelper.getGroupProjects()
        } else if (dashboardDataHelper.getOrgProjects() && $state.current.name === 'dashboard.projects.orgId') {
          return dashboardDataHelper.getOrgProjects()
        } else if (dashboardDataHelper.getPrimaryOrgProjects() && $state.current.name === 'dashboard.projects') {
          return dashboardDataHelper.getPrimaryOrgProjects()
        } else {
          return scope.projects
        }
      }

      function createGridSupplier () {
        return new DefaultGridSupplier(getCollection(), isAddItemPlaceholder())
      }

      function createGridOptions () {
        let result = listViewUtils.createDefaultGridOptions(
          scope, 'project-list-view-header', undefined,
          'data', nextPageCallback,
          loadFirstPageCallback, setGridApiCallback, rowSelectionChangedCallback)
        result.enableSorting = true
        result.useExternalSorting = false
        result.columnDefs = [
          {
            cellTemplate: 'custom-project-list-view-checkbox',
            width: 30,
            name: 'checkbox',
            displayName: '',
            enableSorting: false
          },
          {
            field: 'name',
            name: 'projectName',
            cellTooltip: () => {
              return $filter('i18next')('directives.projectList.projectColumn')
            },
            displayName: $filter('i18next')('directives.projectList.projectColumn')
          },
          {
            field: 'organizationName',
            name: 'organizationName',
            cellTooltip: () => {
              return $filter('i18next')('directives.projectList.organizationColumn')
            },
            displayName: $filter('i18next')('directives.projectList.organizationColumn')
          },
          {
            field: 'projectGroupName',
            name: 'projectGroupName',
            cellTooltip: () => {
              return $filter('i18next')('directives.projectList.projectGroupColumn')
            },
            displayName: $filter('i18next')('directives.projectList.projectGroupColumn')
          },
          {
            field: 'externalProjectId',
            name: 'externalProjectId',
            cellTooltip: () => {
              return $filter('i18next')('directives.projectList.projectNumberColumn')
            },
            width: 145,
            displayName: $filter('i18next')('directives.projectList.projectNumberColumn')
          },
          {
            field: 'getProjectActivationStatusText()',
            name: 'isInactive',
            cellTooltip: () => {
              return $filter('i18next')('directives.projectList.projectStatusColumn')
            },
            width: 120,
            displayName: $filter('i18next')('directives.projectList.projectStatusColumn')
          },
          {
            width: 120,
            cellTemplate: 'custom-project-list-menu',
            name: 'menu',
            displayName: '',
            enableSorting: false
          }
        ]
        return result
      }

      function isFlyoutOpenFor (flyoutId) {
        return flyout.isOpenFor(flyoutId)
      }

      function isDeletedProjectsState () {
        return $state.current.name === 'dashboard.adminOrganizations.organizationId.deletedProjects'
      }

      function isAddItemPlaceholder () {
        return isDeletedProjectsState && (getCollection() === undefined || getCollection().length === 0) || canCreateProject() && !isDeletedProjectsState()
      }

      function showProjectOptions ($event, project) {
        $event.stopPropagation()
        if (isDeletedProjectsState()) {
          flyout.open('deletedProjectOptions', {
            parentElement: $event.currentTarget,
            direction: 'right',
            projects: getCollection(),
            project: project,
            allProjects: scope.projects,
            currentView: scope.currentView,
            flyoutId: project.userId
          })
        } else {
          flyout.open('projectOptions', {
            parentElement: $event.currentTarget,
            direction: 'right',
            projects: getCollection(),
            project: project,
            allProjects: scope.projects,
            currentView: scope.currentView,
            flyoutId: project.userId
          })
        }
      }

      function getCollection () {
        return (isSearch() && scope.searchProjects) ? scope.searchProjects : originalCollection
      }

      //|| $state.current.name === 'dashboard.projects'
      function onProjectsUpdated () {
        if (isSearch()) {
          orderSearchProjects()
        } else {
          orderProjects()
        }
      }

      function onProjectDeleted (message, data) {
        if (isSearch()) {
          searchProjects()
        }
      }

      function onProjectCreated (message, data) {
        data.newlyAdded = true
        data.userProjectRole = 'OWNER'
        let projectWithRoleAndOrgName = data.project
        _.each(data, (value, key) => {
          if (key !== 'project') {
            projectWithRoleAndOrgName[key] = value
          }
        })
        let project = new ProjectModel(projectWithRoleAndOrgName)
        project.project = project // TODO why are we doing this. Infinite recursion issues
        scope.projects.add(project)

        if (isSearch()) {
          searchProjects()
        }

        $timeout(() => {
          project.update({ newlyAdded: false })
          $rootScope.$broadcast('sv-project-add-complete', project)
        }, 1)
      }

      function onProjectViewChanged (event, template) {
        scope.currentView = template.currentView
      }

      function canCreateProject () {
        return currentUser.canCreateProject
      }

      function isSearch () {
        return scope.query && scope.query.length > 0
      }

      function onFavorite ($event, project) {
        projectFavoriteService.favorite(project, scope.projects)
      }

      function onUnfavorite ($event, project) {
        projectFavoriteService.unFavorite(project, scope.projects)
      }

      function onSelect (project) {
        let found = scope.projects.findById(project.id)
        if (found) {
          found.selected = project.selected
        }
        if (!isDeletedProjectsState()) {
          smartvidApi.getProjectUsers(project.id).then((users) => {
            project.users = users
          });
        }
      }

      function getSortedProjects (models) {
        return $filter('orderBy')(
          _.filter(models, (p) => {
            return p.canListAllAssets
          }),
          ['-favorite', 'name'])
      }

      function orderProjects () {
        scope.data = getSortedProjects(getCollection().models)
      }

      function orderSearchProjects () {
        if (scope.searchProjects) {
          _.each(scope.projects.models, (model) => {
            let found = scope.searchProjects.findById(model.id)
            if (found) {
              found.selected = model.selected
              found.favorite = model.favorite
            }
          })
          scope.data = getSortedProjects(scope.searchProjects.models)
        }
      }

      function searchProjects () {
        originalCollection.resetSelected()
        const orgId = originalCollection.orgId
        const projectGroupId = originalCollection.projectGroupId
        scope.searchProjects = new ProjectsCollection({
          partialText: scope.query,
          orgId: orgId,
          projectGroupId: projectGroupId,
          collectionModels: originalCollection.models
        })
        scope.searchProjects.projectPromise.then(() => {
          orderSearchProjects()
          scope.totalRow = scope.data.length
          gridApi.infiniteScroll.dataLoaded()
        })
      }

      function nextPageCallback () {
        if (scope.data.canFetch && !scope.data.isFetching) {
          scope.data.nextPage().then(() => {
            scope.totalRow = scope.data.length
            gridApi.infiniteScroll.dataLoaded()
          })
        }
      }

      function loadFirstPageCallback () {
        return getCollection().projectPromise.then(() => {
          orderProjects()
          scope.totalRow = scope.data.length
          gridApi.infiniteScroll.dataLoaded()
        })
      }

      function setGridApiCallback (api) {
        gridApi = api
      }

      function rowSelectionChangedCallback (row) {
        goToProject(undefined, row.entity)
      }

      function goToProject ($event, project) {
        if (project.canListAllAssets && !isDeletedProjectsState()) {
          if (project.hasInsights) {
            $state.go('dashboard.projects.projectId.insights', {
              projectId: project.id,
              organizationId: project.organizationId,
              projectGroupId: project.projectGroupId
            })
          } else {
            $state.go('dashboard.projects.projectId.files', {
              projectId: project.id,
              organizationId: project.organizationId,
              projectGroupId: project.projectGroupId
            })
          }
        }
      }

      function handleCheckbox ($event, project) {
        $event.target.blur()
        if (!$event.shiftKey) {
          $event.stopPropagation()
        }

        performSelection(project)
      }

      function performSelection (selectedProject) {
        onSelect(selectedProject)
        $timeout(() => {
          if (selectedProject.selected && !$rootScope.lastSelectedPlacard) {
            $rootScope.lastSelectedPlacard = selectedProject
          }
          $rootScope.$broadcast('sv-placards-selected')
        })
      }

      function addTemplates () {
        $templateCache.put('custom-project-list-view-checkbox',
          '<div class="checkbox-container">' +
          '  <label class="checkbox" ng-click="grid.appScope.handleCheckbox($event, row.entity)">' +
          '   <div class="custom-checkbox" ng-class="{ checked: row.entity.selected }">' +
          '     <icon-checkmark ng-if="row.entity.selected" class="fill-red icon-checkmark"></icon-checkmark>' +
          '   </div>' +
          '   <input type="checkbox" ng-model="row.entity.selected">' +
          '  </label>' +
          '</div>'
        )

        $templateCache.put('custom-project-list-menu',
          ' <div class="tag-column to-right"> ' +
          '  <thumbnail-lock class="thumbnail-lock" ng-if="!row.entity.canListAllAssets"></thumbnail-lock>' +
          '  <responsive-tooltip ng-if="!row.entity.canListAllAssets"> ' +
          '    <span>{{\'common.cannotAccessPermissions\' | i18next}}</span> ' +
          '  </responsive-tooltip>' +
          '  <icon-options ng-show="row.entity.canUpdate" class="icon-options" ' +
          '     ng-click="grid.appScope.showProjectOptions($event, row.entity)" ' +
          '     ng-class="{\'active\': hovering || grid.appScope.isFlyoutOpenFor(row.entity.id) || row.entity.selected}">' +
          '  </icon-options> ' +
          '</div>' +
          ' <div class="custom-star"> ' +
          ' <star-button style="font-size: 20px"' +
          ' star-on-select = "grid.appScope.onFavorite($event, row.entity)" ' +
          ' star-on-unselect = "grid.appScope.onUnfavorite($event, row.entity)" ' +
          ' star-selected = "row.entity.favorite" ' +
          ' selected-hover-text = "{{ \'tooltips.unfavoriteProject\' | i18next }}" ' +
          ' unselected-hover-text = "{{ \'tooltips.favoriteProject\' | i18next }}"> ' +
          ' </star-button>' +
          ' </div> '
        )
      }
    }
  }
})
