/* global angular,analytics */

angular.module('smartvid').controller('OrganizationOptionsCtrl', function ($rootScope, $scope, $timeout, $window, $state, smartvidApi, modal, currentUser, dashboardDataHelper) {
  const LEFT_OFFSET = 15
  const TOP_OFFSET = 17

  $scope.canManageProfile = currentUser.getOrganization($scope.organization.id).canManageProfile
  $scope.canDelete = currentUser.getOrganization($scope.organization.id).canDelete

  $scope.editOrganization = (organizations, organization) => {
    $scope.flyout.close()
    $state.go('dashboard.adminOrganizations.organizationId.organizationProfile',
      {organizationId: organization.id, organization: organization})
  }

  $scope.deleteOrganization = (organizations, organization) => {
    $scope.flyout.isOpen = false

    let confirmHandlers = {
      confirm () {
        analytics.track('Delete Organization', {
          category: 'Organization Action',
          orgName: organization.name
        })
        smartvidApi.deleteOrganization(organization.id).then(() => {
          currentUser.deleteOrganization(organization)
          dashboardDataHelper.getAllOrganizations().removeById(organization.id)
          $timeout(() => {
            organizations.removeById(organization.id)
            $state.go('dashboard.adminOrganizations')
          }, 400)
        })
      },
      hasSelections: true
    }

    modal.open('deleteConfirm', confirmHandlers)
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top - TOP_OFFSET,
      left: $scope.styles.left + LEFT_OFFSET
    }
  }
})
