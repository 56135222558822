/* global angular $ _ moment */

angular.module('smartvid').controller('RunSRAReportCtrl', function (
  $scope, $element, $q, $timeout, $filter, smartvidApi, dashboardDataHelper, currentUser, reportStateService, modal,
  ReportName
) {
  const MAX_NUMBER_OF_PROJECTS = 10
  const REPORT_GENERATION_RECIPE_PDF = 'PDF'
  const TAG_CONFIDENCE_LEVELS = [
    {
      prettyName: $filter('i18next')('reporting.confidence.high'),
      value: 'HIGH'
    },
    {
      prettyName: $filter('i18next')('reporting.confidence.medium'),
      value: 'MEDIUM'
    },
    {
      prettyName: $filter('i18next')('reporting.confidence.low'),
      value: 'LOW'
    }
  ]
  const LAST_12_MONTHS = {
    prettyName: $filter('i18next')('reporting.dateRange.12_months'),
    value: '12_MONTHS'
  }

  const LAST_6_MONTHS = {
    prettyName: $filter('i18next')('reporting.dateRange.6_months'),
    value: '6_MONTHS'
  }

  const LAST_3_MONTHS = {
    prettyName: $filter('i18next')('reporting.dateRange.3_months'),
    value: '3_MONTHS'
  }

  const LAST_30_DAYS = {
    prettyName: $filter('i18next')('reporting.dateRange.1_month'),
    value: '30_DAYS'
  }

  const LAST_7_DAYS = {
    prettyName: $filter('i18next')('reporting.dateRange.1_week'),
    value: '7_DAYS'
  }

  const DATE_RANGES = [
    LAST_12_MONTHS,
    LAST_6_MONTHS,
    LAST_3_MONTHS,
    LAST_30_DAYS,
    LAST_7_DAYS
  ]

  const SCHEDULE_NONE = {
    type: 'NEVER',
    displayName: $filter('i18next')('reporting.schedule.never'),
    hint: ''
  }
  const SCHEDULE_DAILY = {
    type: 'DAILY',
    displayName: $filter('i18next')('reporting.schedule.runDaily'),
    hint: $filter('i18next')('reporting.schedule.runDailyHint')
  }
  const SCHEDULE_WEEKLY = {
    type: 'WEEKLY',
    displayName: $filter('i18next')('reporting.schedule.runWeekly'),
    hint: $filter('i18next')('reporting.schedule.runWeeklyHint')
  }
  const SCHEDULE_MONTHLY = {
    type: 'MONTHLY',
    displayName: $filter('i18next')('reporting.schedule.runMonthly'),
    hint: $filter('i18next')('reporting.schedule.runMonthlyHint')
  }
  const SCHEDULE_TYPES = [
    SCHEDULE_NONE,
    SCHEDULE_DAILY,
    SCHEDULE_WEEKLY,
    SCHEDULE_MONTHLY
  ]

  let allProjects = dashboardDataHelper.getAllProjects()

  $scope.isSchedulingEnabled = true
  $scope.MAX_NUMBER_OF_PROJECTS = MAX_NUMBER_OF_PROJECTS
  $scope.moreThan10ErrorMessage = $filter('i18next')('reporting.moreThan10ErrorMessage')
  $scope.selectedMoreThanMax = false
  $scope.allConfidenceLevels = TAG_CONFIDENCE_LEVELS
  $scope.selectedConfidenceLevel = TAG_CONFIDENCE_LEVELS[0]
  $scope.isFetchingSystemData = true
  $scope.reportSafetyTagsModel = {}
  $scope.safetyTagsInOrder = []
  $scope.step = 1
  $scope.projectsSelectedForReport = []
  $scope.selectedUsers = []
  $scope.showProjectValidation = false
  $scope.dateRanges = DATE_RANGES
  $scope.dateRange = {range: DATE_RANGES[0]}
  $scope.scheduleTypes = SCHEDULE_TYPES
  $scope.schedule = {selected: SCHEDULE_NONE}
  $scope.datePicker = {date: null}
  $scope.datePickerOptions = getDatePickerOptions()
  $scope.constructionRelatedOnly = {value: true}
  $scope.editMode = !!$scope.data.report
  $scope.isReportView = !!$scope.data.isReportView

  $scope.searchProjectsByPartialText = searchProjectsByPartialText
  $scope.handleDownArrow = handleDownArrow
  $scope.addProject = addProject
  $scope.hasSelectedProjects = hasSelectedProjects
  $scope.hasSelectedSafetyTags = hasSelectedSafetyTags
  $scope.checkForReturnKey = checkForReturnKey
  $scope.removeProject = removeProject
  $scope.isReachedMaxProjectSelection = isReachedMaxProjectSelection
  $scope.nextStep = nextStep
  $scope.closeModal = closeModal
  $scope.previousStep = previousStep
  $scope.searchByEmail = searchByEmail
  $scope.getAllUsers = getAllUsers
  $scope.submit = submit
  init()

  function init () {
    let lastReportState = $scope.data.report ? fromSafetySummaryReportResponse($scope.data.report) : getLastReportStateForUser(currentUser.id)

    smartvidApi.getReportSystemData().then(function handleReportSysData (sysData) {
      $scope.safetyTagsInOrder = sysData.reportSafetyTags
      sysData.reportSafetyTags.forEach((tagName) => {
        $scope.reportSafetyTagsModel[tagName] = true
      })

      if (!_.isEmpty(lastReportState.selectedSafetyTagNames)) {
        let previouslySelectedTagNames = new Set(lastReportState.selectedSafetyTagNames)
        _.keys($scope.reportSafetyTagsModel)
          .forEach((tagName) => {
            $scope.reportSafetyTagsModel[tagName] = previouslySelectedTagNames.has(tagName)
          })
      }

      $scope.isFetchingSystemData = false
    }, () => {
    })

    $scope.projectsSelectedForReport = _(allProjects.models)
      .filter((project) => _.contains(lastReportState.selectedProjectIds, project.id)).value()

    $scope.selectedConfidenceLevel = lastReportState.selectedConfidenceLevelValue
      ? _.findWhere(TAG_CONFIDENCE_LEVELS, {value: lastReportState.selectedConfidenceLevelValue})
      : $scope.selectedConfidenceLevel

    $scope.constructionRelatedOnly.value = lastReportState.isConstructionRelatedOnly

    $scope.dateRange.range = lastReportState.dateRange
      ? _.findWhere(DATE_RANGES, {value: lastReportState.dateRange})
      : $scope.dateRange.range

    lastReportState.selectedUsers
      ? lastReportState.selectedUsers.forEach(user => $scope.selectedUsers.push(user))
      : $scope.selectedUsers.push(currentUser)

    $scope.schedule.selected = lastReportState.reportFixedScheduleInterval
      ? _.findWhere(SCHEDULE_TYPES, {type: lastReportState.reportFixedScheduleInterval})
      : $scope.schedule.selected

    if (lastReportState.endDate) {
      $timeout(() => {
        $scope.datePicker.date = moment(lastReportState.endDate)
        let dateRangePickerControl = $('#date-picker').data('daterangepicker')
        dateRangePickerControl.setStartDate($scope.datePicker.date)
        dateRangePickerControl.setEndDate($scope.datePicker.date)
      }, 50)
    }
  }

  function nextStep () {
    $scope.step++
  }

  function previousStep () {
    $scope.step--
  }

  function closeModal () {
    modal.close()
  }

  function hasSelectedProjects () {
    return allProjects !== undefined && allProjects.getSelected().length > 0
  }

  function isReachedMaxProjectSelection () {
    return $scope.projectsSelectedForReport.length >= MAX_NUMBER_OF_PROJECTS
  }

  function getLastReportStateForUser (userId) {
    return reportStateService.getReportStateForUser(ReportName.SAFETY_SUMMARY_REPORT, userId)
  }

  function hasSelectedSafetyTags (reportSafetyTagsModel) {
    return _(reportSafetyTagsModel).values().any()
  }

  function searchProjectsByPartialText (partial) {
    function safe (str) {
      return (str || '').toUpperCase()
    }

    let result = _(allProjects.models)
      .filter((project) => project.canRunReports && safe(project.name).indexOf(safe(partial)) !== -1)
      .filter((project) => !_.contains($scope.projectsSelectedForReport, project))
      .filter((project) => project.isVinnieEnabled)
      .sortBy((proj) => safe(proj.name))
      .value()
    return $q.resolve({'data': result})
  }

  function handleDownArrow () {
    let angucompleteElement = angular.element('.report-step-page .angucomplete-holder')
    let angucompleteScope = angucompleteElement.scope()
    if (angucompleteScope.showDropdown) {
      angucompleteScope.showDropdown = false
      return
    }
    let inputField = angucompleteElement.find('input')
    inputField.trigger('mousedown')
    inputField.focus()
    angucompleteScope.showDropdown = true
    angucompleteScope.searching = true
    let inputFieldValue = inputField.val()
    let searchPromise = searchProjectsByPartialText(inputFieldValue)
    searchPromise.then(results => {
      $timeout(() => {
        angucompleteScope.results = _(results.data || []).map((project) => {
          return {
            title: project.name,
            description: '',
            image: '',
            originalObject: project
          }
        }).value()
        angucompleteScope.searching = false
        angucompleteScope.showDropdown = true
      }, 300)
    })
  }

  function checkForReturnKey (evt) {
    if (evt.keyCode === 13) {
      evt.preventDefault()
      addProject()
    }
  }

  function addProject () {
    if (isReachedMaxProjectSelection()) {
      $scope.showProjectValidation = true
      return
    }
    $scope.showProjectValidation = false
    let angucompleteScope = angular.element('.report-step-page .angucomplete-holder').scope()
    if (angucompleteScope.selectedObject && angucompleteScope.selectedObject.originalObject) {
      let projectToAdd = _.isString(angucompleteScope.selectedObject.originalObject)
        ? allProjects.first({name: angucompleteScope.selectedObject.originalObject})
        : angucompleteScope.selectedObject.originalObject
      if (projectToAdd && !_.contains($scope.projectsSelectedForReport, projectToAdd)) {
        $scope.projectsSelectedForReport.push(projectToAdd)
      }
    } else {
      let inputVal = angular.element('.report-step-page .angucomplete-holder input').val()
      if (inputVal) {
        let projectToAdd = _(allProjects.models)
          .filter((project) => project.canUpdate && (project.name || '').toUpperCase() === inputVal.toUpperCase())
          .filter((project) => !_.contains($scope.projectsSelectedForReport, project))
          .value()
          .shift()
        if (projectToAdd) {
          $scope.projectsSelectedForReport.push(projectToAdd)
        }
      }
    }
    $scope.$broadcast('angucomplete-alt:clearInput')
  }

  function removeProject (project) {
    $scope.showProjectValidation = false
    _.remove($scope.projectsSelectedForReport, project)
  }

  function searchByEmail (partialEmail) {
    let promises = []
    let projectIds = _.pluck(allProjects.where({canInviteUsers: true}), 'id')
    let orgIds = _.pluck(_.where(currentUser.organizations, {canManageUsers: true}), 'id')
    promises.push(smartvidApi.getUsersByPartialEmailForProjects(projectIds, partialEmail))
    promises.push(smartvidApi.getUsersByPartialEmailForOrgs(orgIds, partialEmail))
    return $q.all(promises)
  }

  function getAllUsers () {
    let promises = []
    _.each(allProjects.models, (project) => {
      promises.push(smartvidApi.getProjectUsers(project.id))
    })
    _.each(currentUser.organizations, (org) => {
      promises.push(smartvidApi.getOrganizationUsers(org.id))
    })
    return $q.all(promises)
  }

  function toLocalStorageItemFromRequest (request, dateRangeValue) {
    let ret = {}
    ret.selectedProjectIds = request.projectIds
    ret.selectedSafetyTagNames = request.selectedSafetyTags
    ret.selectedConfidenceLevelValue = request.tagConfidenceLevel
    ret.selectedToUserEmails = request.toUserEmails
    ret.isConstructionRelatedOnly = request.isConstructionRelatedOnly
    ret.dateRange = dateRangeValue
    return ret
  }

  function fromSafetySummaryReportResponse (report) {
    let ret = {}
    ret.selectedProjectIds = report.projectIds
    ret.selectedSafetyTagNames = report.selectedSafetyTags
    ret.selectedConfidenceLevelValue = report.tagConfidenceLevel
    ret.selectedToUserEmails = report.toUserEmails
    ret.isConstructionRelatedOnly = report.isConstructionRelatedOnly
    ret.dateRange = getDateRage(report.endDate, report.startDate)
    ret.reportFixedScheduleInterval = report.reportFixedScheduleInterval
    ret.endDate = report.endDate
    ret.selectedUsers = report.selectedUsers
    return ret
  }

  function submit () {
    let requestObject = {}
    requestObject.projectIds = _.pluck($scope.projectsSelectedForReport, 'id')
    requestObject.tagConfidenceLevel = $scope.selectedConfidenceLevel.value
    requestObject.selectedSafetyTags = _($scope.safetyTagsInOrder).filter(tag => $scope.reportSafetyTagsModel[tag]).value()
    requestObject.toUserEmails = _.pluck($scope.selectedUsers, 'email')
    requestObject.startDate = getStartDate($scope.datePicker.date, $scope.dateRange.range).valueOf()
    requestObject.endDate = $scope.datePicker.date.endOf('day').valueOf()
    requestObject.recipeType = REPORT_GENERATION_RECIPE_PDF
    requestObject.isConstructionRelatedOnly = $scope.constructionRelatedOnly.value
    requestObject.reportFixedScheduleInterval = $scope.schedule.selected.type
    requestObject.createdDate = moment().toDate().valueOf()

    $scope.generatingReport = true
    if ($scope.editMode) {
      requestObject.reportId = $scope.data.report.reportId
      smartvidApi.updateSafetySummaryReport(requestObject).then(() => {
        $scope.generatingReport = false
        $scope.step++
        reportStateService.upsertReportStateForUser(ReportName.SAFETY_SUMMARY_REPORT, currentUser.id,
          toLocalStorageItemFromRequest(requestObject, $scope.dateRange.range.value))
      })
    } else {
      smartvidApi.generateSafetySummaryReport(requestObject).then(() => {
        $scope.generatingReport = false
        $scope.step++
        reportStateService.upsertReportStateForUser(ReportName.SAFETY_SUMMARY_REPORT, currentUser.id,
          toLocalStorageItemFromRequest(requestObject, $scope.dateRange.range.value))
      })
    }

  }

  function getDatePickerOptions () {
    return {
      locale: {
        format: 'MM/DD/YYYY'
      },
      singleDatePicker: true,
      maxDate: moment().toDate()
    }
  }

  function getStartDate (endDateMoment, dateRange) {
    let startDateMoment = moment(endDateMoment)
    switch (dateRange.value) {
      case LAST_6_MONTHS.value:
        startDateMoment.startOf('day')
          .startOf('month')
          .subtract(6, 'months')
          .add(1, 'month')
        break
      case LAST_3_MONTHS.value:
        // PL: treat this as 13 weeks back exactly
        startDateMoment.startOf('day')
          .subtract(13, 'weeks')
        break
      case LAST_30_DAYS.value:
        startDateMoment.startOf('day')
          .subtract(30, 'days')
          .add(1, 'day')
        break
      case LAST_7_DAYS.value:
        startDateMoment.startOf('day')
          .subtract(7, 'days')
          .add(1, 'day')
        break
      default:
        startDateMoment.startOf('day')
          .startOf('month')
          .subtract(1, 'years')
          .add(1, 'months')
    }

    return startDateMoment
  }

  function getDateRage (endDate, startDate) {
    let diffDays = moment(endDate).diff(moment(startDate), 'days')
    if (diffDays > 1 && diffDays <= 7) {
      return LAST_7_DAYS.value
    } else if (diffDays > 7 && diffDays <= 30) {
      return LAST_30_DAYS.value
    } else if (diffDays > 30 && diffDays <= 91) {
      return LAST_3_MONTHS.value
    } else if (diffDays > 90 && diffDays <= 180) {
      return LAST_6_MONTHS.value
    } else {
      return LAST_12_MONTHS.value
    }
  }

  $scope.filterFixedScheduleInterval = () => {
    return function (item) {
      return !($scope.editMode === true && $scope.report.reportFixedScheduleInterval !== 'NEVER' && item.type === 'NEVER');
    }
  }
})
