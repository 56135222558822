/* global angular,_ */

angular.module('smartvid').service('md5Service', function ($log, $q, $window, utils) {
  const chunkSize = 5 // in MB
  const maxConcurrency = 8
  let promiseFuncQueue = []

  let loopPromises = []
  this.generateHashes = generateHashes

  function generateHashes (fileInfos) {
    let generator = new MD5HashGenerator(fileInfos)
    return generator.generate()
  }

  function startLoop () {
    if (promiseFuncQueue.length === 0) {
      return
    }
    for (let i = loopPromises.length; i < maxConcurrency; i++) {
      let loopPromise = executionLoop()
      loopPromises.push(loopPromise) - 1
      loopPromise.then(() => {
        let index = loopPromises.indexOf(loopPromise)
        loopPromises.splice(index, 1)
      })
    }
  }

  function executionLoop () {
    let defer = $q.defer()
    let nextPromiseFunc = promiseFuncQueue.shift()
    if (!nextPromiseFunc) {
      defer.resolve()
    } else {
      nextPromiseFunc.call().then(() => {
        executionLoop().then(() => {
          defer.resolve()
        })
      })
    }
    return defer.promise
  }

  function sendPromiseFunctionToQueue (promiseFunctions) {
    promiseFunctions.forEach((func) => {
      promiseFuncQueue.push(func)
    })
    startLoop()
  }

  function createMD5Promise (fileInfo) {
    let md5Promise = utils.calculateMD5Hash(fileInfo.file, chunkSize).then(function (checksum) {
      fileInfo.checksum = checksum
    })
    return md5Promise
  }

  class MD5HashGenerator {
    constructor (fileInfos) {
      this.fileInfos = fileInfos
      this.processedFiles = 0
    }

    generate () {
      let defer = $q.defer()
      let promiseFunctions = []
      let that = this
      this.fileInfos.forEach(function (fileInfo, index) {
        let promiseFunction = () => {
          if (index === 0) {
            that.startTime = Date.now()
            $log.info(`Starting hash calculations for all files. Number of files: ${that.fileInfos.length}, maxConcurrency: ${maxConcurrency}, chunkSize: ${chunkSize}MB`)
          }
          let promise = createMD5Promise(fileInfo)
          promise.then(() => {
            that.processedFiles++
            if (that.processedFiles === that.fileInfos.length) {
              that.endTime = Date.now()
              let sum = ((_.reduce(that.fileInfos, function (memo, fileInfo) { return memo + fileInfo.file.size }, 0)) / (1024 * 1024)).toFixed(2)
              let infoString = `All hashes calculated in ${that.endTime - that.startTime} ms. NumFiles: ${that.fileInfos.length}. Total Size: ${sum}MB`
              $log.info(infoString)
              defer.resolve()
            }
          })
          return promise
        }
        promiseFunctions.push(promiseFunction)
      })
      sendPromiseFunctionToQueue(promiseFunctions)
      return defer.promise
    }
  }
})
