/* global angular, _ */

angular.module('smartvid').factory('TagDefModel', function (BaseModel) {
  class TagDefModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        organizationId: undefined,
        deleted: undefined, // boolean
        createdTime: undefined, // timestamp
        projectId: undefined, // string
        text: undefined,
        isAllowAsr: undefined,
        isAllowImrec: undefined,
        isAllowManual: undefined,
        addedAliases: undefined, // array
        updatedTime: undefined // timestamp
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return TagDefModel
})
