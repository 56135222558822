/* global angular */

angular.module('smartvid').service('appRedirect', function ($timeout, $state, $window) {
  let Redirect = {

    openSmartvid (path, stateUrl) {
      var window = angular.element($window)
      var events = ['pagehide', 'blur']

      var timeout = null

      var preventDialog = function () {
        clearTimeout(timeout)
        timeout = null
        window.off(events.join(' '))
      }

      window.location = path
      window.on(events.join(' '), preventDialog)

      timeout = $timeout(() => {
        $state.go(stateUrl)
      }, 500)
    }
  }

  return Redirect
})
