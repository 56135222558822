/* global angular */

angular.module('smartvid').factory('ProcoreProjectCollection', function (BaseCollection, ProcoreProjectModel, smartvidApi, $interval) {
  class ProcoreProjectCollection extends BaseCollection {
    constructor (models) {
      super(models, ProcoreProjectModel)
    }
  }

  return ProcoreProjectCollection
})
