<div class="assets-insights-dashboard-engagement mdc-layout-grid__inner">
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'ACTIVITY_BY_USER' }">
    <sv-insights-card
      #activityByUserCard
      contentHeight="400px"
      cardTitle="{{ 'dashboard.cards.activityByUser.title' | translate }}"
      subTitle="{{ projectTotalsTableData?.length }} {{ 'dashboard.cards.activityByUser.subTitle' | translate }}"
      class="project-totals-table-card"
      hasTextInput="true"
      [toggleMenuGroups]="activityByUserTableMenu"
      (toggleMenuItemSelected)="activityByUserMenuSelected($event, activityByUserCard)"
      textInputPlaceholder="{{ 'dashboard.cards.activityByUser.searchInputPlaceholder' | translate }}"
      (cardInputChange)="projectTotalsTableFilterChanged($event, projectTotalsTable)"
    >
      <sv-project-totals-table #projectTotalsTable [tableData]="projectTotalsTableData" [dashboardParameters]="currentInsightsDashboardParameters"></sv-project-totals-table>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'UPLOADS_OVERTIME_BY_SOURCE' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.uploadsOverTimeBySource.title' | translate }}"
      subTitle="{{ projectUploadsOverTimeBySourceData?.startDate.toDateString() }} - {{
        projectUploadsOverTimeBySourceData?.endDate.toDateString()
      }}"
      [toggleMenuGroups]="uploadsBySourceChartMenu"
      (toggleMenuItemSelected)="uploadsBySourceChartMenuSelected($event)"
    >
      <sv-insights-column-chart
        [chartData]="projectUploadsOverTimeBySourceData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'UPLOADERS_OVERTIME' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.uploadersCountOverTime.title' | translate }}"
      subTitle="{{ projectUploadersOverTimeData?.startDate.toDateString() }} - {{
        projectUploadersOverTimeData?.endDate?.toDateString()
      }}"
      [toggleMenuGroups]="uploadersChartMenu"
      (toggleMenuItemSelected)="uploadersChartMenuSelected($event)"
    >
      <sv-insights-column-chart
        [chartData]="projectUploadersOverTimeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'CONSTRUCTION_ACTIVITY' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.constructionActivity.title' | translate }}"
      subTitle="{{ projectConstructionActivityData?.startDate.toDateString() }} - {{
        projectConstructionActivityData?.endDate.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectConstructionActivityData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'PHOTO_DESCRIPTION' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.photoDescription.title' | translate }}"
      subTitle="{{ projectPhotoDescriptionData?.startDate.toDateString() }} - {{
        projectPhotoDescriptionData?.endDate.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectPhotoDescriptionData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'UPLOADS_BY_FILE_TYPE' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.uploadsByFileType.title' | translate }}"
      subTitle="{{ projectUploadsByFileTypeData?.startDate.toDateString() }} - {{
        projectUploadsByFileTypeData?.endDate.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectUploadsByFileTypeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'TAGS_BY_TYPE' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.tagsByType.title' | translate }}"
      subTitle="{{ projectTagsByTypeData?.startDate.toDateString() }} - {{
        projectTagsByTypeData?.endDate.toDateString()
      }}"
    >
      <sv-insights-pie-chart [chartData]="projectTagsByTypeData?.chartData" [spinnerDiameter]="64"></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
</div>
