import * as _ from 'lodash'
import * as Q from 'q'
import { Component, OnDestroy, Inject, OnInit, ElementRef, ViewChild, Injector } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { FormControl, FormGroup, FormBuilder } from '@angular/forms'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { MatChipInputEvent } from '@angular/material/chips'
import { Observable } from 'rxjs'
import { startWith, debounceTime, switchMap, map } from 'rxjs/operators'
import { SmartvidApi, SMARTVID_API, CurrentUser, CURRENT_USER } from 'shared/smartvid.types'
import { DashboardDataHelperService } from 'modules/core/services/dashboard-data-helper.service'
import { ModuleType } from 'shared/models/module-type'
import { ProjectGroupService } from 'modules/core/services/projectgroup.service'
import { TranslateService } from '@ngx-translate/core'
import { EMAIL_REGEXP } from '../../../../shared/utils/email-utils'

export class AddUserDialogData {
  organizationId: string
  projectId: string
  strategy: AddUserDialogStrategy
}

enum RoleType {
  GROUP,
  ORG,
  PROJECT,
}

class ProjectGroupCheckbox {
  id: string
  name: string
  selected: boolean
}

class Role {
  name: string
  type: RoleType
  projectGroups: ProjectGroupCheckbox[]
  title: string
  description: string
}

class RoleGroup {
  groupTitle: string
  formControlName: string
  roles: Role[]

  constructor(groupTitle: string, formControlName: string, roles: Role[]) {
    this.groupTitle = groupTitle
    this.formControlName = formControlName
    this.roles = roles
  }
}

const SAFETY_NONE = 'NON_MEMBER'
const DEFAULT_ORG_USER_ROLE = 'USER'

interface AddUserDialogStrategy {
  init(
    currentUser: CurrentUser,
    injector: Injector,
    projectGroupApi: ProjectGroupService,
    dashboardDataHelper: DashboardDataHelperService,
    smartvidApi: SmartvidApi,
    traslate: TranslateService
  )

  initRoles(addUserDialogData: AddUserDialogData): RoleGroup[]

  getFilteredUsers(addUserDialogData: AddUserDialogData, form: FormGroup): Observable<{}>

  onSave(addUserDialogData: AddUserDialogData, emails: string[], form: FormGroup)

  getDialogTitle(): string

  getDialogAddButton(): string

  getEmailsTitle(): string
}

export class AddOrganizationUserDialogStrategy implements AddUserDialogStrategy {
  private projectGroups: ProjectGroupCheckbox[]
  private currentUser: CurrentUser
  private injector: Injector
  private projectGroupApi: ProjectGroupService
  private dashboardDataHelper: DashboardDataHelperService
  private smartvidApi: SmartvidApi
  private translate: TranslateService

  init(
    currentUser: CurrentUser,
    injector: Injector,
    projectGroupApi: ProjectGroupService,
    dashboardDataHelper: DashboardDataHelperService,
    smartvidApi: SmartvidApi,
    translate: TranslateService
  ) {
    this.currentUser = currentUser
    this.injector = injector
    this.projectGroupApi = projectGroupApi
    this.dashboardDataHelper = dashboardDataHelper
    this.smartvidApi = smartvidApi
    this.translate = translate
  }

  initRoles(addUserDialogData: AddUserDialogData): RoleGroup[] {
    let roleGroups: RoleGroup[] = []

    let organization = this.dashboardDataHelper.getOrganizationByOrgId(addUserDialogData.organizationId)

    let orgAssetRoles = this.getOrgAssetRoles(organization)
    roleGroups.push(
      new RoleGroup('components.addOrganizationUser.assetsRolesTitle', 'projectFilesRoleCtrl', orgAssetRoles)
    )
    if (this.isObservationsEnabled(addUserDialogData)) {
      let orgObservationRoles = this.getOrgObservationRoles(organization)
      roleGroups.push(
        new RoleGroup(
          'components.addOrganizationUser.observationRolesTitle',
          'observationsRoleCtrl',
          orgObservationRoles
        )
      )
    }
    return roleGroups
  }

  getFilteredUsers(addUserDialogData: AddUserDialogData, form: FormGroup): Observable<{}> {
    let orgIds = _.without(
      _.pluck(_.where(this.currentUser.organizations, { canManageUsers: true }), 'id'),
      addUserDialogData.organizationId
    )

    let allUsers = Q.defer()
    let promises = []
    _.each(this.currentUser.organizations, org => {
      if (org.id !== addUserDialogData.organizationId) {
        promises.push(this.smartvidApi.getOrganizationUsers(org.id))
      }
    })
    Q.all(promises).then(users => {
      allUsers.resolve(Array.prototype.concat.apply([], users))
    })

    function getAllUsers() {
      return allUsers.promise
    }

    let getServerUsersFunc = () => {
      let value = form.controls['emailCtrl'].value

      if (!value) {
        return getAllUsers()
      }
      return this.smartvidApi.getUsersByPartialEmailForOrgs(orgIds, value)
    }

    return form.controls['emailCtrl'].valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(getServerUsersFunc),
      map((users: any[]) => _.uniq(users, u => u.email).sort())
    )
  }

  getDialogTitle(): string {
    return 'components.addOrganizationUser.dialogTitle'
  }

  getEmailsTitle(): string {
    return 'components.addOrganizationUser.emailInputHeader'
  }

  getDialogAddButton(): string {
    return 'components.addOrganizationUser.addUserButton'
  }

  onSave(addUserDialogData: AddUserDialogData, emails: string[], form: FormGroup) {
    let assetsRole: Role = form.controls['projectFilesRoleCtrl'].value
    let observationsRole: Role = form.controls['observationsRoleCtrl'].value
    let createdOrgUsers: any[] = this.getCreatedOrgUsers(emails, assetsRole, observationsRole)
    this.smartvidApi.createUser(addUserDialogData.organizationId, createdOrgUsers).then(data => {
      this.inviteToProjectGroups(emails, assetsRole, observationsRole, addUserDialogData)
        .toPromise()
        .then(() => {
          this.injector.get('$rootScope').$broadcast('sv-user-created', data)
        })
    })
  }

  private getCreatedOrgUsers(emails: string[], assetsRole: Role, observationsRole: Role): any[] {
    let createdUsers: any[] = []
    let assetsRoleName = assetsRole && assetsRole.type !== RoleType.GROUP ? assetsRole.name : DEFAULT_ORG_USER_ROLE
    let observationsRoleName =
      observationsRole && observationsRole.name !== SAFETY_NONE && observationsRole.type !== RoleType.GROUP
        ? observationsRole.name
        : undefined
    if (assetsRoleName || observationsRoleName) {
      createdUsers.push(
        ..._.map(emails, email => {
          return {
            email: email,
            role: assetsRoleName,
            observationRole: observationsRoleName,
          }
        })
      )
    }
    return createdUsers
  }

  private inviteToProjectGroups(
    emails: string[],
    assetsRole: Role,
    observationsRole: Role,
    addUserDialogData: AddUserDialogData
  ): Observable<any> {
    const _updatedUsers = []
    const _uninvitedUsers = []

    if (assetsRole.type === RoleType.GROUP) {
      emails.forEach(email => {
        assetsRole.projectGroups.forEach(projectGroup => {
          if (projectGroup.selected) {
            _updatedUsers.push({
              userId: undefined,
              projectGroupId: projectGroup.id,
              userEmail: email,
              assetRole: assetsRole.name,
            })
          } else {
            _uninvitedUsers.push({
              userEmail: email,
              projectGroupId: projectGroup.id,
              moduleType: ModuleType.ASSETS,
            })
          }
        })
      })
    }

    if (this.isObservationsEnabled(addUserDialogData) && observationsRole.type === RoleType.GROUP) {
      emails.forEach(email => {
        observationsRole.projectGroups.forEach(projectGroup => {
          if (projectGroup.selected) {
            _updatedUsers.push({
              userId: undefined,
              projectGroupId: projectGroup.id,
              userEmail: email,
              observationRole: observationsRole.name,
            })
          } else {
            _uninvitedUsers.push({
              userEmail: email,
              projectGroupId: projectGroup.id,
              moduleType: ModuleType.OBSERVATIONS,
            })
          }
        })
      })
    }

    return this.projectGroupApi.updateInvitedUsers(addUserDialogData.organizationId, {
      uninvitedUsers: _uninvitedUsers,
      updatedUsers: _updatedUsers,
    })
  }

  private isObservationsEnabled(addUserDialogData: AddUserDialogData): boolean {
    let organization = this.dashboardDataHelper.getOrganizationByOrgId(addUserDialogData.organizationId)
    return this.currentUser.isObservationEnabledForOrganization(organization.id)
  }

  private getOrgAssetRoles(organization: any): Role[] {
    let result = _.map(this.currentUser.getCreatableOrganizationRoles(organization.id), (role: any) => {
      return {
        name: role.name,
        type: RoleType.ORG,
        projectGroups: [],
        title: role.displayName,
        description: role.description,
      }
    })
    for (let groupRole of this.currentUser.regionRoles) {
      if (!groupRole.manuallyAssignable) {
        continue
      }
      let assignableGroups = this.getAssignableAssetGroups(organization, groupRole.name)
      if (assignableGroups.length === 0) {
        continue
      }
      result.push({
        name: groupRole.name,
        type: RoleType.GROUP,
        title: groupRole.displayName,
        description: groupRole.description,
        projectGroups: _.map(assignableGroups, (group: any) => {
          return {
            id: group.id,
            name: group.name,
            selected: false,
          }
        }),
      })
    }
    return result
  }

  private getOrgObservationRoles(organization: any): Role[] {
    let result: Role[] = _.map(
      this.currentUser.getCreatableOrganizationObservationRoles(organization.id),
      (role: any) => {
        return {
          name: role.name,
          type: RoleType.ORG,
          projectGroups: [],
          title: role.displayName,
          description: role.description,
        }
      }
    )
    result.unshift({
      name: SAFETY_NONE,
      type: RoleType.ORG,
      projectGroups: [],
      title: 'None',
      description: '',
    })

    for (let groupRole of this.currentUser.observationRegionRoles) {
      if (!groupRole.manuallyAssignable) {
        continue
      }
      let assignableGroups = this.getAssignableObservationGroups(organization, groupRole.name)
      if (assignableGroups.length === 0) {
        continue
      }
      result.push({
        name: groupRole.name,
        type: RoleType.GROUP,
        title: groupRole.displayName,
        description: groupRole.description,
        projectGroups: _.map(assignableGroups, (group: any) => {
          return {
            id: group.id,
            name: group.name,
            selected: false,
          }
        }),
      })
    }
    return result
  }

  private getAssignableObservationGroups(organization, groupRoleName) {
    let result = []
    for (let group of organization.projectGroups) {
      if (group.creatableObservationRoles.includes(groupRoleName)) {
        result.push(group)
      }
    }
    return result
  }

  private getAssignableAssetGroups(organization, groupRoleName) {
    let result = []
    for (let group of organization.projectGroups) {
      if (group.creatableAssetRoles.includes(groupRoleName)) {
        result.push(group)
      }
    }
    return result
  }
}

export class AddProjectMemberDialogStrategy implements AddUserDialogStrategy {
  private currentUser: CurrentUser
  private injector: Injector
  private projectGroupApi: ProjectGroupService
  private dashboardDataHelper: DashboardDataHelperService
  private smartvidApi: SmartvidApi
  private translate: TranslateService

  init(
    currentUser: CurrentUser,
    injector: Injector,
    projectGroupApi: ProjectGroupService,
    dashboardDataHelper: DashboardDataHelperService,
    smartvidApi: SmartvidApi,
    translate: TranslateService
  ) {
    this.currentUser = currentUser
    this.injector = injector
    this.projectGroupApi = projectGroupApi
    this.dashboardDataHelper = dashboardDataHelper
    this.smartvidApi = smartvidApi
    this.translate = translate
  }

  initRoles(addUserDialogData: AddUserDialogData): RoleGroup[] {
    let roleGroups: RoleGroup[] = []

    let project = this.dashboardDataHelper.getProjectByProjectId(addUserDialogData.projectId)

    let assetProjectRoles = this.currentUser.getAssignableProjectRoles(project)
    roleGroups.push(
      new RoleGroup(
        'project.assetsRolesTitle',
        'projectFilesRoleCtrl',
        _.map(assetProjectRoles, (role: any) => {
          return {
            name: role.name,
            projectGroups: [],
            type: RoleType.PROJECT,
            title: role.displayName,
            description: role.description,
          }
        })
      )
    )

    if (this.isObservationsEnabled(addUserDialogData)) {
      let observationProjectRoles = this.currentUser.getAssignableObservationProjectRoles(project)
      let observationRoles = _.map(observationProjectRoles, (role: any) => {
        return {
          name: role.name,
          projectGroups: [],
          type: RoleType.PROJECT,
          title: role.displayName,
          description: role.description,
        }
      })

      observationRoles.unshift({
        name: SAFETY_NONE,
        projectGroups: [],
        type: RoleType.PROJECT,
        title: 'None',
        description: '',
      })

      roleGroups.push(new RoleGroup('project.observationRolesTitle', 'observationsRoleCtrl', observationRoles))
    }
    return roleGroups
  }

  getFilteredUsers(addUserDialogData: AddUserDialogData, form: FormGroup): Observable<{}> {
    let getServerUsersFunc = () => {
      let value = form.controls['emailCtrl'].value

      if (!value) {
        return this.smartvidApi.getOrganizationUsers(addUserDialogData.organizationId)
      }
      return this.smartvidApi.getUsersByPartialEmailForOrg(addUserDialogData.organizationId, value)
    }

    return form.controls['emailCtrl'].valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(getServerUsersFunc),
      map((users: any[]) => _.uniq(users, u => u.email).sort())
    )
  }

  onSave(addUserDialogData: AddUserDialogData, emails: string[], form: FormGroup) {
    let filesRole = form.controls['projectFilesRoleCtrl'].value
    let observationRole = form.controls['observationsRoleCtrl'].value
    if (!observationRole || observationRole.name === SAFETY_NONE) {
      observationRole = undefined
    } else {
      observationRole = observationRole.name
    }
    this.dashboardDataHelper.getCurrentProject().inviteUsers(
      _.map(emails, email => {
        return {
          userEmail: email,
          role: filesRole ? filesRole.name : undefined,
          observationRole: observationRole,
        }
      })
    )
  }

  getDialogTitle(): string {
    return 'components.addProjectMember.dialogTitle'
  }

  getEmailsTitle(): string {
    return 'components.addProjectMember.emailInputHeader'
  }

  getDialogAddButton(): string {
    return 'components.addProjectMember.addMemberButton'
  }

  private isObservationsEnabled(addUserDialogData: AddUserDialogData): boolean {
    let organization = this.dashboardDataHelper.getOrganizationByOrgId(addUserDialogData.organizationId)
    return this.currentUser.isObservationEnabledForOrganization(organization.id)
  }
}

@Component({
  selector: 'sv-add-user-dialog',
  templateUrl: 'add-user-dialog.component.html',
  styleUrls: ['add-user-dialog.component.scss'],
})
export class AddUserDialogComponent implements OnInit, OnDestroy {
  selectable = true
  removable = true
  addOnBlur = true
  separatorKeysCodes: number[] = [ENTER, COMMA]
  filteredUsers: Observable<{}>
  emails: string[] = []

  addUserForm: FormGroup

  @ViewChild('emailInput', { static: false }) emailInput: ElementRef<HTMLInputElement>
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete

  roleGroups: RoleGroup[] = []

  dialogTitle: string
  emailsTitle: string
  dialogAddButton: string

  constructor(
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    @Inject(CURRENT_USER) private currentUser: CurrentUser,
    private injector: Injector,
    private fb: FormBuilder,
    private projectGroupApi: ProjectGroupService,
    private dashboardDataHelper: DashboardDataHelperService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public addUserDialogData: AddUserDialogData,
    @Inject(SMARTVID_API) private smartvidApi: SmartvidApi
  ) {
    addUserDialogData.strategy.init(currentUser, injector, projectGroupApi, dashboardDataHelper, smartvidApi, translate)

    this.addUserForm = fb.group({
      emailCtrl: new FormControl(),
      projectFilesRoleCtrl: new FormControl(),
      observationsRoleCtrl: new FormControl(),
    })
    this.roleGroups = addUserDialogData.strategy.initRoles(addUserDialogData)
    this.filteredUsers = addUserDialogData.strategy.getFilteredUsers(addUserDialogData, this.addUserForm)
    this.dialogTitle = addUserDialogData.strategy.getDialogTitle()
    this.dialogAddButton = addUserDialogData.strategy.getDialogAddButton()
    this.emailsTitle = addUserDialogData.strategy.getEmailsTitle()
    this.addUserForm.controls['emailCtrl'].setValue('')

    _.each(this.roleGroups, group => {
      if (group.roles.length > 0) {
        this.addUserForm.controls[group.formControlName].setValue(group.roles[0])
      }
    })
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input
      const value = event.value

      if (!EMAIL_REGEXP.test(value.trim())) {
        return
      }

      if ((value || '').trim()) {
        this.emails.push(value.trim())
      }

      if (input) {
        input.value = ''
      }

      this.addUserForm.controls['emailCtrl'].setValue(null)
    }
  }

  canSave() {
    if (this.emails.length === 0 && !this.addUserForm.controls['emailCtrl'].value) {
      return false
    }

    let organization = this.dashboardDataHelper.getOrganizationByOrgId(this.addUserDialogData.organizationId)
    let observationsEnabled = this.currentUser.isObservationEnabledForOrganization(organization.id)

    let assetsRole: Role = this.addUserForm.controls['projectFilesRoleCtrl'].value

    if (assetsRole && assetsRole.type === RoleType.GROUP) {
      if (
        !_.find(assetsRole.projectGroups, (g: any) => {
          return g.selected
        })
      ) {
        return false
      }
    }

    if (!assetsRole) {
      return false
    }

    if (observationsEnabled) {
      let observationsRole: Role = this.addUserForm.controls['observationsRoleCtrl'].value
      if (observationsRole && observationsRole.type === RoleType.GROUP) {
        if (
          !_.find(observationsRole.projectGroups, (g: any) => {
            return g.selected
          })
        ) {
          return false
        }
      }
      if (!observationsRole) {
        return false
      }
    }
    return true
  }

  onAdd() {
    let emails = this.emails
    if (this.addUserForm.controls['emailCtrl'].value) {
      emails = emails.concat(this.addUserForm.controls['emailCtrl'].value)
    }
    this.addUserDialogData.strategy.onSave(this.addUserDialogData, emails, this.addUserForm)
    this.onClose()
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email)

    if (index >= 0) {
      this.emails.splice(index, 1)
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.emails.push(event.option.viewValue)
    this.emailInput.nativeElement.value = ''
    this.addUserForm.controls['emailCtrl'].setValue(null)
  }

  isSelectedRole(formControlName: string, role: Role): boolean {
    let value = this.addUserForm.controls[formControlName].value
    return value && value.name === role.name
  }

  updateCheckboxes() {
    _.each(this.roleGroups, roleGroup => {
      _.each(roleGroup.roles, role => {
        if (this.addUserForm.controls[roleGroup.formControlName].value !== role) {
          _.each(role.projectGroups, group => {
            group.selected = false
          })
        }
      })
    })
  }

  select(formControlName: string, role: Role) {
    this.addUserForm.controls[formControlName].setValue(role)
  }

  ngOnInit() {
    this.dialogRef.updateSize('640px', '80%')
  }

  ngOnDestroy() {}

  onClose() {
    this.dialogRef.close()
  }
}
