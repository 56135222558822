/* global angular, $ */

angular.module('smartvid').directive('projectThumbnail', function (
    $timeout, smartvidApi, $state, utils, flyout, $rootScope, projectFavoriteService
) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'project-thumbnail.html',
    scope: {
      projects: '=',
      allProjects: '=',
      project: '=',
      tagsPopoverShowing: '='
    },
    link (scope, elem) {
      scope.projectNameTooltipOptions = {
        side: 'bottom',
        delay: [1500, 0]
      }
      scope.showProjectOptions = showProjectOptions
      scope.isFlyoutOpenFor = isFlyoutOpenFor
      scope.gotoProject = gotoProject
      scope.fetchUsersForProject = fetchUsersForProject
      scope.onFavorite = onFavorite
      scope.onUnfavorite = onUnfavorite
      scope.onSelect = onSelect

      scope.$on('sv-project-add-complete', onProjectAddComplete)

      function showProjectOptions ($event) {
        $event.stopPropagation()
        flyout.open('projectOptions', {
          parentElement: $event.currentTarget,
          direction: 'left',
          flyoutId: scope.project.id,
          projects: scope.projects,
          project: scope.project,
          allProjects: scope.allProjects,
          source: 'PROJECT-THUMBNAIL'
        })
      }

      function isFlyoutOpenFor (flyoutId) {
        return flyout.isOpenFor(flyoutId)
      }

      function gotoProject (evt) {
        if (!scope.project.canListAllAssets) {
          return
        }
        if (utils.isMetaKeySelect(evt, scope, scope.project, scope.projects)) {
          return
        }
        $state.go('dashboard.projects.projectId.files', {
          projectId: scope.project.id,
          isDemoProject: scope.project.isDemoProjectCopy
        })
      }

      function onSelect ($event, project) {
        fetchUsersForProject($event, project)
        let found = scope.allProjects.findById(project.id)
        if (found) {
          found.selected = project.selected
        }
      }

      function fetchUsersForProject ($event, project) {
        $event.target.blur()
        if (!$event.shiftKey) {
          $event.stopPropagation()
        }
        smartvidApi.getProjectUsers(project.id).then((users) => {
          project.users = users
        })

        $timeout(() => {
          if (project.selected && !$rootScope.lastSelectedPlacard) {
            $rootScope.lastSelectedPlacard = project
          }
        })
      }

      function onProjectAddComplete (event, project) {
        if (project.id === scope.project.id) {
          $(elem).parent('li').addClass('grid-item-born')
          $timeout(() => {
            $(elem).parent('li').removeClass('grid-item-born')
          }, 5000)
        }
      }

      function onFavorite () {
        projectFavoriteService.favorite(scope.project, scope.allProjects)
      }

      function onUnfavorite () {
        projectFavoriteService.unFavorite(scope.project, scope.allProjects)
      }
    }
  }
})
