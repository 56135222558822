import * as moment from 'moment'
import { ProjectTotalCounts } from 'modules/insights/dashboard/models/insights-dashboard-project-assets.model'
import { Component, Input } from '@angular/core'
import { InsightsDashboardProjectAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-project-assets.service'
import {
  DashboardCardId,
  DashboardDateRangeTimeUnit,
  DashboardTabId,
  DashboardType,
  DateRangeChartData,
  InsightsDashboardParameters,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import { CardToggleMenuGroup, CardToggleMenuItem } from 'modules/insights/dashboard/cards/models/insights-card.model'
import { TranslateService } from '@ngx-translate/core'
import { InsightsDashboardComponentBaseDirective } from 'modules/insights/dashboard/components/insights-dashboard-component-base'
import { chartHasAllNullValues, convertNullValuesToZero } from '../../../../../utils/dashboard-utils'

@Component({
  selector: 'sv-assets-insights-dashboard-safety',
  templateUrl: 'assets-insights-dashboard-safety.component.html',
  styleUrls: ['assets-insights-dashboard-safety.component.scss'],
})
export class AssetsInsightsDashboardSafetyComponent extends InsightsDashboardComponentBaseDirective {
  private _projectTotalCounts: ProjectTotalCounts
  @Input() set projectTotals(projectTotalCounts: ProjectTotalCounts) {
    this._projectTotalCounts = projectTotalCounts

    if (this._projectTotalCounts) {
      this.laddersToLiftsRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectTotalCounts.laddersToLiftsRatioPercentChange
      )
      this.peoplePerXConstructionPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectTotalCounts.peoplePerXConstructionPercentChange
      )
    }
  }
  get projectTotals() {
    return this._projectTotalCounts
  }

  projectSlipAndTripTrendOverTimeData: DateRangeChartData
  projectWorkAtHeightsOverTimeData: DateRangeChartData
  projectWorkAtHeightsPerXAssetsData: DateRangeChartData
  projectLaddersPerXAssetsData: DateRangeChartData
  projectLiftsPerXAssetsData: DateRangeChartData
  projectHousekeepingAndStandingWaterData: DateRangeChartData
  projectPpeComplianceOverTimeData: DateRangeChartData
  projectPeopleInGroupsOverTimeData: DateRangeChartData
  peopleInGroupsOverTimeChartMenu: CardToggleMenuGroup[]
  laddersToLiftsRatioPercentChangeClassName: string
  peoplePerXConstructionPercentChangeClassName: string
  chartHasAllNullValues = chartHasAllNullValues
  convertNullValuesToZero = convertNullValuesToZero

  constructor(
    protected insightsDataHelper: InsightsDataHelper,
    protected insightsDashboardConfigurationService: InsightsDashboardConfigurationService,
    protected translate: TranslateService,
    private insightsDashboardProjectService: InsightsDashboardProjectAssetsService
  ) {
    super(insightsDataHelper, insightsDashboardConfigurationService, translate, DashboardType.PROJECT_ASSETS_DASHBOARD)
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // the ()=> function is needed to capture the correct 'this' reference
  protected refresh() {
    this.initializeMenuItems()
    this.guardFor(
      () => this.loadProjectPeopleInGroupsOverTimeBySourceData(DashboardDateRangeTimeUnit.LAST_1),
      DashboardTabId.SAFETY,
      DashboardCardId.PEOPLE_IN_GROUPS
    )
    this.guardFor(
      () => this.loadPpeComplianceTrendOverTimeData(),
      DashboardTabId.SAFETY,
      DashboardCardId.PPE_COMPLIANCE_OVERTIME
    )
    this.guardFor(
      () => this.loadSlipAndTripTrendOverTimeData(),
      DashboardTabId.SAFETY,
      DashboardCardId.SLIP_AND_TRIP_OVERTIME
    )
    this.guardFor(
      () => this.loadWorkAtHeightsTrendOverTimeData(),
      DashboardTabId.SAFETY,
      DashboardCardId.WORK_AT_HEIGHTS_OVERTIME
    )
    this.guardFor(
      () => this.loadWorkAtHeightsPerXAssetsData(),
      DashboardTabId.SAFETY,
      DashboardCardId.WORK_AT_HEIGHT_PER_X_ASSETS
    )
    this.guardFor(() => this.loadLaddersPerXAssetsData(), DashboardTabId.SAFETY, DashboardCardId.LADDERS_PER_X_ASSETS)
    this.guardFor(() => this.loadLiftsPerXAssetsData(), DashboardTabId.SAFETY, DashboardCardId.LIFTS_PER_X_ASSETS)
    this.guardFor(
      () => this.loadHousekeepingAndStandingWaterData(),
      DashboardTabId.SAFETY,
      DashboardCardId.HOUSEKEEPING_AND_STANDING_WATER
    )
  }

  private initializeMenuItems() {
    this.peopleInGroupsOverTimeChartMenu = this.createChartDateSwitchMenuItems(
      'PEOPLE_IN_GROUP_MENU',
      'peopleInGroupsOverTime'
    )
  }

  private loadProjectPeopleInGroupsOverTimeBySourceData(chartResolution: DashboardDateRangeTimeUnit) {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeTimeUnit: chartResolution,
    }
    this.projectPeopleInGroupsOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadPeopleInGroupsOverTimeData(dashboardParams, chartData => {
        this.projectPeopleInGroupsOverTimeData = chartData
      })
    )
  }

  private loadPpeComplianceTrendOverTimeData() {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeStart: moment(this.currentInsightsDashboardParameters.dateRangeEnd)
        .subtract(90, 'days')
        .toDate(),
      dateRangeTimeUnit: DashboardDateRangeTimeUnit.LAST_30,
    }
    this.projectPpeComplianceOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadPpeComplianceTrendOverTimeData(dashboardParams, chartData => {
        this.projectPpeComplianceOverTimeData = chartData
      })
    )
  }

  private loadSlipAndTripTrendOverTimeData() {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeStart: moment(this.currentInsightsDashboardParameters.dateRangeEnd)
        .subtract(90, 'days')
        .toDate(),
      dateRangeTimeUnit: DashboardDateRangeTimeUnit.LAST_30,
    }
    this.projectSlipAndTripTrendOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadSlipAndTripTrendOverTimeData(dashboardParams, chartData => {
        this.projectSlipAndTripTrendOverTimeData = chartData
      })
    )
  }

  private loadWorkAtHeightsTrendOverTimeData() {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeStart: moment(this.currentInsightsDashboardParameters.dateRangeEnd)
        .subtract(90, 'days')
        .toDate(),
      dateRangeTimeUnit: DashboardDateRangeTimeUnit.LAST_30,
    }
    this.projectWorkAtHeightsOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadWorkAtHeightsTrendOverTimeData(dashboardParams, chartData => {
        this.projectWorkAtHeightsOverTimeData = chartData
      })
    )
  }

  private loadWorkAtHeightsPerXAssetsData() {
    this.projectWorkAtHeightsPerXAssetsData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadWorkAtHeightsPerXAssetsData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectWorkAtHeightsPerXAssetsData = chartData
        }
      )
    )
  }

  private loadLaddersPerXAssetsData() {
    this.projectLaddersPerXAssetsData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadLaddersPerXAssetsData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectLaddersPerXAssetsData = chartData
        }
      )
    )
  }

  private loadLiftsPerXAssetsData() {
    this.projectLiftsPerXAssetsData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadLiftsPerXAssetsData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectLiftsPerXAssetsData = chartData
        }
      )
    )
  }

  private loadHousekeepingAndStandingWaterData() {
    this.projectHousekeepingAndStandingWaterData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadHousekeepingAndStandingWaterData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectHousekeepingAndStandingWaterData = chartData
        }
      )
    )
  }

  private peopleInGroupsOverTimeChartMenuSelected($event: CardToggleMenuItem) {
    this.loadProjectPeopleInGroupsOverTimeBySourceData($event.eventId)
  }
}
