<div class="content">
    <h2 mat-dialog-title>
        {{ 'components.adminOrganizationIntegrations.disconnectWarningHeader' | translate }}
        <button mat-icon-button appearance="fill" mat-dialog-close class="close-button">
            <mat-icon>clear</mat-icon>
        </button>
    </h2>
    <mat-dialog-content>
        {{ 'components.adminOrganizationIntegrations.disconnectWarningBody' | translate: {integrationCount: integrationCount} }}
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-stroked-button mat-dialog-close cdkFocusInitial>
            {{ 'components.adminOrganizationIntegrations.disconnectButton' | translate }}
        </button>
        <button mat-flat-button color="primary" [mat-dialog-close]="true">
            {{ 'components.adminOrganizationIntegrations.cancelButton' | translate }}
        </button>
    </mat-dialog-actions>
</div>
