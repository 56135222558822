import * as _ from 'lodash'

export class DashboardDataHelperService {
  private _allOrganizations: any
  private _allProjects: any
  private _orgProjects: any
  private _primaryOrgProjects: any
  private _groupProjects: any
  private _currentProject: any
  private _currentAdminOrg: any
  private _globalAssets: any
  private _organizationAssets: any
  private _projectGroupAssets: any
  private _assets: any
  private _asset: any
  private _currentProjectTags: any
  private _currentOrgTags: any
  private _currentProjectTagTree: any
  private _currentOrgTagTree: any
  private _currentAdminOrgUsers: any
  private _integrations: any
  private _downloads: string[] = []

  getDownloads() {
    return this._downloads
  }

  resetDownloads() {
    this._downloads = []
  }

  addDownloads(url: string) {
    this._downloads.push(url)
  }

  getProjectByProjectId(projectId: string): any {
    return (
      this._allProjects &&
      _.find(this._allProjects.models, proj => {
        return proj.id === projectId
      })
    )
  }

  getOrganizationByOrgId(orgId: string) {
    return _.find(this.getAllOrganizations().models, (org: any) => {
      return org.id === orgId
    })
  }

  getAllOrganizations() {
    return this._allOrganizations
  }

  setAllOrganizations(allOrganizations) {
    this._allOrganizations = allOrganizations
  }

  getOrgProjects() {
    return this._orgProjects
  }

  setOrgProjects(orgProjects) {
    this._orgProjects = orgProjects
  }

  getGroupProjects() {
    return this._groupProjects
  }

  setGroupProjects(groupProjects) {
    this._groupProjects = groupProjects
  }

  getAllProjects() {
    return this._allProjects
  }

  setAllProjects(allProjects) {
    this._allProjects = allProjects
  }

  setPrimaryOrgProjects(primaryOrgProjects) {
    this._primaryOrgProjects = primaryOrgProjects
  }

  getPrimaryOrgProjects() {
    return this._primaryOrgProjects
  }

  setCurrentProject(currentProject) {
    this._currentProject = currentProject
  }

  getCurrentProject() {
    return this._currentProject
  }

  setCurrentAdminOrganization(currentAdminOrg) {
    this._currentAdminOrg = currentAdminOrg
  }

  getCurrentAdminOrganization() {
    return this._currentAdminOrg
  }

  getOrganizationAssets() {
    return this._organizationAssets
  }

  setOrganizationAssets(_organizationAssets) {
    if (_organizationAssets !== this._organizationAssets && !!this._organizationAssets) {
      this._organizationAssets.cleanup()
    }
    this._organizationAssets = _organizationAssets
  }
  getProjectGroupAssets() {
    return this._projectGroupAssets
  }

  setProjectGroupAssets(_projectGroupAssets) {
    if (_projectGroupAssets !== this._projectGroupAssets && !!this._projectGroupAssets) {
      this._projectGroupAssets.cleanup()
    }
    this._projectGroupAssets = _projectGroupAssets
  }

  getGlobalAssets() {
    return this._globalAssets
  }

  setGlobalAssets(globalAssets) {
    if (globalAssets !== this._globalAssets && !!this._globalAssets) {
      this._globalAssets.cleanup()
    }
    this._globalAssets = globalAssets
  }

  getAssets() {
    return this._assets || this.getGlobalAssets()
  }

  setAssets(assets) {
    if (assets !== this._assets && !!this._assets) {
      this._assets.cleanup()
    }
    this._assets = assets
  }

  getCurrentAsset() {
    return this._asset
  }

  setCurrentAsset(asset) {
    this._asset = asset
  }

  getCurrentProjectTags() {
    if (this._currentProjectTags) {
      this._currentProjectTags.refresh()
    }
    return this._currentProjectTags
  }

  setCurrentProjectTags(tags) {
    this._currentProjectTags = tags
  }

  getCurrentOrgTags() {
    if (this._currentOrgTags) {
      this._currentOrgTags.refresh()
    }
    return this._currentOrgTags
  }

  setCurrentOrgTags(tags) {
    this._currentOrgTags = tags
  }

  getCurrentProjectTagTree() {
    if (this._currentProjectTagTree) {
      this._currentProjectTagTree.refresh()
    }
    return this._currentProjectTagTree
  }

  setCurrentProjectTagTree(tagTree) {
    this._currentProjectTagTree = tagTree
  }

  getCurrentOrgTagTree() {
    if (this._currentOrgTagTree) {
      this._currentOrgTagTree.refresh()
    }
    return this._currentOrgTagTree
  }

  setCurrentOrgTagTree(tagTree) {
    this._currentOrgTagTree = tagTree
  }

  setCurrentAdminOrganizationUsers(users) {
    this._currentAdminOrgUsers = users
  }

  getCurrentAdminOrganizationUsers() {
    return this._currentAdminOrgUsers
  }

  getProjectGroup(orgId: string, projectGroupId: string) {
    let org = this.getOrganizationByOrgId(orgId)
    if (!org) {
      return null
    }
    return _.find(org.projectGroups, function(group: any) {
      return group.id === projectGroupId
    })
  }

  getTargetAssets(stateParams) {
    if (stateParams.projectGroupId) {
      return this.getProjectGroupAssets()
    } else if (stateParams.organizationId) {
      return this.getOrganizationAssets()
    } else {
      return this.getAssets()
    }
  }
}
