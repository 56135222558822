/* global angular */

angular.module('smartvid').service('featureSettingsApi', function ($http, $q, $log, config) {
  let rootUrl = config.env.development.apiRootUrl

  let api = {
    getAllFeatureSettingsForOwnerTypeAndFeatureType (ownerType, featureType) {
      let url = `${rootUrl}/api/admin/featuresettings/ownertype/${ownerType}/feature/${featureType}`
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('getAllFeatureSettingsForOwnerTypeAndFeatureType', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getFeatureSettingsForOwnerTypeAndOwnerIdAndFeatureType (ownerType, ownerId, featureType) {
      let url = `${rootUrl}/api/featuresettings/ownertype/${ownerType}/owner/${ownerId}/feature/${featureType}`
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('getFeatureSettingsForOwnerTypeAndOwnerIdAndFeatureType', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    saveFeatureSettings (featureSettings, doPartialCustomDataJsonUpdate) {
      let url = `${rootUrl}/api/admin/featuresettings/${featureSettings.id}?doPartialCustomDataJsonUpdate=${doPartialCustomDataJsonUpdate}`
      let defer = $q.defer()
      //
      $http.put(url, featureSettings).then((response) => {
        $log.debug('saveFeatureSettings', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    }
  }
  return api
})
