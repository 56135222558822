/* global angular,analytics,_ */

angular.module('smartvid').controller('CreateProjectCtrl', function (MAX_PROJECT_NAME_LENGTH, MAX_PROJECT_DESCRIPTION_LENGTH, $scope, $rootScope, $filter, smartvidApi, $state, $q, $timeout, currentUser, dashboardDataHelper) {
  $scope.MAX_PROJECT_NAME_LENGTH = MAX_PROJECT_NAME_LENGTH
  $scope.MAX_PROJECT_ID_LENGTH = MAX_PROJECT_NAME_LENGTH
  $scope.MAX_PROJECT_DESCRIPTION_LENGTH = MAX_PROJECT_DESCRIPTION_LENGTH

  $scope.users = []

  $scope.inTransit = false

  $scope.labelText = $filter('i18next')('directives.createProject.peopleLabel')

  $scope.thumbnailImg = undefined
  $scope.userOrgs = _.filter(dashboardDataHelper.getAllOrganizations().models, filterOrg)
  $scope.selectedOrg = selectedOrg($scope.userOrgs)

  $scope.projectAlreadyExistError = false
  $scope.deletedProjectAlreadyExistError = false
  $scope.projectAlreadyExistErrorMsg = ''
  $scope.selectedProjectGroup = selectedProjectGroup()

  $scope.clear = function () {
    $scope.thumbnailImg = undefined
  }

  $scope.createProject = () => {
    analytics.track('Create Project', {
      category: 'Project Action',
      orgName: $scope.selectedOrg.name,
      projectName: $scope.projectName
    })
    $scope.inTransit = true

    let customErrorHandler = (response) => {
      if (response.errorCode === 'BAD_REQUEST') {
        const find = _.find(response.errorMessages,
          (m) => {
            return m.label === 'resource.project.already.exists'
          })

        if (find) {
          $scope.projectAlreadyExistError = true
          $scope.projectAlreadyExistErrorMsg = find.message
        }
        if (_.find(response.errorMessages, (m) => {
          return m.label === 'resource.deleted.project.name.exists'
        })) {
          $scope.deletedProjectAlreadyExistError = true
        }

        return true
      }

      return false
    }

    let thumbnail = $scope.thumbnailImg ? $scope.thumbnailImg.file : undefined
    let selectedProjectGroupId = $scope.selectedProjectGroup && $scope.selectedProjectGroup.id
    let promise = smartvidApi.createProject($scope.projectName, $scope.externalProjectId, $scope.projectDescription,
        $scope.users, thumbnail, $scope.selectedOrg.id, selectedProjectGroupId, customErrorHandler)
    promise.then((data) => {
      $timeout(function () {
        $scope.inTransit = false
        // $state.reload()
        $rootScope.$broadcast('sv-project-created', data)
        $state.go('dashboard.projects.projectId.files', {
          projectId: data.project.id,
          isDemoProject: false
        })
        $scope.modal.close()
      }, 100)
    }, () => {
      $scope.inTransit = false
    })
    return promise
  }

  function selectedOrg (userOrgs) {
    if (!userOrgs) {
      return undefined
    }
    if (userOrgs.length === 0) {
      return undefined
    }
    let defaultOrg = currentUser.defaultOrganization(true, userOrgs)
    let foundOrg = userOrgs.find(org => org.id === $state.params.organizationId)
    return foundOrg ? foundOrg : defaultOrg
  }

  function selectedProjectGroup () {
    let projectGroupId = $state.params.projectGroupId
    return $scope.selectedOrg.projectGroups.find((group) => group.id === projectGroupId)
  }

  function filterOrg (org) {
    return org.canManageProjects && !org.isOrganizationInactive
  }
})
