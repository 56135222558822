/* global angular _ */
angular.module('smartvid').directive('usersGrid', function ($timeout, UserModel, $stateParams, utils, $filter,
                                                            dashboardDataHelper, DefaultGridSupplier, $state,
                                                            $rootScope, flyout, uiGridConstants, i18nUtils,
                                                            $templateCache, smartvidApi, currentUser, listViewUtils) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'UsersGrid/users-grid.html',

    link: function (scope) {
      let currentOrg = dashboardDataHelper.getCurrentAdminOrganization()
      scope.query = ''
      scope.organizationId = currentOrg.id
      scope.getSortedUsers = getSortedUsers
      scope.isFetching = isFetching
      scope.showUserProfileOptions = showUserProfileOptions
      scope.getUserRoleClassName = getUserRoleClassName
      scope.isUserFirstLastNotDefined = isUserFirstLastNotDefined
      scope.isFlyoutOpenFor = isFlyoutOpenFor
      scope.performSearch = searchUser
      scope.showingRow = 0
      scope.showingRowEnd = 0
      scope.totalRow = 0

      scope.items = []

      let gridApi

      scope.data = scope.users.models

      listViewUtils.initGridHeader('users-list-view-header', 'directives.users-grid.org.search.placeholder')

      scope.gridOptions = listViewUtils.createDefaultGridOptions(
        scope, 'users-list-view-header', sortChanged,
        'data', nextPage,
        loadFirstPage, setGridApiCallback, rowSelectionChangedCallback)

      scope.userRoleClassMap = {}
      scope.userRoleClassMap['USER'] = 'user'
      scope.userRoleClassMap['GUEST'] = 'user'
      scope.userRoleClassMap['ORGANIZATION_ADMIN'] = 'admin'
      scope.userRoleClassMap['PRIMARY_OWNER'] = 'admin'

      init()

      $templateCache.put('custom-user-grid-menu-tag',
        '<div class="tag-column">' +
        '  <icon-options class="icon-options" ' +
        '     ng-click="grid.appScope.showUserProfileOptions($event, row.entity)" ' +
        '     ng-class="{\'active\': hovering || isFlyoutOpenFor(row.entity.id) || row.entity.selected}">' +
        '  </icon-options>' +
        '</div>'
      )

      scope.menuTemplate = 'custom-user-grid-menu-tag'

      i18nUtils.loadLabel(() => {
        scope.gridOptions.columnDefs = [
          {
            cellTemplate: 'default-list-view-checkbox',
            width: 30,
            name: 'checkbox',
            displayName: '',
            enableSorting: false
          },
          {
            sortName: 'name',
            field: 'getName()',
            cellTooltip: (row) => {
              return row.entity.getName()
            },
            displayName: $filter('i18next')('directives.users-grid.nameColumn')
          },
          {
            sortName: 'email',
            name: 'email',
            sort: {
              direction: uiGridConstants.ASC,
              priority: 1
            },
            cellTooltip: (row) => {
              return row.entity.email
            },
            displayName: $filter('i18next')('directives.users-grid.emailColumn')
          },
          {
            sortName: 'roleName',
            field: 'getRolei18nLabel()',
            cellTooltip: (row) => {
              return row.entity.getRolei18nLabel()
            },
            width: 120,
            displayName: $filter('i18next')('directives.users-grid.roleColumn')
          },
          {
            sortName: 'observationRoleName',
            field: 'getObservationRolei18nLabel()',
            cellTooltip: (row) => {
              return row.entity.getObservationRolei18nLabel()
            },
            width: 120,
            visible: currentUser.isObservationEnabledForOrganization(scope.organizationId),
            displayName: $filter('i18next')('directives.users-grid.observationRoleColumn')
          },
          {
            sortName: 'status',
            field: 'getStatus()',
            cellTooltip: (row) => {
              return row.entity.getStatus()
            },
            width: 100,
            displayName: $filter('i18next')('directives.users-grid.statusColumn')
          },
          {
            cellTemplate: 'custom-user-grid-menu-tag',
            width: 30,
            name: 'menu',
            displayName: '',
            enableSorting: false
          }
        ]
      })

      scope.showUserOptions = ($event, user) => {
        $event.stopPropagation()
        flyout.open('userProfileOptions', {
          parentElement: $event.currentTarget,
          direction: 'left',
          organizationId: scope.organizationId,
          user: user,
          flyoutId: user.id
        })
      }

      scope.handleCheckbox = ($event, user) => {
        $event.target.blur()
        if (!$event.shiftKey) {
          $event.stopPropagation()
        }

        $timeout(() => {
          if (user.selected && !$rootScope.lastSelectedPlacard) {
            $rootScope.lastSelectedPlacard = user
          }
        })
      }

      scope.isFlyoutOpenFor = (flyoutId) => {
        return flyout.isOpenFor(flyoutId)
      }

      scope.$on('sv-user-created', onOrgUserCreated)
      scope.$on('sv-users-deleted', onOrgUsersDeleted)

      // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      function setGridApiCallback (api) {
        gridApi = api
      }

      function rowSelectionChangedCallback (row) {
        $state.go('dashboard.adminOrganizations.organizationId.userProfile',
          {organizationId: scope.organizationId, userId: row.entity.id, user: row.entity})
      }

      let initDataWatcher = () => {
        scope.$watchCollection('users.models', (n, o) => {
          if (n.length < o.length) {
            getTotalRow()
          }
        })
      }

      let getTotalRowLastPromise

      let getTotalRow = () => {
        if (scope.query) {
          scope.totalRow = scope.data.length
        } else {
          let promise = smartvidApi.getOrganizationUsersCount(scope.organizationId).then((count) => {
            if (promise !== getTotalRowLastPromise) {
              return
            }
            scope.totalRow = count
          })
          getTotalRowLastPromise = promise
        }
      }

      function sortChanged (grid, sortColumns) {
        if (!sortColumns || sortColumns.length !== 1) {
          // Sorting by multiple columns is not supported
          return
        }

        $timeout(() => {
          gridApi.infiniteScroll.setScrollDirections(false, false)

          scope.data = []

          scope.users.reset()
          scope.users.options.sortColumn = [sortColumns[0].colDef.sortName + ':' + sortColumns[0].sort.direction]
          if (scope.query) {
            searchUser()
            gridApi.infiniteScroll.resetScroll()
          } else {
            loadFirstPage().then(() => {
              $timeout(() => {
                gridApi.infiniteScroll.resetScroll()
              })
            })
          }
        })
      }

      function loadFirstPage () {
        getTotalRow()
        return scope.users.nextPage().then(() => {
          scope.data = scope.users.models
          initDataWatcher()
          gridApi.infiniteScroll.dataLoaded()
        })
      }

      function init () {
        if ($stateParams.showJoinConfirmedNotification && $stateParams.organizationName && $stateParams.user) {
          let msg = $stateParams.user.firstName + ' ' + $stateParams.user.lastName + ' ' +
            $filter('i18next')('directives.users-grid.joinConfirmed') + ' ' + $stateParams.organizationName
          utils.notify(msg)
        }
      }

      function getSortedUsers () {
        let result = $filter('orderBy')(scope.users.models, function (user) {
          return (user.lastName) ? user.lastName : user.email
        })
        return result
      }

      function onOrgUsersDeleted () {
        if (!checkEmptyQuery()) {
          $timeout(function () {
            return searchByTerm()
          }, 400)
        }
      }

      function onOrgUserCreated (message, data) {
        gridApi.infiniteScroll.setScrollDirections(false, false)
        scope.query = ''
        scope.data = []

        scope.users.reset()
        loadFirstPage().then(() => {
          $timeout(() => {
            gridApi.infiniteScroll.resetScroll()
          })
        })
      }

      function nextPage () {
        if (scope.users.canFetch && !scope.users.isFetching) {
          scope.users.nextPage().then(() => {
            scope.data = scope.users.models
            gridApi.infiniteScroll.dataLoaded()
          })
        }
      }

      function isFetching () {
        return scope.users.isFetching && !scope.users.initialized
      }

      function getUserRoleClassName (user) {
        return scope.userRoleClassMap[user.role]
      }

      function showUserProfileOptions ($event, user) {
        $event.stopPropagation()
        let options = {
          parentElement: $event.currentTarget,
          direction: 'left',
          organizationId: currentOrg.id,
          user: user,
          flyoutId: user.id
        }
        if (!checkEmptyQuery()) {
          _.extend(options, {
            callBackFn: {
              onDelete: function () {
                $timeout(function () {
                  return searchByTerm()
                }, 400)
              }
            }
          })
        }
        flyout.open('userProfileOptions', options)
      }

      function isUserFirstLastNotDefined (user) {
        return !user.firstName && !user.lastName
      }

      function isFlyoutOpenFor (flyoutId) {
        return flyout.isOpenFor(flyoutId)
      }

      function checkEmptyQuery () {
        return !scope.query || scope.query.trim().length === 0
      }

      function searchByTerm () {
        let options = {
          page: 0,
          pageSize: scope.users.options.pageSize
        }
        smartvidApi.searchOrgUsers(scope.organizationId, scope.query, options).then((users) => {
          scope.users.init(users, UserModel)
          scope.data = users
          getTotalRow()
        })
      }

      function searchUser () {
        scope.query = this.query
        if (checkEmptyQuery()) {
          scope.users.reset()
          $timeout(() => {
            gridApi.infiniteScroll.resetScroll()
            loadFirstPage()
          })
        } else {
          searchByTerm()
        }
      }
    }
  }
})
