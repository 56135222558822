/* global angular */

angular.module('smartvid').service('systemApi', function ($http, $q, $log, config, currentUser) {
  let rootUrl = config.env.development.apiRootUrl

  let api = {
    getCountries () {
      let url = `${rootUrl}/api/country`
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('getCountries', response.data)
        currentUser.update(response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getCountryStates (countryCode) {
      let url = `${rootUrl}/api/country/${countryCode}/state`
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('getCountryStates', response.data)
        currentUser.update(response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getSystemData () {
      let url = `${rootUrl}/api/system`
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('getSystemData', response.data)
        currentUser.update(response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    }
  }
  return api
})
