import { FieldDataType } from '../../modules/observations/models/field-data-type.enum'
export enum ObservationPredefinedFields {
  OBSERVER_USER_ID = 'observerUserId',
  DATE_CREATED = 'dateCreated',
  STATUS = 'status',
  TRADE_PARTNER_ID = 'tradePartnerId',
  IDENTIFICATION_METHOD = 'identificationMethod',
  HAZARD_CATEGORY = 'hazardCategory',
  NOTES = 'notes',
  SEVERITY = 'severity',
  FREQUENCY = 'frequency',
  RISK = 'risk',
  ASSIGNED_USER_ID = 'assignedUserId',
  RECOMMENDED_ACTION = 'recommendedAction',
  DUE_DATE = 'dueDate',
  TYPE = 'type',
  IS_GREAT_CATCH = 'isGreatCatch',
  ASSETS = 'assets',
  ACTION_TAKEN = 'actionTaken',
  REVIEW_COMMENT = 'reviewComment',
  RISK_TYPE = 'observationRiskType',
}

export enum UiConfigurationFieldType {
  SELECT = 'SELECT',
  INPUT = 'INPUT',
  TEXT_AREA = 'TEXT_AREA',
  RADIO_BUTTON = 'RADIO_BUTTON',
  CHECKBOX = 'CHECKBOX',
  HEADER = 'HEADER',
  DATE_INPUT = 'DATE_INPUT',
  DATE_DISPLAY = 'DATE_DISPLAY',
  CUSTOM_ASSET_LIST_VIEW = 'CUSTOM_ASSET_LIST_VIEW',
}

export class UiConfigurationListFieldData {
  public value: string
  public label: string
}

export class UiConfigurationFieldInfo {
  public name: string
  public label: string
  public listData: UiConfigurationListFieldData[]
  public listFieldDataProviderBean: string
  public isRequired: boolean
  public isReadOnly: boolean
  public isHidden: boolean
  public type: UiConfigurationFieldType
  public fieldDataType: FieldDataType
}

export enum UiConfigurationObjectType {
  OBSERVATION_DETAILS,
  PTP_DETAILS,
}

export class UiConfigurationData {
  private _fieldMap: Map<string, UiConfigurationFieldInfo>
  public predefinedFields: UiConfigurationFieldInfo[]
  public version: string
  public customFields: UiConfigurationFieldInfo[]

  constructor(uiConfigurationData: UiConfigurationData) {
    this.version = uiConfigurationData.version
    this.predefinedFields = uiConfigurationData.predefinedFields
    this.customFields = uiConfigurationData.customFields
    this.init()
  }

  getField(name: string): UiConfigurationFieldInfo {
    if (!this._fieldMap) {
      this.init()
    }
    return this._fieldMap.get(name)
  }

  getFieldListData(name: string): UiConfigurationListFieldData[] {
    return this.getField(name).listData
  }

  getFieldListDataLabel(name: string, value: string) {
    if (!value) {
      return undefined
    }
    let field: UiConfigurationListFieldData = this.getFieldListData(name).find(data => {
      return data.value === value
    })
    if (!field) {
      return undefined
    }
    return field.label
  }

  init() {
    this._fieldMap = new Map()
    for (let field of this.predefinedFields) {
      this._fieldMap.set(field.name, field)
    }
    for (let field of this.customFields) {
      this._fieldMap.set(field.name, field)
    }
  }
}

export class UiConfiguration {
  public organizationId: string
  public objectType: UiConfigurationObjectType
  public configurationVersion: string
  public uiConfigurationData: UiConfigurationData
  public config: UiConfiguration

  static fromBackend(backendConfig: UiConfiguration) {
    let config = new UiConfiguration()
    config.organizationId = backendConfig.organizationId
    config.objectType = backendConfig.objectType
    config.configurationVersion = backendConfig.configurationVersion
    config.uiConfigurationData = new UiConfigurationData(backendConfig.uiConfigurationData)
    return config
  }
}
