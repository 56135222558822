/* global angular, analytics */

angular.module('smartvid').controller('CommentOptionsCtrl', function ($rootScope, $scope, $window, smartvidApi, modal, currentUser) {
  const WIDTH_OF_FLYOUT = 165
  const TOP_OFFSET = 15

  $scope.shareComment = () => {
    $scope.modal.open('shareAsset', {
      asset: $scope.asset,
      comment: $scope.comment,
      currentProject: $scope.currentProject
    })
    $scope.flyout.close()
  }

  $scope.deleteComment = (comment) => {
    $scope.flyout.isOpen = false
    let project = $scope.currentProject
    modal.open('deleteConfirm', {
      confirm () {
        let projectOrg = currentUser.getOrganization(project.organizationId)
        analytics.track('Delete Comment', {
          category: 'Comment Action',
          assetName: $scope.asset.name,
          projectName: project.name,
          orgName: projectOrg ? projectOrg.name : undefined
        })
        smartvidApi.deleteCommentForAsset(comment.id).then(() => {
          $scope.comments.removeById(comment.id)
          $scope.asset.commentsCount--
          $rootScope.$broadcast('sv-comment-removed', comment)
        })
        $scope.flyout.close()
      },
      hasSelections: true
    })
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top - TOP_OFFSET,
      left: $scope.styles.left - WIDTH_OF_FLYOUT
    }
  }
})
