/* global angular */

angular.module('smartvid').factory('ForgeProjectCollection', function (BaseCollection, ForgeProjectModel) {
  class ForgeProjectCollection extends BaseCollection {
    constructor (models) {
      super(models, ForgeProjectModel)
    }
  }

  return ForgeProjectCollection
})
