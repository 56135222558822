/* global angular, _ */
angular.module('smartvid').service('reportStateService', function (
  $window, $log, ReportName
) {
  // increment any time report state model changes
  const REPORT_STATE_VERSION = 'v4'
  const SRA_REPORT_DEFAULTS = {
    selectedProjectIds: [],
    selectedSafetyTagNames: [],
    selectedConfidenceLevelValue: 'HIGH',
    selectedToUserEmails: [],
    isConstructionRelatedOnly: true,
    dateRange: ''
  }

  const PHOTO_REPORT_DEFAULTS = {
    projectId: null,
    isConstructionRelatedOnly: true,
    selectedConfidenceLevelValue: 'HIGH',
    selectedUsers: [],
    dateRange: '',
    endDate: undefined,
    selectedTagIds: [],
    photoReportSubType: 'SINGLE_IMAGE',
    isIncludeAllTags: false,
    description: ''
  }

  const FILES_INFO_REPORT_DEFAULTS = {
    projectId: null,
    isConstructionRelatedOnly: false,
    selectedConfidenceLevelValue: 'HIGH',
    dateRange: '',
    addedTags: [],
    includeAllProjects: null,
    searchStartDate: null,
    searchEndDate: null
  }

  this.upsertReportStateForUser = upsertReportStateForUser
  this.getReportStateForUser = getReportStateForUser
  this.getDefaultsFromReportName = getDefaultsFromReportName

  function upsertReportStateForUser (reportName, userId, reportState) {
    let state = _.defaults(reportState || {}, getDefaultsFromReportName(reportName))
    $window.localStorage.setItem(_getKey(reportName, userId), JSON.stringify(state))
  }

  function getReportStateForUser (reportName, userId) {
    let storedState = $window.localStorage.getItem(_getKey(reportName, userId))
    if (storedState && storedState !== 'undefined') {
      try {
        storedState = JSON.parse(storedState)
      } catch (e) {
        $log.error(e)
        storedState = {}
      }
    }
    let state = _.defaults(storedState || {}, getDefaultsFromReportName(reportName))
    return state
  }

  function getDefaultsFromReportName (reportName) {
    let settings
    switch (reportName) {
      case ReportName.SAFETY_SUMMARY_REPORT:
        settings = SRA_REPORT_DEFAULTS
        break
      case ReportName.PHOTO_REPORT:
        settings = PHOTO_REPORT_DEFAULTS
        break
      case ReportName.FILES_INFO_REPORT:
        settings = FILES_INFO_REPORT_DEFAULTS
        break
      default:
        PHOTO_REPORT_DEFAULTS
        break
    }
    return settings
  }

  function _getKey (reportName, userId) {
    return `reportState:${reportName}:${REPORT_STATE_VERSION}:${userId}`
  }
})
