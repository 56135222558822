import { Component, Inject, Input, OnInit } from '@angular/core'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import { MatTabChangeEvent } from '@angular/material/tabs'
import { DashboardType } from 'modules/insights/dashboard/models/insights-dashboard.model'
import { Organization } from 'modules/admin/models/organization.model'

@Component({
  selector: 'sv-organization-assets-home',
  templateUrl: 'organization-assets-home.component.html',
  styleUrls: ['organization-assets-home.component.scss'],
})
export class OrganizationAssetsHomeComponent implements OnInit {
  private static INSIGHTS_TAB_INDEX = 0

  @Input() hasInsightsTab: boolean
  @Input() organization: Organization

  selectedTab = OrganizationAssetsHomeComponent.INSIGHTS_TAB_INDEX
  organizationId: string

  constructor(
    @Inject('contentSharingContext') public contentSharingContext: any,
    @Inject('searchResultService') public searchResultService: any,
    private insightsDataHelper: InsightsDataHelper
  ) {}

  ngOnInit(): void {
    this.organizationId = this.organization.id
    const selectedTabIndex = this.insightsDataHelper.getInsightsTabSelectedTab(
      DashboardType.ORGANIZATION_ASSETS_DASHBOARD
    )
    this.selectedTab = this.hasInsightsTabView() ? selectedTabIndex : selectedTabIndex - 1
  }

  hasInsightsTabView(): boolean {
    return this.hasInsightsTab && !this.hasStandaloneVideoGrid()
  }

  hasStandaloneVideoGrid(): boolean {
    return this.searchResultService.isInGlobalSearchContext() || this.contentSharingContext.isGlobalSharingContext()
  }

  selectedTabChange(event: MatTabChangeEvent) {
    const selectedTabIndex = this.hasInsightsTabView() ? event.index : event.index + 1
    this.insightsDataHelper.setInsightsSelectedTab(DashboardType.ORGANIZATION_ASSETS_DASHBOARD, selectedTabIndex)
  }
}
