/* global angular,analytics,_,$ */

angular.module('smartvid').controller('RemoveProjectGroupCtrl', function ($scope, dashboardDataHelper, modal, organizationsApi) {

  $scope.removeProjectGroup = removeProjectGroup

  $scope.projectGroup = dashboardDataHelper.getProjectGroup($scope.orgId, $scope.projectGroupId)

  function removeProjectGroup () {
    organizationsApi.removeProjectGroupFromOrganization($scope.orgId, $scope.projectGroupId).then((org) => {
      modal.close()
    })
  }
})
