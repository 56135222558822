import * as _ from 'lodash'
import {
  ElementRef,
  ViewChild,
  Input,
  OnDestroy,
  OnInit,
  Directive,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from '@angular/core'
import { GoogleChartInterface } from 'ng2-google-charts'
import ResizeObserver from 'resize-observer-polyfill'

export interface ChartData {
  options?: any
  dataTable: any
}
@Directive()
export abstract class BaseChartDirective implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() chart: GoogleChartInterface
  @Input() isChartReady = false
  @Input() isResponsive = true
  @Input() spinnerDiameter = 32
  @Input() debugId: string
  @Input() chartData: ChartData

  @ViewChild('chartContainer', { static: false }) elRef: ElementRef

  private containerResizeObserver: ResizeObserver

  ngOnInit() {
    this.containerResizeObserver = new ResizeObserver(entries => {
      const { width, height } = entries[0].contentRect
      this.resizeChart(width, height)
    })
  }

  ngAfterViewInit() {
    this.containerResizeObserver.observe(this.elRef.nativeElement)
    this.initWithChartData()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentChartData: SimpleChange = changes.chartData
    if (currentChartData && this.elRef) {
      this.initWithChartData()
    }
  }

  ngOnDestroy() {
    if (this.containerResizeObserver) {
      this.containerResizeObserver.unobserve(this.elRef.nativeElement)
      this.containerResizeObserver.disconnect()
      this.containerResizeObserver = undefined
    }
    this.cleanupChartComponent()
  }

  private initWithChartData() {
    if (this.chartData && this.chartData.dataTable.length > 0) {
      this.preprocessChartData(this.chartData)
      this.setupChart(this.elRef.nativeElement.clientWidth, this.elRef.nativeElement.clientHeight)
    } else if (this.chartData && this.chartData.dataTable.length === 0) {
      this.isChartReady = true
    } else if (!this.chartData) {
      this.cleanupChartComponent()
    }
  }

  spinnerTopStyle(): string {
    return `calc(50% - ${this.spinnerDiameter / 2}px)`
  }

  public onChartReady() {
    this.isChartReady = true
    this.unregisterEvents()
  }

  abstract getChartType(): string

  abstract getPredefinedChartOptions(): {}

  /*eslint-disable*/
  preprocessChartData(chartData: ChartData) {}
  /*eslint-enable*/

  private unregisterEvents() {
    if (this.chart && this.chart.component && (this.chart.component as any).unregisterEvents) {
      ;(this.chart.component as any).unregisterEvents()
    }
  }

  private cleanupChartComponent() {
    if (this.chart) {
      this.chart.dataTable = undefined
    }

    if (this.chart && this.chart.component && this.chart.component.wrapper) {
      if ((this.chart.component as any).unregisterEvents) {
        try {
          ;(this.chart.component as any).unregisterEvents()
        } catch (e) {
          console.warn("Unable to unregister Google Chart wrapper component's events", e)
        }
      }

      if (this.chart.component.wrapper.visualization) {
        try {
          this.chart.component.wrapper.visualization.clearChart()
        } catch (e) {
          console.warn('Unable to clear Google Chart', e)
        }
      }

      this.chart.component.wrapper.clear()
      this.chart.component.wrapper = undefined
      this.chart.component = undefined
    } else if (this.chart && this.chart.component) {
      if ((this.chart.component as any).unregisterEvents) {
        try {
          ;(this.chart.component as any).unregisterEvents()
        } catch (e) {
          console.warn("Unable to unregister Google Chart wrapper component's events", e)
        }
      }

      this.chart.component = undefined
    }

    this.chart = undefined
  }

  private setupChart(width: number, height: number): void {
    if (!this.chartData || this.chartData.dataTable.length === 0) {
      return
    }

    this.cleanupChartComponent()

    this.chart = {
      chartType: this.getChartType(),
      dataTable: _.cloneDeep(this.chartData.dataTable),
      options: _.merge(
        {
          width: width,
          height: height,
        },
        _.cloneDeep(this.getPredefinedChartOptions()),
        _.cloneDeep(this.chartData.options)
      ),
    }
  }

  private resizeChart(width: number, height: number): void {
    if (!this.isResponsive || !this.chart || !this.chart.component || !this.chart.component.wrapper) {
      return
    }

    this.chart.component.wrapper.setOptions({
      ...this.chart.component.wrapper.getOptions(),
      height: height,
      width: width,
    })
    this.chart.component.wrapper.draw()
  }
}
