/* global angular, sprintf */

angular.module('smartvid').controller('WelcomeCtrl', function ($scope, i18nUtils, $state, $stateParams, $filter) {
  $scope.closeCongratulations = () => {
    $state.go('login', $stateParams, {
      reload: true
    })
  }
  if ($stateParams.organizationName) {
    i18nUtils.success(() => {
      return sprintf($filter('i18next')('welcome.request_sent'), $stateParams.organizationName)
    })
  }
})
