/* global angular,analytics */

angular.module('smartvid').directive('myProfile', function (currentUser, smartvidApi, Notification, passwordService, $filter, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'myprofile.html',
    link: function (scope) {
      scope.inTransit = false
      scope.myFirstName = currentUser.get('firstName')
      scope.myLastName = currentUser.get('lastName')
      scope.myPrimaryEmail = currentUser.get('email')
      scope.originalEmail = currentUser.get('email')
      scope.isValidPassword = true
      scope.passwordError = ''
      scope.displayPasswordGuidelines = false
      scope.isSaml = currentUser.isSaml

      let update = () => {
        let promise = smartvidApi.updateCurrentUser(scope.myFirstName, scope.myLastName)
        promise.then((data) => {
          Notification.success($filter('i18next')('directives.myprofile.successProfile'))
        }, () => {
          Notification.error($filter('i18next')('directives.myprofile.failedProfile'))
        })
      }

      let onAuthSuccess = (response) => {
        update()
      }

      let onAuthFailure = (response) => {
        scope.isValidPassword = false
        setError($filter('i18next')('validation.authenticationFailed'))
      }

      let setError = (errorMessage) => {
        scope.isValidPassword = false
        scope.displayPasswordGuidelines = false
        scope.invalidPasswordMessage = errorMessage
        Notification.error(errorMessage)
      }

      let validateNewPassword = (oldPassword, newPassword, passwordConfirmation) => {
        if (newPassword !== passwordConfirmation) {
          setError($filter('i18next')('validation.passwordsDontMatch'))
          return false
        }
        if (oldPassword === newPassword) {
          setError($filter('i18next')('validation.passwordTheSame'))
          return false
        }
        if (!passwordService.valid(newPassword)) {
          setError($filter('i18next')('validation.passwordInvalid'))
          scope.displayPasswordGuidelines = true
          return false
        }
        scope.isValidPassword = true
        scope.displayPasswordGuidelines = false
        scope.invalidPasswordMessage = ''
        return true
      }

      scope.updateProfile = () => {
        analytics.track('Update User Profile', {
          category: 'User Action'
        })
        scope.inTransit = true
        if (scope.oldPassword || scope.password || scope.passwordConfirmation) {
          if (validateNewPassword(scope.oldPassword, scope.password, scope.passwordConfirmation)) {
            analytics.track('Change Password', {
              category: 'User Action'
            })
            smartvidApi.updatePassword(scope.oldPassword, scope.password).then(onAuthSuccess, onAuthFailure)
          }
        } else {
          update()
        }
        $timeout(function () {
          scope.inTransit = false
        }, 200)
      }
    }
  }
})
