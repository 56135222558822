/* global angular $ _ moment */

import { ModuleType } from 'shared/models/module-type'

angular.module('smartvid').controller('RunPhotoReportCtrl', function (
  $scope, $element, $q, $timeout, $filter, moment, smartvidApi, dashboardDataHelper, currentUser, reportStateService,
  modal, TagDefModel, ReportName, TagNodesCollection, uiGridConstants
) {
  const REPORT_GENERATION_RECIPE_PDF = 'PDF'
  const TAG_CONFIDENCE_LEVELS = [
    {
      prettyName: $filter('i18next')('reporting.confidence.high'),
      value: 'HIGH'
    },
    {
      prettyName: $filter('i18next')('reporting.confidence.medium'),
      value: 'MEDIUM'
    },
    {
      prettyName: $filter('i18next')('reporting.confidence.low'),
      value: 'LOW'
    }
  ]

  const LAST_180_DAYS = {
    prettyName: $filter('i18next')('reporting.dateRange.6_months'),
    value: '180_DAYS'
  }

  const LAST_90_DAYS = {
    prettyName: $filter('i18next')('reporting.dateRange.3_months'),
    value: '90_DAYS'
  }

  const LAST_30_DAYS = {
    prettyName: $filter('i18next')('reporting.dateRange.1_month'),
    value: '30_DAYS'
  }

  const LAST_7_DAYS = {
    prettyName: $filter('i18next')('reporting.dateRange.1_week'),
    value: '7_DAYS'
  }

  const LAST_1_DAY = {
    prettyName: $filter('i18next')('reporting.dateRange.1_day'),
    value: '1_DAY'
  }

  const MONDAY = {
    type: 'MONDAY',
    displayName: $filter('i18next')('reporting.weekDayPicker.weekDays.monday')
  }

  const TUESDAY = {
    type: 'TUESDAY',
    displayName: $filter('i18next')('reporting.weekDayPicker.weekDays.tuesday')
  }

  const WEDNESDAY = {
    type: 'WEDNESDAY',
    displayName: $filter('i18next')('reporting.weekDayPicker.weekDays.wednesday')
  }

  const THURSDAY = {
    type: 'THURSDAY',
    displayName: $filter('i18next')('reporting.weekDayPicker.weekDays.thursday')
  }

  const FRIDAY = {
    type: 'FRIDAY',
    displayName: $filter('i18next')('reporting.weekDayPicker.weekDays.friday')
  }

  const SATURDAY = {
    type: 'SATURDAY',
    displayName: $filter('i18next')('reporting.weekDayPicker.weekDays.saturday')
  }

  const SUNDAY = {
    type: 'SUNDAY',
    displayName: $filter('i18next')('reporting.weekDayPicker.weekDays.sunday')
  }

  const WEEK_DAYS = [
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY
  ]

  const DATE_RANGES = [
    LAST_180_DAYS,
    LAST_90_DAYS,
    LAST_30_DAYS,
    LAST_7_DAYS,
    LAST_1_DAY
  ]

  const SCHEDULE_NONE = {
    type: 'NEVER',
    displayName: $filter('i18next')('reporting.schedule.never'),
    hint: ''
  }
  const SCHEDULE_DAILY = {
    type: 'DAILY',
    displayName: $filter('i18next')('reporting.schedule.runDaily'),
    hint: $filter('i18next')('reporting.schedule.runDailyHint')
  }
  const SCHEDULE_WEEKLY = {
    type: 'WEEKLY',
    displayName: $filter('i18next')('reporting.schedule.runWeekly'),
    hint: $filter('i18next')('reporting.schedule.runWeeklyHint')
  }
  const SCHEDULE_MONTHLY = {
    type: 'MONTHLY',
    displayName: $filter('i18next')('reporting.schedule.runMonthly'),
    hint: $filter('i18next')('reporting.schedule.runMonthlyHint')
  }
  const SCHEDULE_TYPES = [
    SCHEDULE_NONE,
    SCHEDULE_DAILY,
    SCHEDULE_WEEKLY,
    SCHEDULE_MONTHLY
  ]

  const NONE = {
    type: 'NONE',
    displayName: $filter('i18next')('reporting.grouping.none')
  }

  const TAGS_FEWEST_FIRST = {
    type: 'TAGS_FEWEST_FIRST',
    displayName: $filter('i18next')('reporting.grouping.tagsFewestFirst')
  }

  const TAGS_MOST_FIRST = {
    type: 'TAGS_MOST_FIRST',
    displayName: $filter('i18next')('reporting.grouping.tagMostFirst')
  }

  const GROUPING_TAG_NAME_A_Z = {
    type: 'GROUPING_TAG_NAME_A_Z',
    displayName: $filter('i18next')('reporting.grouping.tagNameAz')
  }

  const GROUPING_TAG_NAME_Z_A = {
    type: 'GROUPING_TAG_NAME_Z_A',
    displayName: $filter('i18next')('reporting.grouping.tagNameZa')
  }

  const GROUPING_TYPES = [
    NONE,
    TAGS_FEWEST_FIRST,
    TAGS_MOST_FIRST,
    GROUPING_TAG_NAME_A_Z,
    GROUPING_TAG_NAME_Z_A
  ]

  const TAG_NAME_A_Z = {
    type: 'TAG_NAME_A_Z',
    displayName: $filter('i18next')('reporting.sorting.tagNameAz')
  }

  const TAG_NAME_Z_A = {
    type: 'TAG_NAME_Z_A',
    displayName: $filter('i18next')('reporting.sorting.tagNameZa')
  }

  const PHOTO_DATE_EARLIEST_FIRST = {
    type: 'PHOTO_DATE_EARLIEST_FIRST',
    displayName: $filter('i18next')('reporting.sorting.photoDateEarliestFirst')
  }

  const PHOTO_DATE_LATEST_FIRST = {
    type: 'PHOTO_DATE_LATEST_FIRST',
    displayName: $filter('i18next')('reporting.sorting.photoDateLatestFirst')
  }

  const SORTING_TYPES = [
    TAG_NAME_A_Z,
    TAG_NAME_Z_A,
    PHOTO_DATE_EARLIEST_FIRST,
    PHOTO_DATE_LATEST_FIRST
  ]

  let allProjects = dashboardDataHelper.getAllProjects()

  $scope.projectOptions = _(allProjects.models)
    .filter((project) => project.canRunReports)
    .sortBy((proj) => safe(proj.name))
    .value()
  $scope.isSchedulingEnabled = true
  $scope.moreThan10ErrorMessage = $filter('i18next')('reporting.moreThan10ErrorMessage')
  $scope.selectedMoreThanMax = false
  $scope.allConfidenceLevels = TAG_CONFIDENCE_LEVELS
  $scope.selectedConfidenceLevel = TAG_CONFIDENCE_LEVELS[0]
  $scope.isFetchingSystemData = true
  $scope.reportSafetyTagsModel = {}
  $scope.step = 1
  $scope.selectedProject = null
  $scope.selectedUsers = []
  $scope.dateRanges = DATE_RANGES
  $scope.dateRange = {range: DATE_RANGES[3]}
  $scope.scheduleTypes = SCHEDULE_TYPES
  $scope.schedule = {selected: SCHEDULE_NONE}
  $scope.datePicker = {date: null}
  $scope.datePickerOptions = getDatePickerOptions()
  $scope.constructionRelatedOnly = {value: true}
  $scope.photoReportSubType = {value: 'SINGLE_IMAGE'}
  $scope.addedTags = []
  $scope.ignoredTagTexts = []
  $scope.includeAllTags = { value: false}
  $scope.description = ''
  $scope.reportTitle = ''
  $scope.editMode = !!$scope.data.report
  $scope.isReportView = !!$scope.data.isReportView
  $scope.sortingTypes = SORTING_TYPES
  $scope.sortingType = {selected: PHOTO_DATE_LATEST_FIRST}
  $scope.groupingTypes = GROUPING_TYPES
  $scope.groupingType = {selected: TAGS_MOST_FIRST}
  $scope.reportId = null
  $scope.dropDownVisible = false
  $scope.selectedTag = null
  $scope.tagTree = null
   $scope.tagTreeDropdownGridOptions = {
    rowTemplate: 'TagManager/tree-node-for-dropdown.html',
    multiSelect: false,
    modifierKeysToMultiSelect: false,
    noUnselect: false,
    rowSelection: true,
    enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS
  }
  $scope.monthDays = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
  $scope.dayOfMonth = {selected: 1}
  $scope.weekDays =  WEEK_DAYS
  $scope.dayOfWeek = {selected: MONDAY}
  $scope.dayTimes = [
      '00:00', '00:15', '00:30', '00:45',
      '01:00', '01:15', '01:30', '01:45',
      '02:00', '02:15', '02:30', '02:45',
      '03:00', '03:15', '03:30', '03:45',
      '04:00', '04:15', '04:30', '04:45',
      '05:00', '05:15', '05:30', '05:45',
      '06:00', '06:15', '06:30', '06:45',
      '07:00', '07:15', '07:30', '07:45',
      '08:00', '08:15', '08:30', '08:45',
      '09:00', '09:15', '09:30', '09:45',
      '10:00', '10:15', '10:30', '10:45',
      '11:00', '11:15', '11:30', '11:45',
      '12:00', '12:15', '12:30', '12:45',
      '13:00', '13:15', '13:30', '13:45',
      '14:00', '14:15', '14:30', '14:45',
      '15:00', '15:15', '15:30', '15:45',
      '16:00', '16:15', '16:30', '16:45',
      '17:00', '17:15', '17:30', '17:45',
      '18:00', '18:15', '18:30', '18:45',
      '19:00', '19:15', '19:30', '19:45',
      '20:00', '20:15', '20:30', '20:45',
      '21:00', '21:15', '21:30', '21:45',
      '22:00', '22:15', '22:30', '22:45',
      '23:00', '23:15', '23:30', '23:45'
  ]
  $scope.dayTime = {selected: '00:00'}

  $scope.selectProject = selectProject
  $scope.hasSelectedTags = hasSelectedTags
  $scope.nextStep = nextStep
  $scope.closeModal = closeModal
  $scope.previousStep = previousStep
  $scope.searchByEmail = searchByEmail
  $scope.getAllUsers = getAllUsers
  $scope.submit = submit
  $scope.handleSelectedTag = handleSelectedTag
  $scope.removeTag = removeTag
  $scope.handleDownArrow = handleDownArrow
  $scope.searchProjectsByPartialText = searchProjectsByPartialText

  init()

  function init () {
    let lastReportState = $scope.data.report ? fromPhotoReportResponse($scope.data.report) : getLastReportStateForUser(currentUser.id)

    _.forEach(lastReportState.selectedTagIds || [], (id) => {
      smartvidApi.getTagWithAlias(id).then((tag) => {
        $scope.addedTags.push(tag)
        $scope.ignoredTagTexts.push(tag.text)
      })
    })

    $scope.selectedProject = _(allProjects.models)
      .filter((project) => _.contains(lastReportState.projectId, project.id)).first()

    $scope.selectedConfidenceLevel = lastReportState.selectedConfidenceLevelValue
      ? _.findWhere(TAG_CONFIDENCE_LEVELS, {value: lastReportState.selectedConfidenceLevelValue})
      : $scope.selectedConfidenceLevel

    $scope.constructionRelatedOnly.value = lastReportState.isConstructionRelatedOnly
    $scope.photoReportSubType.value = lastReportState.photoReportSubType ? lastReportState.photoReportSubType : $scope.photoReportSubType.value
    $scope.includeAllTags.value = lastReportState.isIncludeAllTags

    $scope.dateRange.range = lastReportState.dateRange
      ? _.findWhere(DATE_RANGES, {value: lastReportState.dateRange})
      : $scope.dateRange.range

    _.forEach(lastReportState.selectedUsers || [], (u) => $scope.selectedUsers.push(u))

    if (_.isEmpty($scope.selectedUsers)) {
      $scope.selectedUsers.push(currentUser)
    }

    if (lastReportState.endDate) {
      $timeout(() => {
        $scope.datePicker.date = moment(lastReportState.endDate)
        let dateRangePickerControl = $('#date-picker').data('daterangepicker')
        dateRangePickerControl.setStartDate($scope.datePicker.date)
        dateRangePickerControl.setEndDate($scope.datePicker.date)
      }, 50)
    }

    $scope.schedule.selected = lastReportState.reportFixedScheduleInterval
      ? _.findWhere(SCHEDULE_TYPES, {type: lastReportState.reportFixedScheduleInterval})
      : $scope.schedule.selected

    $scope.description = lastReportState.description ? lastReportState.description : $scope.description
    $scope.reportTitle = lastReportState.reportTitle ? lastReportState.reportTitle : $scope.reportTitle
    $scope.sortingType.selected = lastReportState.sortingType ? _.findWhere(SORTING_TYPES, {type: lastReportState.sortingType}) : $scope.sortingType.selected
    $scope.groupingType.selected = lastReportState.groupingType ? _.findWhere(GROUPING_TYPES, {type: lastReportState.groupingType}) : $scope.groupingType.selected
    $scope.dayOfMonth.selected = lastReportState.dayOfMonth ? lastReportState.dayOfMonth : 1
    $scope.dayOfWeek.selected = lastReportState.dayOfWeek ? _.find(WEEK_DAYS, {type: lastReportState.dayOfWeek}) : MONDAY
    if (!isNaN(lastReportState.timeHours) && !isNaN(lastReportState.timeMinutes)) {
      let timeHours = lastReportState.timeHours < 10 ? '0' + lastReportState.timeHours : lastReportState.timeHours
      let timeMinutes = lastReportState.timeMinutes === 0 ? '0' + lastReportState.timeMinutes : lastReportState.timeMinutes
      $scope.dayTime.selected = timeHours + ':' + timeMinutes
    }
  }

  function nextStep () {
    $scope.step++
  }

  function previousStep () {
    $scope.step--
  }

  function closeModal () {
    modal.close()
  }

  function handleSelectedTag (tag) {
    // never allow blank tag
    if (!tag || _.trim(tag).length === 0) {
      return
    }

    if (!(tag instanceof TagDefModel)) {
      return
    }
    if (_.find($scope.addedTags,
      (existingTag) => {
        return tag.id === existingTag.id || tag.text.toUpperCase() === existingTag.text.toUpperCase()
      })) {
      return
    }
    tag.isNew = false
    $scope.addedTags.push(tag)
    $scope.ignoredTagTexts.push(tag.text)
  }

  function removeTag (tag) {
    $scope.addedTags.splice($scope.addedTags.indexOf(tag), 1)
    _.pull($scope.ignoredTagTexts, tag.text)
  }

  function getLastReportStateForUser (userId) {
    return reportStateService.getReportStateForUser(ReportName.PHOTO_REPORT, userId)
  }

  function hasSelectedTags () {
    return _($scope.addedTags).values().any()
  }

  function safe (str) {
    return (str || '').toUpperCase()
  }

  function searchByEmail (partialEmail) {
    let promises = []

    let orgIds = _.pluck(_.where(currentUser.organizations, {canManageUsers: true}), 'id')
    _.each(orgIds, (orgId) => {
      promises.push(smartvidApi.searchOrgUsersByEmailForModule(orgId,
        [],
        partialEmail,
        ModuleType.ASSETS,
        {
          page: 0,
          pageSize: 50,
          sortColumn: ['email:ASC'],
        }))
    })
    return $q.all(promises)
  }

  function getAllUsers () {
    let promises = []
    _.each(currentUser.organizations, (org) => {
      promises.push(smartvidApi.getOrganizationUsers(org.id,
      {
        sortColumn: ['email:ASC'],
      }))
    })
    return $q.all(promises)
  }

  function toLocalStorageItemFromRequest (request, dateRangeValue, tagDefIds, selectedUsers) {
    let ret = {}
    ret.projectId = request.projectId
    ret.selectedTagIds = tagDefIds
    ret.selectedConfidenceLevelValue = request.tagConfidenceLevel
    ret.selectedUsers = _.map(selectedUsers, (u) => {
      return {
        email: u.email,
        id: u.id,
        firstName: u.firstName,
        lastName: u.lastName,
        freeText: u.freeText
      }
    })
    ret.isConstructionRelatedOnly = request.isConstructionRelatedOnly
    ret.photoReportSubType = request.photoReportSubType
    ret.dateRange = dateRangeValue
    ret.isIncludeAllTags = request.isIncludeAllTags
    ret.sortingType = request.sortingType
    ret.groupingType = request.groupingType
    ret.dayOfMonth = request.dayOfMonth
    ret.dayOfWeek = request.dayOfWeek
    ret.timeHours = request.timeHours
    ret.timeMinutes = request.timeMinutes
    ret.reportFixedScheduleInterval = request.reportFixedScheduleInterval
    return ret
  }

  function fromPhotoReportResponse (report) {
    let ret = {}
    ret.projectId = report.projectId
    ret.selectedTagIds = report.tagDefIds
    ret.selectedConfidenceLevelValue = report.tagConfidenceLevel
    ret.isConstructionRelatedOnly = report.isConstructionRelatedOnly
    ret.photoReportSubType = report.photoReportSubType
    ret.endDate = report.endDate
    ret.isIncludeAllTags = report.isIncludeAllTags
    ret.dateRange = getDateRage(report.endDate, report.startDate)
    ret.description = report.description
    ret.reportTitle = report.reportTitle
    ret.reportFixedScheduleInterval = report.reportFixedScheduleInterval
    ret.sortingType = report.sortingType
    ret.groupingType = report.groupingType
    ret.selectedUsers = report.selectedUsers
    ret.dayOfMonth = report.dayOfMonth
    ret.dayOfWeek = report.dayOfWeek
    ret.timeHours = report.timeHours
    ret.timeMinutes = report.timeMinutes
    return ret
  }

  function submit () {
    if ($scope.selectedUsers.length < 1 || $scope.addedTags.length < 1) return
    let requestObject = {}
    requestObject.projectId = $scope.selectedProject.id
    requestObject.isConstructionRelatedOnly = $scope.constructionRelatedOnly.value
    requestObject.photoReportSubType = $scope.photoReportSubType.value
    requestObject.tagConfidenceLevel = $scope.selectedConfidenceLevel.value
    requestObject.includedTags = _.pluck($scope.addedTags, 'text')
    requestObject.toUserEmails = _.map($scope.selectedUsers,
      (user) => defaultString(user.freeText, '') + ': ' + defaultString(user.firstName, '') + ' ' + defaultString(user.lastName, '') + ' <' + defaultString(user.email, '') + '>')
    requestObject.startDate = getStartDate($scope.datePicker.date, $scope.dateRange.range).valueOf()
    requestObject.endDate = $scope.datePicker.date.endOf('day').valueOf()
    requestObject.recipeType = REPORT_GENERATION_RECIPE_PDF
    requestObject.reportFixedScheduleInterval = $scope.schedule.selected.type
    requestObject.isIncludeAllTags = $scope.includeAllTags.value
    requestObject.description = $scope.description
    requestObject.reportTitle = $scope.reportTitle
    requestObject.createdDate = moment().toDate().valueOf()
    requestObject.sortingType = $scope.sortingType.selected.type
    requestObject.groupingType = $scope.groupingType.selected.type
    requestObject.dayOfMonth = $scope.dayOfMonth.selected
    requestObject.dayOfWeek = $scope.dayOfWeek.selected.type
    requestObject.timeHours = parseInt($scope.dayTime.selected.split(':')[0], 10)
    requestObject.timeMinutes = parseInt($scope.dayTime.selected.split(':')[1], 10)
    let tagDefIds = _.pluck($scope.addedTags, 'id')

    $scope.generatingReport = true

    if ($scope.editMode) {
      requestObject.reportId = $scope.data.report.reportId
      smartvidApi.updatePhotoReport(requestObject).then(() => {
        $scope.generatingReport = false
        $scope.step++
        reportStateService.upsertReportStateForUser(ReportName.PHOTO_REPORT, currentUser.id,
          toLocalStorageItemFromRequest(requestObject, $scope.dateRange.range.value, tagDefIds, $scope.selectedUsers))
        $scope.data.confirm()
      })
    } else {
      smartvidApi.generatePhotoReport(requestObject).then(() => {
        $scope.generatingReport = false
        $scope.step++
        reportStateService.upsertReportStateForUser(ReportName.PHOTO_REPORT, currentUser.id,
          toLocalStorageItemFromRequest(requestObject, $scope.dateRange.range.value, tagDefIds, $scope.selectedUsers))
      })
    }
  }

  function defaultString (str, def) {
    return str || def
  }

  function getDatePickerOptions () {
    return {
      locale: {
        format: 'MM/DD/YYYY'
      },
      singleDatePicker: true,
      maxDate: moment().toDate()
    }
  }

  function getStartDate (endDateMoment, dateRange) {
    let startDateMoment = moment(endDateMoment)
    switch (dateRange.value) {
      case LAST_180_DAYS.value:
        startDateMoment.startOf('day')
        .subtract(180, 'days')
        .add(1, 'day')
        break
      case LAST_90_DAYS.value:
        startDateMoment.startOf('day')
        .subtract(90, 'days')
        .add(1, 'day')
        break
      case LAST_30_DAYS.value:
        startDateMoment.startOf('day')
          .subtract(30, 'days')
          .add(1, 'day')
        break
      case LAST_7_DAYS.value:
        startDateMoment.startOf('day')
          .subtract(7, 'days')
          .add(1, 'day')
        break
      case LAST_1_DAY.value:
        startDateMoment.startOf('day')
        break
      default:
        startDateMoment.startOf('day')
    }

    return startDateMoment
  }

  function getDateRage (endDate, startDate) {
    let diffDays = moment(endDate).diff(moment(startDate), 'days')
    if (diffDays > 1 && diffDays <= 7) {
      return LAST_7_DAYS.value
    } else if (diffDays > 7 && diffDays <= 30) {
      return LAST_30_DAYS.value
    } else if (diffDays > 30 && diffDays <= 90) {
      return LAST_90_DAYS.value
    } else if (diffDays > 90) {
      return LAST_180_DAYS.value
    } else {
       return LAST_1_DAY.value
    }
  }

  $scope.selectTag = (event, node) => {
    const {tagDefinitionId, title} = node
    if (tagDefinitionId === '-- Not Real Id ---' || _.find($scope.addedTags, (tag) => tag.id === tagDefinitionId)) {
      $scope.dropDownVisible = false
      return
    }

    $scope.selectedTag = {id: tagDefinitionId, text: title}
    $scope.dropDownVisible = false
  }

  $scope.isSelectedTag = (event, node) => {
    return !!_.find($scope.addedTags, (tag) => tag.id === node.tagDefinitionId)
  }

  $scope.addTag = () => {
    $scope.addedTags.push($scope.selectedTag)
    $scope.ignoredTagTexts.push($scope.selectedTag.title)
  }

  function selectProject (project) {
     if (project) {
       $scope.selectedProject = project.originalObject
     }
  }

  function handleDownArrow () {
    let angucompleteElement = angular.element('.report-step-page .select-project .angucomplete-holder')
    let angucompleteScope = angucompleteElement.scope()
    if (angucompleteScope.showDropdown) {
      angucompleteScope.showDropdown = false
      return
    }
    let inputField = angucompleteElement.find('input')
    inputField.focus()
    $timeout(() => {
      angucompleteScope.results = _.map(searchProjectsByPartialText(), (project) => {
        return {
          title: project.name,
          description: '',
          image: '',
          originalObject: project
        }
      })
      angucompleteScope.showDropdown = true
    }, 300)
  }

  function searchProjectsByPartialText (partial) {
    function safe (str) {
      return (str || '').toUpperCase()
    }

    let result = _(allProjects.models)
        .filter((project) => project.canRunReports && safe(project.name).indexOf(safe(partial)) !== -1)
        .sortBy((proj) => safe(proj.name))
        .value()
   return result
  }

  $scope.filterFixedScheduleInterval = () => {
    return function (item) {
      return !($scope.editMode === true && $scope.report.reportFixedScheduleInterval !== 'NEVER' && item.type === 'NEVER');
    }
  }

})
