import { DoBootstrap, NgModule } from '@angular/core'
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger'
import { UpgradeModule } from '@angular/upgrade/static'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule, DatePipe } from '@angular/common'

import { SmartvidMaterialModule } from 'modules/material/material.module'

import { FormlyModule } from '@ngx-formly/core'
import { FormlyMaterialModule } from '@ngx-formly/material'
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid'
import { UIRouter, UIRouterModule } from '@uirouter/angular'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { NgPipesModule } from 'ngx-pipes'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core'

import { AngularSvgIconModule } from 'angular-svg-icon'
import { ScrollingModule } from '@angular/cdk/scrolling'

import { SmartvidSvgModule } from 'modules/svg/svg.module'
import { AppCoreModule } from 'modules/core/core.module'
import { AppAdminModule } from 'modules/admin/admin.module'
import { AppStateModule } from 'modules/state/state.module'
import { InsightsDashboardModule } from 'modules/insights/insights-dashboard.module'
import { AppAssetsModule } from 'modules/assets/assets.module'
import { AppIntegrationsModule } from 'modules/integrations/integrations.module'
import { environment } from '../..//environments/environment'
import { MatInputModule } from '@angular/material/input'

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/locales/', '.json')
}

export const observationsFutureState = {
  name: 'dashboard.observations.**',
  url: '/observations',
  loadChildren: () => import('./modules/observations/observations.module').then(m => m.AppObservationsModule),
}

export const internalAdminFutureState = {
  name: 'internalAdmin.**',
  url: '/admin',
  loadChildren: () => import('./modules/internal/internal.module').then(m => m.AppInternalModule),
}

export const analyticsFutureState = {
  name: 'dashboard.analytics.**',
  url: '/analytics',
  loadChildren: () => import('./modules/analytics/analytics.module').then(m => m.AppAnalyticsModule),
}

@NgModule({
  imports: [
    CommonModule,
    UpgradeModule,
    UIRouterUpgradeModule,
    UIRouterModule.forChild({ states: [observationsFutureState, analyticsFutureState, internalAdminFutureState] }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatInputModule,
    HttpClientModule,
    NgPipesModule,
    AngularSvgIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.LOG,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    ScrollingModule,
    SmartvidMaterialModule,
    SmartvidSvgModule,
    AppCoreModule,
    AppAdminModule,
    AppAssetsModule,
    AppStateModule,
    InsightsDashboardModule,
    AppIntegrationsModule,
  ],
  providers: [DatePipe],
})
export class AppModule implements DoBootstrap {
  constructor(private upgrade: UpgradeModule, private translate: TranslateService, private router: UIRouter) {
    translate.setDefaultLang('en')
    translate.use('en')
  }

  ngDoBootstrap() {}
}
