/* global angular _ $ */

angular.module('smartvid').directive('player', function (
  $interval, $stateParams, $document, $sce, $timeout, $rootScope, $window, TagInstanceModel, CommentModel, videoPlayer,
  utils, openSeaDragonService, dziTagMarkupService, normalTagMarkupService, hotkeys, smartvidApi, assetDebugUtils,
  sphericalMarkupService, TAG_MARKUP_OVERLAY_ID_PREFIX, $state
) {
  return {
    restrict: 'E',
    templateUrl: 'player.html',
    scope: {
      asset: '=',
      isMobile: '=',
      isLightViewer: '=?'
    },
    link: function (scope, element) {
      const HEIGHT_OF_HEADER_NAV = 280
      const GENERAL_MARKUP_VERSION_NUMBER = 1
      const VIDEO_MARKUP_TYPE = 'VIDEO'
      const IMAGE_MARKUP_TYPE = 'IMAGE'
      let imageRatio
      let playerLoaded = false
      scope.assetTags = scope.asset.tags

      scope.$on('$destroy', onDestroy)
      scope.$on('sv-download-asset-frame-complete', onDownloadAssetFrameComplete)
      scope.$on('sv-download-asset-frame', onDownloadAssetFrame)
      scope.$on('sv-player-buffering', onPlayerBuffering)
      scope.$on('sv-player-playback-progress', onPlayerPlaybackProgress)
      scope.$on('sv-player-canplay', onPlayerCanplay)
      scope.$on('sv-player-is-buffering', onPlayerIsBuffering)
      scope.$on('sv-player-done-buffering', onPlayerDoneBuffering)
      scope.$on('sv-comment-added', onCommentAdded)
      scope.$on('sv-comment-selected', onCommentSelected)
      scope.$on('sv-set-comment-markup', onSetCommentMarkup)
      scope.$on('sv-set-tag-markup', onSetTagMarkup)
      scope.$on('sv-tag-show-markup', onTagShowMarkup)
      scope.$on('sv-clear-all-markup', clearOverlays)
      scope.$watch('data.is360Image', watch360ImageStatus)

      scope.is360Viewer = is360Viewer
      scope.isVideoViewer = isVideoViewer
      scope.isDziViewer = isDziViewer
      scope.isPortrait = isPortrait
      scope.centerVertically = centerVertically
      scope.dragToPositionTagMarkup = dragToPositionTagMarkup
      scope.resizeTagMarkup = resizeTagMarkup
      scope.removeTagMarkup = removeTagMarkup
      scope.canRotateTagMarkup = canRotateTagMarkup
      scope.tagMarkupShapeIs = tagMarkupShapeIs
      scope.rotateTagMarkupStyle = rotateTagMarkupStyle
      scope.rotateTagMarkup = rotateTagMarkup
      scope.shapeIs = shapeIs
      scope.shapeStyle = shapeStyle
      scope.tagShapeStyle = tagShapeStyle
      scope.rotateStyle = rotateStyle
      scope.isEditable = isEditable
      scope.dragToPositionMarkup = dragToPositionMarkup
      scope.resizeShape = resizeShape
      scope.remove = remove
      scope.rotate = rotate
      scope.previousPointOfInterest = previousPointOfInterest
      scope.nextPointOfInterest = nextPointOfInterest
      scope.togglePlay = togglePlay
      scope.onFinishLoadingDzi = onFinishLoadingDzi
      scope.onFinishLoading360 = onFinishLoading360

      init()

      // ------------------------------------------------------------------------------------------------------

      function watch360ImageStatus (newValue, oldValue) {
        if (newValue === oldValue) return
        update360Status()
      }

      function update360Status () {
        scope.showBufferSpinner = true
        smartvidApi.updateAsset(scope.asset.id, undefined, undefined, scope.data.is360Image)
          .then(() => {
            $state.reload()
          }, () => {
            scope.showBufferSpinner = false
          })
      }

      function position (instance, helper, position) {
        position.coord.left += 20
        return position
      }

      function is360Viewer () {
        return scope.asset.is360Image()
      }

      function isVideoViewer () {
        return scope.asset.isVideo() && scope.currentMediaSource !== undefined
      }

      function isDziViewer () {
        return scope.asset.isImage() && !scope.asset.is360Image()
      }

      function onFinishLoadingDzi (frameFunc) {
        scope.showBufferSpinner = false
        scope.getDziImageClipRect = frameFunc
        if (!scope.isMobile) {
          scope.showMarkup = true
        }
        if (!scope.asset.isDziImage() && !scope.asset.isDziInProgress()) {
          smartvidApi.runDziAsset(scope.asset.id).then(() => {
            scope.asset.setDziInProgress()
          })
          utils.notify('player.dziInProgress')
        }
      }

      function onFinishLoading360 () {
        scope.showBufferSpinner = false
      }

      function init () {
        scope.currentPosition = 0
        scope.progress = 0
        scope.duration = 0
        scope.comments = scope.asset.comments
        scope.currentMediaSource = $sce.trustAsResourceUrl(scope.asset.getMediaSource())
        scope.currentAudioSource = scope.asset.audioAccessUrl ? $sce.trustAsResourceUrl(scope.asset.audioAccessUrl) : undefined
        scope.tagInstancesWithMarkups = []
        scope.tooltipOptions = {functionPosition: position}
        scope.player = videoPlayer
        scope.showBufferSpinner = true
        scope.data = {
          is360Image: scope.asset.is360Image()
        }
        assetDebugUtils.printAssetDump(scope.asset)
        // clear limit view on init - single page app keeps too much data in scope across sessions.
        videoPlayer.limitView(undefined, undefined)
        hotkeys.add({
          combo: 'space',
          description: 'togglePlayPause',
          callback: function () {
            videoPlayer.togglePlay()
          }
        })
        hotkeys.add({
          combo: 'left',
          description: 'frameBack',
          callback: function () {
            videoPlayer.frameBackward()
          }
        })
        hotkeys.add({
          combo: 'right',
          description: 'frameForward',
          callback: function () {
            videoPlayer.frameForward()
          }
        })

        hotkeys.add({
          combo: 'alt+left',
          description: 'poiBack',
          callback: function () {
            scope.previousPointOfInterest()
          }
        })
        hotkeys.add({
          combo: 'alt+right',
          description: 'poiForward',
          callback: function () {
            scope.nextPointOfInterest()
          }
        })

        $timeout(function () {
          let videoElem = document.getElementById('sv-video-player')
          if (videoElem) {
            videoPlayer.set(videoElem)
          } else {
            let audioElem = document.getElementById('sv-image-audio-player')
            if (audioElem) {
              videoPlayer.set(audioElem)
            }
          }
        }, 0)
      }

      function onDestroy () {
        let video = document.getElementById('sv-video-player')
        if (video) {
          video.pause()
        }
        let videoSource = document.getElementById('sv-video-player-source')
        if (videoSource) {
          videoSource.src = ''
          video.load()
          videoSource.remove()
        }

        videoPlayer.destroy()
        normalTagMarkupService.destroy()
        angular.element($window).off('resize.player', compareOnResize)
      }

      function onDownloadAssetFrameComplete () {
        scope.showBufferSpinner = false
      }

      function onDownloadAssetFrame () {
        scope.showBufferSpinner = true
      }

      function onPlayerBuffering () {
        scope.showBufferSpinner = true
      }

      function onPlayerPlaybackProgress () {
        if (scope.isMobile && scope.showBufferSpinner) {
          scope.showBufferSpinner = false
        }

        if (!playerLoaded && ($stateParams.tagId || $stateParams.commentId)) {
          seekToTimeOfDeeplinkedPointOfInterest()
        }
        playerLoaded = true

        //
        // adjust progress bar to tag limits
        //
        scope.currentPosition = videoPlayer.currentTime()
        if (scope.viewLimits && scope.viewLimits.start) {
          scope.progress = (scope.currentPosition - scope.viewLimits.start / 1000) / videoPlayer.duration() * 100
        } else {
          scope.progress = scope.currentPosition / videoPlayer.duration() * 100
        }
        scope.duration = videoPlayer.duration()

        if (scope.tagInstancesWithMarkups.length > 0 && !scope.asset.isImagePlusAudio()) {
          let tag = scope.tagInstancesWithMarkups[0]
          if ((tag.startTime / 1000) !== scope.currentPosition &&
            !(tag.isAssetLevel() && scope.currentPosition === 0)) {
            clearOverlays()
          }
        }
      }

      function onPlayerCanplay () {
        scope.showBufferSpinner = false
        scope.showMarkup = true
      }

      function onPlayerIsBuffering () {
        scope.showBufferSpinner = true
      }

      function onPlayerDoneBuffering () {
        scope.showBufferSpinner = false
      }

      function isPortrait () {
        let img
        let $player
        let containerRatio

        if (scope.asset.isVideo() || scope.asset.isDziImage() || scope.asset.is360Image()) {
          return false
        }

        // If we don't have the dimensions of the image we need to go and get them.
        if (!imageRatio) {
          imageRatio = 0.5
          img = new $window.Image()
          img.onload = function () {
            imageRatio = this.height / this.width
            scope.showBufferSpinner = false
            utils.digest(scope)
          }
          img.src = scope.asset.imageUrl
          return false
        }

        $player = $('.player')

        containerRatio = $player.height() / $player.width()
        return imageRatio > containerRatio
      }

      function centerVertically () {
        // Get the width of the player. If the calculated height of the player is great then the height of page then return true.
        let height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
        return (element.width() * 9 / 16) + HEIGHT_OF_HEADER_NAV > height
      }

      /**
       * Seek start of shared tag/comment in video.
       * Limit shared viewed for view only users:
       *  1) when its a comment, only show one frame and the comment
       *  2) when its a tag, only show the tag duration and any tags/comments within that duration
       *
       */
      function seekToTimeOfDeeplinkedPointOfInterest () {
        let selectedPointOfInterest
        if (scope.asset.typeDetails === 'VIDEO') {
          watchVideoParams()
        }

        if ($stateParams.tagId && scope.assetTags) {
          selectedPointOfInterest = scope.assetTags.getSelectedTag()
        }

        //
        // Seek to start time of tag for links only
        //
        if ($stateParams.tagId && $stateParams.sharingId && selectedPointOfInterest.startTime !== -1) {
          scope.viewLimits = {
            type: 'TAG',
            start: selectedPointOfInterest.startTime,
            end: selectedPointOfInterest.endTime
          }
          videoPlayer.limitView(scope.viewLimits.start / 1000, scope.viewLimits.end / 1000)
          videoPlayer.currentTime(scope.viewLimits.start)
        } else if (selectedPointOfInterest) {
          videoPlayer.currentTime(selectedPointOfInterest.startTime / 1000)
        }

        if ($stateParams.commentId && scope.asset && scope.asset.comments) {
          selectedPointOfInterest = scope.asset.comments.getEditedComment($stateParams.commentId)
        }

        //
        // Seek to start time of comment for links only
        //
        if ($stateParams.commentId && $stateParams.linkId) {
          scope.viewLimits = {
            type: 'COMMENT',
            start: selectedPointOfInterest.startTime,
            end: selectedPointOfInterest.startTime
          }
          compareOnResize(selectedPointOfInterest)
          videoPlayer.limitView(scope.viewLimits.start / 1000, scope.viewLimits.end / 1000)
          videoPlayer.currentTime(scope.viewLimits.start)
        } else if (selectedPointOfInterest) {
          videoPlayer.currentTime(selectedPointOfInterest.startTime / 1000)
        }
      }

      function clearOverlays () {
        if (isVideoViewer()) {
          normalTagMarkupService.clearTagsMarkups(scope)
        }
      }

      function onCommentAdded () {
        let selectedComment = scope.comments.getEditedComment()
        if (isVideoViewer()) {
          getVideoParams(selectedComment)
        }
      }

      function onCommentSelected () {
        clearOverlays()
        let selectedComment = scope.comments.getEditedComment()
        if (isVideoViewer()) {
          compareVideoParams(selectedComment)
          watchVideoParams()
        }
      }

      function onSetCommentMarkup () {
        let selectedComment = scope.comments.getEditedComment()
        if (isVideoViewer()) {
          onSetMarkupForRegularViewer(selectedComment)
        }
      }

      function onSetTagMarkup () {
        let selectedTag = scope.assetTags.getSelectedTag()
        if (isVideoViewer()) {
          onSetMarkupForRegularViewerForTag(selectedTag)
        }
      }

      function onTagShowMarkup (evt, tags) {
        if (isVideoViewer()) {
          $timeout(() => {
            watchVideoParams()
            normalTagMarkupService.showTagsMarkups([tags[0]], scope)
          }, 100)
        }
      }

      function dragToPositionTagMarkup (tag, evt) {
        scope.$broadcast('sv-drag-to-position-tag-markup', {
          tag: tag,
          evt: evt
        })
        if (isVideoViewer()) {
          return normalTagMarkupService.dragToPositionTagMarkup(tag, evt, scope)
        }
      }

      function resizeTagMarkup (tag, evt) {
        scope.$broadcast('sv-resize-tag-shape', {
          tag: tag,
          evt: evt
        })
        if (isVideoViewer()) {
          normalTagMarkupService.resizeTagMarkup(tag, evt, scope)
        }
      }

      function removeTagMarkup (tag, evt) {
        scope.$broadcast('sv-remove-tag-markup', {
          tag: tag,
          evt: evt
        })
        if (isVideoViewer()) {
          normalTagMarkupService.removeTagMarkup(tag, evt, scope)
        }
      }

      function canRotateTagMarkup (tag) {
        return tag.markup && (tag.markup.shape === 'right-arrow' || tag.markup.shape === 'line')
      }

      function tagMarkupShapeIs (tag, shapes) {
        return _.find(shapes,
          (s) => {
            return tag.markup && tag.markup.shape === s
          }) !== undefined
      }

      function rotateTagMarkupStyle (tag) {
        return {
          transform: `rotate(${tag.markup.rotation}deg)`
        }
      }

      function rotateTagMarkup (evt, tag) {
        if (isVideoViewer()) {
          normalTagMarkupService.rotateTagMarkup(tag, evt, scope)
        }
        scope.$broadcast('sv-rotate-tag-markup', {
          tag: tag,
          evt: evt
        })
      }

      function getVideoParams (selectedComment) {
        let el = document.getElementsByTagName('video')
        if (typeof selectedComment.markup === 'string' || typeof selectedComment.markup === 'undefined') {
          selectedComment.markup = {}
        }
        selectedComment.markup.videoHeight = el[0].videoHeight
        selectedComment.markup.videoWidth = el[0].videoWidth
        selectedComment.markup.clientHeight = el[0].clientHeight
        selectedComment.markup.clientWidth = el[0].clientWidth
        selectedComment.markup.offsetHeight = el[0].offsetHeight
        selectedComment.markup.offsetWidth = el[0].offsetWidth
        selectedComment.markup.duration = el[0].duration
        selectedComment.markup.currentTime = el[0].currentTime

        if (el[0].clientHeight / el[0].videoHeight > el[0].clientWidth / el[0].videoWidth) {
          selectedComment.markup.actualWidth = el[0].clientWidth
          selectedComment.markup.actualHeight = el[0].clientWidth * (el[0].videoHeight / el[0].videoWidth)
        } else {
          selectedComment.markup.actualHeight = el[0].clientHeight
          selectedComment.markup.actualWidth = el[0].clientHeight * (el[0].videoWidth / el[0].videoHeight)
        }

        selectedComment.markup.vMargins = (el[0].clientHeight - selectedComment.markup.actualHeight) / 2
        selectedComment.markup.hMargins = (el[0].clientWidth - selectedComment.markup.actualWidth) / 2

        return selectedComment
      }

      function compareVideoParams (selectedComment) {
        if (scope.isMobile) {
          return
        }
        let currentVideoParams = document.getElementsByTagName('video')
        getVideoParams(currentVideoParams)

        let xScale = (currentVideoParams.markup.actualWidth / selectedComment.markup.actualWidth)
        let yScale = (currentVideoParams.markup.actualHeight / selectedComment.markup.actualHeight)
        let xShift = (currentVideoParams.markup.hMargins - selectedComment.markup.hMargins)
        let yShift = (currentVideoParams.markup.vMargins - selectedComment.markup.vMargins)

        selectedComment.width = selectedComment.width * xScale
        selectedComment.left = selectedComment.left * xScale + xShift
        selectedComment.height = selectedComment.height * yScale
        selectedComment.top = selectedComment.top * yScale + yShift
        getVideoParams(selectedComment)
        $rootScope.$broadcast('sv-update-comment-markup')
      }

      function compareOnResize () {
        let selectedComment = scope.comments.getEditedComment()
        if (typeof selectedComment !== 'undefined') {
          if (selectedComment.type === 'VIDEO') {
            compareVideoParams(selectedComment)
          }
        }

        let selectedTag = scope.assetTags.getSelectedTag()
        if (angular.isDefined(selectedTag)) {
          normalTagMarkupService.compareVideoParams(selectedTag, scope)
        }
      }

      function watchVideoParams () {
        angular.element($window).on('resize.player', compareOnResize)

        let v = document.getElementsByTagName('video')
        scope.$watch(
          function () {
            return {
              w: v[0].clientWidth,
              h: v[0].clientHeight
            }
          },
          function () {
            compareOnResize()
          }, true)
      }

      function onSetMarkupForRegularViewer (selectedComment) {
        getVideoParams(selectedComment)
        let widthOfMediaContainer = selectedComment.markup.clientWidth
        let heightOfMediaContainer = selectedComment.markup.clientHeight
        selectedComment.width = 100
        selectedComment.height = 100
        selectedComment.left = (widthOfMediaContainer - 100) / 2
        selectedComment.top = (heightOfMediaContainer - 100) / 2
        selectedComment.version = GENERAL_MARKUP_VERSION_NUMBER
        selectedComment.type = scope.asset.type === 'IMAGE' ? IMAGE_MARKUP_TYPE : VIDEO_MARKUP_TYPE
      }

      function onSetMarkupForRegularViewerForTag (selectedTag) {
        getVideoParams(selectedTag)
        let widthOfMediaContainer = selectedTag.markup.clientWidth
        let heightOfMediaContainer = selectedTag.markup.clientHeight
        selectedTag.markup.width = 100
        selectedTag.markup.height = 100
        selectedTag.markup.left = (widthOfMediaContainer - 100) / 2
        selectedTag.markup.top = (heightOfMediaContainer - 100) / 2
        selectedTag.markup.version = GENERAL_MARKUP_VERSION_NUMBER
        selectedTag.markup.type = scope.asset.type === 'IMAGE' ? IMAGE_MARKUP_TYPE : VIDEO_MARKUP_TYPE
        scope.tagInstancesWithMarkups.push(selectedTag)
        normalTagMarkupService.showTagsMarkups([selectedTag], scope)
      }

      function shapeIs (type) {
        let selectedComment = scope.comments.getEditedComment()
        return selectedComment && selectedComment.shape === type
      }

      function shapeStyle () {
        let selectedComment = scope.comments.getEditedComment()
        if (isDziViewer()) {
          return undefined
        } else if (is360Viewer()) {
          return sphericalMarkupService.getShapeStyle(selectedComment)
        } else {
          return selectedComment.getStyles()
        }
      }

      function tagShapeStyle (tag) {
        if (isDziViewer()) {
          return undefined
        } else if (is360Viewer()) {
          return sphericalMarkupService.getShapeStyle(tag)
        } else {
          return normalTagMarkupService.getTagShapeStyle(tag)
        }
      }

      function rotateStyle () {
        let selectedComment = scope.comments.getEditedComment()
        return {
          transform: `rotate(${selectedComment.rotation}deg)`
        }
      }

      function isEditable () {
        if (scope.asset.isReadOnly) {
          return false
        }

        let selectedComment = scope.comments.getEditedComment()
        if (selectedComment && selectedComment.isEditable) {
          return true
        }

        return dziTagMarkupService.isMarkupEditable(scope.tagInstancesWithMarkups)
      }

      function dragToPositionMarkup (evt) {
        evt = evt || window.event
        evt.stopPropagation()
        if (scope.asset.isReadOnly || !scope.isEditable()) {
          return false
        }

        let selectedComment = scope.comments.getEditedComment()

        if (!selectedComment.editMode) {
          selectedComment.editMode = true
        }

        let startXDelta = evt.pageX !== undefined ? evt.pageX : evt.clientX
        let startYDelta = evt.pageY !== undefined ? evt.pageY : evt.clientY

        scope.$broadcast('sv-drag-to-position-markup', {
          startXDelta: startXDelta,
          startYDelta: startYDelta,
          comment: selectedComment,
          evt: evt
        })

        if (isVideoViewer()) {
          dragToPositionMarkupRegularViewer(evt, startXDelta, startYDelta, selectedComment)
        }
      }

      function dragToPositionMarkupRegularViewer (evt, startXDelta, startYDelta, selectedComment) {
        getVideoParams(selectedComment)

        let moved = false
        let startX = selectedComment.left
        let startY = selectedComment.top
        evt = evt || window.event
        let elem = evt.currentTarget

        document.body.onmousemove = function (evt) {
          moved = true
          evt = evt || window.event
          let endX = evt.pageX !== undefined ? evt.pageX : evt.clientX
          let endY = evt.pageY !== undefined ? evt.pageY : evt.clientY

          // TODO: Add x0Limit and y0Limit and update all limit params to include margins

          let x1Limit = selectedComment.markup.clientWidth - selectedComment.width
          let y1Limit = selectedComment.markup.clientHeight - selectedComment.height

          if (endX > startXDelta) {
            selectedComment.left = Math.min(x1Limit, Math.max(0, startX + Math.abs(endX - startXDelta)))
          } else {
            selectedComment.left = Math.min(x1Limit, Math.max(0, startX - Math.abs(endX - startXDelta)))
          }

          if (endY > startYDelta) {
            selectedComment.top = Math.min(y1Limit, Math.max(0, (startY + Math.abs(endY - startYDelta))))
          } else {
            selectedComment.top = Math.min(y1Limit, Math.max(0, (startY - Math.abs(endY - startYDelta))))
          }

          elem.style.left = selectedComment.left + 'px'
          elem.style.top = selectedComment.top + 'px'
        }

        document.body.onmouseup = function (evt) {
          if (moved) {
            elem.style.left = selectedComment.left + 'px'
            elem.style.top = selectedComment.top + 'px'
          }
          document.body.onmousemove = document.body.onmouseup = null
          $rootScope.$broadcast('sv-update-comment-markup')
        }
      }

      function resizeShape (evt) {
        evt = evt || window.event
        evt.stopPropagation()
        if (!scope.isEditable()) {
          return
        }
        let startXDelta = evt.pageX !== undefined ? evt.pageX : evt.clientX
        let startYDelta = evt.pageY !== undefined ? evt.pageY : evt.clientY
        let selectedComment = scope.comments.getEditedComment()

        if (!selectedComment.editMode) {
          selectedComment.editMode = true
        }
        scope.$broadcast('sv-resize-shape', {
          startXDelta: startXDelta,
          startYDelta: startYDelta,
          comment: selectedComment,
          evt: evt
        })
        if (isVideoViewer()) {
          resizeShapeForRegularViewer(evt, startXDelta, startYDelta, selectedComment)
        }
      }

      // TODO: Fix resize limits to include margins

      function resizeShapeForRegularViewer (evt, startXDelta, startYDelta, selectedComment) {
        getVideoParams(selectedComment)
        // let heightLimit = heightOfMediaContainer - selectedComment.height
        let elem = evt.currentTarget.parentElement

        document.body.onmousemove = function (evt) {
          evt = evt || window.event
          let endX = evt.pageX !== undefined ? evt.pageX : evt.clientX
          let endY = evt.pageY !== undefined ? evt.pageY : evt.clientY
          let amountMovedAlongX = endX - startXDelta
          let amountMovedAlongY = endY - startYDelta
          startXDelta = endX
          startYDelta = endY
          let widthLimit = selectedComment.markup.clientWidth - selectedComment.left
          let heightLimit = selectedComment.markup.clientHeight - selectedComment.top
          selectedComment.width = Math.min(widthLimit, (selectedComment.width + amountMovedAlongX))
          selectedComment.height = Math.min(heightLimit, (selectedComment.height + amountMovedAlongY))
          elem.style.width = selectedComment.width + 'px'
          elem.style.height = selectedComment.height + 'px'
        }

        document.body.onmouseup = function (evt) {
          getVideoParams(selectedComment)
          document.body.onmousemove = document.body.onmouseup = null
          $rootScope.$broadcast('sv-update-comment-markup')
        }
      }

      function remove (evt) {
        evt.stopPropagation()
        let selectedComment = scope.comments.getEditedComment()
        if (selectedComment && scope.isEditable()) {
          selectedComment.removeMarkup()
          scope.$broadcast('sv-remove-markup')
          $rootScope.$broadcast('sv-update-comment-markup')
        }
      }

      function rotate (evt) {
        if (scope.isEditable()) {
          let selectedComment = scope.comments.getEditedComment()
          scope.$broadcast('sv-rotate-comment-markup', {
            rotation: 45,
            evt: evt,
            comment: selectedComment
          })
          $rootScope.$broadcast('sv-update-comment-markup')
        }
      }

      function previousPointOfInterest () {
        handlePointOfInterest('previous')
      }

      function nextPointOfInterest () {
        handlePointOfInterest('next')
      }

      function togglePlay () {
        if (scope.isMobile) {
          if (videoPlayer.paused()) {
            videoPlayer.play()
          } else {
            videoPlayer.pause()
          }
        }
      }

      function pointsOfInterestOrderedChronologically () {
        let pointsOfInterest = scope.comments.models.concat(scope.assetTags.models).filter((n) => n.startTime !== -1)

        pointsOfInterest = _.sortBy(pointsOfInterest, (point) => {
          return point.startTime
        })

        return pointsOfInterest
      }

      function handlePointSelection (point) {
        if (point instanceof TagInstanceModel) {
          $rootScope.$broadcast('sv-tag-clicked', point)
        } else {
          $rootScope.$broadcast('sv-comment-clicked', point)
        }
      }

      function handlePointOfInterest (type) {
        let points = pointsOfInterestOrderedChronologically()
        let currentTime = videoPlayer.currentTime()

        function isBeforeNow (points) {
          return points.startTime < currentTime * 1000
        }

        function isAfterNow (points) {
          return points.startTime > currentTime * 1000
        }

        let pointsBefore = points.filter(isBeforeNow)
        let pointsAfter = points.filter(isAfterNow)

        let previousPoint = _.max(pointsBefore, function (x) {
          return x.startTime
        })
        let nextPoint = _.min(pointsAfter, function (x) {
          return x.startTime
        })

        let index

        if (points.length === 0) {
          return
        }

        let selectedPoint = scope.comments.getEditedComment()
        if (!selectedPoint) {
          selectedPoint = scope.assetTags.getSelectedTag()
        }

        if (!selectedPoint && type === 'next') {
          handlePointSelection(nextPoint)
          return
        }

        if (!selectedPoint && type === 'previous') {
          handlePointSelection(previousPoint)
          return
        }

        // If we get here then we have a point selected so lets get that index.
        index = _.findIndex(points, (point) => {
          return selectedPoint.id === point.id
        })

        if (type === 'next') {
          if (index === points.length - 1) {
            return
          }

          handlePointSelection(points[++index])
          return
        }

        if (type === 'previous') {
          if (index === 0) {
            return
          }

          handlePointSelection(points[--index])
          return
        }
      }
    }
  }
})
