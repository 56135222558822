/* global angular _ */

angular.module('smartvid').factory('ForgeIssueTypeModel', function (BaseModel) {
  class ForgeIssueTypeModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        issueTypeId: undefined,
        title: undefined,
        subtypes: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return ForgeIssueTypeModel
})
