/* global angular */

angular.module('smartvid').factory('rateLimitApiInterceptor', function ($q, $timeout, $injector) {

  const HTTP_ERROR_CODE_TOO_MANY_REQUESTS = 429

  let responseError = (response) => {
    if (response.status === HTTP_ERROR_CODE_TOO_MANY_REQUESTS &&
      response.data && response.data.customData && response.data.customData.waitTimeSec) {
      if ('shouldRescheduleUponTooManyRequests' in response.config && !response.config.shouldRescheduleUponTooManyRequests) {
        return $q.reject(response)
      }
      return $timeout(() => {
        let $http = $injector.get('$http');
        return $http(response.config);
      }, response.data.customData.waitTimeSec * 1000)
    }
    return $q.reject(response)
  }

  return {
    responseError: responseError
  }
})
.config(function ($httpProvider) {
  $httpProvider.interceptors.push('rateLimitApiInterceptor')
})
