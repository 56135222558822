/* global angular */

angular.module('smartvid').controller('SsoCtrl', function ($stateParams, $state, $q, smartvidApi, currentUser) {
  currentUser.clearState()
  let defer = $q.defer()
  smartvidApi.onAuthSuccess(defer, {
    token: $stateParams.token,
    expires: $stateParams.expires
  })
  defer.promise.then(() => {
    let defaultHomePage = currentUser.getDefaultHomePage()
    $state.go(defaultHomePage)
  })
})
