<div class="navigation-panel-{{ panelsCount }} project-list sidepanel" [ngClass]="{ open: NavController.leftNavOpen}">
  <mat-accordion [displayMode]="'flat'">
    <mat-expansion-panel
            [disabled]="isProjects"
            [expanded]="isProjects"
            (afterExpand)="openProjects(false)">

      <mat-expansion-panel-header (click)="openProjects(true)">
        <mat-panel-title class="mat-title">
          <div>{{ 'directives.projectList.projects' | translate }}</div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <svSmartvidSpinner *ngIf="displayProjectSpinner()"></svSmartvidSpinner>
      <sv-project-tree *ngIf="isProjects" [projects]="projects"
                    [featureFilter]="ASSET_FEATURE"
                    (favorited)="onFavorite($event)">

      </sv-project-tree>


    </mat-expansion-panel>

    <mat-expansion-panel
            *ngIf="hasObservations"
            [disabled]="isObservations"
            [expanded]="isObservations"
            (afterExpand)="openObservations()">

      <mat-expansion-panel-header (click)="openObservations()">
        <mat-panel-title class="mat-title">
          <div>{{ 'directives.projectList.observations' | translate }}</div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <sv-project-tree *ngIf="isObservations" #obsProjectTree [projects]="projects" [featureFilter]="OBSERVATION_FEATURE"></sv-project-tree>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="hasAnalytics" [disabled]="isAnalytics" [expanded]="isAnalytics"
      (afterExpand)="openAnalytics()">
    
      <mat-expansion-panel-header (click)="openAnalytics()">
        <mat-panel-title class="mat-title">
          <div>Analytics</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <sv-organization-list *ngIf="isAnalytics" [organizations]="powerBiOrganizations" [clickFunc]="selectAnalyticsOrg"></sv-organization-list>
    </mat-expansion-panel>

    <mat-expansion-panel
            *ngIf="hasPtp"
            [disabled]="isPtp"
            [expanded]="isPtp"
            (afterExpand)="openPtp()">

      <mat-expansion-panel-header (click)="openPtp()">
        <mat-panel-title class="mat-title">
          <div>{{ 'directives.projectList.ptp' | translate }}</div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <p>TODO</p>

    </mat-expansion-panel>

    <mat-expansion-panel
            *ngIf="hasAdmin"
            [disabled]="isAdminOrganizations"
            [expanded]="isAdminOrganizations"
            (afterExpand)="openAdminOrganizations()">

      <mat-expansion-panel-header (click)="openAdminOrganizations()">
        <mat-panel-title class="mat-title">
          <div>{{ 'directives.projectList.admin' | translate }}</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul class="nav-menu-item-list">
        <div class ="organization_menu_item_container">
          <svOrganizationMenuItem
                  [organization]="organization"
                  id="organization-{{ organization.id }}"
                  *ngFor="let organization of organizations.models | OrganizationListPipe; trackBy:organization?.id">
          </svOrganizationMenuItem>
        </div>
        <div class="mysection">
          <svUserMenuItem itemName="My Profile" itemState="dashboard.myprofile"></svUserMenuItem>
          <svUserMenuItem itemName="My Settings" itemState="dashboard.mysettings"></svUserMenuItem>
        </div>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>
</div>
