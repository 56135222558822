/* global angular,analytics */

angular.module('smartvid').controller('LoginSSOCtrl', function ($window, utils, $rootScope, $scope, $state, $location, $timeout, redirectorService, smartvidApi, currentUser, errorUtils, $stateParams) {
  $scope.currentUser = currentUser
  $scope.submitted = false

  if (window.localStorage.getItem('email')) {
    let email = window.localStorage.getItem('email')
    $scope.email = email
  }

  $scope.submit = () => {

    //
    // make sure we have value to submit
    //
    $scope.email = $scope.form.email.$viewValue

    smartvidApi.getSsoLink($scope.email).then((response) => {

        if (response.ssoLoginUrl) {
          window.location = response.ssoLoginUrl
        } else {
          // show error message on first form field, email
          $scope.form.email.$error.badCredentials = 'errorCodes.resource_user_bad_email'
          // invalidate signin form fields
          $scope.form.email.$invalid = true
          $scope.form.email.$valid = false
          $scope.form.email.$dirty = true
        }
      }
    ).finally(() => {
      $timeout(() => {
        $scope.inTransit = false
      }, 500)
    })

  }
})
