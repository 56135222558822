/* global angular, $, _ */

angular.module('smartvid').directive('formInputFieldValidator', function ($timeout, $compile) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      let isInputValid = attrs.isInputValid
      let showValidation = attrs.isShowValidation
      let customTopOffset = attrs.validationTopOffset
      let customLeftOffset = attrs.validationLeftOffset
      let validationMessage = attrs.validationMessage
      let validationMessagePosition = (attrs.validationMessagePosition) ? attrs.validationMessagePosition : 'right'
      let isStyleWidthUsingCSS = !!((attrs.useCssForValidationWidth || '').toLowerCase().trim() === 'true')
      let watchExpr = `!${isInputValid} && ${showValidation}`
      let checkMarkHtml = `<icon-checkmark class='type-input' ng-show='${isInputValid} && ${showValidation}'></icon-checkmark>`
      let xMarkHtml = `<icon-x class='type-input' ng-show='!${isInputValid} && ${showValidation}'></icon-x>`
      let errorMessageHtml = `<div class='form-field-validator'><div class='arrow-${validationMessagePosition}'></div><div class='tooltip-message' ng-bind-html="${validationMessage}"></div></div>`

      function getTopOffset () {
        return !_.isNaN(Number(customTopOffset)) ? Number(customTopOffset) : 3
      }

      function getLeftOffset () {
        return !_.isNaN(Number(customLeftOffset)) ? Number(customLeftOffset) : 10
      }

      let getValidationMessageStyle = function (location) {
        let inputPosition = inputField.position()
        let inputWidth = inputField.outerWidth()
        let inputHeight = inputField.outerHeight()
        if (location === 'right') {
          return {
            position: 'absolute',
            right: 'auto',
            bottom: 'auto',
            top: (inputPosition.top + getTopOffset()) + 'px',
            left: (inputPosition.left + inputWidth + getLeftOffset()) + 'px'
          }
        } else if (location === 'bottom') {
          return {
            position: 'absolute',
            right: '0px',
            bottom: 'auto',
            top: (inputPosition.top + inputHeight + getTopOffset()) + 'px',
            left: 'auto'
          }
        }
      }

      let inputField = element
      if (!element.is('input')) {
        inputField = element.find('input')
      }
      let checkMarkElement = angular.element(checkMarkHtml)
      let xMarkElement = angular.element(xMarkHtml)
      let messageElement = angular.element(errorMessageHtml)
      checkMarkElement.insertAfter(inputField)
      xMarkElement.insertAfter(inputField)
      messageElement.insertAfter(inputField)
      $compile(checkMarkElement)(scope)
      $compile(xMarkElement)(scope)
      $compile(messageElement)(scope)
      $.fn.textWidth = function (text, font) {
        if (!$.fn.textWidth.fakeEl) $.fn.textWidth.fakeEl = $('<span>').hide().appendTo(document.body)
        $.fn.textWidth.fakeEl.text(text || this.val() || this.text()).css('font', font || this.css('font'))
        return $.fn.textWidth.fakeEl.width()
      }
      messageElement.hide()
      scope.$watch(watchExpr, function (newValue, oldValue) {
        $timeout(() => {
          if (newValue) {
            let textElement = messageElement.find('.tooltip-message')
            let textLength = textElement.textWidth()
            if (!isStyleWidthUsingCSS) {
              textElement.css({
                width: (textLength + 5) + 'px'
              })
            }
            messageElement.show()
          } else {
            messageElement.hide()
          }
        }, 100)
      })
      // This needs to be executed after page is rendered and timeout seems to be the only way to do it.
      $timeout(() => {
        let inputPosition = inputField.position()
        let inputWidth = inputField.outerWidth()
        xMarkElement.css({
          position: 'absolute',
          top: (inputPosition.top + 10) + 'px',
          left: (inputPosition.left + inputWidth - 45) + 'px',
          right: 'auto',
          bottom: 'auto',
          marginTop: '0px',
          marginRight: '0px'
        })
        checkMarkElement.css({
          position: 'absolute',
          top: (inputPosition.top + 10) + 'px',
          left: (inputPosition.left + inputWidth - 45) + 'px',
          right: 'auto',
          bottom: 'auto',
          marginTop: '0px',
          marginRight: '0px'
        })
        messageElement.css(getValidationMessageStyle(validationMessagePosition))
      }, 150)
    }
  }
})
