/* global angular, _ */

angular.module('smartvid').service('access', function ($timeout, $state, $q, currentUser) {
  let isExpired = (timestamp) => {
    return timestamp < Date.now()
  }

  let Access = {

    OK: 200,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,

    isAuthenticated () {
      let deferred = $q.defer()

      if (!_.isEmpty(currentUser.token) && !_.isNaN(currentUser.expires) && !isExpired(currentUser.expires)) {
        deferred.resolve(currentUser)
      } else {
        deferred.reject({ status: Access.UNAUTHORIZED })
      }

      return deferred.promise
    }

  }

  return Access
})
