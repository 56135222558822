/* global angular _ */

angular.module('smartvid').factory('Bim360FieldProjectModel', function (BaseModel) {
  class Bim360FieldProjectModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        project_id: undefined,
        name: undefined,
        status: undefined
      }

      super(_.defaults(attrs || {}, defaults))

      this.project_id = attrs.id
    }

    getDisplayName () {
      return (this.status ? this.name + ' - ' + this.status : this.name)
    }
  }

  return Bim360FieldProjectModel
})
