import { Component, Injector } from '@angular/core'

@Component({
  selector: 'sv-left-nav-button',
  templateUrl: 'left-nav-button.component.html',
  styleUrls: ['left-nav-button.component.scss'],
})
export class LeftNavButtonComponent {
  NavController = this.injector.get('NavController')

  constructor(private injector: Injector) {}

  toggleNav() {
    this.NavController.toggleLeftNav()
  }
}
