/* global angular _ */

angular.module('smartvid').controller('MoveTagsCtrl', function ($rootScope, $scope, modal, $interpolate, $filter, utils, uiGridConstants) {
  $scope.inTransit = false
  $scope.tagTreeMoveTarget = $scope.treeModel
  $scope.moveTagsGridOptions = getMoveTagsGridOptions()
  $scope.moveTags = moveTags
  $scope.selectTag = selectTag
  $scope.canMoveTagTo = canMoveTagTo
  $scope.getNodeClass = getNodeClass
  $scope.getTagTooltip = getTagTooltip
  $scope.isMoveButtonEnabled = isMoveButtonEnabled
  $scope.closeDialog = closeDialog
  $scope.selectedNodeId = undefined

  let disabledTagIds = $scope.nodeIdsData.topMostSelectedNodeIds.concat($scope.nodeIdsData.childNodeIdsFlatList)

  let uiTreeLoadedListener = $scope.$on('uiTreeLoaded', function (event, gridApi, treeOwnerId) {
    if (treeOwnerId === 'MOVE_TAGS_DIALOG') {
      $scope.gridApi = gridApi
      $scope.gridApi.selection.clearSelectedRows()
      $scope.selectedNodeId = undefined
    }
  })

  $scope.$on('$destroy', () => {
    uiTreeLoadedListener()
  })

  function selectTag (event, node) {
    if (canMoveTagTo(node)) {
      $scope.selectedNodeId = node.id
    }
  }

  function getNodeClass (activeNodeId, nodeId) {
    let node = $scope.tagTreeMoveTarget.getNode(nodeId)
    if (activeNodeId === nodeId) {
      return canMoveTagTo(node) ? 'hovering' : 'hovering-no-update, no-update'
    }
    return canMoveTagTo(node) ? '' : 'no-update'
  }

  function getTagTooltip (tagName) {
    return $interpolate($filter('i18next')('tags.modal.moveNTagsTo'))({
      tagCount: disabledTagIds.length,
      tagName: tagName
    })
  }

  function canMoveTagTo (node) {
    return !_.contains(disabledTagIds, node.id) && node.canUpdate
  }

  function getMoveTagsGridOptions () {
    return {
      rowTemplate: 'TagManager/tree-node-for-move-to-tags.html',
      multiSelect: false,
      modifierKeysToMultiSelect: false,
      noUnselect: true,
      rowSelection: true,
      enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS
    }
  }

  function isMoveButtonEnabled () {
    return $scope.selectedNodeId !== undefined
  }

  function moveTags () {
    $scope.tagTreeMoveTarget.moveTagsToNewParent($scope.nodeIdsData.topMostSelectedNodeIds, $scope.selectedNodeId).then(() => {
      utils.notify($interpolate($filter('i18next')('tags.modal.notificationMovedNTagsTo'))({
        tagCount: disabledTagIds.length,
        tagName: $scope.tagTreeMoveTarget.getNode($scope.selectedNodeId).title
      }))
      $rootScope.$broadcast('uiTreeNodesUpdatedForParent', $scope.selectedNodeId)
    })

    modal.close()
  }

  function closeDialog () {
    modal.close()
  }
})
