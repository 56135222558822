/* global angular */

angular.module('smartvid').factory('ProcoreCompanyCollection', function (BaseCollection, ProcoreCompanyModel, smartvidApi, $interval) {
  class ProcoreCompanyCollection extends BaseCollection {
    constructor (models) {
      super(models, ProcoreCompanyModel)
    }
  }

  return ProcoreCompanyCollection
})
