/* global angular */
/**
 * Created by plitvak on 1/8/16.
 */

angular.module('smartvid').directive('svImage', (imageService) => {
  return {
    restrict: 'E',
    scope: {
      imgSource: '=',
      canvasWidth: '@',
      canvasHeight: '@',
      scaleMode: '@'
    },
    templateUrl: 'sv-image.html',
    link (scope, element) {
      let image = new window.Image()
      scope.drawImageToCanvas = () => {
        let canvasContext = element.find('canvas')[0].getContext('2d')
        let width = parseInt(scope.canvasWidth, 10)
        let height = parseInt(scope.canvasHeight, 10)

        if (!image.src) {
          imageService.clearImage()
          return
        }

        var scaledImageSize = {}
        if (!scope.scaleMode || scope.scaleMode === 'FIT_TARGET_KEEP_ASPECT') {
          scaledImageSize = imageService.getScaleDataToFitTargetPreservingOriginalAspect(image, width, height)
        } else if (scope.scaleMode === 'FILL_TARGET') {
          scaledImageSize = imageService.getScaleDataToFillTargetCenterCropOriginal(image, width, height)
        } else if (scope.scaleMode === 'FIT_TARGET_HEIGHT_ADJUST_ASPECT') {
          scaledImageSize = imageService.getScaleDataToFitTargetHeightAndCropOriginalImageWidth(image, width, height)
        }
        imageService.drawScaledImage(image, canvasContext, scaledImageSize, width, height)
      }

      image.onload = () => {
        scope.drawImageToCanvas()
      }

      if (scope.imgSource) {
        image.src = scope.imgSource
      }

      scope.$watch('imgSource', () => {
        if (scope.imgSource) {
          image.src = scope.imgSource
        }
      })
    }
  }
})
