<div class="table-header-with-search">
  <span class="table-title">
    <div class="mat-body-3 title" *ngIf="!getIntegrationType()">
      {{ 'components.adminOrganizationIntegrations.search.allIntegrations' | translate }}
    </div>
    <div class="mat-body-3 title" *ngIf="getIntegrationType()">
      {{ 'components.adminOrganizationIntegrations.search.byTypeIntegrations' | translate: {integrationType:
      getIntegrationType()} }}
    </div>
    <div class="mat-body-2 subtitle">
      <span class="mat-body-2 subtitle" *ngIf="selectedIntegrationCount === 0">
        {{ 'components.adminOrganizationIntegrations.search.countIntegrations' | translate: {integrationCount:
        visibleIntegrationCount} }}
      </span>
      <span class="mat-body-2 subtitle" *ngIf="selectedIntegrationCount > 0">
        {{ 'components.adminOrganizationIntegrations.search.countSelectedIntegrations' | translate: {selectedIntegrationCount:
        selectedIntegrationCount} }}
      </span>
    </div>
  </span>

  <span class="input-box-container">
    <span class="input-box">
       <mat-form-field class="search-form-field" appearance="standard">
         <input matInput type="search"
                placeholder="{{ 'components.adminOrganizationIntegrations.search.byProject' | translate }}"
                [(ngModel)]="searchByProject" (ngModelChange)="onProjectInputChange()">
         <button mat-button matSuffix *ngIf="searchByProject" mat-icon-button aria-label="Clear" (click)="cancelProjectSearch()">
           <mat-icon>close</mat-icon>
         </button>
       </mat-form-field>
    </span>
    &nbsp;
    <span class="input-box">
       <mat-form-field class="search-form-field" appearance="standard">
         <input matInput type="search"
                placeholder="{{ 'components.adminOrganizationIntegrations.search.byUser' | translate }}"
                [(ngModel)]="searchByUser" (ngModelChange)="onUserInputChange()">
         <button mat-button *ngIf="searchByUser" matSuffix mat-icon-button aria-label="Clear" (click)="cancelUserSearch()">
           <mat-icon>close</mat-icon>
         </button>
       </mat-form-field>
    </span>
    &nbsp;
  </span>
  <div style="clear:both"></div>
</div>
