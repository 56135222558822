import { Component, Input } from '@angular/core'
import { InsightsDashboardComponentBaseDirective } from 'modules/insights/dashboard/components/insights-dashboard-component-base'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import { TranslateService } from '@ngx-translate/core'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import { InsightsDashboardProjectAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-project-assets.service'
import { ProjectTotalCounts } from 'modules/insights/dashboard/models/insights-dashboard-project-assets.model'
import {
  DashboardCardId,
  DashboardTabId,
  DashboardType,
  DateRangeChartData,
} from 'modules/insights/dashboard/models/insights-dashboard.model'

@Component({
  selector: 'sv-assets-insights-dashboard-image-health',
  templateUrl: 'assets-insights-dashboard-image-health.component.html',
  styleUrls: ['assets-insights-dashboard-image-health.component.scss'],
})
export class AssetsInsightsDashboardImageHealthComponent extends InsightsDashboardComponentBaseDirective {
  private _projectTotalCounts: ProjectTotalCounts
  @Input() set projectTotals(projectTotalCounts: ProjectTotalCounts) {
    this._projectTotalCounts = projectTotalCounts

    if (this._projectTotalCounts) {
      this.avgPhotosCreatedPerDayPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectTotalCounts.avgPhotosCreatedPerDayPercentChange
      )
      this.avgPhotoDiffCreatedUploadedPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectTotalCounts.avgPhotoDiffCreatedUploadedPercentChange
      )
      this.constructionPhotosToAllPhotosRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectTotalCounts.constructionPhotosToAllPhotosRatioPercentChange
      )
      this.percentPersonCoveragePercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectTotalCounts.percentPersonCoveragePercentChange
      )
      this.percentOfAssetsWithoutCreatedDatePercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectTotalCounts.percentOfAssetsWithoutCreatedDatePercentChange
      )
      this.percentOfConstructionAssetsWithoutCreatedDatePercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectTotalCounts.percentOfConstructionAssetsWithoutCreatedDatePercentChange
      )
    }
  }
  get projectTotals() {
    return this._projectTotalCounts
  }

  projectAvgPhotosCreatedByDayOfWeekData: DateRangeChartData

  avgPhotosCreatedPerDayPercentChangeClassName: string
  avgPhotoDiffCreatedUploadedPercentChangeClassName: string
  constructionPhotosToAllPhotosRatioPercentChangeClassName: string
  percentPersonCoveragePercentChangeClassName: string
  percentOfAssetsWithoutCreatedDatePercentChangeClassName: string
  percentOfConstructionAssetsWithoutCreatedDatePercentChangeClassName: string

  constructor(
    protected insightsDataHelper: InsightsDataHelper,
    protected translate: TranslateService,
    protected insightsDashboardConfigurationService: InsightsDashboardConfigurationService,
    private insightsDashboardProjectService: InsightsDashboardProjectAssetsService
  ) {
    super(insightsDataHelper, insightsDashboardConfigurationService, translate, DashboardType.PROJECT_ASSETS_DASHBOARD)
  }

  protected refresh() {
    this.guardFor(
      () => this.loadAvgPhotosCreatedByDayOfWeekData(),
      DashboardTabId.IMAGE_HEALTH,
      DashboardCardId.AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK
    )
  }

  private loadAvgPhotosCreatedByDayOfWeekData() {
    this.projectAvgPhotosCreatedByDayOfWeekData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadAvgPhotosCreatedByDayOfWeekData(
        this.currentInsightsDashboardParameters,
        tableData => {
          this.projectAvgPhotosCreatedByDayOfWeekData = tableData
        }
      )
    )
  }
}
