/* global angular, _ */

angular.module('smartvid').directive('responsiveTooltip', function () {
  return {
    restrict: 'E',
    transclude: true,
    templateUrl: 'responsive-tooltip.html',
    scope: {
      options: '='
    },
    link: function (scope, element, attrs, controller, transcludeFn) {
      var defaultOptions = {
        content: element,
        contentCloning: false,
        arrow: false,
        side: 'top',
        delay: [500, 0],
        theme: ['tooltipster-borderless', 'tooltipster-default'],
        functionPosition: defaultPosition,
        functionBefore: functionBefore
      }
      var options = (scope.options) ? _.defaults(scope.options, defaultOptions) : defaultOptions
      var parent = element.parent()
      options.content = element
      element.detach()
      parent.tooltipster(options) // apply tooltip to direct parent

      scope.$on('$destroy', function () {
        try {
          parent.tooltipster('destroy')
        // eslint-disable-next-line no-empty
        } catch (e) {
        }
        element.off()
        element.remove()
      })

      function defaultPosition (instance, helper, position) {
        return position
      }

      function functionBefore () {
        return !parent.hasClass('ng-hide')
      }
    }
  }
})
