/* global angular */

angular.module('smartvid').directive('svValidEmail', function (emailUtils, $q, $timeout) {
  return {
    restrict: 'A',
    replace: false,
    require: 'ngModel',
    link: (scope, element, attrs, ngModel) => {
      ngModel.$validators.svValidEmail = function (email) {
        return emailUtils.isValidEmail(email)
      }
    }
  }
})
