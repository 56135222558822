/* global angular */

angular.module('smartvid').service('batchApi', function ($http, $q, $log, $injector, config) {
  let rootUrl = config.env.development.apiRootUrl

  return {
    getProjectJobStatus (projectId, jobName, jobId) {
      let defer = $q.defer()

      let url = `${rootUrl}/api/batch/job/status/project/${projectId}/job/${jobId}?jobName=${jobName}`

      $http.get(url).then((response) => {
        defer.resolve(response.data.status)
      }, (response) => {
        defer.reject(response)
      })

      return defer.promise
    },
    getOrgJobStatus (orgId, jobName, jobId) {
      let defer = $q.defer()

      let url = `${rootUrl}/api/batch/job/status/organization/${orgId}/job/${jobId}?jobName=${jobName}`

      $http.get(url).then((response) => {
        defer.resolve(response.data.status)
      }, (response) => {
        defer.reject(response)
      })

      return defer.promise
    }
  }
})
