/* global angular */
angular.module('smartvid').directive('staticLoader', () => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'static-loader.html',
    link (scope) {
    }
  }
})
