/* global angular */

angular.module('smartvid').directive('checkbox', function ($compile) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'forms/checkbox.html',
    scope: {
      description: '@',
      label: '@',
      checkedbox: '=',
      disabled: '=',
      checkboxOnly: '='
    },
    link: function (scope, element, args) {
      scope.isCheckedFunc = (typeof scope.checkedbox === 'function')
      scope.isDisabledFunc = (typeof scope.disabled === 'function')
      scope.checkedFunc = checkedFunc
      scope.disabledFunc = disabledFunc
      function checkedFunc () {
        return (scope.isCheckedFunc) ? scope.checkedbox.call() : scope.checkedbox
      }
      function disabledFunc () {
        return (scope.isDisabledFunc) ? scope.disabled.call() : scope.disabled
      }
    }
  }
})
