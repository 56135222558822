/* global angular analytics _ */

angular.module('smartvid').controller('ConnectToOxBlueCtrl',
  function (MAX_OXBLUE_USER_ID_LENGTH, MAX_OXBLUE_USER_PASSWORD_LENGTH, $scope, $rootScope, $filter, smartvidApi,
            $state, $q, $timeout) {
    const UNAUTHORIZED = 'UNAUTHORIZED'
    const CONFLICT = 'CONFLICT'
    const OXBLUE_USER_ALREADY_CONNECTED = 'OAUTH_MULTIPLE_SMARTVID_USERS_SAME_EXTERNAL_USER'

    $scope.inTransit = false
    $scope.incorrectLoginError = false
    $scope.userOxBlueUserAlreadyConnected = false

    $scope.connectToOxBlue = () => {
      analytics.track('Connect to OxBlue - login', {
        category: 'Integration Action'
      })
      $scope.inTransit = true
      $scope.incorrectLoginError = false
      $scope.userOxBlueUserAlreadyConnected = false

      let customErrorHandler = (response) => {
        if (response.errorCode === CONFLICT && _.find(response.errorMessages,
            (m) => {
              return m.label === OXBLUE_USER_ALREADY_CONNECTED
            })) {
          $scope.userOxBlueUserAlreadyConnected = true
          return true
        } else if (response.errorCode === UNAUTHORIZED) {
          $scope.incorrectLoginError = true
          return true
        }

        return false
      }

      smartvidApi.loginInToOxBlue($scope.oxBlueUserId, $scope.oxBlueUserPassword, customErrorHandler).then((data) => {
        $timeout(function () {
          $scope.inTransit = false
          $rootScope.$broadcast('sv-connected-to-oxblue', data)
          $scope.modal.close()
        }, 100)
      }, () => {
        $scope.inTransit = false
      })
    }

    $scope.noUserIdOrPassword = () => {
      return $scope.oxBlueUserId === undefined || $scope.oxBlueUserId === '' ||
        $scope.oxBlueUserPassword === undefined || $scope.oxBlueUserPassword === ''
    }
  })
