/* global angular */

import { DashboardDataHelperService } from 'modules/core/services/dashboard-data-helper.service'
import { DialogUtilsService } from 'modules/core/services/dialog-utils.service'
import { ProjectGroupService } from 'modules/core/services/projectgroup.service'
import { Store } from '@ngxs/store'
import { UiConfigurationService } from 'modules/core/services/ui-configuration.service'
import { ForgeComponentChangeService } from 'modules/integrations/services/forge-component-change.service'
import { downgradeInjectable } from '@angular/upgrade/static'

angular.module('smartvid')
  .factory('projectGroupApi', downgradeInjectable(ProjectGroupService))
  .factory('ngxsStoreService', downgradeInjectable(Store))
  .factory('dialogUtils', downgradeInjectable(DialogUtilsService))
  .factory('uiConfigurationService', downgradeInjectable(UiConfigurationService))
  .factory('forgeComponentChangeService', downgradeInjectable(ForgeComponentChangeService))
