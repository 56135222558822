/* global angular, _, analytics */

angular.module('smartvid').factory('ProjectModel', function (BaseModel, smartvidApi, $q, $rootScope, currentUser) {
  class ProjectModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        createdTime: undefined, // timestamp
        deleted: undefined, // boolean
        location: undefined,
        name: undefined, // string
        updatedTime: undefined, // timestamp
        userProjectRole: undefined, // role of the user for this project
        organizationName: undefined, // String name of org project belongs to
        organizationId: undefined,
        projectGroupId: undefined,
        canListAllAssets: true,
        canUploadAsset: true,
        canUpdate: true,
        canRunReports: true,
        canCreateObservations: true,
        canEditObservations: true,
        canInviteUsers: true,
        hasInsights: undefined,
        hasObservationInsights: undefined,
        thumbnailUrl: undefined,
        users: [],
        isInactive: false,
        projectType: undefined,
        startDate: undefined,
        endDate: undefined,
        longitude: undefined,
        latitude: undefined,
        budget: undefined,
        addressLine1: undefined,
        city: undefined,
        postalCode: undefined,
        state: undefined,
        country: undefined
      }

      super(_.defaults(attrs || {}, defaults))
      this.favorite = this.isFavorite()
    }

    update (attrs, oiteratee = ProjectModel.defaultProjectUpdateOiterateePredicate) {
      super.update(attrs, oiteratee)
    }

    removeUsers (userIds) {
      let defer = $q.defer()
      analytics.track('Remove Project Member', {
        category: 'Project Action',
        orgName: (this.getProjectOrg()) ? this.getProjectOrg().name : undefined,
        projectName: this.name
      })
      smartvidApi.removeUsersFromProject(this.id, userIds).then(() => {
        this.users = _.filter(this.users, function (user) {
          return !_.contains(user.userId, userIds)
        })
        $rootScope.$broadcast('sv-project-members-uninvited-from-project', this.id, userIds)
        defer.resolve()
      }, () => {
        defer.reject()
      })
      return defer.promise
    }

    inviteUsers (users) {
      let defer = $q.defer()
      analytics.track('Add Project Member', {
        category: 'Project Action',
        orgName: (this.getProjectOrg()) ? this.getProjectOrg().name : undefined,
        projectName: this.name
      })
      smartvidApi.addUsersToProject(this.id, users).then((data) => {
        this.users = data.invitedUsers
        $rootScope.$broadcast('sv-project-members-invited-to-project', this.id, data.invitedUsers)
        defer.resolve()
      }, () => {
        defer.reject()
      })
      return defer.promise
    }

    getProjectActivationStatusText () {
      return this.isInactive ? 'Inactive' : 'Active'
    }

    getProjectOrg () {
      if (!this.projectOrg) {
        this.projectOrg = currentUser.getOrganization(this.organizationId)
      }
      return this.projectOrg
    }

    isFavorite () {
      return _.contains((currentUser.userPreferences && currentUser.userPreferences.favoriteProjectIds) || [], this.id)
    }

    static defaultProjectUpdateOiterateePredicate (value, key, model) {
      return angular.isDefined(value) && key !== 'project' && BaseModel.defaultOiterateePredicate(value, key, model)
    }
  }

  return ProjectModel
})
