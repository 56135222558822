import _ from 'lodash'
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import { StateService, TransitionService } from '@uirouter/core'
import { DashboardDataHelperService } from 'modules/core/services/dashboard-data-helper.service'
import { CURRENT_USER, CurrentUser, Utils, UTILS } from 'shared/smartvid.types'
import { ConfirmDialogComponent } from 'shared/components/confirm-dialog/confirm-dialog.component'
import { first } from 'rxjs/operators'
import { DialogUtilsService } from 'modules/core/services/dialog-utils.service'
import { Promise } from 'q'

@Component({
  selector: 'sv-project-item-menu',
  templateUrl: 'project-item-menu.component.html',
  styleUrls: ['project-item-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectItemMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  projectsCollection: any
  hovered: boolean
  isMobile: boolean
  showPropertiesOption = !_.isUndefined(this.stateService.current.name)
  starHovered: boolean
  optionsHovered: boolean
  options3dotsHovered: boolean
  isExpanded = false

  public static PRJ_MENU_FAV_ON_EVENT = 'PRJ_MENU_FAV_ON'
  public static PRJ_MENU_FAV_OFF_EVENT = 'PRJ_MENU_FAV_OFF'
  public static PRJ_MENU_INFO_EVENT = 'PRJ_MENU_INFO'

  @Input() currentProject: any

  @Input() set projects(projects: any) {
    this.projectsCollection = projects
    if (!this.projectsCollection) {
      return
    }
    this.projectsCollection.projectPromise.then(() => {})
  }

  get projects() {
    return this.projectsCollection
  }

  @Output() favorited: EventEmitter<void> = new EventEmitter<void>()

  constructor(
    private $element: ElementRef,
    public stateService: StateService,
    @Inject('contentSharingContext') public contentSharingContext: any,
    @Inject('searchResultService') public searchResultService: any,
    @Inject('projectFavoriteService') public projectFavoriteService: any,
    @Inject('NavController') public NavController: any,
    @Inject('smartvidApi') public smartvidApi: any,
    @Inject(CURRENT_USER) private currentUser: CurrentUser,
    @Inject(UTILS) private utils: Utils,
    public dashboardDataHelper: DashboardDataHelperService,
    private dialogUtils: DialogUtilsService,
    private transitionService: TransitionService
  ) {}

  ngOnInit() {
    this.hovered = false
    this.isMobile = false
    this.transitionService.onSuccess({ to: 'dashboard.projects.**' }, transition => {
      let params = transition.targetState().params()
      if (this.currentProject && this.currentProject.id === params.projectId) {
        this.isExpanded = true
      }
    })
  }

  ngOnDestroy() {
    this.isExpanded = false
  }

  isCrossProjectAssetView() {
    return this.searchResultService.isInGlobalSearchContext() || this.contentSharingContext.isGlobalSharingContext()
  }

  isMenuItemActive() {
    return this.isCurrentProjectState() && this.isExpanded
  }

  isCurrentProjectState() {
    return this.currentProject && this.currentProject.id === this.stateService.params['projectId']
  }

  isCurrentProjectInactive() {
    return this.currentProject.isInactive
  }

  isWithoutGroup() {
    return !this.currentProject.projectGroupId
  }

  getMenuItemHeightAsync() {
    return Promise(resolve => {
      if (!this.isMenuItemActive()) {
        const defaultHeight = '50px'
        resolve(defaultHeight)
      }
      let numberOptions = this.$element.nativeElement.querySelectorAll('.option').length
      resolve(55 + numberOptions * 32 + 'px')
    })
  }

  projectMenuClicked() {
    let project = this.currentProject

    if (project.hasInsights && !this.isCrossProjectAssetView()) {
      this.stateService.go('dashboard.projects.projectId.insights', {
        projectId: project.id,
        isDemoProject: project.isDemoProjectCopy,
        organizationId: project.organizationId,
        projectGroupId: project.projectGroupId,
      })
    } else {
      this.stateService.go('dashboard.projects.projectId.files', {
        projectId: project.id,
        isDemoProject: project.isDemoProjectCopy,
        searchContext: undefined,
        searchFilter: undefined,
        skipRecentSearches: true,
        organizationId: project.organizationId,
        projectGroupId: project.projectGroupId,
      })
    }
    this.isExpanded = !this.isExpanded
  }

  goTo(state: string, params: any) {
    this.stateService.go(state, params)
  }
  getStarIcon() {
    if (this.currentProject.favorite) {
      return 'star'
    } else {
      return 'star_border'
    }
  }
  doFavorite() {
    if (this.currentProject.favorite) {
      this.projectFavoriteService.unFavorite(
        this.currentProject,
        this.projectsCollection,
        ProjectItemMenuComponent.PRJ_MENU_FAV_OFF_EVENT
      )
      this.currentProject.favorite = false
    } else {
      this.projectFavoriteService.favorite(
        this.currentProject,
        this.projectsCollection,
        ProjectItemMenuComponent.PRJ_MENU_FAV_ON_EVENT
      )
      this.currentProject.favorite = true
    }
    this.favorited.emit(this.currentProject)
  }

  getChevronStyle() {
    if (this.canListAllAssets() && this.isMenuItemActive()) {
      let topMenuOffset = this.$element.nativeElement.querySelector('.menu-item-options').offsetTop
      let activeMenuElement = this.$element.nativeElement.querySelector('.option .active')
      let activeMenuOffset = activeMenuElement && activeMenuElement.offsetParent.offsetTop
      if (!topMenuOffset || !activeMenuOffset) {
        return { display: 'none' }
      }
      let result = activeMenuOffset + 10 + 'px'

      return { top: result }
    }
    return {}
  }

  canListAllAssets() {
    return this.currentProject && this.currentProject.canListAllAssets
  }

  shouldDisplayProjectOptions() {
    return !this.isMobile && this.currentProject.canUpdate && !this.isCrossProjectAssetView()
  }

  isState(state: string): boolean {
    return this.stateService.is(state)
  }

  getStarStyle(): any {
    return this.isCrossProjectAssetView() ? { 'margin-right': '5px' } : {}
  }

  getProjectNameWidthStyle() {
    if (this.isCrossProjectAssetView() && this.isMenuItemActive()) {
      return { 'max-width': '40%' }
    } else if (this.hovered || this.currentProject.favorite) {
      return { 'max-width': '53%' }
    } else {
      return {}
    }
  }

  isIntegrationState() {
    return (
      this.stateService.is('dashboard.projects.projectId.integrations') ||
      this.stateService.includes('dashboard.projects.projectId.integrations.*')
    )
  }
  getTooltipForProjectMenu() {
    if (this.isCrossProjectAssetView()) {
      const appender = this.isMenuItemActive() ? ' (' + this.currentProject.assetCount + ')' : ''
      return this.currentProject.name + appender
    } else {
      return this.currentProject.name
    }
  }

  showProjectProperties() {
    this.currentProject.selected = true

    this.projectsCollection.resetSelected()
    let found = this.projectsCollection.findById(this.currentProject.id)
    if (found) {
      found.selected = true
      this.projectFavoriteService.onShowProperties(found, ProjectItemMenuComponent.PRJ_MENU_INFO_EVENT)
    }

    this.NavController.openRightNav()
  }

  openConfirmDeleteDialog(): void {
    const dialogRef = this.dialogUtils.open(ConfirmDialogComponent, {
      width: '400px',
      height: '250px',
      data: {
        title: 'project.confirm.delete.header',
        content: 'project.confirm.delete.message',
        confirm: 'common.delete',
        discard: 'common.cancel',
      },
    })
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(confirm => {
        if (confirm) {
          this.removeProject(this.currentProject)
        }
      })
  }

  removeProject(project: any) {
    this.smartvidApi.deleteProjects([project.id], false).then(() => {
      this.currentProject.update({ newlyAdded: true })
      this.projectsCollection.removeById(project.id)
      this.dashboardDataHelper.getAllProjects().removeById(project.id)

      //$rootScope.$broadcast('sv-project-deleted', {projectId: project.id})
      if (this.currentProject.projectGroupId) {
        this.stateService.go(
          'dashboard.projects.orgId.projectGroupId',
          {
            organizationId: this.currentProject.organizationId,
            projectGroupId: this.currentProject.projectGroupId,
          },
          { reload: true }
        )
      } else {
        this.stateService.go(
          'dashboard.projects.orgId',
          {
            organizationId: this.currentProject.organizationId,
          },
          { reload: true }
        )
      }
      this.utils.notify('common.projectMovedToDeletedFolder', '', 'common.view', null, () => {
        this.stateService.go(
          'dashboard.adminOrganizations.organizationId.deletedProjects',
          {
            organizationId: project.organizationId,
          },
          {
            reload: true,
          }
        )
      })
    })
  }
  getProjectById() {
    if (!this.projectsCollection) return undefined
    const projectId = this.stateService.params['projectId']
    return this.projectsCollection.models.find(p => p.id === projectId)
  }

  ngAfterViewInit(): void {
    const projectById = this.getProjectById()
    if (projectById && projectById.id === this.currentProject.id) {
      this.isExpanded = true
    }
  }
}
