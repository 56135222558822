/* global angular */

angular.module('smartvid').controller('ToolsOptionsCtrl', function ($scope, $rootScope, $timeout) {
  $scope.handleSelection = (shape) => {
    if ($scope.newComment) {
      handleNewComment(shape)
    } else if ($scope.newTag) {
      handleNewTag(shape)
    }
  }

  $scope.isSelectedShape = (shape) => {
    return ($scope.newComment && $scope.newComment.shape === shape) || ($scope.newTag && $scope.newTag.markup && $scope.newTag.markup.shape === shape)
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top,
      left: $scope.styles.left + 5
    }
  }

  function handleNewComment (shape) {
    handleNewInternal($scope.newComment, shape)
    $timeout(() => {
      $rootScope.$broadcast('sv-set-comment-markup')
    }, 10)
  }

  function handleNewTag (shape) {
    $scope.newTag.markup = $scope.newTag.markup || {}
    handleNewInternal($scope.newTag.markup, shape)
    $timeout(() => {
      $rootScope.$broadcast('sv-set-tag-markup')
    }, 10)
  }

  function handleNewInternal (modelMarkup, shape) {
    modelMarkup.shape = shape
    modelMarkup.isManual = true
    $scope.show = false
    $scope.flyout.close()
  }
})
