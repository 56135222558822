/* global angular */
angular.module('templates', [])

importAll(
  require.context('./templates', true, /.*\.html$/)
)

importAll(
  require.context('../../views', true, /.*\.html$/)
)

importAll(
  require.context('../../../node_modules/angular-ui-notification/src', true, /.*\.html$/)
)

function importAll (r) {
  r.keys().forEach(r);
}
