import { Directive, ElementRef, Injector } from '@angular/core'
import { UpgradeComponent } from '@angular/upgrade/static'

@Directive({
  selector: 'svVideoGrid',
})
export class VideoGridDirective extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('videoGrid', elementRef, injector)
  }
}
