/* global angular */

angular.module('smartvid').factory('StrucitonSiteProjectInfoCollection', function (BaseCollection, StructionSiteProjectInfoModel) {
  class StrucitonSiteProjectInfoCollection extends BaseCollection {
    constructor (models) {
      super(models, StructionSiteProjectInfoModel)
    }
  }

  return StrucitonSiteProjectInfoCollection
})
