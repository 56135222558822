/* global angular */
angular.module('smartvid').service('navStateService', function (currentUser, $rootScope) {
  // const VERSION = 'v1'                  // TODO (rrubbico) : use this the bust older versions in local storage
  const Categories = {
    'VIEW': 'VIEW',                     // Pages where there is a 'view' into a collection (project grid, asset grid, integrations grid ...)
    'PAGE': 'PAGE',                     // Pages where a user can update the properties of something. No right nav for these pages. (profile pages, integration pages ...)
    'MEDIA_REVIEWER': 'MEDIA_REVIEWER'  // Pages where video or image content is viewed (shared asset viewer, asset viewer ...)
  }

  let service = {
    setRightNavState: (stateName, booleanOpen) => {
      if (!stateName) { return }
      let category = getCategoryFromStateName(stateName)
      if (!category) { return }
      booleanOpen = stateName === Categories.PAGE ? false : booleanOpen // Don't let a PAGE ever be set to open here
      window.localStorage.setItem('RIGHT-NAV-' + category + currentUser.id, !!booleanOpen)
    },
    setLeftNavState: (stateName, booleanOpen) => {
      if (!stateName) { return }
      let category = getCategoryFromStateName(stateName)
      if (!category) { return }
      window.localStorage.setItem('LEFT-NAV-' + category + currentUser.id, !!booleanOpen)
    },
    getRightNavState: (stateName) => {
      let category = getCategoryFromStateName(stateName)
      if (!category) { category = Categories.PAGE }
      let openForUserProject = window.localStorage.getItem('RIGHT-NAV-' + category + currentUser.id) === 'true'
      let unsetForUserProject = window.localStorage.getItem('RIGHT-NAV-' + category + currentUser.id) === null
      let isMediaReviewer = category === Categories.MEDIA_REVIEWER
      let isPage = category === Categories.PAGE
      let booleanOpen = !isPage && (openForUserProject || (unsetForUserProject && isMediaReviewer))
      return booleanOpen && !$rootScope.isMobile
    },
    getLeftNavState: (stateName) => {
      let category = getCategoryFromStateName(stateName)
      if (!category) { category = Categories.PAGE }
      let booleanOpen = (window.localStorage.getItem('LEFT-NAV-' + category + currentUser.id) === 'true' ||
                        window.localStorage.getItem('LEFT-NAV-' + category + currentUser.id) === null)
      return booleanOpen
    }
  }

  let stateToCategoryMap = {
    'dashboard.projects': Categories.VIEW,
    'dashboard.projects.projectId': Categories.VIEW,
    'dashboard.projects.projectId.files': Categories.VIEW,
    'dashboard.projects.projectId.deletedfiles': Categories.VIEW,
    'dashboard.projects.projectId.tagsmanager': Categories.VIEW,
    'dashboard.projects.projectId.profile': Categories.PAGE,
    'dashboard.projects.projectId.members': Categories.VIEW,
    'dashboard.projects.projectId.members.memberProfile': Categories.PAGE,
    'dashboard.projects.projectId.integrations': Categories.PAGE, // Make page so no right nav
    'dashboard.projects.projectId.integrations.procore': Categories.PAGE,
    'dashboard.projects.projectId.integrations.egnyte': Categories.PAGE,
    'dashboard.projects.projectId.integrations.bim_360_field': Categories.PAGE,
    'dashboard.adminOrganizations': Categories.VIEW,
    'dashboard.adminOrganizations.organizationId': Categories.VIEW,
    'dashboard.adminOrganizations.organizationId.organizationProfile': Categories.PAGE,
    'dashboard.adminOrganizations.organizationId.organizationSettings': Categories.PAGE,
    'dashboard.adminOrganizations.organizationId.userProfile': Categories.PAGE,
    'dashboard.adminOrganizations.organizationId.tagsmanager': Categories.VIEW,
    'dashboard.myprofile': Categories.PAGE,
    'dashboard.mysettings': Categories.PAGE,
    'dashboard.projects.projectId.files.viewer': Categories.MEDIA_REVIEWER,
    'dashboard.projects.viewer': Categories.MEDIA_REVIEWER,
    'dashboard.observations.orgId.projectGroupId.projectId.viewer': Categories.VIEW
  }

  let getCategoryFromStateName = (stateName) => {
    return stateToCategoryMap[stateName]
  }

  return service
})
