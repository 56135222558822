/* global angular */
angular.module('smartvid').service('thumbnailCarouselStateService', function () {
  let defaults = {
    currentIndex: undefined,
    selectedAssetIndex: undefined,
    transitionType: undefined,
    collection: undefined,
    collectionMetaData: {
      isSearchCollection: false
    },
    nextPageInfo: {
      fetching: false,
      data_loaded: true,
      fromIndex: 0,
      toIndex: 0
    },
    prevPageInfo: {
      fetching: false,
      data_loaded: true,
      fromIndex: 0,
      toIndex: 0
    },
    reset: reset,
    resetToBeginning: resetToBeginning,
    isCarouselEnabled: isCarouselEnabled
  }

  let service = { reset: reset }

  function reset () {
    return angular.copy(defaults, service)
  }

  function resetToBeginning () {
    let beginningDefaults = angular.copy(defaults)
    beginningDefaults.currentIndex = 0
    beginningDefaults.selectedAssetIndex = 0
    beginningDefaults.collectionMetaData = service.collectionMetaData
    return angular.copy(beginningDefaults, service)
  }

  function isCarouselEnabled () {
    return this.nextPageInfo.data_loaded && this.prevPageInfo.data_loaded
  }

  return service.reset()
})
