/* global angular */

angular.module('smartvid').directive('formHelpGlyph', function () {
  let helpId = 1
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'forms/form-fields/form-help-glyph.html',
    link ($scope, $element, $attrs) {
      $scope.isVisible = false
      $scope.id = helpId++
    }
  }
})
