/* global angular, _ */

angular.module('smartvid').factory('TagDefCollection', function (BaseCollection, TagDefModel, tagsApi, $q) {
  function getRefreshPromise () {
    let defer = $q.defer()
    getUpdateTime.call(this).then((newUpdateTime) => {
      if (newUpdateTime > this.lastUpdateTime) {
        let tagsPromise = (this.projectId) ? tagsApi.getProjectTags(this.projectId, this.includeOrgTags) : tagsApi.getOrgTags(this.orgId)
        tagsPromise.then((tagsCollection) => {
          this.upsert(tagsCollection, TagDefModel)
          this.lastUpdateTime = newUpdateTime
          defer.resolve()
        }, () => {
          defer.reject()
        })
      } else {
        defer.resolve()
      }
    }, () => {
      defer.reject()
    })
    return defer.promise
  }

  function getUpdateTime () {
    return (this.projectId) ? tagsApi.getProjectTagsUpdateTime(this.projectId) : tagsApi.getOrgTagsUpdateTime(this.orgId)
  }

  class TagDefCollection extends BaseCollection {
    constructor (orgId, projectId, includeOrgTags) {
      super(undefined, TagDefModel)
      this.orgId = orgId
      this.projectId = projectId
      this.lastUpdateTime = -1
      this.isFetching = false
      this.includeOrgTags = includeOrgTags !== undefined ? includeOrgTags : true
      this.refresh()
    }

    refresh () {
      if (this.isFetching) {
        return
      }
      this.isFetching = true
      let tagsPromise = getRefreshPromise.call(this)
      tagsPromise.finally(() => {
        this.isFetching = false
      })
      this.loadingPromise = tagsPromise
      return this.loadingPromise
    }

    findTagByText (text) {
      return _.find(this.models, (tagDef) => {
        return tagDef.text.toLowerCase() === text.toLowerCase()
      })
    }

    findTagsByPartialText (text) {
      let lowerText = text.toLowerCase()
      return _.filter(this.models, (tagDef) => {
        return (tagDef.text.toLowerCase().indexOf(lowerText) !== -1)
      })
    }

    findManualTags () {
      return _.filter(this.models, (tagDef) => {
        return !!tagDef.isAllowManual || angular.isUndefined(tagDef.isAllowManual)
      })
    }

    getFromServerAndUpsert (id) {
      let defer = $q.defer()
      tagsApi.getTagWithAlias(id).then((tagDef) => {
        this.upsert(tagDef)
        defer.resolve(tagDef)
      })
      return defer.promise
    }

    upsert (tagDefinitions) {
      super.upsert(tagDefinitions, TagDefModel)
    }
  }

  return TagDefCollection
})
