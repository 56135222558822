/* global angular, $ */

angular.module('smartvid').directive('childrenPageKeysScroll', function () {
  return {
    restrict: 'A',
    replace: true,
    link: function (scope, element) {
      // Support page up/down keys on children of a given element
      element = element.children()
      let listener = $(window).on('keydown', function (event) {
        if (($('*:focus').get(0))) {
          return
        }
        if (event.which === 33) { // page up
          event.preventDefault()
          element.scrollTop(element.scrollTop() - element.innerHeight())
        } else if (event.which === 34) { // page down
          event.preventDefault()
          element.scrollTop(element.scrollTop() + element.innerHeight())
        }
      })
      scope.$on('$destroy', function () {
        $(window).off('keydown', listener)
      })
    }
  }
})
