import { Injectable } from '@angular/core'
import { Actions, State, Store, StateContext, Action } from '@ngxs/store'

export enum ObservationHomeContentType {
  NOT_INITIALIZED,
  OBSERVATION_INSIGHTS,
  OBSERVATION_LIST,
}

export interface ObservationProjectHomeStateModel {
  projectId: string
  observationHomeContentType: ObservationHomeContentType
}

export class ViewObservationNone {
  static readonly type = '[ObservationHome] ViewObservationNone'

  constructor() {}
}

export class ViewObservationList {
  static readonly type = '[ObservationHome] ViewObservationList'

  constructor(public projectId: string) {}
}

export class ViewObservationInsights {
  static readonly type = '[ObservationHome] ViewObservationInsights'

  constructor(public projectId: string) {}
}

@State<ObservationProjectHomeStateModel>({
  name: 'observationHome',
  defaults: {
    projectId: undefined,
    observationHomeContentType: ObservationHomeContentType.NOT_INITIALIZED,
  },
})
@Injectable()
export class ObservationProjectHomeState {
  constructor(private store: Store, private actions$: Actions) {}

  @Action(ViewObservationInsights)
  viewObservationInsights(
    ctx: StateContext<ObservationProjectHomeStateModel>,
    viewObservationList: ViewObservationList
  ) {
    ctx.setState({
      projectId: viewObservationList.projectId,
      observationHomeContentType: ObservationHomeContentType.OBSERVATION_INSIGHTS,
    })
  }

  @Action(ViewObservationNone)
  ViewObservationNone(ctx: StateContext<ObservationProjectHomeStateModel>) {
    ctx.setState({
      projectId: undefined,
      observationHomeContentType: ObservationHomeContentType.NOT_INITIALIZED,
    })
  }

  @Action(ViewObservationList)
  viewObservationList(
    ctx: StateContext<ObservationProjectHomeStateModel>,
    viewObservationInsights: ViewObservationInsights
  ) {
    ctx.setState({
      projectId: viewObservationInsights.projectId,
      observationHomeContentType: ObservationHomeContentType.OBSERVATION_LIST,
    })
  }
}
