/* global angular */

angular.module('smartvid').service('egnyteIntegrationApi', function ($http, $q, $log, config) {
  let rootUrl = config.env.development.apiRootUrl

  let api = {
    getEgnyteFolders (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/egnyte/folder`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getEgnyteFolders', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getEgnyteChildrenFolders (projectId, path, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/egnyte/folder?path=${path}`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getEgnyteChildrenFolders', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getEgnyteUserInfo (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/user/info`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getEgnyteUserInfo', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    }

  }

  return api
})
