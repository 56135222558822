/* global angular, _ */

angular.module('smartvid').factory('InvitedProjectUserModel', function (BaseModel, $filter) {
  class InvitedProjectUserModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        userId: undefined,
        role: undefined,
        observationRole: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        isInvited: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }

    getName () {
      return (this.firstName || '') + ' ' + (this.lastName || '')
    }

    getRolei18nLabel () {
      return $filter('i18next')('project.roleLabelsNoBreak.' + this.role)
    }

    getObservationRolei18nLabel () {
      if (!this.observationRole) {
        return $filter('i18next')('project.observationRoleLabelsNoBreak.NON_MEMBER')
      }
      return $filter('i18next')('project.observationRoleLabelsNoBreak.' + this.observationRole)
    }

    getStatus () {
      return this.isInvited ? $filter('i18next')('user.organizationUserStatus.invited')
        : $filter('i18next')('user.organizationUserStatus.active')
    }

  }

  return InvitedProjectUserModel
})
