/* global angular */

angular.module('smartvid').directive('assetViewControlbar', function (
    $state, $stateParams, $timeout, $rootScope, secretSlideshowHelper
) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'AssetViewer/controlbar.html',
    link (scope) {
      scope.readOnly = scope.asset.isReadOnly
      scope.downloadingAssetFrame = false

      scope.openShareModal = openShareModal
      scope.commentsActive = commentsActive
      scope.tagsActive = tagsActive
      scope.mobileBackToAssets = mobileBackToAssets
      scope.downloadFrame = downloadFrame
      scope.toggleSecretSlideshow = toggleSecretSlideshow

      scope.$on('sv-download-asset-frame-complete', onDownloadAssetFrameComplete)

      function onDownloadAssetFrameComplete () {
        scope.downloadingAssetFrame = false
      }

      /**
       * Share asset */
      function openShareModal () {
        if (scope.asset) {
          scope.modal.open('shareAsset', {
            assets: [scope.asset],
            currentProject: scope.currentProject
          })
        }
      }

      function commentsActive () {
        return scope.asset.comments.getSelectedComment()
      }

      function tagsActive () {
        return scope.asset.tags.getLocal() || scope.asset.tags.getSelectedTag()
      }

      function mobileBackToAssets () {
        $state.go('dashboard.projects.projectId.files', {projectId: $state.params.projectId})
        $timeout(() => {
          $rootScope.isCapture = false
          $rootScope.$broadcast('sv-mobile-update-capture-mode')
        })
      }

      function downloadFrame () {
        scope.downloadingAssetFrame = true
        $timeout(() => {
          $rootScope.$broadcast('sv-download-asset-frame')
        })
      }

      function toggleSecretSlideshow ($event) {
        let active = !!$rootScope.secretSlidshowActive
        switch (active) {
          case true:
            secretSlideshowHelper.endSlideshow()
            break
          case false:
            secretSlideshowHelper.startSlideshow()
            break
        }
        return true
      }
    }
  }
})
