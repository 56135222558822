/* global angular */

angular.module('smartvid').factory('DefaultGridSupplier', function (AddItemPlaceholder) {
  const ADD_ITEM_PLACEHOLDER = new AddItemPlaceholder() // signal 'add' placard location (Upload File, Add Member...)
  class DefaultGridSupplier {
    constructor (collection, includeAddItemPlaceholder = true, itemsPerRow = 1) {
      this.collection = collection
      this.itemsPerRow = itemsPerRow
      this.includeAddItemPlaceholder = includeAddItemPlaceholder
    }
    getItem (rowIdx, rowItemIdx) {
      let index = rowIdx * this.itemsPerRow + rowItemIdx
      return (index === this.collection.models.length && this.includeAddItemPlaceholder)
          ? ADD_ITEM_PLACEHOLDER : this.collection.models[index]
    }
    getTotalRowCount () {
      var addItemPlacardAffordance = this.includeAddItemPlaceholder ? 1 : 0
      return Math.max(Math.ceil((this.collection.models.length + addItemPlacardAffordance) / this.itemsPerRow), 1)
    }
    setItemsPerRow (newItemsPerRow) {
      this.itemsPerRow = Math.max(newItemsPerRow, 1)
    }
  }

  return DefaultGridSupplier
})
