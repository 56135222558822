/* global angular, _ */
/**
 * Created by ryanrubbico on 5/16/17.
 */
angular.module('smartvid').factory('TagInstanceGroupCollection', function (BaseCollection) {
  class TagInstanceGroup extends BaseCollection {
    constructor (models, SuppliedModel) {
      super(models, SuppliedModel)
      let referenceModel = this.models && this.models[0]
      this.id = referenceModel && referenceModel.tagDefinitionId
      this.text = referenceModel && referenceModel.text
      this.hovered = false
      this.selected = false
      this.allVerified = this.isVerified()
    }

    isVerified () {
      this.allVerified = !_.any(this.models, (model) => { return !model.isVerified() })
      return this.allVerified
    }

    unselectAll () {
      _.each(this.models, (model) => {
        model.selected = false
      })
    }
  }
  return TagInstanceGroup
})
