/* global angular, _ */

angular.module('smartvid').directive('projectMemberProfile', function ($stateParams, smartvidApi, modal, utils,
                                                                       $timeout, $state, $filter, currentUser, $q) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'ProjectMemberGrid/project-member-profile.html',
    link: function (scope) {
      scope.loading = false
      scope.updateLoading = false
      scope.reinviting = false
      scope.removing = false
      scope.assignableRoles = {}
      scope.assignableObservationRoles = {}
      scope.projectMember = undefined
      scope.model = {
        role: '',
        observationRole: '',
        isUploadNotification: false,
        isIncludeTimestampDownload: false
      }
      scope.canUpdate = false
      scope.canUpdateRoles = false
      scope.allProjectRolls = _.where(angular.copy(currentUser.projectRoles), {manuallyAssignable: true})
      scope.hasObservations = currentUser.isObservationEnabledForOrganization(scope.currentProject.organizationId)
      scope.observationProjectRoles = _.where(angular.copy(currentUser.observationProjectRoles), {manuallyAssignable: true})
      scope.observationProjectRoles.unshift({
        name: 'NON_MEMBER',
        displayName: 'None',
        description: 'Cannot access project observations'
      })

      scope.rolesWithProjectNotifications = _.map(
        _.where(angular.copy(currentUser.projectRoles), {canReceiveUploadNotification: true}),
        (role) => role.name)
      scope.rolesWithUserSettings = _.map(
        _.where(angular.copy(currentUser.projectRoles), {isSupportsUserSettings: true}),
        (role) => role.name)

      scope.updatableRolesForMe = currentUser.getAssignableProjectRoles(scope.currentProject)
      scope.updatableObservationRolesForMe = currentUser.getAssignableObservationProjectRoles(scope.currentProject)

      scope.reinviteUserToSmartvidAndProject = function () {
        scope.reinviting = true
        smartvidApi.reinviteUserToSmartvidAndProject(scope.currentProject.id, scope.projectMember.email).then(() => {
          // success
          utils.notify($filter('i18next')('directives.reinviteUser.invitationSent') + scope.projectMember.email, '', '', false, null)
          $timeout(() => {
            scope.reinviting = false
          }, 800)
        }, (data) => {
          // failure
          utils.notify($filter('i18next')('directives.reinviteUser.failedInvitation'), '' + scope.projectMember.email, '', false, null)
          $timeout(() => {
            scope.reinviting = false
          }, 800)
        })
      }

      scope.unInviteProjectMember = () => {
        let confirmHandlers = {
          confirm () {
            scope.currentProject.removeUsers([scope.projectMember.userId]).then(() => {
              let params = {projectId: scope.currentProject.id, isDemoProject: scope.currentProject.isDemoProjectCopy}
              $state.go('dashboard.projects.projectId.members', params)
            }, () => {
            })
          },
          hasSelections: true
        }
        modal.open('unInviteProjectMember', confirmHandlers)
      }

      let updateProjectMemberSuccess = (response) => {
        utils.notify('Project member updated', '', '', false, null)
        scope.projectMember.update(response)
        $timeout(() => {
          scope.updateLoading = false
        }, 800)
      }

      let updateProjectMemberFailure = () => {
        utils.notify('Failed to update project member', '', '', false, null)
        $timeout(() => {
          scope.updateLoading = false
        }, 800)
      }

      scope.updateProjectMember = () => {
        let projectMember = scope.projectMember
        let model = scope.model
        if (projectMember.role === model.role &&
            projectMember.observationRole === model.observationRole &&
          projectMember.isUploadNotification === model.isUploadNotification &&
          projectMember.isIncludeTimestampDownload === model.isIncludeTimestampDownload) {
          return
        }
        scope.updateLoading = true
        let canUpdateUserSettings = (scope.canUpdate || scope.projectMember.canUpdateNotificationSettings) &&
          scope.rolesWithUserSettings.includes(model.role)

        let updateFilesRole = () => {
          smartvidApi.updateUserForProject(
            scope.currentProject.id,
            scope.projectMember.userId,
            scope.canUpdate ? model.role : undefined,
            scope.projectMember.canUpdateNotificationSettings ? model.isUploadNotification : undefined,
            canUpdateUserSettings ? model.isIncludeTimestampDownload : undefined,
            model.observationRole === 'NON_MEMBER' ? undefined : model.observationRole)
            .then(updateProjectMemberSuccess, updateProjectMemberFailure)
        }

        updateFilesRole()
      }

      initializeScope()
      // ////////////////////////////////////////////////////////////////////////////

      function canUpdate (roleName) {
        return !_.isEmpty(_.findWhere(scope.updatableRolesForMe, {'name': roleName}))
      }

      function canUpdateObservation (roleName) {
        return !_.isEmpty(_.findWhere(scope.updatableObservationRolesForMe, {'name': roleName}))
      }

      function getMemberPromise () {
        let deferred = $q.defer()
        let projectMember = _.findWhere(scope.projectMembers.models, {userId: $stateParams.userId})
        if (projectMember) {
          deferred.resolve(projectMember)
        } else {
          smartvidApi.getProjectUser($stateParams.projectId, $stateParams.userId).then((invitedProjectMember) => {
            deferred.resolve(invitedProjectMember)
          })
        }
        return deferred.promise
      }

      function initializeScope () {
        getMemberPromise().then((member) => {
          scope.$evalAsync(() => {
            scope.projectMember = member

            scope.model.isUploadNotification = scope.projectMember.isUploadNotification
            scope.model.isIncludeTimestampDownload = scope.projectMember.isIncludeTimestampDownload

            scope.model.role = scope.projectMember.role
            scope.model.observationRole = scope.projectMember.observationRole || 'NON_MEMBER'
            scope.canUpdate = scope.projectMember.canUpdate
            scope.canUpdateRoles = scope.canUpdate && member.userId !== currentUser.id
            // create map of assignable roles
            if (scope.projectMember.canUpdate) {
              _.each(scope.allProjectRolls, (projectRole) => {
                scope.assignableRoles[projectRole.name] = canUpdate(projectRole.name)
              })
            }
            // create map of assignable roles
            if (scope.projectMember.canUpdate) {
              _.each(scope.observationProjectRoles, (observationProjectRole) => {
                scope.assignableObservationRoles[observationProjectRole.name] = canUpdateObservation(observationProjectRole.name)
              })
              scope.assignableObservationRoles['NON_MEMBER'] = true
            }
            scope.loading = false
          })
        })
      }
    }
  }
})
