/* global angular */

angular.module('smartvid').service('sphericalUtils', function (
    SPHERICAL_MARKUP_RADIUS
) {
  class SphericalUtils {
    dziTo360 (dziMarkup, width, height) {
      let yaw = -Math.PI + 2 * Math.PI * (dziMarkup.leftOriginal + (dziMarkup.widthOriginal / 2)) / width
      let pitch = -Math.PI / 2 + Math.PI * (dziMarkup.topOriginal + (dziMarkup.heightOriginal / 2)) / height

      return {
        yaw: yaw,
        pitch: pitch,
        width: dziMarkup.widthOriginal,
        height: dziMarkup.heightOriginal
      }
    }
    rawXYTo360 (rawXYMarkup, width, height) {
      // TODO rrubbico
    }
    getHospotRadius () {
      return SPHERICAL_MARKUP_RADIUS
    }

    getHospotSphereCircumference () {
      return 2 * Math.PI * SPHERICAL_MARKUP_RADIUS
    }

    radiansToMarkupArcLength (radians) {
      let circumference = this.getHospotSphereCircumference()
      return this.radiansToArcLength(radians, circumference)
    }

    radiansToArcLength (radians, circumference) {
      return circumference * (radians / (2 * Math.PI))
    }

  }

  return new SphericalUtils()
})
