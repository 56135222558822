import { Component, Inject, Input, OnInit } from '@angular/core'
import { DataSource } from '@angular/cdk/table'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, finalize, first } from 'rxjs/operators'
import { ReportsDashboardService } from 'modules/insights/dashboard/services/reports-dashboard-service'
import {
  ReportDashboardModel,
  ReportName,
  ScheduleInterval,
} from 'modules/insights/dashboard/models/report-dashboard.model'
import { ConfirmDialogComponent } from 'shared/components/confirm-dialog/confirm-dialog.component'
import { DialogUtilsService } from 'modules/core/services/dialog-utils.service'

export class ReportsDataSource implements DataSource<ReportDashboardModel> {
  loadingSubject$ = new BehaviorSubject<boolean>(false)
  renderData$ = new BehaviorSubject<ReportDashboardModel[]>([])
  reportsCount: number

  constructor(private reportsDashboardService: ReportsDashboardService) {}

  connect(): Observable<ReportDashboardModel[]> {
    return this.renderData$.asObservable()
  }

  disconnect() {
    this.renderData$.complete()
    this.loadingSubject$.complete()
  }

  loadNextPage(reportType: ScheduleInterval, organizationId: string) {
    this.loadingSubject$.next(true)
    this.reportsDashboardService
      .getReports(organizationId, reportType)
      .pipe(
        catchError(() => {
          return of([])
        }),
        finalize(() => this.loadingSubject$.next(false))
      )
      .subscribe(result => {
        this.renderData$.next(result)
        this.reportsCount = result.length
      })
  }
}

@Component({
  selector: 'sv-reports-table',
  templateUrl: 'reports-table.component.html',
  styleUrls: ['reports-table.component.scss'],
})
export class ReportsTableComponent implements OnInit {
  @Input() reportType: ScheduleInterval
  @Input() organizationId: string

  dataSource: ReportsDataSource
  displayedColumns: string[] = ['nextScheduled', 'reportType', 'createdBy', 'projects', 'tags', 'actions']
  reportTypeAdapter = ScheduleInterval

  constructor(
    private reportsDashboardService: ReportsDashboardService,
    @Inject('$rootScope') public rootScope: any,
    private dialogUtils: DialogUtilsService
  ) {
    this.dataSource = new ReportsDataSource(reportsDashboardService)
  }

  ngOnInit() {
    this.dataSource.loadNextPage(this.reportType, this.organizationId)
  }

  activateReport(report: ReportDashboardModel) {
    this.reportsDashboardService
      .activateReport(this.organizationId, report.reportId, !report.isInactive)
      .subscribe(() => {
        this.dataSource.renderData$.getValue().find(r => r.reportId === report.reportId).isInactive = !report.isInactive
      })
  }

  deleteReport(report: ReportDashboardModel) {
    const dialogRef = this.dialogUtils.open(ConfirmDialogComponent, {
      width: '300px',
      height: '150px',
      data: {
        title: 'reporting.confirm.delete.header',
        content: 'reporting.confirm.delete.message',
        confirm: 'common.delete',
        discard: 'common.cancel',
      },
    })
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(confirm => {
        if (confirm) {
          this.reportsDashboardService.deleteReport(this.organizationId, report.reportId).subscribe(() => {
            const filteredReports = this.dataSource.renderData$.getValue().filter(r => r.reportId !== report.reportId)
            this.dataSource.renderData$.next(filteredReports)
            this.dataSource.reportsCount = filteredReports.length
          })
        }
      })
  }

  runReport(report: ReportDashboardModel) {
    if (report.reportId) {
      this.reportsDashboardService
        .reRuScheduledReport(report.reportId)
        .subscribe(() => this.openReRunReportModal(report))
    } else {
      if (report.reportName === ReportName.PHOTO_REPORT) {
        this.reportsDashboardService
          .reRenRunOneTimePhotoReport(report.projectIds[0])
          .subscribe(() => this.openReRunReportModal(report))
      } else {
        this.reportsDashboardService.reRenRunOneTimeSafetyReport().subscribe(() => this.openReRunReportModal(report))
      }
    }
  }

  editReport(report: ReportDashboardModel) {
    if (report.reportId) {
      this.reportsDashboardService
        .getScheduledReport(report.reportId)
        .subscribe(r => this.openEditReportModal(report.reportName, r))
    } else {
      if (report.reportName === ReportName.PHOTO_REPORT) {
        this.reportsDashboardService
          .getOneTimePhotoReport(report.projectIds[0])
          .subscribe(r => this.openEditReportModal(ReportName.PHOTO_REPORT, r.savedReportGenerationParameters))
      } else {
        this.reportsDashboardService
          .getOneTimeSafetySummaryReport(this.organizationId)
          .subscribe(r => this.openEditReportModal(ReportName.SAFETY_SUMMARY_REPORT, r.savedReportGenerationParameters))
      }
    }
  }

  private openEditReportModal(reportName: string, report) {
    return this.rootScope.modal.open('editReport', {
      selectedReportType: reportName,
      report: report,
      confirm: () => {
        this.reportsDashboardService.getReports(this.organizationId, this.reportType).subscribe(result => {
          this.dataSource.renderData$.next(result)
          this.dataSource.reportsCount = result.length
        })
      },
    })
  }

  private openReRunReportModal(report: ReportDashboardModel) {
    return this.rootScope.modal.open('reRunReport', { toUserEmails: report.toUserEmails })
  }
}
