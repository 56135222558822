/* global angular, _ */
/**
 * Created by ryanrubbico on 8/4/17.
 */
angular.module('smartvid').filter('unique', function () {
  return function orderedUniqueFn (items, filterProp) {
    function getPropValue (item, filterProp) {
      return (_.isObject(item) && _.isString(filterProp)) ? item[filterProp] : item
    }

    let filteredItems = []
    let hashSet = new Set()

    angular.forEach(items, function (item) {
      if (!hashSet.has(getPropValue(item, filterProp))) {
        hashSet.add(getPropValue(item, filterProp))
        filteredItems.push(item)
      }
    })

    hashSet.clear()
    return filteredItems
  }
})
