/* global angular */
angular.module('smartvid').directive('svTabHeading', function () {
  return {
    restrict: 'E',
    transclude: true,
    require: '^svTab',
    scope: {
      tooltipText: '@?'    // Optional tooltip text on hover
    },
    bindToController: true,
    controllerAs: 'tabHeading',
    controller: function ($scope, $element, $attrs) {},
    link: function (scope, elem, attrs, tabCtrl, transclude) {
      var element
      scope.getHeadingElement = function () {  // returns the content of the tab heading element for transclusion elsewhere
        element = transclude()
        return element
      }
      scope.$on('$destroy', function () {
        element.off()
        element.remove()
      })
      tabCtrl.headingElem = scope.getHeadingElement()
    }
  }
})
