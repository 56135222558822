/* global angular */
/**
 * Created by plitvak on 1/11/16.
 */

angular.module('smartvid').service('imageService', function () {
  function getWidthForHeightByAspect (height, aspect) {
    return height * aspect
  }

  function getHeightForWidthByAspect (width, aspect) {
    return width / aspect
  }

  function isSimilar (first, second) {
    return Math.abs(first - second) <= 0.01
  }

  function getNewDimensions (isOriginalInLandscape, targetWidth, targetHeight, aspect) {
    var newWidth = targetWidth * 1.0
    var newHeight = targetHeight * 1.0

    if (isOriginalInLandscape) {
      newHeight = getHeightForWidthByAspect(newWidth, aspect)
      if (newHeight > targetHeight) {
        newHeight = targetHeight
        newWidth = getWidthForHeightByAspect(newHeight, aspect)
      }
    } else {
      newWidth = getWidthForHeightByAspect(newHeight, aspect)
      if (newWidth > targetWidth) {
        newWidth = targetWidth
        newHeight = getHeightForWidthByAspect(newWidth, aspect)
      }
    }

    return {newWidth: newWidth, newHeight: newHeight}
  }

  let ImageService = {

    getScaleDataToFitTargetPreservingOriginalAspect (originalImage, targetWidth, targetHeight) {
      let isOriginalInLandscape = originalImage.width > originalImage.height
      let originalAspect = originalImage.width / originalImage.height
      let targetAspect = targetWidth / targetHeight

      if (isSimilar(originalAspect, targetAspect)) {
        return {
          clipW: originalImage.width,
          clipH: originalImage.height,
          newWidth: Math.floor(targetWidth),
          newHeight: Math.floor(targetHeight)
        }
      }

      // Fit the original image in to the target preserving originals aspect ration. This results in the letter box
      // around the image.
      let newDimensions = getNewDimensions(isOriginalInLandscape, targetWidth, targetHeight, originalAspect)

      return {
        clipW: originalImage.width,
        clipH: originalImage.height,
        newWidth: Math.floor(newDimensions.newWidth),
        newHeight: Math.floor(newDimensions.newHeight)
      }
    },

    getScaleDataToFillTargetCenterCropOriginal (originalImage, targetWidth, targetHeight) {
      let isOriginalInLandscape = originalImage.width > originalImage.height
      let originalAspect = originalImage.width / originalImage.height
      let targetAspect = targetWidth / targetHeight

      if (isSimilar(originalAspect, targetAspect)) {
        return {
          clipW: originalImage.width,
          clipH: originalImage.height,
          newWidth: Math.floor(targetWidth),
          newHeight: Math.floor(targetHeight)
        }
      }

      // Fill the target with the original. This will result in the original center cropped in some cases.
      // around the image.
      let newDimensions = getNewDimensions(isOriginalInLandscape, originalImage.width, originalImage.height, targetAspect)

      return {
        clipW: Math.floor(newDimensions.newWidth),
        clipH: Math.floor(newDimensions.newHeight),
        newWidth: Math.floor(targetWidth),
        newHeight: Math.floor(targetHeight)
      }
    },

    getScaleDataToFitTargetHeightAndCropOriginalImageWidth (originalImage, targetWidth, targetHeight) {
      let originalAspect = originalImage.width / originalImage.height
      let targetAspect = targetWidth / targetHeight

      if (isSimilar(originalAspect, targetAspect)) {
        return {
          clipW: originalImage.width,
          clipH: originalImage.height,
          newWidth: Math.floor(targetWidth),
          newHeight: Math.floor(targetHeight)
        }
      }

      // While using original aspect resize to the target height and check if the resulting width is greater than target
      // with.
      // If that is true set the clip width so that it will match the target aspect when given an original height,
      // which ensures the scaled image will fill it the target completely when horizontally center cropped.
      // If that is false the original image can be scaled to the target height while the width will remain less than
      // a target one, so no cropping is necessary.
      var clipW = originalImage.width
      var clipH = originalImage.height
      var newHeight = targetHeight * 1.0
      var newWidth = getWidthForHeightByAspect(newHeight, originalAspect)
      if (newWidth > targetWidth) {
        clipW = getWidthForHeightByAspect(clipH, targetAspect)
        newWidth = targetWidth
      }

      return {
        clipW: clipW,
        clipH: clipH,
        newWidth: Math.floor(newWidth),
        newHeight: Math.floor(newHeight)
      }
    },

    drawScaledImage (originalImage, canvas, imageScaleData, canvasWidth, canvasHeight) {
      let sX = Math.floor((originalImage.width - imageScaleData.clipW) / 2)
      let sY = Math.floor((originalImage.height - imageScaleData.clipH) / 2)
      let sW = imageScaleData.clipW
      let sH = imageScaleData.clipH

      let dX = Math.floor((canvasWidth - imageScaleData.newWidth) / 2)
      let dY = Math.floor((canvasHeight - imageScaleData.newHeight) / 2)
      let dW = imageScaleData.newWidth
      let dH = imageScaleData.newHeight

      canvas.clearRect(0, 0, canvasWidth, canvasHeight)
      canvas.drawImage(originalImage, sX, sY, sW, sH, dX, dY, dW, dH)
    },

    clearImage (canvas, canvasWidth, canvasHeight) {
      canvas.clearRect(0, 0, canvasWidth, canvasHeight)
    }
  }

  return ImageService
})
