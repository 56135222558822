/* global angular, _ */
angular.module('smartvid').directive('textTooltip', function ($document, $compile) {
  return {
    restrict: 'A',
    scope: {
      textTooltip: '@',
      textTooltipCustomOffset: '@?'
    },
    link: function (scope, element, attrs) {
      let tipClassName = 'text-tooltip'
      let tipActiveClassName = 'text-tooltip-show'
      scope.tooltipOffsetLeft = 0
      scope.tipClass = [tipClassName]
      scope.textTooltipShowArrow = !!(attrs.textTooltipShowArrow !== 'false')

      let emptyTextTooltip = !scope.textTooltip
      if (emptyTextTooltip) {
        scope.textTooltip = 'temporary value'
      }
      let tip = $compile('<div ng-class="tipClass" ng-show="{{ !!textTooltip }}"><div class="content" ng-bind-html="textTooltip"></div><div ng-class="arrowClass"></div></div>')(scope)
      $document.find('body').append(tip)
      if (emptyTextTooltip) {
        scope.textTooltip = ''
      }

      if (scope.textTooltipShowArrow) {
        scope.arrowClass = 'text-tooltip-arrow'
      } else {
        scope.arrowClass = ''
      }
      if (scope.$parent.tab && scope.$parent.tab.textTooltipCustomOffsetLeft) {
        scope.tipClass.push('text-tooltip-custom')
        scope.tooltipOffsetLeft = scope.$parent.tab.textTooltipCustomOffsetLeft
      }
      if (attrs.textTooltipPosition) {
        scope.tipClass.push('text-tooltip-' + attrs.textTooltipPosition)
      } else {
        scope.tipClass.push('text-tooltip-down')
      }

      let addActiveClass = function (tipElement, classToAdd) {
        if (!tipElement.hasClass(classToAdd)) {
          tipElement.addClass(classToAdd)
        }
      }

      let getOffset = function () {
        let offset
        if (!_.isUndefined(scope.textTooltipCustomOffset)) {
          try {
            offset = parseInt(scope.textTooltipCustomOffset, 10)
          } catch (e) { throw new Error('Text-Tooltip custom offset could not be parsed to int') }
        } else {
          offset = (scope.textTooltipShowArrow) ? 10 : 5
        }

        return offset
      }

      scope.tooltipOffset = getOffset()

      element.on('mouseover', function (e) {
        if (!scope.textTooltip) {
          return
        }
        addActiveClass(tip, tipActiveClassName)

        var pos = element[0].getBoundingClientRect()
        var offset = tip.offset()
        var tipHeight = tip.outerHeight()
        var tipWidth = tip.outerWidth()
        var elWidth = pos.width || pos.right - pos.left
        var elHeight = pos.height || pos.bottom - pos.top
        var tipOffset = scope.tooltipOffset

        if (tip.hasClass('text-tooltip-right')) {
          offset.top = pos.top - (tipHeight / 2) + (elHeight / 2)
          offset.left = pos.right + tipOffset
        } else if (tip.hasClass('text-tooltip-custom')) {
          offset.top = pos.top + elHeight + tipOffset
          offset.left = pos.left - (tipWidth / 2) + (elWidth / 2) - scope.tooltipOffsetLeft
        } else if (tip.hasClass('text-tooltip-left')) {
          offset.top = pos.top - (tipHeight / 2) + (elHeight / 2)
          offset.left = pos.left - tipWidth - tipOffset
        } else if (tip.hasClass('text-tooltip-down')) {
          offset.top = pos.top + elHeight + tipOffset
          offset.left = pos.left - (tipWidth / 2) + (elWidth / 2)
        } else {
          offset.top = pos.top - tipHeight - tipOffset
          offset.left = pos.left - (tipWidth / 2) + (elWidth / 2)
        }

        tip.offset(offset)
      })

      element.on('mouseout', function () {
        tip.removeClass(tipActiveClassName)
      })

      tip.on('mouseover', function () {
        addActiveClass(tip, tipActiveClassName)
      })

      tip.on('mouseout', function () {
        tip.removeClass(tipActiveClassName)
      })

      element.on('$destroy', () => {
        tip.remove() /* Very important. If this does not happen the tips will never be removed from DOM */
      })
    }
  }
})
