<div class="controlbar"
     [ngClass]="{ 'left-nav-open': NavController.leftNavOpen, 'right-nav-open': NavController.rightNavOpen }">

  <mat-toolbar>
    <mat-toolbar-row>
      <sv-left-nav-button></sv-left-nav-button>

      <span class="fill-remaining-space"
        (dblclick)="toggleIgnoreCache($event)"
      ></span>

      <span class="date-range-time-unit-selector">
        <mat-select [panelClass]="'date-range-select-panel'"
                    [(ngModel)]="dateRange"
                    (selectionChange)="updateDateRange()">
          <mat-option *ngFor="let range of supportedRanges" [value]="range">{{ "insights.DashboardDateRangeTimeUnit." + range | translate }}</mat-option>
        </mat-select>
      </span>

    </mat-toolbar-row>
  </mat-toolbar>

</div>
