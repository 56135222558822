/* global angular */

angular.module('smartvid').directive('searchStatusBarTagConfidence', (TAG_CONFIDENCE_LEVEL_HIGH, TAG_CONFIDENCE_LEVEL_MEDIUM, TAG_CONFIDENCE_LEVEL_LOW) => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'AssetGrid/search-status-bar-tag-confidence.html',
    scope: {
      tagConfidenceLevel: '=?',
      openShareSearchModal: '=?',
      isSearchSharingEnabled: '=?',
      switchTagConfidenceLevel: '=?',
      searchSharing: '=?',
      clearSearch: '=?',
      resultsHigh: '=?',
      resultsMedium: '=?',
      resultsLow: '=?',
      tagConfidenceBarDisabled: '=?'
    },
    link: (scope) => {
      scope.high = TAG_CONFIDENCE_LEVEL_HIGH
      scope.medium = TAG_CONFIDENCE_LEVEL_MEDIUM
      scope.low = TAG_CONFIDENCE_LEVEL_LOW

      scope.selectHigh = () => {
        scope.switchTagConfidenceLevel(TAG_CONFIDENCE_LEVEL_HIGH)
      }
      scope.selectMedium = () => {
        scope.switchTagConfidenceLevel(TAG_CONFIDENCE_LEVEL_MEDIUM)
      }
      scope.selectLow = () => {
        scope.switchTagConfidenceLevel(TAG_CONFIDENCE_LEVEL_LOW)
      }
    }
  }
})
