/* global angular */

angular.module('smartvid').directive('passwordStrength', function (passwordService) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'password-strength.html',
    link (scope, el) {
      scope.strength = 'very weak'

      scope.$watch('password', (newVal, oldVal) => {
        let strengthNum = passwordService.getStrength(newVal)

        if (strengthNum === 0) {
          scope.strength = 'none'
        } else if (strengthNum < 20) {
          scope.strength = 'very-weak'
        } else if (strengthNum < 40) {
          scope.strength = 'weak'
        } else if (strengthNum < 60) {
          scope.strength = 'ok'
        } else if (strengthNum < 80) {
          scope.strength = 'good'
        } else {
          scope.strength = 'great'
        }
      })
    }
  }
})
