/* global $, angular, _ */

angular.module('smartvid').directive('projectProfile', function (MAX_PROJECT_NAME_LENGTH, MAX_PROJECT_DESCRIPTION_LENGTH, utils, smartvidApi, $stateParams, currentUser, $rootScope, $timeout,
                                                                 modal, $filter, dashboardDataHelper) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'ProjectGrid/project-profile.html',
    link: function (scope) {
      scope.MAX_PROJECT_NAME_LENGTH = MAX_PROJECT_NAME_LENGTH
      scope.MAX_PROJECT_DESCRIPTION_LENGTH = MAX_PROJECT_DESCRIPTION_LENGTH
      scope.project = dashboardDataHelper.getCurrentProject()
      scope.emptyStartDate = !scope.project.startDate
      scope.emptyEndDate = !scope.project.endDate
      scope.projectTypes = [
        'COMMERCIAL',
        'HEALTHCARE',
        'INDUSTRIAL_AND_ENERGY',
        'INFRASTRUCTURE',
        'INSTITUTIONAL',
        'RESIDENTIAL'
      ]
      scope.translatedProjectType = function (type) {
        return $filter('i18next')('directives.projectProfile.projectType.' + type)
      }

      if (scope.project.startDate) {
        scope.startDate = {
          startDate: new Date(scope.project.startDate),
          endDate: null
        }
      } else {
        scope.startDate = {
          startDate: null,
          endDate: null
        }
      }
      if (scope.project.endDate) {
        scope.endDate = {
          startDate: new Date(scope.project.endDate),
          endDate: null
        }
      } else {
        scope.endDate = {
          startDate: null,
          endDate: null
        }
      }
      scope.projectStatusText = getProjectStatusText()
      scope.organizations = currentUser.organizations
      scope.selectedOrganization = _.find(scope.organizations, (organization) => {
        return organization.id === scope.project.organizationId
      })
      scope.selectedProjectGroup = !scope.selectedOrganization ? undefined : _.find(scope.selectedOrganization.projectGroups, (projectGroup) => {
        return projectGroup.id === scope.project.projectGroupId
      })

      scope.isClearThumbnail = false
      scope.mobile = $rootScope.isMobile
      scope.inTransit = false
      scope.thumbnailImg = undefined
      scope.projectAlreadyExistError = false
      scope.deletedProjectAlreadyExistError = false
      scope.userOrgs = _.where(currentUser.organizations, {canManageProjects: true})

      scope.projectFields = {
        projectName: dashboardDataHelper.getCurrentProject().name,
        externalProjectId: scope.project.externalProjectId,
        description: scope.project.description,
        projectType: scope.project.projectType,
        longitude: scope.project.longitude,
        latitude: scope.project.latitude,
        budget: scope.project.budget,
        addressLine1: scope.project.addressLine1,
        city: scope.project.city,
        postalCode: scope.project.postalCode
      }
      scope.stateCountry = {
        state: scope.project.state,
        country: scope.project.country
      }

      scope.countries = []
      scope.states = []
      scope.countryNameToCode = {}

      scope.onCountryChange = () => {
        scope.stateCountry.state = undefined
        if (!scope.stateCountry.country) {
          scope.states = []
          return
        }
        smartvidApi.getCountryStates(scope.countryNameToCode[scope.stateCountry.country]).then((states) => {
          scope.states = states
        })
      }

      smartvidApi.getCountries().then((countries) => {
        scope.countryNameToCode = _.invert(countries.countryCodeToCountryName)
        for (const c in countries.countryCodeToCountryName) {
          scope.countries.push(countries.countryCodeToCountryName[c])
          if (scope.stateCountry.country === countries.countryCodeToCountryName[c]) {
            smartvidApi.getCountryStates(c).then((states) => {
              scope.states = states
            })
          }
        }
        scope.countries = _.sortBy(scope.countries, (c) => {
          return c
        })
      })

      scope.datePickerOptions = {
        locale: {
          format: 'MM/DD/YYYY'
        },
        parentEl: '.entity-profile',
        autoUpdateInput: false,
        singleDatePicker: true
      }

      function getProjectStatusText ()  {
        return scope.project.isInactive ? $filter('i18next')('directives.projectProfile.projectInActive') : $filter('i18next')('directives.projectProfile.projectActive')
      }
      let customErrorHandler = (response) => {
        if (response.errorCode === 'BAD_REQUEST') {
          if (_.find(response.errorMessages, (m) => {
            return m.label === 'resource.project.already.exists'
          })) {
            scope.projectAlreadyExistError = true
          }
          if (_.find(response.errorMessages, (m) => {
            return m.label === 'resource.deleted.project.name.exists'
          })) {
            scope.deletedProjectAlreadyExistError = true
          }

          return true
        }

        return false
      }

      scope.$watch('thumbnailImg', () => {
        scope.isClearThumbnail = scope.thumbnailImg ? false : scope.isClearThumbnail
      })

      scope.clearThumbnail = function () {
        scope.project.thumbnailUrl = undefined
        scope.thumbnailImg = undefined
        scope.isClearThumbnail = true
      }

      scope.unInviteProjectMembers = () => {
        let confirmHandlers = {
          confirm () {
            scope.performProjectUpdate()
          },
          hasSelections: scope.projectMembers.models.length > 0
        }
        modal.open('unInviteAllMembers', confirmHandlers)
      }

      scope.updateProjectProfile = () => {
        if (scope.selectedOrganization.id !== scope.project.organizationId) {
          scope.unInviteProjectMembers()
        } else {
          scope.performProjectUpdate()
        }
      }

      scope.statusActivationConfirm = (isInactive) => {
        let confirmHandlers = {
          confirm () {
            scope.statusActivation(isInactive)
          },
          hasSelections: true,
          projectName: scope.project.name
        }
        const modalName = isInactive ? 'projectStatusActivation' : 'projectStatusDeactivation'
        modal.open(modalName, confirmHandlers)
      }

      scope.statusActivation = (isInactive) => {
        scope.inTransit = true
        let promise = smartvidApi.updateProjectStatus(scope.project.id, !isInactive)
        promise.then(() => {
          scope.inTransit = false
          scope.project.update({isInactive: !isInactive})
          scope.projectStatusText = getProjectStatusText()
          $rootScope.$broadcast('sv-project-updated', scope.project)
        },
          () => {
            scope.inTransit = false
            scope.projectStatusText = getProjectStatusText()
          })
        return promise
      }

      scope.performProjectUpdate = () => {
        if (!scope.projectProfileForm.$valid) {
          return
        }
        let startDate = (!scope.startDate || 'startDate' in scope.startDate) ? null : scope.startDate
        let endDate = (!scope.endDate || 'startDate' in scope.endDate) ? null : scope.endDate

        scope.inTransit = true
        scope.projectAlreadyExistError = false
        scope.deletedProjectAlreadyExistError = false
        let thumbnail = scope.thumbnailImg ? scope.thumbnailImg.file : undefined
        let projectGroupId = scope.selectedProjectGroup ? scope.selectedProjectGroup.id : undefined

        let promise = smartvidApi.updateProject(
          scope.project.id,
          scope.projectFields.projectName, scope.projectFields.externalProjectId,
          scope.projectFields.description,
          scope.selectedOrganization ? scope.selectedOrganization.id : scope.project.organizationId,
          thumbnail, scope.isClearThumbnail, customErrorHandler,
          projectGroupId,
          scope.projectFields.projectType, startDate,
          endDate, scope.projectFields.longitude, scope.projectFields.latitude,
          scope.projectFields.budget, scope.projectFields.addressLine1, scope.projectFields.city,
          scope.projectFields.postalCode, scope.stateCountry.state,
          scope.stateCountry.country)
        promise.then((data) => {
          $timeout(function () {
            scope.inTransit = false
            scope.project.thumbnailUrl = data.project.thumbnailUrl
            scope.project.organizationId = data.project.organizationId
            scope.project.projectGroupId = projectGroupId
            scope.externalProjectId = scope.project.externalProjectId

            scope.project.description = scope.projectFields.description
            scope.project.projectType = scope.projectFields.projectType
            scope.project.startDate = startDate
            scope.project.endDate = endDate
            scope.project.longitude = scope.projectFields.longitude
            scope.project.latitude = scope.projectFields.latitude
            scope.project.budget = scope.projectFields.budget
            scope.project.addressLine1 = scope.projectFields.addressLine1
            scope.project.city = scope.projectFields.city
            scope.project.postalCode = scope.projectFields.postalCode
            scope.project.state = scope.stateCountry.state
            scope.project.country = scope.stateCountry.country

            let projectWithRoleAndOrgName = data.project
            projectWithRoleAndOrgName.projectGroupId = projectGroupId
            projectWithRoleAndOrgName.projectGroupName = data.projectGroupName || ''
            _.each(data, (value, key) => {
              if (key !== 'project') {
                projectWithRoleAndOrgName[key] = value
              }
            })
            /* TODO (rrubbico) remove this when our model layer is figured out */
            $rootScope.$broadcast('sv-project-updated', projectWithRoleAndOrgName)
          }, 100)
        }, () => {
          scope.inTransit = false
        })
        return promise
      }
    }
  }
})
