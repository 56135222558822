<div class="organization-assets-insights-dashboard-safety mdc-layout-grid__inner">
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_SAFETY_BY_GROUP_PROJECT' }">
    <sv-insights-card
      #safetyByGroupOrProjectCard
      contentHeight="400px"
      cardTitle="{{ cardTitle }}"
      subTitle="{{ safetyTableData?.length }} {{ cardSubTitle }}"
      class="organization-safety-table-card"
      hasTextInput="true"
      [toggleMenuGroups]="safetyByGroupOrProjectTableMenu"
      (toggleMenuItemSelected)="safetyByGroupOrProjectMenuSelected($event, safetyByGroupOrProjectCard)"
      textInputPlaceholder="{{ filterInputPlaceholder }}"
      (cardInputChange)="safetyTableFilterChanged($event, groupOrProjectSafetyTable)"
    >
      <sv-organization-assets-safety-table
        #groupOrProjectSafetyTable
        [tableData]="safetyTableData"
        [scope]="currentSafetyScope"
      ></sv-organization-assets-safety-table>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_PEOPLE_IN_GROUPS' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgPeopleInGroupsOverTime.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
      [toggleMenuGroups]="peopleInGroupsOverTimeChartMenu"
      (toggleMenuItemSelected)="peopleInGroupsOverTimeChartMenuSelected($event)"
    >
      <sv-insights-line-chart
        [chartData]="organizationPeopleInGroupsOverTimeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-line-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_PPE_COMPLIANCE_TREND_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgPpeComplianceTrendOverTime.title' | translate }}"
      subTitle="{{ 'dashboard.cards.orgPpeComplianceTrendOverTime.subTitle' | translate }}"
    >
      <sv-insights-line-chart
        [chartData]="organizationPpeComplianceOverTimeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-line-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_PPE_COMPLIANCE_PERCENT_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgOverallPpeCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
      <div *ngIf="organizationSingleValueData" class="card-gauge-container">
        <sv-insights-safety-gauge-chart
          [currentValue]="organizationSingleValueData.ppeCompliancePercentAvg"
        ></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_GLASSES_COMPLIANCE_PERCENT_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgGlassesCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
      <div *ngIf="organizationSingleValueData" class="card-gauge-container">
        <sv-insights-safety-gauge-chart
          [currentValue]="organizationSingleValueData.glassesComplianceAvg"
        ></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_GLOVES_COMPLIANCE_PERCENT_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgGlovesCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
      <div *ngIf="organizationSingleValueData" class="card-gauge-container">
        <sv-insights-safety-gauge-chart
          [currentValue]="organizationSingleValueData.glovesComplianceAvg"
        ></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_HARDHAT_COMPLIANCE_PERCENT_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgHardHatCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
      <div *ngIf="organizationSingleValueData" class="card-gauge-container">
        <sv-insights-safety-gauge-chart
          [currentValue]="organizationSingleValueData.hardHatComplianceAvg"
        ></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_HIVIZ_COMPLIANCE_PERCENT_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgHiVizCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
      <div *ngIf="organizationSingleValueData" class="card-gauge-container">
        <sv-insights-safety-gauge-chart
          [currentValue]="organizationSingleValueData.highVizComplianceAvg"
        ></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_WORK_AT_HEIGHTS_TREND_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgWorkAtHeightTrendOverTime.title' | translate }}"
      subTitle="{{ 'dashboard.cards.orgWorkAtHeightTrendOverTime.subTitle' | translate }}"
    >
      <sv-insights-column-chart
        *ngIf="!chartHasAllNullValues(organizationWorkAtHeightsOverTimeData?.chartData)"
        [chartData]="convertNullValuesToZero(organizationWorkAtHeightsOverTimeData?.chartData)"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
      <div class="text-card-content dashboard-single-value-card" *ngIf="chartHasAllNullValues(organizationWorkAtHeightsOverTimeData?.chartData)">
        <div class="text-card-main-text">
          {{
          ('dashboard.valueNotAvailable' | translate)
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_LADDERS_TO_LIFTS_RATIO_AVG' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.orgLaddersToLiftsRatio.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="organizationSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(organizationSingleValueData.laddersToLiftsRatioAvg)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.laddersToLiftsRatioAvg | number: '.1-1') + ' to 1'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ laddersToLiftsRatioPercentChangeClassName }}">
          {{ getSign(organizationSingleValueData.laddersToLiftsRatioAvgPercentChange)
          }}{{
            isNA(organizationSingleValueData.laddersToLiftsRatioAvg)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.laddersToLiftsRatioAvgPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_WORK_AT_HEIGHT_PER_X_ASSETS_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgWorkAtHeightDistribution.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="organizationWorkAtHeightsPerXAssetsData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_LADDERS_PER_X_ASSETS_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgLaddersDistribution.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="organizationLaddersPerXAssetsData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_LIFTS_PER_X_ASSETS_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgLiftsDistribution.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="organizationLiftsPerXAssetsData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div
    *svDashboardGridCell="{
      tabId: 'SAFETY',
      cardId: 'ORGANIZATION_ASSETS_HOUSEKEEPING_AND_STANDING_WATER_AVG'
    }"
  >
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgHousekeepingAndStandingWater.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="organizationHousekeepingAndStandingWaterData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_SLIP_AND_TRIP_TREND_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgSlipAndTripTrendOverTime.title' | translate }}"
      subTitle="{{ 'dashboard.cards.orgSlipAndTripTrendOverTime.subTitle' | translate }}"
    >
      <sv-insights-column-chart
        *ngIf="!chartHasAllNullValues(organizationSlipAndTripTrendOverTimeData?.chartData)"
        [chartData]="organizationSlipAndTripTrendOverTimeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
      <div class="text-card-content dashboard-single-value-card" *ngIf="chartHasAllNullValues(organizationSlipAndTripTrendOverTimeData?.chartData)">
        <div class="text-card-main-text">
          {{
          ('dashboard.valueNotAvailable' | translate)
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'ORGANIZATION_ASSETS_PEOPLE_PER_X_CONSTRUCTION' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.orgPeoplePerXConstruction.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="organizationSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(organizationSingleValueData.peoplePerXConstructionAvg)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.peoplePerXConstructionAvg | number: '1.0-0')
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ peoplePerXConstructionPercentChangeClassName }}">
          {{ getSign(organizationSingleValueData.peoplePerXConstructionAvgPercentChange)
          }}{{
            isNA(organizationSingleValueData.peoplePerXConstructionAvg)
              ? ('dashboard.valueNotAvailable' | translate)
              : (organizationSingleValueData.peoplePerXConstructionAvgPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
</div>
