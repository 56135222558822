/* global angular EGNYTE_INTEGRATION_UI_ENABLED _ */

angular.module('smartvid').directive('projectIntegrationsEgnyte', function (
    $timeout, $window, $interval, $filter, $interpolate, $stateParams, $state, $rootScope, utils, smartvidApi,
    Notification, currentUser, moment, errorUtils, EgnyteFolderCollection, IntegrationSettingsModel,
    EgnyteUserInfoModel, EgnyteSyncSettingsModel, dashboardDataHelper
) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'integrations/project-integrations-egnyte.html',
    link: function (scope) {
      scope.project = dashboardDataHelper.getCurrentProject()
      if (!scope.project.canUpdate) {
        $state.go('unauthorized')
        return
      }

      const EGNYTE = 'EGNYTE'
      const EGNYTE_SYNC = 'EGNYTE_SYNC'
      var LAST_SYNC_NEVER_LABEL
      var NEXT_SYNC_NA
      var SYNC_PENDING_LABEL
      var SYNC_IN_PROGRESS_LABEL
      var RATE_LIMTED
      scope.connectionStatus = ''
      let smartvidProjectId = $stateParams.projectId
      scope.isEgnyteIntegrationEnabledByCurrentUser = false
      scope.isEgnyteIntegrationEnabledByOtherUser = false
      scope.hasRateLimitError = false
      scope.egnyteFolders = []
      scope.egnyteExpandedFolders = []
      scope.syncSettings = {}
      scope.egnyteUserInfo
      scope.currentUserAuthenticatedWithEgnyte
      scope.isLoadingData
      scope.isRefreshingFolders
      scope.egnyteIntegrationSettings = {}
      scope.egnyteIntegrationSettings.integrationType = EGNYTE_SYNC
      scope.selectedData = {}
      scope.isLoadingData
      scope.selectedData.egnyteSelectedFolder = {}

      scope.dateRanges = [
        {
          name: 'None',
          value: 'NONE'
        },
        {
          name: '7 Days',
          value: '7_DAYS'
        },
        {
          name: '30 Days',
          value: '30_DAYS'
        },
        {
          name: '60 Days',
          value: '60_DAYS'
        },
        {
          name: 'All',
          value: 'ALL'
        }
      ]
      scope.selectedData.historicalDataRange = scope.dateRanges[0]

      // initialize page
      $timeout(() => {
        scope.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
        LAST_SYNC_NEVER_LABEL = $filter('i18next')('integrations.lastSyncNever')
        NEXT_SYNC_NA = $filter('i18next')('integrations.nextSyncNA')
        SYNC_PENDING_LABEL = $filter('i18next')('integrations.syncPending')
        SYNC_IN_PROGRESS_LABEL = $filter('i18next')('integrations.syncInProgress')
        RATE_LIMTED = $filter('i18next')('integrations.egnyte.connection.rate_limited_status')
        resetDefaults()
        checkIntegrationStatusAndLoadData(smartvidProjectId, EGNYTE, EGNYTE_SYNC, false)
      }, 10)

      scope.isEgnyteIntegrationUiEnabled = () => {
        return EGNYTE_INTEGRATION_UI_ENABLED
      }

      let resetDefaults = (hasError) => {
        scope.selectedData.egnyteSelectedFolder = {path: $filter('i18next')('integrations.egnyte.foldersPlaceholder')}
        scope.selectedData.historicalDataRange = scope.dateRanges[0]
        scope.egnyteIntegrationSettings.isEnabled = false
        scope.egnyteIntegrationSettings.userId = undefined
        scope.egnyteIntegrationSettings.projectId = undefined
        scope.egnyteIntegrationSettings.settingsData = {}
      }

      let checkEgnyteIntegrationStatusAndLoadData = (isAfterAuth) => {
        smartvidApi.getOAuthUserDataForProviderForCurrentUser(EGNYTE).then((data) => {
          scope.waitingForEgnyteIntegrationStatus = false
          // TODO: handle multiple identities
          scope.currentUserAuthenticatedWithEgnyte = data.length > 0 ? data[0] : undefined
          loadPageData(smartvidProjectId, EGNYTE_SYNC, isAfterAuth)
        }, () => {
          scope.isLoadingData = false
          scope.waitingForEgnyteIntegrationStatus = false
          Notification.error($filter('i18next')('integrations.egnyte.errors.failedToCheckIntegrationStatus'))
        })
      }

      scope.isEgnyteIntegrationEnabledForProject = () => {
        return (scope.isEgnyteIntegrationEnabledByCurrentUser || scope.isEgnyteIntegrationEnabledByOtherUser)
      }

      scope.isSyncNowEnabled = () => {
        return scope.isEgnyteIntegrationEnabledForProject() && scope.isEgnyteDataComplete() && !scope.isEgnyteIntegrationEnabledByOtherUser && !scope.hasRateLimitError
      }

      scope.isConnectedByAnotherSmartvidUser = () => {
        return scope.egnyteIntegrationSettings.userId !== undefined && scope.egnyteIntegrationSettings.userId !== currentUser.id
      }

      scope.isConnectedByCurrentSmartvidUser = () => {
        return scope.egnyteIntegrationSettings.userId !== undefined && scope.egnyteIntegrationSettings.userId === currentUser.id
      }

      scope.isConnectedByAnotherEgnyteUser = () => {
        return scope.syncSettings.egnyteUserId !== undefined && (!scope.currentUserAuthenticatedWithEgnyte || scope.syncSettings.egnyteUserId !== scope.currentUserAuthenticatedWithEgnyte.externalUserId)
      }

      scope.isConnectedByCurrentlyAuthenticatedEgnyteUser = () => {
        return scope.syncSettings.egnyteUserId !== undefined && scope.currentUserAuthenticatedWithEgnyte !== undefined && scope.syncSettings.egnyteUserId === scope.currentUserAuthenticatedWithEgnyte.externalUserId
      }

      scope.isReadOnly = () => {
        return (scope.egnyteIntegrationSettings.isEnabled || scope.isConnectedByAnotherEgnyteUser() || !scope.isEgnyteIntegrationEnabledForProject())
      }

      scope.disableEgnyteIntegration = () => {
        scope.isLoadingData = true
        smartvidApi.deleteIntegrationSettings(EGNYTE_SYNC, smartvidProjectId).then(() => {
          scope.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
          resetDefaults()
          checkEgnyteIntegrationStatusAndLoadData(false)
        }, () => {
          scope.isLoadingData = false
        })
      }

      scope.disconnect = () => {
        if (scope.isEgnyteIntegrationEnabledForProject() && !scope.hasRateLimitError) {
          scope.disableEgnyteIntegration()
          scope.egnyteFolders = []
          scope.egnyteExpandedFolders = []
          scope.selectedData.egnyteSelectedFolder = {}
        }
      }

      scope.isEgnyteDataComplete = () => {
        return scope.selectedData.egnyteSelectedFolder.folderId
      }

      scope.canLinkFolder = () => {
        return scope.isEgnyteIntegrationEnabledByCurrentUser && scope.isEgnyteDataComplete()
      }

      scope.canUnlinkFolder = () => {
        return scope.isEgnyteIntegrationEnabledByCurrentUser && (scope.isEgnyteDataComplete())
      }

      scope.linkFolder = () => {
        scope.egnyteIntegrationSettings.isEnabled = true
        scope.saveEgnyteSyncSettings()
      }

      scope.unlinkFolder = () => {
        scope.egnyteIntegrationSettings.isEnabled = false
        scope.saveEgnyteSyncSettings()
      }

      scope.getLastSyncDate = () => {
        if (scope.egnyteIntegrationSettings.lastSyncDate > 0) {
          return moment(scope.egnyteIntegrationSettings.lastSyncDate).format('MMM DD, YYYY hh:mmA')
        }

        if (scope.egnyteIntegrationSettings.isEnabled) {
          return SYNC_PENDING_LABEL
        }

        return LAST_SYNC_NEVER_LABEL
      }

      scope.getNextSyncDate = () => {
        if (scope.egnyteIntegrationSettings.isEnabled && scope.egnyteIntegrationSettings.isProcessing) {
          return SYNC_IN_PROGRESS_LABEL
        }

        if (scope.egnyteIntegrationSettings.isEnabled) {
          return $filter('i18next')('integrations.scheduled')
        }

        return NEXT_SYNC_NA
      }

      let getEgnyteUserId = () => {
        if (scope.isEgnyteIntegrationEnabledByOtherUser && scope.egnyteIntegrationSettings.settingsData.egnyteUserId !== undefined) {
          return scope.egnyteIntegrationSettings.settingsData.egnyteUserId
        } else if (scope.currentUserAuthenticatedWithEgnyte !== undefined) {
          return scope.currentUserAuthenticatedWithEgnyte.externalUserId
        } else {
          return undefined
        }
      }

      let continueLoadingPageData = () => {
        adjustConnectionStatus()
        if (scope.isEgnyteIntegrationEnabledForProject()) {
          loadFolders()
        } else {
          scope.isLoadingData = false
        }
      }

      let loadEgnyteUserProfile = (afterProfileLoadedFunc) => {
        let egnyteUserId = getEgnyteUserId()
        if (egnyteUserId) {
          smartvidApi.getEgnyteUserInfo(smartvidProjectId, customErrorHandler).then((data) => {
            scope.egnyteUserInfo = new EgnyteUserInfoModel(data)
            afterProfileLoadedFunc()
          }, afterProfileLoadedFunc)
        } else if (afterProfileLoadedFunc) {
          afterProfileLoadedFunc()
        }
      }

      let prepareSyncSettingsIfNeededAndContinueLoadingData = (isAfterAuth) => {
        if (scope.isEgnyteIntegrationEnabledForProject()) {
          loadEgnyteUserProfile(continueLoadingPageData)
        } else if (scope.currentUserAuthenticatedWithEgnyte && isAfterAuth) {
          // reset the sync settings after authentication with Egnyte
          scope.egnyteIntegrationSettings.isEnabled = false
          scope.egnyteIntegrationSettings.userId = scope.currentUserAuthenticatedWithEgnyte.userId
          scope.egnyteIntegrationSettings.projectId = smartvidProjectId
          scope.egnyteIntegrationSettings.settingsData.egnyteUserId = scope.currentUserAuthenticatedWithEgnyte.externalUserId
          smartvidApi.saveIntegrationSettings(EGNYTE_SYNC, scope.egnyteIntegrationSettings).then(() => {
            scope.isEgnyteIntegrationEnabledByCurrentUser = true
            scope.egnyteIntegrationNotEnabled = false
            loadEgnyteUserProfile(continueLoadingPageData)
          }, () => {
            scope.isLoadingData = false
            scope.waitingForEgnyteIntegrationStatus = false
            Notification.error($filter('i18next')('integrations.egnyte.errors.failedToLoadSyncSettings'))
          })
        } else {
          scope.isLoadingData = false
        }
      }

      let loadPageData = (smartvidProjectId, integrationType, isAfterOAuth) => {
        scope.isLoadingData = true
        smartvidApi.getIntegrationSettingsForProject(integrationType, smartvidProjectId).then((data) => {
          scope.egnyteIntegrationSettings = new IntegrationSettingsModel(data)
          scope.egnyteIntegrationSettings.integrationType = EGNYTE_SYNC

          scope.syncSettings = new EgnyteSyncSettingsModel(data.settingsData)
          scope.waitingForOAuthIntegrationStatus = false

          scope.isEgnyteIntegrationEnabledByOtherUser = scope.isConnectedByAnotherEgnyteUser() || scope.isConnectedByAnotherSmartvidUser()
          scope.isEgnyteIntegrationEnabledByCurrentUser = scope.isConnectedByCurrentlyAuthenticatedEgnyteUser() && scope.isConnectedByCurrentSmartvidUser()
          scope.egnyteIntegrationNotEnabled = !scope.syncSettings.egnyteUserId

          scope.selectedData.historicalDataRange = _.find(scope.dateRanges, (dr) => {
            return dr.value === scope.syncSettings.historicalDataSyncDateRange
          }) || scope.dateRanges[0]

          prepareSyncSettingsIfNeededAndContinueLoadingData(isAfterOAuth)
        }, () => {
          scope.isLoadingData = false
          scope.waitingForOAuthIntegrationStatus = false
          Notification.error($filter('i18next')('integrations.egnyte.errors.failedToLoadSyncSettings'))
        })
      }

      let adjustConnectionStatus = () => {
        if (scope.isEgnyteIntegrationEnabledByCurrentUser && !scope.hasRateLimitError) {
          let egnyteUserName = scope.egnyteUserInfo ? (scope.egnyteUserInfo.firstName + ' ' + scope.egnyteUserInfo.lastName) : ''
          scope.connectionStatus = $interpolate($filter('i18next')('integrations.egnyte.connection.connectedAsUser'))({
            egnyteUser: egnyteUserName
          })
        } else if (scope.isEgnyteIntegrationEnabledByOtherUser) {
          scope.connectionStatus = $interpolate($filter('i18next')('integrations.connection.connectedAsSmartvidUser'))({
            smartvidUser: ''
          })
        } else if (scope.hasRateLimitError) {
          // TODO(vgorsky) Rate limit error handling
        }
      }

      let checkIntegrationStatusAndLoadData = (smartvidProjectId, providerName, integrationType, isAfterOAuth) => {
        smartvidApi.getOAuthUserDataForProviderForCurrentUser(providerName).then((data) => {
          scope.waitingForIntegrationStatus = false
          scope.waitingForEgnyteIntegrationStatus = false
          scope.currentUserAuthenticatedWithEgnyte = data.length > 0 ? data[0] : undefined
          if (data.length === 0 && isAfterOAuth) {
            scope.isLoadingData = false
            Notification.error($filter('i18next')('directives.mysettings.failedToEstablishEgnyteIntegrationStatus'))
            return
          }
          loadPageData(smartvidProjectId, integrationType, isAfterOAuth)
        }, () => {
          scope.isLoadingData = false
          scope.waitingForOAuthIntegrationStatus = false
          scope.waitingForEgnyteIntegrationStatus = false
          Notification.error($filter('i18next')('directives.mysettings.failedToCheckEgnyteIntegrationStatus'))
        })
      }

      let monitorOAuthPopup = (smartvidProjectId, providerName, integrationType, oauthWindow) => {
        let winCheck = $interval(() => {
          if (!oauthWindow || oauthWindow.closed) {
            $interval.cancel(winCheck)
            checkIntegrationStatusAndLoadData(smartvidProjectId, providerName, integrationType, true)
          }
        }, 300)
      }

      scope.saveEgnyteSyncSettings = (afterSaveFunc) => {
        scope.egnyteIntegrationSettings.settingsData.egnyteFolderId = scope.selectedData.egnyteSelectedFolder.folderId
        scope.egnyteIntegrationSettings.settingsData.egnyteFolderPath = scope.selectedData.egnyteSelectedFolder.path

        setHistoricalDataSyncThresholdDate(scope.egnyteIntegrationSettings.settingsData, scope.selectedData.historicalDataRange)

        smartvidApi.saveIntegrationSettings(EGNYTE_SYNC, scope.egnyteIntegrationSettings).then(() => {
          if (afterSaveFunc) {
            afterSaveFunc()
          }
        }, () => {
          scope.egnyteIntegrationSettings.isEnabled = false
        })
      }

      scope.linkFolder = () => {
        scope.egnyteIntegrationSettings.isEnabled = true
        scope.egnyteIntegrationSettings.syncOnlyAfterDate = Date.now()

        scope.saveEgnyteSyncSettings()
      }

      scope.folderTreeOptions = {
        nodeChildren: 'folders',
        dirSelectable: true,
        allowDeselect: false,
        isLeaf: (node) => {
          return node.folders !== undefined && node.folders.length === 0
        }
      }

      scope.selectAndLoadFolder = (folder) => {
        scope.selectedData.egnyteSelectedFolder = folder
        scope.egnyteExpandedFolders.push(folder)
        scope.loadFolder(folder)

        scope.saveEgnyteSyncSettings()
      }

      scope.loadFolder = (folder) => {
        if (folder.folders !== undefined) {
          return
        }
        scope.isLoadingData = true
        smartvidApi.getEgnyteChildrenFolders(smartvidProjectId, folder.path, customErrorHandler).then((data) => {
          folder.folders = []
          scope.isLoadingData = false
          _.each(new EgnyteFolderCollection(data).models, (m) => {
            folder.folders.push(m)
          })
        }, () => {
          folder.folders = []
          scope.isLoadingData = false
        })
      }

      scope.reloadFolders = () => {
        scope.egnyteFolders = []
        scope.egnyteExpandedFolders = []

        scope.isLoadingData = true
        scope.isRefreshingFolders = true
        loadFolders()
      }

      let loadFolders = () => {
        smartvidApi.getEgnyteChildrenFolders(smartvidProjectId, '', customErrorHandler).then((data) => {
          _.each(new EgnyteFolderCollection(data).models, (m) => {
            scope.egnyteFolders.push(m)
          })
          scope.isLoadingData = false
          scope.isRefreshingFolders = false
          if (scope.egnyteIntegrationSettings.settingsData.egnyteFolderId) {
            scope.selectedData.egnyteSelectedFolder = _.find(scope.egnyteFolders, (folder) => {
              return folder.folderId === scope.egnyteIntegrationSettings.settingsData.egnyteFolderId
            })
            if (scope.selectedData.egnyteSelectedFolder === undefined) {
              scope.selectedData.egnyteSelectedFolder = {
                path: scope.egnyteIntegrationSettings.settingsData.egnyteFolderPath,
                folderId: scope.egnyteIntegrationSettings.settingsData.egnyteFolderId
              }
            }
          }
        }, () => {
          scope.isLoadingData = false
          scope.isRefreshingFolders = false
        })
      }

      scope.authenticateWithEgnyteAndEnableIntegration = () => {
        scope.waitingForEgnyteIntegrationStatus = true
        let oauthWindow = $window.open('', '_blank', 'width=800,height=600')
        smartvidApi.getOAuthUrlForProvider(EGNYTE).then((data) => {
          oauthWindow.location = data.value
          monitorOAuthPopup(smartvidProjectId, EGNYTE, EGNYTE_SYNC, oauthWindow)
        }, (response) => {
          oauthWindow.close()
          if (!utils.isTimeoutResponse(response)) {
            Notification.error($filter('i18next')('directives.mysettings.failedToEnableEgnyteIntegration'))
          }
        })
      }

      let customErrorHandler = (response) => {
        if (errorUtils.isRateLimit(response)) {
          scope.hasRateLimitError = true
          resetDefaults(scope.hasRateLimitError)
          adjustConnectionStatus()
          scope.connectionStatus = RATE_LIMTED
          return true
        }
        return false
      }

      scope.enableRunOnceSync = () => {
        scope.isWaitingForRunOnce = true

        smartvidApi.enableRunOnceSyncIntegrationSettingsForProject(EGNYTE_SYNC, smartvidProjectId).then(() => {
          scope.projectLinkToggleInTransit = false
          scope.isWaitingForRunOnce = false
          scope.egnyteIntegrationSettings.lastSyncDate = Date.now()
        }, () => {
          scope.projectLinkToggleInTransit = false
          scope.isWaitingForRunOnce = false
        })
      }

      scope.connect = () => {
        if (scope.hasRateLimitError) {
          return
        }
        scope.egnyteUserInactiveError = false
        scope.authenticateWithEgnyteAndEnableIntegration()
      }

      function setHistoricalDataSyncThresholdDate (settingsData, historicalDataRange) {
        if (!historicalDataRange || historicalDataRange.value === 'ALL') {
          settingsData.historicalDataSyncThresholdDate = null
          if (historicalDataRange) {
            settingsData.historicalDataSyncDateRange = historicalDataRange.value
          }
        } else {
          switch (historicalDataRange.value) {
            case '7_DAYS':
              settingsData.historicalDataSyncThresholdDate = moment().subtract(7, 'days').startOf('day').toDate()
              settingsData.historicalDataSyncDateRange = historicalDataRange.value
              break
            case '30_DAYS':
              settingsData.historicalDataSyncThresholdDate = moment().subtract(30, 'days').startOf('day').toDate()
              settingsData.historicalDataSyncDateRange = historicalDataRange.value
              break
            case '60_DAYS':
              settingsData.historicalDataSyncThresholdDate = moment().subtract(60, 'days').startOf('day').toDate()
              settingsData.historicalDataSyncDateRange = historicalDataRange.value
              break
            case 'NONE':
              settingsData.historicalDataSyncThresholdDate = moment().toDate()
              settingsData.historicalDataSyncDateRange = historicalDataRange.value
              break
          }
        }
      }

    }
  }
})
