import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'

export class ConfirmDisconnectIntegrationsDialogData {
  integrationCount: number
}

@Component({
  selector: 'sv-confirm-disconnect-integrations-dialog',
  templateUrl: 'confirm-disconnect-integrations-dialog.component.html',
  styleUrls: ['confirm-disconnect-integrations-dialog.component.scss'],
})
export class ConfirmDisconnectIntegrationsDialogComponent {
  integrationCount: number

  constructor(
    private dialogRef: MatDialogRef<ConfirmDisconnectIntegrationsDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmDisconnectIntegrationsDialogData
  ) {
    this.integrationCount = dialogData.integrationCount
  }
}
