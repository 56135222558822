/* global angular, _ */
angular.module('smartvid').factory('GroupMetadata', function (BaseModel) {
  class GroupMetadata extends BaseModel {
    constructor (attrs) {
      let defaults = {
        groupHeader: true,
        text: '',
        parsedSize: 0
      }
      super(_.defaults(attrs || {}, defaults))
    }
  }
  return GroupMetadata
})
