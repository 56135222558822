/* global angular */

angular.module('smartvid').directive('assetGridControlbar', function ($rootScope, $state, currentUser, contentSharingContext, searchResultService, dashboardDataHelper) {
  return {
    restrict: 'E',
    // TODO: PL: remove commented out code
    // replace: true,
    templateUrl: 'AssetGrid/controlbar.html',
    link (scope) {
      scope.showingDeletedFiles = $state.current.name === 'dashboard.projects.projectId.deletedfiles'
      scope.isSharing = contentSharingContext.isSet()
      scope.isInGlobalViewContext = searchResultService.isInGlobalSearchContext() || contentSharingContext.isGlobalSharingContext()
      scope.isActiveSearch = searchResultService.isInSearchContext()
      scope.isAnonymous = currentUser.isAnonymous

      scope.toggleCapture = () => {
        $rootScope.isCapture = !$rootScope.isCapture
        $rootScope.$broadcast('sv-mobile-update-capture-mode')
      }

      scope.openShareModal = () => {
        $rootScope.$broadcast('assetGrid:shareSelectedAssetSelected', true)
      }

      scope.openFileUploadModal = () => {
        scope.modal.open('fileUpload', {tags: scope.projectTags, currentProject: scope.currentProject})
      }

      scope.showSettingsOptions = ($event) => {
        scope.flyout.open('assetGridOptions', {
          parentElement: $event.currentTarget,
          direction: 'top',
          assets: scope.assets
        })
      }

      scope.showDownloadOptions = ($event) => {
        scope.flyout.open('assetGridDownloadOptions', {
          parentElement: $event.currentTarget,
          direction: 'top',
          assets: scope.assets
        })
      }

      scope.showSortOptions = ($event) => {
        scope.flyout.open('sortOptions', {
          parentElement: $event.currentTarget,
          direction: 'top',
          project: scope.project,
          isSearch: scope.helper.isActiveSearch()
        })
      }

      scope.showGroupOptions = ($event) => {
        scope.flyout.open('groupOptions', {
          parentElement: $event.currentTarget,
          direction: 'top',
          project: scope.project,
          isSearch: scope.helper.isActiveSearch()
        })
      }

      scope.shareNotAllowedAssets = () => {
        let assets = dashboardDataHelper.getAssets()
        if (!assets) {
          return false
        }
        let shareNotAllowedAssets = assets.where({selected: true, canShare: false})
        return shareNotAllowedAssets.length > 0
      }
    }
  }
})
