/* global angular, _ */

angular.module('smartvid').service('usersApi', function ($http, $q, $log, config, currentUser, systemApi) {
  let angularRootUrl = config.env.development.angularRootUrl
  let rootUrl = config.env.development.apiRootUrl

  let flattenOrgData = (data) => {
    let organization = data.organization
    let org = data
    _.each(org, (value, key) => {
      if (key !== 'organization') {
        organization[key] = value
      }
    })
    return organization
  }

  let updateLocalCurrentUser = (data) => {
    data.organizations = _.map(data.organizations, flattenOrgData)
    currentUser.update(data)
  }

  let onAuthSuccess = (defer, userData) => {
    currentUser.update(userData)

    $q.all([
      api.getCurrentUser(),
      systemApi.getSystemData()
    ])
    .then(function (responses) {
      defer.resolve(responses)
    })
  }

  function authenticateInternal (data, customErrorHandler) {
    let defer = $q.defer()

    let postParams = {}
    if (customErrorHandler) {
      postParams.customErrorHandler = customErrorHandler
    }
    $http.post(`${rootUrl}/api/authenticate`, data, postParams).then((response) => {
      onAuthSuccess(defer, response.data)
    }, (response) => {
      defer.reject(response.data)
    })

    return defer.promise
  }

  let api = {
    
    onAuthSuccess: onAuthSuccess,

    authenticate (email, password, isMobile, customErrorHandler) {
      password = password || ''
      let data = 'username=' + encodeURIComponent(email) +
        '&password=' + encodeURIComponent(password) + '&isMobile=' + (!!isMobile)
      return authenticateInternal(data, customErrorHandler)
    },
    impersonate (email) {
      let data = 'username=' + encodeURIComponent(email)
      let defer = $q.defer()

      let onAuthSuccess = (userData) => {
        currentUser.update(userData)

        $q.all([
          api.getCurrentUser(),
          systemApi.getSystemData()
        ])
          .then(function (responses) {
            defer.resolve(responses)
          })
      }

      $http.post(`${rootUrl}/api/impersonate`, data).then((response) => {
        onAuthSuccess(response.data)
      }, (response) => {
        defer.reject(response.data)
      })

      return defer.promise
    },
    login (username, password, isMobile, customErrorHandler) {
      let promise = this.authenticate(username, password, isMobile, customErrorHandler).then(function (userData) {
        currentUser.update(_.assign(userData, {
          username: username
        }))
      })

      return promise
    },
    loginForLink (isMobile) {
      let data = 'isMobile=' + (!!isMobile)
      let customErrorHandler = (response) => {
        return !response || response.errorCode === 'BAD_REQUEST' || response.errorCode === 'NOT_FOUND'
      }
      return authenticateInternal(data, customErrorHandler)
    },
    getCurrentUser () {
      let url = `${rootUrl}/api/user`
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('getCurrentUser', response.data)
        updateLocalCurrentUser(response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    refreshToken () {
      let url = `${rootUrl}/api/refreshToken`
      let defer = $q.defer()
      $http.get(url).then((response) => {
        defer.resolve(response)
      }, (response) => {
        defer.reject(response)
      })
      return defer.promise
    },
    updateCurrentUser (firstName, lastName, primaryOrgId, defaultHomePage, projectsNotificationSettings,
                       organizationsNotificationSettings, userOrganizationPushNotificationSettings) {
      let url = `${rootUrl}/api/updateUser`
      let defer = $q.defer()
      let payload = {
        firstName: firstName,
        lastName: lastName,
        primaryOrgId: primaryOrgId,
        defaultHomePage: defaultHomePage,
        projectsNotificationSettings: projectsNotificationSettings,
        organizationsNotificationSettings: organizationsNotificationSettings,
        userOrganizationPushNotificationSettings: userOrganizationPushNotificationSettings
      }

      $http.put(url, payload).then(function (response) {
        $log.debug('updateCurrentUser', response.data)
        updateLocalCurrentUser(response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    acceptEula (userId) {
      let url = `${rootUrl}/api/user/${userId}/eula/accept`
      let defer = $q.defer()

      $http.post(url).then(function acceptEulaSuccess (response) {
        defer.resolve(response)
      }, function acceptEulaFailure (response) {
        defer.reject(response)
      })

      return defer.promise
    },
    getUserByEmail (emailAddress) {
      let url = `${rootUrl}/api/user/email/${emailAddress}`
      let defer = $q.defer()

      $http.get(url).then(
        function (response) {
          let user = response.data
          defer.resolve(user)
        },
        function (response) {
          defer.reject(response.data)
        })

      return defer.promise
    },
    getSsoLink (emailAddress) {
      let url = `${rootUrl}/api/ssoLoginUrl?username=${encodeURIComponent(emailAddress)}`
      let defer = $q.defer()

      $http.get(url).then(
        function (response) {
          defer.resolve(response.data)
        },
        function (response) {
          defer.reject(response.data)
        })

      return defer.promise
    },
    requestPasswordReset (email) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/user/password/reset/token`
      let payload = {
        userEmail: email,
        resetPasswordUiUrl: `${angularRootUrl}${config.resetPasswordUrl}`
      }

      $http.post(url, payload).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.resolve(response)
      })

      return defer.promise
    },
    updatePassword (oldPassword, newPassword) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/user/password/update`
      let payload = {
        oldPassword: oldPassword,
        newPassword: newPassword
      }
      let customErrorHandler = (response) => {
        let hasError = response.errorCode === 'NOT_FOUND'
        return hasError
      }
      $http.put(url, payload, {
        customErrorHandler: customErrorHandler
      }).then(
        function (response) {
          currentUser.update(response.data)
          defer.resolve(response)
        }, function (response) {
          defer.reject(response)
        }
      )
      return defer.promise
    },
    checkIfPasswordResetLinkExpired (token) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/user/password/reset/${token}/status`

      $http.get(url).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    resetPassword (password, token) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/user/password/update/${token}`
      let payload = {
        password: password
      }

      $http.put(url, payload).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    sendInvitationToSmartvidEmail (email) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/user/email/invite/${email}`

      $http.post(url).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    registerNewUser (firstName, lastName, email, password, isProcoreTrial, isBim360Trial, isEgnyteTrial,
                     isOxBlueTrial, isBoxTrial, hubspotutk) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/user/register`
      let trialType
      if (isBim360Trial) {
        trialType = 'BIM360'
      } else if (isProcoreTrial) {
        trialType = 'PROCORE'
      } else if (isEgnyteTrial) {
        trialType = 'EGNYTE'
      } else if (isOxBlueTrial) {
        trialType = 'OXBLUE'
      } else if (isBoxTrial) {
        trialType = 'BOX'
      } else {
        trialType = 'UNSPECIFIED'
      }
      let payload = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        trialType: trialType,
        hubspotutk: hubspotutk
      }

      $http.post(url, payload).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    validateEmail (token) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/user/activate/${token}`

      $http.post(url).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    sendRegisterNewUserEmail (email) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/user/email/resend/${email}`

      $http.post(url).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    sendWelcomeNewUserEmail (email) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/user/email/welcome/${email}`

      $http.post(url).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    joinOrganization (token) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/organization/user/${token}`

      $http.post(url).then(function (response) {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    updateUserInformation (firstName, lastName, email, password, userToken, customErrorHandler) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/user/profile/${userToken}`
      let payload = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password
      }

      let config = {customErrorHandler: customErrorHandler}

      $http.put(url, payload, config).then(function (response) {
        defer.resolve(response)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    /**
     * Used for autocomplete search of users by email address.
     * e.g. when creating a project and inviting users.
     * @param oranizationId the organization id
     * @param email a part of an email address which belongs to the organization
     * @returns  promise
     */
    getUsersByPartialEmailForOrg (organizationId, email) {
      let url = `${rootUrl}/api/organization/${organizationId}/user/email/${email}`
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('getUsersByPartialEmailForOrg', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    },

    /**
     * Used for autocomplete search of assignee for observations by email in project.
     */
    findAssigneeUsersByPartialEmailInProject (projectId, email) {
      let url = `${rootUrl}/api/project/${projectId}/user/assignee/email?limit=50` + (email ? `&email=${encodeURIComponent(email)}` : '')
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('findAssigneeUsersByPartialEmailInProject', response.data)
        defer.resolve(_.sortBy(response.data, 'email'))
      }, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    },

    /**
     * Used for autocomplete search of assignee for observations by email in organization.
     */
    findAssigneeUsersByPartialEmailInOrganization (organizationId, email) {
      let url = `${rootUrl}/api/organization/${organizationId}/user/assignee/email?limit=50` + (email ? `&email=${encodeURIComponent(email)}` : '')
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('findAssigneeUsersByPartialEmailInOrganization', response.data)
        defer.resolve(_.sortBy(response.data, 'email'))
      }, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    },

    /**
     * Used for autocomplete search of users by email address.
     * e.g. when creating a project and inviting users.
     * @param oranizationId the organization id
     * @param email a part of an email address which belongs to the organization
     * @returns  promise
     */
    getUsersByPartialEmailForOrgs (organizationIds, email) {
      let url = `${rootUrl}/api/organization/user/email/${email}`
      let defer = $q.defer()

      let payload = {
        items: organizationIds || []
      }

      $http.post(url, payload).then((response) => {
        $log.debug('getUsersByPartialEmailForOrgs', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    },

    /**
     * Used for autocomplete search of users by email address.
     * e.g. when sharing a project asset with users.
     * @param projectId the project id
     * @param email a part of an email address which belongs to the project
     * @returns  promise
     */
    getUsersByPartialEmailForProject (projectId, email) {
      let url = `${rootUrl}/api/project/${projectId}/user/email/${email}`
      let defer = $q.defer()

      $http.get(url).then((response) => {
        $log.debug('getUsersByPartialEmailForProj', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    },

    /**
     * Used for autocomplete search of users by email address.
     * e.g. when sharing a project asset with users.
     * @param projectId the project id
     * @param email a part of an email address which belongs to the project
     * @returns  promise
     */
    getUsersByPartialEmailForProjects (projectIds, email) {
      let url = `${rootUrl}/api/project/user/email/${email}`
      let defer = $q.defer()

      let payload = {
        items: projectIds || []
      }

      $http.post(url, payload).then((response) => {
        $log.debug('getUsersByPartialEmailForProjects', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    },

    getUserStatus (email) {
      let url = `${rootUrl}/api/user/status/${email}`
      let defer = $q.defer()

      $http.get(url).then((response) => {
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })
      return defer.promise
    }

  }

  return api
})
