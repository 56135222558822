/* global angular,analytics */

angular.module('smartvid').controller('TagOptionsCtrl', function ($scope, $stateParams, $rootScope, $window, smartvidApi,
                                                                  modal, utils, tagVerificationService) {
  const LEFT_OFFSET = 17
  const TOP_OFFSET = 17
  $scope.shareTag = () => {
    $scope.modal.open('shareAsset', {
      asset: $scope.asset,
      tag: $scope.tag,
      currentProject: $scope.currentProject
    })
    $scope.flyout.close()
  }

  $scope.verifyTag = () => {
    tagVerificationService.verifyTag($scope.tag, $scope.asset).then(() => {
      if ($scope.onVerify) {
        $scope.onVerify($scope.tag)
      }
    })
    $scope.flyout.close()
  }

  $scope.unverifyTag = () => {
    tagVerificationService.unverifyTag($scope.tag, $scope.asset).then(() => {
      if ($scope.onUnverify) {
        $scope.onUnverify($scope.tag)
      }
    })
    $scope.flyout.close()
  }

  $scope.rejectTag = () => {
    tagVerificationService.rejectTag($scope.tag, $scope.tagCollection, $scope.asset).then(() => {
      if ($scope.onReject) {
        $scope.onReject($scope.tag)
      }
    })
    $scope.flyout.close()
  }

  $scope.deleteTag = () => {
    $scope.flyout.isOpen = false
    modal.open('deleteConfirm', {
      confirm () {
        if ($scope.onDelete) {
          $scope.onDelete($scope.tag)
        }

        analytics.track('Delete Tag Instance', {
          category: 'Tag Action',
          tagName: $scope.tag.text
        })
        smartvidApi.destroyTagInstance($scope.tag.id).then((response) => {
          $scope.tagCollection.removeById($scope.tag.id)
          if ($scope.tag.status === 'PENDING_VERIFICATION') {
            $scope.asset.unverifiedTagsCount--
          }
          if ($scope.onTagDeleted) {
            $scope.onTagDeleted($scope.tag)
          }
          $scope.asset.tagsCount--
          $scope.flyout.close()
          utils.digest($scope)
        })
      },
      hasSelections: true
    })
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top - TOP_OFFSET,
      left: $scope.styles.left + LEFT_OFFSET
    }
  }

  $scope.isShowVerify = isShowVerify
  $scope.isShowUnverify = isShowUnverify
  $scope.isShowDelete = isShowDelete

  function isShowVerify (tag) {
    return tag.status === 'PENDING_VERIFICATION' && tag.type !== 'MANUAL'
  }

  function isShowUnverify (tag) {
    return tag.status === 'VERIFIED' && tag.type !== 'MANUAL' && tag.type !== 'INTEGRATION'
  }

  function isShowDelete (tag) {
    return !isShowVerify(tag) && !isShowUnverify(tag)
  }
})
