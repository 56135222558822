/* global angular, _ */

angular.module('smartvid').factory('BaseCollection', function (BaseModel, $log) {
  class BaseCollection {
    constructor (models, SuppliedModel) {
      this.reset()
      this.add(models, SuppliedModel)
      this.allSelected = false
    }

    init (collection, SuppliedModel) {
      let modelList = (collection instanceof BaseCollection) ? collection.models : collection || []
      this.reset()
      this.add(modelList, SuppliedModel)
    }

    add (models, SuppliedModel, beginning = false) {
      models = (!_.isArray(models)) ? [models] : models

      let that = this
      let mapped = models.map(function (model) {
        if (_.isEmpty(_.keys(model))) return

        let ret
        if (model instanceof BaseModel) {
          ret = model
        } else if (SuppliedModel) {
          ret = new SuppliedModel(model)
        } else {
          ret = new BaseModel(model)
        }
        if (that.allSelected) {
          ret.selected = true
        }
        return ret
      })

      if (angular.isFunction(this.sortedIndexFunc)) {
        _.each(mapped, function (model) {
          let sortedIndex = _.sortedIndex(that.models, model, that.sortedIndexFunc)
          that.models.splice(sortedIndex, 0, model)
        })
      } else {
        if (!beginning) {
          this.models = this.models.concat(mapped)
        } else {
          this.models = mapped.concat(this.models)
        }
      }

      this.models = _.compact(this.models)

      // Provides a hook for collections to manipulate models returned by server. Used by comment collection.
      if (angular.isFunction(this.postAddProcessing)) {
        this.postAddProcessing()
      }
    }

    // TODO (rrubbico) stop using upsert for nextPage calls - it requires too many passes
    upsert (models, SuppliedModel, beginning = false, oiteratee = undefined) {
      models = (!_.isArray(models)) ? [models] : models
      let updated = false
      let collisionCount = 0
      _.each(models, (model) => {
        updated = false
        if (this.findAndUpdate({id: model.id}, model, oiteratee)) {
          updated = true
          collisionCount++
        }

        if (!updated) this.add(model, SuppliedModel, beginning)
      })

      if (collisionCount > 5) {
        $log.warn('Many collisions detected when upserting to collection. ' +
          'This could be an indication that redundant models are being fetched which could cause poor performance. ' +
          'Collision Count = ' + collisionCount)
      }
    }

    findAndUpdate (match, model, oiteratee = undefined) {
      let found = this.first(match)
      return (found) ? found.update(model, oiteratee) : undefined
    }

    reset () {
      this.models = []
      this.page = 0
      this.canFetch = true
      this.isFetching = false
      this.allSelected = false
    }

    removeById (id) {
      for (let i = 0; i < this.models.length; i++) {
        if (this.models[i].id === id) {
          this.models.splice(i, 1)
          if (this.isEmpty) {
            this.allSelected = false
          }
          return
        }
      }
    }

    findById (id) {
      for (let i = 0; i < this.models.length; i++) {
        if (this.models[i].id === id) {
          return this.models[i]
        }
      }
      return undefined
    }

    uniq () {
      return _.uniq(this.models, 'id')
    }

    uniqOn (attribute) {
      return _.uniq(this.models, attribute)
    }

    pluck (key) {
      return _.compact(_.pluck(this.models, key))
    }

    where (match) {
      return _.compact(_.where(this.models, match))
    }

    filter (predicateFunction) {
      return _.compact(_.filter(this.models, predicateFunction))
    }

    first (match) {
      return (match) ? _.findWhere(this.models, match) : _.first(this.models)
    }

    get isEmpty () {
      return _.isEmpty(this.models)
    }

    get length () {
      /*
       TODO
       I'm  not understanding _why_ `this` is an empty object and this.models doesn't exist. But it's annoying, so I'm going to put this workaround on for now, and I'll solve this another day.
       */
      if (!this.models) {
        return 0
      }

      return this.models.length
    }

    resetSelected () {
      for (let i = 0; i < this.models.length; i++) {
        this.models[i].selected = false
      }
      this.allSelected = false
    }

    selectAll () {
      this.allSelected = true
      _.each(this.models, (m) => {
        m.selected = true
      })
    }

    getSelected () {
      return _.where(this.models, {selected: true})
    }

    getUnselected () {
      return _.where(this.models, {selected: false})
    }

    get (idx) {
      return this.models && this.models.length > idx ? this.models[idx] : undefined
    }

  }

  return BaseCollection
})
