/* global angular _ */
/**
 * Created by ryanrubbico on 1/3/18.
 */
angular.module('smartvid').factory('StorageServiceUserModel', function (BaseModel) {
  class StorageServiceUserModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined,
        account: undefined,
        userId: undefined,
        service: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return StorageServiceUserModel
})
