/* global angular, _ */

angular.module('smartvid').service('reportingApi', function ($http, $q, $log, config, jstzService) {
  let rootUrl = config.env.development.apiRootUrl

  let api = {
    generateSafetySummaryReport (request, customErrorHandler) {
      let url = `${rootUrl}/api/report/safety_summary`
      let defer = $q.defer()

      let defaults = {
        projectIds: [],
        tagConfidenceLevel: 'HIGH',
        selectedSafetyTags: [],
        isConstructionRelatedOnly: true,
        toUserEmails: [],
        startDate: null,
        endDate: null,
        recipeType: 'PDF'
      }

      let payload = _.defaults(request || {}, defaults)

      $http.post(url, payload, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('generateSafetySummaryReport', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    generatePhotoReport (request, customErrorHandler) {
      let url = `${rootUrl}/api/report/photo`
      let defer = $q.defer()

      let defaults = {
        projectId: '',
        tagConfidenceLevel: 'HIGH',
        selectedSafetyTags: [],
        isConstructionRelatedOnly: true,
        toUserEmails: [],
        startDate: null,
        endDate: null,
        recipeType: 'PDF',
        isIncludeAllTags: false,
        description: ''
      }

      let payload = _.defaults(request || {}, defaults)

      $http.post(url, payload, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('generatePhotoReport', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    updateSafetySummaryReport (request, customErrorHandler) {
      let url = `${rootUrl}/api/report/safety_summary`
      let defer = $q.defer()

      let defaults = {
        projectIds: [],
        tagConfidenceLevel: 'HIGH',
        selectedSafetyTags: [],
        isConstructionRelatedOnly: true,
        toUserEmails: [],
        startDate: null,
        endDate: null,
        recipeType: 'PDF'
      }

      let payload = _.defaults(request || {}, defaults)

      $http.put(url, payload, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('generateSafetySummaryReport', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    updatePhotoReport (request, customErrorHandler) {
      let url = `${rootUrl}/api/report/photo`
      let defer = $q.defer()

      let defaults = {
        projectId: '',
        tagConfidenceLevel: 'HIGH',
        selectedSafetyTags: [],
        isConstructionRelatedOnly: true,
        toUserEmails: [],
        startDate: null,
        endDate: null,
        recipeType: 'PDF',
        isIncludeAllTags: false,
        description: ''
      }

      let payload = _.defaults(request || {}, defaults)

      $http.put(url, payload, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('generatePhotoReport', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getLastExecutedPhotoReportParameters (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/report/photo/project/${projectId}/latest/parameters`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getLastExecutedPhotoReportParameters', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    rerunLastExecutedPhotoReport (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/report/photo/project/${projectId}/latest`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('rerunLastExecutedPhotoReport', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    generateFilesInfoReport (orgId, request, customErrorHandler) {
      let url = `${rootUrl}/api/report/${orgId}/files_info`
      let defer = $q.defer()

      let defaults = {
        projectId: '',
        tagConfidenceLevel: 'HIGH',
        selectedSafetyTags: [],
        isConstructionRelatedOnly: true,
        startDate: null,
        endDate: null
      }

      let payload = _.defaults(request || {}, defaults)

      $http.post(url, payload, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('generateFilesInfoReport', response.data)
        defer.resolve(response)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getReportSystemData () {
      let url = `${rootUrl}/api/report/properties/global`
      let defer = $q.defer()

      function handleReportSystemDataErrorResponse (response) {
        defer.reject(response)
      }

      $http.get(url).then(function handleReportSystemDataSuccessResponse (response) {
        defer.resolve(response.data)
      }, handleReportSystemDataErrorResponse).catch(handleReportSystemDataErrorResponse)

      return defer.promise
    },

    updateReportingScheduleData (scheduledReportDataId, request, customErrorHandler) {
      let url = `${rootUrl}/api/report/schedule/${scheduledReportDataId}`
      let defer = $q.defer()

      function handleErrorResponse (response) {
        defer.reject(response)
      }

      $http.put(url, request, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('updateReportingScheduleData', response.data)
        defer.resolve(response.data)
      }, handleErrorResponse).catch(handleErrorResponse)

      return defer.promise
    }
  }

  return api
})
