/* global angular _ */

angular.module('smartvid').factory('ProcorePhotoSyncSettingsModel', function (BaseModel) {
  class ProcorePhotoSyncSettingsModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        userId: undefined,
        isEnabled: false,
        isRunOnce: false,
        procoreUserId: undefined,
        procoreUserName: undefined,
        procoreCompanyId: undefined,
        procoreProjectId: undefined,
        procoreAlbumId: undefined,
        fixedAlbumName: undefined,
        albumHandlingStrategy: undefined,
        photoSyncDirection: undefined,
        lastSyncDate: -1,
        rateLimitInfo: undefined,
        isProcessing: false,
        syncOnlyAfterDate: -1,
        lastHeartbeat: -1,
        isDownloadLocations: false,
        syncConfigParams: {
          syncInterval: -1,
          downloadInterval: -1,
          staleSyncCleanupInterval: -1
        },
        historicalDataSyncDateRange: undefined,
        historicalDataSyncThresholdDate: undefined
      }

      super(_.defaults(attrs.photoSyncSettings || {}, defaults))

      this.userId = attrs.userId
      this.isEnabled = attrs.isEnabled === undefined ? false : attrs.isEnabled
      this.isRunOnce = attrs.isRunOnce === undefined ? false : attrs.isRunOnce
      this.lastSyncDate = attrs.lastSyncDate === undefined ? -1 : attrs.lastSyncDate
      this.rateLimitInfo = attrs.rateLimitInfo
      this.isProcessing = attrs.isProcessing
      this.syncOnlyAfterDate = attrs.syncOnlyAfterDate === undefined ? -1 : attrs.syncOnlyAfterDate
      this.lastHeartbeat = attrs.lastHeartbeat === undefined ? -1 : attrs.lastHeartbeat
      this.syncConfigParams = attrs.syncConfigParams
    }
  }

  return ProcorePhotoSyncSettingsModel
})
