import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import {
  ApplyPreviousNextObservationView,
  ChangeObservationStatus,
  ObservationUpdated,
  ReviewObservation,
  ViewObservation,
} from './observations.actions'
import { ObservationService } from 'modules/observations/services/observation.service'
import { Observation } from 'modules/observations/models/observation.model'
import { finalize, tap, take, switchMap } from 'rxjs/operators'
import { concat, EMPTY, of } from 'rxjs'
import { ObservationListState } from 'modules/state/observation-list.state'
import { Injectable } from '@angular/core'

export interface ObservationViewStateModel {
  projectId: string
  observationId: string
  observation: Observation
  isLoading: boolean
  nextStatuses: string[]
  nextObservation: Observation
  previousObservation: Observation
}

@State<ObservationViewStateModel>({
  name: 'observationView',
  defaults: {
    projectId: null,
    observationId: null,
    observation: null,
    nextStatuses: [],
    isLoading: false,
    nextObservation: null,
    previousObservation: null,
  },
})
@Injectable()
export class ObservationViewState {
  constructor(private store: Store, private api: ObservationService) {}

  @Selector()
  static observation(state: ObservationViewStateModel) {
    return state.observation
  }

  @Selector()
  static isLoading(state: ObservationViewStateModel) {
    return state.isLoading
  }

  @Selector()
  static nextStatuses(state: ObservationViewStateModel) {
    return state.nextStatuses
  }

  @Selector()
  static nextObservation(state: ObservationViewStateModel) {
    return state.nextObservation
  }

  @Selector()
  static previousObservation(state: ObservationViewStateModel) {
    return state.previousObservation
  }

  @Action(ViewObservation, { cancelUncompleted: true })
  viewObservation(ctx: StateContext<ObservationViewStateModel>, { projectId, observationId }: ViewObservation) {
    ctx.patchState({ projectId, observationId, isLoading: true })
    return this.reloadObservation(ctx).pipe(
      take(1),
      switchMap(observation => {
        return observation.canEdit ? concat(this.getNextStatus(ctx)) : of([])
      }),
      finalize(() => ctx.patchState({ isLoading: false }))
    )
  }

  @Action(ObservationUpdated, { cancelUncompleted: true })
  updated({ getState, dispatch }: StateContext<ObservationViewStateModel>, { observationId }: ObservationUpdated) {
    let state = getState()
    if (state.observationId !== observationId) return EMPTY
    return dispatch([new ViewObservation(state.projectId, state.observationId)])
  }

  @Action(ReviewObservation)
  reviewObservation({ dispatch }: StateContext<ObservationViewStateModel>, { request }: ReviewObservation) {
    return this.api.reviewObservation(request.projectId, request.observationId, request).pipe(
      tap(() => {
        return dispatch(new ObservationUpdated(request.observationId))
      })
    )
  }

  @Action(ChangeObservationStatus)
  updateStatus({ dispatch }: StateContext<ObservationViewStateModel>, { request }: ChangeObservationStatus) {
    return this.api.updateObservationStatus(request.projectId, request.observationId, request).pipe(
      tap(() => {
        return dispatch(new ObservationUpdated(request.observationId))
      })
    )
  }

  @Action(ApplyPreviousNextObservationView)
  applyPreviousNextObservationView(
    ctx: StateContext<ObservationViewStateModel>,
    { observationId }: ApplyPreviousNextObservationView
  ) {
    ctx.patchState({
      nextObservation: null,
      previousObservation: null,
    })

    const index = this.getObservationIndex(observationId)

    for (let i = index + 1; i < this.downloadedObservations().length; i++) {
      if (this.downloadedObservations()[i] instanceof Observation) {
        ctx.patchState({
          nextObservation: this.downloadedObservations()[i] as Observation,
        })
        break
      }
    }

    for (let i = index - 1; i >= 0; i--) {
      if (this.downloadedObservations()[i] instanceof Observation) {
        ctx.patchState({
          previousObservation: this.downloadedObservations()[i] as Observation,
        })
        break
      }
    }
  }

  private downloadedObservations() {
    return this.store.selectSnapshot(ObservationListState.data)
  }

  private getObservationIndex(observationId: string) {
    return this.store.selectSnapshot(ObservationListState.data).findIndex(obs => {
      if (obs instanceof Observation) {
        return obs.observationId === observationId
      }
      return false
    })
  }

  reloadObservation({ getState, patchState }: StateContext<ObservationViewStateModel>) {
    let { projectId, observationId } = getState()
    return this.api.getObservation(projectId, observationId).pipe(tap(observation => patchState({ observation })))
  }

  getNextStatus({ getState, patchState }: StateContext<ObservationViewStateModel>) {
    let { projectId, observationId } = getState()
    return this.api
      .getNextStatuses(projectId, observationId)
      .pipe(tap(resp => patchState({ nextStatuses: resp.nextSteps })))
  }
}
