import { Action, Actions, ofActionDispatched, Selector, State, StateContext, Store } from '@ngxs/store'
import { ObservationService } from 'modules/observations/services/observation.service'
import {
  ApplyObservationFilters,
  ApplyObservationQuickFilters,
  ApplyObservationSearch,
  ApplyObservationSort,
  CreateObservation,
  GetObservationCount,
  GetObservationPage,
  ObservationUpdated,
  ReloadObservations,
  RemoveObservation,
  ResetObservationAdvancedFilters,
  ResetObservationFilters,
  ResetObservationSearch,
  ResetObservationSearchAndFilters,
  UpdateObservationFilters,
} from './observations.actions'
import { finalize, map, takeUntil, tap } from 'rxjs/operators'
import { groupBy } from 'lodash'
import {
  Observation,
  ObservationFilterCriteria,
  ObservationGroup,
  ObservationPage,
  ObservationQuickFilterCriteria,
  ObservationRow,
  ObservationSearchCountRequest,
  ObservationSearchCriteria,
  ObservationSortType,
} from 'modules/observations/models/observation.model'
import { Observable } from 'rxjs'
import * as moment from 'moment'
import deepEqual from 'deep-equal'
import { ObservationCommonState } from './observation-common.state'
import { LIST_VIEW_OBSERVATION_DETAILS, ObservationDetails } from '../observations/models/observation-details.enum'
import { RawParams, StateService } from '@uirouter/core'
import { DashboardDataHelperService } from '../core/services/dashboard-data-helper.service'
import { CURRENT_USER, CurrentUser, Utils, UTILS } from 'angular2/shared/smartvid.types'
import { Inject, Injectable } from '@angular/core'
import { ObservationType } from '../observations/models/observation-type.enum'
import { RouteChangeSuccess, RouteChangeStart } from 'modules/state/router.actions'
import { RouterState } from 'modules/state/router.state'

export interface ObservationListStateModel {
  data: ObservationRow[]
  cachedData: Observation[]
  count: number
  loadingPages: Set<number>
  isLoading: boolean
  isFetched: boolean
  isCountFetched: boolean
  sortType: ObservationSortType
  quickFilterCriteria: ObservationQuickFilterCriteria
  filterCriteria: ObservationFilterCriteria
  searchCriteria: ObservationSearchCriteria
  observationDetails: ObservationDetails[]
  lastUpdatedObservationId: string
  scrollToObservationId: string
}

@State<ObservationListStateModel>({
  name: 'observationList',
  defaults: {
    data: [],
    cachedData: [],
    count: 0,
    loadingPages: new Set<number>(),
    isLoading: false,
    isFetched: false,
    isCountFetched: false,
    sortType: ObservationSortType.RISK,
    quickFilterCriteria: ObservationQuickFilterCriteria.empty(),
    filterCriteria: ObservationFilterCriteria.empty(),
    searchCriteria: ObservationSearchCriteria.empty(),
    observationDetails: LIST_VIEW_OBSERVATION_DETAILS,
    lastUpdatedObservationId: undefined,
    scrollToObservationId: undefined,
  },
})
@Injectable()
export class ObservationListState {
  private OBSERVATION_FILTER_STORAGE_KEY = 'OBSERVATION_FILTER_STATE'

  constructor(
    private store: Store,
    private api: ObservationService,
    private actions$: Actions,
    private stateService: StateService,
    private dashboardDataHelper: DashboardDataHelperService,
    @Inject(UTILS) private utils: Utils,
    @Inject(CURRENT_USER) private currentUser: CurrentUser
  ) {}

  @Selector()
  static data(state: ObservationListStateModel) {
    return state.data
  }

  @Selector()
  static cachedData(state: ObservationListStateModel) {
    return state.cachedData
  }

  @Selector()
  static count(state: ObservationListStateModel) {
    return state.isFetched && state.isCountFetched ? state.count : ''
  }

  @Selector()
  static isLoading(state: ObservationListStateModel) {
    return state.isLoading
  }

  @Selector()
  static isDataEmpty(state: ObservationListStateModel) {
    return state.data.length < 1 && state.isFetched
  }

  @Selector()
  static isDataFull(state: ObservationListStateModel) {
    return state.data.length > 0 && state.isFetched
  }

  @Selector()
  static isFilterUsed(state: ObservationListStateModel) {
    return !state.filterCriteria.isEmpty()
  }

  @Selector()
  static isQuickFilterUsed(state: ObservationListStateModel) {
    return !deepEqual(state.quickFilterCriteria, ObservationQuickFilterCriteria.empty())
  }

  @Selector()
  static isAnyFilterUsed(state: ObservationListStateModel) {
    return this.isFilterUsed(state) || this.isQuickFilterUsed(state)
  }

  @Selector()
  static isSearchUsed(state: ObservationListStateModel) {
    return !deepEqual(state.searchCriteria, ObservationSearchCriteria.empty())
  }

  @Selector()
  static sortType(state: ObservationListStateModel) {
    return state.sortType
  }

  @Selector()
  static quickFilterCriteria(state: ObservationListStateModel) {
    return state.quickFilterCriteria
  }

  @Selector()
  static filterCriteria(state: ObservationListStateModel) {
    return state.filterCriteria
  }

  @Selector()
  static searchCriteria(state: ObservationListStateModel) {
    return state.searchCriteria
  }

  @Selector()
  static scrollToIndex(state: ObservationListStateModel): number {
    return state.data.findIndex(row => {
      if (row instanceof Observation) {
        return row.observationId === state.scrollToObservationId
      } else {
        return false
      }
    })
  }

  @Selector()
  static observationDetails(state: ObservationListStateModel) {
    return state.observationDetails
  }

  @Selector()
  static scrollToObservationId(state: ObservationListStateModel): string {
    return state.scrollToObservationId
  }

  @Action(ApplyObservationSort)
  applySort({ patchState, dispatch }: StateContext<ObservationListStateModel>, { payload }: ApplyObservationSort) {
    patchState({ sortType: payload })
    return dispatch(new ReloadObservations())
  }

  @Action(RemoveObservation)
  removeObservation({ patchState }: StateContext<ObservationListStateModel>, { projectId, observationId }) {
    return this.api.deleteObservation(projectId, observationId).pipe(
      tap(() => {
        const selectedSnapshot = this.store.selectSnapshot(ObservationListState.data)
        const filter = selectedSnapshot.filter(obs => {
          if (obs instanceof Observation) {
            return obs.observationId !== observationId
          }
          return false
        })
        patchState({ data: filter })
      })
    )
  }

  @Action(ApplyObservationQuickFilters)
  applyQuickFilters(
    { patchState, dispatch }: StateContext<ObservationListStateModel>,
    { payload }: ApplyObservationQuickFilters
  ) {
    patchState({ quickFilterCriteria: payload })
    return dispatch(new ReloadObservations())
  }

  @Action(UpdateObservationFilters)
  updateFilters({ patchState }: StateContext<ObservationListStateModel>, { payload }: ApplyObservationFilters) {
    if (!payload.isEmpty()) {
      patchState({
        quickFilterCriteria: ObservationQuickFilterCriteria.empty(),
      })
    }
    patchState({ filterCriteria: payload })
  }

  @Action(ApplyObservationFilters)
  applyFilters(
    { patchState, dispatch }: StateContext<ObservationListStateModel>,
    { payload }: ApplyObservationFilters
  ) {
    if (!payload.isEmpty()) {
      patchState({
        quickFilterCriteria: ObservationQuickFilterCriteria.empty(),
      })
    }
    patchState({ filterCriteria: payload })
    this.saveMyFilterCriteriaForOrg(payload, this.selectNavigation().organizationId)
    return dispatch(new ReloadObservations())
  }

  @Action(ApplyObservationSearch)
  applySearchContext({ patchState }: StateContext<ObservationListStateModel>, { payload }: ApplyObservationSearch) {
    patchState({ searchCriteria: payload })
    //this.stateService.reload()
    this.stateService.transitionTo(
      this.stateService.$current,
      {
        ...this.stateService.params,
        forceShowList: true,
      },
      {
        reload: true,
        inherit: false,
      }
    )
  }

  @Action(ResetObservationFilters)
  resetFilters({ patchState, dispatch }: StateContext<ObservationListStateModel>) {
    patchState({
      filterCriteria: ObservationFilterCriteria.empty(),
      quickFilterCriteria: ObservationQuickFilterCriteria.empty(),
      isFetched: false,
    })
    this.removeMyFilterCriteriaForOrg(this.selectNavigation().organizationId)
    return dispatch(new ReloadObservations())
  }

  @Action(ResetObservationAdvancedFilters)
  resetAdvancedFilters({ patchState, dispatch }: StateContext<ObservationListStateModel>) {
    patchState({
      filterCriteria: ObservationFilterCriteria.empty(),
      isFetched: false,
    })
    this.removeMyFilterCriteriaForOrg(this.selectNavigation().organizationId)
    return dispatch(new ReloadObservations())
  }

  @Action(ResetObservationSearch)
  resetObservationSearch({ patchState }: StateContext<ObservationListStateModel>) {
    patchState({
      searchCriteria: ObservationSearchCriteria.empty(),
      isFetched: false,
    })
    this.stateService.reload()
  }

  @Action(ResetObservationSearchAndFilters)
  resetObservationSearchAndFilters({ patchState }: StateContext<ObservationListStateModel>) {
    patchState({
      searchCriteria: ObservationSearchCriteria.empty(),
      quickFilterCriteria: ObservationQuickFilterCriteria.empty(),
      filterCriteria: ObservationFilterCriteria.empty(),
      isFetched: false,
    })
    this.removeMyFilterCriteriaForOrg(this.selectNavigation().organizationId)
    this.stateService.reload()
  }

  @Action(ReloadObservations)
  reload({ patchState, dispatch }: StateContext<ObservationListStateModel>) {
    patchState({
      cachedData: [],
      data: [],
      count: 0,
      isLoading: false,
      isFetched: false,
      isCountFetched: false,
      loadingPages: new Set<number>(),
    })
    return dispatch([new GetObservationCount(), new GetObservationPage(ObservationPage.of(0, ObservationPage.SIZE))])
  }

  @Action(GetObservationCount, { cancelUncompleted: true })
  getCount({ patchState, getState }: StateContext<ObservationListStateModel>) {
    const state = getState()
    return this.getCountApi$(state).pipe(
      tap(count => {
        patchState({ count: count, isCountFetched: true })
      })
    )
  }

  @Action(GetObservationPage)
  getObservations(ctx: StateContext<ObservationListStateModel>, { payload }: GetObservationPage) {
    const state = ctx.getState()
    if (state.loadingPages.has(payload.pageNum) || state.isLoading) {
      // return
    } else {
      ctx.patchState({
        loadingPages: state.loadingPages.add(payload.pageNum),
      })
    }
    ctx.patchState({ isLoading: true })
    return this.api
      .getObservationApi(
        this.selectNavigation().organizationId,
        this.selectNavigation().projectGroupId,
        this.selectNavigation().projectId,
        this.store.selectSnapshot(ObservationListState.sortType),
        this.store.selectSnapshot(ObservationListState.searchCriteria),
        this.store.selectSnapshot(ObservationListState.filterCriteria),
        this.store.selectSnapshot(ObservationListState.quickFilterCriteria),
        this.store.selectSnapshot(ObservationListState.observationDetails),
        payload
      )
      .pipe(
        takeUntil(this.actions$.pipe(ofActionDispatched(ReloadObservations))),
        tap(data => {
          this.patchData(ctx, data)
        }),
        finalize(() => {
          this.onPageLoaded(ctx)
          ctx.patchState({ isLoading: false })
        })
      )
  }

  @Action(CreateObservation)
  createObservation(ctx: StateContext<ObservationListStateModel>, { request }: CreateObservation) {
    return this.api.createObservation(request).pipe(
      tap((observation: Observation) => {
        this.utils.notify('New observation created', '', 'View', null, () => {
          let project = this.dashboardDataHelper.getProjectByProjectId(observation.projectId)
          this.stateService.go('dashboard.observations.orgId.projectGroupId.projectId.viewer', {
            organizationId: project.organizationId,
            projectGroupId: project.projectGroup ? project.projectGroup.id : 'default',
            projectId: project.id,
            observationId: observation.observationId,
          })
        })
        ctx.dispatch(new ReloadObservations())
      })
    )
  }

  @Action(ObservationUpdated)
  observationUpdated({ patchState }: StateContext<ObservationListStateModel>, { observationId }: ObservationUpdated) {
    patchState({ lastUpdatedObservationId: observationId })
  }

  @Action(RouteChangeSuccess)
  routeChangeSuccess({ patchState }: StateContext<ObservationListStateModel>, { transition }: RouteChangeSuccess) {
    let obsRegex = new RegExp('^dashboard.observations.*$')
    let notObsRegex = new RegExp('(?!dashboard.observations)^.*$')
    // If transition from any Observation to other routes
    if (obsRegex.test(transition.from) && notObsRegex.test(transition.to)) {
      patchState({
        searchCriteria: ObservationSearchCriteria.empty(),
        filterCriteria: ObservationFilterCriteria.empty(),
      })
    }
  }

  @Action(RouteChangeStart)
  handleScrollToLastObservation(
    { patchState }: StateContext<ObservationListStateModel>,
    { transition }: RouteChangeStart
  ) {
    let viewerRegexp = new RegExp('^dashboard.observations.*viewer$')
    let obsListRegex = new RegExp('(?!.*?viewer)^dashboard.observations.*$')
    if (viewerRegexp.test(transition.from) && obsListRegex.test(transition.to)) {
      let previousState = this.store.selectSnapshot(RouterState.previousState)
      let previousStateHash = this.getListStateHash(previousState.name, previousState.params)
      let listStateHash = this.getListStateHash(transition.to, transition.toParams)
      if (previousStateHash === listStateHash) {
        patchState({
          scrollToObservationId: transition.fromParams.observationId,
        })
      } else {
        patchState({ scrollToObservationId: undefined })
      }
    } else {
      patchState({ scrollToObservationId: undefined })
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private onPageLoaded(ctx: StateContext<ObservationListStateModel>) {
    let state = ctx.getState()
    if (state.lastUpdatedObservationId) {
      if (ObservationListState.isSearchUsed(state) || ObservationListState.isFilterUsed(state)) {
        let found = state.cachedData.find(o => o.observationId === state.lastUpdatedObservationId)
        if (found) {
          this.notifyToRefreshListView(ctx)
        }
      }
      ctx.patchState({ lastUpdatedObservationId: undefined })
    }
  }

  private notifyToRefreshListView(ctx: StateContext<ObservationListStateModel>) {
    this.utils.clearNotifications()
    this.utils.notify('This view has been updated', '', 'Refresh', null, () => {
      ctx.dispatch(new ReloadObservations())
    })
  }

  private patchData(ctx: StateContext<ObservationListStateModel>, data: Observation[]) {
    const state = ctx.getState()
    const preparedData = this.prepareData(data)
    const mergedData: Observation[] = this.mergeData(state.cachedData, preparedData)
    ctx.patchState({
      cachedData: mergedData,
      data: this.groupData(mergedData, state.sortType),
      isFetched: true,
    })
  }

  private prepareData(data: Observation[]): Observation[] {
    data.forEach(obs => {
      if (obs.type === ObservationType.POSITIVE) {
        obs.risk = 0
      }
    })
    return data
  }

  private mergeData(cachedData: Observation[], data: Observation[]): Observation[] {
    let ids = new Set<string>(cachedData.map(o => o.observationId))
    const res = [...cachedData]
    data.forEach(obs => {
      if (!ids.has(obs.observationId)) {
        res.push(obs)
      }
    })
    return res
  }

  private selectNavigation() {
    return this.store.selectSnapshot(ObservationCommonState.navigation)
  }

  private getCountApi$(state: ObservationListStateModel): Observable<number> {
    if (state.searchCriteria.isEmpty() && state.filterCriteria.isEmpty()) {
      return this.getDatabaseCountApi$(state)
    } else {
      return this.getSearchCountApi$(state)
    }
  }

  private getSearchCountApi$(state: ObservationListStateModel): Observable<number> {
    const { organizationId, projectGroupId, projectId } = this.selectNavigation()
    const req = new ObservationSearchCountRequest()

    req.searchType = this.api.getSearchType(organizationId, projectGroupId, projectId)
    req.organizationId = organizationId
    req.projectGroupId = projectGroupId === 'default' ? null : projectGroupId
    req.projectId = projectId
    req.quickFilterCriteria = state.quickFilterCriteria
    req.filterCriteria = state.filterCriteria
    req.searchCriteria = state.searchCriteria
    return this.api.countObservations(req).pipe(map(resp => resp.grandTotal))
  }

  private getDatabaseCountApi$(state: ObservationListStateModel): Observable<number> {
    const { organizationId, projectGroupId, projectId } = this.selectNavigation()
    const quickFilter = ObservationQuickFilterCriteria.fromObject(state.quickFilterCriteria)
    if (projectId) {
      return this.api.countProjectObservations(projectId, quickFilter)
    } else if (organizationId && projectGroupId) {
      return this.api.countGroupObservations(organizationId, projectGroupId, quickFilter)
    } else if (organizationId) {
      return this.api.countOrgObservations(organizationId, quickFilter)
    } else {
      return this.api.countUserObservations(quickFilter)
    }
  }

  private groupData(data: Observation[], sortType: ObservationSortType) {
    const result: ObservationRow[] = []
    let sortedData = data.sort(this.getSortFn(sortType))
    let groups = groupBy(sortedData, obs => this.getGroupId(obs, sortType))
    // eslint-disable-next-line guard-for-in
    for (let projectGroupId in groups) {
      let group: Observation[] = groups[projectGroupId]
      result.push(new ObservationGroup(projectGroupId, group.length))
      group.sort(this.getSortFn(sortType))
      result.push(...group)
    }
    return result
  }

  private getGroupId(obs: Observation, sortType: ObservationSortType): string {
    switch (sortType) {
      case ObservationSortType.RISK:
        return obs.riskType.toString()
      case ObservationSortType.CREATED_DATE: {
        return moment(obs.dateCreated).format('MMM D, YYYY')
      }
    }
  }

  private getSortFn(sortType: ObservationSortType): (a: Observation, b: Observation) => number {
    const sortByRisk = (x: Observation, y: Observation) => {
      return y.risk === x.risk ? y.dateCreated - x.dateCreated : y.risk - x.risk
    }
    const sortByCreateTime = (x: Observation, y: Observation) => y.dateCreated - x.dateCreated
    switch (sortType) {
      case ObservationSortType.RISK:
        return sortByRisk
      case ObservationSortType.CREATED_DATE:
        return sortByCreateTime
    }
  }

  private getListStateHash(state: string, params: RawParams): string {
    return [state, params.organizationId, params.projectGroupId, params.projectId].join('_')
  }

  private removeMyFilterCriteriaForOrg(organizationId: string) {
    localStorage.removeItem(this.OBSERVATION_FILTER_STORAGE_KEY + ':' + this.currentUser.id + ':' + organizationId)
  }

  private saveMyFilterCriteriaForOrg(filterCriteria: ObservationFilterCriteria, organizationId: string) {
    localStorage.setItem(
      this.OBSERVATION_FILTER_STORAGE_KEY + ':' + this.currentUser.id + ':' + organizationId,
      JSON.stringify(filterCriteria)
    )
  }
}
