/* global angular, _ */

angular.module('smartvid').service('sharingApi', function ($http, $q, $log, config, AssetSearchContext, SearchEntityModel, $injector,
                                CommentsCollection, CommentModel, TagInstanceCollection, TagInstanceModel, apiUtils) {
  let rootUrl = config.env.development.apiRootUrl

  let convertShareWithUsers = (payload, users) => {
    payload.toUserId = []
    payload.toUserEmail = []
    _.each(users, (user) => {
      if (user.userId) {
        payload.toUserId.push({
          toUserId: user.userId,
          role: user.role.type
        })
      } else if (user.email) {
        payload.toUserEmail.push({
          toUserEmail: user.email,
          role: user.role.type
        })
      } else {
        payload.shareableLinkRole = user.role.type
      }
    })
  }

  let shareResource = (payload, projectId, users, sharingMessage, url) => {
    payload.projectId = projectId
    convertShareWithUsers(payload, users)
    payload.sharingMessage = sharingMessage
    let defer = $q.defer()
    $http.post(url, payload).then(function (response) {
      defer.resolve(response.data)
    })
    return defer.promise
  }

  let api = {
    shareContent (projectId, contentIds, contentShareType, users, sharingMessage) {
      let payload = {
        contentIds: contentIds,
        contentShareType: contentShareType
      }
      let url = `${rootUrl}/api/content/shared`
      return shareResource(payload, projectId, users, sharingMessage, url)
    },
    getSharedTag (linkId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/content/shared/tag/${linkId}`
      $http.get(url).then(function (response) {
        defer.resolve(response.data)
      })

      return defer.promise
    },
    getSharedComment (linkId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/content/shared/comment/thread/${linkId}`
      $http.get(url).then(function (response) {
        defer.resolve(response.data)
      })

      return defer.promise
    },
    getProjectForSharedAssets (linkId) {
      let url = `${rootUrl}/api/content/shared/assets/project/` + linkId
      let defer = $q.defer()

      $http.get(url, config).then(function (response) {
        let project = response.data
        $log.debug('getProjectForSharedAssets', project)
        defer.resolve(project)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },
    shareSearch (projectId, tagSearchEntityModels, createdByUserIds, freeTextFragments, gridState, users, sharingMessage, tagConfidenceLevel, searchDateRange, searchQueryType) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/content/shared/search`
      let tags = _.map(tagSearchEntityModels, (searchEntityModel) => {
        return {
          tagDefId: searchEntityModel.id,
          hasChildren: searchEntityModel.hasChildren
        }
      })
      let payload = {
        searchRequest: {
          tagDefs: tags,
          textFragments: freeTextFragments,
          userIds: createdByUserIds,
          searchQueryType: searchQueryType
        },
        projectId: projectId,
        groupByColumn: gridState.groupByColumn,
        groupByOrder: gridState.groupByOrder,
        sortByColumn: gridState.sortByColumn,
        sortByOrder: gridState.sortByOrder,
        sharingMessage: sharingMessage,
        tagConfidenceLevel: tagConfidenceLevel,
        searchDateRange: searchDateRange
      }
      convertShareWithUsers(payload, users)
      $http.post(url, payload).then(function (response) {
        defer.resolve(response.data)
      })
      return defer.promise
    },
    getSharedSearch (searchId, customErrorHandler) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/content/shared/search/${searchId}`
      $http.get(url, {
        customErrorHandler: customErrorHandler
      }).then(function (response) {
        let result = apiUtils.convertSearchResponseToAssetSearchContext(response.data)
        defer.resolve(result)
      }, function (response) {
        defer.reject(response)
      })
      return defer.promise
    },
    getShareInfo (sharingType, sharingId, customErrorHandler) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/content/shareinfo/${sharingType}/${sharingId}`
      $http.get(url, {
        customErrorHandler: customErrorHandler
      }).then((shareInfo) => {
        let result = shareInfo.data
        if (result.searchParams) {
          result.sharedSearch = apiUtils.convertSearchResponseToAssetSearchContext(result.searchParams)
        }
        defer.resolve(result)
      }, (response) => {
        defer.reject(response)
      })
      return defer.promise
    },
    getSharedProjects (sharingType, sharingId) {
      let url = `${rootUrl}/api/content/shared/projects/${sharingType}/${sharingId}`
      let defer = $q.defer()
      $http.get(url).then((response) => {
        defer.resolve(response.data)
      }, (response) => {
        defer.reject(response)
      })
      return defer.promise
    },
    getSharedAssets (sharingType, sharingId, options = {}, params = {}) {
      let assetsApi = $injector.get('assetsApi')
      let url = `${rootUrl}/api/content/shared/assets/${sharingType}/${sharingId}`
      return assetsApi._getServerAssets(url, params, options)
    },
    getSharedAssetsCount (sharingType, sharingId) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/content/shared/assets/count/${sharingType}/${sharingId}`
      $http.get(url).then((response) => {
        defer.resolve(response.data.totalCount)
      })
      return defer.promise
    },
    getSharedAssetGroupMetadata (sharingType, sharingId, options = {}) {
      let defer = $q.defer()
      let url = `${rootUrl}/api/content/shared/assets/metadata/${sharingType}/${sharingId}`
      let params = {}
      if (options.sortInfo) {
        params.sortColumn = _.map(options.sortInfo, (sortColumn) => {
          return `${sortColumn.sortBy}:${sortColumn.sortOrder}`
        })
      }
      $http.get(url, {params: params}).then((response) => {
        defer.resolve(response.data)
      }, (response) => {
        defer.reject(response)
      })
      return defer.promise
    },
    getSharedAsset (sharingType, sharingId, assetId) {
      let assetsApi = $injector.get('assetsApi')
      let defer = $q.defer()
      let url = `${rootUrl}/api/content/shared/asset/${sharingType}/${sharingId}?assetId=${assetId}`
      $http.get(url).then(function (response) {
        let commentCollection = new CommentsCollection()
        commentCollection.add(response.data.comments, CommentModel)

        $log.debug('getCommentsForAsset', commentCollection)

        let AssetModel = $injector.get('AssetModel')
        let model = new AssetModel(assetsApi._formatAssetNewAPI(response.data))
        model.comments = commentCollection
        model.commentsCount = response.data.comments.length

        let tagInstCollection = new TagInstanceCollection()
        tagInstCollection.add(response.data.tags, TagInstanceModel)
        model.tags = tagInstCollection
        model.tagsCount = response.data.tags.length
        model.unverifiedTagsCount = (model.tags) ? model.tags.getUnverifiedTags().length : 0
        defer.resolve(model)
      }).catch(function (data) {
        defer.reject(data)
      })

      return defer.promise
    }
  }

  return api
})
