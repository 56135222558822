/* global angular, $ */

/**
* Make an element draggable
*/
angular.module('smartvid').directive('makeDraggable', function (utils) {
  return {
    restrict: 'A',
    link: function (scope, el) {
      let watchListener = scope.$watch('row.entity', (newVal, oldVal) => {
        removeHandlers()
        if (newVal.canUpdate) {
          el.attr('draggable', 'true')
          addHandlers()
        } else {
          el.removeAttr('draggable')
        }
      })

      scope.$on('$destroy', () => {
        removeHandlers()
        watchListener()
      })

      function addHandlers () {
        el.on('dragstart', onDragStart)
        el.on('drag', onDrag)
        el.on('dragend', onDragEnd)
      }

      function removeHandlers () {
        el.off('dragstart', onDragStart)
        el.off('drag', onDrag)
        el.off('dragend', onDragEnd)
      }

      function onDragStart (e) {
        let node = scope.row.entity
        if (!node.canUpdate) {
          return
        }
        if ($('.drag').length > 0) {
          return false
        }
        $('.over').removeClass('over')
        $('.hovering').removeClass('hovering')
        setTimeout(function () {
          $('.tree-container').addClass('drag-active')
        }, 50)
        e.originalEvent.dataTransfer.effectAllowed = 'move'
        e.originalEvent.dataTransfer.setData('nodeId', node.id)
        this.classList.add('drag')
        utils.clearText()
      }

      function onDrag (e) {
        let node = scope.row.entity
        if (!node.canUpdate) {
          return
        }
        if (e.preventDefault) {
          e.preventDefault()
        }
        utils.clearText()
      }

      function onDragEnd (e) {
        let node = scope.row.entity
        if (!node.canUpdate) {
          return
        }
        this.classList.remove('drag')
        $('.tree-container').removeClass('drag-active')
      }
    }
  }
})
