/* global angular,_ */

angular.module('smartvid').service('errorUtils', function () {
  return {
    USER_INACTIVE_ERROR_CODE: 'user.inactive',
    USER_INVITED_ERROR_CODE: 'user.invited',
    USER_SSO: 'user.should.use.sso',
    BAD_PASSWORD_ERROR_CODE: 'resource.user.bad.password',
    USER_ALREADY_A_MEMBER_OF_ORG: 'user.already.member.of.org',
    USER_INVALID_EMAIL: 'user.invalid.email',
    BAD_USER_ERROR_CODE: 'resource.user.bad.credentials',

    isUserInvalidEmail (response) {
      return this.containsErrorCode(response, this.USER_INVALID_EMAIL)
    },

    isSsoUser (response) {
      return this.containsErrorCode(response, this.USER_SSO)
    },

    isUserAlreadyMemberOfOrg (response) {
      return this.containsErrorCode(response, this.USER_ALREADY_A_MEMBER_OF_ORG)
    },

    isUserInactive (response) {
      return this.containsErrorCode(response, this.USER_INACTIVE_ERROR_CODE)
    },

    isUserInvited (response) {
      return this.containsErrorCode(response, this.USER_INVITED_ERROR_CODE)
    },

    isBadPassword (response) {
      return this.containsErrorCode(response, this.BAD_PASSWORD_ERROR_CODE)
    },

    isBadUser(response) {
      return this.containsErrorCode(response, this.BAD_USER_ERROR_CODE)
    },

    isRateLimit (response) {
      return response && response.errorCode === 'RATE_LIMIT_REACHED'
    },

    getSsoUrl (response) {
      if (response && response.errorCode === 'BAD_REQUEST' && 'errorMessages' in response) {
        let errorMessage = _.find(response.errorMessages, (errorMessage) => { return errorMessage.label === this.USER_SSO })
        if (errorMessage) {
          return errorMessage.message
        }
      }
      return undefined
    },

    containsErrorCode (response, errorCode) {
      return (response && (response.errorCode === 'NOT_FOUND' || response.errorCode === 'BAD_REQUEST') && 'errorMessages' in response &&
        _.find(response.errorMessages,
          (errorMessage) => { return errorMessage.label === errorCode }))
    }

  }
})
