/* global angular, _ */

angular.module('smartvid').controller('DeletedProjectsGridOptionsCtrl', function ($state, $timeout,  $scope, $rootScope,  modal, smartvidApi,  dashboardDataHelper, utils, batchJobService) {
  /**
   * Permanently delete selected projects
   */
  $scope.deleteSelectedProjects = () => {
    let selected = $scope.projects.where({selected: true})
    let deleteIsProhibited = false
    selected.forEach(function (value) {
      if (!value.canDelete) {
        deleteIsProhibited = true
      }
    })
    let projectIds = _.pluck(selected, 'id')

    $scope.flyout.isOpen = false
    modal.open('projectPermanentlyDeleteConfirm', {
      confirm () {
        smartvidApi.deleteProjects(projectIds, true).then(() => {
          $timeout(() => {
            _.each(selected, (project) => {
              $scope.projects.removeById(project.id)
              dashboardDataHelper.getAllProjects().removeById(selected.id)
            })
            $state.go('dashboard.adminOrganizations.organizationId.deletedProjects', {}, {
              reload: true
            })
          }, 400)
          $scope.flyout.close()
        })
      },
      hasSelections: selected.length > 0,
      deleteIsProhibited: deleteIsProhibited
    })
  }

  /**
   * Restore selected projects
   */
  $scope.restoreSelectedProjects = () => {
    let selected = $scope.projects.where({selected: true})
    let deleteIsProhibited = false
    selected.forEach(function (value) {
      if (!value.canDelete) {
        deleteIsProhibited = true
      }
    })
    let projectIds = _.pluck(selected, 'id')

    $scope.flyout.isOpen = false
    modal.open('projectRestoreConfirm', {
      confirm () {
        smartvidApi.restoreProjects(selected[0].organizationId, projectIds).then((jobResponse) => {
          if (!jobResponse.jobName || !jobResponse.jobId) {
            return
          }
          let successCallback = () => {
            $timeout(() => {
              _.each(selected, (project) => {
                $scope.projects.removeById(project.id)
                dashboardDataHelper.getAllProjects().removeById(selected.id)
              })
              $state.go('dashboard.adminOrganizations.organizationId.deletedProjects', {}, {
                reload: true
              })
            }, 400)
            utils.notify('common.projectsRestore', '', 'common.view', null, () => {
              $state.go('dashboard.projects.projectId.files',
                {
                  projectId: selected[0].id,
                  isDemoProject: selected[0].isDemoProjectCopy,
                  searchContext: undefined,
                  searchFilter: undefined,
                  skipRecentSearches: true,
                  organizationId: selected[0].organizationId,
                  projectGroupId: selected[0].projectGroupId
                },
                {
                  reload: true
                })
            })
            $scope.flyout.close()
          }
          batchJobService.watchBatchJob(
            smartvidApi.getOrgJobStatus, selected[0].organizationId, jobResponse.jobName, jobResponse.jobId,
            null, null, successCallback, jobResponse);
        })
      },
      hasSelections: selected.length > 0,
      deleteIsProhibited: deleteIsProhibited
    })
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top,
      left: $scope.styles.left
    }
  }
})
