/* global angular,_ */

angular.module('smartvid').controller('InviteProjectMembersCtrl', function (
    $scope, $filter, $timeout, $q, currentUser, smartvidApi, emailUtils
) {
  $scope.selectedUsers = []
  $scope.inTransit = false
  $scope.userSearchOrg = getSearchOrg()
  $scope.userPoolRoles = currentUser.getAssignableProjectRoles($scope.currentProject)
  $scope.projectUserEmails = _.pluck($scope.projectMembers.models, 'email')

  $scope.inviteProjectMembers = inviteProjectMembers
  $scope.searchByEmail = searchByEmail
  $scope.getAllUsers = getAllUsers
  $scope.validateEmailFn = validateEmailFn

  function getSearchOrg () {
    // If user belongs to the project's organization, search this org, otherwise search first org for the user
    return _.find(currentUser.organizations, (org) => {
      return org.id === $scope.currentProject.organizationId
    }) ? $scope.currentProject.organizationId : currentUser.organizations[0].id
  }

  function inviteProjectMembers () {
    $scope.inTransit = true
    if ($scope.selectedUsers === null || !$scope.selectedUsers.length) {
      return
    }
    let apiUsers = _($scope.selectedUsers)
        .map((user) => {
          return {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            userEmail: user.email,
            role: user.role.name
          }
        }).value()

    return $scope.currentProject.inviteUsers(apiUsers).then(() => {
      $timeout(function () {
        $scope.inTransit = false
        $scope.modal.close()
      }, 100)
    }, () => {
      $scope.inTransit = false
    })
  }

  function searchByEmail (partialEmail) {
    return smartvidApi.getUsersByPartialEmailForOrg($scope.userSearchOrg, partialEmail)
  }

  function getAllUsers () {
    return smartvidApi.getOrganizationUsers($scope.userSearchOrg)
  }

  function validateEmailFn (email) {
    if (!email) {
      return $q.when()
    }
    let defer = $q.defer()
    validateEmail(email).then(isUserInProject).then(isUserActive).then(() => {
      defer.resolve()
    }, (error) => {
      defer.reject(error)
    })
    return defer.promise
  }

  function isUserInProject (email) {
    let defer = $q.defer()
    smartvidApi.getUsersByPartialEmailForProject($scope.currentProject.id, email).then(users => {
      if (users.length !== 0) {
        defer.reject($filter('i18next')('directives.createUser.userAlreadyBelongsToProject'))
      } else {
        defer.resolve(email)
      }
    })
    return defer.promise
  }

  function isUserActive (email) {
    let defer = $q.defer()
    smartvidApi.getUserByEmail(email).then((user) => {
      if (user.id && !user.isActive && !user.isInvited) {
        defer.reject($filter('i18next')('directives.userPool.userDeactivated'))
      } else {
        defer.resolve(email)
      }
    })
    return defer.promise
  }

  function validateEmail (email) {
    let defer = $q.defer()
    if (!emailUtils.isValidEmail(email)) {
      defer.reject({status: '', message: $filter('i18next')('directives.createUser.userInvalidEmail')})
    } else {
      defer.resolve(email)
    }
    return defer.promise
  }
})
