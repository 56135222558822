/* global angular _ */

angular.module('smartvid').factory('StructionSiteProjectInfoModel', function (BaseModel) {
  class StructionSiteProjectInfoModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined,
        name: undefined,
        selected: false
      }

      super(_.defaults(attrs || {}, defaults))
    }

    getDisplayName () {
      return this.name
    }
  }

  return StructionSiteProjectInfoModel
})
