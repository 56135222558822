/* global angular */

/**
 * Created by ryanrubbico on 1/13/17.
 */
angular.module('smartvid').factory('AddItemPlaceholder', function () {
  class AddItemPlaceholder {
    constructor () {
      this.addItemPlaceholder = true
    }
  }
  return AddItemPlaceholder
})
