import { MatDialogRef } from '@angular/material/dialog'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'sv-dialog-header',
  templateUrl: 'dialog-header.component.html',
  styleUrls: ['dialog-header.component.scss'],
})
export class DialogHeaderComponent {
  @Input() title: string
  @Input() onClose: Function

  constructor(private dialogRef: MatDialogRef<any>) {}

  close() {
    if (this.onClose) {
      this.onClose()
    } else {
      this.dialogRef.close()
    }
  }
}
