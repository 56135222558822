/* global angular,_ */
angular.module('smartvid').service('searchResultService', function (
  $q, $injector, $rootScope, $stateParams, searchApi, projectsApi, sharingApi, AssetSearchContext, utils, contentSharingContext,
  TagInstanceCollection, TagInstanceModel, CommentsCollection, CommentModel, AssetViewType, assetGridHelper
) {
  let _sharedSearchData
  let _globalSearchInfo
  let _currentProjectSearchInfo
  let _isSharingInitialized

  this.resetCurrentAssetGroup = resetCurrentAssetGroup
  this.resetGlobalAssetGroup = resetGlobalAssetGroup
  this.getCurrentSearchContext = getCurrentSearchContext
  this.isInSearchContext = isInSearchContext
  this.clearProjectSearchInfo = clearProjectSearchInfo
  this.clearGlobalSearchInfo = clearGlobalSearchInfo
  this.setCurrentProjectSearchContext = setCurrentProjectSearchContext
  this.getGlobalSearchContext = getGlobalSearchContext
  this.isInGlobalSearchContext = isInGlobalSearchContext
  this.setGlobalSearchContext = setGlobalSearchContext

  this.setSharedSearch = setSharedSearch
  this.clearSharedSearch = clearSharedSearch
  this.getSharedSearch = getSharedSearch
  this.getSharedSearchLinkId = getSharedSearchLinkId
  this.getSharedSearchProject = getSharedSearchProject

  this.reset = reset
  this.getProjectContextFromGlobal = getProjectContextFromGlobal

  this.applyFilterToContext = applyFilterToContext
  this.setupTempGridState = setupTempGridState

  this.setupSharing = setupSharing
  this.setupSharingWhenInitialized = setupSharingWhenInitialized
  this.isSharingInitialized = isSharingInitialized
  this.isProjectSearchSharing = isProjectSearchSharing
  this.isCrossProjectSearchSharing = isCrossProjectSearchSharing
  this.isSearchSharing = isSearchSharing
  this.isAssetSharing = isAssetSharing
  this.isAssetsSharing = isAssetsSharing
  this.isTagSharing = isTagSharing
  this.isCommentSharing = isCommentSharing

  resetSearchData()

  function resetSearchData () {
    _sharedSearchData = {}
    _globalSearchInfo = {}
    _currentProjectSearchInfo = {}
    _currentProjectSearchInfo.searchFilter = {}
    _isSharingInitialized = false

    $rootScope.$broadcast('sv-search-context-update')
  }

  function resetCurrentAssetGroup () {
    if ($stateParams.searchFilter) {
      $stateParams.searchFilter.assetGroup = undefined
    }
  }
  function resetGlobalAssetGroup () {
    if ($stateParams.globalSearchFilter) {
      $stateParams.globalSearchFilter.assetGroup = undefined
    }
  }

  function getCurrentSearchContext () {
    if (_currentProjectSearchInfo.searchContext) {
      return _currentProjectSearchInfo.searchContext
    }
    return _globalSearchInfo.searchContext
  }

  function clearProjectSearchInfo () {
    _currentProjectSearchInfo = {}
  }

  function clearGlobalSearchInfo () {
    if (isSearchSharing()) {
      return
    }
    resetSearchData()
  }

  function setCurrentProjectSearchContext (searchContext) {
    if (!searchContext) {
      _currentProjectSearchInfo.searchContext = undefined
      _currentProjectSearchInfo.term = undefined
    } else {
      _currentProjectSearchInfo.searchContext = searchContext
      _currentProjectSearchInfo.term = getSearchContextTerm(searchContext)
    }

    $rootScope.$broadcast('sv-search-context-update')
  }

  function getGlobalSearchContext () {
    return _globalSearchInfo.searchContext
  }

  function isInGlobalSearchContext () {
    return angular.isDefined(_globalSearchInfo.searchContext)
  }

  function isInSearchContext () {
    return angular.isDefined(getCurrentSearchContext())
  }

  function setGlobalSearchContext (searchContext) {
    if (!searchContext) {
      _globalSearchInfo.searchContext = undefined
      _globalSearchInfo.term = undefined
    } else {
      _globalSearchInfo.searchContext = searchContext
      _globalSearchInfo.term = getSearchContextTerm(searchContext)
    }

    $rootScope.$broadcast('sv-search-context-update')
  }

  function setSharedSearch (linkId, sharedSearch) {
    _sharedSearchData.linkId = linkId
    _sharedSearchData.sharedSearch = sharedSearch
  }

  function clearSharedSearch () {
    _sharedSearchData = {}
  }

  function isProjectSearchSharing () {
    return contentSharingContext.getSharedContentType() === 'search'
  }

  function isCrossProjectSearchSharing () {
    return contentSharingContext.getSharedContentType() === 'xsearch'
  }

  function isSearchSharing () {
    return isProjectSearchSharing() || isCrossProjectSearchSharing()
  }

  function isAssetsSharing () {
    return contentSharingContext.getSharedContentType() === 'assets'
  }

  function isAssetSharing () {
    return contentSharingContext.getSharedContentType() === 'asset'
  }

  function isTagSharing () {
    return contentSharingContext.getSharedContentType() === 'tag'
  }

  function isCommentSharing () {
    return contentSharingContext.getSharedContentType() === 'comment'
  }

  function getSharedSearch () {
    return _sharedSearchData.sharedSearch
  }

  function getSharedSearchLinkId () {
    return _sharedSearchData.linkId
  }

  function getSharedSearchProject () {
    return _sharedSearchData.project
  }

  function setupSharingWhenInitialized (selectedAssetId) {
    let navController = $injector.get('NavController')
    let navStateService = $injector.get('navStateService')
    if (isAssetsSharing() && !selectedAssetId) {
      _sharedSearchData.originalLeftNavState = navStateService.getLeftNavState('dashboard.projects.projectId.files')
      navController.closeLeftNav()
    } else if (isAssetSharing()) {
      _sharedSearchData.originalLeftNavState = navStateService.getLeftNavState('dashboard.projects')
      navController.openLeftNav()
    }
  }

  function setupSharing (shareInfo, selectedAssetId) {
    _isSharingInitialized = true
    if (shareInfo.shareType === 'search' || shareInfo.shareType === 'xsearch') {
      setSharedSearch(shareInfo.shareId, shareInfo.sharedSearch)
      setupSharedSearchInternal(shareInfo.sharedSearch)
    }
    if (shareInfo.shareType === 'assets') {
      let navController = $injector.get('NavController')
      setupTempGridState(shareInfo.projectId, {
        groupByColumn: undefined
      })
      if (!selectedAssetId) {
        navController.closeLeftNav()
      }
    }
  }

  function isSharingInitialized () {
    return _isSharingInitialized
  }

  function setupSharedSearchInternal (sharedSearch) {
    let navStateService = $injector.get('navStateService')
    let navController = $injector.get('NavController')
    setupTempGridState(sharedSearch.projectId, sharedSearch)
    if (sharedSearch.projectId) {
      _sharedSearchData.originalLeftNavState = navStateService.getLeftNavState('dashboard.projects.projectId.files')
      navController.closeLeftNav()
    } else {
      _sharedSearchData.originalLeftNavState = navStateService.getLeftNavState('dashboard.projects')
      navController.openLeftNav()
    }
  }

  function setupTempGridState (projectId, newGridState) {
    assetGridHelper.setupTempGridState(projectId, newGridState, AssetViewType.SHARED_ASSETS)
  }

  function getSearchContextTerm (searchContext) {
    return _.union(
      _.map(searchContext.searchTags, (searchTag) => {
        return searchTag.text
      }).join(' '),
      _.map(searchContext.searchCreatedByUsers, (user) => {
        return user.text
      }).join(' ')
      )
  }

  function reset () {
    let navController = $injector.get('NavController')
    if (!(_sharedSearchData.originalLeftNavState === undefined)) {
      if (_sharedSearchData.originalLeftNavState) {
        navController.openLeftNav()
      } else {
        navController.closeLeftNav()
      }
    }
    resetSearchData()
    contentSharingContext.reset()
  }

  function getProjectContextFromGlobal (projectId, globalSearchContext, searchFilter) {
    let defer = $q.defer()
    let globalSearchTags = globalSearchContext.getSearchByTagDefs()
    if (!globalSearchTags || globalSearchTags.length === 0) {
      if (searchFilter) {
        let newContext = angular.copy(globalSearchContext)
        applyFilterToContext(newContext, searchFilter)
        defer.resolve(newContext)
      }
      defer.resolve(globalSearchContext)
    }
    let tagIds = _.map(globalSearchTags, (t) => {
      return t.tagDefId
    })
    searchApi.findSearchProjectTags(projectId, tagIds).then((tags) => {
      if (tags.length === 0 && tagIds.length !== 0) {
        defer.resolve(undefined)
      } else {
        let newAssetSearchContext = new AssetSearchContext()
        newAssetSearchContext.searchCreatedByUsers = globalSearchContext.searchCreatedByUsers
        newAssetSearchContext.textFragments = globalSearchContext.textFragments
        newAssetSearchContext.assetGroup = globalSearchContext.assetGroup
        newAssetSearchContext.tagConfidenceLevel = globalSearchContext.tagConfidenceLevel
        newAssetSearchContext.userTimeZone = globalSearchContext.userTimeZone
        newAssetSearchContext.searchTags = tags
        let i
        for (i = 0; i < globalSearchTags.length; i++) {
          let originalTag = globalSearchTags[i]
          let projectTag = newAssetSearchContext.searchTags[i]
          if (projectTag) {
            projectTag.hasChildren = originalTag.hasChildren
          }
        }
        newAssetSearchContext.searchQueryType = globalSearchContext.searchQueryType
        newAssetSearchContext.searchDateRange = globalSearchContext.searchDateRange
        if (searchFilter) {
          applyFilterToContext(newAssetSearchContext, searchFilter)
        }
        defer.resolve(newAssetSearchContext)
      }
    })
    return defer.promise
  }

  function applyFilterToContext (context, filter) {
    context.assetGroup = filter.assetGroup
    context.tagConfidenceLevel = filter.tagConfidenceLevel
  }
})
