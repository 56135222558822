/* global angular, _ */
angular.module('smartvid').directive('contentExpander', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'content-expander.html',
    transclude: true,
    link: function (scope, elem, attrs) {
      scope.headerText = attrs.headerText || ''
      scope.defaultOpen = !_.isUndefined(attrs.defaultOpen) ? !!attrs.defaultOpen : false
      scope.open = false
      let body = elem.find('.expander-body')
      if (!scope.defaultOpen) {
        body.slideUp(0)
      }
      scope.toggleBody = function () {
        body.slideToggle(300, function () {})
        scope.open = !scope.open
      }
    }
  }
})
