/* global angular */

angular.module('smartvid').directive('file2', () => {
  return {
    restrict: 'AE',
    scope: {
      files2: '@'
    },
    link (scope, el, attrs) {
      el.on('change', (event) => {
        var files = event.target.files
        scope.files2 = files
        scope.$parent.files2 = files
        scope.$apply()
      })
    }
  }
})
