import { Component, OnDestroy, Injector, OnInit } from '@angular/core'
import { AdminIntegrationSettingsControlBarActions } from './admin.integration.settings.control.bar.actions'

@Component({
  selector: 'sv-admin-organization-integrations-control-bar',
  templateUrl: 'admin-organization-integrations-control-bar.component.html',
  styleUrls: ['admin-organization-integrations-control-bar.component.scss'],
})
export class AdminOrganizationIntegrationsControlBarComponent implements OnInit, OnDestroy {
  NavController: any

  constructor(
    private adminIntegrationSettingsControlBarActions: AdminIntegrationSettingsControlBarActions,
    private injector: Injector
  ) {
    this.NavController = this.injector.get('NavController')
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  enableIntegrations() {
    this.adminIntegrationSettingsControlBarActions.enableIntegrations()
  }

  disableIntegrations() {
    this.adminIntegrationSettingsControlBarActions.disableIntegrations()
  }

  disconnectIntegrations() {
    this.adminIntegrationSettingsControlBarActions.disconnectIntegrations()
  }
}
