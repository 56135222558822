import * as moment from 'moment'
import { Component, Input, Output } from '@angular/core'
import {
  CardInputChangeEvent,
  CardToggleMenuGroup,
  CardToggleMenuItem,
} from 'modules/insights/dashboard/cards/models/insights-card.model'
import {
  DashboardCardId,
  DashboardDateRangeTimeUnit,
  DashboardTabId,
  DashboardType,
  DateRangeChartData,
  InsightsDashboardParameters,
  TableScopeFilter,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { TranslateService } from '@ngx-translate/core'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import { InsightsDashboardComponentBaseDirective } from 'modules/insights/dashboard/components/insights-dashboard-component-base'
import { InsightsDashboardOrganizationAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-organization-assets.service'
import {
  OrganizationAssetsSafetyTableData,
  OrganizationSingleValueDashboardData,
} from 'modules/insights/dashboard/models/insights-dashboard-organization-assets.model'
import { OrganizationAssetsSafetyTableComponent } from 'modules/insights/dashboard/components/totals-table/organization-assets/organization-assets-safety-table.component'
import { InsightsCardComponent } from 'modules/insights/dashboard/cards/components/insights-card/insights-card.component'
import { chartHasAllNullValues, convertNullValuesToZero } from '../../../../../utils/dashboard-utils'

@Component({
  selector: 'sv-organization-assets-insights-dashboard-safety',
  templateUrl: 'organization-assets-insights-dashboard-safety.component.html',
  styleUrls: ['organization-assets-insights-dashboard-safety.component.scss'],
})
export class OrganizationAssetsInsightsDashboardSafetyComponent extends InsightsDashboardComponentBaseDirective {
  private _organizationSingleValueData: OrganizationSingleValueDashboardData

  safetyTableData: OrganizationAssetsSafetyTableData[]
  safetyByGroupOrProjectTableMenu: CardToggleMenuGroup[]
  organizationPeopleInGroupsOverTimeData: DateRangeChartData
  peopleInGroupsOverTimeChartMenu: CardToggleMenuGroup[]
  organizationPpeComplianceOverTimeData: DateRangeChartData
  laddersToLiftsRatioPercentChangeClassName: string
  organizationWorkAtHeightsOverTimeData: DateRangeChartData
  organizationWorkAtHeightsPerXAssetsData: DateRangeChartData
  organizationLaddersPerXAssetsData: DateRangeChartData
  organizationLiftsPerXAssetsData: DateRangeChartData
  organizationHousekeepingAndStandingWaterData: DateRangeChartData
  organizationSlipAndTripTrendOverTimeData: DateRangeChartData
  peoplePerXConstructionPercentChangeClassName: string
  chartHasAllNullValues = chartHasAllNullValues
  convertNullValuesToZero = convertNullValuesToZero

  @Input() set organizationSingleValueData(value: OrganizationSingleValueDashboardData) {
    this._organizationSingleValueData = value
    if (this._organizationSingleValueData) {
      this.laddersToLiftsRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._organizationSingleValueData.laddersToLiftsRatioAvgPercentChange
      )
      this.peoplePerXConstructionPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._organizationSingleValueData.peoplePerXConstructionAvgPercentChange
      )
    }
  }
  get organizationSingleValueData() {
    return this._organizationSingleValueData
  }

  @Output() currentSafetyScope: TableScopeFilter = this.orgHasGroups
    ? TableScopeFilter.BY_PROJECT_GROUP
    : TableScopeFilter.BY_PROJECT

  constructor(
    protected insightsDataHelper: InsightsDataHelper,
    protected translate: TranslateService,
    protected insightsDashboardConfigurationService: InsightsDashboardConfigurationService,
    private insightsDashboardOrganizationAssetsService: InsightsDashboardOrganizationAssetsService
  ) {
    super(
      insightsDataHelper,
      insightsDashboardConfigurationService,
      translate,
      DashboardType.ORGANIZATION_ASSETS_DASHBOARD
    )
  }

  safetyByGroupOrProjectMenuSelected($event: CardToggleMenuItem, safetyByGroupOrProjectCard: InsightsCardComponent) {
    safetyByGroupOrProjectCard.clearCardInputControl()
    this.currentSafetyScope = $event.eventId
    this.loadSafetyTableData($event.eventId)
  }

  peopleInGroupsOverTimeChartMenuSelected($event: CardToggleMenuItem) {
    this.loadProjectPeopleInGroupsOverTimeBySourceData($event.eventId)
  }

  get cardTitle() {
    return this.currentSafetyScope === TableScopeFilter.BY_PROJECT_GROUP
      ? this.translate.instant('dashboard.cards.orgSafetyByProjectOrGroup.titleGroup')
      : this.translate.instant('dashboard.cards.orgSafetyByProjectOrGroup.titleProject')
  }

  get cardSubTitle() {
    return this.currentSafetyScope === TableScopeFilter.BY_PROJECT_GROUP
      ? this.translate.instant('dashboard.cards.orgSafetyByProjectOrGroup.subTitleGroup')
      : this.translate.instant('dashboard.cards.orgSafetyByProjectOrGroup.subTitleProject')
  }

  get filterInputPlaceholder() {
    return this.currentSafetyScope === TableScopeFilter.BY_PROJECT_GROUP
      ? this.translate.instant('dashboard.cards.orgSafetyByProjectOrGroup.searchInputPlaceholderGroup')
      : this.translate.instant('dashboard.cards.orgSafetyByProjectOrGroup.searchInputPlaceholderProject')
  }

  safetyTableFilterChanged(
    $event: CardInputChangeEvent,
    organizationAssetsSafetyTable: OrganizationAssetsSafetyTableComponent
  ) {
    if (!$event.value || !$event.value.trim()) {
      organizationAssetsSafetyTable.clearFilter()
      return
    }

    organizationAssetsSafetyTable.applyFilter({
      fieldName: 'name',
      filter: $event.value,
    })
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private get orgHasGroups() {
    return this.insightsDataHelper.getOrgHasGroups(DashboardType.ORGANIZATION_ASSETS_DASHBOARD)
  }

  // the ()=> function is needed to capture the correct 'this' reference
  protected refresh() {
    this.initializeMenuItems()

    this.guardFor(
      () =>
        this.loadSafetyTableData(this.orgHasGroups ? TableScopeFilter.BY_PROJECT_GROUP : TableScopeFilter.BY_PROJECT),
      DashboardTabId.SAFETY,
      DashboardCardId.ORGANIZATION_ASSETS_SAFETY_BY_GROUP_PROJECT
    )
    this.guardFor(
      () => this.loadProjectPeopleInGroupsOverTimeBySourceData(DashboardDateRangeTimeUnit.LAST_1),
      DashboardTabId.SAFETY,
      DashboardCardId.ORGANIZATION_ASSETS_PEOPLE_IN_GROUPS
    )
    this.guardFor(
      () => this.loadPpeComplianceTrendOverTimeData(),
      DashboardTabId.SAFETY,
      DashboardCardId.ORGANIZATION_ASSETS_PPE_COMPLIANCE_TREND_AVG
    )
    this.guardFor(
      () => this.loadWorkAtHeightsTrendOverTimeData(),
      DashboardTabId.SAFETY,
      DashboardCardId.ORGANIZATION_ASSETS_WORK_AT_HEIGHTS_TREND_AVG
    )
    this.guardFor(
      () => this.loadWorkAtHeightsPerXAssetsData(),
      DashboardTabId.SAFETY,
      DashboardCardId.ORGANIZATION_ASSETS_WORK_AT_HEIGHT_PER_X_ASSETS_AVG
    )
    this.guardFor(
      () => this.loadLaddersPerXAssetsData(),
      DashboardTabId.SAFETY,
      DashboardCardId.ORGANIZATION_ASSETS_LADDERS_PER_X_ASSETS_AVG
    )
    this.guardFor(
      () => this.loadLiftsPerXAssetsData(),
      DashboardTabId.SAFETY,
      DashboardCardId.ORGANIZATION_ASSETS_LIFTS_PER_X_ASSETS_AVG
    )
    this.guardFor(
      () => this.loadHousekeepingAndStandingWaterData(),
      DashboardTabId.SAFETY,
      DashboardCardId.ORGANIZATION_ASSETS_HOUSEKEEPING_AND_STANDING_WATER_AVG
    )
    this.guardFor(
      () => this.loadSlipAndTripTrendOverTimeData(),
      DashboardTabId.SAFETY,
      DashboardCardId.ORGANIZATION_ASSETS_SLIP_AND_TRIP_TREND_AVG
    )
  }

  private initializeMenuItems() {
    this.safetyByGroupOrProjectTableMenu = [
      {
        groupId: 'SAFETY_BY_PROJECT_OR_GROUP',
        groupItems: [
          {
            label: 'dashboard.cards.orgSafetyByProjectOrGroup.menu.group',
            eventId: TableScopeFilter.BY_PROJECT_GROUP,
            isChecked: this.orgHasGroups,
            isDisabled: !this.orgHasGroups,
          },
          {
            label: 'dashboard.cards.orgSafetyByProjectOrGroup.menu.project',
            eventId: TableScopeFilter.BY_PROJECT,
            isChecked: !this.orgHasGroups,
          },
        ],
      },
    ]
    this.peopleInGroupsOverTimeChartMenu = this.createChartDateSwitchMenuItems(
      'PEOPLE_IN_GROUP_MENU',
      'orgPeopleInGroupsOverTime'
    )
  }

  private loadSafetyTableData(filter: string) {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      filter: filter,
    }
    this.safetyTableData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadOrganizationSafetyTableData(dashboardParams, tableData => {
        this.safetyTableData = tableData
      })
    )
  }

  private loadProjectPeopleInGroupsOverTimeBySourceData(chartResolution: DashboardDateRangeTimeUnit) {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeTimeUnit: chartResolution,
    }
    this.organizationPeopleInGroupsOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadPeopleInGroupsOverTimeData(dashboardParams, chartData => {
        this.organizationPeopleInGroupsOverTimeData = chartData
      })
    )
  }

  private loadPpeComplianceTrendOverTimeData() {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeStart: moment(this.currentInsightsDashboardParameters.dateRangeEnd)
        .subtract(90, 'days')
        .toDate(),
      dateRangeTimeUnit: DashboardDateRangeTimeUnit.LAST_30,
    }
    this.organizationPpeComplianceOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadPpeComplianceTrendOverTimeData(dashboardParams, chartData => {
        this.organizationPpeComplianceOverTimeData = chartData
      })
    )
  }

  private loadWorkAtHeightsTrendOverTimeData() {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeStart: moment(this.currentInsightsDashboardParameters.dateRangeEnd)
        .subtract(90, 'days')
        .toDate(),
      dateRangeTimeUnit: DashboardDateRangeTimeUnit.LAST_30,
    }
    this.organizationWorkAtHeightsOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadWorkAtHeightsTrendOverTimeData(dashboardParams, chartData => {
        this.organizationWorkAtHeightsOverTimeData = chartData
      })
    )
  }

  private loadWorkAtHeightsPerXAssetsData() {
    this.organizationWorkAtHeightsPerXAssetsData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadWorkAtHeightsPerXAssetsData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.organizationWorkAtHeightsPerXAssetsData = chartData
        }
      )
    )
  }

  private loadLaddersPerXAssetsData() {
    this.organizationLaddersPerXAssetsData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadLaddersPerXAssetsData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.organizationLaddersPerXAssetsData = chartData
        }
      )
    )
  }

  private loadLiftsPerXAssetsData() {
    this.organizationLiftsPerXAssetsData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadLiftsPerXAssetsData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.organizationLiftsPerXAssetsData = chartData
        }
      )
    )
  }

  private loadHousekeepingAndStandingWaterData() {
    this.organizationHousekeepingAndStandingWaterData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadHousekeepingAndStandingWaterData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.organizationHousekeepingAndStandingWaterData = chartData
        }
      )
    )
  }

  private loadSlipAndTripTrendOverTimeData() {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeStart: moment(this.currentInsightsDashboardParameters.dateRangeEnd)
        .subtract(90, 'days')
        .toDate(),
      dateRangeTimeUnit: DashboardDateRangeTimeUnit.LAST_30,
    }
    this.organizationSlipAndTripTrendOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadSlipAndTripTrendOverTimeData(dashboardParams, chartData => {
        this.organizationSlipAndTripTrendOverTimeData = chartData
      })
    )
  }
}
