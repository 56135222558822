/* global angular, $ */

/*
  Directive to verify that a user entered password and confirm password match.
  I created it as part of the smartvid module for now but we need to consider creating
  a validation module for this type of logic.
  TODO: add validation module for form revamp
*/
angular.module('smartvid').directive('passwordMatch', function () {
  return {
    require: 'ngModel',
    link: function (scope, elem, attrs, ctrl) {
      let updateValidity = function () {                                  // Function to check and update validity
        scope.$apply(function () {
          var v = confirmPassword.val() === password.val()
          ctrl.$setValidity('pwmatch', v)

          // Exclude empty string match for the 'once matched' flag
          let trueMatch = v && password.val()
          scope.passwordsOnceMatched = trueMatch || scope.passwordsOnceMatched
        })
      }

      var password = $('input[name=' + attrs.passwordMatch + ']')       // Grab first password element
      var confirmPassword = elem

      confirmPassword.on('change', updateValidity)                      // Update validity if confirm password changes
      confirmPassword.on('keyup', updateValidity)

      password.on('change', updateValidity)                             // Update validity if first password changes
      password.on('keyup', updateValidity)
    }
  }
})
