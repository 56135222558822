import _ from 'lodash'
import { ProjectTotalsTableUserInfo } from './insights-dashboard-project-assets.model'
import { ChartData } from '../charts/models/base-chart.type'
import { DateRangeChartData, DashboardDataObjectResponseBase } from './insights-dashboard.model'
import { COLUMN_CHART_OPTIONS, PIE_CHART_OPTIONS } from '../charts/models/chart-static-options.type'
import { CardToggleMenuGroup } from '../cards/models/insights-card.model'
import { ObservationType } from '../../../observations/models/observation-type.enum'
import { ObservationRiskType } from '../../../observations/models/observation-risk-type.enum'

export enum OrganizationObservationActivityViewType {
  PROJECT_GROUP = 'PROJECT_GROUP',
  PROJECT = 'PROJECT',
  USER = 'USER',
}

export enum OrganizationObservationSafetyViewType {
  PROJECT_GROUP = 'PROJECT_GROUP',
  PROJECT = 'PROJECT',
}

export enum OrganizationObservationWorkflowViewType {
  PROJECT_GROUP = 'PROJECT_GROUP',
  PROJECT = 'PROJECT',
}

export class ObservationTotalCountsBase extends DashboardDataObjectResponseBase {
  riskObservationCount: number
  riskObservationCountPercentChange: number
  positiveObservationCount: number
  positiveObservationCountPercentChange: number
  observationCreatorCount: number
  observationCreatorCountPercentChange: number
  openObservationCount: number
  openObservationCountPercentChange: number
  avgRiskScore: number
  avgRiskScorePercentChange: number
  pastDueObservationCount: number
  pastDueObservationCountPercentChange: number
}

export class ProjectObservationTotalCounts extends ObservationTotalCountsBase {}

export class OrganizationObservationTotalCounts extends ObservationTotalCountsBase {
  positiveToRiskObservationRatio: number
  positiveToRiskObservationRatioPercentChange: number

  maxRiskScore: number
  maxRiskScoreType: ObservationRiskType

  avgDaysOpen: number
  avgDaysToClose: number
  avgDaysLateness: number
}

export class ProjectObservationTotalsTableData {
  userInfo: ProjectTotalsTableUserInfo
  allObservationCount: number
  allObservationCountRatio: number
  riskObservationCount: number
  riskObservationCountRatio: number
  positiveObservationCount: number
  positiveObservationCountRatio: number

  chartData: ChartData
}

export class OrganizationUserInfo {
  id: string
  firstName: string
  lastName: string
  email: string
}

export class OrganizationObservationTotalsTableData {
  id: string
  name: string
  subtitle: string
  avatar: string
  userInfo: OrganizationUserInfo
  allObservationCount: number
  allObservationCountRatio: number
  riskObservationCount: number
  riskObservationCountRatio: number
  positiveObservationCount: number
  positiveObservationCountRatio: number

  chartData: ChartData
}

export class OrganizationObservationSafetyTotalsTableData {
  id: string
  name: string
  subtitle: string
  avgRiskScore: number
  avgRiskScorePercentChange: number
  extremeRiskCount: number
  extremeRiskCountRatio: number
  highRiskCount: number
  highRiskCountRatio: number
  mediumRiskCount: number
  mediumRiskCountRatio: number
  lowRiskCount: number
  lowRiskCountRatio: number
}

export class OrganizationObservationWorkflowTotalsTableData {
  id: string
  name: string
  subtitle: string
  avgRiskScore: number
  avgRiskScorePercentChange: number
  openCount: number
  openCountRatio: number
  closedCount: number
  closedCountRatio: number
  avgDaysOpenCount: number
  avgDaysOpenCountRatio: number
  avgDaysToCloseCount: number
  avgDaysToCloseCountRatio: number
}

export class DashboardProjectObservationActivity extends DashboardDataObjectResponseBase {
  activityByUserData: ProjectObservationTotalsTableData[]
}

export class DashboardOrganizationObservationActivity extends DashboardDataObjectResponseBase {
  activityData: OrganizationObservationTotalsTableData[]
}

export class DashboardOrganizationObservationSafety extends DashboardDataObjectResponseBase {
  safetyData: OrganizationObservationSafetyTotalsTableData[]
}

export class DashboardOrganizationObservationWorkflow extends DashboardDataObjectResponseBase {
  workflowData: OrganizationObservationWorkflowTotalsTableData[]
}

export class DashboardProjectObservationActivityTotals extends DashboardDataObjectResponseBase {
  positiveToRiskObservationRatio: number
  positiveToRiskObservationRatioPercentChange: number
}

export class DashboardProjectObservationSafetyTotals extends DashboardDataObjectResponseBase {
  maxRiskScore: number
  maxRiskScoreType: ObservationRiskType
}

export class DashboardProjectObservationWorkflowTotals extends DashboardDataObjectResponseBase {
  avgDaysOpen: number
  avgDaysToClose: number
  avgDaysLateness: number
}

export const OBSERVATIONS_OVERTIME_BY_TYPE_CHART_OPTIONS = _.merge({}, COLUMN_CHART_OPTIONS, {
  chartArea: {
    height: '73%',
  },
  isStacked: true,
  hAxis: {
    format: 'MMM d, y',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    // textPosition: 'none',
  },
  legend: {
    position: 'bottom',
  },
  // the order here must match the order of data rows returned from the server (e.g. negative and then positive)
  colors: ['#E29A27', '#2EA96F'],
})

export const OBSERVATIONS_OVERTIME_BY_TYPE_CHART_COLUMNS: any[] = [
  'dashboard.observations.observationsOverTimeChart.columns.date',
]

export const OBSERVATIONS_BY_TYPE_CHART_COLUMNS: any[] = [
  'dashboard.observations.observationsByTypeChart.columns.type',
  'dashboard.observations.observationsByTypeChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const OBSERVATIONS_BY_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const OBSERVATIONS_GREAT_CATCH_BY_TYPE_CHART_COLUMNS: any[] = [
  'dashboard.observations.observationsGreatCatchByTypeChart.columns.type',
  'dashboard.observations.observationsGreatCatchByTypeChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const OBSERVATIONS_GREAT_CATCH_BY_TYPE_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const OBSERVATIONS_BY_IDENTIFICATION_METHOD_CHART_COLUMNS: any[] = [
  'dashboard.observations.observationsByIdentificationMethodChart.columns.type',
  'dashboard.observations.observationsByIdentificationMethodChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const OBSERVATIONS_BY_IDENTIFICATION_METHOD_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const OBSERVATIONS_BY_CREATOR_CHART_COLUMNS: any[] = [
  'dashboard.observations.observationsByIdentificationMethodChart.columns.type',
  'dashboard.observations.observationsByIdentificationMethodChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const OBSERVATIONS_BY_CREATOR_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const OBSERVATIONS_BY_HAZARD_CATEGORY_CHART_COLUMNS: any[] = [
  'dashboard.observations.observationsByHazardCategoryChart.columns.type',
  'dashboard.observations.observationsByHazardCategoryChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const OBSERVATIONS_BY_HAZARD_CATEGORY_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const OBSERVATIONS_BY_TYPE_MENU: CardToggleMenuGroup[] = [
  {
    groupId: 'observationTypeMenu',
    groupItems: [
      {
        label: `dashboard.cards.observationsByTypeMenu.menu.all`,
        eventId: undefined,
        isChecked: true,
      },
      {
        label: `dashboard.cards.observationsByTypeMenu.menu.risk`,
        eventId: ObservationType.NEGATIVE,
      },
      {
        label: `dashboard.cards.observationsByTypeMenu.menu.positive`,
        eventId: ObservationType.POSITIVE,
      },
    ],
  },
]

export const ORGANIZATION_OBSERVATIONS_ACTIVITY_MENU: CardToggleMenuGroup[] = [
  {
    groupId: 'organizationObservationActivityMenu',
    groupItems: [
      {
        label: `dashboard.cards.organizationObservationActivityMenu.menu.group`,
        eventId: OrganizationObservationActivityViewType.PROJECT_GROUP,
        isChecked: true,
      },
      {
        label: `dashboard.cards.organizationObservationActivityMenu.menu.project`,
        eventId: OrganizationObservationActivityViewType.PROJECT,
      },
      {
        label: `dashboard.cards.organizationObservationActivityMenu.menu.user`,
        eventId: OrganizationObservationActivityViewType.USER,
      },
    ],
  },
]

export const PROJECT_GROUP_OBSERVATIONS_ACTIVITY_MENU: CardToggleMenuGroup[] = [
  {
    groupId: 'projectGroupObservationActivityMenu',
    groupItems: [
      {
        label: `dashboard.cards.organizationObservationActivityMenu.menu.project`,
        eventId: OrganizationObservationActivityViewType.PROJECT,
        isChecked: true,
      },
      {
        label: `dashboard.cards.organizationObservationActivityMenu.menu.user`,
        eventId: OrganizationObservationActivityViewType.USER,
      },
    ],
  },
]

export const ORGANIZATION_OBSERVATIONS_SAFETY_MENU: CardToggleMenuGroup[] = [
  {
    groupId: 'organizationObservationActivityMenu',
    groupItems: [
      {
        label: `dashboard.cards.organizationObservationSafetyMenu.menu.group`,
        eventId: OrganizationObservationSafetyViewType.PROJECT_GROUP,
        isChecked: true,
      },
      {
        label: `dashboard.cards.organizationObservationSafetyMenu.menu.project`,
        eventId: OrganizationObservationSafetyViewType.PROJECT,
      },
    ],
  },
]

export const ORGANIZATION_OBSERVATIONS_WORKFLOW_MENU: CardToggleMenuGroup[] = [
  {
    groupId: 'organizationObservationWorkflowMenu',
    groupItems: [
      {
        label: `dashboard.cards.organizationObservationWorkflowMenu.menu.group`,
        eventId: OrganizationObservationWorkflowViewType.PROJECT_GROUP,
        isChecked: true,
      },
      {
        label: `dashboard.cards.organizationObservationWorkflowMenu.menu.project`,
        eventId: OrganizationObservationWorkflowViewType.PROJECT,
      },
    ],
  },
]

export const OBSERVATIONS_BY_RISK_BAND_CHART_COLUMNS: any[] = [
  'dashboard.observations.observationsByRiskBandChart.columns.type',
  'dashboard.observations.observationsByRiskBandChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const OBSERVATIONS_BY_RISK_BAND_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
  // the order here must match the order of data rows returned from the server (e.g. negative and then positive)
  slices: {
    0: { color: '#FFB400' },
    1: { color: '#E29A27' },
    2: { color: '#E27027' },
    3: { color: '#E24027' },
  },
})

export const OBSERVATIONS_OVERTIME_BY_RISK_BAND_CHART_OPTIONS = _.merge({}, COLUMN_CHART_OPTIONS, {
  chartArea: {
    height: '73%',
  },
  isStacked: true,
  hAxis: {
    format: 'MMM d, y',
    gridlines: {
      color: '#323232',
    },
  },
  vAxis: {
    // textPosition: 'none',
  },
  legend: {
    position: 'bottom',
  },
  // the order here must match the order of data rows returned from the server (e.g. negative and then positive)
  colors: ['#FFB400', '#E29A27', '#E27027', '#E24027'],
})

export const OBSERVATIONS_BY_RISK_SCORE_CHART_COLUMNS: any[] = [
  'dashboard.observations.observationsScoreScoreChart.columns.type',
  'dashboard.observations.observationsScoreScoreChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const OBSERVATIONS_BY_RISK_SCORE_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const OBSERVATION_RISK_SCORE_OVER_TIME_CHART_COLUMNS: any[] = [
  'dashboard.projectObservations.observationsByRiskScoreChart.columns.type',
  'dashboard.projectObservations.observationsByRiskScoreChart.columns.maximum',
  // { type: 'string', role: 'tooltip' },
  'dashboard.projectObservations.observationsByRiskScoreChart.columns.average',
  // { type: 'string', role: 'tooltip' },
]

export const OBSERVATIONS_RISK_SCORE_OVER_TIME_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  chartArea: {
    height: '73%',
  },
  isStacked: false,
  hAxis: {
    format: 'MMM d, y',
    gridlines: {
      color: '#323232',
    },
  },
  sliceVisibilityThreshold: null,
  legend: {
    position: 'bottom',
  },
})

export const OBSERVATIONS_OVERTIME_BY_STATUS_CHART_COLUMNS: any[] = [
  'dashboard.observations.observationsOverTimeByStatusChart.columns.date',
]

export const OBSERVATIONS_COUNT_BY_OPEN_VS_CLOSED_CHART_COLUMNS: any[] = [
  'dashboard.observations.observationCountByOpenVsClosedChart.columns.type',
  'dashboard.observations.observationCountByOpenVsClosedChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const OBSERVATIONS_COUNT_BY_OPEN_VS_CLOSED_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const OBSERVATIONS_OPEN_BY_STATUS_CHART_COLUMNS: any[] = [
  'dashboard.observations.observationOpenByStatusChart.columns.type',
  'dashboard.observations.observationOpenByStatusChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const OBSERVATIONS_OPEN_BY_STATUS_CHART_OPTIONS = _.merge({}, PIE_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'right',
  },
})

export const OBSERVATIONS_AVG_DAYS_OPEN_BY_RISK_BAND_CHART_COLUMNS: any[] = [
  'dashboard.observations.observationAvgDaysOpenByRiskBandChart.columns.type',
  'dashboard.observations.observationAvgDaysOpenByRiskBandChart.columns.count',
  { type: 'string', role: 'tooltip' },
]

export const OBSERVATIONS_AVG_DAYS_OPEN_BY_RISK_BAND_CHART_OPTIONS = _.merge({}, COLUMN_CHART_OPTIONS, {
  sliceVisibilityThreshold: null,
  legend: {
    position: 'none',
  },
})

export function applyRiskBandColors(data: DateRangeChartData) {
  data.chartData.dataTable[0].push({ role: 'style' })
  data.chartData.dataTable[1].push('color: #FFB400')
  data.chartData.dataTable[2].push('color: #E29A27')
  data.chartData.dataTable[3].push('color: #E27027')
  data.chartData.dataTable[4].push('color: #E24027')
}
