<div class="mdc-layout-grid__inner">
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.avgPhotoCreatedByDayOfWeek.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-column-chart
        [chartData]="projectAvgPhotosCreatedByDayOfWeekData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'AVG_PHOTOS_CREATED_PER_DAY' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.avgPhotoCreatedPerDay.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
      <div class="text-card-content" *ngIf="projectTotals">
        <div class="text-card-main-text">
          {{
            isNA(projectTotals.avgPhotosCreatedPerDay)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.avgPhotosCreatedPerDay | number: '.1-1')
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ avgPhotosCreatedPerDayPercentChangeClassName }}">
          {{ getSign(projectTotals.avgPhotosCreatedPerDayPercentChange)
          }}{{
            isNA(projectTotals.avgPhotosCreatedPerDay)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.avgPhotosCreatedPerDayPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'AVG_TIME_BETWEEN_PHOTO_CREATION_AND_UPLOAD' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.avgTimeDiffPhotoCreatedUploaded.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
      <div class="text-card-content" *ngIf="projectTotals">
        <div class="text-card-main-text">
          {{
            isNA(projectTotals.avgPhotoDiffCreatedUploaded)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.avgPhotoDiffCreatedUploaded | number: '.1-1')
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ avgPhotoDiffCreatedUploadedPercentChangeClassName }}">
          {{ getSign(projectTotals.avgPhotoDiffCreatedUploadedPercentChange)
          }}{{
            isNA(projectTotals.avgPhotoDiffCreatedUploaded)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.avgPhotoDiffCreatedUploadedPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'CONSTRUCTION_TO_ALL_RATIO' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.constructionToAllPhotosRatio.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
      <div class="text-card-content" *ngIf="projectTotals">
        <div class="text-card-main-text">
          {{
            isNA(projectTotals.constructionPhotosToAllPhotosRatio)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.constructionPhotosToAllPhotosRatio | number: '.1-1') + ' to 1'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ constructionPhotosToAllPhotosRatioPercentChangeClassName }}">
          {{ getSign(projectTotals.constructionPhotosToAllPhotosRatioPercentChange)
          }}{{
            isNA(projectTotals.constructionPhotosToAllPhotosRatio)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.constructionPhotosToAllPhotosRatioPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'PERSON_COVERAGE_PERCENT' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.personCoveragePercent.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
      <div class="text-card-content" *ngIf="projectTotals">
        <div class="text-card-main-text">
          {{
            isNA(projectTotals.percentPersonCoverage)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.percentPersonCoverage | number: '.1-1') + '%'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ percentPersonCoveragePercentChangeClassName }}">
          {{ getSign(projectTotals.percentPersonCoveragePercentChange)
          }}{{
            isNA(projectTotals.percentPersonCoverage)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.percentPersonCoveragePercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'PHOTOS_WITHOUT_CREATED_DATE' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.photosWithoutCreatedDate.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
      <div class="text-card-content" *ngIf="projectTotals">
        <div class="text-card-main-text">
          {{
            isNA(projectTotals.percentOfAssetsWithoutCreatedDate)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.percentOfAssetsWithoutCreatedDate | number: '.1-1') + '%'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ percentOfAssetsWithoutCreatedDatePercentChangeClassName }}">
          {{ getSign(projectTotals.percentOfAssetsWithoutCreatedDatePercentChange)
          }}{{
            isNA(projectTotals.percentOfAssetsWithoutCreatedDate)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.percentOfAssetsWithoutCreatedDatePercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'IMAGE_HEALTH', cardId: 'CONSTRUCTION_PHOTOS_WITHOUT_CREATED_DATE' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.constructionPhotosWithoutCreatedDate.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectTotals"></mat-spinner>
      <div class="text-card-content" *ngIf="projectTotals">
        <div class="text-card-main-text">
          {{
            isNA(projectTotals.percentOfConstructionAssetsWithoutCreatedDate)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.percentOfConstructionAssetsWithoutCreatedDate | number: '.1-1') + '%'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ percentOfConstructionAssetsWithoutCreatedDatePercentChangeClassName }}">
          {{ getSign(projectTotals.percentOfConstructionAssetsWithoutCreatedDatePercentChange)
          }}{{
            isNA(projectTotals.percentOfConstructionAssetsWithoutCreatedDate)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectTotals.percentOfConstructionAssetsWithoutCreatedDatePercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
</div>
