/* global angular, _ */

angular.module('smartvid').controller('UsersGridOptionsCtrl', function ($scope, $stateParams, $rootScope, modal, smartvidApi, dashboardDataHelper) {
  $scope.deleteSelectedUsers = () => {
    let selected = $scope.users.where({selected: true})
    $scope.flyout.isOpen = false
    modal.open('deleteConfirm', {
      confirm () {
        let userIds = _.map(selected, user => {
          return user.id
        })
        smartvidApi.removeFromOrganization($stateParams.organizationId, userIds).then(() => {
          _.each(selected, (user) => {
            dashboardDataHelper.getCurrentAdminOrganizationUsers().removeById(user.id)
          })
        })
        $rootScope.$broadcast('sv-users-deleted', userIds)
        $scope.flyout.close()
      },
      hasSelections: selected.length > 0
    })
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top,
      left: $scope.styles.left
    }
  }
})
