import {
  AfterViewInit,
  Component,
  Directive,
  ElementRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { UpgradeComponent } from '@angular/upgrade/static'
import { StateDeclaration, StateObject, StateOrName, StateService, TransitionService } from '@uirouter/core'
import { CURRENT_USER, CurrentUser, PROJECT_FAVORITE_SERVICE, ProjectFavoriteService } from 'shared/smartvid.types'
import scrollIntoView from 'scroll-into-view-if-needed'
import { MatDialog } from '@angular/material/dialog'
import { ProjectTreeComponent, ProjectTreeFeatureFilter } from '../project-tree/project-tree.component'
import { Store } from '@ngxs/store'
import _ from 'lodash'

@Directive({
  selector: 'svUserMenuItem',
})
export class UserMenuItemDirective extends UpgradeComponent {
  @Input() itemName: any
  @Input() itemState: any

  constructor(elementRef: ElementRef, injector: Injector) {
    super('userMenuItem', elementRef, injector)
  }
}

@Directive({
  selector: 'svSmartvidSpinner',
})
export class SmartvidSpinnerDirective extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('smartvidSpinner', elementRef, injector)
  }
}

@Directive({
  selector: 'svOrganizationMenuItem',
})
export class OrganizationMenuItemDirective extends UpgradeComponent {
  @Input() organization: any

  constructor(elementRef: ElementRef, injector: Injector) {
    super('organizationMenuItem', elementRef, injector)
  }
}

/*
 Pipe that performs sorting of organizations
 */
@Pipe({
  name: 'OrganizationListPipe',
  pure: false,
})
export class OrganizationListPipe implements PipeTransform {
  transform(items: any[]): any {
    if (!items) {
      return items
    }
    return items.sort((lhs, rhs) => {
      if (!lhs.isPrimary && rhs.isPrimary) {
        return 1
      }
      if (lhs.isPrimary && !rhs.isPrimary) {
        return -1
      }
      return lhs.name.localeCompare(rhs.name)
    })
  }
}

/*
 Pipe that performs sorting of projects
 */
@Pipe({
  name: 'ProjectListPipe',
  pure: false,
})
export class ProjectListPipe implements PipeTransform {
  transform(items: any[]): any {
    if (!items) {
      return items
    }
    return items.sort((lhs: any, rhs: any) => {
      if (!lhs.favorite && rhs.favorite) {
        return 1
      }
      if (lhs.favorite && !rhs.favorite) {
        return -1
      }
      return lhs.name.localeCompare(rhs.name)
    })
  }
}

@Component({
  selector: 'sv-navigation-panel',
  templateUrl: 'navigation-panel.component.html',
  styleUrls: ['navigation-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationPanelComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    public stateService: StateService,
    public transitionService: TransitionService,
    private injector: Injector,
    private store: Store,
    @Inject('contentSharingContext') public contentSharingContext: any,
    @Inject(CURRENT_USER) private currentUser: CurrentUser,
    private dialog: MatDialog,
    @Inject('dashboardDataHelper') public dashboardDataHelper: any,
    @Inject(PROJECT_FAVORITE_SERVICE)
    private projectFavoriteService: ProjectFavoriteService
  ) {}

  NavController = this.injector.get('NavController')
  panelsCount: number
  OBSERVATION_FEATURE: ProjectTreeFeatureFilter = ProjectTreeFeatureFilter.OBSERVATION
  ASSET_FEATURE: ProjectTreeFeatureFilter = ProjectTreeFeatureFilter.ASSET

  isProjects = false
  isObservations = false
  isAnalytics = false
  isPtp = false
  isAdminOrganizations = false
  displayProjects = true

  projects: any
  organizations: any
  powerBiOrganizations: unknown[]

  hasObservations: boolean
  hasPtp: boolean
  hasAdmin: boolean
  hasAnalytics: boolean

  transitionHooks: Function[] = []

  @ViewChild(ProjectTreeComponent, { static: false }) projectTree: ProjectTreeComponent
  @ViewChild('obsProjectTree', { static: false }) obsProjectTree: ProjectTreeComponent

  clearSearchValue() {
    if (this.projectTree) {
      this.projectTree.searchValue = ''
    }
    if (this.obsProjectTree) {
      this.obsProjectTree.searchValue = ''
    }
  }
  go(to: StateOrName) {
    this.clearSearchValue()
    this.stateService.go(to)
  }

  openProjects(resetState: boolean) {
    if (resetState) {
      this.go('dashboard.projects')
      if (this.projectTree) {
        this.projectTree.treeControl.collapseAll()
        this.projectTree.selectedNode = null
      }
    }
    this.clearSearchValue()
  }

  selectAnalyticsOrg = organization => {
    this.stateService.go('dashboard.analytics.orgId', {
      organizationId: organization.id,
    })
  }

  openObservations() {
    this.go('dashboard.observations')
    this.clearSearchValue()
  }

  openAnalytics() {
    this.go('dashboard.analytics')
  }

  openAdminOrganizations() {
    if (!NavigationPanelComponent.isAdminState(this.stateService.current)) {
      this.go('dashboard.adminOrganizations')
      this.clearSearchValue()
    }
  }

  openPtp() {
    this.go('dashboard.ptp')
    this.clearSearchValue()
  }

  scrollToMenuItem(selector: string) {
    setTimeout(() => {
      const element: any = document.querySelector(selector)
      if (element) {
        scrollIntoView(element, { scrollMode: 'if-needed' })
      }
    }, 50)
  }

  scrollToMenuItemWithoutTimeout(selector: string) {
    const element: any = document.querySelector(selector)
    if (element) {
      scrollIntoView(element, { scrollMode: 'if-needed' })
    }
  }

  ngOnInit() {
    this.updateCurrentState()

    this.transitionHooks.push(
      this.transitionService.onSuccess({ to: NavigationPanelComponent.isAdminState }, () => {
        this.setProjects(undefined)
        this.updateCurrentState()
      })
    )
    this.transitionHooks.push(
      this.transitionService.onSuccess({ to: 'dashboard.analytics.**' }, () => {
        this.setProjects(undefined)
        this.updateCurrentState()
      })
    )
    this.transitionHooks.push(
      this.transitionService.onSuccess({ to: 'dashboard.projects' }, () => {
        this.updateCurrentState()
      })
    )
    this.transitionHooks.push(
      this.transitionService.onSuccess({ to: 'dashboard.projects.**' }, () => {
        this.scrollToActiveProject()
      })
    )
    this.transitionHooks.push(
      this.transitionService.onSuccess({ to: 'dashboard.observations' }, () => {
        this.updateCurrentState()
        if (this.projectTree) {
          this.projectTree.resetSelectedNode()
        }
      })
    )
    this.transitionHooks.push(
      this.transitionService.onSuccess({ to: 'dashboard.ptp' }, () => {
        this.setProjects(undefined)
        this.updateCurrentState()
      })
    )
  }

  ngOnDestroy() {
    this.transitionHooks.forEach(hook => hook())
  }

  ngAfterViewInit(): void {
    if (this.isProjects) {
      this.displayProjects = false
      this.scrollToActiveProject()
    }

    if (this.isAdminOrganizations) {
      this.scrollToActiveOrganization()
    }
  }

  setProjects(projects) {
    this.projects = projects
  }

  displayProjectSpinner() {
    return !this.projects || this.projects.isFetching
  }

  projectsLoaded() {
    return this.projects && !this.projects.isFetching
  }

  getItemsSize() {
    return this.projectsLoaded() ? this.projects.models.length : 0
  }

  onFavorite() {
    setTimeout(() => {
      this.setProjects(this.dashboardDataHelper.getAllProjects())
    }, 300)
  }

  updateCurrentState() {
    this.panelsCount = 1

    this.isProjects = this.stateService.includes('dashboard.projects.**')
    this.isObservations = this.stateService.includes('dashboard.observations.**')
    this.isAnalytics = this.stateService.includes('dashboard.analytics.**')
    this.isPtp = this.stateService.includes('dashboard.ptp.**')
    this.isAdminOrganizations = NavigationPanelComponent.isAdminState(this.stateService.current)

    this.setProjects(this.dashboardDataHelper.getAllProjects())
    this.organizations = this.dashboardDataHelper.getAllOrganizations()
    this.powerBiOrganizations = _.filter(this.organizations.models, org => {
      return this.currentUser.isAnalyticsFeatureEnabledForOrganization((org as any).id)
    })

    this.hasObservations =
      this.currentUser.isObservationFeatureEnabledForAnyOrganization() && !this.contentSharingContext.isSet()
    this.hasAnalytics =
      this.currentUser.isAnalyticsFeatureEnabledForAnyOrganization() && !this.contentSharingContext.isSet()
    this.hasPtp = this.currentUser.isPtpFeatureEnabledForAnyOrganization() && !this.contentSharingContext.isSet()
    this.hasAdmin = !this.contentSharingContext.isSet()

    if (this.hasObservations) {
      this.panelsCount++
    }
    if (this.hasAnalytics) {
      this.panelsCount++
    }
    if (this.hasAdmin) {
      this.panelsCount++
    }
    if (this.hasPtp) {
      this.panelsCount++
    }
  }

  isActiveProject(project) {
    return project.id === this.stateService.params['projectId']
  }

  private static isAdminState(state: StateDeclaration | StateObject) {
    return (
      state.name.includes('dashboard.adminOrganizations') ||
      state.name === 'dashboard.myprofile' ||
      state.name === 'dashboard.mysettings'
    )
  }

  private static isObservationState(state: StateDeclaration | StateObject) {
    return state.name.includes('dashboard.observations')
  }

  private scrollToActiveProject() {
    this.dashboardDataHelper.getAllProjects().projectPromise.then(() => {
      this.updateCurrentState()

      setTimeout(() => {
        const selectedNode = this.projectTree.selectedNode
        //This is inside of a timeout because to scroll we need to wait for the view component to render
        if (selectedNode) {
          this.scrollToMenuItemWithoutTimeout("[id*='" + selectedNode.id + "']")
        }
        setTimeout(() => {
          //This timeout is here because we don't actually know when scrolling is done.
          this.displayProjects = true
        }, 0)
      }, 10)
    })
  }

  private scrollToActiveOrganization() {
    let org = this.dashboardDataHelper.getCurrentAdminOrganization()
    if (org) {
      this.scrollToMenuItem('#organization-' + org.id)
    }
  }
}
