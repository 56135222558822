/* global angular, analytics */
angular.module('smartvid').service('tagVerificationService', function (smartvidApi, $rootScope) {
  function verifyTag (tag, asset) {
    analytics.track('Verify Tag Instance', {
      category: 'Tag Action',
      tagName: tag.text
    })
    tag.status = 'VERIFIED'
    return smartvidApi.updateTagForAsset(tag).then((response) => {
      tag.status = 'VERIFIED'
      asset.unverifiedTagsCount--
    }, (response) => {
      tag.status = 'PENDING_VERIFICATION'
    })
  }

  function rejectTag (tag, tagCollection, asset) {
    analytics.track('Reject Tag Instance', {
      category: 'Tag Action',
      tagName: tag.text
    })
    tag.status = 'REJECTED'
    return smartvidApi.updateTagForAsset(tag).then((response) => {
      tag.status = 'REJECTED'
      tagCollection.removeById(tag.id)
      asset.unverifiedTagsCount--
      asset.tagsCount--
      $rootScope.$broadcast('sv-clear-all-markup')
    }, (response) => {
      tag.status = 'PENDING_VERIFICATION'
    })
  }

  function unverifyTag (tag, asset) {
    analytics.track('Unverify Tag Instance', {
      category: 'Tag Action',
      tagName: tag.text
    })
    tag.status = 'PENDING_VERIFICATION'
    return smartvidApi.updateTagForAsset(tag).then((response) => {
      tag.status = 'PENDING_VERIFICATION'
      asset.unverifiedTagsCount++
    }, (response) => {
      tag.status = 'VERIFIED'
    })
  }

  return {
    verifyTag: verifyTag,
    unverifyTag: unverifyTag,
    rejectTag: rejectTag
  }
})
