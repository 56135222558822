import { NgModule } from '@angular/core'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { BootstrapComponent } from './components/bootstrap/bootstrap.component'
import { HttpConfigInterceptor } from './interceptors/httpconfig.interceptor'
import {
  assetProducerServiceProvider,
  assetsCollectionProducerServiceProvider,
  configServiceProvider,
  currentUserServiceProvider,
  dashboardDataHelperServiceProvider,
  fileUploadServiceProvider,
  httpServiceProvider,
  navControllerProvider,
  smartvidApiServiceProvider,
  utilsProvider,
  projectFavoriteServiceProvider,
  searchServiceProvider,
} from './services/ajs-upgraded-providers'

@NgModule({
  declarations: [BootstrapComponent],
  exports: [BootstrapComponent],
  entryComponents: [BootstrapComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    assetProducerServiceProvider,
    assetsCollectionProducerServiceProvider,
    configServiceProvider,
    currentUserServiceProvider,
    dashboardDataHelperServiceProvider,
    fileUploadServiceProvider,
    httpServiceProvider,
    navControllerProvider,
    smartvidApiServiceProvider,
    utilsProvider,
    projectFavoriteServiceProvider,
    searchServiceProvider,
  ],
})
export class AppCoreModule {}
