/* global angular, _ */

angular.module('smartvid').directive('selectAllPlacards', function (hotkeys, $rootScope) {
  return {
    restrict: 'A',
    replace: true,
    link: function (scope, element, attrs) {
      let collection = scope.$eval(attrs['selectAllPlacards'])
      collection.allSelected = false
      const $stateParams = $rootScope.$stateParams
      let isSharing = $stateParams && $stateParams.sharingType && $stateParams.sharingType === 'xsearch'
      let selectAll = (e) => {
        // Don't select all if the collection is already selected or is empty
        collection.allSelected = !!(!collection.allSelected && !collection.isEmpty)
        _.each(collection.models, (model) => {
          if (('canUpdate' in model) && model.canUpdate === false && !isSharing) {
            return
          }
          model.selected = collection.allSelected
        })

        $rootScope.lastSelectedPlacard = undefined

        $rootScope.$broadcast('sv-placards-selected', {})

        e.preventDefault()
        return false
      }

      hotkeys.add({
        combo: 'command+a',
        callback: selectAll
      })

      hotkeys.add({
        combo: 'ctrl+a',
        callback: selectAll
      })
    }
  }
})
