/* global angular */

angular.module('smartvid').directive('organizationMenuItem', function (currentUser, $rootScope,
                                                                       $state, $stateParams, flyout) {
  return {
    restrict: 'E',
    templateUrl: 'MenuItems/organization-menu-item.html',
    link: function (scope) {
      scope.canViewActive = ($stateParams.canViewOrgSelected === scope.organization.id)
      updateScope()

      scope.$watch('organization', updateScope)

      scope.$on('sv-view-only-org-selected', (event, payload) => {
        scope.canViewActive = (payload.organizationId === scope.organization.id && !scope.canManage)
      })

      scope.selectOrg = () => {
        if ($state.current.name !== 'dashboard.adminOrganizations' && !scope.canManage) {
          $state.go('dashboard.adminOrganizations', {canViewOrgSelected: scope.organization.id})
          scope.canViewActive = true
        } else if (!scope.canViewActive) {
          $rootScope.$broadcast('sv-view-only-org-selected', {organizationId: scope.organization.id})
        }
      }

      scope.showOrganizationOptions = ($event) => {
        $event.stopPropagation()
        if (scope.canManage) {
          flyout.open('organizationOptions', {
            parentElement: $event.currentTarget,
            direction: 'left',
            adminOrganizations: scope.adminOrganizations,
            organization: scope.organization,
            flyoutId: 'menu-item-' + scope.organization.id
          })
        }
      }

      scope.isFlyoutOpenFor = (flyoutId) => {
        return flyout.isOpenFor('menu-item-' + flyoutId)
      }

      function updateScope () {
        scope.canManage = scope.organization.canManage
        scope.canManageUsers = scope.organization.canManageUsers
        scope.canManageProfile = scope.organization.canManageProfile
        scope.isAnalyticsEnabled = currentUser.isAnalyticsFeatureEnabledForOrganization(scope.organization.id)
      }

      function canUpdateGroupsFunc (groups) {
        if (groups && groups.length) {
          for (let group of groups) {
            if (group.canUpdate) {
              return true
            }
          }
        }
        return false
      }
    }
  }
})
