/* global angular, _, goog */

angular.module('smartvid').factory('DefaultGroupedGridSupplier', function (AddItemPlaceholder, GroupMetadata) {
  class Cache {
    constructor () {
      this.cache = []
    }
    bust () {
      this.cache = []
    }
    get (virtualIdx) {
      return this.cache[virtualIdx]
    }
    put (virtualIdx, item) {
      this.cache[virtualIdx] = item
    }
  }
  const ADD_ITEM_PLACEHOLDER = new AddItemPlaceholder() // signal 'add' placard location (Upload File, Add Member...)
  const EMPTY_ITEM = {}
  class DefaultGroupedGridSupplier {
    constructor (collection, parser, includeAddItemPlaceholder = true) {
      this.collection = collection
      this.parser = parser
      this.itemsPerRow = 0
      this.totalRowCount = 0
      this.groups = this.parser.parse(this.collection.models) || []
      this.itemCache = new Cache()
      this.rangeArray = []
      this.offsetArray = []
      this.offsetInversionMap = null
      this.includeAddItemPlaceholder = includeAddItemPlaceholder
      this.refreshInProg = false
    }
    getItem (rowIdx, rowItemIdx) {
      var virtualIdx = (rowIdx * this.itemsPerRow) + rowItemIdx
      if (this.itemCache.get(virtualIdx) !== undefined) {                   // item cached
        return this.itemCache.get(virtualIdx)
      } else if (this.collection.models.length > 0) {                       // item not cached
        return this.handleUncachedRequest(virtualIdx, rowItemIdx)
      } else {                                                              // empty collection
        return this.handleEmptyCollectionRequest(rowIdx, rowItemIdx)
      }
    }
    getTotalRowCount () {
      return Math.max(this.totalRowCount, this.includeAddItemPlaceholder ? 1 : 0)
    }
    setItemsPerRow (newItemsPerRow) {
      if (this.itemsPerRow === newItemsPerRow) { return }
      this.refreshInProg = true
      this.itemsPerRow = newItemsPerRow
      this.refreshFromRangePhase(true)
      this.refreshInProg = false
    }
    setAddItemPlaceholder (includeAddItemPlaceholder, refresh = true) {
      this.refreshInProg = true
      this.includeAddItemPlaceholder = !!includeAddItemPlaceholder
      if (refresh) {
        this.refreshFromRangePhase(true)
      }
      this.refreshInProg = false
    }
    roundUp (n, multiple) {
      return (n !== 0) ? multiple * Math.ceil(n / parseFloat(multiple)) : 0
    }
    buildRangeAndOffsetLists (groups, itemsPerRow) {
      var rangeAndOffset = {
        range: [],
        offset: [],
        totalRowCount: 0
      }
      var virtualIdx = 0
      var offset = 0
      var prevGroupSize = 0
      var addItemPlacardAffordance = this.includeAddItemPlaceholder ? 1 : 0
      _.each(groups, (group) => {
        rangeAndOffset.offset.push(group)  // because its a group header we will map to group
        rangeAndOffset.range.push(virtualIdx)

        // account for complete last group row
        virtualIdx += this.roundUp(prevGroupSize, itemsPerRow) - prevGroupSize  // add unfilled spaces to next group header range
        offset += this.roundUp(prevGroupSize, itemsPerRow) - prevGroupSize      // update offset for unfilled spaces
        // account for group header
        virtualIdx += itemsPerRow          // account for group header
        offset += itemsPerRow              // update offset anytime we account for a group header

        // account for group items
        rangeAndOffset.offset.push(offset)
        rangeAndOffset.range.push(virtualIdx)
        virtualIdx += group.parsedSize

        prevGroupSize = group.parsedSize
      })

      rangeAndOffset.totalRowCount = Math.ceil((virtualIdx + addItemPlacardAffordance) / parseFloat(itemsPerRow))
      return rangeAndOffset
    }
    buildInversionMap (rangeArray, offsetArray) {
      return new goog.structs.InversionMap(rangeArray, offsetArray, false)
    }
    handleUncachedRequest (virtualIdx, rowItemIdx) {
      var inversionResult = this.offsetInversionMap.at(virtualIdx)
      var result = EMPTY_ITEM
      if (_.isNumber(inversionResult)) {
        let realIdx = virtualIdx - inversionResult
        if (realIdx === this.collection.models.length && this.includeAddItemPlaceholder) { // we need an add item placeholder
          result = ADD_ITEM_PLACEHOLDER
        } else {                                                          // item not cached so get
          result = this.collection.models[virtualIdx - inversionResult]
        }
      } else if (inversionResult instanceof GroupMetadata) {              // in group header
        result = (rowItemIdx === 0) ? inversionResult : result            // only return GroupMetatdata for first item in group header
      }
      this.itemCache.put(virtualIdx, result)                              // cache computed item
      return result
    }
    handleEmptyCollectionRequest (rowIdx, rowItemIdx) {
      if (rowIdx === 0) {
        return (rowItemIdx === 0 && this.includeAddItemPlaceholder) ? ADD_ITEM_PLACEHOLDER : EMPTY_ITEM
      }
      return undefined
    }
    refreshFromParsePhase (includeAddItemPlaceholder) {
      this.refreshInProg = true
      if (_.isBoolean(includeAddItemPlaceholder)) {
        this.setAddItemPlaceholder(includeAddItemPlaceholder, false)
      }
      this.groups = this.parser.parse(this.collection.models)
      this.refreshFromRangePhase(true)
      this.refreshInProg = false
    }
    refreshFromRangePhase (continueRefresh = false) {
      this.refreshInProg = true
      var rangeAndOffset = this.buildRangeAndOffsetLists(this.groups, this.itemsPerRow)
      this.rangeArray = rangeAndOffset.range
      this.offsetArray = rangeAndOffset.offset
      this.totalRowCount = rangeAndOffset.totalRowCount
      this.refreshFromBuildPhase(true)
      if (!continueRefresh) {
        this.refreshInProg = false
      }
    }
    refreshFromBuildPhase (continueRefresh = false) {
      this.refreshInProg = true
      this.itemCache.bust()
      this.offsetInversionMap = this.buildInversionMap(this.rangeArray, this.offsetArray)
      if (!continueRefresh) {
        this.refreshInProg = false
      }
    }
    extendGroupMetadata (sourceGroups, filterInDestFn = _.identity) {
      let destinationGroups = _.filter(this.groups, filterInDestFn)
      if (_.isEmpty(destinationGroups) || _.isEmpty(sourceGroups)) { return }

      let minLength = Math.min(sourceGroups.length, destinationGroups.length)
      for (let i = 0; i < minLength; ++i) {
        destinationGroups[i].update(sourceGroups[i], (val) => !_.isUndefined(val))
      }
    }
  }

  return DefaultGroupedGridSupplier
})
