/* global angular, _ */

/**
* Service to handle all logic regarding passwords
*/
angular.module('smartvid').service('passwordService', function (passwordStrength) {
  /** ---------------------
  * Valid
  * -----------------------
  * Function to determine if a given password
  * is valid according to the current smartvid
  * standards for passwords
  * @param password -> the password in question
  */
  this.valid = function (password) {
    /* ***** Extra password tests that are currently unused
    var hasUpperCase = /[A-Z]/.test(password)
    var hasLowerCase = /[a-z]/.test(password)
    var hasNumbers = /\d/.test(password)
    var hasNonalphas = /\W/.test(password)
    var characterGroupCount = hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas
    */

    if (_.isString(password) && password.length >= 8) {
      return true
    } else {
      return false
    }
  }

  /** ------------------
  * Get Strength
  * --------------------
  * Function to get the strength of
  * a given password in the range of
  * 0 to 100 where 100 is the strongest
  * @param password  -> the password in question
  */
  this.getStrength = function (password) {
    return passwordStrength.getStrength(password)
  }
})
