import * as _ from 'lodash'
import * as moment from 'moment'
import { Inject, Injectable } from '@angular/core'
import { HTTP } from 'shared/smartvid.types'
import { toObservable } from 'shared/utils/api-utils'
import {
  CancellableRequestSubscription,
  DashboardDataObjectType,
  DashboardDataResponse,
  DateRangeChartData,
  InsightsDashboardParameters,
  ScopeObjectType,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { first } from 'rxjs/operators'
import { InsightsDashboardServiceHelper } from 'modules/insights/dashboard/services/insights-dashboard-service-helper'
import {
  DashboardOrganizationActivityTableDataResponse,
  ORG_UPLOADERS_OVERTIME_CHART_OPTIONS,
  ORG_UPLOADS_OVERTIME_BY_SOURCE_CHART_COLUMNS,
  ORG_UPLOADS_OVERTIME_CHART_OPTIONS,
  OrganizationAssetsActivityTableData,
  OrganizationSingleValueDashboardData,
  PROJECT_OR_GROUP_ACTIVITY_CHART_DATA_COLUMNS,
  PROJECT_OR_GROUP_ACTIVITY_CHART_OPTIONS,
  ORG_UPLOADERS_OVERTIME_CHART_COLUMNS,
  ORG_CONSTRUCTION_ACTIVITY_CHART_OPTIONS,
  ORG_CONSTRUCTION_ACTIVITY_CHART_COLUMNS,
  ORG_PHOTO_DESCRIPTION_CHART_OPTIONS,
  ORG_PHOTO_DESCRIPTION_CHART_COLUMNS,
  ORG_UPLOADS_BY_FILE_TYPE_CHART_OPTIONS,
  ORG_UPLOADS_BY_FILE_TYPE_CHART_COLUMNS,
  ORG_TAGS_BY_TYPE_CHART_OPTIONS,
  ORG_TAGS_BY_TYPE_CHART_COLUMNS,
  OrganizationAssetsSafetyTableData,
  DashboardOrganizationSafetyTableDataResponse,
  ORG_PEOPLE_IN_GROUPS_OVERTIME_CHART_OPTIONS,
  ORG_PEOPLE_IN_GROUPS_OVERTIME_CHART_COLUMNS,
  ORG_PPE_COMPLIANCE_OVERTIME_CHART_COLUMNS,
  ORG_PPE_COMPLIANCE_OVERTIME_CHART_OPTIONS,
  ORG_WORK_AT_HEIGHTS_OVERTIME_CHART_COLUMNS,
  ORG_WORK_AT_HEIGHTS_OVERTIME_CHART_OPTIONS,
  ORG_WORK_AT_HEIGHTS_PER_X_ASSETS_CHART_OPTIONS,
  ORG_WORK_AT_HEIGHTS_PER_X_ASSETS_CHART_COLUMNS,
  ORG_LADDERS_PER_X_ASSETS_CHART_COLUMNS,
  ORG_LADDERS_PER_X_ASSETS_CHART_OPTIONS,
  ORG_LIFTS_PER_X_ASSETS_CHART_COLUMNS,
  ORG_LIFTS_PER_X_ASSETS_CHART_OPTIONS,
  ORG_HOUSEKEEPING_AND_STANDING_WATER_CHART_COLUMNS,
  ORG_HOUSEKEEPING_AND_STANDING_WATER_CHART_OPTIONS,
  ORG_SLIP_AND_TRIP_OVERTIME_CHART_OPTIONS,
  ORG_SLIP_AND_TRIP_OVERTIME_CHART_COLUMNS,
  ORG_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK_CHART_OPTIONS,
  ORG_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK_CHART_COLUMNS,
} from 'modules/insights/dashboard/models/insights-dashboard-organization-assets.model'
import { makeMultilineChartTooltip } from 'modules/insights/dashboard/utils/dashboard-utils'
import { TranslateService } from '@ngx-translate/core'
import * as Q from 'q'

@Injectable({
  providedIn: 'root',
})
export class InsightsDashboardOrganizationAssetsService {
  constructor(
    @Inject(HTTP) private $http: ng.IHttpService,
    private insightsDashboardServiceHelper: InsightsDashboardServiceHelper,
    private translate: TranslateService
  ) {}

  loadOrganizationSingleValueDashboardData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: OrganizationSingleValueDashboardData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSingleValueDashboardData<OrganizationSingleValueDashboardData>(
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_TOTALS,
      ScopeObjectType.ORGANIZATION,
      dashboardParameters,
      onDataLoadedFunc
    )
  }

  loadOrganizationActivityTableData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: OrganizationAssetsActivityTableData[]) => void
  ) {
    let url = this.insightsDashboardServiceHelper.getApiUrl(dashboardParameters.organizationId)
    let payload = InsightsDashboardServiceHelper.createDashboardRequest(
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_ACTIVITY_BY_PROJECT_OR_GROUP,
      dashboardParameters,
      ScopeObjectType.ORGANIZATION
    )

    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardDataResponse<DashboardOrganizationActivityTableDataResponse>>(
      this.$http.post(url, payload, { timeout: cancellableRequest.promise }),
      DashboardDataResponse
    )
      .pipe(first())
      .subscribe((data: DashboardDataResponse<DashboardOrganizationActivityTableDataResponse>) => {
        let tableData =
          data.dashboardData[DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_ACTIVITY_BY_PROJECT_OR_GROUP]
            .tableDate
        tableData.forEach(entry => {
          entry.chartData.dataTable.forEach(row => {
            row[0] = moment(new Date(row[0]))
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0)
              .set('millisecond', 0)
              .toDate()
            row.push(
              makeMultilineChartTooltip([
                moment(row[0]).format('MMM D, YYYY'),
                this.translate.instant('dashboard.organizationAssets.groupOrProjectActivityTable.chartTooltip', {
                  count: row[1],
                }),
              ])
            )
          })
          entry.chartData.dataTable = [
            [...this.insightsDashboardServiceHelper.translateStringsIn(PROJECT_OR_GROUP_ACTIVITY_CHART_DATA_COLUMNS)],
            ...entry.chartData.dataTable,
          ]
          entry.chartData.options = { ..._.cloneDeep(PROJECT_OR_GROUP_ACTIVITY_CHART_OPTIONS) }
        })
        onDataLoadedFunc(tableData)
      })

    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  loadUploadsOverTimeBySourceData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleDateBasedChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_UPLOADS_BY_SOURCE,
      ORG_UPLOADS_OVERTIME_BY_SOURCE_CHART_COLUMNS,
      true,
      ORG_UPLOADS_OVERTIME_CHART_OPTIONS,
      onDataLoadedFunc,
      ScopeObjectType.ORGANIZATION
    )
  }

  loadUploadersOverTimeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleDateBasedChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_UPLOADERS_OVER_TIME,
      ORG_UPLOADERS_OVERTIME_CHART_COLUMNS,
      false,
      ORG_UPLOADERS_OVERTIME_CHART_OPTIONS,
      onDataLoadedFunc,
      ScopeObjectType.ORGANIZATION
    )
  }

  loadConstructionActivityData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_CONSTRUCTION_ACTIVITY,
      ORG_CONSTRUCTION_ACTIVITY_CHART_COLUMNS,
      ORG_CONSTRUCTION_ACTIVITY_CHART_OPTIONS,
      onDataLoadedFunc,
      ScopeObjectType.ORGANIZATION
    )
  }

  loadPhotoDescriptionData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_PHOTO_DESCRIPTION,
      ORG_PHOTO_DESCRIPTION_CHART_COLUMNS,
      ORG_PHOTO_DESCRIPTION_CHART_OPTIONS,
      onDataLoadedFunc,
      ScopeObjectType.ORGANIZATION
    )
  }

  loadUploadsByFileTypeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_UPLOADS_BY_FILE_TYPE,
      ORG_UPLOADS_BY_FILE_TYPE_CHART_COLUMNS,
      ORG_UPLOADS_BY_FILE_TYPE_CHART_OPTIONS,
      onDataLoadedFunc,
      ScopeObjectType.ORGANIZATION
    )
  }

  loadTagsByTypeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_TAGS_BY_TYPE,
      ORG_TAGS_BY_TYPE_CHART_COLUMNS,
      ORG_TAGS_BY_TYPE_CHART_OPTIONS,
      onDataLoadedFunc,
      ScopeObjectType.ORGANIZATION
    )
  }

  loadOrganizationSafetyTableData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: OrganizationAssetsSafetyTableData[]) => void
  ) {
    let url = this.insightsDashboardServiceHelper.getApiUrl(dashboardParameters.organizationId)
    let payload = InsightsDashboardServiceHelper.createDashboardRequest(
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_SAFETY_BY_PROJECT_OR_GROUP,
      dashboardParameters,
      ScopeObjectType.ORGANIZATION
    )

    const cancellableRequest = Q.defer()
    const cancellableSubscription = toObservable<DashboardDataResponse<DashboardOrganizationSafetyTableDataResponse>>(
      this.$http.post(url, payload, { timeout: cancellableRequest.promise }),
      DashboardDataResponse
    )
      .pipe(first())
      .subscribe((data: DashboardDataResponse<DashboardOrganizationSafetyTableDataResponse>) => {
        onDataLoadedFunc(
          data.dashboardData[DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_SAFETY_BY_PROJECT_OR_GROUP]
            .tableDate
        )
      })

    return new CancellableRequestSubscription(cancellableRequest, cancellableSubscription)
  }

  loadPeopleInGroupsOverTimeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleDateBasedChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_PEOPLE_IN_GROUPS,
      ORG_PEOPLE_IN_GROUPS_OVERTIME_CHART_COLUMNS,
      false,
      ORG_PEOPLE_IN_GROUPS_OVERTIME_CHART_OPTIONS,
      onDataLoadedFunc,
      ScopeObjectType.ORGANIZATION
    )
  }

  loadPpeComplianceTrendOverTimeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadStandardPpeComplianceTrendOverTimeData(
      dashboardParameters,
      onDataLoadedFunc,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_PPE_COMPLIANCE_OVER_TIME,
      ScopeObjectType.ORGANIZATION,
      ORG_PPE_COMPLIANCE_OVERTIME_CHART_COLUMNS,
      ORG_PPE_COMPLIANCE_OVERTIME_CHART_OPTIONS,
      'dashboard.organizationAssets.ppeComplianceOverTimeChart.columns.tooltip.compliance',
      'dashboard.organizationAssets.ppeComplianceOverTimeChart.columns.tooltip.complianceCutoff'
    )
  }

  loadWorkAtHeightsTrendOverTimeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    // TODO: PL: We always return 3 data points here and they can be not equidistant in time
    //  so for now just hardcode the X-axis labels
    let funcWrapper = (data: DateRangeChartData) => {
      this.insightsDashboardServiceHelper.adjustDataForNPointsChartWithEqualXAxisTicksSpacing(
        data,
        dashboardParameters,
        30
      )
      onDataLoadedFunc(data)
    }

    return this.insightsDashboardServiceHelper.loadSimpleDateBasedChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_OVER_TIME,
      ORG_WORK_AT_HEIGHTS_OVERTIME_CHART_COLUMNS,
      true,
      ORG_WORK_AT_HEIGHTS_OVERTIME_CHART_OPTIONS,
      funcWrapper,
      ScopeObjectType.ORGANIZATION
    )
  }

  loadWorkAtHeightsPerXAssetsData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_WORK_AT_HEIGHTS_PER_X_ASSETS,
      ORG_WORK_AT_HEIGHTS_PER_X_ASSETS_CHART_COLUMNS,
      ORG_WORK_AT_HEIGHTS_PER_X_ASSETS_CHART_OPTIONS,
      onDataLoadedFunc,
      ScopeObjectType.ORGANIZATION
    )
  }

  loadLaddersPerXAssetsData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_LADDERS_PER_X_ASSETS,
      ORG_LADDERS_PER_X_ASSETS_CHART_COLUMNS,
      ORG_LADDERS_PER_X_ASSETS_CHART_OPTIONS,
      onDataLoadedFunc,
      ScopeObjectType.ORGANIZATION
    )
  }

  loadLiftsPerXAssetsData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_LIFTS_PER_X_ASSETS,
      ORG_LIFTS_PER_X_ASSETS_CHART_COLUMNS,
      ORG_LIFTS_PER_X_ASSETS_CHART_OPTIONS,
      onDataLoadedFunc,
      ScopeObjectType.ORGANIZATION
    )
  }

  loadHousekeepingAndStandingWaterData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_HOUSEKEEPING_AND_STANDING_WATER,
      ORG_HOUSEKEEPING_AND_STANDING_WATER_CHART_COLUMNS,
      ORG_HOUSEKEEPING_AND_STANDING_WATER_CHART_OPTIONS,
      onDataLoadedFunc,
      ScopeObjectType.ORGANIZATION
    )
  }

  loadSlipAndTripTrendOverTimeData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    // TODO: PL: We always return 3 data points here and they can be not equidistant in time
    //  so for now just hardcode the X-axis labels
    let funcWrapper = (data: DateRangeChartData) => {
      this.insightsDashboardServiceHelper.adjustDataForNPointsChartWithEqualXAxisTicksSpacing(
        data,
        dashboardParameters,
        30
      )
      onDataLoadedFunc(data)
    }

    return this.insightsDashboardServiceHelper.loadSimpleDateBasedChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_SLIP_AND_TRIP_OVER_TIME,
      ORG_SLIP_AND_TRIP_OVERTIME_CHART_COLUMNS,
      true,
      ORG_SLIP_AND_TRIP_OVERTIME_CHART_OPTIONS,
      funcWrapper,
      ScopeObjectType.ORGANIZATION
    )
  }

  loadAvgPhotosCreatedByDayOfWeekData(
    dashboardParameters: InsightsDashboardParameters,
    onDataLoadedFunc: (data: DateRangeChartData) => void
  ) {
    return this.insightsDashboardServiceHelper.loadSimpleCategoryCountChartData(
      dashboardParameters,
      DashboardDataObjectType.ORGANIZATION_ASSETS_DATE_RANGE_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK,
      ORG_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK_CHART_COLUMNS,
      ORG_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK_CHART_OPTIONS,
      onDataLoadedFunc,
      ScopeObjectType.ORGANIZATION
    )
  }
}
