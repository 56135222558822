<li class="menu-item"
    id="li-menu-item-{{currentProject?.id}}"

    [class.active]="isMenuItemActive()"
    [ngStyle]="{height: getMenuItemHeightAsync()|async}"
    (mouseleave)="hovered = false"
    (mouseover)="hovered = true">

    <div class="project-header-container" *ngIf="currentProject && currentProject.canListAllAssets">

        <a class="ptor-project-list name"
           [ngClass]="{'active': isMenuItemActive(),'without-group':isWithoutGroup()}"
           (click)="projectMenuClicked($event)">
            <div class="name-count-wrapper" matTooltip="{{ getTooltipForProjectMenu() }}" matTooltipPosition='right'>
                <div>
                    <mat-icon class="mat-icon-rtl-mirror material-icons md-light"
                              [ngClass]="{'closed-button': !isMenuItemActive(), 'md-current': isMenuItemActive()}">
                        arrow_drop_down
                    </mat-icon>
                    <mat-icon *ngIf="isCurrentProjectInactive()"
                              class="material-icons svg"
                              [ngClass]="{'md-current': isMenuItemActive()}" svgIcon="work_off">
                    </mat-icon>
                    <mat-icon *ngIf="!isCurrentProjectInactive()"
                              class="material-icons md-light"
                            [ngClass]="{'md-current': isMenuItemActive()}">folder
                    </mat-icon>
                </div>
                <div class="project-name"  [ngStyle]="getProjectNameWidthStyle()">
                    {{currentProject?.name}}
                </div>
                <div class="project-file-count" *ngIf="isCrossProjectAssetView() && isMenuItemActive()">
                    {{ ' (' + currentProject?.assetCount + ')' }}
                </div>
                <div class="project-icons"
                     (mouseleave)="optionsHovered = false"
                     (mouseover)="optionsHovered = true">
                    <div class="options" *ngIf="shouldDisplayProjectOptions()">
                        <mat-icon  class="material-icons md-light"
                                  [ngClass]="{'md-inactive': options3dotsHovered, 'active': optionTrigger.menuOpen || optionsHovered}"
                                   #optionTrigger="matMenuTrigger"
                                  [matMenuTriggerFor]="projectOptions"
                                  (click)="$event.stopPropagation();"
                                   (mouseleave)="options3dotsHovered = false"
                                   (mouseover)="options3dotsHovered = true"
                                  >more_vert
                        </mat-icon>
                    </div>
                    <mat-icon style="float: right;" class="material-icons md-light"
                              [ngStyle]="getStarStyle()"
                              [ngClass]="{'md-inactive': starHovered, 'margined-20': !shouldDisplayProjectOptions()}"
                              *ngIf="currentProject?.favorite || hovered"
                              (mouseleave)="starHovered = false"
                              (mouseover)="starHovered = true"
                              (click)="doFavorite($event)"
                    >{{getStarIcon()}}
                    </mat-icon>
                </div>

            </div>

            <mat-menu #projectOptions="matMenu" >
                <button mat-menu-item *ngIf="showPropertiesOption" (click)="showProjectProperties();">
                    {{  'flyouts.project.information' |  translate}}
                </button>
                <button mat-menu-item *ngIf="currentProject?.canDelete"  (click)="openConfirmDeleteDialog();">
                    {{'common.delete'  | translate}}
                </button>
            </mat-menu>

        </a>
    </div>

    <a class="ptor-project-list name"
       id="thumnail-lock-{{currentProject?.id}}"
       [ngClass]="{'active': isMenuItemActive()}"
       [ngStyle]="{'padding-left':'30px'}"
       *ngIf="currentProject && !currentProject.canListAllAssets">
        {{currentProject?.name }}
        <mat-icon *ngIf="isCurrentProjectInactive()" class="material-icons svg" [ngClass]="{'md-current': isMenuItemActive()}" svgIcon="work_off"> </mat-icon>
        <mat-icon *ngIf="!isCurrentProjectInactive()" class="material-icons md-light" [ngClass]="{'md-current': isMenuItemActive()}"> folder</mat-icon>
        <mat-icon  class="material-icons md-light"
                   [ngClass]="{'md-current': isMenuItemActive()}"
                   matTooltip="{{'common.cannotAccessPermissions' | translate}}"
                   matTooltipPosition='right'>
              lock_outline
        </mat-icon>

    </a>

    <ol class="menu-item-options" #mio
        *ngIf="canListAllAssets() && isMenuItemActive()">

        <li class="option" id="insights-option-{{currentProject?.id}}"
            *ngIf="currentProject.hasInsights && !isCrossProjectAssetView()">
            <a (click)="goTo('dashboard.projects.projectId.insights',{ projectId: currentProject.id, isDemoProject: currentProject?.isDemoProjectCopy } )"
               [ngClass]="{ active : isState('dashboard.projects.projectId.insights')}">
                {{ "common.insights" | translate }}
            </a>
        </li>

        <li class="option" id="files-option-{{currentProject?.id}}">
            <a (click)="goTo('dashboard.projects.projectId.files',{ projectId: currentProject.id, isDemoProject: currentProject?.isDemoProjectCopy } )"
               [ngClass]="{ active : isState('dashboard.projects.projectId.files')}">
                {{ "common.files" | translate }}
            </a>
        </li>

        <li class="option ptor-tags-manager" id="tags-option-{{currentProject?.id}}" *ngIf="!currentProject.isInactive && currentProject?.canAddTagDefs && !isCrossProjectAssetView() ">
            <a (click)="goTo('dashboard.projects.projectId.tagsmanager', { projectId: currentProject?.id })"
               [ngClass]="{ active : isState('dashboard.projects.projectId.tagsmanager')}">
                {{ "common.tags" | translate }}
            </a>
        </li>


        <li class="option ptor-project-profile" id="profile-option-{{currentProject?.id}}" *ngIf="currentProject?.canUpdate && !isCrossProjectAssetView()">
            <a (click)="goTo('dashboard.projects.projectId.profile',{ projectId: currentProject?.id });"
               [ngClass]="{ active : isState('dashboard.projects.projectId.profile')}">
                {{ "project.profile" | translate }}
            </a>
        </li>

        <li class="option ptor-members" id="members-option-{{currentProject?.id}}" *ngIf="currentProject?.canInviteUsers && !isCrossProjectAssetView()">
            <a (click)="goTo('dashboard.projects.projectId.members',{ projectId: currentProject?.id })"
               [ngClass]="{ active : isState('dashboard.projects.projectId.members') ||
                                    isState('dashboard.projects.projectId.members.memberProfile')}">
                {{ "project.members" | translate }}
            </a>
        </li>

        <li class="option ptor-integrations" id="integrations-option-{{currentProject?.id}}" *ngIf="currentProject?.canUpdate && !isCrossProjectAssetView() && !currentProject.isInactive && !currentUser.isOrganizationInactive(currentProject?.organizationId)">
            <a (click)="goTo('dashboard.projects.projectId.integrations',{ projectId: currentProject.id })"
              [ngClass]="{ active : isIntegrationState()}">
                {{ "project.integrations" | translate }}
            </a>
        </li>

        <li class="option" id="deleted-files-option-{{currentProject?.id}}" *ngIf="currentProject?.canUpdate && !isCrossProjectAssetView()">
            <a (click)="goTo('dashboard.projects.projectId.deletedfiles', { projectId: currentProject?.id, isDemoProject: currentProject?.isDemoProjectCopy })"
               [ngClass]="{ active : isState('dashboard.projects.projectId.deletedfiles')}">
                Deleted
            </a>
        </li>

        <p class="chevron" [ngStyle]="getChevronStyle()">
        </p>
    </ol>
</li>
