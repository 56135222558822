import { ConfigService } from 'modules/core/services/config.service'
import { HTTP } from 'shared/smartvid.types'
import { Injectable, Inject } from '@angular/core'
import { TradePartner } from '../models/tradepartner.model'
import { Observable } from 'rxjs'
import { toObservableArray } from 'shared/utils/api-utils'

@Injectable({
  providedIn: 'root',
})
export class TradePartnerService {
  rootUrl: string

  constructor(@Inject(HTTP) private $http: ng.IHttpService, private config: ConfigService) {
    this.rootUrl = this.config.env.development.apiRootUrl
  }

  getActiveTradePartnersForProject(projectId: string): Observable<TradePartner[]> {
    let url = this.rootUrl + `/api/project/${projectId}/tradepartner`
    return toObservableArray<TradePartner>(this.$http.get(url), TradePartner)
  }

  getActiveTradePartnersForOrganization(organizationId: string): Observable<TradePartner[]> {
    let url = this.rootUrl + `/api/organization/${organizationId}/tradepartner?isActiveOnly=true`
    return toObservableArray<TradePartner>(this.$http.get(url), TradePartner)
  }

  getAllTradePartnersForOrganization(organizationId: string): Observable<TradePartner[]> {
    let url = this.rootUrl + `/api/organization/${organizationId}/tradepartner?isActiveOnly=false`
    return toObservableArray<TradePartner>(this.$http.get(url), TradePartner)
  }
}
