/* global angular _  */

angular
  .module('smartvid')
  .directive('projectIntegrationsOxblue', function (
    utils,
    smartvidApi,
    Notification,
    currentUser,
    moment,
    $rootScope,
    $timeout,
    $window,
    $interval,
    $filter,
    $interpolate,
    $stateParams,
    $state,
    errorUtils,
    IntegrationSettingsModel,
    dashboardDataHelper,
    projectIntegrationUtils,
    IntegrationSettingsState,
    OxBlueCameraInfoModel,
    OxBlueCameraInfoCollection,
    OxBlueUserModel
  ) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'integrations/project-integrations-oxblue.html',
      link: function (scope) {
        scope.project = dashboardDataHelper.getCurrentProject()
        if (!scope.project.canUpdate) {
          $state.go('unauthorized')
          return
        }

        scope.cameraSelectionListSettings = {
          bootstrap2: false,
          filterClear: $filter('i18next')('integrations.oxblue.cameraFilterShowAll'),
          filterPlaceHolder: $filter('i18next')('integrations.oxblue.cameraFilter'),
          moveSelectedLabel: $filter('i18next')('integrations.oxblue.cameraMoveSelectedOnly'),
          moveAllLabel: $filter('i18next')('integrations.oxblue.cameraMoveAll'),
          removeSelectedLabel: $filter('i18next')('integrations.oxblue.cameraRemoveSelectedOnly'),
          removeAllLabel: $filter('i18next')('integrations.oxblue.cameraRemoveAll'),
          moveOnSelect: false,
          preserveSelection: 'moved',
          selectedListLabel: $filter('i18next')('integrations.oxblue.cameraSelected'),
          nonSelectedListLabel: $filter('i18next')('integrations.oxblue.cameraAvailable'),
          postfix: '_helperz',
          selectMinHeight: 130,
          filter: true,
          filterNonSelected: '',
          filterSelected: '',
          filterValues: true
        }

        scope.timeOfDateSyncOptions = _.map(_.range(25), n => {
          return { value: n, name: n + ':00' }
        })

        let intervalSelectionOptions = _.map(_.range(14), n => {
          if (n < 2) {
            let minutes = (n + 1) * 15
            return { value: minutes, name: minutes + ' ' + $filter('i18next')('integrations.oxblue.minutes') }
          } else {
            let hours = n - 1
            return {
              value: hours * 60,
              name:
                hours +
                (hours === 1
                  ? ' ' + $filter('i18next')('integrations.oxblue.hour')
                  : ' ' + $filter('i18next')('integrations.oxblue.hours'))
            }
          }
        })
        scope.syncIntervalOptions = intervalSelectionOptions

        let availableOxBlueHistoricalDataSyncDepthOptions = [
          {
            name: $filter('i18next')('integrations.oxblue.historicalDataNone'),
            value: 'NONE'
          },
          {
            name: $filter('i18next')('integrations.oxblue.historicalData7Days'),
            value: 'PAST_7'
          },
          {
            name: $filter('i18next')('integrations.oxblue.historicalData14Days'),
            value: 'PAST_14'
          },
          {
            name: $filter('i18next')('integrations.oxblue.historicalData30Days'),
            value: 'PAST_30'
          }
        ]
        scope.historicalDataSyncDepthOptions = availableOxBlueHistoricalDataSyncDepthOptions

        const OXBLUE = 'OXBLUE'
        const OXBLUE_SYNC = 'OXBLUE_SYNC'

        scope.integrationSettingsState = new IntegrationSettingsState()
        scope.integrationSettingsState.setCreatePartnerSyncSettingsFunc(settingsData => {
          return settingsData
        })

        scope.oxBlueCameras = []
        scope.oxBlueUserInfo = {}
        scope.connectionStatus = ''

        let smartvidProjectId = $stateParams.projectId

        let initializeSelectedData = () => {
          scope.selectedData = {
            syncStartHour: { value: 0 },
            syncEndHour: { value: 24 },
            intervalInMinutes: { value: 15 },
            historicalDataSyncDepth: availableOxBlueHistoricalDataSyncDepthOptions[0],
            oxBlueTimeLapseOptions: scope.availableOxBlueTimeLapseOptions[0]
          }
        }

        let resetOxBlueCameras = () => {
          scope.oxBlueCameras = new OxBlueCameraInfoCollection()
        }

        let resetDefaults = () => {
          initializeSelectedData()
          scope.integrationSettingsState.clear()
          resetOxBlueCameras()
          scope.integrationSettingsState.isEnabled = false
        }

        scope.isCameraDataUnavailable = () => {
          return !scope.oxBlueCameras || scope.oxBlueCameras.length === 0
        }

        scope.getCameraDataUnavailableMessage = () => {
          if (scope.isCameraDataUnavailable() && scope.isPartnerIntegrationEnabledForProject()) {
            return $filter('i18next')('integrations.oxblue.noCamerasPlaceholder')
          }

          return $filter('i18next')('integrations.unavailablePlaceholder')
        }

        scope.integrationSettingsState.isLoadingData = true
        // initialize page
        $timeout(() => {
          scope.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
          scope.availableOxBlueTimeLapseOptions = [
            {
              name: $filter('i18next')('integrations.oxblue.timelapseNone'),
              value: 'NONE'
            },
            {
              name: $filter('i18next')('integrations.oxblue.timelapseDaily'),
              value: 'DAILY'
            },
            {
              name: $filter('i18next')('integrations.oxblue.timelapseWeekly'),
              value: 'WEEKLY'
            }
          ]
          scope.syncIntervalLablePrefix = $filter('i18next')('integrations.oxblue.syncEveryXPrefix')
          resetDefaults()
          projectIntegrationUtils.checkIntegrationStatusAndLoadData(
            scope.integrationSettingsState,
            smartvidProjectId,
            OXBLUE,
            OXBLUE_SYNC,
            false
          )
        })

        scope.disableOxBlueIntegration = () => {
          setLoading(true)
          smartvidApi.deleteIntegrationSettings(OXBLUE_SYNC, smartvidProjectId).then(
            () => {
              scope.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
              resetDefaults()
              projectIntegrationUtils.checkIntegrationStatusAndLoadData(
                scope.integrationSettingsState,
                smartvidProjectId,
                OXBLUE,
                OXBLUE_SYNC,
                false
              )
            },
            () => {
              resetDefaults()
              setLoading(false)
            }
          )
        }

        scope.disconnect = () => {
          if (scope.isPartnerIntegrationEnabledForProject()) {
            scope.disableOxBlueIntegration()
          }
        }

        scope.isOxBlueCamerasSelected = () => {
          return scope.oxBlueCameras && scope.oxBlueCameras.getSelected().length > 0
        }

        scope.canLinkCameras = () => {
          return (
            scope.integrationSettingsState.isPartnerIntegrationEnabledByCurrentUser && scope.isOxBlueCamerasSelected()
          )
        }

        scope.linkCameras = () => {
          scope.syncWindowStartAfterEnd = scope.isSyncWindowStartAfterEnd()
          scope.syncIntervalLargerThanWindow = scope.isSyncIntervalLargerThanWindow()

          if (scope.syncWindowStartAfterEnd || scope.syncIntervalLargerThanWindow) {
            scope.integrationSettingsState.partnerIntegrationSettings.isEnabled = false
            return
          }

          scope.integrationSettingsState.partnerIntegrationSettings.isEnabled = true
          scope.integrationSettingsState.syncOnlyAfterDate = Date.now()
          saveOxBlueSyncSettings()
        }

        scope.unlinkCameras = () => {
          scope.integrationSettingsState.partnerIntegrationSettings.isEnabled = false
          saveOxBlueSyncSettings()
        }

        let adjustConnectionStatus = () => {
          if (scope.integrationSettingsState.isAuthenticationTokenExpired) {
            let oxBlueUser = scope.oxBlueUserInfo ? scope.oxBlueUserInfo.id : ''
            scope.connectionStatus = $interpolate(
              $filter('i18next')('integrations.oxblue.connection.authTokenExpired')
            )({
              oxBlueUser: oxBlueUser
            })
          } else if (scope.integrationSettingsState.isPartnerIntegrationEnabledByCurrentUser) {
            let oxBlueUser = scope.oxBlueUserInfo ? scope.oxBlueUserInfo.id : ''
            scope.connectionStatus = $interpolate(
              $filter('i18next')('integrations.oxblue.connection.connectedAsOxBlueUser')
            )({
              oxBlueUser: oxBlueUser
            })
          } else if (scope.integrationSettingsState.isPartnerIntegrationEnabledByOtherUser) {
            scope.connectionStatus = $interpolate(
              $filter('i18next')('integrations.connection.connectedAsSmartvidUser')
            )({
              smartvidUser: ''
            })
          }
        }

        let saveOxBlueSyncSettings = afterSaveFunc => {
          scope.setIntegrationSettingsState()

          smartvidApi
            .saveIntegrationSettings(OXBLUE_SYNC, scope.integrationSettingsState.partnerIntegrationSettings)
            .then(
              () => {
                if (afterSaveFunc) {
                  afterSaveFunc()
                }
              },
              () => {
                if (scope.integrationSettingsState) {
                  scope.integrationSettingsState.partnerIntegrationSettings.isEnabled = false
                }
              }
            )
        }

        scope.isReadOnly = () => {
          return projectIntegrationUtils.isReadOnly(scope.integrationSettingsState)
        }

        scope.needToShowPleaseSelectCameraMessage = () => {
          return false
        }

        scope.isSyncWindowStartAfterEnd = () => {
          return scope.selectedData.syncStartHour && scope.selectedData.syncEndHour
            ? scope.selectedData.syncStartHour.value >= scope.selectedData.syncEndHour.value
            : false
        }

        scope.isSyncIntervalLargerThanWindow = () => {
          return scope.selectedData.syncStartHour &&
            scope.selectedData.syncEndHour &&
            scope.selectedData.intervalInMinutes
            ? Math.abs(scope.selectedData.syncEndHour.value - scope.selectedData.syncStartHour.value) * 60 <
                scope.selectedData.intervalInMinutes.value
            : false
        }

        scope.resetValidation = () => {
          scope.syncWindowStartAfterEnd = false
          scope.syncIntervalLargerThanWindow = false
        }

        let setLoading = value => {
          scope.integrationSettingsState.isLoadingData = value
          utils.digest(scope)
        }

        scope.setIntegrationSettingsState = () => {
          scope.integrationSettingsState.partnerIntegrationSettings.settingsData.oxBlueCameraSyncSettings = {
            startHour: scope.selectedData.syncStartHour.value,
            endHour: scope.selectedData.syncEndHour.value,
            intervalInMinutes: scope.selectedData.intervalInMinutes.value,
            historicalDataSyncDepth: scope.selectedData.historicalDataSyncDepth.value,
            oxBlueTimeLapseOptions: scope.selectedData.oxBlueTimeLapseOptions.value
          }

          scope.integrationSettingsState.partnerIntegrationSettings.settingsData.oxBlueCameraInfoSyncSettings = _.map(
            scope.oxBlueCameras.getSelected(),
            c => {
              return {
                cameraId: c.id,
                overrideGlobalSettings: false
              }
            }
          )
        }

        scope.reloadOxBlueData = () => {
          scope.setIntegrationSettingsState()
          scope.loadOxBlueData()
        }

        let customErrorHandler = response => {
          if (
            response &&
            projectIntegrationUtils.checkIfAuthenticationTokenExpired(
              scope.integrationSettingsState,
              response.errorCode,
              response.errorMessages
            )
          ) {
            scope.expiredAuthenticationPartnerUserId = scope.integrationSettingsState.syncSettings.partnerUserId
            resetDefaults()
            adjustConnectionStatus()

            return true
          }

          Notification.error($filter('i18next')('integrations.oxblue.errors.failedToLoadSyncSettings'))
          return true
        }

        scope.loadOxBlueData = () => {
          setLoading(true)
          scope.expiredAuthenticationPartnerUserId = undefined
          smartvidApi.listOxBlueCamerasInfo(smartvidProjectId, true, customErrorHandler).then(
            data => {
              scope.oxBlueCameras = new OxBlueCameraInfoCollection(_.sortBy(data, 'siteName'))
              if (scope.oxBlueCameras.isEmpty) {
                setLoading(false)
              } else {
                if (
                  scope.integrationSettingsState.partnerIntegrationSettings &&
                  scope.integrationSettingsState.partnerIntegrationSettings.settingsData
                ) {
                  let selectedCameraIds = _.map(
                    scope.integrationSettingsState.partnerIntegrationSettings.settingsData.oxBlueCameraInfoSyncSettings,
                    s => {
                      return s.cameraId
                    }
                  )

                  _.each(scope.oxBlueCameras.models, c => {
                    c.selected = _.contains(selectedCameraIds, c.id)
                  })

                  if (scope.integrationSettingsState.partnerIntegrationSettings.settingsData.oxBlueCameraSyncSettings) {
                    scope.selectedData.syncStartHour = {
                      value:
                        scope.integrationSettingsState.partnerIntegrationSettings.settingsData.oxBlueCameraSyncSettings
                          .startHour
                    }
                    scope.selectedData.syncEndHour = {
                      value:
                        scope.integrationSettingsState.partnerIntegrationSettings.settingsData.oxBlueCameraSyncSettings
                          .endHour
                    }
                    scope.selectedData.intervalInMinutes = {
                      value:
                        scope.integrationSettingsState.partnerIntegrationSettings.settingsData.oxBlueCameraSyncSettings
                          .intervalInMinutes
                    }

                    scope.selectedData.historicalDataSyncDepth = {
                      value:
                        scope.integrationSettingsState.partnerIntegrationSettings.settingsData.oxBlueCameraSyncSettings
                          .historicalDataSyncDepth
                    }

                    scope.selectedData.oxBlueTimeLapseOptions = _.find(scope.availableOxBlueTimeLapseOptions, o => {
                      return (
                        o.value ===
                        scope.integrationSettingsState.partnerIntegrationSettings.settingsData.oxBlueCameraSyncSettings
                          .oxBlueTimeLapseOptions
                      )
                    })
                    if (!scope.selectedData.oxBlueTimeLapseOptions) {
                      scope.selectedData.oxBlueTimeLapseOptions = scope.availableOxBlueTimeLapseOptions[0]
                    }
                  }
                }

                setLoading(false)
              }
            },
            () => {
              setLoading(false)
            }
          )
        }

        scope.isPartnerIntegrationEnabledForProject = _.bind(
          projectIntegrationUtils.isPartnerIntegrationEnabledForProject,
          undefined,
          scope.integrationSettingsState
        )

        scope.integrationSettingsState.setLoadPageData(integrationSettingsState => {
          let partnerUserId = projectIntegrationUtils.getPartnerUserId(integrationSettingsState)
          if (partnerUserId) {
            scope.oxBlueUserInfo = new OxBlueUserModel({
              id: partnerUserId
            })
            adjustConnectionStatus()
            scope.loadOxBlueData()
          } else {
            scope.loadOxBlueData()
          }
        })

        scope.getNextSyncDate = _.bind(
          projectIntegrationUtils.getNextSyncDate,
          undefined,
          scope.integrationSettingsState
        )

        scope.getLastSyncDate = _.bind(
          projectIntegrationUtils.getLastSyncDate,
          undefined,
          scope.integrationSettingsState
        )

        scope.isConnectionStatusTextClipped = _.bind(
          projectIntegrationUtils.isConnectionStatusTextClipped,
          undefined,
          '#connectionStatus'
        )

        scope.connect = () => {
          if (
            !scope.expiredAuthenticationPartnerUserId &&
            projectIntegrationUtils.isAutoConnect(scope.integrationSettingsState)
          ) {
            projectIntegrationUtils.enableIntegration(
              scope.integrationSettingsState,
              smartvidProjectId,
              OXBLUE,
              OXBLUE_SYNC
            )
          } else {
            if (scope.expiredAuthenticationPartnerUserId) {
              setLoading(true)
              smartvidApi
                .removeOAuthDataForProviderForExternalUserId(OXBLUE, scope.expiredAuthenticationPartnerUserId)
                .then(
                  () => {
                    smartvidApi.deleteIntegrationSettings(OXBLUE_SYNC, smartvidProjectId).then(
                      () => {
                        setLoading(false)
                        projectIntegrationUtils.performDirectLoginAndEnableIntegration(
                          scope,
                          $rootScope,
                          'sv-connected-to-oxblue',
                          'connectToOxBlue',
                          scope.integrationSettingsState,
                          smartvidProjectId,
                          OXBLUE,
                          OXBLUE_SYNC
                        )
                      },
                      () => {
                        setLoading(false)
                        Notification.error(
                          $filter('i18next')('directives.mysettings.oxblue.failedToEstablishIntegrationStatus')
                        )
                      }
                    )
                  },
                  () => {
                    setLoading(false)
                    Notification.error(
                      $filter('i18next')('directives.mysettings.oxblue.failedToEstablishIntegrationStatus')
                    )
                  }
                )
            } else {
              projectIntegrationUtils.performDirectLoginAndEnableIntegration(
                scope,
                $rootScope,
                'sv-connected-to-oxblue',
                'connectToOxBlue',
                scope.integrationSettingsState,
                smartvidProjectId,
                OXBLUE,
                OXBLUE_SYNC
              )
            }
          }
        }

        scope.$on('sv-project-deleted', () => {
          scope.projectWasDeleted = true
        })

        // when user signs out the $destroy event is received the sate already changed to 'login', don't try to save the settings in this case
        scope.$on('$destroy', () => {
          if ($state.$current.name !== 'login' && !scope.isReadOnly() && !scope.projectWasDeleted) {
            saveOxBlueSyncSettings()
          }
        })

        scope.adjustIntervalSelections = () => {
          let selectedCameraCount = scope.oxBlueCameras.getSelected().length
          if (selectedCameraCount < 3 && scope.syncIntervalOptions.length !== 14) {
            scope.syncIntervalOptions = intervalSelectionOptions
          } else if (selectedCameraCount > 2 && selectedCameraCount < 5 && scope.syncIntervalOptions.length !== 13) {
            scope.syncIntervalOptions = intervalSelectionOptions.slice(1)
          } else if (selectedCameraCount > 4 && scope.syncIntervalOptions.length !== 12) {
            scope.syncIntervalOptions = intervalSelectionOptions.slice(2)
          }

          let selectedIntervalOption = _.find(scope.syncIntervalOptions, o => {
            return o.value === scope.selectedData.intervalInMinutes.value
          })
          if (!selectedIntervalOption) {
            scope.selectedData.intervalInMinutes = scope.syncIntervalOptions[0]
          }
        }

        scope.adjustHistoricalDataSyncDepthSelections = () => {
          let selectedCameraCount = scope.oxBlueCameras.getSelected().length
          if (selectedCameraCount < 2) {
            scope.historicalDataSyncDepthOptions = availableOxBlueHistoricalDataSyncDepthOptions
          } else if (selectedCameraCount > 1 && selectedCameraCount < 3) {
            scope.historicalDataSyncDepthOptions = availableOxBlueHistoricalDataSyncDepthOptions.slice(0, 3)
          } else if (selectedCameraCount >= 3) {
            scope.historicalDataSyncDepthOptions = availableOxBlueHistoricalDataSyncDepthOptions.slice(0, 2)
          }

          let selectedHistoricalDataSyncDepthOption = _.find(scope.historicalDataSyncDepthOptions, o => {
            return o.value === scope.selectedData.historicalDataSyncDepth.value
          })
          if (!selectedHistoricalDataSyncDepthOption) {
            scope.selectedData.historicalDataSyncDepth = scope.historicalDataSyncDepthOptions[0]
          }
        }
      }
    }
  })
