/* global angular,analytics, _ */

angular.module('smartvid').controller('ProjectOptionsCtrl', function ($rootScope, $scope, $timeout, $window, $state,
                                                                     dashboardDataHelper, smartvidApi, modal,
                                                                     NavController, utils, currentUser) {
  const LEFT_OFFSET = 20
  const LEFT_OFFSET_WHEN_LIST_VIEW = -155
  const TOP_OFFSET = 17
  $scope.showPropertiesOption = !_.isUndefined($state.current.name) && ($state.current.name === 'dashboard.projects' || $state.current.name === 'dashboard.projects.orgId')

  $scope.styleRect = {
    top: $scope.styles.top - TOP_OFFSET,
    left: $scope.styles.left + LEFT_OFFSET
  }
  $timeout(() => {
    $scope.styleRect = {
      top: $scope.styles.top - TOP_OFFSET,
      left: $scope.styles.left + ($scope.currentView === 'LIST_VIEW' ? LEFT_OFFSET_WHEN_LIST_VIEW : LEFT_OFFSET)
    }
  }, 10)

  $scope.deleteProject = (projects, project, allProjects) => {
    $scope.flyout.isOpen = false

    let confirmHandlers = {
      confirm () {
        analytics.track('Delete Project', {
          category: 'Project Action',
          orgName: (project.getProjectOrg()) ? project.getProjectOrg().name : undefined,
          projectName: project.name
        })
        smartvidApi.deleteProjects([project.id], false).then(() => {
          $timeout(() => {
            project.update({ newlyAdded: true })
          }, 1)
          $timeout(() => {
            projects.removeById(project.id)
            dashboardDataHelper.getAllProjects().removeById(project.id)
            if (allProjects) {
              allProjects.removeById(project.id)
            }
            $rootScope.$broadcast('sv-project-deleted', { projectId: project.id })
            $state.go($state.current.name, {}, {
              reload: true
            })
          }, 400)
          utils.notify('common.projectMovedToDeletedFolder', '', 'common.view', null, () => {
            $state.go('dashboard.adminOrganizations.organizationId.deletedProjects',
              {
                organizationId: project.getProjectOrg().id
              },
              {
                reload: true
              })
          })
          $scope.flyout.close()
        })
      },
      hasSelections: true
    }

    modal.open('projectDeleteConfirm', confirmHandlers)
  }

  $scope.openProjectProperties = function (projects, project, allProjects) {
    $scope.flyout.close()
    _.each(projects.models, (model) => {
      model.selected = false
    })
    project.selected = true
    // in case project not in scope.projects collection
    if (allProjects) {
      allProjects.resetSelected()
      let found = allProjects.findById(project.id)
      if (found) {
        found.selected = true
      }
    }
    NavController.openRightNav()
  }

  $scope.showActivateOption = function (project) {
    return project.isInactive && currentUser.getOrganization(project.organizationId).canManageProjects
  }

  $scope.showDeactivateOption = function (project) {
    return !project.isInactive && currentUser.getOrganization(project.organizationId).canManageProjects
  }

  $scope.setProjectActivityStatus = function (project, isActive) {
    // address confusing parameter name
    let isInactive = !isActive
    let promise = smartvidApi.updateProjectStatus(project.id, isInactive)
    promise.then(() => {
      project.update({ isInactive: isInactive })
      $rootScope.$broadcast('sv-project-updated', project)
      $state.go($state.current.name, {}, {
        reload: true
      })
    })
    $scope.flyout.close()
  }
})
