import { Inject, Injectable } from '@angular/core'
import { Action, Actions, Selector, State, StateContext } from '@ngxs/store'
import { ApplyObservationContext, UpdateObservationFilters } from './observations.actions'
import { ObservationFilterCriteria } from 'modules/observations/models/observation.model'
import { CURRENT_USER, CurrentUser } from 'shared/smartvid.types'

export interface ObservationCommonStateModel {
  navigation: {
    organizationId: string
    projectGroupId: string
    projectId: string
  }
}

@State<ObservationCommonStateModel>({
  name: 'observations',
  defaults: {
    navigation: {
      organizationId: undefined,
      projectGroupId: undefined,
      projectId: undefined,
    },
  },
})
@Injectable()
export class ObservationCommonState {
  constructor(private actions$: Actions, @Inject(CURRENT_USER) private currentUser: CurrentUser) {}

  @Selector()
  static navigation(state: ObservationCommonStateModel) {
    return state.navigation
  }

  @Action(ApplyObservationContext)
  applyFilter(
    { patchState, getState, dispatch }: StateContext<ObservationCommonStateModel>,
    { payload }: ApplyObservationContext
  ) {
    const organizationId = payload.organization ? payload.organization.id : undefined
    const projectGroupId = payload.projectGroup ? payload.projectGroup.id : undefined
    const projectId = payload.project ? payload.project.id : undefined

    let orgFilterCriteria: ObservationFilterCriteria = this.getMyFilterCriteriaForOrg(organizationId)
    if (organizationId !== getState().navigation.organizationId) {
      dispatch(new UpdateObservationFilters(orgFilterCriteria ? orgFilterCriteria : ObservationFilterCriteria.empty()))
    }

    patchState({
      navigation: { organizationId, projectGroupId, projectId },
    })
  }

  private getMyFilterCriteriaForOrg(organizationId: string): ObservationFilterCriteria {
    const filterCriteriaStr: string = localStorage.getItem(
      'OBSERVATION_FILTER_STATE' + ':' + this.currentUser.id + ':' + organizationId
    )

    if (filterCriteriaStr) {
      return ObservationFilterCriteria.fromObject(JSON.parse(filterCriteriaStr))
    }

    return null
  }
}
