/* global angular */

angular.module('smartvid').service('ensure', function (smartvidApi, $q) {
  let asset = (assetId) => {
    let defer = $q.defer()

    smartvidApi.getAsset(assetId).then((asset) => {
      defer.resolve(asset)
    }).catch((data) => {
      defer.reject(data)
    })
    return defer.promise
  }

  let sharedAsset = (contentType, linkId) => {
    let defer = $q.defer()

    smartvidApi.getSharedAsset(contentType, linkId).then((asset) => {
      defer.resolve(asset)
    }).catch((data) => {
      defer.reject(data)
    })
    return defer.promise
  }

  function ensureTags (asset) {
    let defer = $q.defer()

    smartvidApi.getTagsForAsset(asset.id).then((TagInstanceCollection) => {
      // asset.tags = TagInstanceCollection
      asset.tags = (asset.tags.length > 0) ? angular.copy(TagInstanceCollection, asset.tags) : TagInstanceCollection
      asset.unverifiedTagsCount = (asset.tags) ? asset.tags.getUnverifiedTags().length : 0
      asset.tagsCount = (asset.tags) ? asset.tags.length : 0
      defer.resolve(asset)
    }).catch((data) => {
      defer.reject(data)
    })

    return defer.promise
  }

  return {
    asset,
    sharedAsset,
    tags: ensureTags
  }
})
