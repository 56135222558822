/* global angular */

angular.module('smartvid').service('projectCopyService', function ($q, $rootScope, $window, $filter, $interval, $stateParams, smartvidApi, Notification, utils, ProjectModel) {
  let pollAndCheck

  let onProjectCopyProgress = (data, isDemoProject) => {
    if (data.status === 'COMPLETED') {
      if (isDemoProject) {
        utils.notify('services.projectCopyService.demoProjectCopied')
      } else {
        utils.notify('services.projectCopyService.projectCopied')
      }
      return {done: true, success: true}
    } else if (data.status === 'FAILED') {
      if (isDemoProject) {
        utils.notify('services.projectCopyService.demoProjectCopyFailed')
      } else {
        utils.notify('services.projectCopyService.projectCopyFailed')
      }
      return {done: true, success: false}
    }

    return {done: false}
  }

  //
  // The exposed API for projectCopyService
  //
  let service = {

    startPollingForProjectCopyCompletion: (data, isDemoProject) => {
      if (!onProjectCopyProgress(data).done && !pollAndCheck) {
        pollAndCheck = $interval(() => {
          smartvidApi.getCopyProjectProgress(data.copyProjectProcessId).then((response) => {
            let progress = onProjectCopyProgress(response, isDemoProject)
            if (progress.done) {
              $interval.cancel(pollAndCheck)
              pollAndCheck = undefined
              $rootScope.copyProjectInProgress = false
              if (progress.success) {
                smartvidApi.getUserProject(response.targetProjectId).then((projectWithRole) => {
                  $rootScope.$broadcast('sv-project-copy-done', new ProjectModel(utils.flattenProject(projectWithRole)))
                })
              }
            }
          }, (response) => {
            $interval.cancel(pollAndCheck)
            pollAndCheck = undefined
            $rootScope.copyProjectInProgress = false
          })
        }, 1000)
      }
    }
  }

  return service
})
