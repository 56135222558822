/* global angular */

angular.module('smartvid').directive('svTooltip', function () {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    templateUrl: 'tooltip.html',
    scope: {
      showTooltip: '=',
      right: '@'
    },
    link (scope, el, attrs) {
      el.css('pointer-events', 'none')
      el.on('click', () => { return false })

      if (attrs.left) {
        el.css('left', attrs.left)
      }
      if (attrs.top) {
        el.css('top', attrs.top)
      }
    }
  }
})
