import { NgModule } from '@angular/core'

import { TranslateModule } from '@ngx-translate/core'

import { SmartvidMaterialModule } from 'modules/material/material.module'

import { HttpClientModule } from '@angular/common/http'
import { NgPipesModule } from 'ngx-pipes'
import { ScrollingModule } from '@angular/cdk/scrolling'

import { AppSharedModule } from 'shared/shared.module'

import { InsightsDashboardModule } from '../insights/insights-dashboard.module'
import { OrganizationAssetsHomeComponent } from './components/organization-home/organization-assets-home.component'
import { ProjectGroupAssetsHomeComponent } from 'modules/assets/components/project-group-home/project-group-assets-home.component'
import { AssetsHomeCommonControlBarComponent } from 'modules/assets/components/assets-home-common-control-bar/assets-home-common-control-bar.component'
import { CommonModule } from '@angular/common'

@NgModule({
  imports: [
    CommonModule,
    SmartvidMaterialModule,
    HttpClientModule,
    TranslateModule,
    NgPipesModule,
    ScrollingModule,
    AppSharedModule,
    InsightsDashboardModule,
  ],
  declarations: [OrganizationAssetsHomeComponent, ProjectGroupAssetsHomeComponent, AssetsHomeCommonControlBarComponent],
  entryComponents: [
    OrganizationAssetsHomeComponent,
    ProjectGroupAssetsHomeComponent,
    AssetsHomeCommonControlBarComponent,
  ],
})
export class AppAssetsModule {}
