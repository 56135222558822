import { Component } from '@angular/core'
import {
  CardInputChangeEvent,
  CardToggleMenuGroup,
  CardToggleMenuItem,
} from 'modules/insights/dashboard/cards/models/insights-card.model'
import {
  DashboardCardId,
  DashboardDateRangeTimeUnit,
  DashboardTabId,
  DashboardType,
  DateRangeChartData,
  InsightsDashboardParameters,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { TranslateService } from '@ngx-translate/core'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import { InsightsDashboardComponentBaseDirective } from 'modules/insights/dashboard/components/insights-dashboard-component-base'
import { ProjectGroupAssetsActivityTableData } from 'modules/insights/dashboard/models/insights-dashboard-project-group-assets.model'
import { InsightsDashboardProjectGroupAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-project-group-assets.service'
import { ProjectGroupAssetsActivityTableComponent } from 'modules/insights/dashboard/components/totals-table/project-group-assets/project-group-assets-activity-table.component'

@Component({
  selector: 'sv-project-group-assets-insights-dashboard-activity',
  templateUrl: 'project-group-assets-insights-dashboard-activity.component.html',
  styleUrls: ['project-group-assets-insights-dashboard-activity.component.scss'],
})
export class ProjectGroupAssetsInsightsDashboardActivityComponent extends InsightsDashboardComponentBaseDirective {
  activityTableData: ProjectGroupAssetsActivityTableData[]
  projectGroupUploadsOverTimeBySourceData: DateRangeChartData
  uploadsBySourceChartMenu: CardToggleMenuGroup[]
  projectGroupUploadersOverTimeData: DateRangeChartData
  uploadersChartMenu: CardToggleMenuGroup[]
  projectGroupConstructionActivityData: DateRangeChartData
  projectGroupPhotoDescriptionData: DateRangeChartData
  projectGroupUploadsByFileTypeData: DateRangeChartData
  projectGroupTagsByTypeData: DateRangeChartData

  constructor(
    protected insightsDataHelper: InsightsDataHelper,
    protected translate: TranslateService,
    protected insightsDashboardConfigurationService: InsightsDashboardConfigurationService,
    private insightsDashboardProjectGroupAssetsService: InsightsDashboardProjectGroupAssetsService
  ) {
    super(
      insightsDataHelper,
      insightsDashboardConfigurationService,
      translate,
      DashboardType.PROJECT_GROUP_ASSETS_DASHBOARD
    )
  }

  uploadsBySourceChartMenuSelected($event: CardToggleMenuItem) {
    this.loadProjectGroupUploadsOverTimeBySourceData($event.eventId)
  }

  uploadersChartMenuSelected($event: CardToggleMenuItem) {
    this.loadProjectGroupUploadersOverTimeData($event.eventId)
  }

  activityTableFilterChanged(
    $event: CardInputChangeEvent,
    organizationAssetsActivityTable: ProjectGroupAssetsActivityTableComponent
  ) {
    if (!$event.value || !$event.value.trim()) {
      organizationAssetsActivityTable.clearFilter()
      return
    }

    organizationAssetsActivityTable.applyFilter({
      fieldName: 'name',
      filter: $event.value,
    })
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // the ()=> function is needed to capture the correct 'this' reference
  protected refresh() {
    this.initializeMenuItems()

    this.guardFor(
      () => this.loadActivityTableData(),
      DashboardTabId.ACTIVITY,
      DashboardCardId.PROJECT_GROUP_ASSETS_ACTIVITY_BY_PROJECT
    )
    this.guardFor(
      () => this.loadProjectGroupUploadsOverTimeBySourceData(DashboardDateRangeTimeUnit.LAST_1),
      DashboardTabId.ACTIVITY,
      DashboardCardId.PROJECT_GROUP_ASSETS_UPLOADS_OVERTIME_BY_SOURCE
    )
    this.guardFor(
      () => this.loadProjectGroupUploadersOverTimeData(DashboardDateRangeTimeUnit.LAST_1),
      DashboardTabId.ACTIVITY,
      DashboardCardId.PROJECT_GROUP_ASSETS_UPLOADERS_OVERTIME
    )
    this.guardFor(
      () => this.loadProjectGroupConstructionActivityData(),
      DashboardTabId.ACTIVITY,
      DashboardCardId.PROJECT_GROUP_ASSETS_CONSTRUCTION_ACTIVITY
    )
    this.guardFor(
      () => this.loadProjectGroupPhotoDescriptionData(),
      DashboardTabId.ACTIVITY,
      DashboardCardId.PROJECT_GROUP_ASSETS_PHOTO_DESCRIPTION
    )
    this.guardFor(
      () => this.loadProjectGroupUploadsByFileTypeData(),
      DashboardTabId.ACTIVITY,
      DashboardCardId.PROJECT_GROUP_ASSETS_UPLOADS_BY_FILE_TYPE
    )
    this.guardFor(
      () => this.loadProjectGroupTagsByTypeData(),
      DashboardTabId.ACTIVITY,
      DashboardCardId.PROJECT_GROUP_ASSETS_TAGS_BY_TYPE
    )
  }

  private initializeMenuItems() {
    this.uploadsBySourceChartMenu = this.createChartDateSwitchMenuItems(
      'UPLOADS_MENU',
      'projectGroupUploadsOverTimeBySource'
    )
    this.uploadersChartMenu = this.createChartDateSwitchMenuItems(
      'UPLOADERS_MENU',
      'projectGroupUploadersCountOverTime'
    )
  }

  private loadActivityTableData() {
    this.activityTableData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadProjectGroupActivityTableData(
        this.currentInsightsDashboardParameters,
        tableData => {
          this.activityTableData = tableData
        }
      )
    )
  }

  private loadProjectGroupUploadsOverTimeBySourceData(chartResolution: DashboardDateRangeTimeUnit) {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeTimeUnit: chartResolution,
    }
    this.projectGroupUploadsOverTimeBySourceData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadUploadsOverTimeBySourceData(dashboardParams, chartData => {
        this.projectGroupUploadsOverTimeBySourceData = chartData
      })
    )
  }

  private loadProjectGroupUploadersOverTimeData(chartResolution: DashboardDateRangeTimeUnit) {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeTimeUnit: chartResolution,
    }
    this.projectGroupUploadersOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadUploadersOverTimeData(dashboardParams, chartData => {
        this.projectGroupUploadersOverTimeData = chartData
      })
    )
  }

  private loadProjectGroupConstructionActivityData() {
    this.projectGroupConstructionActivityData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadConstructionActivityData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectGroupConstructionActivityData = chartData
        }
      )
    )
  }

  private loadProjectGroupPhotoDescriptionData() {
    this.projectGroupPhotoDescriptionData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadPhotoDescriptionData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectGroupPhotoDescriptionData = chartData
        }
      )
    )
  }

  private loadProjectGroupUploadsByFileTypeData() {
    this.projectGroupUploadsByFileTypeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadUploadsByFileTypeData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectGroupUploadsByFileTypeData = chartData
        }
      )
    )
  }

  private loadProjectGroupTagsByTypeData() {
    this.projectGroupTagsByTypeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadTagsByTypeData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectGroupTagsByTypeData = chartData
        }
      )
    )
  }
}
