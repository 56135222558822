import { Inject, Injectable } from '@angular/core'
import { HTTP } from 'shared/smartvid.types'
import { ConfigService } from 'modules/core/services/config.service'
import { Observable } from 'rxjs'
import { toObservable, toObservableArray } from 'shared/utils/api-utils'
import {
  OneReportModel,
  ReportDashboardModel,
  ScheduleInterval,
} from 'modules/insights/dashboard/models/report-dashboard.model'

@Injectable({
  providedIn: 'root',
})
export class ReportsDashboardService {
  private readonly rootUrl: string

  constructor(@Inject(HTTP) private $http: ng.IHttpService, private config: ConfigService) {
    this.rootUrl = this.config.env.development.apiRootUrl
  }

  getReports(organizationId: string, scheduleInterval: ScheduleInterval): Observable<ReportDashboardModel[]> {
    let url = this.rootUrl + `/api/report/${organizationId}/${scheduleInterval}`
    return toObservableArray<ReportDashboardModel>(this.$http.get(url), ReportDashboardModel)
  }

  deleteReport(organizationId: string, reportId: string) {
    let url = this.rootUrl + `/api/organization/${organizationId}/scheduled/report/${reportId}`
    return toObservable(this.$http.delete(url), Object)
  }

  activateReport(organizationId: string, reportId: string, isInactive: boolean) {
    let url = this.rootUrl + `/api/organization/${organizationId}/report/scheduled/${reportId}?isInactive=` + isInactive
    return toObservable(this.$http.put(url, ''), Object)
  }

  getScheduledReport(reportId: string) {
    let url = this.rootUrl + `/api/report/schedule/${reportId}/`
    return toObservable(this.$http.get(url), Object)
  }

  getOneTimeSafetySummaryReport(organizationId: string) {
    let url = this.rootUrl + `/api/report/safety_summary/organization/${organizationId}/latest/parameters`
    return toObservable<OneReportModel>(this.$http.get(url), OneReportModel)
  }

  getOneTimePhotoReport(projectId: string) {
    let url = this.rootUrl + `/api/report/photo/project/${projectId}/latest/parameters`
    return toObservable<OneReportModel>(this.$http.get(url), OneReportModel)
  }

  reRuScheduledReport(reportId: string) {
    let url = this.rootUrl + `/api/report/scheduled/${reportId}`
    return toObservable(this.$http.post(url, ''), Object)
  }

  reRenRunOneTimePhotoReport(projectId: string) {
    let url = this.rootUrl + `/api/report/photo/project/${projectId}/latest`
    return toObservable(this.$http.post(url, ''), Object)
  }

  reRenRunOneTimeSafetyReport() {
    let url = this.rootUrl + `/api/report/safety_summary/latest`
    return toObservable(this.$http.post(url, ''), Object)
  }
}
