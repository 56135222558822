import * as moment from 'moment'
import * as _ from 'lodash'
import { ObservationType } from './observation-type.enum'
import { ObservationStatus } from './observation-status.enum'
import { ObservationRiskType } from './observation-risk-type.enum'
import { ObservationDetails } from './observation-details.enum'
import { FilterDueDatePeriod } from '../components/filter-observations/filter-due-date-period.enum'
import { CustomFieldValue } from '../../../shared/models/custom-field'
import { TradePartner } from 'modules/observations/models/tradepartner.model'
import { ObservationReportProjectScope } from './observation-report.model'

/**
 * @author vseletskyi
 */
export class Observation {
  public observationId: string
  public observerUserId: string
  public externalId: number
  public updatedByUserId: string
  public projectId: string
  public tradePartnerId: string
  public assignedUserId: string
  public hazardCategory: string
  public status: ObservationStatus
  public projectName: string
  public severity: string
  public frequency: string
  public risk: number
  public riskType: ObservationRiskType
  public identificationMethod: string
  public recommendedAction: string
  public notes: string
  public dueDate: number
  public dateCreated: number
  public dateUpdated: number
  public type: ObservationType
  public isGreatCatch: boolean
  public assets: any
  public tradePartnerName: string
  public assignedUserFirstName: string
  public assignedUserLastName: string
  public assignedUserEmail: string
  public updatedByUserFirstName: string
  public updatedByUserLastName: string
  public updatedByUserEmail: string
  public actionTaken: string
  public reviewComment: string
  public observerUserFirstName: string
  public observerUserLastName: string
  public observerUserEmail: string
  public canEdit: boolean
  public canDelete: boolean
  public customFields?: CustomFieldValue[]

  public getDisplayName(): string {
    if (!this.observerUserFirstName && !this.observerUserLastName) {
      return this.observerUserEmail
    }
    return this.observerUserFirstName + ' ' + this.observerUserLastName
  }
}

export type ObservationRow = Observation | ObservationGroup

export class ObservationGroup {
  groupId: string
  count: number

  constructor(groupId: string, count: number) {
    this.groupId = groupId
    this.count = count
  }
}

export enum ObservationSortType {
  RISK = 'risk:DESC',
  CREATED_DATE = 'createdTime:DESC',
}

export enum ObservationSearchType {
  IN_PROJECT = 'IN_PROJECT',
  IN_PROJECTS = 'IN_PROJECTS',
  IN_PROJECT_GROUP = 'IN_PROJECT_GROUP',
  IN_PROJECT_GROUPS = 'IN_PROJECT_GROUPS',
  IN_ORGANIZATION = 'IN_ORGANIZATION',
  CROSS_ORGANIZATIONS = 'CROSS_ORGANIZATIONS',
}

export class ObservationPage {
  public static SIZE = 30

  offset: number
  pageNum: number
  limit: number
  sort: string[]

  constructor(page: number, pageSize: number, sort: string[]) {
    this.pageNum = page
    this.limit = pageSize
    this.offset = page * pageSize
    this.sort = sort
  }

  static of(page: number, pageSize: number) {
    return new ObservationPage(page, pageSize, [ObservationSortType.RISK.toString()])
  }
}

export class ObservationNextSteps {
  public nextSteps: string[]
}

export class ObservationRiskCalculatorResponse {
  public risk: number
  public riskType: ObservationRiskType
  public riskTypeDependentDueDate: Date
}

export interface ObservationReviewRequest {
  projectId: string
  observationId: string
  reviewComment: string
}

export interface ObservationUpdateStatusRequest {
  projectId: string
  observationId: string
  status: ObservationStatus
}

export interface ObservationCreateRequest extends ObservationCreateUpdateRequestBase {
  projectId: string
  assetIds: any[]
}

export interface ObservationUpdateRequest extends ObservationCreateUpdateRequestBase {
  addedAssetIds: string[]
  removedAssetIds: string[]
}

export interface ObservationCreateUpdateRequestBase {
  tradePartnerId: string
  projectId: string
  assignedUserEmail: string
  status: string
  hazardCategory: string
  identificationMethod: string
  notes: string
  severity: string
  frequency: string
  risk: number
  recommendedAction: string
  actionTaken: string
  reviewComment: string
  dueDate: number
  type: ObservationType
  isGreatCatch: boolean
  filesInProgress?: UploadingFile[]
  customFields?: CustomFieldValue[]
}

export interface ObservationCloseRequest {
  actionTaken: string
}

export interface UploadingFile {
  filename: string
  captureTime: number
}

export class ObservationSearchCriteria {
  dateRangeStart: number
  dateRangeEnd: number
  orTerms: string[]
  andTerms: string[]

  isEmpty() {
    return !(this.dateRangeStart || this.dateRangeEnd || this.andTerms || this.orTerms)
  }

  static empty(): ObservationSearchCriteria {
    return new ObservationSearchCriteria()
  }

  static fromSearchContext(ctx: any): ObservationSearchCriteria {
    let criteria = new ObservationSearchCriteria()
    if (ctx) {
      criteria.dateRangeStart =
        ctx.searchDateRange && ctx.searchDateRange.startDate
          ? new Date(ctx.searchDateRange.startDate).getTime()
          : undefined
      criteria.dateRangeEnd =
        ctx.searchDateRange && ctx.searchDateRange.endDate ? new Date(ctx.searchDateRange.endDate).getTime() : undefined
      criteria.andTerms = ctx.searchQueryType === 'ALL_TERMS' ? ctx.textFragments : undefined
      criteria.orTerms = ctx.searchQueryType === 'ANY_TERMS' ? ctx.textFragments : undefined
    }
    return criteria
  }

  static getSearchContext(criteria: ObservationSearchCriteria): any {
    let dateRange =
      criteria.dateRangeStart && criteria.dateRangeEnd
        ? {
            startDate: moment(criteria.dateRangeStart).format('M/DD/YYYY'),
            endDate: moment(criteria.dateRangeEnd).format('M/DD/YYYY'),
          }
        : undefined

    return ObservationSearchCriteria.getSearchContextFrom(criteria.andTerms ? criteria.andTerms : [], dateRange)
  }

  static getSearchContextFrom(terms: any, dateRange: any): any {
    return {
      textFragments: terms,
      searchDateRange: dateRange,
      searchQueryType: 'ALL_TERMS',
      getSearchByValues: function() {
        let res: any = _.map(this.textFragments, term => {
          return {
            text: term,
            type: 'CHIP_TEXT',
          }
        })
        res.searchDateRange = this.searchDateRange
        res.searchQueryType = this.searchQueryType
        return res
      },
    }
  }
}

export class ObservationFilterCriteriaWithOrganization {
  organizationId: string
  filters: ObservationFilterCriteria
}

export class ObservationFilterCriteria {
  type: ObservationType
  statuses: ObservationStatus[]
  riskTypes: ObservationRiskType[]
  dueDatePeriod: FilterDueDatePeriod
  hazardCategory: string
  identificationMethod: string
  assignedUserId: string
  assignedUser: any
  creatorUserId: string
  creatorUser: any
  tradePartner: TradePartner
  tradePartnerId: string

  // this part of the filter may not be shown to the user and will be empty
  creationTimeTo: number
  creationTimeFrom: number

  static of(
    type?: ObservationType,
    statuses?: ObservationStatus[],
    riskTypes?: ObservationRiskType[],
    dueDatePeriod?: FilterDueDatePeriod,
    hazardCategory?: string,
    identificationMethod?: string,
    assignedUser?: any,
    assignedUserId?: string,
    creatorUser?: any,
    creatorUserId?: string,
    tradePartner?: any,
    tradePartnerId?: string
  ) {
    let ret = new ObservationFilterCriteria()
    ret.type = type
    ret.statuses = statuses
    ret.riskTypes = riskTypes
    ret.dueDatePeriod = dueDatePeriod
    ret.hazardCategory = hazardCategory
    ret.identificationMethod = identificationMethod
    ret.assignedUser = assignedUser ? assignedUser : undefined
    ret.assignedUserId = assignedUserId
    ret.creatorUser = creatorUser ? creatorUser : undefined
    ret.creatorUserId = creatorUserId
    ret.tradePartner = tradePartner
    ret.tradePartnerId = tradePartnerId
    return ret
  }

  static fromObject(other) {
    if (!other) {
      return ObservationFilterCriteria.empty()
    }
    return ObservationFilterCriteria.of(
      other.type ? (ObservationType[other.type] as ObservationType) : undefined,
      (other.statuses || []).map(s => s as ObservationStatus),
      (other.riskTypes || []).map(rt => rt as ObservationRiskType),
      other.dueDatePeriod as FilterDueDatePeriod,
      other.hazardCategory,
      other.identificationMethod,
      other.assignedUser,
      other.assignedUserId,
      other.creatorUser,
      other.creatorUserId,
      other.tradePartner,
      other.tradePartnerId
    )
  }

  isEmpty(): boolean {
    return (
      !this.type &&
      (!this.statuses || !this.statuses.length) &&
      (!this.riskTypes || !this.riskTypes.length) &&
      !this.dueDatePeriod &&
      !this.hazardCategory &&
      !this.identificationMethod &&
      !this.assignedUser &&
      !this.assignedUserId &&
      !this.creatorUser &&
      !this.creatorUserId &&
      !this.tradePartner &&
      !this.tradePartnerId
    )
  }

  static empty(): ObservationFilterCriteria {
    return ObservationFilterCriteria.of()
  }
}

export class ObservationQuickFilterCriteria {
  includeHighRisk = false
  includePastDue = false
  includeMine = false
  includeCreatedByMe = false
  includeClosed = false
  includeIncomplete = false

  static of(
    withHighRisk: boolean,
    withPastDue: boolean,
    withMine: boolean,
    withCreatedByMe: boolean,
    withClosed: boolean
  ) {
    const f = new ObservationQuickFilterCriteria()
    f.includeHighRisk = withHighRisk === true
    f.includePastDue = withPastDue === true
    f.includeMine = withMine === true
    f.includeCreatedByMe = withCreatedByMe === true
    f.includeClosed = withClosed === true
    return f
  }

  static fromObject(filterCriteria) {
    const f = new ObservationQuickFilterCriteria()
    f.includeHighRisk = filterCriteria.includeHighRisk === true
    f.includePastDue = filterCriteria.includePastDue === true
    f.includeMine = filterCriteria.includeMine === true
    f.includeCreatedByMe = filterCriteria.includeCreatedByMe === true
    f.includeClosed = filterCriteria.includeClosed === true
    return f
  }

  static empty() {
    return new ObservationQuickFilterCriteria()
  }

  toUrlParams(): string {
    return `includeHighRisk=${this.includeHighRisk}&includePastDue=${this.includePastDue}&includeMine=${this.includeMine}&includeCreatedByMe=${this.includeCreatedByMe}&includeClosed=${this.includeClosed}`
  }
}

export class ObservationSearchRequestBase {
  organizationId: string
  // Keeping projectId and projectGroupId for backward API compatibility
  projectGroupId: string
  projectId: string

  projectGroupIds: string[]
  projectIds: string[]

  searchType: ObservationSearchType
  searchCriteria: ObservationSearchCriteria
  quickFilterCriteria: ObservationQuickFilterCriteria
  filterCriteria: ObservationFilterCriteria
}

export class ObservationSearchRequest extends ObservationSearchRequestBase {
  offset: number
  limit: number
  sortColumns: string[]
  details: ObservationDetails[]
}

export class ObservationSearchCountRequest extends ObservationSearchRequestBase {
  fieldsForCounts: string[]
}

export class ObservationSearchCountResponse {
  countsPerProjectPerField: Map<string, Map<string, number>>
  totalCountsPerField: Map<string, number>
  grandTotal: number
}

export const MAX_OBSERVATIONS_PER_REPORT = 200

export class ObservationPhotoReportRequest extends ObservationSearchRequestBase {
  reportScope: ObservationReportProjectScope
  recipientEmails: string[]
  sorts: ObservationPhotoReportSortType[]
  reportTitle: string
}

export enum ObservationPhotoReportSortType {
  PROJECT_NAME = 'PROJECT_NAME',
  RISK_SCORE = 'RISK_SCORE',
  CREATED_DATE = 'CREATED_DATE',
  CREATED_BY = 'CREATED_BY',
}
