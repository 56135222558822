import _ from 'lodash'
import { IHttpPromise } from 'angular'
import { from, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

/**
 * @author vseletskyi
 */
export function toObservable<T>(promise: IHttpPromise<T>, type: new () => T): Observable<T> {
  return from(promise).pipe(
    map(resp => {
      let ret = new type()
      Object.assign(ret, resp.data)
      return ret
    })
  )
}

export function toObservableArray<T>(promise: IHttpPromise<T[]>, type: new () => T): Observable<T[]> {
  return from(promise).pipe(
    map(resp => {
      return _.map(resp.data, data => {
        let ret = new type()
        Object.assign(ret, data)
        return ret
      })
    })
  )
}

export function toNumberObservable(promise: IHttpPromise<number>): Observable<number> {
  return from(promise).pipe(
    map(resp => {
      return resp.data
    })
  )
}
