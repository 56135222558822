/* global angular */

angular.module('smartvid').service('bim360FieldIntegrationApi', function ($http, $q, $log, config, currentUser) {
  let rootUrl = config.env.development.apiRootUrl

  let api = {
    listBim360FieldProjects (projectId, includeInactive, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/bim360/field/project?includeInactive=${includeInactive}`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('listBim360FieldProjects', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    loginInToBim360Field (userLogin, password, selectedRegion, customErrorHandler) {
      let url = `${rootUrl}/api/integration/bim360/login`
      let defer = $q.defer()

      let payload = {
        userId: currentUser.id,
        bim360UserId: userLogin,
        password: password,
        bim360FieldApiRegion: selectedRegion
      }

      $http.post(url, payload, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('loginInToBim360Field', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    }
  }

  return api
})
