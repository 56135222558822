/* global angular */
angular.module('smartvid').service('templatePickService', function ($q, $injector) {
  let _projectViewStates = window.localStorage.getItem('_projectViewStates')
  _projectViewStates = _projectViewStates ? JSON.parse(_projectViewStates) : undefined
  if (!angular.isDefined(_projectViewStates)) {
    const views = { currentView: 'GRID_VIEW', nextView: 'LIST_VIEW' }
    _projectViewStates = views
    window.localStorage.setItem('_projectViewStates', JSON.stringify(views))
  }

  this.pickTemplate = pickTemplate
  this.setProjectCurrentView = setProjectCurrentView
  this.setProjectNextView = setProjectNextView
  this.getProjectCurrentView = getProjectCurrentView
  this.getProjectNextView = getProjectNextView
  this.isGridView = isGridView

  function setProjectCurrentView (projectCurrentView) {
    _projectViewStates.currentView = projectCurrentView
    window.localStorage.setItem('_projectViewStates', JSON.stringify(_projectViewStates))
  }

  function setProjectNextView (projectNextView) {
    _projectViewStates.nextView = projectNextView
    window.localStorage.setItem('_projectViewStates', JSON.stringify(_projectViewStates))
  }

  function getProjectCurrentView () {
    return _projectViewStates.currentView
  }

  function getProjectNextView () {
    return _projectViewStates.nextView
  }

  function isGridView () {
    return this.getProjectCurrentView() === 'GRID_VIEW'
  }

  function pickTemplate () {
    const searchResultService = $injector.get('searchResultService')
    const contentSharingContext = $injector.get('contentSharingContext')
    const videoGrid = '<video-grid></video-grid>'
    const projectGrid = '<project-grid></project-grid>'
    if (searchResultService.isInGlobalSearchContext() || contentSharingContext.isGlobalSharingContext()) {
      return videoGrid
    } else {
      return projectGrid
    }
  }
})
