/* global angular _ */

angular.module('smartvid').factory('ReportScheduleDataModel', function (BaseModel, $filter) {
  class ReportScheduleDataModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        reportName: undefined,
        createdBy: undefined,
        toUserEmails: [],
        startDate: undefined,
        endDate: undefined,
        reportFixedScheduleInterval: 'NEVER',
        reportFixedScheduleIntervalLabel: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }

    getScheduleIntervalLabel () {
      return $filter('i18next')('reporting.schedule.' + this.reportFixedScheduleInterval)
    }
  }

  return ReportScheduleDataModel
})
