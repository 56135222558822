<mat-spinner class="dashboard-spinner" diameter="128" *ngIf="false"></mat-spinner>

<div class="report-options-container">
    <div class="report-option-container">
        <div class="icon-container">
            <mat-icon class="icon fill-grey safety-report-icon"
                      svgIcon="summary-reports"></mat-icon>
        </div>
        <div class="report-option-wrapper">
            <div class="text-container">
                <p class="choose-report-title">{{ "reporting.safetyReport" | translate }}</p>
                <p class="choose-report-description">{{ "reporting.safetyReportDescription" | translate }}</p>
            </div>
            <button type="button"
                    (click)="selectSummaryReportType()"
                    class="clickable btn btn-primary report-option-btn">
                {{ 'reporting.safetyReportButton' | translate }}
            </button>
        </div>
    </div>
    <div class="report-option-container">
        <div class="icon-container">
            <mat-icon class="icon fill-grey photo-report-icon" svgIcon="photo-reports"></mat-icon>
        </div>
        <div class="report-option-wrapper">
            <div class="text-container">
                <p class="choose-report-title">{{ "reporting.photoReport" | translate }}</p>
                <p class="choose-report-description">{{ "reporting.photoReportDescription" | translate }}</p>
            </div>
            <button type="button"
                    (click)="selectPhotoReportType()"
                    class="clickable btn btn-primary report-option-btn">
                {{ 'reporting.photoReportButton' | translate }}
            </button>
        </div>
    </div>
</div>

<mat-tab-group>
    <mat-tab label="{{ 'reporting.reportType.' + reportTypesAdapter.DAILY | translate }}">
        <ng-template matTabContent>
            <div class="tab-content mdc-layout-grid">
                <sv-reports-table [organizationId]="organizationId" [reportType]="reportTypesAdapter.DAILY"></sv-reports-table>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'reporting.reportType.' + reportTypesAdapter.WEEKLY | translate }}">
        <ng-template matTabContent>
            <div class="tab-content mdc-layout-grid">
                <sv-reports-table [organizationId]="organizationId" [reportType]="reportTypesAdapter.WEEKLY"></sv-reports-table>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'reporting.reportType.' + reportTypesAdapter.MONTHLY | translate }}">
        <ng-template matTabContent>
            <div class="tab-content mdc-layout-grid">
                <sv-reports-table [organizationId]="organizationId" [reportType]="reportTypesAdapter.MONTHLY"></sv-reports-table>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'reporting.reportType.' + reportTypesAdapter.NEVER | translate }}">
        <ng-template matTabContent>
            <div class="tab-content mdc-layout-grid">
                <sv-reports-table [organizationId]="organizationId" [reportType]="reportTypesAdapter.NEVER"></sv-reports-table>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>
