/* global angular, _ */

angular.module('smartvid').factory('MediaModel', function (BaseModel) {
  class MediaModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        accessUrl: undefined,
        contentType: undefined,
        type: undefined,
        organizationId: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return MediaModel
})
