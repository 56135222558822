/* global angular, _ */

angular.module('smartvid').factory('OrganizationModel', function (BaseModel) {
  class OrganizationModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        name: undefined,
        role: undefined,
        thumbnailUrl: undefined,
        logoUrl: undefined,
        signUpMode: undefined,
        selected: false,
        id: undefined,
        emailDomains: undefined,
        ssoLoginUrl: undefined,
        canManageProjects: false,
        canMoveAssets: false,
        isOrganizationInactive: undefined,
        hasAssetInsights: false,
        hasObservationInsights: false
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return OrganizationModel
})
