<div class="organization-assets-insights-dashboard-activity mdc-layout-grid__inner">
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'ORGANIZATION_ASSETS_ACTIVITY_BY_GROUP_PROJECT' }">
    <sv-insights-card
      #activityByGroupOrProjectCard
      contentHeight="400px"
      cardTitle="{{ cardTitle }}"
      subTitle="{{ activityTableData?.length }} {{ cardSubTitle }}"
      class="organization-activity-table-card"
      hasTextInput="true"
      [toggleMenuGroups]="activityByGroupOrProjectTableMenu"
      (toggleMenuItemSelected)="activityByGroupOrProjectMenuSelected($event, activityByGroupOrProjectCard)"
      textInputPlaceholder="{{ filterInputPlaceholder }}"
      (cardInputChange)="activityTableFilterChanged($event, groupOrProjectActivityTable)"
    >
      <sv-organization-assets-activity-table
        #groupOrProjectActivityTable
        [tableData]="activityTableData"
      ></sv-organization-assets-activity-table>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'ORGANIZATION_ASSETS_UPLOADS_OVERTIME_BY_SOURCE' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgUploadsOverTimeBySource.title' | translate }}"
      subTitle="{{ organizationUploadsOverTimeBySourceData?.startDate.toDateString() }} - {{
        organizationUploadsOverTimeBySourceData?.endDate.toDateString()
      }}"
      [toggleMenuGroups]="uploadsBySourceChartMenu"
      (toggleMenuItemSelected)="uploadsBySourceChartMenuSelected($event)"
    >
      <sv-insights-column-chart
        [chartData]="organizationUploadsOverTimeBySourceData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'ORGANIZATION_ASSETS_UPLOADERS_OVERTIME' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgUploadersCountOverTime.title' | translate }}"
      subTitle="{{ organizationUploadersOverTimeData?.startDate.toDateString() }} - {{
        organizationUploadersOverTimeData?.endDate?.toDateString()
      }}"
      [toggleMenuGroups]="uploadersChartMenu"
      (toggleMenuItemSelected)="uploadersChartMenuSelected($event)"
    >
      <sv-insights-column-chart
        [chartData]="organizationUploadersOverTimeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'ORGANIZATION_ASSETS_CONSTRUCTION_ACTIVITY' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgConstructionActivity.title' | translate }}"
      subTitle="{{ organizationConstructionActivityData?.startDate.toDateString() }} - {{
        organizationConstructionActivityData?.endDate.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="organizationConstructionActivityData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'ORGANIZATION_ASSETS_PHOTO_DESCRIPTION' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgPhotoDescription.title' | translate }}"
      subTitle="{{ organizationPhotoDescriptionData?.startDate.toDateString() }} - {{
        organizationPhotoDescriptionData?.endDate.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="organizationPhotoDescriptionData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'ORGANIZATION_ASSETS_UPLOADS_BY_FILE_TYPE' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgUploadsByFileType.title' | translate }}"
      subTitle="{{ organizationUploadsByFileTypeData?.startDate.toDateString() }} - {{
        organizationUploadsByFileTypeData?.endDate.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="organizationUploadsByFileTypeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'ACTIVITY', cardId: 'ORGANIZATION_ASSETS_TAGS_BY_TYPE' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.orgTagsByType.title' | translate }}"
      subTitle="{{ organizationTagsByTypeData?.startDate.toDateString() }} - {{
        organizationTagsByTypeData?.endDate.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="organizationTagsByTypeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
</div>
