/* global angular */

angular.module('smartvid').controller('UserInactiveCtrl', function ($scope, $stateParams, $filter, smartvidApi, Notification, $state) {
  $scope.errorLabel = $state.current.name
  $scope.inTransit

  $scope.sendRegisterNewUserEmail = () => {
    if (!$scope.inTransit) {
      $scope.inTransit = true
      smartvidApi.sendRegisterNewUserEmail($stateParams.email).then(function () {
        $scope.inTransit = false
      }, function (response) {
        $scope.inTransit = false
      }).catch(function () {
      })
    }
  }

  $scope.sendWelcomeNewUserEmail = () => {
    if (!$scope.inTransit) {
      $scope.inTransit = true
      smartvidApi.sendWelcomeNewUserEmail($stateParams.email).then(function () {
        Notification.success($filter('i18next')('welcome.invitation_resent'))
        $scope.inTransit = false
      }, function (response) {
        $scope.inTransit = false
      }).catch(function () {
      })
    }
  }
})
