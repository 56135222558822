/* global angular, _ */

angular.module('smartvid').directive('breadcrumbs', (
    currentUser, $rootScope, $state, $timeout, utils, $stateParams, searchResultService, contentSharingContext,
    secretSlideshowHelper, dashboardDataHelper
) => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'breadcrumbs.html',
    link (scope, el) {
      scope.organization = getOrganization()

      scope.isIconDisabled = isIconDisabled
      scope.back = back
      scope.showOrgName = showOrgName
      scope.getProjectGroupName = getProjectGroupName
      scope.showProjectName = showProjectName
      scope.isPreviousStateObsViewer = isPreviousStateObsViewer
      scope.getObservationExternalId = getObservationExternalId
      scope.showProjectGroupName = showProjectGroupName
      scope.showUserInfo = showUserInfo
      scope.showCurrentAdminOrgInfo = showCurrentAdminOrgInfo
      scope.showMyProfileInfo = showMyProfileInfo
      scope.showAllProjects = showAllProjects
      scope.showCurrentOrgInfo = showCurrentOrgInfo
      scope.observationAccessFromState = undefined
      scope.observationAccessFromStateParams = undefined
      scope.fromState = undefined
      scope.fromParams = undefined

      scope.$on('$stateChangeStart', onStateChange)

      function onStateChange (event, toState, toParams, fromState, fromParams) {
        //If going from the same state to the same state like going through asset carousel or going throw observations
        // don't overwrite fromState.
        if (!scope.fromState || (fromState.name !== toState.name && fromState.name !== 'dashboard.analytics')) {
          scope.fromState = fromState
          scope.fromParams = fromParams
        }

        if (stateIncludesOrgId(toState)) {
          scope.organization = currentUser.getOrganization(toParams.organizationId)
          return
        }

        scope.organization = currentUser.defaultOrganization(true)
        let projectChanged = $state.includes('dashboard.projects.projectId') && (scope.currentProject && scope.currentProject.id !== toParams.projectId)
        let fromNoProjectToProject = !fromParams.projectId && toParams.projectId

        if (projectChanged || fromNoProjectToProject) {
          scope.projectChanged = true
        }

        if (fromParams.userId !== toParams.userId) {
          scope.userChanged = true
        } else if (fromParams.organizationId !== toParams.organizationId) {
          scope.organizationChanged = true
        }

        let removeAfterTransition = () => {
          scope.projectChanged = false
          scope.organizationChanged = false
          scope.userChanged = false
          utils.digest(scope)
        }

        if (scope.projectChanged || scope.organizationChanged || scope.userChanged) {
          $timeout(() => {
            removeAfterTransition()
          }, 800)
        }
      }

      function getOrganization () {
        const organizationId = $stateParams.organizationId
        return organizationId ? currentUser.getOrganization(organizationId) : currentUser.defaultOrganization(true)
      }

      function isSecretSlideshowActive () {
        return !!$rootScope.secretSlidshowActive
      }

      function showOrgName () {
        return ($state.current.name === 'dashboard.projects' || $state.current.name === 'dashboard.adminOrganizations' ||
          $state.current.name === 'dashboard.observations') &&
          !searchResultService.isInSearchContext()
      }

      function showProjectName () {
        let integrationsState = 'dashboard.projects.projectId.integrations'
        let memberState = 'dashboard.projects.projectId.members'
        let viewerState = 'dashboard.projects.viewer'
        let projectIdStates = ['dashboard.observations.orgId.projectGroupId.projectId',
          'dashboard.observations.orgId.projectGroupId.projectId.viewer',
          'dashboard.observations.orgId.projectId',
          'dashboard.projects.projectId.files',
          'dashboard.projects.projectId.files.viewer',
          'dashboard.projects.projectId.insights',
          'dashboard.projects.projectId.tagsmanager',
          'dashboard.projects.projectId.deletedfiles',
          'dashboard.projects.projectId.profile'
        ]
        return projectIdStates.includes($state.current.name) ||
          $state.current.name.startsWith(integrationsState) ||
          $state.current.name.startsWith(memberState) ||
          $state.current.name.startsWith(viewerState)
      }

      function getProjectGroupName () {
        const organization = getOrganization()
        const group = organization && organization.projectGroups.find((group) => group.id === $stateParams.projectGroupId)
        return group ? group.name : '';
      }

      function showProjectGroupName () {
        let projectGroupIdStates = ['dashboard.observations.orgId.projectGroupId',
          'dashboard.projects.orgId.projectGroupId']
        return projectGroupIdStates.includes($state.current.name)
      }
      function showUserInfo () {
        return $state.current.name.startsWith('dashboard.adminOrganizations.organizationId.userProfile')
      }

      function showCurrentOrgInfo () {
        return ($state.current.name.startsWith('dashboard.observations.orgId') ||
            $state.current.name.startsWith('dashboard.projects.orgId') ||
            $state.current.name.startsWith('dashboard.analytics.orgId')
          )  && !$state.current.name.startsWith('dashboard.observations.orgId.projectGroupId.projectId')
      }

      function showCurrentAdminOrgInfo () {
        return $state.current.name.startsWith('dashboard.adminOrganizations') && $state.current.name !== 'dashboard.adminOrganizations.organizationId.userProfile'
      }

      function showMyProfileInfo () {
        return $state.current.name === 'dashboard.myprofile' || $state.current.name === 'dashboard.mysettings'
      }

      function showAllProjects () {
        return $state.current.name === 'dashboard.projects' && searchResultService.isInSearchContext() && !searchResultService.isSearchSharing()
      }

      function isIconDisabled () {
        let crossProjectSearchSharing = searchResultService.isInGlobalSearchContext() && searchResultService.isSearchSharing()
        if ($state.current.name === 'dashboard.observations') {
          return true
        }
        if ($state.current.name === 'dashboard.projects' && !searchResultService.isInGlobalSearchContext()) {
          return true
        }
        if ($state.current.name === 'dashboard.projects' && crossProjectSearchSharing) {
          return true
        }
        if ($state.current.name === 'dashboard.adminOrganizations') {
          return true
        }
        if ($state.current.name === 'dashboard.projects.projectId.files' && contentSharingContext.isSet() &&
            !(searchResultService.isInGlobalSearchContext() || contentSharingContext.isGlobalSharingContext())) {
          return true
        }
        return false
      }

     function isEqualStates (toState, fromState) {
       return toState.name === 'dashboard.observations.orgId.projectGroupId.projectId.viewer' && fromState.name === 'dashboard.observations.orgId.projectGroupId.projectId.viewer';
     }

      function stateIncludesOrgId (toState) {
        return toState.name === 'dashboard.observations.orgId' ||
          toState.name === 'dashboard.observations.orgId.projectGroupId' ||
          toState.name === 'dashboard.projects.orgId' ||
          toState.name === 'dashboard.projects.orgId.projectGroupId' ||
          toState.name === 'dashboard.analytics.orgId'
      }

      /**
       * Navigation is pretty fixed so we will always know where to go back to - without the browser history api
       * which gets erased on refresh.
       */
      function back () {
        // The current state name and the back-to route
        if (scope.isIconDisabled()) {
          return
        }

        // if the slideshow is active, back button should cancel
        if (isSecretSlideshowActive()) {
          secretSlideshowHelper.endSlideshow()
          return
        }
        let currentState = $state.current.name
        let isGlobalSearch = searchResultService.isInGlobalSearchContext()
        let currentProject = dashboardDataHelper.getCurrentProject()

        //If at the top level of the search and click back then cancel search.
        if (currentState === 'dashboard.projects' && isGlobalSearch) {
          scope.clearSearch()
          return
        }
        //If inside the project in the asset grid then go back to the group level or org level depending where the project is.
        if (currentState === 'dashboard.projects.projectId.files') {

          if (currentProject.projectGroupId) {
            $state.go('dashboard.projects.orgId.projectGroupId', {
                organizationId: currentProject.organizationId,
                projectGroupId: currentProject.projectGroupId
            })
          } else {
            $state.go('dashboard.projects.orgId', {
              organizationId: currentProject.organizationId
            })
          }
          return
        }
        //If inside of a cross project search and in the viewer then go back to where you came from.
        if (currentState === 'dashboard.projects.viewer' && isGlobalSearch && scope.fromState) {
          $state.go(scope.fromState.name, scope.fromParams)
          return
        }

        //If inside of the observation viewer go back to where it was clicked from (all/org/region level)
        if (currentState === 'dashboard.observations.orgId.projectGroupId.projectId.viewer' && scope.fromState || isPreviousStateObsViewer()) {
          let modifiedParams = _.extend({}, scope.fromParams)
          modifiedParams.forceShowList = true
          if ($stateParams.obsExternalId) {
            modifiedParams.mediaReviewerAssetId = $stateParams.mediaReviewerAssetId
            $state.go(scope.fromState.name, modifiedParams)
          } else {
            $state.go(scope.fromState.name, modifiedParams)
          }

          return
        }

        if (currentState === 'dashboard.analytics.orgId') {
          $state.go(scope.fromState.name, scope.fromParams)
          return
        }

        $state.go('^')
      }

      function isPreviousStateObsViewer () {
        return scope.fromState && scope.fromState.name === 'dashboard.observations.orgId.projectGroupId.projectId.viewer'
      }
      function getObservationExternalId () {
        return $stateParams.obsExternalId ? ' - SO-' + $stateParams.obsExternalId : ''
      }
    }
  }
})
