<div class="project-table-container">
  <mat-spinner class="table-spinner" diameter="64" *ngIf="!tableData"></mat-spinner>
  <table
    mat-table
    [dataSource]="dataSource"
    class="project-totals-table"
    *ngIf="tableData"
    (mousewheel)="onMouseWheel($event)"
  >
    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef>{{ 'dashboard.projectAssets.activityTable.columns.name' | translate }}</th>
      <td mat-cell *matCellDef="let row" class="td-name">
        <div class="avatar">
          <span class="initials">{{ getInitials(row.userInfo) }}</span>
        </div>
        <div class="name-cell">
          <p class="mat-body-2 title-text">{{ row.userInfo.firstName }} {{ row.userInfo.lastName }}</p>
          <p class="mat-caption subtitle-text">
            {{ row.userInfo.isExternalUser ? row.userInfo.integrationType : row.userInfo.roleName }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="files">
      <th mat-header-cell *matHeaderCellDef>{{ 'dashboard.projectAssets.activityTable.columns.files' | translate }}</th>
      <td mat-cell *matCellDef="let row" class="td-other">
        <div>
          <p class="mat-body-2 title-text">
            {{
              isNA(row.countsInfo.userFilesCount)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.countsInfo.userFilesCount | number)
            }}
          </p>
          <p
            class="mat-caption subtitle-text"
            [ngClass]="getColorClassForValue(row.countsInfo.userFilesCountRatio, [0, 0.0001])"
          >
            {{ getSign(row.countsInfo.userFilesCountRatio)
            }}{{
              isNA(row.countsInfo.userFilesCount)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.countsInfo.userFilesCountRatio | number: '.1-1') + '%'
            }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef>{{ 'dashboard.projectAssets.activityTable.columns.tags' | translate }}</th>
      <td mat-cell *matCellDef="let row" class="td-other">
        <div>
          <p class="mat-body-2 title-text">
            {{
              isNA(row.countsInfo.userTagCount)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.countsInfo.userTagCount | number)
            }}
          </p>
          <p
            class="mat-caption subtitle-text"
            [ngClass]="getColorClassForValue(row.countsInfo.userTagCountRatio, [0, 0.0001])"
          >
            {{ getSign(row.countsInfo.userTagCountRatio)
            }}{{
              isNA(row.countsInfo.userTagCount)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.countsInfo.userTagCountRatio | number: '.1-1') + '%'
            }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'dashboard.projectAssets.activityTable.columns.comments' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-other">
        <div>
          <p class="mat-body-2 title-text">
            {{
              isNA(row.countsInfo.userCommentsCount)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.countsInfo.userCommentsCount | number)
            }}
          </p>
          <p
            class="mat-caption subtitle-text"
            [ngClass]="getColorClassForValue(row.countsInfo.userCommentsCountRatio, [0, 0.0001])"
          >
            {{ getSign(row.countsInfo.userCommentsCountRatio)
            }}{{
              isNA(row.countsInfo.userCommentsCount)
                ? ('dashboard.valueNotAvailable' | translate)
                : (row.countsInfo.userCommentsCountRatio | number: '.1-1') + '%'
            }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="assetCountsForTimePeriod">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'dashboard.projectAssets.activityTable.columns.fileUploads' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-chart">
        <div class="chart-cell">
          <sv-insights-area-chart [chartData]="row.chartData" [isResponsive]="false"></sv-insights-area-chart>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="viewDetails" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="td-view-button">
        <div class="view-button-wrapper">
          <button
            type="button"
            [disabled]="row.userInfo.isExternalUser"
            mat-stroked-button
            (click)="showUserFiles(row.userInfo)"
          >
            {{ 'dashboard.projectAssets.activityTable.buttons.view' | translate }}
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div [hidden]="!tableData" class="totals-table-paginator">
  <mat-paginator [pageSizeOptions]="[4]" showFirstLastButtons hidePageSize></mat-paginator>
</div>
