/* global angular _ */

angular.module('smartvid').factory('Bim360FieldUserModel', function (BaseModel) {
  class Bim360FieldUserModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined,
        first_name: undefined,
        last_name: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return Bim360FieldUserModel
})
