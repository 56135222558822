/* global _ angular analytics */

import {ModuleType} from 'shared/models/module-type'

angular.module('smartvid').component('mySettings', {
  templateUrl: 'mysettings.html',
  bindings: {
    allProjects: '<',
    allOrganizations: '<',
    projectSettings: '<',
    organizationSettings: '<'
  },
  controller: function (
    $filter,
    $timeout,
    $state,
    $rootScope,
    Notification,
    currentUser,
    smartvidApi,
    projectCopyService
  ) {
    let ctrl = this
    let allProjectNotificationsSelected = false
    let allOrganizationNotificationsSelected = false
    let showInactiveProjectsSelected = false

    ctrl.inTransit = false
    ctrl.hasObservations = false
    ctrl.projectsWithSettings = []
    ctrl.organizationsCanRecieveNotifications = []
    ctrl.organizationsPushNotificationSettings = []
    ctrl.organizationsPushNotificationsEventGroups = []
    ctrl.organizationsShowInactiveProjects = []
    ctrl.selectedOrg = null
    ctrl.allOrgs = null
    ctrl.selectedHomePage = null
    ctrl.homePageOptions = null
    ctrl.$rootScope = $rootScope

    ctrl.$onInit = onInit
    ctrl.isFetching = isFetching
    ctrl.updateSettings = updateSettings
    ctrl.copySampleProject = copySampleProject
    ctrl.selectAllProjectNotifications = selectAllProjectNotifications
    ctrl.selectAllOrganizationNotifications = selectAllOrganizationNotifications
    ctrl.selectAllPushNotifications = selectAllPushNotifications
    ctrl.selectShowInactiveProjects = selectShowInactiveProjects
    ctrl.validateSelectAllFor = validateSelectAllFor

    function onInit () {
      ctrl.hasObservations = currentUser.isObservationFeatureEnabledForAnyOrganization()
      ctrl.allProjects.projectPromise.then(() => {
        let projects = _.filter(ctrl.allProjects.models, p => {
          return p.canReceiveUploadNotification
        })
        let projectToSettings = _.indexBy(ctrl.projectSettings, 'projectId')
        ctrl.projectsWithSettings = _.map(projects, project => {
          let settings = projectToSettings[project.id]
          let previousValue = settings ? settings.isUploadNotification : false
          return {
            project: project,
            isUploadNotificationPreviousValue: previousValue,
            isUploadNotification: previousValue
          }
        })

        allProjectNotificationsSelected = _.every(ctrl.projectsWithSettings, p => p.isUploadNotification)
      })

      let canReceiveNotificationOrganizations = _.filter(ctrl.allOrganizations.models, o => {
        return o.canReceiveProjectCreationNotification
      })
      let organizationToSettings = _.indexBy(ctrl.organizationSettings, 'organizationId')
      ctrl.organizationsCanRecieveNotifications = _.map(canReceiveNotificationOrganizations, organization => {
        let settings = organizationToSettings[organization.id]
        let previousValue = settings ? settings.isProjectCreationNotification : false
        return {
          organization: organization,
          isProjectCreationNotificationPreviousValue: previousValue,
          isProjectCreationNotification: previousValue
        }
      })
      allOrganizationNotificationsSelected = _.every(
        ctrl.organizationsCanRecieveNotifications,
        p => p.isProjectCreationNotification
      )

      let showInactiveProjectsOrganizations = _.filter(ctrl.allOrganizations.models, o => {
        return o.role !== 'GUEST'
      })
      ctrl.organizationsShowInactiveProjects = _.map(showInactiveProjectsOrganizations, organization => {
        let settings = organizationToSettings[organization.id]
        let showInactiveProjectsPreviousValue = settings ? settings.showInactiveProjects : false
        return {
          organization: organization,
          showInactiveProjects: showInactiveProjectsPreviousValue,
          showInactiveProjectsPreviousValue: showInactiveProjectsPreviousValue
        }
      })

      showInactiveProjectsSelected = _.every(ctrl.organizationsShowInactiveProjects, ows => ows.showInactiveProjects)

      ctrl.organizationsPushNotificationSettings = _.map(ctrl.allOrganizations.models, organization => {
        let settings = organizationToSettings[organization.id]
        return {
          organization: organization,
          pushNotificationEventsSettings: settings.pushNotificationEventsSettings
        }
      })

      let groupSelectAllTracker = {}
      ctrl.organizationsPushNotificationsTableHeaderData = _.uniq(
        _.flatten(
          _.map(ctrl.organizationSettings, settings => {
            return _.map(settings.pushNotificationEventsSettings, pn => {
              if (groupSelectAllTracker[pn.eventGroup] === undefined) {
                groupSelectAllTracker[pn.eventGroup] = true
              }
              groupSelectAllTracker[pn.eventGroup] =
                groupSelectAllTracker[pn.eventGroup] && areAllEventTypesEnabled(pn.eventTypeSettings)
              return {
                checkAll: false,
                eventGroup: pn.eventGroup
              }
            })
          })
        ),
        'eventGroup'
      )

      ctrl.organizationsPushNotificationsTableHeaderData.forEach(headerData => {
        headerData.checkAll = groupSelectAllTracker[headerData.eventGroup]
      })

      ctrl.selectedOrg = currentUser.defaultOrganization(false)
      if (!ctrl.selectedOrg) {
        let defaultOrg = {
          name: $filter('i18next')('directives.mysettings.selectOrg')
        }
        ctrl.selectedOrg = defaultOrg
        ctrl.allOrgs = currentUser.organizations.slice(0)
        ctrl.allOrgs.unshift(defaultOrg)
      } else {
        ctrl.allOrgs = currentUser.organizations
      }
      ctrl.selectedHomePage = {
        value: currentUser.userPreferences.defaultHomePage || ModuleType.ASSETS
      }
      ctrl.homePageOptions = [
        {
          name: 'Projects',
          value: ModuleType.ASSETS
        },
        {
          name: 'Observations',
          value: ModuleType.OBSERVATIONS
        },
        {
          name: 'Analytics',
          value: ModuleType.ANALYTICS
        }
      ]
    }

    function isFetching () {
      return ctrl.allProjects.isFetching
    }

    function updateSettings () {
      analytics.track('Update User Settings', {
        category: 'User Action'
      })
      ctrl.inTransit = true
      let projectsNotificationSettings = _.map(
        _.filter(ctrl.projectsWithSettings, e => {
          return e.isUploadNotification !== e.isUploadNotificationPreviousValue
        }),
        e => {
          return {
            projectId: e.project.id,
            isUploadNotification: e.isUploadNotification
          }
        }
      )
      let organizationsNotificationSettings = _.map(
        _.filter(ctrl.organizationsCanRecieveNotifications, e => {
          return e.isProjectCreationNotification !== e.isProjectCreationNotificationPreviousValue
        }),
        e => {
          let first = _.find(ctrl.organizationsShowInactiveProjects, s => s.organization.id === e.organization.id)
          return {
            organizationId: e.organization.id,
            isProjectCreationNotification: e.isProjectCreationNotification,
            showInactiveProjects: first ? first.showInactiveProjects : false
          }
        }
      )
      let organizationsInactiveProjectsSettings = _.map(
        _.filter(ctrl.organizationsShowInactiveProjects, e => {
          return e.showInactiveProjects !== e.showInactiveProjectsPreviousValue
        }),
        e => {
          let first = _.find(ctrl.organizationsCanRecieveNotifications, s => s.organization.id === e.organization.id)
          return {
            organizationId: e.organization.id,
            isProjectCreationNotification: first ? first.isProjectCreationNotification : false,
            showInactiveProjects: e.showInactiveProjects
          }
        }
      )

      if (!ctrl.selectedOrg.id) {
        Notification.error($filter('i18next')('directives.mysettings.primaryOrgError'))
      } else {
        let settings = _.unique(
          _.union(organizationsNotificationSettings, organizationsInactiveProjectsSettings),
          o => {
            return o.organizationId
          }
        )
        // fill in push notifications part
        let pushNotificationSettings = []
        ctrl.organizationsPushNotificationSettings.forEach(settings => {
          settings.pushNotificationEventsSettings.forEach(notificationEventGroup => {
            notificationEventGroup.eventTypeSettings.forEach(notificationEventType => {
              pushNotificationSettings.push({
                id: notificationEventType.id,
                organizationId: settings.organization.id,
                eventGroup: notificationEventGroup.eventGroup,
                eventType: notificationEventType.eventType,
                isEnabled: notificationEventType.isEnabled
              })
            })
          })
        })

        let promise = smartvidApi.updateCurrentUser(
          null,
          null,
          ctrl.selectedOrg.id,
          ctrl.selectedHomePage.value,
          projectsNotificationSettings,
          settings,
          pushNotificationSettings
        )
        promise.then(
          data => {
            $state.reload()
          },
          () => {
            Notification.error($filter('i18next')('directives.mysettings.failedSettings'))
          }
        )
      }
      $timeout(function () {
        ctrl.inTransit = false
      }, 200)
    }

    function copySampleProject () {
      analytics.track('Update User Settings', {
        category: 'Copy Sample Project'
      })
      $rootScope.copyProjectInProgress = true
      let promise = smartvidApi.copySampleProject()
      promise.then(
        data => {
          projectCopyService.startPollingForProjectCopyCompletion(data, true)
        },
        () => {
          Notification.error($filter('i18next')('directives.mysettings.failedCopySampleProject'))
        }
      )
    }

    function selectAllProjectNotifications (checked) {
      if (!arguments.length) {
        allProjectNotificationsSelected = _.every(ctrl.projectsWithSettings, p => p.isUploadNotification)
        return allProjectNotificationsSelected
      }

      _.forEach(ctrl.projectsWithSettings || [], projectsWithSetting => {
        projectsWithSetting.isUploadNotification = checked
      })
      allProjectNotificationsSelected = _.every(ctrl.projectsWithSettings, p => p.isUploadNotification)
      return allProjectNotificationsSelected
    }

    function selectAllOrganizationNotifications (checked) {
      if (!arguments.length) {
        allOrganizationNotificationsSelected = _.every(
          ctrl.organizationsCanRecieveNotifications,
          o => o.isProjectCreationNotification
        )
        return allOrganizationNotificationsSelected
      }

      _.forEach(ctrl.organizationsCanRecieveNotifications || [], organizationWithSetting => {
        organizationWithSetting.isProjectCreationNotification = checked
      })
      allOrganizationNotificationsSelected = _.every(
        ctrl.organizationsCanRecieveNotifications,
        o => o.isProjectCreationNotification
      )
      return allOrganizationNotificationsSelected
    }

    function selectAllPushNotifications (eventGroupCheckAllData) {
      ctrl.organizationsPushNotificationSettings.forEach(setting => {
        setting.pushNotificationEventsSettings.forEach(pn => {
          if (pn.eventGroup === eventGroupCheckAllData.eventGroup) {
            pn.eventTypeSettings.forEach(eventType => {
              eventType.isEnabled = eventGroupCheckAllData.checkAll
            })
          }
        })
      })
    }

    function selectShowInactiveProjects (checked) {
      if (!arguments.length) {
        showInactiveProjectsSelected = _.every(ctrl.organizationsShowInactiveProjects, o => o.showInactiveProjects)
        return showInactiveProjectsSelected
      }
      _.forEach(ctrl.organizationsShowInactiveProjects || [], organizationWithSetting => {
        organizationWithSetting.showInactiveProjects = checked
      })
      showInactiveProjectsSelected = _.every(ctrl.organizationsShowInactiveProjects, o => o.showInactiveProjects)
      return showInactiveProjectsSelected
    }

    function areAllEventTypesEnabled (eventTypeSettings) {
      return _.isEmpty(_.filter(eventTypeSettings, { isEnabled: false }))
    }

    function validateSelectAllFor (eventGroupObj) {
      let hasUnselected = false
      ctrl.organizationsPushNotificationSettings.forEach(setting => {
        setting.pushNotificationEventsSettings.forEach(pn => {
          if (pn.eventGroup === eventGroupObj.eventGroup) {
            hasUnselected = hasUnselected || !_.isEmpty(_.filter(pn.eventTypeSettings, { isEnabled: false }))
          }
        })
      })

      ctrl.organizationsPushNotificationsTableHeaderData.forEach(headerData => {
        if (headerData.eventGroup === eventGroupObj.eventGroup) {
          headerData.checkAll = !hasUnselected
        }
      })
    }
  }
})
