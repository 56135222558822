import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import { DashboardTabId, DashboardType } from 'modules/insights/dashboard/models/insights-dashboard.model'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import { InsightsDashboardComponentBaseDirective } from 'modules/insights/dashboard/components/insights-dashboard-component-base'
import { TranslateService } from '@ngx-translate/core'
import { OrganizationSingleValueDashboardData } from 'modules/insights/dashboard/models/insights-dashboard-organization-assets.model'
import { InsightsDashboardOrganizationAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-organization-assets.service'
import { NavController } from 'shared/smartvid.types'

@Component({
  selector: 'sv-organization-assets-insights-dashboard',
  templateUrl: 'organization-assets-insights-dashboard.component.html',
  styleUrls: ['organization-assets-insights-dashboard.component.scss'],
})
export class OrganizationAssetsInsightsDashboardComponent extends InsightsDashboardComponentBaseDirective
  implements OnInit, OnDestroy {
  organizationSingleValueData: OrganizationSingleValueDashboardData
  uploadedFilesRatioPercentChangeClassName: string
  uploadersRatioPercentChangeClassName: string
  tagsTotalRatioPercentChangeClassName: string
  ppeCompliancePercentAvgChangeClassName: string
  workAtHeightAvgPercentChangeClassName: string
  slipAndTripAvgPercentChangeClassName: string

  isConfigurationLoaded = false

  private wasRightNavOpen: boolean

  constructor(
    protected insightsDataHelper: InsightsDataHelper,
    protected insightsDashboardConfigurationService: InsightsDashboardConfigurationService,
    protected translate: TranslateService,
    private insightsDashboardOrganizationAssetsService: InsightsDashboardOrganizationAssetsService,
    @Inject('NavController') private navController: NavController
  ) {
    super(
      insightsDataHelper,
      insightsDashboardConfigurationService,
      translate,
      DashboardType.ORGANIZATION_ASSETS_DASHBOARD
    )
  }

  ngOnInit() {
    this.wasRightNavOpen = this.navController.isRightNavOpen()
    this.navController.closeRightNav()
    this.loadDashboardConfiguration()
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    if (this.wasRightNavOpen) {
      this.navController.openRightNav()
    }
  }

  getTabTitle(tabId: DashboardTabId) {
    let tabConfig = this.insightsDashboardConfigurationService.getTabConfiguration(tabId)
    return tabConfig && tabConfig.displayLabel ? tabConfig.displayLabel : ''
  }

  protected refresh() {
    this.loadSummaryCardsData()
  }

  private loadSummaryCardsData() {
    this.organizationSingleValueData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardOrganizationAssetsService.loadOrganizationSingleValueDashboardData(
        this.currentInsightsDashboardParameters,
        (data: OrganizationSingleValueDashboardData) => {
          this.organizationSingleValueData = data
          this.uploadedFilesRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.organizationSingleValueData.uploadedFilesRatioPercentChange
          )
          this.uploadersRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.organizationSingleValueData.uploadersRatioPercentChange
          )
          this.tagsTotalRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.organizationSingleValueData.tagsTotalRatioPercentChange
          )
          this.ppeCompliancePercentAvgChangeClassName = this.pickColorClassNameForPercentChange(
            this.organizationSingleValueData.ppeCompliancePercentAvgChange
          )
          this.workAtHeightAvgPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.organizationSingleValueData.workAtHeightAvgPercentChange
          )
          this.slipAndTripAvgPercentChangeClassName = this.pickColorClassNameForPercentChange(
            this.organizationSingleValueData.slipAndTripAvgPercentChange
          )
        }
      )
    )
  }

  private loadDashboardConfiguration() {
    this.apiCallsSubscriptions.push(
      this.insightsDashboardConfigurationService.loadDashboardConfiguration(
        {
          dashboardType: DashboardType.ORGANIZATION_ASSETS_DASHBOARD,
          organizationId: this.currentInsightsDashboardParameters.organizationId,
          projectGroupId: this.currentInsightsDashboardParameters.projectGroupId,
          projectId: this.currentInsightsDashboardParameters.projectId,
          userId: this.currentInsightsDashboardParameters.userId,
        },
        () => {
          this.isConfigurationLoaded = true
          this.refresh()
        }
      )
    )
  }
}
