/* global angular, _ */

angular.module('smartvid').factory('AssetModel', function (
  $injector, $q, $log, BaseModel, TagDefModel, CommentsCollection, tagUtils, AssetType, TagInstanceCollection,
  TagInstanceModel, CommentModel, BaseCollection, utils, AssetTypeDetails, ProjectionType
) {
  let smartvidApi = $injector.get('smartvidApi')

  // We use a bitmask to determine the status of various states
  const UPLOADING = 0

  class AssetModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        enabled: false,
        active: false,
        tags: new TagInstanceCollection(),
        comments: new CommentsCollection(),
        name: '',
        selected: false,
        type: '', // video, image
        typeDetails: '',
        duration: 0,
        thumbnailUrl: undefined,
        computedThumbnailUrl: undefined,
        imageUrl: '',
        audioAccessUrl: undefined,
        status: 0,
        processingProgress: 0,
        uploadProgress: 0,
        isUploading: false,
        snrPerFile: undefined,
        assets: [],
        commentsCount: 0,
        tagsCount: 0,
        unverifiedTagsCount: 0,
        isError: false,
        isCaptureDateOriginal: undefined,
        projectId: '',
        creatorUser: undefined,
        childToParentTagMap: undefined,
        metadata: undefined,
        debugInfo: undefined,
        extended: false,
        failed: false,
        processing: false,
        viewable: false,
        isPossible360Image: false,
        fc: Date.now(),
        // Optional
        totalCountTagConfidenceHigh: undefined,
        totalCountTagConfidenceMedium: undefined,
        totalCountTagConfidenceLow: undefined,
        projectionType: 'NONE',
        isReadOnly: undefined,
        canUpdate: undefined,
        canComment: undefined,
        canTag: undefined,
        canShare: undefined
      }
      attrs = _.defaults(_.clone(attrs || {}), defaults)
      super(attrs)
      this.wasProcessing = this.processing
    }

    getTags (tagConfidenceLevel) {
      // If we are uploading there are no assets to fetch
      if (this.status === UPLOADING) {
        return $q.resolve(undefined)
      }

      return smartvidApi.getTagsForAsset(this.id, tagConfidenceLevel).then((tags) => {
        this.tagsCount = tags.models.length
        this.tags.init(tags.models)
      })
    }

    getComments () {
      // If we are uploading there are no assets to fetch
      if (this.status === UPLOADING) {
        return $q.resolve(undefined)
      }

      return smartvidApi.getCommentsForAsset(this.id).then((comments) => {
        this.commentsCount = comments.models.length
        this.comments.init(comments.models)
      })
    }

    getExif () {
      if (this.metadata && this.metadata.exif) {
        return this.metadata.exif
      }

      return undefined
    }

    /**
     *
     * @param projectId
     * @param tag (String|TagDefModel)
     * @returns Promise(TagInstanceModel)
     */
    addTag (payload) {
      let defer = $q.defer()
      let onCreateSuccess = (tagInstance) => {
        if (!_.isEmpty(this.tags)) {
          this.tags.add(tagInstance)
          this.tagsCount++
          defer.resolve(tagInstance)
        } else {
          this.getTags().then(() => {
            this.tags.add(tagInstance)
            this.tagsCount++
            defer.resolve(tagInstance)
          })
        }
      }
      smartvidApi.createTagInstanceDefAndNode(this.projectId, payload).then(onCreateSuccess, () => {
        defer.reject()
      })
      return defer.promise
    }

    getMediaSource () {
      if (angular.isUndefined(this.assets)) {
        return undefined
      }

      for (let i = 0, length = this.assets.length; i < length; i++) {
        if (this.assets[i].contentType === 'video/mp4' && this.assets[i].type !== 'OriginalFile') {
          return this.assets[i].accessUrl.replace('.bin', '') + '/video.mp4'
        }
      }
      return undefined
    }

    removeTag (tagInstanceId) {
      let tag = this.tags.findById(tagInstanceId)
      this.unverifiedTagsCount = (tag.status === 'PENDING_VERIFICATION') ? this.unverifiedTagsCount - 1 : this.unverifiedTagsCount
      tag = null
      smartvidApi.destroyTagInstance(tagInstanceId)
      this.tags.removeById(tagInstanceId)
      this.tagsCount--
    }

    removeTagByTagDefId (tagDefId) {
      let models = _.clone(this.tags.models)
      _.each(models, (tag) => {
        if (tag.tagDefinitionId === tagDefId) {
          smartvidApi.destroyTagInstance(tag.id)
          this.tags.removeById(tag.id)
          this.tagsCount--
          this.unverifiedTagsCount = (tag.status === 'PENDING_VERIFICATION') ? this.unverifiedTagsCount - 1 : this.unverifiedTagsCount
        }
      })
    }

    snrAvailable () {
      return this.hasAudio() && this.snrPerFile
    }

    isSnrInProgress () {
      return this.snrInProgress
    }

    setSnrInProgress () {
      this.snrInProgress = true
    }

    isSnrFinished () {
      return this.snrFinished
    }

    isSmartTaggingInProgress () {
      return this.smartTaggingInProgress
    }

    isDziInProgress () {
      return this.dziInProgress
    }

    setDziInProgress () {
      this.dziInProgress = true
    }

    isDeleted () {
      return this.deleted
    }

    getTagInstanceByTagDefId (tagDefId) {
      return _.find(this.tags.models, function (tag) {
        return tag.tagDefinitionId === tagDefId
      })
    }

    isImage () {
      return this.type === AssetType.IMAGE
    }

    isVideo () {
      return this.type === AssetType.VIDEO
    }

    is360Image () {
      return this.isImage() && (this.typeDetails === AssetTypeDetails.SPHERICAL_IMAGE || this.projectionType === ProjectionType.EQUIRECTANGULAR)
    }

    isPossible360 () {
      return this.isPossible360Image
    }

    isDziImage () {
      return this.type === 'IMAGE' && this.imageUrl && this.imageUrl.indexOf('DziImage.dzi') !== -1
    }

    hasAudio () {
      return this.isVideo() || !!this.audioAccessUrl
    }

    isImagePlusAudio () {
      return this.isImage() && this.hasAudio()
    }

    isViewable () {
      if (this.type === AssetType.VIDEO) {
        return this.viewable && this.getMediaSource()
      }
      return this.viewable
    }

    isProcessing () {
      return this.processing
    }

    isFailed () {
      return this.failed
    }

    getComputedThumbnailUrl () {
      return (this.tagThumbnailUrl) ? this.tagThumbnailUrl : this.thumbnailUrl
    }

    hasComputedThumbnailUrl () {
      return !_.isEmpty(this.getComputedThumbnailUrl())
    }

    setTagThumbnailUrl (tagThumbnailUrl) {
      this.tagThumbnailUrl = (tagThumbnailUrl) ? tagThumbnailUrl + '&fc=' + this.fc : undefined
      this.refreshComputedThumbnailUrl()
    }

    setThumbnailUrl (thumbnailUrl) {
      if (!this.thumbnailUrl) {
        this.thumbnailUrl = thumbnailUrl
      }
      if (thumbnailUrl) {
        utils.checkImage(thumbnailUrl).then(() => {
          this.thumbnailUrl = thumbnailUrl
        }, () => {
          if (this.thumbnailUrl === thumbnailUrl) {
            this.thumbnailUrl = undefined
          }
          $log.debug('invalid image url: ' + thumbnailUrl)
        }).then(() => this.refreshComputedThumbnailUrl())
      }
      this.refreshComputedThumbnailUrl()
    }

    refreshComputedThumbnailUrl () {
      this.computedThumbnailUrl = this.getComputedThumbnailUrl()
    }

    update (attrs, oiteratee = AssetModel.defaultOiterateePredicate) {
      super.update(attrs, oiteratee)
      this.refreshComputedThumbnailUrl()
      this.tags = (this.tags instanceof BaseCollection) ? this.tags : new TagInstanceCollection()
      this.comments = (this.comments instanceof BaseCollection) ? this.comments : new CommentsCollection()
      if (attrs.tags && attrs.tags.length) {
        this.tags.init(attrs.tags, TagInstanceModel)
      }
      if (attrs.comments && attrs.comments.length) {
        this.comments.init(attrs.comments, CommentModel)
      }
      return this
    }

    static defaultOiterateePredicate (value, key, model) {
      return (angular.isDefined(value) &&
        key !== 'selected' &&
        key !== 'tags' &&
        key !== 'comments')
    }
  }

  return AssetModel
})
