/* global angular */

angular.module('smartvid').service('moveAssetsService', function ($q, $rootScope, $state, $window, $filter, $interval, $interpolate,
                                                                  $stateParams, smartvidApi, Notification, utils, dashboardDataHelper, $timeout) {
  let pollAndCheck

  let canMoveAssets = (selectedAssets) => {

    if (selectedAssets && selectedAssets.length > 0) {
      const assetProject = dashboardDataHelper.getProjectByProjectId(selectedAssets[0].projectId)
      if (assetProject && assetProject.organizationId) {
        const organization = dashboardDataHelper.getOrganizationByOrgId(assetProject.organizationId)
        return organization && organization.canMoveAssets
      }
    }
    return false
  }

  let showMoveAssetNotification = (data) => {
    let allProjects = dashboardDataHelper.getAllProjects()
    let targetProject = allProjects.where({id: data.targetProjectId})
    const number = data.successfullyMovedAssetIds.length
    const assetsMovedSuccessfully = number === 1 ? 'services.moveAssetsService.assetMovedSuccessfully' : 'services.moveAssetsService.assetsMovedSuccessfully'
    utils.notify($interpolate($filter('i18next')(assetsMovedSuccessfully))({
      number: number,
      projectName: targetProject.name
    }), '', 'common.view', true, () => {
      $state.go('dashboard.projects.projectId.files', {
        projectId: data.targetProjectId
      }, {
        reload: true
      })
    })
  }
  let showFailedToMoveAssetNotification = (data) => {
    const allAssets = dashboardDataHelper.getAssets()
    const failedAssetNames = allAssets.filter(asset => data.failedToMoveAssetIds.indexOf(asset.id !== -1)).map(a => a.name)

    utils.notify('', '', '', null, null, {
      templateName: 'move-asset',
      templateOptions: {failedAssetNames: failedAssetNames, allFailed: false}
    })
  }
  let onMoveAssetsProgress = (data) => {

    let messages = [$filter('i18next')('services.moveAssetsService.assetsMoveFailedReasons'),
      $filter('i18next')('services.moveAssetsService.assetsMoveFailedReason1'),
      $filter('i18next')('services.moveAssetsService.assetsMoveFailedReason2'),
      $filter('i18next')('services.moveAssetsService.assetsMoveFailedReason3'),
      $filter('i18next')('services.moveAssetsService.assetsMoveFailedReason4'),
      $filter('i18next')('services.moveAssetsService.assetsMoveFailedContactSupport')
    ]
    let subMsg = messages.join('\n')
    if (data.status === 'COMPLETED') {

      if (data.successfullyMovedAssetIds.length > 0 && data.failedToMoveAssetIds.length > 0) {
        showMoveAssetNotification(data)
        $timeout(() => {
          return showFailedToMoveAssetNotification(data)
        }, 3000)
        return {done: true, success: false}
      }

      if (data.failedToMoveAssetIds.length > 0) {
        utils.notify('',
          '', null, null, null, {templateName: 'move-asset', templateOptions: {allFailed: true}})
        return {done: true, success: false}
      }
      if (data.successfullyMovedAssetIds.length > 0) {
        showMoveAssetNotification(data)
      }
      return {done: true, success: true}
    } else if (data.status === 'FAILED') {
      utils.notify($filter('i18next')('services.moveAssetsService.assetsMoveFailedMessage2'),
        subMsg, '', null, null, {templateName: 'move-asset', templateOptions: {allFailed: true}})
      return {done: true, success: false}
    }

    return {done: false}
  }

//
// The exposed API for moveAssetsService
//
  let service = {

    startPollingForMoveAssetsCompletion: (data) => {
      let totalProgress = 0
      if (!onMoveAssetsProgress(data).done && !pollAndCheck) {
        pollAndCheck = $interval(() => {
          totalProgress = totalProgress + parseFloat(data.progress, 10)
          totalProgress = parseFloat((totalProgress) * 100, 10)
          $rootScope.$broadcast('sv-move-asset-total-progress', totalProgress)
          smartvidApi.getMoveAssetsProgress(data.moveAssetsProcessId).then((response) => {
            let progress = onMoveAssetsProgress(response)
            if (progress.done) {
              $interval.cancel(pollAndCheck)
              pollAndCheck = undefined
              $rootScope.moveAssetsInProgress = false
              if (progress.success) {
                $rootScope.$broadcast('sv-move-asset-complete')
              } else {
                $rootScope.$broadcast('sv-move-asset-failed')
              }
            }
          }, (response) => {
            $interval.cancel(pollAndCheck)
            pollAndCheck = undefined
            $rootScope.moveAssetsInProgress = false
          })
        }, 1000)
      }
    },
    canMoveAssets: (assets) => {
      return canMoveAssets(assets);
    }
  }

  return service
})
