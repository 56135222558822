/* global angular, _, analytics */

angular.module('smartvid').directive('projectGridInfoPanel', function ($rootScope, $filter, $state, smartvidApi, utils, $window,
   Notification, InvitedProjectUserModel, currentUser, emailUtils, MAX_PROJECT_NAME_LENGTH,
   MAX_PROJECT_DESCRIPTION_LENGTH) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'ProjectGrid/infopanel.html',
    link (scope, elem) {
      scope.commonUsers = []
      scope.selectedProjects = []
      scope.currentlySelectedProject = null
      scope.projectRoles = []
      scope.userToAdd = {}
      scope.emailError = false
      scope.showValidation = false
      scope.emailErrorLabel = undefined
      scope.input = elem.find('input')
      scope.projectUpdated = false
      scope.MAX_PROJECT_DESCRIPTION_LENGTH = MAX_PROJECT_DESCRIPTION_LENGTH
      scope.MAX_PROJECT_NAME_LENGTH = MAX_PROJECT_NAME_LENGTH
      scope.isFetchingProjectDetails = false
      scope.currentlySelectedOrganization = null
      scope.isInactiveProjectsSelected = false
      let keyupBound = false

      scope.onProjectChange = function () {
        scope.projectUpdated = true
      }

      let keyupHandler = function () {
        if (scope.showValidation) {
          scope.$evalAsync(function () {
            scope.validateEmail()
          })
        }
      }

      let resetValidation = function () {
        scope.emailError = false
        scope.showValidation = false
        scope.emailErrorLabel = undefined
      }

      let tryBindKeyupToInput = function () {
        if (keyupBound) return
        scope.input = elem.find('input')
        if (!_.isEmpty(scope.input)) {
          scope.input.on('keyup.sv.project-grid-infopanel', keyupHandler)
          keyupBound = true
        }
      }

      let getAssignableProjectRoles = function (selectedProjects) {
        let listsOfRoles = _.map(scope.selectedProjects, function (project) {
          return currentUser.getAssignableProjectRoles(project)
        })
        let assignableProjectRoles = _.intersection.apply(this, listsOfRoles)
        return assignableProjectRoles
      }

      let getCommonUsers = function (selectedProjects) {
        let selectedUsers = _.pluck(selectedProjects, 'users')
        let unsortedCommonUsers = utils.intersectionOfObjects(...selectedUsers, function (userOne, userTwo) {
          return userOne.userId === userTwo.userId
        })
        let sortedCommonUsers = $filter('orderBy')(unsortedCommonUsers, 'email')
        let result = _.map(sortedCommonUsers, function (user) {
          let matchedUsers = _.map(selectedUsers, function (users) {
            return _.find(users, function (matchedUser) {
              return matchedUser.userId === user.userId
            })
          })
          let canUpdate = _.reduce(matchedUsers, function (memo, num) {
            return memo && num.canUpdate
          }, true)
          return {
            userId: user.userId,
            role: user.role,
            canUpdate: canUpdate,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName
          }
        })
        return (_.isEqual(scope.commonUsers, result)) ? scope.commonUsers : result
      }

      scope.noProjectSelected = () => {
        // TODO (rrubbico) refactor this function so it does not have to be watched!
        scope.selectedProjects = scope.projects && scope.projects.where({selected: true})
        scope.isInactiveProjectsSelected = !!scope.selectedProjects.find(p => p.isInactive)
        if (scope.selectedProjects && scope.selectedProjects.length === 1) {
          let newlySelectedProject = scope.selectedProjects[0]
          if (scope.currentlySelectedProject !== newlySelectedProject && newlySelectedProject && angular.isDefined(newlySelectedProject.id)) {
            scope.currentlySelectedProject = newlySelectedProject
            scope.currentlySelectedOrganization = _.find(currentUser.organizations, (organization) => {
              return organization.id === scope.currentlySelectedProject.organizationId
            })
            scope.isFetchingProjectDetails = true
            smartvidApi.getUserProject(newlySelectedProject.id, scope.isDeletedProjectsState()).then(function (project) {
              let flattenedProject = utils.flattenProject(project)
              newlySelectedProject.update(flattenedProject, (a) => angular.isDefined(a) && a !== '')
              scope.isFetchingProjectDetails = false
            })
          }
        } else {
          scope.currentlySelectedProject = null
          scope.currentlySelectedOrganization = null
        }
        scope.projectRoles = getAssignableProjectRoles(scope.selectedProjects)
        if (scope.selectedProjects && scope.selectedProjects.length === 0 || !scope.userToAdd.role) {
          scope.userToAdd.role = scope.projectRoles[0]
        }
        scope.commonUsers = getCommonUsers(scope.selectedProjects)

        // binding keyup validation
        let noProjectSelectedBool = scope.selectedProjects && scope.selectedProjects.length === 0
        if (!noProjectSelectedBool) {
          tryBindKeyupToInput()
        } else {
          keyupBound = false
          scope.userToAdd.email = ''
          resetValidation()
        }
        return noProjectSelectedBool
      }

      scope.projectRoleLabel = (projectMember) => {
        return $filter('i18next')('project.roleLabelsNoBreak.' + projectMember.role)
      }

      scope.validateEmail = function () {
        let email = scope.userToAdd.email
        if (email === '' || email === undefined) {
          resetValidation()
          return
        }
        let isValid = false
        if (!emailUtils.isValidEmail(email)) {
          scope.emailError = true
          scope.showValidation = true
          scope.emailErrorLabel = $filter('i18next')('directives.userPool.emailInvalid')
        } else {
          scope.emailError = false
          scope.emailErrorLabel = undefined
          scope.showValidation = true
          isValid = true
        }

        return isValid
      }

      scope.addUser = () => {
        let email = scope.userToAdd.email
        if (!scope.validateEmail()) {
          if (!_.isEmpty(scope.input)) {
            scope.input.focus()
          }
          return
        }
        resetValidation()
        smartvidApi.getUserByEmail(email).then(function (user) {
          let userObj = {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            userEmail: email,
            role: scope.userToAdd.role.name
          }
          let ids = _.pluck(scope.selectedProjects, 'id')
          smartvidApi.addUsersToProjects(ids, [userObj]).then((data) => {
            _.each(scope.selectedProjects, (project) => {
              const projectResponse = _.find(data, {projectId: project.id})
              analytics.track('Add Project Member', {
                category: 'Project Action',
                orgName: (project.getProjectOrg()) ? project.getProjectOrg().name : undefined,
                projectName: project.name
              })
              if (projectResponse) {
                $rootScope.$broadcast('sv-project-members-invited-to-project', project.id, projectResponse.invitedUsers)
                project.update({users: projectResponse.invitedUsers})
              }
            })
          })
        })
        scope.userToAdd.email = ''
      }

      scope.removeUser = (user) => {
        _.each(scope.selectedProjects, (project) => {
          project.removeUsers([user.userId])
        })
      }

      scope.$on('$destroy', function destroy () {
        scope.input.off('keyup.sv.project-grid-infopanel', keyupHandler)
      })

      let customErrorHandler = function (response) {
        if (response.errorCode === 'BAD_REQUEST' && _.find(response.errorMessages,
                (m) => {
                  return m.label === 'resource.project.already.exists'
                })) {
          scope.projectAlreadyExistError = true
          return true
        }
        return false
      }

      scope.updateProjectGroup = function (project) {
        project.projectGroupId = project.projectGroupId ? project.projectGroupId : undefined
        project.projectGroupName = project.projectGroupId ? _.find(scope.currentlySelectedOrganization.projectGroups, group => group.id === project.projectGroupId).name : undefined
        smartvidApi.updateProject(project.id, project.name, project.externalProjectId, project.description,
            project.organizationId, undefined, false, customErrorHandler, project.projectGroupId)
      }

      scope.updateProject = function (project) {
        if (scope.projectUpdated && project) {
          scope.projectUpdated = false
          smartvidApi.updateProject(project.id, project.name, project.externalProjectId, project.description,
              project.organizationId, undefined, false, customErrorHandler, project.projectGroupId)
        }
      }

      scope.isDeletedProjectsState = function () {
        return $state.current.name === 'dashboard.adminOrganizations.organizationId.deletedProjects';
      }
    }
  }
})
