/* global angular */

angular.module('smartvid').directive('captureMobile', function (utils, $rootScope) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'capture-mobile.html',
    link (scope) {
      utils.fixCaptureButtonPosition()
    }
  }
})

