<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- Leaf node -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node"
                      [disabled]="!expandable || readOnly"
                      [checked]="checkboxSelection.isSelected(node)"
                      (change)="toggleCheckbox(node, $event)">{{node.name}}</mat-checkbox>
    </mat-tree-node>

    <!-- Expandable node -->
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button *ngIf="!expandable" disabled>
            <mat-icon class="mat-icon-rtl-mirror">chevron_right</mat-icon>
        </button>
        <button mat-icon-button *ngIf="expandable"
                [attr.aria-label]="'Toggle ' + node.name"
                (click)="toggleExpand(node)"
                matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </button>
        <mat-checkbox [checked]="checkboxSelection.isSelected(node)"
                      [indeterminate]="partiallySelection.isSelected(node)"
                      [disabled]="!expandable || readOnly"
                      (change)="toggleCheckbox(node, $event)">
            {{node.name}}
            <mat-spinner *ngIf="node.isLoading"
                         [diameter]="24"
                         class="folder-spinner"></mat-spinner>
        </mat-checkbox>
    </mat-tree-node>
</mat-tree>
