/* global angular */
angular.module('smartvid').filter('formattedTime', function (moment, $filter) {
  return function (time) {
    if (!time) {
      return $filter('i18next')('directives.thumbnail.timeUnknown')
    }
    let momentObj = moment(time)
    if (momentObj.isAfter(moment().subtract(5, 'minutes')) && momentObj.isBefore(moment().add(1, 'seconds'))) {
      return $filter('i18next')('directives.thumbnail.timeNow')
    }
    if (moment().isSame(momentObj, 'days')) {
      return $filter('i18next')('directives.thumbnail.timeToday') + ' ' + momentObj.format('hh:mmA')
    }
    if (moment().subtract(1, 'days').isSame(momentObj, 'days')) {
      return $filter('i18next')('directives.thumbnail.timeYesterday') + ' ' + momentObj.format('hh:mmA')
    }
    return momentObj.format('MMM DD, YYYY hh:mmA')
  }
})
