import * as _ from 'lodash'

const COMMON_OPTIONS = {
  titlePosition: 'none',
  axisTitlesPosition: 'none',
  fontName: 'Poppins',
  chartArea: {
    height: '80%',
    width: '85%',
  },
  backgroundColor: {
    fill: '#323232',
  },
  hAxis: {
    baselineColor: '#4a4a4a',
    format: 'short',
    textStyle: {
      color: 'white',
    },
    gridlines: {
      color: '#4a4a4a',
    },
  },
  vAxis: {
    baselineColor: '#4a4a4a',
    format: 'short',
    textStyle: {
      color: 'white',
    },
    gridlines: {
      color: '#4a4a4a',
    },
  },
  legend: {
    alignment: 'center',
    position: 'top',
    maxLines: 3,
    textStyle: {
      color: 'white',
    },
  },
  tooltip: {
    textStyle: {
      fontName: 'Poppins',
    },
  },
  colors: [
    '#3366cc',
    '#ff9900',
    '#109618',
    '#990099',
    '#0099c6',
    '#66aa00',
    '#316395',
    '#994499',
    '#22aa99',
    '#aaaa11',
    '#6633cc',
    '#e67300',
    '#651067',
    '#329262',
    '#5574a6',
    '#3b3eac',
  ],
}

// Setting up colors for pie charts excluding red variants, based on:
// https://stackoverflow.com/questions/32131668/from-where-can-i-get-list-of-all-color-patterns-used-by-google-charts
// we don't need more than 15
export const PIE_CHART_OPTIONS = _.merge(
  {
    pieHole: 0.5,
    pieSliceText: 'none',
    pieSliceBorderColor: 'none',
    sliceVisibilityThreshold: 0.1,
    legend: {
      position: 'right',
    },
    slices: {
      0: { color: '#3366cc' },
      1: { color: '#ff9900' },
      2: { color: '#109618' },
      3: { color: '#990099' },
      4: { color: '#0099c6' },
      5: { color: '#66aa00' },
      6: { color: '#316395' },
      7: { color: '#994499' },
      8: { color: '#22aa99' },
      9: { color: '#aaaa11' },
      10: { color: '#6633cc' },
      11: { color: '#e67300' },
      12: { color: '#651067' },
      13: { color: '#329262' },
      14: { color: '#5574a6' },
      15: { color: '#3b3eac' },
    },
    pieSliceTextStyle: {
      color: 'white',
    },
    chartArea: {
      height: '95%',
      width: '100%',
    },
  },
  COMMON_OPTIONS
)

export const BAR_CHART_OPTIONS = {
  ...COMMON_OPTIONS,
}

export const LINE_CHART_OPTIONS = {
  ...COMMON_OPTIONS,
}

export const AREA_CHART_OPTIONS = {
  ...COMMON_OPTIONS,
}

export const COLUMN_CHART_OPTIONS = {
  ...COMMON_OPTIONS,
}

export const COMBO_CHART_OPTIONS = {
  ...COMMON_OPTIONS,
  seriesType: 'bars',
}
