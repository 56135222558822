/* global angular */

angular.module('smartvid').service('forgeIntegrationApi', function ($http, $q, $log, config) {
  let rootUrl = config.env.development.apiRootUrl

  let api = {
    getForgeUser (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/forge/user`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getForgeUser', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getForgeHubs (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/forge/hubs`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getForgeHubs', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getForgeProjects (projectId, hubId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/forge/hub/${hubId}/projects`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getForgeProjects', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getForgeListFolders (projectId, folderId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/forge/folder`
      if(folderId) {
        let url = `${rootUrl}/api/integration/project/${projectId}/forge/folder/${folderId}`
      }

      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getForgeListFolders', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    createForgeIssue (projectId, contentIds, contentShareType, forgeIssue, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/forge/issue`
      let defer = $q.defer()

      let payload = {
        contentIds: contentIds,
        contentShareType: contentShareType,
        forgeIssue: forgeIssue
      }

      $http.post(url, payload, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('createForgeIssue', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getForgeFolderInfo (projectId, folderId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/forge/folder/${folderId}/info`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getForgeFolderInfo', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getForgeIssueTypes (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/forge/issue/type`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getForgeIssueTypes', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getForgeIssueCreateContext (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/forge/issue/context`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getForgeIssueCreateContext', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    getForgeRootCauses (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/forge/issue/root-causes`
      let defer = $q.defer()

      $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('getForgeRootCauses', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    },

    clearForgeCache (projectId, customErrorHandler) {
      let url = `${rootUrl}/api/integration/project/${projectId}/forge/cache/evict`
      let defer = $q.defer()

      $http.post(url, {customErrorHandler: customErrorHandler}).then((response) => {
        $log.debug('clearForgeCache', response.data)
        defer.resolve(response.data)
      }, function (response) {
        defer.reject(response.data)
      })

      return defer.promise
    }
  }

  return api
})
