/* global angular,cacheJS,$,_,analytics */
import UAParser from 'ua-parser-js'
import {RouteChangeStart, RouteChangeSuccess} from 'modules/state'

angular.module('smartvid')
  .config(function ($provide) {
    $provide.decorator('$exceptionHandler', function ($delegate, $window) {
      return function (exception, cause) {
        $delegate(exception, cause)
      }
    })
  // https://github.com/angular-ui/ui-grid/issues/1926
  $provide.decorator('Grid', function ($delegate, $timeout) {
    $delegate.prototype.renderingComplete = function () {
      if (angular.isFunction(this.options.onRegisterApi)) {
        this.options.onRegisterApi(this.api)
      }
      this.api.core.raise.renderingComplete(this.api)
      $timeout(function () {
        let $viewport = $('.ui-grid-render-container')
        let events = ['touchstart', 'touchmove', 'touchend', 'keydown', 'wheel', 'mousewheel', 'DomMouseScroll', 'MozMousePixelScroll']
        events.forEach(function (eventName) {
          $viewport.off(eventName)
        })
      })
    }
    return $delegate
  })
}).config(($logProvider) => {
  /*eslint-disable */
  !function () {
    var analytics = window.analytics = window.analytics || [];
    if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
      analytics.invoked = !0;
      analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "page", "once", "off", "on"];
      analytics.factory = function (t) {
        return function () {
          var e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          analytics.push(e);
          return analytics
        }
      };
      for (var t = 0; t < analytics.methods.length; t++) {
        var e = analytics.methods[t];
        analytics[e] = analytics.factory(e)
      }
      // analytics.load = function (t) {
      //   var e = document.createElement("script");
      //   e.type = "text/javascript";
      //   e.async = !0;
      //   e.src = ("https:" === document.location.protocol ? "https://" : "http://") + "cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
      //   var n = document.getElementsByTagName("script")[0];
      //   n.parentNode.insertBefore(e, n)
      // };
      // analytics.SNIPPET_VERSION = "3.1.0";
      // if (SEGMENT_IO_KEY) {
      //   analytics.load(SEGMENT_IO_KEY);
      // }
    }
  }();
  /*eslint-enable */
  $logProvider.debugEnabled(true)
}).config(function ($provide, $logProvider) {
  /* TODO (rrubbico) Remove this when FullStory resolves this issue
   * FullStory does not capture the logs made from the angular $log service. This is a workaround
   * that will call the console directly if possible
   */
  $provide.decorator('$log', function ($delegate) {
    let loggerWrapperFn = function (name, originalFn, argsParam) {
      if (name === 'debug' && !$logProvider.debugEnabled()) {
        return
      }

      let args = [].slice.call(argsParam)
      if (console && console[name]) {
        try {
          console[name].apply(console, args)
        } catch (e) {
          originalFn.apply(null, args)
        }
      } else {
        originalFn.apply(null, args)
      }
    }

    let origLogFn = $delegate.log
    let origInfoFn = $delegate.info
    let origWarnFn = $delegate.warn
    let origErrorFn = $delegate.error
    let origDebugFn = $delegate.debug

    $delegate.log = function () {
      let args = [].slice.call(arguments)
      loggerWrapperFn('log', origLogFn, args)
    }
    $delegate.info = function () {
      let args = [].slice.call(arguments)
      loggerWrapperFn('info', origInfoFn, args)
    }
    $delegate.warn = function () {
      let args = [].slice.call(arguments)
      loggerWrapperFn('warn', origWarnFn, args)
    }
    $delegate.error = function () {
      let args = [].slice.call(arguments)
      loggerWrapperFn('error', origErrorFn, args)
    }
    $delegate.debug = function () {
      let args = [].slice.call(arguments)
      loggerWrapperFn('debug', origDebugFn, args)
    }

    return $delegate
  })
}).config(function ($ariaProvider) {
  $ariaProvider.config({
    ariaHidden: false,
    ariaChecked: false,
    ariaReadonly: false,
    ariaDisabled: false,
    ariaRequired: false,
    ariaInvalid: false,
    ariaValue: false,
    tabindex: false,
    bindRoleForClick: false,
    bindKeydown: false
  })
}).config(['$urlServiceProvider', $urlService => {
  $urlService.deferIntercept()
}]).config(function ($i18nextProvider, $animateProvider, laddaProvider) {
  $i18nextProvider.options = {
    lng: 'en',
    useCookie: false,
    useLocalStorage: false,
    fallbackLng: 'dev',
    resGetPath: 'assets/locales/__lng__.json',
    defaultLoadingValue: '' // ng-i18next option, *NOT* directly supported by i18next
  }

  // We do not want the ng-animate always being added. This will allow us to control which elements get the ng-animate classes
  $animateProvider.classNameFilter(/sv-animate/)

  laddaProvider.setOption({
    style: 'zoom-in'
  })
}).run(function ($window, $cookies, utils, $rootScope, access, $location, flyout, modal, redirectorService, $state, $stateParams, $timeout, currentUser,
  contentSharingContext, $log, searchResultService, listViewUtils, NavController, $transitions, usersApi, smartvidApi, sharingApi, dashboardDataHelper, $trace) {

  let justWentBack = false

  $trace.disable()
  $rootScope.modal = modal // surfacing our modal service for the whole app to use
  $rootScope.flyout = flyout // Don't forget abou the flyout service. I am kind of like a JV modal.
  $rootScope.$state = $state
  $rootScope.$stateParams = $stateParams
  $rootScope.breadcrumbs = []
  $rootScope.isMobile = false
  $rootScope.isDesktop = !$rootScope.isMobile
  $rootScope.isShowingMobileBottomNav = utils.isShowingMobileBottomNav()
  $rootScope.showingSection = 'showing-projects-space'
  $rootScope.initMobile = $rootScope.isMobile
  $rootScope.currentUser = currentUser
  $rootScope.initAnalytics = false
  $rootScope.uaResult = new UAParser().getResult()
  $rootScope.appcuesIdentify = false
  $rootScope.isWidget = false

  cacheJS.use('array')
  listViewUtils.initDefaultGridTemplates()

  /**
   * Allow right click. e.g. to capture video frames to clipboard
   * @param event
   * @param isAllow
   * @param cb
   * @returns {boolean}
   */

  if (!$cookies.guid) {
    $cookies.guid = utils.createGuid()
  }

  $window.handleRightClick = handleRightClick

  $rootScope.$on('$stateChangeStart', onTransitionBefore)

  $rootScope.$on('$stateChangeSuccess', onTransitionSuccess)

  $rootScope.$on('$locationChangeSuccess', onLocationChangeSuccess)

  $rootScope.$on('$stateChangeError', onTransitionError)

  $transitions.onStart({}, onTransitionStart)

  $rootScope.back = back

  function handleRightClick (event, isAllow, cb) {
    if (isAllow === true) {
      event.stopPropagation()
      return true
    } else {
      return false
    }
  }

  function onTransitionBefore (event, toState, toParams, fromState, fromParams) {
    let state = (_.isEmpty(toState.name)) ? $state.current : toState
    let data = state.data
    let isAnonymous = Boolean(data && data.anonymous)
    let currentUser = $rootScope.currentUser
    let isAuthenticated = currentUser.isAuthenticated()

    let defaultRoute = 'dashboard.projects'
    if (isAuthenticated && currentUser.isAnonymous && toState.name === defaultRoute && !toParams.sharingType) {
      event.preventDefault()
      $state.go('login')
      return
    }

    if (!isAuthenticated && toParams.sharingType) {
      event.preventDefault()
      currentUser.clearState()
      contentSharingContext.set(toParams.sharingId, toParams.sharingType)
      usersApi.loginForLink().then(() => {
        $state.go(toState, toParams)
      }, () => {
        handleUnauthorized(toState, toParams)
      })
      return
    } else if (!isAuthenticated && !isAnonymous) {
      event.preventDefault()
      handleUnauthorized(toState, toParams)
      return
    }

    if (isAuthenticated) {
      currentUser.initAnalytics()
    }

    // 1 For mobile user with valid token and projectId when user arrives at login screen or projects we want
    // to route them to the last project they were using.
    // 2 For a valid token and no previous projectId we bring them to the projects screen.
    if ($rootScope.initMobile && $rootScope.isMobile && currentUser.isAuthenticated() && (state.name === 'login' || state.name === 'dashboard.projects')) {
      $rootScope.initMobile = false
      if (currentUser.get('projectId')) {
        let pid = currentUser.get('projectId')
        $timeout(() => {
          $state.go('dashboard.projects.projectId.files', {projectId: pid})
        })
      } else {
        $timeout(() => {
          $state.go('dashboard.projects')
        })
      }
      event.preventDefault()
      return false
    }
    handleInactiveProjectNavigation(toState)
    handleInternalAdminUrls(toState)
  }

  function onTransitionSuccess (event, toState, toParams, fromState, fromParams) {
    $log.debug('State change to  ', toState.name)

    utils.turnOnControlbarAnimateAfterLoad()

    if (toState.name.startsWith('dashboard')) {
      redirectorService.toState = toState
      redirectorService.toParams = toParams
      redirectorService.userEmail = currentUser.email
    }

    let path = $location.path()
    let querystring = (path.indexOf('?') !== -1) ? path.substring(path.indexOf('?'), path.length) : ''
    let referrer = (fromState.name) ? $location.protocol() + '://' + $location.host() + ':' + $location.port() + $state.href(fromState.name) : ''

    analytics.page(toState.name, {
      path: path,
      search: querystring,
      referrer: referrer,
      url: $location.absUrl()
    })

    // save previous state name in current state
    $rootScope.previousStateName = fromState.name

    let fromStateName = fromState.name
    let toStateName = toState.name
    //
    // Special defer to set css class for ui transition.
    // Seems excessive to get css animations to trigger - but timing is everything
    //
    if (toStateName.startsWith('dashboard.observations')) {
      usersApi.getCurrentUser()
      $timeout(() => {
        $rootScope.showingSection = 'showing-observations-space'
      }, 0)
    } else if (toStateName.startsWith('dashboard.adminOrganizations')) {
      usersApi.getCurrentUser()
      $timeout(() => {
        $rootScope.showingSection = 'showing-admin-space'
      }, 0)
      // special wait to set css class for ui transition
    } else if (toStateName === 'dashboard.projects' && fromStateName && _.startsWith(fromStateName, 'dashboard.adminOrganizations')) {
      $rootScope.showingSection = 'showing-admin-space'
      $timeout(() => {
        $rootScope.showingSection = 'showing-projects-space'
      }, 0)
    } else if (toStateName === 'dashboard.adminOrganizations' && _.isEmpty(fromStateName)) {
      $rootScope.showingSection = 'showing-admin-space'
    } else if (toStateName === 'dashboard.myprofile' || toStateName === 'dashboard.mysettings') {
      $timeout(() => {
        $rootScope.showingSection = 'showing-admin-space'
      }, 0)
    }

    if (toStateName === 'dashboard.observations') {
      $rootScope.breadcrumbs = []
    } else if (toStateName === 'dashboard.adminOrganizations') {
      $rootScope.breadcrumbs = []
    } else if (toStateName === 'dashboard.projects') {
      // special wait to set css class for ui transition
      $timeout(() => {
        $rootScope.showingSection = 'showing-projects-space'
      }, 0)

      $rootScope.breadcrumbs = []
    } else if (!justWentBack && !_.isEmpty(fromStateName)) {
      if (toStateName === 'dashboard.projects.projectId') {
        $rootScope.breadcrumbs = [{name: 'dashboard.projects', params: ''}]
      } else {
        $rootScope.breadcrumbs.push({name: fromStateName, params: fromParams})
      }
    } else if (_.isEmpty(fromStateName) && toStateName === 'dashboard.projects.projectId') {
      $rootScope.breadcrumbs = [{name: 'dashboard.projects', params: ''}]
    }
    justWentBack = false
    if (toParams.sharingType && searchResultService.isSharingInitialized() && currentUser.isAuthenticated()) {
      searchResultService.setupSharingWhenInitialized(toParams.assetId)
    }
  }

  function onLocationChangeSuccess (event, oldUrl, newUrl) {
    currentUser.callAppcues()
  }

  function onTransitionError (event, toState, toParams, fromState, fromParams, error) {
    if (error && error.status === access.UNAUTHORIZED) {
      handleUnauthorized(toState, toParams)
    } else if (error && error.status === access.FORBIDDEN) {
      $state.go('dashboard.projects')
    } else {
      $log.error('Could not switch state ', error)
      $state.go('login')
    }
  }

  function onTransitionStart (transition) {
    let toParams = transition.params('to')
    let toState = transition.to()
    let isAuthenticated = currentUser.isAuthenticated()
    const stateService = transition.router.stateService
    if (!toParams.sharingType || searchResultService.isSharingInitialized() || !isAuthenticated) {
      return true
    }
    contentSharingContext.set(toParams.sharingId, toParams.sharingType)
    let customErrorHandler = (response) => {
      return true
    }
    return sharingApi.getShareInfo(toParams.sharingType, toParams.sharingId, customErrorHandler).then((shareInfo) => {
      let sharingType = shareInfo.shareType
      contentSharingContext.set(toParams.sharingId, sharingType)
      let targetState
      let targetParams = {}
      targetParams.projectId = shareInfo.projectId
      targetParams.assetId = shareInfo.assetId
      targetParams.tagId = shareInfo.tagId
      targetParams.commentId = shareInfo.commentId
      targetParams.sharingType = sharingType
      targetParams.sharingId = toParams.sharingId
      targetParams.isWidget = toParams.isWidget
      if (toState.name === 'sharing') {
        if (sharingType === 'assets') {
          targetState = 'dashboard.projects.projectId.files'
        } else if (sharingType === 'xassets') {
          targetState = 'dashboard.projects'
        } else if (sharingType === 'asset') {
          targetState = 'dashboard.projects.projectId.files.viewer'
        } else if (sharingType === 'tag') {
          targetState = 'dashboard.projects.projectId.files.viewer'
        } else if (sharingType === 'comment') {
          targetState = 'dashboard.projects.projectId.files.viewer'
        } else if (sharingType === 'search') {
          targetState = 'dashboard.projects.projectId.files'
        } else if (sharingType === 'xsearch') {
          targetState = 'dashboard.projects'
        }
      } else {
        targetState = toState.name
        targetParams = Object.assign({}, toParams)
      }
      if (sharingType === 'search') {
        targetParams.searchContext = shareInfo.searchParams.assetSearchContext
      } else if (sharingType === 'xsearch') {
        targetParams.globalSearchContext = shareInfo.searchParams.assetSearchContext
      }
      searchResultService.setupSharing(shareInfo, toParams.assetId)
      if (toParams.isWidget === 'true') {
        $rootScope.isWidget = true
      }
      return stateService.target(targetState, targetParams)
    }, () => {
      contentSharingContext.reset()
      return stateService.target('dashboard.projects')
    })
  }

  function back () {
    let state = $rootScope.breadcrumbs.pop()

    if (state) {
      justWentBack = true
      $state.go(state.name, state.params)
    }
  }
  function isInProhibitedState (toState) {
    const prohibitedStates = ['dashboard.projects.projectId.tagsmanager', 'dashboard.projects.projectId.integrations', 'dashboard.projects.projectId.members']
    let deleteIsProhibited = false
    prohibitedStates.forEach(function (value) {
      if (toState.name.startsWith(value)) {
        deleteIsProhibited = true
      }
    })
    return deleteIsProhibited
  }

  function handleInactiveProjectNavigation (toState) {
    const currentProject = dashboardDataHelper.getCurrentProject()

    if (currentProject && currentProject.isInactive && isInProhibitedState(toState)) {
      $state.go('dashboard.projects', {}, {reload: true})
    }
  }

  function handleInternalAdminUrls(toState) {
    const prohibitedStates = ['internalAdmin']
    let reject = prohibitedStates.some(value => toState.name.startsWith(value))
    if (!reject) {
      return
    }
    if (!currentUser.isAuthenticated()) {
      $state.go('login')
    }
    smartvidApi.ensureAdminMode().then(() => {
    }, () => {
      $state.go('unauthorized')
    })

  }

  function handleUnauthorized (toState, toParams) {
    redirectorService.toState = toState
    redirectorService.toParams = toParams
    if ((toState.name === 'sharing' || toState.name === 'dashboard.projects.projectId.files.viewer') && toParams.email) {
      let email = window.atob(toParams.email)
      usersApi.getUserStatus(email).then((response) => {
        if (response.status === 'INVITED') {
          redirectorService.setAccessRouteBeforeLoginState()
          $state.go('registration', {
            email: toParams.email,
            token: toParams.token,
            sharing: true
          })
        } else {
          $state.go('login', {email: toParams.email})
        }
      })
    } else {
      $state.go('login')
    }
  }
}).run(['$rootScope', '$injector', function($rootScope, $injector) {
  $rootScope.$on(
    '$stateChangeSuccess',
    function (event, toState, toParams, fromState, fromParams) {
      $injector.get('ngxsStoreService').dispatch(
        new RouteChangeSuccess({
          to: toState.name,
          toParams: toParams,
          from: fromState.name,
          fromParams: fromParams,
        })
      )
    }
  )
  $rootScope.$on(
    '$stateChangeStart',
    function (event, toState, toParams, fromState, fromParams) {
      $injector.get('ngxsStoreService').dispatch(
        new RouteChangeStart({
          to: toState.name,
          toParams: toParams,
          from: fromState.name,
          fromParams: fromParams,
        })
      )
    }
  )
}])

