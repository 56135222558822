/* global angular, _, jQuery, $ */

angular.module('smartvid').service('flyout', function ($rootScope, $compile, $timeout, $templateCache, utils, $q) {
  class Flyout {
    constructor () {
      this._open = false

      this.commentOptions = {
        templateUrl: 'flyouts/comment-options.html'
      }

      this.tagOptions = {
        templateUrl: 'flyouts/tag-options.html'
      }

      this.projectOptions = {
        templateUrl: 'flyouts/project-options.html'
      }

      this.deletedProjectOptions = {
        templateUrl: 'flyouts/deleted-project-options.html'
      }

      this.deletedProjectsGridOptions = {
        templateUrl: 'flyouts/deleted-projects-grid-options.html'
      }

      this.queryOptions = {
        templateUrl: 'flyouts/query-options.html'
      }

      this.organizationOptions = {
        templateUrl: 'flyouts/organization-options.html'
      }

      this.assetOptions = {
        templateUrl: 'flyouts/asset-options.html'
      }

      this.userProfileOptions = {
        templateUrl: 'flyouts/user-profile-options.html'
      }

      this.projectMemberOptions = {
        templateUrl: 'flyouts/project-member-options.html'
      }

      this.projectMembersGridOptions = {
        templateUrl: 'flyouts/project-members-grid-options.html'
      }

      this.tagManagerOptions = {
        templateUrl: 'flyouts/tag-manager-options.html'
      }

      this.sortOptions = {
        templateUrl: 'flyouts/sort-options.html'
      }

      this.tagSortOptions = {
        templateUrl: 'flyouts/tag-sort-options.html'
      }

      this.groupOptions = {
        templateUrl: 'flyouts/group-options.html'
      }

      this.assetGridOptions = {
        templateUrl: 'flyouts/asset-grid-options.html'
      }

      this.assetGridDownloadOptions = {
        templateUrl: 'flyouts/asset-grid-download-options.html'
      }

      this.adminOrganizationsGridOptions = {
        templateUrl: 'flyouts/admin-organizations-grid-options.html'
      }

      this.usersGridOptions = {
        templateUrl: 'flyouts/users-grid-options.html'
      }

      this.projectGridOptions = {
        templateUrl: 'flyouts/project-grid-options.html'
      }

      this.toolsOptions = {
        templateUrl: 'flyouts/tools-options.html'
      }

      this.userOptions = {
        templateUrl: 'flyouts/user-options.html'
      }

      this.organizationOptions = {
        templateUrl: 'flyouts/organization-options.html'
      }

      this.formValidationMessage = {
        templateUrl: 'flyouts/form-validation-message.html'
      }
    }

    createScope (flyoutName, scope = {}) {
      let flyout = this[flyoutName] // grab the modal details (assigned in constructor)

      _.assign(flyout, scope) // apply the passed in scope
      this.scope = _.assign($rootScope.$new(), flyout) // make our flyout object into a scope
    }

    resetFlyoutHtml (flyout) {
      let markup = $templateCache.get('flyouts/flyout.html') // grab the modal wrapper template
      let link = $compile(markup) // get a link function back from the $compile service
      let html = link(this.scope) // link the scope to the template

      // Overwrite html in the flyout container (check index.html) and blow away previous modal
      jQuery('.flyout-container').html(html)

      // bind keypress here for closing modal with escape.
      // TODO: possibly move it out of jQuery and add common KEYs constants lookup.
      let ESCAPE_KEY = 27
      let handler = (e) => {
        if (e.which === ESCAPE_KEY && this.isOpen) {
          this.close()
          jQuery('body').off('keyup', handler)
        }
      }
      jQuery('body').on('keyup', handler)
    }

    open (flyoutName, scope) {
      //
      // pass in a parent element and direction to determine the position
      //
      let rect
      if (scope.parentElement && scope.direction && !scope.styles) {
        rect = scope.parentElement.getBoundingClientRect()
        scope.styles = {}
        switch (scope.direction) {
          case 'left':
            scope.styles.top = rect.top + (rect.height / 2)
            scope.styles.left = rect.left + rect.width - 10
            break
          case 'right':
            scope.styles.top = rect.top + (rect.height / 2)
            scope.styles.left = rect.left
            break
          case 'top':
            scope.styles.top = rect.top + rect.height + 10
            scope.styles.left = rect.left - (rect.width / 2)
            break
          case 'bottom':
            scope.styles.top = rect.top
            scope.styles.left = rect.left + (rect.width / 2)
            break
        }
      }

      this.createScope(flyoutName, scope)
      this.resetFlyoutHtml()

      //
      // Adjust to fit in window at bottom after html is inserted and DOM is ready with dimensions
      //
      window.setTimeout(() => {
        if (rect) {
          let bottomOfFlyout = $('.flyout-container .menu').offset().top + $('.flyout-container .menu').height()
          if (bottomOfFlyout > $('body').height()) {
            $('.flyout-container .menu').css({
              top: rect.top - $('.flyout-container .menu').height() + 30
            })

            // Move arrow on DOM to last menu item. This will work for left or right menus
            if ($('.flyout-container .arrow-top-left').length) {
              $('.flyout-container .menu .menu-item').last().prepend($('.flyout-container .arrow-top-left'))
            } else if ($('.flyout-container .arrow-top-right').length) {
              $('.flyout-container .menu .menu-item').last().prepend($('.flyout-container .arrow-top-right'))
            }
          }

          let rightOfFlyout = $('.flyout-container .menu').offset().left + $('.flyout-container .menu').width()
          if (Math.round(rightOfFlyout) > $('body').width()) {
            $('.flyout-container .menu').css({
              left: rect.left - (Math.round(rightOfFlyout) - $('body').width() + 40)
            })

            // Move arrow on DOM to last menu item. This will work for left or right menus
            if ($('.flyout-container .arrow-top-left').length) {
              let ar = $('<div class="arrow-top-right"></div>')
              $('.flyout-container .menu .menu-item').first().prepend(ar)
              $('.flyout-container .arrow-top-left').remove()
            }
          }
        }
        this.isOpen = true
      })

      utils.digest(this.scope)
    }

    close () {
      let defer = $q.defer()
      this.isOpen = false
      utils.digest(this.scope)

      defer.resolve(this.isOpen)

      this.scope.$destroy()
      this.scope = {}

      return defer.promise
    }

    get isOpen () {
      return this._open
    }

    set isOpen (openState) {
      this._open = openState
      utils.digest(this.scope)
    }

    isOpenFor (flyoutId) {
      return this.scope && this.scope.flyoutId && this.scope.flyoutId === flyoutId
    }

    get templateUrl () {
      return this.scope.templateUrl || ''
    }

    get controller () {
      return this.scope.controller
    }
  }

  let flyout = new Flyout()
  return flyout // return our class instance
})
