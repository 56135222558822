/* global angular */

angular.module('smartvid').controller('AssetGridOptionsCtrl', function ($scope, $rootScope, modal, dashboardDataHelper, moveAssetsService) {
  $scope.deleteSelectedAssets = () => {
    let selected = $scope.assets.where({selected: true})
    $scope.flyout.isOpen = false
    modal.open('deleteConfirm', {
      confirm () {
        $rootScope.$broadcast('assetGrid:deleteSelected', true)
        $scope.flyout.close()
      },
      hasSelections: selected.length > 0
    })
  }

  $scope.reRunSmartTaggingAssets = () => {
    let selected = $scope.assets.where({selected: true})
    $scope.flyout.isOpen = false
    modal.open('reRunSmartTaggingConfirm', {
      confirm () {
        if (selected.length > 1) {
          $rootScope.$broadcast('assetGrid:reRunSmartTaggingSelected', true)
        } else {
          $rootScope.$broadcast('assetGrid:reRunSmartTaggingAsset', selected[0])
        }
        $scope.flyout.close()
      },
      hasSelections: selected.length > 0
    })
  }

  $scope.canMoveAssets = () => {
    return moveAssetsService.canMoveAssets($scope.assets.getSelected())
  }

  $scope.openMoveAssetModal = () => {
    let selected = $scope.assets.getSelected()
    $scope.flyout.isOpen = false
    if (selected.length > 0) {

      $scope.modal.open('moveAssetModal', {
        assets: selected,
        collection: $scope.assets,
        currentProject: $scope.currentProject,
        allSelected: $scope.assets.allSelected
      })
    }
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top,
      left: $scope.styles.left,
      width: '190'
    }
  }
  $scope.isProjectInactive = () => {
    const currentProject = dashboardDataHelper.getCurrentProject();
    return currentProject && currentProject.isInactive
  }

  $scope.isOrganizationInactive = () => {
    return $scope.currentUser.isOrganizationInactive(dashboardDataHelper.getCurrentProject() &&
      dashboardDataHelper.getCurrentProject().organizationId)
  }

})
