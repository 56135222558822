/* global angular _ */
/**
 * Created by ryanrubbico on 1/3/18.
 */
angular.module('smartvid').factory('StorageServiceFolderModel', function (BaseModel) {
  class StorageServiceFolderModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined,
        name: undefined,
        path: undefined
      }
      super(_.defaults(attrs || {}, defaults))
    }
  }

  return StorageServiceFolderModel
})

