/* global angular _ */

angular.module('smartvid').factory('SearchEntityModel', function (BaseModel, SEARCH_ENTITY_MODEL_TYPE_TAG_DEFINITION,
                SEARCH_ENTITY_MODEL_TYPE_TAG_DEFINITION_ALIAS, SEARCH_ENTITY_MODEL_TYPE_USER, SEARCH_ENTITY_MODEL_TYPE_FREE_TEXT) {
  class SearchEntityModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined,
        text: undefined,
        additionalText: undefined,
        type: undefined,
        hasChildren: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }

    isTagDefinition () {
      return this.type === SEARCH_ENTITY_MODEL_TYPE_TAG_DEFINITION ||
        this.type === SEARCH_ENTITY_MODEL_TYPE_TAG_DEFINITION_ALIAS
    }

    isUser () {
      return this.type === SEARCH_ENTITY_MODEL_TYPE_USER
    }

    isFreeText () {
      return this.type === SEARCH_ENTITY_MODEL_TYPE_FREE_TEXT
    }

  }

  return SearchEntityModel
})
