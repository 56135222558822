/* global angular _ */

angular.module('smartvid').factory('FilesInfoReportScheduleDataModel', function (ReportScheduleDataModel) {
  class FilesInfoReportScheduleDataModel extends ReportScheduleDataModel {
    constructor (attrs) {
      let defaults = {
        projectIds: [],
        projectNames: [],
        isConstructionRelatedOnly: undefined,
        tagConfidenceLevel: undefined,
        selectedTags: []
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return FilesInfoReportScheduleDataModel
})
