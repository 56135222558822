/* global angular */
angular.module('smartvid').directive('tagListFlyout', function ($timeout) {
  return {
    restrict: 'E',
    templateUrl: 'tag-list-flyout.html',
    scope: {
      asset: '='
    },
    link (scope, element) {
      const NUMBER_OF_TAGS_TO_SHOW = 10
      let parent = element.parent()

      scope.fetchingTags = false
      scope.moreTagsThanLimit = getIsMoreTagsThanLimit(scope.asset.tags, NUMBER_OF_TAGS_TO_SHOW)
      scope.orderByVerified = orderByVerified
      init()

      function getIsMoreTagsThanLimit (tagInstanceCollection, numberOfTagsToShow) {
        return scope.asset.tags && tagInstanceCollection.uniqOn('tagDefinitionId').length > numberOfTagsToShow
      }

      function init () {
        element.detach()
        scope.$on('$destroy', function () {
          element.remove()
          element.off()
          parent.tooltipster('destroy')
        })

        parent.tooltipster({
          content: element,
          updateAnimation: 'scale',
          contentCloning: false,
          arrow: true,
          /* trigger: 'custom', // for debugging
           triggerOpen: {
           mouseenter: true
           },
           triggerClose: {
           click: true
           },
           debug: true, */
          side: ['right', 'left', 'bottom', 'top'],
          delay: [500, 200],
          theme: ['tooltipster-borderless', 'tooltipster-tag-list-flyout'],
          interactive: true,
          functionPosition: position,
          functionBefore: initHoverTags
        })
      }

      function orderByVerified (item) {
        return item.isVerified()
      }

      function position (instance, helper, position) {
        return position
      }

      function initHoverTags (instance, helper) {
        if (scope.asset.tags && scope.asset.tags.length) {
          return
        }
        scope.fetchingTags = true
        scope.asset.getTags().then(() => {
          scope.moreTagsThanLimit = getIsMoreTagsThanLimit(scope.asset.tags, NUMBER_OF_TAGS_TO_SHOW)
          scope.fetchingTags = false
          $timeout(() => {
            instance.content(element)
          })
        })
      }
    }
  }
})
