/* global angular, _, analytics $ */

angular.module('smartvid').directive('taglist', function (
    $rootScope, smartvidApi, $q, $log, $stateParams, $state, $filter, videoPlayer, $timeout, currentUser, TagDefCollection,
    TagInstanceGroupCollection, TagInstanceModel, dashboardDataHelper, tagVerificationService, tagConfidenceLevelService,
    TAG_CONFIDENCE_LEVEL_HIGH, TAG_CONFIDENCE_LEVEL_MEDIUM, TAG_CONFIDENCE_LEVEL_LOW, utils
) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'AssetViewer/taglist.html',
    link (scope, element) {
      const DEFAULT_LENGTH_OF_TAG = 2000
      let isCreatingTag = false
      let cachedTagInstanceGroups = {}
      let deepLinkTag = scope.tag
      scope.isSharing = $stateParams.sharingId

      scope.currentlySelectedTag = null
      scope.currentlySelectedGroup = null
      scope.currentProject = getProject()
      scope.comments = scope.asset.comments
      scope.canTag = scope.asset.canTag && !scope.asset.isTagsReadOnly
      scope.assetTags = scope.asset.tags
      scope.groupedTagInstances = groupTagsIfImage()
      scope.newTag = { editMode: false }
      scope.provenanceIndicatorOptions = {
        content: element,
        contentCloning: false,
        arrow: false,
        side: 'right',
        debug: false,
        /* trigger: 'custom', // for debugging
         triggerOpen: {
         mouseenter: true
         },
         triggerClose: {
         click: true
         },
         debug: true, */
        delay: [500, 0],
        theme: ['tooltipster-borderless', 'tooltipster-provenance-indicator'],
        functionPosition: position
      }
      scope.canCreate = canCreate()

      scope.verifyAll = verifyAll
      scope.tagsActive = tagsActive
      scope.initNewTag = initNewTag
      scope.inputFocused = inputFocused
      scope.handleTagClick = handleTagClick
      scope.handleListItemClick = handleListItemClick
      scope.addTag = addTag
      scope.addTagToAsset = addTagToAsset
      scope.handleSelectedTagDefinition = handleSelectedTagDefinition
      scope.showTagOptions = showTagOptions
      scope.keyPressTagStartTime = keyPressTagStartTime
      scope.keyPressTagEndTime = keyPressTagEndTime
      scope.isTagInstanceGroup = isTagInstanceGroup
      scope.unselectAllTagsAndGroups = unselectAllTagsAndGroups
      scope.showToolsOptions = showToolsOptions
      scope.verifyTag = verifyTag
      scope.rejectTag = rejectTag

      scope.$watchCollection('assetTags.models', onTagInstanceCollectionChange)

      scope.$on('sv-player-seeked', unselectComment)
      scope.$on('sv-player-seeked', setCurrentTime)
      scope.$on('sv-player-pause', setCurrentTime)
      scope.$on('sv-tag-clicked', handleTagClickEventHandler)
      scope.$on('sv-unselect-all-tags-and-groups', unselectAllTagsAndGroups)
      scope.$on('$stateChangeStart', persistCurrentlySelectedTag)

      scope.tagConfidenceLevel = tagConfidenceLevelService.getTagConfidenceLevel()

      scope.switchTagConfidenceLevel = (level) => {
        unselectAllTagsAndGroups()
        scope.tagConfidenceLevel = level
        tagConfidenceLevelService.setTagConfidenceLevel(level)
        scope.asset.getTags()
      }

      scope.asset.getTags(scope.tagConfidenceLevel).then(() => {
        deepLinkToTag()
      })

      scope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
        scope.asset.getTags(tagConfidenceLevelService.getAssetGridTagTagConfidenceLevel())
        if (conditionToResetTagConfidenceLevel(toState, fromState)) {
          tagConfidenceLevelService.setTagConfidenceLevel(undefined)
        }
      })

      function conditionToResetTagConfidenceLevel (toState, fromState) {
        return toState.name === 'dashboard.projects.projectId.files' || (toState.name === 'dashboard.projects' && fromState.name === 'dashboard.projects.viewer')
      }

      enterKeyWorkaround()

      function deepLinkToTag () {
        //
        // DEEP LINK TO TAG
        //  select tag/tag group if a user expects to be linked straight to a tag instance (search and tag share)
        if (deepLinkTag) {
          $timeout(() => {
            if (deepLinkTag.tagConfidenceLevel && !tagConfidenceLevelService.isTagConfidenceSet()) {
              scope.tagConfidenceLevel = deepLinkTag.tagConfidenceLevel
            }
            let tagOrGroup = cachedTagInstanceGroups[deepLinkTag.tagDefinitionId] || deepLinkTag
            selectTagInstanceOrGroup(tagOrGroup, /* force selected state */true)
          }, 500)
        }
      }

      function enterKeyWorkaround () {
        //
        // Enter key work around for auto create tag instance.
        // angucomplete-alt has known bug where keyupHandler for enter key
        // does nothing on purpose. We can address the plugin in the future.
        //
        $timeout(() => {
          angular.element('.taglist .autocomplete .taginput').on('keyup', function (e) {
            if (e.originalEvent.keyCode === 13) {
              let val = $(this).val()
              scope.$apply(() => {
                if (!scope.newTag && _.trim(val)) {
                  scope.newTag = {
                    tagDefId: undefined,
                    text: val
                  }
                  createTag(scope.newTag)
                }
              })
            }
          })
        })
      }

      function onTagInstanceCollectionChange (newVal, oldVal) {
        if (newVal !== oldVal) {
          scope.groupedTagInstances = groupTagsIfImage()
        }
      }

      function groupTagsIfImage () {
        if (scope.asset.isImage()) {
          return _.chain(scope.assetTags.models)
              .sortBy(manualFirstSort)
              .groupBy(model => model.tagDefinitionId)
              .map((values, key) => {
                if (values && values.length === 1) {
                  return values[0]
                } else {
                  if (cachedTagInstanceGroups[key]) {
                    return _.extend(cachedTagInstanceGroups[key], {models: values})
                  }
                  cachedTagInstanceGroups[key] = new TagInstanceGroupCollection(values, TagInstanceModel)
                  return cachedTagInstanceGroups[key]
                }
              })
              .value()
        } else {
          return _.chain(scope.assetTags.models)
              .sortBy(manualFirstSort)
              .sortBy(function (model) {
                return model.startTime
              })
              .value()
        }
      }

      function manualFirstSort (model) {
        if (model.type === 'MANUAL') {
          return 0
        }
        if (model.type === 'INTEGRATION') {
          return 1
        }
        return 2
      }

      function isTagInstanceGroup (val) {
        return val instanceof TagInstanceGroupCollection
      }

      function handleListItemClick ($event, listItem) {
        selectTagInstanceOrGroup(listItem, false)
      }

      function selectTagInstanceOrGroup (tagOrGroup, forceSelectedState = false) {
        if (isTagInstanceGroup(tagOrGroup)) {
          handleTagGroupClick(tagOrGroup, forceSelectedState)
        } else {
          handleTagClick(tagOrGroup, forceSelectedState)
        }
      }

      function unselectAllTagsAndGroups () {
        scope.assetTags.unselectAll()
        _.each(scope.groupedTagInstances, (item) => {
          item.selected = false
          if (!isTagInstanceGroup(item)) { item.editMode = false }
        })
        scope.currentlySelectedGroup = null
        scope.currentlySelectedTag = null
        $rootScope.$broadcast('sv-clear-all-markup')
      }

      function calculateDefaultTagLength (startTime) {
        let playerStartTime = videoPlayer.startLimit ? videoPlayer.startLimit : 0
        return Math.min(videoPlayer.duration() * 1000 + playerStartTime * 1000 - startTime, DEFAULT_LENGTH_OF_TAG)
      }

      function getMinDuration () {
        if (_.isNumber(scope.asset.duration)) {
          return Math.min(videoPlayer.duration() * 1000, scope.asset.duration)
        }
      }

      function createTag (newTag) {
        let project = getProject()
        let projectOrg = currentUser.getOrganization(project.organizationId)
        analytics.track('Add Tag Instance', {
          category: 'Tag Action',
          tagName: newTag.text,
          projectName: project.name,
          orgName: (projectOrg) ? projectOrg.name : undefined,
          fromTagDef: newTag.tagDefId !== undefined,
          manual: true
        })
        let payload = {
          assetId: scope.asset.id,
          text: newTag.text,
          onTimeline: false,
          startTime: undefined,
          endTime: undefined,
          status: 'VERIFIED',
          type: 'MANUAL'
        }

        // TODO refactor this out
        if (newTag.startTime !== -1 && scope.asset.hasAudio()) {
          _.assign(payload, {
            startTime: newTag.startTime,
            endTime: newTag.endTime,
            onTimeline: true
          })
        }
        let promise = createTagDefIfNeeded(newTag)
        promise.then(() => {
          payload.tagDefinitionId = newTag.tagDefId
          scope.asset.addTag(payload).then((tagInstance) => {
            isCreatingTag = false
          }, () => {
            isCreatingTag = false
          })
        }, () => {
          isCreatingTag = false
        })
        scope.newTag = {
          editMode: false
        }
        scope.asset.totalCountTagConfidenceHigh++
        scope.asset.totalCountTagConfidenceMedium++
        scope.asset.totalCountTagConfidenceLow++
        scope.initNewTag()
      }

      function createTagDefIfNeeded (newTag) {
        let defer = $q.defer()
        if (newTag.tagDefId) {
          defer.resolve()
        } else {
          let tagTree = dashboardDataHelper.getCurrentProjectTagTree()
          tagTree.createTag(newTag.text, true, true, true, [], {tagDefinitionId: newTag.parentTagDefId}, !newTag.parentTagDefId).finally(() => {
            defer.resolve()
          })
        }
        return defer.promise
      }

      function verifyAll () {
        let unverifiedTags = scope.assetTags.getUnverifiedTags()
        let title = $filter('i18next')('directives.taglist.noTagToVerifyTitle')
        let message = $filter('i18next')('directives.taglist.noTagToVerifyMessage')

        if (unverifiedTags.length === 0) {
          scope.modal.open('alert', {title: title, message: message})
          return
        }

        smartvidApi.verifyAllTagsForAsset($stateParams.assetId).then((response) => {
          _.each(unverifiedTags, (tag) => {
            tag.status = 'VERIFIED'
            if (tag.tagConfidenceLevel === TAG_CONFIDENCE_LEVEL_LOW) {
              scope.asset.totalCountTagConfidenceMedium++
              scope.asset.totalCountTagConfidenceHigh++
            }
            if (tag.tagConfidenceLevel === TAG_CONFIDENCE_LEVEL_MEDIUM) {
              scope.asset.totalCountTagConfidenceHigh++
            }
          })
          unverifiedTags = []
          scope.asset.unverifiedTagsCount = 0
        })
      }

      function tagsActive () {
        return scope.asset.tags.getLocal() || angular.isDefined(scope.asset.tags.getSelectedTag())
      }

      function setCurrentTime (ev, payload) {
        if (!scope.newTag) {
          return
        }
        scope.newTag.startTime = payload.currentTime * 1000
        scope.newTag.endTime = scope.newTag.startTime + DEFAULT_LENGTH_OF_TAG
      }

      function unselectComment (ev, payload) {
        if (scope.comments.getSelectedComment() &&
          scope.comments.getSelectedComment().startTime !== Math.round(payload.currentTime * 1000)) {
          scope.comments.unselectAllAndPersistChanges($stateParams.assetId)
        }
      }

      function initNewTag () {
        scope.newTag.startTime = Math.min(videoPlayer.currentTime() * 1000, getMinDuration())
        scope.newTag.endTime = Math.min(scope.newTag.startTime + calculateDefaultTagLength(scope.newTag.startTime), getMinDuration())
        scope.newTag.status = 'VERIFIED'
        scope.newTag.local = true
        scope.newTag.onTimeline = true
        scope.parentTagDef = undefined
      }

      function inputFocused () {
        // If we are already in edit mode do not add another one
        if (scope.newTag.editMode) {
          return
        }
        scope.newTag.editMode = true
        scope.unselectAllTagsAndGroups()
        scope.comments.unselectAllAndPersistChanges($stateParams.assetId)
        videoPlayer.pause()
        scope.initNewTag()
      }

      function handleTagClick (tag, forceSelectedState = false) {
        unselectAllComments(scope.comments, scope.asset.canUpdate)

        if (!tag.selected || forceSelectedState) {
          selectTagAndPersistChanges(tag)
          tag.editMode = true
        } else if (!scope.asset.canUpdate) {
          unselectAllTagsAndGroups()
        } else if (tag.editMode && scope.asset.canUpdate) {
          // If we are in edit mode, then save and close.
          smartvidApi.updateTagForAsset(tag)
          tag.editMode = false
          unselectAllTagsAndGroups()
        }
      }

      function selectTagAndPersistChanges (tag) {
        if (!tag) {
          return
        }
        persistCurrentlySelectedTag()
        selectTag(tag)
      }

      function ensureTagSelected () {
        let tag = scope.currentlySelectedTag
        let tagModel = scope.assetTags.models.find(t => t.id === tag.id)
        if (tagModel) {
          tagModel.selected = true
        }
      }

      function selectTag (tag) {
        let tagGroup = cachedTagInstanceGroups[tag.tagDefinitionId]
        let isTagGroupCurrentlySelected = tagGroup && tagGroup === scope.currentlySelectedGroup
        // if the newly selected tag is subtag of selected group
        if (isTagGroupCurrentlySelected) {
          scope.currentlySelectedGroup.unselectAll()
        } else if (tagGroup) {
          selectTagGroupAndPersistChanges(tagGroup, false)
        } else {
          unselectAllTagsAndGroups()
        }
        tag.selected = true
        scope.currentlySelectedTag = tag
        ensureTagSelected() // WA-3362
        if (!tag.isAssetLevel()) {
          videoPlayer.currentTime(tag.startTime / 1000)
        }
        $rootScope.$broadcast('sv-tag-show-markup', [tag])
      }

      function handleTagGroupClick (tagGroup, forceSelectedState = false) {
        unselectAllComments(scope.comments, scope.asset.canUpdate)
        if (tagGroup.selected && !forceSelectedState) {
          unselectAllTagsAndGroups()
        } else {
          selectTagGroupAndPersistChanges(tagGroup)
        }
      }

      function persistCurrentlySelectedTag () {
        // If the currently selected item was in edit mode then save.
        if (scope.currentlySelectedTag && scope.currentlySelectedTag.editMode && scope.asset.canUpdate &&
            scope.assetTags.findById(scope.currentlySelectedTag.id)) {
          smartvidApi.updateTagForAsset(scope.currentlySelectedTag)
        }
      }

      function selectTagGroupAndPersistChanges (tagGroup, displayMarkup = true) {
        persistCurrentlySelectedTag()
        unselectAllTagsAndGroups()
        selectTagGroup(tagGroup, displayMarkup)
      }

      function selectTagGroup (tagGroup, displayMarkup = true) {
        tagGroup.selected = true
        scope.currentlySelectedGroup = tagGroup
        scope.currentlySelectedTag = null
        if (displayMarkup) { $rootScope.$broadcast('sv-tag-show-markup', tagGroup.models) }
      }

      function unselectAllComments (comments, canUpdate) {
        if (canUpdate) {
          comments.unselectAllAndPersistChanges($stateParams.assetId)
        } else {
          comments.unselectAll()
        }
      }

      function handleTagClickEventHandler (evt, tag) {
        handleTagClick(tag)
      }

      function addTag () {
        if (!scope.newTag.text) {
          return
        }
        createTag(scope.newTag)
      }

      function addTagToAsset () {
        scope.modal.open('addTagToAsset', {
          project: getProject(),
          asset: scope.asset,
          createTagCallback: (tagDefId, text, parentTagDefId, startTime, endTime) => {
            initNewTag()
            scope.newTag.tagDefId = tagDefId
            scope.newTag.text = text
            scope.newTag.startTime = startTime
            scope.newTag.endTime = endTime
            if (parentTagDefId) {
              scope.newTag.parentTagDefId = parentTagDefId
            }
            createTag(scope.newTag)
          }
        })
      }

      function handleSelectedTagDefinition (tag) {
        if (isCreatingTag) {
          return
        }
        if (!tag || !tag.id) {
          return
        }
        let tagObj = tag
        scope.newTag.tagDefId = tagObj.id
        scope.newTag.text = _.trim(tagObj.text || tagObj)

        isCreatingTag = true
        createTag(scope.newTag)
      }

      function showTagOptions ($event, tag) {
        scope.flyout.open('tagOptions', {
          asset: scope.asset,
          onTagDeleted: onTagDeleted,
          tag: tag,
          tagCollection: scope.assetTags,
          parentElement: $event.currentTarget,
          currentProject: scope.currentProject,
          onVerify: onVerify,
          onUnverify: onUnverify,
          onReject: onReject,
          onDelete: onDelete,
          direction: 'left'
        })
      }

      function onVerify (tag) {
        if (tag.tagConfidenceLevel === TAG_CONFIDENCE_LEVEL_LOW) {
          scope.asset.totalCountTagConfidenceMedium++
          scope.asset.totalCountTagConfidenceHigh++
        } else if (tag.tagConfidenceLevel === TAG_CONFIDENCE_LEVEL_MEDIUM) {
          scope.asset.totalCountTagConfidenceHigh++
        }
      }

      function onUnverify (tag) {
        tag.tagConfidenceLevel = tag.originalTagConfidenceLevel
        if (tag.originalTagConfidenceLevel === TAG_CONFIDENCE_LEVEL_LOW) {
          scope.asset.totalCountTagConfidenceMedium--
          scope.asset.totalCountTagConfidenceHigh--
        } else if (tag.originalTagConfidenceLevel === TAG_CONFIDENCE_LEVEL_MEDIUM) {
          scope.asset.totalCountTagConfidenceHigh--
        }
      }

      function onReject (tag) {
        if (tag.tagConfidenceLevel === TAG_CONFIDENCE_LEVEL_LOW) {
          scope.asset.totalCountTagConfidenceLow--
        } else if (tag.tagConfidenceLevel === TAG_CONFIDENCE_LEVEL_MEDIUM) {
          scope.asset.totalCountTagConfidenceMedium--
          scope.asset.totalCountTagConfidenceLow--
        } else {
          scope.asset.totalCountTagConfidenceMedium--
          scope.asset.totalCountTagConfidenceLow--
          scope.asset.totalCountTagConfidenceHigh--
        }
      }

      function onDelete (tag) {
        scope.asset.totalCountTagConfidenceHigh--
        scope.asset.totalCountTagConfidenceMedium--
        scope.asset.totalCountTagConfidenceLow--
      }

      function onTagDeleted (tag) {
        let searchTags = []
        let searchFreeTextTerms = []
        if (scope.assets.searchContext) {
          searchTags = _.map(scope.assets.searchContext.searchTags, (searchTag) => {
            return searchTag.text
          })

          searchFreeTextTerms = scope.assets.searchContext.textFragments
        }

        let sameNameTags = _.filter(scope.assetTags.models, (assetTagModel) => {
          return assetTagModel.text === tag.text
        })

        if (sameNameTags.length === 0 && (searchFreeTextTerms.includes(tag.text) || searchTags.includes(tag.text))) {
          scope.assets.removeById(scope.asset.id)
          scope.assets.totalAssetCount = scope.assets.length
        }
      }

      function keyPressTagStartTime (tag) {
        if (_.isNumber(tag.startTime)) {
          smartvidApi.updateTagForAsset(tag)
        }
      }

      function keyPressTagEndTime (tag) {
        if (_.isNumber(tag.endTime)) {
          smartvidApi.updateTagForAsset(tag)
        }
      }

      // Provenance Indicator
      function position (instance, helper, position) {
        position.coord.top -= 45
        position.coord.left -= 35
        return position
      }

      function showToolsOptions ($event) {
        scope.flyout.open('toolsOptions', {
          parentElement: $event.currentTarget,
          direction: 'top',
          newTag: scope.assetTags.getSelectedTag(),
          newComment: null
        })
      }

      function getProject () {
        let project = dashboardDataHelper.getCurrentProject()
        if (project) {
          return project
        }
        let projects = dashboardDataHelper.getAllProjects()
        let asset = dashboardDataHelper.getCurrentAsset()
        project = _.findWhere(projects.models, {id: asset.projectId})
        return project
      }

      function verifyTag (event, ti) {
        onVerify(ti)
        if (event) {
          event.preventDefault()
          event.stopPropagation()
        }

        let nextTagToValidate = getNextUnverifiedTag(ti, scope.groupedTagInstances)
        tagVerificationService.verifyTag(ti, scope.asset).then(() => {
          updateCurrentlySelectedTagByValues(ti)
          selectTagAndPersistChanges(nextTagToValidate)
        })
      }

      function updateCurrentlySelectedTagByValues (tag) {
        if (angular.isDefined(scope.currentlySelectedTag)) {
          scope.currentlySelectedTag.update(tag)
        }
      }

      function rejectTag (event, ti) {
        if (event) {
          event.preventDefault()
          event.stopPropagation()
        }

        let nextTagToValidate = getNextUnverifiedTag(ti, scope.groupedTagInstances)
        if (ti.status !== 'PENDING_VERIFICATION') {
          return
        }
        tagVerificationService.rejectTag(ti, scope.assetTags, scope.asset).then(() => {
          selectTagAndPersistChanges(nextTagToValidate)
          onReject(ti)
        })
      }

      function getNextUnverifiedTag (currTag, groupedTagInstances) {
        let unverifiedTags = _.chain(groupedTagInstances)
            .map((tagOrGroup) => { return isTagInstanceGroup(tagOrGroup) ? tagOrGroup.models : tagOrGroup })
            .flatten(true)
            .where({status: 'PENDING_VERIFICATION'})
            .value()
        if (unverifiedTags && unverifiedTags.length) {
          for (let i = 0; i < unverifiedTags.length - 1; i++) {
            if (unverifiedTags[i].id === currTag.id) {
              return unverifiedTags[i + 1]
            }
          }
        }
      }

      function canCreate () {
        if ($state.current.name === 'dashboard.adminOrganizations.organizationId.tagsmanager') {
          return true
        } else {
          var org = _.find(currentUser.organizations, org => {
            return org.id === dashboardDataHelper.getCurrentProject().organizationId
          })
          return org && !org.projectLevelTagsDisabled
        }
      }

    }
  }
})
