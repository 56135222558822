/* global angular _ */

angular.module('smartvid').factory('IntegrationSettingsModel', function (BaseModel) {
  class IntegrationSettingsModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        projectId: undefined,
        userId: undefined,
        integrationType: undefined,
        isEnabled: false,
        isRunOnce: false,
        lastSyncDate: -1,
        syncOnlyAfterDate: -1,
        settingsData: {},
        isProcessing: false,
        lastHeartbeat: -1,
        syncConfigParams: {
          syncInterval: -1,
          downloadInterval: -1,
          staleSyncCleanupInterval: -1
        }
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return IntegrationSettingsModel
})
