import { Injectable } from '@angular/core'
import { Actions, State, Store, StateContext, Action } from '@ngxs/store'

export enum ObservationOrganizationHomeContentType {
  NOT_INITIALIZED,
  OBSERVATION_ORGANIZATION_INSIGHTS,
  OBSERVATION_ORGANIZATION_LIST,
}

export interface ObservationOrganizationHomeStateModel {
  organizationId: string
  observationOrganizationHomeContentType: ObservationOrganizationHomeContentType
}

export class ViewObservationOrganizationNone {
  static readonly type = '[ObservationOrganizationHome] ViewObservationOrganizationNone'

  constructor() {}
}

export class ViewObservationOrganizationList {
  static readonly type = '[ObservationOrganizationHome] ViewObservationOrganizationList'

  constructor(public organizationId: string) {}
}

export class ViewObservationOrganizationInsights {
  static readonly type = '[ObservationOrganizationHome] ViewObservationOrganizationInsights'

  constructor(public organizationId: string) {}
}

@State<ObservationOrganizationHomeStateModel>({
  name: 'observationOrganizationHome',
  defaults: {
    organizationId: undefined,
    observationOrganizationHomeContentType: ObservationOrganizationHomeContentType.NOT_INITIALIZED,
  },
})
@Injectable()
export class ObservationOrganizationHomeState {
  constructor(private store: Store, private actions$: Actions) {}

  @Action(ViewObservationOrganizationInsights)
  viewObservationOrganizationInsights(
    ctx: StateContext<ObservationOrganizationHomeStateModel>,
    viewObservationList: ViewObservationOrganizationList
  ) {
    ctx.setState({
      organizationId: viewObservationList.organizationId,
      observationOrganizationHomeContentType: ObservationOrganizationHomeContentType.OBSERVATION_ORGANIZATION_INSIGHTS,
    })
  }

  @Action(ViewObservationOrganizationNone)
  viewObservationOrganizationNone(ctx: StateContext<ObservationOrganizationHomeStateModel>) {
    ctx.setState({
      organizationId: undefined,
      observationOrganizationHomeContentType: ObservationOrganizationHomeContentType.NOT_INITIALIZED,
    })
  }

  @Action(ViewObservationOrganizationList)
  viewObservationOrganizationList(
    ctx: StateContext<ObservationOrganizationHomeStateModel>,
    viewObservationInsights: ViewObservationOrganizationInsights
  ) {
    ctx.setState({
      organizationId: viewObservationInsights.organizationId,
      observationOrganizationHomeContentType: ObservationOrganizationHomeContentType.OBSERVATION_ORGANIZATION_LIST,
    })
  }
}
