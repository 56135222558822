import * as _ from 'lodash'
import * as moment from 'moment'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { Component, Input, Output, EventEmitter, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core'
import { SelectionModel } from '@angular/cdk/collections'
import { AdminProjectWithIntegrationSettings } from '../../models/admin.project.with.integration.settings.model'
import { TranslateService } from '@ngx-translate/core'
import { IntegrationType } from '../../../../shared/models/integration-type'
import {
  IntegrationSettingsFilterInputChangeEvent,
  IntegrationSettingsDisconnectedEvent,
  IntegrationSettingsEnableEvent,
} from '../../models/admin.integration.settings.events'
import { StateService } from '@uirouter/core'
import { Subscription } from 'rxjs'
import { AdminIntegrationSettingsControlBarActions } from '../admin-organization-integrations-control-bar/admin.integration.settings.control.bar.actions'
import { DialogUtilsService } from 'modules/core/services/dialog-utils.service'
import { ConfirmDisconnectIntegrationsDialogComponent } from './confirm-disconnect-integrations-dialog/confirm-disconnect-integrations-dialog.component'
import { first } from 'rxjs/operators'

@Component({
  selector: 'sv-admin-organization-integrations-table',
  templateUrl: 'admin-organization-integrations-table.component.html',
  styleUrls: ['admin-organization-integrations-table.component.scss'],
})
export class AdminOrganizationIntegrationsTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() projectsWithIntegrationSettings: AdminProjectWithIntegrationSettings[]
  @Input() integrationType: IntegrationType

  enableSubscription: Subscription
  disableSubscription: Subscription
  disconnectSubscription: Subscription

  selection = new SelectionModel<AdminProjectWithIntegrationSettings>(true, [])
  public dataSource: MatTableDataSource<AdminProjectWithIntegrationSettings>

  @ViewChild(MatPaginator, { static: false }) protected paginator: MatPaginator
  @ViewChild(MatSort, { static: false }) sort: MatSort

  filter: Filter = new Filter()

  mouseOverMenuIntegrationSettingsId: string

  @Output() integrationSettingsDisconnectedChange = new EventEmitter<IntegrationSettingsDisconnectedEvent>()
  @Output() integrationSettingsEnableChange = new EventEmitter<IntegrationSettingsEnableEvent>()

  public displayedColumns: string[] = ['checkbox', 'name', 'connectedBy', 'integration', 'status', 'lastSync', 'menu']

  NavController: any

  INTEGRATIONS_ROUTES = {}

  constructor(
    private dialogUtilsService: DialogUtilsService,
    private adminIntegrationSettingsControlBarActions: AdminIntegrationSettingsControlBarActions,
    private stateService: StateService,
    private translate: TranslateService
  ) {
    this.INTEGRATIONS_ROUTES[IntegrationType.STRUCTION_SITE_SYNC] =
      'dashboard.projects.projectId.integrations.struction_site'
    this.INTEGRATIONS_ROUTES[IntegrationType.PROJECT_PHOTO_SYNC] = 'dashboard.projects.projectId.integrations.procore'
    this.INTEGRATIONS_ROUTES[IntegrationType.BIM360_FIELD_PHOTO_SYNC] =
      'dashboard.projects.projectId.integrations.bim_360_field'
    this.INTEGRATIONS_ROUTES[IntegrationType.EGNYTE_SYNC] = 'dashboard.projects.projectId.integrations.egnyte'
    this.INTEGRATIONS_ROUTES[IntegrationType.FORGE_SYNC] = 'dashboard.projects.projectId.integrations.forge'
    this.INTEGRATIONS_ROUTES[IntegrationType.OXBLUE_SYNC] = 'dashboard.projects.projectId.integrations.oxblue'
    this.INTEGRATIONS_ROUTES[IntegrationType.BOX_SYNC] = 'dashboard.projects.projectId.integrations.box'
    this.INTEGRATIONS_ROUTES[IntegrationType.ACONEX_SYNC] = 'dashboard.projects.projectId.integrations.aconex'
    this.INTEGRATIONS_ROUTES[IntegrationType.SHAREPOINT_SYNC] = 'dashboard.projects.projectId.integrations.sharepoint'
  }

  ngOnInit(): void {
    this.enableSubscription = this.adminIntegrationSettingsControlBarActions.enableIntegrations$.subscribe(() => {
      this.setEnabledForSelected(true)
    })

    this.disableSubscription = this.adminIntegrationSettingsControlBarActions.disableIntegrations$.subscribe(() => {
      this.setEnabledForSelected(false)
    })
    this.disconnectSubscription = this.adminIntegrationSettingsControlBarActions.disconnectIntegrations$.subscribe(
      () => {
        this.openConfirmDisconnectDialog(this.selection.selected)
      }
    )

    if (this.projectsWithIntegrationSettings) {
      this.setData(this.projectsWithIntegrationSettings)
    }
  }

  setData(projectsWithIntegrationSettings: AdminProjectWithIntegrationSettings[]) {
    this.projectsWithIntegrationSettings = projectsWithIntegrationSettings
    this.dataSource = new MatTableDataSource(this.projectsWithIntegrationSettings)
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.dataSource.filterPredicate = this.filterTable
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort
    this.dataSource.paginator = this.paginator
    this.dataSource.sortingDataAccessor = (item: AdminProjectWithIntegrationSettings, property) => {
      switch (property) {
        case 'name': {
          return item.project.name
        }
        case 'connectedBy': {
          return this.getUserName(item)
        }
        case 'integration': {
          return this.translate.instant(
            'components.adminOrganizationIntegrations.' + item.integrationSettings.integrationSettings.integrationType
          )
        }
        case 'status': {
          return this.translate.instant(
            'components.adminOrganizationIntegrations.enabled.' + item.integrationSettings.integrationSettings.isEnabled
          )
        }
        case 'lastSync': {
          return item.integrationSettings.integrationSettings.lastSyncDate
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.enableSubscription.unsubscribe()
    this.disableSubscription.unsubscribe()
    this.disconnectSubscription.unsubscribe()
  }

  getUserName(row: AdminProjectWithIntegrationSettings) {
    if (!row.integrationSettings.user.firstName && !row.integrationSettings.user.lastName) {
      return row.integrationSettings.user.email
    }
    return row.integrationSettings.user.firstName + ' ' + row.integrationSettings.user.lastName
  }

  getLastSyncDate(lastSyncDate) {
    if (lastSyncDate > 0) {
      return moment(lastSyncDate).format('MMM DD, YYYY hh:mmA')
    }
    return this.translate.instant('integrations.lastSyncNever')
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    return numSelected === numRows
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear()
    } else {
      this.dataSource.data.forEach(row => this.selection.select(row))
    }
  }

  getStatusColorStyle(row: AdminProjectWithIntegrationSettings) {
    return row.integrationSettings.integrationSettings.isEnabled ? 'status-enabled' : 'status-disabled'
  }

  handleMouseOver(row: AdminProjectWithIntegrationSettings) {
    this.mouseOverMenuIntegrationSettingsId = row.integrationSettings.integrationSettings.id
  }

  handleMouseLeave() {
    this.mouseOverMenuIntegrationSettingsId = undefined
  }

  isEnabled(row: AdminProjectWithIntegrationSettings) {
    return row.integrationSettings.integrationSettings.isEnabled
  }

  viewIntegration(row: AdminProjectWithIntegrationSettings) {
    let route = this.INTEGRATIONS_ROUTES[row.integrationSettings.integrationSettings.integrationType]
    if (!route) {
      throw new Error('Unsupported integration type ' + row.integrationSettings.integrationSettings.integrationType)
    }
    this.stateService.go(route, {
      organizationId: row.project.organizationId,
      projectId: row.project.id,
    })
  }

  disableIntegration(row: AdminProjectWithIntegrationSettings) {
    this.integrationSettingsEnableChange.emit({
      enabled: false,
      integrationSettingsIds: [row.integrationSettings.integrationSettings.id],
    })
  }

  enableIntegration(row: AdminProjectWithIntegrationSettings) {
    this.integrationSettingsEnableChange.emit({
      enabled: true,
      integrationSettingsIds: [row.integrationSettings.integrationSettings.id],
    })
  }

  disconnectIntegration(row: AdminProjectWithIntegrationSettings) {
    this.openConfirmDisconnectDialog([row])
  }

  applyFilter() {
    this.dataSource.filter = JSON.stringify(this.filter)
  }

  onUserFilterChange($event: IntegrationSettingsFilterInputChangeEvent) {
    this.filter.user = $event.value
    this.applyFilter()
  }

  onProjectFilterChange($event: IntegrationSettingsFilterInputChangeEvent) {
    this.filter.projectName = $event.value
    this.applyFilter()
  }

  filterTable(data: AdminProjectWithIntegrationSettings, filterString: string): boolean {
    let currentFilter = JSON.parse(filterString)
    if (currentFilter.projectName) {
      if (!data.project.name.toUpperCase().includes(currentFilter.projectName.toUpperCase())) {
        return false
      }
    }
    if (!currentFilter.user) {
      return true
    }
    if (
      data.integrationSettings.user.firstName &&
      data.integrationSettings.user.firstName.toUpperCase().includes(currentFilter.user.toUpperCase())
    ) {
      return true
    }

    if (
      data.integrationSettings.user.lastName &&
      data.integrationSettings.user.lastName.toUpperCase().includes(currentFilter.user.toUpperCase())
    ) {
      return true
    }

    if (data.integrationSettings.user.firstName && data.integrationSettings.user.lastName) {
      let fullName = data.integrationSettings.user.firstName + ' ' + data.integrationSettings.user.lastName
      fullName = fullName.replace(/  +/g, ' ').toUpperCase()
      let filter = currentFilter.user.replace(/  +/g, ' ').toUpperCase()

      if (fullName.includes(filter)) {
        return true
      }
    }

    if (data.integrationSettings.user.email.toUpperCase().includes(currentFilter.user.toUpperCase())) {
      return true
    }
    return false
  }

  getVisibleIntegrationCount() {
    return this.dataSource.filteredData.length
  }

  getSelectedIntegrationCount() {
    return this.selection.selected.length
  }

  setEnabledForSelected(enabled: boolean) {
    let ids: string[] = _.map(this.selection.selected, i => {
      //i.isEnabled = enabled
      return i.integrationSettings.integrationSettings.id
    })

    this.integrationSettingsEnableChange.emit({
      enabled: enabled,
      integrationSettingsIds: ids,
    })
  }

  openConfirmDisconnectDialog(integrationSettings: AdminProjectWithIntegrationSettings[]): void {
    let ids: string[] = _.map(integrationSettings, i => {
      return i.integrationSettings.integrationSettings.id
    })

    const dialogRef = this.dialogUtilsService.open(ConfirmDisconnectIntegrationsDialogComponent, {
      width: '500px',
      height: '250px',
      data: {
        integrationCount: integrationSettings.length,
      },
    })
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(cancel => {
        if (!cancel) {
          let idsSet = new Set(ids)

          _.each(this.dataSource.data, i => {
            if (idsSet.has(i.integrationSettings.integrationSettings.id)) {
              this.selection.deselect(i)
            }
          })

          this.dataSource.data = _.filter(this.dataSource.data, i => {
            return !idsSet.has(i.integrationSettings.integrationSettings.id)
          })

          this.integrationSettingsDisconnectedChange.emit({
            integrationSettingsIds: ids,
          })
        }
      })
  }
}

class Filter {
  projectName: string
  user: string
}
