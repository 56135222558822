import { BootstrapComponent } from 'modules/core/components/bootstrap/bootstrap.component'
import { downgradeComponent } from '@angular/upgrade/static'

import 'angular-input-highlight'
import '@uirouter/angular-hybrid'
import 'angular-cookies'
import 'angular-sanitize'
import 'angular-moment'
import 'ng-infinite-scroll'
import 'ng-i18next'
import 'angular-animate'
import 'angular-ui-notification'
import 'angular-ladda'
import 'angular-spinner'
import 'angular-tree-control'
import 'angular-svg-round-progressbar'
import 'angular-messages'
import 'angular-hotkeys'
import 'angular-slick-carousel'
import 'angular-ui-grid'
import 'angular-material'
import 'angular-aria'
import 'openseadragon'
import 'devextreme/viz/circular_gauge'
import 'devextreme/integration/jquery'
import 'devextreme/integration/angular'
import '@uirouter/angularjs/release/stateEvents'
import 'angular-tree-control/context-menu'
import 'angular-ui-tree/dist/angular-ui-tree'
import 'angular-scroll-rtl/dist/angular-scroll-rtl'
import 'ng-tags-input/ng-tags-input'
import 'angucomplete-alt/angucomplete-alt'
import 'angular-tileview/tileview'
import 'angular-daterangepicker/js/angular-daterangepicker'

const dependencies = [
  'input-highlight',
  'ui.router',
  'ui.router.state.events',
  'ui.router.upgrade',
  'ngTagsInput',
  'ngCookies',
  'ngSanitize',
  'angucomplete-alt',
  'angularMoment',
  'infinite-scroll',
  'jm.i18next',
  'ngAnimate',
  'ui-notification',
  'ui.tree',
  'angular-ladda',
  'angularSpinner',
  'treeControl',
  'angular-svg-round-progress',
  'ngMessages',
  'cfp.hotkeys',
  'slickCarousel',
  'td.scroll',
  'td.tileview',
  'daterangepicker',
  'ui.grid',
  'ui.grid.saveState',
  'ui.grid.treeView',
  'ui.grid.selection',
  'ui.grid.autoResize',
  'ui.grid.cellNav',
  'ui.grid.edit',
  'ui.grid.rowEdit',
  'ui.grid.resizeColumns',
  'ui.grid.pagination',
  'ui.grid.infiniteScroll',
  'ngMaterial',
  'dx',
  'templates',
]

const MODULE_NAME = 'smartvid'

let appModule = angular
  .module(MODULE_NAME, dependencies)
  .directive('svBootstrapAngular', downgradeComponent({ component: BootstrapComponent }) as angular.IDirectiveFactory)

require('./downgradedComponents.js')
require('./downgradedServices.js')
require('./templates.js')
require('./constants.js')
require('./app-config.js')
require('./config/routes.ts')

importAll(require.context('./collections', true, /.*\.js/))
importAll(require.context('./components', true, /.*\.js/))
importAll(require.context('./config', true, /.*\.js/))
importAll(require.context('./constants', true, /.*\.js/))
importAll(require.context('./controllers', true, /.*\.js/))
importAll(require.context('./directives', true, /.*\.js/))
importAll(require.context('./filters', true, /.*\.js/))
importAll(require.context('./models', true, /.*\.js/))
importAll(require.context('./services', true, /.*\.js/))

function importAll(r) {
  r.keys().forEach(r)
}

export default appModule
