import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { TranslateModule } from '@ngx-translate/core'

import { CommonModule } from '@angular/common'
import { SmartvidMaterialModule } from 'modules/material/material.module'

import { FormlyModule } from '@ngx-formly/core'
import { FormlyMaterialModule } from '@ngx-formly/material'

import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component'
import { MediaViewerComponent, SmartvidPlayerDirective } from './components/media-viewer/media-viewer.component'
import {
  MediaViewerDialogComponent,
  MediaViewerDialogContentsComponent,
} from './components/media-viewer-dialog/media-viewer-dialogue.component'

import { LeftNavButtonComponent } from './components/left-nav-button/left-nav-button.component'
import { RightNavButtonComponent } from './components/right-nav-button/right-nav-button.component'
import { ProjectGridDirective } from 'shared/components/project-grid/project-grid.component'
import { VideoGridDirective } from 'shared/components/video-grid/video-grid.component'
import { AssetGridControlBarDirective } from 'shared/components/asset-grid-controlbar/asset-grid-controlbar.component'
import { ProjectGridControlbarDirective } from 'shared/components/project-grid-controlbar/project-grid-controlbar.component'
import { ConfirmDialogComponent } from 'shared/components/confirm-dialog/confirm-dialog.component'

@NgModule({
  imports: [
    CommonModule,
    SmartvidMaterialModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
  ],
  declarations: [
    MediaViewerComponent,
    SmartvidPlayerDirective,
    ProjectGridDirective,
    VideoGridDirective,
    AssetGridControlBarDirective,
    ProjectGridControlbarDirective,
    MediaViewerDialogContentsComponent,
    MediaViewerDialogComponent,
    LeftNavButtonComponent,
    RightNavButtonComponent,
    DialogHeaderComponent,
    ConfirmDialogComponent,
  ],
  exports: [
    MediaViewerComponent,
    SmartvidPlayerDirective,
    ProjectGridDirective,
    VideoGridDirective,
    AssetGridControlBarDirective,
    ProjectGridControlbarDirective,
    MediaViewerDialogComponent,
    LeftNavButtonComponent,
    RightNavButtonComponent,
    DialogHeaderComponent,
  ],
  entryComponents: [MediaViewerDialogContentsComponent],
})
export class AppSharedModule {}
