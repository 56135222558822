/* global angular,analytics,_ */
angular.module('smartvid').component('adminOrganizationProfile', {
  templateUrl: 'AdminOrganizationsGrid/admin-organization-profile.html',
  bindings: {
    organization: '<'
  },
  controller: function (MAX_ORGANIZATION_NAME_LENGTH, MAX_ORGANIZATION_DESCRIPTION_LENGTH,
    organizationsApi, projectGroupApi, dashboardDataHelper, $q, modal) {
    let ctrl = this
    ctrl.MAX_ORGANIZATION_NAME_LENGTH = MAX_ORGANIZATION_NAME_LENGTH
    ctrl.MAX_ORGANIZATION_DESCRIPTION_LENGTH = MAX_ORGANIZATION_DESCRIPTION_LENGTH
    ctrl.inTransit = false
    ctrl.thumbnailImg = undefined
    ctrl.logoImg = undefined
    ctrl.isClearThumbnail = false
    ctrl.isClearLogo = false
    ctrl.groupName = ''
    ctrl.$onInit = onInit
    ctrl.updateOrganization = updateOrganization
    ctrl.clearThumbnail = clearThumbnail
    ctrl.clearLogo = clearLogo
    ctrl.addGroup = addGroup
    ctrl.removeGroup = removeGroup

    function onInit () {
      ctrl.organizationCopy = angular.copy(ctrl.organization)
    }

    function updateOrganization () {
      analytics.track('Update Organization Profile', {
        category: 'Organization Action',
        orgName: ctrl.organization.name
      })
      ctrl.inTransit = true
      let thumbnail = ctrl.thumbnailImg ? ctrl.thumbnailImg.file : undefined
      let logo = ctrl.logoImg ? ctrl.logoImg.file : undefined
      ctrl.isClearThumbnail = ctrl.thumbnailImg ? false : ctrl.isClearThumbnail
      ctrl.isClearLogo = ctrl.logoImg ? false : ctrl.isClearLogo

      let updateOrgPromise = organizationsApi.updateOrganization(
        ctrl.organization.id,
        ctrl.organizationCopy.name, ctrl.organizationCopy.description, ctrl.organization.signUpMode,
        (ctrl.organization.emailDomains && ctrl.organization.emailDomains.length > 0)
        ? ctrl.organization.emailDomains.join(',') : undefined,
        ctrl.organization.publicLinksEnabled,
        ctrl.organization.ssoLoginUrl,
        thumbnail, logo, ctrl.isClearThumbnail, ctrl.isClearLogo)

      let promises = [updateOrgPromise]
      for (let i = 0; i < ctrl.organizationCopy.projectGroups.length; i++) {
        let projectGroupCopy = ctrl.organizationCopy.projectGroups[i]
        let projectGroup = _.findWhere(ctrl.organization.projectGroups, {id: projectGroupCopy.id})
        if (projectGroupCopy.name !== projectGroup.name) {
          promises.push(
            organizationsApi.updateProjectGroup(ctrl.organization.id, projectGroupCopy.id, projectGroupCopy.name)
          )
        }
      }
      $q.all(promises).then(() => {
        let updatedOrg = dashboardDataHelper.getOrganizationByOrgId(ctrl.organization.id)
        ctrl.organizationCopy = angular.copy(updatedOrg)
        ctrl.inTransit = false
      }, () => {
        ctrl.inTransit = false
      })
    }

    function clearThumbnail () {
      ctrl.organizationCopy.thumbnailUrl = undefined
      ctrl.thumbnailImg = undefined
      ctrl.isClearThumbnail = true
    }

    function clearLogo () {
      ctrl.organizationCopy.logoUrl = undefined
      ctrl.logoImg = undefined
      ctrl.isClearLogo = true
    }

    function addGroup () {
      organizationsApi.addProjectGroupToOrganization(ctrl.organization.id, ctrl.groupName).then((org) => {
        ctrl.organizationCopy = angular.copy(org)
        ctrl.groupName = ''
      })
    }

    function removeGroup (projectGroupId) {
      let projectExist = _.findWhere(dashboardDataHelper.getAllProjects().models, {
        projectGroupId: projectGroupId
      })
      if (projectExist) {
        let group = dashboardDataHelper.getProjectGroup(ctrl.organization.id, projectGroupId)
        modal.open('projectsExistWarning', {
          groupName: group.name
        })
        return
      }
      modal.open('removeProjectGroup', {
        orgId: ctrl.organization.id,
        projectGroupId: projectGroupId,
        callback: () => {
          ctrl.organizationCopy = angular.copy(ctrl.organization)
        }
      })
    }
  }
})

