import { ConfigService } from './config.service'
import {
  AssetProducer,
  ASSETS_COLLECTION_PRODUCER,
  ASSET_PRODUCER,
  AssetsCollectionProducer,
  CURRENT_USER,
  CurrentUser,
  FILE_UPLOAD_SERVICE,
  FileUploadService,
  HTTP,
  SMARTVID_API,
  SmartvidApi,
  NAV_CONTROLLER,
  NavController,
  UTILS,
  Utils,
  PROJECT_FAVORITE_SERVICE,
  ProjectFavoriteService,
  SearchService,
  SEARCH_SERVICE,
} from 'shared/smartvid.types'
import { DashboardDataHelperService } from './dashboard-data-helper.service'

export function configServiceFactory(i: any): ConfigService {
  return i.get('config')
}

export function projectFavoriteServiceFactory(i: any): ProjectFavoriteService {
  return i.get('projectFavoriteService')
}

export function currentUserServiceFactory(i: any): CurrentUser {
  return i.get('currentUser')
}

export function dashboardDataHelperServiceFactory(i: any): DashboardDataHelperService {
  return i.get('dashboardDataHelper')
}

export function smartvidApiServiceFactory(i: any): SmartvidApi {
  return i.get('smartvidApi')
}

export function httpServiceFactory(i: any): ng.IHttpService {
  return i.get('$http')
}

export function assetsCollectionProducerServiceFactory(i: any): AssetsCollectionProducer {
  return i.get('assetsCollectionProducer')
}

export function assetProducerServiceFactory(i: any): AssetProducer {
  return i.get('assetProducer')
}

export function fileUploadServiceFactory(i: any): FileUploadService {
  return i.get('fileUploadService')
}

export function utilsServiceFactory(i: any): Utils {
  return i.get('utils')
}

export function navControllerFactory(i: any): NavController {
  return i.get('NavController')
}

export function searchServiceFactory(i: any): SearchService {
  return i.get('searchService')
}

export const projectFavoriteServiceProvider = {
  provide: PROJECT_FAVORITE_SERVICE,
  useFactory: projectFavoriteServiceFactory,
  deps: ['$injector'],
}

export const navControllerProvider = {
  provide: NAV_CONTROLLER,
  useFactory: navControllerFactory,
  deps: ['$injector'],
}

export const configServiceProvider = {
  provide: ConfigService,
  useFactory: configServiceFactory,
  deps: ['$injector'],
}

export const currentUserServiceProvider = {
  provide: CURRENT_USER,
  useFactory: currentUserServiceFactory,
  deps: ['$injector'],
}

export const httpServiceProvider = {
  provide: HTTP,
  useFactory: httpServiceFactory,
  deps: ['$injector'],
}

export const dashboardDataHelperServiceProvider = {
  provide: DashboardDataHelperService,
  useFactory: dashboardDataHelperServiceFactory,
  deps: ['$injector'],
}

export const smartvidApiServiceProvider = {
  provide: SMARTVID_API,
  useFactory: smartvidApiServiceFactory,
  deps: ['$injector'],
}

export const assetsCollectionProducerServiceProvider = {
  provide: ASSETS_COLLECTION_PRODUCER,
  useFactory: assetsCollectionProducerServiceFactory,
  deps: ['$injector'],
}

export const assetProducerServiceProvider = {
  provide: ASSET_PRODUCER,
  useFactory: assetProducerServiceFactory,
  deps: ['$injector'],
}

export const fileUploadServiceProvider = {
  provide: FILE_UPLOAD_SERVICE,
  useFactory: fileUploadServiceFactory,
  deps: ['$injector'],
}

export const utilsProvider = {
  provide: UTILS,
  useFactory: utilsServiceFactory,
  deps: ['$injector'],
}

export const searchServiceProvider = {
  provide: SEARCH_SERVICE,
  useFactory: searchServiceFactory,
  deps: ['$injector'],
}
