/* global angular _ $ */

angular.module('smartvid').service('projectIntegrationUtils', function (
    $timeout, $window, $document, $injector, $filter, $interval, Notification, smartvidApi, utils,
    IntegrationSettingsModel, currentUser, moment, modal
) {
  const UNAUTHORIZED = 'UNAUTHORIZED'
  const TOKEN_ACQUISITION_ERROR = 'integration.oauth.error.token_acquisition'
  // This message is used for OAuth popup -> our application communication to pass through OAuth workflow errors.
  // The message is sent by the JavaScript code generated by our backend in response to OAuth callback.
  $window.addEventListener('message', (msg) => {
    if (!!msg.data && _.has(msg.data, 'type') && msg.data.type === 'Smartvid.io') {
      Notification.error(msg.data.msg)
    }
  }, false)

  let hasErrorMessageWithLabel = (messages, label) => {
    return messages ? _.find(messages, (m) => {
      return m.label === label
    }) !== undefined : false
  }

  let checkIfAuthenticationTokenExpired = (integrationSettingsState, code, messages) => {
    integrationSettingsState.isAuthenticationTokenExpired = code === UNAUTHORIZED && hasErrorMessageWithLabel(messages, TOKEN_ACQUISITION_ERROR)
    return integrationSettingsState.isAuthenticationTokenExpired
  }

  let checkIntegrationStatusAndLoadData = (integrationSettingsState, smartvidProjectId, providerName, integrationType, isAfterOAuth) => {
    integrationSettingsState.isLoadingData = true
    smartvidApi.getOAuthUserDataForProviderForCurrentUser(providerName).then((data) => {
      integrationSettingsState.waitingForIntegrationStatus = false
      integrationSettingsState.waitingForPartnerIntegrationStatus = false
      integrationSettingsState.currentUserAuthenticatedWithPartner = data.length > 0 ? data[0] : undefined
      integrationSettingsState.isAuthenticationTokenExpired = false
      if (data.length === 0 && isAfterOAuth) {
        integrationSettingsState.isLoadingData = false
        Notification.error($filter('i18next')('directives.mysettings.' + providerName.toLowerCase() + '.failedToEstablishIntegrationStatus'))
        return
      }
      loadPageData(integrationSettingsState, smartvidProjectId, providerName, integrationType, isAfterOAuth)
    }, () => {
      integrationSettingsState.isLoadingData = false
      integrationSettingsState.waitingForOAuthIntegrationStatus = false
      integrationSettingsState.waitingForPartnerIntegrationStatus = false
      Notification.error($filter('i18next')('directives.mysettings.' + providerName.toLowerCase() + '.failedToCheckIntegrationStatus'))
    })
  }

  let isPartnerIntegrationEnabledForProject = (integrationSettingsState) => {
    return (integrationSettingsState.isPartnerIntegrationEnabledByCurrentUser || integrationSettingsState.isPartnerIntegrationEnabledByOtherUser)
  }

  let isSyncNowEnabled = (integrationSettingsState) => {
    return isPartnerIntegrationEnabledForProject() && integrationSettingsState.isPartnerDataComplete() && !integrationSettingsState.isPartnerIntegrationEnabledByOtherUser && !integrationSettingsState.hasRateLimitError
  }

  let isConnectedByAnotherSmartvidUser = (integrationSettingsState) => {
    return integrationSettingsState.partnerIntegrationSettings.userId !== undefined && integrationSettingsState.partnerIntegrationSettings.userId !== currentUser.id
  }

  let isConnectedByCurrentSmartvidUser = (integrationSettingsState) => {
    return integrationSettingsState.partnerIntegrationSettings.userId !== undefined && integrationSettingsState.partnerIntegrationSettings.userId === currentUser.id
  }

  let isConnectedByAnotherPartnerUser = (integrationSettingsState) => {
    return integrationSettingsState.syncSettings.partnerUserId !== undefined &&
      (!integrationSettingsState.currentUserAuthenticatedWithPartner || integrationSettingsState.syncSettings.partnerUserId !== integrationSettingsState.currentUserAuthenticatedWithPartner.externalUserId)
  }

  let isAutoConnect = (integrationSettingsState) => {
    return integrationSettingsState &&
      integrationSettingsState.currentUserAuthenticatedWithPartner &&
      !integrationSettingsState.isPartnerIntegrationEnabledByCurrentUser &&
      !integrationSettingsState.isPartnerIntegrationEnabledByOtherUser
  }

  let isConnectedByCurrentlyAuthenticatedPartnerUser = (integrationSettingsState) => {
    return integrationSettingsState.syncSettings.partnerUserId !== undefined &&
      integrationSettingsState.currentUserAuthenticatedWithPartner !== undefined &&
      integrationSettingsState.syncSettings.partnerUserId === integrationSettingsState.currentUserAuthenticatedWithPartner.externalUserId
  }

  let isReadOnly = (integrationSettingsState) => {
    let ret = !integrationSettingsState.partnerIntegrationSettings || integrationSettingsState.partnerIntegrationSettings.isEnabled ||
      isConnectedByAnotherPartnerUser(integrationSettingsState) ||
      !isPartnerIntegrationEnabledForProject(integrationSettingsState)
    return ret
  }

  let getPartnerUserId = (integrationSettingsState) => {
    if (integrationSettingsState.isPartnerIntegrationEnabledByOtherUser &&
      integrationSettingsState.partnerIntegrationSettings.settingsData.partnerUserId !== undefined) {
      return integrationSettingsState.partnerIntegrationSettings.settingsData.partnerUserId
    } else if (integrationSettingsState.currentUserAuthenticatedWithPartner !== undefined) {
      return integrationSettingsState.currentUserAuthenticatedWithPartner.externalUserId
    } else {
      return undefined
    }
  }

  let enableIntegration = (integrationSettingsState, smartvidProjectId, providerName, integrationType) => {
    integrationSettingsState.partnerIntegrationSettings.isEnabled = false
    integrationSettingsState.partnerIntegrationSettings.userId = integrationSettingsState.currentUserAuthenticatedWithPartner.userId
    integrationSettingsState.partnerIntegrationSettings.projectId = smartvidProjectId
    integrationSettingsState.partnerIntegrationSettings.settingsData.partnerUserId = integrationSettingsState.currentUserAuthenticatedWithPartner.externalUserId
    smartvidApi.saveIntegrationSettings(integrationType, integrationSettingsState.partnerIntegrationSettings).then(() => {
      integrationSettingsState.isPartnerIntegrationEnabledByCurrentUser = true
      integrationSettingsState.partnerIntegrationNotEnabled = false
      integrationSettingsState.loadPageData()
    }, () => {
      integrationSettingsState.isLoadingData = false
      integrationSettingsState.waitingForPartnerIntegrationStatus = false
      Notification.error($filter('i18next')('integrations.' + providerName.toLowerCase() + '.errors.failedToLoadSyncSettings'))
    })
  }

  let prepareSyncSettingsIfNeededAndContinueLoadingData = (integrationSettingsState, smartvidProjectId, providerName, integrationType, isAfterAuth) => {
    if (isPartnerIntegrationEnabledForProject(integrationSettingsState)) {
      integrationSettingsState.loadPageData()
    } else if (integrationSettingsState.currentUserAuthenticatedWithPartner && isAfterAuth) {
      enableIntegration(integrationSettingsState, smartvidProjectId, providerName, integrationType)
    } else {
      integrationSettingsState.isLoadingData = false
    }
  }

  let loadPageData = (integrationSettingsState, smartvidProjectId, providerName, integrationType, isAfterOAuth) => {
    integrationSettingsState.isLoadingData = true
    smartvidApi.getIntegrationSettingsForProject(integrationType, smartvidProjectId).then((data) => {
      integrationSettingsState.partnerIntegrationSettings =
          _.extend(new IntegrationSettingsModel(data), integrationSettingsState.partnerIntegrationSettings)
      integrationSettingsState.partnerIntegrationSettings.integrationType = integrationType

      integrationSettingsState.syncSettings = integrationSettingsState.createPartnerSyncSettings(data.settingsData)
      integrationSettingsState.waitingForOAuthIntegrationStatus = false

      integrationSettingsState.isPartnerIntegrationEnabledByOtherUser =
        isConnectedByAnotherPartnerUser(integrationSettingsState) || isConnectedByAnotherSmartvidUser(integrationSettingsState)
      integrationSettingsState.isPartnerIntegrationEnabledByCurrentUser =
        isConnectedByCurrentlyAuthenticatedPartnerUser(integrationSettingsState) && isConnectedByCurrentSmartvidUser(integrationSettingsState)
      integrationSettingsState.partnerIntegrationNotEnabled = !integrationSettingsState.syncSettings.partnerUserId

      prepareSyncSettingsIfNeededAndContinueLoadingData(integrationSettingsState, smartvidProjectId, providerName, integrationType, isAfterOAuth)
    }, () => {
      integrationSettingsState.isLoadingData = false
      integrationSettingsState.waitingForOAuthIntegrationStatus = false
      Notification.error($filter('i18next')('integrations.' + providerName.toLowerCase() + '.errors.failedToLoadSyncSettings'))
    })
  }

  let monitorOAuthPopup = (integrationSettingsState, smartvidProjectId, providerName, integrationType, oauthWindow) => {
    let winCheck = $interval(() => {
      if (!oauthWindow || oauthWindow.closed) {
        $interval.cancel(winCheck)
        checkIntegrationStatusAndLoadData(integrationSettingsState, smartvidProjectId, providerName, integrationType, true)
      }
    }, 300)
  }

  let authenticateWithPartnerAndEnableIntegration = (integrationSettingsState, smartvidProjectId, providerName, integrationType, userId = null) => {
    integrationSettingsState.waitingForForgeIntegrationStatus = true
    let oauthWindow = $window.open('', '_blank', 'width=800,height=600')
    smartvidApi.getOAuthUrlForProvider(providerName, userId).then((data) => {
      oauthWindow.location = data.value
      monitorOAuthPopup(integrationSettingsState, smartvidProjectId, providerName, integrationType, oauthWindow)
    }, (response) => {
      oauthWindow.close()
      if (!utils.isTimeoutResponse(response)) {
        Notification.error($filter('i18next')('directives.mysettings.' + providerName.toLowerCase() + '.failedToEnableIntegration'))
      }
    })
  }

  var directLoginListeners = {}

  let performDirectLoginAndEnableIntegration = (scope, rootScope, eventName, modalName, integrationSettingsState,
                                                smartvidProjectId, providerName, integrationType) => {
    if (!directLoginListeners[eventName]) {
      let listener = rootScope.$on(eventName, () => {
        checkIntegrationStatusAndLoadData(integrationSettingsState, smartvidProjectId, providerName, integrationType, true)
      })
      scope.$on('$destroy', () => {
        listener()
        directLoginListeners[eventName] = null
      })
      directLoginListeners[eventName] = eventName
    }

    modal.open(modalName)
  }

  var LAST_SYNC_NEVER_LABEL
  var NEXT_SYNC_NA
  var SYNC_PENDING_LABEL
  var SYNC_IN_PROGRESS_LABEL

  $timeout(() => {
    LAST_SYNC_NEVER_LABEL = $filter('i18next')('integrations.lastSyncNever')
    NEXT_SYNC_NA = $filter('i18next')('integrations.nextSyncNA')
    SYNC_PENDING_LABEL = $filter('i18next')('integrations.syncPending')
    SYNC_IN_PROGRESS_LABEL = $filter('i18next')('integrations.syncInProgress')
  }, 10)

  let getLastSyncDate = (integrationSettingsState) => {
    if (!integrationSettingsState.partnerIntegrationSettings) {
      return NEXT_SYNC_NA
    }

    if (integrationSettingsState.partnerIntegrationSettings.lastSyncDate > 0) {
      return moment(integrationSettingsState.partnerIntegrationSettings.lastSyncDate).format(
        'MMM DD, YYYY hh:mmA')
    }

    if (integrationSettingsState.partnerIntegrationSettings.isEnabled) {
      return SYNC_PENDING_LABEL
    }

    return LAST_SYNC_NEVER_LABEL
  }

  let getNextSyncDate = (integrationSettingsState) => {
    if (!integrationSettingsState.partnerIntegrationSettings) {
      return NEXT_SYNC_NA
    }

    if (integrationSettingsState.partnerIntegrationSettings.isEnabled && integrationSettingsState.partnerIntegrationSettings.isProcessing) {
      return SYNC_IN_PROGRESS_LABEL
    }

    if (integrationSettingsState.partnerIntegrationSettings.isEnabled) {
      return $filter('i18next')('integrations.scheduled')
    }

    return NEXT_SYNC_NA
  }

  let getTextWidth = (text, font) => {
    let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
    let context = canvas.getContext('2d')
    context.font = font
    let metrics = context.measureText(text)

    return metrics.width
  }

  let isConnectionStatusTextClipped = (fieldId) => {
    let connectionStatusInput = $(fieldId)
    // TODO: brute force approach, hardcoded font family, input width adjustment and padding for possible ellipses
    return connectionStatusInput && connectionStatusInput[0].value ? (getTextWidth(connectionStatusInput[0].value + '...', 'Normal 15px Poppins') >= connectionStatusInput.width() + 2) : false
  }

  let api = {
    authenticateWithPartnerAndEnableIntegration: authenticateWithPartnerAndEnableIntegration,
    performDirectLoginAndEnableIntegration: performDirectLoginAndEnableIntegration,
    checkIntegrationStatusAndLoadData: checkIntegrationStatusAndLoadData,
    isPartnerIntegrationEnabledForProject: isPartnerIntegrationEnabledForProject,
    getPartnerUserId: getPartnerUserId,
    loadPageData: loadPageData,
    isReadOnly: isReadOnly,
    isAutoConnect: isAutoConnect,
    enableIntegration: enableIntegration,
    isSyncNowEnabled: isSyncNowEnabled,
    getNextSyncDate: getNextSyncDate,
    getLastSyncDate: getLastSyncDate,
    isConnectionStatusTextClipped: isConnectionStatusTextClipped,
    checkIfAuthenticationTokenExpired: checkIfAuthenticationTokenExpired
  }

  return api
})
