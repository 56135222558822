/* global angular PROCORE_INTEGRATION_UI_ENABLED BIM_360_FIELD_INTEGRATION_UI_ENABLED, EGNYTE_INTEGRATION_UI_ENABLED,
FORGE_INTEGRATION_UI_ENABLED, OXBLUE_INTEGRATION_UI_ENABLED, BOX_INTEGRATION_UI_ENABLED,
SHAREPOINT_INTEGRATION_UI_ENABLED, ACONEX_INTEGRATION_UI_ENABLED, STRUCTION_SITE_INTEGRATION_UI_ENABLED */

angular.module('smartvid').directive('projectIntegrations', function (
    $filter, $stateParams, $interval, smartvidApi, ProcorePhotoSyncSettingsModel, IntegrationSettingsModel, IntegrationType,
    IntegrationName
) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'integrations/project-integrations.html',
    link: function (scope) {
      scope.sortFunc = sortFunc
      let PROCORE = {
        name: IntegrationName.PROCORE,
        description: 'integrations.procore.title',
        placardImage: '/assets/images/integration/procore/procore-integration-thumbnail.png',
        selected: false,
        isEnabled: false,
        status: ''
      }

      let BIM_360_FIELD = {
        name: IntegrationName.BIM_360_FIELD,
        description: 'integrations.bim360.field.title',
        placardImage: '/assets/images/integration/bim360/field/bim360-field-integration-thumbnail.png',
        selected: false,
        isEnabled: false,
        status: ''
      }

      let EGNYTE = {
        name: IntegrationName.EGNYTE,
        description: 'integrations.egnyte.title',
        placardImage: '/assets/images/integration/egnyte/egnyte-integration-thumbnail.png',
        selected: false,
        isEnabled: false,
        status: ''
      }

      let FORGE = {
        name: IntegrationName.FORGE,
        description: 'integrations.forge.title',
        placardImage: '/assets/images/integration/bim360/bim360-integration-thumbnail.png',
        selected: false,
        isEnabled: false,
        status: ''
      }

      let OXBLUE = {
        name: IntegrationName.OXBLUE,
        description: 'integrations.oxblue.title',
        placardImage: '/assets/images/integration/oxblue/oxblue-integration-thumbnail.png',
        selected: false,
        isEnabled: false,
        status: ''
      }

      let BOX = {
        name: IntegrationName.BOX,
        description: 'integrations.storageService.prettyNames.BOX',
        placardImage: '/assets/images/integration/storageService/box/integration-thumbnail.png',
        selected: false,
        isEnabled: false,
        status: ''
      }

      let SHAREPOINT = {
        name: IntegrationName.SHAREPOINT,
        description: 'integrations.storageService.prettyNames.SHAREPOINT',
        placardImage: '/assets/images/integration/storageService/sharepoint/integration-thumbnail.png',
        selected: false,
        isEnabled: false,
        status: ''
      }

      let ACONEX = {
        name: IntegrationName.ACONEX,
        description: 'integrations.aconex.title',
        placardImage: '/assets/images/integration/aconex/integration-thumbnail.png',
        selected: false,
        isEnabled: false,
        status: ''
      }

      let STRUCTION_SITE = {
        name: IntegrationName.STRUCTION_SITE,
        description: 'integrations.structionSite.title',
        placardImage: '/assets/images/integration/structionSite/integration-thumbnail.png',
        selected: false,
        isEnabled: false,
        status: ''
      }

      scope.providers = []
      if (PROCORE_INTEGRATION_UI_ENABLED) {
        scope.providers.push(PROCORE)
      }
      if (BIM_360_FIELD_INTEGRATION_UI_ENABLED) {
        scope.providers.push(BIM_360_FIELD)
      }
      if (EGNYTE_INTEGRATION_UI_ENABLED) {
        scope.providers.push(EGNYTE)
      }
      if (FORGE_INTEGRATION_UI_ENABLED) {
        scope.providers.push(FORGE)
      }
      if (OXBLUE_INTEGRATION_UI_ENABLED) {
        scope.providers.push(OXBLUE)
      }
      if (BOX_INTEGRATION_UI_ENABLED) {
        scope.providers.push(BOX)
      }
      if (SHAREPOINT_INTEGRATION_UI_ENABLED) {
        scope.providers.push(SHAREPOINT)
      }
      if (ACONEX_INTEGRATION_UI_ENABLED) {
        scope.providers.push(ACONEX)
      }
      if (STRUCTION_SITE_INTEGRATION_UI_ENABLED) {
        scope.providers.push(STRUCTION_SITE)
      }
      scope.isFetching = true

      let fetchCount = scope.providers.length

      let fetchCheck = $interval(() => {
        if (fetchCount <= 0) {
          scope.isFetching = false
          $interval.cancel(fetchCheck)
        }
      }, 500)

      if (PROCORE_INTEGRATION_UI_ENABLED) {
        smartvidApi.getProcorePhotoSyncSettingsForProject($stateParams.projectId).then((data) => {
          scope.photoSyncSettings = new ProcorePhotoSyncSettingsModel(data)
          PROCORE.status = scope.photoSyncSettings.isEnabled === true ? 'integrations.procore.enabled' : 'integrations.procore.disabled'
          PROCORE.isEnabled = scope.photoSyncSettings.isEnabled === true

          fetchCount = fetchCount - 1
        }, () => {
          fetchCount = fetchCount - 1
        })
      }

      if (EGNYTE_INTEGRATION_UI_ENABLED) {
        smartvidApi.getIntegrationSettingsForProject(IntegrationType.EGNYTE_SYNC, $stateParams.projectId).then((data) => {
          scope.egnyteIntegrationSettings = new IntegrationSettingsModel(data)
          EGNYTE.status = scope.egnyteIntegrationSettings.isEnabled === true ? 'integrations.egnyte.enabled' : 'integrations.egnyte.disabled'
          EGNYTE.isEnabled = scope.egnyteIntegrationSettings.isEnabled === true

          fetchCount = fetchCount - 1
        }, () => {
          fetchCount = fetchCount - 1
        })
      }

      if (FORGE_INTEGRATION_UI_ENABLED) {
        smartvidApi.getIntegrationSettingsForProject(IntegrationType.FORGE_SYNC, $stateParams.projectId).then((data) => {
          scope.forgeIntegrationSettings = new IntegrationSettingsModel(data)
          FORGE.status = scope.forgeIntegrationSettings.isEnabled === true ? 'integrations.forge.enabled' : 'integrations.forge.disabled'
          FORGE.isEnabled = scope.forgeIntegrationSettings.isEnabled === true

          fetchCount = fetchCount - 1
        }, () => {
          fetchCount = fetchCount - 1
        })
      }

      if (BIM_360_FIELD_INTEGRATION_UI_ENABLED) {
        smartvidApi.getIntegrationSettingsForProject(IntegrationType.BIM360_FIELD_PHOTO_SYNC, $stateParams.projectId).then((data) => {
          scope.bim360FieldIntegrationSettings = new IntegrationSettingsModel(data)
          BIM_360_FIELD.status = scope.bim360FieldIntegrationSettings.isEnabled === true ? 'integrations.bim360.field.enabled' : 'integrations.bim360.field.disabled'
          BIM_360_FIELD.isEnabled = scope.bim360FieldIntegrationSettings.isEnabled === true

          fetchCount = fetchCount - 1
        }, () => {
          fetchCount = fetchCount - 1
        })
      }

      if (OXBLUE_INTEGRATION_UI_ENABLED) {
        smartvidApi.getIntegrationSettingsForProject(IntegrationType.OXBLUE_SYNC, $stateParams.projectId).then((data) => {
          scope.oxBlueIntegrationSettings = new IntegrationSettingsModel(data)
          OXBLUE.status = scope.oxBlueIntegrationSettings.isEnabled === true ? 'integrations.enabled' : 'integrations.disabled'
          OXBLUE.isEnabled = scope.oxBlueIntegrationSettings.isEnabled === true

          fetchCount = fetchCount - 1
        }, () => {
          fetchCount = fetchCount - 1
        })
      }

      if (BOX_INTEGRATION_UI_ENABLED) {
        smartvidApi.getIntegrationSettingsForProject(IntegrationType.BOX_SYNC, $stateParams.projectId).then((data) => {
          scope.boxIntegrationSettings = new IntegrationSettingsModel(data)
          BOX.status = scope.boxIntegrationSettings.isEnabled === true ? 'integrations.enabled' : 'integrations.disabled'
          BOX.isEnabled = scope.boxIntegrationSettings.isEnabled === true

          fetchCount = fetchCount - 1
        }, () => {
          fetchCount = fetchCount - 1
        })
      }

      if (SHAREPOINT_INTEGRATION_UI_ENABLED) {
        smartvidApi.getIntegrationSettingsForProject(IntegrationType.SHAREPOINT_SYNC, $stateParams.projectId).then((data) => {
          scope.sharepointIntegrationSettings = new IntegrationSettingsModel(data)
          SHAREPOINT.status = scope.sharepointIntegrationSettings.isEnabled === true ? 'integrations.enabled' : 'integrations.disabled'
          SHAREPOINT.isEnabled = scope.sharepointIntegrationSettings.isEnabled === true

          fetchCount = fetchCount - 1
        }, () => {
          fetchCount = fetchCount - 1
        })
      }

      if (ACONEX_INTEGRATION_UI_ENABLED) {
        smartvidApi.getIntegrationSettingsForProject(IntegrationType.ACONEX_SYNC, $stateParams.projectId).then((data) => {
          scope.aconexIntegrationSettings = new IntegrationSettingsModel(data)
          ACONEX.status = scope.aconexIntegrationSettings.isEnabled === true ? 'integrations.enabled' : 'integrations.disabled'
          ACONEX.isEnabled = scope.aconexIntegrationSettings.isEnabled === true

          fetchCount = fetchCount - 1
        }, () => {
          fetchCount = fetchCount - 1
        })
      }

      if (STRUCTION_SITE_INTEGRATION_UI_ENABLED) {
        smartvidApi.getIntegrationSettingsForProject(IntegrationType.STRUCTION_SITE_SYNC, $stateParams.projectId).then((data) => {
          scope.structionSiteIntegrationSettings = new IntegrationSettingsModel(data)
          STRUCTION_SITE.status = scope.structionSiteIntegrationSettings.isEnabled === true ? 'integrations.enabled' : 'integrations.disabled'
          STRUCTION_SITE.isEnabled = scope.structionSiteIntegrationSettings.isEnabled === true

          fetchCount = fetchCount - 1
        }, () => {
          fetchCount = fetchCount - 1
        })
      }

      function sortFunc (provider) {
        return $filter('i18next')(provider.description)
      }
    }
  }
})
