/* global angular analytics _ */

angular.module('smartvid').directive('adminOrganizationSettings', function ($rootScope, $stateParams, $timeout, currentUser, smartvidApi, utils, $q, MAX_SSO_LOGIN_URL_LENGTH) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'AdminOrganizationsGrid/admin-organization-settings.html',
    scope: {
      organization: '='
    },
    link: function (scope) {
      let organization = currentUser.getOrganization($stateParams.organizationId)
      scope.organizationEmailDomains = organization.emailDomains ? organization.emailDomains.toString() : ''
      scope.signUpMode = organization.signUpMode
      scope.inTransit = false
      scope.publicSharingEnabled = organization.publicSharingEnabled
      scope.projectLevelTagsDisabled = organization.projectLevelTagsDisabled
      scope.ssoLoginUrl = organization.ssoLoginUrl
      scope.MAX_SSO_LOGIN_URL_LENGTH = MAX_SSO_LOGIN_URL_LENGTH

      scope.updateOrganizationSettings = updateOrganizationSettings
      scope.emailDomainsFocused = emailDomainsFocused
      scope.validateOrganizationEmailDomains = validateOrganizationEmailDomains

      function updateOrganizationSettings () {
        scope.validateOrganizationEmailDomains().then(() => {
          updateOrganizationSettingsInternal(organization, scope)
        })
      }

      function emailDomainsFocused () {
        scope.showDomainsErrorTooltip = false
        scope.errorsData = undefined
      }

      function validateOrganizationEmailDomains () {
        let emailDomains = getNonEmptyEmailDomains(scope.organizationEmailDomains)
        // cleanup possible trailing commas
        scope.organizationEmailDomains = joinEmailDomains(emailDomains)
        scope.invalidDomains = undefined
        scope.forbiddenDomains = undefined
        scope.existingDomains = undefined
        scope.showDomainsErrorTooltip = false
        scope.errorsData = undefined

        let result = $q.defer()
        if (emailDomains) {
          smartvidApi.validateOrganizationEmailDomains(organization.id, emailDomains).then((data) => {
            scope.invalidDomains = data.invalidDomains.length > 0 ? data.invalidDomains : undefined
            scope.forbiddenDomains = data.forbiddenDomains.length > 0 ? data.forbiddenDomains : undefined
            scope.existingDomains = data.existingDomains.length > 0 ? data.existingDomains : undefined

            if (data.isValid) {
              result.resolve(true)
            } else {
              let allErrorDomains = (angular.isDefined(scope.invalidDomains) ? scope.invalidDomains : [])
                .concat(angular.isDefined(scope.forbiddenDomains) ? scope.forbiddenDomains : [])
                .concat(angular.isDefined(scope.existingDomains) ? scope.existingDomains : [])

              scope.errorsData = {
                '#AD4549': buildErrorDomainMatcher(allErrorDomains)
              }

              scope.showDomainsErrorTooltip = true

              result.reject(false)
            }
          })
        } else {
          result.resolve(true)
        }

        return result.promise
      }

      function updateOrganizationSettingsInternal (organization, scope) {
        analytics.track('Update Organization Settings', {
          category: 'Organization Action',
          orgName: organization.name
        })
        scope.inTransit = true

        let promise = smartvidApi.updateOrganization(
          organization.id,
          organization.name, organization.description, scope.signUpMode,
          joinEmailDomains(getNonEmptyEmailDomains(scope.organizationEmailDomains)),
          scope.publicSharingEnabled,
          scope.ssoLoginUrl,
          undefined, undefined, false, false, scope.projectLevelTagsDisabled)
        promise.then((data) => {
          $timeout(function () {
            scope.inTransit = false
            $rootScope.$broadcast('sv-organization-updated', data)
          }, 100)
        }, () => {
          scope.inTransit = false
        })
        return promise
      }

      function buildErrorDomainMatcher (domains) {
        var matcherStr = ''

        if (domains) {
          _.each(domains, (d) => {
            /*eslint-disable */
            matcherStr += d.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&').trim() + '|'
            /*eslint-enable */
          })
        }

        return new RegExp(matcherStr.replace(/\|$/gi, ''), 'gi')
      }

      function getNonEmptyEmailDomains (domainsStr) {
        return domainsStr ? _.filter(domainsStr.split(','), (d) => {
          return d.trim().length > 0
        }) : undefined
      }

      function joinEmailDomains (domains) {
        return domains ? domains.join(',') : undefined
      }
    }
  }
})
