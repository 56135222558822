/* global angular */

angular.module('smartvid').controller('UserOptionsCtrl', function ($scope, currentUser, $window, flyout, contentSharingContext) {
  $scope.currentUser = currentUser
  $scope.isSharing = contentSharingContext.isSet()

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top + 5,
      left: $scope.styles.left - 85
    }
  }

  $scope.goToHelp = () => {
    flyout.close()
    $window.open('https://cegbu.custhelp.com/app/answers/list/p/895')
  }
})
