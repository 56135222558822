/* global angular */

angular.module('smartvid').controller('AdminOrganizationsGridOptionsCtrl', function ($scope, $rootScope, modal) {
  $scope.deleteSelectedOrganizations = () => {
    let selected = $scope.adminOrganizations.where({selected: true})
    $scope.flyout.isOpen = false
    modal.open('deleteConfirm', {
      confirm () {
        $rootScope.$broadcast('adminOrganizationsGrid:deleteSelected', true)
        $scope.flyout.close()
      },
      hasSelections: selected.length > 0
    })
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top,
      left: $scope.styles.left
    }
  }
})
