import * as _ from 'lodash'
import * as moment from 'moment'
import { Component, Inject, Input } from '@angular/core'
import { getFirstLettersOfWords } from '../../../utils/dashboard-utils'
import {
  ProjectTotalsTableData,
  ProjectTotalsTableUserInfo,
} from 'modules/insights/dashboard/models/insights-dashboard-project-assets.model'
import { SEARCH_SERVICE, SearchService } from 'shared/smartvid.types'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import {
  DashboardCardId,
  DashboardTabId,
  InsightsDashboardParameters,
  NameBasedTableFilter,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { TotalsTableBaseDirective } from 'modules/insights/dashboard/components/totals-table/totals-table-base'

const FORMAT = 'MM/DD/YYYY'
@Component({
  selector: 'sv-project-totals-table',
  templateUrl: 'project-totals-table.component.html',
  styleUrls: ['project-totals-table.component.scss'],
})
export class ProjectTotalsTableComponent extends TotalsTableBaseDirective<ProjectTotalsTableData> {
  private displayedColumns: string[] = ['name', 'files', 'tags', 'comments', 'assetCountsForTimePeriod', 'viewDetails']

  constructor(
    @Inject(SEARCH_SERVICE) private searchService: SearchService,
    private insightsDashboardConfigurationService: InsightsDashboardConfigurationService
  ) {
    super()
    let cardConfig = this.insightsDashboardConfigurationService.getCardConfiguration(
      DashboardTabId.ACTIVITY,
      DashboardCardId.ACTIVITY_BY_USER
    )
    if (cardConfig && cardConfig.customCardData && cardConfig.customCardData.tableColumns) {
      this.displayedColumns = _.filter(cardConfig.customCardData.tableColumns, (column: string) => {
        return this.displayedColumns.includes(column)
      })
    }
  }

  @Input() set tableData(value: ProjectTotalsTableData[]) {
    this.setTableData(value)
  }
  get tableData(): ProjectTotalsTableData[] {
    return this._tableData
  }

  @Input() dashboardParameters: InsightsDashboardParameters

  getInitials(userInfo: ProjectTotalsTableUserInfo) {
    return userInfo.firstName && userInfo.lastName
      ? userInfo.firstName[0] + userInfo.lastName[0]
      : userInfo.firstName
      ? getFirstLettersOfWords(userInfo.firstName, 2, s => s.toUpperCase())
      : getFirstLettersOfWords(userInfo.lastName, 2, s => s.toUpperCase())
  }

  showUserFiles(userInfo: ProjectTotalsTableUserInfo) {
    this.searchService.searchWithAssetSearchContext({
      searchQueryType: 'ANY_TERM',
      searchCreatedByUsers: [
        {
          id: userInfo.id,
          email: userInfo.email,
        },
      ],
      searchTags: [],
      tagConfidenceLevel: 'HIGH',
      getSearchByTagDefs: function() {
        return []
      },
      getSearchByUserIds: function() {
        return _.map(this.searchCreatedByUsers, (u: any) => {
          return u.id
        })
      },
      getSearchByValues: function() {
        return []
      },
      searchDateRange: {
        startDate: moment(this.dashboardParameters.dateRangeStart).format(FORMAT),
        endDate: moment(this.dashboardParameters.dateRangeEnd)
          .subtract(1, 'days')
          .endOf('day')
          .format(FORMAT),
      },
    })
  }

  applyFilter(filter: NameBasedTableFilter) {
    this.dataSource.filter = JSON.stringify(filter)
  }

  clearFilter() {
    this.dataSource.filter = undefined
  }

  filterTable(data: ProjectTotalsTableData, filterString: string): boolean {
    //TODO: PL: Only name filter is supported at the moment
    let currentFilter: NameBasedTableFilter = JSON.parse(filterString)
    if (currentFilter.fieldName === 'name') {
      let firstNameMatch = data.userInfo.firstName
        ? data.userInfo.firstName.toLowerCase().includes(currentFilter.filter.toLowerCase())
        : false
      let lastNameMatch = data.userInfo.lastName
        ? data.userInfo.lastName.toLowerCase().includes(currentFilter.filter.toLowerCase())
        : false

      return firstNameMatch || lastNameMatch
    }

    return true
  }
}
