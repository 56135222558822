import { Injectable, Inject } from '@angular/core'
import { InsightsDashboardServiceHelper } from './insights-dashboard-service-helper'
import { ConfigService } from '../../../core/services/config.service'
import { HTTP } from '../../../../shared/smartvid.types'
import { TranslateService } from '@ngx-translate/core'
import { ScopeObjectType, DashboardDataObjectType } from '../models/insights-dashboard.model'
import { InsightsDashboardObservationsServiceBase } from './insights-dashboard-observations-base.service'

@Injectable({
  providedIn: 'root',
})
export class InsightsDashboardOrganizationObservationsService extends InsightsDashboardObservationsServiceBase {
  constructor(
    @Inject(HTTP) $http: ng.IHttpService,
    config: ConfigService,
    insightsDashboardServiceHelper: InsightsDashboardServiceHelper,
    translate: TranslateService
  ) {
    super(ScopeObjectType.ORGANIZATION, $http, config, insightsDashboardServiceHelper, translate)
  }

  protected getObservationDateRangeTotalsObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_TOTALS
  }

  protected getObservationsByRiskScoreObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_SCORE
  }

  protected getObservationsByRiskBandDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_RISK_OBSERVATION_COUNT_BY_RISK_BAND
  }

  protected getObservationsByHazardCategoryDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_HAZARD_CATEGORY
  }

  protected getObservationsOverTimeByRiskBandDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME_BY_RISK_BAND
  }

  protected getObservationScoresOverTimeObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATION_SCORES_OVER_TIME
  }

  protected getObservationsOverTimeDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_OVER_TIME
  }

  protected getObservationsByTypeDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_TYPE
  }

  protected getObservationsGreatCatchByTypeDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_GREAT_CATCH_COUNT_BY_TYPE
  }

  protected getObservationsByIdentificationMethodDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_IDENTIFICATION_METHOD
  }

  protected getObservationsByCreatorMethodDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_OBSERVATIONS_BY_CREATOR
  }

  protected getObservationsOverTimeByStatusDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_OVERTIME_BY_STATUS
  }

  protected getObservationCountByOpenVsClosedDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_COUNT_BY_OPEN_VS_CLOSED
  }

  protected getObservationAvgDaysOpenByRiskBandDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_AVG_DAYS_OPEN_BY_RISK_BAND
  }

  protected getObservationOpenByStatusDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_OPEN_BY_STATUS
  }

  protected getActivityDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_ACTIVITY
  }

  protected getSafetyDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_SAFETY_BY_GROUP_PROJECT
  }

  protected getWorkflowDataObjectType(): DashboardDataObjectType {
    return DashboardDataObjectType.ORGANIZATION_OBSERVATION_DATE_RANGE_WORKFLOW_BY_GROUP_PROJECT
  }
}
