/* global angular, _, $ */

angular.module('smartvid').service('sphericalMarkupService', function (
    $timeout, TAG_MARKUP_OVERLAY_ID_PREFIX, SPHERICAL_MARKUP_RADIUS, sphericalUtils, smartvidApi, CommentModel
) {
  class SphericalMarkupService {
    constructor () {
      this._scene = null
      this._hotspotContainer = null
      this._viewer = null
      this._view = null
      this._width = 0
      this._height = 0
    }

    setProperties (marzipanoScene) {
      this._scene = marzipanoScene
      this._hotspotContainer = this._scene.hotspotContainer()
      this._viewer = this._scene.viewer()
      this._view = this._scene.view()
    }

    showMarkups (entitiesWithMarkup, asset) {
      // noinspection ES6ConvertVarToLetConst
      this._width = asset.metadata.width ? asset.metadata.width : asset.metadata.exif.ImageWidth
      this._height = asset.metadata.height ? asset.metadata.height : asset.metadata.exif.ImageHeight

      if (entitiesWithMarkup.length > 0) {
        $timeout(() => {
          $timeout(() => {
            _.forEach(entitiesWithMarkup, e => {
              let elem = $('#' + e.markup.id).get(0)
              if (elem) {
                let position
                if (e.markup && e.markup.sphericalMarkup) {
                  position = _.clone(e.markup.sphericalMarkup)
                } else {
                  position = sphericalUtils.dziTo360(e.markup, this._width, this._height)
                  e.markup.sphericalMarkup = {}
                  _.assign(e.markup, {
                    pitch: position.pitch,
                    yaw: position.yaw,
                    height: position.height,
                    width: position.width
                  })
                }
                let hotspot = this._hotspotContainer.createHotspot(elem, position, {perspective: {radius: SPHERICAL_MARKUP_RADIUS, extraRotations: 'rotateX(5deg)'}})
                e.markup.readyToShow = true
                hotspot._data = {
                  id: e.markup.id
                }
                if (entitiesWithMarkup.length === 1) {
                  this._scene.lookTo(position)
                }
              }
            })
          }, 200)
        })
      }
    }

    clearMarkups () {
      (this._hotspotContainer.listHotspots() || []).forEach((hs) => {
        try {
          this._hotspotContainer.destroyHotspot(hs)
        } catch (e) {
          //ignore
        }
      })
    }

    dragToPositionTagMarkup (tag, mouseDownEvent) {
      this.dragToPositionMarkupAndSave(tag, mouseDownEvent, (tag) => smartvidApi.updateTagForAsset(tag))
    }

    dragToPositionMarkupAndSave (entityWithMarkup, mouseDownEvent, saveCallback = (entityToSave) => {}) {
      stopPropHandler(mouseDownEvent)
      let that = this
      let view = that._scene.view()
      let hotspot = _(that._hotspotContainer.listHotspots()).filter((hs) => hs._data.id === entityWithMarkup.markup.id).first()
      let oldMouseCoordinates = view.screenToCoordinates({x: mouseDownEvent.clientX, y: mouseDownEvent.clientY})
      let newMouseCoordinates = view.screenToCoordinates({x: mouseDownEvent.clientX, y: mouseDownEvent.clientY})
      // pointer move
      document.body.addEventListener('pointermove', onmousemoveHandler)
      // mouse up
      document.body.onmouseup = onmouseupHandler

      function stopPropHandler (evt) {
        evt.stopPropagation()
        evt.preventDefault()
      }

      function onmousemoveHandler (evt) {
        stopPropHandler(evt)
        newMouseCoordinates = view.screenToCoordinates({x: evt.clientX, y: evt.clientY})
        let deltaPitch = newMouseCoordinates.pitch - oldMouseCoordinates.pitch
        let deltaYaw = newMouseCoordinates.yaw - oldMouseCoordinates.yaw
        let newPosition = {
          pitch: hotspot.position().pitch + deltaPitch,
          yaw: hotspot.position().yaw + deltaYaw
        }
        hotspot.setPosition(newPosition)
        _.assign(entityWithMarkup.markup.sphericalMarkup, newPosition)
        oldMouseCoordinates = newMouseCoordinates
      }

      function onmouseupHandler (evt) {
        evt = evt || window.event
        stopPropHandler(evt)
        document.body.removeEventListener('pointermove', onmousemoveHandler)
        document.body.onmouseup = null
        saveCallback(entityWithMarkup)
      }
    }

    resizeTagMarkup (tag, mouseDownEvent) {
      this.resizeMarkupAndSave(tag, mouseDownEvent, (tag) => smartvidApi.updateTagForAsset(tag))
    }

    resizeMarkupAndSave (entityWithMarkup, mouseDownEvent, saveCallback = (entityToSave) => {}) {
      stopPropHandler(mouseDownEvent)
      let that = this
      let view = that._scene.view()
      let oldMouseCoordinates = view.screenToCoordinates({x: mouseDownEvent.clientX, y: mouseDownEvent.clientY})
      let newMouseCoordinates = view.screenToCoordinates({x: mouseDownEvent.clientX, y: mouseDownEvent.clientY})
      // pointer move
      document.body.addEventListener('pointermove', onmousemoveHandler)
      // mouse up
      document.body.onmouseup = onmouseupHandler

      function stopPropHandler (evt) {
        evt.stopPropagation()
        evt.preventDefault()
      }

      function onmousemoveHandler (evt) {
        stopPropHandler(evt)
        newMouseCoordinates = view.screenToCoordinates({x: evt.clientX, y: evt.clientY})
        let deltaPitch = newMouseCoordinates.pitch - oldMouseCoordinates.pitch
        let deltaYaw = newMouseCoordinates.yaw - oldMouseCoordinates.yaw

        let deltaX = sphericalUtils.radiansToMarkupArcLength(deltaYaw)
        let deltaY = sphericalUtils.radiansToMarkupArcLength(deltaPitch)
        $timeout(function () {
          entityWithMarkup.markup.sphericalMarkup.width = Math.max(5, entityWithMarkup.markup.sphericalMarkup.width + deltaX)
          entityWithMarkup.markup.sphericalMarkup.height = Math.max(5, entityWithMarkup.markup.sphericalMarkup.height + deltaY)
        })

        oldMouseCoordinates = newMouseCoordinates
      }

      function onmouseupHandler (evt) {
        evt = evt || window.event
        stopPropHandler(evt)
        document.body.removeEventListener('pointermove', onmousemoveHandler)
        document.body.onmouseup = null
        if (saveCallback) {
          saveCallback(entityWithMarkup)
        }
      }
    }

    rotateTagMarkup (tag, evt) {
      this.rotateMarkupAndSave(tag, evt, (tag) => smartvidApi.updateTagForAsset(tag))
    }

    rotateMarkupAndSave (entityWithMarkup, evt, saveCallback = (entityToSave) => {}) {
      evt.stopPropagation()
      entityWithMarkup.markup.rotation = (entityWithMarkup.markup.rotation || 0) - 45
      if (entityWithMarkup instanceof CommentModel) {
        entityWithMarkup.rotation = entityWithMarkup.markup.rotation
      }
      if (saveCallback) {
        saveCallback(entityWithMarkup)
      }
    }

    getShapeStyle (entityWithMarkup) {
      return entityWithMarkup.markup && entityWithMarkup.markup.sphericalMarkup ? {
        width: entityWithMarkup.markup.sphericalMarkup.width,
        height: entityWithMarkup.markup.sphericalMarkup.height
      } : {}
    }

    setDefaultMarkup (entity) {
      entity.markup = entity.markup || {}
      entity.markup.sphericalMarkup = {
        pitch: this._view.pitch(),
        yaw: this._view.yaw(),
        width: 300,
        height: 300
      }
    }
  }

  return new SphericalMarkupService()
})
