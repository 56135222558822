import { Component, Input } from '@angular/core'
import { InsightsDashboardComponentBaseDirective } from 'modules/insights/dashboard/components/insights-dashboard-component-base'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import { TranslateService } from '@ngx-translate/core'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import {
  DashboardCardId,
  DashboardTabId,
  DashboardType,
  DateRangeChartData,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { ProjectGroupSingleValueDashboardData } from 'modules/insights/dashboard/models/insights-dashboard-project-group-assets.model'
import { InsightsDashboardProjectGroupAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-project-group-assets.service'

@Component({
  selector: 'sv-project-group-assets-insights-dashboard-image-health',
  templateUrl: 'project-group-assets-insights-dashboard-image-health.component.html',
  styleUrls: ['project-group-assets-insights-dashboard-image-health.component.scss'],
})
export class ProjectGroupAssetsInsightsDashboardImageHealthComponent extends InsightsDashboardComponentBaseDirective {
  private _projectGroupSingleValueData: ProjectGroupSingleValueDashboardData
  @Input() set projectGroupSingleValueData(value: ProjectGroupSingleValueDashboardData) {
    this._projectGroupSingleValueData = value

    if (this._projectGroupSingleValueData) {
      this.avgPhotosCreatedPerDayPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectGroupSingleValueData.avgPhotosCreatedPerDayPercentChange
      )
      this.avgPhotoDiffCreatedUploadedPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectGroupSingleValueData.avgPhotoDiffCreatedUploadedPercentChange
      )
      this.constructionPhotosToAllPhotosRatioPercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectGroupSingleValueData.constructionPhotosToAllPhotosRatioAvgPercentChange
      )
      this.percentPersonCoveragePercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectGroupSingleValueData.percentPersonCoveragePercentChange
      )
      this.percentOfAssetsWithoutCreatedDatePercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectGroupSingleValueData.percentOfAssetsWithoutCreatedDatePercentChange
      )
      this.percentOfConstructionAssetsWithoutCreatedDatePercentChangeClassName = this.pickColorClassNameForPercentChange(
        this._projectGroupSingleValueData.percentOfConstructionAssetsWithoutCreatedDatePercentChange
      )
    }
  }
  get projectGroupSingleValueData() {
    return this._projectGroupSingleValueData
  }

  projectGroupAvgPhotosCreatedByDayOfWeekData: DateRangeChartData

  avgPhotosCreatedPerDayPercentChangeClassName: string
  avgPhotoDiffCreatedUploadedPercentChangeClassName: string
  constructionPhotosToAllPhotosRatioPercentChangeClassName: string
  percentPersonCoveragePercentChangeClassName: string
  percentOfAssetsWithoutCreatedDatePercentChangeClassName: string
  percentOfConstructionAssetsWithoutCreatedDatePercentChangeClassName: string

  constructor(
    protected insightsDataHelper: InsightsDataHelper,
    protected translate: TranslateService,
    protected insightsDashboardConfigurationService: InsightsDashboardConfigurationService,
    private insightsDashboardProjectGroupAssetsService: InsightsDashboardProjectGroupAssetsService
  ) {
    super(
      insightsDataHelper,
      insightsDashboardConfigurationService,
      translate,
      DashboardType.PROJECT_GROUP_ASSETS_DASHBOARD
    )
  }

  protected refresh() {
    this.guardFor(
      () => this.loadAvgPhotosCreatedByDayOfWeekData(),
      DashboardTabId.IMAGE_HEALTH,
      DashboardCardId.PROJECT_GROUP_ASSETS_AVG_PHOTOS_CREATED_BY_DAY_OF_WEEK
    )
  }

  private loadAvgPhotosCreatedByDayOfWeekData() {
    this.projectGroupAvgPhotosCreatedByDayOfWeekData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectGroupAssetsService.loadAvgPhotosCreatedByDayOfWeekData(
        this.currentInsightsDashboardParameters,
        tableData => {
          this.projectGroupAvgPhotosCreatedByDayOfWeekData = tableData
        }
      )
    )
  }
}
