/* global angular, $, _ */

angular.module('smartvid').directive('svFormFieldDecorate', function ($rootScope, $compile, $q, $timeout, $window) {
  return {
    restrict: 'A',
    replace: false,
    require: 'ngModel',
    link: (scope, element, attrs, ngModel) => {
      //
      // Get the form
      //
      let form = $(element[0]).parents('form').first()

      //
      // we need the form to wire up validation decorations and messages
      //
      if (form && form.length && form[0].name) {
        //
        // The input field decorations
        //
        let tmpl = ''

        if (attrs.svFormFieldDecorate) {
          tmpl = `
          <label class="type-input {{decorateOptions.style}}"
            ng-if="decorateOptions && ${form[0].name}.${element[0].name}.$dirty"
            ng-mouseover="decorateOptions.mouseover()"
            ng-mouseout="decorateOptions.mouseout()">
            {{decorateOptions.label | i18next}}
          </label>`
        } else {
          if (!$(form).attr('no-immediate-password-validation') || element[0].name !== 'password') {
            tmpl = `
            <icon-checkmark
              class="type-input"
              ng-if="${form[0].name}.${element[0].name}.$valid && (${form[0].name}.${element[0].name}.$dirty || submitted)">
            </icon-checkmark>`
          }
          let conditional = element[0].name === 'confirmPassword'
          ? `"${form[0].name}.${element[0].name}.$invalid && ((${form[0].name}.${element[0].name}.$dirty && passwordsOnceMatched) || submitted)"`
          : `"${form[0].name}.${element[0].name}.$invalid && (${form[0].name}.${element[0].name}.$dirty || submitted)"`

          tmpl = `${tmpl}<icon-x class="type-input"
            ng-if=${conditional}>
          </icon-x>`
        }

        var decorate = angular.element(tmpl)
        decorate.insertAfter(element)
        $compile(decorate)(scope)

        // no tooltip decoration for mobile
        if ($rootScope.isMobile) {
          return
        }

        //
        // Position any ng-message as a tooltip
        //
        let prefix = `${form[0].name}.${element[0].name}`
        let ngMsg = $(`div[ng-messages="${prefix}.$error"],div[ng-messages="${prefix}.$submit"],div[ng-messages="${prefix}.$info"]`)
        if (ngMsg && ngMsg.length) {
          $(ngMsg).addClass('invalid-message-tooltip')

          // add arrow decoration
          $(ngMsg).prepend('<div class="arrow"></div>')

          $timeout(() => { adjustTooltip(ngMsg) }, 200)

          $(window).on('resize.tooltip', _.throttle(() => { adjustTooltip(ngMsg) }, 200))
        }
      }

      scope.$on('$destroy', function () {
        $(window).off('resize.tooltip')
      })

      //
      // Defer: find right edge of input field add set tooltip
      //
      function adjustTooltip (ngMsg) {
        scope.$evalAsync(() => {
          let inputRect = element[0].getBoundingClientRect()
          // let msgRect = $(ngMsg)[0].getBoundingClientRect()

          $(ngMsg).css({
            left: inputRect.left + inputRect.width + 10,
            top: inputRect.top + 3
          })
        })
      }
    }
  }
})
