/* global angular, analytics, _ */

angular.module('smartvid').directive('assetViewerInfoPanel', function (
  videoPlayer, $rootScope, currentUser, smartvidApi, $stateParams, CommentModel, $timeout, utils,
  infopanelCommentsTabService
) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'AssetViewer/infopanel.html',
    link: function (scope) {
      scope.comments = scope.asset.comments
      scope.readOnly = !scope.asset.canComment
      scope.linkedObservations = scope.asset.linkedObservations
      scope.newComment = {
        editMode: false
      }
      scope.assetChanged = false
      scope.onAssetChanged = () => {
        scope.assetChanged = true
      }
      scope.saveAssetChanges = (asset) => {
        if (scope.assetChanged) {
          scope.assetChanged = false
          smartvidApi.updateAsset(asset.id, asset.name, asset.userDescription)
        }
      }
      scope.commentsActive = () => {
        return angular.isDefined(scope.asset.comments.getSelectedComment())
      }

      scope.getNewComment = () => {
        return scope.newComment
      }

      scope.addComment = () => {
        infopanelCommentsTabService.addComment(scope.asset, scope.newComment, scope.asset.comments,
          (comment) => {
            scope.newComment = comment
            scope.newComment.id = undefined
            $rootScope.$broadcast('sv-comment-added')
          },
          () => {
            videoPlayer.pause()
          },
          () => {
            $rootScope.$broadcast('sv-unselect-all-tags-and-groups')
          },
          () => {
            $rootScope.$broadcast('sv-comment-added')
          },
          () => {
            return videoPlayer.currentTime() * 1000
          })
      }

      scope.isCommentEditable = (comment) => {
        return infopanelCommentsTabService.isCommentEditable(comment)
      }

      scope.saveComment = () => {
        infopanelCommentsTabService.saveComment(scope.asset, scope.currentProject, scope.asset.comments,
          scope.newComment,
          (cc) => {
            scope.handleCommentClick(cc)
          },
          () => {
            // TODO: Is this suppose to be undefined?
            scope.currentTime = undefined
            scope.newComment = {
              editMode: false
            }
          })
      }

      scope.checkForReturnKey = (evt, comment, editMode) => {
        infopanelCommentsTabService.checkForReturnKeyForComment(evt, scope.asset, scope.currentProject, comment, editMode,
          () => {
            scope.saveComment()
          })
      }

      scope.checkForArrowKey = (evt) => {
        infopanelCommentsTabService.checkForArrowKeyForComment(evt)
      }

      scope.keyPressCommentLocation = (comment) => {
        if (!scope.isCommentEditable(comment) || scope.readOnly || !comment.id) {
          return
        }
        analytics.track('Edit Comment', {
          category: 'Comment Action',
          assetName: scope.asset.name,
          projectName: scope.currentProject.name,
          orgName: (scope.currentProject.getProjectOrg()) ? scope.currentProject.getProjectOrg().name : undefined
        })
        smartvidApi.updateCommentForAsset($stateParams.assetId, comment).then((response) => {
        })
      }

      scope.stopEventIfInEditMode = (event, comment) => {
        infopanelCommentsTabService.stopEventIfInEditModeForComment(event, comment)
      }

      scope.handleCommentClick = (comment) => {
        document.querySelector('#asset-comment-textarea').setAttribute('style', '');
        infopanelCommentsTabService.handleCommentClick(scope.asset, scope.asset.comments, comment, scope.readOnly,
          () => {
            scope.forceOverflowUpdate = (new Date()).getTime()
          },
          () => {
            $rootScope.$broadcast('sv-unselect-all-tags-and-groups')
          },
          (comment) => {
            videoPlayer.pause()
            videoPlayer.currentTime(comment.startTime / 1000)
            $timeout(() => {
              comment.selected = true
              $rootScope.$broadcast('sv-comment-selected')
            }, 250)
          },
          () => {
            $rootScope.$broadcast('sv-comment-selected')
          })
      }

      scope.showCommentOptions = ($event, comment) => {
        infopanelCommentsTabService.showCommentOptions($event, scope, scope.asset, scope.currentProject,
          scope.asset.comments, comment)
      }

      scope.showMoreComment = ($event, comment) => {
        return infopanelCommentsTabService.showMoreComment($event, comment)
      }

      scope.$on('sv-comment-clicked', (evt, comment) => {
        scope.handleCommentClick(comment)
      })

      scope.showToolsOptions = ($event) => {
        let comment = scope.comments.getEditedComment()
        scope.flyout.open('toolsOptions', {
          parentElement: $event.currentTarget,
          direction: 'top',
          newComment: comment,
          newTag: null
        })
      }

      scope.$on('sv-update-comment-markup', () => {
        if (!scope.isMobile) {
          let comment = scope.asset.comments.getEditedComment()
          if (scope.isCommentEditable(comment) && !scope.readOnly && comment.id) {
            smartvidApi.updateCommentForAsset(scope.asset.id, comment)
          }
        }
      })
    }
  }
})
