/* global angular */
/**
 * Created by ryanrubbico on 12/8/17.
 */
angular.module('smartvid').service('IntegrationName', function () {
  class IntegrationName {
    constructor () {
      Object.defineProperties(this, {
        'ACONEX': {
          value: 'ACONEX',
          writable: false
        },
        'BOX': {
          value: 'BOX',
          writable: false
        },
        'SHAREPOINT': {
          value: 'SHAREPOINT',
          writable: false
        },
        'EGNYTE': {
          value: 'EGNYTE',
          writable: false
        },
        'FORGE': {
          value: 'FORGE',
          writable: false
        },
        'OXBLUE': {
          value: 'OXBLUE',
          writable: false
        },
        'PROCORE': {
          value: 'PROCORE',
          writable: false
        },
        'BIM_360_FIELD': {
          value: 'BIM_360_FIELD',
          writable: false
        },
        'STRUCTION_SITE': {
          value: 'STRUCTION_SITE',
          writable: false
        }
      })
    }
  }

  return new IntegrationName()
})
