/* global angular */

angular.module('smartvid').directive('projectIntegrationsAconex', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'integrations/project-integrations-aconex.html',
    link: function (scope) {}
  }
})
