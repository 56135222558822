/* global angular */

angular.module('smartvid').directive('enterKeypress', function () {
  return function (scope, element, attrs) {
    element.on('keydown keypress', function (event) {
      if (event.which === 13) {
        element.blur()
        scope.$apply(function () {
          scope.$eval(attrs.enterKeypress)
        })

        event.preventDefault()
      }
    })
  }
})
