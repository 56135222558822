/* global angular */

angular.module('smartvid').directive('formFieldGroupCreatePassword', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'forms/form-field-groups/form-field-group-create-password.html',
    link ($scope, $element, $attrs) {
      $scope.decorateOptions = $scope.stength

      $scope.mouseover = () => {
        $scope.showPasswordStrengMsg = true
      }

      $scope.mouseout = () => {
        $scope.showPasswordStrengMsg = false
      }

      $scope.$watch('strength', () => {
        if ($scope.strength === 'none') {
          $scope.decorateOptions = {
            label: 'resetPassword.strength.very-weak',
            style: 'very-weak',
            mouseover: $scope.mouseover,
            mouseout: $scope.mouseout
          }
        } else {
          $scope.decorateOptions = {
            label: 'resetPassword.strength.' + $scope.strength,
            style: $scope.strength,
            mouseover: $scope.mouseover,
            mouseout: $scope.mouseout
          }
        }

        // show tooltip when password is less than strong
        if ($scope.strength !== 'great' && $scope.form && $scope.form.password) {
          $scope.form.password.$info = {newPasswordInfo: true}
        } else if ($scope.form && $scope.form.password) {
          $scope.form.password.$info = {newPasswordInfo: false}
        }
      })
    }
  }
})
