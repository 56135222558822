import * as _ from 'lodash'
import { Component, Input } from '@angular/core'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import {
  DashboardCardId,
  DashboardTabId,
  NameBasedTableFilter,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { ProjectGroupAssetsActivityTableData } from 'modules/insights/dashboard/models/insights-dashboard-project-group-assets.model'
import { TotalsTableBaseDirective } from 'modules/insights/dashboard/components/totals-table/totals-table-base'

@Component({
  selector: 'sv-project-group-assets-activity-table',
  templateUrl: 'project-group-assets-activity-table.component.html',
  styleUrls: ['project-group-assets-activity-table.component.scss'],
})
export class ProjectGroupAssetsActivityTableComponent extends TotalsTableBaseDirective<
  ProjectGroupAssetsActivityTableData
> {
  displayedColumns: string[] = ['score', 'name', 'files', 'tags', 'assetCountsForTimePeriod']

  projectScoreColorClassMap = {}

  constructor(private insightsDashboardConfigurationService: InsightsDashboardConfigurationService) {
    super()
    let cardConfig = this.insightsDashboardConfigurationService.getCardConfiguration(
      DashboardTabId.ACTIVITY,
      DashboardCardId.PROJECT_GROUP_ASSETS_ACTIVITY_BY_PROJECT
    )
    if (cardConfig && cardConfig.customCardData && cardConfig.customCardData.tableColumns) {
      this.displayedColumns = _.filter(cardConfig.customCardData.tableColumns, (column: string) => {
        return this.displayedColumns.includes(column)
      })
    }
  }

  @Input() set tableData(value: ProjectGroupAssetsActivityTableData[]) {
    this.setTableData(value)
    if (this._tableData) {
      this._tableData.forEach(row => {
        this.projectScoreColorClassMap[row.scopeObjectInfo.scopeObjectId] = this.getProjectScoreColorClassName(
          row.scopeObjectCountsInfo.projectScore
        )
      })
    }
  }
  get tableData(): ProjectGroupAssetsActivityTableData[] {
    return this._tableData
  }

  applyFilter(filter: NameBasedTableFilter) {
    this.dataSource.filter = JSON.stringify(filter)
  }

  clearFilter() {
    this.dataSource.filter = undefined
  }

  filterTable(data: ProjectGroupAssetsActivityTableData, filterString: string): boolean {
    //TODO: PL: Only name filter is supported at the moment
    let currentFilter: NameBasedTableFilter = JSON.parse(filterString)
    if (currentFilter.fieldName === 'name') {
      return data.scopeObjectInfo.scopeObjectName
        ? data.scopeObjectInfo.scopeObjectName.toLowerCase().includes(currentFilter.filter.toLowerCase())
        : false
    }

    return true
  }

  private getProjectScoreColorClassName(scoreValue: number) {
    return this.pickClassNameFor(scoreValue, 'bg-na-value', [
      [35, 'bg-critical-value'],
      [45, 'bg-warning-3-value'],
      [55, 'bg-warning-2-value'],
      [65, 'bg-warning-1-value'],
      [100, 'bg-normal-value'],
    ])
  }
}
