<div class="content">
  <sv-dialog-header [title]="dialogTitle"></sv-dialog-header>

  <div mat-dialog-content class="observation-edit-panel" cdkScrollable>

    <form [formGroup]="addUserForm">

      <h2 class="mat-caption">{{ emailsTitle | translate }}</h2>

      <mat-form-field appearance="fill" floatLabel="never">
        <mat-label>{{ 'components.addProjectMember.emailInputTitle' | translate }}</mat-label>
        <mat-chip-list #chipList>
          <mat-chip
                  *ngFor="let email of emails"
                  class="outline"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(email)">
            {{email}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
                  placeholder="{{ 'components.addProjectMember.emailInputPlaceholder' | translate }}"
                  #emailInput
                  formControlName="emailCtrl"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let user of (filteredUsers | async)" [value]="user">
            {{user.email}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div *ngFor="let roleGroup of roleGroups; let fst = first">
        <div *ngIf="roleGroup.roles.length > 0">
          <div *ngIf="fst === false" class="mat-divider-header"></div>
          <mat-divider></mat-divider>

          <h2 class="mat-caption">{{ roleGroup.groupTitle | translate }}</h2>

          <mat-radio-group
                  (change)="updateCheckboxes()"
                  formControlName="{{ roleGroup.formControlName }}">
            <mat-radio-button color="warn" *ngFor="let role of roleGroup.roles"
                              [value]="role">
              <div class="mat-body-2">{{ role.title }}</div>
              <div class="mat-caption">{{ role.description }}</div>
              <div *ngFor="let projectGroup of role.projectGroups">
                <div>
                  <mat-checkbox
                          (change)="select(roleGroup.formControlName, role)"
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="projectGroup.selected">
                      <div class="mat-body-2">{{projectGroup.name}}</div>
                  </mat-checkbox>
                </div>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

    </form>

  </div>

  <div class="spacer"></div>
  <mat-divider></mat-divider>

  <div mat-dialog-actions>
    <button mat-stroked-button (click)="onClose()">
      {{ 'components.addProjectMember.cancelButton' | translate }}
    </button>
    <button mat-flat-button
            (click)="onAdd()"
            [disabled]="!canSave()"
            cdkFocusInitial
            color="primary">
      <span>{{ dialogAddButton | translate }}</span>
    </button>

  </div>

</div>
