<mat-spinner
  class="dashboard-spinner dashboard-spinner-forced-color"
  diameter="128"
  *ngIf="!isConfigurationLoaded"
></mat-spinner>
<div
  class="insights-dashboard-container top-and-left-border smartvid-dark-theme mdc-layout-grid"
  *ngIf="isConfigurationLoaded"
>
  <div class="mdc-layout-grid__inner">
    <div *svDashboardGridCell="{ cardId: 'ORGANIZATION_ASSETS_UPLOADED_TOTAL' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.orgFileUploads.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
        <div class="text-card-content" *ngIf="organizationSingleValueData">
          <div class="text-card-main-text">
            {{
              isNA(organizationSingleValueData.uploadedFilesTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (organizationSingleValueData.uploadedFilesTotal | number)
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ uploadedFilesRatioPercentChangeClassName }}">
            {{ getSign(organizationSingleValueData.uploadedFilesRatioPercentChange)
            }}{{
              isNA(organizationSingleValueData.uploadedFilesTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (organizationSingleValueData.uploadedFilesRatioPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'ORGANIZATION_ASSETS_UPLOADERS_TOTAL' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.orgTotalUploaders.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
        <div class="text-card-content" *ngIf="organizationSingleValueData">
          <div class="text-card-main-text">
            {{
              isNA(organizationSingleValueData.uploadersTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (organizationSingleValueData.uploadersTotal | number)
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ uploadersRatioPercentChangeClassName }}">
            {{ getSign(organizationSingleValueData.uploadersRatioPercentChange)
            }}{{
              isNA(organizationSingleValueData.uploadersTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (organizationSingleValueData.uploadersRatioPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'ORGANIZATION_ASSETS_TAGS_TOTAL' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.orgTotalTags.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
        <div class="text-card-content" *ngIf="organizationSingleValueData">
          <div class="text-card-main-text">
            {{
              isNA(organizationSingleValueData.tagsTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (organizationSingleValueData.tagsTotal | number)
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ tagsTotalRatioPercentChangeClassName }}">
            {{ getSign(organizationSingleValueData.tagsTotalRatioPercentChange)
            }}{{
              isNA(organizationSingleValueData.tagsTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (organizationSingleValueData.tagsTotalRatioPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'ORGANIZATION_ASSETS_PPE_COMPLIANCE_AVG' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.orgPpeComplianceAvg.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
        <div class="text-card-content" *ngIf="organizationSingleValueData">
          <div class="text-card-main-text">
            {{
              isNA(organizationSingleValueData.ppeCompliancePercentAvg)
                ? ('dashboard.valueNotAvailable' | translate)
                : (organizationSingleValueData.ppeCompliancePercentAvg | number: '.1-1') + '%'
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ ppeCompliancePercentAvgChangeClassName }}">
            {{ getSign(organizationSingleValueData.ppeCompliancePercentAvgChange)
            }}{{
              isNA(organizationSingleValueData.ppeCompliancePercentAvg)
                ? ('dashboard.valueNotAvailable' | translate)
                : (organizationSingleValueData.ppeCompliancePercentAvgChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'ORGANIZATION_ASSETS_WORK_AT_HEIGHTS_AVG' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.orgWorkAtHeightsAgv.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
        <div class="text-card-content" *ngIf="organizationSingleValueData">
          <div class="text-card-main-text">
            {{
              isNA(organizationSingleValueData.workAtHeightAvg)
                ? ('dashboard.valueNotAvailable' | translate)
                : (organizationSingleValueData.workAtHeightAvg | number: '.1-1')
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ workAtHeightAvgPercentChangeClassName }}">
            {{ getSign(organizationSingleValueData.workAtHeightAvgPercentChange)
            }}{{
              isNA(organizationSingleValueData.workAtHeightAvg)
                ? ('dashboard.valueNotAvailable' | translate)
                : (organizationSingleValueData.workAtHeightAvgPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'ORGANIZATION_ASSETS_SLIP_AND_TRIP_AVG' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.orgSlipAndTripAvg.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!organizationSingleValueData"></mat-spinner>
        <div class="text-card-content" *ngIf="organizationSingleValueData">
          <div class="text-card-main-text">
            {{
              isNA(organizationSingleValueData.slipAndTripAvg)
                ? ('dashboard.valueNotAvailable' | translate)
                : (organizationSingleValueData.slipAndTripAvg | number: '.1-1')
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ slipAndTripAvgPercentChangeClassName }}">
            {{ getSign(organizationSingleValueData.slipAndTripAvgPercentChange)
            }}{{
              isNA(organizationSingleValueData.slipAndTripAvg)
                ? ('dashboard.valueNotAvailable' | translate)
                : (organizationSingleValueData.slipAndTripAvgPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div
      class="tab-container-wrapper mdc-layout-grid__cell--order-12 mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone"
    >
      <mat-tab-group>
        <mat-tab *svDashboardTab="'ACTIVITY'" label="{{ getTabTitle('ACTIVITY') | translate }}">
          <div class="tab-content mdc-layout-grid">
            <sv-organization-assets-insights-dashboard-activity></sv-organization-assets-insights-dashboard-activity>
          </div>
        </mat-tab>
        <mat-tab *svDashboardTab="'SAFETY'" label="{{ getTabTitle('SAFETY') | translate }}">
          <div class="tab-content mdc-layout-grid">
            <sv-organization-assets-insights-dashboard-safety
              [organizationSingleValueData]="organizationSingleValueData"
            ></sv-organization-assets-insights-dashboard-safety>
          </div>
        </mat-tab>
        <mat-tab *svDashboardTab="'IMAGE_HEALTH'" label="{{ getTabTitle('IMAGE_HEALTH') | translate }}">
          <div class="tab-content mdc-layout-grid">
            <sv-organization-assets-insights-dashboard-image-health
              [organizationSingleValueData]="organizationSingleValueData"
            ></sv-organization-assets-insights-dashboard-image-health>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
