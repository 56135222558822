/* global angular */

angular.module('smartvid').factory('ProcoreObservationIssueTypeCollection', function (BaseCollection, ProcoreObservationIssueTypeModel) {
  class ProcoreObservationIssueTypeCollection extends BaseCollection {
    constructor (models) {
      super(models, ProcoreObservationIssueTypeModel)
    }
  }

  return ProcoreObservationIssueTypeCollection
})
