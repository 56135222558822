/* global angular, $ */
angular.module('smartvid').service('NavController', function (
    $state, $rootScope, $timeout, navStateService, utils, searchResultService, TRANSITION_SPEED
) {
  function getStateName () {
    return ($state.transition) ? $state.transition.to().name : $state.current.name
  }

  function leftNavInitialState () {
    if ($rootScope.isWidget) {
      return false
    }
    let stateName = getStateName()
    if (searchResultService.isProjectSearchSharing() && stateName === 'dashboard.projects.projectId.files') {
      return false
    }
    let open = navStateService.getLeftNavState(stateName)
    mobileAdjustLeftNav()
    return open
  }

  function rightNavInitialState () {
    if ($rootScope.isWidget) {
      return false
    }
    return navStateService.getRightNavState(getStateName()) && !$rootScope.isMobile
  }

  function mobileAdjustLeftNav () {
    if ($rootScope.isMobile) {
      $('.sidepanel').css('height', $('body').innerHeight())
      $('.sidepanel .items-container').css('height', $('body').innerHeight() - 175)
    }
  }

  function triggerLeftNavAnimationTimeout () {
    $timeout(() => {
      $rootScope.$broadcast('sv-left-nav-animation-finished')
    }, TRANSITION_SPEED /* transition speed 300ms */)
  }

  function triggerRightNavAnimationTimeout () {
    $timeout(() => {
      $rootScope.$broadcast('sv-right-nav-animation-finished')
    }, TRANSITION_SPEED /* transition speed 300ms */)
  }

  class NavController {
    constructor () {
      this.leftNavOpen = leftNavInitialState()
      this.rightNavOpen = rightNavInitialState()
      this.temporarySaveModeActive = false
      this.temporaryLeftNavOpen = false
      this.temporaryRightNavOpen = false
    }

    isLeftNavOpen () {
      return this.leftNavOpen
    }

    isRightNavOpen () {
      return this.rightNavOpen
    }

    openLeftNav (save = true) {
      this.leftNavOpen = true
      if (save && !this.temporarySaveModeActive) {
        navStateService.setLeftNavState(getStateName(), this.leftNavOpen)
      }

      if (this.temporarySaveModeActive) {
        this.temporaryLeftNavOpen = true
      }
      mobileAdjustLeftNav()
      triggerLeftNavAnimationTimeout()
    }

    openRightNav (save = true) {
      this.rightNavOpen = true
      if (save && !this.temporarySaveModeActive) {
        navStateService.setRightNavState(getStateName(), this.rightNavOpen)
      }

      if (this.temporarySaveModeActive) {
        this.temporaryRightNavOpen = true
      }
      triggerRightNavAnimationTimeout()
    }

    closeLeftNav (save = true) {
      this.leftNavOpen = false
      if (save && !this.temporarySaveModeActive) {
        navStateService.setLeftNavState(getStateName(), this.leftNavOpen)
      }

      if (this.temporarySaveModeActive) {
        this.temporaryLeftNavOpen = false
      }
      triggerLeftNavAnimationTimeout()
    }

    closeRightNav (save = true) {
      this.rightNavOpen = false
      if (save && !this.temporarySaveModeActive) {
        navStateService.setRightNavState(getStateName(), this.rightNavOpen)
      }

      if (this.temporarySaveModeActive) {
        this.temporaryRightNavOpen = false
      }
      triggerRightNavAnimationTimeout()
    }

    toggleLeftNav (save = true) {
      if (this.leftNavOpen) {
        this.closeLeftNav(save)
      } else {
        this.openLeftNav(save)
      }
    }

    toggleRightNav (save = true) {
      if (this.rightNavOpen) {
        this.closeRightNav(save)
      } else {
        this.openRightNav(save)
      }
    }

    activateTemporarySaveMode () {
      this.temporarySaveModeActive = true
    }

    deactivateTemporarySaveMode () {
      this.temporarySaveModeActive = false
      this.temporaryRightNavOpen = false
      this.temporaryLeftNavOpen = false
    }

    reset () {
      this.leftNavOpen = undefined
      this.rightNavOpen = undefined
    }

    init () {
      this.leftNavOpen = this.temporarySaveModeActive ? this.temporaryLeftNavOpen : leftNavInitialState()
      this.rightNavOpen = this.temporarySaveModeActive ? this.temporaryRightNavOpen : rightNavInitialState()
      triggerLeftNavAnimationTimeout()
      triggerRightNavAnimationTimeout()
    }
  }

  return new NavController()
})

