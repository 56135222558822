/* global angular,_,analytics,Appcues */

import {ModuleType} from 'shared/models/module-type'

angular.module('smartvid').service('currentUser', function (
  $window, $injector, $rootScope, $timeout, $log, BaseModel, utils,
  FEATURE_TYPE_OBSERVATION, FEATURE_TYPE_PTP, FEATURE_TYPE_POWERBI,
  FEATURE_TYPE_POWERBI_SAFETY_OBSERVATIONS, FEATURE_TYPE_POWERBI_SAFETY_MONITORING, FEATURE_TYPE_POWERBI_PREDICTIVE
) {
  const storageKey = 'currentUser'

  function getStoredUser() {
    return JSON.parse($window.localStorage.getItem(storageKey))
  }

  function _canShareGlobalWritableForGivenAssetSharingRoles (assetSharingRoles) {
    let roles = assetSharingRoles || []
    let sufficientRole = _.find(roles, role => role.name === 'ORG_MEMBER_COLLABORATOR' || role.name === 'PROJECT_MEMBER_COLLABORATOR')
    return !!sufficientRole
  }

  class CurrentUserModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        username: '',
        token: '',
        expires: '',
        organizations: [],
        userId: '',
        isSaml: false,
        daysInTrial: undefined,
        userPreferences: {}
      }

      attrs = attrs || getStoredUser()

      let userInfo = _.defaults(attrs || {}, defaults)
      super(userInfo)
    }

    addOrganization (organization) {
      this.organizations.push(organization)
      this.update({
        organizations: this.organizations
      })
    }

    renewToken (token, expires) {
      if (this.isKeepMeSignedIn()) {
        // Don't tokens for 'keep me signed in' sessions, since the initial
        // token received upon authentication is long enough
        return
      }
      this.update({
        token: token,
        expires: expires
      })
    }

    deleteOrganization (organization) {
      this.organizations = _.filter(this.organizations, function (org) {
        return org.id !== organization.id
      })
      this.update({
        organizations: this.organizations
      })
    }

    isObservationFeatureEnabledForAnyOrganization () {
      return _.find(this.organizations, function (org) {
        return org.orgFeatureSetting && org.orgFeatureSetting[FEATURE_TYPE_OBSERVATION]
      }) !== undefined
    }

    isAnalyticsFeatureEnabledForAnyOrganization () {
      return _.find(this.organizations, function (org) {
        return org.orgFeatureSetting && org.orgFeatureSetting[FEATURE_TYPE_POWERBI]
      }) !== undefined
    }

    isPtpFeatureEnabledForAnyOrganization () {
      return _.find(this.organizations, function (org) {
          return org.orgFeatureSetting && org.orgFeatureSetting[FEATURE_TYPE_PTP]
        }) !== undefined
    }

    isFeatureEnabledForAnyOrganization (featureType) {
      return _.find(this.organizations, function (org) {
        return org.orgFeatureSetting && org.orgFeatureSetting[featureType]
      }) !== undefined
    }

    isObservationEnabledForOrganization (organizationId) {
      return this.isFeatureEnabledForOrganization(organizationId, FEATURE_TYPE_OBSERVATION)
    }

    isAnalyticsFeatureEnabledForOrganization(organizationId) {
      return this.isFeatureEnabledForOrganization(organizationId, FEATURE_TYPE_POWERBI)
    }

    isAnalyticsSafetyMonitoringFeatureEnabledForOrganization(organizationId) {
      return this.isFeatureEnabledForOrganization(organizationId, FEATURE_TYPE_POWERBI_SAFETY_MONITORING)
    }

    isAnalyticsSafetyObservationsFeatureEnabledForOrganization(organizationId) {
      return this.isFeatureEnabledForOrganization(organizationId, FEATURE_TYPE_POWERBI_SAFETY_OBSERVATIONS)
    }

    isAnalyticsPredictiveFeatureEnabledForOrganization(organizationId) {
      return this.isFeatureEnabledForOrganization(organizationId, FEATURE_TYPE_POWERBI_PREDICTIVE)
    }

    isFeatureEnabledForOrganization (organizationId, featureType) {
      return _.find(this.organizations, function (org) {
        return org.id === organizationId && org.orgFeatureSetting && org.orgFeatureSetting[featureType]
      }) !== undefined
    }

    getOrganization (organizationId) {
      return _.find(this.organizations, function (org) {
        return org.id === organizationId
      })
    }

    updateOrganization (organization) {
      let existingOrg = _.find(this.organizations, function (org) {
        return org.id === organization.id
      })
      if (existingOrg) {
        _.extend(existingOrg, organization)
      }
      this.update({
        organizations: this.organizations
      })
    }

    defaultOrganization (firstIfNotSet, selectionOrgs) {
      let orgs = (selectionOrgs) ? selectionOrgs : this.organizations
      let result = _.findWhere(orgs, {isPrimary: true})
      if (!result && firstIfNotSet) {
        result = _.findWhere(orgs, {isMyOrg: false})
        if (!result) {
          result = _.first(orgs)
        }
      }
      return result
    }

    get canCreateProject () {
      return !!_.find(this.organizations, (o) => {
        return o.canManageProjects
      })
    }

    get canMoveAssets () {
      return !!_.find(this.organizations, (o) => {
        return o.canMoveAssets
      })
    }

    signout (loggedOut) {
      analytics.track('Logout', {
        category: 'Authentication'
      })
      let stateName = (loggedOut) ? 'loggedOut' : 'login'
      this.signoutState(stateName)
    }

    isSignedOut () {
      let $state = $injector.get('$state')
      return !getStoredUser() && !$state.includes('dashboard')
    }

    timeoutUser () {
      this.signoutState('timeout')
    }

    clearState () {
      $injector.get('uiConfigurationService').clearCache()
      analytics.reset()
      $injector.get('redirectorService').clear()
      this.token = ''
      $rootScope.initAnalytics = false
      $rootScope.appcuesIdentify = false
      $injector.get('searchResultService').reset()
      let flyout = $injector.get('flyout')
      let modal = $injector.get('modal')

      $window.localStorage.removeItem(storageKey) // remove the stored user

      for (var attr in this) {
        if (Object.prototype.hasOwnProperty.call(this, attr)) {
          delete this[attr]
        }
      }

      // closing up anything that would linger in the ui
      if (flyout.isOpen) {
        flyout.close()
      }

      if (modal.isOpen) {
        modal.close()
      }
      $injector.get('scrollService').reset()
      $injector.get('tagTreeStateService').reset()

      $injector.get('contentSharingContext').reset()

      $window.localStorage.removeItem('token') // something is watching this, so it should be last
    }

    signoutState (stateName) {
      let $state = $injector.get('$state')
      this.clearState()
      $state.go(stateName)
    }

    isAuthenticated () {
      return !_.isEmpty(this.token) && !_.isNaN(this.expires) && !(this.expires < Date.now())
    }

    setIsKeepMeSignedIn (val) {
      $window.localStorage.setItem('keepMeSignedIn', !!val)
    }

    isKeepMeSignedIn () {
      return ($window.localStorage.getItem('keepMeSignedIn') === 'true')
    }

    getPrimaryOrganization () {
      return this.defaultOrganization(true)
    }

    getSecondaryOrganization () {
      let primaryOrg = this.getPrimaryOrganization()
      if (primaryOrg) {
        let secondaryOrg = _.find(this.organizations, function (org) {
          return org !== primaryOrg && org.isMyOrg === false
        })
        return secondaryOrg
      }
      return undefined
    }

    initAnalytics (callback) {
      if ($rootScope.initAnalytics) {
        if (callback) {
          callback()
        }
      } else {
        let primaryOrg = this.getPrimaryOrganization()
        let secondaryOrg = this.getSecondaryOrganization()
        let primaryOrgName = (primaryOrg) ? primaryOrg.name : undefined
        let secondaryOrgName = (secondaryOrg) ? secondaryOrg.name : undefined
        $timeout(() => {
          // If call to segment analytics.identify fails to invoke our callback, we should still
          // be able to proceed.
          if (callback) {
            callback()
            callback = undefined
          }
        }, 500)
        analytics.identify(this.id, {
          name: this.firstName + ' ' + this.lastName,
          email: this.email,
          primaryOrgName: primaryOrgName,
          secondaryOrgName: secondaryOrgName
        }, function () {
          if (primaryOrg) {
            analytics.group(primaryOrg.id, {
              name: primaryOrg.name
            })
          }
          if (callback) {
            callback()
            callback = undefined
          }
          $rootScope.initAnalytics = true
        })
      }
    }

    callAppcues () {
      if (this.isAuthenticated()) {
        if ($rootScope.appcuesIdentify) {
          Appcues.start()
        } else {
          let primaryOrg = this.getPrimaryOrganization()
          let secondaryOrg = this.getSecondaryOrganization()
          let primaryOrgName = (primaryOrg) ? primaryOrg.name : undefined
          let secondaryOrgName = (secondaryOrg) ? secondaryOrg.name : undefined
          Appcues.identify(this.id, {
            name: this.firstName + ' ' + this.lastName,
            email: this.email,
            createdTime: this.createdTime,
            firstSigninTime: this.firstSigninTime,
            lastSigninTime: this.lastSigninTime,
            numSignins: this.numSignins,
            primaryOrgName: primaryOrgName,
            secondaryOrgName: secondaryOrgName,
            daysInTrial: this.daysInTrial,
            smartvidBrowser: $rootScope.uaResult.browser.name,
            smartvidOS: $rootScope.uaResult.os.name
          })
          $rootScope.appcuesIdentify = true
        }
      } else {
        let anonId = 'anon:' + utils.createGuid()
        Appcues.identify(anonId, {
          smartvidBrowser: $rootScope.uaResult.browser.name,
          smartvidOS: $rootScope.uaResult.os.name
        })
      }
    }

    /**
     * Set one or more properties on user for storage.
     * See currentUser.get(propName) also.
     *
     * TODO: need backend support for storing user state server-side.
     * @param attrs
     * @returns {boolean}
     */
    update (attrs) {
      super.update(attrs)
      $window.localStorage.setItem(storageKey, JSON.stringify(this))


      $rootScope.$broadcast('sv-current-user-updated')
      return true
    }

    /**
     * We can set UI properties on current user for storage and retrieval.
     * Use currentUser.update({propertyName: propValue, ...}) to set one or more properties.
     *
     * TODO: need backend support for storing user state server-side.
     * @param property
     * @returns {*}
     */
    get (property) {
      return this[property]
    }

    getAssetOrgRoleByName (name) {
      return _.findWhere(this.organizationRoles, {name: name})
    }

    getAssetGroupRoleByName (name) {
      return _.findWhere(this.regionRoles, {name: name})
    }

    getObservationOrgRoleByName (name) {
      return _.findWhere(this.observationOrganizationRoles, {name: name})
    }

    getObservationGroupRoleByName (name) {
      return _.findWhere(this.observationRegionRoles, {name: name})
    }

    getProjectRole (project) {
      return _.findWhere(this.projectRoles, {name: project.userProjectRole})
    }

    getAssignableProjectRoles (project) {
      return _.filter(this.projectRoles, function (role) {
        return _.contains(project.assignableProjectRoles, role.name)
      })
    }

    getAssignableObservationProjectRoles (project) {
      return _.filter(this.observationProjectRoles, function (role) {
        return _.contains(project.assignableObservationProjectRoles, role.name)
      })
    }

    getCreatableOrganizationRoles (organizationId) {
      let org = this.getOrganization(organizationId)
      return _.filter(this.organizationRoles, function (role) {
        return _.contains(org.creatableRoles, role.name)
      })
    }

    getCreatableOrganizationObservationRoles (organizationId) {
      let org = this.getOrganization(organizationId)
      return _.filter(this.observationOrganizationRoles, function (role) {
        return _.contains(org.creatableObservationRoles, role.name)
      })
    }

    getAllSharingRoles () {
      return this.sharingRoles
    }

    getSearchSharingRolesForProject (project) {
      return _.filter(this.sharingRoles, function (role) {
        return _.contains(project.searchSharingRoles, role.name)
      })
    }

    getSharingRolesForAsset (asset) {
      return _.filter(this.sharingRoles, function (role) {
        return _.contains(asset.sharingRoles, role.name) && !_.startsWith(role.name, 'GLOBAL')
      })
    }

    getCrossProjectSearchSharingRoles () {
      let crossProjectSharingRoles = this.crossProjectSearchSharingRoles
      return _.filter(this.sharingRoles, function (role) {
        return _.contains(crossProjectSharingRoles, role.name)
      })
    }

    getCrossProjectAssetSharingRolesForAssetAndOrgId (asset, organizationId) {
      let sharingRolesForAsset = this.getSharingRolesForAsset(asset)
      let canShareGlobalWritable = _canShareGlobalWritableForGivenAssetSharingRoles(sharingRolesForAsset)
      let canSharePublic = this.isPublicSharingEnabledForAssetOrg(organizationId)
      let crossProjectSharingRoles = this.crossProjectAssetSharingRoles
      return _(this.sharingRoles)
              .filter(role => {
                return _.contains(crossProjectSharingRoles, role.name) &&
                  (!(!canShareGlobalWritable && role.name === 'GLOBAL_CAN_WRITE')) &&
                  (!(!canSharePublic && _.startsWith(role.name, 'PUBLIC')))
              })
              .value()
    }

    isPublicSharingEnabledForAssetOrg (organizationId) {
      let org = this.getOrganization(organizationId)
      return org && org.publicSharingEnabled
    }

    isOrganizationInactive (organizationId) {
      if (!organizationId) return false
      let org = this.getOrganization(organizationId)
      return org && org.isOrganizationInactive
    }

    isSupportsCreationOrganizations () {
      return !!this.supportsCreationOrganizations
    }

    getDefaultHomePage () {
      let result = 'dashboard.projects'
      let defaultHomePage = this.userPreferences && this.userPreferences.defaultHomePage || ModuleType.ASSETS
      if (defaultHomePage === ModuleType.OBSERVATIONS && this.isObservationFeatureEnabledForAnyOrganization()) {
        result = 'dashboard.observations'
      }
      if (defaultHomePage === ModuleType.ANALYTICS && this.isAnalyticsFeatureEnabledForAnyOrganization()) {
        result = 'dashboard.analytics'
      }
      return result
    }

    getSupportedFileExtensions () {
      if (!this.supportedImageExtensions) {
        return ['.JPG', '.JPEG', '.PNG', '.TIFF', '.GIF', '.BMP', '.JFIF', '.MP3', '.WAV', '.MOV', '.MPG', '.AVI', '.FLV', '.F4V', '.MP4', '.M4V', '.ASF', '.WMV', '.VOB', '.MOD', '.3GP', '.MKV', '.DIVX', '.XVID', '.WEBM']
      }
      return this.supportedImageExtensions.concat(this.supportedAudioExtensions, this.supportedVideoExtensions).map(e => '.' + e)
    }
  }

  return new CurrentUserModel()
})
