/* global angular _ */
/**
 * Created by ryanrubbico on 1/2/18.
 */

angular.module('smartvid').factory('StorageServiceSyncSettingsModel', function (BaseModel) {
  class StorageServiceSyncSettingsModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        externalUserId: undefined,
        folderId: undefined,
        integrationType: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return StorageServiceSyncSettingsModel
})
