import {
  DATA_CANNOT_BE_CALCULATED,
  NO_DATA_AVAILABLE,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import * as _ from 'lodash'
import { Subscription } from 'rxjs'
import { ChartData } from 'modules/insights/dashboard/charts/models/base-chart.type'

export function getColorClassForValue(value: number, thresholds: number[], isBackground = false): string {
  if (isNA(value)) {
    return isBackground ? 'bg-na-value' : 'na-value'
  }

  if (value < thresholds[0]) {
    return isBackground ? 'bg-critical-value' : 'critical-value'
  } else if (value < thresholds[1]) {
    return isBackground ? 'bg-warning-value' : 'warning-value'
  }

  return isBackground ? 'bg-normal-value' : 'normal-value'
}

type ThresholdClassTuple = [number, string]
export function pickClassNameFor(value: number, naClass: string, thresholds: ThresholdClassTuple[]): string {
  if (isNA(value)) {
    return naClass
  }

  let sortedThresholds = _.sortBy(thresholds, [
    function(t) {
      return t[0]
    },
  ])

  let thresholdData = _.find(sortedThresholds, t => {
    if (value < t[0]) {
      return true
    }
    return false
  })

  return thresholdData ? thresholdData[1] : sortedThresholds[sortedThresholds.length - 1][1]
}

export function getSign(value: number): string {
  return isNA(value) || value < 0 ? '' : '+'
}

export function strIfNotNA(value: number, str: string): string {
  return isNA(value) ? '' : str
}

export function isNA(value: number): boolean {
  return value === NO_DATA_AVAILABLE || value === DATA_CANNOT_BE_CALCULATED
}

export function chartHasAllNullValues(chartData: ChartData): boolean {
  return (
    chartData != null &&
    chartData.dataTable.every((elem, index) => {
      return index > 0 ? elem[1] == null : true
    })
  )
}

export function convertNullValuesToZero(chartData: ChartData): ChartData {
  if (chartData !== null) {
    chartData.dataTable.forEach((elem, index) => {
      chartData.dataTable[index] = elem.map(e => (e === null ? 0 : e))
    })
  }
  return chartData
}

export function chartHasSomeNullValues(chartData: ChartData): boolean {
  return (
    chartData != null &&
    chartData.dataTable.some((elem, index) => {
      return index > 0 ? elem[1] == null : false
    })
  )
}

export function makeMultilineChartTooltip(text: string[]): string {
  let tooltipPrefixHtml =
    '<div style="padding:5px 5px 5px 5px; z-index: 10000;"><span style="white-space: nowrap; color: #2a2a2a; font-size: 12px; font-weight: bold !important;">'
  let tooltipSuffixHtml = '</span></div>'

  return tooltipPrefixHtml + text.join('<br>') + tooltipSuffixHtml
}

export function getFirstLettersOfWords(val: string, limit: number, caseFunction: (srt: string) => string): string {
  let words = val.split(' ', limit)
  if (words.length === limit) {
    let letters = ''
    words.forEach(w => {
      letters += caseFunction(w[0])
    })

    return letters
  } else if (val) {
    return val.length >= 2 ? caseFunction(val.substring(0, 2)) : caseFunction(val)
  }

  return ''
}

export function forceUnsubscribe(subscription: Subscription) {
  if (subscription) {
    subscription.unsubscribe()
    // PL: Attempt at workaround for the issue described in https://github.com/ReactiveX/rxjs/issues/4230
    if ((subscription as any).destination) {
      if ((subscription as any).destination.unsubscribe) {
        try {
          ;(subscription as any).destination.unsubscribe()
        } catch (e) {
          console.warn('Unable to unsubscribe destination of a Subscription', e)
        }
      }
      ;(subscription as any).destination = undefined
    }

    if ((subscription as any)._parentOrParents) {
      console.warn(
        '>>> Non empty parent subscriptions after unsubscribe and cleanup',
        (subscription as any)._parentOrParents
      )
    }
    if ((subscription as any)._subscriptions) {
      console.warn('>>> Non empty subscriptions after unsubscribe and cleanup', (subscription as any)._subscriptions)
    }
  }
}
