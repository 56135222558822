import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators'
import { Component, Injectable, Input, OnDestroy, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { FlatTreeControl } from '@angular/cdk/tree'
import { SelectionModel } from '@angular/cdk/collections'
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree'
import { ForgeService, ForgeTemplate } from 'modules/integrations/services/forge.service'
import {
  ForgeCheckboxValueType,
  ForgeComponentChangeService,
} from 'modules/integrations/services/forge-component-change.service'

/** Nested node */
class FolderNode {
  childrenChange = new BehaviorSubject<FolderNode[]>([])

  get children(): FolderNode[] {
    return this.childrenChange.value
  }

  constructor(public name: string, public templateId: string, public expandable, public offset) {}
}

/** Flat node with expandable and level information */
class FolderFlatNode {
  constructor(
    public name: string,
    public templateId: string,
    public level = 1,
    public expandable = false,
    public offset = 0,
    public isLoading = false
  ) {}
}

const ROOT_FOLDER_ID = '@@FORGE_FOLDER_ROOT_NODE_ID@@'
const LOAD_MORE_NODE = '@@FORGE_LOAD_MORE_NODE@@'
const PAGE_SIZE = 3000

@Injectable()
export class ForgeTemplatesDatabase {
  dataChange = new BehaviorSubject<FolderNode[]>([])

  private _nodeMap = new Map<string, FolderNode>()
  private _cachedNodes: FolderNode[] = []
  private _prevTerm = undefined

  constructor(private _forgeService: ForgeService, private translate: TranslateService) {}

  initialize() {
    this._nodeMap.clear()
    this._cachedNodes = []
    this._prevTerm = undefined
    const rootNode = this.createRootNode()
    this._nodeMap.set(rootNode.templateId, rootNode)
    this.dataChange.next([rootNode])
  }

  createRootNode() {
    const rootName = this.translate.instant('integrations.forge.allTemplates')
    return new FolderNode(rootName, ROOT_FOLDER_ID, true, 0)
  }

  getRootNode() {
    return this._nodeMap.get(ROOT_FOLDER_ID)
  }

  isRootNode(node: FolderFlatNode) {
    return ROOT_FOLDER_ID === node.templateId
  }

  getLoadedSize() {
    return this._cachedNodes.length
  }

  isFullyLoaded() {
    const ln = this._cachedNodes.length
    return ln > 0 && !this._cachedNodes.find(n => n.name === LOAD_MORE_NODE)
  }

  loadMore(
    projectId: string,
    flatNode: FolderFlatNode,
    searchTerm?: string,
    expandFn?: (FolderFlatNode) => void
  ): void {
    const rootNode = this.getRootNode()
    flatNode.isLoading = true
    if (this._cachedNodes.length > 0) {
      if (searchTerm && searchTerm.length > 0) {
        this._searchFromCache(searchTerm)
        expandFn?.(flatNode)
      } else {
        rootNode.childrenChange.next(this._cachedNodes)
      }
      flatNode.isLoading = false
      this.dataChange.next(this.dataChange.value)
      return
    }
    this._fetchTemplates(projectId, flatNode).subscribe(resp => {
      this._cachedNodes = resp.map(t => this._mapTemplateToNode(t))
      rootNode.childrenChange.next(this._cachedNodes)
      this.dataChange.next(this.dataChange.value)

      if (searchTerm && searchTerm.length > 0) {
        this._searchFromCache(searchTerm)
        expandFn?.(flatNode)
      }
    })
  }

  private _searchFromCache(searchTerm: string) {
    const filtered = this._searchLocal(this._cachedNodes, searchTerm)
    this.getRootNode().childrenChange.next([...filtered])
    this._prevTerm = searchTerm
    this.dataChange.next(this.dataChange.value)
  }

  private _fetchTemplates(projectId: string, node: FolderFlatNode): Observable<ForgeTemplate[]> {
    node.isLoading = true
    return this._forgeService.getTemplates(projectId, null, node.offset, PAGE_SIZE).pipe(
      take(1),
      tap({
        next: () => {
          node.isLoading = false
        },
        error: err => {
          console.error('failed to fetch forge checklist templates', err)
          node.isLoading = false
        },
      })
    )
  }

  private _searchLocal(nodes: FolderNode[], searchTerm: string): FolderNode[] {
    const bySearch = (n: FolderNode) => n.name.toLowerCase().includes(searchTerm.toLowerCase())
    return nodes
      .filter(n => bySearch(n) || n.children.find(bySearch))
      .map(n => {
        if (!bySearch(n)) {
          n.childrenChange.next([...n.children.filter(bySearch)])
        }
        return n
      })
  }

  private _mapTemplateToNode(template: ForgeTemplate): FolderNode {
    if (this._nodeMap.has(template.id)) {
      return this._nodeMap.get(template.id)
    }
    const result = new FolderNode(template.title, template.id, false, 0)
    this._nodeMap.set(template.id, result)
    return result
  }
}

/**
 * @title Forge folder tree with checkboxes
 */
@Component({
  selector: 'sv-forge-template-tree',
  templateUrl: 'forge-template-tree.component.html',
  styleUrls: ['forge-template-tree.component.css'],
  providers: [ForgeTemplatesDatabase],
})
export class ForgeTemplateTreeComponent implements OnInit, OnDestroy {
  @Input() projectId: string
  @Input() readOnly: boolean

  treeControl: FlatTreeControl<FolderFlatNode>
  treeFlattener: MatTreeFlattener<FolderNode, FolderFlatNode>
  dataSource: MatTreeFlatDataSource<FolderNode, FolderFlatNode>
  checkboxSelection: SelectionModel<FolderFlatNode>
  partiallySelection: SelectionModel<FolderFlatNode>
  expandable = false
  searchTerm = ''

  private $destroy = new Subject<void>()
  private $searchTitle = new Subject<string>()
  private _nodeMap = new Map<string, FolderFlatNode>()
  private _initialTemplateIds: string[] = []

  constructor(private _database: ForgeTemplatesDatabase, private _folderTreeChange: ForgeComponentChangeService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isNodeExpandable, this.getChildren)
    this.treeControl = new FlatTreeControl<FolderFlatNode>(this.getLevel, this.isNodeExpandable)
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener)
    this.checkboxSelection = new SelectionModel<FolderFlatNode>(true)
    this.partiallySelection = new SelectionModel<FolderFlatNode>(true)
  }

  ngOnInit(): void {
    this._database.dataChange.pipe(takeUntil(this.$destroy)).subscribe(data => {
      this.dataSource.data = data
      this.autoSelectChildren(data, 0, this._initialTemplateIds)
    })
    this._folderTreeChange.forgeProjectChange.pipe(takeUntil(this.$destroy)).subscribe(changeEvent => {
      this._nodeMap.clear()
      this._database.initialize()
      this.searchTerm = ''
      this._initialTemplateIds = changeEvent.checklistTemplateIds
      this.checkboxSelection.clear()
      this.partiallySelection.clear()
      this.expandable = changeEvent.forgeProjectId !== null && changeEvent.forgeProjectId !== undefined
      const rootFlatNode = this.getRootFlatNode()
      switch (changeEvent.checklistSelectedType) {
        case ForgeCheckboxValueType.PARTIALLY_SELECTED:
          this.checkboxSelection.deselect(rootFlatNode)
          this.partiallySelection.select(rootFlatNode)
          break
        case ForgeCheckboxValueType.NONE_SELECTED:
          this.checkboxSelection.deselect(rootFlatNode)
          this.partiallySelection.deselect(rootFlatNode)
          break
        default:
          this.checkboxSelection.select(rootFlatNode)
          this.partiallySelection.deselect(rootFlatNode)
          break
      }
    })
    this._database.initialize()
    const rootFlatNode = this.getRootFlatNode()
    this.checkboxSelection.select(rootFlatNode)
    this.partiallySelection.deselect(rootFlatNode)

    this.$searchTitle.pipe(debounceTime(600), distinctUntilChanged(), takeUntil(this.$destroy)).subscribe(term => {
      const rootNode = this.getRootFlatNode()
      this._database.loadMore(this.projectId, rootNode, term, n => this.treeControl.expand(n))
    })
  }

  ngOnDestroy(): void {
    this.$destroy.next()
    this.$destroy.complete()
  }

  getFlatNode(nodeId: string): FolderFlatNode {
    return this._nodeMap.get(nodeId)
  }

  getRootFlatNode(): FolderFlatNode {
    return this.getFlatNode(ROOT_FOLDER_ID)
  }

  isRootNode(node: FolderFlatNode): boolean {
    return node.templateId === ROOT_FOLDER_ID
  }

  isSearchMode() {
    return this.searchTerm && this.searchTerm.length > 0
  }

  isExpandable(): boolean {
    return this.expandable && !this.isSearchMode()
  }

  onSearch(term: string) {
    this.$searchTitle.next(term.trim())
  }

  clearSearch() {
    this.searchTerm = ''
    this.onSearch(this.searchTerm)
  }

  autoSelectChildren(nodes: FolderNode[], level: number, templateIds: string[]) {
    nodes.forEach(node => {
      const flatNode = this.getFlatNode(node.templateId)
      if (this.checkboxSelection.isSelected(flatNode)) {
        const descendants = this.treeControl.getDescendants(flatNode)
        if (descendants && descendants.length > 0) {
          this.checkboxSelection.select(...descendants)
          this.partiallySelection.deselect(...descendants)
        }
      }
      if (templateIds.includes(flatNode.templateId)) {
        this.checkboxSelection.select(flatNode)
        this.partiallySelection.deselect(flatNode)
      }
      this.autoSelectChildren(node.children, level + 1, templateIds)
    })
  }

  getChildren = (node: FolderNode): Observable<FolderNode[]> => node.childrenChange

  transformer = (node: FolderNode, level: number) => {
    const existingNode = this._nodeMap.get(node.templateId)
    if (existingNode) {
      return existingNode
    }
    const newNode = new FolderFlatNode(node.name, node.templateId, level, node.expandable, node.offset)
    this._nodeMap.set(node.templateId, newNode)
    return newNode
  }

  getLevel = (node: FolderFlatNode) => node.level

  isNodeExpandable = (node: FolderFlatNode) => node.expandable

  hasChild = (_: number, node: FolderFlatNode) => node.expandable

  emitSelectionChange(): void {
    let rootNode = this.getRootFlatNode()
    let selectedType = ForgeCheckboxValueType.ALL_SELECTED
    let selectedTemplateIds: string[] = []
    if (!this.checkboxSelection.isSelected(rootNode) && this.partiallySelection.isSelected(rootNode)) {
      selectedType = ForgeCheckboxValueType.PARTIALLY_SELECTED
      selectedTemplateIds = this.checkboxSelection.selected.map(n => n.templateId)
    } else if (!this.checkboxSelection.isSelected(rootNode) && !this.partiallySelection.isSelected(rootNode)) {
      selectedType = ForgeCheckboxValueType.NONE_SELECTED
      selectedTemplateIds = []
    }
    this._folderTreeChange.templateSelectedChange.emit({
      checklistSelectedType: selectedType,
      checklistTemplateIds: selectedTemplateIds,
    })
  }

  clickLoadMore(node: FolderFlatNode) {
    this._database.loadMore(this.projectId, node, this.searchTerm)
  }

  toggleCheckbox(folderNode: FolderFlatNode): void {
    this.checkboxSelection.toggle(folderNode)
    this.checkboxSelection.isSelected(folderNode)
    this.partiallySelection.isSelected(folderNode)
    if (folderNode.templateId === ROOT_FOLDER_ID) {
      const descendants = this.treeControl.getDescendants(folderNode)
      if (this.checkboxSelection.isSelected(folderNode)) {
        this.checkboxSelection.select(...descendants)
      } else {
        this.checkboxSelection.deselect(...descendants)
      }
      this.partiallySelection.deselect(folderNode)
      this.partiallySelection.deselect(...descendants)
      descendants.forEach(child => this.checkboxSelection.isSelected(child))
    }
    this._initialTemplateIds = []
    this.checkAllParentsSelection(folderNode)
    this.emitSelectionChange()
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FolderFlatNode): void {
    let parent: FolderFlatNode | null = this.getParentNode(node)
    while (parent) {
      if (this.isRootNode(parent) && this.isSearchMode()) {
        this.checkRootNode(parent)
      } else {
        this.checkParentNodeSelection(parent)
      }
      parent = this.getParentNode(parent)
    }
  }

  /** Check root node checked state and change it accordingly */
  checkParentNodeSelection(node: FolderFlatNode): void {
    const descendants = this.treeControl.getDescendants(node)
    const allSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checkboxSelection.isSelected(child)
      })
    const someSelected =
      descendants.length > 0 &&
      descendants.some(child => {
        return this.checkboxSelection.isSelected(child) || this.partiallySelection.isSelected(child)
      })

    if (!allSelected && someSelected) {
      this.checkboxSelection.deselect(node)
      this.partiallySelection.select(node)
    } else if (allSelected && someSelected) {
      this.checkboxSelection.select(node)
      this.partiallySelection.deselect(node)
    } else if (!allSelected && !someSelected) {
      this.checkboxSelection.deselect(node)
      this.partiallySelection.deselect(node)
    }
  }

  checkRootNode(rootNode: FolderFlatNode): void {
    const selectedNodes = this.checkboxSelection.selected.filter(n => n.templateId !== ROOT_FOLDER_ID)
    if (selectedNodes.length === this._database.getLoadedSize()) {
      this.checkboxSelection.select(rootNode)
      this.partiallySelection.deselect(rootNode)
    } else if (selectedNodes.length > 0) {
      this.checkboxSelection.deselect(rootNode)
      this.partiallySelection.select(rootNode)
    } else {
      this.checkboxSelection.deselect(rootNode)
      this.partiallySelection.deselect(rootNode)
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: FolderFlatNode): FolderFlatNode | null {
    const currentLevel = this.getLevel(node)
    if (currentLevel < 1) {
      return null
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i]
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode
      }
    }
    return null
  }
}
