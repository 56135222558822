import { Injectable } from '@angular/core'
import {
  DashboardDateRangeTimeUnit,
  DashboardType,
  InsightsDashboardParameters,
  TIME_UNIT_TO_DAYS,
} from '../dashboard/models/insights-dashboard.model'
import * as moment from 'moment'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class InsightsDataHelper {
  private _currentInsightsDashboardParameters: { [key in DashboardType]?: InsightsDashboardParameters } = {}
  private _onParametersChange$: { [key in DashboardType]?: Subject<InsightsDashboardParameters> } = {}
  private _insightsSelectedTab: { [key in DashboardType]?: number } = {}
  private _onInsightsSelectedTabChanged$: { [key in DashboardType]?: Subject<number> } = {}

  constructor() {
    Object.keys(DashboardType).forEach(k => {
      this._currentInsightsDashboardParameters[k] = {
        organizationId: '',
        userId: '',
        dateRangeTimeUnit: DashboardDateRangeTimeUnit.LAST_30,
        dateRangeEnd: new Date(),
        dateRangeStart: moment(new Date())
          .subtract(TIME_UNIT_TO_DAYS[DashboardDateRangeTimeUnit.LAST_30], 'day')
          .toDate(),
        useDemoData: false,
        ignoreCache: false,
      }
      this._onParametersChange$[k] = new Subject<InsightsDashboardParameters>()

      this._onInsightsSelectedTabChanged$[k] = new Subject<number>()
      this._insightsSelectedTab[k] = 0
    })
  }

  setCurrentInsightsDashboardParameters(dashboardType: DashboardType, parameters: InsightsDashboardParameters) {
    this._currentInsightsDashboardParameters[dashboardType] = parameters
    this._onParametersChange$[dashboardType].next(this._currentInsightsDashboardParameters[dashboardType])
  }

  getCurrentInsightsDashboardParameters(dashboardType: DashboardType): InsightsDashboardParameters {
    return this._currentInsightsDashboardParameters[dashboardType]
  }

  setIds(
    dashboardType: DashboardType,
    organizationId: string,
    projectGroupId: string,
    projectId: string,
    userId: string,
    emitEvent = false
  ) {
    this._currentInsightsDashboardParameters[dashboardType].organizationId = organizationId
    this._currentInsightsDashboardParameters[dashboardType].projectGroupId = projectGroupId
    this._currentInsightsDashboardParameters[dashboardType].projectId = projectId
    this._currentInsightsDashboardParameters[dashboardType].userId = userId
    if (emitEvent) {
      this._onParametersChange$[dashboardType].next(this._currentInsightsDashboardParameters[dashboardType])
    }
  }

  setDateRangeTimeUnit(dashboardType: DashboardType, dateRangeTimeUnit: DashboardDateRangeTimeUnit) {
    this._currentInsightsDashboardParameters[dashboardType].dateRangeTimeUnit = dateRangeTimeUnit
    this._currentInsightsDashboardParameters[dashboardType].dateRangeEnd = new Date()
    if (dateRangeTimeUnit === DashboardDateRangeTimeUnit.MONTH_TO_DATE) {
      this._currentInsightsDashboardParameters[dashboardType].dateRangeStart = moment()
        .startOf('month')
        .toDate()
    } else {
      this._currentInsightsDashboardParameters[dashboardType].dateRangeStart = moment(new Date())
        .subtract(TIME_UNIT_TO_DAYS[dateRangeTimeUnit], 'day')
        .toDate()
    }
    this._onParametersChange$[dashboardType].next(this._currentInsightsDashboardParameters[dashboardType])
  }

  onParametersChange$(dashboardType: DashboardType) {
    return this._onParametersChange$[dashboardType]
  }

  setIgnoreCache(dashboardType: DashboardType, ignoreCache: boolean) {
    this._currentInsightsDashboardParameters[dashboardType].ignoreCache = ignoreCache
    this._onParametersChange$[dashboardType].next(this._currentInsightsDashboardParameters[dashboardType])
  }

  onInsightsSelectedTabChanged$(dashboardType: DashboardType) {
    return this._onInsightsSelectedTabChanged$[dashboardType]
  }

  /* stored index based for each dashboard type on
  OrganizationAssetsDashboardTabs or ProjectGroupAssetsDashboardTabs enums respectively
  */
  setInsightsSelectedTab(dashboardType: DashboardType, index: number) {
    this._insightsSelectedTab[dashboardType] = index
    this._onInsightsSelectedTabChanged$[dashboardType].next(this._insightsSelectedTab[dashboardType])
  }

  getInsightsTabSelectedTab(dashboardType: DashboardType): number {
    return this._insightsSelectedTab[dashboardType]
  }

  setOrgHasGroups(dashboardType: DashboardType, orgHasGroups: boolean) {
    this._currentInsightsDashboardParameters[dashboardType].orgHasGroups = orgHasGroups
  }

  getOrgHasGroups(dashboardType: DashboardType): boolean {
    return this._currentInsightsDashboardParameters[dashboardType].orgHasGroups
  }
}
