/* global angular analytics _ */

angular.module('smartvid').controller('StructionSiteLoginCtrl', function (MAX_STRUCTION_SITE_USER_ID_LENGTH, MAX_STRUCTION_SITE_USER_PASSWORD_LENGTH, $scope, $timeout, $rootScope, smartvidApi) {
    const CONFLICT = 'CONFLICT'
    const UNAUTHORIZED = 'UNAUTHORIZED'
    const STRUCTIONSITE_USER_ALREADY_CONNECTED = 'OAUTH_MULTIPLE_SMARTVID_USERS_SAME_EXTERNAL_USER'
    $scope.incorrectLoginError = false
    $scope.inTransit = false

    $scope.connectToStructionSite = () => {
        analytics.track('Connect to StrcutionSite - login', {
            category: 'Integration Action'
        })

        $scope.inTransit = true

        let customErrorHandler = (response) => {
            if (response.errorCode === CONFLICT && _.find(response.errorMessages,
                (m) => {
                    return m.label === STRUCTIONSITE_USER_ALREADY_CONNECTED
                })) {
                $scope.userStructionSiteUserAlreadyConnected = true
                return true
            } else if (response.errorCode === UNAUTHORIZED) {
                $scope.incorrectLoginError = true
                return true
            }

            return false
        }

        smartvidApi.loginToStructionSiteIntegration($scope.name, $scope.password, customErrorHandler).then((data) => {
            $timeout(function () {
                $scope.inTransit = false
                $rootScope.$broadcast('sv-connected-to-structionsite', data)
                $scope.modal.close()
            }, 100)
        }, () => {
            $scope.inTransit = false
        })

        $scope.noNameOrPassword = () => {
            return $scope.name === undefined || $scope.name === '' ||
                $scope.password === undefined || $scope.password === ''
        }
    }
})

