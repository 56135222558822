/* global angular, _ */

angular.module('smartvid').controller('AssetOptionsCtrl', function ($scope, $rootScope, modal, NavController, $timeout,
                                                                    dashboardDataHelper, searchResultService, moveAssetsService) {
  $scope.deleteAsset = () => {
    $scope.flyout.isOpen = false
    modal.open('deleteConfirm', {
      confirm () {
        $rootScope.$broadcast('assetGrid:deleteAsset', $scope.asset)
        $scope.flyout.close()
      },
      hasSelections: 1
    })
  }
  $scope.isInSearchContext = () => {
    return searchResultService.isInSearchContext() || searchResultService.isInGlobalSearchContext()
  }
  $scope.isProjectInactive = () => {
    const projectId = $scope.asset.projectId;
    const projectByProjectId = dashboardDataHelper.getProjectByProjectId(projectId)
    return projectByProjectId && projectByProjectId.isInactive
  }
  $scope.forceDeleteAsset = () => {
    $scope.flyout.isOpen = false
    modal.open('forceDeleteConfirm', {
      confirm () {
        $rootScope.$broadcast('assetGrid:forceDeleteAsset', $scope.asset)
        $scope.flyout.close()
      },
      hasSelections: 1
    })
  }

  $scope.undeleteAsset = () => {
    $rootScope.$broadcast('assetGrid:undeleteAsset', $scope.asset)
    $scope.flyout.close()
  }

  $scope.reRunSmartTaggingAsset = () => {
    $scope.flyout.isOpen = false
    modal.open('reRunSmartTaggingConfirm', {
      confirm () {
        $rootScope.$broadcast('assetGrid:reRunSmartTaggingAsset', $scope.asset)
        $scope.flyout.close()
      },
      hasSelections: 1
    })
  }

  $scope.canMoveAssets = () => {
    return moveAssetsService.canMoveAssets($scope.assets.getSelected())
  }

  $scope.openMoveAssetModal = () => {
    let selected = $scope.assets.getSelected()
    $scope.flyout.isOpen = false
    if (selected.length > 0) {

      $scope.modal.open('moveAssetModal', {
        assets: selected,
        collection: $scope.assets,
        currentProject: $scope.currentProject,
        allSelected: $scope.assets.allSelected
      })

    }
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top - 20,
      left: $scope.styles.left + 10
    }
  }

  $scope.openAssetProperties = function (assets, asset) {
    $scope.flyout.close()
    _.each(assets.models, (model) => {
      model.selected = false
    })
    asset.selected = true
    $rootScope.$broadcast('sv-placards-selected')
    $timeout(function () {
      NavController.openRightNav()
    }, 10)
  }
})
