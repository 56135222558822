/* global angular _ */

angular.module('smartvid').factory('AconexRegionModel', function (BaseModel) {
  class AconexRegionModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        name: undefined,
        displayName: undefined
      }
      super(_.defaults(attrs || {}, defaults))
    }
  }

  return AconexRegionModel
})
