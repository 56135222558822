import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { TranslateModule } from '@ngx-translate/core'

import { SmartvidMaterialModule } from 'modules/material/material.module'

import { FormlyModule } from '@ngx-formly/core'
import { FormlyMaterialModule } from '@ngx-formly/material'

import { HttpClientModule } from '@angular/common/http'

import { AppSharedModule } from 'shared/shared.module'

import { ProjectTreeComponent } from './components/project-tree/project-tree.component'
import {
  NavigationPanelComponent,
  OrganizationListPipe,
  OrganizationMenuItemDirective,
  ProjectListPipe,
  SmartvidSpinnerDirective,
  UserMenuItemDirective,
} from './components/navigation-panel/navigation-panel.component'
import { AddUserDialogComponent } from './components/add-user-dialog/add-user-dialog.component'
import { ProjectItemMenuComponent } from './components/project-tree/menu-item/project-item-menu.component'
import { InsightsDashboardModule } from 'modules/insights/insights-dashboard.module'
import { AdminOrganizationIntegrationsControlBarComponent } from './components/admin-organization-integrations-control-bar/admin-organization-integrations-control-bar.component'
import { AdminOrganizationIntegrationsComponent } from './components/admin-organization-integrations/admin-organization-integrations.component'
import { Ng2GoogleChartsModule } from 'ng2-google-charts'
import { AdminOrganizationIntegrationsTableComponent } from './components/admin-organization-integrations-table/admin-organization-integrations-table.component'
import { AdminOrganizationIntegrationsSearchComponent } from './components/admin-organization-integrations-search/admin-organization-integrations-search.component'
import { ConfirmDisconnectIntegrationsDialogComponent } from './components/admin-organization-integrations-table/confirm-disconnect-integrations-dialog/confirm-disconnect-integrations-dialog.component'
import { CommonModule } from '@angular/common'
import {
  OrganizationListComponent,
  OrganizationSortListPipe,
} from './components/organization-list/organization-list.component'
import { AdminOrganizationAnalyticsComponent } from './components/admin-organization-analytics/admin-organization-analytics.component'

@NgModule({
  imports: [
    CommonModule,
    Ng2GoogleChartsModule,
    SmartvidMaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    FormsModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    AppSharedModule,
    InsightsDashboardModule,
  ],
  declarations: [
    OrganizationMenuItemDirective,
    SmartvidSpinnerDirective,
    UserMenuItemDirective,
    OrganizationListPipe,
    OrganizationSortListPipe,
    ProjectListPipe,
    NavigationPanelComponent,
    AddUserDialogComponent,
    ProjectTreeComponent,
    ProjectItemMenuComponent,
    AdminOrganizationIntegrationsControlBarComponent,
    AdminOrganizationIntegrationsComponent,
    AdminOrganizationIntegrationsTableComponent,
    AdminOrganizationIntegrationsSearchComponent,
    ConfirmDisconnectIntegrationsDialogComponent,
    OrganizationListComponent,
    AdminOrganizationAnalyticsComponent,
  ],
  entryComponents: [
    NavigationPanelComponent,
    ProjectTreeComponent,
    ProjectItemMenuComponent,
    AdminOrganizationIntegrationsControlBarComponent,
    AdminOrganizationIntegrationsComponent,
    AddUserDialogComponent,
    ConfirmDisconnectIntegrationsDialogComponent,
  ],
})
export class AppAdminModule {}
