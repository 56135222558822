/* global angular */
angular.module('smartvid').service('constantRetrivalService', function ($filter
) {
  this.TAG_CONFIDENCE_LEVELS = getTagConfidenceLevels()
  this.REPORT_GENERATION_RECIPE_PDF = getReportGenerationRecipe('PDF')
  this.REPORT_GENERATION_RECIPE_CSV = getReportGenerationRecipe('CSV')
  this.DATE_RANGES = getDateRanges()
  this.SCHEDULE_TYPES = getSchedules()

  function getTagConfidenceLevels () {
    const TAG_CONFIDENCE_LEVELS = [
      {
        prettyName: $filter('i18next')('reporting.confidence.high'),
        value: 'HIGH'
      },
      {
        prettyName: $filter('i18next')('reporting.confidence.medium'),
        value: 'MEDIUM'
      },
      {
        prettyName: $filter('i18next')('reporting.confidence.low'),
        value: 'LOW'
      }
    ]
    return TAG_CONFIDENCE_LEVELS
  }

  function getReportGenerationRecipe (type) {
    let recipe = ''
    switch (type) {
      case 'PDF':
        recipe = 'PDF'
        break
      case 'CSV':
        recipe = 'CSV'
        break
      default:
        recipe = 'PDF'
        break
    }
    return recipe
  }

  function getDateRanges () {

    const LAST_12_MONTHS = {
      prettyName: $filter('i18next')('reporting.dateRange.12_months'),
      value: '12_MONTHS'
    }

    const LAST_6_MONTHS = {
      prettyName: $filter('i18next')('reporting.dateRange.6_months'),
      value: '6_MONTHS'
    }

    const LAST_3_MONTHS = {
      prettyName: $filter('i18next')('reporting.dateRange.3_months'),
      value: '3_MONTHS'
    }

    const LAST_30_DAYS = {
      prettyName: $filter('i18next')('reporting.dateRange.1_month'),
      value: '30_DAYS'
    }

    const LAST_7_DAYS = {
      prettyName: $filter('i18next')('reporting.dateRange.1_week'),
      value: '7_DAYS'
    }

    const LAST_1_DAY = {
      prettyName: $filter('i18next')('reporting.dateRange.1_day'),
      value: '1_DAY'
    }

    const DATE_RANGES = [
      LAST_12_MONTHS,
      LAST_6_MONTHS,
      LAST_3_MONTHS,
      LAST_30_DAYS,
      LAST_7_DAYS,
      LAST_1_DAY
    ]
    return DATE_RANGES
  }

  function getSchedules () {
    const SCHEDULE_NONE = {
      type: 'NEVER',
      displayName: $filter('i18next')('reporting.schedule.never'),
      hint: ''
    }
    const SCHEDULE_DAILY = {
      type: 'DAILY',
      displayName: $filter('i18next')('reporting.schedule.runDaily'),
      hint: $filter('i18next')('reporting.schedule.runDailyHint')
    }
    const SCHEDULE_WEEKLY = {
      type: 'WEEKLY',
      displayName: $filter('i18next')('reporting.schedule.runWeekly'),
      hint: $filter('i18next')('reporting.schedule.runWeeklyHint')
    }
    const SCHEDULE_MONTHLY = {
      type: 'MONTHLY',
      displayName: $filter('i18next')('reporting.schedule.runMonthly'),
      hint: $filter('i18next')('reporting.schedule.runMonthlyHint')
    }
    const SCHEDULE_TYPES = [
      SCHEDULE_NONE,
      SCHEDULE_DAILY,
      SCHEDULE_WEEKLY,
      SCHEDULE_MONTHLY
    ]
    return SCHEDULE_TYPES
  }

})
