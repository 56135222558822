/* global angular */

angular.module('smartvid').factory('EgnyteFolderCollection', function (BaseCollection, EgnyteFolderModel) {
  class EgnyteFolderCollection extends BaseCollection {
    constructor (models) {
      super(models, EgnyteFolderModel)
    }
  }

  return EgnyteFolderCollection
})
