export enum IntegrationType {
  // This is Procore integration type
  PROJECT_PHOTO_SYNC = 'PROJECT_PHOTO_SYNC',

  BIM360_FIELD_PHOTO_SYNC = 'BIM360_FIELD_PHOTO_SYNC',
  EGNYTE_SYNC = 'EGNYTE_SYNC',
  FORGE_SYNC = 'FORGE_SYNC',
  FORGE_ISSUE_SYNC = 'FORGE_ISSUE_SYNC',
  FORGE_CHECKLIST_INSTANCE_SYNC = 'FORGE_CHECKLIST_INSTANCE_SYNC',
  FORGE_ISSUE_CREATED_SYNC = 'FORGE_ISSUE_CREATED_SYNC',
  OXBLUE_SYNC = 'OXBLUE_SYNC',
  PLANGRID_SYNC = 'PLANGRID_SYNC',
  BOX_SYNC = 'BOX_SYNC',
  ACONEX_SYNC = 'ACONEX_SYNC',
  SHAREPOINT_SYNC = 'SHAREPOINT_SYNC',
  STRUCTION_SITE_SYNC = 'STRUCTION_SITE_SYNC',
}
