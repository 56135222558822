/* global angular */

angular.module('smartvid').directive('tagInstanceGroup', function (
    TRANSITION_SPEED, $rootScope
) {
  return {
    restrict: 'E',
    templateUrl: 'AssetViewer/tag-instance-group.html',
    link (scope, element) {
      let dropDownList = element.find('.dropdown-list')
      dropDownList.hide()
      scope.isMobile = $rootScope.isMobile
      scope.tagGroup = scope.tag

      scope.handleTagInstanceSelect = handleTagInstanceSelect

      scope.$watch('tagGroup.selected', handleTagGroupSelection)

      function handleTagGroupSelection (newVal, oldVal) {
        if (newVal === oldVal) {
          return
        }

        if (newVal) {
          dropDownList.slideDown(TRANSITION_SPEED)
        } else {
          dropDownList.slideUp(TRANSITION_SPEED)
        }
      }

      function handleTagInstanceSelect (event, tagInstance) {
        if (event) {
          event.preventDefault()
          event.stopPropagation()
        }
        scope.handleTagClick(tagInstance)
      }
    }
  }
})
