/* global angular */

angular.module('smartvid').directive('runReport', function (ReportName) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'modals/run-report.html',
    link (scope, element) {
      scope.ReportName = ReportName
      scope.data = {}
      scope.data.selectedReportType = scope.$parent.selectedReportType
      scope.data.report = scope.$parent.report
      scope.data.isReportView = scope.$parent.isReportView
      scope.data.confirm = scope.$parent.confirm
      scope.selectReportType = selectReportType

      function selectReportType (reportName) {
        scope.data.selectedReportType = reportName
      }
    }
  }
})
