/* global angular, _ */

angular.module('smartvid').factory('CommentsCollection', function (BaseCollection, CommentModel, $injector) {
  class CommentsCollection extends BaseCollection {

    postAddProcessing () {
      // We need to map any markupJSON to actual values on the model
      _.each(this.models, (model) => {
        model.populateValuesFromMarkupJSON()
      })
    }

    sortedIndexFunc (model) {
      return model ? model.startTime : undefined
    }

    getSelectedComment () {
      return _.find(this.models, (model) => {
        return model.selected
      })
    }

    getEditedComment () {
      return this.getSelectedComment() ||
        _.find(this.models, (model) => {
          return model.editMode
        })
    }

    unselectAll () {
      _.each(this.models, (model) => {
        // If we have a comment that is editMode we need to persist the changes to prod.
        model.selected = false
        model.editMode = false
        model.showMoreActive = false
      })
    }

    unselectAllAndPersistChanges (assetId) {
      _.each(this.models, (model) => {
        // We need to use an $injector to prevent a circular reference.
        let smartvidApi = $injector.get('smartvidApi')
        // If we have a comment that is editMode we need to persist the changes to prod.
        if (model.selected && model.editMode && !model.local) {
          smartvidApi.updateCommentForAsset(assetId, model)
        }
        model.selected = false
        model.editMode = false
      })
      // If we have any local comments hanging around we want to remove them.
      this.models = _.filter(this.models, (model) => {
        return !model.local
      })
    }

    // Local is a comment that is currently being created, but has not been saved to the server yet.
    // Typically this is because the meta data is still being filled out.
    getLocal () {
      return _.find(this.models, (model) => {
        return model.local
      })
    }
  }

  return CommentsCollection
})
