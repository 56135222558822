/* global angular */

angular.module('smartvid').controller('ImpersonateCtrl', function ($scope, $state, $timeout, smartvidApi, currentUser) {
  $scope.currentUser = currentUser
  $scope.submitted = false

  $scope.submit = () => {
    $scope.email = $scope.form.email.$viewValue

    if (!$scope.inTransit) {
      $scope.inTransit = true
      smartvidApi.impersonate($scope.email)
          .then(() => {
            $state.go('dashboard.projects')
          }).finally(() => {
            $timeout(() => {
              $scope.inTransit = false
            }, 500)
          }
      )
    }
  }
})
