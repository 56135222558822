import { FieldDataType } from '../../modules/observations/models/field-data-type.enum'

export class CustomFieldValue {
  public identifier: string
  public dataType: FieldDataType
  public valueLong: number
  public valueReal: number
  public valueBoolean: boolean
  public valueDate: number
  public valueString: string

  constructor(identifier: string, dataType: FieldDataType, value: any) {
    this.identifier = identifier
    this.dataType = dataType
    switch (this.dataType) {
      case FieldDataType.LONG:
        this.valueLong = value
        break
      case FieldDataType.REAL:
        this.valueReal = value
        break
      case FieldDataType.BOOLEAN:
        this.valueBoolean = value
        break
      case FieldDataType.DATE:
        this.valueDate = value.getTime()
        break
      default:
        this.valueString = value
    }
  }

  static getValue(value: CustomFieldValue) {
    switch (value.dataType) {
      case FieldDataType.LONG:
        return value.valueLong
      case FieldDataType.REAL:
        return value.valueReal
      case FieldDataType.BOOLEAN:
        return value.valueBoolean
      case FieldDataType.DATE:
        return new Date(value.valueDate)
    }
    return value.valueString
  }
}
