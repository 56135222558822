import { Component, Inject, Input, OnInit } from '@angular/core'
import { ScheduleInterval } from 'modules/insights/dashboard/models/report-dashboard.model'

@Component({
  selector: 'sv-reports-dashboard',
  templateUrl: 'reports-dashboard.component.html',
  styleUrls: ['reports-dashboard.component.scss'],
})
export class ReportsDashboardComponent implements OnInit {
  reportTypesAdapter = ScheduleInterval
  @Input() organizationId: string

  constructor(@Inject('$rootScope') public rootScope: any) {}

  ngOnInit(): void {}

  selectPhotoReportType() {
    this.rootScope.modal.open('runReport', { selectedReportType: 'PHOTO_REPORT', isReportView: true })
  }

  selectSummaryReportType() {
    this.rootScope.modal.open('runReport', { selectedReportType: 'SAFETY_SUMMARY_REPORT', isReportView: true })
  }
}
