export enum ObservationStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  WITH_PARTNER = 'WITH_PARTNER',
  DELAYED = 'DELAYED',
  CLOSED = 'CLOSED',
  CLOSED_REVIEWED = 'CLOSED_REVIEWED',
  READY_TO_REVIEW = 'READY_TO_REVIEW',
  OBSERVED = 'OBSERVED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}
