/* global angular _ */

angular.module('smartvid').factory('AconexUserModel', function (BaseModel) {
  class AconexUserModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined,
        firstName: undefined,
        lastName: undefined,
        userName: undefined,
        region: undefined
      }
      super(_.defaults(attrs || {}, defaults))
    }
  }

  return AconexUserModel
})
