/* global angular */

angular.module('smartvid').controller('JoinOrganizationCtrl', function ($stateParams, $state, $scope, smartvidApi) {
  let token = $stateParams.token

  let promise = smartvidApi.joinOrganization(token)
  promise.then((data) => {
    $state.go('dashboard.adminOrganizations.organizationId', {
      organizationId: data.organizationId,
      organizationName: data.organizationName,
      user: data.user,
      showJoinConfirmedNotification: true
    })
  }, () => {
    $state.go('login')
  })
})
