/* global angular _ */

angular.module('smartvid').factory('ForgeProjectModel', function (BaseModel) {
  class ForgeProjectModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined,
        name: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return ForgeProjectModel
})
