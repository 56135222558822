import { Observable, Subject, from, forkJoin } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { Component, Input, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core'
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import { NGXLogger } from 'ngx-logger'
import { Select, Store } from '@ngxs/store'
import * as moment from 'moment'
import * as _ from 'lodash'
import { StateService } from '@uirouter/core'
import { DashboardDataHelperService } from 'modules/core/services/dashboard-data-helper.service'
import {
  Observation,
  ObservationFilterCriteria,
  ObservationGroup,
  ObservationPage,
  ObservationQuickFilterCriteria,
  ObservationRow,
  ObservationSortType,
} from '../../models/observation.model'
import { ObservationType } from '../../models/observation-type.enum'
import {
  ApplyObservationFilters,
  ApplyObservationQuickFilters,
  GetObservationPage,
  ObservationListState,
  ReloadObservations,
  ResetObservationFilters,
  ResetObservationSearch,
  ResetObservationSearchAndFilters,
} from 'modules/state'
import { ObservationRiskType } from '../../models/observation-risk-type.enum'
import { ObservationStatus } from '../../models/observation-status.enum'
import { takeUntil, mergeMap, filter, map } from 'rxjs/operators'
import { UiConfigurationService } from '../../../core/services/ui-configuration.service'
import { UiConfiguration } from '../../../../shared/models/ui-configuration.model'
import { UiConfigurationSupport } from '../common/ui-configuration-support'

@Pipe({
  name: 'obsExtIdPipe',
  pure: false,
})
export class ObservationExternalIdPipe implements PipeTransform {
  transform(externalId: any): any {
    if (!externalId) {
      return externalId
    }
    return 'SO-' + externalId
  }
}

@Component({
  selector: 'sv-observation-list',
  templateUrl: 'observation-list.component.html',
  styleUrls: ['observation-list.component.scss'],
})
export class ObservationListComponent extends UiConfigurationSupport implements OnInit, OnDestroy {
  @Input() organization: { id: string }
  @Input() region: { id: string }
  @Input() project: { id: string }

  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewport: CdkVirtualScrollViewport

  @Select(ObservationListState.data) dataSource$: Observable<ObservationRow[]>
  @Select(ObservationListState.count) count$: Observable<number>
  @Select(ObservationListState.isLoading) isLoading$: Observable<boolean>
  @Select(ObservationListState.isDataEmpty) isDataEmpty$: Observable<boolean>
  @Select(ObservationListState.isDataFull) isDataFull$: Observable<boolean>
  @Select(ObservationListState.sortType) sortType$: Observable<ObservationSortType>
  @Select(ObservationListState.quickFilterCriteria) quickFilters$: Observable<ObservationQuickFilterCriteria>
  @Select(ObservationListState.filterCriteria) filters$: Observable<ObservationFilterCriteria>
  @Select(ObservationListState.isAnyFilterUsed) isAnyFilterUsed$: Observable<boolean>
  @Select(ObservationListState.isFilterUsed) isFilterUsed$: Observable<boolean>
  @Select(ObservationListState.isSearchUsed) isSearchUsed$: Observable<boolean>

  ITEM_SIZE = 70

  filterCriteria: ObservationFilterCriteria
  private uiConfigurations: {
    [organizationId: string]: UiConfiguration
  } = {}

  private onDestroy$ = new Subject()

  constructor(
    private logger: NGXLogger,
    private translate: TranslateService,
    uiConfigApi: UiConfigurationService,
    private store: Store,
    private stateService: StateService,
    private dashboardDataHelper: DashboardDataHelperService
  ) {
    super(uiConfigApi)
  }

  ngOnInit() {
    this.filters$.pipe(takeUntil(this.onDestroy$)).subscribe(filters => {
      this.filterCriteria = filters
    })

    forkJoin(
      from(this.dashboardDataHelper.getAllOrganizations().models).pipe(
        filter((org: any) => org.orgFeatureSetting.OBSERVATION === true),
        mergeMap((org: any) => this.initOrganizationUiConfiguration(org.id)),
        map(uiConfiguration => (this.uiConfigurations[uiConfiguration.organizationId] = uiConfiguration))
      )
    ).subscribe()

    this.store.dispatch(new ReloadObservations())
  }

  ngOnDestroy() {
    this.onDestroy$.next()
  }

  viewObservation(observation: Observation): void {
    let project = this.dashboardDataHelper.getProjectByProjectId(observation.projectId)
    this.stateService.go('dashboard.observations.orgId.projectGroupId.projectId.viewer', {
      organizationId: project.organizationId,
      projectGroupId: project.projectGroup ? project.projectGroup.id : 'default',
      projectId: project.id,
      observationId: observation.observationId,
    })
  }

  onScroll() {
    const start = this.viewport.getRenderedRange().start
    const end = this.viewport.getRenderedRange().end
    let startPage = this.pageNum(start)
    let endPage = this.pageNum(end) + 1
    for (let i = startPage; i <= endPage; i++) {
      if (i === 0) {
        continue
      }
      this.store.dispatch(new GetObservationPage(ObservationPage.of(i, ObservationPage.SIZE)))
    }
  }

  observationRowTrackFn(num: number, obs: ObservationRow): string {
    return obs instanceof ObservationGroup ? obs.groupId : obs.observationId
  }

  private getScrollToIndex(): number {
    return this.store.selectSnapshot(ObservationListState.scrollToIndex)
  }

  private getScrollToObservationId(): string {
    return this.store.selectSnapshot(ObservationListState.scrollToObservationId)
  }

  private pageNum(index: number): number {
    return Math.floor(index / ObservationPage.SIZE)
  }

  private isPositive(obs: Observation) {
    return obs.type === ObservationType.POSITIVE
  }

  private isNegative(obs: Observation) {
    return obs.type === ObservationType.NEGATIVE
  }

  applyQuickFilters(source: ObservationQuickFilterCriteria, value: any) {
    let filters = Object.assign(ObservationQuickFilterCriteria.empty(), source, value)
    this.store.dispatch(new ApplyObservationQuickFilters(filters))
  }

  resetFilters() {
    this.store.dispatch(new ResetObservationFilters())
  }

  resetSearch() {
    this.store.dispatch(new ResetObservationSearch())
  }

  resetSearchAndFilters() {
    this.store.dispatch(new ResetObservationSearchAndFilters())
  }

  getRowTemplate(obs: ObservationRow): string {
    if (obs instanceof ObservationGroup) {
      return 'obsGroup'
    } else {
      return this.isPositive(obs) ? 'obsPositive' : 'obsNegative'
    }
  }

  getHeaderTitle(count: number) {
    const plural = this.translate.instant('components.observationList.headerTitle')
    const single = this.translate.instant('components.observationList.singleHeaderTitle')
    return count === 1 ? single : plural
  }

  getGroupCountTitle(count: number) {
    const plural = this.translate.instant('components.observationList.observations')
    const single = this.translate.instant('components.observationList.observation')
    return count === 1 ? single : plural
  }

  getGroupTitle(obs: ObservationGroup) {
    const sortType: ObservationSortType = this.store.selectSnapshot(ObservationListState.sortType)
    switch (sortType) {
      case ObservationSortType.RISK:
        return this.translate.instant('components.observationList.riskGroupTitle.' + obs.groupId)
      case ObservationSortType.CREATED_DATE: {
        const today: string = moment().format('MMM D, YYYY')
        const yesterday: string = moment()
          .subtract(1, 'day')
          .format('MMM D, YYYY')
        if (obs.groupId === today) {
          return this.translate.instant('components.observationList.timeGroupTitle.today')
        } else if (obs.groupId === yesterday) {
          return this.translate.instant('components.observationList.timeGroupTitle.yesterday')
        } else {
          return obs.groupId
        }
      }
    }
  }

  getStatusStyle(obs: Observation) {
    return _.camelCase(obs.status)
  }

  getStatus(obs: Observation) {
    return this.translate.instant('components.observationDialog.status.' + obs.status)
  }

  isFilterHidden(field: string): boolean {
    return this.organization
      ? this.isHiddenForUiConfiguration(this.uiConfigurations[this.organization.id], field)
      : false
  }

  isTradePartnerIdHidden(obs: Observation): boolean {
    return this.isHiddenField(obs, 'tradePartnerId')
  }

  isAssignedUserIdHidden(obs: Observation): boolean {
    return this.isHiddenField(obs, 'assignedUserId')
  }

  isDueDateHidden(obs: Observation): boolean {
    return this.isHiddenField(obs, 'dueDate')
  }

  isStatusHidden(obs: Observation): boolean {
    return this.isHiddenField(obs, 'status')
  }

  isHiddenField(obs: Observation, field: string): boolean {
    let project = this.dashboardDataHelper.getProjectByProjectId(obs.projectId)
    return project && this.isHiddenForUiConfiguration(this.uiConfigurations[project.organizationId], field)
  }

  getRisk(obs: Observation) {
    return this.isHiddenField(obs, 'risk') ? '' : obs.risk
  }

  getAssignee(obs: Observation) {
    if (obs.assignedUserFirstName && obs.assignedUserLastName) {
      return `${obs.assignedUserFirstName} ${obs.assignedUserLastName}`
    } else if (obs.assignedUserEmail) {
      return obs.assignedUserEmail
    } else if (this.isNegative(obs)) {
      return this.translate.instant('components.observationList.noAssignee')
    } else if (this.isPositive(obs) && obs.dueDate) {
      return this.translate.instant('components.observationList.noAssignee')
    } else {
      return ''
    }
  }

  getPartnerAssigned(obs: Observation) {
    if (obs.tradePartnerId && obs.tradePartnerName) {
      return obs.tradePartnerName
    } else {
      return this.translate.instant('components.observationList.tpNotAssigned')
    }
  }

  getDueDate(obs: Observation) {
    if (obs.dueDate) {
      const date = moment(obs.dueDate).format('MMM D, YYYY')
      return `Due: ${date}`
    } else if (this.isNegative(obs)) {
      return this.translate.instant('components.observationList.noDueDate')
    } else if (this.isPositive(obs) && obs.assignedUserFirstName) {
      return this.translate.instant('components.observationList.noDueDate')
    } else {
      return ''
    }
  }

  toDateString(time: number | undefined) {
    if (time) {
      return moment(time).format('MMM D, YYYY')
    }

    return ''
  }

  getCreatedDate(obs: Observation) {
    if (obs.dateCreated) {
      const date = moment(obs.dateCreated).format('MMM D, YYYY h:mmA')
      return `Created: ${date}`
    } else {
      return this.translate.instant('components.observationList.noCreatedDate')
    }
  }

  getUserDisplayName(user: { firstName: string; lastName: string; email: string }) {
    if (!user) {
      return 'unknown user'
    }
    if (user.firstName || user.lastName) {
      return user.firstName + ' ' + user.lastName
    }
    return user.email
  }

  showDuePastIcon(obs: Observation) {
    if (
      obs.status === ObservationStatus.CLOSED ||
      obs.status === ObservationStatus.CLOSED_REVIEWED ||
      obs.status === ObservationStatus.READY_TO_REVIEW
    ) {
      return false
    } else if (obs.dueDate) {
      return moment(obs.dueDate).isBefore(moment(), 'day')
    } else {
      return false
    }
  }

  removeTypeChip() {
    let filterCopy = Object.assign(new ObservationFilterCriteria(), this.filterCriteria)
    filterCopy.type = undefined
    this.store.dispatch(new ApplyObservationFilters(filterCopy))
  }

  removeStatusChip(status: ObservationStatus) {
    let filterCopy = Object.assign(new ObservationFilterCriteria(), this.filterCriteria)
    filterCopy.statuses = _.without(filterCopy.statuses, status)
    this.store.dispatch(new ApplyObservationFilters(filterCopy))
  }

  removeRiskTypeChip(riskType: ObservationRiskType) {
    let filterCopy = Object.assign(new ObservationFilterCriteria(), this.filterCriteria)
    filterCopy.riskTypes = _.without(filterCopy.riskTypes, riskType)
    this.store.dispatch(new ApplyObservationFilters(filterCopy))
  }

  removeHazardCategoryChip() {
    let filterCopy = Object.assign(new ObservationFilterCriteria(), this.filterCriteria)
    filterCopy.hazardCategory = undefined
    this.store.dispatch(new ApplyObservationFilters(filterCopy))
  }

  removeIdentificationMethodChip() {
    let filterCopy = Object.assign(new ObservationFilterCriteria(), this.filterCriteria)
    filterCopy.identificationMethod = undefined
    this.store.dispatch(new ApplyObservationFilters(filterCopy))
  }

  removeAssignedUserChip() {
    let filterCopy = Object.assign(new ObservationFilterCriteria(), this.filterCriteria)
    filterCopy.assignedUser = undefined
    filterCopy.assignedUserId = undefined
    this.store.dispatch(new ApplyObservationFilters(filterCopy))
  }

  removeCreatorUserChip() {
    let filterCopy = Object.assign(new ObservationFilterCriteria(), this.filterCriteria)
    filterCopy.creatorUser = undefined
    filterCopy.creatorUserId = undefined
    this.store.dispatch(new ApplyObservationFilters(filterCopy))
  }

  removeDueDateChip() {
    let filterCopy = Object.assign(new ObservationFilterCriteria(), this.filterCriteria)
    filterCopy.dueDatePeriod = undefined
    this.store.dispatch(new ApplyObservationFilters(filterCopy))
  }

  removeTradePartnerChip() {
    let filterCopy = Object.assign(new ObservationFilterCriteria(), this.filterCriteria)
    filterCopy.tradePartnerId = undefined
    filterCopy.tradePartner = undefined
    this.store.dispatch(new ApplyObservationFilters(filterCopy))
  }
}
