/* global angular _ */

angular.module('smartvid').factory('OxBlueCameraInfoModel', function (BaseModel) {
  class OxBlueCameraInfoModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        id: undefined,
        siteName: undefined,
        camName: undefined,
        status: undefined,
        archiveHour: undefined,
        location: undefined,
        offset: undefined,
        maxZoom: undefined,
        isPano: undefined,
        vodEnabled: undefined,
        showVideoPtz: undefined,
        vptzVideoLength: undefined,
        vodVideoLength: undefined,
        thumbPath: undefined,
        imagePath: undefined,
        nocPath: undefined,
        slideshowPath: undefined,
        videoPath: undefined,
        videoPathMp4: undefined,
        videoDownloadPath: undefined,
        isOnline: undefined,
        isWideView: undefined,
        latitude: undefined,
        longitude: undefined,
        selected: false
      }

      super(_.defaults(attrs || {}, defaults))
    }

    getDisplayName () {
      var displayLabel = ''
      if (this.camName && this.camName.trim().length > 0) {
        displayLabel = this.camName
      }
      if (this.location && this.location.trim().length > 0) {
        displayLabel = displayLabel.length > 0 ? this.location + ' - ' + displayLabel : this.location
      }
      if (this.siteName && this.siteName.trim().length > 0) {
        displayLabel = displayLabel.length > 0 ? this.siteName + ' - ' + displayLabel : this.siteName
      }

      return displayLabel
    }
  }

  return OxBlueCameraInfoModel
})
