import { Inject, Injectable } from '@angular/core'
import { ConfigService } from 'angular2/modules/core/services/config.service'
import { HTTP } from 'angular2/shared/smartvid.types'
import { Observable } from 'rxjs'
import { toObservable } from 'shared/utils/api-utils'

export enum ReportType {
  SafetyMonitoring = 'SAFETY_MONITORING',
  SafetyObservations = 'SAFETY_OBSERVATIONS',
  Predictive = 'PREDICTIVE',
}

export class ConfigResponse {
  reportId: string
  reportEmbedUrl: string
  embedToken: TokenResponse
}

export class TokenResponse {
  token: string
  expirationTimeInMillis: number
}

export class EnabledReportsResponse {
  isPredictiveEnabled: boolean
  isSafetyObservationsEnabled: boolean
  isSafetyMonitoringEnabled: boolean
}

export class RolesSettings {
  isOrgLevel: boolean
  isGroupLevel: boolean
  isProjectLevel: boolean
}

export class AnalyticsRolesSettingsResponse {
  safetyMonitoring: RolesSettings
  safetyObservations: RolesSettings
  predictive: RolesSettings
}

export class AnalyticsRolesSettingsRequest {
  safetyMonitoring: RolesSettings
  safetyObservations: RolesSettings
  predictive: RolesSettings
}

@Injectable({
  providedIn: 'root',
})
export class PowerBiService {
  apiRootUrl: string

  constructor(@Inject(HTTP) private $http: ng.IHttpService, private config: ConfigService) {
    this.apiRootUrl = this.config.env.development.apiRootUrl
  }

  /**
   * @returns embed configuration
   */
  getEmbedConfig(orgId: string, reportType: ReportType): Observable<ConfigResponse> {
    let url = this.apiRootUrl + `/api/powerbi/organization/${orgId}/report/${reportType}`
    return toObservable(this.$http.get(url), ConfigResponse)
  }

  getEmbedToken(orgId: string, reportType: ReportType): Observable<TokenResponse> {
    let url = this.apiRootUrl + `/api/powerbi/organization/${orgId}/report/${reportType}/token`
    return toObservable(this.$http.get(url), TokenResponse)
  }

  getEnabledReports(orgId: string): Observable<EnabledReportsResponse> {
    let url = this.apiRootUrl + `/api/powerbi/organization/${orgId}/reports`
    return toObservable(this.$http.get(url), EnabledReportsResponse)
  }

  updateAnalyticsRolesSettings(
    orgId: string,
    request: AnalyticsRolesSettingsRequest
  ): Observable<AnalyticsRolesSettingsResponse> {
    let url = this.apiRootUrl + `/api/powerbi/organization/${orgId}/roles`
    return toObservable(this.$http.put(url, request), AnalyticsRolesSettingsResponse)
  }

  getAnalyticsRolesSettings(orgId: string): Observable<AnalyticsRolesSettingsResponse> {
    let url = this.apiRootUrl + `/api/powerbi/organization/${orgId}/roles`
    return toObservable(this.$http.get(url), AnalyticsRolesSettingsResponse)
  }
}
