/* global angular _ $ cacheJS OpenSeadragon */

angular.module('smartvid').service('openSeaDragonService', function ($window, smartvidApi, $log) {
  // This assumes that there is only one instance of this service running at a time
  let currentAssetId

  // This is a hack to get tile URL, call back-end API and receive pre-signed URL to access this tile
  (function ($) {
    let originalAddJob = $.ImageLoader.prototype.addJob

    $.ImageLoader.prototype.addJob = function (options) {
      let _this = this
      let parser = document.createElement('a')
      parser.href = options.src
      let existingUrl = cacheJS.get(options.src)
      if (existingUrl) {
        options.src = existingUrl
        originalAddJob.apply(_this, [options])
        return
      }

      smartvidApi.getAssetImageUrl(currentAssetId, parser.pathname).then((url) => {
        cacheJS.set(options.src, url, 3600, {service: 'openSeaDragonServiceContext'})
        options.src = url
        originalAddJob.apply(_this, [options])
      },
      (data) => {
        $log.error('Failure to get tile url  ', data)
      })
    }
  }(OpenSeadragon))

  let openSeaDragonService = {

    openSeaDragonViewer (options) {
      cacheJS.removeByContext({service: 'openSeaDragonServiceContext'})
      currentAssetId = options.assetId
      return $window.OpenSeadragon(options)
    },

    rect (x, y, w, h) {
      return new $window.OpenSeadragon.Rect(x, y, w, h)
    },

    point (x, y) {
      return new $window.OpenSeadragon.Point(x, y)
    },

    displayOverlaysForMarkups (dziViewer, markups) {
      _.each(markups, (m) => {
        dziViewer.addOverlay({
          element: m.id,
          x: parseFloat(m.left),
          y: parseFloat(m.top),
          width: parseFloat(m.width),
          height: parseFloat(m.height)
        })
        $('#' + m.id).show()
        m.readyToShow = true
      })

      if (markups.length === 1) {
        this.navigateToPanAndZoomForMarkup(dziViewer, markups[0])
      } else {
        dziViewer.viewport.goHome()
      }
    },

    navigateToPanAndZoom (dziViewer, options) {
      if (options.panX && options.panY) {
        dziViewer.viewport.panTo(this.point(options.panX, options.panY))
      }

      if (options.zoomLevel) {
        dziViewer.viewport.zoomTo(options.zoomLevel)
      }

      if (!options.panX && !options.panY && !options.zoomLevel) {
        dziViewer.viewport.goHome()
      }
    },

    navigateToPanAndZoomForMarkup (dziViewer, markup) {
      let fire = () => {
        if (markup.panX || markup.panY || markup.zoomLevel) {
          this.navigateToPanAndZoom(dziViewer, {
            panX: markup.panX,
            panY: markup.panY,
            zoomLevel: markup.zoomLevel
          })
          return
        }
        dziViewer.viewport.fitBoundsWithConstraints(
            this.rect(
                parseFloat(markup.left), parseFloat(markup.top), parseFloat(markup.width), parseFloat(markup.height)
            )
        )
      }
      if (dziViewer.isOpen()) {
        fire()
      } else {
        dziViewer.addOnceHandler('open', fire)
      }
    },

    removeOverlay (dziViewer, id) {
      dziViewer.removeOverlay(id)
    },

    clearAllOverlays (dziViewer) {
      dziViewer.clearOverlays()
    }
  }

  return openSeaDragonService
})
