/* global angular _ analytics $ */

angular.module('smartvid').directive('videoGrid', function (
  $state, $stateParams, $rootScope, $filter, fileUploadService, currentUser, $timeout, $window, $interpolate, utils,
  MAX_ASSET_NAME_LENGTH, flyout, DefaultGridSupplier, DefaultGroupedGridSupplier, $compile, smartvidApi,
  assetGridHelper, AssetGridGroupParser, searchResultService, tagUtils, batchJobService,
  contentSharingContext, AssetViewType, vinnieLimitsNotificationService, $interval, FeatureSettingsModel, scrollService,
  FEATURE_TYPE_VINNIE_SAGEMAKER, moment) {
  return {
    restrict: 'E',
    // TODO: PL: remove commented out code
    // replace: true,
    templateUrl: 'video-grid.html',
    link (scope, elem) {
      const UPLOADING_VALUE = Number.MAX_VALUE
      const UNKNOWN_VALUE = UPLOADING_VALUE / 2
      const TILEVIEW_ROW_WIDTH = 248
      const TILEVIEW_ROW_HEIGHT = 231
      const TILEVIEW_MOBILE_ROW_WIDTH = 147
      const TILEVIEW_MOBILR_ROW_HEIGHT = 103
      let currentProject = scope.currentProject
      let projectId = (currentProject) ? currentProject.id : undefined
      let assetSearchContext = searchResultService.getCurrentSearchContext()
      let cleanupListeners = []

      scope.items = []
      scope.searchStatusBarDetails = {}
      scope.modal = $rootScope.modal
      scope.MAX_ASSET_NAME_LENGTH = MAX_ASSET_NAME_LENGTH
      scope.hovering = false
      scope.assetNameChanged = false
      scope.countsUpdated = false
      scope.showingDeletedFiles = scope.assets && scope.assets.assetViewType === AssetViewType.DELETED_ASSETS
      scope.isActiveSearch = scope.assets && scope.assets.searchContext
      scope.contentSharingContext = contentSharingContext
      scope.isVinnieEnabled = currentProject && currentUser.isFeatureEnabledForOrganization(currentProject.organizationId, FEATURE_TYPE_VINNIE_SAGEMAKER)

      scope.isFetching = isFetching
      scope.handleCheckbox = handleCheckbox
      scope.showAssetOptions = showAssetOptions
      scope.onAssetNameChanged = onAssetNameChanged
      scope.saveAssetChanges = saveAssetChanges
      scope.goToMediaReviewer = goToMediaReviewer
      scope.isFlyoutOpenFor = isFlyoutOpenFor
      scope.getFormattedCreateTime = getFormattedCreateTime
      scope.openFileUploadModal = openFileUploadModal
      scope.videoGridInit = videoGridInit
      scope.isInactiveProject = scope.currentProject && scope.currentProject.isInactive

      // TODO: PL: remove when we don't need to limit VINNIE anymore ->
      scope.vinnieLimitsInfo = {}
      scope.showGreenLimitsIcon = false
      scope.showRedLimitsIcon = false
      scope.vinnieLimitsFooterText = ''
      scope.showVinnieLimitsFooter = false
      if (vinnieLimitsNotificationService.canProcessVinnieLimits(currentProject)) {
        var vinnieFooterUpdateInterval
        $timeout(() => {
          vinnieLimitsNotificationService.getVinnieLimitsInfo(currentProject).then((data) => {
            let vinnieLimitsInfo = new FeatureSettingsModel(data)
            vinnieLimitsNotificationService.showNotificationModalIfNeeded(vinnieLimitsInfo, currentProject)
            scope.showVinnieLimitsFooter = vinnieLimitsNotificationService.needToShowVinnieLimitsFooter(vinnieLimitsInfo, currentProject)
            if (scope.showVinnieLimitsFooter) {
              vinnieLimitsNotificationService.updateFooterContent(scope, vinnieLimitsInfo)
              vinnieFooterUpdateInterval = vinnieLimitsNotificationService.startUpdatingVinnieFooter(scope)
              cleanupListeners.push(() => {
                $interval.cancel(vinnieFooterUpdateInterval)
              })
            }
          })
        }, 100)
        scope.showVinnieTagsHelp = () => {
          vinnieLimitsNotificationService.showVinnieTagsHelp()
        }
      }

      if (currentProject && currentProject.getProjectOrg()) {
        showInactiveOrganizationModalIfNeeded()
      }

      // TODO: PL: remove when we don't need to limit VINNIE anymore <-

      if ($stateParams.saveScrollOptions) {
        scope.saveScrollOptions = $stateParams.saveScrollOptions
      } else {
        scope.saveScrollOptions = {
          id: getScrollId(),
          selector: '.tile-view',
          performScroll: conditionToScroll(),
          performFade: true
        }
      }

      scope.tileViewOptions = {
        templateUrl: 'video-thumbnail.html',
        groupHeaderTemplateUrl: 'AssetGrid/asset-grid-group-header.html',
        tileSize: (scope.isMobile ? { width: TILEVIEW_MOBILE_ROW_WIDTH, height: TILEVIEW_MOBILR_ROW_HEIGHT }
          : { width: TILEVIEW_ROW_WIDTH, height: TILEVIEW_ROW_HEIGHT }),
        onScrollEnd: nextPage,
        scrollEndOffset: 5,
        debounce: 0 // 10?
      }

      $rootScope.isCapture = ($rootScope.isCapture !== undefined) ? $rootScope.isCapture : scope.isMobile

      cleanupListeners.push(scope.$on('assetGrid:reRunSmartTaggingSelected', () => {
        _.each(scope.assets.getSelected(), (asset) => {
          asset.tags = [] // reset tags collection
        })
        let multiAssetSelection = scope.assets.getMultiAssetSelection()
        let goToStateName = 'dashboard.projects.projectId.files'
        scope.saveScrollOptions.performScroll = true
        let goToStateParams = {
          projectId: projectId,
          isDemoProject: $stateParams.isDemoProject,
          assetSearchContext: $stateParams.assetSearchContext,
          saveScrollOptions: scope.saveScrollOptions
        }
        $rootScope.$broadcast('sv-before-batch-asset-smarttag-start')
        batchJobService.manageBatchJob(
          null,
          projectId,
          smartvidApi.smartTagAssets(multiAssetSelection),
          ['sv-all-batch-asset-smarttag-complete', goToStateName, goToStateParams],
          ['sv-all-batch-asset-smarttag-failed']
        )
      }))

      cleanupListeners.push(scope.$on('sv-trigger-batch-asset-tag', (e, tag) => {
        let goToStateName = 'dashboard.projects.projectId.files'
        scope.saveScrollOptions.performScroll = true
        let goToStateParams = {
          projectId: projectId,
          isDemoProject: $stateParams.isDemoProject,
          assetSearchContext: $stateParams.assetSearchContext,
          saveScrollOptions: scope.saveScrollOptions
        }
        $rootScope.$broadcast('sv-before-batch-asset-tag-start')
        batchJobService.manageBatchJob(
          null,
          projectId,
          smartvidApi.addTagInstanceToAssets(
            scope.assets.getMultiAssetSelection(), tagUtils.createTagPayload(undefined, tag)),
          ['sv-all-batch-asset-tag-complete', goToStateName, goToStateParams],
          ['sv-all-batch-asset-tag-failed']
        )
      }))

      cleanupListeners.push(scope.$on('sv-left-nav-animation-finished', () => {
        $rootScope.$broadcast('td.tileview.resize')
      }))
      cleanupListeners.push(scope.$on('sv-right-nav-animation-finished', () => {
        $rootScope.$broadcast('td.tileview.resize')
      }))

      cleanupListeners.push(scope.$on('sv-asset-finished-processing', () => {
        if (scope.isGrouped) {
          updateTileView()
          utils.digest(scope)
        }
      }))

      initAssetCollection()

      updateView()

      scope.$on('$destroy', () => {
        cleanup()
      })

      // //////////////////////////////////////////////////////////////////////////////////////////////
      function initAssetCollection () {
        cleanupListeners.push(scope.assets.addUpdateListener(() => {
          updateView()
        }))
        cleanupListeners.push(scope.assets.addResetListener(() => {
          scope.gridSupplier = null
          scope.$broadcast('perform-scroll-top')
          scrollService.resetForKey(scope.saveScrollOptions.id)
        }))
      }

      function cleanup () {
        _.each(cleanupListeners, (listener) => {
          listener()
        })
      }

      function getScrollId () {
        return 'video-grid-' + projectId + '_' + $state.$current.name
      }

      function conditionToScroll () {
        let views = ['dashboard.projects.viewer', 'dashboard.projects.projectId.files.viewer']
        return views.includes($rootScope.previousStateName)
      }

      function updateView () {
        scope.assets.nextPagePromise.then(() => {
          scope.projects.projectPromise.then(() => {
            updateTileView()
            $timeout(() => {
              scope.$broadcast('td.tileview.update')
            })
            scope.assets.assetGroupMetadataPromise.then(() => {
              let assetGroupMetadata = scope.assets.assetGroupMetadata
              if (assetGroupMetadata) {
                extendGroupMetadata(assetGroupMetadata)
              }
            })
          })
        })
      }

      function isFetching () {
        return scope.assets.isFetching && !scope.assets.initialized
      }

      function handleCheckbox ($event, asset) {
        $event.target.blur()
        if (!$event.shiftKey && !$event.originalEvent.shiftKey) {
          $event.stopPropagation()
          $rootScope.$broadcast('sv-placards-selected', {})
        }

        $timeout(() => {
          if (asset.selected && !$rootScope.lastSelectedPlacard) {
            $rootScope.lastSelectedPlacard = asset
          }
        })
      }

      function showAssetOptions ($event, asset) {
        $event.stopPropagation()
        flyout.open('assetOptions', {
          parentElement: $event.currentTarget,
          direction: 'left',
          assets: scope.assets,
          asset: asset,
          flyoutId: asset.id,
          projectObj: currentProject
        })
      }

      function onAssetNameChanged () {
        scope.assetNameChanged = true
      }

      function saveAssetChanges (asset) {
        if (scope.assetNameChanged) {
          scope.assetNameChanged = false
          smartvidApi.updateAsset(asset.id, asset.name)
        }
      }

      function goToMediaReviewer (evt, asset) {
        if (asset.isDeleted()) {
          return
        }

        let useCollectionOperations = true
        if (utils.isMetaKeySelect(evt, scope, asset, scope.assets,
          (asset) => {
            asset.getTags()
          }, useCollectionOperations)) {
          return
        }

        let tagInstance
        if (!asset.isViewable()) {
          evt.stopPropagation()
          evt.preventDefault()
          // TODO - show alert message
          return
        }
        let projectOrg = (currentProject) ? currentProject.projectOrg : undefined
        analytics.track('View Asset', {
          category: 'Asset Action',
          assetType: asset.type,
          assetName: asset.name,
          projectName: (currentProject) ? currentProject.name : undefined,
          orgName: (projectOrg) ? projectOrg.name : undefined
        })
        let viewerState = $state.current.name + '.viewer'
        // If we have a searchedTag value on the collection then we are in search mode and should deep link to the tag
        let searchedTags = (assetSearchContext) ? assetSearchContext.searchTags : undefined
        if (!_.isEmpty(searchedTags)) {
          if (!asset.childToParentTagMap) {
            if (assetSearchContext.isOnlySearchByTagDefs()) {
              return
            }
            $state.go(viewerState, createViewerParams(asset))
            return
          }
          asset.getTags().then(() => {
            for (let searchedTag of searchedTags) {
              if (asset.childToParentTagMap) {
                let tagMap = asset.childToParentTagMap
                tagInstance = _.find(asset.tags.models, ti => {
                  let originalTag = tagMap[ti.tagDefinitionId]
                  return searchedTag.id === originalTag
                })
                if (tagInstance) {
                  break
                }
              }
            }
            if (tagInstance) {
              let viewerParams = createViewerParams(asset)
              viewerParams.tagId = tagInstance.id
              $state.go(viewerState, viewerParams)
            }
          })
          return
        }
        $state.go(viewerState, createViewerParams(asset))
      }

      function createViewerParams (asset) {
        let result = {
          projectId: asset.projectId,
          assetId: asset.id,
          asset: asset,
          obsLinkedAssets: true
        }
        return result
      }

      function isFlyoutOpenFor (flyoutId) {
        return flyout.isOpenFor(flyoutId)
      }

      function getFormattedCreateTime (asset) {
        if (!asset.captureTime) {
          return $filter('i18next')('directives.thumbnail.timeUnknown')
        }
        return $filter('formattedTime')(moment(asset.captureTime))
      }

      function assetTime (asset, fieldName, altValue) {
        return (!!asset[fieldName] === true) ? asset[fieldName] : altValue
      }

      function groupByHeader (asset, groupByColumn) {
        groupByColumn = (groupByColumn.includes('PrecisionWeek')) ? groupByColumn.replace('PrecisionWeek', '') : groupByColumn
        if (asset.isUploading || asset.recentUpload || asset.wasProcessing) {
          return $filter('i18next')('gridGroup.uploadingAssets')
        }
        if (!asset.captureTime) {
          const RECENT_UPLOAD_INTERVAL_MS = 10 * 60 * 1000 // 10 min
          if ((new Date()) - asset.createdTime < RECENT_UPLOAD_INTERVAL_MS) {
            return $filter('i18next')('gridGroup.uploadingAssets')
          }
        }

        if (groupByColumn === 'lastName') {
          if (!asset.creatorUser) {
            return $filter('i18next')('gridGroup.Processing')
          }
          return asset.creatorUser.firstName + ' ' + asset.creatorUser.lastName
        } else if (groupByColumn === 'captureTime' && assetTime(asset, groupByColumn, UNKNOWN_VALUE) === UNKNOWN_VALUE) {
          return $filter('i18next')('gridGroup.Unknown')
        } else if (groupByColumn === 'captureTime' || groupByColumn === 'createdTime') {
          if (assetTime(asset, groupByColumn, UNKNOWN_VALUE) === UNKNOWN_VALUE) {
            return $filter('i18next')('gridGroup.Unknown')
          } else {
            return $filter('i18next')('gridGroup.weekOf') + ' ' +
              moment('/Date(' +
                assetTime(asset, groupByColumn, UNKNOWN_VALUE) + ')/').startOf('week').format('MMMM Do YYYY')
          }
        } else if (groupByColumn === 'type' || groupByColumn === 'typeDetails') {
          if ((asset.type === 'IMAGE')) {
            if (asset.typeDetails === 'IMAGE_AUDIO') {
              return $filter('i18next')('common.images_audio')
            }

            if (asset.typeDetails === 'SPHERICAL_IMAGE') {
              return $filter('i18next')('common.images_spherical')
            }

            return $filter('i18next')('common.images')
          }

          return $filter('i18next')('common.videos')
        } else {
          throw new Error('Unexpected group by column')
        }
      }

      function openFileUploadModal () {
        if (scope.showingDeletedFiles) {
          return
        }
        scope.modal.open('fileUpload', { tags: scope.projectTags, currentProject: scope.currentProject })
      }

      function nextPage () {
        if (scope.assets.canFetch && !scope.assets.isFetching) {
          scope.$evalAsync(function () {
            scope.assets.nextPage()
          })
        }
      }

      function extendGroupMetadata (metadata) {
        scope.gridSupplier.extendGroupMetadata(metadata, (destGroup) => {
          return !destGroup.recentUploadsGroup
        })
      }

      function updateTileView () {
        scope.gridSupplier = createGridSupplier()
      }

      function createGridSupplier () {
        let supplier = null
        let parser = getGridParser()
        if (parser) {
          if (scope.gridSupplier instanceof DefaultGroupedGridSupplier) {
            scope.gridSupplier.parser = parser
            scope.gridSupplier.refreshFromParsePhase(includeAddItemPlaceholder())
            supplier = scope.gridSupplier
          } else {
            supplier = new DefaultGroupedGridSupplier(scope.assets, parser, includeAddItemPlaceholder())
          }
        } else {
          if (scope.gridSupplier instanceof DefaultGridSupplier ||
            scope.gridSupplier instanceof DefaultGroupedGridSupplier) {
            supplier = new DefaultGridSupplier(
              scope.assets, includeAddItemPlaceholder(), scope.gridSupplier.itemsPerRow)
          } else {
            supplier = new DefaultGridSupplier(scope.assets, includeAddItemPlaceholder())
          }
        }
        return supplier
      }

      function getGridParser () {
        let parser = null
        if ($state.is('dashboard.projects')) {
          parser = new AssetGridGroupParser((asset) => {
            let project = _.findWhere(scope.projects.models, { id: asset.projectId })
            return project.name
          })
        } else if (assetSearchContext && assetSearchContext.assetGroup) {
          let groupHeader = $filter('i18next')('search.group.' + assetSearchContext.assetGroup)
          parser = new AssetGridGroupParser(() => {
            return groupHeader
          })
        } else if (scope.isGrouped && !scope.isMobile && scope.assets) {
          let groupByColumn = scope.assets.options.state.groupByColumn
          parser = new AssetGridGroupParser((item) => {
            return groupByHeader(item, groupByColumn)
          })
        }
        return parser
      }

      function includeAddItemPlaceholder () {
        if (!scope.currentProject) {
          return false
        }

        let includeInAssetSection = scope.currentProject.canUploadAsset && !contentSharingContext.isSet() && !scope.isMobile && !scope.isActiveSearch && !scope.showingDeletedFiles
        let includeInDeletedSection = scope.showingDeletedFiles && scope.assets.length === 0

        return includeInAssetSection || includeInDeletedSection
      }

      function subscribeVideoGridScroll () {
        if (!scope.isActiveSearch) {
          return
        }

        const $videoGrid = $('#video-grid')
        const $searchStatusBar = $('header.search-results')
        const videoGridElem = $videoGrid[0]
        const tileViewElem = elem.find('.tile-view')

        videoGridElem.style.setProperty('--height-offset', '0px')

        tileViewElem.on('scroll', () => {
          onScroll()
        })
        scope.$on('perform-scroll-top', () => {
          onScroll()
        })
        scope.$on('$destroy', () => {
          tileViewElem.off()
          $searchStatusBar.off()
        })

        let lastScrollTop = 0

        function onScroll () {
          if (!scope.isActiveSearch || scope.isWidget) {
            return
          }
          const MIN_SCROLL_DELTA = 5
          const SCROLL_TOP_DELTA = 50
          const currentScrollTop = tileViewElem.scrollTop()
          const scrollDelta = Math.abs(lastScrollTop - currentScrollTop)

          if (scrollDelta <= MIN_SCROLL_DELTA) {
            return
          }

          const videoGridElem = $('#video-grid')[0]
          const statusBarElem = $('header.search-results')[0]

          const isScrollingDown = currentScrollTop > lastScrollTop
          const totalRows = scope.gridSupplier && scope.gridSupplier.getTotalRowCount()
          const totalGridHeight = totalRows * TILEVIEW_ROW_HEIGHT
          const visibleGridHeight = videoGridElem.offsetHeight
          const hasEnoughRowsToScrollDown = (totalGridHeight - visibleGridHeight) > TILEVIEW_ROW_HEIGHT

          if (isScrollingDown && !hasEnoughRowsToScrollDown) {
            return
          }

          if (isScrollingDown && currentScrollTop > SCROLL_TOP_DELTA) {
            hideStatusBar(statusBarElem, videoGridElem)
            //nextPage()
          } else {
            // Scroll Up
            if (!isScrollingDown && currentScrollTop < SCROLL_TOP_DELTA) {
              showStatusBar(statusBarElem, videoGridElem)
            }
          }

          lastScrollTop = currentScrollTop
        }

        function hideStatusBar (statusBarElem, videoGridElem) {
          let barHeight = statusBarElem.clientHeight || 205
          statusBarElem.style.zIndex = '-1'
          statusBarElem.style.position = 'absolute'
          statusBarElem.style.top = -barHeight + 'px'

          videoGridElem.style.setProperty('--height-offset', '150px')
          videoGridElem.style.transform = 'translateY(-150px)'
          videoGridElem.style.top = '150px'
        }

        function showStatusBar (statusBarElem, videoGridElem) {
          statusBarElem.style.top = '0px'
          statusBarElem.style.zIndex = '0'
          statusBarElem.style.position = 'relative'

          videoGridElem.style.setProperty('--height-offset', '0px')
          videoGridElem.style.transform = 'translateY(0px)'
          videoGridElem.style.top = '0px'
        }
      }

      // //////////////////////////////////////////// init
      function videoGridInit () {
        subscribeVideoGridScroll()
        appendSpinner()
      }

      // /////////////////////////////////////////// paging spinner
      function appendSpinner () {
        const spinner = angular.element(`<div ng-if="assets.canFetch && assets.isFetching" class="grid-paging-spinner" style="position: absolute;bottom: -100px;margin-bottom: 30px;height: 39px;width: 100%;">
            <div us-spinner="{ color: '#fff', length: 7, radius: 9, width: 3 }"></div>
        </div>`)

        const linkFunction = $compile(spinner)
        linkFunction(scope, function (compiledSpinner) {
          $timeout(() => {
            elem.find('.item-container').append(compiledSpinner)
          })
        })
      }

      function showInactiveOrganizationModalIfNeeded () {
        let LOCAL_STORAGE_KEY = 'inactiveOrganizationPopupShownForOrg' + currentProject.projectOrg.id
        let wasInactiveOrganizationPopupShown = $window.localStorage.getItem(LOCAL_STORAGE_KEY) === 'true'
        if (currentProject.projectOrg.isOrganizationInactive && !wasInactiveOrganizationPopupShown) {
          $window.localStorage.setItem(LOCAL_STORAGE_KEY, true)

          scope.modal.open('inactiveOrganizationNotification', {
            message: $interpolate($filter('i18next')('admin.inactiveOrganization.popupText'))({
              orgName: currentProject.projectOrg.name
            })
          })
        }
      }

    }
  }
})
