import * as _ from 'lodash'
import { Component } from '@angular/core'
import { ProjectTotalsTableComponent } from 'modules/insights/dashboard/components/totals-table/assets/project-totals-table.component'
import {
  CardInputChangeEvent,
  CardToggleMenuGroup,
  CardToggleMenuItem,
} from 'modules/insights/dashboard/cards/models/insights-card.model'
import {
  ACTIVITY_BY_USER_TABLE_MENU,
  ProjectTotalsTableData,
} from 'modules/insights/dashboard/models/insights-dashboard-project-assets.model'
import { InsightsDashboardProjectAssetsService } from 'modules/insights/dashboard/services/insights-dashboard-project-assets.service'
import {
  DashboardCardId,
  DashboardDateRangeTimeUnit,
  DashboardTabId,
  DashboardType,
  DateRangeChartData,
  InsightsDashboardParameters,
  TableScopeFilter,
} from 'modules/insights/dashboard/models/insights-dashboard.model'
import { TranslateService } from '@ngx-translate/core'
import { InsightsDataHelper } from 'modules/insights/services/insights-data-helper.service'
import { InsightsDashboardConfigurationService } from 'modules/insights/dashboard/services/insights-dashboard-configuration.service'
import { InsightsDashboardComponentBaseDirective } from 'modules/insights/dashboard/components/insights-dashboard-component-base'
import { InsightsCardComponent } from 'modules/insights/dashboard/cards/components/insights-card/insights-card.component'

@Component({
  selector: 'sv-assets-insights-dashboard-activity',
  templateUrl: 'assets-insights-dashboard-activity.component.html',
  styleUrls: ['assets-insights-dashboard-activity.component.scss'],
})
export class AssetsInsightsDashboardActivityComponent extends InsightsDashboardComponentBaseDirective {
  projectTotalsTableData: ProjectTotalsTableData[]
  projectUploadsOverTimeBySourceData: DateRangeChartData
  projectUploadersOverTimeData: DateRangeChartData
  projectUploadsByFileTypeData: DateRangeChartData
  projectTagsByTypeData: DateRangeChartData
  activityByUserTableMenu: CardToggleMenuGroup[]
  uploadsBySourceChartMenu: CardToggleMenuGroup[]
  uploadersChartMenu: CardToggleMenuGroup[]
  projectConstructionActivityData: DateRangeChartData
  projectPhotoDescriptionData: DateRangeChartData

  constructor(
    protected insightsDataHelper: InsightsDataHelper,
    protected translate: TranslateService,
    protected insightsDashboardConfigurationService: InsightsDashboardConfigurationService,
    private insightsDashboardProjectService: InsightsDashboardProjectAssetsService
  ) {
    super(insightsDataHelper, insightsDashboardConfigurationService, translate, DashboardType.PROJECT_ASSETS_DASHBOARD)
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // the ()=> function is needed to capture the correct 'this' reference
  protected refresh() {
    this.initializeMenuItems()

    this.guardFor(
      () => this.loadProjectTotalsTableData(TableScopeFilter.ACTIVITY_BY_USER_MENU_SV_USERS),
      DashboardTabId.ACTIVITY,
      DashboardCardId.ACTIVITY_BY_USER
    )
    this.guardFor(
      () => this.loadProjectUploadsOverTimeBySourceData(DashboardDateRangeTimeUnit.LAST_1),
      DashboardTabId.ACTIVITY,
      DashboardCardId.UPLOADS_OVERTIME_BY_SOURCE
    )
    this.guardFor(
      () => this.loadProjectUploadersOverTimeData(DashboardDateRangeTimeUnit.LAST_1),
      DashboardTabId.ACTIVITY,
      DashboardCardId.UPLOADERS_OVERTIME
    )
    this.guardFor(
      () => this.loadProjectConstructionActivityData(),
      DashboardTabId.ACTIVITY,
      DashboardCardId.CONSTRUCTION_ACTIVITY
    )
    this.guardFor(
      () => this.loadProjectPhotoDescriptionData(),
      DashboardTabId.ACTIVITY,
      DashboardCardId.PHOTO_DESCRIPTION
    )
    this.guardFor(
      () => this.loadProjectUploadsByFileTypeData(),
      DashboardTabId.ACTIVITY,
      DashboardCardId.UPLOADS_BY_FILE_TYPE
    )
    this.guardFor(() => this.loadProjectTagsByTypeData(), DashboardTabId.ACTIVITY, DashboardCardId.TAGS_BY_TYPE)
  }

  private initializeMenuItems() {
    this.activityByUserTableMenu = _.cloneDeep(ACTIVITY_BY_USER_TABLE_MENU)
    this.uploadsBySourceChartMenu = this.createChartDateSwitchMenuItems('UPLOADS_MENU', 'uploadsOverTimeBySource')
    this.uploadersChartMenu = this.createChartDateSwitchMenuItems('UPLOADERS_MENU', 'uploadersCountOverTime')
  }

  private projectTotalsTableFilterChanged(
    $event: CardInputChangeEvent,
    projectTotalsTable: ProjectTotalsTableComponent
  ) {
    if (!$event.value || !$event.value.trim()) {
      projectTotalsTable.clearFilter()
      return
    }

    projectTotalsTable.applyFilter({
      fieldName: 'name',
      filter: $event.value,
    })
  }

  private loadProjectTotalsTableData(filter: string) {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      filter: filter,
    }
    this.projectTotalsTableData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadProjectTotalsTableData(dashboardParams, tableData => {
        this.projectTotalsTableData = tableData
      })
    )
  }

  private loadProjectUploadsOverTimeBySourceData(chartResolution: DashboardDateRangeTimeUnit) {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeTimeUnit: chartResolution,
    }
    this.projectUploadsOverTimeBySourceData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadUploadsOverTimeBySourceData(dashboardParams, chartData => {
        this.projectUploadsOverTimeBySourceData = chartData
      })
    )
  }

  private loadProjectUploadersOverTimeData(chartResolution: DashboardDateRangeTimeUnit) {
    let dashboardParams: InsightsDashboardParameters = {
      ...this.currentInsightsDashboardParameters,
      dateRangeTimeUnit: chartResolution,
    }
    this.projectUploadersOverTimeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadUploadersOverTimeData(dashboardParams, chartData => {
        this.projectUploadersOverTimeData = chartData
      })
    )
  }

  private loadProjectConstructionActivityData() {
    this.projectConstructionActivityData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadConstructionActivityData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectConstructionActivityData = chartData
        }
      )
    )
  }

  private loadProjectPhotoDescriptionData() {
    this.projectPhotoDescriptionData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadPhotoDescriptionData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectPhotoDescriptionData = chartData
        }
      )
    )
  }

  private loadProjectUploadsByFileTypeData() {
    this.projectUploadsByFileTypeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadUploadsByFileTypeData(
        this.currentInsightsDashboardParameters,
        chartData => {
          this.projectUploadsByFileTypeData = chartData
        }
      )
    )
  }

  private loadProjectTagsByTypeData() {
    this.projectTagsByTypeData = undefined
    this.apiCallsSubscriptions.push(
      this.insightsDashboardProjectService.loadTagsByTypeData(this.currentInsightsDashboardParameters, chartData => {
        this.projectTagsByTypeData = chartData
      })
    )
  }

  private activityByUserMenuSelected($event: CardToggleMenuItem, activityByUserCard: InsightsCardComponent) {
    activityByUserCard.clearCardInputControl()
    this.loadProjectTotalsTableData($event.eventId)
  }

  private uploadsBySourceChartMenuSelected($event: CardToggleMenuItem) {
    this.loadProjectUploadsOverTimeBySourceData($event.eventId)
  }

  private uploadersChartMenuSelected($event: CardToggleMenuItem) {
    this.loadProjectUploadersOverTimeData($event.eventId)
  }
}
