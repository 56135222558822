/* global angular */

angular.module('smartvid').directive('adminOrganizationThumbnail', function ($timeout, smartvidApi, flyout, $state, $rootScope, $stateParams, utils, currentUser) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'AdminOrganizationsGrid/admin-organization-thumbnail.html',
    scope: {
      organization: '=',
      adminOrganizations: '='
    },
    link (scope) {
      scope.canManage = scope.organization.canManage
      scope.canViewActive = ($stateParams.canViewOrgSelected === scope.organization.id)

      scope.handleCheckbox = ($event, organization) => {
        $event.target.blur()
        if (!$event.shiftKey) {
          $event.stopPropagation()
        }

        $timeout(() => {
          if (organization.selected && !$rootScope.lastSelectedPlacard) {
            $rootScope.lastSelectedPlacard = organization
          }
        })
      }

      scope.showOrganizationOptions = ($event) => {
        $event.stopPropagation()
        if (scope.canManage) {
          flyout.open('organizationOptions', {
            parentElement: $event.currentTarget,
            direction: 'left',
            adminOrganizations: scope.adminOrganizations,
            organization: scope.organization,
            flyoutId: scope.organization.id
          })
        }
      }
      scope.isFlyoutOpenFor = (flyoutId) => {
        return flyout.isOpenFor(flyoutId)
      }

      scope.$on('sv-view-only-org-selected', (event, payload) => {
        scope.canViewActive = (payload.organizationId === scope.organization.id)
      })

      scope.goToOrgState = (evt) => {
        if (utils.isMetaKeySelect(evt, scope, scope.organization, scope.adminOrganizations)) {
          return
        }

        if (scope.canManage) {
          $state.go('dashboard.adminOrganizations.organizationId', { organizationId: scope.organization.id })
          $rootScope.$broadcast('sv-view-only-org-selected', { organizationId: scope.organization.id })
        } else if (!scope.canViewActive) {
          $rootScope.$broadcast('sv-view-only-org-selected', { organizationId: scope.organization.id })
        }
      }
    }
  }
})
