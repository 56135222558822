<div class="project-group-assets-insights-dashboard-safety mdc-layout-grid__inner">
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_SAFETY_BY_PROJECT' }">
    <sv-insights-card
      contentHeight="400px"
      cardTitle="{{ 'dashboard.cards.projectGroupSafetyByProject.titleProject' | translate }}"
      subTitle="{{ safetyTableData?.length }} {{
        'dashboard.cards.projectGroupSafetyByProject.subTitleProject' | translate
      }}"
      class="project-group-safety-table-card"
      hasTextInput="true"
      textInputPlaceholder="{{ 'dashboard.cards.projectGroupSafetyByProject.searchInputPlaceholderProject' | translate }}"
      (cardInputChange)="safetyTableFilterChanged($event, projectGroupSafetyTable)"
    >
      <sv-project-group-assets-safety-table
        #projectGroupSafetyTable
        [tableData]="safetyTableData"
      ></sv-project-group-assets-safety-table>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_PEOPLE_IN_GROUPS' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupPeopleInGroupsOverTime.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
      [toggleMenuGroups]="peopleInGroupsOverTimeChartMenu"
      (toggleMenuItemSelected)="peopleInGroupsOverTimeChartMenuSelected($event)"
    >
      <sv-insights-line-chart
        [chartData]="projectGroupPeopleInGroupsOverTimeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-line-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_PPE_COMPLIANCE_TREND_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupPpeComplianceTrendOverTime.title' | translate }}"
      subTitle="{{ 'dashboard.cards.projectGroupPpeComplianceTrendOverTime.subTitle' | translate }}"
    >
      <sv-insights-line-chart
        [chartData]="projectGroupPpeComplianceOverTimeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-line-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_PPE_COMPLIANCE_PERCENT_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupOverallPpeCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
      <div *ngIf="projectGroupSingleValueData" class="card-gauge-container">
        <sv-insights-safety-gauge-chart
          [currentValue]="projectGroupSingleValueData.ppeCompliancePercentAvg"
        ></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_GLASSES_COMPLIANCE_PERCENT_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupGlassesCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
      <div *ngIf="projectGroupSingleValueData" class="card-gauge-container">
        <sv-insights-safety-gauge-chart
          [currentValue]="projectGroupSingleValueData.glassesComplianceAvg"
        ></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_GLOVES_COMPLIANCE_PERCENT_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupGlovesCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
      <div *ngIf="projectGroupSingleValueData" class="card-gauge-container">
        <sv-insights-safety-gauge-chart
          [currentValue]="projectGroupSingleValueData.glovesComplianceAvg"
        ></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_HARDHAT_COMPLIANCE_PERCENT_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupHardHatCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
      <div *ngIf="projectGroupSingleValueData" class="card-gauge-container">
        <sv-insights-safety-gauge-chart
          [currentValue]="projectGroupSingleValueData.hardHatComplianceAvg"
        ></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_HIVIZ_COMPLIANCE_PERCENT_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupHiVizCompliance.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
      <div *ngIf="projectGroupSingleValueData" class="card-gauge-container">
        <sv-insights-safety-gauge-chart
          [currentValue]="projectGroupSingleValueData.highVizComplianceAvg"
        ></sv-insights-safety-gauge-chart>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_WORK_AT_HEIGHTS_TREND_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupWorkAtHeightTrendOverTime.title' | translate }}"
      subTitle="{{ 'dashboard.cards.projectGroupWorkAtHeightTrendOverTime.subTitle' | translate }}"
    >
      <sv-insights-column-chart
        *ngIf="!chartHasAllNullValues(projectGroupWorkAtHeightsOverTimeData?.chartData)"
        [chartData]="convertNullValuesToZero(projectGroupWorkAtHeightsOverTimeData?.chartData)"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
      <div class="text-card-content dashboard-single-value-card" *ngIf="chartHasAllNullValues(projectGroupWorkAtHeightsOverTimeData?.chartData)">
        <div class="text-card-main-text">
          {{
          ('dashboard.valueNotAvailable' | translate)
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_LADDERS_TO_LIFTS_RATIO_AVG' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.projectGroupLaddersToLiftsRatio.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="projectGroupSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(projectGroupSingleValueData.laddersToLiftsRatioAvg)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.laddersToLiftsRatioAvg | number: '.1-1') + ' to 1'
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ laddersToLiftsRatioPercentChangeClassName }}">
          {{ getSign(projectGroupSingleValueData.laddersToLiftsRatioAvgPercentChange)
          }}{{
            isNA(projectGroupSingleValueData.laddersToLiftsRatioAvg)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.laddersToLiftsRatioAvgPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_WORK_AT_HEIGHT_PER_X_ASSETS_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupWorkAtHeightDistribution.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectGroupWorkAtHeightsPerXAssetsData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_LADDERS_PER_X_ASSETS_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupLaddersDistribution.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectGroupLaddersPerXAssetsData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_LIFTS_PER_X_ASSETS_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupLiftsDistribution.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectGroupLiftsPerXAssetsData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div
    *svDashboardGridCell="{
      tabId: 'SAFETY',
      cardId: 'PROJECT_GROUP_ASSETS_HOUSEKEEPING_AND_STANDING_WATER_AVG'
    }"
  >
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupHousekeepingAndStandingWater.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <sv-insights-pie-chart
        [chartData]="projectGroupHousekeepingAndStandingWaterData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-pie-chart>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_SLIP_AND_TRIP_TREND_AVG' }">
    <sv-insights-card
      contentHeight="300px"
      cardTitle="{{ 'dashboard.cards.projectGroupSlipAndTripTrendOverTime.title' | translate }}"
      subTitle="{{ 'dashboard.cards.projectGroupSlipAndTripTrendOverTime.subTitle' | translate }}"
    >
      <sv-insights-column-chart
        *ngIf="!chartHasAllNullValues(projectGroupSlipAndTripTrendOverTimeData?.chartData)"
        [chartData]="projectGroupSlipAndTripTrendOverTimeData?.chartData"
        [spinnerDiameter]="64"
      ></sv-insights-column-chart>
      <div class="text-card-content dashboard-single-value-card" *ngIf="chartHasAllNullValues(projectGroupSlipAndTripTrendOverTimeData?.chartData)">
        <div class="text-card-main-text">
          {{
          ('dashboard.valueNotAvailable' | translate)
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
  <div *svDashboardGridCell="{ tabId: 'SAFETY', cardId: 'PROJECT_GROUP_ASSETS_PEOPLE_PER_X_CONSTRUCTION' }">
    <sv-insights-card
      contentHeight="50px"
      cardTitle="{{ 'dashboard.cards.projectGroupPeoplePerXConstruction.title' | translate }}"
      subTitle="{{ currentInsightsDashboardParameters?.dateRangeStart.toDateString() }} - {{
        currentInsightsDashboardParameters?.dateRangeEnd.toDateString()
      }}"
    >
      <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
      <div class="text-card-content" *ngIf="projectGroupSingleValueData">
        <div class="text-card-main-text">
          {{
            isNA(projectGroupSingleValueData.peoplePerXConstructionAvg)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.peoplePerXConstructionAvg | number: '1.0-0')
          }}
        </div>
        <div style="height: 5px !important;"></div>
        <div class="text-card-sub-text {{ peoplePerXConstructionPercentChangeClassName }}">
          {{ getSign(projectGroupSingleValueData.peoplePerXConstructionAvgPercentChange)
          }}{{
            isNA(projectGroupSingleValueData.peoplePerXConstructionAvg)
              ? ('dashboard.valueNotAvailable' | translate)
              : (projectGroupSingleValueData.peoplePerXConstructionAvgPercentChange | number: '.1-1') + '%'
          }}
        </div>
      </div>
    </sv-insights-card>
  </div>
</div>
