/* global angular,analytics,_ */

angular.module('smartvid').controller('RegistrationCtrl', function (MAX_USER_FIRST_NAME_LENGTH, MAX_USER_LAST_NAME_LENGTH,
$scope, $stateParams, $rootScope, $location, smartvidApi, $filter, Notification, $timeout, $state, redirectorService, currentUser, $log) {
  $scope.MAX_USER_FIRST_NAME_LENGTH = MAX_USER_FIRST_NAME_LENGTH
  $scope.MAX_USER_LAST_NAME_LENGTH = MAX_USER_LAST_NAME_LENGTH

  $scope.submitted = false
  $scope.inTransit = false
  $scope.failedToUpdate = false
  $scope.readOnly = true
  $scope.sharing = ($stateParams.sharing) ? $stateParams.sharing : false
  // user
  $scope.firstName
  $scope.lastName
  $scope.email = window.atob($stateParams.email)
  $scope.password
  $scope.confirmPassword
  $scope.formSatisfactory = false

  let customUpdateUserErrorHandler = function (response) {
    let hasError = true
    let errorMessage = 'Failed to update user: '
    if (response.errorMessages) {
      _.each(response.errorMessages, function appendMessages (messageObj) {
        errorMessage += '\n' + messageObj.message
      })
    }

    $log.error(errorMessage, {firstName: $scope.firstName, lastName: $scope.lastName, email: $scope.email})

    return hasError
  }

  let customLoginErrorHandler = function (response) {
    let hasError = true
    let errorMessage = 'Could not login user: '
    if (response.errorMessages) {
      _.each(response.errorMessages, function appendMessages (messageObj) {
        errorMessage += '\n' + messageObj.message
      })
    }

    $log.error(errorMessage, {firstName: $scope.firstName, lastName: $scope.lastName, email: $scope.email})

    return hasError
  }

  $scope.submit = function () {
    if (!$scope.password) {
      $scope.form.password.$invalid = true
      $scope.form.password.$valid = false
      $scope.form.password.$dirty = true
      return
    }
    $scope.inTransit = true
    let promise = smartvidApi.updateUserInformation($scope.firstName, $scope.lastName, $scope.email, $scope.password,
                                                    $stateParams.token, customUpdateUserErrorHandler)
    promise.then((data) => {
      $timeout(function () {
        $scope.inTransit = false
        $scope.failedToUpdate = false
        $rootScope.$broadcast('sv-user-updated', data)
        let userId = data.data.id
        smartvidApi.login($scope.email, $scope.password, undefined, customLoginErrorHandler).then(function () {
          currentUser.initAnalytics(() => {
            analytics.track('Register', {
              category: 'User Action'
            })
            if (redirectorService.toState && redirectorService.shouldRedirectForUser($scope.email)) {
              $state.go(redirectorService.toState, redirectorService.toParams)
            } else {
              $state.go('dashboard.projects')
            }
          })
          // Accept EULA
          smartvidApi.acceptEula(userId)
        })
      }, 100)
    }, (data) => {
      $scope.inTransit = false
      $scope.failedToUpdate = true
    })
    return promise
  }

  $scope.submittable = function () {
    return $scope.form.$valid && $scope.password && $scope.password.length >= 8
  }
})
