<mat-spinner
  class="dashboard-spinner dashboard-spinner-forced-color"
  diameter="128"
  *ngIf="!isConfigurationLoaded"
></mat-spinner>
<div
  class="insights-dashboard-container top-and-left-border smartvid-dark-theme mdc-layout-grid"
  *ngIf="isConfigurationLoaded"
>
  <div class="mdc-layout-grid__inner">
    <div *svDashboardGridCell="{ cardId: 'PROJECT_GROUP_ASSETS_PROJECT_SCORE_AVG' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.projectGroupProjectScore.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
        <div class="text-card-content" *ngIf="projectGroupSingleValueData">
          <div class="project-score-dot {{ projectScoreValueClassName }}">
            <div class="text-card-main-text">
              {{
                isNA(projectGroupSingleValueData.projectScoreAvg)
                  ? ('dashboard.valueNotAvailable' | translate)
                  : (projectGroupSingleValueData.projectScoreAvg | number: '1.0-0')
              }}
            </div>
            <div class="v-space-5"></div>
            <div class="text-card-sub-text">
              {{ getSign(projectGroupSingleValueData.projectScoreAvgPercentChange)
              }}{{
                isNA(projectGroupSingleValueData.projectScoreAvg)
                  ? ('dashboard.valueNotAvailable' | translate)
                  : (projectGroupSingleValueData.projectScoreAvgPercentChange | number: '.1-1') + '%'
              }}
            </div>
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'PROJECT_GROUP_ASSETS_UPLOADED_TOTAL' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.projectGroupFileUploads.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
        <div class="text-card-content" *ngIf="projectGroupSingleValueData">
          <div class="text-card-main-text">
            {{
              isNA(projectGroupSingleValueData.uploadedFilesTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectGroupSingleValueData.uploadedFilesTotal | number)
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ uploadedFilesRatioPercentChangeClassName }}">
            {{ getSign(projectGroupSingleValueData.uploadedFilesRatioPercentChange)
            }}{{
              isNA(projectGroupSingleValueData.uploadedFilesTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectGroupSingleValueData.uploadedFilesRatioPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'PROJECT_GROUP_ASSETS_UPLOADERS_TOTAL' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.projectGroupTotalUploaders.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
        <div class="text-card-content" *ngIf="projectGroupSingleValueData">
          <div class="text-card-main-text">
            {{
              isNA(projectGroupSingleValueData.uploadersTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectGroupSingleValueData.uploadersTotal | number)
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ uploadersRatioPercentChangeClassName }}">
            {{ getSign(projectGroupSingleValueData.uploadersRatioPercentChange)
            }}{{
              isNA(projectGroupSingleValueData.uploadersTotal)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectGroupSingleValueData.uploadersRatioPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'PROJECT_GROUP_ASSETS_PPE_COMPLIANCE_AVG' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.projectGroupPpeComplianceAvg.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
        <div class="text-card-content" *ngIf="projectGroupSingleValueData">
          <div class="text-card-main-text">
            {{
              isNA(projectGroupSingleValueData.ppeCompliancePercentAvg)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectGroupSingleValueData.ppeCompliancePercentAvg | number: '.1-1') + '%'
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ ppeCompliancePercentAvgChangeClassName }}">
            {{ getSign(projectGroupSingleValueData.ppeCompliancePercentAvgChange)
            }}{{
              isNA(projectGroupSingleValueData.ppeCompliancePercentAvg)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectGroupSingleValueData.ppeCompliancePercentAvgChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'PROJECT_GROUP_ASSETS_WORK_AT_HEIGHTS_AVG' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.projectGroupWorkAtHeightsAgv.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
        <div class="text-card-content" *ngIf="projectGroupSingleValueData">
          <div class="text-card-main-text">
            {{
              isNA(projectGroupSingleValueData.workAtHeightAvg)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectGroupSingleValueData.workAtHeightAvg | number: '.1-1')
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ workAtHeightAvgPercentChangeClassName }}">
            {{ getSign(projectGroupSingleValueData.workAtHeightAvgPercentChange)
            }}{{
              isNA(projectGroupSingleValueData.workAtHeightAvg)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectGroupSingleValueData.workAtHeightAvgPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div *svDashboardGridCell="{ cardId: 'PROJECT_GROUP_ASSETS_SLIP_AND_TRIP_AVG' }" class="smartvid-light-theme">
      <sv-insights-card
        [darkTheme]="false"
        contentHeight="100px"
        cardTitle="{{ 'dashboard.cards.projectGroupSlipAndTripAvg.title' | translate }}"
      >
        <mat-spinner class="text-card-spinner" diameter="64" *ngIf="!projectGroupSingleValueData"></mat-spinner>
        <div class="text-card-content" *ngIf="projectGroupSingleValueData">
          <div class="text-card-main-text">
            {{
              isNA(projectGroupSingleValueData.slipAndTripAvg)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectGroupSingleValueData.slipAndTripAvg | number: '.1-1')
            }}
          </div>
          <div class="v-space-5"></div>
          <div class="text-card-sub-text {{ slipAndTripAvgPercentChangeClassName }}">
            {{ getSign(projectGroupSingleValueData.slipAndTripAvgPercentChange)
            }}{{
              isNA(projectGroupSingleValueData.slipAndTripAvg)
                ? ('dashboard.valueNotAvailable' | translate)
                : (projectGroupSingleValueData.slipAndTripAvgPercentChange | number: '.1-1') + '%'
            }}
          </div>
        </div>
      </sv-insights-card>
    </div>
    <div
      class="tab-container-wrapper mdc-layout-grid__cell--order-12 mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone"
    >
      <mat-tab-group>
        <mat-tab *svDashboardTab="'ACTIVITY'" label="{{ getTabTitle('ACTIVITY') | translate }}">
          <div class="tab-content mdc-layout-grid">
            <sv-project-group-assets-insights-dashboard-activity></sv-project-group-assets-insights-dashboard-activity>
          </div>
        </mat-tab>
        <mat-tab *svDashboardTab="'SAFETY'" label="{{ getTabTitle('SAFETY') | translate }}">
          <div class="tab-content mdc-layout-grid">
            <sv-project-group-assets-insights-dashboard-safety
              [projectGroupSingleValueData]="projectGroupSingleValueData"
            ></sv-project-group-assets-insights-dashboard-safety>
          </div>
        </mat-tab>
        <mat-tab *svDashboardTab="'IMAGE_HEALTH'" label="{{ getTabTitle('IMAGE_HEALTH') | translate }}">
          <div class="tab-content mdc-layout-grid">
            <sv-project-group-assets-insights-dashboard-image-health
              [projectGroupSingleValueData]="projectGroupSingleValueData"
            ></sv-project-group-assets-insights-dashboard-image-health>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
