/* global angular */

angular.module('smartvid').factory('ForgeRootCauseCollection', function (BaseCollection, ForgeRootCauseModel) {
  class ForgeRootCauseCollection extends BaseCollection {
    constructor (models) {
      super(models, ForgeRootCauseModel)
    }
  }

  return ForgeRootCauseCollection
})
