import { Component, Inject, Input, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { AnalyticsRolesSettingsResponse, PowerBiService } from 'angular2/modules/analytics/services/powerbi.service'
import { CurrentUser, CURRENT_USER } from 'angular2/shared/smartvid.types'
import { Organization } from '../../models/organization.model'

@Component({
  selector: 'sv-admin-organization-analytics',
  templateUrl: 'admin-organization-analytics.component.html',
  styleUrls: ['admin-organization-analytics.component.scss'],
})
export class AdminOrganizationAnalyticsComponent implements OnInit {
  @Input() organization: Organization

  private updateInProgress = false

  constructor(
    private formBuilder: FormBuilder,
    private powerBiService: PowerBiService,
    @Inject(CURRENT_USER) private currentUser: CurrentUser
  ) {}

  rolePermissionForms = this.formBuilder.group({
    safetyMonitoring: this.formBuilder.group({
      orgAdmin: [false],
      groupAdmin: [false],
      projectAdmin: [false],
    }),
    safetyObservations: this.formBuilder.group({
      orgAdmin: [false],
      groupAdmin: [false],
      projectAdmin: [false],
    }),
    predictive: this.formBuilder.group({
      orgAdmin: [false],
      groupAdmin: [false],
      projectAdmin: [false],
    }),
  })

  ngOnInit(): void {
    this.powerBiService.getAnalyticsRolesSettings(this.organization.id).subscribe(response => {
      this.init(response)
    })
  }

  updateRolePermissions(): void {
    let request = {
      safetyMonitoring: {
        isOrgLevel: this.rolePermissionForms.get('safetyMonitoring.orgAdmin').value,
        isGroupLevel: this.rolePermissionForms.get('safetyMonitoring.groupAdmin').value,
        isProjectLevel: this.rolePermissionForms.get('safetyMonitoring.projectAdmin').value,
      },
      safetyObservations: {
        isOrgLevel: this.rolePermissionForms.get('safetyObservations.orgAdmin').value,
        isGroupLevel: this.rolePermissionForms.get('safetyObservations.groupAdmin').value,
        isProjectLevel: this.rolePermissionForms.get('safetyObservations.projectAdmin').value,
      },
      predictive: {
        isOrgLevel: this.rolePermissionForms.get('predictive.orgAdmin').value,
        isGroupLevel: this.rolePermissionForms.get('predictive.groupAdmin').value,
        isProjectLevel: this.rolePermissionForms.get('predictive.projectAdmin').value,
      },
    }
    this.powerBiService.updateAnalyticsRolesSettings(this.organization.id, request).subscribe(response => {
      this.init(response)
    })
  }

  init(response: AnalyticsRolesSettingsResponse) {
    this.rolePermissionForms.setValue({
      safetyMonitoring: {
        orgAdmin: response.safetyMonitoring.isOrgLevel,
        groupAdmin: response.safetyMonitoring.isGroupLevel,
        projectAdmin: response.safetyMonitoring.isProjectLevel,
      },
      safetyObservations: {
        orgAdmin: response.safetyObservations.isOrgLevel,
        groupAdmin: response.safetyObservations.isGroupLevel,
        projectAdmin: response.safetyObservations.isProjectLevel,
      },
      predictive: {
        orgAdmin: response.predictive.isOrgLevel,
        groupAdmin: response.predictive.isGroupLevel,
        projectAdmin: response.predictive.isProjectLevel,
      },
    })
  }

  isPredictiveEnabled() {
    return this.currentUser.isAnalyticsPredictiveFeatureEnabledForOrganization(this.organization.id)
  }

  isSafetyObservationsEnabled() {
    return this.currentUser.isAnalyticsSafetyObservationsFeatureEnabledForOrganization(this.organization.id)
  }

  isSafetyMonitoringEnabled() {
    return this.currentUser.isAnalyticsSafetyMonitoringFeatureEnabledForOrganization(this.organization.id)
  }
}
