/* global $, angular */

angular.module('smartvid').directive('tagTreeDropdown', ($timeout, uiGridConstants) => {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      project: '=',
      tagSelectedCallback: '=',
      tagTree: '='
    },
    templateUrl: 'TagManager/tag-tree-dropdown.html',
    link (scope, $el) {
      let treeCallbacks

      scope.tagDefTitle = 'Top of tag list'
      scope.selectedTreeNode

      scope.dropDownVisible = true

      scope.initTreeCallbacks = function (callbacks) {
        treeCallbacks = callbacks
        scope.dropDownVisible = false
      }

      scope.selectTag = (event, node) => {
        scope.selectedTreeNode = node
        scope.tagDefTitle = node.title
        scope.tagSelectedCallback(node.tagDefinitionId, node.title)
        hide()
      }

      scope.tagTreeDropdownGridOptions = {
        rowTemplate: 'TagManager/tree-node-for-dropdown.html',
        multiSelect: false,
        modifierKeysToMultiSelect: false,
        noUnselect: false,
        rowSelection: true,
        enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS
      }

      let outsideClickListener = (event) => {
        if (!$(event.target).closest('.tag-tree-container-parent').length) {
          if (scope.dropDownVisible) {
            hide()
            event.stopPropagation()
            event.preventDefault()
          }
        }
      }

      let hide = () => {
        document.body.removeEventListener('mousedown', outsideClickListener)
        scope.dropDownVisible = false
      }

      scope.$on('$destroy', function () {
        document.body.removeEventListener('mousedown', outsideClickListener)
      })

      scope.toggleTagTree = (event) => {
        if (scope.dropDownVisible) {
          hide()
        } else {
          treeCallbacks.clearSearchInput()
          scope.dropDownVisible = true
          event.stopPropagation()
          event.preventDefault()
          document.body.addEventListener('mousedown', outsideClickListener)
        }
        return false
      }
    }
  }
})
