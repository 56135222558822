/* global angular */

angular.module('smartvid').directive('userMenuItem', function (currentUser) {
  return {
    restrict: 'E',
    templateUrl: 'user-menu-item.html',
    scope: {
      itemName: '=',
      itemState: '='
    },
    link: function (scope) {

    }
  }
})
