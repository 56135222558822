/* global angular, _ */

angular.module('smartvid').factory('CommentModel', function (BaseModel) {
  class CommentModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        assetId: undefined, // timestamp
        deleted: undefined, // boolean
        createdTime: undefined,
        description: undefined,
        updatedTime: undefined,
        creator: {
          firstName: undefined,
          lastName: undefined,
          email: undefined
        },
        startTime: undefined,
        markup: '{}',
        shape: undefined,
        width: undefined,
        height: undefined,
        top: undefined,
        left: undefined,
        rotation: 0,
        color: 'red',
        editMode: false,
        local: false,
        isEditable: true,
        panX: undefined,
        panY: undefined,
        zoomLevel: undefined,
        imageZoomLevel: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }

    startTimeInSeconds () {
      return this.startTime / 1000
    }

    hasStyle () {
      return ((angular.isDefined(this.width) &&
        angular.isDefined(this.height) &&
        angular.isDefined(this.top) &&
        angular.isDefined(this.left)) ||
        (angular.isDefined(this.shape) &&
        angular.isDefined(this.markup && this.markup.sphericalMarkup)))
    }

    getStyles () {
      if (!this.hasStyle()) {
        return undefined
      }

      return {
        width: this.width,
        height: this.height,
        top: this.top,
        left: this.left
      }
    }

    markupAsJSONString () {
      let json
      if (this.getStyles()) {
        json = this.getStyles()
        json.shape = this.shape
        json.color = this.color
        json.rotation = this.rotation
        json.version = this.version
        json.type = this.type
        json.top = this.top
        json.left = this.left
        json.height = this.height
        json.width = this.width
        json.videoHeight = this.markup.videoHeight
        json.videoWidth = this.markup.videoWidth
        json.clientHeight = this.markup.clientHeight
        json.clientWidth = this.markup.clientWidth
        json.actualHeight = this.markup.actualHeight
        json.actualWidth = this.markup.actualWidth
        json.vMargins = this.markup.vMargins
        json.hMargins = this.markup.hMargins
        json.sphericalMarkup = this.markup.sphericalMarkup
        return JSON.stringify(json)
      }
      return JSON.stringify({})
    }

    removeMarkup () {
      this.width = undefined
      this.height = undefined
      this.top = undefined
      this.left = undefined
      this.markup = {}
      this.shape = undefined
    }

    populateValuesFromMarkupJSON () {
      // TODO(vgorsky) Support multiple markups per comment
      let markupJSON
      if (this.markupData && this.markupData.length > 0) {
        markupJSON = JSON.parse(this.markupData[0].markup)
      } else if (typeof this.markup === 'string') {
        // TODO(vgorsky) Switch comment creation endpoint to new model
        //  TODO: Markup Testing that will probably break something in DZI
        markupJSON = JSON.parse(this.markup)
      }
      if (markupJSON) {
        this.markup = markupJSON
        this.width = markupJSON.type && markupJSON.type === 'DZI' ? parseFloat(markupJSON.width) : markupJSON.width
        this.height = markupJSON.type && markupJSON.type === 'DZI' ? parseFloat(markupJSON.height) : markupJSON.height
        this.top = markupJSON.type && markupJSON.type === 'DZI' ? parseFloat(markupJSON.top) : markupJSON.top
        this.left = markupJSON.type && markupJSON.type === 'DZI' ? parseFloat(markupJSON.left) : markupJSON.left
        this.color = markupJSON.color
        this.shape = markupJSON.shape
        this.rotation = markupJSON.rotation || 0
        this.version = markupJSON.version
        this.type = markupJSON.type
        if (markupJSON.type !== 'DZI') {
          this.markup = {}
          this.top = markupJSON.top
          this.left = markupJSON.left
          this.width = markupJSON.width
          this.height = markupJSON.height
          this.markup.videoHeight = markupJSON.videoHeight
          this.markup.videoWidth = markupJSON.videoWidth
          this.markup.clientHeight = markupJSON.clientHeight
          this.markup.clientWidth = markupJSON.clientWidth
          this.markup.actualHeight = markupJSON.actualHeight
          this.markup.actualWidth = markupJSON.actualWidth
          this.markup.vMargins = markupJSON.vMargins
          this.markup.hMargins = markupJSON.hMargins
        }
      }
    }
  }

  return CommentModel
})
