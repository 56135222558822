/* global angular analytics _ */

angular.module('smartvid').controller('ConnectToBim360FieldCtrl',
  function (MAX_BIM_360_USER_ID_LENGTH, MAX_BIM_360_USER_PASSWORD_LENGTH, $scope, $rootScope, $filter, smartvidApi,
            $state, $q, $timeout) {
    const BIM360_FIELD_USER_IS_A_SUB = 'bim360.field.user.is.sub'
    const UNAUTHORIZED = 'UNAUTHORIZED'
    const SHOW_NON_US_REGION_WARNING = false

    $scope.inTransit = false
    $scope.userIsASubError = false
    $scope.incorrectLoginError = false
    $scope.selectedData = {nonUSRegionAgreed: false}
    $scope.regions = [
      {
        name: $filter('i18next')('integrations.bim360.field.connection.bim360FieldUserRegionUS'),
        id: 'US'
      },
      {
        name: $filter('i18next')('integrations.bim360.field.connection.bim360FieldUserRegionEMEA'),
        id: 'EMEA'
      }]
    $scope.selectedRegion = $scope.regions[0]

    $scope.isNonUSRegionSelected = () => {
      return $scope.selectedRegion !== $scope.regions[0]
    }

    $scope.connectToBim360Field = () => {
      analytics.track('Connect to BIM360 Field - login', {
        category: 'Integration Action'
      })
      $scope.inTransit = true

      let customErrorHandler = (response) => {
        if (response.errorCode === UNAUTHORIZED && _.find(response.errorMessages,
          (m) => {
            return m.label === BIM360_FIELD_USER_IS_A_SUB
          })) {
          $scope.userIsASubError = true
          return true
        } else if (response.errorCode === UNAUTHORIZED) {
          $scope.incorrectLoginError = true
          return true
        }

        return false
      }

      smartvidApi.loginInToBim360Field($scope.bim360FieldUserId, $scope.bim360FieldUserPassword, $scope.selectedRegion.id, customErrorHandler).then((data) => {
        $timeout(function () {
          $scope.inTransit = false
          $rootScope.$broadcast('sv-connected-to-bim360-field', data)
          $scope.modal.close()
        }, 100)
      }, () => {
        $scope.inTransit = false
      })
    }

    $scope.isConnectDisabled = () => {
      return $scope.bim360FieldUserId === undefined || $scope.bim360FieldUserId === '' ||
        $scope.bim360FieldUserPassword === undefined || $scope.bim360FieldUserPassword === '' ||
        (SHOW_NON_US_REGION_WARNING && $scope.isNonUSRegionSelected() && !$scope.selectedData.nonUSRegionAgreed)
    }
  })
