import { ModuleType } from './module-type'

export class UpdateInvitedUserProjectGroupsRequest {
  public updatedUsers: {
    userId: string
    userEmail: string
    projectGroupId: string
    assetRole: string
    observationRole: string
    tradePartnerRole: string
  }[]

  public uninvitedUsers: {
    userId: string
    projectGroupId: string
    moduleType: ModuleType
  }[]
}

export class UpdateInvitedUserProjectGroupsResponse {
  public invitedUsers: {
    userId: string
    email: string
    assetRole: string
    observationRole: string
    tradePartnerRole: string
  }[]
}
