/* global angular _ moment $ */

angular.module('smartvid').controller('RunFilesInfoReportCtrl', function (
  $scope, $rootScope, $element, $q, $timeout, $filter, utils, smartvidApi, dashboardDataHelper, currentUser, reportStateService, modal,
  ReportName, constantRetrivalService, MultiAssetSelection, TagDefModel, batchJobService) {
  const TAG_CONFIDENCE_LEVELS = constantRetrivalService.TAG_CONFIDENCE_LEVELS
  const SCHEDULE_TYPES = constantRetrivalService.SCHEDULE_TYPES
  let allProjects = dashboardDataHelper.getAllProjects()
  let myOrg = _.first(dashboardDataHelper.getAllOrganizations().where({isMyOrg: true}))
  const FORMAT = 'MM/DD/YYYY'
  $scope.scheduleTypes = SCHEDULE_TYPES
  $scope.schedule = {selected: SCHEDULE_TYPES.find(t => t.type === 'NEVER')}
  $scope.addedTags = []
  $scope.ignoredTagTexts = []
  $scope.allConfidenceLevels = TAG_CONFIDENCE_LEVELS
  $scope.selectedConfidenceLevel = TAG_CONFIDENCE_LEVELS[0]
  $scope.isFetchingSystemData = true

  $scope.step = 1
  $scope.projectsSelectedForReport = []
  $scope.showProjectValidation = false
  $scope.constructionRelatedOnly = {value: true}
  $scope.includeAllProjects = null
  $scope.searchStartDate
  $scope.searchEndDate
  $scope.searchDateModified = false
  $scope.selectedUsers = []

  $scope.getAllUsers = getAllUsers
  $scope.searchByEmail = searchByEmail
  $scope.searchProjectsByPartialText = searchProjectsByPartialText
  $scope.handleDownArrow = handleDownArrow
  $scope.handleSelectedTag = handleSelectedTag
  $scope.addProject = addProject
  $scope.addAllProjects = addAllProjects
  $scope.hasSelectedProjects = hasSelectedProjects
  $scope.hasSelectedTags = hasSelectedTags
  $scope.checkForReturnKey = checkForReturnKey
  $scope.removeProject = removeProject
  $scope.removeTag = removeTag
  $scope.nextStep = nextStep
  $scope.closeModal = closeModal
  $scope.previousStep = previousStep

  $scope.submit = submit
  init()
  $scope.datePicker = {date: null}
  $scope.datePickerOptions = getDatePickerOptions($scope.searchStartDate)
  $scope.rangePickerInFocus = false

  function init () {
    let lastReportState = getLastReportStateForUser(currentUser.id)

    $scope.projectsSelectedForReport = _(allProjects.models)
      .filter((project) => _.contains(lastReportState.selectedProjectIds, project.id)).value()

    $scope.selectedConfidenceLevel = lastReportState.selectedConfidenceLevelValue
      ? _.findWhere(TAG_CONFIDENCE_LEVELS, {value: lastReportState.selectedConfidenceLevelValue})
      : $scope.selectedConfidenceLevel
    $scope.addedTags = lastReportState.addedTags || []
    $scope.ignoredTagTexts = lastReportState.addedTags && _.pluck(lastReportState.addedTags, 'text') || []
    $scope.constructionRelatedOnly.value = lastReportState.isConstructionRelatedOnly

    $scope.searchStartDate = lastReportState.searchStartDate
    $scope.searchEndDate = lastReportState.searchEndDate
    $scope.includeAllProjects = lastReportState.includeAllProjects
    _.forEach(lastReportState.selectedUsers || [], (u) => $scope.selectedUsers.push(u))
    if (_.isEmpty($scope.selectedUsers)) {
      $scope.selectedUsers.push(currentUser)
    }
  }

  function nextStep () {
    $scope.step++
    if ($scope.step === 3 && $scope.searchDateModified) {
      $scope.datePickerOptions = getDatePickerOptions(true)
    }
  }

  function previousStep () {
    $scope.step--
    if ($scope.step === 3 && $scope.searchDateModified) {
      $scope.datePickerOptions = getDatePickerOptions(true)
    }
  }

  function closeModal () {
    modal.close()
  }

  function hasSelectedProjects () {
    return allProjects !== undefined && allProjects.getSelected().length > 0
  }

  function getLastReportStateForUser (userId) {
    return reportStateService.getReportStateForUser(ReportName.FILES_INFO_REPORT, userId)
  }

  function getAllUsers () {
    let promises = []
    _.each(allProjects.models, (project) => {
      promises.push(smartvidApi.getProjectUsers(project.id))
    })
    _.each(currentUser.organizations, (org) => {
      promises.push(smartvidApi.getOrganizationUsers(org.id))
    })
    return $q.all(promises)
  }

  function searchByEmail (partialEmail) {
    let promises = []
    let projectIds = _.pluck(allProjects.where({canInviteUsers: true}), 'id')
    let orgIds = _.pluck(_.where(currentUser.organizations, {canManageUsers: true}), 'id')
    promises.push(smartvidApi.getUsersByPartialEmailForProjects(projectIds, partialEmail))
    promises.push(smartvidApi.getUsersByPartialEmailForOrgs(orgIds, partialEmail))
    return $q.all(promises)
  }

  function searchProjectsByPartialText (partial) {
    function safe (str) {
      return (str || '').toUpperCase()
    }

    let result = _(allProjects.models)
      .filter((project) => project.canRunReports && safe(project.name).indexOf(safe(partial)) !== -1)
      .filter((project) => !_.contains($scope.projectsSelectedForReport, project))
      .sortBy((proj) => safe(proj.name))
      .value()
    return $q.resolve({'data': result})
  }

  function handleDownArrow () {
    let angucompleteElement = angular.element('.report-step-page .angucomplete-holder')
    let angucompleteScope = angucompleteElement.scope()
    if (angucompleteScope.showDropdown) {
      angucompleteScope.showDropdown = false
      return
    }
    let inputField = angucompleteElement.find('input')
    inputField.trigger('mousedown')
    inputField.focus()
    angucompleteScope.showDropdown = true
    angucompleteScope.searching = true
    let inputFieldValue = inputField.val()
    let searchPromise = searchProjectsByPartialText(inputFieldValue)
    searchPromise.then(results => {
      $timeout(() => {
        angucompleteScope.results = _(results.data || []).map((project) => {
          return {
            title: project.name,
            description: '',
            image: '',
            originalObject: project
          }
        }).value()
        angucompleteScope.searching = false
        angucompleteScope.showDropdown = true
      }, 300)
    })
  }

  function checkForReturnKey (evt) {
    if (evt.keyCode === 13) {
      evt.preventDefault()
      addProject()
    }
  }

  function addAllProjects (checked) {
    if (!arguments.length) {
      return $scope.includeAllProjects
    }
    let projects = _(allProjects.models)
      .filter((project) => project.canRunReports)
      .filter((project) => !_.contains($scope.projectsSelectedForReport, project))
      .value()
    if (projects.length === 0) {
      $scope.projectsSelectedForReport = []
    } else {
      $scope.projectsSelectedForReport.push(...projects)
    }
    $scope.includeAllProjects = checked
    return $scope.includeAllProjects
  }

  function addProject () {
    $scope.showProjectValidation = false
    let angucompleteScope = angular.element('.report-step-page .angucomplete-holder').scope()
    if (angucompleteScope.selectedObject && angucompleteScope.selectedObject.originalObject) {
      let projectToAdd = _.isString(angucompleteScope.selectedObject.originalObject)
        ? allProjects.first({name: angucompleteScope.selectedObject.originalObject})
        : angucompleteScope.selectedObject.originalObject
      if (projectToAdd && !_.contains($scope.projectsSelectedForReport, projectToAdd)) {
        $scope.projectsSelectedForReport.push(projectToAdd)
      }
    } else {
      let inputVal = angular.element('.report-step-page .angucomplete-holder input').val()
      if (inputVal) {
        let projectToAdd = _(allProjects.models)
          .filter((project) => project.canRunReports && (project.name || '').toUpperCase() === inputVal.toUpperCase())
          .filter((project) => !_.contains($scope.projectsSelectedForReport, project))
          .value()
          .shift()
        if (projectToAdd) {
          $scope.projectsSelectedForReport.push(projectToAdd)
        }
      }
    }
    const canUpdateProjects = _(allProjects.models).filter((project) => project.canRunReports).value()
    if ($scope.projectsSelectedForReport.length > 0 && canUpdateProjects.length === $scope.projectsSelectedForReport.length) {
      $scope.includeAllProjects = true
    }
    $scope.$broadcast('angucomplete-alt:clearInput')
  }

  function removeProject (project) {
    $scope.showProjectValidation = false
    _.remove($scope.projectsSelectedForReport, project)
    $scope.includeAllProjects = false
  }

  function removeTag (tag) {
    $scope.addedTags.splice($scope.addedTags.indexOf(tag), 1)
    _.pull($scope.ignoredTagTexts, tag.text)
  }

  function hasSelectedTags () {
    return _($scope.addedTags).values().any()
  }

  function handleSelectedTag (tag) {
    // never allow blank tag
    if (!tag || _.trim(tag).length === 0) {
      return
    }

    if (!(tag instanceof TagDefModel)) {
      return
    }
    if (_.find($scope.addedTags,
      (existingTag) => {
        return tag.id === existingTag.id || tag.text.toUpperCase() === existingTag.text.toUpperCase()
      })) {
      return
    }
    tag.isNew = false
    $scope.addedTags.push(tag)
    $scope.ignoredTagTexts.push(tag.text)
  }

  function toLocalStorageItemFromRequest (request) {
    let ret = {}
    const datePickerDate = $scope.datePicker.date
    const datePickerStartDate = datePickerDate && datePickerDate.startDate
    const datePickerEndDate = datePickerDate && datePickerDate.endDate
    ret.selectedProjectIds = request.searchRequest.includedProjectIds
    ret.searchStartDate = datePickerStartDate !== null && datePickerStartDate.format(FORMAT)
    ret.searchEndDate = datePickerEndDate !== null && datePickerEndDate.format(FORMAT)
    ret.addedTags = $scope.addedTags
    ret.selectedConfidenceLevelValue = request.searchRequest.tagConfidenceLevel
    ret.isConstructionRelatedOnly = request.isConstructionRelatedOnly
    ret.includeAllProjects = $scope.includeAllProjects
    ret.selectedUsers = _.map($scope.selectedUsers, (u) => {
      return {
        email: u.email,
        id: u.id,
        firstName: u.firstName,
        lastName: u.lastName,
        freeText: u.freeText
      }
    })
    return ret
  }

  function collectTags (projectIds, tagDefsNames) {
    let defer = $q.defer()
    const tagPromises = []

    let allTags = []
    let finalTags = []

    _.each(projectIds, (id) => {
      _.each(tagDefsNames, (text) => {
        tagPromises.push(smartvidApi.findSearchableEntity(id, text))
      })
    })

    $q.all(tagPromises).then(responses => {
      _.each(responses, (response) => {
        allTags.push(...response)
      })
      _.each(tagDefsNames, (tagName) => {
        let filter = allTags.filter(t => {
          return t.type === 'TAG_DEFINITION' && t.text === tagName
        })
        if (filter.length !== 0) {
          finalTags.push(...filter)
        }
      })
      defer.resolve(finalTags)
    })
    return defer.promise
  }

  function constructRequestObject (tags) {
    let requestObject = {}
    requestObject.projectId = null
    requestObject.isAllAssets = true
    const projectIds = _.pluck($scope.projectsSelectedForReport, 'id')
    const tagDefsIds = _.pluck(tags, 'id')
    const tagDefs = _.map(tags, (t) => {
      return {
        tagDefId: t.id,
        hasChildren: t.hasChildren
      }
    })
    const datePickerDate = $scope.datePicker.date
    const startDate = datePickerDate && datePickerDate.startDate
    const endDate = datePickerDate && datePickerDate.endDate
    const searchDateRange = angular.isDefined(startDate) && startDate !== null && angular.isDefined(endDate) && endDate !== null ? {
      startDate: startDate && startDate.format(FORMAT),
      endDate: endDate && endDate.format(FORMAT)
    } : null
    requestObject.searchRequest = {
      includedProjectIds: projectIds,
      tagDefs: tagDefs,
      tagConfidenceLevel: $scope.selectedConfidenceLevel.value,
      searchDateRange: searchDateRange,
      searchQueryType: null
    }
    requestObject.assetViewType = 'SEARCH_RESULTS'
    requestObject.tagDefIds = tagDefsIds
    requestObject.isConstructionRelatedOnly = $scope.constructionRelatedOnly.value
    requestObject.reportFixedScheduleInterval = $scope.schedule.selected.type
    requestObject.toUserEmails = _.pluck($scope.selectedUsers, 'email')

    return requestObject
  }

  function submit () {
    if ($scope.selectedUsers.length < 1 || $scope.addedTags.length < 1) return
    const projectIds = _.pluck($scope.projectsSelectedForReport, 'id')
    const tagDefsNames = _.pluck($scope.addedTags, 'text')
    $scope.generatingReport = true
    collectTags(projectIds, tagDefsNames).then(tags => {
      let requestObject = constructRequestObject.call(this, tags)

      smartvidApi.generateFilesInfoReport(myOrg.id, requestObject).then(() => {
        $scope.step++
        $scope.generatingReport = false
        reportStateService.upsertReportStateForUser(ReportName.FILES_INFO_REPORT, currentUser.id,
          toLocalStorageItemFromRequest(requestObject))
      })
    })
  }

  $scope.clearDateRange = (e) => {
    clearSearchDateRange()
  }

  function clearSearchDateRange () {
    let dateRangePicker = $.find('.date-picker')
    if (dateRangePicker && dateRangePicker.data && dateRangePicker.data('daterangepicker').clear) {
      dateRangePicker.data('daterangepicker').clear()
    }
    $scope.searchStartDate = null
    $scope.searchEndDate = null
    $scope.datePickerOptions = getDatePickerOptions(false)

    $scope.datePicker.date.startDate = null
    $scope.datePicker.date.endDate = null
    $scope.searchDateModified = false
  }

  function searchStartDate () {
    return $scope.searchStartDate ? moment($scope.searchStartDate, FORMAT) : moment()
  }

  function searchEndDate () {
    return $scope.searchEndDate ? moment($scope.searchEndDate, FORMAT) : moment()
  }

  function getDate (modified) {
    return {startDate: searchStartDate(), endDate: searchEndDate(), modified: modified}
  }

  function getDatePickerOptions (modified) {
    return {
      locale: {
        format: FORMAT
      },
      pickerModifiedStartDate: modified ? searchStartDate().format(FORMAT) : undefined,
      pickerModifiedEndDate: modified ? searchEndDate().format(FORMAT) : undefined,
      pickerInitialVal: $filter('i18next')('reporting.datePicker.initialLabel'),
      startDate: searchStartDate(),
      endDate: searchEndDate(),
      maxDate: moment().toDate(),
      eventHandlers: {
        'apply.daterangepicker': function (ev, picker) {
          $scope.searchStartDate = ev.model.startDate
          $scope.searchEndDate = ev.model.endDate
          $scope.datePicker.date = getDate(true)
          $scope.searchDateModified = true
        },
        'show.daterangepicker': function (ev, picker) {
          $scope.rangePickerInFocus = true
        },
        'hide.daterangepicker': function (ev, picker) {
          $timeout(() => {
            $scope.rangePickerInFocus = false
          }, 100)
        }
      }
    }
  }
})
