/* global angular _ */

angular.module('smartvid').factory('ProcoreObservationIssueModel', function (BaseModel) {
  class ProcoreObservationIssueModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        assignee_id: '',
        contributing_behavior_id: undefined,
        contributing_condition_id: undefined,
        checklist_item_id: undefined,
        created_by_id: '',
        description: '',
        due_date: undefined,
        hazard_id: undefined,
        name: '',
        number: 1,
        personal: true,
        priority: undefined,
        status: undefined,
        trade_id: undefined,
        type_id: undefined,
        distribution_member_ids: [],
        location_id: undefined,
        mt_location: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return ProcoreObservationIssueModel
})
