/* global angular */

angular.module('smartvid').controller('TagSortOptionsCtrl', function ($scope, uiGridConstants, rowSorter, flyout) {
  let sortingColumn = $scope.gridApi.grid.getColumn('title')
  $scope.sorts = ['default', 'alphabeticalAZ', 'alphabeticalZA']
  $scope.sortData = {
    'default': {
      bundle: 'common.default',
      sorter: (grid, column) => {
        column.unsort()
      }
    },
    'alphabeticalAZ': {
      bundle: 'common.alphabeticalAZ',
      sorter: (grid, column) => {
        column.sortingAlgorithm = sortingFn
        grid.sortColumn(column, uiGridConstants.ASC)
      }
    },
    'alphabeticalZA': {
      bundle: 'common.alphabeticalZA',
      sorter: (grid, column) => {
        column.sortingAlgorithm = sortingFn
        grid.sortColumn(column, uiGridConstants.DESC)
      }
    }
  }

  $scope.selectedSort = getSelectedSort()
  $scope.sortBy = sortBy
  $scope.getStyles = getStyles

  function getSelectedSort () {
    if (sortingColumn.sort === undefined || sortingColumn.sort.direction === undefined) {
      return 'default'
    }
    if (sortingColumn.sort.direction === 'asc') {
      return 'alphabeticalAZ'
    }
    return 'alphabeticalZA'
  }

  function sortBy (method) {
    $scope.sortData[method].sorter($scope.gridApi.grid, sortingColumn)
    flyout.close()
  }

  function getStyles () {
    return {
      top: $scope.styles.top,
      left: $scope.styles.left,
      width: 205
    }
  }

  function sortingFn (a, b, rowA, rowB, direction) {
    let entityA = rowA.entity
    let entityB = rowB.entity
    if (entityA.projectId && !entityB.projectId) {
      return (direction === 'asc') ? 1 : -1
    }
    if (!entityA.projectId && entityB.projectId) {
      return (direction === 'asc') ? -1 : 1
    }
    return rowSorter.sortAlpha(a, b)
  }
})
