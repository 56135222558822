/* global angular */
/**
 * Created by ryanrubbico on 1/12/18.
 */
angular.module('smartvid').filter('interpolate', function ($interpolate) {
  function filterFactory () {
    return function (template, myObject) {
      return $interpolate(template)(myObject)
    }
  }

  return filterFactory()
})
