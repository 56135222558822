import angular from 'angular'
import 'globals'
import { PlatformRef, StaticProvider, enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { downgradeModule, setAngularJSGlobal } from '@angular/upgrade/static'
import { UrlService } from '@uirouter/core'
import { environment } from './environments/environment'

if (environment.production) {
  enableProdMode()
}

setAngularJSGlobal(angular)

import { AppModule } from 'angular2/app.module'
import angularJSModule from 'angularjs/app.module.ajs'

//https://hub.packtpub.com/building-better-bundles-why-processenvnodeenv-matters-optimized-builds/
//https://www.bennadel.com/blog/3598-webpack-4-automatically-makes-process-env-node-env-available-in-your-javascript.htm

const bootstrapFn = (extraProviders: StaticProvider[]) => {
  const platformRef: PlatformRef = platformBrowserDynamic(extraProviders)
  return platformRef.bootstrapModule(AppModule)
}

angularJSModule.config(['$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept()])

angular.bootstrap(document.body, [downgradeModule(bootstrapFn), angularJSModule.name], {
  strictDi: true,
})

// const urlService: UrlService = injector.get('$urlService');

// https://github.com/ui-router/angular-hybrid/issues/98
// setTimeout(() => {
//   // setTimeout needed to allow angular routes to initialize after refresh
//   urlService.listen();
//   urlService.sync();
// })
