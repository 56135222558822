/* global angular, _ */

angular.module('smartvid').controller('ProjectMembersGridOptionsCtrl', function ($scope, $stateParams, $rootScope, modal) {
  $scope.unInviteSelectedProjectMembers = () => {
    let selectedUserIds = _.pluck($scope.projectMembers.where({selected: true, canUpdate: true}), 'userId')
    $scope.flyout.isOpen = false
    modal.open('unInviteProjectMember', {
      confirm () {
        $scope.currentProject.removeUsers(selectedUserIds).finally(() => {
          $scope.flyout.close()
        })
      },
      hasSelections: selectedUserIds.length > 0
    })
  }

  $scope.getStyles = () => {
    return {
      top: $scope.styles.top,
      left: $scope.styles.left
    }
  }
})
