<div class="risk-score-header mat-title">
  <button class="mat-flat-button sv-risk-label sv-risk-{{riskType.toLowerCase()}}">
    <ng-container [ngSwitch]="riskType">
      <mat-icon *ngSwitchCase="ObservationRiskType.POSITIVE">star</mat-icon>
      <mat-icon *ngSwitchCase="ObservationRiskType.UNKNOWN" svgIcon="score_unknown"></mat-icon>
      <div *ngSwitchDefault [hidden]="riskHidden">{{riskScore}}</div>
    </ng-container>
  </button>
  <div class="risk-score-title">{{ 'components.observationDialog.riskTitle.' + riskType | translate }}</div>
  <mat-divider></mat-divider>
</div>
