/* global angular _ */

angular.module('smartvid').factory('Bim360FieldPhotoSyncSettingsModel', function (BaseModel) {
  class Bim360FieldPhotoSyncSettingsModel extends BaseModel {
    constructor (attrs) {
      let defaults = {
        bim360FieldUserId: undefined,
        bim360FieldProjectId: undefined,
        isDownloadLocations: undefined
      }

      super(_.defaults(attrs || {}, defaults))
    }
  }

  return Bim360FieldPhotoSyncSettingsModel
})
