/* global angular,_,$ */

angular.module('smartvid').directive('userSelector', function ($q, $timeout, $filter, $stateParams, smartvidApi, BaseCollection, utils, $rootScope, emailUtils, currentUser) {
  return {
    restrict: 'E',
    replace: false,
    scope: {
      searchEmailFn: '&',
      allUsersFn: '&',
      validateEmailFn: '&?',
      label: '@?',
      roles: '=',
      helpPath: '=',
      noResultsText: '@',
      ignoredUserEmails: '=',
      selectedData: '='
    },
    templateUrl: 'user-selector.html',
    link (scope) {
      scope.searchPromise = undefined
      scope.emailValidationInProgress = false
      scope.emailValidationPromise = undefined
      scope.emailError = false
      scope.showValidation = false
      scope.emailErrorLabel = undefined
      scope.selectedUser = undefined
      scope.selectedData.role = scope.roles[0]

      let getEmailValue = () => {
        let userEmailElement = $('#userEmail_value')[0]
        return (userEmailElement) ? userEmailElement.value : undefined
      }

      let validateEmailInternal = (email) => {
        let defer = $q.defer()
        if (!emailUtils.isValidEmail(email)) {
          defer.reject($filter('i18next')('directives.userPool.emailInvalid'))
        } else {
          smartvidApi.getUserByEmail(email).then((user) => {
            if (user.id && !user.isActive && !user.isInvited) {
              defer.reject($filter('i18next')('directives.userPool.userDeactivated'))
            } else {
              if (scope.validateEmailFn) {
                scope.validateEmailFn()(email).then(() => {
                  defer.resolve()
                }, (error) => {
                  defer.reject(error)
                })
              } else {
                defer.resolve()
              }
            }
          })
        }
        return defer.promise
      }

      let validateEmail = (email) => {
        let defer = $q.defer()
        validateEmailInternal(email).then(() => {
          scope.emailError = false
          scope.emailErrorLabel = undefined
          scope.emailValidationInProgress = false
          defer.resolve()
        }, (error) => {
          scope.emailValidationInProgress = false
          if (email === undefined || email === '') {
            scope.emailError = false
            scope.emailErrorLabel = undefined
            scope.showValidation = false
          } else {
            scope.showValidation = true
            scope.emailError = true
            scope.emailErrorLabel = error
          }
          defer.reject(error)
        })
        return defer.promise
      }

      let filterUsers = (users) => {
        let result = _.reject(users, function (user) {
          return _.contains(getDisabledUsers(scope), user.email) || _.contains(scope.ignoredUserEmails, user.email)
        })
        result = _.uniq(_.sortBy(result, 'email'), true, 'email')
        return result
      }

      let getDisabledUsers = (scope) => {
        let emails = _.pluck(scope.users, 'email')
        emails.push(currentUser.get('email'))
        return emails
      }

      let addUserInternal = (userEmail) => {
        scope.showValidation = false
        return scope.searchPromise.then(() => {
          let user = _.find(scope.currentResults, function (user) {
            return user.email === userEmail
          })
          if (!user) {
            user = ({email: userEmail})
          }
          return user
        })
      }

      scope.checkForReturnKey = (evt) => {
        if (evt.keyCode === 13) {
          evt.preventDefault()
          scope.emailValidationInProgress = false
          scope.emailError = false
          scope.addUser()
        } else {
          scope.resetField()
        }
      }

      scope.setEmailValue = () => {
        scope.selectedData.userEmail = getEmailValue()
      }

      scope.addUser = (email) => {
        let userEmail = email || getEmailValue()
        if (scope.emailError || userEmail === undefined || userEmail === '') {
          scope.selectedData.userPromise = undefined
          scope.selectedData.userEmail = undefined
          return
        }
        scope.selectedData.userPromise = getUser(userEmail)
        scope.selectedData.userEmail = userEmail
      }

      scope.addSelectedUser = (user) => {
        if (user) {
          scope.addUser(user.originalObject.email)
        }
      }

      /**
       * The search handler for the autocomplete control
       * @param queryString
       * @returns promise
       */
      scope.searchUsersByPartialEmail = (queryString) => {
        let defer = $q.defer()
        let searchPromise = scope.searchEmailFn()(queryString)
        let allUsers = []
        searchPromise.then(results => {
          _.each(results, result => {
            if (_.isArray(result)) {
              Array.prototype.push.apply(allUsers, result)
            } else {
              allUsers.push(result)
            }
          })
          $timeout(() => {
            allUsers = filterUsers(allUsers)
            scope.currentResults = allUsers
            defer.resolve({'data': allUsers})
          }, 50)
        })
        scope.searchPromise = defer.promise
        return defer.promise
      }

      scope.label = scope.label || $filter('i18next')('common.people')

      scope.selectedRole = scope.roles[0]

      scope.validateEmail = () => {
        scope.showValidation = true
        scope.emailValidationInProgress = true
        let email = getEmailValue()
        scope.emailValidationPromise = validateEmail(email)
      }

      scope.resetField = () => {
        scope.emailError = false
        scope.emailErrorLabel = undefined
        scope.showValidation = false
      }

      scope.handleDownArrowAutoComplete = () => {
        let angucompleteElement = angular.element('user-selector .angucomplete-holder')
        let angucompleteScope = angucompleteElement.scope()
        if (angucompleteScope.showDropdown) {
          angucompleteScope.showDropdown = false
          return
        }
        let inputField = angucompleteElement.find('input')
        inputField.trigger('mousedown')
        inputField.focus()
        angucompleteScope.showDropdown = true
        angucompleteScope.searching = true
        let inputFieldValue = inputField.val()
        let searchPromise = (inputFieldValue) ? scope.searchEmailFn()(inputFieldValue) : scope.allUsersFn()()
        searchPromise.then(results => {
          let allUsers = []
          _.each(results, result => {
            if (_.isArray(result)) {
              Array.prototype.push.apply(allUsers, result)
            } else {
              allUsers.push(result)
            }
          })
          $timeout(() => {
            allUsers = filterUsers(allUsers)
            scope.currentResults = allUsers
            let angucompleteResults = _.map(allUsers, (user) => {
              return {
                title: user.email,
                description: '',
                image: '',
                originalObject: user
              }
            })
            angucompleteScope.results = angucompleteResults
            angucompleteScope.searching = false
            angucompleteScope.showDropdown = true
          }, 300)
        })
        scope.searchPromise = searchPromise
      }

      function getUser (userEmail) {
        if (scope.emailValidationInProgress) {
          return scope.emailValidationPromise.then(() => {
            return addUserInternal(userEmail)
          })
        } else {
          return validateEmail(userEmail).then(() => {
            return addUserInternal(userEmail)
          })
        }
      }
    }
  }
})
