/* global angular FORGE_INTEGRATION_UI_ENABLED _ */

import { ForgeChecklistSelectedType } from '../../../angular2/modules/integrations/services/forge-component-change.service'

angular.module('smartvid').directive('projectIntegrationsForge', function (
    $rootScope, $timeout, $window, $interval, $filter, $interpolate, $stateParams, $state, utils, smartvidApi,
    Notification, currentUser, moment, errorUtils, IntegrationSettingsModel, ForgeSyncSettingsModel,
    dashboardDataHelper, projectIntegrationUtils, IntegrationSettingsState, ForgeHubCollection, ForgeUserInfoModel,
    ForgeProjectCollection, ForgeHubModel, ForgeProjectModel, forgeComponentChangeService
) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'integrations/project-integrations-forge.html',
    link: function (scope) {
      const FORGE = 'FORGE'
      const FORGE_SYNC = 'FORGE_SYNC'
      const ALL_SELECTED = 'ALL_SELECTED'
      const NONE_SELECTED = 'NONE_SELECTED'
      const PARTIALLY_SELECTED = 'PARTIALLY_SELECTED'
      const IN_PROGRESS_AND_COMPLETED = 'IN_PROGRESS_AND_COMPLETED'
      const IN_PROGRESS = 'IN_PROGRESS'
      const COMPLETED = 'COMPLETED'
      let smartvidProjectId = $stateParams.projectId
      let RATE_LIMTED
      scope.smartvidProjectId = smartvidProjectId
      scope.project = dashboardDataHelper.getCurrentProject()
      scope.integrationSettingsState = new IntegrationSettingsState()
      scope.integrationSettingsState.isLoadingData = true
      scope.hubs
      scope.projects
      scope.forgeUserInfo
      scope.selectedData = {}
      scope.connectionStatus = ''
      scope.hasRateLimitError = false
      scope.folderTreeOptions = {
        nodeChildren: 'folders',
        dirSelectable: true,
        allowDeselect: false,
        isLeaf: (node) => {
          return node.folders !== undefined && node.folders.length === 0
        }
      }
      scope.dateRanges = [
        {
          name: 'None',
          value: 'NONE'
        },
        {
          name: '7 Days',
          value: '7_DAYS'
        },
        {
          name: '30 Days',
          value: '30_DAYS'
        },
        {
          name: '60 Days',
          value: '60_DAYS'
        },
        {
          name: 'All',
          value: 'ALL'
        }
      ]
      scope.selectedData.historicalDataRange = scope.dateRanges[0]
      scope.selectedData.checklistStatusSelectionType = IN_PROGRESS_AND_COMPLETED

      scope.isForgeIntegrationUiEnabled = isForgeIntegrationUiEnabled
      scope.disableForgeIntegration = disableForgeIntegration
      scope.disconnect = disconnect
      scope.isForgeDataComplete = isForgeDataComplete
      scope.isBIM360Project = isBIM360Project
      scope.isPartnerIntegrationEnabledForProject = isPartnerIntegrationEnabledForProject
      scope.reloadForgeProjects = reloadForgeProjects
      scope.loadForgeProjects = loadForgeProjects
      scope.setIntegrationSettingsState = setIntegrationSettingsState
      scope.reloadForgeData = reloadForgeData
      scope.isRefreshing = false
      scope.loadForgeData = loadForgeData
      scope.disableIntegration = disableIntegration
      scope.enableIntegration = enableIntegration
      scope.forgeProjectSelected = forgeProjectSelected
      scope.connect = connect
      scope.onForgeFolderSelected = onForgeFolderSelected
      scope.onForgeChecklistTemplateSelected = onForgeChecklistTemplateSelected
      scope.onForgeIssueTypeSelected = onForgeIssueTypeSelected
      scope.updateCheclkistStatuses = updateCheclkistStatuses
      scope.selectedFolderCountLabel = selectedFolderCountLabel
      scope.selectedTemplatesCountLabel = selectedTemplatesCountLabel
      scope.selectedIssueTypeCountLabel = selectedIssueTypeCountLabel

      scope.isReadOnly =
          _.bind(
              projectIntegrationUtils.isReadOnly,
              undefined,
              scope.integrationSettingsState)

      scope.getNextSyncDate =
          _.bind(
              projectIntegrationUtils.getNextSyncDate,
              undefined,
              scope.integrationSettingsState)

      scope.getLastSyncDate =
          _.bind(
              projectIntegrationUtils.getLastSyncDate,
              undefined,
              scope.integrationSettingsState)

      scope.authenticateWithForgeAndEnableIntegration =
          _.bind(
              projectIntegrationUtils.authenticateWithPartnerAndEnableIntegration,
              undefined,
              scope.integrationSettingsState,
              smartvidProjectId,
              FORGE,
              FORGE_SYNC)
      scope.$on('$destroy', function() {
        cleanup();
      });

      init()

      function forgeProjectSelected () {
        // forgeSelectedFolderFullPath is retained for backward compatibility with the implementation that
        // only allowed a single folder per project vs all the folders
        scope.selectedData.forgeSelectedFolderFullPath = undefined
        scope.selectedData.forgeSelectedFolder = undefined
        scope.selectedData.documentSelectedFolderIdPaths = []
        scope.selectedData.documentFolderSelectedType = ALL_SELECTED
        scope.selectedData.checklistSelectedType = ALL_SELECTED
        scope.selectedData.checklistTemplateIds = []
        scope.selectedData.checklistStatusSelectionType = IN_PROGRESS_AND_COMPLETED
        scope.selectedData.issueTypeIds = []
        scope.selectedData.issueSelectedType = ALL_SELECTED
        scope.integrationSettingsState.partnerIntegrationSettings.settingsData.forgeFolderId = undefined
        saveForgeSyncSettings(() => {
          forgeComponentChangeService.forgeProjectChange.emit({
            forgeProjectId: scope.selectedData.forgeSelectedProject ? scope.selectedData.forgeSelectedProject.id : undefined,
            documentSelectedFolderIdPaths: scope.selectedData.documentSelectedFolderIdPaths,
            documentFolderSelectedType: scope.selectedData.documentFolderSelectedType,
            checklistTemplateIds: scope.selectedData.checklistTemplateIds,
            checklistSelectedType: scope.selectedData.checklistSelectedType,
            issueTypeIds: scope.selectedData.issueTypeIds,
            issueSelectedType: scope.selectedData.issueSelectedType
          })
        })
      }

      function init () {
        scope.integrationSettingsState.setCreatePartnerSyncSettingsFunc((settingsData) => {
          return new ForgeSyncSettingsModel(settingsData)
        })

        if (!scope.project.canUpdate) {
          $state.go('unauthorized')
          return
        }
        resetDefaults()

        $timeout(() => {
          scope.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
          projectIntegrationUtils.checkIntegrationStatusAndLoadData(scope.integrationSettingsState, smartvidProjectId, FORGE, FORGE_SYNC, false)
        }, 10)

        scope.integrationSettingsState.setLoadPageData((integrationSettingsState) => {
          let partnerUserId = projectIntegrationUtils.getPartnerUserId(integrationSettingsState)
          if (partnerUserId) {
            smartvidApi.getForgeUser(smartvidProjectId, customErrorHandler).then((data) => {
              scope.forgeUserInfo = new ForgeUserInfoModel(data)
              adjustConnectionStatus()
              scope.loadForgeData()
            }, scope.loadForgeData)
          } else {
            scope.loadForgeData()
          }
        })

        scope.folderChangeSub = forgeComponentChangeService.folderSelectedChange.subscribe(changeEvent => {
          scope.onForgeFolderSelected(changeEvent)
        })
        scope.templateChangeSub = forgeComponentChangeService.templateSelectedChange.subscribe(changeEvent => {
          scope.onForgeChecklistTemplateSelected(changeEvent)
        })
        scope.issueChangeSub = forgeComponentChangeService.issueTypeSelectedChange.subscribe(changeEvent => {
          scope.onForgeIssueTypeSelected(changeEvent)
        })
      }

      function cleanup() {
        scope.folderChangeSub.unsubscribe()
        scope.templateChangeSub.unsubscribe()
        scope.issueChangeSub.unsubscribe()
      }

      function onForgeFolderSelected (folderChangeEvent) {
        scope.selectedData.documentSelectedFolderIdPaths = folderChangeEvent.documentSelectedFolderIdPaths
        scope.selectedData.documentFolderSelectedType = folderChangeEvent.documentFolderSelectedType
        saveForgeSyncSettings()
      }

      function onForgeChecklistTemplateSelected (templateChangeEvent) {
        scope.selectedData.checklistTemplateIds = templateChangeEvent.checklistTemplateIds
        scope.selectedData.checklistSelectedType = templateChangeEvent.checklistSelectedType
        saveForgeSyncSettings()
      }

      function onForgeIssueTypeSelected (issueChangeEvent) {
        scope.selectedData.issueTypeIds = issueChangeEvent.issueTypeIds
        scope.selectedData.issueSelectedType = issueChangeEvent.issueSelectedType
        saveForgeSyncSettings()
      }

      function updateCheclkistStatuses () {
        saveForgeSyncSettings()
      }

      function selectedFolderCountLabel () {
        switch (scope.selectedData.documentFolderSelectedType) {
          case ALL_SELECTED:
            return $filter('i18next')('integrations.forge.allSelected')
          case NONE_SELECTED:
            return $filter('i18next')('integrations.forge.noneSelected')
          case PARTIALLY_SELECTED:
            var selectedCount = scope.selectedData.documentSelectedFolderIdPaths.length
            return selectedCount + ' ' + $filter('i18next')('integrations.forge.selected')
          default:
            return $filter('i18next')('integrations.forge.allSelected')
        }
      }

      function selectedTemplatesCountLabel () {
        switch (scope.selectedData.checklistSelectedType) {
          case ALL_SELECTED:
            return $filter('i18next')('integrations.forge.allSelected')
          case NONE_SELECTED:
            return $filter('i18next')('integrations.forge.noneSelected')
          case PARTIALLY_SELECTED:
            var selectedCount = scope.selectedData.checklistTemplateIds.length
            return selectedCount + ' ' + $filter('i18next')('integrations.forge.selected')
          default:
            return $filter('i18next')('integrations.forge.allSelected')
        }
      }

      function selectedIssueTypeCountLabel () {
        switch (scope.selectedData.issueSelectedType) {
          case ALL_SELECTED:
            return $filter('i18next')('integrations.forge.allSelected')
          case NONE_SELECTED:
            return $filter('i18next')('integrations.forge.noneSelected')
          case PARTIALLY_SELECTED:
            var selectedCount = scope.selectedData.issueTypeIds.length
            return selectedCount + ' ' + $filter('i18next')('integrations.forge.selected')
          default:
            return $filter('i18next')('integrations.forge.allSelected')
        }
      }

      function resetFolders (hasError) {
        scope.selectedData.forgeSelectedFolderFullPath = undefined
        scope.selectedData.forgeSelectedFolder = undefined
        scope.selectedData.documentSelectedFolderIdPaths = []
        scope.selectedData.documentFolderSelectedType = ALL_SELECTED
      }

      function resetDefaults (hasError) {
        scope.hubs = []
        scope.projects = []
        scope.selectedData = {}
        scope.integrationSettingsState.clear()
        scope.integrationSettingsState.isEnabled = false
        scope.selectedData.historicalDataRange = scope.dateRanges[0]
        scope.selectedData.checklistStatusSelectionType = IN_PROGRESS_AND_COMPLETED
        resetFolders()
        forgeComponentChangeService.forgeProjectChange.emit({
          forgeProjectId: undefined,
          documentSelectedFolderIdPaths: [],
          documentFolderSelectedType: ALL_SELECTED,
          checklistTemplateIds: [],
          checklistSelectedType: ALL_SELECTED,
          issueTypeIds: [],
          issueSelectedType: ALL_SELECTED
        })
      }

      function getHubsPlaceholder (hasError) {
        return new ForgeHubModel({
          id: undefined,
          name: (hasError ? $filter('i18next')('integrations.forge.unavailablePlaceholder') : $filter('i18next')('integrations.forge.hubsPlaceholder'))
        })
      }

      function getProjectsPlaceholder (hasError) {
        return new ForgeProjectModel({
          id: undefined,
          name: (hasError ? $filter('i18next')('integrations.forge.unavailablePlaceholder') : $filter('i18next')('integrations.forge.projectsPlaceholder'))
        })
      }

      function isForgeIntegrationUiEnabled () {
        return FORGE_INTEGRATION_UI_ENABLED
      }

      function disableForgeIntegration () {
        setLoading(true)
        smartvidApi.deleteIntegrationSettings(FORGE_SYNC, smartvidProjectId).then(() => {
          scope.connectionStatus = $filter('i18next')('integrations.connection.not_connected')
          resetDefaults()
          projectIntegrationUtils.checkIntegrationStatusAndLoadData(
              scope.integrationSettingsState, smartvidProjectId, FORGE, FORGE_SYNC, false)
        }, () => {
          resetDefaults()
          setLoading(false)
        })
      }

      function disconnect () {
        if (scope.isPartnerIntegrationEnabledForProject() && !scope.hasRateLimitError) {
          scope.disableForgeIntegration()
        }
      }

      function isForgeDataComplete () {
        return !!scope.selectedData.forgeSelectedProject
      }

      function isBIM360Project () {
        if (scope.selectedData.forgeSelectedProject) {
          return scope.selectedData.forgeSelectedProject.projectType == 'BIM360'
        }
        return null
      }

      function enableIntegration () {
        scope.integrationSettingsState.partnerIntegrationSettings.isEnabled = true
        scope.integrationSettingsState.syncOnlyAfterDate = Date.now()
        saveForgeSyncSettings()
      }

      function disableIntegration () {
        scope.integrationSettingsState.partnerIntegrationSettings.isEnabled = false
        saveForgeSyncSettings()
      }

      function adjustConnectionStatus () {
        if (scope.integrationSettingsState.isPartnerIntegrationEnabledByCurrentUser && !scope.hasRateLimitError) {
          let forgeUserName = scope.forgeUserInfo ? (scope.forgeUserInfo.firstName + ' ' + scope.forgeUserInfo.lastName) : ''
          scope.connectionStatus = $interpolate($filter('i18next')('integrations.forge.connection.connectedAsUser'))({
            forgeUserName: forgeUserName
          })
        } else if (scope.integrationSettingsState.isPartnerIntegrationEnabledByOtherUser) {
          scope.connectionStatus = $interpolate($filter('i18next')('integrations.connection.connectedAsSmartvidUser'))({
            smartvidUser: ''
          })
        } else if (scope.hasRateLimitError) {
          // TODO(vgorsky) Rate limit error handling
        }
      }

      function saveForgeSyncSettings (afterSaveFunc) {
        scope.setIntegrationSettingsState()
        smartvidApi.saveIntegrationSettings(FORGE_SYNC, scope.integrationSettingsState.partnerIntegrationSettings).then(() => {
          if (afterSaveFunc) {
            afterSaveFunc()
          }
        }, () => {
          if (scope.integrationSettingsState) {
            scope.integrationSettingsState.partnerIntegrationSettings.isEnabled = false
          }
        })
      }

      function isPartnerIntegrationEnabledForProject () {
        return projectIntegrationUtils.isPartnerIntegrationEnabledForProject(scope.integrationSettingsState)
      }

      function loadFullSelectedFolderPath () {
        if (!scope.selectedData.forgeSelectedFolder) {
          return
        }
        setLoading(true)
        smartvidApi.getForgeFolderInfo(smartvidProjectId, scope.selectedData.forgeSelectedFolder.id, customErrorHandler).then((data) => {
          scope.selectedData.forgeSelectedFolderFullPath = data.fullPath
          setLoading(false)
        }, () => {
          setLoading(false)
        })
      }

      function reloadForgeProjects (doneCallback, failedCallback) {
        scope.projects = []
        scope.selectedData.forgeSelectedProject = undefined
        scope.selectedData.forgeSelectedFolder = undefined
        scope.integrationSettingsState.partnerIntegrationSettings.settingsData.forgeProjectId = undefined
        resetFolders()
        setLoading(true)
        scope.loadForgeProjects(doneCallback, failedCallback)
      }

      function setLoading (value) {
        scope.integrationSettingsState.isLoadingData = value
        utils.digest(scope)
      }

      function loadForgeProjects (doneCallback, failedCallback) {
        scope.projects = []
        if (!scope.selectedData.forgeSelectedHub || !scope.selectedData.forgeSelectedHub.id) {
          setLoading(false)
          if (doneCallback) {
            doneCallback()
          }
          return
        }
        setLoading(true)
        smartvidApi.getForgeProjects(smartvidProjectId, scope.selectedData.forgeSelectedHub.id, customErrorHandler).then((data) => {
          scope.projects = new ForgeProjectCollection(data)
          scope.projects.models.splice(0, 0, getProjectsPlaceholder())

          if (scope.integrationSettingsState.partnerIntegrationSettings &&
            scope.integrationSettingsState.partnerIntegrationSettings.settingsData &&
            scope.integrationSettingsState.partnerIntegrationSettings.settingsData.forgeProjectId) {
            scope.selectedData.forgeSelectedProject = _.find(scope.projects.models, (p) => {
              return p.id === scope.integrationSettingsState.partnerIntegrationSettings.settingsData.forgeProjectId
            })
            if (!scope.selectedData.forgeSelectedProject) {
              setLoading(false)
            }
            if (doneCallback) {
              doneCallback()
            } else {
              setLoading(false)
            }
          } else {
            setLoading(false)
            if (doneCallback) {
              doneCallback()
            }
          }
        }, () => {
          setLoading(false)
          if (failedCallback) {
            failedCallback()
          }
        })
      }

      function setIntegrationSettingsState () {
        const settingsData = scope.integrationSettingsState.partnerIntegrationSettings.settingsData;
        if (scope.selectedData.forgeSelectedHub && scope.selectedData.forgeSelectedHub.id) {
          settingsData.forgeHubId = scope.selectedData.forgeSelectedHub.id
        }
        if (scope.selectedData.forgeSelectedProject && scope.selectedData.forgeSelectedProject.id) {
          settingsData.forgeProjectId = scope.selectedData.forgeSelectedProject.id
        }
        if (scope.integrationSettingsState.partnerIntegrationSettings) {
          setHistoricalDataSyncThresholdDate(settingsData, scope.selectedData.historicalDataRange)
        }
        if (scope.selectedData.documentFolderSelectedType) {
          settingsData.documentSelectedFolderIdPaths = scope.selectedData.documentSelectedFolderIdPaths
          settingsData.documentFolderSelectedType = scope.selectedData.documentFolderSelectedType
        } else {
          settingsData.documentSelectedFolderIdPaths = []
          settingsData.documentFolderSelectedType = ALL_SELECTED
        }
        if (scope.selectedData.checklistSelectedType) {
          settingsData.checklistTemplateIds = scope.selectedData.checklistTemplateIds
          settingsData.checklistSelectedType = scope.selectedData.checklistSelectedType
        } else {
          settingsData.checklistTemplateIds = []
          settingsData.checklistSelectedType = ALL_SELECTED
        }
        if (scope.selectedData.checklistStatusSelectionType) {
          settingsData.checklistStatuses = fromTypeToChecklistStatuses(scope.selectedData.checklistStatusSelectionType)
        }
        if (scope.selectedData.issueSelectedType) {
          settingsData.issueTypeIds = scope.selectedData.issueTypeIds
          settingsData.issueSelectedType = scope.selectedData.issueSelectedType
        } else {
          settingsData.issueTypeIds = []
          settingsData.issueSelectedType = ALL_SELECTED
        }
      }

      function reloadForgeData () {
        scope.setIntegrationSettingsState()
        scope.loadForgeData()
      }

      function loadForgeData () {
        setLoading(true)
        smartvidApi.getForgeHubs(smartvidProjectId, customErrorHandler).then((data) => {
          scope.hubs = new ForgeHubCollection(data)
          scope.hubs.models.splice(0, 0, getHubsPlaceholder())

          if (scope.integrationSettingsState.partnerIntegrationSettings &&
            scope.integrationSettingsState.partnerIntegrationSettings.settingsData &&
            scope.integrationSettingsState.partnerIntegrationSettings.settingsData.forgeHubId) {

            scope.selectedData.historicalDataRange = _.find(scope.dateRanges, (dr) => {
              return dr.value === scope.integrationSettingsState.partnerIntegrationSettings.settingsData.historicalDataSyncDateRange
            }) || scope.dateRanges[0]

            scope.selectedData.forgeSelectedHub = _.find(scope.hubs.models, (h) => {
              return h.id === scope.integrationSettingsState.partnerIntegrationSettings.settingsData.forgeHubId
            })
            var selectedFolder
            // Keep the following for backward compatibility, when only one folder per project was supported
            if (scope.integrationSettingsState.partnerIntegrationSettings.settingsData.forgeFolderId) {
              selectedFolder = {
                'id': scope.integrationSettingsState.partnerIntegrationSettings.settingsData.forgeFolderId
              }
            }
            scope.loadForgeProjects(() => {
              $timeout(() => {
                var storedSettingsData = scope.integrationSettingsState.partnerIntegrationSettings.settingsData
                scope.selectedData.documentSelectedFolderIdPaths = storedSettingsData.documentSelectedFolderIdPaths
                scope.selectedData.documentFolderSelectedType = storedSettingsData.documentFolderSelectedType
                scope.selectedData.checklistStatusSelectionType = fromChecklistStatusesToType(storedSettingsData.checklistStatuses)
                scope.selectedData.checklistSelectedType = storedSettingsData.checklistSelectedType
                scope.selectedData.checklistTemplateIds = storedSettingsData.checklistTemplateIds
                scope.selectedData.issueTypeIds = storedSettingsData.issueTypeIds
                scope.selectedData.issueSelectedType = storedSettingsData.issueSelectedType
                forgeComponentChangeService.forgeProjectChange.emit({
                    forgeProjectId: scope.selectedData.forgeSelectedProject.id,
                    documentSelectedFolderIdPaths: scope.selectedData.documentSelectedFolderIdPaths,
                    documentFolderSelectedType: scope.selectedData.documentFolderSelectedType,
                    checklistTemplateIds: scope.selectedData.checklistTemplateIds,
                    checklistSelectedType: scope.selectedData.checklistSelectedType,
                    issueTypeIds: scope.selectedData.issueTypeIds,
                    issueSelectedType: scope.selectedData.issueSelectedType
                  })
              }, 1)

              if (selectedFolder) {
                scope.selectedData.forgeSelectedFolder = selectedFolder
                loadFullSelectedFolderPath()
              } else {
                setLoading(false)
              }
            })
          } else {
            setLoading(false)
          }
        }, () => {
          setLoading(false)
        })
      }

      function fromChecklistStatusesToType (checklistStatuses) {
        if(checklistStatuses.length === 1 && _.contains(checklistStatuses, 'IN_PROGRESS')) {
          return IN_PROGRESS
        } else if(checklistStatuses.length === 1 && _.contains(checklistStatuses, 'COMPLETED')) {
          return COMPLETED
        } else {
          return IN_PROGRESS_AND_COMPLETED
        }
      }

      function fromTypeToChecklistStatuses (checklistStatusSelectionType) {
        if(checklistStatusSelectionType === IN_PROGRESS) {
          return ['IN_PROGRESS']
        } else if(checklistStatusSelectionType === COMPLETED) {
          return ['COMPLETED']
        } else {
          return ['IN_PROGRESS', 'COMPLETED']
        }
      }

      function customErrorHandler (response) {
        if (errorUtils.isRateLimit(response)) {
          scope.hasRateLimitError = true
          resetDefaults(scope.hasRateLimitError)
          // adjustConnectionStatus()
          scope.connectionStatus = RATE_LIMTED
          return true
        }
        Notification.error($filter('i18next')('integrations.forge.errors.failedToAccessForgeData'))
        return false
      }

      function connect () {
        if (scope.hasRateLimitError) {
          return
        }
        if (projectIntegrationUtils.isAutoConnect(scope.integrationSettingsState)) {
          projectIntegrationUtils.enableIntegration(
            scope.integrationSettingsState, smartvidProjectId, FORGE, FORGE_SYNC)
        } else {
          scope.authenticateWithForgeAndEnableIntegration()
        }
      }

      function setHistoricalDataSyncThresholdDate (settingsData, historicalDataRange) {
        if (!historicalDataRange || historicalDataRange.value === 'ALL') {
          settingsData.historicalDataSyncThresholdDate = null
          if (historicalDataRange) {
            settingsData.historicalDataSyncDateRange = historicalDataRange.value
          }
        } else {
          switch (historicalDataRange.value) {
            case '7_DAYS':
              settingsData.historicalDataSyncThresholdDate = moment().subtract(7, 'days').startOf('day').toDate()
              settingsData.historicalDataSyncDateRange = historicalDataRange.value
              break
            case '30_DAYS':
              settingsData.historicalDataSyncThresholdDate = moment().subtract(30, 'days').startOf('day').toDate()
              settingsData.historicalDataSyncDateRange = historicalDataRange.value
              break
            case '60_DAYS':
              settingsData.historicalDataSyncThresholdDate = moment().subtract(60, 'days').startOf('day').toDate()
              settingsData.historicalDataSyncDateRange = historicalDataRange.value
              break
            case 'NONE':
              settingsData.historicalDataSyncThresholdDate = moment().toDate()
              settingsData.historicalDataSyncDateRange = historicalDataRange.value
              break
          }
        }
      }
    }
  }
})
