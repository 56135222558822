import { Component } from '@angular/core'
import { BaseChartDirective } from 'modules/insights/dashboard/charts/models/base-chart.type'
import { LINE_CHART_OPTIONS } from 'modules/insights/dashboard/charts/models/chart-static-options.type'

@Component({
  selector: 'sv-insights-line-chart',
  templateUrl: 'insights-line-chart.component.html',
  styleUrls: ['insights-line-chart.component.scss'],
})
export class InsightsLineChartComponent extends BaseChartDirective {
  getChartType(): string {
    return 'LineChart'
  }

  getPredefinedChartOptions(): {} {
    return LINE_CHART_OPTIONS
  }
}
