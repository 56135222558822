/* global angular, _ */

angular.module('smartvid').directive('comments', function (MAX_COMMENT_THREAD_DESCRIPTION_LENGTH, videoPlayer, $rootScope, currentUser, smartvidApi, $stateParams, CommentModel, $timeout, utils) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'AssetViewer/comments.html',
    link (scope) {
      scope.MAX_COMMENT_THREAD_DESCRIPTION_LENGTH = MAX_COMMENT_THREAD_DESCRIPTION_LENGTH

      _.each(scope.comments.models, (comment) => {
        comment.isCollapsed = $rootScope.isMobile
      })

      scope.handleCommentClick = (comment) => {
        _.each(scope.comments.models, (cc) => {
          if (cc.id !== comment.id) {
            cc.isCollapsed = true
          }
        })

        comment.isCollapsed = !comment.isCollapsed

        $rootScope.$broadcast('sv-unselect-all-tags-and-groups')

        $timeout(function () {
          scope.forceOverflowUpdate = (new Date()).getTime()
        }, 350)

        if (!comment.selected) {
          comment.selected = true
          $rootScope.$broadcast('sv-comment-selected')
          videoPlayer.pause()
          videoPlayer.currentTime(comment.startTime / 1000)
          return
        }
      }
    }
  }
})
