import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { isNA } from '../../../utils/dashboard-utils'

@Component({
  selector: 'sv-insights-safety-gauge-chart',
  templateUrl: 'insights-safety-gauge-chart.component.html',
  styleUrls: ['insights-safety-gauge-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsSafetyGaugeChartComponent {
  @Input() currentValue: number

  isNA = isNA
}
