 <div class="project-tree-container">

     <mat-form-field class="search-form-field" appearance = "standard">
         <input matInput type="search" placeholder="{{ 'directives.projectList.search-projects.placeholder' | translate }}" [ngModel]="searchValue" (ngModelChange)="filterNodes($event)">
         <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="filterNodes()">
             <mat-icon>close</mat-icon>
         </button>
     </mat-form-field>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

    <mat-tree-node
            class="mat-tree-node"
            [ngClass]="{'mat-tree-node-selection-level': selectedNode === node}"
            (click)="selectedNode = node; goToItems()"
            matTooltip="{{node?.name }}"
            matTooltipPosition='right'
            matTooltipClass="project-tooltip"
            *matTreeNodeDef="let node; when: isObsProjectLevel" matTreeNodeToggle matTreeNodePadding [matTreeNodePaddingIndent]="12">

        <span class="node-name-icon" >
            <mat-icon *ngIf="isGivenProjectInactive(node?.id)" svgIcon="work_off" class="svg"></mat-icon>
            <mat-icon *ngIf="!isGivenProjectInactive(node?.id)">folder</mat-icon>
        </span>
        <span class="node-name-span"  >{{node.name}}</span>


    </mat-tree-node>
      <mat-tree-node id="{{'node-'+node?.id}}"  *matTreeNodeDef="let node; when: isAssetProjectLevel" (click)="selectedNode = node; goToItems()">

          <ul class="nav-menu-item-list">
              <sv-project-item-menu
                      [currentProject]="getProjectById(node?.id)"
                      [(projects)]="projectsCollection"
                      class="project-menu-item"
                      (favorited)="onFavorite()"
                      id="project-{{node?.id }}"
              ></sv-project-item-menu>
          </ul>

      </mat-tree-node>

    <mat-tree-node
            id="{{'parent-node-' + node?.id}}"
            class="mat-tree-node"
            [ngClass]="{'mat-tree-node-selection-level': selectedNode === node}"
            matTooltip="{{node?.name }}"
            matTooltipPosition='right'
            matTooltipClass="project-tooltip"
            *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="12">

      <button mat-icon-button matTreeNodeToggle (click)="selectedNode = node; goToItems()" class="parent-mat-button">
        <mat-icon class="mat-icon-rtl-mirror" [ngClass]="{ 'closed-button' : !treeControl.isExpanded(node)}">
          arrow_drop_down
        </mat-icon>
        <span>
            <mat-icon *ngIf="node.type === TreeNodeType.PROJECT_GROUP">inbox</mat-icon>
            <mat-icon *ngIf="node.type === TreeNodeType.ORGANIZATION" svgIcon="all_inbox"></mat-icon>
        </span>
        <span class="node-name-span">{{node?.name}}</span>
      </button>
    </mat-tree-node>
  </mat-tree>
</div>
