import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

@NgModule()
export class SmartvidSvgModule {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'all_inbox',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/baseline-all_inbox-24px.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'add_observation_btn',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/add-observation-icon.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'filter-glyph',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/filter-glyph.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'observation_severity_LOW',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Risk_Score_Calculation_Icon-1.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'observation_severity_MEDIUM',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Risk_Score_Calculation_Icon-2.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'observation_severity_HIGH',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Risk_Score_Calculation_Icon-3.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'observation_severity_CRITICAL',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Risk_Score_Calculation_Icon-4.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'observation_severity_LOSS_OF_LIFE',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Risk_Score_Calculation_Icon-5.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'observation_frequency_UNLIKELY',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Risk_Score_Calculation_Icon-1.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'observation_frequency_ANNUALLY',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Risk_Score_Calculation_Icon-2.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'observation_frequency_MONTHLY',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Risk_Score_Calculation_Icon-3.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'observation_frequency_WEEKLY',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Risk_Score_Calculation_Icon-4.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'observation_frequency_DAILY',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Risk_Score_Calculation_Icon-5.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'score_positive',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Score-Positive.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'score_unknown',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Score-score_unknown.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'status_observed',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Status_Icon-Observed.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'status_closed',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Status_Icon-Closed.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'status_in_progress',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Status_Icon-In_Progress.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'status_new',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Status_Icon-New.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'status_delayed',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Status_Icon-Delayed.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'status_with_partner',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Status_Icon-With_Partner.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'status_scheduled',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Status_Icon-Scheduled.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'empty_observation_list',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/empty-observation-list.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'asset_image',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/icon-asset-image.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'asset_video',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/icon-asset-video.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'sort_dropdown_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/sort-dropdown-icon.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'status_closed_reviewed',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Status_Icon-Closed.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'status_ready_to_review',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/Status_Icon-Closed.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'chevron_left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/chevron_left.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'chevron_right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/chevron_right.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'work_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/work_off-24px.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'icon-reports',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/icon-reports.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'photo-reports',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/photo-reports.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'summary-reports',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/summary-reports.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'icon-settings-dropdown',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/icon-settings-dropdown.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'icon-photo-report-success',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/icon-photo-report-success.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'icon-newmetrix-logo-dark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/newmetrix_logo_dark.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'icon-newmetrix-logo-light',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/newmetrix_logo_light.svg')
    )
  }
}
