/* global angular SRA_UI_ENABLED */

angular.module('smartvid').directive('deletedProjectsGridControlbar', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'DeletedProjectsGrid/controlbar.html',
    link: function (scope) {

      scope.showOptions = ($event) => {
        scope.flyout.open('deletedProjectsGridOptions', {
          parentElement: $event.currentTarget,
          direction: 'top',
          projects: scope.projects
        })
      }

    }
  }
})
