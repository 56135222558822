/* global angular, _ */
angular.module('smartvid').factory('AssetGridGroupParser', function (DefaultGroupParser, GroupMetadata) {
  class AssetGridGroupParser extends DefaultGroupParser {
    parse (items) {
      if (_.isEmpty(items) || !_.isFunction(this.comparetorFn)) { return [] }
      var groups = []
      var prevGroup = this.comparetorFn(items[0])
      var currGroup
      var currItemCount = 1
      var videoCount = 0
      var imageCount = 0
      var mergeGroups = (sourceGroup, destinationGroup) => {
        var getByField = (arr, field) => {
          let found = arr.find(el => el[field])
          return found ? found[field] : undefined
        }

        function getCountsForField (objects, fieldName) {
          return !objects[0][fieldName] && !objects[1][fieldName] ? undefined : (getByField(objects, fieldName) ? getByField(objects, fieldName) : _.sum(objects, fieldName))
        }

        var result = _.chain([sourceGroup, destinationGroup])
          .groupBy('text')
          .map(function (objects, text) {
            return new GroupMetadata({
              groupHeader: true,
              text: text,
              parsedSize: _.sum(objects, 'parsedSize'),
              parsedImageCount: getCountsForField(objects, 'parsedImageCount'),
              parsedVideoCount: getCountsForField(objects, 'parsedVideoCount'),
              recentUploadsGroup: objects[0]['recentUploadsGroup'] || objects[1]['recentUploadsGroup'] || undefined
            })
          })
          .value()[0]
        return result
      }
      if (items[0].type === 'VIDEO') { videoCount++ } else { imageCount++ }
      var inRecentUploadsGroup = !!items[0].recentUpload

      for (var i = 1; i < items.length; ++i) {
        currGroup = this.comparetorFn(items[i])
        if (prevGroup !== currGroup && !groups.find((g) => g.text === prevGroup)) {
          groups.push(new GroupMetadata({
            groupHeader: true,
            text: String(prevGroup),
            parsedSize: currItemCount,
            parsedImageCount: imageCount,
            parsedVideoCount: videoCount,
            recentUploadsGroup: (inRecentUploadsGroup) ? true : undefined
          }))
          inRecentUploadsGroup = false
          videoCount = (items[i].type === 'VIDEO') ? 1 : 0
          imageCount = (items[i].type === 'VIDEO') ? 0 : 1
          currItemCount = 1
        } else {
          if (items[i].type === 'VIDEO') { videoCount++ } else { imageCount++ }
          inRecentUploadsGroup = !!items[i].recentUpload
          currItemCount++
        }
        prevGroup = currGroup
      }
      let lastGroupMetadata = new GroupMetadata({
        groupHeader: true,
        text: String(prevGroup),
        parsedSize: currItemCount,
        parsedImageCount: (inRecentUploadsGroup) ? imageCount : undefined,
        parsedVideoCount: (inRecentUploadsGroup) ? videoCount : undefined,
        recentUploadsGroup: (inRecentUploadsGroup) ? true : undefined
      })
      let groupExist = groups.find((g) => g.text === prevGroup)
      if (groupExist) {
        let result = mergeGroups(groupExist, lastGroupMetadata)
        let index = groups.indexOf(groupExist)
        if (index !== -1) { groups[index] = result }
      } else {
        groups.push(lastGroupMetadata) // push last group
      }
      return groups
    }
  }

  return AssetGridGroupParser
})
