/* global angular, _, $, jQuery */

angular.module('smartvid').directive('tagInstanceAccumulator', function (
  MAX_TAG_DEF_TEXT_LENGTH, TAG_DEF_LIMIT, TagDefModel, smartvidApi, $q, $log, $timeout, $document, $window,
  AngucompleteModel, TagDefCollection, dashboardDataHelper, TagNodesCollection, $state
) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: 'tag-instance-accumulator.html',
    scope: {
      project: '=?',
      addTagHandler: '=',
      createTagHandler: '=?',
      allowAsr: '=?',
      allowImrec: '=?',
      hideIcon: '=?',
      supportNewTags: '=?',
      focusIn: '=?',
      focusOut: '=?',
      disabled: '@?',
      defaultText: '@',
      value: '=?',
      inputChanged: '=?',
      textTooltipPosition: '=?',
      showLongTagNotFound: '@?',
      ignoredTagTexts: '=',
      clearAfterAddTag: '=?'
    },
    link (scope, el) {
      let tagTrees = null
      let tagDefs = null
      if (dashboardDataHelper.getCurrentProjectTagTree()) {
        tagTrees = [dashboardDataHelper.getCurrentProjectTagTree()]
        tagDefs = [dashboardDataHelper.getCurrentProjectTags()]
      } else if (scope.project && _.isArray(scope.project)) {
        tagDefs = []
        tagTrees = []
        angular.forEach(scope.project, (p) => {
          let projectOrg = _.first(dashboardDataHelper.getAllOrganizations().where({id: p.organizationId}))
          let tTree = new TagNodesCollection(projectOrg, p)
          tTree.init()
          tagTrees.push(tTree)
          let tDefs = tTree.getTagDefs()
          tagDefs.push(tDefs)
        })
      } else {
        let currentProject = scope.project ? scope.project : dashboardDataHelper.getCurrentProject()
        if (!currentProject) {
          let targetAssets = dashboardDataHelper.getTargetAssets($state.params)
          let selectedAssets = targetAssets.where({selected: true})
          if (selectedAssets.length > 0) {
            let selectedAsset = selectedAssets[0]
            const projectId = selectedAsset.projectId
            currentProject = dashboardDataHelper.getProjectByProjectId(projectId)
          }
        }
        let projectOrganization = _.first(dashboardDataHelper.getAllOrganizations().where({id: currentProject.organizationId}))
        tagTrees = [new TagNodesCollection(projectOrganization, currentProject)]
        tagTrees[0].init()
        tagDefs = tagTrees[0].getTagDefs()
      }

      scope.clearAfterAddTag = scope.clearAfterAddTag === undefined ? true : scope.clearAfterAddTag

      scope.TAG_DEF_LIMIT = TAG_DEF_LIMIT
      scope.MAX_TAG_DEF_TEXT_LENGTH = MAX_TAG_DEF_TEXT_LENGTH
      scope.allowAsr = angular.isDefined(scope.allowAsr) || false
      scope.allowImrec = angular.isDefined(scope.allowImrec) || false
      scope.showLongTagNotFound = !!scope.showLongTagNotFound || false
      scope.messagePresent = false
      scope.messages = {
        showLoading: false,
        tagSearchFocused: false
      }
      scope.searchInputFocused = false
      if (scope.supportNewTags === undefined) {
        scope.supportNewTags = true
      }

      scope.focusHandler = focusHandler
      scope.checkForReturnKey = checkForReturnKey
      scope.blurHandler = blurHandler
      scope.searchTagDefs = searchTagDefs
      scope.toggleTagList = toggleTagList
      scope.createTag = createTag
      scope.onSelected = onSelected

      function focusHandler (event) {
        scope.searchInputFocused = true
        if (scope.focusIn) { scope.focusIn() }
      }

      function checkForReturnKey (evt) {
        if (evt.keyCode === 13) {
          evt.preventDefault()
        }
      }

      function blurHandler (event) {
        if (downArrowMouseDown) {
          downArrowMouseDown = false
          searchInput.focus()
          return
        }
        scope.searchInputFocused = false
        hideMessages()
        if (searchInput.val() !== scope.angucompleteScope.searchStr) {
          scope.angucompleteScope.searchStr = searchInput.val()
        }
        if (scope.focusOut) { scope.focusOut() }
      }

      function reduceDuplicates (a, b) {
        function safe (str) {
          return (str || '').toUpperCase()
        }
        if (a && !_.isEmpty(a) && b) {
          b = _.reject(a, (a_) => {
            return _.filter(b, (b_) => {
              return safe(b_.text) === safe(a_.text)
            })
          })
        }
        return b
      }

      function searchTagDefs (text) {
        let defer = $q.defer()
        if (!tagDefs) {
          return $q.resolve({'data': []})
        }
        if (angular.isArray(tagDefs)) {
          let allTagDefs = []
          angular.forEach(tagDefs, (tagDef) => {
            tagDef.loadingPromise.then(() => {
              let foundTags = tagDef.findTagsByPartialText(text)
              if (foundTags.length === 0) {
                showMessage('tagSearchFocused')
              }
              foundTags = filterTags(scope.ignoredTagTexts, foundTags)
              foundTags = reduceDuplicates(allTagDefs, foundTags)
              allTagDefs.push(...foundTags)
              defer.resolve({'data': allTagDefs})
            }, () => {
              $log.error('Failure to search tag definitions ', tagDef)
              defer.resolve({'data': []})
            })
          })
        } else {
          tagDefs.loadingPromise.then(() => {
            let foundTags = tagDefs.findTagsByPartialText(text)
            if (foundTags.length === 0) {
              showMessage('tagSearchFocused')
            }
            foundTags = filterTags(scope.ignoredTagTexts, foundTags)
            defer.resolve({'data': foundTags})
          }, () => {
            $log.error('Failure to search tag definitions ', tagDefs)
            defer.resolve({'data': []})
          })
        }
        return defer.promise
      }

      function filterTags (ignoredTagTexts, searchResults) {
        if (ignoredTagTexts && !_.isEmpty(ignoredTagTexts) && searchResults) {
          searchResults = _.reject(searchResults, (tag) => {
            return angular.isDefined(tag) && _.contains(ignoredTagTexts, tag.text)
          })
        }
        return searchResults
      }

      function toggleTagList (event) {
        // stop mousedown event propagation
        if (event) {
          event.stopPropagation()
          event.preventDefault()
        }

        // if the dropdown is showing then hide it
        let angucompleteScope = scope.angucompleteScope
        if (angucompleteScope.showDropdown) {
          angucompleteScope.showDropdown = false
          roundBottomCorners()
          return
        }

        // show dropdown
        if (!scope.searchInputFocused) {
          searchInput.focus()
        } else {
          downArrowMouseDown = true // tells the blurHandler to not blur because the input field needs to stay focused
        }
        if (searchInput && searchInput.val() !== '') {
          triggerAngucompleteSearch(searchInput)
        } else {
          showMessage('showLoading')
          if (tagTrees) {
            let allProjectsTags = []
            let projectTags = []
            angular.forEach(tagTrees, (tagTree) => {
              let tagDefsLocal = tagTree.getTagDefinitionsInOrder()
              tagDefsLocal = filterTags(scope.ignoredTagTexts, tagDefsLocal)
              allProjectsTags.push(...tagDefsLocal)
              projectTags = _.map(allProjectsTags, transformIntoAngucompleteListObj)
            })
            if (!_.isEmpty(projectTags)) {
              showResults(projectTags, angucompleteScope)
            } else {
              hideMessages()
            }

          } else {
            showMessage('tagSearchFocused')
          }
        }
      }

      function createTag () {
        let tagText = $(searchInput).val()
        if (tagText !== '') {
          searchTagDefs(tagText).then((results) => {
            if (results.data && results.data.length) {
              let match = _.find(results.data, (tag) => tag.text.toUpperCase() === tagText.toUpperCase())
              if (match) {
                scope.createTagHandler(match)
              } else {
                scope.createTagHandler(tagText)
              }
            }
          }, () => {
            scope.createTagHandler(tagText)
          })
        }

        if (scope.clearAfterAddTag) {
          scope.$broadcast('angucomplete-alt:clearInput')
        }
        roundBottomCorners()
      }

      function onSelected (event) {
        if (!event) { return }
        let tag = event.originalObject
        let results = getResults()

        if (!(tag instanceof TagDefModel) && results.length > 0) {
          tag = _.first(results)
        }

        scope.addTagHandler(tag)
        roundBottomCorners()
      }

      scope.$on('$destroy', function onTagInstanceAccumulatorDestroy () {
        unbindEventHandlers()
      })

      //
      // //////////////////////////*** PRIVATE FUNCTIONS/VARIABLES ***\\\\\\\\\\\\\\\\\\\\\\\\\\\\
      //
      var searchInput // this is the input field
      var downArrowMouseDown = false

      function init () {
        $timeout(() => { // Make sure DOM is loaded
          searchInput = angular.element(el).find('input')
          scope.angucompleteScope = searchInput ? searchInput.scope() : undefined
          bindEventHandlers()
        })
      }

      function bindEventHandlers () {
        angular.element($window).on('click.outside.tag.instance.accumulator', onClickOutsideList)
        angular.element(searchInput).on('keyup.tag.instance.accumulator', keyupHandler)
        searchInput.on('focus.input.tag.instance.accumulator', scope.focusHandler)
        searchInput.on('blur.input.tag.instance.accumulator', scope.blurHandler)
        el.on('click', trapClickBubbleAtDirectiveExterior)
      }

      function unbindEventHandlers () {
        angular.element($window).off('click.outside.tag.instance.accumulator', onClickOutsideList)
        angular.element(searchInput).off('keyup.tag.instance.accumulator', keyupHandler)
        searchInput.off('focus.input.tag.instance.accumulator', scope.focusHandler)
        searchInput.off('blur.input.tag.instance.accumulator', scope.blurHandler)
        el.off('click', trapClickBubbleAtDirectiveExterior)
      }

      function trapClickBubbleAtDirectiveExterior (event) {
        if (event && event.originalEvent) {
          event.originalEvent.tag_instance_accumulator_click = true
        }
      }

      function keyupHandler (e) {
        if ($(e.currentTarget).val() !== '') {
          if (e.originalEvent && e.originalEvent.code === 'Enter') {
            scope.createTag()
            roundBottomCorners()
            hideMessages()
          } else {
            unroundBottomCorners()
          }
        } else {
          roundBottomCorners()
          hideMessages()
        }
      }

      function showResults (results, angucompleteScope) {
        scope.$evalAsync(function showAngucompleteResults () {
          hideMessages()
          angucompleteScope.results = results
          angucompleteScope.searching = false
          angucompleteScope.showDropdown = true
        })
      }

      function showMessage (messageName) {
        scope.$evalAsync(() => {
          scope.messagePresent = true
          unroundBottomCorners()
          if (!scope.messages[messageName]) {
            for (let key in scope.messages) {
              scope.messages[key] = key === messageName
            }
          }
        })
      }

      function hideMessages () {
        if (scope.messagePresent) {
          scope.$evalAsync(() => {
            scope.messagePresent = false
            for (let key in scope.messages) {
              scope.messages[key] = false
            }
          })
        }
      }

      function roundBottomCorners () {
        if ($(searchInput).hasClass('bottom-corners-not-round')) {
          $(searchInput).removeClass('bottom-corners-not-round')
        }
      }

      function unroundBottomCorners () {
        if (!$(searchInput).hasClass('bottom-corners-not-round')) {
          $(searchInput).addClass('bottom-corners-not-round')
        }
      }

      function getResults () {
        let $dropdown = el.find('#tagInstanceAccumulator_dropdown')
        let results = $dropdown.scope().results
        let models = _.pluck(results, 'originalObject')
        return models // [TagDefModel, ...]
      }

      function transformIntoAngucompleteListObj (item) {
        if (item instanceof AngucompleteModel) {
          return item
        }

        return new AngucompleteModel({
          title: (item && item.text) || '',
          originalObject: item
        })
      }

      function onClickOutsideList (event) {
        if (!event.originalEvent) {
          return
        }
        if (!event.originalEvent.tag_instance_accumulator_click) {
          hideMessages()
          roundBottomCorners()
        }
      }

      function triggerAngucompleteSearch (angucompleteInputElement) {
        triggerArrowKeyDownUpForElem(angucompleteInputElement)
      }

      function triggerArrowKeyDownUpForElem (element) {
        element.focus()
        $timeout(() => {
          var keydownEvent = jQuery.Event('keydown')
          keydownEvent.keyCode = 50
          element.trigger(keydownEvent)

          var keyupEvent = jQuery.Event('keyup')
          keyupEvent.keyCode = 50
          element.trigger(keyupEvent)
        })
      }

      // ///////////////////////// INIT \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
      init()
    }
  }
})
