/* global angular */

angular.module('smartvid').controller('ReportingScheduleUnsubscribeCtrl', function ($scope, $filter, $q, $state,
                                                                                    $timeout, $stateParams, modal,
                                                                                    SafetySummaryReportScheduleDataModel,
                                                                                    PhotoReportScheduleDataModel,
                                                                                    FilesInfoReportScheduleDataModel,
                                                                                    smartvidApi, Notification, moment) {
  $scope.isLoading = true
  $scope.reportScheduleHandlingStrategy = undefined

  const REPORT_SCHEDULE_HANDLING_STRATEGIES = {
    'SAFETY_SUMMARY_REPORT': {
      'templateUrl': 'modals/safety-summary-report-unsubscribe-confirmation.html',
      'createModelObject': (response) => {
        return new SafetySummaryReportScheduleDataModel(response)
      }
    },
    'PHOTO_REPORT': {
      'templateUrl': 'modals/photo-report-unsubscribe-confirmation.html',
      'createModelObject': (response) => {
        return new PhotoReportScheduleDataModel(response)
      }
    },
    'FILES_INFO': {
      'templateUrl': 'modals/files-info-report-unsubscribe-confirmation.html',
      'createModelObject': (response) => {
        return new FilesInfoReportScheduleDataModel(response)
      }
    }
  }

  let customErrorHandler = (response) => {
    if (response && response.errorCode === 'NOT_FOUND') {
      modal.open('reportingScheduleUnsubscribeConfirmation', {
        confirmationTemplateUrl: 'modals/report-unsubscribe-confirmation-all.html',
        confirm () {
          $state.go('dashboard.projects')
        },
        callback () {
          $state.go('dashboard.projects')
        }
      })
      return true
    }

    return false
  }

  let updatedSchedulePayload = {
    'removedRecipientsEmails': [$stateParams.email]
  }

  smartvidApi.updateReportingScheduleData($stateParams.scheduleId, updatedSchedulePayload, customErrorHandler).then((data) => {
    $scope.isLoading = false
    $scope.reportScheduleHandlingStrategy = REPORT_SCHEDULE_HANDLING_STRATEGIES[data.reportName]
    modal.open('reportingScheduleUnsubscribeConfirmation', {
      confirmationTemplateUrl: $scope.reportScheduleHandlingStrategy.templateUrl,
      confirmationDataModel: $scope.reportScheduleHandlingStrategy.createModelObject(data),
      reportName: 'reporting.schedule.unsubscribe.' + data.reportName,
      getCsvString: function (arr, defaultVal) {
        var res = ''
        for (var i = 0; i < arr.length; i++) {
          res += arr[i]
          if (i + 1 < arr.length) {
            res += ', '
          }
        }

        return res === '' ? defaultVal : res
      },

      formatDate: function (instant) {
        return moment(instant).format('MM/DD/YYYY')
      },

      getDate: function (instantStart, instantEnd) {
        if (instantEnd > 0 && instantStart > 0) {
          return this.formatDate(instantStart) + ' - ' + this.formatDate(instantEnd)
        } else {
          return 'All time - now'
        }

      },
      confirm () {
        $state.go('dashboard.projects')
      },
      callback () {
        $state.go('dashboard.projects')
      }
    })
  }, () => {
    $scope.isLoading = false
  })
})
